import React from 'react';
import useStyles from './index.style';

const CategoryItem = (props) => {
    const { category, setActiveCategory, activeCategory } = props;
    const classes = useStyles();

    return (
        <div
            className={`${classes.wrapper} ${activeCategory === category ? classes.active : ''}`}
            onClick={() => setActiveCategory(category)}
        >
            {category}
        </div>
    );
};

export default CategoryItem;
