import { makeStyles } from '@material-ui/core/styles';
import { COLOR_PRIMARY_SHADE_01, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    lastApplicationDateTypo: {
        ...fontStyle,
        fontSize: '12px',
        fontWeight: 'normal',
        padding: '3px 4px 5px 6px',
        borderRadius: '4px',
        backgroundColor: COLOR_PRIMARY_SHADE_01,
        height: '24px',
    },
});

export default useStyles;
