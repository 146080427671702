import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import Xarrow, { useXarrow } from 'react-xarrows';
import useStyles from './index.style';
import CardItem from './CardItem';
import MobileStepper from './MobileStepper';
import arrowPng from 'fintech/static/images/arrow.png';

const xarrowsStyle = { lineColor: '#F1F5F7', showHead: false, strokeWidth: 2 };
const Timesheet = ({ supportProgramOnePage, supportProgramId }) => {
    const { t } = useTranslation(['preLogin']);
    const classes = useStyles();

    return (
        <>
            <section className={classes.timesheetSection} id="section-timesheet">
                <div className={classes.one} />
                <div className={classes.two} />
                <div className={classes.three} />
                <div className={classes.timesheetContainer}>
                    <span className={classes.title}>{t('titles.timesheet')}</span>
                    <Grid container spacing={3} onLoad={useXarrow()} justify="center" className={classes.webContainer}>
                        {supportProgramOnePage.timesheetAreaData.map((item, inx) => (
                            <Grid item xs={12} sm={6} md={4} key={`keyItem_${inx}`} className={`element${item?.id}`}>
                                <CardItem item={item} supportProgramId={supportProgramId} />
                            </Grid>
                        ))}

                        <Xarrow
                            start="element1"
                            end="element2"
                            {...xarrowsStyle}
                            labels={{
                                middle: (
                                    <img
                                        className={classes.xArrowLabels}
                                        src={arrowPng}
                                        style={{
                                            opacity: '.65',
                                        }}
                                    />
                                ),
                            }}
                        />
                        <Xarrow
                            start="element2"
                            end="element3"
                            {...xarrowsStyle}
                            labels={{
                                middle: (
                                    <img
                                        className={classes.xArrowLabels}
                                        src={arrowPng}
                                        style={{
                                            opacity: '.72',
                                        }}
                                    />
                                ),
                            }}
                        />
                        <Xarrow start="element3" end="element3_1" {...xarrowsStyle} />
                        <Xarrow
                            start="element3_1"
                            end="element3_2"
                            {...xarrowsStyle}
                            path="grid"
                            labels={{
                                middle: (
                                    <img
                                        className={classes.xArrowLabels}
                                        src={arrowPng}
                                        style={{ transform: 'rotate(90deg)', opacity: '.79' }}
                                    />
                                ),
                            }}
                        />
                        <Xarrow start="element3_2" end="element4" {...xarrowsStyle} />
                        <Xarrow
                            start="element4"
                            end="element5"
                            {...xarrowsStyle}
                            labels={{
                                middle: (
                                    <img
                                        className={classes.xArrowLabels}
                                        src={arrowPng}
                                        style={{ transform: 'rotate(180deg)', opacity: '.85' }}
                                    />
                                ),
                            }}
                        />
                        <Xarrow
                            start="element5"
                            end="element6"
                            {...xarrowsStyle}
                            labels={{
                                middle: (
                                    <img
                                        className={classes.xArrowLabels}
                                        src={arrowPng}
                                        style={{ transform: 'rotate(180deg)', opacity: '.91' }}
                                    />
                                ),
                            }}
                        />
                        <Xarrow
                            start="element6"
                            end="element6_1"
                            {...xarrowsStyle}
                            labels={{
                                middle: (
                                    <img
                                        className={classes.xArrowLabels}
                                        src={arrowPng}
                                        style={{ transform: 'rotate(180deg)', opacity: '.94' }}
                                    />
                                ),
                            }}
                        />
                        <Xarrow
                            start="element6_1"
                            end="element7"
                            {...xarrowsStyle}
                            path="grid"
                            startAnchor={'bottom'}
                            labels={{
                                middle: (
                                    <img
                                        src={arrowPng}
                                        className={classes.xArrowLabels}
                                        style={{ transform: 'rotate(0deg)', opacity: '1', marginTop: '68px' }}
                                    />
                                ),
                            }}
                        />
                    </Grid>

                    <Grid container spacing={3} justifyContent="center" className={classes.mobileContainer}>
                        <MobileStepper
                            supportProgramOnePage={supportProgramOnePage}
                            supportProgramId={supportProgramId}
                        />
                    </Grid>
                </div>
            </section>
        </>
    );
};

export default Timesheet;
