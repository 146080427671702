import { makeStyles } from '@material-ui/core';
import { fontStyle, COLOR_GREEN, COLOR_THEME_FILL_03, COLOR_THEME_FILL_04 } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    approverIcon: {
        display: 'flex',
    },
    approverNameAndDate: {
        ...fontStyle,
        display: 'flex',
        color: COLOR_GREEN,
        paddingLeft: '4px',
        '& > div:last-child': {
            paddingLeft: '4px',
        },
    },
    deniedIcon: {
        background: COLOR_THEME_FILL_04,
        borderRadius: '50%',
    },
    approved: {
        color: COLOR_GREEN,
    },
    denied: {
        color: COLOR_THEME_FILL_03,
    },
    picker: {
        [theme.breakpoints.down('sm')]: {
            height: '780px',
            maxHeight: '780px',
        },
    },
}));

export default useStyles;
