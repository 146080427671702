import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, useMediaQuery } from '@material-ui/core';
import ActionModal from 'fintech/components/util/ActionModal';
import MultipleSelect from 'fintech/components/ui/form/select/MultipleSelect';
import { laptopLarge } from 'fintech/components/style/common';
import { SUBMIT, REJECT } from 'fintech/components/util/ActionModalConstants';

export default function ({ buttonActions, multipleSelectValues }) {
    const matches = useMediaQuery(laptopLarge);
    const modalSize = matches ? { minWidth: '700px', minHeight: '690px' } : {};
    const { t } = useTranslation('entrepreneur');
    const [selectedValues, setSelectedValues] = useState(null);

    const editModalActionHandler = (action) => {
        const { type } = action;

        if (type === SUBMIT) {
            buttonActions.apply(selectedValues);
            buttonActions.reject();
        } else if (type === REJECT) {
            buttonActions.reject();
        } else {
            return;
        }
    };

    const multipleSelectHandler = (elements, isValid, selectedValues) => {
        setSelectedValues(selectedValues);
    };

    return (
        <Box display="flex">
            <ActionModal
                open={open}
                title={t('modal.assignMentor')}
                onAction={editModalActionHandler}
                modalSize={modalSize}
            >
                {multipleSelectValues && multipleSelectValues.length > 0 && (
                    <Box p={2}>
                        <MultipleSelect
                            preventValidation
                            label={t('modal.mentors')}
                            callBack={(elements, isValid, selectedValues) =>
                                multipleSelectHandler(elements, isValid, selectedValues)
                            }
                            id="technology"
                            value={multipleSelectValues}
                            helperText={t('validation.notEmpty')}
                            multipleSelect={true}
                        />
                    </Box>
                )}
            </ActionModal>
        </Box>
    );
}
