import { makeStyles } from '@material-ui/core';
import { COLOR_THEME_FILL_01, COLOR_PRIMARY_BASE, COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    tabPanelWeb: { overflowY: 'scroll', maxHeight: '400px', padding: '16px 24px', width: '100%', height: '400px' },
    tabPanelMobile: { padding: '16px 24px', width: '100%' },
    tabContainerMobile: {
        display: 'inherit',
    },
    tabContainerWeb: {
        display: 'flex',
    },
    tabPanelHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '16px',
        alignItems: 'center',
        '& h6': {
            marginBottom: '0px',
        },
    },
    tabsRoot: {
        overflow: 'inherit',
        borderRight: `1px solid ${COLOR_PRIMARY_SHADE_02}`,
    },
    wrapperTab: {
        alignItems: 'self-start',
        justifyContent: 'flex-start',
    },
    tabRoot: {
        borderBottom: `1px solid ${COLOR_PRIMARY_SHADE_02}`,
        textTransform: 'inherit',
        padding: '6px 24px',
        '@media (min-width: 600px)': {
            minWidth: '200px',
        },
    },
    selectedTab: {
        color: COLOR_THEME_FILL_01,
        background: COLOR_PRIMARY_BASE,
        borderColor: COLOR_PRIMARY_BASE,
    },
    indicator: {
        backgroundColor: COLOR_PRIMARY_BASE,
    },
    switchText: {
        fontSize: '.813em',
        lineHeight: '1.5',
        fontWeight: '700',
        color: COLOR_PRIMARY_BASE,
        marginBottom: 0,
    },
}));

export default useStyles;
