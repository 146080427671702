import { InvestorApplicationActions } from 'fintech/store/actions/ActionTypes';
import { INVESTOR_INDIVIDUAL, INVESTOR_INSTITUTIONAL } from 'fintech/components/util/ApplicationFormConstants';
import { APIS } from 'fintech/constants';
import { getRequest } from 'fintech/api/api';

// Set investor info from invite
export const setInvestorFromInvite = ({ name, surname, email, companyName, companyIdentityNumber, companyType }) => {
    if (companyType === INVESTOR_INDIVIDUAL) {
        return {
            type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_INVITE_INDIVIDUAL,
            data: {
                type: INVESTOR_INDIVIDUAL,
                name,
                surname,
                companyIdentityNumber,
                email,
            },
        };
    } else {
        return {
            type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_INVITE_INSTITUTIONAL,
            data: {
                type: INVESTOR_INSTITUTIONAL,
                companyName,
                companyIdentityNumber,
                name,
                surname,
                email,
            },
        };
    }
};

// Actions for the countries in the system for nationality choice
const setCountries = (countries) => ({
    type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_COUNTRIES,
    countries: countries,
});

export const fetchCountries = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: InvestorApplicationActions.GET_INVESTOR_APPLICATION_COUNTRIES });
        getRequest(APIS.USER.getCountriesURL(), keycloak)
            .then((data) => {
                dispatch(setCountries(data));
            })
            .catch(() => {
                dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_COUNTRIES_LOAD_ERROR });
            });
    };
};

// Actions for genders in the system
const setGenders = (genders) => ({
    type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_GENDERS,
    genders: genders,
});

export const fetchGenders = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: InvestorApplicationActions.GET_INVESTOR_APPLICATION_GENDERS });
        getRequest(APIS.USER.getGendersURL(), keycloak)
            .then((data) => {
                dispatch(setGenders(data));
            })
            .catch(() => {
                dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_GENDERS_LOAD_ERROR });
            });
    };
};

// Actions for the education in the system for nationality choice
const setEducationLevels = (levels) => ({
    type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_EDUCATION_LEVELS,
    levels: levels,
});

export const fetchEducationLevels = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: InvestorApplicationActions.GET_INVESTOR_APPLICATION_EDUCATION_LEVELS });
        getRequest(APIS.SUPPORT_PROGRAM.getEducationInfos(), keycloak)
            .then((data) => {
                dispatch(setEducationLevels(data));
            })
            .catch(() => {
                dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR });
            });
    };
};

export const setMkkForm = (value) => ({
    type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_MKK_FORM,
    value,
})
