import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { EstablishmentActions } from 'fintech/store/actions/ActionTypes';
import { fetchEstablishmentIntroData } from 'fintech/store/actions/establishment';
import ActionModal from 'fintech/components/util/ActionModal';
import * as constants from 'fintech/components/util/ActionModalConstants';
import MediaUpdateActionModal from 'fintech/components/profile/idea/actionmodal/informationcard/content/MediaUpdateActionModal';
import * as FintechBeService from 'fintech/api/fintechService';
import GeneralInfoModal from 'fintech/components/profile/establishment/intro/form/GeneralInfoModal/GeneraInfoModal';
import KeywordsUpdateActionModal from 'fintech/components/profile/establishment/intro/form/KeywordsUpdateActionModal/KeywordsUpdateActionModal';
import { ISO_FORMAT, NUMERIC_FULL_DATE } from 'fintech/utils/DateUtils';
import { format, parse } from 'date-fns';
import { isDate, isEmpty } from 'lodash';
import { setActiveRequest } from 'fintech/store/actions/common';

const EstablishmentIntroEditModal = ({ open, onCancel }) => {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { uuid, investorMediaSet: media, hasActiveRequest } = useSelector((state) => ({
        ...state.establishment.intro.data,
        ...state.common,
    }));
    const { data: fetchedVerticals, isLoading: verticalsLoading, isError: verticalsError } = useSelector(
        (state) => state.establishment.intro.form.verticals
    );
    const { data: fetchedInvestmentAreas, isLoading: areasLoading, isError: areasError } = useSelector(
        (state) => state.establishment.intro.form.investmentAreas
    );
    const { data: fetchedStages, isLoading: stagesLoading, isError: stagesError } = useSelector(
        (state) => state.establishment.intro.form.stages
    );

    const fetchedData = useSelector((state) => getGeneralInfosFromState(state));
    const { enqueueSnackbar } = useSnackbar();

    const [selectedTabId, setSelectedTabId] = useState(constants.ESTABLISHMENT_GENERAL_INFO_UPDATE);
    const [mediaInfos, setMediaInfos] = useState(media);

    const [generalInfos, setGeneralInfos] = useState(fetchedData ? { ...fetchedData } : {});
    const [keywordsData, setKeywordsData] = useState({
        verticals: [],
        investmentAreas: [],
        stageData: [],
    });

    useEffect(() => {
        if (fetchedData && fetchedData.investorType?.id !== 0) {
            setGeneralInfos((prev) => ({
                ...prev,
                investorType: fetchedData.investorType,
            }));
        }
    }, [JSON.stringify(fetchedData)]);

    useEffect(() => {
        if (fetchedData && fetchedData.investorGroup?.id !== 0) {
            setGeneralInfos((prev) => ({
                ...prev,
                investorGroup: fetchedData.investorGroup,
            }));
        }
    }, [JSON.stringify(fetchedData)]);

    const tabs = [
        {
            id: constants.ESTABLISHMENT_GENERAL_INFO_UPDATE,
            name: t('idea.updateGeneralInfoTabLabel'),
            selected: true,
        },
        {
            id: constants.ESTABLISHMENT_KEYWORDS_UPDATE,
            name: t('idea.updateKeywordsLabel'),
            selected: false,
        },
        {
            id: constants.ESTABLISHMENT_MEDIA_UPDATE,
            name: t('idea.updateMedialLabel'),
            selected: false,
        },
    ];

    const keywordsChangeHandler = (data) => {
        setKeywordsData(data);
    };

    const rejectForm = () => {
        dispatch({
            type: EstablishmentActions.REMOVE_ESTABLISHMENT_INFORMATION_CARD_EDIT_STATE,
        });
        setGeneralInfos(fetchedData ? { ...fetchedData } : {});
        onCancel();
    };

    const generalInfosChangeHandler = ({ type, data }) => {
        setGeneralInfos((prevState) => {
            switch (type) {
                case constants.ESTABLISHMENT_DATE_FOUNDED_UPDATE:
                    return {
                        ...prevState,
                        dateFounded: {
                            ...data,
                        },
                    };
                case constants.ESTABLISHMENT_INVESTOR_TYPE_UPDATE:
                    return {
                        ...prevState,
                        investorType: {
                            ...data,
                        },
                    };
                case constants.ESTABLISHMENT_INVESTOR_GROUP_UPDATE:
                    return {
                        ...prevState,
                        investorGroup: {
                            ...data,
                        },
                    };
                case constants.ESTABLISHMENT_INVESTOR_WEBSITE_UPDATE:
                    return {
                        ...prevState,
                        webSite: {
                            ...data,
                        },
                    };
                case constants.ESTABLISHMENT_INVESTOR_FORM_SUBMIT:
                    return {
                        ...prevState,
                        formSubmitted: data,
                    };
                default:
                    return prevState;
            }
        });
    };

    const getSelectedTab = (selectedTabId) => {
        switch (selectedTabId) {
            case constants.ESTABLISHMENT_GENERAL_INFO_UPDATE:
                return (
                    <GeneralInfoModal
                        {...generalInfos}
                        formIsSubmitted={generalInfos.formSubmitted}
                        infoChangeCallback={generalInfosChangeHandler}
                    />
                );
            case constants.ESTABLISHMENT_KEYWORDS_UPDATE:
                return (
                    <KeywordsUpdateActionModal
                        formIsSubmitted={generalInfos.formSubmitted}
                        changeCallback={(tabId) => keywordsChangeHandler(tabId)}
                        fetchedVerticals={fetchedVerticals}
                        fetchedInvestmentAreas={fetchedInvestmentAreas}
                        fetchedStages={fetchedStages}
                        verticalsLoading={verticalsLoading}
                        verticalsError={verticalsError}
                        areasLoading={areasLoading}
                        areasError={areasError}
                        stagesLoading={stagesLoading}
                        stagesError={stagesError}
                    />
                );
            case constants.ESTABLISHMENT_MEDIA_UPDATE:
                return (
                    <MediaUpdateActionModal
                        initData={mediaInfos}
                        changeMediaCallback={(mediaInfos) => {
                            mediaChangeHandler(mediaInfos);
                        }}
                    />
                );
            default:
                return <GeneralInfoModal />;
        }
    };

    const updateData = () => {
        if (
            generalInfos.webSite.validated &&
            generalInfos.dateFounded.validated &&
            !isEmpty(generalInfos.investorType) &&
            !isEmpty(keywordsData.verticals) &&
            !isEmpty(keywordsData.investmentAreas) &&
            !isEmpty(keywordsData.stageData)
        ) {
            dispatch(setActiveRequest(true));
            const requestData = {
                dateFounded: isDate(generalInfos.dateFounded.value)
                    ? format(generalInfos.dateFounded.value, ISO_FORMAT)
                    : generalInfos.dateFounded.value,
                webSite: generalInfos.webSite.value,
                investorType: generalInfos.investorType,
                investorGroup: !isEmpty(generalInfos.investorGroup) ? generalInfos.investorGroup : {},
                selectedFintechSubVerticalInterests: keywordsData.verticals,
                selectedInvestmentAreas: keywordsData.investmentAreas,
                selectedStagePreferences: keywordsData.stageData,
                investorMedias: mediaInfos,
            };

            FintechBeService.updateInvestorInfo(keycloak, requestData, uuid)
                .then(() => {
                    dispatch(setActiveRequest(false));
                    dispatch({
                        type: EstablishmentActions.REMOVE_ESTABLISHMENT_INFORMATION_CARD_EDIT_STATE,
                    });
                    dispatch(fetchEstablishmentIntroData(keycloak, uuid));
                    onCancel();
                    enqueueSnackbar(t('idea.updateSucces'), { variant: 'success' });
                })
                .catch((reason) => {
                    dispatch(setActiveRequest(false));
                    enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
                });
        } else {
            enqueueSnackbar(t('validation.genericError'), { variant: 'error' });
        }
    };

    const editModalActionHandler = (action) => {
        switch (action.type) {
            case constants.TAB: {
                const { selectedTabId } = action;
                setSelectedTabId(selectedTabId);
                break;
            }
            case constants.SUBMIT:
                updateData();
                generalInfosChangeHandler({ type: constants.ESTABLISHMENT_INVESTOR_FORM_SUBMIT, data: true });
                return;
            case constants.REJECT:
                rejectForm();
                return;
            default:
                return;
        }
    };

    const mediaChangeHandler = (mediaInfos) => {
        setMediaInfos(mediaInfos);
    };

    return (
        <ActionModal
            open={open}
            tabs={tabs}
            title={t('profile.establishments.edit_information_card_title')}
            onAction={editModalActionHandler}
            okClickDisabled={hasActiveRequest || verticalsError || areasError || stagesError}
            cancelClickDisabled={hasActiveRequest}
            showSpinnerWhenDisabled={hasActiveRequest}
        >
            {getSelectedTab(selectedTabId)}
        </ActionModal>
    );
};

export default EstablishmentIntroEditModal;

const getGeneralInfosFromState = (state) => {
    const initialState = {};
    const { dateFounded, website } = state.establishment.intro.data;
    const { selectedType } = state.establishment.intro.form.investorTypes;
    const { selectedGroup } = state.establishment.intro.form.investorGroups;

    // Date founded
    initialState.dateFounded = {
        value: dateFounded ? parse(dateFounded, NUMERIC_FULL_DATE, new Date()) : '',
        validated: true,
    };

    initialState.investorType = selectedType;
    initialState.investorGroup = selectedGroup;

    initialState.webSite = {
        value: website,
        validated: !!website,
    };
    return initialState;
};
