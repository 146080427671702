import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LogoFinTechHub from 'fintech/static/images/Logo-FinTechHub-Erkek.svg';
import { COLOR_SECONDARY_BASE, fontStyle } from 'fintech/components/style/common';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';

const useStyles = makeStyles({
    loadingTypo: {
        ...fontStyle,
        textAlign: 'center',
        fontSize: '20px',
        marginTop: '144px',
        marginBottom: '34px',
        color: COLOR_SECONDARY_BASE,
    },
});

const LoadingPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container direction="column" justify="center" alignItems="center">
            <Grid item>
                <img src={LogoFinTechHub} width={260} />
            </Grid>
            <Grid item>
                <Typography className={classes.loadingTypo}>{t('loadingMessage')}</Typography>
            </Grid>
            <Grid item>
                <LoadingSpinner />
            </Grid>
        </Grid>
    );
};

export default LoadingPage;
