import React, { useCallback } from 'react';
import IconsMultipleDuyuru from 'fintech/icons/IconsMultipleDuyuru';
import IconsMultipleGirisimci from 'fintech/icons/IconsMultipleGirisimci';
import IconsMultipleYatirimci from 'fintech/icons/IconsMultipleYatirimci';
import IconsMultipleMentor from 'fintech/icons/IconsMultipleMentor';
import IconsMultipleDanisman from 'fintech/icons/IconsMultipleDanisman';
import IconsMultipleKisi from 'fintech/icons/IconsMultipleKisi';
import IconsMultipleEgitim from 'fintech/icons/IconsMultipleEgitim';
import IconsMultipleDestekprogramlari from 'fintech/icons/IconsMultipleDestekprogramlari';
import IconsMultipleProfil from 'fintech/icons/IconsMultipleProfil';
import IconsMultipleProfilSirket from 'fintech/icons/IconsMultipleProfilSirket';
import IconsMultipleProfilStartup from 'fintech/icons/IconsMultipleProfilStartup';
import IconsMultipleSettings from 'fintech/icons/IconsMultipleSettings';
import IconsMultipleLogout from 'fintech/icons/IconsMultipleLogout';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { IconsMultipleRapor } from 'fintech/icons';
import Tooltip from 'fintech/components/ui/tooltip';

const menuIconMap = {
    'EVENTS&ANNOUNCEMENT': <IconsMultipleDuyuru />,
    ENTREPRENEURS: <IconsMultipleGirisimci />,
    INVESTORS: <IconsMultipleYatirimci />,
    MENTORS: <IconsMultipleMentor />,
    CONSULTANTS: <IconsMultipleDanisman />,
    PERSONS: <IconsMultipleKisi />,
    TRAININGS: <IconsMultipleEgitim />,
    SUPPORTPROGRAMS: <IconsMultipleDestekprogramlari />,
    MYPROFILE: <IconsMultipleProfil />,
    MYATTEMPT: <IconsMultipleProfilStartup />,
    MYESTABLISHMENT: <IconsMultipleProfilSirket />,
    MEMBERSHIP_MANAGEMENT: <IconsMultipleProfil />,
    REPORTS: <IconsMultipleRapor />,
    SIGN_OUT: <IconsMultipleLogout />,
    SETTINGS: <IconsMultipleSettings />,
};

const LeftMenuItem = ({ id, name, isOneRow, onSelect, onClick, routing, currentPathname }) => {
    const onItemClick = useCallback(() => {
        onSelect(id, routing, onClick);
    }, [onSelect]);

    const getMenuItemIcon = function () {
        return menuIconMap[id];
    };

    const isCurrentRoute = function () {
        return currentPathname.includes(routing);
    };

    const createMenuItem = function () {
        if (isOneRow) {
            let rowViewClassName = 'poppin-500-14px full-width leftMenuItemContainer leftMenuRowView';
            if (isCurrentRoute()) {
                rowViewClassName += ' leftMenuRowItemSelected';
            }
            return (
                <Grid
                    item
                    xs={12}
                    container
                    direction={'row'}
                    className={rowViewClassName}
                    alignItems={'center'}
                    justify={'center'}
                    onClick={onItemClick}
                >
                    <div className={'flex-1'}>{getMenuItemIcon()}</div>
                    <div className={'flex-4'}>{name}</div>
                </Grid>
            );
        } else {
            let cardViewClassName = 'poppin-500-12px leftMenuItemContainer leftMenuCardView';
            if (isCurrentRoute()) {
                cardViewClassName += ' leftMenuCardItemSelected';
            }
            return (
                <Tooltip title={name} aria-label={name}>
                    <Grid
                        container
                        className={cardViewClassName}
                        alignItems={'center'}
                        justify={'center'}
                        direction={'row'}
                        onClick={onItemClick}
                    >
                        <Grid item>
                            <Grid
                                className={''}
                                container
                                item
                                xs={12}
                                direction={'column'}
                                alignItems={'center'}
                                justify={'center'}
                            >
                                {getMenuItemIcon()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Tooltip>
            );
        }
    };

    return createMenuItem();
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(LeftMenuItem);
