import React, { useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function CheckboxesGroup({ id, defaultState, label, callbackMethod }) {
    const [state, setState] = React.useState(defaultState);

    const handleChange = (event) => {
        setState(event.target.checked);
        callbackMethod(id, event.target.checked);
    };

    return (
        <div className={''}>
            <FormControlLabel
                key={id}
                value="end"
                control={<Checkbox checked={state} onChange={handleChange} color={'primary'} />}
                label={label}
                labelPlacement="end"
            />
        </div>
    );
}
