import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        background: '#102738',
        height: 88,
        alignItems: 'center',
        paddingLeft: 52,
        paddingRight: 52,
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23)',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'auto',
            padding: '10px 0',
        },
    },
    logoWrapper: {
        cursor: 'pointer',
    },
    languagePanelWrapper: {
        '& *': {
            color: 'white !important',
        },

        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
}));

export default useStyles;
