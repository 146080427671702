import { Tooltip, withStyles } from '@material-ui/core';
import {
    fontStyle,
    COLOR_THEME_FILL_01,
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_SHADE_03,
} from 'fintech/components/style/common';

export default withStyles({
    tooltip: {
        ...fontStyle,
        color: COLOR_SECONDARY_BASE,
        borderRadius: '8px',
        boxShadow: '0 12px 33px 0 rgba(0, 0, 0, 0.3), 0 3px 7px 0 rgba(0, 0, 0, 0.22)',
        border: `solid 1px ${COLOR_PRIMARY_SHADE_03}`,
        backgroundColor: COLOR_THEME_FILL_01,
        textAlign: 'center',
        margin: '0px 0',
    },
})(Tooltip);
