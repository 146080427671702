import * as React from 'react';

function SvgIconsSingleKeyword(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <path
                d="M13.28 19c.314 0 .619-.13.84-.342l4.538-4.546a1.19 1.19 0 000-1.673L12.051 5.84C11.58 5.37 10.683 5 10.027 5H6.183C5.536 5 5 5.536 5 6.183v3.844c0 .656.37 1.553.84 2.015l6.608 6.616c.213.213.518.342.832.342zM7.957 9.14c-.656 0-1.183-.527-1.183-1.183s.527-1.183 1.183-1.183 1.183.527 1.183 1.183S8.613 9.14 7.957 9.14z"
                fill="#3F5162"
                fillRule="nonzero"
            />
        </svg>
    );
}

export default SvgIconsSingleKeyword;
