import { getRequest, putRequest } from 'fintech/api/api';
import { StartupActions } from './ActionTypes';
import { APIS } from 'fintech/constants';
import { parseMedia, generateGridRowFromStartUpData } from 'fintech/utils/DataParser';
import { postProfileVisitCount } from 'fintech/api/fintechService';
import { getInitialGridHeaderState } from './entrepreneur';
const timeoutDur = 500;

// Actions for Intro card & general information
const setIntro = (startup) => ({
    type: StartupActions.SET_STARTUP_INTRO,
    startup: startup,
});

const setVisitTimes = (visitTimes) => ({
    type: StartupActions.SET_STARTUP_INTRO_VISIT_TIMES,
    visitTimes: visitTimes,
});

export const fetchStartupIntroData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.SET_STARTUP_INTRO_LOADING });
        setTimeout(() => {
            getRequest(APIS.STARTUP.getProfileURL(uuid), keycloak)
                .then((data) => {
                    if (data.medias != null && data.medias.length > 0) {
                        parseMedia(data.medias).then(function (resp) {
                            data.medias = resp;
                            dispatch(setIntro(data));
                        });
                    } else {
                        dispatch(setIntro(data));
                    }
                })
                .catch(() => {
                    dispatch({ type: StartupActions.SET_STARTUP_INTRO_LOAD_ERROR });
                });
        }, timeoutDur);
    };
};

export const updateVisitCount = (keycloak, pageType, startupUuid) => {
    return (dispatch) => {
        postProfileVisitCount(keycloak, pageType, startupUuid).then((response) => {
            if (response && response.success) {
                dispatch(setVisitTimes(response.data));
            }
        });
    };
};

// Actions for team members
const setTeam = (uuid, team) => ({
    type: StartupActions.SET_STARTUP_TEAM,
    id: uuid,
    team: team,
});

export const fetchStartupTeamData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.SET_STARTUP_TEAM_LOADING });
        setTimeout(() => {
            getRequest(APIS.STARTUP.getTeamURL(uuid), keycloak)
                .then((data) => {
                    dispatch(setTeam(uuid, data));
                })
                .catch(() => {
                    dispatch({ type: StartupActions.SET_STARTUP_TEAM_ERROR });
                });
        }, timeoutDur);
    };
};

// Actions for verticals
const setVerticals = (uuid, verticals) => ({
    type: StartupActions.SET_STARTUP_VERTICALS,
    id: uuid,
    verticalData: verticals,
});

export const fetchStartupVerticalsData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.SET_STARTUP_VERTICALS_LOADING });
        getRequest(APIS.STARTUP.getVerticalsURL(uuid), keycloak)
            .then((data) => dispatch(setVerticals(uuid, data)))
            .catch(() => {
                dispatch({ type: StartupActions.SET_STARTUP_VERTICALS_LOAD_ERROR });
            });
    };
};

// Actions for technologies
const setTechnologies = (uuid, technologies) => ({
    type: StartupActions.SET_STARTUP_TECHNOLOGIES,
    id: uuid,
    technologyData: technologies,
});

export const fetchStartupTechnologiesData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.SET_STARTUP_TECHNOLOGIES_LOADING });
        getRequest(APIS.STARTUP.getTechnologiesURL(uuid), keycloak)
            .then((data) => dispatch(setTechnologies(uuid, data)))
            .catch(() => {
                dispatch({ type: StartupActions.SET_STARTUP_TECHNOLOGIES_LOAD_ERROR });
            });
    };
};

// Actions for stages
const setStages = (uuid, stages) => ({
    type: StartupActions.SET_STARTUP_STAGES,
    id: uuid,
    stageData: stages,
});

export const fetchStartupStagesData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.SET_STARTUP_STAGES_LOADING });
        getRequest(APIS.STARTUP.getStagesURL(uuid), keycloak)
            .then((data) => dispatch(setStages(uuid, data)))
            .catch(() => {
                dispatch({ type: StartupActions.SET_STARTUP_STAGES_LOAD_ERROR });
            });
    };
};

// Actions for countries
const setCountries = (uuid, countryData) => ({
    type: StartupActions.SET_STARTUP_COUNTRIES,
    id: uuid,
    countryData: countryData,
});

export const fetchStartupCountryData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.SET_STARTUP_COUNTRIES_LOADING });
        getRequest(APIS.STARTUP.getCountryURL(uuid), keycloak)
            .then((data) =>
                setTimeout(() => {
                    dispatch(setCountries(uuid, data));
                }, timeoutDur)
            )
            .catch(() => {
                dispatch({ type: StartupActions.SET_STARTUP_COUNTRIES_LOAD_ERROR });
            });
    };
};

// Actions for cities
const setCities = (uuid, cityData) => ({
    type: StartupActions.SET_STARTUP_CITIES,
    id: uuid,
    cityData: cityData,
});

export const fetchStartupCitiesData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.SET_STARTUP_CITIES_LOADING });
        getRequest(APIS.STARTUP.getCitiesURL(uuid), keycloak)
            .then((data) =>
                setTimeout(() => {
                    dispatch(setCities(uuid, data));
                }, timeoutDur)
            )
            .catch(() => {
                dispatch({ type: StartupActions.SET_STARTUP_CITIES_LOAD_ERROR });
            });
    };
};

// Action for SimilarVentures
export const fetchSimilarVenturesData = (keycloak, uuid, errorCallback) => {
    return (dispatch) => {
        getRequest(`${APIS.STARTUP.getSimilarStartups(uuid)}`, keycloak)
            .then((resp) => {
                const content = resp.data.result;
                const rows = [];
                const headers = getInitialGridHeaderState(false, resp.data.canFavorite);

                content.forEach((data) => {
                    rows.push(generateGridRowFromStartUpData(data, resp.data.canFavorite));
                });

                dispatch({
                    type: StartupActions.SET_STARTUP_SIMILARVENTURES_GRID_DATA,
                    data: { rows, headers },
                });
            })
            .catch(() => {
                errorCallback();
                dispatch(setGridLoadErrorOnSimilarVenture());
            });
    };
};

export const favoriteStartupOnSimilarVenture = (
    keycloak,
    startupUuid,
    successCallback,
    errorCallback,
    index,
    subIndex
) => {
    return (dispatch) => {
        putRequest(APIS.USER.getFavoriteStartup(startupUuid), keycloak)
            .then(() => {
                dispatch({
                    type: StartupActions.SET_STARTUP_SIMILARVENTURES_GRID_ROW_FAVOURITE,
                    data: { index, subIndex, status: true },
                });

                successCallback();
            })
            .catch(() => errorCallback());
    };
};

export const unFavoriteStartupOnSimilarVenture = (
    keycloak,
    startupUuid,
    successCallback,
    errorCallback,
    index,
    subIndex
) => {
    return (dispatch) => {
        putRequest(APIS.USER.getUnfavoriteStartup(startupUuid), keycloak)
            .then(() => {
                dispatch({
                    type: StartupActions.SET_STARTUP_SIMILARVENTURES_GRID_ROW_FAVOURITE,
                    data: { index, subIndex, status: false },
                });
                successCallback();
            })
            .catch(() => {
                errorCallback();
            });
    };
};

export const toggleGridHeaderOnSimilarVenture = (id) => ({
    type: StartupActions.TOGGLE_STARTUP_SIMILARVENTURES_GRID_STATE,
    data: +id,
});

export const setPageNumberOfPaginationOnSimilarVenture = (pageNumber) => ({
    type: StartupActions.SET_STARTUP_SIMILARVENTURES_GRID_PAGINATION,
    data: {
        page: pageNumber,
    },
});

export const setGridLoadErrorOnSimilarVenture = () => ({
    type: StartupActions.SET_STARTUP_SIMILARVENTURES_GRID_LOAD_ERROR,
});

// Actions for financials info in the financials tab
const setFinancials = (financials, uuid) => ({
    type: StartupActions.SET_STARTUP_FINANCIALS,
    financials: financials,
    startupId: uuid,
});

export const fetchStartupFinancialsData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.SET_STARTUP_FINANCIALS_LOADING });
        setTimeout(() => {
            getRequest(APIS.STARTUP.getFinancialURL(uuid), keycloak)
                .then((data) => dispatch(setFinancials(data, uuid)))
                .catch(() => {
                    dispatch({ type: StartupActions.SET_STARTUP_FINANCIALS_LOAD_ERROR });
                });
        }, timeoutDur);
    };
};

// Actions for the investments in the financials tab
const setInvestments = (investments) => ({
    type: StartupActions.SET_STARTUP_INVESTMENTS,
    investments: investments,
});

export const fetchStartupInvestmentsData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.SET_STARTUP_INVESTMENTS_LOADING });
        setTimeout(() => {
            getRequest(APIS.STARTUP.getInvestmentsURL(uuid), keycloak)
                // mockApiRequest(1000, mockInvestmentSuccessResponse())
                .then((data) => dispatch(setInvestments(data)))
                .catch(() => {
                    dispatch({ type: StartupActions.SET_STARTUP_INVESTMENTS_LOAD_ERROR });
                });
        }, timeoutDur);
    };
};

// Actions for financials interactions in the financials tab
const setIncomeModels = (uuid, incomeModels) => ({
    type: StartupActions.SET_STARTUP_INCOME_MODELS,
    id: uuid,
    incomeModelData: incomeModels,
});

export const fetchStartupIncomeModelsData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.SET_STARTUP_INCOME_MODELS_LOADING });
        getRequest(APIS.STARTUP.getIncomeModelsURL(uuid), keycloak)
            // mockApiRequest(1000, mockIncomeModelSuccessResponse())
            .then((data) => dispatch(setIncomeModels(uuid, data)))
            .catch(() => {
                dispatch({ type: StartupActions.SET_STARTUP_INCOME_MODELS_LOAD_ERROR });
            });
    };
};

// Actions for investor interactions in the financials tab
const setInvestorsForAutoSelect = (investors) => ({
    type: StartupActions.SET_STARTUP_INVESTOR_OPTIONS,
    investors: investors,
});

export const fetchInvestorOptions = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: StartupActions.GET_STARTUP_INVESTOR_OPTIONS });
        getRequest(APIS.INVESTOR.getInvestorsAutocompleteURL(), keycloak)
            // mockApiRequest(500, mockInvestorsSuccessResponse(searchText))
            .then((data) => dispatch(setInvestorsForAutoSelect(data)))
            .catch(() => {
                dispatch({ type: StartupActions.SET_STARTUP_INVESTOR_OPTIONS_LOAD_ERROR });
            });
    };
};

// Initialize the form with initial data if editing a record.
export const setStartupInvestmentForm = (id) => ({
    type: StartupActions.SET_STARTUP_INVESTMENT,
    id: id,
});

// Investor id, name & isVisible
export const setStartupInvestor = ({
    investorId,
    investorName,
    freeTextInvestorName,
    isValid,
    isVisible,
    unregistered,
}) => ({
    type: StartupActions.SET_STARTUP_INVESTOR,
    investor: {
        investorId: investorId,
        investorName: investorName,
        freeTextInvestorName: freeTextInvestorName,
        isValid: isValid,
        unregistered: unregistered,
    },
    investorVisible: isVisible,
});

// Investment date & isVisible
export const setStartupInvestmentDate = ({ dateISOStr, isVisible, isValid }) => ({
    type: StartupActions.SET_STARTUP_INVESTMENT_DATE,
    investmentDate: dateISOStr,
    investmentDateIsValid: isValid,
    investmentDateVisible: isVisible,
});

// Monetary value id, amount, currencyType id & isVisible
export const setStartupInvestmentAmount = ({ id, value, currency, isVisible, isValid }) => ({
    type: StartupActions.SET_STARTUP_INVESTMENT_AMOUNT,
    investmentAmount: {
        id: id,
        value: value,
        currencyId: currency,
        isValid: isValid,
    },
    investmentAmountVisible: isVisible,
});

// General visibility of the record
export const setStartupInvestmentVisibility = (isVisible) => ({
    type: StartupActions.SET_STARTUP_INVESTMENT_VISIBILITY,
    investmentVisible: isVisible,
});

export const sendStartupInvestorForm = () => ({
    type: StartupActions.SEND_STARTUP_INVESTMENT_FORM,
});

export const resetStartupInvestorForm = () => ({
    type: StartupActions.RESET_STARTUP_INVESTOR_FORM,
});
