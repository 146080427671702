import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    rectangleDivider: {
        width: '3px',
        height: '32px',
        margin: '1px 13px 8px 0',
        background: 'white',
    },
    eventGridContainer: {
        display: 'flex',

        '& > div > div > div': {
            height: '270px !important',
        },

        '& p': {
            position: 'static',
        },

        '& p:last-child': {
            marginBottom: 23,
        },

        '& li > div > div': {
            height: '270px !important',
            borderRadius: 10,
        },
    },
    eventTitle: {
        'font-size': '24px',
        color: 'white',
    },
}));

export default useStyles;
