import React from 'react';

import InstagramIcon from '@material-ui/icons/Instagram';
import Twitter from '@material-ui/icons/Twitter';
import YouTube from '@material-ui/icons/YouTube';
import LanguageIcon from '@material-ui/icons/Language';

import IconsSingleEllipsis from 'fintech/icons/IconsSingleEllipsis';
import IconsSingleTeams from 'fintech/icons/IconsSingleTeams';
import IconsSingleZoom from 'fintech/icons/IconsSingleZoom';
import IconsSingleSlack from 'fintech/icons/IconsSingleSlack';

import useStyles from 'fintech/components/profile/user/Intro/Details/SocialLink/index.style';
import { LinkedIn } from '@material-ui/icons';

const SocialLink = ({ social, link }) => {
    const classes = useStyles();
    let iconJSX;
    if (social === 'instagram') {
        iconJSX = <InstagramIcon className={classes.SocialMediaIcon} />;
    } else if (social === 'twitter') {
        iconJSX = <Twitter className={classes.SocialMediaIcon} />;
    } else if (social === 'youtube') {
        iconJSX = <YouTube className={classes.SocialMediaIcon} />;
    } else if (social === 'teams') {
        iconJSX = <IconsSingleTeams className={classes.SocialMediaIcon} />;
    } else if (social === 'zoom') {
        iconJSX = <IconsSingleZoom className={classes.SocialMediaIcon} />;
    } else if (social === 'slack') {
        iconJSX = <IconsSingleSlack className={classes.SocialMediaIcon} />;
    } else if (social === 'linkedin') {
        iconJSX = <LinkedIn className={classes.SocialMediaIcon} />;
    }

    return (
        <a href={link} target="_blank" className={classes.SocialLink} rel="noreferrer">
            {iconJSX}
        </a>
    );
};

export default SocialLink;
