import * as React from 'react';

function SvgIconsMultipleEgitim(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#D9DCE0"
                    d="M18.75 20.25l-2.625 1.5-3-1.5h-2.25l-3 1.5-2.625-1.5H3l-2.625 1.5V24h23.25v-2.25L21 20.25z"
                />
                <path
                    fill="#3F5162"
                    d="M16.137 22.175l-3.18-1.589.336-.671 2.82 1.41 2.451-1.401.372.651zm-8.274 0l-2.799-1.6.372-.651 2.451 1.401 2.82-1.41.336.671z"
                />
                <path
                    fill="#3F5162"
                    d="M7.5 22.875h.75V21.75H7.5zM0 21.533V24h.75v-2.032l2.436-1.392-.372-.651zm20.814-.957l2.436 1.392V24H24v-2.468l-2.814-1.608zm-5.064 2.299h.75V21.75h-.75z"
                />
                <path
                    fill="#FFF"
                    d="M21.75 18c0-.75-.375-1.875-1.875-1.875S18 17.25 18 18v.375c0 1.125.75 2.25 1.875 2.25s1.875-1.125 1.875-2.25V18z"
                />
                <path
                    fill="#3F5162"
                    d="M19.875 16.5c-1.354 0-1.5 1.05-1.5 1.5v.375c0 .902.573 1.875 1.5 1.875s1.5-.973 1.5-1.875V18c0-.45-.146-1.5-1.5-1.5m0 4.5c-1.39 0-2.25-1.362-2.25-2.625V18c0-1.038.59-2.25 2.25-2.25 1.66 0 2.25 1.212 2.25 2.25v.375c0 1.263-.86 2.625-2.25 2.625"
                />
                <path
                    fill="#FFF"
                    d="M13.875 18c0-.75-.375-1.875-1.875-1.875S10.125 17.25 10.125 18v.375c0 1.125.75 2.25 1.875 2.25s1.875-1.125 1.875-2.25V18z"
                />
                <path
                    fill="#3F5162"
                    d="M12 16.5c-1.354 0-1.5 1.05-1.5 1.5v.375c0 .902.573 1.875 1.5 1.875s1.5-.973 1.5-1.875V18c0-.45-.146-1.5-1.5-1.5m0 4.5c-1.39 0-2.25-1.362-2.25-2.625V18c0-1.038.59-2.25 2.25-2.25 1.66 0 2.25 1.212 2.25 2.25v.375C14.25 19.638 13.39 21 12 21"
                />
                <path
                    fill="#FFF"
                    d="M6 18c0-.75-.375-1.875-1.875-1.875S2.25 17.25 2.25 18v.375c0 1.125.75 2.25 1.875 2.25S6 19.5 6 18.375V18z"
                />
                <path
                    fill="#3F5162"
                    d="M4.125 16.5c-1.354 0-1.5 1.05-1.5 1.5v.375c0 .902.573 1.875 1.5 1.875s1.5-.973 1.5-1.875V18c0-.45-.146-1.5-1.5-1.5m0 4.5c-1.39 0-2.25-1.362-2.25-2.625V18c0-1.038.59-2.25 2.25-2.25 1.66 0 2.25 1.212 2.25 2.25v.375c0 1.263-.86 2.625-2.25 2.625"
                />
                <path fill="#E8E6E4" d="M2.25 1.875v-.75a.75.75 0 00-.75-.75H.75A.375.375 0 00.375.75v3L2.25 1.875z" />
                <path
                    fill="#D9DCE0"
                    d="M22.125 2.25L18.375 6H5.625l-3.75-3.75-1.5 1.5L5.25 8.625h1.875c.621 0 1.125.504 1.125 1.125v4.875h7.5V9.75c0-.621.504-1.125 1.125-1.125h1.875l4.875-4.875-1.5-1.5z"
                />
                <path
                    fill="#E8E6E4"
                    d="M21.75 1.875v-.75a.75.75 0 01.75-.75h.75c.207 0 .375.168.375.375v3L21.75 1.875z"
                />
                <path
                    fill="#3F5162"
                    d="M16.125 14.625h-.75V9.75c0-.827.673-1.5 1.5-1.5h1.72l4.655-4.655V.75h-.75a.375.375 0 00-.375.375h-.75C21.375.505 21.88 0 22.5 0h.75a.75.75 0 01.75.75v3.155L18.905 9h-2.03a.75.75 0 00-.75.75v4.875zm-7.5 0h-.75V9.75a.75.75 0 00-.75-.75h-2.03L0 3.905V.75A.75.75 0 01.75 0h.75c.62 0 1.125.505 1.125 1.125h-.75A.375.375 0 001.5.75H.75v2.845L5.405 8.25h1.72c.827 0 1.5.673 1.5 1.5v4.875z"
                />
                <path
                    fill="#3F5162"
                    d="M21.75 2.625a.375.375 0 01-.375-.375V1.125a.375.375 0 01.75 0V2.25a.375.375 0 01-.375.375m-19.5 0a.375.375 0 01-.375-.375V1.125a.375.375 0 01.75 0V2.25a.375.375 0 01-.375.375m9.375 5.625h.75V7.5h-.75zm0 1.5h.75V9h-.75z"
                />
                <path fill="#FFF" d="M14.25 2.625a2.25 2.25 0 11-4.502-.002 2.25 2.25 0 014.502.002" />
                <path
                    fill="#3F5162"
                    d="M12 .75c-1.034 0-1.875.841-1.875 1.875S10.966 4.5 12 4.5s1.875-.841 1.875-1.875S13.034.75 12 .75m0 4.5a2.629 2.629 0 01-2.625-2.625C9.375 1.177 10.553 0 12 0s2.625 1.177 2.625 2.625A2.629 2.629 0 0112 5.25M10.125 15H24v-.75H10.125zM0 15h9.375v-.75H0z"
                />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleEgitim;
