import { makeStyles, withStyles, Button } from '@material-ui/core';
import {
    poppins500_12px,
    poppins500_14px,
    COLOR_SECONDARY_BASE,
    COLOR_THEME_FILL_01,
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
} from 'fintech/components/style/common';

export const CloseButton = withStyles({
    root: {
        textTransform: 'none',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        color: COLOR_SECONDARY_BASE,
        borderRadius: '8px',
        padding: '14px 22px',
        '&:hover': {
            backgroundColor: 'rgb(0 0 0 / 8%)',
        },
    },
})(Button);

export const SaveButton = withStyles({
    root: {
        textTransform: 'none',
        backgroundColor: COLOR_PRIMARY_BASE,
        borderRadius: '8px',
        padding: '14px 22px',
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    modal: {
        zIndex: '10000',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        backgroundColor: COLOR_THEME_FILL_01,
        width: '740px',
        height: '520px',
        padding: '24px 48px  32px',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            height: 'auto',
            padding: '10px',
        },
    },
    modalHeading: {
        fontSize: '24px',
        fontWeight: '600',
        textAlign: 'center',
        color: COLOR_SECONDARY_BASE,
    },
    modalDescription: {
        ...poppins500_12px,
        textAlign: 'center',
        marginTop: '11px',
    },
    formControl: {
        minWidth: 120,
        marginTop: '42px',
        marginBottom: '40px',
        ...poppins500_14px,
        height: '56px',
        width: '325px',
    },
    menuPaper: {
        maxHeight: '378px',
    },
    timeSlots: {
        marginBottom: '24px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },
    slot: {
        width: '144px',
        margin: '0 22px 16px 0',
        '&:nth-child(4n)': {
            marginRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            flexBasis: '47%',
            marginRight: 0,
            width: 'auto',
        },
        '& > div:first-child': {
            borderRadius: '8px',
            fontWeight: '500',
            userSelect: 'none',
        },
        cursor: 'pointer',
    },
}));

export default useStyles;
