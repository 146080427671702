import { makeStyles } from '@material-ui/core';

import {
    COLOR_THEME_FILL_01,
    COLOR_THEME_FILL_03,
    COLOR_THEME_FILL_04,
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_SHADE_01,
    COLOR_PRIMARY_SHADE_03,
    COLOR_SECONDARY_BASE,
    COLOR_DANGER,
} from 'fintech/components/style/common';

export const makeChatPanelStyles = makeStyles(() => ({
    panel: {
        flex: 3,
        display: "flex",
        flexDirection: "column"
    },
}));