import * as React from 'react';

function SvgIconsSingleCheckWhite(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" {...props}>
            <defs>
                <filter id="1l8q4ks2ia" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g
                            filter="url(#1l8q4ks2ia)"
                            transform="translate(-834 -892) translate(350 440) translate(480.96 440)"
                        >
                            <g>
                                <path
                                    fill="#3F5162"
                                    fillRule="nonzero"
                                    d="M9.75 16.938L18.75 7.938 17.688 6.876 9.75 14.832 6.618 11.7 5.574 12.762z"
                                    transform="translate(4 12)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleCheckWhite;
