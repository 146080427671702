import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { Box, Button, Typography } from '@material-ui/core';
import StickyTable from 'fintech/components/table/StickyTable';
import { renderGridStyle } from 'fintech/pages/post-login/EntrepreneursPage/index.style';
import Empty from 'fintech/components/util/Empty';
import useCardGridStyles from 'fintech/components/search/CardGrid/index.style';
import useTableScroll from 'fintech/components/table/StickyTable/hooks/useTableScroll';

const SearchDataGrid = (props) => {
    const {
        type,
        headers,
        buttonActions,
        adminActions,
        stickyHeaderCount,
        leftStickyColumnCount,
        disableStickyHeader = false,
        heightInPixelsPerElement = 82,
        messages = {
            emptyMessageTitle: 'noResult',
        },
    } = props;
    const cardGridClasses = useCardGridStyles();
    const { t } = useTranslation(type);
    // TODO: Give the grid a disabled effect much like in DealRoom
    const { rows, isLoading, isError } = useSelector((state) => {
        const _type = type.split('.');
        return _type.reduce((accumulator, current) => {
            return accumulator[current];
        }, state).grid;
    });

    const { lastQueryType = null } = useSelector((state) => {
        const _type = type.split('.');
        const globalState = _type.reduce((accumulator, current) => {
            return accumulator[current];
        }, state).global;

        return isNil(globalState) ? { lastQueryType: 'search' } : globalState;
    });

    const isScrollable = useTableScroll([rows]);
    const styles = renderGridStyle({
        isLoading,
        numberOfRows: rows ? rows.length : 0,
        heightInPixelsPerElement,
        isScrollable,
    });

    let buttonText = null;

    if (messages.buttonText) {
        buttonText = messages.buttonText;
    } else {
        buttonText = lastQueryType === 'search' ? t('searchbar.clearSearchResult') : t('searchbar.clearFilters');
    }
    const emptyMessage = (
        <>
            <Typography style={{ textAlign: 'center' }}>{t(`searchbar.${messages.emptyMessageTitle}`)}</Typography>
            {(buttonActions.clearAll || buttonActions.goToEntrepreneurs) && (
                <Box className={styles.removeSearchResultBtnContainer}>
                    <Button
                        className={styles.removeSearchResultBtn}
                        onClick={buttonActions.clearAll || buttonActions.goToEntrepreneurs}
                    >
                        {buttonText}
                    </Button>
                </Box>
            )}
        </>
    );

    let gridJSX = null;
    if (isLoading) {
        gridJSX = (
            <div className={cardGridClasses.EmptyWrapperWithBorder}>
                <Empty isLoading message={t('profile.loadingMessage')} circularShapes />
            </div>
        );
    } else if (isError) {
        gridJSX = (
            <div className={cardGridClasses.EmptyWrapperWithBorder}>
                <Empty isError message={t('dataGrid.fetchError')} circularShapes />
            </div>
        );
    } else if (rows && rows.length > 0) {
        gridJSX = (
            <StickyTable
                containerClass={styles.container}
                rightScrollButtonRightMargin={43}
                disableStickyHeader={disableStickyHeader}
                headers={headers}
                rows={rows}
                buttonActions={buttonActions}
                adminActions={adminActions}
                stickyHeaderCount={stickyHeaderCount}
                leftStickyColumnCount={leftStickyColumnCount}
                rowHeight={heightInPixelsPerElement}
                type={type}
            />
        );
    } else {
        gridJSX = (
            <div className={cardGridClasses.EmptyWrapperWithBorder}>
                <Empty showIcon={false} messageComponent={emptyMessage} circularShapes />
            </div>
        );
    }

    return gridJSX;
};

export default SearchDataGrid;
