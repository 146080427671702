import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ApplicationPageTemplate from 'fintech/components/applications/layout/pre-login/PageTemplate';
import investorImage from 'fintech/static/images/yatirimcilar-centered.jpg';
import InvestorNRepForm from 'fintech/components/applications/form/investor/InvestorNRepForm';
import InvestorGeneralInfoForm from 'fintech/components/applications/form/investor/GeneralInfoForm';
import ApplicationResult from 'fintech/components/applications/form/common/ApplicationResult';
import { InvestorApplicationActions } from 'fintech/store/actions/ActionTypes';

const InvestorApplicationPage = () => {
    const { t } = useTranslation('application');
    const { token } = useParams();
    const [tab, setTab] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: InvestorApplicationActions.RESET_INVESTOR_APPLICATION_FORM });
    }, []);

    const switchTab = (next) => setTab((prev) => (next ? prev + 1 : prev - 1));

    const getSelectedTab = () => {
        switch (tab) {
            case 0:
                return <InvestorGeneralInfoForm inviteToken={token} switchTabHandler={switchTab} />;
            case 1:
                return <InvestorNRepForm isInvite={token} switchTabHandler={switchTab} />;
            case 2: {
                dispatch({ type: InvestorApplicationActions.RESET_INVESTOR_APPLICATION_FORM });
                return <ApplicationResult title={t('investor.resultTitle')} info={t('common.resultInfo')} />;
            }
        }
    };

    return (
        <ApplicationPageTemplate
            image={investorImage}
            imageTitle={t('investor.title')}
            options={[t('common.tabs.generalInfo'), t('investor.tabs.investorAndRepInfo'), t('common.tabs.result')]}
            currentIndex={tab}
        >
            {getSelectedTab()}
        </ApplicationPageTemplate>
    );
};

export default InvestorApplicationPage;
