import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_SECONDARY_BASE,
    COLOR_SECONDARY_SHADE_01,
    COLOR_THEME_FILL_05,
    COLOR_THEME_FILL_06,
} from 'fintech/components/style/common';

export const useStyles = makeStyles({
    EventDetailHeaderGrid: {
        padding: '20px 16px 0px 16px',
    },
    EventNameStyle: {
        fontWeight: 'bold',
        fontSize: 24,
        color: COLOR_SECONDARY_BASE,
        lineHeight: 1.2,
        marginRight: '4px',
    },
    EventDateStyle: {
        fontSize: 14,
        color: COLOR_SECONDARY_SHADE_01,
        marginBottom: '8px',
    },
    EventApplicationInfo: {
        borderRadius: 4,
        fontSize: 12,
        background: COLOR_THEME_FILL_06,
        color: COLOR_THEME_FILL_05,
        width: 'fit-content',
        padding: '3px 8px 3px 8px',
    },
});
