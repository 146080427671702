import React, { useState } from 'react';
import _ from 'lodash';
import FileUpload from 'fintech/components/util/FileUpload';
import { MEDIA_MIME_TYPES, DOCUMENT_MIME_TYPES } from 'fintech/components/util/FileConstants';

export default function MediaUpdateActionModal({ initData, changeMediaCallback, areDocuments = false, fileLimit = 5 }) {
    const [files, setFiles] = useState(_.clone(initData, true) || []);
    const uploadCallback = (uploadFiles) => {
        setFiles(uploadFiles);
        changeMediaCallback(uploadFiles);
    };

    return (
        <div>
            <FileUpload
                uploadCallback={uploadCallback}
                acceptMimeTypes={areDocuments ? DOCUMENT_MIME_TYPES : MEDIA_MIME_TYPES}
                videoLimit={1}
                imageLimit={4}
                documentLimit={areDocuments ? 4 : 0}
                fileLimit={fileLimit}
                initFiles={files}
                areDocuments={areDocuments}
            />
        </div>
    );
}
