import * as React from 'react';

function SvgIconsSingleMultipleYatirimci(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <path
                                    fill="#D9DCE0"
                                    d="M9.375 12H6.75L6 10.125H4.875l-.75-1.875H3l-.75-1.875h6c.621 0 1.125.504 1.125 1.125V12z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#D9DCE0"
                                    d="M14.625 13.5v-6c0-.621.504-1.125 1.125-1.125h7.875V4.5H.375v1.875H8.25c.621 0 1.125.504 1.125 1.125v6h5.25zM9.75 23.875L14.25 23.875 14.25 13.875 9.75 13.875z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M11.625 23.625L12.375 23.625 12.375 15.375 11.625 15.375zM11.625 6.75L12.375 6.75 12.375 6 11.625 6z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#D9DCE0"
                                    d="M14.625 12h2.625l.75-1.875h1.125l.75-1.875H21l.75-1.875h-6c-.621 0-1.125.504-1.125 1.125V12z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M21.254 8.625L18 8.625 18 7.875 20.746 7.875 21.402 6.236 22.098 6.514z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M19.379 10.5L16.5 10.5 16.5 9.75 18.871 9.75 19.527 8.111 20.223 8.389z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M17.504 12.375L15.75 12.375 15.75 11.625 16.996 11.625 17.652 9.986 18.348 10.264z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#FFF"
                                    d="M13.5 1.875c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5.672-1.5 1.5-1.5 1.5.672 1.5 1.5"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M12 .75c-.62 0-1.125.505-1.125 1.125S11.38 3 12 3s1.125-.505 1.125-1.125S12.62.75 12 .75m0 3c-1.034 0-1.875-.841-1.875-1.875S10.966 0 12 0s1.875.841 1.875 1.875S13.034 3.75 12 3.75M6 8.625L2.746 8.625 1.902 6.514 2.598 6.236 3.254 7.875 6 7.875z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M7.5 10.5L4.621 10.5 3.777 8.389 4.473 8.111 5.129 9.75 7.5 9.75z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M8.25 12.375L6.496 12.375 5.652 10.264 6.348 9.986 7.004 11.625 8.25 11.625z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M15 13.875H9V7.5c0-.414-.336-.75-.75-.75H.375V6H8.25c.827 0 1.5.673 1.5 1.5v5.625h4.5V7.5c0-.827.673-1.5 1.5-1.5h7.875v.75H15.75c-.414 0-.75.336-.75.75v6.375zM7 24.05L17 24.05 17 23.3 7 23.3z"
                                    transform="translate(-308.000000, -327.000000) translate(280.000000, 223.000000) translate(0.000000, 92.000000) translate(20.000000, 4.000000) translate(8.000000, 8.000000)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleMultipleYatirimci;
