import * as React from 'react';

function SvgCloseButton(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <defs>
                <filter id="close-button_svg__a">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <circle cx={12} cy={12} r={12} fill="#5B7ADB" />
                <g filter="url(#close-button_svg__a)">
                    <path
                        fill="#3F5162"
                        fillRule="nonzero"
                        d="M7.14 18.454l4.86-4.86 4.86 4.86 1.594-1.594-4.86-4.86 4.86-4.86-1.594-1.594-4.86 4.86-4.86-4.86L5.546 7.14l4.86 4.86-4.86 4.86z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgCloseButton;
