import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import { LocalOffer } from '@material-ui/icons';
import useStyles from './index.style';
import VentureLogoGrid from './Logo';
import VentureInfoGridItem from './VentureInfo';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import { StyledTag } from 'fintech/components/ui/button/common';
import { IntroCardContent, IntroCardTitle } from 'fintech/components/profile/common';
import SplitButton from 'fintech/components/ui/button/SplitButton';
import { useHistory } from 'react-router';
import { ATTEMPT } from 'fintech/routes';
import { styledTagInSimilarVenturesStyle } from 'fintech/components/style/common';
import withPreventPropagation from 'fintech/hoc/withPreventPropagation';

const UserVenture = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const {
        t,
        logo,
        name,
        position,
        verticals,
        stage,
        date,
        employees,
        uuid,
        onTagExpand,
        type,
    } = props;

    // Verticals
    let vertsJSX = null;
    if (verticals && _.keys(verticals).length > 0) {
        const verts = _.keys(verticals)?.map((vert, idx) => {
            const groupStyle = idx > 0 ? { marginTop: '11px' } : null;
            return (
                <Grid item key={idx}>
                    <SplitButton groupStyle={groupStyle} option={vert} subOptions={verticals[vert]} />
                </Grid>
            );
        });
        vertsJSX = (
            <>
                {withPreventPropagation(
                    <Box display="flex" flexDirection="row" alignItems="flex-start">
                        <TagGroup
                            isGridItem
                            limit={1}
                            items={verts}
                            preventDefault
                            onClick={() => onTagExpand({ title: t('profile.venture.verticals'), tags: verts })}
                        />
                    </Box>
                )}
            </>
        );
    } else {
        vertsJSX = <>-</>;
    }

    const goToVentureProfile = () => {
        history.push(`${ATTEMPT}/${uuid}`);
    };

    return (
        <Grid className={classes.UserVentureContainer} container direction="row" justify="center" alignItems="stretch">
            <Grid className={classes.UserVentureWrapper} item xs={12}>
                <Grid
                    className={classes.UserVenture}
                    container
                    direction={'row'}
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} md={12} lg={3} onClick={goToVentureProfile} style={{ cursor: 'pointer' }}>
                        <VentureLogoGrid
                            logo={logo}
                            name={name}
                            type={position}
                            ventureType={type}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} lg={4}>
                        <VentureInfoGridItem
                            title={<IntroCardTitle>{t('profile.venture.verticals')}</IntroCardTitle>}
                            content={vertsJSX}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={2}>
                        <VentureInfoGridItem
                            title={<IntroCardTitle>{t('profile.user.stage')}</IntroCardTitle>}
                            content={
                                stage && stage !== '' ? (
                                    <StyledTag
                                        disableElevation
                                        disableRipple
                                        disableTouchRipple
                                        startIcon={<LocalOffer fontSize="small" />}
                                        style={styledTagInSimilarVenturesStyle}
                                    >
                                        {stage}
                                    </StyledTag>
                                ) : (
                                    '-'
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={6} md={2} lg={1}>
                        <VentureInfoGridItem
                            title={<IntroCardTitle>{t('profile.user.dateFounded')}</IntroCardTitle>}
                            content={date && date !== '' ? <IntroCardContent>{date}</IntroCardContent> : '-'}
                        />
                    </Grid>
                    <Grid item xs={6} md={2} lg={2}>
                        <VentureInfoGridItem
                            title={<IntroCardTitle>{t('profile.user.employees')}</IntroCardTitle>}
                            content={
                                employees && employees !== '' ? <IntroCardContent>{employees}</IntroCardContent> : '-'
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withTranslation('profile')(UserVenture);
