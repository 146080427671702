import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NewInputTextArea from 'fintech/components/ui/form/textinput/NewInputTextArea';
import { InvestorApplicationActions } from 'fintech/store/actions/ActionTypes';
import useGeneralInfoStyles from 'fintech/components/applications/form/investor/GeneralInfoForm/index.style';
import AlertInfo from '../../common/AlertInfo';

const IndividualInvestorForm = (props) => {
    const generalInfoClasses = useGeneralInfoStyles();
    const { t } = useTranslation('application');
    const dispatch = useDispatch();
    const { disabled, validationError, setPathIsBlurred } = props;
    const { name, surname, idNumber } = useSelector((state) => state.investorApplication);

    const nameChangedHandler = (val) => {
        const newState = {
            value: val,
            validated: true,
        };
        dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_NAME, data: newState });
    };

    const surnameChangedHandler = (val) => {
        const newState = {
            value: val,
            validated: true,
        };
        dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_SURNAME, data: newState });
    };

    const idNumberChangeHandler = (val) => {
        const newState = {
            value: val,
            validated: true,
        };
        dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_IDENTITY, data: newState });
    };

    return (
        <Grid item xs={12} container direction="row" justify="center">
            <Grid item xs={12}>
                <AlertInfo title={t('investor.form.helper.generalInfoHelperIndividual')} />
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={generalInfoClasses.divInputLeft}>
                    <NewInputTextArea
                        containerClass={generalInfoClasses.inputTextAreaWrapper}
                        disabled={disabled}
                        type="text"
                        label={t('formLabel.name')}
                        maxLength={100}
                        hideCountIndicator
                        validationError={validationError}
                        validationPath="name"
                        value={name?.value}
                        onChange={nameChangedHandler}
                        onBlur={() => setPathIsBlurred('name')}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={generalInfoClasses.divInputRight}>
                    <NewInputTextArea
                        containerClass={generalInfoClasses.inputTextAreaWrapper}
                        disabled={disabled}
                        type="text"
                        label={t('formLabel.surname')}
                        maxLength={100}
                        hideCountIndicator
                        validationError={validationError}
                        validationPath="surname"
                        value={surname?.value}
                        onChange={surnameChangedHandler}
                        onBlur={() => setPathIsBlurred('surname')}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <NewInputTextArea
                        containerClass={generalInfoClasses.inputTextAreaWrapper}
                        disabled={disabled}
                        type="identity"
                        label={t('formLabel.idNumber')}
                        maxLength={11}
                        hideCountIndicator
                        validationError={validationError}
                        validationPath="idNumber"
                        value={idNumber?.value}
                        onChange={idNumberChangeHandler}
                        onBlur={() => setPathIsBlurred('idNumber')}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default IndividualInvestorForm;
