import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import Announcement from 'fintech/components/dashboard/Announcement';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import Empty from 'fintech/components/util/Empty';
import ErrorBoundary from 'fintech/components/util/ErrorBoundary';
import { fetchAnnouncements } from 'fintech/store/actions/announcement';

const AnnouncementGrid = (props) => {
    const { t } = props;
    const { keycloak } = useKeycloak();
    const { announcements: fetchedAnn, isLoading, isError } = useSelector((state) => state.announcement);
    const [announcements, setAnnouncements] = useState(fetchedAnn);
    const dispatch = useDispatch();

    const tabs = [{ label: t('announcement.latest') }];

    // t & tReady are not accepted on a DOM element
    const others = omit(props, ['t', 'tReady']);

    const hasAnnouncements = announcements && announcements.length > 0;

    let announcementJSX = null;
    let emptyProps = {};

    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('announcement.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('announcement.errorMessage');
    } else if (hasAnnouncements) {
        announcementJSX = announcements?.map((ann) => <Announcement key={ann.id} {...ann} />);
    } else {
        emptyProps.message = t('announcement.emptyMessage');
    }

    useEffect(() => {
        setAnnouncements(fetchedAnn);
    }, [fetchedAnn]);

    useEffect(() => {
        dispatch(fetchAnnouncements(keycloak));
    }, []);

    return (
        <>
            <NavTabs value={0} tabs={tabs} />
            <Grid container item direction="column" justify="flex-start" alignItems="center" {...others}>
                <ErrorBoundary message={t('announcement.errorMessage')}>
                    {hasAnnouncements ? announcementJSX : <Empty {...emptyProps} />}
                </ErrorBoundary>
            </Grid>
        </>
    );
};

AnnouncementGrid.propTypes = {
    announcements: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            video: PropTypes.string,
            images: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    url: PropTypes.string,
                })
            ),
            attachments: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    url: PropTypes.string,
                })
            ),
        })
    ),
};

AnnouncementGrid.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('announcement')(AnnouncementGrid);
