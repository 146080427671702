import { makeStyles } from '@material-ui/core';
import pattern from 'fintech/static/images/pattern.svg';
import { COLOR_THEME_FILL_01, COLOR_SECONDARY_BASE, COLOR_THEME_FILL_09 } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    educationSection: {
        background: `${COLOR_THEME_FILL_01}`,
    },
    imagesContainer: {
        textAlign: 'center',

        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
        },
    },
    educationProgramsIcon: {
        width: '100%',
        height: '338px',
        [theme.breakpoints.down('md')]: {
            height: '215px',
        },
    },
    educationContainer: {
        // margin: '-65px 0 0',
        padding: '99px 121px 83px 120px',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('md')]: {
            padding: '91px 22px 65px',
            margin: '0',
        },
    },
    title: {
        fontSize: '36px',
        fontWeight: '500',
        color: COLOR_SECONDARY_BASE,
        marginTop: 52,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: 25,
        },
    },
    definition: {
        fontSize: 16,
        color: COLOR_SECONDARY_BASE,
        maxWidth: 820,
        margin: '32px auto 54px auto',
        textAlign: 'center',
        fontWeight: '500',
        lineHeight: '28px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginBottom: 0,
        },
    },
    educationGrid: {
        flexGrow: 1,
        maxWidth: '1300px',
        margin: '-4px auto',
    },
    educationGridItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    educationItemIcon: {
        width: 32,
        height: 32,
        flexShrink: 0,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        background: COLOR_THEME_FILL_09,
        border: '4px solid rgba(241,245,247, 1)',
        borderRadius: '50%',
    },
}));

export default useStyles;
