import { makeStyles } from '@material-ui/core';
import { poppins600_14px } from 'fintech/components/style/common';
import { COLOR_SECONDARY_SHADE_01 } from 'fintech/components/style/common';

const useStyles = makeStyles(() => ({
    table: {
        width: '100%',
        '& > tr > td:first-child': {
            ...poppins600_14px,
            fontWeight: 'normal',
            color: COLOR_SECONDARY_SHADE_01,
        },
        '& > tr > td:last-child': {
            ...poppins600_14px,
            textAlign: 'end',
        },
    },
}));

export default useStyles;
