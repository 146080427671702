import { omitBy, isUndefined, isNull, isNil, isEmpty } from 'lodash';
import { getPublicFileUrl, getVideoUrl } from 'fintech/api/fileService';
import { getYearFromDateString } from './DateUtils';
import { formatMonetaryValue } from 'fintech/utils/StringUtils';
import { GRID_HEADER_STATE } from 'fintech/components/profile/startup/SimilarVentures/SimilarVenturesUtils';
import { VIDEO_MIMES_START } from 'fintech/components/util/FileConstants';
import { getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js';
import { ACTIVE, PASSIVE, TO_BE_DEACTIVATED } from 'fintech/components/util/StatusConstants';
import { ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE } from 'fintech/components/util/RoleConstants';
import { STATES } from 'fintech/store/actions/ActionTypes';

// Assumes an investor only has one logo.
export const getLogo = (file) =>
    file
        ? {
            title: file.fileName,
            url: getPublicFileUrl(file.uuid),
        }
        : null;

export const parseInfos = (infoArr) => {
    const infos = {};
    infoArr.forEach((info) => {
        infos[info.field] = info;
    });

    return infos;
};

export const parseSocialMedia = (userProfileInfoList) => {
    const socialMedias = userProfileInfoList.filter((cur) => cur.group === 'SOCIAL_MEDIA');
    return socialMedias.some((cur) => cur.public);
};

/**
 * Parse subverticals array to a nested object with verticals as keys &
 * subverticals of that vertical as the value (Array).
 * @param {*} subvertArr Subverticals array
 * @param {*} siblingData Indicates whether the vertical data is at the same level with the subvertical data in the subvertArr.
 * Example: if true { vertical: A, subvertical: B}, if not { name: B, vertical: { name: A } }
 * @returns A nested object with verticals as keys &
 * subverticals of that vertical as the value (Array).
 */
export const parseSubVerticals = (subvertArr, siblingData = false) => {
    const verticals = {};

    !siblingData
        ? subvertArr?.forEach((sub) => {
            const name = sub.vertical?.name;
            verticals[name] = verticals[name] ? [...verticals[name], sub.name] : [sub.name];
        })
        : subvertArr?.forEach((sub) => {
            const name = sub.vertical;
            verticals[name] = verticals[name] ? [...verticals[name], sub.subVertical] : [sub.subVertical];
        });

    return verticals;
};

export const parseTags = (tagArr) => tagArr?.map((tag) => tag.name);

export const parseMedia = async (mediaArr) => {
    const result = [];
    if (mediaArr != null && mediaArr.length > 0) {
        for (const med of mediaArr) {
            if (med.file && med.file.mimeType) {
                if (med.file.mimeType.startsWith(VIDEO_MIMES_START)) {
                    med.url = getVideoUrl(med.file.uuid);
                } else {
                    med.url = getPublicFileUrl(med.file.uuid);
                }
                result.push(med);
            }
        }
    }
    return result;
};

export const parseVentureData = (venture) => {
    const res = {};
    res.uuid = venture.uuid;
    res.name = venture.name;
    res.position = venture.title;
    res.logo = getLogo(venture.logoFile);
    res.verticals = parseSubVerticals(venture.subverticals);
    res.stage = venture.stage?.name;
    res.date = getYearFromDateString(venture.dateFounded);
    res.employees = venture.employeeCount;
    res.type = venture.ventureType.description;
    return res;
};

export const parseStartupInvestmentData = (data) => {
    const res = {};
    res.editable = data.editable;
    res.investments = data.startupInvestments?.map((inv) => ({
        id: inv.id,

        // Id of the StartupInvestor entity
        stupInvestorId: inv.investor?.id,
        investorId: inv.investor?.investorId,
        logo: getLogo(inv.investor?.logoFile),
        companyTitle: inv.investor
            ? inv.investor.investorId
                ? inv.investor.investorName
                : inv.investor.freeTextInvestorName
            : null,
        companyIsPublic: inv.investorVisible,
        date: inv.investmentDate,
        dateIsPublic: inv.investmentDateVisible,
        investment: inv.investmentAmount,
        investmentIsPublic: inv.investmentAmountVisible,
        hideAll: !inv.investmentVisible,
    }));
    return res;
};

export const parseEstablishmentInvestmentData = (data) => {
    const res = {};
    res.editable = data.editable;
    res.investments = data.startupInvestments?.map((inv) => ({
        id: inv.id,
        investorStartupId: inv.startup?.id,
        startupId: inv.startup?.startupId,
        logo: getLogo(inv.startup?.logoFile),
        companyTitle: inv.startup ? (inv.startup.startupId ? inv.startup.name : inv.startup.freeTextStartupName) : null,
        companyIsPublic: inv.startupVisible,
        date: inv.investmentDate,
        dateIsPublic: inv.investmentDateVisible,
        investment: inv.investmentAmount,
        investmentIsPublic: inv.investmentAmountVisible,
        hideAll: !inv.investmentVisible,
    }));
    return res;
};

export const parseStartupInvestorOptionsData = (data) =>
    data?.map((inv) => ({
        id: inv.id,
        name: inv.name,
        logo: inv.logoFile && getLogo(inv.logoFile),
    }));

export const parsePhoneNumberData = (phoneNumber) => {
    if (!phoneNumber || !phoneNumber.number || !phoneNumber.country || !phoneNumber.country.alphaTwo) {
        return;
    }
    return parsePhoneNumber(`+${getCountryCallingCode(phoneNumber.country.alphaTwo)}${phoneNumber.number}`);
};

export const parseSortQueryFromHeaders = (headers) => {
    let query = '';
    const selectedHeader = headers.find((e) => e.value.status !== GRID_HEADER_STATE.NONE);

    if (typeof selectedHeader !== 'undefined') {
        query += `${selectedHeader.value.searchIndex},${selectedHeader.value.status}`;
    } else {
        query += 'name,ASC';
    }

    return query === '' ? '' : `&sort=${query}`;
};

export const generateGridRowFromStartUpData = (data, isFavourite) => {
    const {
        name,
        address,
        stage,
        dateFounded,
        totalInvestment,
        userFavorite,
        technologies,
        subverticals,
        logoFile,
        uuid,
        startupFinancial,
        startupInfos,
        ventureType,
    } = data;
    const defaultText = '-';

    const row = [
        {
            startupUuid: uuid,
            ventureTypeID: ventureType.id,
        },
        // # Girişim
        {
            type: isFavourite ? 'venture' : 'ventureFavorite',
            value: {
                label: name,
                isFavourite: userFavorite,
                startupUuid: uuid,
                ventureTypeID: ventureType.id,
                logoFile,
                ventureType: ventureType?.description,
            },
        },
        // Lokasyon
        {
            type: 'text',
            value: {
                label: address[0] ? address[0]['city']['name'] : defaultText,
            },
        },
        // Fintek Dikeyleri
        {
            type: 'verticalGroup',
            value: parseSubVerticals(subverticals),
            title: 'entrepreneur.expandModal.fintechVerticals',
        },
        // Aşama
        {
            type: 'tagGroup',
            value:
                stage && stage.name
                    ? [
                        {
                            id: 0,
                            name: stage.name,
                        },
                    ]
                    : null,
        },
        // Kuruluş Yılı
        {
            type: 'text',
            value: {
                label: dateFounded,
            },
        },
        // Çalışan Sayısı
        {
            type: 'text',
            value: {
                label: startupInfos.find((e) => e.field === 'employees')
                    ? startupInfos.find((e) => e.field === 'employees').value
                    : defaultText,
            },
        },
        // Yatırım Tutarı
        {
            type: 'text',
            value: {
                label:
                    totalInvestment && totalInvestment.value
                        ? formatMonetaryValue(totalInvestment.value, totalInvestment.currencyType)
                        : defaultText,
            },
        },
        // Piyasa Değeri
        {
            type: 'text',
            value: {
                label:
                    startupFinancial && startupFinancial.marketValue
                        ? formatMonetaryValue(
                            startupFinancial.marketValue.value,
                            typeof startupFinancial?.marketValue?.currencyType === 'undefined'
                                ? 'TRY'
                                : startupFinancial.marketValue.currencyType
                        )
                        : defaultText,
            },
        },
        // Gelir modeli (X)  incomeModels
        {
            type: 'tagGroup',
            value: startupFinancial && startupFinancial.incomeModels ? startupFinancial.incomeModels : [],
            title: 'entrepreneur.expandModal.incomeModels',
        },
        // Teknoloji
        {
            type: 'tagGroup',
            value: technologies,
            title: 'entrepreneur.expandModal.technologies',
        },
    ];

    if (isFavourite) {
        row.splice(1, 0, {
            type: 'favorite',
            value: {
                label: name,
                isFavourite: userFavorite,
                startupUuid: uuid,
                ventureTypeID: ventureType.id,
                logoFile,
            },
        });
    }

    return row;
};

// for consultant faq
export const parseQuestions = (content, previousRows) => {
    const parsedQuestions = content.map((cur) => {
        return {
            question: cur.question?.question,
            answer: cur.question.answer?.answer,
            expertise: cur.expertise?.name,
            educationLink: cur.educationLink,
        };
    });
    if (previousRows) {
        return previousRows.concat(parsedQuestions);
    }
    return parsedQuestions;
};

export const sanitizeFalsyValuesFromObject = (object) => {
    return omitBy(object, (v) => isUndefined(v) || isNull(v) || v === '');
};

// User search status changeability.
export const canChangeUserStatus = (status, roles) => {
    if (status === ACTIVE) {
        return true;
    }

    if (status === TO_BE_DEACTIVATED) {
        return false;
    }

    /**
     * Roles [ROLE_ADMIN], [ROLE_COUNSELOR], [ROLE_EVALUATION_COMMITTEE] are
     * assumed to be mutually exclusive with roles that are related to startup,
     * investor etc.
     *
     * Therefore this method returns [true] if the user roles contain one of the
     * roles mentioned above.
     */
    if (status === PASSIVE && !isEmpty(roles)) {
        for (let i = 0; i < roles.length; i = i + 1) {
            if ([ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE].includes(roles[i])) {
                return true;
            }
        }
    }

    return false;
};

export const getInvestorOrStartupFormValueFromState = (state, type) => {
    if (type === STATES.ESTABLISHMENT) {
        return state[STATES.ESTABLISHMENT].investmentPreferences.form.startupForm;
    } else {
        return state.startup.form.investorForm;
    }
};

export const parseRepresentativeInfo = (data) => {
    const repData = {};
    repData.name = {
        value: data?.name,
        validated: data?.name ? true : false,
    };

    repData.lastName = {
        value: data?.surname,
        validated: data?.surname ? true : false,
    };

    // TODO: Delayed until Phase 2
    /* 
    repData.nationality = {
        value: {
            id: data?.country?.id,
            name: data?.country?.name,
        },
        validated: data?.country?.id ? true : false,
    }; 
    */

    repData.identity = {
        value: data?.identityNumber,
        validated: data?.identityNumber ? true : false,
    };

    repData.gender = {
        value: {
            id: data?.gender,
        },
        validated: data?.gender ? true : false,
    };

    repData.birthDate = {
        value: data?.birthDate,
        validated: data?.birthDate ? true : false,
    };

    repData.email = {
        value: data?.mailAddress,
        validated: data?.mailAddress ? true : false,
    };

    repData.phoneNumber = {
        value:
            data?.phoneNumber && data?.phoneNumber?.country
                ? {
                    countryCallingCode: data.phoneNumber.country.callingCode,
                    nationalNumber: data.phoneNumber.number,
                    number: `+${data.phoneNumber.country.callingCode}${data.phoneNumber.number}`,
                    country: data.phoneNumber.country.alphaTwo,
                }
                : {},
        validated: data?.phoneNumber?.number && data?.phoneNumber?.country ? true : false,
    };

    repData.workExperienceInYears = {
        value: !isNil(data?.expertiseYear) && `${data?.expertiseYear}`,
        validated: !isNil(data?.expertiseYear) ? true : false,
    };

    repData.educationInfo = {
        value: {
            id: data?.educationInfo?.id,
        },
        validated: data?.educationInfo?.id ? true : false,
    };

    return repData;
};
