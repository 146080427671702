import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Welcome from './sections/Welcome';
import AboutProgram from './sections/AboutProgram';
import SupportsOnePage from './sections/SupportsOnePage';
import MentorsOnePage from './sections/MentorsOnePage';
import Timesheet from './sections/Timesheet';
import EducationAndSeminars from './sections/EducationAndSeminars';
import Continua from './sections/Continua';
import Faq from './sections/Faq';
import Footer from './sections/Footer';
import {
    SUPPORT_PROGRAM_ONE_PAGE_LIST,
    SUPPORT_PROGRAM_ONE_PAGE_LIST_EN,
} from 'fintech/pages/pre-login/SupportProgramOnePageJsonData';
import { NOT_FOUND } from 'fintech/routes';
import { LANGUAGE_CODES } from 'fintech/constants';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';

const SupportProgramOnePage = () => {
    const history = useHistory();
    const { id } = useParams();
    const [supportProgramOnePage, setSupportProgramOnePage] = useState(undefined);
    const [programControlCompleted, setProgramControlCompleted] = useState(false);

    let jsonData = [];

    if (getSystemLanguage() === LANGUAGE_CODES.TR) {
        jsonData = SUPPORT_PROGRAM_ONE_PAGE_LIST;
    } else {
        jsonData = SUPPORT_PROGRAM_ONE_PAGE_LIST_EN;
    }

    useEffect(() => {
        console.log(jsonData);
        console.log(id);
        const idKeyToBeUsed = '' + process.env.DB_ENV + 'EnvironmentId';
        const foundSupportProgramOnePage = jsonData.find(
            (supportProgramOnePage) => supportProgramOnePage[idKeyToBeUsed] === id
        );
        if (foundSupportProgramOnePage) {
            setSupportProgramOnePage(foundSupportProgramOnePage);
            setProgramControlCompleted(true);
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        } else {
            history.replace(NOT_FOUND);
        }
    }, [id]);

    if (!programControlCompleted) {
        return <></>;
    }

    return (
        <>
            <Welcome supportProgramOnePage={supportProgramOnePage} supportProgramId={id} />
            <AboutProgram supportProgramOnePage={supportProgramOnePage} />
            <SupportsOnePage supportProgramOnePage={supportProgramOnePage} />
            <Timesheet supportProgramOnePage={supportProgramOnePage} supportProgramId={id} />
            <Continua supportProgramOnePage={supportProgramOnePage} />
            <EducationAndSeminars supportProgramOnePage={supportProgramOnePage} />
            <MentorsOnePage supportProgramOnePage={supportProgramOnePage} supportProgramId={id} />
            <Faq supportProgramOnePage={supportProgramOnePage} supportProgramId={id} />
            <Footer />
        </>
    );
};

export default SupportProgramOnePage;
