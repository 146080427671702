import * as React from 'react';

function SvgIconsMultipleProfilSirket(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                fill="#D9DCE0"
                                                d="M0 14L18 14 18 2.917 0 2.917z"
                                                transform="translate(-187.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(70.000000, 0.000000) translate(16.000000, 11.000000) translate(3.000000, 5.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M18 8.75L0 8.75 0 2.917 0.9 2.917 0.9 7.875 17.1 7.875 17.1 2.917 18 2.917z"
                                                transform="translate(-187.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(70.000000, 0.000000) translate(16.000000, 11.000000) translate(3.000000, 5.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M3 10.208L4.5 10.208 4.5 8.021 3 8.021zM13.5 10.208L15 10.208 15 8.021 13.5 8.021zM13.5 2.917L12.6 2.917 12.6.875 5.4.875 5.4 2.917 4.5 2.917 4.5 0 13.5 0z"
                                                transform="translate(-187.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(70.000000, 0.000000) translate(16.000000, 11.000000) translate(3.000000, 5.000000)"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleProfilSirket;
