import { makeStyles } from '@material-ui/core/styles';
import { buttonStyle, COLOR_SECONDARY_BASE, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    mentorAvatarContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: 284,
    },
    mentorAvatar: {
        width: 80,
        height: 80,
    },
    mentorName: {
        fontWeight: 600,
        fontSize: 14,
        color: COLOR_SECONDARY_BASE,
        marginBottom: 36,
        marginTop: 8,
    },
    mentorButton: {
        ...fontStyle,
        ...buttonStyle,
        padding: '6px 8px',
    },
});

export default useStyles;
