import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useStyle from './index.style';
import Dropdown from 'fintech/components/ui/dropdown';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import SvgIconsSingleExport from 'fintech/icons/IconsSingleExport';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';
import { Button } from '@material-ui/core';
import IconsSingleMinus from 'fintech/icons/IconsSingleMinus';
import SingleAdd from 'fintech/icons/IconsSingleAdd';
const Index = ({ children, title, dropdown, icon, button, isLoading, isCollapse, onOpen }) => {
    const [isOpen, setOpen] = useState(false);
    const classes = useStyle();

    const handleOpen = (param) => {
        if (param && onOpen) {
            onOpen();
        }
        setOpen(param);
    };

    return (
        <div className={`${classes.card} ${(isOpen || !isCollapse) && 'show'}`}>
            <div className={'header'} onClick={() => handleOpen(!isOpen)}>
                <div>
                    {icon}
                    <p>{title}</p>
                </div>
                <div>
                    {(isOpen || !isCollapse) && dropdown && (
                        <Dropdown buttonText={dropdown.buttonText} items={dropdown.items}></Dropdown>
                    )}
                    {(isOpen || !isCollapse) && button && (
                        <ShrinkingButton
                            shrinkedIcon={<SvgIconsSingleExport white fontSize="small" />}
                            fullIcon={<SvgIconsSingleExport white />}
                            onClick={e => {
                                e.stopPropagation();
                                button.onClick();
                            }}
                            fullText={button.text}
                        ></ShrinkingButton>
                    )}
                    {isCollapse && (
                        <Button className={'collapseButton'} color="primary">
                            {isOpen ? <IconsSingleMinus /> : <SingleAdd />}
                        </Button>
                    )}
                </div>
            </div>
            <div className={'body'}>
                {isLoading ? (
                    <div className={'loadingContainer'}>
                        <LoadingSpinner />{' '}
                    </div>
                ) : (
                    children
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    children: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    dropdown: PropTypes.objectOf({ buttonText: PropTypes.string.isRequired, items: PropTypes.arrayOf().isRequired }),
    icon: PropTypes.element.isRequired,
    button: PropTypes.objectOf({ text: PropTypes.string.isRequired, onClick: PropTypes.func.isRequired }),
};

Index.defaultProps = {};

export default Index;
