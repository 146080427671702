import { withStyles, makeStyles, Typography } from '@material-ui/core';
import { poppins500_12px } from 'fintech/components/style/common';

export const MobileLabel = withStyles({
    root: {
        ...poppins500_12px,
    },
})(Typography);

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0 24px',
        ' & > *': {
            marginBottom: '30px',
        },
    },
    amountSelect: {
        overflow: 'hidden',
        padding: '0 12px 0 0',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '8px !important',
        },
        [theme.breakpoints.up('md')]: {
            '& > span': {
                display: 'none',
            },
        },
    },
    currencyContainer: {
        paddingTop: '20px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '8px',
        },
    },
    currencySelect: {
        width: '100%',
        '& > div:first-child': {
            borderRadius: '0 6px 6px 0',
            minWidth: '65px',
            maxHeight: '56px',
        },
    },
    mobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default useStyles;
