import { makeStyles } from '@material-ui/core';
import pattern from 'fintech/static/images/pattern.svg';
import {
    COLOR_THEME_FILL_01,
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_BASE,
    COLOR_THEME_FILL_10,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    supportSection: {
        background: `${COLOR_THEME_FILL_01} url(${pattern}) repeat-x bottom center`,
    },
    imagesContainer: {
        textAlign: 'center',

        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
        },
    },
    noProgramLeft: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    noProgramMid: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    noProgramRight: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    rocketLeft: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    rocketRight: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    supportProgramsIcon: {
        width: 40,
        height: 40,
    },
    supportContainer: {
        // margin: '-65px 0 0',
        padding: '99px 121px 83px 120px',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('md')]: {
            padding: '91px 22px 65px',
            margin: '0',
        },
    },
    title: {
        fontSize: '36px',
        fontWeight: '500',
        color: COLOR_SECONDARY_BASE,
        marginTop: 8,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: 25,
        },
    },
    definition: {
        fontSize: 16,
        color: COLOR_SECONDARY_BASE,
        maxWidth: 937,
        margin: '9px auto 70px auto',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginBottom: '16px',
        },
    },
    itemsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 65,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            marginTop: 40,
        },
    },
    emptyWrapper: {
        display: 'flex',
        position: 'relative',
        marginTop: 65,
        alignItems: 'center',

        '& span': {
            position: 'relative',
            color: COLOR_THEME_FILL_10,
            textAlign: 'center',
            marginLeft: 20,
        },
    },
    preLoginEmptyDivIcon: {
        '& > svg': {
            fill: COLOR_THEME_FILL_10,
        },
    },
    titleWrapper: {
        display: 'flex',
    },
    divider: {
        width: 3,
        height: 32,
        margin: '1px 13px 0 0',
        background: COLOR_PRIMARY_BASE,

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mentorDefinition: {
        fontSize: 24,
        marginBottom: 0,

        '& span': {
            color: COLOR_PRIMARY_BASE,
        },

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
}));

export default useStyles;
