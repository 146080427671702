import { makeStyles } from '@material-ui/core';
import {
    COLOR_PRIMARY_SHADE_01,
    COLOR_SECONDARY_BASE,
    COLOR_THEME_FILL_03,
    COLOR_THEME_FILL_04,
    COLOR_THEME_FILL_05,
    COLOR_THEME_FILL_06,
} from 'fintech/components/style/common';

const defaultOptions = {
    '& > div > svg': {
        marginRight: 10,
    },
};
const useStyles = makeStyles({
    success: {
        ...defaultOptions,
        backgroundColor: `${COLOR_THEME_FILL_06} !important`,
        color: `${COLOR_THEME_FILL_05} !important`,
    },
    warning: {
        ...defaultOptions,
        backgroundColor: '#fddcaa !important',
        color: '#62543f !important',
    },
    error: {
        ...defaultOptions,
        backgroundColor: `${COLOR_THEME_FILL_04} !important`,
        color: `${COLOR_THEME_FILL_03} !important`,
    },
    info: {
        ...defaultOptions,
        backgroundColor: `${COLOR_PRIMARY_SHADE_01} !important`,
        color: `${COLOR_SECONDARY_BASE} !important`,
    },
});

export default useStyles;
