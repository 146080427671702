import React, { useEffect, useState } from 'react';
import { DialogContent, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import { fetchInvestorUsersComboData } from 'fintech/store/actions/investorSearch';
import { clone } from 'lodash';
import { INVESTOR_GRID_REPRESENTATIVE_UPDATE } from 'fintech/components/util/ActionModalConstants';
import StagesUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/StagesUpdate';
import { fontStyle } from 'fintech/components/style/common';
import { IconsSingleTooltip } from 'fintech/icons';
import useStyles from './index.style';

const RepresentativeActionModal = (props) => {
    const classes = useStyles();
    const { uuid, changeCallback } = props;
    const { t } = useTranslation('investorSearch');
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();

    const repDataChangeHandler = (investorTeam, valid) =>
        changeCallback({
            type: INVESTOR_GRID_REPRESENTATIVE_UPDATE,
            data: {
                repData: filterObjectList(investorTeam, 'selected', [true]),
                repValid: valid,
            },
        });

    const { data: fetchedTeamData, isLoading: teamLoading, isError: teamError } = useSelector(
        (state) => state.investorSearch.form.teamData
    );
    const [repData, setRepData] = useState([]);

    useEffect(() => {
        if (uuid && !fetchedTeamData.teamData) {
            dispatch(fetchInvestorUsersComboData(keycloak, uuid));
        }
    }, [uuid]);

    useEffect(() => {
        setRepData(clone(fetchedTeamData, true));
    }, [fetchedTeamData]);

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item xs={12}>
                <DialogContent className={classes.RepInfoDialog}>
                    <Typography style={{ ...fontStyle }}>
                        <IconsSingleTooltip /> {t('form.changeRepresentativeInfo')}
                    </Typography>
                </DialogContent>
            </Grid>
            <Grid item xs={12}>
                <DialogContent>
                    <StagesUpdate
                        label={t('form.representative')}
                        stages={repData}
                        isLoading={teamLoading}
                        isError={teamError}
                        changeCallback={(data, valid) => repDataChangeHandler(data, valid)}
                    />
                </DialogContent>
            </Grid>
        </Grid>
    );
};

export default RepresentativeActionModal;
