const MOBILE_HEADER_HEIGHT = 85;
const DESKTOP_PAGE_TITLE_HEIGHT = 79;

export const scrollIntoApplicationDetails = (condition, isMobile) => {
    if (!condition) return;

    const applicationDetailsDOMElement = document.getElementById('applicationDetails');
    const elementDistanceRelativeToTheViewport = applicationDetailsDOMElement.getBoundingClientRect().top;
    const distanceFromTopOfThePage = elementDistanceRelativeToTheViewport + window.scrollY;

    window.scrollTo({
        top: isMobile
            ? distanceFromTopOfThePage - MOBILE_HEADER_HEIGHT
            : distanceFromTopOfThePage - DESKTOP_PAGE_TITLE_HEIGHT,
        left: 0,
        behavior: 'smooth',
    });
};

export const scrollIntoApplicationPicker = () => {
    const applicationPickerDOMElement = document.getElementById('applicationPicker');
    const elementDistanceRelativeToTheViewport = applicationPickerDOMElement.getBoundingClientRect().top;
    const distanceFromTopOfThePage = elementDistanceRelativeToTheViewport + window.scrollY;

    window.scrollTo({
        top: distanceFromTopOfThePage - MOBILE_HEADER_HEIGHT,
        left: 0,
        behavior: 'smooth',
    });
};

export const resetInfiniteScrollRelatedStates = (setHasMore, setPickerItems, setPageNumber) => {
    setHasMore(false);
    setPickerItems([]);
    setPageNumber(0);
};
