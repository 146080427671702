import { getRequest } from 'fintech/api/api';
import { AttempActions } from './ActionTypes';
import { APIS } from 'fintech/constants';

// Actions for Intro card & general information
const setAttemps = (attemps) => ({
    type: AttempActions.SET_ATTEMP_LIST,
    attemps: attemps,
});

const setAttempsLoading = () => ({ type: AttempActions.SET_ATTEMP_LIST_LOADING });

const setAttempsError = () => ({ type: AttempActions.SET_ATTEMP_LIST_LOAD_ERROR });

export const fetchAttempListData = (keycloak) => {
    return (dispatch) => {
        dispatch(setAttempsLoading());
        getRequest(`${APIS.USER.getAttempsURL()}`, keycloak)
            .then((data) => {
                dispatch(setAttemps(data.data));
            })
            .catch(() => {
                dispatch(setAttempsError());
            });
    };
};
