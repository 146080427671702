import { makeStyles } from '@material-ui/core';

import {
    COLOR_THEME_FILL_01,
    COLOR_PRIMARY_BASE,
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    COLOR_THEME_FILL_04,
    fontStyle,
    buttonStyle,
} from 'fintech/components/style/common';

export const makeSearchStyle = makeStyles((theme) => ({
    searchBarContainer: {
        position: 'relative',
        width: '100%',
        height: '260px',
        margin: '24px 0 8px 0',
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            height: '252px',
        },
    },
    searchbarPlaceholder: {
        borderRadius: '8px',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        transform: 'scaleX(-1)',
    },
    description: {
        position: 'absolute',
        fontSize: '24px',
        width: '412px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },
    searchArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        backgroundColor: '#fff',
        position: 'absolute',
        width: '98%',
        height: '52px',
        bottom: '18px',
        left: '50%',
        transform: 'translate(-50%, 0)',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            // flexDirection: 'column',
            // bottom: '50px',
        },

        '& .searchInput': {
            display: 'block',
            width: '100%',
        },
    },
    searchButton: {
        ...fontStyle,
        marginLeft: 'auto',
        display: 'block',
        textTransform: 'none',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        borderRadius: '8px',
        height: '28px',
        paddingTop: '0px',
        paddingBottom: '0px',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        },
    },
    searchBoxAdnWrapperDiv: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchBoxFilterAdn: {
        display: 'flex',
        direction: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
        padding: '2px 12px 2px 12px',
        marginLeft: '16px',
        minHeight: '28px',
        borderRadius: '8px',
        backgroundColor: COLOR_PRIMARY_BASE,
    },
    searchBoxFilterTypo: {
        ...fontStyle,
        margin: 'auto',
        color: COLOR_THEME_FILL_01,
    },
}));

export const makeAdvancedFilteringStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '16px 0 14px 0',
    },
    splittedButtonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
        marginBottom: '1rem',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    btn: {
        marginTop: '4px',
        textTransform: 'none',
        borderRadius: '8px',
    },
    removeAllBtn: {
        marginRight: '1rem',
        backgroundColor: COLOR_THEME_FILL_04,
    },
    alignRight: {
        marginLeft: 'auto',
    },
    tableInfoLabeL: {
        ...fontStyle,
        fontSize: '18px',
        marginRight: '8px',
        color: COLOR_SECONDARY_BASE,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
}));

export const renderSearchCategoryStyle = makeStyles((theme) => ({
    secondaryBase: {
        color: COLOR_SECONDARY_BASE,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    dropdown: {
        marginLeft: '0.3rem',
    },
    bold: {
        fontWeight: 600,
    },
}));

const calculatePixelsFromNumberOfRows = (numberOfRows, heightInPixelsPerElement, isLoading) => {
    if (numberOfRows === 0 || isLoading) {
        return '380px';
    } else {
        return `${numberOfRows * heightInPixelsPerElement + 46}px`;
    }
};

export const renderGridStyle = makeStyles({
    container: {
        position: 'relative',
        overflowX: 'auto',
        overflowY: 'hidden',
        height: ({ numberOfRows = 0, heightInPixelsPerElement = 125, isLoading }) =>
            calculatePixelsFromNumberOfRows(numberOfRows, heightInPixelsPerElement, isLoading),
        borderRight: ({ numberOfRows, isLoading, isScrollable }) =>
            !isLoading && numberOfRows > 0 && isScrollable ? `4px solid ${COLOR_THEME_FILL_01}` : null,
        borderLeft: ({ numberOfRows, isLoading }) =>
            !isLoading && numberOfRows > 0 && `4px solid ${COLOR_THEME_FILL_01}`,
    },
    removeSearchResultBtnContainer: {
        textAlign: 'center',
        paddingTop: '1rem',
    },
    removeSearchResultBtn: {
        ...buttonStyle,
    },
});
