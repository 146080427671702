import * as React from 'react';

function SvgSupported(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} {...props}>
            <g fill="none" fillRule="evenodd">
                <circle cx={17} cy={17} r={16.5} fill="#E6FAE6" stroke="#C8EDC1" />
                <path
                    fill="#17480E"
                    fillRule="nonzero"
                    d="M15.275 27c.207 0 .395-.042.56-.127.167-.085.32-.212.458-.382l7.463-10.059a1.3 1.3 0 00.24-.645 1.178 1.178 0 00-.136-.666 1.241 1.241 0 00-.489-.499 1.364 1.364 0 00-.696-.186H18.83l1.206-5.89a.961.961 0 00.041-.294c0-.339-.124-.629-.374-.87A1.335 1.335 0 0018.789 7h-.167a1.4 1.4 0 00-.301.068.955.955 0 00-.26.147.8.8 0 00-.291.235l-.018.02-.013.02a.167.167 0 01-.031.038l-7.463 9.276a1.307 1.307 0 00-.24.646c-.02.235.025.457.136.665.11.21.274.376.489.5.214.123.446.185.696.185l3.846.02-1.227 6.712c-.055.287-.007.558.146.812.152.255.367.44.644.558.083.04.17.065.26.078.09.013.184.02.28.02zm0-1.252l1.496-8.16-5.446-.02 7.4-9.316-1.496 7.417 5.446.02-7.4 10.059z"
                />
            </g>
        </svg>
    );
}

export default SvgSupported;
