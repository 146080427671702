import { getRequest } from 'fintech/api/api';
import { CommonActions, CurrencyActions } from './ActionTypes';
import { APIS } from 'fintech/constants';
const timeoutDur = 500;

// Actions for currencies in the ststem
const setCurrencies = (currencies) => ({
    type: CurrencyActions.SET_CURRENCIES,
    currencies: currencies,
});

export const fetchCurrencies = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: CurrencyActions.SET_CURRENCIES_LOADING });
        getRequest(APIS.CURRENCY.getCurrenciesURL(), keycloak)
            .then((data) => {
                setTimeout(() => {
                    dispatch(setCurrencies(data));
                }, timeoutDur);
            })
            .catch(() => {
                dispatch({ type: CurrencyActions.SET_CURRENCIES_LOAD_ERROR });
            });
    };
};

// CBFOFH-508 Overlay
export const setActiveRequest = (isActive) => ({
    type: CommonActions.SET_ACTIVE_REQUEST,
    data: isActive,
});
