import React from 'react';
import useStyle from './index.style';
import { useTranslation } from 'react-i18next';

const Index = ({ title, description, percentage }) => {
    const classes = useStyle();
    const { t } = useTranslation('reports');
    return (
        <div className={classes.roleBox}>
            <p>{title}</p>
            <p>{description}</p>
            <p>{t('card.percentage', { count: percentage })}</p>
        </div>
    );
};

Index.propTypes = {};
Index.defaultProps = {};

export default Index;
