import React from 'react';
import PropTypes from 'prop-types';
import { CardMedia, Typography } from '@material-ui/core';
import useStyles from './index.style';

const SideImage = (props) => {
    const { image, imageTitle } = props;

    const classes = useStyles();

    return (
        <div className={classes.mediaWrapper}>
            <CardMedia className={classes.media} image={image} />
            <Typography className={classes.mediaText}>{imageTitle}</Typography>
        </div>
    );
};

SideImage.propTypes = {
    image: PropTypes.string,
    imageTitle: PropTypes.string,
};

export default SideImage;
