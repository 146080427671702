import { makeStyles } from '@material-ui/core';

import {
    COLOR_PRIMARY_BUTTON_DISABLED,
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_BASE,
    COLOR_SECONDARY_SHADE_01,
} from 'fintech/components/style/common';

export const makeMessageInputStyles = makeStyles(() => ({
    inputArea: {
        height: "80px",
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    input: {
        width: "100%",
        border: "none",
        outline: "none",
        fontSize: "12pt",
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "5px",

        "&::placeholder": {
            color: "gray",
        },
    },

    fileInput: {
        display: "none"
    },

    send: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },

    sendImage: {
        height: "40px",
        cursor: "pointer",
    },

    sendButton: {
        border: "none",
        padding: "10px 15px",
        color: "white",
        backgroundColor: COLOR_SECONDARY_BASE,

        "&:hover": {
            backgroundColor: COLOR_SECONDARY_SHADE_01,
        },

        "&:disabled": {
            backgroundColor: COLOR_PRIMARY_BUTTON_DISABLED,
        }
    }
}));