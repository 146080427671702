import { makeStyles } from '@material-ui/core/styles';
import { COLOR_PRIMARY_BASE, COLOR_PRIMARY_BUTTON_HOVERED, COLOR_THEME_FILL_01 } from 'fintech/components/style/common';

const defaultCardButton = {
    position: 'absolute',
    top: 320,
    lineHeight: 'normal',
    borderRadius: 8,
    backgroundColor: COLOR_PRIMARY_BASE,
    fontSize: 14,
    height: 28,
    width: '55%',
    minWidth: 'fit-content',
    textTransform: 'none',
    color: COLOR_THEME_FILL_01,
    '&:hover': {
        backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
        color: COLOR_THEME_FILL_01,
    },
};

const useStyles = makeStyles({
    root: {
        width: 270,
        marginRight: 20,
        height: 368,
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
    },
    media: {
        height: 104,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
    },
    cardButton: {
        ...defaultCardButton,
    },
    cardButtonPreLogin: {
        ...defaultCardButton,
        top: 220,
        left: 20,
    },
});

export default useStyles;
