import React from 'react';
import { NavigateNext } from '@material-ui/icons';
import { Breadcrumbs, Grid, Typography, useMediaQuery, Box } from '@material-ui/core';
import {
    fontStyle,
    mobileMediaQuery,
    COLOR_SECONDARY_SHADE_01,
    COLOR_SECONDARY_BASE,
} from 'fintech/components/style/common';
import useStyles from './index.style';

const ArrowNavigation = (props) => {
    const matches = useMediaQuery(mobileMediaQuery);
    const { currentIndex = 0, options = [], optionsWithLongText = [] } = props;
    const classes = useStyles();

    const optsJSX = options?.map((opt, idx) => (
        <Typography
            key={idx}
            style={{
                ...fontStyle,
                fontSize: '18px',
                color: idx <= currentIndex ? COLOR_SECONDARY_BASE : COLOR_SECONDARY_SHADE_01,
            }}
        >
            {opt}
        </Typography>
    ));
    return (
        <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            className={matches ? classes.stickyContainer : ''}
        >
            <Grid item container direction="row" justify="flex-start" alignItems="flex-start">
                {matches ? (
                    <Grid item container direction="row" justify="center" className={classes.rectangle}>
                        <Breadcrumbs
                            classes={{ ol: classes.breadcrumbsOl, separator: classes.breadcrumbsSeperator }}
                            separator={<NavigateNext className={classes.navigateArrow} fontSize="small" />}
                        >
                            {optsJSX}
                        </Breadcrumbs>
                    </Grid>
                ) : (
                    optionsWithLongText.length && (
                        <Grid item xs={12}>
                            <Box component="div" className={classes.mobilePageIndicator}>
                                <Typography className={classes.pageIndicatorTypo}>{`${
                                    optionsWithLongText[currentIndex]
                                } (${currentIndex + 1}/${optionsWithLongText?.length})`}</Typography>
                            </Box>
                        </Grid>
                    )
                )}
            </Grid>
        </Grid>
    );
};

export default ArrowNavigation;
