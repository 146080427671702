import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import EventDetailHeader from 'fintech/components/dashboard/eventdetail/eventdetailheader/EventDetailHeader';
import { useStyles } from 'fintech/components/dashboard/eventdetail/evendetailgrid/EventDetailGrid.style';
import EventDetailContent from 'fintech/components/dashboard/eventdetail/eventdetailcontent/EventDetailContent';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import OtherEventsGridContainer from 'fintech/components/dashboard/eventdetail/othereventsgridcontainer/OtherEventsGridContainer';
import { fetchViewedEvent, setViewedEvent } from 'fintech/store/actions/event';
import { useKeycloak } from '@react-keycloak/web';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import { withTranslation } from 'react-i18next';
import useWindowSize from 'fintech/hooks/useWindowSize';
import upperImage from 'fintech/static/images/event-big.png';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';

const EventDetailGrid = (props) => {
    const classes = useStyles();
    const { t } = props;
    const { events, otherEvents, viewedEvent } = useSelector((state) => state.event);
    const [event, setEvent] = useState(null);
    const windowSize = useWindowSize();
    const [isLoading, setLoading] = useState(true);
    const { id } = useParams();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const tabs = [{ label: t('eventDetail.aboutFintechEvent'), value: 0 }];

    //On refresh the redux store values will be lost, we have to fetch the event again by id
    useEffect(() => {
        //Checks the event store
        let eventFromStore = events.find((event) => event.id == id);
        //Checks the other events store
        if (!eventFromStore) {
            otherEvents.find((event) => event.id == id);
        }
        if (eventFromStore) {
            dispatch(setViewedEvent(eventFromStore));
        } else {
            dispatch(fetchViewedEvent(keycloak, id));
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [id]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => setLoading(false), 300);
    }, [id]);

    useEffect(() => {
        viewedEvent ? setEvent(viewedEvent) : null;
    }, [viewedEvent]);

    const getEventDetailsJSX = () => {
        const eventHeader = {
            eventName: event.eventName,
            eventDate: event.eventDate,
            eventEndDate: event.eventEndDate,
            online: event.online,
        };
        const eventDetailContent = {
            eventInformation: event.eventInformation,
            eventAttachments: event.attachments,
            eventImageUuid: event.imageUuid,
        };

        return (
            <>
                <Grid item xs={12}>
                    <img alt={'event-up-image'} className={classes.EventDetailUpImage} src={upperImage} />
                </Grid>
                <EventDetailHeader {...eventHeader} />
                <EventDetailContent {...eventDetailContent} />
            </>
        );
    };

    return (
        <>
            <NavTabs value={0} tabs={tabs} />
            {isLoading ? (
                <Paper
                    style={{
                        height: windowSize.height ? windowSize.height / 2 : '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <LoadingSpinner />{' '}
                </Paper>
            ) : (
                <Grid className={classes.EventDetailGrid} container>
                    {event ? getEventDetailsJSX() : null}
                </Grid>
            )}
            <Box height="32px" />
            <OtherEventsGridContainer eventId={event && event.id} />
        </>
    );
};

export default withTranslation('event')(EventDetailGrid);
