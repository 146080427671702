import React, { useContext, useEffect } from 'react';
import { Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { CarouselContext } from 'pure-react-carousel';
import useWindowSize from 'fintech/hooks/useWindowSize';
import SupportsCard from '../SupportsCard/index';

const CarouselSlider = ({ setSlideCount, setCurrentSlide, data }) => {
    const screenWidth = useWindowSize();
    const carouselContext = useContext(CarouselContext);

    useEffect(() => {
        const updateCarouselSlide = (slideToBeVisible) => {
            const { currentSlide, totalSlides, visibleSlides } = carouselContext.state;

            setSlideCount(slideToBeVisible);
            //bu, ekran yeniden boyutlandırıldığında currentSlide'ı sıfırlamak için bir düzeltmedir
            if (currentSlide >= totalSlides - visibleSlides || currentSlide >= totalSlides - slideToBeVisible) {
                setCurrentSlide(totalSlides - slideToBeVisible);
            }
        };

        if (screenWidth.width < 832) {
            updateCarouselSlide(1);
        } else if (screenWidth.width < 1200) {
            updateCarouselSlide(2);
        } else {
            //>= 1200
            updateCarouselSlide(3);
        }
    }, [screenWidth, setSlideCount, setCurrentSlide, carouselContext]);

    return (
        <>
            <Slider>
                {data?.map((event, inx) => (
                    <Slide key={inx} index={inx}>
                        <SupportsCard item key={event.id} {...event} />
                    </Slide>
                ))}
            </Slider>
        </>
    );
};

export default CarouselSlider;
