import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { COLOR_PRIMARY_BASE, fontStyle } from 'fintech/components/style/common';

const VisibilityToggle = (props) => {
    const { disabled, useDisabledValue, disabledValue, useEnabledValue, enabledValue } = props;
    const classes = makeStyles({
        ToggleTypo: {
            ...fontStyle,
            color: !disabled ? COLOR_PRIMARY_BASE : '#9E9E9E',
            cursor: !disabled && 'pointer',
            display: 'flex',
            justifyContent: 'end',
            gap: '5px',
            alignItems: 'center',
            padding: '2px',
            borderRadius: '4px',
            '&:hover': {
                backgroundColor: !disabled && '#f5f5f5',
            },
        },
    })();
    const { t } = useTranslation();
    const [selected, setSelected] = useState(!disabled && props.initialValue ? props.initialValue : false);

    const onChangeHandler = () => {
        if (disabled) {
            return;
        }

        setSelected(!selected);
        props.onChange && props.onChange(!selected);
    };

    useEffect(() => {
        if (disabled && useDisabledValue) {
            setSelected(disabledValue);
            props.onChange && props.onChange(disabledValue);
        }
        if (!disabled && useEnabledValue) {
            setSelected(enabledValue);
            props.onChange && props.onChange(enabledValue);
        }
    }, [disabled, disabledValue]);

    const iconJSX = selected ? <Visibility size="small" /> : <VisibilityOff size="small" />;
    const text = selected ? t('formLabel.public') : t('formLabel.private');

    return (
        <div onClick={onChangeHandler} className={classes.ToggleTypo}>
            {iconJSX}
            <Typography className={classes.ToggleTypo}>{text}</Typography>
        </div>
    );
};

export default VisibilityToggle;
