import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';

import { useHistory } from 'react-router';
import { ENTREPRENEURS_ATTEMPT } from 'fintech/routes';

import { withTranslation } from 'react-i18next';
import PageTemplate from 'fintech/components/PageTemplate';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import SplitButton from 'fintech/components/ui/button/SplitButton';

import startupImage from 'fintech/static/images/startup.jpg';
import startupImageMobile from 'fintech/static/images/startup-mobil.jpg';
import {
    makeSearchStyle,
    makeAdvancedFilteringStyle,
    renderSearchCategoryStyle,
    renderGridStyle,
} from 'fintech/pages/post-login/EntrepreneursPage/index.style';
import useCardGridStyles from 'fintech/components/search/CardGrid/index.style';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { buttonStyleGridClear, mobileMediaQuery } from 'fintech/components/style/common';

import {
    Button,
    CardMedia,
    Box,
    Select,
    InputBase,
    MenuItem,
    Checkbox,
    ListItemText,
    Typography,
} from '@material-ui/core';

import StickyTable from 'fintech/components/table/StickyTable';
import AdvancedFilteringModal from 'fintech/components/modal/AdvancedFilteringModal/';
import Empty from 'fintech/components/util/Empty';
import GridPagination from 'fintech/components/pagination/GridPagination/';
import MentorAssignModal from 'fintech/components/modal/MentorAssignModal';
import RepresentiveChangeModal from 'fintech/components/modal/RepresentiveChangeModal';

import * as EntrepreneurActions from 'fintech/store/actions/entrepreneur';
import { EntrepreneurActions as EntrepreneurActionsTypes } from 'fintech/store/actions/ActionTypes';

import { ROLE_ADMIN, ROLE_INVESTOR_REPRESANTATIVE } from 'fintech/components/util/RoleConstants';
import { hasRoles } from 'fintech/utils/Auth';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import { groupBy, isNil } from 'lodash';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import { InfoIcon } from 'fintech/components/profile/common';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import { Close } from '@material-ui/icons';
import useAdvancedFilterAreaStyles from 'fintech/components/search/AdvancedFilterArea/index.style.js';
import SvgIconsSingleFilter from 'fintech/icons/IconsSingleFilter';
import { Grid } from '@material-ui/core';
import SvgIconsSet from 'fintech/icons/IconsSet';

const EntrepreneurPage = (props) => {
    const { t } = props;
    const { persist } = useSelector((state) => state.entrepreneur.global);
    const { input, filters, lastQueryParams } = useSelector((state) => state.entrepreneur.searchBox);
    const { enqueueSnackbar } = useSnackbar();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const history = useHistory();

    const showSuccessMessage = (message) => enqueueSnackbar(message, { variant: 'success' });
    const showErrorMessage = (message) => enqueueSnackbar(message, { variant: 'error' });
    const showUpdateError = () => enqueueSnackbar(t('idea.updateError'), { variant: 'error' });
    const getIsFavouriteSupportes = () => {
        return hasRoles(keycloak, ROLE_INVESTOR_REPRESANTATIVE);
    };
    const isAdmin = hasRoles(keycloak, ROLE_ADMIN);

    // Selectors
    const { selectedTab, lastFetchStatus } = useSelector((state) => state.entrepreneur.global);
    const entrepreneur = useSelector((state) => state.entrepreneur);
    const advancedFilter = useSelector((state) => state.entrepreneur.advancedFilter);

    // Initial Requests
    useEffect(() => {
        if (!persist) {
            window.scroll({ top: 0, left: 0 });
            dispatch(EntrepreneurActions.resetToInitialGrid());
            dispatch(EntrepreneurActions.getStartupActiveCount(keycloak));
            dispatch(EntrepreneurActions.fetchAdvancedFilterData(keycloak, showUpdateError));

            if (selectedTab === TAB_CONSTANTS.ALL_VENTURES) {
                dispatch(EntrepreneurActions.fetchGridData(keycloak, showUpdateError));
            } else if (selectedTab === TAB_CONSTANTS.FAVOURITE_VENTURES) {
                dispatch(EntrepreneurActions.fetchFavoriteStartup(keycloak, showUpdateError));
            }
        } else {
            // I added it to get updated data after editing.
            dispatch(EntrepreneurActions.getStartupActiveCount(keycloak));
            dispatch(EntrepreneurActions.fetchAdvancedFilterData(keycloak, showUpdateError));

            if (selectedTab === TAB_CONSTANTS.ALL_VENTURES) {
                dispatch(EntrepreneurActions.fetchGridData(keycloak, showUpdateError));
            } else if (selectedTab === TAB_CONSTANTS.FAVOURITE_VENTURES) {
                dispatch(EntrepreneurActions.fetchFavoriteStartup(keycloak, showUpdateError));
            }
            dispatch(EntrepreneurActions.persistState(false));
        }
    }, [selectedTab]);

    const fetchData = () => {
        if (selectedTab === TAB_CONSTANTS.ALL_VENTURES) {
            if (lastFetchStatus === LAST_FETCH_STATUS.SEARCH) {
                dispatch(
                    EntrepreneurActions.makeSearch(
                        keycloak,
                        EntrepreneurActions.generateSearchDataForFiltering(filters, input),
                        showUpdateError
                    )
                );
            } else if (lastFetchStatus === LAST_FETCH_STATUS.FILTER) {
                dispatch(EntrepreneurActions.fetchGridData(keycloak, showUpdateError));
            }
        } else if (selectedTab == TAB_CONSTANTS.FAVOURITE_VENTURES) {
            dispatch(EntrepreneurActions.fetchFavoriteStartup(keycloak, showUpdateError));
        }
    };

    useEffect(() => {
        if (input === '' && lastQueryParams?.searchTerm && lastQueryParams.searchTerm !== '') {
            dispatch(EntrepreneurActions.resetToInitialGrid());
            dispatch(EntrepreneurActions.fetchGridData(keycloak, showUpdateError));
        }
    }, [input]);

    // Style Hooks
    const renderSearchStyles = makeSearchStyle();
    const renderAdvancedFilteringStyles = makeAdvancedFilteringStyle();
    const renderAdvancedFilterAreaStyles = useAdvancedFilterAreaStyles();
    const renderSearchCategoryStyles = renderSearchCategoryStyle();
    const renderGridStyles = renderGridStyle({
        selectedTab,
        isLoading: entrepreneur.grid.isLoading,
        numberOfRows: entrepreneur.grid && entrepreneur.grid.rows ? entrepreneur.grid.rows.length : 1,
        heightInPixelsPerElement: 82,
    });
    const cardGridClasses = useCardGridStyles();
    const isMobile = !useMediaQuery(mobileMediaQuery);
    const [adminModalState, setAdminModalState] = useState({
        isMentorModalDisplayed: false,
        isRepresentiveModalDisplayed: false,
    });
    const [selectedElement, setSelectedElement] = useState(null);

    // Passive Modal
    const [isMakePassiveModalOpen, setIsMakePassiveModalOpen] = useState(false);

    return (
        <>
            {adminModalState.isMentorModalDisplayed && (
                <MentorAssignModal
                    buttonActions={{
                        apply: (selectedValues) => {
                            const parsedSelectedValues = {
                                mentors: selectedValues.map((element) => {
                                    return { uuid: element.uuid };
                                }),
                            };
                            if (!isNil(selectedElement)) {
                                EntrepreneurActions.postMentorList(
                                    keycloak,
                                    selectedElement.uuid,
                                    parsedSelectedValues,
                                    () => showSuccessMessage(t('entrepreneur.startupsMentorInformationsUpdated')),
                                    showUpdateError
                                );
                                fetchData();
                            }
                        },
                        reject: () =>
                            setAdminModalState((prevState) => ({ ...prevState, isMentorModalDisplayed: false })),
                    }}
                    multipleSelectValues={entrepreneur.adminModal.mentorList}
                ></MentorAssignModal>
            )}
            {adminModalState.isRepresentiveModalDisplayed && (
                <RepresentiveChangeModal
                    buttonActions={{
                        apply: (selectedRepresentativeUUID) => {
                            dispatch(
                                EntrepreneurActions.putRepresentative(
                                    keycloak,
                                    selectedElement.uuid,
                                    selectedRepresentativeUUID,
                                    () =>
                                        showSuccessMessage(
                                            t('entrepreneur.startupRepresentiveUpdated', {
                                                name: selectedElement.name,
                                            })
                                        ),
                                    showUpdateError
                                )
                            );
                            fetchData();
                        },
                        reject: () =>
                            setAdminModalState((prevState) => ({ ...prevState, isRepresentiveModalDisplayed: false })),
                    }}
                    multipleSelectValues={entrepreneur.adminModal.representativeList}
                ></RepresentiveChangeModal>
            )}
            <ConfirmDialog
                open={isMakePassiveModalOpen}
                icon={<InfoIcon fontSize="large" />}
                title={t('dialog.confirmTitle')}
                injectHtml
                content={t('modal.passiveModal.statusDialogWarning', { status: t('status.passive') })}
                labelCancel={t('dialog.cancelAction')}
                labelConfirm={t('dialog.confirmAction')}
                onCancel={() => {
                    setIsMakePassiveModalOpen(false);
                }}
                onConfirm={() => {
                    dispatch(
                        EntrepreneurActions.deactiveStartup(
                            selectedElement.uuid,
                            keycloak,
                            (keycloak) => {
                                dispatch(EntrepreneurActions.getStartupActiveCount(keycloak));
                                fetchData();
                                showSuccessMessage(
                                    t('modal.passiveModal.statusDialogSuccess', {
                                        name: selectedElement.name,
                                    })
                                );
                            },
                            showUpdateError
                        )
                    );
                    setIsMakePassiveModalOpen(false);
                }}
            />
            <PageTemplate>
                <PageHeaderBox />
                <Box>
                    {renderNav({ t, selectedTab, dispatch, getIsFavouriteSupportes })}
                    {selectedTab === TAB_CONSTANTS.ALL_VENTURES && (
                        <>
                            {renderSearch({
                                t,
                                input,
                                filters,
                                dispatch,
                                keycloak,
                                showUpdateError,
                                showErrorMessage,
                                selectedTab,
                                renderSearchStyles,
                                renderSearchCategoryStyles,
                                entrepreneur,
                                isMobile,
                            })}
                            {renderAdvancedFiltering({
                                dispatch,
                                t,
                                keycloak,
                                showUpdateError,
                                advancedFilter,
                                entrepreneur,
                                renderAdvancedFilteringStyles,
                                renderAdvancedFilterAreaStyles,
                            })}
                        </>
                    )}

                    {renderGrid({
                        entrepreneur,
                        renderGridStyles,
                        dispatch,
                        keycloak,
                        showUpdateError,
                        showSuccessMessage,
                        lastFetchStatus,
                        selectedTab,
                        filters,
                        t,
                        input,
                        history,
                        getIsFavouriteSupportes,
                        setAdminModalState,
                        setSelectedElement,
                        setIsMakePassiveModalOpen,
                        isAdmin,
                        cardGridClasses,
                    })}
                    {renderPagination({
                        entrepreneur,
                        dispatch,
                        selectedTab,
                        keycloak,
                        showUpdateError,
                        lastFetchStatus,
                        filters,
                        input,
                    })}
                </Box>
            </PageTemplate>
        </>
    );
};

// Containers
const renderNav = ({ t, selectedTab, dispatch, getIsFavouriteSupportes }) => {
    const isFavouriteSupported = getIsFavouriteSupportes();
    const tabs = [{ label: t('navtab.allVentures'), value: TAB_CONSTANTS.ALL_VENTURES }];

    if (isFavouriteSupported) {
        tabs.push({ label: t('navtab.favoriteVentures'), value: TAB_CONSTANTS.FAVOURITE_VENTURES });
    }

    return (
        <NavTabs
            id="search-navtabs"
            style={{ marginBottom: isFavouriteSupported ? '28px' : 'unset' }}
            value={selectedTab}
            tabs={tabs}
            onChange={(e, value) => {
                dispatch({
                    type: EntrepreneurActionsTypes.SET_ENTREPRENEUR_GRID_DATA,
                    data: { rows: null, headers: null },
                });
                dispatch({
                    type: EntrepreneurActionsTypes.SET_ENTREPRENEUR_GRID_LOADING_INITIAL,
                });

                dispatch({ type: EntrepreneurActionsTypes.SET_ENTREPRENEUR_GLOBAL_SELECTEDTAB, data: value });
                dispatch({
                    type: EntrepreneurActionsTypes.SET_ENTREPRENEUR_GRID_PAGINATION,
                    data: EntrepreneurActions.getInitialPaginationState(),
                });
            }}
        />
    );
};

const renderSearch = (props) => {
    const {
        t,
        input,
        filters,
        keycloak,
        showUpdateError,
        showErrorMessage,
        dispatch,
        renderSearchStyles,
        entrepreneur,
        isMobile,
    } = props;

    const handleSearch = () => {
        if (EntrepreneurActions.isAllSearchDataFiltersDisabled(filters)) {
            showErrorMessage(t('searchbar.validationText'));
            return;
        }
        if (input.trim() === '') return;
        dispatch(
            EntrepreneurActions.makeSearch(
                keycloak,
                EntrepreneurActions.generateSearchDataForFiltering(filters, input),
                showUpdateError,
                true
            )
        );
    };

    return (
        <Box id="boxed-searchbar" className={renderSearchStyles.searchBarContainer}>
            <CardMedia
                className={renderSearchStyles.searchbarPlaceholder}
                image={isMobile ? startupImageMobile : startupImage}
                alt=""
            />
            {!isNil(entrepreneur.global.totalNumberOfStartups) && (
                <p className={renderSearchStyles.description}>
                    {t('searchbar.description').replace('${0}', entrepreneur.global.totalNumberOfStartups)}
                </p>
            )}
            <Box p={2} className={renderSearchStyles.searchArea}>
                <Box flexGrow={2}>{renderSearchCategory(props)}</Box>
                <Box className="searchInput" flexGrow={7}>
                    {renderSearchInput({ props, handleSearch, isMobile, t })}
                </Box>
                <Box className={renderSearchStyles.searchButtonContainer} flexGrow={1}>
                    <Button onClick={handleSearch} className={renderSearchStyles.searchButton}>
                        {t('searchbar.search')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

const renderAdvancedFiltering = ({
    dispatch,
    t,
    keycloak,
    showUpdateError,
    advancedFilter,
    entrepreneur,
    renderAdvancedFilteringStyles,
}) => {
    const { isDisplayed, verticals, selectedVertical, data } = advancedFilter;
    const { appliedFilters, global } = entrepreneur;

    const getNumberOfAppliedFilters = () => {
        let count = 0;

        for (const properties in appliedFilters) {
            count += appliedFilters[properties].length;
        }

        return count;
    };
    const numberOfAppliedFilters = getNumberOfAppliedFilters();

    let listedMessage = null;
    if (numberOfAppliedFilters !== 0) {
        listedMessage =
            global.totalNumberOfResults > 1
                ? t('searchbar.descriptionAboutFiltersMulti').replace('${0}', global.totalNumberOfResults)
                : t('searchbar.descriptionAboutFilters').replace('${0}', global.totalNumberOfResults);
    } else if (global.lastFetchStatus === LAST_FETCH_STATUS.SEARCH) {
        listedMessage =
            global.totalNumberOfResults > 1
                ? t('searchbar.descriptionAboutSearchMulti').replace('${0}', global.totalNumberOfResults)
                : t('searchbar.descriptionAboutSearch').replace('${0}', global.totalNumberOfResults);
    } else {
        listedMessage = t('searchbar.allStartupsListed');
    }

    return (
        <Box id="advanced-filter-area">
            {isDisplayed && (
                <AdvancedFilteringModal
                    type="entrepreneur"
                    data={data}
                    buttonActions={{
                        closeButton: () => {
                            dispatch(EntrepreneurActions.closeAdvancedFilter());
                        },
                        unCheckAll: () => {
                            dispatch(EntrepreneurActions.uncheckAllCheckboxes());
                        },
                        apply: () => {
                            dispatch(EntrepreneurActions.setAppliedFilters(keycloak, null, showUpdateError));
                        },
                    }}
                    verticals={verticals}
                    selectedVertical={selectedVertical}
                    setVertical={(index) => dispatch(EntrepreneurActions.setSelectedVertical(index))}
                    toggleCheckbox={(id) => {
                        dispatch(EntrepreneurActions.toggleCheckbox(id));
                    }}
                ></AdvancedFilteringModal>
            )}

            <Grid
                className={renderAdvancedFilteringStyles.container}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center">
                    <Typography display="inline" className={renderAdvancedFilteringStyles.tableInfoLabeL}>
                        {global.totalNumberOfResults !== 0 ? listedMessage : ''}
                    </Typography>
                    {(numberOfAppliedFilters !== 0 || global.lastFetchStatus === LAST_FETCH_STATUS.SEARCH) && (
                        <ShrinkingButton
                            onClick={() => {
                                dispatch(EntrepreneurActions.resetToInitialGrid(keycloak, showUpdateError));
                                dispatch(EntrepreneurActions.fetchGridData(keycloak, showUpdateError));
                            }}
                            shrinkedIcon={<Close fontSize="small" />}
                            fullIcon={<Close />}
                            fullText={
                                global.lastFetchStatus === LAST_FETCH_STATUS.FILTER
                                    ? t('searchbar.removeAll')
                                    : t('searchbar.clearResults')
                            }
                            otherStyles={buttonStyleGridClear}
                        />
                    )}
                    <ShrinkingButton
                        onClick={() => dispatch(EntrepreneurActions.toggleAdvancedFilter())}
                        shrinkedIcon={<SvgIconsSingleFilter fontSize="small" />}
                        fullIcon={<SvgIconsSingleFilter />}
                        fullText={
                            numberOfAppliedFilters === 0
                                ? t('searchbar.filterEntrepreneurs')
                                : `${numberOfAppliedFilters} ${t('searchbar.appyFilter')}`
                        }
                    ></ShrinkingButton>
                </Grid>
            </Grid>
            {numberOfAppliedFilters !== 0 && (
                <Box className={renderAdvancedFilteringStyles.splittedButtonContainer}>
                    {generateSplittedFilters({ appliedFilters, t, keycloak, showUpdateError, dispatch })}
                </Box>
            )}
        </Box>
    );
};

const renderGrid = ({
    entrepreneur,
    renderGridStyles,
    dispatch,
    keycloak,
    showSuccessMessage,
    showUpdateError,
    lastFetchStatus,
    filters,
    t,
    input,
    history,
    getIsFavouriteSupportes,
    setAdminModalState,
    setSelectedElement,
    setIsMakePassiveModalOpen,
    isAdmin,
    cardGridClasses,
}) => {
    const { selectedTab } = entrepreneur.global;
    const { rows, headers, isLoading, isError, canFavorite } = entrepreneur.grid;
    const isFavouriteSupported = getIsFavouriteSupportes();

    const emptyMessage =
        selectedTab === TAB_CONSTANTS.ALL_VENTURES ? (
            <>
                <Typography style={{ textAlign: 'center' }}>{t('searchbar.noResult')}</Typography>
                <Box className={renderGridStyles.removeSearchResultBtnContainer}>
                    <Button
                        className={renderGridStyles.removeSearchResultBtn}
                        onClick={() => {
                            dispatch(EntrepreneurActions.resetToInitialGrid(keycloak, showUpdateError));
                            dispatch(EntrepreneurActions.fetchGridData(keycloak, showUpdateError));
                        }}
                    >
                        {lastFetchStatus === LAST_FETCH_STATUS.SEARCH
                            ? t('searchbar.clearSearchResult')
                            : t('searchbar.clearFilters')}
                    </Button>
                </Box>
            </>
        ) : (
            <Grid container direction="column" justify="flex-start" alignItems="center">
                <Grid item>
                    <Typography
                        style={{ textAlign: 'center', maxWidth: '320px' }}
                        dangerouslySetInnerHTML={{ __html: t('dataGrid.noFavorites') }}
                    />
                </Grid>
                <Grid item>
                    <div style={{ marginTop: '26px' }}>
                        <SvgIconsSet />
                    </div>
                </Grid>
            </Grid>
        );

    let gridJSX = null;
    if (isLoading) {
        gridJSX = (
            <div className={cardGridClasses.EmptyWrapperWithBorder}>
                <Empty isLoading message={t('profile.loadingMessage')} circularShapes />
            </div>
        );
    } else if (isError) {
        gridJSX = (
            <div className={cardGridClasses.EmptyWrapperWithBorder}>
                <Empty isError message={t('dataGrid.fetchError')} circularShapes />
            </div>
        );
    } else if (rows && rows.length > 0) {
        gridJSX = (
            <StickyTable
                type="entrepreneur"
                containerClass={renderGridStyles.container}
                rightScrollButtonRightMargin={43}
                style={{ height: '100%' }}
                headers={headers}
                rows={rows}
                leftStickyColumnCount={canFavorite || isAdmin || isFavouriteSupported ? 2 : 1}
                buttonActions={{
                    favouriteCompany: (value, index, subIndex) =>
                        dispatch(
                            EntrepreneurActions.favoriteStartup(
                                keycloak,
                                value.startupUuid,
                                () =>
                                    showSuccessMessage(
                                        t('entrepreneur.addedStartupToFavorite').replace('${0}', value.label)
                                    ),
                                showUpdateError,
                                index,
                                subIndex
                            )
                        ),
                    unfavouriteCompany: (value, index, subIndex) =>
                        dispatch(
                            EntrepreneurActions.unFavoriteStartup(
                                keycloak,
                                value.startupUuid,
                                () =>
                                    showSuccessMessage(
                                        t('entrepreneur.removeStartupToFavorite').replace('${0}', value.label)
                                    ),
                                showUpdateError,
                                index,
                                subIndex
                            )
                        ),
                    sort: (id) => {
                        dispatch({ type: EntrepreneurActionsTypes.TOGGLE_ENTREPRENEUR_GRID_STATE, data: +id });
                        if (selectedTab === TAB_CONSTANTS.ALL_VENTURES) {
                            if (lastFetchStatus === LAST_FETCH_STATUS.FILTER) {
                                dispatch(EntrepreneurActions.fetchGridData(keycloak, showUpdateError, true));
                            } else {
                                dispatch(
                                    EntrepreneurActions.makeSearch(
                                        keycloak,
                                        EntrepreneurActions.generateSearchDataForFiltering(filters, input),
                                        showUpdateError,
                                        true
                                    )
                                );
                            }
                        } else if (selectedTab === TAB_CONSTANTS.FAVOURITE_VENTURES) {
                            dispatch(EntrepreneurActions.fetchFavoriteStartup(keycloak, showUpdateError, true));
                        }
                    },
                    handleRowClick: (startupUuid) => {
                        dispatch(EntrepreneurActions.persistState(true));
                        history.push(`${ENTREPRENEURS_ATTEMPT}/${startupUuid}`);
                    },
                    admin: {
                        setPassive: (uuid, name) => {
                            setIsMakePassiveModalOpen(true);
                            setSelectedElement({ uuid, name });
                        },
                        assignMentor: (uuid, name) => {
                            dispatch(EntrepreneurActions.fetchMentorList(keycloak, uuid, showUpdateError));
                            setSelectedElement({ uuid, name });
                            setAdminModalState((prevState) => ({ ...prevState, isMentorModalDisplayed: true }));
                        },
                        changeRepresentative: (uuid, name) => {
                            dispatch(EntrepreneurActions.fetchRepresentativeList(keycloak, uuid, showUpdateError));
                            setSelectedElement({ uuid, name });
                            setAdminModalState((prevState) => ({ ...prevState, isRepresentiveModalDisplayed: true }));
                        },
                    },
                }}
            />
        );
    } else {
        gridJSX = (
            <div className={cardGridClasses.EmptyWrapperWithBorder}>
                <Empty showIcon={false} messageComponent={emptyMessage} circularShapes />
            </div>
        );
    }

    return gridJSX;
};

const renderPagination = ({
    entrepreneur,
    dispatch,
    selectedTab,
    keycloak,
    showUpdateError,
    lastFetchStatus,
    filters,
    input,
}) => {
    const { rows, pagination, isLoading, gridDataLoading } = entrepreneur.grid;
    return rows && rows.length > 0 ? (
        <GridPagination
            controlled
            disabled={isLoading || gridDataLoading}
            {...pagination}
            handleChange={(e, value) => {
                window.scroll({ top: 0, left: 0 });
                dispatch(EntrepreneurActions.setDefaultPageOfPagination(+value));
                if (selectedTab === TAB_CONSTANTS.ALL_VENTURES) {
                    if (lastFetchStatus === LAST_FETCH_STATUS.SEARCH) {
                        dispatch(
                            EntrepreneurActions.makeSearch(
                                keycloak,
                                EntrepreneurActions.generateSearchDataForFiltering(filters, input),
                                showUpdateError,
                                false
                            )
                        );
                    } else if (lastFetchStatus === LAST_FETCH_STATUS.FILTER) {
                        dispatch(EntrepreneurActions.fetchGridData(keycloak, showUpdateError, false));
                    }
                } else if (selectedTab == TAB_CONSTANTS.FAVOURITE_VENTURES) {
                    dispatch(EntrepreneurActions.fetchFavoriteStartup(keycloak, showUpdateError, false, false));
                }
            }}
        />
    ) : null;
};

const generateSplittedFilters = ({ appliedFilters, t, keycloak, showUpdateError, dispatch }) => {
    const render = [];

    Object.entries(appliedFilters).map((filter) => {
        const [finTechVertical, values] = filter;

        const groups = groupBy(values, 'parentData');
        for (const [key, value] of Object.entries(groups)) {
            let subCategory = '';
            let infixText = '';
            if (key !== 'null') {
                subCategory = key;
                infixText = `: ${key}`;
            }

            const title = `${t('entrepreneur.' + finTechVertical)} ${infixText} [${value.length}]`;
            render.push(
                <SplitButton
                    idPrefix={EntrepreneurActions.generateIDPrefix(finTechVertical)}
                    removeComponentHandler={(_verticalPrefix, _subCategory) => {
                        dispatch(EntrepreneurActions.removeAppliedFiltersBySubcategory(_verticalPrefix, _subCategory));
                        dispatch(EntrepreneurActions.fetchGridData(keycloak, showUpdateError, true));
                    }}
                    removeItemHandler={(_verticalPrefix, _itemID) => {
                        dispatch(EntrepreneurActions.removeAppliedFilter(_verticalPrefix, _itemID));
                        dispatch(EntrepreneurActions.fetchGridData(keycloak, showUpdateError, true));
                    }}
                    startIcon={null}
                    type="filter"
                    option={title}
                    subOptions={value}
                    subCategory={subCategory}
                ></SplitButton>
            );
        }
    });

    return render;
};

// Components
const renderSearchInput = ({ props, handleSearch, isMobile, t }) => {
    const { input, dispatch } = props;
    return (
        <InputBase
            style={{ width: '100%' }}
            value={input}
            onChange={(e) => {
                dispatch(EntrepreneurActions.searchBoxOnChange(e.target.value));
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    handleSearch();
                }
            }}
            placeholder={isMobile ? t('searchbar.placeholderShort') : t('searchbar.placeholder')}
            inputProps={{ 'aria-label': 'naked' }}
        />
    );
};

const renderSearchCategory = (props) => {
    const { t, dispatch, renderSearchCategoryStyles } = props;
    const { filters } = props;
    const selectedLabels = filters.filter((element) => element.status === true).map((e) => e.label);

    return (
        <Select
            multiple
            value={selectedLabels}
            onChange={(e) => {
                dispatch(EntrepreneurActions.setSearchBoxFilterStatus(e.target.value));
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            }}
            displayEmpty={true}
            renderValue={(selected) => {
                return (
                    <Box component="div">
                        <Box
                            component="span"
                            className={`${renderSearchCategoryStyles.secondaryBase} ${renderSearchCategoryStyles.bold}`}
                        >
                            {`${t('entrepreneur.searchPreferences')} `}
                        </Box>
                        <Typography
                            className={`${renderSearchCategoryStyles.dropdown} ${renderSearchCategoryStyles.bold}`}
                            color="primary"
                            display="inline"
                        >{`(${selected.length}/${filters.length})`}</Typography>
                    </Box>
                );
            }}
        >
            {filters.map((element) => (
                <MenuItem key={element.id} value={element.label}>
                    <Checkbox color="primary" checked={selectedLabels.indexOf(element.label) > -1}></Checkbox>
                    <ListItemText primary={t(`entrepreneur.${element.label}`)}></ListItemText>
                </MenuItem>
            ))}
        </Select>
    );
};

export const TAB_CONSTANTS = {
    ALL_VENTURES: 'ALL_VENTURES',
    FAVOURITE_VENTURES: 'FAVOURITE_VENTURES',
};

export const LAST_FETCH_STATUS = {
    FILTER: 'FILTER',
    SEARCH: 'SEARCH',
};

export default withTranslation('entrepreneur')(EntrepreneurPage);
