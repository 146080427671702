import React from 'react';
import { CANCEL_BUTTON, PRIMARY_BUTTON } from 'fintech/components/util/ButtonConstanst';
import Button from '@material-ui/core/Button';

export default function ActionButton({ label, onClick, type, disabled = false }) {
    const getStyles = () => {
        if (type == PRIMARY_BUTTON) {
            return 'okButton';
        } else if (type == CANCEL_BUTTON) {
            return 'cancelButton';
        } else {
            return '';
        }
    };

    return (
        <div className={'cfoGenericButton'}>
            <Button variant="contained" disabled={disabled} onClick={onClick} className={getStyles()}>
                {label}
            </Button>
        </div>
    );
}
