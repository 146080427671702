import { buttonStyle, COLOR_PRIMARY_SHADE_03 } from 'fintech/components/style/common';
import { Button, makeStyles, withStyles } from '@material-ui/core';

const useStyles = makeStyles({
    invitesContainer: {
        '& .sticky-table-table': {
            width: '100%',
        },
    },
    header: {
        height: '128px',
        marginTop: '27px',
        marginBottom: '28px',

        '& > img': {
            width: '100%',
            height: '100%',
            borderRadius: '8px',
            objectFit: 'cover',
        },
    },
    searchBox: {
        marginTop: '28px',
        marginBottom: '11px',
        padding: '13px 18px',
        backgroundColor: 'inherit',
        borderRadius: '6px',
        border: `1px solid ${COLOR_PRIMARY_SHADE_03}`,
        display: 'flex',
        alignItems: 'center',
    },
    searchInputGrid: {
        flexGrow: '1',
        width: 'initial',
        '@media (max-width:450px)': {
            width: '50%',
        },
    },
    searchInput: {
        backgroundColor: 'inherit',
        border: 'none',
        outline: 'none',
        width: '100%',
        marginLeft: '10px',
        fontSize: 14,
        fontWeight: 500,
    },
    infoBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '7px',
        marginTop: '15px',
        marginBottom: '20px',
    },
    clearAll: {
        marginLeft: 'auto',
    },
});

export const SearchButton = withStyles({
    root: {
        ...buttonStyle,
        padding: '4px 22px',
        marginLeft: 'auto',
    },
})(Button);

export default useStyles;
