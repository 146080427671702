import * as React from 'react';

function SvgIconsSingleClose2(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <filter id="9nqpgbb3xa" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.384314 0 0 0 0 0.247059 0 0 0 0 0.247059 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g
                            filter="url(#9nqpgbb3xa)"
                            transform="translate(-575 -763) translate(399 663) translate(166 96)"
                        >
                            <g>
                                <path
                                    fill="#3F5162"
                                    fillRule="nonzero"
                                    d="M12.845 18L12.845 12.845 18 12.845 18 11.155 12.845 11.155 12.845 6 11.155 6 11.155 11.155 6 11.155 6 12.845 11.155 12.845 11.155 18z"
                                    transform="translate(10 4) rotate(45 12 12)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleClose2;
