import { getRequest } from 'fintech/api/api';
import { SUPPORT_PROGRAM } from 'fintech/constants/apis';
import { SupportProgramApplicationActions } from 'fintech/store/actions/ActionTypes';

const setApplication = (application) => ({
    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION,
    data: application,
});

const setApplicationLoading = () => ({
    type: SupportProgramApplicationActions.GET_SUPPORT_PROGRAM_APPLICATION,
});

const setApplicationError = () => ({
    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION_ERROR,
});

export const fetchApplication = (id, func) => {
    return (dispatch) => {
        dispatch(setApplicationLoading());
        getRequest(SUPPORT_PROGRAM.getSupportProgramApplicationForm(id), null)
            .then((res) => dispatch(setApplication(res.data)))
            .catch(() => func());
    };
};

// Represenative data
const setRepresentativeInfo = (rep) => ({
    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION_REP_INFO,
    data: rep,
});

const sethRepresentativeInfoLoading = () => ({
    type: SupportProgramApplicationActions.GET_SUPPORT_PROGRAM_APPLICATION_REP_INFO,
});

const setRepresentativeInfoError = () => ({
    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION_REP_INFO_ERROR,
});

export const fetchRepresentativeInfo = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(sethRepresentativeInfoLoading());
        getRequest(SUPPORT_PROGRAM.getRepresentativeInfo(uuid), keycloak)
            // mockApiRequest(1000, mockRepInfoSuccessResponse())
            .then((res) => dispatch(setRepresentativeInfo(res.data)))
            .catch(() => dispatch(setRepresentativeInfoError()));
    };
};
