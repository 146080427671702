import { cloneDeep, isEmpty, isNil } from 'lodash';
import { EntrepreneurActions } from 'fintech/store/actions/ActionTypes';
import {
    getVerticalKeyFromIDPrefix,
    getInitialSearchBoxState,
    getInitialAdvancedFiltersState,
    getInitialAppliedFiltersState,
    getInitialPaginationState,
    getInitialGridHeaderState,
    GRID_HEADER_STATE,
    getGridRowsBySettingStartupStatus,
} from 'fintech/store/actions/entrepreneur';
import { TAB_CONSTANTS, LAST_FETCH_STATUS } from 'fintech/pages/post-login/EntrepreneursPage';

const initialState = {
    advancedFilter: getInitialAdvancedFiltersState(),
    appliedFilters: getInitialAppliedFiltersState(),
    searchBox: getInitialSearchBoxState(),
    grid: {
        headers: null,
        rows: null, // Needs to be fetched from server
        pagination: getInitialPaginationState(),
        isLoading: true,
        gridDataLoading: false,
        isError: false,
        canFavorite: false,
    },
    global: {
        totalNumberOfStartups: null,
        totalNumberOfResults: null,
        selectedTab: TAB_CONSTANTS.ALL_VENTURES,
        lastFetchStatus: LAST_FETCH_STATUS.FILTER,
        persist: false,
    },
    adminModal: {
        mentorList: null,
        representativeList: null,
        currentRepresentative: null,
    },
};

// Optimization Required
const entrepreneur = (state = initialState, action) => {
    const { type } = action;
    const advancedFilter = state.advancedFilter;
    const data = cloneDeep(advancedFilter.data);

    switch (type) {
        // Global
        case EntrepreneurActions.SET_ENTREPRENEUR_GLOBAL_PERSIST_STATE:
            return {
                ...state,
                global: {
                    ...state.global,
                    persist: action.data,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_GLOBAL_TOTALNUMBEROFSTARTUPS:
            return {
                ...state,
                global: {
                    ...state.global,
                    totalNumberOfStartups: action.data,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_GLOBAL_TOTALNUMBEROFRESULTS:
            return {
                ...state,
                global: {
                    ...state.global,
                    totalNumberOfResults: action.data,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_GLOBAL_SELECTEDTAB:
            return {
                ...state,
                global: {
                    ...state.global,
                    selectedTab:
                        action.data === TAB_CONSTANTS.ALL_VENTURES
                            ? TAB_CONSTANTS.ALL_VENTURES
                            : TAB_CONSTANTS.FAVOURITE_VENTURES,
                },
            };
        case EntrepreneurActions.SET_LAST_FETCH_STATUS:
            return {
                ...state,
                global: {
                    ...state.global,
                    lastFetchStatus: action.data,
                },
            };
        // Advanced Filter Actions
        case EntrepreneurActions.SET_ENTREPRENEUR_ADVANCEDFILTER_DATA:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    data: action.data,
                },
            };
        case EntrepreneurActions.TOGGLE_ENTREPRENEUR_ADVANCEDFILTER:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    isDisplayed: !state.advancedFilter.isDisplayed,
                },
            };
        case EntrepreneurActions.TOGGLE_ENTREPRENEUR_ADVANCEDFILTER_CHECKBOX:
            const { vertical, checkboxInputID } = action.data;
            const verticalKey = getVerticalKeyFromIDPrefix(vertical);

            const selectedIndex = data[verticalKey].findIndex((e, index) => e.id === checkboxInputID);
            data[verticalKey][selectedIndex] = {
                ...data[verticalKey][selectedIndex],
                selected: !data[verticalKey][selectedIndex].selected,
            };

            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    data,
                },
            };
        case EntrepreneurActions.RESET_ENTREPRENEUR_ADVANCEDFILTER_CHECKBOX:
            for (const property in data) {
                data[property].forEach((element, index, arr) => {
                    arr[index] = {
                        ...element,
                        selected: false,
                    };
                });
            }

            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    data,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_VERTICAL:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    selectedVertical: action.data,
                },
            };
        case EntrepreneurActions.RESET_ENTREPRENEUR_ADVANCEDFILTER:
            return {
                ...state,
                advancedFilter: {
                    ...getInitialAdvancedFiltersState(),
                    data: state.advancedFilter.data,
                },
            };
        // Applied Filters
        case EntrepreneurActions.SET_ENTREPRENEUR_APPLIED_FILTERS:
            return {
                ...state,
                appliedFilters: action.data,
            };
        case EntrepreneurActions.REMOVE_ENTREPRENEUR_APPLIED_FILTERS:
            const appliedFilterVertical = getVerticalKeyFromIDPrefix(action.data.verticalPrefix);
            const appliedFilterID = action.data.id;
            if (appliedFilterID === null) {
                return {
                    ...state,
                    appliedFilters: {
                        ...state.appliedFilters,
                        [appliedFilterVertical]: [],
                    },
                };
            } else {
                return {
                    ...state,
                    advancedFilter: {
                        ...state.advancedFilter,
                        data: {
                            ...state.advancedFilter.data,
                            [appliedFilterVertical]: state.advancedFilter.data[appliedFilterVertical].map((element) => {
                                if (element.id === appliedFilterID) {
                                    return {
                                        ...element,
                                        selected: false,
                                    };
                                }

                                return element;
                            }),
                        },
                    },
                    appliedFilters: {
                        ...state.appliedFilters,
                        [appliedFilterVertical]: state.appliedFilters[appliedFilterVertical].filter(
                            (e) => e.id !== appliedFilterID
                        ),
                    },
                };
            }
        case EntrepreneurActions.REMOVE_ENTREPRENEUR_APPLIED_FILTERS_BY_SUBCATEGORY:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    data: {
                        ...state.advancedFilter.data,
                        [getVerticalKeyFromIDPrefix(action.data.verticalPrefix)]: state.advancedFilter.data[
                            getVerticalKeyFromIDPrefix(action.data.verticalPrefix)
                        ].map((element) => {
                            if (
                                (element.parentData === null && action.data.subCategory === '') ||
                                element.parentData === action.data.subCategory
                            ) {
                                return {
                                    ...element,
                                    selected: false,
                                };
                            }
                            return element;
                        }),
                    },
                },
                appliedFilters: {
                    ...state.appliedFilters,
                    [getVerticalKeyFromIDPrefix(action.data.verticalPrefix)]: state.appliedFilters[
                        getVerticalKeyFromIDPrefix(action.data.verticalPrefix)
                    ].filter((e) => e.parentData !== (action.data.subCategory === '' ? null : action.data.subCategory)),
                },
            };
        case EntrepreneurActions.RESET_ENTREPRENEUR_APPLIED_FILTERS:
            return {
                ...state,
                appliedFilters: getInitialAppliedFiltersState(),
            };
        // SearchBox
        case EntrepreneurActions.SET_ENTREPRENEUR_SEARCHBOX_LAST_QUERY:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    lastQueryParams: action.data,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_SEARCHBOX_INPUT:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    input: action.data,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_SEARCHBOX_FILTERSTATUS:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    filters: state.searchBox.filters.map((element) => {
                        return action.data.includes(element.label)
                            ? {
                                  ...element,
                                  status: true,
                              }
                            : {
                                  ...element,
                                  status: false,
                              };
                    }),
                },
            };
        case EntrepreneurActions.RESET_ENTREPRENEUR_SEARCHBOX:
            return {
                ...state,
                searchBox: getInitialSearchBoxState(),
            };
        // Grid
        case EntrepreneurActions.SET_ENTREPRENEUR_GRID_DATA:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    headers: action.data.headers,
                    rows: action.data.rows,
                    isLoading: false,
                    gridDataLoading: false,
                    canFavorite: action.data.canFavorite,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_GRID_ROW_FAVOURITE:
            const _rows = cloneDeep(state.grid.rows);
            _rows[action.data.index][action.data.subIndex].value.isFavourite = action.data.status;

            return {
                ...state,
                grid: {
                    ...state.grid,
                    rows: _rows,
                },
            };
        case EntrepreneurActions.TOGGLE_ENTREPRENEUR_GRID_STATE:
            let _headers = cloneDeep(state.grid.headers);
            const selectedHeader = state.grid.headers.findIndex((e) => e.id === action.data);

            switch (_headers[selectedHeader].value.status) {
                case GRID_HEADER_STATE.NONE:
                    _headers[selectedHeader].value.status = GRID_HEADER_STATE.ASC;
                    break;
                case GRID_HEADER_STATE.ASC:
                    _headers[selectedHeader].value.status = GRID_HEADER_STATE.DESC;
                    break;
                case GRID_HEADER_STATE.DESC:
                    _headers[selectedHeader].value.status = GRID_HEADER_STATE.ASC;
                    break;
                default:
                    break;
            }

            _headers = _headers.map((element, index) => {
                if (selectedHeader !== index) {
                    return {
                        ...element,
                        value: {
                            ...element.value,
                            status: GRID_HEADER_STATE.NONE,
                        },
                    };
                } else {
                    return element;
                }
            });

            return {
                ...state,
                grid: {
                    ...state.grid,
                    headers: _headers,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOADING_INITIAL:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    rows: [],
                    isLoading: true,
                    isError: false,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOADING:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    gridDataLoading: true,
                    isError: false,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOAD_ERROR:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    isLoading: false,
                    gridDataLoading: false,
                    isError: true,
                },
            };
        // Pagination
        case EntrepreneurActions.SET_ENTREPRENEUR_GRID_PAGINATION:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    pagination: action.data,
                },
            };
        case EntrepreneurActions.RESET_ENTREPRENEUR_GRID_PAGINATION:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    pagination: getInitialPaginationState(),
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_GRID_STARTUP_STATUS:
            let outherIndex = null,
                innerIndex = null;

            cloneDeep(state.grid.rows).every((elements, _outherIndex) => {
                const _innerIndex = elements.findIndex((e) => e.value.uuid === action.data.uuid);
                if (_innerIndex !== -1) {
                    outherIndex = _outherIndex;
                    innerIndex = _innerIndex;
                    return false;
                }

                return true;
            });

            if (!isNil(outherIndex) && !isNil(innerIndex)) {
                return {
                    ...state,
                    grid: {
                        ...state.grid,
                        rows: getGridRowsBySettingStartupStatus(
                            state.grid.rows,
                            outherIndex,
                            innerIndex,
                            action.data.status
                        ),
                    },
                };
            }
            return state;
        // Admin Modals
        case EntrepreneurActions.SET_ENTREPRENEUR_MENTOR_LIST:
            return {
                ...state,
                adminModal: {
                    ...state.adminModal,
                    mentorList: action.data,
                },
            };
        case EntrepreneurActions.SET_ENTREPRENEUR_REPRESENTIVE_LIST:
            return {
                ...state,
                adminModal: {
                    ...state.adminModal,
                    representativeList: action.data.map((element, index) => {
                        return {
                            id: element.uuid,
                            data: `${element.name} ${element.surname}`,
                            selected: index === 0 ? true : false,
                        };
                    }),
                    currentRepresentativeID: action.data && action.data.length > 0 && action.data[0]?.uuid,
                },
            };
        default:
            return state;
    }
};

export default entrepreneur;
