import React from 'react';
import PropTypes from 'prop-types';
import useStyle from './index.style';
import RoleBoxItem from './components/roleBoxItem';

const Index = ({ items }) => {
    const classes = useStyle();
    return (
        <div className={classes.container}>
            {items.map((x, index) => (
                <RoleBoxItem {...x} key={'' + index} />
            ))}
        </div>
    );
};

Index.propTypes = {
    items: PropTypes.arrayOf().isRequired,
    defaultBarColor: PropTypes.string.isRequired,
};
Index.defaultProps = {
    items: [],
};

export default Index;
