import React, { useEffect } from 'react';

import { Button, Box } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import useStyles from 'fintech/components/profile/startup/SimilarVentures/index.style';

import { ENTREPRENEURS } from 'fintech/routes';

import RenderStickyTableWithPagination from 'fintech/components/profile/startup/SimilarVentures/containers/RenderStickyTableWithPagination';

import { fetchSimilarVenturesData } from 'fintech/store/actions/startup';
import { StartupActions } from 'fintech/store/actions/ActionTypes';

export default function SimilarVentures() {
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const errorCallback = () => {};
    const { uuid } = useSelector((state) => state.startup.intro.data);
    const { rows } = useSelector((state) => state.startup.similarVentures.grid);

    useEffect(() => {
        dispatch(fetchSimilarVenturesData(keycloak, uuid, errorCallback));

        return () => {
            dispatch({ type: StartupActions.RESET_STARTUP_SIMILARVENTURES_GRID_DATA });
        };
    }, []);

    return (
        <>
            <RenderStickyTableWithPagination errorCallback={errorCallback} />

            {rows && rows.length > 0 && (
                <Box className={classes.box}>
                    <div
                        style={{ fontWeight: 500 }}
                        dangerouslySetInnerHTML={{ __html: t('startup.similarVentures.info.hasData') }}
                    />
                    <Button
                        size="small"
                        className={classes.cardButton}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            history.push(ENTREPRENEURS);
                        }}
                    >
                        {t('startup.similarVentures.goToStartups')}
                    </Button>
                </Box>
            )}
        </>
    );
}
