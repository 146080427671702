import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import supportProgramImage from 'fintech/static/images/destek-programlari.jpg';
import { fetchApplication } from 'fintech/store/actions/supportProgramApplication';
import Other from 'fintech/components/support/program/form/SupportProgramApplication/Other';
import ApplicationPageTemplate from 'fintech/components/applications/layout/pre-login/PageTemplate';
import GeneralInformation from 'fintech/components/support/program/form/SupportProgramApplication/GeneralInformation';
import IntellectualProperty from 'fintech/components/support/program/form/SupportProgramApplication/IntellectualProperty';
import StructuralInformation from 'fintech/components/support/program/form/SupportProgramApplication/StructuralInformation';
import FounderAndTeamInformation from 'fintech/components/support/program/form/SupportProgramApplication/FounderAndTeamInformation';
import PreApplicationInformation from 'fintech/components/support/program/form/SupportProgramApplication/PreApplicationInformation';
import RepresentativeInformation from 'fintech/components/support/program/form/SupportProgramApplication/RepresentativeInformation';
import { hasAnyAuthorities, isAuthenticated } from 'fintech/utils/Auth';
import { ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE } from 'fintech/components/util/RoleConstants';
import { NOT_FOUND, SUPPORT_PROGRAM_APPLICATION_SECURE } from 'fintech/routes';
import ApplicationResult from 'fintech/components/applications/form/common/ApplicationResult';

const SupportProgramApplicationPage = () => {
    const [currentIndex, setCurrentIndex] = useState(-1);

    const { keycloak } = useKeycloak();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation('supportProgramApplication');
    const { t: t2 } = useTranslation('application');

    useEffect(() => {
        window.scroll({ top: 0, left: 0 });
    }, [currentIndex]);

    useEffect(() => {
        if (hasAnyAuthorities(keycloak, [ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE])) {
            history.replace(NOT_FOUND);
            return;
        }

        if (isAuthenticated(keycloak)) {
            history.replace(`${SUPPORT_PROGRAM_APPLICATION_SECURE}/${id}`);
            return;
        }

        dispatch(fetchApplication(id, redirectPage));
    }, []);

    const redirectPage = () => {
        history.replace(NOT_FOUND);
    };

    const options = [
        t('pageTitles.representativeInformation'),
        t('pageTitles.generalInformation'),
        t('pageTitles.intellectualProperty'),
        t('pageTitles.founderAndTeamInformation'),
        t('pageTitles.structureInformation'),
        t('pageTitles.other'),
        t('pageTitles.result'),
    ];

    const optionsWithLongText = [
        t('pageTitles.representativeInformation'),
        t('pageTitles.generalInformation'),
        t('pageTitles.intellectualProperty'),
        t('pageTitles.founderAndTeamInformation'),
        t('pageTitles.structureInformationLongText'),
        t('pageTitles.otherLongText'),
        t('pageTitles.result'),
    ];

    const nextPage = () => setCurrentIndex((prevState) => prevState + 1);
    const prevPage = () => setCurrentIndex((prevState) => prevState - 1);

    const getCurrentFormPage = () => {
        switch (currentIndex) {
            case -1:
                return <PreApplicationInformation onApplyClick={nextPage} />;
            case 0:
                return <RepresentativeInformation formId={id} pageFunctions={{ nextPage }} />;
            case 1:
                return <GeneralInformation formId={id} pageFunctions={{ prevPage, nextPage }} />;
            case 2:
                return <IntellectualProperty formId={id} pageFunctions={{ prevPage, nextPage }} />;
            case 3:
                return <FounderAndTeamInformation formId={id} pageFunctions={{ prevPage, nextPage }} />;
            case 4:
                return <StructuralInformation formId={id} pageFunctions={{ prevPage, nextPage }} />;
            case 5:
                return <Other formId={id} pageFunctions={{ prevPage, nextPage }} />;
            case 6: {
                return <ApplicationResult title={t2('supportProgram.resultTitle')} info={t2('common.resultInfo')} />;
            }
            default:
                return null;
        }
    };

    return (
        <ApplicationPageTemplate
            reducedPadding
            image={supportProgramImage}
            imageTitle={t('supportProgramApplication')}
            options={currentIndex === -1 ? [] : options}
            optionsWithLongText={currentIndex === -1 ? [] : optionsWithLongText}
            currentIndex={currentIndex}
        >
            {getCurrentFormPage()}
        </ApplicationPageTemplate>
    );
};

export default SupportProgramApplicationPage;
