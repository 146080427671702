import { MentorApplicationActions } from 'fintech/store/actions/ActionTypes';
import { PUBLIC_RESOURCES } from 'fintech/constants/apis';
import { getRequest } from 'fintech/api/api';

// Actions for the countries in the system for nationality choice
const setCountries = (countries) => ({
    type: MentorApplicationActions.SET_MENTOR_APPLICATION_COUNTRIES,
    countries: countries,
});

export const fetchCountries = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: MentorApplicationActions.GET_MENTOR_APPLICATION_COUNTRIES });
        getRequest(PUBLIC_RESOURCES.getCountries(), keycloak)
            .then((data) => {
                const key = 'data';
                if (data && data[key] && data[key][0]) {
                    data[key][0]['selected'] = true;
                }
                dispatch(setCountries(data));
            })
            .catch(() => {
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_COUNTRIES_LOAD_ERROR });
            });
    };
};

// Actions for genders in the system
const setGenders = (genders) => ({
    type: MentorApplicationActions.SET_MENTOR_APPLICATION_GENDERS,
    gender: genders,
});

export const fetchGenders = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: MentorApplicationActions.GET_MENTOR_APPLICATION_GENDERS });
        getRequest(PUBLIC_RESOURCES.getGenders(), keycloak)
            .then((data) => {
                dispatch(setGenders(data));
            })
            .catch(() => {
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_GENDERS_LOAD_ERROR });
            });
    };
};

// Actions for the education in the system for nationality choice
const setEducationLevels = (levels) => ({
    type: MentorApplicationActions.SET_MENTOR_APPLICATION_EDUCATION_LEVELS,
    levels: levels,
});

export const fetchEducationLevels = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: MentorApplicationActions.GET_MENTOR_APPLICATION_EDUCATION_LEVELS });

        getRequest(PUBLIC_RESOURCES.getEducationInfos(), keycloak)
            .then((data) => {
                dispatch(setEducationLevels(data));
            })
            .catch(() => {
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR });
            });
    };
};

// Actions for verticals in the system
const setVerticals = (verticals) => ({
    type: MentorApplicationActions.SET_MENTOR_APPLICATION_VERTICALS,
    verticals: verticals,
});

export const fetchVerticals = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: MentorApplicationActions.GET_MENTOR_APPLICATION_VERTICALS });
        getRequest(PUBLIC_RESOURCES.getVerticals(), keycloak)
            .then((data) => {
                dispatch(setVerticals(data));
            })
            .catch(() => {
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_VERTICALS_LOAD_ERROR });
            });
    };
};

// Actions for technologies in the system
const setTechnologies = (technologies) => ({
    type: MentorApplicationActions.SET_MENTOR_APPLICATION_TECHNOLOGIES,
    technologies: technologies,
});

export const fetchTechnologies = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: MentorApplicationActions.GET_MENTOR_APPLICATION_TECHNOLOGIES });
        getRequest(PUBLIC_RESOURCES.getTechnologies(), keycloak)
            .then((data) => {
                dispatch(setTechnologies(data));
            })
            .catch(() => {
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_TECHNOLOGIES_LOAD_ERROR });
            });
    };
};
