import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NewInputTextArea from 'fintech/components/ui/form/textinput/NewInputTextArea';
import { InvestorApplicationActions } from 'fintech/store/actions/ActionTypes';
import useGeneralInfoStyles from 'fintech/components/applications/form/investor/GeneralInfoForm/index.style';
import AlertInfo from '../../common/AlertInfo';

const InstitutionalInvestorForm = (props) => {
    const generalInfoClasses = useGeneralInfoStyles();
    const { t } = useTranslation('application');
    const dispatch = useDispatch();
    const { disabled, validationError, setPathIsBlurred } = props;
    const { name, email, idNumber } = useSelector((state) => state.investorApplication.institution);

    const nameChangedHandler = (val) => {
        const newState = val;
        dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_INSTITUTION_NAME, data: newState });
    };

    const idChangeHandler = (val) => {
        const newState = val;
        dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_INSTITUTION_IDENTITY, data: newState });
    };

    const emailChangeHandler = (val) => {
        const newState = val;
        dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_INSTITUTION_EMAIL, data: newState });
    };

    return (
        <Grid item xs={12} container direction="row" justify="center">
            <Grid item xs={12}>
                <AlertInfo
                    title={t('investor.form.helper.generalInfoHelperInstitutional')}
                    subTitle={t('investor.form.helper.generalInfoHelperInstitutionalSubTitle')}
                />
            </Grid>
            <Grid item xs={12}>
                <div>
                    <NewInputTextArea
                        containerClass={generalInfoClasses.inputTextAreaWrapper}
                        disabled={disabled}
                        type="text"
                        label={t('formLabel.institutionName')}
                        maxLength={255}
                        hideCountIndicator
                        validationError={validationError}
                        validationPath="institution.name"
                        value={name}
                        onChange={nameChangedHandler}
                        onBlur={() => setPathIsBlurred('institution.name')}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={generalInfoClasses.divInputLeft}>
                    <NewInputTextArea
                        containerClass={generalInfoClasses.inputTextAreaWrapper}
                        disabled={disabled}
                        type="identity"
                        label={t('formLabel.taxIdNumber')}
                        maxLength={10}
                        hideCountIndicator
                        validationError={validationError}
                        validationPath="institution.idNumber"
                        value={idNumber}
                        onChange={idChangeHandler}
                        onBlur={() => setPathIsBlurred('institution.idNumber')}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={generalInfoClasses.divInputRight}>
                    <NewInputTextArea
                        containerClass={generalInfoClasses.inputTextAreaWrapper}
                        type="mail"
                        label={t('formLabel.institutionEmail')}
                        validationText={t('validation.notEmpty')}
                        validationError={validationError}
                        validationPath="institution.email"
                        value={email}
                        onChange={emailChangeHandler}
                        onBlur={() => setPathIsBlurred('institution.email')}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default InstitutionalInvestorForm;
