import { UserSearchActions } from 'fintech/store/actions/ActionTypes';
import { APIS } from 'fintech/constants';
import { getRequest, postRequest } from 'fintech/api/api';
import { canChangeUserStatus, parseSubVerticals } from 'fintech/utils/DataParser';
import { withPagination } from 'fintech/constants/apis';
import { GRID_HEADER_STATE } from './entrepreneur';
import { hasRoles } from 'fintech/utils/Auth';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';

export const persistState = (persist) => ({
    type: UserSearchActions.SET_USER_GLOBAL_PERSIST_STATE,
    data: persist,
});

export const resetAllFilters = () => {
    return (dispatch) => {
        dispatch({ type: UserSearchActions.RESET_USER_ADVANCED_FILTER });
        dispatch({ type: UserSearchActions.RESET_USER_APPLIED_FILTERS });
        dispatch({ type: UserSearchActions.RESET_USER_SEARCHBOX });

        // Reset pagination
        dispatch({ type: UserSearchActions.RESET_USER_GRID_PAGINATION });
    };
};

// Global
export const setTotalNumber = (data) => ({
    type: UserSearchActions.SET_USER_GLOBAL_TOTAL_ACTIVE_NUMBER,
    data,
});
export const setFilteredNumber = (data) => ({
    type: UserSearchActions.SET_USER_GLOBAL_FILTERED_NUMBER,
    data,
});

export const toggleAdvancedFilter = () => ({ type: UserSearchActions.TOGGLE_USER_ADVANCEDFILTER });

export const fetchAdvancedFilterData = (keycloak, errorCallback) => {
    return (dispatch) => {
        getRequest(APIS.USER.getFilterURL(), keycloak)
            .then((resp) => {
                dispatch({ type: UserSearchActions.SET_USER_ADVANCEDFILTER_DATA, data: resp.data });
            })
            .catch(() => {
                errorCallback();
            });
    };
};

export const setGridHeaders = (headers) => {
    return (dispatch) => {
        dispatch({ type: UserSearchActions.SET_USER_GRID_HEADERS, data: headers });
    };
};

export const setGridPage = (pageNumber) => ({
    type: UserSearchActions.SET_USER_GRID_PAGINATION,
    data: {
        page: pageNumber,
    },
});

export const fetchActiveUsersCount = (keycloak, errorCallback) => {
    return (dispatch) => {
        getRequest(APIS.USER.getActiveUsersURL(), keycloak)
            .then((resp) => {
                dispatch({ type: UserSearchActions.SET_USER_GLOBAL_TOTAL_ACTIVE_NUMBER, data: resp.data });
            })
            .catch(() => {
                errorCallback();
            });
    };
};

export const fetchGridData = ({
    keycloak,
    data,
    errorCallback,
    useLastQueryType = false,
    isFilter = true,
    reloadGrid = true,
    timeout = 700,
}) => {
    return (dispatch, getState) => {
        // Initial call when the component mounts
        if (reloadGrid) {
            // Show a loading indicator in place of the whole grid
            dispatch({ type: UserSearchActions.SET_USER_GRID_LOADING_INITIAL });
        } else {
            // TODO: Give the grid a disabled effect much like in DealRoom
            dispatch({ type: UserSearchActions.SET_USER_GRID_LOADING });
        }

        setTimeout(() => {
            const { appliedFilters, global, grid, searchBox } = getState().userSearch;
            const { filteredNumber, lastQueryType } = global;
            const { lastQueryParams } = searchBox;
            const { headers, pagination } = grid;

            let commonArgs = {
                dispatch,
                keycloak,
                headers,
                pageSize: pagination.pageSize,
                page: pagination.page,
                filteredNumber,
                errorCallback,
                isAdmin: hasRoles(keycloak, ROLE_ADMIN),
            };
            if (!useLastQueryType) {
                isFilter ? makeFilter({ ...commonArgs, appliedFilters }) : makeSearch({ ...commonArgs, data });
            } else {
                lastQueryType === 'search'
                    ? makeSearch({ ...commonArgs, data: lastQueryParams })
                    : makeFilter({ ...commonArgs, appliedFilters });
            }
        }, timeout);
    };
};

export const makeFilter = ({ dispatch, keycloak, headers, appliedFilters, pageSize, page, errorCallback, isAdmin }) => {
    const url = withPagination(APIS.USER.getFilterURL(), pageSize, page - 1);
    postRequest(appendSortQueryToUrl(url, headers), keycloak, appliedFilters)
        .then((resp) => {
            const { result } = resp.data;
            const { totalElements, content, totalPages } = result;
            let users = {};
            const rows = [];

            content.forEach((data) => {
                users[data.uuid] = { status: data.status, roles: data.roles?.map((role) => role.roleUniqueId) };
                rows.push(generateGridRowFromUserData(data, isAdmin));
            });

            let count = 0;
            for (const properties in appliedFilters) {
                count += appliedFilters[properties].length;
            }

            if (count > 0) {
                dispatch(setFilteredNumber(totalElements));
            } else {
                dispatch(setFilteredNumber(null));
            }

            dispatch({
                type: UserSearchActions.SET_USER_GRID_DATA,
                data: {
                    rows: rows,
                    users: users,
                },
            });
            dispatch({
                type: UserSearchActions.SET_USER_GRID_PAGINATION,
                data: { count: totalPages },
            });
            dispatch({ type: UserSearchActions.SET_USER_LAST_QUERY_TYPE, data: 'filter' });
        })
        .catch(() => {
            dispatch({ type: UserSearchActions.SET_USER_GRID_LOAD_ERROR });
            errorCallback();
        });
};

export const makeSearch = ({ dispatch, keycloak, data, headers, pageSize, page, errorCallback, isAdmin }) => {
    const url = withPagination(APIS.USER.getSearchURL(), pageSize, page - 1);
    postRequest(appendSortQueryToUrl(url, headers), keycloak, data)
        .then((resp) => {
            const { totalElements, content, totalPages } = resp.data.result;
            let users = {};
            const rows = [];

            content.forEach((data) => {
                users[data.uuid] = { status: data.status, roles: data.roles?.map((role) => role.roleUniqueId) };
                rows.push(generateGridRowFromUserData(data, isAdmin));
            });

            // TODO: Change count for search
            let count = data.searchTerm?.length;
            if (count > 0) {
                dispatch(setFilteredNumber(totalElements));
            } else {
                dispatch(setFilteredNumber(null));
            }

            // Save last query
            dispatch({ type: UserSearchActions.SET_USER_SEARCHBOX_LAST_QUERY, data: data });

            // Reset advanced filters
            dispatch({ type: UserSearchActions.RESET_USER_ADVANCED_FILTER });
            dispatch({ type: UserSearchActions.RESET_USER_APPLIED_FILTERS });

            dispatch({
                type: UserSearchActions.SET_USER_GRID_DATA,
                data: {
                    rows: rows,
                    users: users,
                },
            });
            dispatch({
                type: UserSearchActions.SET_USER_GRID_PAGINATION,
                data: { count: totalPages },
            });
            dispatch({ type: UserSearchActions.SET_USER_LAST_QUERY_TYPE, data: 'search' });
        })
        .catch(() => {
            dispatch({ type: UserSearchActions.SET_USER_GRID_LOAD_ERROR });
            errorCallback();
        });
};

export const setSelectedCategory = (index) => ({
    type: UserSearchActions.SET_USER_CATEGORY,
    data: index,
});

export const closeAdvancedFilter = () => {
    return (dispatch, getState) => {
        const state = getState().userSearch;
        let isAppliedFiltersExist = false;

        for (const value of Object.values(state.appliedFilters)) {
            if (value && value.length !== 0) {
                isAppliedFiltersExist = true;
                break;
            }
        }

        if (isAppliedFiltersExist) {
            dispatch(setSelectedCategory(0));
            dispatch(toggleAdvancedFilter());
        } else {
            dispatch(setSelectedCategory(0));
            dispatch(toggleAdvancedFilter());
            dispatch(uncheckAllCheckboxes());
        }
    };
};

export const toggleCheckbox = (data) => ({
    type: UserSearchActions.TOGGLE_USER_ADVANCEDFILTER_CHECKBOX,
    data,
});

export const uncheckAllCheckboxes = () => ({
    type: UserSearchActions.RESET_USER_ADVANCEDFILTER_CHECKBOX,
});

// SearchBox
export const searchBoxOnChange = (data) => ({
    type: UserSearchActions.SET_USER_SEARCHBOX_INPUT,
    data,
});

export const setSearchBoxFilterStatus = (data) => ({
    type: UserSearchActions.SET_USER_SEARCHBOX_FILTERSTATUS,
    data,
});

// Applied Filters
export const setAppliedFilters = (keycloak, data, errorCallback) => {
    return (dispatch, getState) => {
        const state = getState();
        const { data: advanvedFilterData } = state.userSearch.advancedFilter;

        const appliedFilters = generateAppliedFiltersTemplate();
        for (const [category, values] of Object.entries(advanvedFilterData)) {
            for (const value of values) {
                if (value.selected === true) {
                    appliedFilters[category].push(Object.assign({}, value));
                }
            }
        }

        dispatch({ type: UserSearchActions.RESET_USER_SEARCHBOX });

        if (!data) {
            dispatch({ type: UserSearchActions.SET_USER_APPLIED_FILTERS, data: appliedFilters });
            dispatch(toggleAdvancedFilter());
        } else {
            dispatch({ type: UserSearchActions.SET_USER_APPLIED_FILTERS, data });
        }
        dispatch(fetchGridData({ keycloak, errorCallback }));
    };
};

export const removeAppliedFilter = (categoryPrefix, id = null) => ({
    type: UserSearchActions.REMOVE_USER_APPLIED_FILTERS,
    data: {
        categoryPrefix: categoryPrefix,
        id: id,
    },
});

export const removeAppliedFiltersBySubcategory = (categoryPrefix, subCategory) => ({
    type: UserSearchActions.REMOVE_USER_APPLIED_FILTERS_BY_SUBCATEGORY,
    data: {
        categoryPrefix,
        subCategory,
    },
});

// Admin actions
// Actions for role options
const setRoles = (roles) => ({
    type: UserSearchActions.SET_USER_FORM_ROLES,
    roles: roles,
});

export const fetchRoles = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: UserSearchActions.GET_USER_FORM_ROLES });
        getRequest(APIS.USER.getRolesURL(), keycloak)
            .then((data) => {
                dispatch(setRoles(data));
            })
            .catch(() => {
                dispatch({ type: UserSearchActions.SET_USER_FORM_ROLES_LOAD_ERROR });
            });
    };
};

// Actions for genders in the system
const setGenders = (genders) => ({
    type: UserSearchActions.SET_USER_FORM_GENDERS,
    genders: genders,
});

export const fetchGenders = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: UserSearchActions.GET_USER_FORM_GENDERS });
        getRequest(APIS.USER.getGendersURL(), keycloak)
            .then((data) => {
                dispatch(setGenders(data));
            })
            .catch(() => {
                dispatch({ type: UserSearchActions.SET_USER_FORM_GENDERS_LOAD_ERROR });
            });
    };
};

// Actions for the fields of expertise in the system (Consultants)
const setFieldsOfExpertise = (expertise) => ({
    type: UserSearchActions.SET_USER_FORM_FIELDS_OF_EXPERTISE,
    expertise: expertise,
});

export const fetchFieldsOfExpertise = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: UserSearchActions.GET_USER_FORM_FIELDS_OF_EXPERTISE });
        getRequest(APIS.USER.getFieldsOfExpertiseURL(), keycloak)
            .then((data) => {
                dispatch(setFieldsOfExpertise(data));
            })
            .catch(() => {
                dispatch({ type: UserSearchActions.SET_USER_FORM_FIELDS_OF_EXPERTISE_LOAD_ERROR });
            });
    };
};

// Actions for the countries in the system for nationality choice
const setCountries = (countries) => ({
    type: UserSearchActions.SET_USER_FORM_COUNTRIES,
    countries: countries,
});

export const fetchCountries = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: UserSearchActions.GET_USER_FORM_COUNTRIES });
        getRequest(APIS.USER.getCountriesURL(), keycloak)
            .then((data) => {
                dispatch(setCountries(data));
            })
            .catch(() => {
                dispatch({ type: UserSearchActions.SET_USER_FORM_FIELDS_OF_EXPERTISE_LOAD_ERROR });
            });
    };
};

// Utils
const generateGridRowFromUserData = (data, isAdmin = false) => {
    const {
        uuid,
        profilePictureFile: logoFile,
        name,
        surname,
        roles,
        userFintechSubVerticalSet: subverticals,
        technologySet: technologies,
        status,
    } = data;

    const defaultColumns = [
        // Row id
        {
            id: uuid,
        },
        // Kullanıcı
        /* Modify src\components\table\StickyTable\index.style.js
         * userColumnTextWrapper to customize styling
         */
        {
            type: 'user',
            value: {
                label: `${name ? name : ''} ${surname ? surname : ''}`,
                logoFile,
            },
        },
        // Rol
        {
            type: 'role-multiline',
            value: roles?.map((role) => role.description),
            title: 'userSearch.expandModal.roller',
            style: {
                minWidth: '364px',
            },
        },
        // Fintek dikeyleri
        {
            type: 'verticalGroup',
            value: parseSubVerticals(subverticals, true),
            title: 'userSearch.expandModal.fintechVerticals',
            style: {
                minWidth: '378px',
            },
        },
        // Teknolojiler
        {
            type: 'tagGroup',
            value: technologies
                ?.sort((a, b) => {
                    return a.id < b.id ? -1 : a.id === b.id ? 0 : 1;
                })
                .map((tech) => ({
                    id: tech.id,
                    name: tech.name,
                })),
            title: 'userSearch.expandModal.technologies',
            style: {
                minWidth: '378px',
            },
        },
    ];

    if (isAdmin) {
        defaultColumns.splice(
            1,
            0,
            {
                type: 'actions',
                value: [
                    // Status change
                    {
                        id: uuid,
                        name: `${name} ${surname}`,
                        disabled: !canChangeUserStatus(
                            status,
                            roles?.map((role) => role.roleUniqueId)
                        ),
                    },
                ],
            },
            {
                type: 'status',
                value: status,
            }
        );
    }
    return defaultColumns;
};

const generateAppliedFiltersTemplate = () => {
    return {
        fintechVerticals: [],
        technologies: [],
        roles: [],
    };
};

const appendSortQueryToUrl = (url, headers) => {
    const filteredHeaders = headers.filter(
        (header) => header.type === 'sortLabel' && header.status !== GRID_HEADER_STATE.NONE
    );

    if (filteredHeaders.length === 0) {
        return url;
    }

    let query = '';
    filteredHeaders.forEach((e) => {
        query += '&sort=';
        query += `fullName,${e.status}`;
    });

    return `${url}${query}`;
};
