import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DialogContent, Grid } from '@material-ui/core';
import VisibilityToggle from 'fintech/components/ui/form/toggle/VisibilityToggle';
import CurrencyInput from 'fintech/components/ui/form/textinput/CurrencyInput';
import { getInvestorOrStartupFormValueFromState } from 'fintech/utils/DataParser';

const InvestorAmountUpdate = (props) => {
    const { type, setInvestmentAmount } = props;
    const { t } = useTranslation();
    const { investmentAmount, investmentAmountVisible, investmentVisible, sending } = useSelector((state) =>
        getInvestorOrStartupFormValueFromState(state, type)
    );
    const dispatch = useDispatch();

    const onChangeHandler = ({ value, currency, isVisible, isValid }) =>
        dispatch(
            setInvestmentAmount({
                id: investmentAmount.id,
                value: value,
                currency: currency,
                isVisible: isVisible,
                isValid: isValid,
            })
        );

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item>
                <CurrencyInput
                    id="startup-investment-currency-input"
                    label={t('profile.financials.investors.investmentAmount')}
                    required
                    initialValue={investmentAmount}
                    validatedAfterFormSubmit={!sending}
                    validateOnBlur
                    onChange={(monVal, isValid) =>
                        onChangeHandler({
                            value: monVal.value,
                            isValid: isValid,
                            currency: investmentAmount.currencyId,
                            isVisible: investmentAmountVisible,
                        })
                    }
                />
            </Grid>
            <Grid item>
                <DialogContent style={{ paddingTop: '0', textAlign: 'end' }}>
                    <VisibilityToggle
                        initialValue={investmentAmountVisible}
                        disabled={!investmentVisible}
                        useDisabledValue
                        disabledValue={false}
                        useEnabledValue
                        enabledValue={investmentAmountVisible}
                        onChange={(isVisible) =>
                            onChangeHandler({
                                isVisible: isVisible,
                                value: investmentAmount.value,
                                currency: investmentAmount.currencyId,
                                isValid: investmentAmount.isValid,
                            })
                        }
                    />
                </DialogContent>
            </Grid>
        </Grid>
    );
};

export default InvestorAmountUpdate;
