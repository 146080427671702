import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import useWindowSize from 'fintech/hooks/useWindowSize';
import * as EventCardUtils from 'fintech/components/dashboard/event/eventcardgrid/EventCardUtils';
import { mobileSizeForCarousel } from 'fintech/components/dashboard/event/eventcardgrid/EventCardUtils';
import EventCard from 'fintech/components/dashboard/event/eventcard/EventCard';
import PropTypes from 'prop-types';
import ActionModal from 'fintech/components/util/ActionModal';
import * as constants from 'fintech/components/util/ActionModalConstants';
import useStyles, { detailsModalStyle } from './index.style';
import { fromEpoch, NUMERIC_FULL_DATE_W_HOUR } from 'fintech/utils/DateUtils';

/**
 * Event Card Grid which uses the carousel for showing the event cards
 */
const EventCardGrid = (props) => {
    const classes = useStyles();
    const [visibleSlides, setVisibleSlides] = useState(4);
    const windowSize = useWindowSize();
    const [showNextButton, setShowNextButton] = useState();
    const [eventCardContent, setEventCardContent] = useState();
    const { t, isUserAuthenticated, isPreLogin } = props;

    useEffect(() => {
        setShowNextButton(EventCardUtils.isNextButtonVisible(windowSize, props.events.length));
    }, []);

    let EventsSlides = [];

    const eventsJSX = props.events?.map((event) => (
        <EventCard
            item
            key={event.id}
            isPreLogin={isPreLogin}
            isUserAuthenticated={isUserAuthenticated}
            setEventCardContent={setEventCardContent}
            {...event}
        />
    ));

    EventsSlides = eventsJSX.map((eventsJSX, index) => {
        const style = windowSize.width < mobileSizeForCarousel ? null : { maxWidth: 285 };
        return (
            <Slide style={style} key={index} index={index}>
                {eventsJSX}
            </Slide>
        );
    });

    useEffect(() => {
        setVisibleSlides(EventCardUtils.getNumberOfVisibleSlides(windowSize));
        setShowNextButton(EventCardUtils.isNextButtonVisible(windowSize, EventsSlides.length));
    }, [windowSize]);

    const getCarousel = () => {
        return (
            <CarouselProvider
                className={'eventCardCarousel'}
                style={{ marginTop: isPreLogin ? 16 : 26 }}
                naturalSlideWidth={270}
                naturalSlideHeight={368}
                totalSlides={EventsSlides.length}
                visibleSlides={visibleSlides}
            >
                <div className={'sliderContainer'}>
                    <Slider style={{ height: 400 }}>{EventsSlides}</Slider>
                    {windowSize.width > mobileSizeForCarousel ? (
                        <>
                            <ButtonBack className={'sliderButton sliderButtonBack'}>{'<'}</ButtonBack>
                            {showNextButton ? (
                                <ButtonNext className={'sliderButton sliderButtonNext'}>{'>'}</ButtonNext>
                            ) : null}
                        </>
                    ) : null}
                </div>
            </CarouselProvider>
        );
    };

    const detailsModalContentJsx = (
        <div className={classes.detailsModalContent}>
            <div className={classes.detailsModalContentLabel}>{t('event.eventDate')}</div>
            <div>{eventCardContent && fromEpoch(eventCardContent.eventDate, NUMERIC_FULL_DATE_W_HOUR)}</div>
            <div className={classes.detailsModalContentLabel}>{t('event.eventDetail')}</div>
            <div className={classes.detailsModalEventDetail}>
                {eventCardContent && <div dangerouslySetInnerHTML={{ __html: eventCardContent.eventInformation }} />}
            </div>
        </div>
    );

    const detailsModalActionHandler = (action) => {
        switch (action.type) {
            case constants.SUBMIT:
                setEventCardContent(null);
                return;
            case constants.REJECT:
                setEventCardContent(null);
                return;
            default:
                return;
        }
    };

    return (
        <>
            {getCarousel()}
            <ActionModal
                titleMarginBottom30={false}
                titleContainerClass={classes.modalTitle}
                tabsContainerClass={'padding-bottom-26 border-bottom'}
                modalStyle={detailsModalStyle}
                buttonContainerClass={classes.dialogButtonContainerClass}
                open={!!eventCardContent}
                okClickHidden={false}
                overrideTitle
                title={eventCardContent && eventCardContent.eventName}
                okButtonLabel={t('dialog.close')}
                onAction={detailsModalActionHandler}
                showCancelOnTop
                isGridItem={false}
            >
                {detailsModalContentJsx}
            </ActionModal>
        </>
    );
};

EventCardGrid.propTypes = {
    events: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            eventQuotaFull: PropTypes.bool.isRequired,
            eventName: PropTypes.string.isRequired,
            eventDate: PropTypes.string.isRequired,
            eventInformation: PropTypes.string,
            image: PropTypes.string,
        })
    ),
};

export default withTranslation('event')(EventCardGrid);
