import React, { useEffect, useState } from 'react';
import { Grid, Modal } from '@material-ui/core';
import useStyles from './index.style';
import MediaCarousel from 'fintech/components/profile/startup/Intro/Details/MediaCarousel';
import { useSelector } from 'react-redux';
import PlaceholdersMediaSmall from 'fintech/static/images/placeholders-media-small.svg';
import PlaceholdersMediaBig from 'fintech/static/images/placeholders-media-big.svg';
import CloseButton from 'fintech/icons/CloseButton';
import { VIDEO_MIMES_START } from 'fintech/components/util/FileConstants';
import { getThumbnailUrl } from 'fintech/api/fileService';
import ReactPlayer from 'react-player/lazy';
import _ from 'lodash';

const IntroMediaGrid = (props) => {
    const classes = useStyles();
    const { media } = props;
    const [showMediaCarousel, setShowMediaCarousel] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(false);
    const [sortedMedia, setSortedMedia] = useState(media);
    const { editable } = useSelector((state) => state.startup.intro.data);

    const showCarouselHandler = (idx) => {
        setShowMediaCarousel(true);
        setSelectedMedia(idx);
    };

    const dismissCarouselHandler = () => {
        setShowMediaCarousel(false);
    };

    useEffect(() => {
        if (media != null && media.length > 0) {
            /**
             * Ideally there must be no set of medias without a featured one.
             * This block sets the first media as featured if none is specified.
             */

            const sorted = media?.sort((a, b) => a.featured - b.featured).reverse();
            const featured = _.result(_.find(sorted, (obj) => obj.featured === true));
            if (!featured) {
                sorted[0].featured = true;
            }
            setSortedMedia(sorted);
        }
    }, [media]);

    const mediaGridJSX = sortedMedia?.map((med, idx) =>
        med.featured ? (
            <Grid
                item
                key={idx}
                className={classes.MediaGridTile}
                onClick={() => {
                    showCarouselHandler(idx);
                }}
                xs={12}
            >
                {med.file.mimeType.startsWith(VIDEO_MIMES_START) ? (
                    <div className="player-wrapper">
                        <ReactPlayer
                            className="react-player"
                            width="100%"
                            height="200px"
                            light={getThumbnailUrl(med.file.uuid)}
                            controls
                            url={getThumbnailUrl(med.file.uuid)}
                        />
                    </div>
                ) : (
                    <img width={'100%'} src={med.url} med={med.file.fileName} />
                )}
            </Grid>
        ) : med.file.mimeType.startsWith(VIDEO_MIMES_START) ? (
            <Grid
                item
                onClick={() => {
                    showCarouselHandler(idx);
                }}
                key={idx}
                className={classes.MediaGridTile}
                xs={3}
            >
                <ReactPlayer
                    className="react-player"
                    width="100%"
                    height="100%"
                    light={getThumbnailUrl(med.file.uuid)}
                    controls
                    url={getThumbnailUrl(med.file.uuid)}
                />
            </Grid>
        ) : (
            <Grid
                item
                onClick={() => {
                    showCarouselHandler(idx);
                }}
                key={idx}
                className={classes.MediaGridTile}
                xs={3}
            >
                <img width={'100%'} src={med.url} med={med.file.fileName} />
            </Grid>
        )
    );

    const curLen = mediaGridJSX?.length;

    if (curLen && curLen < 5) {
        for (let i = 1; i <= 5 - curLen; i = i + 1) {
            mediaGridJSX.push(
                <Grid item key={i + curLen} className={classes.MediaGridTilePlaceholder} xs={3}>
                    <div className="media-grid-div" style={{ height: '90px', width: '100%' }}>
                        {editable ? (
                            <img style={{ width: '100%', height: '100%' }} src={PlaceholdersMediaSmall} />
                        ) : null}
                    </div>
                </Grid>
            );
        }
    }

    return (
        <>
            <Modal
                style={{ zIndex: '10000', textAlign: 'center', border: 'none' }}
                open={showMediaCarousel}
                onClose={dismissCarouselHandler}
            >
                <div
                    className="carousel-modal-div"
                    style={{ width: '45%', maxWidth: '100%', height: '80%', textAlign: 'end' }}
                >
                    <div style={{ marginBottom: '4px' }} onClick={dismissCarouselHandler}>
                        <CloseButton style={{ cursor: 'pointer' }} />
                    </div>
                    <MediaCarousel media={media} selectedItem={selectedMedia} />
                </div>
            </Modal>
            <Grid container item xs={12} md={4} style={{ marginBottom: '30px' }}>
                {mediaGridJSX ? (
                    <Grid container item>
                        {mediaGridJSX}
                    </Grid>
                ) : (
                    <div className="media-grid-div" style={{ height: '300px', width: '100%' }}>
                        {editable && <img style={{ width: '100%', height: '100%' }} src={PlaceholdersMediaBig} />}
                    </div>
                )}
            </Grid>
        </>
    );
};

export default IntroMediaGrid;
