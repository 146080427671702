import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import CfoFormLabel from 'fintech/components/applications/form/common/CfoFormLabel';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import useLegalTextClick from 'fintech/hooks/useLegalTextClick';
import LegalTextModal from 'fintech/components/applications/modal/LegalTextModal';
import * as constants from 'fintech/components/util/ApplicationFormConstants';
import { MentorApplicationActions } from 'fintech/store/actions/ActionTypes';
import { fetchVerticals, fetchTechnologies } from 'fintech/store/actions/application-mentor';
import useStyles from 'fintech/components/applications/form/mentor/CoverLetterAndInterestsForm/index.style';
import CfoSingleCheckbox from 'fintech/components/ui/form/checkbox/CfoSingleCheckbox';
import { Check, KeyboardBackspace } from '@material-ui/icons';
import VericalUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/VerticalsUpdate';
import TechnologyUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/TechnologyUpdate';
import { postRequest } from 'fintech/api/api';
import { MENTOR_APPLICATION } from 'fintech/constants/apis';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DOCUMENT_MIME_TYPES } from 'fintech/components/util/FileConstants';
import FileUpload from 'fintech/components/util/FileUpload';
import { loadingSpinnerWithTextGeneralTextStyle, mobileMediaQuery } from 'fintech/components/style/common';
import { useMediaQuery } from '@material-ui/core';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const CoverLetterAndInterestsForm = (props) => {
    const { t } = useTranslation('application');
    const { switchTabHandler } = props;
    const { keycloak } = useKeycloak();
    const classes = useStyles();
    const matches = !useMediaQuery(mobileMediaQuery);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { options, files, ...form } = useSelector((state) => state.mentorApplication);
    const { verticals, technologies } = options;
    const [openModal, setOpenModal] = useState(false);
    const [clickedAgreement, setClickedAgreement] = useState(null);

    useLegalTextClick({
        legalClickListener: (e) => {
            if (e?.currentTarget) {
                setClickedAgreement(e?.currentTarget?.id);
                setOpenModal(true);
            }
        },
    });

    useEffect(() => {
        dispatch(fetchVerticals());
        dispatch(fetchTechnologies());
    }, []);

    const closeModalHandler = () => setOpenModal(false);

    const formChangeHandler = ({ type, data }) => {
        switch (type) {
            case constants.MENTOR_FORM_COVER_LETTER_UPDATE:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_COVER_LETTER, data });
                break;
            case constants.MENTOR_FORM_INTERESTED_VERTICAL_UPDATE:
                dispatch({
                    type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_INTERESTED_VERTICALS,
                    data: data,
                });
                break;
            case constants.MENTOR_FORM_INTERESTED_TECHNOLOGY_UPDATE:
                dispatch({
                    type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_INTERESTED_TECHNOLOGIES,
                    data: data,
                });
                break;

            case constants.MENTOR_FORM_FILE_UPDATE:
                dispatch({
                    type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_FILES,
                    data: data,
                });
                break;
            case constants.MENTOR_FORM_AGREEMENTS:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_AGREEMENTS });
                break;
        }
    };

    const clickContinueHandler = async () => {
        const {
            name,
            surname,
            country,
            idNumber,
            gender,
            birthDate,
            email,
            phoneNumber,
            experience,
            education,
            trafficSource,
            coverLetter,
            interestedVerticals,
            interestedTechnologies,
        } = form;

        const requestData = {};

        // First step
        requestData.name = name?.value;
        requestData.surname = surname?.value;
        requestData.nationality = {
            id: country?.value?.id,
        };
        requestData.tckn = idNumber?.value;
        requestData.gender = gender?.value?.id;
        requestData.birthDate = birthDate?.value;
        requestData.email = email?.value;
        requestData.phoneNumber = {
            number: phoneNumber?.value?.nationalNumber,
            country: {
                callingCode: phoneNumber?.value?.countryCallingCode,
                alphaTwo: phoneNumber?.value?.country,
            },
        };
        requestData.expertiseYear = +experience?.value;
        requestData.educationInfo = {
            id: education?.value?.id,
        };
        requestData.trafficSource = trafficSource;

        // Second step
        requestData.coverLetter = coverLetter?.value;
        requestData.mentorData = {
            fintechSubVerticalSet: interestedVerticals?.data.filter((d) => d.selected),
            technologies: interestedTechnologies?.data.filter((d) => d.selected),
            files: files.map((f) => f.file),
        };
        let res = null;
        try {
            res = await postRequest(MENTOR_APPLICATION.fetchApplyComplete(), keycloak, requestData);
        } catch (reason) {
            enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        if (!res || !res.data) {
            enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        switchTabHandler(true);
    };

    const validate = () => {
        const { coverLetter, interestedVerticals, interestedTechnologies, readAgreement } = form;
        return (
            coverLetter?.validated &&
            interestedVerticals?.validated &&
            interestedTechnologies?.validated &&
            readAgreement
        );
    };

    const isLoading = verticals.isLoading || technologies.isLoading;

    return isLoading ? (
        <LoadingSpinnerWithText style={loadingSpinnerWithTextGeneralTextStyle} />
    ) : (
        <CfoFormContainer>
            <Grid
                className={classes.formGridContainer}
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <CfoFormLabel
                    rootStyle={{ marginBottom: '20px' }}
                    label={t('mentor.form.label.aboutMentor')}
                    subText={t('mentor.form.helper.maxChars', { count: 400 })}
                />
                <CfoFormInput>
                    <InputTextArea
                        containerClass={classes.inputTextAreaWrapper}
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        onChange={(value, validated) => {
                            formChangeHandler({
                                type: constants.MENTOR_FORM_COVER_LETTER_UPDATE,
                                data: { value: value, validated: validated },
                            });
                        }}
                        type="text"
                        required
                        rowCount={5}
                        count={400}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                    />
                </CfoFormInput>
            </Grid>

            <Grid
                className={classes.formGridContainer}
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <CfoFormLabel rootStyle={{ marginBottom: '20px' }} label={t('mentor.form.label.interestedVerticals')} />
                <CfoFormInput>
                    <VericalUpdate
                        verticals={verticals.data}
                        changeCallback={(data, valid) =>
                            formChangeHandler({
                                type: constants.MENTOR_FORM_INTERESTED_VERTICAL_UPDATE,
                                data: {
                                    data: data,
                                    validated: valid,
                                },
                            })
                        }
                    />
                </CfoFormInput>
            </Grid>

            <Grid
                className={classes.formGridContainer}
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <CfoFormLabel
                    rootStyle={{ marginBottom: '20px' }}
                    label={t('mentor.form.label.interestedTechnologies')}
                />
                <CfoFormInput>
                    <TechnologyUpdate
                        isError={technologies.isError}
                        technologies={technologies.data}
                        changeCallback={(data, valid) =>
                            formChangeHandler({
                                type: constants.MENTOR_FORM_INTERESTED_TECHNOLOGY_UPDATE,
                                data: {
                                    data: data,
                                    validated: valid,
                                },
                            })
                        }
                    />
                </CfoFormInput>
            </Grid>
            <Grid
                className={classes.formGridContainer}
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <CfoFormLabel
                    rootStyle={{ marginBottom: '20px' }}
                    label={t('mentor.form.label.files')}
                    subText={t('mentor.form.helper.files')}
                />
                <CfoFormInput>
                    <FileUpload
                        uploadCallback={(uploadFiles) => {
                            formChangeHandler({
                                type: constants.MENTOR_FORM_FILE_UPDATE,
                                data: uploadFiles,
                            });
                        }}
                        acceptMimeTypes={DOCUMENT_MIME_TYPES}
                        videoLimit={1}
                        imageLimit={4}
                        documentLimit={0}
                        fileLimit={5}
                        initFiles={files}
                        hasNoHorizontalMargin
                        areDocuments
                        isPublic
                    />
                </CfoFormInput>
            </Grid>

            <Grid item container direction="row" justify="flex-end">
                <Grid item xs={12} lg={8}>
                    <div className={classes.legalDiv}>
                        <CfoSingleCheckbox
                            checked={form.readAgreement}
                            onChange={() => {
                                formChangeHandler({
                                    type: constants.MENTOR_FORM_AGREEMENTS,
                                });
                            }}
                            label={<Typography dangerouslySetInnerHTML={{ __html: t('startup.legal') }} />}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid item container direction="row-reverse" justify="space-between" alignItems="flex-start">
                <CfoFormInput xs={7} lg={8} className={classes.applyBtnWrapper}>
                    <Button
                        className={classes.applyBtn}
                        startIcon={!matches && <Check />}
                        disabled={!validate()}
                        variant="contained"
                        onClick={clickContinueHandler}
                    >
                        {t('common.form.label.sendApplication')}
                    </Button>
                </CfoFormInput>
                <CfoFormInput xs={5} lg={4} className={classes.backBtnWrapper}>
                    <Button
                        className={classes.backBtn}
                        startIcon={!matches && <KeyboardBackspace />}
                        variant="contained"
                        onClick={() => switchTabHandler()}
                    >
                        {matches ? t('common.form.label.previousStepShort') : t('common.form.label.previousStep')}
                    </Button>
                </CfoFormInput>
            </Grid>
            <LegalTextModal initialTab={clickedAgreement} open={openModal} onCancel={closeModalHandler} />
        </CfoFormContainer>
    );
};

export default CoverLetterAndInterestsForm;
