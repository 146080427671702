import { withStyles, makeStyles, Typography } from '@material-ui/core';
import {
    fontStyle,
    COLOR_SECONDARY_SHADE_01,
    COLOR_THEME_FILL_01,
    COLOR_THEME_FILL_03,
    COLOR_THEME_FILL_04,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    typeSelect: {
        marginTop: '28px',
        marginBottom: '22px',
        justifyContent: 'normal',
        gap: '8px',
    },
    contentContainer: {
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        '& > div': {
            '&:first-child': {
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '10px',
                    overflow: 'hidden',
                },
            },
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'none',
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                minHeight: '881px',
                '&:last-child': {
                    display: 'flex',
                    alignSelf: 'flex-start',
                    '& > div': {
                        alignItems: 'stretch',
                    },
                },
            },
        },
    },
    picker: {
        [theme.breakpoints.down('sm')]: {
            height: '704px',
            maxHeight: '704px',
        },
    },
    dialogRoot: {
        [theme.breakpoints.up('sm')]: {
            '& .MuiPaper-root': {
                width: '700px',
                height: (props) => props.height || '360px',
                minHeight: (props) => props.minHeight || 'auto',
                maxWidth: 'none',
            },
        },
    },
    confirmDialogContentSuccess: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: '0',
            padding: '0 71px !important',
        },
    },
    confirmDialogContentFailed: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: '0',
            padding: '0 95px !important',
        },
    },
    approvedConfirmationText: {
        '& > div:first-child': {
            backgroundColor: '#27ae60',
            color: COLOR_THEME_FILL_01,
            borderRadius: '50%',
            fontSize: '10px',
            width: '16px',
            height: '16px',
            textAlign: 'center',
        },
        '& > div:last-child': { color: '#27ae60', marginLeft: '6px' },
    },
    rejectedConfirmationText: {
        '& > div:first-child': {
            backgroundColor: COLOR_THEME_FILL_04,
            color: COLOR_THEME_FILL_03,
            borderRadius: '50%',
            fontSize: '10px',
            width: '16px',
            height: '16px',
            textAlign: 'center',
        },
        '& > div:last-child': { color: COLOR_THEME_FILL_03, marginLeft: '6px' },
    },
    contentDivStyle: {
        ...fontStyle,
        marginTop: '4px',
        marginRight: '20px',
        marginBottom: '10px',
        textAlign: 'start',
        fontWeight: '500',
    },
}));
export const DetailTitle = withStyles({
    root: {
        ...fontStyle,
        color: COLOR_SECONDARY_SHADE_01,
        textAlign: 'start',
        marginTop: '4px',
        marginRight: '20px',
        marginBottom: '8px',
    },
})(Typography);

export const DetailContent = withStyles({
    root: {
        ...fontStyle,
        marginTop: '4px',
        marginRight: '20px',
        marginBottom: '10px',
        textAlign: 'start',
        fontWeight: '500',
    },
})(Typography);

export default useStyles;
