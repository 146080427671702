import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ApplicationPageTemplate from 'fintech/components/applications/layout/pre-login/PageTemplate';
import membershipImage from 'fintech/static/images/kisiler-centered.jpg';
import PersonalInfoForm from 'fintech/components/applications/form/member/PersonalInfoForm';
import ApplicationResult from 'fintech/components/applications/form/common/ApplicationResult';
import { MemberApplicationActions } from 'fintech/store/actions/ActionTypes';

const TeamMemberApplicationPage = () => {
    const { t } = useTranslation('application');
    const { token } = useParams();
    const [tab, setTab] = useState(0);
    const dispatch = useDispatch();

    const switchTab = (next) => setTab((prev) => (next ? prev + 1 : prev - 1));

    const getSelectedTab = () => {
        switch (tab) {
            case 0:
                return <PersonalInfoForm inviteToken={token} switchTabHandler={switchTab} />;
            case 1: {
                dispatch({ type: MemberApplicationActions.RESET_MEMBER_APPLICATION_FORM });
                return <ApplicationResult title={t('member.resultTitle')} info={t('common.resultInfo')} />;
            }
        }
    };

    return (
        <ApplicationPageTemplate
            image={membershipImage}
            imageTitle={t('member.title')}
            options={[t('member.tabs.personalInfo'), t('common.tabs.result')]}
            currentIndex={tab}
        >
            {getSelectedTab()}
        </ApplicationPageTemplate>
    );
};

export default TeamMemberApplicationPage;
