export const arrayToMap = (arr, keyField) => {
    const map = {};
    if (arr) {
        arr.forEach((each) => {
            if (map[each[`${keyField}`]]) {
                map[each[`${keyField}`]].push(each);
            } else {
                map[each[`${keyField}`]] = [each];
            }
        });
    }
    return map;
};

export const findObject = (dataArr, key, value) => {
    if (dataArr && dataArr.length > 0) {
        return _.find(dataArr, [key, value]);
    } else {
        return null;
    }
};

export const includeObject = (dataArr, key, value) => {
    if (dataArr && dataArr.length > 0) {
        return dataArr.find((obj) => value.includes(obj[key]));
    } else {
        return null;
    }
};

export const filterObjectList = (dataArr, keyword, values) => {
    if (dataArr && dataArr.length > 0) {
        return _.filter(dataArr, function (o) {
            return values ? values.includes(o[keyword]) : null;
        });
    } else {
        return null;
    }
};

export const uniqueArrayForField = (dataArr, key) => {
    if (dataArr && dataArr.length > 0) {
        return _.uniqBy(dataArr, function (item) {
            return item[key];
        });
    } else {
        return null;
    }
};
export const indexOf = (dataArr, item) => {
    return _.indexOf(dataArr, item);
};
