import React, { useEffect, useState } from 'react';
import { withStyles, FormControlLabel, Switch } from '@material-ui/core';
import {
    COLOR_PRIMARY_BASE,
    COLOR_SECONDARY_SHADE_01,
    COLOR_THEME_FILL_01,
    fontStyle,
} from 'fintech/components/style/common';

const StyledSwitchLabel = withStyles({
    root: {
        margin: 0,
        minWidth: '90px',
        justifyContent: 'center',
    },
    label: {
        ...fontStyle,
        lineHeight: 1.71,
        letterSpacing: '0.13px',
    },
})(FormControlLabel);

const StyledSwitch = withStyles({
    root: {
        width: 32,
        height: 16,
        padding: 0,
        marginRight: '8px',
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: COLOR_SECONDARY_SHADE_01,
        '&$checked': {
            transform: 'translateX(16px)',
            color: COLOR_THEME_FILL_01,
            '& + $track': {
                opacity: 1,
                backgroundColor: COLOR_PRIMARY_BASE,
                borderColor: COLOR_PRIMARY_BASE,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${COLOR_SECONDARY_SHADE_01}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: COLOR_THEME_FILL_01,
    },
    checked: {},
})(Switch);

const SwitchLabel = (props) => {
    const {
        checked,
        labelChecked,
        labelUnchecked,
        requireConfirmation = false,
        labelStyle = {},
        switchStyle = {},
        onChange,
        useOverride,
        overrideValue,
    } = props;
    const [check, setChecked] = useState(checked);

    useEffect(() => {
        useOverride && setChecked(overrideValue);
    }, [overrideValue]);

    const onChangeHandler = () => {
        setChecked((prevChecked) => {
            !requireConfirmation && onChange(!prevChecked);
            return !prevChecked;
        });
    };

    const onChangeHandlerWithCallback = () => {
        onChange(!check, onChangeHandler);
    };

    return (
        <StyledSwitchLabel
            style={{ ...labelStyle }}
            control={
                <StyledSwitch
                    style={{ ...switchStyle }}
                    checked={check}
                    onChange={!requireConfirmation ? onChangeHandler : onChangeHandlerWithCallback}
                />
            }
            label={check ? labelChecked : labelUnchecked}
        />
    );
};

export default SwitchLabel;
