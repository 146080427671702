const BASE_URL = 'api';

const basePrefix = (url) => `${BASE_URL}/${url}`;

export const withPagination = (url, size, page) => `${url}?size=${size}&page=${page}`;

const ancmentBase = (url) => `${basePrefix('announcement')}/${url}`;
export const ANNOUNCEMENT = {
    LATEST: `${ancmentBase('latest')}`,
};

const userBase = (url) => `${basePrefix('user')}/${url}`;
const userManagement = (url) => `${userBase(`management/${url}`)}`;
const publicBase = (url) => `${basePrefix('public')}/${url}`;
const publicUserManagement = (url) => `${publicBase(`user/management/${url}`)}`;
const publicConstant = (url) => `${publicBase(`constant/${url}`)}`;
export const USER = {
    getProfileURL: (uuid) => `${userBase(uuid)}`,
    getVenturesURL: (uuid) => `${userBase(`${uuid}/venture`)}`,
    getVerticalsURL: (uuid) => `${userBase(`${uuid}/verticals`)}`,
    getTechnologiesURL: (uuid) => `${userBase(`${uuid}/technologies`)}`,
    getUserImage: (uuid) => `${userBase(`image/${uuid}`)}`,
    getFavorite: () => `${userBase('favorite/startup')}`,

    getFavoriteStartup: (startupUuid) => `${userBase(`favorite/startup/${startupUuid}`)}`,
    getUnfavoriteStartup: (startupUuid) => `${userBase(`unfavorite/startup/${startupUuid}`)}`,
    getPassword: () => `${userBase(`password`)}`,
    getUserOtpControl: () => `${userBase('otp')}`,

    getConsultantExpertisesURL: (consultantUuid) => `${basePrefix('consultant-info')}/${consultantUuid}/expertises/`,
    getAttempsURL: () => `${userBase('startups')}`,

    // User search
    getActiveUsersURL: () => `${userBase('person/active/count')}`,
    getFilterURL: () => `${userBase('person/filter')}`,
    getSearchURL: () => `${userBase('person/search')}`,

    // User search - Add user
    getRolesURL: () => userManagement('roles'),
    getFieldsOfExpertiseURL: () => userManagement('expertises'),

    // User search - Add user (public)
    getGendersURL: () => publicUserManagement('genders'),
    getCountriesURL: () => publicUserManagement('countries'),

    // User block
    getBlockURL: (userUuid) => `${userBase(`block/${userUuid}`)}`,
    getUnblockURL: (userUuid) => `${userBase(`unblock/${userUuid}`)}`,
    getIsBlockedURL: (userName) => `${userBase(`is-blocked/${userName}`)}`,
};

const startupBase = (url) => `${basePrefix('startup')}/${url}`;
export const STARTUP = {
    getProfileURL: (uuid) => `${basePrefix('startup')}/${uuid}`,
    getTeamURL: (uuid) => `${basePrefix('startup')}/${uuid}/team`,
    getVerticalsURL: (uuid) => `${basePrefix('startup')}/${uuid}/verticals`,
    getTechnologiesURL: (uuid) => `${basePrefix('startup')}/${uuid}/technology`,
    getStagesURL: (uuid) => `${basePrefix('startup')}/${uuid}/stage`,
    getFinancialURL: (uuid) => `${basePrefix('startup')}/${uuid}/finance`,
    getIncomeModelsURL: (uuid) => `${basePrefix('startup')}/${uuid}/finance-income-models`,
    getMentorshipsURL: (uuid) => `${basePrefix('startup')}/mentors/${uuid}`,
    getUsersShipsURL: (uuid) => `${basePrefix('startup')}/users/${uuid}`,
    getCountryURL: (uuid) => `${basePrefix('startup')}/${uuid}/country`,
    getCitiesURL: (uuid) => `${basePrefix('startup')}/${uuid}/city`,
    getInvestmentsURL: (uuid) => `${basePrefix('startup')}/${uuid}/investment`,
    getSimilarStartups: (uuid) => `${basePrefix('similarity/startup')}/${uuid}`,
    getFilterURL: () => `${basePrefix('startup')}/filter`,
    getSearchURL: () => `${basePrefix('startup')}/search`,
    getStartupActiveCountURL: () => `${basePrefix('startup')}/active/count`,
    getDeactiveURL: (uuid) => `${basePrefix('startup')}/management/deactivate/${uuid}`,
    getMentorListURL: (uuid) => `${basePrefix('startup')}/management/mentor/${uuid}`,
    getRepresantivePutURL: (uuid, representativeUUID) =>
        `${basePrefix('startup')}/management/representative/${uuid}/${representativeUUID}`,

    getStartupsAutoCompleteURL: () => `${basePrefix('startup')}/autocompletebox`,

    // Startup block
    getBlockURL: (userUuid) => `${startupBase(`block/${userUuid}`)}`,
    getUnblockURL: (userUuid) => `${startupBase(`unblock/${userUuid}`)}`,
    getIsBlockedURL: (userUuid) => `${startupBase(`is-blocked/${userUuid}`)}`,
};
export const IDEA = {
    DETAILS: `${basePrefix('idea/details')}`,
};
export const CURRENCY = {
    getCurrenciesURL: () => `${basePrefix('currency')}`,
};

const investorBase = (url) => `${basePrefix('investor')}/${url}`;
export const INVESTOR = {
    getInvestorsAutocompleteURL: () => `${investorBase('autocompletebox')}`,

    //establishment
    getInvestor: (uuid) => `${basePrefix('investor')}/${uuid}`,
    getInvestorTypes: (uuid) => investorBase(`${uuid}/investor-types`),
    getInvestorGroups: (uuid) => investorBase(`${uuid}/informal-investor-groups`),
    getInvestorVerticals: (uuid) => investorBase(`${uuid}/verticals`),
    getInvestorInvestmentAreas: (uuid) => investorBase(`${uuid}/investment-areas`),
    getInvestorStages: (uuid) => investorBase(`${uuid}/stages`),
    getInvestorAboutInfo: (uuid) => investorBase(`${uuid}/about-info`),
    getInvestorTeamData: (uuid) => investorBase(`${uuid}/team`),
    getInvestorInvestmentPreferences: (uuid) => investorBase(`${uuid}/investor-preferences`),
    getInvestorManagementTypes: (uuid) => investorBase(`${uuid}/management-types`),
    getInvestorInvestmentAmounts: (uuid) => investorBase(`${uuid}/investment-amounts`),
    getInvestorCompanyInvestmentsAmounts: (uuid) => investorBase(`${uuid}/company-investment-amounts`),
    getInvestorAllInvestments: (uuid) => investorBase(`${uuid}/investment`),

    // Investor search
    getActiveUsersURL: () => `${investorBase('active-investors-count')}`,
    getFilterURL: () => `${investorBase('filter')}`,
    getSearchURL: () => `${investorBase('search')}`,
    getUsersComboURL: (uuid) => `${investorBase(`${uuid}/investor-users`)}`,
    getTeamComboURL: (uuid) => `${basePrefix('investor')}/${uuid}/team-combo`,
    getInvestorsRelatedToUser: (uuid) => `${basePrefix('investor')}/user/${uuid}`,

    // Investor block
    getBlockURL: (userUuid) => `${investorBase(`block/${userUuid}`)}`,
    getUnblockURL: (userUuid) => `${investorBase(`unblock/${userUuid}`)}`,
    getIsBlockedURL: (userUuid) => `${investorBase(`is-blocked/${userUuid}`)}`,
};

const inviteSearchBase = (url) => `${basePrefix('user-invite-search')}/${url}`;
export const INVITES_SEARCH = {
    getSearchURL: () => `${inviteSearchBase('')}`,
};

const inviteBase = (url) => `${basePrefix('user-invite')}/${url}`;
export const INVITES = {
    sendInviteMailURL: (inviteId) => `${inviteBase(`send-invite-mail/${inviteId}`)}`,
    cancelInviteURL: (inviteId) => `${inviteBase(`cancel-invite/${inviteId}`)}`,
};

export const NOTIFICATIONS = {
    getNotifications: () => `${basePrefix('notification')}`,
    getReadNotification: (notificationID) => `${basePrefix('notification')}/${notificationID}/read`,
    getReadAllNotifications: () => `${basePrefix('notification')}/read`,
    getBadgeCount: () => `${basePrefix('notification')}/badge-count`,
};

const mentorsBase = (url) => `${basePrefix('user/mentor')}/${url}`;
export const MENTOR = {
    // Mentor search
    getActiveMentorsURL: () => `${publicBase('active-mentors-count')}`,
    getSearchURL: () => `${mentorsBase('search')}`,
};

const supportProgramBase = (url) => `${basePrefix('support-program')}/${url}`;
const supportProgramPublicBase = (url) => `${publicBase('support-program')}/${url}`;
export const SUPPORT_PROGRAM = {
    // Support program GET
    getSupportProgramList: () => supportProgramPublicBase('list'),
    getSupportProgramDetail: (id) => supportProgramPublicBase(id),
    getSupportProgramApplicationForm: (id) => supportProgramPublicBase(`application-form/${id}`),
    getEducationInfos: () => supportProgramPublicBase('education-info'),
    getSourceTypes: (sourceType) => supportProgramPublicBase(`sources?sourceType=${sourceType}`),
    getRepresentativeInfo: (uuid) => supportProgramBase(`represant-info/${uuid}`),
};

const consultantBase = (url) => `${basePrefix('user/consultant')}/${url}`;
const consultantFaqBase = (url) => `${basePrefix('consultant-frequently-questions')}/${url}`;

export const CONSULTANT = {
    // Consultant search
    getSearchURL: () => `${consultantBase('search')}`,
    getFaqSearchUrl: () => `${consultantFaqBase('search')}`,
};

export const MENTOR_PRE_LOGIN = {
    // Mentor search
    getActiveMentorsCountURL: () => `${publicBase('active-mentors-count')}`,
    getMentors: (searchTerm, size, page) =>
        `${publicBase('search/mentor?searchTerm=' + searchTerm + '&size=' + size + '&page=' + page)}`,
};

export const CONSULTANT_PRE_LOGIN = {
    // Mentor search
    getActiveConsultantCountURL: () => `${publicBase('active-consultant-count')}`,
    getConsultants: (searchTerm, size, page) =>
        `${publicBase('search/consultant?searchTerm=' + searchTerm + '&size=' + size + '&page=' + page)}`,
};

export const SUPPORT_PROGRAMS_PRE_LOGIN = {
    // Mentor search
    getSupportProgramList: () => `${publicBase('support-program/list')}`,
};

export const USER_APPLICATION = {
    fetchApplicationsUrlByType(type, page = 0, size = 10) {
        return `${basePrefix('user-application')}/${type}/search?page=${page}&size=${size}`;
    },
};

export const MENTOR_APPLICATION = {
    fetchApplyFirstStepPreRecaptcha() {
        return `${publicBase('user/apply-first-step-pre-recaptcha')}`;
    },
    fetchApplyFirstStepPostRecaptcha() {
        return `${publicBase('user/apply-first-step-post-recaptcha')}`;
    },
    fetchApplyComplete() {
        return `${publicBase('user/apply-complete')}`;
    },
};

export const reCaptchaVerificationURL = (secret, response) =>
    publicBase(`recaptcha/siteverify?secret=${secret}&response=${response}`);

export const PUBLIC_RESOURCES = {
    getCountries: () => `${publicConstant('country')}`,
    getGenders: () => `${publicUserManagement('genders')}`,
    getEducationInfos: () => `${publicConstant('education-info')}`,
    getVerticals: () => `${publicConstant('vertical')}`,
    getTechnologies: () => `${publicConstant('technology')}`,
    getIsMentorApplicationActive: () => `${publicBase('is-mentor-application-active')}`,
};

export const keycloakUserInfo = () => basePrefix('keycloak-user/name');

export const CHAT = {
    sendMessage: () => `${basePrefix('chat/message-send')}`,
    getMessagesByUser: () => `${basePrefix('chat/messages')}`,
    getMessagesByUserAndClient: (clientUuid) => `${basePrefix(`chat/messages/${clientUuid}`)}`,
    getMessageEntriesByUser: () => `${basePrefix('chat/message-entries')}`,
    getNotSeenMessageEntriesCountsByUser: () => `${basePrefix('chat/not-seen-message-entries-counts')}`,
    getMessagesSeenByUserAndClient: (clientUuid) => `${basePrefix(`chat/messages-seen/${clientUuid}`)}`,
    getContacts: () => `${basePrefix(`chat/get-contacts`)}`,
};
