import React from 'react';
import { Grid } from '@material-ui/core';
import IntroDuoGrid from 'fintech/components/profile/common/DuoGrid';

const IntroDetails = (props) => {
    const { duoItems } = props;

    return (
        <Grid container direction="column" alignItems="stretch" style={{ padding: '20px 20px 10px 20px' }}>
            <IntroDuoGrid items={duoItems} />
        </Grid>
    );
};

export default IntroDetails;
