import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import LanguagePanel from 'fintech/components/lang/LanguagePanel';
import { fetchTotalNumberOfUnreadNotifications } from 'fintech/store/actions/notifications';
import IconsMultipleChat from 'fintech/icons/IconsMultipleChat';
import IconsMultipleNotifications from 'fintech/icons/IconsMultipleNotifications';
import IconsMultipleLogout from 'fintech/icons/IconsMultipleLogout';
import LogoFinTechHub from 'fintech/static/images/Logo-FinTechHub-Erkek.svg';
import { useKeycloak } from '@react-keycloak/web';
import { doLogout } from 'fintech/utils/Auth';
import { Badge, IconButton, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NotificationBox from 'fintech/components/notificationBox';
import { mobileMediaQuery } from 'fintech/components/style/common';
import { useClickAway } from 'ahooks';
import { useHistory } from 'react-router';
import { HOME, CHAT, SETTINGS } from 'fintech/routes';
import { IconsMultipleSettings } from 'fintech/icons';
import {getRequest} from "fintech/api/api";
import { APIS } from 'fintech/constants';
import {useSnackbar} from "notistack";

const Navbar = ({ selectedMenuTitle }) => {
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        isNotificationBoxDisplayed: false,
        isMobileNBoxOpen: false,
    });
    const isWeb = useMediaQuery(mobileMediaQuery);

    const numberOfUnreadNotifications = useSelector((state) => state.notifications.totalNumberOfUnreadNotifications);
    const history = useHistory();
    /*const unreadMessageCount = useSelector(({ inbox }) =>
        Object.values(inbox.rooms).reduce((count, room) => count + (room.unreadMessageCount ? 1 : 0), 0)
    );*/
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);

    const { enqueueSnackbar } = useSnackbar();
    const showErrorMessage = (message) => enqueueSnackbar(message, { variant: 'error' });

    // Notification Controller foreach 30 sec, can be moved to notificationBox/hooks folder
    useEffect(() => {
        dispatch(fetchTotalNumberOfUnreadNotifications(keycloak));
        const interval = setInterval(() => {
            dispatch(fetchTotalNumberOfUnreadNotifications(keycloak));
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getRequest(APIS.CHAT.getNotSeenMessageEntriesCountsByUser(), keycloak)
            .then((result) => {
                setUnreadMessageCount(result.data);
            })
            .catch((error) => showErrorMessage(error.message));
    }, []);

    // custom badge color
    const useStyles = makeStyles(() => ({
        badge: {
            backgroundColor: '#850e0e',
            color: '#fff',
        },
        inboxButton: {
            padding: 0,
        },
        rightContainerElement: {
            marginRight: 40,
            cursor: 'pointer',
        },
    }));

    const onClickOutsideNotificationBox = () => {
        if (!isWeb) {
            return;
        }

        if (state.isNotificationBoxDisplayed) {
            setState((prevState) => ({
                ...prevState,
                isNotificationBoxDisplayed: false,
            }));
        }
    };

    const classes = useStyles();

    const nRef = useRef();
    const onOpen = () => {
        setState((prevState) => ({
            ...prevState,
            isMobileNBoxOpen: true,
        }));
    };

    // for mobile bug
    useClickAway(() => {
        if (isWeb) {
            return;
        }

        if (state.isNotificationBoxDisplayed && state.isMobileNBoxOpen) {
            setState((prevState) => ({
                ...prevState,
                isNotificationBoxDisplayed: false,
                isMobileNBoxOpen: false,
            }));
        }
    }, nRef);

    const handleSettingsButtonClick = () => {
        history.push(SETTINGS);
    };

    const ChatButton = () => (
        <Badge classes={{ badge: classes.badge }} badgeContent={unreadMessageCount}>
            <IconButton
                className={classes.inboxButton}
                onClick={() => {
                    history.push(CHAT);
                }}
            >
                <IconsMultipleChat />
            </IconButton>
        </Badge>
    );

    return (
        <div className={'full-width '}>
            <div className={'web d-flex'}>
                <div className={'full-width poppin-600-24px margin-left-16'}>{selectedMenuTitle}</div>
                <div className={'webChildRightContainer'}>
                    <div className={classes.rightContainerElement}>
                        <LanguagePanel />
                    </div>
                    <div className={classes.rightContainerElement} onClick={() => handleSettingsButtonClick()}>
                        <IconsMultipleSettings />
                    </div>
                    <div className={classes.rightContainerElement}>
                        <ChatButton />
                    </div>
                    <div className={classes.rightContainerElement}>
                        <Badge
                            classes={{ badge: classes.badge }}
                            badgeContent={numberOfUnreadNotifications}
                            onClick={() =>
                                setState((prevState) => ({
                                    ...prevState,
                                    isNotificationBoxDisplayed: !prevState.isNotificationBoxDisplayed,
                                }))
                            }
                        >
                            <IconsMultipleNotifications />
                        </Badge>

                        {state.isNotificationBoxDisplayed && (
                            <NotificationBox onClickOutside={onClickOutsideNotificationBox} />
                        )}
                    </div>
                    <div className={classes.rightContainerElement} onClick={() => doLogout(keycloak)}>
                        <IconsMultipleLogout />
                    </div>
                </div>
            </div>

            <div className={'mobile mobileTopbar'}>
                <div className={'fintechLogoDiv'}>
                    {<img src={LogoFinTechHub} width="220px" onClick={() => history.push(HOME)} />}
                </div>
                <div className={'mobileChildRightContainer'}>
                    <div className={'mobileTopbarRightIcon'}>
                        <ChatButton />
                    </div>
                    <div className={'mobileTopbarRightIcon'}>
                        <Badge
                            classes={{ badge: classes.badge }}
                            badgeContent={numberOfUnreadNotifications}
                            onClick={() =>
                                setState((prevState) => ({
                                    ...prevState,
                                    isNotificationBoxDisplayed: !prevState.isNotificationBoxDisplayed,
                                }))
                            }
                        >
                            <IconsMultipleNotifications />
                        </Badge>

                        {state.isNotificationBoxDisplayed && (
                            <div ref={nRef}>
                                <NotificationBox onOpen={onOpen} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Navbar);
