import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_BASE, COLOR_SECONDARY_SHADE_01, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '210px',
        marginRight: '16px',
        marginBottom: '8px',
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
            maxWidth: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    formLabel: {
        ...fontStyle,
        fontWeight: 'normal',
        fontSize: '16px',
        wordBreak: 'break-word',
    },
    formSubtext: {
        ...fontStyle,
        fontWeight: 'normal',
        fontSize: '16px',
        color: COLOR_SECONDARY_SHADE_01,
        wordBreak: 'break-word',
    },
    formIsOptional: {
        ...fontStyle,
        fontSize: '16px',
        color: COLOR_PRIMARY_BASE,
    },
}));

export default useStyles;
