import React from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './index.style';
import AnnouncementHeader from './Header';
import AnnouncementMedia from './Media';
import { Typography } from '@material-ui/core';
import Attachment from 'fintech/components/ui/attachment';
import { withTranslation } from 'react-i18next';
import { VIDEO_MIMES_START, IMAGE_MIMES_START } from 'fintech/components/util/FileConstants';
import { getVideoUrl, getPublicFileUrl } from 'fintech/api/fileService';

const Announcement = (props) => {
    const classes = useStyles();
    const { t, date, title, content, video, medias, attachments } = props;

    let allMedias = [];
    let attachmentFiles = [];
    if (attachments && attachments.length > 0) {
        for (const att of attachments) {
            if (att.mimeType && att.mimeType.startsWith(VIDEO_MIMES_START)) {
                att.url = getVideoUrl(att.uuid);
                allMedias.push(att);
            } else {
                att.url = getPublicFileUrl(att.uuid);
                attachmentFiles.push(att);
            }
        }
    }

    if (medias && medias.length > 0) {
        for (const media of medias) {
            if (media.mimeType && media.mimeType.startsWith(IMAGE_MIMES_START)) {
                media.url = getPublicFileUrl(media.uuid);
                allMedias.push(media);
            }
        }
    }

    const showMedias = allMedias.length > 0;
    const showAttachments = attachmentFiles.length > 0;

    return (
        <Grid className={classes.AnnGrid} container direction="column">
            <AnnouncementHeader date={date} title={title} video={video} />
            <Grid className={classes.AnnContent} item>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </Grid>
            {showMedias && <AnnouncementMedia medias={allMedias} />}
            {showAttachments && (
                <Attachment attachments={attachmentFiles} header={t('announcement.attachmentHeader')} />
            )}
        </Grid>
    );
};

export default withTranslation('announcement')(Announcement);
