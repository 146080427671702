import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_BASE, COLOR_SECONDARY_SHADE_01 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    SocialMediaIcon: {
        height: '24px',
        width: '24px',
        marginLeft: '24px',
        color: COLOR_SECONDARY_SHADE_01,
        cursor: 'pointer',
    },
    ActiveSocialMediaIcon: {
        height: '24px',
        width: '24px',
        marginLeft: '24px',
        color: COLOR_PRIMARY_BASE,
    },
});

export default useStyles;
