import { DialogActions, makeStyles, withStyles } from '@material-ui/core';
import { buttonStyle, buttonStyleColorAlt, COLOR_SECONDARY_BASE, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    ConfirmDialogRoot: {
        textAlign: 'center',
    },
    ConfirmDialogTitle: {
        paddingTop: '32px',
        position: 'relative',
    },
    ConfirmDialogTitleTypo: {
        ...fontStyle,
        fontSize: '24px',
        fontWeight: 600,
        marginTop: '20px',
        color: COLOR_SECONDARY_BASE,
    },
    ConfirmDialogContent: {
        paddingTop: 0,
        marginBottom: '84px',
    },
    ConfirmDialogContentTypo: {
        ...fontStyle,
        textAlign: 'center',
    },
    CancelBtn: {
        ...buttonStyleColorAlt,
        padding: '14px 22px',
        margin: '0 12px',
    },
    OkBtn: {
        ...buttonStyle,
        padding: '14px 22px',
        margin: '0 12px',
    },
});

export const CenteredDialogActions = withStyles({
    root: {
        justifyContent: 'center',
        marginBottom: '24px',
    },
})(DialogActions);

export default useStyles;
