import { addDays, format } from 'date-fns';
import { SupportProgramActions } from 'fintech/store/actions/ActionTypes';
import { ISO_FORMAT } from 'fintech/utils/DateUtils';

const initialState = {
    programs: {
        current: [],
        past: [],
        isLoading: true,
        isError: false,
    },
    viewed: {
        program: null,
        isLoading: true,
        isError: false,
    },
    form: {
        programForm: {
            id: null,
            title: null,
            description: null,
            startDate: format(new Date(), ISO_FORMAT),
            endDate: format(addDays(new Date(), 1), ISO_FORMAT),
            lastApplicationDate: format(new Date(), ISO_FORMAT),
            committeeLastReviewDate: format(new Date(), ISO_FORMAT),
        },
    },
};

const supportProgram = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case SupportProgramActions.SET_SUPPORT_PROGRAMS:
            const { currentPrograms, pastPrograms } = action.data;
            return {
                ...state,
                programs: {
                    ...state.programs,
                    current: currentPrograms,
                    past: pastPrograms,
                    isLoading: false,
                },
            };
        case SupportProgramActions.GET_SUPPORT_PROGRAMS:
            return {
                ...state,
                programs: {
                    ...state.programs,
                    isLoading: true,
                    isError: false,
                },
            };
        case SupportProgramActions.SET_SUPPORT_PROGRAMS_ERROR:
            return {
                ...state,
                programs: {
                    ...state.programs,
                    isLoading: false,
                    isError: true,
                },
            };
        case SupportProgramActions.SET_VIEWED_SUPPORT_PROGRAM:
            return {
                ...state,
                viewed: {
                    ...state.viewed,
                    program: action.data,
                    isLoading: false,
                },
            };
        case SupportProgramActions.GET_VIEWED_SUPPORT_PROGRAM:
            return {
                ...state,
                viewed: {
                    program: null,
                    isLoading: true,
                    isError: false,
                },
            };
        case SupportProgramActions.SET_VIEWED_SUPPORT_PROGRAM_ERROR:
            return {
                ...state,
                viewed: {
                    ...state.viewed,
                    isLoading: false,
                    isError: true,
                },
            };
        default:
            return state;
    }
};

export default supportProgram;
