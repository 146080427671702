import React, {useEffect, useState} from 'react';

import { makeStartChatPanelStyles } from "./StartChatPanel.style";
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import {useKeycloak} from "@react-keycloak/web";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {makeSearch} from "fintech/store/actions/userSearch";
import {withPagination} from "fintech/constants/apis";
import {getRequest, postRequest} from "fintech/api/api";
import {MentorSearchActions} from "fintech/store/actions/ActionTypes";
import {setFilteredNumber} from "fintech/store/actions/mentorSearch";
import { APIS } from 'fintech/constants';
import SingleSelect from "fintech/components/ui/form/select/SingleSelect";


const StartChatPanel = ({ onContactSelect }) => {
    const startChatPanelStyles = makeStartChatPanelStyles();
    const { keycloak } = useKeycloak();
    const selectedLanguage = getSystemLanguage();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        getRequest(APIS.CHAT.getContacts(), keycloak).then((response) => {
            const comboboxDtoList = [];
            response.data.map((startupUserDto) => {
                const comboboxDto = {
                    id: startupUserDto.uuid,
                    data: `${startupUserDto.name} ${startupUserDto.surname}`,
                }
                comboboxDtoList.push(comboboxDto);
            })
            setContacts(comboboxDtoList);
        })
    }, []);

    const handleSetSelectedUser = (value) => {
        onContactSelect(value.id);
    }

    return (
        <SingleSelect
            label={t('profile.user.chat.startChat')}
            dontValidate={true}
            canBeEmpty={false}
            options={contacts}
            initValue={null}
            onChange={handleSetSelectedUser}
        />
    )
};

export default StartChatPanel;
