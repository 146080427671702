import * as React from 'react';

function SvgIconsSingleExcel(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#3F5162" fillRule="nonzero">
                    <path d="M18.75 21c.532 0 .964-.432.964-.964V8.464c0-.532-.311-1.275-.683-1.647l-3.134-3.134C15.526 3.311 14.782 3 14.25 3h-9c-.532 0-.964.432-.964.964v16.072c0 .532.432.964.964.964h13.5zm-.321-1.286H5.57V4.286h7.715v4.178c0 .533.432.965.964.965h4.179v10.285zm-.08-11.571H14.57V4.366c.171.06.342.15.412.221l3.144 3.144c.07.07.161.241.221.412zm-6.932 10.286v-1.065h-.753l1.035-1.617c.12-.191.18-.342.21-.342h.02c.01.04.03.07.05.1.041.081.101.141.172.242l1.074 1.617h-.763v1.065h2.923v-1.065h-.683l-1.929-2.742 1.96-2.833h.672v-1.075h-2.802v1.075h.743l-1.035 1.597c-.12.191-.21.342-.21.332h-.02c-.01-.04-.03-.07-.05-.1-.041-.071-.091-.141-.172-.232l-1.064-1.597h.763v-1.075H8.645v1.075h.683l1.899 2.732-1.95 2.843h-.682v1.065h2.822z" />
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleExcel;
