import * as React from 'react';

function SvgIconsMultipleMentor(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#D9DCE0"
                    d="M13.5 20.625C6.75 18.75 6.75 27 0 22.5V12c6.75 4.5 6.75-3.75 13.5-1.875v10.5zM10.5 24h12v-1.875h-12zm7.875-15l-1.875.75L14.625 9V7.5h3.75z"
                />
                <path
                    fill="#D9DCE0"
                    d="M14.25 10.125c-3 1.875-4.875 2.625-4.875 4.5v6a1.5 1.5 0 001.5 1.5h11.25a1.5 1.5 0 001.5-1.5v-6c0-1.875-1.875-2.625-4.875-4.5h-4.5z"
                />
                <path
                    fill="#3F5162"
                    d="M13.5 3.375a.375.375 0 00-.375.375v1.125a.375.375 0 00.75 0V3.75a.375.375 0 00-.375-.375m6 0a.375.375 0 00-.375.375v1.125a.375.375 0 00.75 0V3.75a.375.375 0 00-.375-.375"
                />
                <path
                    fill="#E8E6E4"
                    d="M19.5 3.75v.75c0 1.125-.52 2.166-1.125 3.375-.375.75-1.875.75-1.875.75s-1.5 0-1.875-.75C14.021 6.665 13.5 5.625 13.5 4.5v-.75c.75 0 1.125-1.875 1.125-1.875S15.375 3 16.5 3h1.875c.75 0 1.125.75 1.125.75"
                />
                <path
                    fill="#3F5162"
                    d="M13.5 2.625V3.75c.75 0 1.125-1.875 1.125-1.875S15.375 3 16.5 3h1.875c.75 0 1.125.75 1.125.75V2.625c0-1.5-1.125-2.625-3-2.625-.75 0-1.125.375-1.125.375s-1.875 0-1.875 2.25m6.752 17.073c-.08 0-.159-.009-.238-.026l-6.607-1.684a.375.375 0 11.186-.726l6.592 1.68a.364.364 0 00.3-.078.374.374 0 00.14-.292V15a.375.375 0 01.75 0v3.572c0 .342-.152.66-.418.875-.202.164-.45.25-.705.25"
                />
                <path
                    fill="#3F5162"
                    d="M21 22.5c-1.52 0-8.018-2.331-8.516-2.511a1.131 1.131 0 01-.859-1.093V15a.375.375 0 01.75 0v3.896c0 .175.124.329.295.366l.048.014c1.904.688 7.105 2.474 8.282 2.474h1.125c.62 0 1.125-.505 1.125-1.125v-6c0-1.35-1.169-2.051-3.29-3.324-.436-.262-.906-.544-1.409-.858a.376.376 0 01.398-.636c.497.311.965.591 1.397.85 2.2 1.32 3.654 2.193 3.654 3.968v6a1.877 1.877 0 01-1.875 1.875H21z"
                />
                <path
                    fill="#3F5162"
                    d="M16.5 22.5h-5.625A1.877 1.877 0 019 20.625v-6c0-1.775 1.454-2.647 3.654-3.967.432-.26.9-.54 1.397-.851a.375.375 0 11.397.636c-.502.314-.972.596-1.408.858-2.121 1.273-3.29 1.974-3.29 3.324v6c0 .62.505 1.125 1.125 1.125H16.5a.375.375 0 010 .75"
                />
                <path fill="#3F5162" d="M16.5 13.125l-1.5-1.5-1.5-1.5L14.625 9l1.875.75L18.375 9l1.125 1.125z" />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleMentor;
