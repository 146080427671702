import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_BASE,
    COLOR_SECONDARY_SHADE_01,
    COLOR_THEME_FILL_01,
    fontStyle,
} from 'fintech/components/style/common';

const cardRootCommon = {
    borderRadius: '8px',
    padding: '4px',
    backgroundColor: '#ffffff',
    maxWidth: '400px',
};
const useStyles = makeStyles({
    CardRoot: {
        ...cardRootCommon,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    CardRootAnonymous: {
        ...cardRootCommon,
    },
    CardHeaderBg: {
        backgroundColor: ({ headerColor = COLOR_PRIMARY_SHADE_02 }) => headerColor,
        borderRadius: '6px 6px 0 0',
        height: '68px',
    },
    CardAvatar: {
        marginTop: '4px',
        height: '96px',
        width: '96px',
        margin: 'auto',
    },
    MentorNameTypo: {
        ...fontStyle,
        color: COLOR_SECONDARY_BASE,
        fontSize: '16px',
        fontWeight: 'bold',
    },
    CardContentContainer: {
        marginTop: '42px',
        textAlign: 'center',
    },
    ProfileBtn: {
        margin: 'auto',
        color: ({ btnColor = COLOR_THEME_FILL_01 }) => btnColor,
        textTransform: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    VerticalsTitle: {
        ...fontStyle,
        color: COLOR_SECONDARY_SHADE_01,
        marginBottom: '12px',
    },
});

export default useStyles;
