import { getRequest, putRequest } from 'fintech/api/api';
import { APIS } from 'fintech/constants';
import { UserActions } from 'fintech/store/actions/ActionTypes';
import { ROLE_COUNSELOR } from '../../components/util/RoleConstants';

const setUser = (user) => ({
    type: UserActions.SET_USER,
    user: user,
});

const setVisitCount = (count) => ({
    type: UserActions.SET_VISIT_COUNT,
    count,
});

const setUserImage = (imageFile) => ({
    type: UserActions.SET_USER_IMAGE,
    userImage: imageFile,
});

const setLoading = () => ({ type: UserActions.SET_USER_LOADING });

const setError = () => ({ type: UserActions.SET_USER_ERROR });

export const fetchUser = (keycloak, id, isOwnProfile, callback) => {
    return (dispatch) => {
        dispatch(setLoading());
        getRequest(APIS.USER.getProfileURL(id), keycloak)
            .then((data) => {
                const isConsultant = data.role.some((cur) => cur.roleUniqueId === ROLE_COUNSELOR);
                data.isConsultant = isConsultant;
                data.isConsultantFromUsersPerspective = isConsultant && !isOwnProfile;
                data.isOwnProfile = isOwnProfile;

                dispatch(setUser(data));
                if (callback) callback();
            })
            .catch(() => {
                dispatch(setError());
            });
    };
};

export const updatePassword = (keycloak, successCallback, errorCallback, passwordData) => {
    return () => {
        putRequest(APIS.USER.getPassword(), keycloak, passwordData)
            .then(() => {
                successCallback();
            })
            .catch((error) => {
                errorCallback(error);
            });
    };
};

export const updateVisitCount = (count) => {
    return (dispatch) => {
        dispatch(setVisitCount(count));
    };
};

export const fetchUserImage = (keycloak, id) => {
    return (dispatch) => {
        getRequest(APIS.USER.getUserImage(id), keycloak).then((data) => dispatch(setUserImage(data)));
    };
};

// Actions for ventures
const setUserVentures = (ventures) => ({
    type: UserActions.SET_USER_VENTURES,
    ventures: ventures,
});

const setUserVenturesLoading = () => ({ type: UserActions.SET_USER_VENTURES_LOADING });

const setUserVenturesLoadError = () => ({ type: UserActions.SET_USER_VENTURES_LOAD_ERROR });

export const fetchUserVentures = (keycloak, id) => {
    return (dispatch) => {
        dispatch(setUserVenturesLoading());
        getRequest(APIS.USER.getVenturesURL(id), keycloak)
            .then((data) => dispatch(setUserVentures(data)))
            .catch(() => {
                dispatch(setUserVenturesLoadError());
            });
    };
};

// Actions for mentorships
const setUserMentorships = (mentorships) => ({
    type: UserActions.SET_USER_MENTORSHIPS,
    mentorships: mentorships,
});

const setUserMentorshipsLoading = () => ({ type: UserActions.SET_USER_MENTORSHIPS_LOADING });

const setUserMentorshipsLoadError = () => ({ type: UserActions.SET_USER_MENTORSHIPS_LOAD_ERROR });

export const fetchUserMentorships = (keycloak, id) => {
    return (dispatch) => {
        dispatch(setUserMentorshipsLoading());
        getRequest(APIS.STARTUP.getMentorshipsURL(id), keycloak)
            .then((data) => {
                dispatch(setUserMentorships(data));
            })
            .catch(() => {
                dispatch(setUserMentorshipsLoadError());
            });
    };
};

//Actions for uersship
const setUserUsersships = (usersship) => ({
    type: UserActions.SET_USER_USERSSHIPS,
    usersship: usersship,
});

const setUserUsersshipsLoading = () => ({ type: UserActions.SET_USER_USERSSHIPS_LOADING });

const setUserUsersshipsLoadError = () => ({ type: UserActions.SET_USER_USERSSHIPS_LOAD_ERROR });

export const fetchUserUsersships = (keycloak, id) => {
    return (dispatch) => {
        dispatch(setUserUsersshipsLoading());
        getRequest(APIS.STARTUP.getUsersShipsURL(id), keycloak)
            .then((data) => dispatch(setUserUsersships(data)))
            .catch(() => {
                dispatch(setUserUsersshipsLoadError());
            });
    };
};

//Actions for related investors
export const fetchUserRelatedInvestors = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setUserRelatedInvestorLoading());
        getRequest(APIS.INVESTOR.getInvestorsRelatedToUser(uuid), keycloak)
            .then((data) => dispatch(setRelatedInvestors(data)))
            .catch(() => {
                dispatch(setUserRelatedInvestorsLoadError());
            });
    };
};

const setRelatedInvestors = (relatedInvestors) => ({
    type: UserActions.SET_USER_RELATED_INVESTORS,
    relatedInvestors: relatedInvestors,
});

const setUserRelatedInvestorLoading = () => ({ type: UserActions.SET_USER_RELATED_INVESTORS_LOADING });

const setUserRelatedInvestorsLoadError = () => ({ type: UserActions.SET_USER_RELATED_INVESTORS_LOAD_ERROR });

// Actions for verticals
const setVerticals = (uuid, verticals) => ({
    type: UserActions.SET_USER_VERTICALS,
    userUuid: uuid,
    verticalData: verticals,
});

const setVerticalsLoading = () => ({ type: UserActions.SET_USER_VERTICALS_LOADING });

const setVerticalsError = () => ({ type: UserActions.SET_USER_VERTICALS_LOAD_ERROR });

export const fetchUserVerticalsData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setVerticalsLoading());
        getRequest(APIS.USER.getVerticalsURL(uuid), keycloak)
            .then((data) => dispatch(setVerticals(uuid, data)))
            .catch(() => {
                dispatch(setVerticalsError());
            });
    };
};

// Actions for technologies
const setTechnologies = (uuid, technologies) => ({
    type: UserActions.SET_USER_TECHNOLOGIES,
    userUuid: uuid,
    technologyData: technologies,
});

const setTechnologiesLoading = () => ({ type: UserActions.SET_USER_TECHNOLOGIES_LOADING });

const setTechnologiesError = () => ({ type: UserActions.SET_USER_TECHNOLOGIES_LOAD_ERROR });

export const fetchUserTechnologiesData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setTechnologiesLoading());
        getRequest(APIS.USER.getTechnologiesURL(uuid), keycloak)
            .then((data) => dispatch(setTechnologies(uuid, data)))
            .catch(() => {
                dispatch(setTechnologiesError());
            });
    };
};

export const cleanUpUser = () => ({
    type: UserActions.CLEAN_UP_USER,
});
