import React, { useState, useEffect } from 'react';
import useStyles from './index.style';
import SingleMinus from 'fintech/static/images/icons-single-minus-blue.svg';
import SingleAdd from 'fintech/static/images/icons-single-add-blue.svg';

const QuestionItem = ({ item, activeCategory }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setIsExpanded(false);
    }, [activeCategory]);

    return (
        <div className={classes.wrapper} onClick={() => setIsExpanded((prevState) => !prevState)}>
            <div className={classes.header}>
                {isExpanded ? (
                    <img src={SingleMinus} />
                ) : (
                    <img src={SingleAdd} />
                )}
                <span className={classes.question}>{item.question}</span>
            </div>
            {isExpanded &&
                <p className={classes.content} dangerouslySetInnerHTML={{ __html: item.answer }}></p>
            }
            {!isExpanded && <div className={classes.divider}></div>}
        </div>
    );
};

export default QuestionItem;
