import axios from 'axios';
import { io } from 'socket.io-client';
import { MessageType, RoomStatus } from './constants.js';

export default class ics {
    constructor(authToken, socketAddress, socketPath, restApiAddress) {
        this.authToken = authToken;
        this.socketAddress = socketAddress;
        this.socketPath = socketPath;
        this.restApiAddress = restApiAddress;
        this.doesApiAddressEndWithASlash = restApiAddress.endsWith('/');
        this.axios = axios.create({
            headers: {
                Authorization: 'Bearer ' + this.authToken,
            },
        });
    }

    setToken(authToken) {
        this.authToken = authToken;
        this.axios = axios.create({
            headers: {
                Authorization: 'Bearer ' + this.authToken,
            },
        });
    }

    request(address, data, type, successCallback, errorCallback) {
        this.axios[type](address, data)
            .then((response) => {
                successCallback(response.data.payload);
            })
            .catch((err) => {
                errorCallback ? errorCallback(err) : console.error(err);
            });
    }

    get(address, params, successCallback, errorCallback) {
        this.request(address, { params }, 'get', successCallback, errorCallback);
    }

    post(address, data, successCallback, errorCallback) {
        this.request(address, data, 'post', successCallback, errorCallback);
    }

    fetchRoom(roomId, successCallback, errorCallback) {
        this.get(
            this.restApiAddress + (this.doesApiAddressEndWithASlash ? '' : '/') + roomId,
            {},
            (roomData) => {
                successCallback(roomData);
            },
            errorCallback
        );
    }

    createRoom(username, successCallback, errorCallback) {
        this.post(
            this.restApiAddress,
            { username },
            (roomData) => {
                successCallback(roomData);
            },
            errorCallback
        );
    }

    fetchRoomList(successCallback, errorCallback) {
        this.get(this.restApiAddress, {}, successCallback, errorCallback);
    }

    fetchMessages({ roomId, lastMessageId, maxResult }, successCallback, errorCallback) {
        this.get(
            this.restApiAddress + (this.doesApiAddressEndWithASlash ? '' : '/') + roomId + '/list',
            lastMessageId ? (maxResult ? { lastMessageId, maxResult } : { lastMessageId }) : {},
            (payload) => {
                successCallback(payload.content);
            },
            errorCallback
        );
    }

    connect(onConnect) {
        this.socket = io(this.socketAddress, {
            auth: { token: this.authToken },
            path: this.socketPath,
            transports: ['websocket'],
        });
        this.socket.on('connect', onConnect);
    }

    sendMessage(message, successCallback, errorCallback) {
        if (this.socket.connected) {
            this.socket.emit(MessageType.DIRECT_MESSAGE, message, (result) => {
                if (result.status) successCallback(result);
                else errorCallback(result);
            });
        } else {
            errorCallback({ status: 'socket_disconnected' });
        }
    }

    getMessage(callback) {
        this.socket.on(MessageType.DIRECT_MESSAGE, (data) => {
            this.sendReceived(data);
            callback(data);
        });
    }

    getReceived(callback) {
        this.socket.on(MessageType.DIRECT_MESSAGE_RECEIVED, callback);
    }

    sendReceived(message) {
        const receivedAt = Date.now();
        const { roomId, messageId, sender } = message;

        this.socket.emit(MessageType.DIRECT_MESSAGE_RECEIVED, {
            roomId,
            messageId,
            receivedAt,
            sender,
        });
    }

    approveRoom(roomId, successCallback) {
        this.post(
            this.restApiAddress + (this.doesApiAddressEndWithASlash ? '' : '/') + roomId + '/status',
            { status: RoomStatus.APPROVED },
            successCallback
        );
    }

    rejectRoom(roomId, successCallback) {
        this.post(
            this.restApiAddress + (this.doesApiAddressEndWithASlash ? '' : '/') + roomId + '/status',
            { status: RoomStatus.REJECTED },
            successCallback
        );
    }

    removeMessage(roomId, messageId) {
        this.socket.emit(MessageType.REMOVE_MESSAGE, { roomId, messageId });
    }

    getRemoved(callback) {
        this.socket.on(MessageType.REMOVE_MESSAGE, callback);
    }

    getConnected(callback) {
        this.socket.on(MessageType.USER_CONNECTED, callback);
    }

    getPendingMessages(roomId, lastMessageQueuedAt, callback) {
        this.socket.emit(MessageType.REQUEST_ROOM_MESSAGES, { roomId, lastMessageQueuedAt });
        this.socket.on(MessageType.ROOM_MESSAGES, callback);
    }

    sendLastSeen(roomId) {
        this.socket?.emit(MessageType.ROOM_LAST_SEEN, { roomId });
    }

    getLastSeen(callback) {
        this.socket.on(MessageType.ROOM_LAST_SEEN, callback);
    }

    disconnect() {
        this.socket.disconnect();
    }

    getRoomInfo(requests, callback) {
        this.socket.emit(MessageType.REQUEST_ROOM_INFO, requests, callback);
    }

    getRoomMessages({ roomId, lastMessageQueuedAt }, callback) {
        this.socket.emit(MessageType.REQUEST_ROOM_MESSAGES, { roomId, lastMessageQueuedAt }, callback);
    }
}
