import { makeStyles } from '@material-ui/core';

import {
    COLOR_PRIMARY_SHADE_01,
    COLOR_ROW_HOVERED,
} from 'fintech/components/style/common';

export const makeChatEntryStyles = makeStyles(() => ({
    grid: {
        display: "flex",
        flexDirection: "row",
        padding: "20px",
        cursor: "pointer",
        borderBottom: `1px solid ${COLOR_PRIMARY_SHADE_01}`,

        '&:hover': {
            backgroundColor: COLOR_ROW_HOVERED,
        }
    },

    profilePicture: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        objectFit: "cover",
    },

    info: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "20px",
    },

    seenTitle: {
        fontWeight: 400,
    },

    notSeenTitle: {
        fontWeight: 600,
    },

    seenMessage: {
        fontWeight: 300,
    },

    notSeenMessage: {
        fontWeight: 500,
    },

    title: {
        fontSize: "12pt",
    },

    message: {
        fontSize: "10pt",
    }
}));