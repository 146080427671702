import { makeStyles, withStyles, Button } from '@material-ui/core';
import {
    poppins500_14px,
    poppins600_12px,
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_BUTTON_HOVERED,
    COLOR_PRIMARY_BUTTON_DISABLED,
    COLOR_PRIMARY_BUTTON_DISABLED_TEXT,
    COLOR_SECONDARY_BASE,
    COLOR_THEME_FILL_01,
    COLOR_THEME_FILL_03,
    COLOR_THEME_FILL_04,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    meetingFormAutocomplete: {
        width: '50%',
    },
    container: {
        marginTop: '33px',
    },
    heading: {
        ...poppins500_14px,
        fontSize: '18px',
        borderLeft: '4px solid',
        borderLeftColor: COLOR_SECONDARY_BASE,
        paddingLeft: '16px',
        marginTop: '32px',
    },
    warningArea: {
        backgroundColor: COLOR_THEME_FILL_04,
        marginTop: '16px',
        padding: '14px 17px 14px 20px',
        borderRadius: '4px',
        fontSize: '12px',
        color: COLOR_THEME_FILL_03,
        '& > :last-child': {
            marginLeft: '10px',
        },
    },
    warningDetail: {
        flexGrow: 1,
    },
    warningText: {
        maxWidth: '784px',
    },
    cancelAndDetail: {
        ...poppins600_12px,
        marginLeft: 'auto',
        display: 'flex',
    },
    cancel: {
        marginRight: '16px',
        cursor: 'pointer',
    },
    showDetail: {
        color: COLOR_PRIMARY_BASE,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    formContainer: {
        backgroundColor: COLOR_THEME_FILL_01,
        padding: '33px 0 22px 24px',
        borderRadius: '8px',
        marginTop: '31px',
    },
    formArea: {
        marginTop: '33px',
        maxWidth: '695px',
    },
    formControl: {
        width: '341px',
        marginBottom: '40px',
    },
    label: {
        height: '53.6px',
        '& > div': {
            fontFamily: ' Poppins',
            fontSize: ' 14px',
            fontWeight: ' 500',
            fontStretch: ' normal',
            fontStyle: ' normal',
            lineHeight: ' normal',
            letterSpacing: ' normal',
        },
    },
    backedStartups: {
        maxHeight: '235px',
    },
    timeSlot: {
        [theme.breakpoints.up('1007')]: {
            marginLeft: '13px',
        },
    },
    detailsInputContainer: {
        '&.MuiDialogContent-root:first-child': {
            padding: '5px 0 0 0',
        },
    },
    detailsInput: {
        [theme.breakpoints.up('md')]: {
            height: '189px',
            '& .MuiInputBase-formControl': {
                height: '100%',
            },
        },
    },
}));
export const StyledButton = withStyles({
    root: {
        textTransform: 'none',
        backgroundColor: COLOR_PRIMARY_BASE,
        color: COLOR_THEME_FILL_01,
        borderRadius: '8px',
        padding: '14px 52px',
        '&$disabled': {
            backgroundColor: COLOR_PRIMARY_BUTTON_DISABLED,
            color: COLOR_PRIMARY_BUTTON_DISABLED_TEXT,
        },
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
            color: COLOR_THEME_FILL_01,
        },
    },
    disabled: {},
})(Button);

export default useStyles;
