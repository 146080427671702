import React from 'react';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const IntroQuadGrid = (props) => {
    const { items } = props;

    return (
        <>
            <Grid item md={12} container direction="row">
                {items && items}
            </Grid>
        </>
    );
};

export default withTranslation('profile')(IntroQuadGrid);
