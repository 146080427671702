import React from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './index.style';
import { ErrorOutline } from '@material-ui/icons';
import { Typography, useMediaQuery } from '@material-ui/core';
import left from 'fintech/static/images/placeholder-2-left.svg';
import right from 'fintech/static/images/placeholder-2-right.svg';
import leftCircular from 'fintech/static/images/placeholder-1-left.svg';
import rightCircular from 'fintech/static/images/placeholder-1-right.svg';
import { desktopLargeQuery } from 'fintech/components/style/common';
import BackgroundPiece from './BgPiece';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';

const Empty = (props) => {
    const currentStyles = useStyles();
    const classes = props.useStyles ? props.useStyles() : currentStyles;
    const {
        message,
        messageComponent,
        isLoading,
        isError,
        showIcon = true,
        circularShapes = false,
        isPreLogin,
        style,
    } = props;
    const matches = useMediaQuery(desktopLargeQuery);

    let iconJSX = <ErrorOutline fontSize="large" className={classes.BgIconRev} />;
    if (isLoading) {
        iconJSX = <LoadingSpinner />;
    } else if (isError) {
        iconJSX = <ErrorOutline fontSize="large" />;
    }

    let messageJSX = null;

    // Message Override
    if (messageComponent) {
        messageJSX = messageComponent;
    } else {
        messageJSX = <Typography className={classes.BgPara}>{message}</Typography>;
    }

    const preLoginJsx = (
        <div className={classes.preLoginEmptyDiv}>
            {showIcon && <span className={classes.preLoginEmptyDivIcon}>{iconJSX}</span>}
            <span className={classes.preLoginEmptyDivMessage}>{messageJSX}</span>
        </div>
    );

    const secureAreaJsx = (
        <Grid
            style={{ backgroundColor: 'white', marginTop: '24px', padding: '0', ...style }}
            container
            direction="row"
            justify={matches ? 'space-between' : 'center'}
            alignItems="center"
        >
            {matches && <BackgroundPiece imgSrc={circularShapes ? leftCircular : left} imgAlign="left" />}
            <Grid
                className={classes.BgDiv}
                item
                xs={matches ? 4 : 12}
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item>{messageJSX}</Grid>
                {showIcon && (
                    <Grid item className={classes.iconGrid}>
                        {iconJSX}
                    </Grid>
                )}
            </Grid>
            {matches && <BackgroundPiece imgSrc={circularShapes ? rightCircular : right} imgAlign="right" />}
        </Grid>
    );

    return isPreLogin ? preLoginJsx : secureAreaJsx;
};

export default Empty;
