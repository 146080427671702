import * as React from 'react';

function SvgIconsMultipleMenu2(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        fill="#D9DCE0"
                                        d="M10.125 15.469C5.062 14.063 5.062 20.25 0 16.875V9c5.063 3.375 5.063-2.813 10.125-1.406v7.875zM7.875 18L16.875 18 16.875 16.594 7.875 16.594zM13.781 6.75L12.375 7.313 10.969 6.75 10.969 5.625 13.781 5.625z"
                                        transform="translate(-1090 -135) translate(1071 77) translate(0 48) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#D9DCE0"
                                        d="M10.688 7.594C8.438 9 7.03 9.562 7.03 10.969v4.5c0 .621.504 1.125 1.125 1.125h8.438c.621 0 1.125-.504 1.125-1.125v-4.5c0-1.406-1.407-1.969-3.657-3.375h-3.374z"
                                        transform="translate(-1090 -135) translate(1071 77) translate(0 48) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M10.125 2.531c-.156 0-.281.126-.281.281v.844c0 .155.125.281.281.281.156 0 .281-.125.281-.28v-.845c0-.155-.125-.28-.281-.28M14.625 2.531c-.156 0-.281.126-.281.281v.844c0 .155.125.281.281.281.156 0 .281-.125.281-.28v-.845c0-.155-.125-.28-.281-.28"
                                        transform="translate(-1090 -135) translate(1071 77) translate(0 48) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#E8E6E4"
                                        d="M14.625 2.813v.562c0 .844-.39 1.624-.844 2.531-.281.563-1.406.563-1.406.563s-1.125 0-1.406-.563c-.454-.907-.844-1.687-.844-2.531v-.563c.563 0 .844-1.406.844-1.406s.562.844 1.406.844h1.406c.563 0 .844.563.844.563"
                                        transform="translate(-1090 -135) translate(1071 77) translate(0 48) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M10.125 1.969v.843c.563 0 .844-1.406.844-1.406s.562.844 1.406.844h1.406c.563 0 .844.563.844.563v-.844C14.625.844 13.781 0 12.375 0c-.563 0-.844.281-.844.281s-1.406 0-1.406 1.688M15.19 14.773c-.06 0-.12-.006-.18-.019l-4.954-1.263c-.151-.038-.242-.191-.204-.342.039-.15.191-.24.342-.203l4.945 1.26c.072.017.159-.004.225-.058.066-.054.105-.133.105-.219V11.25c0-.155.126-.281.281-.281.155 0 .281.126.281.281v2.68c0 .255-.114.494-.313.656-.152.122-.338.187-.529.187"
                                        transform="translate(-1090 -135) translate(1071 77) translate(0 48) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M15.75 16.875c-1.14 0-6.013-1.749-6.387-1.883-.375-.092-.644-.433-.644-.82V11.25c0-.155.126-.281.281-.281.155 0 .281.126.281.281v2.922c0 .131.093.247.222.275l.035.01c1.429.516 5.33 1.855 6.212 1.855h.844c.465 0 .843-.378.843-.843v-4.5c0-1.013-.876-1.539-2.467-2.494-.327-.196-.68-.407-1.057-.643-.131-.082-.171-.256-.089-.387.082-.132.256-.172.388-.09.373.234.723.444 1.047.638C16.91 8.983 18 9.638 18 10.97v4.5c0 .775-.63 1.406-1.406 1.406h-.844z"
                                        transform="translate(-1090 -135) translate(1071 77) translate(0 48) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M12.375 16.875H8.156c-.775 0-1.406-.63-1.406-1.406v-4.5c0-1.331 1.09-1.986 2.74-2.976.325-.194.675-.404 1.048-.638.132-.082.306-.042.388.09.082.131.042.305-.09.387-.376.236-.729.447-1.056.643-1.591.955-2.467 1.481-2.467 2.494v4.5c0 .465.378.844.843.844h4.219c.155 0 .281.126.281.28 0 .156-.126.282-.281.282"
                                        transform="translate(-1090 -135) translate(1071 77) translate(0 48) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M12.375 9.844L11.25 8.719 10.125 7.594 10.969 6.75 12.375 7.313 13.781 6.75 14.625 7.594z"
                                        transform="translate(-1090 -135) translate(1071 77) translate(0 48) translate(19 9.5) translate(0 1)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleMenu2;
