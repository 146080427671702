import React, { useEffect, useState } from 'react';
import { Add } from '@material-ui/icons';
import { Grid, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './index.style';
import { fontStyle } from 'fintech/components/style/common';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import TeamMember from 'fintech/components/profile/startup/Team/TeamMember';
import { useKeycloak } from '@react-keycloak/web';
import Empty from 'fintech/components/util/Empty';
import ModalAddNewMember from 'fintech/components/profile/startup/Team/TeamGrid/ModalAddNewMember/ModalAddNewMember';
import { OPERATION_TYPE } from 'fintech/components/profile/startup/Team/TeamGrid/OperationType';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import { useSnackbar } from 'notistack';
import { InfoIcon } from 'fintech/components/profile/common';
import { isEmpty } from 'lodash';
import { getPublicFileUrl } from 'fintech/api/fileService';
import { setActiveRequest } from 'fintech/store/actions/common';

const TeamGrid = (props) => {
    const { t, id, type, teamServices } = props;
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const { data: fetchedData, isLoading, isError } = useSelector((state) => state[type].team);
    const { hasActiveRequest } = useSelector((state) => state.common);
    const dispatch = useDispatch();

    const [teamData, setTeamData] = useState(fetchedData);
    const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
    const [modalOperationType, setModalOperationType] = useState('');
    const [userInfo, setUserInfo] = useState({});
    const [memberUuidToBeDeleted, setMemberUuidToBeDeleted] = useState('');
    const [selectedInviteId, setSelectedInviteId] = useState();

    let membersJSX = null;
    let emptyProps = {};

    let teamLength = 0;

    if (!isEmpty(teamData)) {
        teamLength +=
            (teamData?.representatives?.length || 0) +
            (teamData?.members?.length || 0) +
            (teamData?.invites?.length || 0);
    }

    const remainingSize = 6 - teamLength;
    const showAddMember = teamData.editable;
    const isInviteNewMember = openAddMemberModal && modalOperationType === OPERATION_TYPE.INVITE;
    const isEditMember = openAddMemberModal && modalOperationType === OPERATION_TYPE.EDIT;

    const hasAnyData =
        teamData && (!isEmpty(teamData.representatives) || !isEmpty(teamData.invites) || !isEmpty(teamData.members));

    const deleteUser = () => {
        dispatch(setActiveRequest(true));
        teamServices
            .deleteTeamMember(keycloak, id, memberUuidToBeDeleted)
            .then((response) => {
                dispatch(setActiveRequest(false));
                if (response && response.success) {
                    enqueueSnackbar(t('profile.team.deleteMember.success'), {
                        variant: 'success',
                    });
                    setMemberUuidToBeDeleted('');
                    dispatch(teamServices.fetchTeamData(keycloak, id));
                }
            })
            .catch((error) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(error.message, {
                    variant: 'error',
                });
            });
    };

    const cancelInvitation = () => {
        dispatch(setActiveRequest(true));
        teamServices
            .cancelTeamInvitation(keycloak, id, selectedInviteId)
            .then((response) => {
                dispatch(setActiveRequest(false));
                if (response && response.success) {
                    enqueueSnackbar(t('profile.team.cancelInvitation.success'), {
                        variant: 'success',
                    });
                    dispatch(teamServices.fetchTeamData(keycloak, id));
                    setSelectedInviteId(null);
                }
            })
            .catch((error) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(error.message, {
                    variant: 'error',
                });
            });
    };

    const getLogo = (user) => {
        const uuid = user?.profilePictureFile?.uuid;
        return uuid && { url: getPublicFileUrl(uuid) };
    };

    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.team.errorMessage');
    } else if (hasAnyData) {
        membersJSX = (
            <>
                <Grid
                    className={classes.TeamCard}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                >
                    {showAddMember && (
                        <Grid
                            className={classes.teamItem}
                            item
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <ShrinkingButton
                                    shrinkedIcon={<Add fontSize="small" />}
                                    fullIcon={<Add />}
                                    fullText={t('profile.team.addMember')}
                                    onClick={() => {
                                        setModalOperationType(OPERATION_TYPE.INVITE);
                                        setOpenAddMemberModal(true);
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography style={{ ...fontStyle, textAlign: 'center', margin: '0 14px' }}>
                                    {remainingSize > 0
                                        ? `${remainingSize} ${t('profile.team.remainingMembers')}`
                                        : t('profile.team.reachedLimit')}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    {teamData.editable &&
                        teamData.invites?.map((usr) => (
                            <TeamMember
                                key={usr.uuid}
                                logo={getLogo(usr)}
                                {...usr}
                                editable={teamData.editable}
                                onClickDelete={() => setSelectedInviteId(usr.id)}
                            />
                        ))}
                    {teamData.representatives?.map((usr) => (
                        <TeamMember
                            key={usr.uuid}
                            editable={teamData.editable}
                            logo={getLogo(usr)}
                            {...usr}
                            onClickEdit={() => {
                                setModalOperationType(OPERATION_TYPE.EDIT);
                                setOpenAddMemberModal(true);
                                setUserInfo({ ...usr });
                            }}
                            onClickDelete={() => setMemberUuidToBeDeleted(usr.uuid)}
                            showDeleteButton={false}
                        />
                    ))}
                    {teamData.members?.map((usr) => (
                        <TeamMember
                            key={usr.uuid}
                            editable={teamData.editable}
                            logo={getLogo(usr)}
                            {...usr}
                            onClickEdit={() => {
                                setModalOperationType(OPERATION_TYPE.EDIT);
                                setOpenAddMemberModal(true);
                                setUserInfo({ ...usr });
                            }}
                            onClickDelete={() => setMemberUuidToBeDeleted(usr.uuid)}
                        />
                    ))}
                </Grid>
                {isInviteNewMember && (
                    <ModalAddNewMember
                        id={id}
                        keycloak={keycloak}
                        open={openAddMemberModal}
                        setOpen={setOpenAddMemberModal}
                        operationType={modalOperationType}
                        title={t('profile.team.addMember')}
                        buttonText={t('profile.team.addNewMember.add')}
                        description={t('profile.team.addNewMember.description')}
                        postInviteMember={teamServices.postInviteMember}
                        updateTeamMember={teamServices.updateTeamMember}
                        fetchTeamData={teamServices.fetchTeamData}
                    />
                )}
                {isEditMember && (
                    <ModalAddNewMember
                        id={id}
                        keycloak={keycloak}
                        open={openAddMemberModal}
                        setOpen={setOpenAddMemberModal}
                        operationType={modalOperationType}
                        title={t('profile.team.edit')}
                        buttonText={t('profile.team.save')}
                        postInviteMember={teamServices.postInviteMember}
                        updateTeamMember={teamServices.updateTeamMember}
                        fetchTeamData={teamServices.fetchTeamData}
                        userInfo={userInfo}
                    />
                )}
                <ConfirmDialog
                    open={!!memberUuidToBeDeleted}
                    onConfirm={deleteUser}
                    icon={<InfoIcon fontSize="large" />}
                    title={t('dialog.confirmTitle')}
                    injectHtml
                    content={t('profile.team.deleteMember.confirm')}
                    labelCancel={t('dialog.cancelAction')}
                    labelConfirm={t('dialog.confirmAction')}
                    onCancel={() => setMemberUuidToBeDeleted('')}
                    confirmDisabled={hasActiveRequest}
                    cancelDisabled={hasActiveRequest}
                    showSpinnerWhenDisabled={hasActiveRequest}
                />
                <ConfirmDialog
                    open={!!selectedInviteId}
                    onConfirm={cancelInvitation}
                    icon={<InfoIcon fontSize="large" />}
                    title={t('dialog.confirmTitle')}
                    injectHtml
                    content={t('profile.team.cancelInvitation.confirm')}
                    labelCancel={t('dialog.cancelAction')}
                    labelConfirm={t('dialog.confirmAction')}
                    onCancel={() => setSelectedInviteId('')}
                    confirmDisabled={hasActiveRequest}
                    cancelDisabled={hasActiveRequest}
                    showSpinnerWhenDisabled={hasActiveRequest}
                />
            </>
        );
    } else {
        emptyProps.message = t('profile.team.emptyMessage');
    }

    useEffect(() => {
        fetchedData.id !== id && dispatch(teamServices.fetchTeamData(keycloak, id));
    }, [id]);

    useEffect(() => {
        setTeamData(fetchedData);
    }, [fetchedData]);

    return membersJSX ? membersJSX : <Empty {...emptyProps} />;
};

export default withTranslation('profile')(TeamGrid);
