import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Empty from 'fintech/components/util/Empty';
import ProgramCardGrid from 'fintech/components/support/program/card/ProgramCardGrid';
import { fetchSupportProgramList } from 'fintech/store/actions/supportProgram';
import { CURRENT } from 'fintech/constants/supportProgram';
import { hasRoles } from 'fintech/utils/Auth';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';

/**
 * Support Program Card Grid which uses the carousel for showing the event cards
 */
const ProgramGridContainer = (props) => {
    const { type } = props;
    const { t } = useTranslation('supportProgram');
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { fetchedPrograms, isLoading, isError } = useSelector((state) => ({
        isLoading: state.supportProgram.programs.isLoading,
        isError: state.supportProgram.programs.isError,
        fetchedPrograms: state.supportProgram.programs[type],
    }));
    const [programs, setPrograms] = useState([]);

    const isAdmin = hasRoles(keycloak, ROLE_ADMIN);
    const hasPrograms = programs && programs.length > 0;

    let emptyProps = {};

    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = type === CURRENT ? t('list.currentProgramsError') : t('list.pastProgramsError');
    } else {
        emptyProps.message = type === CURRENT ? t('list.noCurrentPrograms') : t('list.noPastPrograms');
    }

    useEffect(() => {
        setPrograms(fetchedPrograms);
    }, [fetchedPrograms]);

    useEffect(() => {
        dispatch(fetchSupportProgramList(keycloak));
    }, []);

    return hasPrograms || (type === CURRENT && isAdmin) ? (
        <ProgramCardGrid showAdd={type === CURRENT && isAdmin} isAdmin={isAdmin} programs={programs} />
    ) : (
        <Empty {...emptyProps} />
    );
};

export default ProgramGridContainer;
