import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { withTranslation } from 'react-i18next';
import { Grid, Box } from '@material-ui/core';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import PageTemplate from 'fintech/components/PageTemplate';
import TabSwitch from 'fintech/components/ui/navigation/TabSwitch';
import IntroGrid from 'fintech/components/profile/user/IntroGrid';
import { getLoggedInUserUuid, hasRoles } from 'fintech/utils/Auth';
import CreateMeetingIntroGrid from 'fintech/components/profile/ConsultantCreateMeeting/IntroGrid/IntroGrid';
import MeetingCalendar from 'fintech/components/profile/ConsultantCreateMeeting/MeetingCalendar/MeetingCalendar';
import MentorshipGrid from 'fintech/components/profile/user/MentorshipGrid';
import VenturesGrid from 'fintech/components/profile/user/VenturesGrid';
import { useParams } from 'react-router';
import {
    ROLE_COUNSELOR,
    ROLE_IDEA_REPRESANTATIVE,
    ROLE_IDEA_WORKER,
    ROLE_MENTOR,
    ROLE_STARTUP_REPRESANTATIVE,
    ROLE_STARTUP_WORKER,
    ROLE_INVESTOR_STAKEHOLDER,
    ROLE_INVESTOR_REPRESANTATIVE,
} from 'fintech/components/util/RoleConstants';
import { ConsultantCalendar } from 'fintech/components/profile/user/consultant/calendar/ConsultantCalendar';
import { useDispatch, useSelector } from 'react-redux';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import InvestorsGrid from 'fintech/components/profile/user/InvestorsGrid/InvestorsGrid';
import { fetchUser, updateVisitCount, cleanUpUser } from 'fintech/store/actions/user';
import { getProfileVisitCount, postProfileVisitCount } from 'fintech/api/fintechService';
import * as PAGE_TYPES from 'fintech/components/util/PageTypes';
import * as constants from 'fintech/components/util/ActionModalConstants';
import ActionModal from 'fintech/components/util/ActionModal';
import { tagsModalStyle } from 'fintech/components/table/StickyTable/index.style';
import useStyles from './ProfilePage.style';

const ProfilePage = (props) => {
    const { keycloak } = useKeycloak();
    const { t } = props;
    const { userId } = useParams(); //Viewed user id
    const dispatch = useDispatch();

    const isCounselor = hasRoles(keycloak, ROLE_COUNSELOR);
    const { data: fetchedData } = useSelector((state) => state.user.intro);

    const [tabs, setTabs] = useState(undefined);
    const userUuid = userId || getLoggedInUserUuid(keycloak);
    const loggedInUserUuid = getLoggedInUserUuid(keycloak);

    const isOwnProfile = !userId || userId === loggedInUserUuid;
    const isDifferentCounselorProfile = userId && fetchedData && fetchedData.isConsultantFromUsersPerspective;

    /* Handle tags */
    const [showTagsModal, setShowTagsModal] = useState(false);
    const [title, setTitle] = useState('');
    const [tags, setTags] = useState([]);
    const defaultLimit = 8;
    const textLimit = 11;
    const [tagLimit, setTagLimit] = useState(defaultLimit);
    const styles = useStyles({ showBorder: tags.length > tagLimit, isScrollable: false });

    const onTagExpand = ({ title, tags, notTag = false }) => {
        setTags(tags);
        setTitle(t(title));
        setShowTagsModal(true);
        if (notTag && tagLimit != textLimit) {
            setTagLimit(textLimit);
        } else if (!notTag && tagLimit != defaultLimit) {
            setTagLimit(defaultLimit);
        } else {
            return;
        }
    };

    const onCancel = () => {
        setShowTagsModal(false);
        setTimeout(() => {
            setTitle(t(''));
            setTags([]);
        }, [300]);
    };

    const tagsModalActionHandler = (action) => {
        switch (action.type) {
            case constants.SUBMIT:
                onCancel();
                return;
            case constants.REJECT:
                onCancel();
                return;
            default:
                return;
        }
    };
    /* Handle tags - End */
    useEffect(() => {
        if (isOwnProfile) {
            dispatch(
                fetchUser(keycloak, loggedInUserUuid, isOwnProfile, () => {
                    getProfileVisitCount(keycloak, PAGE_TYPES.USER_PROFILE, userUuid).then((response) => {
                        if (response && response.success) {
                            dispatch(updateVisitCount(response.data));
                        }
                    });
                })
            );
        } else {
            dispatch(
                fetchUser(keycloak, userId, isOwnProfile, () => {
                    postProfileVisitCount(keycloak, PAGE_TYPES.USER_PROFILE, userId).then((response) => {
                        if (response && response.success) {
                            dispatch(updateVisitCount(response.data));
                        }
                    });
                })
            );
        }
    }, [userId]);

    useEffect(() => {
        return () => {
            dispatch(cleanUpUser());
        };
    }, []);

    useEffect(() => {
        if (fetchedData && fetchedData.role && fetchedData.role.length > 0) {
            const tmpTabs = [];
            const isMemberOrRepresent = filterObjectList(fetchedData.role, 'roleUniqueId', [
                ROLE_STARTUP_WORKER,
                ROLE_STARTUP_REPRESANTATIVE,
                ROLE_IDEA_WORKER,
                ROLE_IDEA_REPRESANTATIVE,
            ]);
            const isMentor = filterObjectList(fetchedData.role, 'roleUniqueId', [ROLE_MENTOR]);

            const isInvestor = filterObjectList(fetchedData.role, 'roleUniqueId', [
                ROLE_INVESTOR_STAKEHOLDER,
                ROLE_INVESTOR_REPRESANTATIVE,
            ]);

            if (isMemberOrRepresent && isMemberOrRepresent.length > 0) {
                tmpTabs.push({
                    header: { label: t('profile.relatedVentures.label') },
                    component: <VenturesGrid userUuid={userUuid} onTagExpand={onTagExpand} />,
                    errorMsg: t('profile.relatedVentures.errorMessage'),
                });
            }
            if (isMentor && isMentor.length > 0) {
                tmpTabs.push({
                    header: { label: t('profile.mentorships.label') },
                    component: <MentorshipGrid userUuid={userUuid} onTagExpand={onTagExpand} />,
                    errorMsg: t('profile.mentorships.errorMessage'),
                });
            }
            if (isInvestor && isInvestor.length > 0) {
                tmpTabs.push({
                    header: { label: t('profile.relatedInvestors.label') },
                    component: <InvestorsGrid userUuid={userUuid} onTagExpand={onTagExpand} />,
                    errorMsg: t('profile.relatedInvestors.errorMessage'),
                });
            }
            setTabs(tmpTabs);
        }
    }, [fetchedData]);

    if (isDifferentCounselorProfile) {
        return (
            <PageTemplate>
                <PageHeaderBox disableOnMobile={true} />
                <CreateMeetingIntroGrid userUuid={userUuid} />
                <MeetingCalendar consultantId={userId}></MeetingCalendar>
            </PageTemplate>
        );
    }
    return (
        <PageTemplate>
            <PageHeaderBox disableOnMobile={true} />
            <IntroGrid userUuid={userUuid} isOwnProfile={isOwnProfile} />
            {isOwnProfile && isCounselor ? (
                <ConsultantCalendar userUuid={userUuid} />
            ) : (
                <>
                    <Box height="32px" />
                    {tabs && tabs.length > 0 && <TabSwitch tabs={tabs} />}
                    <Box height="32px" />
                </>
            )}

            <ActionModal
                titleMarginBottom30={false}
                titleContainerClass={`sticky-table-tag-modal ${styles.modalTitle}`}
                tabsContainerClass={'padding-bottom-26 border-bottom'}
                modalStyle={tagsModalStyle}
                dialogActionsStyle={{
                    borderTop: tags.length > tagLimit ? '1px solid #dee2e6' : null,
                    height: '44px',
                }}
                open={showTagsModal}
                okClickHidden
                overrideTitle
                title={title}
                okButtonLabel={t('dialog.close')}
                onAction={tagsModalActionHandler}
                showCancelOnTop
            >
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    {tags}
                </Grid>
            </ActionModal>
        </PageTemplate>
    );
};

export default withTranslation('profile')(ProfilePage);
