import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTemplate from 'fintech/components/PageTemplate';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import { COLOR_THEME_FILL_01 } from 'fintech/components/style/common';
import ArrowNavigation from 'fintech/components/ui/navigation/ArrowNavigation';
import { fetchApplication, fetchRepresentativeInfo } from 'fintech/store/actions/supportProgramApplication';
import Other from 'fintech/components/support/program/form/SupportProgramApplication/Other';
import GeneralInformation from 'fintech/components/support/program/form/SupportProgramApplication/GeneralInformation';
import IntellectualProperty from 'fintech/components/support/program/form/SupportProgramApplication/IntellectualProperty';
import StructuralInformation from 'fintech/components/support/program/form/SupportProgramApplication/StructuralInformation';
import FounderAndTeamInformation from 'fintech/components/support/program/form/SupportProgramApplication/FounderAndTeamInformation';
import RepresentativeInformation from 'fintech/components/support/program/form/SupportProgramApplication/RepresentativeInformation';
import { useKeycloak } from '@react-keycloak/web';
import ApplicationResult from 'fintech/components/applications/form/common/ApplicationResult';
import { getLoggedInUserUuid } from 'fintech/utils/Auth';
import { SUPPORT_PROGRAM_SECURE } from 'fintech/routes';

const SupportProgramApplicationPage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation('supportProgramApplication');
    const { t: t2 } = useTranslation('application');
    const { id } = useParams();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchApplication(id, redirectPage));
        dispatch(fetchRepresentativeInfo(keycloak, getLoggedInUserUuid(keycloak)));
    }, []);

    const redirectPage = () => {
        history.replace(SUPPORT_PROGRAM_SECURE);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }, 700);
        return () => clearTimeout(timer);
    }, [currentIndex]);

    const options = [
        t('pageTitles.representativeInformation'),
        t('pageTitles.generalInformation'),
        t('pageTitles.intellectualProperty'),
        t('pageTitles.founderAndTeamInformation'),
        t('pageTitles.structureInformation'),
        t('pageTitles.other'),
        t('pageTitles.result'),
    ];

    const optionsWithLongText = [
        t('pageTitles.representativeInformation'),
        t('pageTitles.generalInformation'),
        t('pageTitles.intellectualProperty'),
        t('pageTitles.founderAndTeamInformation'),
        t('pageTitles.structureInformationLongText'),
        t('pageTitles.otherLongText'),
        t('pageTitles.result'),
    ];

    const nextPage = () => setCurrentIndex((prevState) => prevState + 1);
    const prevPage = () => setCurrentIndex((prevState) => prevState - 1);

    const getCurrentFormPage = () => {
        switch (currentIndex) {
            case 0:
                return <RepresentativeInformation formId={id} secure pageFunctions={{ nextPage }} />;
            case 1:
                return <GeneralInformation formId={id} secure pageFunctions={{ prevPage, nextPage }} />;
            case 2:
                return <IntellectualProperty formId={id} pageFunctions={{ prevPage, nextPage }} />;
            case 3:
                return <FounderAndTeamInformation formId={id} pageFunctions={{ prevPage, nextPage }} />;
            case 4:
                return <StructuralInformation formId={id} pageFunctions={{ prevPage, nextPage }} />;
            case 5:
                return <Other formId={id} secure pageFunctions={{ prevPage, nextPage }} />;
            case 6: {
                return (
                    <ApplicationResult secure title={t2('supportProgram.resultTitle')} info={t2('common.resultInfo')} />
                );
            }
            default:
                return null;
        }
    };

    return (
        <PageTemplate>
            <PageHeaderBox />
            <ArrowNavigation currentIndex={currentIndex} options={options} optionsWithLongText={optionsWithLongText} />
            <Grid
                item
                style={{
                    backgroundColor: COLOR_THEME_FILL_01,
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
                }}
            >
                {getCurrentFormPage()}
            </Grid>
        </PageTemplate>
    );
};

export default SupportProgramApplicationPage;
