import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import IntroDuoGrid from 'fintech/components/profile/common/DuoGrid';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import { Edit } from '@material-ui/icons';
import Empty from 'fintech/components/util/Empty';
import useFinancialDetailStyles from 'fintech/components/profile/startup/Financials/Details/index.style';
import { useTranslation } from 'react-i18next';
import PreferencesEditModal from 'fintech/components/profile/establishment/InvestmentPreferences/form/PreferencesEditModal/PreferencesEditModal';

const PreferencesInfo = ({ editable, duoItems = [] }) => {
    const classes = useFinancialDetailStyles();
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const clickEditHandler = () => setOpenModal(true);
    const cancelModalHandler = () => setOpenModal(false);
    return (
        <>
            <Grid
                className={classes.StupFinancialsCard}
                container
                direction={editable ? 'row-reverse' : 'row'}
                justify="space-between"
                alignItems="flex-start"
            >
                {editable && (
                    <Grid
                        item
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-start"
                        style={{ width: 'auto' }}
                    >
                        <ShrinkingButton
                            shrinkedIcon={<Edit fontSize="small" />}
                            fullIcon={<Edit />}
                            fullText={t('profile.edit')}
                            onClick={clickEditHandler}
                        />
                    </Grid>
                )}
                <Grid item style={{ flexGrow: 1 }}>
                    <Grid container direction="column" justify="flex-start" alignItems="stretch">
                        {duoItems ? (
                            <IntroDuoGrid items={duoItems} />
                        ) : (
                            <Empty message={t('profile.financials.emptyMessage')} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {editable && openModal && <PreferencesEditModal open={openModal} onCancel={() => cancelModalHandler()} />}
        </>
    );
};

export default PreferencesInfo;
