import { NotificationActions } from 'fintech/store/actions/ActionTypes';

const initialState = {
    notifications: [],
    hasMore: false,
    totalNumberOfUnreadNotifications: 0,
    isLoading: false,
};

const notification = (state = initialState, action) => {
    switch (action.type) {
        case NotificationActions.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.data.sort((a, b) => -(a.id - b.id)),
            };
        case NotificationActions.DISABLE_ALL_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.map((element) => ({ ...element, read: true })),
            };
        case NotificationActions.DISABLE_NOTIFICATION:
            return {
                ...state,
                notifications: readNotification(state.notifications, action.data),
            };
        case NotificationActions.SET_TOTAL_NUMBER_OF_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                totalNumberOfUnreadNotifications: action.data,
            };
        case NotificationActions.SET_HAS_MORE:
            return {
                ...state,
                hasMore: action.data,
            };
        case NotificationActions.SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.data,
            };
        default:
            return state;
    }
};

const readNotification = (notifications, id) => {
    const _notifications = [...notifications];
    const notificationID = _notifications.findIndex((e) => e.id === id);
    _notifications[notificationID] = {
        ..._notifications[notificationID],
        read: true,
    };

    return _notifications;
};

export default notification;
