import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    COLOR_SECONDARY_BASE,
} from 'fintech/components/style/common';

const defaultAvatarIcon = {
    width: 64,
    height: 64,
    display: 'block',
};
const useStyles = makeStyles(() => ({
    root: {
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '& > *': {
            margin: '0',
        },
    },
    iconButton: {
        '&:hover': {
            backgroundColor: 'inherit',
        },
        '&:active': { borderStyle: 'outset' },
        padding: '0 0 0 0',
        borderRadius: '50%',
    },
    input: {
        display: 'none',
    },
    preLoginPlaceholderAvatar: {
        ...defaultAvatarIcon,
        width: 42,
        height: 42,
    },
    avatarIconWithoutBorder: {
        ...defaultAvatarIcon,
    },
    avatarIcon: {
        ...defaultAvatarIcon,
        border: `solid 2px ${COLOR_PRIMARY_SHADE_02}`,
    },
    avatarIconPlaceholder: {
        ...defaultAvatarIcon,
        border: `solid 1px ${COLOR_PRIMARY_SHADE_03}`,
    },
    hovered: {
        background: COLOR_PRIMARY_SHADE_02,
        width: '100%',
        height: '100%',
        border: 'solid 2px',
        borderColor: COLOR_PRIMARY_BASE,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textTransform: 'none',
    },
    hoveredText: {
        fontSize: 11,
        color: COLOR_SECONDARY_BASE,
        fontWeight: 500,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        width: '100%',
        transition: '.3s ease',
    },
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
}));

export default useStyles;
