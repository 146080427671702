// material
import React from 'react';
import { Grid, Container, Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import useStyles from './index.style';
import * as ROUTES from 'fintech/routes';
import startupImage from 'fintech/static/images/illustration-startup.svg';
import IconsRightArrow from 'fintech/icons/IconsRightArrow';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    padding: '60px 120px 80px 120px',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(8, 2),
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
    textAlign: 'center',
    position: 'relative',
    marginBottom: theme.spacing(2),
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        height: '100%',
        marginBottom: 0,
        textAlign: 'left',
        alignItems: 'flex-start',
    },
    [theme.breakpoints.down(900)]: {
        width: '100%',
        alignItems: 'center',
    },
}));

// ----------------------------------------------------------------------

export default function Startup() {
    const { t } = useTranslation('preLogin');
    const history = useHistory();
    const classes = useStyles();
    return (
        <RootStyle id="section-startup">
            <Container maxWidth="lg">
                <Grid
                    container
                    spacing={5}
                    direction="row-reverse"
                    justifyContent="space-between"
                    className={classes.container}
                >
                    <Grid item xs={12} md={5}>
                        <ContentStyle>
                            <span className={classes.sectionTitle}>{t('titles.startups')}</span>
                            <span className={classes.sectionDef}>{t('startups')}</span>
                            <Button
                                variant="contained"
                                endIcon={<IconsRightArrow />}
                                color="primary"
                                className={classes.applyButton}
                                style={{
                                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
                                    width: 'fit-content',
                                    padding: '8px 48px 8px 48px',
                                }}
                                onClick={() => history.push(ROUTES.STARTUP_APPLICATION)}
                            >
                                {t('applyStartup')}
                            </Button>
                        </ContentStyle>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <img alt="startupImage" src={startupImage} className={classes.startupImg} />
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    );
}
