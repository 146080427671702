import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './index.style';
import Mentor from '../Testimonial/Mentor';

const MentorsOnePage = ({ supportProgramId }) => {
    const { t } = useTranslation('preLogin');
    const classes = useStyles();

    return (
        <section className={classes.testimonialSection} id="section-testimonial">
            <div className={classes.testimonialWrapper}>
                <h2>{t('titles.mentors')}</h2>
                <Mentor supportProgramId={supportProgramId} />
            </div>
        </section>
    );
};

export default MentorsOnePage;
