import { makeStyles } from '@material-ui/core';
import {
    buttonStyle,
    buttonStyleColorAlt,
    COLOR_PRIMARY_BASE,
    COLOR_THEME_FILL_01,
    COLOR_PRIMARY_BUTTON_HOVERED,
    COLOR_SECONDARY_SHADE_01,
    COLOR_PRIMARY_SHADE_03,
    COLOR_THEME_FILL_06,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    loggedInContainer: {
        display: 'flex',
        position: 'relative',
    },
    profileImg: {
        border: '3px solid #5b7adb',
        borderRadius: '50%',
        marginRight: 16,
        cursor: 'pointer',
        width: 48,
        height: 48,
        overflow: 'hidden',
    },
    dropdownWrapper: {
        '&:hover div.preLoginSecureDropdownMenu': {
            display: 'flex',
        },
    },
    goToApp: {
        padding: '12px 4px 12px 11px',
        color: COLOR_THEME_FILL_01,
        backgroundColor: COLOR_PRIMARY_BASE,
        textAlign: 'center',
        borderRadius: 8,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        minWidth: 'fit-content',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
            color: COLOR_THEME_FILL_01,
        },
        '& > span': {
            maxHeight: 48,
        },
    },
    dropdownMenu: {
        position: 'absolute',
        width: 184,
        background: 'white',
        borderRadius: 8,
        display: 'none',
        top: 50,
        right: 92,
        flexDirection: 'column',
        padding: '14px 24px 13px 11px',
    },
    name: {
        fontWeight: 600,
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 23,
    },
    menuItem: {
        display: 'flex',
        cursor: 'pointer',
        borderRadius: '8px',
        '& span': {
            fontSize: 14,
            marginLeft: 8,
            fontWeight: 500,
        },
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        },
    },
    changePasswordModal: {
        width: 700,
        background: 'white',
        position: 'absolute',
        top: '50vh',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        zIndex: 30,
        borderRadius: 6,
        boxShadow: '0 19px 38px 0 rgba(0, 0, 0, 0.3), 0 15px 12px 0 rgba(0, 0, 0, 0.22)',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '24px 40px',

        [theme.breakpoints.down('sm')]: {
            padding: '24px 12px 32px 12px',
            maxWidth: '90%',
        },
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#000000',
        opacity: 0.5,
        zIndex: 20,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
    closeIcon: {
        position: 'absolute',
        top: 8,
        right: 8,
        cursor: 'pointer',
    },
    modalTitle: {
        fontWeight: 600,
        fontSize: 24,
        color: '#3f5162',
    },
    modalDef: {
        fontSize: 13,
        marginTop: 8,
        color: COLOR_SECONDARY_SHADE_01,
        textAlign: 'center',
        maxWidth: 340,
        marginBottom: 8,
    },
    changePasswordForm: {
        width: '100%',
    },
    changePasswordFormItem: {
        height: 80,
    },
    buttonsWrapper: {
        display: 'flex',
        marginTop: 32,

        [theme.breakpoints.down('sm')]: {
            marginTop: 25,
        },
    },
    cancelButton: {
        ...buttonStyleColorAlt,
        width: 97,
        height: 48,
        margin: '0 24px 0 0',
        padding: '14px 22px',
    },
    changePassword: {
        ...buttonStyle,
        width: 'fit-content',
        height: 48,
        margin: '0 0 0 24px',
        padding: '14px 22px',
    },
    successIconDiv: {
        textAlign: 'center',
        marginTop: 50,
        marginBottom: 40,
        '& > svg': {
            backgroundColor: COLOR_THEME_FILL_06,
            borderRadius: 62,
        },
    },
    updatePasswordSuccessfulTextDiv: {
        fontSize: 21,
        fontWeight: 600,
        marginBottom: 40,
        textAlign: 'center',
    },
}));

export default useStyles;
