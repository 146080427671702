import React, { useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import GeneralInfoHeader from 'fintech/components/profile/startup/GeneralInfo/Header';
import UpdateStartupGeneralInfoActionModal from 'fintech/components/profile/startup/GeneralInfo/ActionModal/container/UpdateStartupGeneralInfoActionModal';
import useStyles from './index.style';
import { useDispatch } from 'react-redux';
import { fontStyle, laptopLarge, mobileMediaQuery } from 'fintech/components/style/common';
import Empty from 'fintech/components/util/Empty';
import { fetchStartupIntroData } from 'fintech/store/actions/startup';
import { fetchEstablishmentAboutData } from 'fintech/store/actions/establishment';
import { useKeycloak } from '@react-keycloak/web';
import Box from '@material-ui/core/Box';
import MentorCarousel from 'fintech/components/profile/startup/GeneralInfo/startupmentors/mentorcarousel/MentorCarousel';
import Documents from 'fintech/components/profile/startup/GeneralInfo/GeneralInfoGrid/Documents/Documents';
import UpdateEstablishmentGeneralInfoActionModal from '../../../establishment/UpdateEstablishmentGeneralInfoActionModal/UpdateEstablishmentGeneralInfoActionModal';
import { STATES } from 'fintech/store/actions/ActionTypes';

const GeneralInfoGrid = (props) => {
    const classes = useStyles();
    const {
        t,
        pageType = 'startup',
        documentServices,
        generalInfo: fetchedInfo,
        editable,
        introData,
        isLoading,
        isError,
        uuid,
    } = props;

    const [generalInfo, setGeneralInfo] = useState(fetchedInfo);
    const matchesLaptopLarge = useMediaQuery(laptopLarge);
    const matchesMobileMediaQuery = useMediaQuery(mobileMediaQuery);

    const [openEditModal, setOpenEditModal] = useState(false);

    let documentsSectionRowCount = null;

    if (!introData?.mentors || (introData && introData.mentors?.length < 1)) {
        documentsSectionRowCount = 12;
    } else if (matchesMobileMediaQuery) {
        documentsSectionRowCount = matchesLaptopLarge ? 8 : 6;
    }

    const onEditClick = () => {
        setOpenEditModal(true);
    };

    let generalInfoJSX = null;
    let emptyProps = {};

    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.generalInfo.errorMessage');
    } else {
        generalInfoJSX = (
            <>
                <Grid
                    className={classes.GeneralInfoCard}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                >
                    <GeneralInfoHeader
                        editable={editable}
                        onEditClickCallback={onEditClick}
                        title={
                            pageType === STATES.STARTUP
                                ? t('profile.generalInfo.about')
                                : t('profile.establishments.generalInfo.about')
                        }
                    />
                    <Grid item style={{ padding: '20px' }}>
                        {generalInfo ? (
                            <Typography
                                style={{ ...fontStyle, wordBreak: 'break-word' }}
                                dangerouslySetInnerHTML={{ __html: generalInfo }}
                            />
                        ) : (
                            <Empty message={t('profile.generalInfo.emptyMessage')} />
                        )}
                    </Grid>
                    {editable && (
                        <GeneralInfoActionModal
                            pageType={pageType}
                            open={openEditModal}
                            setOpen={setOpenEditModal}
                            initData={introData}
                            aboutInfo={generalInfo}
                            t={t}
                        />
                    )}
                </Grid>
                <Box height={16} />
                <Grid container direction={matchesMobileMediaQuery ? 'row' : 'column'} spacing={2}>
                    <Grid item xs={documentsSectionRowCount}>
                        {introData.uuid && (
                            <Documents uuid={uuid} editable={editable} documentServices={documentServices} />
                        )}
                    </Grid>
                    {introData.mentors?.length > 0 && (
                        <Grid item xs={matchesMobileMediaQuery ? (matchesLaptopLarge ? 4 : 6) : null}>
                            <MentorCarousel />
                        </Grid>
                    )}
                </Grid>
            </>
        );
    }

    useEffect(() => {
        setGeneralInfo(fetchedInfo);
    }, [fetchedInfo]);

    return generalInfoJSX ? generalInfoJSX : <Empty {...emptyProps} />;
};

export default withTranslation('profile')(GeneralInfoGrid);

const GeneralInfoActionModal = ({ pageType, open, setOpen, initData, aboutInfo, t }) => {
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    if (pageType === STATES.STARTUP) {
        const updateCallback = (isUpdated) => {
            if (isUpdated) {
                dispatch(fetchStartupIntroData(keycloak, initData.uuid));
            }
        };
        return (
            <UpdateStartupGeneralInfoActionModal
                title={t('profile.generalInfo.editModalTitle')}
                open={open}
                setOpen={setOpen}
                updateCallback={(isUpdated) => updateCallback(isUpdated)}
                initData={initData}
            />
        );
    } else if (pageType === STATES.ESTABLISHMENT) {
        const updateCallback = (isUpdated) => {
            if (isUpdated) {
                dispatch(fetchEstablishmentAboutData(keycloak, initData.uuid));
            }
        };
        return (
            <UpdateEstablishmentGeneralInfoActionModal
                title={t('profile.establishments.generalInfo.about')}
                open={open}
                setOpen={setOpen}
                updateCallback={(isUpdated) => updateCallback(isUpdated)}
                initData={initData}
                aboutInfo={aboutInfo}
            />
        );
    }
};
