import React, { useState } from 'react';
import { Grid, Modal } from '@material-ui/core';
import { CloseButton, SaveButton } from 'fintech/components/ui/modal/Buttons/Buttons';
import useStyles from './index.style';
import { useTranslation } from 'react-i18next';
import IconsSingleTooltip from 'fintech/icons/IconsSingleTooltip';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import { OPERATION_TYPE } from 'fintech/components/profile/startup/Team/TeamGrid/OperationType';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';

const ModalAddNewMember = ({
    keycloak,
    id,
    open,
    setOpen,
    title,
    description,
    buttonText,
    operationType,
    userInfo,
    postInviteMember,
    updateTeamMember,
    fetchTeamData,
}) => {
    const { hasActiveRequest } = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { t } = useTranslation();

    const [name, setName] = useState(
        userInfo && userInfo.name ? { val: userInfo.name, validated: true } : { val: '', validated: true }
    );
    const [surname, setSurname] = useState(
        userInfo && userInfo.surname ? { val: userInfo.surname, validated: true } : { val: '', validated: true }
    );
    const [email, setEmail] = useState(
        userInfo && userInfo.mailAddress ? { val: userInfo.mailAddress, validated: true } : { val: '', validated: true }
    );
    const [position, setPosition] = useState(
        userInfo && userInfo.position ? { val: userInfo.position, validated: true } : { val: '', validated: true }
    );
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const isInvite = operationType === OPERATION_TYPE.INVITE;

    const handleClick = () => {
        setIsFormSubmitted(true);
        if (
            !name.val ||
            !name.validated ||
            !surname.val ||
            !surname.validated ||
            !email.val ||
            !email.validated ||
            !position.val ||
            !position.validated
        ) {
            enqueueSnackbar(t('validation.genericError'), {
                variant: 'error',
            });
            return;
        }
        dispatch(setActiveRequest(true));
        if (isInvite) {
            postInviteMember(keycloak, id, {
                name: name.val,
                surname: surname.val,
                position: position.val,
                email: email.val,
            })
                .then((response) => {
                    dispatch(setActiveRequest(false));
                    if (response && response.success) {
                        dispatch(fetchTeamData(keycloak, id));
                        setOpen(false);
                        enqueueSnackbar(t(response.message), {
                            variant: 'success',
                        });
                    }
                })
                .catch((error) => {
                    dispatch(setActiveRequest(false));
                    enqueueSnackbar(error.message, {
                        variant: 'error',
                    });
                });
        } else {
            updateTeamMember(keycloak, id, userInfo.uuid, {
                name: name.val,
                surname: surname.val,
                email: email.val,
                position: position.val,
            })
                .then((response) => {
                    dispatch(setActiveRequest(false));
                    if (response && response.success) {
                        dispatch(fetchTeamData(keycloak, id));
                        enqueueSnackbar(t(response.message), {
                            variant: 'success',
                        });
                        setOpen(false);
                    }
                })
                .catch((error) => {
                    dispatch(setActiveRequest(false));
                    enqueueSnackbar(error.message, {
                        variant: 'error',
                    });
                });
        }
    };

    return (
        <Modal open={open} className={classes.modal} onClose={() => setOpen(false)}>
            <div className={classes.modalContainer}>
                <div className={classes.modalHeading}>
                    <span>{title || ''}</span>
                </div>

                {description && (
                    <div className={classes.modalDescription}>
                        <span>
                            <IconsSingleTooltip />
                        </span>
                        <span>{description}</span>
                    </div>
                )}

                <Grid container direction="column">
                    <Grid item>
                        <InputTextArea
                            required
                            type="text"
                            variant="outlined"
                            label={t('profile.team.addNewMember.name')}
                            containerClass={`${classes.input} ${!isInvite ? classes.disabled : ''}`}
                            validateOnBlur
                            validationText={t('validation.name')}
                            onChange={(val, validated) => {
                                setName(() => ({
                                    val,
                                    validated,
                                }));
                            }}
                            initText={name.val}
                            validatedAfterFormSubmit={!(isFormSubmitted && !name.val)}
                            disabled={!isInvite}
                        />
                    </Grid>
                    <Grid item>
                        <InputTextArea
                            required
                            type="text"
                            variant="outlined"
                            label={t('profile.team.addNewMember.surname')}
                            containerClass={`${classes.input} ${!isInvite ? classes.disabled : ''}`}
                            validateOnBlur
                            validationText={t('validation.surname')}
                            onChange={(val, validated) =>
                                setSurname(() => ({
                                    val,
                                    validated,
                                }))
                            }
                            initText={surname.val}
                            validatedAfterFormSubmit={!(isFormSubmitted && !surname.val)}
                            disabled={!isInvite}
                        />
                    </Grid>
                    <Grid item>
                        <InputTextArea
                            required
                            type="mail"
                            variant="outlined"
                            label={t('profile.team.addNewMember.email')}
                            containerClass={`${classes.input} ${!isInvite ? classes.disabled : ''}`}
                            validateOnBlur
                            validationText={t('validation.email')}
                            onChange={(val, validated) =>
                                setEmail(() => ({
                                    val,
                                    validated,
                                }))
                            }
                            initText={email.val}
                            validatedAfterFormSubmit={!(isFormSubmitted && !email.val)}
                            disabled={!isInvite}
                        />
                    </Grid>
                    <Grid item>
                        <InputTextArea
                            required
                            type="text"
                            variant="outlined"
                            label={t('profile.team.addNewMember.jobTitle')}
                            containerClass={classes.input}
                            validateOnBlur
                            validationText={t('validation.jobTitle')}
                            onChange={(val, validated) =>
                                setPosition(() => ({
                                    val,
                                    validated,
                                }))
                            }
                            initText={position.val}
                            validatedAfterFormSubmit={!(isFormSubmitted && !position.val)}
                        />
                    </Grid>
                </Grid>

                <Grid container justify="space-between" className={classes.modalButtons}>
                    <Grid item>
                        <CloseButton
                            onClick={() => {
                                !hasActiveRequest && setOpen(false);
                            }}
                        >
                            {t('profile.user.consultant.meetingCalendar.meetingDetail.close')}
                        </CloseButton>
                    </Grid>
                    <Grid item>
                        <SaveButton
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                            disabled={hasActiveRequest}
                        >
                            {!hasActiveRequest ? buttonText : <ButtonCircularIndicator size={24} />}
                        </SaveButton>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

export default ModalAddNewMember;
