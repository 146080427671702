import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './index.style';
import IconsSingleRightArrow from 'fintech/icons/IconsSingleRightArrow';
import IconsSinglePassword from 'fintech/icons/IconsSinglePassword';
import IconsSingleSignOut from 'fintech/icons/IconsSingleSignOut';
import IconsSingleCloseRounded from 'fintech/icons/IconsSingleCloseRounded';
import { Button } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { getRequest } from 'fintech/api/api';
import { APIS } from 'fintech/constants';
import { UserAvatar } from 'fintech/components/profile/user/Intro/Header/UserAvatar';
import * as ROUTES from 'fintech/routes';
import { useHistory } from 'react-router-dom';
import { doLogout, getLoggedInUserUuid } from 'fintech/utils/Auth';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import IconsSingleCheckBig from 'fintech/icons/IconsSingleCheckBig';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import * as UserActions from 'fintech/store/actions/user';
import EscKeyListener from 'fintech/components/util/EscKeyListener';

const SecureContainer = ({ userUuid, userNameInfo, keycloak }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation('preLogin');
    const [modalActive, setModalActive] = useState(false);
    const [passwordUpdatedWithSuccess, setPasswordUpdatedWithSuccess] = useState(false);
    const [userImage, setUserImage] = useState(null);
    const [formIsSubmitted, setFormIsSubmitted] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const showErrorMessage = (message) => enqueueSnackbar(message, { variant: 'error' });

    useEffect(() => {
        if (!userImage) {
            getRequest(APIS.USER.getUserImage(userUuid), keycloak).then((data) => setUserImage(data));
        }
    }, []);

    const openModal = () => {
        document.body.style.height = '100vh';
        document.body.style.overflowY = 'hidden';
        setModalActive(true);
    };

    const closeModal = () => {
        document.body.style.height = 'auto';
        document.body.style.overflowY = 'auto';
        resetState();
        setModalActive(false);
    };

    const [values, setValues] = useState({
        currentPassword: '',
        newPassword: '',
        reNewPassword: '',
        showCurrentPassword: false,
        showNewPassword: false,
        showReNewPassword: false,
        currentPasswordValidated: false,
        newPasswordValidated: false,
        reNewPasswordValidated: false,
        otpToken: '',
        secondStep: 0,
    });

    const resetState = () => {
        setValues({
            currentPassword: '',
            newPassword: '',
            reNewPassword: '',
            showCurrentPassword: false,
            showNewPassword: false,
            showReNewPassword: false,
            currentPasswordValidated: false,
            newPasswordValidated: false,
            reNewPasswordValidated: false,
            otpToken: '',
            secondStep: 0,
        });
        setPasswordUpdatedWithSuccess(false);
        setFormIsSubmitted(false);
    };

    const handleChange = (key, value, validated) => {
        setValues({ ...values, [key]: value, [key + 'Validated']: validated });
    };

    const handleClickShowPassword = (prop) => {
        setValues({ ...values, [prop]: !values[prop] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePasswordHandler = () => {
        const isValid = values.currentPasswordValidated && values.newPasswordValidated && values.reNewPasswordValidated;
        setFormIsSubmitted(true);

        if (isValid) {
            const passwordData = {
                oldPassword: values.currentPassword,
                newPassword: values.newPassword,
                newPasswordConfirmation: values.reNewPassword,
            };

            if (values.secondStep === 0) {
                getRequest(APIS.USER.getUserOtpControl(), keycloak)
                    .then((result) => {
                        if (result.data) {
                            setValues({ ...values, secondStep: 1 });
                        } else {
                            updatePasswordDispatch(passwordData);
                        }
                    })
                    .catch((error) => showErrorMessage(error.message));
            } else if (values.secondStep === 1 && values.otpToken !== '') {
                passwordData.otpToken = values.otpToken;
                updatePasswordDispatch(passwordData);
            } else {
                showErrorMessage(t('validation.genericError'));
            }
        } else {
            showErrorMessage(t('profile.user.validationError'));
        }
    };

    const updatePasswordDispatch = (passwordData) => {
        dispatch(
            UserActions.updatePassword(
                keycloak,
                () => {
                    setPasswordUpdatedWithSuccess(true);
                },
                (error) => {
                    showErrorMessage(error.message);
                },
                passwordData
            )
        );
    };

    const gotoAppButtonJsx = (
        <div className={classes.goToApp} onClick={() => history.push(ROUTES.PLATFORM)}>
            <span>{t('secureContainer.goToPlatform')}</span>
            <IconsSingleRightArrow></IconsSingleRightArrow>
        </div>
    );

    const changePasswordFormJsx = (
        <>
            <span className={classes.modalTitle}>{t('secureContainer.changePasswordTitle')}</span>
            <span className={classes.modalDef}>
                {values.secondStep === 1
                    ? t('secureContainer.secondStepPasswordVal')
                    : t('secureContainer.passwordVal')}
            </span>
            <form className={classes.changePasswordForm}>
                {values.secondStep === 0 && (
                    <>
                        <div className={classes.changePasswordFormItem}>
                            <InputTextArea
                                label={t('secureContainer.currentPassword')}
                                required={true}
                                validateOnBlur={true}
                                validatedAfterFormSubmit={!formIsSubmitted}
                                validationText={t('validation.notEmpty')}
                                type={values.showCurrentPassword ? 'text' : 'password'}
                                initText={values.currentPassword}
                                onChange={(value, validated) => handleChange('currentPassword', value, validated)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('showCurrentPassword')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                        <div className={classes.changePasswordFormItem}>
                            <InputTextArea
                                label={t('secureContainer.newPassword')}
                                required={true}
                                validateOnBlur={true}
                                validatedAfterFormSubmit={!formIsSubmitted}
                                validationText={t('validation.notEmpty')}
                                type={values.showNewPassword ? 'text' : 'password'}
                                initText={values.newPassword}
                                onChange={(value, validated) => handleChange('newPassword', value, validated)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('showNewPassword')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                        <div className={classes.changePasswordFormItem}>
                            <InputTextArea
                                label={t('secureContainer.reNewPassword')}
                                required={true}
                                validateOnBlur={true}
                                validatedAfterFormSubmit={!formIsSubmitted}
                                validationText={t('validation.notEmpty')}
                                type={values.showReNewPassword ? 'text' : 'password'}
                                initText={values.reNewPassword}
                                onChange={(value, validated) => handleChange('reNewPassword', value, validated)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('showReNewPassword')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showReNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </>
                )}
                {values.secondStep === 1 && (
                    <div className={classes.changePasswordFormItem}>
                        <InputTextArea
                            label={t('secureContainer.otpCode')}
                            fullWidth
                            required={values.secondStep === 1}
                            validateOnBlur={true}
                            validatedAfterFormSubmit={!formIsSubmitted}
                            validationText={t('validation.notEmpty')}
                            type="text"
                            initText={values.otpToken}
                            onChange={(value, validated) => handleChange('otpToken', value, validated)}
                        />
                    </div>
                )}
            </form>
        </>
    );

    const afterChangePasswordFormJsx = (
        <div>
            <div className={classes.successIconDiv}>
                <IconsSingleCheckBig width={62} height={62} />
            </div>
            <div className={classes.updatePasswordSuccessfulTextDiv}>
                {t('secureContainer.updatePasswordSuccessful')}
            </div>
        </div>
    );

    return (
        <>
            <div className={classes.loggedInContainer}>
                <div className={classes.dropdownWrapper}>
                    <div className={classes.profileImg}>
                        <UserAvatar disabled isPreLogin hideBorder={true} userUuid={getLoggedInUserUuid(keycloak)} />
                    </div>
                    <div className={`${classes.dropdownMenu} preLoginSecureDropdownMenu`}>
                        <span className={classes.name}>{`${t('secureContainer.welcome')} ${userNameInfo}`}</span>
                        <div className={classes.menuItem} onClick={openModal}>
                            <IconsSinglePassword></IconsSinglePassword>
                            <span>{t('secureContainer.changePassword')}</span>
                        </div>
                        <div className={classes.menuItem} onClick={() => doLogout(keycloak)}>
                            <IconsSingleSignOut></IconsSingleSignOut>
                            <span>{t('secureContainer.logout')}</span>
                        </div>
                    </div>
                </div>
                {gotoAppButtonJsx}
            </div>
            {modalActive && (
                <>
                    <EscKeyListener onEsc={closeModal} />
                    <div className={classes.changePasswordModal}>
                        <IconsSingleCloseRounded className={classes.closeIcon} onClick={closeModal} />
                        {passwordUpdatedWithSuccess ? afterChangePasswordFormJsx : changePasswordFormJsx}
                        <div className={classes.buttonsWrapper}>
                            <Button className={classes.cancelButton} onClick={closeModal}>
                                {passwordUpdatedWithSuccess ? t('secureContainer.close') : t('secureContainer.cancel')}
                            </Button>
                            {passwordUpdatedWithSuccess ? (
                                gotoAppButtonJsx
                            ) : (
                                <Button className={classes.changePassword} onClick={changePasswordHandler}>
                                    {t('secureContainer.changePassword')}
                                </Button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SecureContainer;
