import { makeStyles } from '@material-ui/core';
import { buttonStyle, COLOR_SECONDARY_BASE, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    titleSvg: {
        marginRight: '20px',
    },
    titleTypo: {
        ...fontStyle,
        fontSize: '32px',
        marginBottom: '16px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: '24px',
        },
    },
    infoTypo: {
        ...fontStyle,
        fontWeight: 'normal',
        fontSize: '16px',
        color: COLOR_SECONDARY_BASE,
        marginBottom: '110px',
        paddingLeft: '60px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            paddingLeft: 0,
        },
    },
    actionBtn: {
        ...buttonStyle,
        marginLeft: '60px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: '100%',
        },
    },
}));

export default useStyles;
