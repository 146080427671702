import { makeStyles } from '@material-ui/core';
import { buttonStyle, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    FullButtonText: ({ queryCondition, otherStyles }) => ({
        ...fontStyle,
        ...buttonStyle,
        maxHeight: '28px',
        margin: '4px 4px',
        [theme.breakpoints.down(queryCondition)]: {
            display: 'none',
        },
        ...otherStyles,
    }),
    ShrinkedButtonIcon: ({ queryCondition, otherStyles }) => ({
        ...buttonStyle,
        maxHeight: '28px',
        maxWidth: '28px',
        minHeight: '22px',
        minWidth: '22px',
        padding: '4px',
        margin: '4px 4px',
        [theme.breakpoints.up(queryCondition)]: {
            display: 'none',
        },
        ...otherStyles,
    }),
    AlwaysShrinkedButtonIcon: ({ otherStyles }) => ({
        ...buttonStyle,
        maxHeight: '28px',
        maxWidth: '28px',
        minHeight: '22px',
        minWidth: '22px',
        padding: '4px',
        margin: '4px 4px',
        ...otherStyles,
    }),
}));

export default useStyles;
