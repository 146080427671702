import React, { useRef } from 'react';
import moment from 'moment';
import IconsMultipleNotifications from 'fintech/icons/IconsMultipleNotifications';
import IconsMultipleNotificationsDisabled from 'fintech/icons/IconsMultipleNotificationsDisabled';
import { Checkbox, Grid } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import { Box } from '@material-ui/core';

import useStyle from 'fintech/components/notificationBox/Notification/index.style';
import { useHover } from 'ahooks';
import { COLOR_PRIMARY_BASE, COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const DATE_FORMAT = 'DD.MM.YYYY';

const Notification = React.forwardRef((props, ref) => {
    const { body, createDate, read } = props.value;
    const styles = useStyle({ isReaded: read });
    const hoverRef = useRef();
    const isHovering = useHover(hoverRef);
    const checkedIcon =
        isHovering && !read ? (
            <CircleCheckedFilled style={{ color: COLOR_PRIMARY_BASE }} />
        ) : (
            <CircleUnchecked style={{ color: COLOR_PRIMARY_BASE }} />
        );

    return (
        <span ref={ref}>
            <div
                onClick={props.handleClick}
                style={{ flexGrow: 1, borderBottom: '1px solid ' + COLOR_PRIMARY_SHADE_02 }}
                ref={hoverRef}
            >
                <Grid container alignItems="center" classes={{ root: styles.root }}>
                    <Grid item xs align="center">
                        <Box className={styles.bellIconContainer}>
                            {read ? (
                                <IconsMultipleNotificationsDisabled className={styles.bellIcon} />
                            ) : (
                                <IconsMultipleNotifications className={styles.bellIcon} />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <Box display="flex" flexDirection="column">
                            <Box className={styles.bodyText}>{body}</Box>
                            <Box className={styles.dateText}>{moment(createDate).format(DATE_FORMAT)}</Box>
                        </Box>
                    </Grid>
                    <Grid item xs className={styles.checkbox}>
                        <Checkbox
                            disabled={read}
                            checked={read}
                            icon={checkedIcon}
                            checkedIcon={<CircleCheckedFilled />}
                        />
                    </Grid>
                </Grid>
            </div>
        </span>
    );
});

export default Notification;
