import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        paddingTop: 48,
        paddingBottom: 40,
        fontSize: 32,
        fontWeight: 500,
        textAlign: 'center',
        background: 'white',
    },
    content: {
        color: '#3f5162',
        textAlign: 'left',
        background: 'white',
        padding: '40px 300px 51px 300px',
        marginBottom: 8,
        fontWeight: 500,
        [theme.breakpoints.down(1500)]: {
            padding: '40px 80px 50px 80px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px',
            fontSize: 12,
        },
    },
}));

export default useStyles;
