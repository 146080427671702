import { makeStyles } from '@material-ui/core';
import { COLOR_DARK_BLUE_GREY } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    barRoot: {
        position: 'fixed',
        zIndex: 100,
        backgroundColor: COLOR_DARK_BLUE_GREY,
        minHeight: 88,
        padding: '12px 12px',
        marginBottom: '8px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23)',
        [theme.breakpoints.up('xl')]: {
            padding: '12px 52px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: ({ reducedPadding }) => (reducedPadding ? '12px 20px' : '12px 52px'),
        },
        [theme.breakpoints.down('sm')]: {
            padding: '12px 20px',
        },
    },
    breadcrumbsRoot: {
        margin: '0 16px',
    },
    breadcrumbsOl: {
        justifyContent: 'center',
    },
    breadcrumbsSeperator: {
        margin: 0,
    },
    logoWrapper: {
        cursor: 'pointer',
    },
    languagePanelWrapper: {
        '& *': {
            color: 'white !important',
        },
    },
}));

export default useStyles;
