import { makeStyles } from '@material-ui/core';
import {
    COLOR_PRIMARY_BASE,
    COLOR_THEME_FILL_01,
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_BASE,
    poppins600_10px,
} from 'fintech/components/style/common';

export default makeStyles({
    root: {
        position: 'fixed',
        width: '560px',
        height: '404px',
        backgroundColor: COLOR_THEME_FILL_01,
        transform: 'translate(-85%, 8px)',
        borderRadius: '8px',
        padding: '2px 2px 120px',
        boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26)',
        userSelect: 'none',
    },

    rootMobile: {
        position: 'fixed',
        left: '2%',
        top: '52px',
        width: '96%',
        height: '412px',
        margin: '0 auto',
        backgroundColor: COLOR_THEME_FILL_01,
        borderRadius: '8px',
        padding: '2px 2px 120px',
        boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26)',
        userSelect: 'none',
    },
    headerContainer: {
        height: '38px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        borderRadius: '8px',
    },
    headerTextPrimary: {
        ...poppins600_10px,
        textTransform: 'uppercase',
        marginLeft: '18px',
    },
    headerTextSecondary: {
        ...poppins600_10px,
        textTransform: 'uppercase',
        marginRight: '24px',
        color: COLOR_PRIMARY_BASE,
        cursor: 'pointer',
    },
    loadingGrid: {
        verticalAlign: 'middle',
        height: '100%',
        color: COLOR_SECONDARY_BASE,
    },
});
