import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import { postRequest } from 'fintech/api/api';
import { postApproveApplication, postRejectApplication } from 'fintech/api/fintechService';
import ApplicationDetails from 'fintech/components/membership/ApplicationDetails/ApplicationDetails';
import ApplicationPickerAndSearch from 'fintech/components/membership/ApplicationPickerAndSearch/ApplicationPickerAndSearch';
import {
    APPLICATION_TYPE_ROLE_IDS,
    ApplicationTypes,
    isMentorOrUser,
} from 'fintech/components/membership/ApplicationTypes';
import getInvestorSections from 'fintech/components/membership/Sections/InvestorSections/getInvestorSections';
import getMentorSections from 'fintech/components/membership/Sections/MentorSections/getMentorSections';
import getStartupSections from 'fintech/components/membership/Sections/StartupSections/getStartupSections';
import getUserSections from 'fintech/components/membership/Sections/UserSections/getUsersSections';
import TypeSelect from 'fintech/components/membership/TypeSelect/TypeSelect';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import { APIS } from 'fintech/constants';
import IconsSingleCheckBig from 'fintech/icons/IconsSingleCheckBig';
import IconsSingleCloseBig from 'fintech/icons/IconsSingleCloseBig';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApplicationStyles, { DetailContent, DetailTitle } from './index.style';
import { format, parse } from 'date-fns';
import { getFnsLocale } from 'fintech/utils/LanguageUtils';
import {
    NUMERIC_FULL_DATE_WITH_FORWARD_SLASH,
    DAY_MONTH_DESC_W_YEAR,
    NUMERIC_FULL_DATE_W_HOUR_SECOND,
} from 'fintech/utils/DateUtils';
import IconsSingleMultipleYatirimci from 'fintech/icons/IconsSingleMultipleYatirimci';
import IconsSingleMultipleProfil from 'fintech/icons/IconsSingleMultipleProfil';
import IconsSingleMultipleGirisimci from 'fintech/icons/IconsSingleMultipleGirisimci';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveRequest } from 'fintech/store/actions/common';
import {
    scrollIntoApplicationDetails,
    scrollIntoApplicationPicker,
    resetInfiniteScrollRelatedStates,
} from 'fintech/components/util/ApplicationsAndSupportProgram';

const getLogoByApplicationType = (type) => {
    if (APPLICATION_TYPE_ROLE_IDS.INVESTOR === type) {
        return <IconsSingleMultipleYatirimci />;
    } else if (APPLICATION_TYPE_ROLE_IDS.STARTUP === type) {
        return <IconsSingleMultipleGirisimci />;
    } else {
        return <IconsSingleMultipleProfil />;
    }
};

const Applications = ({ status, filterTypes }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const { keycloak } = useKeycloak();
    const { t } = useTranslation('membershipManagement');
    const classes = ApplicationStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { hasActiveRequest } = useSelector((state) => state.common);

    const [selectedFilter, setSelectedFilter] = useState(filterTypes[0].value);
    const [requestData, setRequestData] = useState({ searchTerm: '', userApplicationStatus: status });
    const [pickerItems, setPickerItems] = useState([]);
    const [content, setContent] = useState([]);
    const [selectedContentInfo, setSelectedContentInfo] = useState({});
    const [sections, setSections] = useState([]);

    const [pageNumber, setPageNumber] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [openRejectModal, setOpenRejectModal] = useState(false);

    const [totalPages, setTotalPages] = useState(1);
    const [isApplicationDetailLoading, setIsApplicationDetailLoading] = useState(false);
    const [populatePickerItemsLoading, setPopulatePickerItemsLoading] = useState(false);

    const isFirstRender = useRef(true);

    const initialRequestData = useMemo(() => {
        resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
        return {
            userApplicationStatus: status,
            searchTerm: '',
        };
    }, [status]);

    const handleTypeChange = (filter) => {
        if (filter !== selectedFilter) {
            setSelectedFilter(filter);
            setIsApplicationDetailLoading(true);
            // reset previously displayed content details
            setSelectedContentInfo({});
        }
    };

    const handleSearchButton = () => {
        if (!requestData.searchTerm || requestData.searchTerm.length < 1) {
            return;
        }
        resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
        fetchApplications({ makeFirstItemSelected: true, page: 0 });
    };

    const handleInputChange = (value) => {
        if (value.length === 0) {
            resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
            fetchApplications({ makeFirstItemSelected: true, request: initialRequestData, page: 0 });
        }
        setRequestData((prev) => ({
            ...prev,
            searchTerm: value,
        }));
    };

    const observer = useRef();
    const lastPickerItemRef = useCallback(
        (node) => {
            if (pickerItems.length > 0) {
                if (observer.current) observer.current.disconnect();
                observer.current = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting && hasMore) {
                        // This means we are at the end of the scroll and more items exists
                        !isMobile && setPageNumber((prev) => prev + 1);
                    }
                });
                if (node) observer.current.observe(node);
            }
        },
        [hasMore, pickerItems]
    );

    const getSelectedContent = (selectedItem, _content) => {
        setIsApplicationDetailLoading(true);

        const selectedContentDetails = selectedItem ? content.find((cur) => cur.id === selectedItem.id) : _content[0];

        setSelectedContentInfo(selectedContentDetails || {});

        setTimeout(() => {
            setIsApplicationDetailLoading(false);
        }, 500);
        scrollIntoApplicationDetails(selectedItem, isMobile);
    };

    const fetchApplications = ({
        makeFirstItemSelected,
        request = requestData,
        filter = selectedFilter,
        page = pageNumber,
        withPagination = false,
    }) => {
        setPopulatePickerItemsLoading(true);
        postRequest(APIS.USER_APPLICATION.fetchApplicationsUrlByType(filter, page), keycloak, request)
            .then((response) => {
                if (response && response.success && response.data) {
                    let contents = [];

                    isMobile && setTotalPages(response.data.totalPages);

                    if (!withPagination && page > 0) {
                        contents = content.concat(response.data.content);
                    } else {
                        contents = response.data.content;
                    }

                    setContent(contents);
                    setHasMore(!response.data.last);

                    setPickerItems(
                        contents.map((cur, index) => {
                            const name = isMentorOrUser(cur.applicationTypeRoleId)
                                ? `${cur.name} ${cur.surname}`
                                : cur.companyName;

                            return {
                                ...cur,
                                name: name,
                                applicationDate: cur.createDate
                                    ? cur.createDate.split(' -')[0].replace(/\//g, '.')
                                    : '',
                                applicationType: cur.applicationType,
                                // case 1: make first picker item selected if filter type or status change,
                                // case 2: keep previously selected row , after fetching new data through infinite scroll.
                                selected: (makeFirstItemSelected && index === 0) || selectedContentInfo.id === cur.id,
                            };
                        })
                    );

                    // after filter type has changed, populate right panel with first item of contents
                    makeFirstItemSelected && getSelectedContent(null, contents);
                }
            })
            .catch((reason) => {
                // when received an error, reset picker items.
                setPickerItems([]);

                enqueueSnackbar(reason.message, {
                    variant: 'error',
                });
            })
            .finally(() => {
                setPopulatePickerItemsLoading(false);
            });
    };

    const makeApplicationOperation = ({ operation, successMessage, setOpenModal }) => {
        dispatch(setActiveRequest(true));
        resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);

        const { id } = selectedContentInfo;

        operation(keycloak, id)
            .then((response) => {
                dispatch(setActiveRequest(false));
                if (response && response.success && response.data) {
                    enqueueSnackbar(successMessage, {
                        variant: 'success',
                    });
                } else {
                    enqueueSnackbar(response.data.message, {
                        variant: 'error',
                    });
                }
                // The backend is not that fast to provide updated data immediately after rejecting or approving application.
                // to temporarily overcome this, wait 0.5 seconds before fetching updated data.
                setTimeout(() => {
                    fetchApplications({ makeFirstItemSelected: true, request: initialRequestData, page: 0 });
                }, 500);

                setOpenModal(false);
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason.message, {
                    variant: 'error',
                });
            });
    };

    const approveApplication = () => {
        makeApplicationOperation({
            operation: postApproveApplication,
            successMessage: t('validation.approve.success'),
            setOpenModal: setOpenApproveModal,
        });
    };

    const rejectApplication = () => {
        makeApplicationOperation({
            operation: postRejectApplication,
            successMessage: t('validation.reject.success'),
            setOpenModal: setOpenRejectModal,
        });
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });

        resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
        fetchApplications({ makeFirstItemSelected: true, request: initialRequestData, page: 0 });

        // reset searchbox,  after status or filter changed
        setRequestData((prev) => ({
            ...prev,
            searchTerm: '',
        }));
        isFirstRender.current = isMobile;
    }, [status, selectedFilter]);

    useEffect(() => {
        const usePagination = isMobile && !isFirstRender.current;
        const useInfiniteScroll = !isMobile && pageNumber !== 0;

        if (useInfiniteScroll || usePagination) {
            fetchApplications({
                makeFirstItemSelected: !!isMobile,
                request: requestData,
                filter: selectedFilter,
                page: pageNumber,
                withPagination: !!isMobile,
            });
        }
    }, [pageNumber]);

    useEffect(() => {
        setRequestData((prev) => ({
            ...prev,
            userApplicationStatus: status,
        }));
    }, [status]);

    useEffect(() => {
        const { applicationTypeRoleId } = selectedContentInfo;

        let _sections = [];

        if (
            APPLICATION_TYPE_ROLE_IDS.STARTUP === applicationTypeRoleId ||
            selectedFilter === t('filterTypes.onlyStartups.value')
        ) {
            const titles = {
                startupInfos: t('applicationDetails.sections.titles.startupInfos'),
                representativeInfos: t('applicationDetails.sections.titles.representativeInfos'),
            };
            _sections = getStartupSections(titles, selectedContentInfo);
        } else if (
            APPLICATION_TYPE_ROLE_IDS.MENTOR === applicationTypeRoleId ||
            selectedFilter === t('filterTypes.onlyMentors.value')
        ) {
            const titles = {
                personalInfos: t('applicationDetails.sections.titles.personalInfos'),
                coverLetterAndInterests: t('applicationDetails.sections.titles.coverLetterAndInterests'),
            };
            _sections = getMentorSections(titles, selectedContentInfo);
        } else if (
            APPLICATION_TYPE_ROLE_IDS.INVESTOR === applicationTypeRoleId ||
            selectedFilter === t('filterTypes.onlyInvestors.value')
        ) {
            const titles = {
                generalInfos: t('applicationDetails.sections.titles.generalInfos'),
                representativeInfos: t('applicationDetails.sections.titles.representativeInfos'),
            };
            _sections = getInvestorSections(titles, selectedContentInfo);
        } else if (
            APPLICATION_TYPE_ROLE_IDS.USER.includes(applicationTypeRoleId) ||
            selectedFilter === t('filterTypes.onlyUsers.value')
        ) {
            const titles = {
                representativeInfos: t('applicationDetails.sections.titles.representativeInfos'),
            };
            _sections = getUserSections(titles, selectedContentInfo);
        }
        setSections(_sections);
    }, [selectedContentInfo]);

    const handlePageChangeForMobile = (clickedPageNumber) => {
        if (pageNumber === clickedPageNumber - 1) return;
        isFirstRender.current = false;
        setPageNumber(clickedPageNumber - 1);
        isMobile && scrollIntoApplicationPicker();
    };

    return (
        <>
            <TypeSelect
                items={filterTypes}
                onClick={handleTypeChange}
                className={classes.typeSelect}
                loading={isApplicationDetailLoading}
            />
            <Grid container className={classes.contentContainer}>
                <ApplicationPickerAndSearch
                    className={classes.picker}
                    ref={lastPickerItemRef}
                    pickerItems={pickerItems}
                    // hızlandırma programlarındaki select icin, true setle.
                    showApplicationProgramSelectBox={false}
                    onChange={handleInputChange}
                    searchTerm={requestData.searchTerm}
                    handleSearchButton={handleSearchButton}
                    getSelectedContentCallback={getSelectedContent}
                    buttonText={t('applicationDetails.picker.search.buttonText')}
                    placeholder={t('applicationDetails.picker.search.placeholder')}
                    getLogoByApplicationType={getLogoByApplicationType}
                    handlePageChangeForMobile={handlePageChangeForMobile}
                    totalPagesForMobile={totalPages}
                    isLoading={populatePickerItemsLoading}
                />
                <ApplicationDetails
                    status={status}
                    getQuadGridItems={() => getQuadGridItems(t, selectedContentInfo, classes)}
                    sections={sections}
                    buttonsOperation={{
                        reject: {
                            text: t('applicationDetails.applicationOperation.reject'),
                            handler: () => setOpenRejectModal(true),
                        },
                        approve: {
                            text: t('applicationDetails.applicationOperation.approve'),
                            handler: () => setOpenApproveModal(true),
                        },
                        showButtons: selectedContentInfo?.id ? true : false,
                    }}
                    isLoading={isApplicationDetailLoading}
                />
            </Grid>

            <ConfirmDialog
                open={openApproveModal}
                icon={
                    <IconsSingleCheckBig
                        style={{ width: '62px', height: '62px', backgroundColor: '#e6fae6', borderRadius: '50%' }}
                    />
                }
                title={t('validation.approve.question')}
                labelConfirm={t('applicationDetails.applicationOperation.confirm')}
                labelCancel={t('applicationDetails.applicationOperation.cancel')}
                dialogRootClass={classes.dialogRoot}
                confirmDialogContentClass={classes.confirmDialogContentSuccess}
                onConfirm={approveApplication}
                onCancel={() => {
                    setOpenApproveModal(false);
                }}
                showTopCloseButton
                confirmDisabled={hasActiveRequest}
                cancelDisabled={hasActiveRequest}
            />

            <ConfirmDialog
                open={openRejectModal}
                icon={
                    <IconsSingleCloseBig
                        style={{ width: '62px', height: '62px', backgroundColor: '#fae6e6', borderRadius: '50%' }}
                    />
                }
                title={t('validation.reject.question')}
                labelConfirm={t('applicationDetails.applicationOperation.confirm')}
                labelCancel={t('applicationDetails.applicationOperation.cancel')}
                dialogRootClass={classes.dialogRoot}
                confirmDialogContentClass={classes.confirmDialogContentFailed}
                onConfirm={rejectApplication}
                onCancel={() => {
                    setOpenRejectModal(false);
                }}
                showTopCloseButton
                confirmDisabled={hasActiveRequest}
                cancelDisabled={hasActiveRequest}
            />
        </>
    );
};

export default Applications;

const getQuadGridItems = (t, selectedContentInfo, classes) => {
    const itemsJSX = [];
    const {
        approverFullName,
        applicationTypeRoleId,
        applicationType,
        createDate: fullCreateDate,
        updateDate: fullUpdateDate,
        status,
    } = selectedContentInfo;

    let applicationTypeText;

    if (
        applicationTypeRoleId === APPLICATION_TYPE_ROLE_IDS.STARTUP ||
        applicationTypeRoleId === APPLICATION_TYPE_ROLE_IDS.INVESTOR
    ) {
        applicationTypeText = t(`applicationDetails.sections.applicationTypes.${applicationType}`);
    } else {
        applicationTypeText = applicationType || '-';
    }

    const createDate = fullCreateDate ? fullCreateDate.split('-')[0].trim() : '';
    const createTime = fullCreateDate ? fullCreateDate.split('-')[1] : '';

    let formattedCreateDate = '-';
    if (createDate) {
        formattedCreateDate = format(
            parse(createDate, NUMERIC_FULL_DATE_WITH_FORWARD_SLASH, new Date()),
            DAY_MONTH_DESC_W_YEAR,
            {
                locale: getFnsLocale(),
            }
        );
        formattedCreateDate += ` ${createTime ? `- ${createTime}` : ''}`;
    }

    let formattedFullUpdateDate = '';
    if (fullUpdateDate) {
        formattedFullUpdateDate = `(${format(new Date(fullUpdateDate), NUMERIC_FULL_DATE_W_HOUR_SECOND, {
            locale: getFnsLocale(),
        })})`;
    }

    const approverFullNameWithDate = formattedFullUpdateDate
        ? `${approverFullName || ''} ${formattedFullUpdateDate}`
        : approverFullName;

    itemsJSX.push(
        <IntroGridItem
            key="applicationType"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.application.type')}</DetailTitle>}
            content={<DetailContent>{applicationTypeText}</DetailContent>}
        />
    );

    itemsJSX.push(
        <IntroGridItem
            key="applicationDate"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.application.date')}</DetailTitle>}
            content={<DetailContent>{formattedCreateDate || ''}</DetailContent>}
        />
    );

    let adminConfirmationContent;
    if (status !== ApplicationTypes.PENDING && approverFullNameWithDate) {
        const isApproved = status === ApplicationTypes.ACCEPTED;
        adminConfirmationContent = (
            <Grid
                container
                alignItems="center"
                className={isApproved ? classes.approvedConfirmationText : classes.rejectedConfirmationText}
            >
                <Grid item>{isApproved ? '✓' : ' X'}</Grid>
                <Grid item>{approverFullNameWithDate}</Grid>
            </Grid>
        );
    } else {
        adminConfirmationContent = '-';
    }

    itemsJSX.push(
        <IntroGridItem
            key="applicationAdminConfirmation"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.application.adminConfirmation')}</DetailTitle>}
            content={<div className={classes.contentDivStyle}>{adminConfirmationContent}</div>}
        />
    );

    return itemsJSX;
};
