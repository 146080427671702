import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_THEME_FILL_01,
    COLOR_SECONDARY_SHADE_01,
    COLOR_THEME_FILL_06,
    buttonStyle,
    fontStyle,
    buttonStyleColorAlt,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    SettingsGrid: {
        ...fontStyle,
        marginTop: '26px',
        backgroundColor: COLOR_THEME_FILL_01,
        padding: '4px',
        borderRadius: 8,
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
    },
    infoDiv: {
        fontSize: 16,
        marginTop: 24,
        color: COLOR_SECONDARY_SHADE_01,
        marginLeft: 25,
        marginBottom: 35,
    },
    changePasswordForm: {
        width: '544px',
        marginBottom: '24px !important',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            maxWidth: '90%',
        },
    },
    changePasswordFormItem: {
        height: 80,
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 36,
        marginBottom: 36,
        padding: '8px 24px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 15,
            marginBottom: 15,
        },
    },
    cancelButton: {
        ...buttonStyleColorAlt,
        width: 97,
        height: 48,
        margin: '0 24px 0 0',
        padding: '14px 22px',
    },
    changePassword: {
        ...buttonStyle,
        width: 'fit-content',
        height: 48,
        margin: '0 0 0 24px',
        padding: '14px 22px',
    },
    successIconDiv: {
        textAlign: 'center',
        marginTop: 100,
        marginBottom: 40,
        '& > svg': {
            backgroundColor: COLOR_THEME_FILL_06,
            borderRadius: 62,
        },
    },
    updatePasswordSuccessfulTextDiv: {
        fontSize: 24,
        fontWeight: 600,
        marginBottom: 126,
        textAlign: 'center',
    },
}));

export default useStyles;
