import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { isNil } from 'lodash';
import useStyles, { useTabContainerStyles, useTabStyles } from './index.styles';
import {
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
    Typography,
    Tabs,
    Tab,
} from '@material-ui/core';
import { COLOR_SECONDARY_BASE, fontStyle, mobileMediaQuery } from 'fintech/components/style/common';

const NavTabs = (props) => {
    const {
        tabs,
        value: initValue,
        onChange,
        dropdownLimit = 1,
        keepValueOnParent,
        tabProps,
        ...others
    } = props;
    const classes = useStyles();
    const tabContainerClasses = useTabContainerStyles({ multipleTabs: tabs?.length > dropdownLimit });
    const tabClasses = useTabStyles();

    const matches = !useMediaQuery(mobileMediaQuery);
    let value, setValue;
    let itemValue, setItemValue;
    if (keepValueOnParent) {
        value = itemValue = initValue;
        setValue = setItemValue = value => onChange(null, value);
    } else {
        [value, setValue] = useState(initValue);
        [itemValue, setItemValue] = useState(initValue);
    }

    // Handle mobile view - start
    const [isMobile, setMobile] = useState(false);
    const [subOpen, setSubOpen] = useState(false);
    const optionRef = useRef(null);

    useEffect(() => {
        if (matches && !isMobile && tabs?.length > dropdownLimit) {
            // Transition to mobile
            setMobile(true);
            const val = value;
            const tab = getSelectedTab(tabs, val);
            setItemValue(val);
            setValue(tab.value);
        } else if (!matches && isMobile && tabs?.length > dropdownLimit) {
            // Transition to desktop
            setMobile(false);
            setValue(itemValue);
        }
    }, [matches]);

    // Options
    const handleSubToggle = () => {
        setSubOpen((prevOpen) => !prevOpen);
    };

    const handleSubClose = (event) => {
        if (optionRef.current && optionRef.current.contains(event.target)) {
            return;
        }

        setSubOpen(false);
    };

    const onChangeHandler = (event, value, listItemClick = false) => {
        if (!isMobile || listItemClick) {
            onChange && onChange(event, value);
            !listItemClick ? setValue(value) : setItemValue(value);

            if (!listItemClick) {
                setValue(value);
            } else {
                setItemValue(value);
                handleSubToggle();
            }
        } else {
            handleSubToggle();
        }
    };

    const getSelectedTab = (tabs, val) => {
        let selectedIdx = tabs.findIndex((o) => o.value === val);

        if (selectedIdx === -1) {
            selectedIdx = !isNil(tabs[val]) ? val : -1;
        }
        const idx = selectedIdx !== -1 ? selectedIdx : 0;

        return {
            ...tabs[idx],
            value: !isNil(tabs[idx].value) ? tabs[idx].value : idx,
        };
    };

    let tabsJSX = null;
    const tab = getSelectedTab(tabs, itemValue);
    if (isMobile && tabs?.length > dropdownLimit) {
        tabsJSX = (
            <Tabs
                classes={tabContainerClasses}
                {...others}
                ref={optionRef}
                value={tab.value ? tab.value : 0}
                onChange={(event, value) => onChangeHandler(event, value)}
            >
                [
                <Tab
                    classes={tabClasses}
                    key={tab.label}
                    disableRipple
                    label={tab.label}
                    value={tab.value}
                    {...tabProps}
                />
                ,
                <Tab
                    classes={tabClasses}
                    style={{ paddingLeft: 0, marginLeft: '-10px' }}
                    key="dropdown"
                    disableRipple
                    label={subOpen ? <ExpandLess /> : <ExpandMore />}
                    value={tab.value}
                />
                ]
            </Tabs>
        );
        // Handle mobile view - end
    } else {
        tabsJSX = (
            <Tabs
                classes={tabContainerClasses}
                {...others}
                ref={optionRef}
                value={value ? value : 0}
                onChange={(event, value) => onChangeHandler(event, value)}
            >
                {tabs.map((tab, idx) => (
                    <Tab
                        classes={tabClasses}
                        style={tabs.length === 1 ? { cursor: 'default' } : null}
                        key={tab.label}
                        disableRipple
                        label={tab.label}
                        value={tab.value ? tab.value : idx}
                        {...tabProps}
                    />
                ))}
            </Tabs>
        );
    }

    return (
        <>
            {tabsJSX}
            <Popper
                style={{ zIndex: '50' }}
                open={subOpen}
                anchorEl={optionRef.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom-start"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'center top',
                        }}
                    >
                        <Paper classes={{ root: classes.paperRoot, elevation1: classes.paperElevation }}>
                            <ClickAwayListener onClickAway={handleSubClose}>
                                <List>{tabs && renderSubOptions(tabs, onChangeHandler, tab?.value)}</List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

const renderSubOptions = (tabs, onChange, selected) => {
    const handleItemOnClick = (event, value) => {
        onChange(event, value, true);
    };

    const listTabs = [];

    tabs.forEach((tab, idx) => {
        if (!isNil(tab.value) && tab.value === selected) {
            return;
        } else if (idx === selected) {
            return;
        } else {
            listTabs.push(
                <ListItem
                    key={`ntabs-${idx}-${tab.value}`}
                    style={{ cursor: 'pointer' }}
                    onClick={(event) => handleItemOnClick(event, tab.value ? tab.value : idx)}
                >
                    <ListItemText style={{ margin: 0 }}>
                        <Typography style={{ ...fontStyle, color: COLOR_SECONDARY_BASE, padding: 0 }}>
                            {tab.label}
                        </Typography>
                    </ListItemText>
                </ListItem>
            );
        }
    });

    return listTabs;
};

NavTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
    ),
    onChange: PropTypes.func,
};

export default NavTabs;
