import { makeStyles, withStyles, Button } from '@material-ui/core';
import {
    COLOR_THEME_FILL_01,
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_SHADE_03,
    COLOR_PRIMARY_BASE,
    COLOR_ROW_HOVERED,
    COLOR_SECONDARY_SHADE_01,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: COLOR_THEME_FILL_01,
        width: '32.4%',
        [theme.breakpoints.between(900, 1280)]: {
            width: '40.4%',
        },
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        overflowX: 'hidden',
        overflowY: 'auto',

        [theme.breakpoints.down('sm')]: {
            marginBottom: '12px',
        },
    },
    searchInputGrid: {
        flexGrow: '1',
        width: 'min-content',
    },
    searchBoxContainer: {
        padding: '20px',
    },
    searchBox: {
        borderRadius: '8px',
        border: '1px solid',
        borderColor: COLOR_PRIMARY_SHADE_03,
        padding: '14px 16px 14px 14px',
        alignItems: 'center',

        '& > div:last-child': {
            marginLeft: 'auto',
        },
    },
    input: {
        width: '100%',
        outline: 'none',
        border: 'none',
        flexGrow: 1,
    },
    item: {
        alignItems: 'center',
        padding: '4px 20px',
        marginBottom: '8px',
        cursor: 'pointer',
        '& > :last-child': {
            marginLeft: 'auto',
        },
    },
    logo: {
        padding: '8px',
        backgroundColor: '#f1f5f7',
        borderRadius: '50%',
        aspectRatio: '1/1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        maxWidth: '40px',
        '& svg': {
            width: '80%',
        },
    },
    selected: {
        backgroundColor: COLOR_SECONDARY_BASE,
        color: COLOR_THEME_FILL_01,
    },
    notSelected: {
        '&:hover': {
            backgroundColor: COLOR_ROW_HOVERED,
        },
    },
    name: {
        paddingLeft: '12px',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        fontWeight: 600,
    },
    date: {
        textAlign: 'right',
        fontSize: '12px',
        fontWeight: 500,
        color: COLOR_SECONDARY_SHADE_01,
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverPaper: {
        padding: theme.spacing(1),
    },
    appliedProgramsSelectContainer: {
        padding: '20px 20px 0 20px',

        '& > div:first-child': {
            width: '100%',
        },
    },
    notFound: {
        padding: '30px',
        fontWeight: 500,
        textAlign: 'center',
    },
    reviewIconWrapper: {
        float: 'right',
        [theme.breakpoints.between(1280, 1600)]: {
            float: 'none',
        },
    },
    emptyCircle: {
        borderRadius: '50%',
        border: `1px solid ${COLOR_PRIMARY_BASE}`,
        width: '16px',
        height: '16px',
    },
    select: {
        '& > div:first-child': {
            fontWeight: '500',
            fontSize: '14px',
        },
    },
    pagination: {
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
}));

export const SearchButton = withStyles({
    root: {
        textTransform: 'none',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        borderRadius: '8px',
        padding: '4px 22px',
        marginLeft: 'auto',
        color: COLOR_SECONDARY_BASE,

        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        },
    },
})(Button);

export default useStyles;
