import { makeStyles } from '@material-ui/core/styles';
import { cardGridStyle, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    AnnGrid: {
        ...cardGridStyle,
        ...fontStyle,
        marginTop: '26px',
        padding: '26px 20px 20px',
    },
    AnnContent: {
        ...fontStyle,
        textAlign: 'start',
        color: '#3f5162',
        whiteSpace: 'pre-wrap',
    },
});

export default useStyles;
