import { withStyles, makeStyles, Typography } from '@material-ui/core';
import {
    fontStyle,
    COLOR_PRIMARY_BASE,
    COLOR_SECONDARY_SHADE_01,
    COLOR_SECONDARY_BASE,
} from 'fintech/components/style/common';

const useStyles = makeStyles(() => ({
    item: {
        display: 'flex',
        flexDirection: 'column',
    },
    url: {
        color: `${COLOR_PRIMARY_BASE}!important`,
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
}));

export const Title = withStyles({
    root: {
        ...fontStyle,
        color: COLOR_SECONDARY_SHADE_01,
        textAlign: 'start',
        marginTop: '16px',
        marginBottom: '8px',
    },
})(Typography);

export const Content = withStyles({
    root: {
        ...fontStyle,
        color: COLOR_SECONDARY_BASE,
        marginBottom: '8px',
    },
})(Typography);

export default useStyles;
