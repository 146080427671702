import React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import { laptopLarge } from 'fintech/components/style/common';

const StrechedGridItem = (props) => {
    const { title, content, wrapperClassName } = props;

    const matches = useMediaQuery(laptopLarge);

    if (matches) {
        return (
            <div style={{ width: '100%' }} className={wrapperClassName}>
                <Box display="flex">
                    <Box style={{ width: '164px' }}>{title}</Box>
                    <Box width="100%">{content}</Box>
                </Box>
            </div>
        );
    }
    return <IntroGridItem title={title} content={content} />;
};

export default StrechedGridItem;
