import React, { useState } from 'react';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadingSpinnerWithTextGeneralTextStyle, mobileMediaQuery } from 'fintech/components/style/common';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import CfoFormLabel from 'fintech/components/applications/form/common/CfoFormLabel';
import { SupportProgramApplicationActions } from 'fintech/store/actions/ActionTypes';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import useStyles from 'fintech/components/support/program/form/SupportProgramApplication/index.style';
import {
    Typography,
    Grid,
    FormControlLabel,
    Radio,
    RadioGroup,
    useMediaQuery,
    Button,
    FormControl,
    FormHelperText,
} from '@material-ui/core';
import * as FintechBeService from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import {
    CATEGORY_INTELLECTUAL_PROPERTY,
    generateIntellectualPropertyAnswers,
    generateRepInfoData,
} from 'fintech/components/support/program/utils/SupportProgramApplicationUtils';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const IntellectualProperty = ({ pageFunctions: { prevPage, nextPage }, formId }) => {
    const { t } = useTranslation('supportProgramApplication');
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { form, hasActiveRequest } = useSelector((state) => ({
        ...state.supportProgramApplication,
        ...state.common,
    }));
    const questions = form.questionFormatList.slice(13, 17);
    const matches = useMediaQuery(mobileMediaQuery);

    const [sending, setSending] = useState(false);

    const getCorrespondingInput = (question) => {
        switch (question.questionNumber) {
            case 14:
                return (
                    <InputTextArea
                        type="text"
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q14,
                                data: { value, validated },
                            });
                        }}
                        required={question.required === 'REQUIRED'}
                        rowCount={5}
                        count={question.answerDefinitionList[0].textLimit ?? 400}
                        containerClass={classes.inputTextAreaWrapper}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        initText={question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''}
                    />
                );
            case 15: {
                const { answerOptionList } = question.answerDefinitionList[0];

                const val = question?.selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id;
                const error = sending && !val;
                return (
                    <FormControl error={error}>
                        <RadioGroup
                            className={classes.radioWrapper}
                            row
                            value={val ? `${val}` : ''}
                            onChange={({ target: { value } }) => {
                                dispatch({
                                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q15,
                                    data: { value: +value },
                                });
                            }}
                        >
                            {answerOptionList.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    value={`${option.id}`}
                                    control={<Radio classes={{ colorPrimary: classes.radio }} color="primary" />}
                                    label={option.name}
                                />
                            ))}
                        </RadioGroup>
                        {error && (
                            <FormHelperText className={classes.formHelperWrapper} error>
                                {t('validation.selectionNotEmpty')}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }
            case 16:
                return (
                    <InputTextArea
                        type="text"
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q16,
                                data: { value, validated },
                            });
                        }}
                        required={question.required === 'REQUIRED'}
                        rowCount={5}
                        count={question.answerDefinitionList[0].textLimit ?? 1000}
                        containerClass={classes.inputTextAreaWrapper}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                        initText={question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''}
                    />
                );
            case 17: {
                const { answerOptionList } = question.answerDefinitionList[0];

                const val = question?.selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id;
                const error = sending && question.required === 'REQUIRED';

                return (
                    <FormControl error={error}>
                        <RadioGroup
                            className={classes.radioWrapper}
                            row
                            value={val ? `${val}` : ''}
                            onChange={({ target: { value } }) => {
                                dispatch({
                                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q17,
                                    data: { value: +value },
                                });
                            }}
                        >
                            {answerOptionList.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    value={`${option.id}`}
                                    control={<Radio classes={{ colorPrimary: classes.radio }} color="primary" />}
                                    label={option.name}
                                />
                            ))}
                        </RadioGroup>
                        {error && (
                            <FormHelperText className={classes.formHelperWrapper} error>
                                {t('validation.selectionNotEmpty')}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }
            default:
                return null;
        }
    };

    const isValid = () =>
        // Validate Q14 FREE_TEXT
        questions[0].freeTextAnswerList &&
        questions[0].freeTextAnswerList[0]?.validated &&
        // Validate Q15 RADIO_BUTTON
        questions[1].selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id &&
        // Validate Q16 FREE_TEXT
        (questions[2].freeTextAnswerList ? questions[2].freeTextAnswerList[0]?.validated : true);

    const clickContinueHandler = async () => {
        setSending(true);

        if (isValid()) {
            dispatch(setActiveRequest(true));
            const requestData = {};
            requestData.supportApplicantInfo = generateRepInfoData(form.supportApplicantInfo);
            requestData.questionList = generateIntellectualPropertyAnswers(questions);

            let res = null;
            try {
                res = await FintechBeService.supportProgramApply({
                    keycloak,
                    formId,
                    requestData,
                    category: CATEGORY_INTELLECTUAL_PROPERTY,
                });
                dispatch(setActiveRequest(false));
            } catch (reason) {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            if (!res || !res.success) {
                enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            nextPage();
        } else {
            enqueueSnackbar(t('validation.genericMessage'), { variant: 'error' });
        }
    };

    const isLoading = form.isLoading || questions.length < 1;

    return isLoading ? (
        <LoadingSpinnerWithText style={loadingSpinnerWithTextGeneralTextStyle} />
    ) : (
        <CfoFormContainer>
            {matches && <Typography className={classes.pageTitle}>{t('pageTitles.intellectualProperty')}</Typography>}
            {questions.map((question) => (
                <Grid
                    item
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    key={question.questionNumber}
                    style={{ paddingBottom: '24px' }}
                >
                    <CfoFormLabel
                        label={`${question.questionNumber}. ${question.name}`}
                        subText={question.subText}
                        isOptional={question.required === 'OPTIONAL'}
                    />
                    <CfoFormInput>{getCorrespondingInput(question)}</CfoFormInput>
                </Grid>
            ))}
            <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className={classes.buttonsWrapper}
            >
                <CfoFormInput xs={4} lg={3}>
                    <Button
                        startIcon={matches && <ArrowBack />}
                        className={classes.secondaryButton}
                        onClick={prevPage}
                        fullWidth
                        disabled={hasActiveRequest}
                    >
                        {matches ? t('buttonTexts.returnToPreviousStep') : t('buttonTexts.previousStep')}
                    </Button>
                </CfoFormInput>
                <CfoFormInput xs={7} lg={8}>
                    <Button
                        className={classes.primaryButton}
                        onClick={clickContinueHandler}
                        fullWidth
                        disabled={hasActiveRequest}
                    >
                        {!hasActiveRequest ? t('buttonTexts.continue') : <ButtonCircularIndicator />}
                    </Button>
                </CfoFormInput>
            </Grid>
        </CfoFormContainer>
    );
};

export default IntellectualProperty;
