import { makeStyles } from '@material-ui/core';
import { buttonStyle, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    mentorWrapper: {
        marginTop: 35,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    definition: {
        display: 'block',
        textAlign: 'center',
        fontSize: 16,
        color: '#3f5162',
    },
    applyButton: {
        ...fontStyle,
        ...buttonStyle,
        '@media (max-width:320px)': {
            fontSize: 11,
        },
    },
}));

export default useStyles;
