import * as React from 'react';

function SvgIconsSingleChevronDown(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <path d="M17.5 10.298L16.202 9 12 13.224 7.798 9 6.5 10.298l5.5 5.5z" fill="#3F5162" fillRule="nonzero" />
        </svg>
    );
}

export default SvgIconsSingleChevronDown;
