import { makeStyles } from '@material-ui/core';
import { poppinsBold_18px } from 'fintech/components/style/common';

const useStyles = makeStyles(() => ({
    card: {
        borderRadius: ' 8px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        '& > .header': {
            width: '100%',
            height: '72px',
            display: 'flex',
            borderBottom: '1px solid #d7e2e8',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 24px',
            backgroundColor: 'white',
            borderRadius: '8px',

            '& > div': {
                display: 'flex',
                '&:last-child': {
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                },
            },
            '& > div > p': { ...poppinsBold_18px, margin: '0 0 0 8px' },
        },

        '& > .body': {
            backgroundColor: 'white',
            width: '100%',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            padding: '24px',
            display: 'none',
            '& > .loadingContainer': {
                width: '100%',
                height: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        '& .collapseButton': {
            height: '32px !important',
            minWidth: '32px !important',
            padding: '0',
        },
        '&.show': {
            height: '100%',
            '& > .header': {
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
            },
            '& > .body': {
                display: 'block',
                height: 'calc(100% - 72px)',
            },
        },
    },
}));

export default useStyles;
