import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_SHADE_02, COLOR_SECONDARY_BASE, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    VisibilityDialog: {
        marginTop: '50px',
        padding: '16px',
        margin: '0 24px 40px',
        borderRadius: '4px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
    },
    VisibilityLabel: {
        ...fontStyle,
        lineHeight: 1.71,
        letterSpacing: '0.13px',
        color: COLOR_SECONDARY_BASE,
    },
    VisibilityDesc: {
        ...fontStyle,
        fontSize: '12px',
        color: COLOR_SECONDARY_BASE,
    },
});

export default useStyles;
