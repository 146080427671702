import { makeStyles } from '@material-ui/core';
import { COLOR_THEME_FILL_01 } from 'fintech/components/style/common';

const useStyles = makeStyles(() => ({
    contentWrapper: {
        backgroundColor: COLOR_THEME_FILL_01,
        minHeight: '630px',
        margin: (barHeight) => (barHeight ? `${barHeight}px 8px 8px 8px` : '0 8px 8px 8px'),
    },
}));

export default useStyles;
