import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery } from '@material-ui/core';
import { mobileMediaQuery } from 'fintech/components/style/common';
import useStyles, { CenteredDialogActions } from './index.style';
import IconsSingleCloseRounded from 'fintech/icons/IconsSingleCloseRounded';
import ButtonCircularIndicator from '../../spinner/ButtonCircularIndicator';
import EscKeyListener from 'fintech/components/util/EscKeyListener';

const ConfirmDialog = (props) => {
    const classes = useStyles();
    const matches = useMediaQuery(mobileMediaQuery);
    const {
        open,
        icon,
        title,
        injectHtml,
        content,
        labelCancel,
        labelConfirm,
        onCancel,
        onConfirm,
        dialogRootClass,
        confirmDialogContentClass,
        hideCancelButton,
        showTopCloseButton,
        confirmDisabled = false,
        cancelDisabled = false,
        showSpinnerWhenDisabled = true,
    } = props;

    let contentJSX = null;
    if (injectHtml) {
        contentJSX = (
            <Typography className={classes.ConfirmDialogContentTypo} dangerouslySetInnerHTML={{ __html: content }} />
        );
    } else {
        contentJSX = <Typography className={classes.ConfirmDialogContentTypo}>{content}</Typography>;
    }
    const confirmDialogRoot = `${classes.ConfirmDialogRoot} ${dialogRootClass || ''} `;
    const confirmDialogContent = `${classes.ConfirmDialogContent} ${confirmDialogContentClass || ''}`;
    return (
        <div>
            {open && (
                <EscKeyListener
                    onEsc={(e) => {
                        !cancelDisabled && onCancel(e);
                    }}
                />
            )}
            <Dialog id="dialog-id" className={confirmDialogRoot} open={open} disableEscapeKeyDown={cancelDisabled}>
                <DialogTitle className={classes.ConfirmDialogTitle}>
                    {showTopCloseButton && (
                        <IconsSingleCloseRounded
                            onClick={(e) => {
                                !cancelDisabled && onCancel(e);
                            }}
                            style={{ position: 'absolute', top: '8px', right: '8px', cursor: 'pointer' }}
                        />
                    )}
                    {icon ? icon : null}
                    <Typography className={classes.ConfirmDialogTitleTypo}>{title}</Typography>
                </DialogTitle>
                <DialogContent
                    className={confirmDialogContent}
                    style={matches ? { paddingLeft: '125px', paddingRight: '125px' } : null}
                >
                    {contentJSX}
                </DialogContent>
                <CenteredDialogActions>
                    {!hideCancelButton && (
                        <Button
                            className={classes.CancelBtn}
                            onClick={onCancel}
                            color="primary"
                            disabled={cancelDisabled}
                        >
                            {labelCancel}
                        </Button>
                    )}
                    <Button
                        className={classes.OkBtn}
                        onClick={onConfirm}
                        color="primary"
                        autoFocus
                        disabled={confirmDisabled}
                    >
                        {!confirmDisabled || !showSpinnerWhenDisabled ? (
                            labelConfirm
                        ) : (
                            <ButtonCircularIndicator size={24} />
                        )}
                    </Button>
                </CenteredDialogActions>
            </Dialog>
        </div>
    );
};

ConfirmDialog.propTypes = {
    open: PropTypes.bool,
    icon: PropTypes.element,
    // title: PropTypes.string.isRequired,
    injectHtml: PropTypes.bool,
    content: PropTypes.string,
    labelCancel: PropTypes.string.isRequired,
    labelConfirm: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default ConfirmDialog;
