import * as React from 'react';

function SvgIconsDanısmanlar(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        fill="#D9DCE0"
                                        d="M22.5 34.375C11.25 31.25 11.25 45 0 37.5V20c11.25 7.5 11.25-6.25 22.5-3.125v17.5zM17.5 40L37.5 40 37.5 36.875 17.5 36.875zM30.625 15L27.5 16.25 24.375 15 24.375 12.5 30.625 12.5z"
                                        transform="translate(-497 -1644) translate(0 1579) translate(354 65) translate(143)"
                                    />
                                    <path
                                        fill="#D9DCE0"
                                        d="M23.75 16.875c-5 3.125-8.125 4.375-8.125 7.5v10c0 1.38 1.12 2.5 2.5 2.5h18.75c1.38 0 2.5-1.12 2.5-2.5v-10c0-3.125-3.125-4.375-8.125-7.5h-7.5z"
                                        transform="translate(-497 -1644) translate(0 1579) translate(354 65) translate(143)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M22.5 5.625c-.346 0-.625.28-.625.625v1.875c0 .345.28.625.625.625.346 0 .625-.28.625-.625V6.25c0-.345-.28-.625-.625-.625M32.5 5.625c-.346 0-.625.28-.625.625v1.875c0 .345.28.625.625.625.346 0 .625-.28.625-.625V6.25c0-.345-.28-.625-.625-.625"
                                        transform="translate(-497 -1644) translate(0 1579) translate(354 65) translate(143)"
                                    />
                                    <path
                                        fill="#E8E6E4"
                                        d="M32.5 6.25V7.5c0 1.875-.867 3.61-1.875 5.625-.625 1.25-3.125 1.25-3.125 1.25s-2.5 0-3.125-1.25C23.367 11.109 22.5 9.375 22.5 7.5V6.25c1.25 0 1.875-3.125 1.875-3.125S25.625 5 27.5 5h3.125c1.25 0 1.875 1.25 1.875 1.25"
                                        transform="translate(-497 -1644) translate(0 1579) translate(354 65) translate(143)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M22.5 4.375V6.25c1.25 0 1.875-3.125 1.875-3.125S25.625 5 27.5 5h3.125c1.25 0 1.875 1.25 1.875 1.25V4.375c0-2.5-1.875-4.375-5-4.375-1.25 0-1.875.625-1.875.625s-3.125 0-3.125 3.75M33.754 32.83c-.133 0-.265-.014-.397-.043L22.346 29.98c-.335-.085-.537-.426-.452-.76.086-.335.425-.536.76-.452l10.988 2.8c.161.037.354-.01.5-.13.148-.118.233-.295.233-.485V25c0-.345.28-.625.625-.625s.625.28.625.625v5.954c0 .57-.254 1.1-.696 1.458-.338.273-.75.417-1.175.417"
                                        transform="translate(-497 -1644) translate(0 1579) translate(354 65) translate(143)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M35 37.5c-2.534 0-13.363-3.886-14.193-4.185-.833-.203-1.432-.962-1.432-1.822V25c0-.345.28-.625.625-.625s.625.28.625.625v6.493c0 .291.207.548.492.61l.08.023C24.37 33.273 33.038 36.25 35 36.25h1.875c1.034 0 1.875-.841 1.875-1.875v-10c0-2.25-1.947-3.419-5.483-5.54-.727-.436-1.51-.907-2.348-1.43-.293-.183-.382-.569-.199-.861.183-.293.569-.382.861-.199.83.519 1.608.986 2.329 1.418 3.667 2.2 6.09 3.654 6.09 6.612v10c0 1.723-1.402 3.125-3.125 3.125H35z"
                                        transform="translate(-497 -1644) translate(0 1579) translate(354 65) translate(143)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M27.5 37.5h-9.375C16.402 37.5 15 36.098 15 34.375v-10c0-2.958 2.422-4.412 6.09-6.613.72-.431 1.5-.898 2.329-1.417.292-.183.68-.094.861.199.183.292.094.678-.199.861-.837.523-1.621.994-2.348 1.43-3.535 2.121-5.483 3.29-5.483 5.54v10c0 1.034.841 1.875 1.875 1.875H27.5c.345 0 .625.28.625.625s-.28.625-.625.625"
                                        transform="translate(-497 -1644) translate(0 1579) translate(354 65) translate(143)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M27.5 21.875L25 19.375 22.5 16.875 24.375 15 27.5 16.25 30.625 15 32.5 16.875z"
                                        transform="translate(-497 -1644) translate(0 1579) translate(354 65) translate(143)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsDanısmanlar;
