import React from 'react';
import { Grid } from '@material-ui/core';

const VentureInfoGridItem = (props) => {
    const { title, content } = props;
    return (
        <Grid container direction="column" justify="center" alignItems="flex-start" style={{ padding: '10px 2px' }}>
            {title}
            <Grid item>{content}</Grid>
        </Grid>
    );
};

export default VentureInfoGridItem;
