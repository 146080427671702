import React from 'react';

import ErrorPageTemplate from 'fintech/components/errors/layout/PageTemplate';
import NotFound from 'fintech/components/errors/NotFound';

const NotFoundPage = () => {
    return (
        <ErrorPageTemplate>
            <NotFound />
        </ErrorPageTemplate>
    );
};

export default NotFoundPage;
