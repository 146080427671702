import React, { useState, useEffect } from 'react';
import ReportCard from 'fintech/components/reports/common/card';
import { Grid } from '@material-ui/core';
import ProgressBars from 'fintech/components/reports/common/progressbar';
import { COLOR_BLUE } from 'fintech/components/style/common';
import InfoBoxs from 'fintech/components/reports/common/InfoBoxs';
import { useTranslation } from 'react-i18next';
import IconsMultipleStartup from 'fintech/icons/IconsMultipleStartup';
import { useKeycloak } from '@react-keycloak/web';
import { getStartup } from 'fintech/api/fintechService';
import { ReportType } from 'fintech/utils/ReportUtils';

const Index = ({ onShowModal }) => {
    const { t } = useTranslation('reports');
    const { keycloak } = useKeycloak();
    const [startupData, setStartupData] = useState(null);

    useEffect(() => {
        getStartup(keycloak).then((e) => {
            setStartupData(e.data);
        });
    }, []);

    return (
        <ReportCard
            title={t('card.startups')}
            icon={<IconsMultipleStartup />}
            dropdown={{
                buttonText: t('card.generateDetailedReport'),
                items: [
                    {
                        text: t('card.buildForStartupsOnly'),
                        onClick: () => {
                            onShowModal(ReportType.STARTUP);
                        },
                    },
                    {
                        text: t('card.generateStatisticsbyStages'),
                        onClick: () => {
                            onShowModal(ReportType.STAGE);
                        }
                    },
                ],
            }}
            isLoading={startupData === null}
        >
            {startupData !== null && (
                <Grid container spacing={3}>
                    <Grid item xs={12} xl={12} lg={12}>
                        <InfoBoxs
                            items={[
                                { title: t('card.activeStartupCount'), description: startupData.activeStartupCount },
                                {
                                    title: t('card.startupApplicationCount'),
                                    description: startupData.startupApplicationCount,
                                },
                                { title: t('card.newStartupCount'), description: startupData.newStartupCount },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={5} lg={5}>
                        <ProgressBars
                            title={t('card.numberOfInterest')}
                            items={
                                startupData !== null
                                    ? startupData.fintechVerticalPercentageDistribution.map(
                                        data => {
                                            return {
                                                percentage: data.percentage,
                                                barText: data.count,
                                                title: data.label,
                                            };
                                        }
                                    )
                                    : []
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={5} lg={5}>
                        <ProgressBars
                            defaultBarColor={COLOR_BLUE}
                            title={t('card.numberOfattempts')}
                            items={
                                startupData !== null
                                    ? startupData.stagePercentageDistribution.map(data => {
                                        return {
                                            percentage: data.percentage,
                                            barText: data.count,
                                            title: data.label,
                                        };
                                    })
                                    : []
                            }
                            defaultOpacity={0.05}
                        />
                    </Grid>
                </Grid>
            )}
        </ReportCard>
    );
};

Index.propTypes = {};

export default Index;
