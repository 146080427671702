import * as React from 'react';

function SvgIconsSingleFilter(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <g fill="#FFFFFF" fillRule="nonzero">
                    <path d="M13 18c.063 0 .133-.016.195-.04.18-.077.305-.257.305-.46v-5.797l3.852-3.851c.148-.141.187-.36.109-.547C17.383 7.125 17.203 7 17 7H7c-.203 0-.383.125-.46.305-.08.187-.04.406.108.547l3.852 3.851V15.5c0 .133.055.258.148.352l2 2c.094.101.22.148.352.148z" />
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleFilter;
