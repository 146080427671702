import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import placeholderPerson from 'fintech/static/images/placeholder-kisi-rounded.png';
import AvatarUploader from 'fintech/components/avataruploader/AvatarUploader';
import { fetchUserImage } from 'fintech/store/actions/user';
import { useKeycloak } from '@react-keycloak/web';
import { getRequest } from 'fintech/api/api';
import { APIS } from 'fintech/constants';
import { getLoggedInUserUuid } from 'fintech/utils/Auth';

const userImageUrl = 'api/user/image/';

export const UserAvatar = (props) => {
    const { disabled, isPreLogin, hideBorder, userUuid } = props;
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { userImage: fetchedUserImage } = useSelector((state) => state.user);
    const [userImage, setUserImage] = useState();

    //For updating the image on the left menu avatar
    useEffect(() => {
        if (userUuid === getLoggedInUserUuid(keycloak)) {
            setUserImage(fetchedUserImage);
        }
    }, [fetchedUserImage]);

    useEffect(() => {
        if (!userImage) {
            getRequest(APIS.USER.getUserImage(userUuid), keycloak).then((data) => setUserImage(data));
        }
    }, []);

    return (
        <AvatarUploader
            serviceUri={userImageUrl}
            entityUuid={userUuid}
            pictureUuid={userImage?.uuid}
            placeholder={placeholderPerson}
            hideBorder={hideBorder}
            disabled={!!disabled}
            isPreLogin={isPreLogin}
            fetchOnUpload={() => dispatch(fetchUserImage(keycloak, userUuid))}
        />
    );
};
