import { combineReducers } from 'redux';
import auth from 'fintech/store/reducers/auth';
import announcement from 'fintech/store/reducers/anouncment';
import user from 'fintech/store/reducers/user';
import startup from 'fintech/store/reducers/startup';
import idea from 'fintech/store/reducers/idea';
import event from 'fintech/store/reducers/event';
import common from 'fintech/store/reducers/common';
import attemps from 'fintech/store/reducers/attemp';
import entrepreneur from 'fintech/store/reducers/entrepreneur';
import investorSearch from 'fintech/store/reducers/investorSearch';
import mentorSearch from 'fintech/store/reducers/mentorSearch';
import consultantSearch from 'fintech/store/reducers/consultantSearch';
import faqSearch from 'fintech/store/reducers/faqSearch';
import userSearch from 'fintech/store/reducers/userSearch';
import notifications from 'fintech/store/reducers/notifications';
import supportProgram from 'fintech/store/reducers/supportProgram';
import establishment from 'fintech/store/reducers/establishment';
import investorApplication from 'fintech/store/reducers/application-investor';
import supportProgramApplication from 'fintech/store/reducers/supportProgramApplication';
import startupApplication from 'fintech/store/reducers/application-startup';
import memberApplication from 'fintech/store/reducers/application-member';
import mentorApplication from 'fintech/store/reducers/application-mentor';
import inbox from 'fintech/store/reducers/inbox';
import navigation from 'fintech/store/reducers/navigation';
import inviteSearch from 'fintech/store/reducers/inviteSearch';
import cookie from 'fintech/store/reducers/cookie';

const reducer = combineReducers({
    auth,
    announcement,
    user,
    startup,
    idea,
    event,
    common,
    attemps,
    entrepreneur,
    investorSearch,
    mentorSearch,
    userSearch,
    notifications,
    supportProgram,
    consultantSearch,
    faqSearch,
    establishment,
    investorApplication,
    supportProgramApplication,
    startupApplication,
    memberApplication,
    mentorApplication,
    inbox,
    navigation,
    inviteSearch,
    cookie,
});

export default reducer;
