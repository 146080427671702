import { makeStyles } from '@material-ui/core';

import {
    COLOR_PRIMARY_SHADE_02,
} from 'fintech/components/style/common';

export const makeConversationAreaStyles = makeStyles(() => ({
    area : {
        height: "calc(100% - 80px)",
        padding: "20px 10px",
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        overflowY: "scroll",
    },
}));