import { makeStyles } from '@material-ui/core/styles';
import { COLOR_SECONDARY_BASE, COLOR_SECONDARY_SHADE_01 } from 'fintech/components/style/common';
import { fontStyle } from '@material-ui/system';

const useStyles = makeStyles({
    eventNameStyle: {
        fontWeight: 'bold',
        fontSize: 16,
        wordBreak: 'break-word',
        color: COLOR_SECONDARY_BASE,
        marginTop: 15,
        marginBottom: 8,
        textTransform: 'capitalize',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
    },
    eventDateStyle: {
        fontSize: 12,
        fontWeight: 500,
        color: COLOR_SECONDARY_SHADE_01,
    },
    eventInformationStyle: {
        ...fontStyle,
        wordBreak: 'break-word',
        fontSize: 14,
        color: COLOR_SECONDARY_BASE,
        marginTop: 16,
        display: '-webkit-box',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
    },
});

export default useStyles;
