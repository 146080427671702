import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import { getIsMentorApplicationActive } from 'fintech/api/fintechService';
import Welcome from './sections/Welcome';
import Support from './sections/Support/New';
import Startup from './sections/Startup';
import Testimonial from './sections/Testimonial';
import Investors from './sections/Investors';
import Faq from './sections/Faq';
import Footer from './sections/Footer';
import {
    SECTION_CONSULTANTS,
    SECTION_MENTORS,
    SECTION_TESTIMONIAL,
    SECTION_WELCOME,
} from 'fintech/components/util/HomePageConstants';

const Home = () => {
    const { section } = useParams();
    const { keycloak } = useKeycloak();
    const [isMentorApplicationActive, setIsMentorApplicationActive] = useState(false);

    const scrollTo = (element) => {
        const welcomeSectionProperties = document.getElementById(SECTION_WELCOME).getBoundingClientRect();
        const elementProperties = document.getElementById(element).getBoundingClientRect();

        window.scroll({
            top: -welcomeSectionProperties.top + elementProperties.top,
            left: -welcomeSectionProperties.left + elementProperties.left,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        getIsMentorApplicationActive(keycloak)
            .then((response) => {
                if (response) {
                    setIsMentorApplicationActive(response.data);
                }
            })
            .catch((reason) => console.error(reason.toString()));

        if (section) {
            // Allow the page to render
            setTimeout(() => {
                if (section === SECTION_CONSULTANTS) {
                    scrollTo(SECTION_TESTIMONIAL);
                    document.getElementById('consultants')?.click();
                } else if (section === SECTION_MENTORS) {
                    scrollTo(SECTION_TESTIMONIAL);
                    document.getElementById('mentors')?.click();
                } else {
                    scrollTo(section);
                }
            }, 700);
        } else {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, []);

    return (
        <>
            <Welcome isMentorApplicationActive={isMentorApplicationActive} />
            <Support />
            <Testimonial isMentorApplicationActive={isMentorApplicationActive} />
            <Startup />
            <Investors />
            <Faq />
            <Footer />
        </>
    );
};

export default Home;
