import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { LocalOffer } from '@material-ui/icons';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector, useDispatch } from 'react-redux';
import FinancialDetails from './Details';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import { IntroCardContent, IntroCardTitle } from 'fintech/components/profile/common';
import { StyledTag } from 'fintech/components/ui/button/common';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import Empty from 'fintech/components/util/Empty';
import { fetchStartupFinancialsData, fetchStartupInvestmentsData } from 'fintech/store/actions/startup';
import { formatMonetaryValue } from 'fintech/utils/StringUtils';
import StartupInvestorsGrid from './InvestorsGrid';
import { styledTagNotInDatagridStyle } from 'fintech/components/style/common';

const Financials = (props) => {
    const { t, id } = props;
    const { keycloak } = useKeycloak();
    const { data: fetchedData, isLoading, isError } = useSelector((state) => state.startup.financials.info);
    const [financialData, setFinancialData] = useState(fetchedData);
    const dispatch = useDispatch();

    let hasData = _.keys(financialData).length > 0 && financialData.id;

    let financialJSX = null;
    let emptyProps = {};

    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.financials.errorMessage');
    } else {
        financialJSX = (
            <FinancialDetails
                editable={financialData.editable}
                duoItems={hasData ? getDuoGridItems(t, financialData) : null}
            />
        );
    }

    useEffect(() => {
        if (fetchedData.startupId !== id) {
            dispatch(fetchStartupFinancialsData(keycloak, id));
            dispatch(fetchStartupInvestmentsData(keycloak, id));
        }
    }, []);

    useEffect(() => {
        setFinancialData(fetchedData);
    }, [fetchedData]);

    return (
        <>
            {financialJSX ? financialJSX : <Empty {...emptyProps} />}
            <StartupInvestorsGrid />
        </>
    );
};

export default withTranslation('profile')(Financials);

const getDuoGridItems = (t, { marketValue, acceptOffers, incomeModels }) => {
    const itemsJSX = [];

    // Market Value
    itemsJSX.push(
        <IntroGridItem
            title={<IntroCardTitle>{t('profile.financials.marketValue')}</IntroCardTitle>}
            content={
                marketValue && (
                    <IntroCardContent>
                        {formatMonetaryValue(marketValue.value, marketValue.currencyType)}
                    </IntroCardContent>
                )
            }
        />
    );

    // Investment request
    itemsJSX.push(
        <IntroGridItem
            title={<IntroCardTitle>{t('profile.financials.investmentRequest')}</IntroCardTitle>}
            content={
                <IntroCardContent>
                    {acceptOffers ? t('profile.financials.acceptOffers') : t('profile.financials.declineOffers')}
                </IntroCardContent>
            }
        />
    );

    // Income models
    const models = incomeModels?.map((model, idx) => (
        <StyledTag
            disableElevation
            disableRipple
            disableTouchRipple
            key={idx}
            startIcon={<LocalOffer fontSize="small" />}
            style={styledTagNotInDatagridStyle}
        >
            {model}
        </StyledTag>
    ));

    itemsJSX.push(
        <IntroGridItem
            title={<IntroCardTitle>{t('profile.financials.incomeModel')}</IntroCardTitle>}
            content={
                models && <TagGroup limit={5} items={models} clickableStyledTagStyle={styledTagNotInDatagridStyle} />
            }
        />
    );

    return itemsJSX;
};
