import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as ROUTES from 'fintech/routes';

const WarningPage = ({ title, desc }) => (
    <div>
        <h1>{title}</h1>
        <div>
            {desc}
            <p>
                <Link to={ROUTES.HOME}>Navigate to Home</Link>
            </p>
        </div>
    </div>
);

WarningPage.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
};

export default WarningPage;
