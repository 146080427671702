import { MentorSearchActions } from 'fintech/store/actions/ActionTypes';
import { getLogo, parseSubVerticals } from 'fintech/utils/DataParser';

const initialState = {
    global: {
        totalNumber: null,
        filteredNumber: null,
    },
    searchBox: {
        input: '',
        lastQueryParams: {},
        filters: [
            { id: 0, label: 'mentorName', status: true },
            { id: 1, label: 'mentorSubVerticals', status: true },
        ],
    },
    grid: {
        rows: [], // Needs to be fetched from server
        isLoading: true,
        gridDataLoading: false,
        isError: false,
        pagination: {
            count: 0,
            pageSize: 8,
            defaultPage: 1,
            page: 1,
        },
    },
};

const mentorSearch = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        // Global
        case MentorSearchActions.SET_MENTOR_GLOBAL_TOTAL_ACTIVE_NUMBER:
            return {
                ...state,
                global: {
                    ...state.global,
                    totalNumber: action.data,
                },
            };
        case MentorSearchActions.SET_MENTOR_GLOBAL_FILTERED_NUMBER:
            return {
                ...state,
                global: {
                    ...state.global,
                    filteredNumber: action.data,
                },
            };

        // SearchBox
        case MentorSearchActions.SET_MENTOR_SEARCHBOX_LAST_QUERY:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    lastQueryParams: action.data,
                },
            };
        case MentorSearchActions.SET_MENTOR_SEARCHBOX_INPUT:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    input: action.data,
                },
            };
        case MentorSearchActions.SET_MENTOR_SEARCHBOX_FILTERSTATUS:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    filters: state.searchBox.filters.map((element) => {
                        return action.data.includes(element.label)
                            ? {
                                  ...element,
                                  status: true,
                              }
                            : {
                                  ...element,
                                  status: false,
                              };
                    }),
                },
            };
        case MentorSearchActions.RESET_MENTOR_SEARCHBOX:
            return {
                ...state,
                searchBox: initialState.searchBox,
            };
        case MentorSearchActions.SET_MENTOR_GRID_DATA:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    rows: action.data?.map((usr) => ({
                        uuid: usr.uuid,
                        name: `${usr.name} ${usr.surname}`,
                        logo: getLogo(usr.profilePictureFile),
                        verticals: parseSubVerticals(usr.userFintechSubVerticalSet, true),
                    })),
                    isLoading: false,
                    gridDataLoading: false,
                },
            };
        case MentorSearchActions.SET_MENTOR_GRID_LOADING_INITIAL:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    rows: [],
                    isLoading: true,
                    isError: false,
                },
            };
        case MentorSearchActions.SET_MENTOR_GRID_LOADING:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    gridDataLoading: true,
                    isError: false,
                },
            };
        case MentorSearchActions.SET_MENTOR_GRID_LOAD_ERROR:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    isLoading: false,
                    gridDataLoading: false,
                    isError: true,
                },
            };
        // Pagination
        case MentorSearchActions.SET_MENTOR_GRID_PAGINATION:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    pagination: {
                        ...state.grid.pagination,
                        ...action.data,
                    },
                },
            };
        case MentorSearchActions.RESET_MENTOR_GRID_PAGINATION:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    pagination: initialState.grid.pagination,
                },
            };
        default:
            return state;
    }
};

export default mentorSearch;
