import React, { useEffect, useState } from 'react';
import MultipleSelect from 'fintech/components/ui/form/select/MultipleSelect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectInputEmptyStyle } from 'fintech/components/style/common';
import DropdownError from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/DropdownError';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';

export default function CityUpdate({ cities, changeCallback, formSubmitted }) {
    const { t } = useTranslation();
    const [cityData, setCityData] = useState(cities);
    const { isLoading, isError } = useSelector((state) => state.startup.form.cityData);
    const [hasEverChanged, setHasEverChanged] = useState(false);

    useEffect(() => {
        setCityData(cities);
    }, [cities]);

    const cityId = 'city';

    const cityChange = (val) => {
        setCityData(val && val.length > 0 ? val : []);
        const selected = val?.filter((item) => item.selected);
        changeCallback(val && val.length > 0 ? val : [], selected && selected[0]);
    };

    const handleOnSelectionChange = () => {
        setHasEverChanged(true);
    };

    const preventValidation = !formSubmitted && !hasEverChanged;

    let renderedJSX = null;
    if (isLoading) {
        renderedJSX = (
            <div style={{ ...selectInputEmptyStyle }}>
                <LoadingSpinner />
            </div>
        );
    } else if (isError) {
        renderedJSX = <DropdownError noBottomMargin style={{ ...selectInputEmptyStyle }} />;
    } else {
        renderedJSX = (
            <MultipleSelect
                label={t('formLabel.city')}
                callBack={(data, valid) => cityChange(data, valid)}
                onSelectionChange={handleOnSelectionChange}
                id={cityId}
                value={cityData}
                helperText={t('validation.notEmpty')}
                validateOnBlur={true}
                preventValidation={preventValidation}
                disableClearable
            />
        );
    }

    return renderedJSX;
}
