import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DialogContent, Grid } from '@material-ui/core';
import * as constants from 'fintech/components/util/ActionModalConstants';
import CfoDatePicker from 'fintech/components/ui/form/picker/CfoDatePicker';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import CountryUpdate from 'fintech/components/profile/startup/form/intro/CountryUpdate';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import TechnologyUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/TechnologyUpdate';
import SingleSelect from 'fintech/components/ui/form/select/SingleSelect';
import { ROLE_COUNSELOR } from 'fintech/components/util/RoleConstants';

const NewUserActionModal = (props) => {
    const { t } = useTranslation();
    const { changeCallback, sending, initFormData } = props;

    const {
        roles: fetchedRoles,
        countries: fetchedCountries,
        genders: fetchedGenders,
        expertise: fetchedExpertise,
    } = useSelector((state) => state.userSearch.form);

    const [typeOptions, setTypeOptions] = useState([]);
    const [userType, setUserType] = useState(initFormData.role);
    const [name, setName] = useState(initFormData.name);
    const [surname, setSurname] = useState(initFormData.surname);
    const [countries, setCountry] = useState([]);
    const [idNumber, setIdNumber] = useState({
        value: '',
        validated: false,
    });
    const [genderOptions, setGenderOptions] = useState([]);
    const [gender, setGender] = useState(initFormData.gender);
    const [birthDate, setBirthDate] = useState(initFormData.birthDate);
    const [email, setEmail] = useState(initFormData.email);
    const [expertiseFields, setExpertiseFields] = useState(initFormData.expertise);

    useEffect(() => {
        setTypeOptions(fetchedRoles.data);
    }, [fetchedRoles]);

    useEffect(() => {
        setCountry(fetchedCountries.data);
    }, [fetchedCountries]);

    useEffect(() => {
        setGenderOptions(fetchedGenders.data);
    }, [fetchedGenders]);

    useEffect(() => {
        setExpertiseFields(fetchedExpertise.data);
    }, [fetchedExpertise]);

    const userTypeChangedHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setUserType(newState);
        changeCallback({ type: constants.USER_SEARCH_USER_TYPE_UPDATE, data: newState });
    };

    const nameChangedHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setName(newState);
        changeCallback({ type: constants.USER_SEARCH_NAME_UPDATE, data: newState });
    };

    const surnameChangedHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setSurname(newState);
        changeCallback({ type: constants.USER_SEARCH_SURNAME_UPDATE, data: newState });
    };

    const countryChangeHandler = (val, selected) => {
        setCountry(val);
        changeCallback({
            type: constants.USER_SEARCH_COUNTRY_UPDATE,
            data: {
                country: {
                    id: selected?.id,
                    data: selected?.data,
                },
                countryValid: selected && true,
            },
        });
    };

    const idChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setIdNumber(newState);
        changeCallback({ type: constants.USER_SEARCH_IDENTITY_UPDATE, data: newState });
    };

    const genderChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setGender(newState);
        changeCallback({ type: constants.USER_SEARCH_GENDER_UPDATE, data: newState });
    };

    const dateChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setBirthDate(newState);
        changeCallback({ type: constants.USER_SEARCH_BIRTHDATE_UPDATE, data: newState });
    };

    const emailChangedHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setEmail(newState);
        changeCallback({ type: constants.USER_SEARCH_EMAIL_UPDATE, data: newState });
    };

    const expertiseChangeHandler = (expertise, valid) => {
        setExpertiseFields(expertise);
        const updateData = {
            expertise: filterObjectList(expertise, 'selected', [true]),
            expertiseValid: valid,
        };
        changeCallback({ type: constants.USER_SEARCH_EXPERTISE_UPDATE, data: updateData });
    };

    const isConsultant = userType.value && userType.value.roleUniqueId === ROLE_COUNSELOR;

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item xs={12}>
                <DialogContent>
                    <SingleSelect
                        label={t('formLabel.userType')}
                        options={typeOptions}
                        initValue={userType.value}
                        isLoading={fetchedRoles.isLoading}
                        isError={fetchedRoles.isError}
                        onChange={userTypeChangedHandler}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                    />
                </DialogContent>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                        <InputTextArea
                            type="text"
                            label={t('formLabel.name')}
                            validationText={t('validation.notEmpty')}
                            initText={name.value}
                            onChange={(val, validated) => nameChangedHandler(val, validated)}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputTextArea
                            type="text"
                            label={t('formLabel.surname')}
                            validationText={t('validation.notEmpty')}
                            initText={surname.value}
                            onChange={(val, validated) => surnameChangedHandler(val, validated)}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                        <DialogContent>
                            <CountryUpdate
                                label={t('formLabel.nationality')}
                                countries={countries}
                                isLoading={fetchedCountries.isLoading}
                                isError={fetchedCountries.isError}
                                changeCallback={(data, selected) => countryChangeHandler(data, selected)}
                            />
                        </DialogContent>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputTextArea
                            type="tckn"
                            label={t('formLabel.idNumber')}
                            validationText={idNumber.value ? t('validation.tckn') : t('validation.notEmpty')}
                            initText={idNumber.value}
                            onChange={(val, validated) => idChangeHandler(val, validated)}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                        <DialogContent>
                            {/* TODO: gender list combobox API */}
                            <SingleSelect
                                label={t('formLabel.gender')}
                                options={genderOptions}
                                initValue={gender.value}
                                isLoading={fetchedGenders.isLoading}
                                isError={fetchedGenders.isError}
                                onChange={genderChangeHandler}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                            />
                        </DialogContent>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* TODO: minimum age? */}
                        <CfoDatePicker
                            id={'formLabel.birthDate'}
                            label={t('formLabel.birthDate')}
                            invalidDateMessage={birthDate.value ? t('validation.date') : t('validation.notEmpty')}
                            initialDate={undefined}
                            onChange={dateChangeHandler}
                            autoOk
                            openTo="year"
                            disableFuture
                            maxDateMessage={t('validation.noFutureDate')}
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="row" justify="center">
                <Grid item xs={12}>
                    <InputTextArea
                        type="mail"
                        label={t('profile.user.email')}
                        validationText={email.value?.length > 0 ? t('validation.email') : t('validation.notEmpty')}
                        initText={email.value}
                        onChange={(val, validated) => emailChangedHandler(val, validated)}
                        required
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                    />
                </Grid>
                {isConsultant && (
                    <Grid item xs={12}>
                        <DialogContent>
                            <TechnologyUpdate
                                multipleSelect={false}
                                label={t('formLabel.fieldOfExpertise')}
                                technologies={expertiseFields}
                                isLoading={fetchedExpertise.isLoading}
                                isError={fetchedExpertise.isError}
                                changeCallback={(data, valid) => expertiseChangeHandler(data, valid)}
                            />
                        </DialogContent>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default NewUserActionModal;
