import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import AppBar from 'fintech/components/applications/layout/pre-login/AppBar';
import Footer from 'fintech/components/applications/layout/pre-login/Footer';
import useStyles from './index.style';
import SideImage from '../SideImage';
import { mobileMediaQuery } from 'fintech/components/style/common';
import useWindowSize from 'fintech/hooks/useWindowSize';
import { useScroll } from 'ahooks';

const ApplicationPageTemplate = (props) => {
    const appBarRef = useRef(null);

    const matches = useMediaQuery(mobileMediaQuery);
    const { options = [], optionsWithLongText = [], currentIndex, image, imageTitle, reducedPadding } = props;

    const [barHeight, setBarHeight] = useState(90);

    const window = useWindowSize();
    const scroll = useScroll();

    useEffect(() => {
        const newHeight = appBarRef?.current?.offsetHeight;
        barHeight !== newHeight && setBarHeight(newHeight);
    }, [window, scroll, currentIndex]);

    const classes = useStyles(barHeight);

    return (
        <Grid container direction="column" justify="space-between" alignItems="stretch">
            <Grid item container direction="row" justify="flex-start" alignItems="flex-start">
                <AppBar
                    reducedPadding={reducedPadding}
                    innerRef={appBarRef}
                    currentIndex={currentIndex}
                    options={options}
                />
                <Grid className={classes.sideImage} item xs={12} md={4}>
                    <SideImage image={image} imageTitle={imageTitle} scroll={scroll} />
                </Grid>
                {!matches && optionsWithLongText.length > 0 && (
                    <Grid item xs={12}>
                        <Box component="div" className={classes.mobilePageIndicator}>
                            <Typography className={classes.pageIndicatorTypo}>{`${optionsWithLongText[currentIndex]} (${
                                currentIndex + 1
                            }/${optionsWithLongText?.length})`}</Typography>
                        </Box>
                    </Grid>
                )}
                <Grid className={classes.formGrid} item xs={12} md={8}>
                    <div className={classes.contentWrapper}>{props.children}</div>
                </Grid>
            </Grid>
            {matches && (
                <Grid item>
                    <Footer />
                </Grid>
            )}
        </Grid>
    );
};

ApplicationPageTemplate.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string),
    currentIndex: PropTypes.number,
    image: PropTypes.string,
    imageTitle: PropTypes.string,
    children: PropTypes.node,
};

export default ApplicationPageTemplate;
