import * as React from 'react';

function IconsSingleChevronDownWhite() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <filter id="282yif1oka" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g filter="url(#282yif1oka)" transform="translate(-1275 -114) translate(1271 112)">
                        <g>
                            <path
                                fill="#3F5162"
                                fillRule="nonzero"
                                d="M14.101 17.899L15.399 16.601 11.175 12.399 15.399 8.197 14.101 6.899 8.601 12.399z"
                                transform="translate(4 2) rotate(-90 12 12.399)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default IconsSingleChevronDownWhite;
