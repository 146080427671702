import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import educationAndSeminarsImage from 'fintech/static/images/egitim.svg';
import useStyles from './index.style';

const EducationAndSeminars = ({ supportProgramOnePage }) => {
    const { t } = useTranslation('preLogin');
    const classes = useStyles();

    const educationJSX = (
        <Grid container spacing={2} className={classes.educationGrid}>
            {supportProgramOnePage.educationAreaData.map((title, inx) => (
                <Grid item md={6} xs={12} key={inx}>
                    <div className={classes.educationGridItem}>
                        <div className={classes.educationItemIcon} />
                        <div>
                            <h6 style={{ margin: 0 }}>{title}</h6>
                        </div>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
    return (
        <>
            <section className={classes.educationSection} id="section-educationAndSeminars">
                <div className={classes.educationContainer}>
                    <div className={classes.imagesContainer}>
                        <img src={educationAndSeminarsImage} className={classes.educationProgramsIcon} />
                    </div>
                    <span className={classes.title}>{t('titles.educationAndSeminars')}</span>
                    <p className={classes.definition}>{supportProgramOnePage.educationAndSeminarsDescription}</p>
                    {educationJSX}
                </div>
            </section>
        </>
    );
};

export default EducationAndSeminars;
