import { INVITE_URL } from 'fintech/components/util/TrafficStatusConstants';

import { MentorApplicationActions } from 'fintech/store/actions/ActionTypes';

const initialState = {
    name: {
        value: '',
        validated: false,
    },
    surname: {
        value: '',
        validated: false,
    },
    country: {
        value: '',
        validated: false,
    },
    idNumber: {
        value: '',
        validated: false,
    },
    gender: {
        value: null,
        validated: false,
    },
    birthDate: {
        value: '',
        validated: false,
    },
    email: {
        value: '',
        validated: false,
    },
    phoneNumber: {
        value: {
            country: 'TR',
            countryCallingCode: '90',
            nationalNumber: '',
            number: '',
        },
        validated: false,
    },
    experience: {
        value: '',
        validated: false,
    },
    education: {
        value: null,
        validated: false,
    },
    coverLetter: {
        value: null,
        validated: false,
    },
    interestedVerticals: {
        value: [],
        validated: false,
    },
    interestedTechnologies: {
        value: [],
        validated: false,
    },
    files: [],
    readAgreement: false,
    inviteFetch: false,
    trafficSource: INVITE_URL,

    // Select options
    options: {
        countries: {
            data: null,
            isLoading: true,
            isError: false,
        },
        genders: {
            data: null,
            isLoading: true,
            isError: false,
        },
        educationLevels: {
            data: null,
            isLoading: true,
            isError: false,
        },
        verticals: {
            data: null,
            isLoading: true,
            isError: false,
        },
        technologies: {
            data: null,
            isLoading: true,
            isError: false,
        },
    },
};

const mentorApplication = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_NAME:
            return {
                ...state,
                name: action.data,
            };

        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_SURNAME:
            return {
                ...state,
                surname: action.data,
            };

        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_COUNTRY:
            return {
                ...state,
                country: action.country,
            };

        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_IDENTITY:
            return {
                ...state,
                idNumber: action.data,
            };

        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_GENDER:
            return {
                ...state,
                gender: action.data,
            };

        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_BIRTHDATE:
            return {
                ...state,
                birthDate: action.data,
            };

        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_EMAIL:
            return {
                ...state,
                email: action.data,
            };

        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: {
                    ...state.phoneNumber,
                    ...action.data,
                },
            };

        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_EXPERIENCE:
            return {
                ...state,
                experience: action.data,
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_EDUCATION:
            return {
                ...state,
                education: action.data,
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_COVER_LETTER:
            return {
                ...state,
                coverLetter: action.data,
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_INTERESTED_VERTICALS:
            return {
                ...state,
                interestedVerticals: action.data,
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_INTERESTED_TECHNOLOGIES:
            return {
                ...state,
                interestedTechnologies: action.data,
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_FILES:
            return {
                ...state,
                files: action.data,
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_AGREEMENTS:
            return {
                ...state,
                readAgreement: !state.readAgreement,
            };
        // Countries
        case MentorApplicationActions.SET_MENTOR_APPLICATION_COUNTRIES:
            const { countries } = action;
            return {
                ...state,
                country: {
                    value: countries.data[0],
                    validated: true,
                },
                options: {
                    ...state.options,
                    countries: {
                        ...state.options.countries,
                        data: countries.data,
                        isLoading: false,
                    },
                },
            };
        case MentorApplicationActions.GET_MENTOR_APPLICATION_COUNTRIES:
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_COUNTRIES_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        ...state.options.countries,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Genders
        case MentorApplicationActions.SET_MENTOR_APPLICATION_GENDERS:
            const { gender } = action;
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        ...state.options.genders,
                        data: gender.data,
                        isLoading: false,
                    },
                },
            };
        case MentorApplicationActions.GET_MENTOR_APPLICATION_GENDERS:
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_GENDERS_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        ...state.options.genders,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Education levels
        case MentorApplicationActions.SET_MENTOR_APPLICATION_EDUCATION_LEVELS:
            const { levels } = action;
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        ...state.options.educationLevels,
                        data: levels.data,
                        isLoading: false,
                    },
                },
            };
        case MentorApplicationActions.GET_MENTOR_APPLICATION_EDUCATION_LEVELS:
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        ...state.options.levels,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Verticals
        case MentorApplicationActions.SET_MENTOR_APPLICATION_VERTICALS:
            const { verticals } = action;
            return {
                ...state,
                options: {
                    ...state.options,
                    verticals: {
                        ...state.options.verticals,
                        data: verticals.data,
                        isLoading: false,
                    },
                },
            };
        case MentorApplicationActions.GET_MENTOR_APPLICATION_VERTICALS:
            return {
                ...state,
                options: {
                    ...state.options,
                    verticals: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_VERTICALS_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    verticals: {
                        ...state.options.verticals,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Technologies
        case MentorApplicationActions.SET_MENTOR_APPLICATION_TECHNOLOGIES:
            const { technologies } = action;
            return {
                ...state,
                options: {
                    ...state.options,
                    technologies: {
                        ...state.options.technologies,
                        data: technologies.data,
                        isLoading: false,
                    },
                },
            };
        case MentorApplicationActions.GET_MENTOR_APPLICATION_TECHNOLOGIES:
            return {
                ...state,
                options: {
                    ...state.options,
                    technologies: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case MentorApplicationActions.SET_MENTOR_APPLICATION_TECHNOLOGIES_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    technologies: {
                        ...state.options.technologies,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case MentorApplicationActions.RESET_MENTOR_APPLICATION_FORM:
            return initialState;
        default:
            return state;
    }
};

export default mentorApplication;
