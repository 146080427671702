import React, { useState } from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import MultipleSelect from 'fintech/components/ui/form/select/MultipleSelect';
import { ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CfoCheckbox from 'fintech/components/util/CfoCheckbox';
import { isEmpty } from 'lodash';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import DropdownError from './DropdownError';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';

export default function VerticalsUpdate({
    verticals,
    changeCallback,
    title,
    formIsSubmitted,
    usePlaceholder = false,
    validateOnBlur = false,
    validatedAfterFormSubmit = true,
    className = 'full-width',
    noBottomMargin = false,
    isLoading = false,
    isError = false,
}) {
    const { t } = useTranslation();

    if (!title) {
        title = t('idea.verticals');
    }

    const [verticalData, setVerticalData] = useState(_.clone(verticals, true) || []);
    if (verticals && verticals.length > 0 && verticalData.length == 0) {
        setVerticalData(verticals);
    }

    const [selectedSearchBar, setSelectedSearchBar] = React.useState(null);
    const [checkboxContainerVerticals, setCheckboxContainerVerticals] = useState([]);
    const [valid, setValid] = useState(false);
    const [selectionIsChanged, setSelectionIsChanged] = useState(false);

    const fintechChange = (val, valid) => {
        setValid(valid);
        setVerticalData(val && val.length > 0 ? val : []);
        changeCallback(val && val.length > 0 ? val : [], valid);
        setCheckboxContainerVerticals(val && val.length > 0 ? _.groupBy(val, 'parentId') : []);
    };

    const fintechCheckboxChange = (id, checked) => {
        let tmpVerticals = [...verticalData];
        tmpVerticals.forEach(function (vertical) {
            if (vertical.id == id) {
                vertical.selected = checked;
            }
        });
        setVerticalData([tmpVerticals]);
        fintechChange(tmpVerticals, valid);
    };

    const chipsClick = () => {
        setSelectedSearchBar(true);
    };

    const handleClickAway = () => {
        if (selectedSearchBar) {
            setSelectedSearchBar(false);
        }
    };

    const handleOnSelectionChange = () => {
        setSelectionIsChanged(true);
    };

    const createGroupedCheckbox = (idx) => {
        const items = checkboxContainerVerticals[idx];
        let row = '';
        if (items) {
            row = items.map(function (item, idx) {
                return (
                    <div key={idx} className={''}>
                        {idx == 0 && <div className={'title'}>{item.parentData}</div>}
                        <CfoCheckbox
                            id={item.id}
                            defaultState={item.selected}
                            label={item.data}
                            callbackMethod={(id, selected) => {
                                fintechCheckboxChange(id, selected);
                            }}
                        />
                    </div>
                );
            });
        }
        return row;
    };

    const changedOrSubmitted = formIsSubmitted || selectionIsChanged;

    let renderedJSX = null;
    if (isLoading) {
        renderedJSX = (
            <div className={className} style={{ marginBottom: !noBottomMargin && '30px', textAlign: 'center' }}>
                <LoadingSpinner />
            </div>
        );
    } else if (isError) {
        renderedJSX = (
            <div className={className}>
                <DropdownError noBottomMargin={noBottomMargin} />
            </div>
        );
    } else {
        renderedJSX = (
            <div className={className}>
                {verticalData && verticalData.length > 0 && (
                    <Grid container direction={'row'}>
                        <ClickAwayListener onClickAway={() => handleClickAway()}>
                            <Grid xs={12} item className={`full-width ${!noBottomMargin ? 'margin-bottom-30' : ''}`}>
                                <MultipleSelect
                                    label={!usePlaceholder ? title : ''}
                                    placeholder={
                                        usePlaceholder && isEmpty(filterObjectList(verticalData, 'selected', [true]))
                                            ? title
                                            : ''
                                    }
                                    callBack={(data, valid) => fintechChange(data, valid)}
                                    chipCallBack={(option) => chipsClick(option)}
                                    searchDataAndParentData
                                    value={verticalData}
                                    multipleSelect={true}
                                    helperText={t('validation.notEmpty')}
                                    preventValidation={!changedOrSubmitted}
                                    onSelectionChange={handleOnSelectionChange}
                                    validateOnBlur={validateOnBlur}
                                    validatedAfterFormSubmit={validatedAfterFormSubmit}
                                />

                                {selectedSearchBar && (
                                    <div className={'selectedCheckboxContainer poppin-500-14px'}>
                                        <div className={'checkContent'}>
                                            {checkboxContainerVerticals &&
                                                Object.keys(checkboxContainerVerticals).map(function (parentId, idx) {
                                                    return (
                                                        <div key={idx}>
                                                            <div>{createGroupedCheckbox(parentId)}</div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </ClickAwayListener>
                    </Grid>
                )}
            </div>
        );
    }

    return renderedJSX;
}
