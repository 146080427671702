import { makeStyles } from '@material-ui/core/styles';
import { COLOR_THEME_FILL_01, fontStyle } from 'fintech/components/style/common';

export const useStyles = makeStyles({
    EventDetailGrid: {
        ...fontStyle,
        marginTop: '26px',
        backgroundColor: COLOR_THEME_FILL_01,
        padding: '4px',
        borderRadius: 8,
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
    },
    EventDetailUpImage: {
        width: '100%',
        height: 104,
        objectFit: 'cover',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        objectPosition: '100% 61%',
    },
});
