import { makeStyles } from '@material-ui/core';
import {
    COLOR_PRIMARY_SHADE_01,
    poppins500_12px,
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
} from 'fintech/components/style/common';

const useStyles = makeStyles({
    LogoGrid: {
        maxWidth: '80px',
        padding: '10px 2px',
    },
    NameGrid: {
        paddingTop: '10px',
        paddingLeft: '24px',
        paddingBottom: '10px',
    },
    MemberLogoMask: {
        width: '64px',
        height: '64px',
        background: '#ffffff',
        margin: 'auto',
        '& .MuiAvatar-colorDefault': {
            backgroundColor: `${COLOR_PRIMARY_SHADE_02} !important`,
        },
    },
    MemberLogoMedia: {
        width: '64px',
        height: '64px',
    },
    invited: {
        marginTop: '5px',
        padding: '3px 8px 4px 8px',
        backgroundColor: COLOR_PRIMARY_SHADE_01,
        borderRadius: '4px',
        '& > div': {
            ...poppins500_12px,
            color: COLOR_PRIMARY_BASE,
        },
    },
});

export default useStyles;
