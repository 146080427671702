import { makeStyles } from '@material-ui/core';
import {
    COLOR_SECONDARY_BASE,
    COLOR_THEME_FILL_03,
    COLOR_THEME_FILL_04,
    fontStyle,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    listedMessageTypo: {
        ...fontStyle,
        fontSize: '18px',
        marginRight: '8px',
        color: COLOR_SECONDARY_BASE,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    clearBtn: {
        backgroundColor: COLOR_THEME_FILL_04,
        color: COLOR_THEME_FILL_03,
        '&:hover': {
            backgroundColor: '#f7dcdc',
            color: COLOR_THEME_FILL_03,
        },
    },
}));

export default useStyles;
