import React from 'react';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { PlaceholdersPerson } from 'fintech/icons';
import { COLOR_SECONDARY_BASE, fontStyle } from 'fintech/components/style/common';
import useStyles from './index.style';
import { useTranslation } from 'react-i18next';

const AvatarGrid = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { breakPoints = {}, logo, name, role, isInvited } = props;

    const logoJSX = logo ? (
        <Avatar className={classes.MemberLogoMask} alt={name ? name : 'no-name'} src={logo?.url} />
    ) : (
        <Avatar className={classes.MemberLogoMedia} alt={'placeholder-person'}>
            <PlaceholdersPerson />
        </Avatar>
    );

    return (
        <Grid item {...breakPoints} container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={6} className={classes.LogoGrid}>
                <div className={classes.MemberLogoMask}>{logoJSX}</div>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                container
                direction="column"
                justify="flex-start"
                className={classes.NameGrid}
            >
                {name && (
                    <Typography style={{ ...fontStyle, fontWeight: '600' }}>{name}</Typography>
                )}
                {!isInvited && role && (
                    <Typography style={{ ...fontStyle, color: COLOR_SECONDARY_BASE }}>
                        {role}
                    </Typography>
                )}
                {isInvited && (
                    <div className={classes.invited}>
                        <div>{t('profile.team.invited')}</div>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default AvatarGrid;
