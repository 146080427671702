import React, { useState } from 'react';
import { getVideoUrl } from 'fintech/api/fileService';

const VideoComponent = () => {
    const [requestedVideo, setRequestedVideo] = useState('');
    const [requestedVideoUrl, setRequestedVideoUrl] = useState('');

    const setVideoUrl = () => {
        setRequestedVideoUrl(getVideoUrl(requestedVideo));
    };

    const getVideoPlayer = () => {
        return (
            <video id="videoPlayer" key={requestedVideoUrl} width="650" controls muted="muted" autoPlay>
                <source src={requestedVideoUrl} type="video/mp4" />
            </video>
        );
    };

    return (
        <div style={{ marginTop: 70 }}>
            <h2>FinTech Video Streaming</h2>
            <div>
                <label>Video name(UUID):</label>
                <input value={requestedVideo} onInput={(e) => setRequestedVideo(e.target.value)} />
            </div>
            <div>
                <button onClick={setVideoUrl}>Request Video</button>
            </div>
            {requestedVideoUrl ? getVideoPlayer() : null}
        </div>
    );
};

export default VideoComponent;
