import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './index.style';
import { useTranslation } from 'react-i18next';
const CfoFormLabel = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { label, subText, isOptional = false, rootStyle = {} } = props;

    return (
        <Grid
            className={classes.root}
            style={rootStyle}
            item
            xs={12}
            lg={3}
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
        >
            <Grid item>
                <Typography className={classes.formLabel}>{label}</Typography>
            </Grid>
            {subText && (
                <Grid item>
                    <Typography className={classes.formSubtext}>{subText}</Typography>
                </Grid>
            )}
            {isOptional && (
                <Grid item>
                    <Typography className={classes.formIsOptional}>{t('formLabel.optional')}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default CfoFormLabel;
