import { getToken } from 'fintech/utils/Auth';
import * as FintechBeService from 'fintech/api/fintechService';
import { postRequest } from 'fintech/api/api';
import fileDownload from 'react-file-download';
import axios from 'axios';

const withFileServer = (value) => `${process.env.FILE_SERVER_URL}/${value}`;
const uri = 'upload-file';
const publicUri = 'upload-public';

const fileUploadRequest = async (requestUrl, keycloak, formData, fileName) => {
    return await fetch(withFileServer(requestUrl), {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ` + getToken(keycloak),
        },
    })
        .then((response) => response.text())
        .then((result) => {
            const resultParsed = JSON.parse(result);
            const fileInfo = {
                fileName: fileName,
                uuid: resultParsed.uuid,
                fileSize: resultParsed.size,
                mimeType: resultParsed.mimeType,
                height: resultParsed.height ? resultParsed.height : null,
                width: resultParsed.width ? resultParsed.width : null,
            };
            return FintechBeService.postFileInformation(keycloak, fileInfo, false);
        })
        .catch((error) => {
            console.error('Error:', error);
            return null;
        });
};

const fileRequest = (requestUrl, id, keycloak) => {
    let header = new Headers();
    if (keycloak) {
        header.append('Authorization', `Bearer ` + getToken(keycloak));
    }

    return fetch(withFileServer(requestUrl), { headers: header }).then((response) => {
        if (response.ok) {
            return response.blob();
        } else {
            throw response.text();
        }
    });
};

export const uploadFile = async (keycloak, formData, fileName) => {
    return await fileUploadRequest(uri, keycloak, formData, fileName);
};

export const uploadAvatarFile = (keycloak, formData, entityUuid, fileName, beServiceUri) => {
    return new Promise((resolve, reject) => {
        AvatarUploadRequest(uri, keycloak, entityUuid, formData, fileName, beServiceUri)
            .then(() => {
                resolve(true);
            })
            .catch(() => reject(false));
    });
};

const AvatarUploadRequest = (requestUrl, keycloak, entityUuid, formData, fileName, beServiceUri) => {
    return new Promise((resolve, reject) =>
        fetch(withFileServer(requestUrl), {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ` + getToken(keycloak),
            },
        })
            .then((response) => response.json())
            .then((result) => {
                const fileInfo = {
                    fileName: fileName,
                    uuid: result.uuid,
                    mimeType: result.mimeType,
                    fileSize: result.size,
                    height: result.height ? result.height : null,
                    width: result.width ? result.width : null,
                };
                const uri = beServiceUri + entityUuid;
                postFileInformation(keycloak, fileInfo, uri).then(() => resolve());
            })
            .catch(() => {
                reject();
            })
    );
};

const postFileInformation = (keycloak, fileInfo, beServiceUri) => {
    return postRequest(beServiceUri, keycloak, fileInfo);
};

export const getFile = (id, keycloak) => {
    const uri = 'file/' + (keycloak ? 'private' : 'public') + `/${id}`;
    return fileRequest(uri, id, keycloak);
};

export const getVideoUrl = (videoId, token) => {
    return getPublicFileUrl(videoId, token);
};

export const getThumbnailUrl = (videoId) => {
    return withFileServer('thumbnail/' + videoId);
};

export const getPublicFileUrl = (fileId, token) => {
    if (fileId) {
        if (token) {
            return withFileServer('file/public/') + fileId + '?s=' + token;
        } else {
            return withFileServer('file/public/') + fileId;
        }
    }
};

export const uploadFileWithPercentage = async (keycloak, formData, fileName, callback, isPublic) => {
    const config = {
        onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            callback(percentCompleted);
        },
        headers: {
            Authorization: `Bearer ` + getToken(keycloak),
        },
    };

    const finalUri = isPublic ? publicUri : uri;
    return await axios
        .post(withFileServer(finalUri), formData, config)
        .then(function (res) {
            const fileInfo = {
                fileName: fileName,
                uuid: res.data.uuid,
                fileSize: res.data.size,
                mimeType: res.data.mimeType,
                height: res.data.height ? res.data.height : null,
                width: res.data.width ? res.data.width : null,
            };
            return FintechBeService.postFileInformation(keycloak, fileInfo, isPublic);
        })
        .catch(function () {
            return null;
        });
};

export const downloadFile = (id, name) => {
    getFile(id).then((result) => {
        fileDownload(result, name);
    });
};
