import { InviteSearchActions } from 'fintech/store/actions/ActionTypes';
import { APIS } from 'fintech/constants';
import { postRequest } from 'fintech/api/api';
import { withPagination } from 'fintech/constants/apis';
import { hasRoles } from 'fintech/utils/Auth';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';

export const GRID_HEADER_STATE = {
    NONE: 'NONE',
    ASC: 'ASC',
    DESC: 'DESC',
};

export const persistState = (persist) => ({
    type: InviteSearchActions.SET_INVITE_SEARCH_GLOBAL_PERSIST_STATE,
    data: persist,
});

export const setGridHeaders = (headers) => {
    return (dispatch) => {
        dispatch({ type: InviteSearchActions.SET_INVITE_SEARCH_GRID_HEADERS, data: headers });
    };
};

export const setGridPage = (pageNumber) => ({
    type: InviteSearchActions.SET_INVITE_SEARCH_GRID_PAGINATION,
    data: {
        page: pageNumber,
    },
});

export const resetAllFilters = () => {
    return (dispatch) => {
        dispatch({ type: InviteSearchActions.RESET_INVITE_SEARCH_SEARCHBOX });
        // Reset pagination
        dispatch({ type: InviteSearchActions.RESET_INVITE_SEARCH_GRID_PAGINATION });
    };
};

// Global
export const setTotalNumber = (data) => ({
    type: InviteSearchActions.SET_INVITE_GLOBAL_TOTAL_ACTIVE_NUMBER,
    data,
});
export const setFilteredNumber = (data) => ({
    type: InviteSearchActions.SET_INVITE_GLOBAL_FILTERED_NUMBER,
    data,
});

export const fetchGridData = ({ keycloak, errorCallback, reloadGrid = true, dayTranslation, timeout = 700 }) => {
    return (dispatch, getState) => {
        // Initial call when the component mounts
        if (reloadGrid) {
            // Show a loading indicator in place of the whole grid
            dispatch({ type: InviteSearchActions.SET_INVITE_SEARCH_GRID_LOADING_INITIAL });
        } else {
            // TODO: Give the grid a disabled effect much like in DealRoom
            dispatch({ type: InviteSearchActions.SET_INVITE_SEARCH_GRID_LOADING });
        }

        setTimeout(() => {
            const { global, grid, searchBox } = getState().inviteSearch;
            const { filteredNumber } = global;
            const { input } = searchBox;
            const { headers, pagination } = grid;

            let commonArgs = {
                dispatch,
                keycloak,
                headers,
                pageSize: pagination.pageSize,
                page: pagination.page,
                filteredNumber,
                errorCallback,
                isAdmin: hasRoles(keycloak, ROLE_ADMIN),
            };
            makeSearch({ ...commonArgs, data: input, dayTranslation: dayTranslation });
        }, timeout);
    };
};

export const makeSearch = ({
    dispatch,
    keycloak,
    data,
    headers,
    pageSize,
    page,
    errorCallback,
    isAdmin,
    dayTranslation,
}) => {
    const url = withPagination(APIS.INVITES_SEARCH.getSearchURL(), pageSize, page - 1);
    postRequest(appendSortQueryToUrl(url, headers), keycloak, data)
        .then((resp) => {
            const { totalElements, content, totalPages } = resp.data;
            const rows = [];

            content.forEach((element) => {
                rows.push(generateGridRowFromInvestorData(element, isAdmin, dayTranslation));
            });

            let count = data?.length;
            if (count > 0) {
                dispatch(setFilteredNumber(totalElements));
            } else {
                dispatch(setFilteredNumber(null));
            }

            // Save last query
            dispatch({ type: InviteSearchActions.SET_INVITE_SEARCH_SEARCHBOX_LAST_QUERY, data: data });

            dispatch({ type: InviteSearchActions.SET_INVITE_SEARCH_GRID_DATA, data: rows });
            dispatch({
                type: InviteSearchActions.SET_INVITE_SEARCH_GRID_PAGINATION,
                data: { count: totalPages },
            });
            if (count > 0) {
                dispatch({ type: InviteSearchActions.SET_INVITE_SEARCH_LAST_QUERY_TYPE, data: 'search' });
            } else {
                dispatch({ type: InviteSearchActions.SET_INVITE_SEARCH_LAST_QUERY_TYPE, data: 'filter' });
            }
        })
        .catch(() => {
            dispatch({ type: InviteSearchActions.SET_INVITE_SEARCH_GRID_LOAD_ERROR });
            errorCallback();
        });
};

// SearchBox
export const searchBoxOnChange = (data) => ({
    type: InviteSearchActions.SET_INVITE_SEARCH_SEARCHBOX_INPUT,
    data,
});

export const setSearchBoxFilterStatus = (data) => ({
    type: InviteSearchActions.SET_INVITE_SEARCH_SEARCHBOX_FILTERSTATUS,
    data,
});

// Admin related actions

// Utils
const generateGridRowFromInvestorData = (data, isAdmin = false, dayTranslation) => {
    const { id, fullName, companyName, email, memberType, dayCount } = data;
    const defaultText = '-';
    const defaultColumns = [
        // Row id
        {
            id: id,
        },
        // İsim Soyisim
        {
            type: 'placeholder-name-surname',
            value: {
                label: fullName ? fullName : defaultText,
            },
            style: {
                minWidth: '250px',
            },
        },
        // Şirket İsmi
        {
            type: 'text',
            value: {
                label: companyName ? companyName : defaultText,
            },
            style: { fontSize: '12px', minWidth: '200px' },
        },
        // EMail
        {
            type: 'text',
            value: {
                label: email ? email : defaultText,
            },
            style: { fontSize: '12px', minWidth: '150px' },
        },
        // Üye Tipi
        {
            type: 'text',
            value: {
                label: memberType ? memberType : defaultText,
            },
            style: { fontSize: '12px', minWidth: '150px' },
        },
        // Kalan Süre
        {
            type: 'text',
            value: {
                label: dayCount ? dayCount + ' ' + dayTranslation : defaultText,
            },
            style: { fontSize: '12px', minWidth: '100px' },
        },
    ];

    if (isAdmin) {
        defaultColumns.splice(1, 0, {
            type: 'actions',
            value: [
                // send mail
                {
                    id: id,
                    name: name,
                    disabled: false,
                },

                // remove record
                {
                    id: id,
                    name: name,
                    disabled: false,
                },
            ],
        });
    }
    return defaultColumns;
};
const appendSortQueryToUrl = (url, headers) => {
    const filteredHeaders = headers
        ? headers.filter((header) => header.type === 'sortLabel' && header.value.status !== GRID_HEADER_STATE.NONE)
        : [];

    if (filteredHeaders.length === 0) {
        return url;
    }

    let query = '';
    filteredHeaders.forEach((e) => {
        query += '&sort=';
        query += `${e.value.searchIndex},${e.value.status}`;
    });

    return `${url}${query}`;
};
