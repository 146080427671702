import { makeStyles } from '@material-ui/core';
import { poppins500_14px } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        [theme.breakpoints.down('md')]: {
            padding: '0 22px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '0 78px',
        },
        '& .MuiTextField-root, & .MuiFormControl-root': {
            width: '100%',
        },
        '& > div > div:not(:last-child)': {
            marginBottom: '24px',
        },
        '& label, & input, & select': {
            ...poppins500_14px,
        },
    },
    buttonContainer: {
        marginTop: '25px',
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            padding: '33px 78px !important',
            '& > :first-child': {
                justifyContent: 'flex-start',
            },
            '& > :last-child': {
                justifyContent: 'flex-end',
            },
        },
    },
    biography: {
        [theme.breakpoints.up('md')]: {
            height: '250px',
            '& .MuiInputBase-formControl': {
                height: '100%',
            },
        },
    },
    containerClass: {
        padding: '0',
    },
    formControl: {
        minWidth: 120,
    },
    select: {
        ...poppins500_14px,
    },
}));

export { useStyles };
