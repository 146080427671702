import { useCallback, useEffect } from 'react';

const EscKeyListener = ({ onEsc }) => {
    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            onEsc();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keyup', escFunction, false);

        return () => {
            document.removeEventListener('keyup', escFunction, false);
        };
    }, []);

    return null;
};

export default EscKeyListener;
