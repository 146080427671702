import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { HideFieldIcon, InfoIcon, IntroCardContent, IntroCardTitle } from 'fintech/components/profile/common';
import { formatMonetaryValue } from 'fintech/utils/StringUtils';
import PopperWrapper from 'fintech/components/util/PopperWrapper';
import { maskedText } from 'fintech/constants';

const StartupInvestmentInfoGrid = (props) => {
    const { breakPoints = {}, showInvestment, investmentIsPublic, investment, date, dateIsPublic, showDate } = props;
    const { t } = useTranslation();

    const maskedJSX = (
        <IntroCardContent>
            {maskedText} <InfoIcon fontSize="small" />
        </IntroCardContent>
    );

    let investmentJSX = null;
    if (showInvestment) {
        investmentJSX = (
            <IntroCardContent>
                {investment?.value &&
                    investment?.currencyType &&
                    formatMonetaryValue(investment.value, investment.currencyType)}
            </IntroCardContent>
        );
    } else {
        investmentJSX = maskedJSX;
    }

    let dateJSX = null;
    if (showDate) {
        dateJSX = <IntroCardContent>{date}</IntroCardContent>;
    } else {
        dateJSX = maskedJSX;
    }

    return (
        <Grid item {...breakPoints}>
            <Grid container direction="row" justify="flex-start">
                <Grid item xs={12} md={6}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        style={{ padding: '10px 2px' }}
                    >
                        <Grid item>
                            <IntroCardTitle>
                                {t('profile.financials.investors.investmentDate')}
                                {` `}
                                {showDate && !dateIsPublic && <HideFieldIcon />}
                            </IntroCardTitle>
                        </Grid>
                        <Grid item>
                            <PopperWrapper
                                showPopper={!showDate}
                                content={t('profile.financials.investors.hiddenDateMessage')}
                            >
                                {dateJSX}
                            </PopperWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        style={{ padding: '10px 2px' }}
                    >
                        <Grid item>
                            <IntroCardTitle>
                                {t('profile.financials.investors.investmentAmount')}
                                {` `}
                                {showInvestment && !investmentIsPublic && <HideFieldIcon />}
                            </IntroCardTitle>
                        </Grid>
                        <Grid item>
                            <PopperWrapper
                                showPopper={!showInvestment}
                                content={t('profile.financials.investors.hiddenInvestmentMessage')}
                            >
                                {investmentJSX}
                            </PopperWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StartupInvestmentInfoGrid;
