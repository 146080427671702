import React, { useState, useEffect } from 'react';
import useStyles, { ButtonTypeSelect } from 'fintech/components/util/ButtonTypeSelect';
import { Grid } from '@material-ui/core';
import { cloneDeep } from 'lodash';

const TypeSelect = ({ items: initialItems, onClick, className, loading }) => {
    const classes = useStyles();

    // eslint-disable-next-line no-undef
    const [items, setItems] = useState(cloneDeep(initialItems));
    const [selectedId, setSelectedId] = useState(1);

    const handleClick = (clickedItem) => {
        const localItems = items.map((cur) => {
            let updatedObj = {};

            if (cur.id === selectedId || clickedItem.id != cur.id) {
                updatedObj = { ...cur, selected: false };
            } else {
                updatedObj = { ...cur, selected: true };
            }

            updatedObj.selected ? setSelectedId(updatedObj.id) : setSelectedId(null);

            return updatedObj;
        });
        setItems(localItems);
        onClick && onClick(clickedItem.value);
    };

    useEffect(() => {
        setItems(cloneDeep(initialItems));
    }, [initialItems]);

    return (
        <Grid container justify={'space-between'} className={className} spacing={1}>
            {items.map((item) => (
                <Grid key={item.id} item className={classes.buttons}>
                    <ButtonTypeSelect
                        onClick={() => handleClick(item)}
                        style={{ width: '100%' }}
                        className={item.selected ? classes.selected : ''}
                        disabled={loading}
                    >
                        {item.name}
                    </ButtonTypeSelect>
                </Grid>
            ))}
        </Grid>
    );
};

export default TypeSelect;
