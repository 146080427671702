import { makeStyles } from '@material-ui/core';
import {
    poppins600_10px,
    poppins600_14px,
    poppins500_12px,
    poppins500_14px,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    COLOR_PRIMARY_BASE,
    COLOR_SECONDARY_BASE,
    COLOR_THEME_FILL_01,
    COLOR_THEME_FILL_03,
    COLOR_THEME_FILL_04,
    COLOR_PRIMARY_BUTTON_HOVERED,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        width: 260,
    },
    heading: {
        ...poppins500_14px,
        fontSize: '18px',
        borderLeft: '4px solid',
        borderLeftColor: COLOR_SECONDARY_BASE,
        paddingLeft: '16px',
        marginTop: '32px',
    },
    organizeWorkingHoursArea: {
        width: 'auto',
        display: 'inline-flex',
        marginLeft: 'auto',
        marginTop: '-12px',
        backgroundColor: COLOR_PRIMARY_BASE,
        borderRadius: '8px',
        padding: '4px 12px 4px 4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
        },
        [theme.breakpoints.down(840)]: {
            marginTop: '10px',
            marginLeft: 0,
        },
    },
    organizeWorkingHoursText: {
        ...poppins500_14px,
        color: COLOR_THEME_FILL_01,
        marginLeft: '4px',
    },
    inputLabel: {
        fontWeight: 'normal',
    },
    select: {
        ...poppins500_14px,
    },
    dateSelect: {
        maxHeight: '371px',
    },
    selectDateAndRequestType: {
        marginTop: '15px',
        '& > div:first-child': {
            marginRight: '16px',
        },
        [theme.breakpoints.down(840)]: {
            '& > div:last-child': {
                marginTop: '16px',
            },
        },
    },
    tableContainer: {
        padding: '3px',
        borderRadius: '8px',
        backgroundColor: 'white',
        marginTop: '17px',
    },
    table: {
        width: '100%',
        '& th, & td:not(:first-child)': {
            paddingLeft: '17px',
        },
        '& thead': {
            backgroundColor: COLOR_PRIMARY_SHADE_02,
            borderBottom: '1px solid',
            borderBottomColor: COLOR_PRIMARY_SHADE_03,
            '& th': {
                paddingTop: '11px',
                paddingBottom: '12px',
                ...poppins600_10px,
                [theme.breakpoints.down('1247')]: {
                    width: '33% !important',
                    paddingLeft: '6px',
                },
            },
            '& th:not(:last-child)': {
                borderRightWidth: '1px',
                borderRightStyle: 'solid',
                borderRightColor: COLOR_PRIMARY_SHADE_03,
            },
            '& th:first-child': {
                borderTopLeftRadius: '4px',
                width: '12.4%',
                '& span:last-child': {
                    marginLeft: '31px',
                    [theme.breakpoints.down('1247')]: {
                        marginLeft: '14px',
                    },
                },
            },
            '& th:nth-child(2)': {
                width: '24.2%',
            },
            '& th:last-child': {
                borderTopRightRadius: '4px',
            },
        },
        '& tbody': {
            '& tr': {
                borderBottom: '1px solid',
                borderBottomColor: COLOR_PRIMARY_SHADE_02,
                '&:first-child': {
                    '& td': {
                        paddingTop: '20px',
                        paddingBottom: '8px',
                    },
                },
                '&:not(:first-child)': {
                    '& td': {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                    },
                },
            },
        },
    },
    modal: {
        zIndex: '10000',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        backgroundColor: COLOR_THEME_FILL_01,
        width: '740px',
        height: '520px',
        padding: '24px 48px  32px',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            height: 'auto',
            padding: '10px',
        },
    },
    modalHeading: {
        fontSize: '24px',
        fontWeight: '600',
        textAlign: 'center',
        color: COLOR_SECONDARY_BASE,
    },
    modalDescription: {
        ...poppins500_12px,
        textAlign: 'center',
        marginTop: '11px',
    },
    modalButtons: {
        marginTop: '68px',
        '& button': {
            textTransform: 'none',
        },
        '& > :first-child > button': {
            backgroundColor: COLOR_PRIMARY_SHADE_02,
            '&:hover': {
                backgroundColor: COLOR_PRIMARY_SHADE_03,
            },
        },
    },
    cancelButton: {
        backgroundColor: '#fae6e6',
        color: COLOR_THEME_FILL_03,
    },
    acceptButton: {
        backgroundColor: COLOR_PRIMARY_BASE,
        marginLeft: '8px',
        paddingLeft: '4px',
    },
    time: {
        ...poppins500_12px,
        paddingLeft: '4px',
        '& > span': {
            cursor: 'pointer',
            '&:last-child': {
                [theme.breakpoints.down('376')]: {
                    display: 'block',
                    paddingLeft: '11px',
                    paddingTop: '4px',
                },
            },
        },
    },
    requirer: {
        ...poppins600_14px,
        '& > div': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& > span': {
                marginLeft: '11px',
            },
        },
    },
    avatar: {
        width: '32px',
        height: '32px',
    },
    attemptName: {
        ...poppins500_12px,
    },
    popoverContent: {
        padding: '14px 43px 15px 11px',
        '& > div': {
            cursor: 'pointer',
        },
    },
    accept: {
        ...poppins500_14px,
    },
    reject: {
        ...poppins500_14px,
        color: COLOR_THEME_FILL_03,
    },
    acceptRejectButton: {
        backgroundColor: COLOR_PRIMARY_BASE,
        marginLeft: '8px',
        paddingLeft: '4px',
    },
    warningArea: {
        backgroundColor: COLOR_THEME_FILL_04,
        marginTop: '16px',
        padding: '14px 17px 14px 20px',
        borderRadius: '4px',
        fontSize: '12px',
        color: COLOR_THEME_FILL_03,
        '& > :last-child': {
            marginLeft: '10px',
        },
    },
    warningDetail: {
        flexGrow: 1,
    },
    warningText: {
        maxWidth: '784px',
    },
}));

export default useStyles;
