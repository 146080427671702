import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAttempListData } from 'fintech/store/actions/attemp';
import { useKeycloak } from '@react-keycloak/web';
import { fetchStartupIntroData, fetchStartupTeamData, updateVisitCount } from 'fintech/store/actions/startup';
import { Box } from '@material-ui/core';
import IntroGrid from 'fintech/components/profile/startup/Intro/IntroGrid';
import PageTemplate from 'fintech/components/PageTemplate';
import TabSwitch from 'fintech/components/ui/navigation/TabSwitch';
import TeamGrid from 'fintech/components/profile/startup/Team/TeamGrid';
import SimilarVentures from 'fintech/components/profile/startup/SimilarVentures';
import { VENTURE_TYPES, STATES } from 'fintech/store/actions/ActionTypes';
import GeneralInfoGrid from 'fintech/components/profile/startup/GeneralInfo/GeneralInfoGrid';
import Financials from 'fintech/components/profile/startup/Financials';
import * as PAGE_TYPES from 'fintech/components/util/PageTypes';
import {
    getStartupDocuments,
    updateStartupDocuments,
    deleteStartupDocument,
    deleteStartupTeamMember,
    cancelStartupTeamInvitation,
    postStartupInviteMember,
    updateStartupTeamMember,
} from 'fintech/api/fintechService';

const AttempPage = (props) => {
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { t } = props;
    // retrieve params into a variable
    const params = useParams();

    const [generalInfo, editable] = useSelector((state) => {
        const { startupInfos, editable } = state.startup.intro.data;
        return [startupInfos?.generalInfo?.value, editable];
    });
    const { data: startupIntroData, isLoading, isError } = useSelector((state) => state.startup.intro);

    const [attemptId, setAttempId] = useState(params.id);

    const [ventureType, setVentureType] = useState();

    const tabs = useMemo(() => {
        const documentServices = {
            getDocuments: getStartupDocuments,
            updateDocuments: updateStartupDocuments,
            deleteDocuments: deleteStartupDocument,
        };
        const teamServices = {
            fetchTeamData: fetchStartupTeamData,
            deleteTeamMember: deleteStartupTeamMember,
            cancelTeamInvitation: cancelStartupTeamInvitation,
            postInviteMember: postStartupInviteMember,
            updateTeamMember: updateStartupTeamMember,
        };

        return getTabs(
            ventureType,
            attemptId,
            t,
            documentServices,
            teamServices,
            generalInfo,
            editable,
            startupIntroData,
            isLoading,
            isError
        );
    }, [ventureType]);

    useEffect(() => {
        setVentureType(startupIntroData.ventureType?.description);
    }, [startupIntroData.ventureType?.description]);

    useEffect(() => {
        if (!attemptId) dispatch(fetchAttempListData(keycloak));
    }, []);

    useEffect(() => {
        setAttempId(params.id);
    }, [params.id, params.type]);

    useEffect(() => {
        dispatch(fetchStartupIntroData(keycloak, attemptId));
        dispatch(updateVisitCount(keycloak, PAGE_TYPES.STARTUP_PROFILE, attemptId));
    }, [attemptId]);

    return (
        <PageTemplate>
            <IntroGrid id={attemptId} ventureType={ventureType} />
            <Box height="32px" />
            {tabs && <TabSwitch tabs={tabs} />}
        </PageTemplate>
    );
};

export default withTranslation('profile')(AttempPage);

const getTabs = (
    ventureType,
    attemptId,
    t,
    documentServices,
    teamServices,
    generalInfo,
    editable,
    introData,
    isLoading,
    isError
) => {
    const isStartup =
        ventureType && (ventureType === VENTURE_TYPES.STARTUP || ventureType === VENTURE_TYPES.BACKED_STARTUP);
    const isIdea = ventureType && (ventureType === VENTURE_TYPES.IDEA || ventureType === VENTURE_TYPES.BACKED_IDEA);

    const ideaTabs = [
        {
            header: { label: t('profile.generalInfo.label') },
            component: (
                <GeneralInfoGrid
                    documentServices={documentServices}
                    generalInfo={generalInfo}
                    editable={editable}
                    introData={introData}
                    isLoading={isLoading}
                    isError={isError}
                    uuid={attemptId}
                />
            ),
            errorMsg: t('profile.generalInfo.errorMessage'),
        },
        {
            header: { label: t('profile.team.label') },
            component: <TeamGrid id={attemptId} type={STATES.STARTUP} teamServices={teamServices} />,
            errorMsg: t('profile.team.errorMessage'),
        },
        {
            header: { label: t('profile.similarVentures.label') },
            component: <SimilarVentures id={attemptId} />,
            errorMsg: t('profile.similarVentures.errorMessage'),
        },
    ];

    if (isStartup) {
        const tmpTabs = ideaTabs.slice();
        tmpTabs.splice(2, 0, {
            header: { label: t('profile.financials.label') },
            component: <Financials id={attemptId} />,
            errorMsg: t('profile.financials.errorMessage'),
        });
        return tmpTabs;
    } else if (isIdea) {
        return ideaTabs;
    }
};
