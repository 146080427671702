import React, { useState } from 'react';
import ReportCard from 'fintech/components/reports/common/card';
import IconsMultipleChat from 'fintech/icons/IconsMultipleChat';
import Table from 'fintech/components/reports/common/table';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { getGeneral } from 'fintech/api/fintechService';

const Index = () => {
    const { t } = useTranslation('reports');
    const { keycloak } = useKeycloak();
    const [generalData, setGeneralData] = useState(null);

    return (
        <ReportCard
            title={t('card.messaging')}
            icon={<IconsMultipleChat />}
            isLoading={generalData === null}
            isCollapse
            onOpen={() => {
                if (generalData === null)
                    getGeneral(keycloak).then((e) => {
                        setGeneralData(e.data);
                    });
            }}
        >
            {generalData !== null && (
                <Table
                    column={[
                        { title: t('card.messageStartingCount'), dataIndex: 'roomCount' },
                        { title: t('card.totalMessageCount'), dataIndex: 'messageCount' },
                    ]}
                    dataSource={generalData}
                />
            )}
        </ReportCard>
    );
};

Index.propTypes = {};

export default Index;
