import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';
import useStyles from 'fintech/components/util/LoadingSpinnerWithText.style';

const LoadingSpinnerWithText = ({ loadingMessage, style, iconGridStyle }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Grid container direction="column" justify="center" alignItems="center" style={style}>
            <Grid item>
                <Typography className={classes.BgPara}>{loadingMessage || t('loadingMessage')}</Typography>
            </Grid>
            <Grid item className={`${classes.iconGrid} ${iconGridStyle || ''}`}>
                <LoadingSpinner />
            </Grid>
        </Grid>
    );
};

export default LoadingSpinnerWithText;
