import * as React from 'react';

function SvgChevronRight(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <defs>
                <filter id="a9o2q51g0a" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g
                                filter="url(#a9o2q51g0a)"
                                transform="translate(-1287 -1992) translate(0 1579) translate(120 412) translate(1167 1)"
                            >
                                <path
                                    fill="#3F5162"
                                    fillRule="nonzero"
                                    d="M13.536 23.733L20.869 16.4 13.536 9.067 11.805 10.797 17.437 16.4 11.805 22.003z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgChevronRight;
