import React, {useEffect, useRef, useState} from 'react';

import { makeConversationAreaStyles } from "./ConversationArea.style";
import MessageBox from "fintech/components/chat/MessageBox";
import {getRequest} from "fintech/api/api";
import {getPublicFileUrl} from "fintech/api/fileService";
import { APIS } from 'fintech/constants';
import {useKeycloak} from "@react-keycloak/web";

const ConversationArea = ({ messages, currentClientUuid }) => {
    const { keycloak } = useKeycloak();
    const conversationAreaStyles = makeConversationAreaStyles();
    const [fetchedMessages, setFetchedMessages] = useState([]);
    const [userProfilePictureSrc, setUserProfilePictureSrc] = useState(null);
    const [clientProfilePictureSrc, setClientProfilePictureSrc] = useState(null);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        setFetchedMessages(messages);
    }, [messages]);

    useEffect(() => {
        setProfileImages();
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [fetchedMessages]);

    const setProfileImages = () => {
        getRequest(APIS.USER.getUserImage(keycloak.subject), keycloak).then((data) => {
            let fileUuid = data.uuid;
            setUserProfilePictureSrc(getPublicFileUrl(fileUuid));
        });

        getRequest(APIS.USER.getUserImage(currentClientUuid), keycloak).then((data) => {
            let fileUuid = data.uuid;
            setClientProfilePictureSrc(getPublicFileUrl(fileUuid));
        });

    };

    return <div className={conversationAreaStyles.area}>
            {fetchedMessages.map((message) => {
                return (<MessageBox
                        message={message}
                        currentClientUuid={currentClientUuid}
                        userProfilePictureSrc={userProfilePictureSrc}
                        clientProfilePictureSrc={clientProfilePictureSrc}
                    />
                );
            })}
        <div ref={messagesEndRef} />
    </div>;
};

export default ConversationArea;
