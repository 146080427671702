import React, { useState, useRef, useEffect } from 'react';
import useStyles from './index.style';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import IconsMultipleMenu1 from 'fintech/icons/IconsMultipleMenu1';
import IconsMultipleMenu2 from 'fintech/icons/IconsMultipleMenu2';
import IconsMultipleMenu3 from 'fintech/icons/IconsMultipleMenu3';
import IconsMultipleMenu4 from 'fintech/icons/IconsMultipleMenu4';
import * as ROUTES from 'fintech/routes';

const ApplyMenu = React.forwardRef((props, ref) => {
    const { handleOutsideClick, setIsMenuOpen, isMentorApplicationActive } = props;
    const { t } = useTranslation('preLogin');
    const [style, setStyle] = useState({});
    const history = useHistory();
    const selfRef = useRef(null);
    const classes = useStyles();

    useEffect(() => {
        if (ref && ref.current && selfRef && selfRef.current) {
            const refRect = ref.current.getBoundingClientRect();
            const selfRect = selfRef.current.getBoundingClientRect();
            const top = refRect.bottom + 10;
            const left = refRect.left - selfRect.width + refRect.width;
            setStyle({ top, left });
        }
    }, [ref, selfRef]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selfRef.current && !selfRef.current.contains(event.target)) {
                handleOutsideClick(event);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selfRef]);

    const scrollTo = (element) => {
        const { top, left } = document.getElementById(element).getBoundingClientRect();

        window.scroll({ top, left, behavior: 'smooth' });
        setIsMenuOpen(false);
    };

    return (
        <div className={classes.applyMenuWrapper} style={style} ref={selfRef}>
            <ul className={classes.applyList}>
                <li onClick={() => scrollTo('section-support')}>
                    <span>
                        <IconsMultipleMenu1 />
                    </span>
                    {t('applyMenu.supportProgram')}
                </li>
                <li onClick={() => history.push(ROUTES.STARTUP_APPLICATION)}>
                    <span>
                        <IconsMultipleMenu3 />
                    </span>
                    {t('applyMenu.applyAsStartup')}
                </li>
                <li onClick={() => history.push(ROUTES.INVESTOR_APPLICATION)}>
                    <span>
                        <IconsMultipleMenu4 />
                    </span>
                    {t('applyMenu.applyAsInvestor')}
                </li>
                {isMentorApplicationActive && (
                    <li onClick={() => history.push(ROUTES.MENTOR_APPLICATION)}>
                        <span>
                            <IconsMultipleMenu2 />
                        </span>
                        {t('applyMenu.applyAsMentor')}
                    </li>
                )}
            </ul>
        </div>
    );
});

export default ApplyMenu;
