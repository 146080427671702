import * as React from 'react';

function SvgPlaceholdersPerson(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="66"
            height="66"
            viewBox="0 0 66 66"
        >
            <defs>
                <circle id="4psdru3ika" cx="32" cy="32" r="32" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-175.000000, -61.000000) translate(176.000000, 62.000000)">
                        <mask id="fjz5a9q7xb" fill="#fff">
                            <use xlinkHref="#4psdru3ika" />
                        </mask>
                        <circle cx="32" cy="32" r="32.5" stroke="#D7E2E8" />
                        <g mask="url(#fjz5a9q7xb)">
                            <g>
                                <path
                                    fill="#D9DCE0"
                                    d="M11.02 3.306c0-1.521 1.234-2.755 2.756-2.755C23.143.551 27 10.469 27 10.469l3.306 8.817v1.102H27V27c0 .608-.494 1.102-1.102 1.102h-11.02c-2.13 0-3.858-1.727-3.858-3.857V3.306z"
                                    transform="translate(14.000000, 14.000000) translate(2.250000, 0.000000)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M20.939 35.265L22.041 35.265 22.041 28.102 20.939 28.102z"
                                    transform="translate(14.000000, 14.000000) translate(2.250000, 0.000000)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M25.898 28.653h-8.265v-1.102h8.265c.304 0 .551-.247.551-.551v-7.163h3.306v-.451l-3.271-8.723c-.138-.409-3.315-9.561-12.708-9.561H4.408V0h9.368c10.197 0 13.712 10.192 13.746 10.295l3.335 8.891v1.753h-3.306V27c0 .911-.742 1.653-1.653 1.653M2.204 1.102L3.306 1.102 3.306 0 2.204 0zM0 1.102L1.102 1.102 1.102 0 0 0zM1.653 13.224L8.265 13.224 8.265 12.122 1.653 12.122z"
                                    transform="translate(14.000000, 14.000000) translate(2.250000, 0.000000)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgPlaceholdersPerson;
