import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { fetchInvestorTypes, fetchInvestorGroups } from 'fintech/store/actions/establishment';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import { Grid, FormControl, FormHelperText } from '@material-ui/core';
import CfoDatePicker from 'fintech/components/ui/form/picker/CfoDatePicker';
import useStyles from './index.style';
import * as constants from 'fintech/components/util/ActionModalConstants';
import { isEmpty } from 'lodash';
import SingleSelect from 'fintech/components/ui/form/select/SingleSelect';

const GeneralInfoModal = ({
    dateFounded,
    webSite: _website,
    infoChangeCallback,
    investorType,
    investorGroup,
    formIsSubmitted,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    const { uuid } = useSelector((state) => state.establishment.intro.data);
    const { data: investorTypes } = useSelector((state) => state.establishment.intro.form.investorTypes);
    const { data: investorGroups } = useSelector((state) => state.establishment.intro.form.investorGroups);
    const [selectedInvestorType, setSelectedInvestorType] = useState(investorType);
    const [selectedInvestorGroup, setSelectedInvestorGroup] = useState(investorGroup);
    const [website, setWebsite] = useState({ value: _website.value, validated: _website.validated });
    const [foundedDate, setFoundedDate] = useState({ value: dateFounded.value, validated: dateFounded.validated });

    useEffect(() => {
        if (investorType.id !== 0 && selectedInvestorType.id === 0) {
            setSelectedInvestorType(investorType);
        }
    }, [investorType.id]);

    useEffect(() => {
        if (investorGroup.id !== 0 && selectedInvestorGroup.id === 0) {
            setSelectedInvestorGroup(investorGroup);
        }
    }, [investorGroup.id]);

    useEffect(() => {
        if (isEmpty(investorTypes)) dispatch(fetchInvestorTypes(keycloak, uuid));
        if (isEmpty(investorGroups)) dispatch(fetchInvestorGroups(keycloak, uuid));
    }, []);

    const dateChangeHandler = (val, validated) => {
        const newState = {
            value: val === null ? '' : val,
            validated: val === null ? true : validated,
        };

        setFoundedDate(newState);
        infoChangeCallback({ type: constants.ESTABLISHMENT_DATE_FOUNDED_UPDATE, data: newState });
    };

    const investorTypeChangeHandler = (data) => {
        setSelectedInvestorType(data);
        infoChangeCallback({
            type: constants.ESTABLISHMENT_INVESTOR_TYPE_UPDATE,
            data: data,
        });
    };

    const investorGroupChangeHandler = (data) => {
        setSelectedInvestorGroup(data);
        infoChangeCallback({ type: constants.ESTABLISHMENT_INVESTOR_GROUP_UPDATE, data: data });
    };

    const investorWebsiteChangeHandler = ({ value, validated }) => {
        setWebsite(() => ({ value, validated }));
        infoChangeCallback({ type: constants.ESTABLISHMENT_INVESTOR_WEBSITE_UPDATE, data: { value, validated } });
    };

    const isInvestorTypeValid = formIsSubmitted && (!selectedInvestorType || !selectedInvestorType.id);
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} md={6}>
                <CfoDatePicker
                    id={t('profile.startup.dateFounded')}
                    label={t('profile.startup.dateFounded')}
                    invalidDateMessage={foundedDate.value ? t('validation.date') : t('validation.notEmpty')}
                    initialDate={foundedDate.value || null}
                    onChange={dateChangeHandler}
                    autoOk
                    openTo="year"
                    disableFuture
                    maxDateMessage={t('validation.noFutureDate')}
                    required={!!foundedDate.value}
                    dontValidate={!foundedDate.value}
                />
            </Grid>

            <Grid item xs={12} md={6} className={classes.item}>
                <FormControl variant="outlined" className={classes.formControl} error={isInvestorTypeValid}>
                    <SingleSelect
                        label={t('profile.establishments.investorType')}
                        canBeEmpty={false}
                        options={investorTypes}
                        initValue={selectedInvestorType}
                        onChange={investorTypeChangeHandler}
                    />
                    <FormHelperText
                        style={{
                            display: isInvestorTypeValid ? 'block' : 'none',
                        }}
                        error={isInvestorTypeValid}
                    >
                        {t('validation.notEmpty')}
                    </FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6} className={classes.item}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <SingleSelect
                        label={t('profile.establishments.angelInvestorGroup')}
                        canBeEmpty={false}
                        options={investorGroups}
                        initValue={selectedInvestorGroup}
                        onChange={investorGroupChangeHandler}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <InputTextArea
                    validateOnBlur
                    initText={website.value}
                    onChange={(val, validated) => investorWebsiteChangeHandler({ value: val, validated })}
                    label={t('formLabel.website')}
                    validationText={t('validation.url')}
                    type="url"
                    id="outlined-multiline-static"
                    variant="outlined"
                    initiallyValidated
                />
            </Grid>
        </Grid>
    );
};

export default GeneralInfoModal;
