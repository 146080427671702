import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import { IntroCardTitle } from 'fintech/components/profile/common';
import useStyles from './index.style';

const GeneralInfoHeader = (props) => {
    const classes = useStyles();
    const { t, editable, onEditClickCallback, title } = props;

    return (
        <Grid
            className={classes.StupGenInfoHeaderGrid}
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
        >
            <Grid item xs={editable && 8}>
                <IntroCardTitle>{title}</IntroCardTitle>
            </Grid>
            {editable && (
                <Grid item xs={4} style={{ textAlign: 'end' }} onClick={() => onEditClickCallback()}>
                    <ShrinkingButton
                        shrinkedIcon={<Edit fontSize="small" />}
                        fullIcon={<Edit />}
                        fullText={t('profile.edit')}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default withTranslation('profile')(GeneralInfoHeader);
