import React, { useState } from 'react';
import ReportCard from 'fintech/components/reports/common/card';
import IconsMultipleDanisman from 'fintech/icons/IconsMultipleDanisman';
import { useTranslation } from 'react-i18next';
import Table from 'fintech/components/reports/common/table';
import { useKeycloak } from '@react-keycloak/web';
import { getConsultant } from 'fintech/api/fintechService';
import { ReportType } from 'fintech/utils/ReportUtils';

const Index = ({ onShowModal }) => {
    const { t } = useTranslation('reports');
    const { keycloak } = useKeycloak();
    const [consultantsData, setConsultants] = useState(null);

    const rowKeys = [
        'incomingMeetingRequestCount',
        'averageMeetingRequestResponseTime',
        'acceptedMeetingCount',
        'rejectedMeetingCount',
        'cancelledMeetingByConsultantCount',
        'cancelledMeetingByUserCount',
    ];

    const getRow = columnKey => ({
        title: t('card.' + columnKey),
        dataIndex: columnKey,
    });

    return (
        <ReportCard
            title={t('card.consultants')}
            icon={<IconsMultipleDanisman />}
            button={{
                text: t('card.generateDetailedReport'),
                onClick: () => {
                    onShowModal(ReportType.CONSULTANT);
                },
            }}
            isLoading={consultantsData === null}
            isCollapse
            onOpen={() => {
                if (consultantsData === null)
                    getConsultant(keycloak).then((e) => {
                        setConsultants(e.data);
                    });
            }}
        >
            {consultantsData !== null && (
                <Table
                    column={rowKeys.map(getRow)}
                    dataSource={consultantsData}
                />
            )}
        </ReportCard>
    );
};

Index.propTypes = {};

export default Index;
