import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_PRIMARY_BASE, mobileMediaQuery } from 'fintech/components/style/common';
import SvgIconsSingleArrowRight from 'fintech/icons/IconsSingleArrowRight';
import { Typography, ListItemText, Button, List, ListItem, Grid, useMediaQuery } from '@material-ui/core';
import { StyledListItemIcon } from 'fintech/components/ui/button/SplitButton/index.style';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import useStyles from 'fintech/components/support/program/form/SupportProgramApplication/index.style';

const PreApplicationInformation = ({ onApplyClick }) => {
    const { t } = useTranslation('supportProgram');
    const { t: t2 } = useTranslation('supportProgramApplication');
    const classes = useStyles();
    const isMobile = !useMediaQuery(mobileMediaQuery);

    return (
        <CfoFormContainer>
            <List>
                <ListItem>
                    <Typography className={classes.pageTitle}>{t2('pageTitles.preApplicationInformation')}</Typography>
                </ListItem>
                <ListItem>
                    <StyledListItemIcon style={{ marginRight: '10px' }}>
                        <SvgIconsSingleArrowRight />
                    </StyledListItemIcon>
                    <ListItemText>
                        <Typography>{t('process.warning')}</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <StyledListItemIcon style={{ marginRight: '10px' }}>
                        <SvgIconsSingleArrowRight />
                    </StyledListItemIcon>
                    <ListItemText>
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: t('process.step1', { color: COLOR_PRIMARY_BASE, totalCount: 43 }),
                            }}
                        />
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <StyledListItemIcon style={{ marginRight: '10px' }}>
                        <SvgIconsSingleArrowRight />
                    </StyledListItemIcon>
                    <ListItemText>
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: t('process.step2', {
                                    color: COLOR_PRIMARY_BASE,
                                    totalCount: 43,
                                    optionalCount: 18,
                                }),
                            }}
                        />
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <StyledListItemIcon style={{ marginRight: '10px' }}>
                        <SvgIconsSingleArrowRight />
                    </StyledListItemIcon>
                    <ListItemText>
                        <Typography>{t('process.step3')}</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <StyledListItemIcon style={{ marginRight: '10px' }}>
                        <SvgIconsSingleArrowRight />
                    </StyledListItemIcon>
                    <ListItemText>
                        <Typography>{t('process.step4')}</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem className={classes.preAppBtnListItem}>
                    <Grid container direction="row" justify={isMobile ? 'center' : 'flex-start'} alignItems="center">
                        <Button
                            className={classes.primaryButton}
                            style={{ width: '200px' }}
                            onClick={onApplyClick}
                            fullWidth
                        >
                            {t('process.start')}
                        </Button>
                    </Grid>
                </ListItem>
            </List>
        </CfoFormContainer>
    );
};

export default PreApplicationInformation;
