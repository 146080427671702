import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_SHADE_03 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    applyButton: {
        height: '48px',
        color: '#3f5162',
        backgroundColor: 'white',
        fontWeight: 600,
        padding: '12px 14px 12px 18px',
        borderRadius: '8px',
        textTransform: 'none',
        minWidth: 'fit-content',
        '& span': {
            minWidth: 'fit-content',
        },
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        },
    },
});

export default useStyles;
