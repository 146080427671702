import useWindowSize from 'fintech/hooks/useWindowSize';
import { useEffect } from 'react';

const useStickyTableHeaderPositioning = ({ disableStickyHeader, rows }) => {
    const windowSize = useWindowSize();

    const scrollEventListener = (stickyTableHeader, navtabs, dataGridWrapper) => {
        const originalDistance = dataGridWrapper.getBoundingClientRect().top - navtabs.getBoundingClientRect().top;
        if (window.pageYOffset > originalDistance) {
            let totalOffset = window.pageYOffset;
            if (window.innerWidth < 900) {
                totalOffset -= 86;
            } else {
                totalOffset -= 21;
            }
            stickyTableHeader.style.top = `${totalOffset - originalDistance}px`;
        } else {
            stickyTableHeader.style.top = 'unset';
        }
    };

    useEffect(() => {
        if (!disableStickyHeader) {
            const stickyTableHeader = document.getElementById('sticky-table-header');
            const navtabs = document.getElementById('search-navtabs');
            const dataGridWrapper = document.getElementById('search-data-grid-wrapper');

            stickyTableHeader.style.position = 'sticky';
            stickyTableHeader.style.zIndex = 2;
            document.addEventListener(
                'scroll',
                scrollEventListener.bind(this, stickyTableHeader, navtabs, dataGridWrapper)
            );

            return () => {
                document.removeEventListener('scroll', scrollEventListener);
            };
        }
    }, [rows, windowSize.width]);
};

export default useStickyTableHeaderPositioning;
