import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import * as StartupActions from 'fintech/store/actions/startup';

import SearchDataGrid from 'fintech/components/search/DataGrid';
import { ENTREPRENEURS } from 'fintech/routes';
import { ATTEMPT } from 'fintech/routes';

function RenderStickyTable(props) {
    const { t, errorCallback } = props;
    const type = 'startup.similarVentures';

    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { headers, pagination, rows } = useSelector((state) => state.startup.similarVentures.grid);
    const { uuid } = useSelector((state) => state.startup.intro.data);

    const showSuccessMessage = (message) => enqueueSnackbar(message, { variant: 'success' });
    const showUpdateError = () => enqueueSnackbar(t('idea.updateError'), { variant: 'error' });

    return (
        <>
            {rows && rows.length > 0 && (
                <Typography style={{ fontSize: '18px', fontWeight: '500', marginTop: '30px', marginBottom: '10px' }}>
                    {t('startup.similarVentures.allStartupsListed')}
                </Typography>
            )}

            <SearchDataGrid
                type={type}
                headers={headers}
                pagination={pagination}
                leftStickyColumnCount={1}
                isLastQueryTypeLogicExists={false}
                messages={{
                    emptyMessageTitle: 'similarVenturesDoesNotExist',
                    buttonText: t('startup.similarVentures.goToStartups'),
                }}
                buttonActions={{
                    favouriteCompany: (value, index, subIndex) => {
                        dispatch(
                            StartupActions.favoriteStartupOnSimilarVenture(
                                keycloak,
                                value.startupUuid,
                                () =>
                                    showSuccessMessage(
                                        t('entrepreneur.addedStartupToFavorite').replace('${0}', value.label)
                                    ),
                                showUpdateError,
                                index,
                                subIndex
                            )
                        );
                    },
                    unfavouriteCompany: (value, index, subIndex) => {
                        dispatch(
                            StartupActions.unFavoriteStartupOnSimilarVenture(
                                keycloak,
                                value.startupUuid,
                                () =>
                                    showSuccessMessage(
                                        t('entrepreneur.removeStartupToFavorite').replace('${0}', value.label)
                                    ),
                                showUpdateError,
                                index,
                                subIndex
                            )
                        );
                    },
                    goToEntrepreneurs: () => {
                        window.scrollTo(0, 0);
                        history.push(ENTREPRENEURS);
                    },
                    handleRowClick: (startupUuid) => {
                        history.push(`${ATTEMPT}/${startupUuid}`);
                    },
                    sort: (id) => {
                        dispatch(StartupActions.toggleGridHeaderOnSimilarVenture(id));
                        dispatch(StartupActions.fetchSimilarVenturesData(keycloak, uuid, errorCallback));
                    },
                }}
            />
        </>
    );
}

export default withTranslation('entrepreneur')(RenderStickyTable);
