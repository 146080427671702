import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    LogoGrid: {
        maxWidth: '80px',
        padding: '10px 2px',
    },
    NameGrid: {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    MemberLogoMask: {
        width: '48px',
        height: '48px',
        marginRight: '16px',
        borderRadius: '8px',
        background: '#ffffff',
    },
    logoMedia: {
        width: '48px',
        height: '48px',
    },
    ventureLogoMedia: {
        width: '48px',
        height: '48px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        '& img': {
            padding: '6px',
            width: '85%',
            height: '85%',
        },
    },
});

export default useStyles;
