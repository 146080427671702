import { GRID_HEADER_STATE } from 'fintech/store/actions/entrepreneur';

// TODO: Use constants for field value
// Investor Filter categories
const categories = [
    {
        id: 0,
        value: 'fintechVerticals',
    },
    {
        id: 1,
        value: 'technologies',
    },
    {
        id: 2,
        value: 'roles',
    },
];

export const defaultFilterCategories = [...categories];

// Currently unused until Phase 2
export const adminFilterCategories = [
    {
        id: 4,
        value: 'status',
    },
    ...categories,
];

// Investor Search
export const defaultSearchBoxFilters = [
    { id: 0, label: 'name', status: true },
    { id: 2, label: 'fintechVerticals', status: true },
    { id: 3, label: 'technologies', status: true },
];

// Currently unused until Phase 2
export const searchBoxAdminFilters = [
    {
        id: 5,
        label: 'status',
    },
    ...defaultSearchBoxFilters,
];

const headers = [
    {
        id: 0,
        type: 'sortLabel',
        value: 'dataGrid.headers.name',
        status: GRID_HEADER_STATE.ASC,
    },
    {
        id: 1,
        type: 'label',
        value: 'dataGrid.headers.role',
    },
    {
        id: 2,
        type: 'label',
        value: 'dataGrid.headers.fintechVerticals',
    },
    {
        id: 4,
        type: 'label',
        value: 'dataGrid.headers.technologies',
    },
];

export const defaultTableHeaders = [...headers];

export const adminTableHeaders = [
    {
        id: 5,
        type: 'label-actions',
        value: '#',
    },
    {
        id: 6,
        type: 'label-status',
        value: 'dataGrid.headers.status',
    },
    ...headers,
];
