import { postRequest } from 'fintech/api/api';
import { APIS } from 'fintech/constants';
import { MessageStatus, USER_INFO_BATCH_SIZE } from 'fintech/utils/InboxUtils';
import { InboxActions } from './ActionTypes';

export const setViewedRoom = (viewedRoomId, targetUser) => ({
    type: InboxActions.SET_VIEWED_ROOM,
    viewedRoomId,
    targetUser,
});

export const createRoomWith = (username, successCallback, keycloak) => {
    return (dispatch, getState) => {
        const { rooms, socket: chatSocket } = getState().inbox;
        chatSocket.createRoom(username, (roomData) => {
            const { id: roomId } = roomData;
            dispatch(getUsersInfo({ [username]: roomId }, keycloak));
            if (roomId && rooms[roomId] == undefined) {
                dispatch(addRoom(roomData));
                chatSocket.fetchMessages({ roomId }, (messages) => {
                    dispatch(setMessages(roomId, messages));
                });
            }
            dispatch(setViewedRoom(roomId));
            successCallback();
        });
    };
};

export const setActiveMessage = (text) => ({
    type: InboxActions.SET_ACTIVE_MESSAGE,
    text,
});

const _sendMessage = (message) => ({
    type: InboxActions.SEND_MESSAGE,
    message,
});

export const sendMessage = (message) => (dispatch, getState) => {
    const { viewedRoomId } = getState().inbox;
    dispatch(_sendMessage(message));
    dispatch(updateRoomLastMessage(viewedRoomId, message));
};

export const setRoomList = (rooms) => {
    return {
        type: InboxActions.SET_ROOM_LIST,
        rooms,
    };
};

const _setMessages = (roomId, messages) => {
    return {
        type: InboxActions.SET_MESSAGES,
        roomId,
        messages,
    };
};

export const setMessages = (roomId, messages) => {
    return (dispatch, getState) => {
        dispatch(_setMessages(roomId, messages));

        const inboxState = getState().inbox;
        const chatSocket = inboxState.socket;
        const targetUserName = inboxState.rooms[roomId].targetUser.userName;
        messages?.forEach((message) => {
            if (message.owner === targetUserName) {
                chatSocket.sendReceived(message);
            }
        });
    };
};

const addRoom = (roomData) => {
    return {
        type: InboxActions.ADD_ROOM,
        roomData,
    };
};

export const fetchRoom = (roomId, keycloak) => {
    return (dispatch, getState) => {
        const chatSocket = getState().inbox.socket;
        chatSocket.fetchRoom(roomId, (roomData) => {
            const {
                id: roomId,
                lastMessage: { queuedAt },
            } = roomData;
            dispatch(getUsersInfo({ [roomData.targetUser.userName]: roomId }, keycloak));
            chatSocket.getRoomInfo(
                {
                    requests: [
                        {
                            roomId,
                            lastMessageQueuedAt: queuedAt,
                        },
                    ],
                },
                (pendingRoomInfo) => {
                    dispatch(
                        setRoomList(
                            rooms.map(({ roomId, unreadMessageCount, ...rest }) => {
                                const { lastMessage, unreadMessageCount: pendingUnreadCount } = pendingRoomInfo[roomId];
                                return {
                                    ...rest,
                                    lastMessage,
                                    unreadMessageCount: unreadMessageCount + pendingUnreadCount,
                                };
                            })
                        )
                    );
                }
            );
            dispatch(addRoom(roomData));
            chatSocket.fetchMessages({ roomId }, (messages) => {
                dispatch(setMessages(roomId, messages));
            });
        });
    };
};

const addMessage = (messageData) => {
    return {
        type: InboxActions.ADD_MESSAGE,
        messageData,
    };
};

export const getMessage = (messageData, keycloak) => {
    return (dispatch, getState) => {
        const { rooms, viewedRoomId } = getState().inbox;
        const { roomId } = messageData;
        if (rooms[roomId] == undefined) {
            dispatch(fetchRoom(roomId, keycloak));
        }
        dispatch(addMessage(messageData));
        dispatch(updateRoomLastMessage(roomId, messageData));
        if (viewedRoomId !== roomId) {
            dispatch(increaseUnread(roomId));
        }
    };
};

export const getReceived = (roomId, messageId, seen) => {
    return {
        type: InboxActions.GET_RECEIVED,
        roomId,
        messageId,
        seen,
    };
};

const removeMessage = (roomId, messageId) => {
    return {
        type: InboxActions.REMOVE_MESSAGE,
        roomId,
        messageId,
    };
};

const updateRoomLastMessage = (roomId, message) => {
    return {
        type: InboxActions.UPDATE_ROOM_LAST_MESSAGE,
        roomId,
        message,
    };
};

const removeRoomLastMessage = (roomId) => {
    return {
        type: InboxActions.REMOVE_ROOM_LAST_MESSAGE,
        roomId,
    };
};

export const deleteMessage = (roomId, messageId) => (dispatch, getState) => {
    const { rooms } = getState().inbox;
    if (rooms[roomId].lastMessage.messageId === messageId) {
        dispatch(removeRoomLastMessage(roomId));
    }
    dispatch(removeMessage(roomId, messageId));
};

export const approveRoom = (roomId) => {
    return {
        type: InboxActions.APPROVE_ROOM,
        roomId,
    };
};

export const rejectRoom = (roomId) => {
    return {
        type: InboxActions.REJECT_ROOM,
        roomId,
    };
};

export const setSocket = (socket) => {
    return {
        type: InboxActions.SET_SOCKET,
        socket,
    };
};

export const increaseUnread = (roomId, count) => {
    return {
        type: InboxActions.INCREASE_UNREAD,
        roomId,
        count,
    };
};

export const decreaseUnread = (roomId, count) => {
    return {
        type: InboxActions.DECREASE_UNREAD,
        roomId,
        count,
    };
};

export const clearViewedRoom = () => {
    return {
        type: InboxActions.CLEAR_VIEWED_ROOM,
    };
};

export const addMessages = (messages) => {
    return {
        type: InboxActions.ADD_MESSAGES,
        messages,
    };
};

const setUserInfo = (roomId, avatarUuid, userType) => {
    return {
        type: InboxActions.SET_USER_INFO,
        roomId,
        avatarUuid,
        userType,
    };
};

export const getUsersInfo = (usernamesRoomsMap, keycloak) => (dispatch) => {
    const usernames = Object.keys(usernamesRoomsMap);
    for (let i = 0; i < usernames.length; i += USER_INFO_BATCH_SIZE) {
        postRequest(
            APIS.keycloakUserInfo(),
            keycloak,
            usernames.slice(i, Math.min(i + USER_INFO_BATCH_SIZE, usernames.length))
        )
            .then(({ data }) => {
                for (const username in data) {
                    const { pictureFileUuid, keycloakUserType } = data[username];
                    dispatch(setUserInfo(usernamesRoomsMap[username], pictureFileUuid, keycloakUserType));
                }
            })
            .catch((error) => console.error({ error }));
    }
};

export const sendChatMessage = (senderUsername, receiverUsername, messageText, callback) => {
    return (dispatch, getState) => {
        const { rooms, socket: chatSocket } = getState().inbox;
        chatSocket.createRoom(receiverUsername, (roomData) => {
            const { id: roomId } = roomData;
            const message = {
                text: messageText,
                roomId,
                createdAt: Date.now(),
            };

            if (roomId && rooms[roomId] == undefined) {
                dispatch(addRoom(roomData));
                chatSocket.fetchMessages({ roomId }, (messages) => {
                    dispatch(setMessages(roomId, messages));
                });
            }

            chatSocket.sendMessage(
                message,
                ({ messageId }) => {
                    message.messageId = messageId;
                    message.roomId = roomId;
                    message.sender = senderUsername;
                    message.seenStatus = MessageStatus.SENT;
                    dispatch(addMessage(message));
                    dispatch(updateRoomLastMessage(roomId, message));
                    callback && callback();
                },
                (sendChatMessageError) => {
                    console.error({ sendChatMessageError });
                }
            );
        });
    };
};

export const setSenderInfo = (userType) => {
    return {
        type: InboxActions.SET_SENDER_INFO,
        userType,
    };
};

export const getSenderInfo = (username, keycloak) => (dispatch) => {
    postRequest(APIS.keycloakUserInfo(), keycloak, [username])
        .then(({ data }) => {
            const { keycloakUserType } = data[username];
            dispatch(setSenderInfo(keycloakUserType));
        })
        .catch((error) => console.error({ error }));
};

export const setHasBlockedTarget = (roomId, value) => {
    return {
        type: InboxActions.SET_HAS_BLOCKED_TARGET,
        roomId,
        value,
    };
};

export const setBlockingFromProfile = (username, value, keycloak) => {
    return (dispatch, getState) => {
        const { rooms } = getState().inbox;
        const relatedRoomId = Object.values(rooms).find((room) => room.targetUser.userName === username)?.id;
        if (relatedRoomId) {
            dispatch(setHasBlockedTarget(relatedRoomId, value));
            dispatch(fetchRoom(relatedRoomId, keycloak));
        }
    };
};

export const setInsideARoom = (isInsideARoom) => {
    return {
        type: InboxActions.SET_INSIDE_A_ROOM,
        isInsideARoom,
    };
};

export const markAllAsRead = (roomId) => {
    return {
        type: InboxActions.MARK_ALL_AS_READ,
        roomId,
    };
};

export const setNewContact = (username) => {
    return {
        type: InboxActions.SET_NEW_CONTACT,
        username,
    };
};
