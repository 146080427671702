import { makeStyles } from '@material-ui/core/styles';
import { COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const searchCardGridCommon = {
    display: 'grid !important',
    gap: '14px',
    margin: '8px 5vw',
    padding: 0,
};
const useStyles = makeStyles((theme) => ({
    SearchCardGrid: {
        ...searchCardGridCommon,
        gridTemplateColumns: 'repeat(auto-fit, minmax(282px, 1fr))',
        [theme.breakpoints.up('1800')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(362px, 1fr))',
        },
    },
    SearchCardGridTwoOrThreeCards: {
        ...searchCardGridCommon,
        gridTemplateColumns: 'repeat(auto-fit, minmax(282px, 1fr))',
        [theme.breakpoints.between(1200, 1800)]: {
            gridTemplateColumns: 'repeat(auto-fit, 28%)',
        },
        [theme.breakpoints.up(1800)]: {
            gridTemplateColumns: 'repeat(auto-fit, 24%)',
        },
    },
    EmptyWrapper: {
        overflowX: 'hidden',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        borderTop: '4px solid #ffffff',
        borderRight: '4px solid #ffffff',
    },
    EmptyWrapperWithBorder: {
        overflowX: 'hidden',
        borderTop: '4px solid #ffffff',
        borderRight: '4px solid #ffffff',
        borderLeft: '4px solid #ffffff',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        marginTop: '16px',
        minHeight: 'fit-content',
    },
}));

export const defaultCardStyle = {
    position: 'relative',
    display: 'block',
    borderRadius: '8px',
};

export default useStyles;
