import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import LanguagePanel from 'fintech/components/lang/LanguagePanel';
import LogoFinTechHub from 'fintech/static/images/Logo-FinTechHub-Disi.svg';
import useStyles from 'fintech//components/applications/layout/pre-login/AppBar/index.style';
import { mobileMediaQuery } from 'fintech/components/style/common';
import { HOME } from 'fintech/routes';
import { useHistory } from 'react-router-dom';

const AppBar = (props) => {
    const history = useHistory();
    const matches = useMediaQuery(mobileMediaQuery);
    const classes = useStyles();

    return (
        <Grid
            {...props}
            className={classes.barRoot}
            item
            xs={12}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
        >
            <Grid className={classes.logoWrapper} item xs={matches ? 6 : 8} onClick={() => history.push(HOME)}>
                <img src={LogoFinTechHub} width="220px" />
            </Grid>
            <Grid
                className={classes.languagePanelWrapper}
                item
                xs={matches ? 2 : 2}
                container
                direction="row"
                justify="flex-end"
            >
                <LanguagePanel makeColorWhite={true} />
            </Grid>
        </Grid>
    );
};

export default AppBar;
