import { makeStyles } from '@material-ui/core';
import { buttonStyle, buttonStyleColorAlt, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: 'rgba(41, 55, 84, 0.8)',
        padding: '32px 120px',
        position: 'relative',
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '100%',
        color: '#fff',
        zIndex: 1200,
        fontSize: '14px',
        textAlign: 'justify',
        '& a': {
            color: '#fff',
            textDecoration: 'underline',
        },
        [theme.breakpoints.down('md')]: {
            padding: '24px 54px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '16px 48px',
        },
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '32px',
        '& :nth-child(1)': {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '16px',
        },
    },
    approvalRejectionArea: {
        display: 'flex',
        gap: '16px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    manageCookieButton: {
        ...fontStyle,
        ...buttonStyle,
        height: 48,
        padding: '8px 24px 8px 24px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        '@media (max-width:320px)': {
            fontSize: 11,
        },
    },
    approvalRejectionButton: {
        ...fontStyle,
        ...buttonStyleColorAlt,
        height: 48,
        padding: '8px 24px 8px 24px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        '@media (max-width:320px)': {
            fontSize: 11,
        },
    },
}));

export default useStyles;
