import React, { useEffect, useRef, useState } from 'react';
import { useValidatableForm } from 'react-validatable-form';
import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';

import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import BasicInfoForm from 'fintech/components/applications/form/startup/BasicInfoForm';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import CfoRadioGroup from 'fintech/components/ui/form/radio/CfoRadioGroup';
import { STARTUP_INDIVIDUAL, STARTUP_EQUITY, INVITE_EXPIRED } from 'fintech/components/util/ApplicationFormConstants';
import { StartupApplicationActions } from 'fintech/store/actions/ActionTypes';
import { setStartupFromInvite } from 'fintech/store/actions/application-startup';
import { TCKN, VKN } from 'fintech/components/util/IdentityConstants';
import ReCAPTCHA from 'react-google-recaptcha';
import useGeneralInfoStyles from 'fintech/components/applications/form/investor/GeneralInfoForm/index.style';
import * as ROUTES from 'fintech/routes';
import { CAPTCH_API_KEY_INVIS } from 'fintech/constants/auth';
import { getLocale } from 'fintech/utils/LanguageUtils';
import * as FintechBeService from 'fintech/api/fintechService';
import { mobileMediaQuery } from 'fintech/components/style/common';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const rules = [
    {
        path: 'tradeName',
        ruleSet: [{ rule: 'required' }, { rule: 'length', lessThanOrEqualTo: 255 }],
    },
    {
        path: 'companyIdentity',
        ruleSet: [
            { rule: 'required' },
            {
                rule: 'tckn',
                enableIf: (formData) => {
                    return formData.tab === STARTUP_INDIVIDUAL;
                },
                dependantPaths: ['tab'],
            },
            {
                rule: 'vkn',
                disableIf: (formData) => {
                    return formData.tab === STARTUP_INDIVIDUAL;
                },
                dependantPaths: ['tab'],
            },
        ],
    },
    { path: 'companyEmail', ruleSet: [{ rule: 'required' }, { rule: 'email' }] },
];

const initialFormData = {};

export const StartupGeneralInfoForm = (props) => {
    const generalInfoClasses = useGeneralInfoStyles();
    const matches = useMediaQuery(mobileMediaQuery);
    const { t } = useTranslation('application');
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { inviteToken, switchTabHandler } = props;
    const dispatch = useDispatch();
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
    const { type: tab, tradeName, companyEmail, companyIdentity, isLoading, hasActiveRequest } = useSelector(
        (state) => ({
            ...state.startupApplication,
            ...state.common,
        })
    );

    const {
        isValid,
        validationError,
        setFormData,
        setFormIsSubmitted,
        setPathIsBlurred,
        resetForm,
    } = useValidatableForm({
        rules,
        initialFormData,
        hideBeforeSubmit: true,
        showAfterBlur: true,
        focusToErrorAfterSubmit: true,
    });

    useEffect(() => {
        const newFormData = {
            tab,
            tradeName,
            companyEmail,
            companyIdentity,
        };
        setFormData(newFormData);
    }, [tab, tradeName, companyEmail, companyIdentity]);

    useEffect(() => {
        resetForm();
    }, [tab]);

    // ReCaptcha - Start
    const recaptchaRef = useRef();

    const asyncOnLoad = () => {
        setRecaptchaLoaded(true);
    };

    // ReCaptcha - End

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        if (inviteToken) {
            setTimeout(() => {
                FintechBeService.fetchStartupInviteInfo(keycloak, inviteToken)
                    .then((res) => {
                        // Invite endpoint throws an exception if invite status is anything
                        // other than INVITED
                        dispatch(setStartupFromInvite(res.data));
                        dispatch({
                            type: StartupApplicationActions.SET_STARTUP_APPLICATION_INVITE_LOADING,
                            data: false,
                        });
                    })
                    .catch((e) => {
                        if (e.data === INVITE_EXPIRED) {
                            history.replace(ROUTES.EXPIRED_INVITE);
                        } else {
                            history.replace(ROUTES.NOT_FOUND);
                        }
                    });
            }, 1000);
        } else {
            dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_INVITE_LOADING, data: false });
        }
    }, []);

    const typeChangeHandler = (val) => {
        dispatch({ type: StartupApplicationActions.RESET_STARTUP_APPLICATION_FORM });
        dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_STARTUP_TYPE, data: val });
    };

    const showErrorSnackbar = (error) => {
        dispatch(setActiveRequest(false));
        enqueueSnackbar(error?.message || t('idea.updateError'), { variant: 'error' });
    };

    const clickContinueHandler = async () => {
        setFormIsSubmitted();
        if (!isValid) {
            return;
        }

        dispatch(setActiveRequest(true));
        const requestData = {};
        if (tab === STARTUP_INDIVIDUAL) {
            requestData.tradeName = tradeName;
            requestData.startupEmail = companyEmail;
            requestData.identity = {
                value: companyIdentity,
                identityType: TCKN,
            };
        } else {
            requestData.tradeName = tradeName;
            requestData.startupEmail = companyEmail;
            requestData.identity = {
                value: companyIdentity,
                identityType: VKN,
            };
        }

        let res = null;
        try {
            res = await FintechBeService.startupApplicationFirstStep(keycloak, requestData);
        } catch (reason) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        if (!res || !res.data) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        // Trigger captcha
        try {
            const token = await recaptchaRef?.current?.executeAsync();
            dispatch(setActiveRequest(false));
            if (!token) {
                enqueueSnackbar(t('validation.reCaptcha'), { variant: 'error' });
                return;
            }
        } catch (e) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(t('validation.reCaptcha'), { variant: 'error' });
            return;
        }

        FintechBeService.startupApplicationLastStep(keycloak, requestData)
            .then((response) => {
                if (!response?.data) {
                    showErrorSnackbar(response);
                    return;
                }
                switchTabHandler(true);
            })
            .catch((error) => {
                showErrorSnackbar(error);
                return;
            });
    };

    let formJSX = null;
    if (tab === STARTUP_INDIVIDUAL) {
        formJSX = (
            <BasicInfoForm
                type={STARTUP_INDIVIDUAL}
                disabled={inviteToken ? true : false}
                validationError={validationError}
                setPathIsBlurred={setPathIsBlurred}
            />
        );
    } else {
        formJSX = (
            <BasicInfoForm
                type={STARTUP_EQUITY}
                disabled={inviteToken ? true : false}
                validationError={validationError}
                setPathIsBlurred={setPathIsBlurred}
            />
        );
    }

    return (
        <CfoFormContainer generalInfo>
            {!isLoading ? (
                <>
                    {matches && (
                        <Grid item xs={12}>
                            <Typography className={generalInfoClasses.titleTypo}>
                                {t('common.tabs.generalInfo')}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <CfoRadioGroup
                            classFormControl={generalInfoClasses.radioGrpDiv}
                            row
                            onChange={typeChangeHandler}
                            disabled={inviteToken}
                            value={tab}
                            defaultValue={tab}
                            options={[
                                { value: STARTUP_EQUITY, label: t(`startup.form.label.${STARTUP_EQUITY}`) },
                                { value: STARTUP_INDIVIDUAL, label: t(`startup.form.label.${STARTUP_INDIVIDUAL}`) },
                            ]}
                        />
                    </Grid>
                    {formJSX}

                    <CfoFormInput xs={12} lg={12}>
                        <Button
                            className={generalInfoClasses.continueBtn}
                            disabled={!isValid || hasActiveRequest || !recaptchaLoaded}
                            variant="contained"
                            onClick={clickContinueHandler}
                        >
                            {!hasActiveRequest ? t('dialog.continue') : <ButtonCircularIndicator />}
                        </Button>
                    </CfoFormInput>
                </>
            ) : (
                <div style={{ margin: '198px 0' }}>
                    <LoadingSpinnerWithText />
                </div>
            )}
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={CAPTCH_API_KEY_INVIS}
                asyncScriptOnLoad={asyncOnLoad}
                hl={getLocale()}
                onChange={() => recaptchaRef?.current?.reset()}
            />
        </CfoFormContainer>
    );
};

export default StartupGeneralInfoForm;
