import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './index.style';

const AboutProgram = ({ supportProgramOnePage }) => {
    const { t } = useTranslation(['preLogin']);
    const classes = useStyles();

    return (
        <>
            <section className={classes.supportSection} id="section-about-program">
                <div className={classes.supportContainer}>
                    <span className={classes.title}>{t('titles.aboutProgram')}</span>

                    <p className={classes.definition}>
                        <span style={{ fontWeight: '500' }}>{supportProgramOnePage.programTitle}</span>
                        {supportProgramOnePage.programAboutDescriptionOne}
                    </p>
                    <p className={classes.definition}>{supportProgramOnePage.programAboutDescriptionTwo}</p>
                </div>
            </section>
        </>
    );
};

export default AboutProgram;
