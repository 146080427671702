import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ShowMore = ({ text, limit }) => {
    const [showMore, setShowMore] = useState(true);

    const { t } = useTranslation();

    const overflow = text ? limit <= text.length : false;

    const preventDefault = (event) => {
        event.preventDefault();
        setShowMore(!showMore);
    };

    if (!overflow) {
        return <span>{text}</span>;
    }

    if (showMore) {
        return (
            <span>
                {text.slice(0, limit) + '... '}
                <a href="#" onClick={preventDefault}>
                    {t('showMore.showMore')}
                </a>
            </span>
        );
    }

    return (
        <span>
            {text}
            <br />
            <a href="#" onClick={preventDefault}>
                {t('showMore.showLess')}
            </a>
        </span>
    );
};

export default ShowMore;
