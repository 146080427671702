import * as React from 'react';

function SvgIconsMultipleMenu1(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <path
                                fill="#D9DCE0"
                                d="M7.313 10.125L10.688 10.125 10.688 3.938 7.313 3.938zM7.313 17.438L10.688 17.438 10.688 10.688 7.313 10.688zM12.375 17.438L15.75 17.438 15.75 6.188 12.375 6.188zM2.25 17.438L5.625 17.438 5.625 9 2.25 9z"
                                transform="translate(-1090 -95) translate(1071 77) translate(19 18)"
                            />
                            <path
                                fill="#3F5162"
                                d="M8.719 5.063L9.281 5.063 9.281 4.5 8.719 4.5zM8.719 17.719L9.281 17.719 9.281 11.813 8.719 11.813zM11.25 17.719L11.813 17.719 11.813 2.25 11.25 2.25zM6.188 17.719L6.75 17.719 6.75 2.25 6.188 2.25z"
                                transform="translate(-1090 -95) translate(1071 77) translate(19 18)"
                            />
                            <path
                                fill="#3F5162"
                                d="M7.395 3.574L6.469 2.648 5.543 3.574 5.145 3.176 6.469 1.852 7.793 3.176zM12.457 3.574L11.531 2.648 10.605 3.574 10.207 3.176 11.531 1.852 12.855 3.176zM7.313 10.688L10.688 10.688 10.688 10.125 7.313 10.125zM3.656 8.438L4.219 8.438 4.219 7.875 3.656 7.875zM14.344 5.625L14.906 5.625 14.906 5.063 14.344 5.063zM13.219 5.625L13.781 5.625 13.781 5.063 13.219 5.063z"
                                transform="translate(-1090 -95) translate(1071 77) translate(19 18)"
                            />
                            <path
                                fill="#FFF"
                                d="M10.125 1.406c0 .622-.504 1.125-1.125 1.125s-1.125-.503-1.125-1.125C7.875.785 8.379.281 9 .281s1.125.504 1.125 1.125"
                                transform="translate(-1090 -95) translate(1071 77) translate(19 18)"
                            />
                            <path
                                fill="#3F5162"
                                d="M9 .563c-.465 0-.844.378-.844.843 0 .465.379.844.844.844.465 0 .844-.379.844-.844 0-.465-.379-.843-.844-.843m0 2.25c-.775 0-1.406-.631-1.406-1.407C7.594.631 8.224 0 9 0c.775 0 1.406.63 1.406 1.406 0 .776-.63 1.407-1.406 1.407M0 18L18 18 18 17.438 0 17.438z"
                                transform="translate(-1090 -95) translate(1071 77) translate(19 18)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleMenu1;
