import * as React from 'react';

function SvgIconsMultipleSuccess({ circleFill = '#D9DCE0', pathFill = '#3F5162', ...props }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <circle cx="12" cy="12" r="12" fill={circleFill} />
                    <path
                        fill={pathFill}
                        fillRule="nonzero"
                        d="M9.75 16.938L18.75 7.938 17.688 6.876 9.75 14.832 6.618 11.7 5.574 12.762z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleSuccess;
