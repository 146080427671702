import React from 'react';
import placeholderCompany from 'fintech/static/images/placeholders-company.png';
import AvatarUploader from 'fintech/components/avataruploader/AvatarUploader';
const startupLogoUrl = 'api/startup/logo/';

export const LogoAvatar = ({
    placeholder,
    serviceUri = startupLogoUrl,
    disabled = false,
    userUuid,
    pictureUuid,
    fetchOnUpload,
}) => {
    return (
        <AvatarUploader
            serviceUri={serviceUri}
            entityUuid={userUuid}
            pictureUuid={pictureUuid}
            placeholder={placeholder || placeholderCompany}
            disabled={disabled}
            fetchOnUpload={fetchOnUpload}
        />
    );
};
