import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as constants from 'fintech/components/util/ActionModalConstants';
import ActionModal from 'fintech/components/util/ActionModal';
import { AgreementWrapperDiv, AgreementTypo } from './index.style';

const LegalTextModal = (props) => {
    const { open, initialTab, onCancel, supportClarificationText = false } = props;
    const { t } = useTranslation('policy');
    const [selectedTabId, setSelectedTabId] = useState(initialTab ? initialTab : constants.LEGAL_TEXT_CLARIFICATION);
    const [tabs, setTabs] = useState([
        {
            id: constants.LEGAL_TEXT_CLARIFICATION,
            name: t('clarificationTitle'),
            selected: initialTab === constants.LEGAL_TEXT_CLARIFICATION,
        },
        {
            id: constants.LEGAL_TEXT_TERMS_OF_USE,
            name: t('termsOfUseTitle'),
            selected: initialTab === constants.LEGAL_TEXT_TERMS_OF_USE,
        },
        {
            id: constants.LEGAL_PRIVACY_POLICY,
            name: t('privacyPolicyTitle'),
            selected: initialTab === constants.LEGAL_PRIVACY_POLICY,
        },
    ]);

    useEffect(() => {
        setSelectedTabId(initialTab);
        setTabs((prevTabs) =>
            prevTabs.map((tab) => ({
                ...tab,
                selected: tab.id === initialTab,
            }))
        );
    }, [initialTab]);

    const editModalActionHandler = (action) => {
        const { selectedTabId } = action;
        switch (action.type) {
            case constants.TAB:
                setSelectedTabId(selectedTabId);
                break;
            case constants.SUBMIT:
                onCancel();
                return;
            case constants.REJECT:
                onCancel();
                return;
            default:
                return;
        }
    };

    const getSelectedTab = (selectedTabId) => {
        switch (selectedTabId) {
            case constants.LEGAL_TEXT_CLARIFICATION:
                return (
                    <AgreementWrapperDiv component="div">
                        <AgreementTypo
                            style={{ wordBreak: 'break-word', maxWidth: '100%' }}
                            dangerouslySetInnerHTML={{
                                __html: supportClarificationText
                                    ? t('supportClarificationText')
                                    : t('clarificationText'),
                            }}
                        />
                    </AgreementWrapperDiv>
                );
            case constants.LEGAL_TEXT_TERMS_OF_USE:
                return (
                    <AgreementWrapperDiv component="div">
                        <AgreementTypo dangerouslySetInnerHTML={{ __html: t('termsOfUseMob') }} />
                    </AgreementWrapperDiv>
                );
            case constants.LEGAL_PRIVACY_POLICY:
                return (
                    <AgreementWrapperDiv component="div">
                        <AgreementTypo dangerouslySetInnerHTML={{ __html: t('privacyPolicy') }} />
                    </AgreementWrapperDiv>
                );
            default:
                return (
                    <AgreementWrapperDiv component="div">
                        <AgreementTypo
                            style={{ wordBreak: 'break-word', maxWidth: '100%' }}
                            dangerouslySetInnerHTML={{ __html: t('clarificationText') }}
                        />
                    </AgreementWrapperDiv>
                );
        }
    };

    return (
        <ActionModal
            overrideInitialTab
            titleMarginBottom30={false}
            tabsContainerClass={'padding-bottom-26 border-bottom'}
            dialogActionsStyle={{
                borderTop: '1px solid #dee2e6',
                justifyContent: 'center',
                padding: '32px',
            }}
            open={open}
            tabs={tabs}
            title={t('agreements')}
            okButtonLabel={t('dialog.close')}
            onAction={editModalActionHandler}
            showCancelOnTop
        >
            {getSelectedTab(selectedTabId)}
        </ActionModal>
    );
};

export default LegalTextModal;
