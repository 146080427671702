import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { hasRoles } from 'fintech/utils/Auth';
import { useKeycloak } from '@react-keycloak/web';
import { ROLE_ADMIN, ROLE_EVALUATION_COMMITTEE } from 'fintech/components/util/RoleConstants';
import {
    ApplicationStatus,
    SupportApplicationReviewedUserType,
    StatusTypes,
    SupportApplicationApprovalStatus,
} from 'fintech/constants/supportProgram';
import TypeSelect from 'fintech/components/membership/TypeSelect/TypeSelect';
import ApplicationDetails from 'fintech/components/membership/ApplicationDetails/ApplicationDetails';
import useApplicationStyles, {
    DetailContent,
    DetailTitle,
} from 'fintech/components/membership/Applications/index.style';
import ApplicationPickerAndSearch from 'fintech/components/membership/ApplicationPickerAndSearch/ApplicationPickerAndSearch';
import { useTranslation } from 'react-i18next';
import {
    fetchSupportPrograms,
    fetchStartupAndIdeas,
    fetchSupportApplicationDetails,
    fetchStartupAndIdeasFromSearchBox,
    approveSupportProgramApplication,
    rejectSupportProgramApplication,
} from 'fintech/api/fintechService';
import { isEmpty, isEqual } from 'lodash';
import IconsSingleMultipleGirisimci from 'fintech/icons/IconsSingleMultipleGirisimci';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import RepresentativeInfos from 'fintech/components/membership/Sections/RepresentativeInfos';
import Section from 'fintech/components/support/program/Applications/Sections/Section/Section';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import IconsSingleCheckBig from 'fintech/icons/IconsSingleCheckBig';
import IconsSingleCloseBig from 'fintech/icons/IconsSingleCloseBig';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { NUMERIC_FULL_DATE_W_HOUR_SECOND_2, NUMERIC_FULL_DATE_W_HOUR_SECOND_3 } from 'fintech/utils/DateUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveRequest } from 'fintech/store/actions/common';
import useStyles from './index.style';
import SvgIconsMultipleSuccess from 'fintech/icons/IconsMultipleSuccess';
import IconsSingleClose4 from 'fintech/icons/IconsSingleClose4';
import { COLOR_THEME_FILL_01, COLOR_GREEN, COLOR_THEME_FILL_03 } from 'fintech/components/style/common';
import { getFnsLocale } from 'fintech/utils/LanguageUtils';
import {
    scrollIntoApplicationDetails,
    scrollIntoApplicationPicker,
    resetInfiniteScrollRelatedStates,
} from 'fintech/components/util/ApplicationsAndSupportProgram';

const SupportProgramApplications = ({ type }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const ApplicationStyles = useApplicationStyles({ height: 'auto', minHeight: '360px' });
    const { t } = useTranslation('supportProgramApplication');
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { hasActiveRequest } = useSelector((state) => state.common);

    const isAdmin = hasRoles(keycloak, ROLE_ADMIN);
    const isEvaluationCommittee = hasRoles(keycloak, ROLE_EVALUATION_COMMITTEE);

    const [filterTypes, setFilterTypes] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [pickerItems, setPickerItems] = useState([]);
    const [appliedPrograms, setAppliedPrograms] = useState([]);
    const [selectedAppliedProgramId, setSelectedAppliedProgramId] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [sections, setSections] = useState([]);
    const [selectedContent, setSelectedContent] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [processAlreadyCompleted, setProcessAlreadyCompleted] = useState(null);
    const [showOperationButtonsAndInfoText, setShowOperationButtonsAndInfoText] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [approveButtonText, setApproveButtonText] = useState(
        isAdmin
            ? t('supportProgramApplicationApproveReject.admin.preselection.approveButtonText')
            : t('supportProgramApplicationApproveReject.evaluationCommittee.general.approveButtonText')
    );
    const [modalApproveText, setModalApproveText] = useState(
        isAdmin
            ? t('supportProgramApplicationApproveReject.admin.preselection.modalApproveText')
            : t('supportProgramApplicationApproveReject.evaluationCommittee.general.modalApproveText')
    );
    const [rejectButtonText] = useState(t('supportProgramApplicationApproveReject.common.reject'));

    const [modalRejectText, setModalRejectText] = useState(
        isAdmin
            ? t('supportProgramApplicationApproveReject.admin.general.modalRejectText')
            : t('supportProgramApplicationApproveReject.evaluationCommittee.general.modalRejectText')
    );

    const [openRejectModal, setOpenRejectModal] = useState();
    const [openApproveModal, setOpenApproveModal] = useState();
    const [isApplicationDetailLoading, setIsApplicationDetailLoading] = useState(false);
    const [populatePickerItemsLoading, setPopulatePickerItemsLoading] = useState(false);

    const selectedStartupSupportApplicationId = useRef();

    const populatePickerItems = (response, makeFirstItemSelected, page, withPagination) => {
        let _pickerItems = [];
        if (response && response.data) {
            let content = response.data.content || [];

            if (!withPagination && page > 0) {
                content = pickerItems.concat(content);
            }
            isMobile && setTotalPages(response.data.totalPages || 1);

            _pickerItems = content.map((cur, index) => {
                return {
                    ...cur,
                    id: cur.supportApplicationId,
                    selected:
                        (makeFirstItemSelected && index === 0) ||
                        (page > 0 && cur.supportApplicationId === selectedStartupSupportApplicationId.current),
                    applicationDate: cur.applicationDate.replace(/-/g, '.'),
                };
            });
            setHasMore(!response.data.last);
        }
        setPickerItems(_pickerItems);

        if (makeFirstItemSelected) {
            const havePickerItems = !isEmpty(_pickerItems);
            selectedStartupSupportApplicationId.current = havePickerItems
                ? _pickerItems[0].supportApplicationId
                : undefined;
            getSelectedApplicationDetail({
                supportApplicationId: havePickerItems ? _pickerItems[0].supportApplicationId : undefined,
                reviewStatus: havePickerItems ? _pickerItems[0].reviewStatus : undefined,
            });
        }
    };

    const getStartupAndIdeas = ({
        makeFirstItemSelected,
        page = 0,
        operationType = 'default',
        _searchTerm,
        withPagination = false,
    }) => {
        if (operationType === 'default') {
            !hasMore && setIsApplicationDetailLoading(true);
            setPopulatePickerItemsLoading(true);
            fetchStartupAndIdeas({
                keycloak,
                page: page,
                programId: selectedAppliedProgramId,
                status: selectedStatus,
            })
                .then((response) => {
                    populatePickerItems(response, makeFirstItemSelected, page, withPagination);
                })
                .catch((reason) => {
                    setPickerItems([]);
                    console.error(reason.message);
                })
                .finally(() => {
                    setPopulatePickerItemsLoading(false);
                });
        } else if (operationType === 'fromSearchBox') {
            !hasMore && setIsApplicationDetailLoading(true);
            setPopulatePickerItemsLoading(true);
            fetchStartupAndIdeasFromSearchBox({
                keycloak,
                page: page,
                body: {
                    applicationStatus: selectedStatus,
                    searchTerm: _searchTerm !== undefined ? _searchTerm : searchTerm,
                    programId: selectedAppliedProgramId,
                },
            })
                .then((response) => {
                    resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
                    populatePickerItems(response, makeFirstItemSelected, page, withPagination);
                })
                .catch((reason) => {
                    console.error(reason);
                })
                .finally(() => {
                    setPopulatePickerItemsLoading(false);
                });
        }
    };

    const getSelectedApplicationDetail = ({ supportApplicationId, reviewStatus }) => {
        if (!supportApplicationId) {
            setSelectedContent([]);
            setIsApplicationDetailLoading(false);
            setProcessAlreadyCompleted(null);
            return;
        }

        setIsApplicationDetailLoading(true);

        setTimeout(() => {
            if (
                (isAdmin &&
                    (selectedStatus === StatusTypes.PRESELECTION ||
                        selectedStatus === StatusTypes.IN_THE_INTERVIEW_PROCESS)) ||
                (isEvaluationCommittee && selectedStatus === StatusTypes.COMMITTEE_QUALIFYING)
            ) {
                setShowOperationButtonsAndInfoText(true);
            } else {
                setShowOperationButtonsAndInfoText(false);
            }

            if (isEvaluationCommittee && reviewStatus && reviewStatus !== SupportApplicationApprovalStatus.NOTSEEN) {
                if (reviewStatus === SupportApplicationApprovalStatus.APPROVED) {
                    setProcessAlreadyCompleted({
                        status: reviewStatus,
                        text: t(
                            'supportProgramApplicationApproveReject.evaluationCommittee.processAlreadyCompleted.approved'
                        ),
                    });
                } else if (reviewStatus === SupportApplicationApprovalStatus.DENIED) {
                    setProcessAlreadyCompleted({
                        status: reviewStatus,
                        text: t(
                            'supportProgramApplicationApproveReject.evaluationCommittee.processAlreadyCompleted.denied'
                        ),
                    });
                }
            } else {
                setProcessAlreadyCompleted(null);
            }

            fetchSupportApplicationDetails({ keycloak, supportApplicationId })
                .then((response) => {
                    setSelectedContent({ ...response.data, supportApplicationId });
                })
                .catch((reason) => {
                    console.error(reason.message);
                })
                .finally(() => {
                    setIsApplicationDetailLoading(false);
                });
        }, 300);
    };

    //set applied programs
    useEffect(() => {
        fetchSupportPrograms(keycloak)
            .then((response) => {
                setAppliedPrograms(response.data);

                if (!isEmpty(response.data)) {
                    setSelectedAppliedProgramId(response.data[0].id);
                }
            })
            .catch((reason) => {
                console.error(reason.message);
            });
    }, []);

    // set selectedStatus
    useEffect(() => {
        if (!isEmpty(filterTypes)) {
            setSelectedStatus(filterTypes[0].value);
        }
    }, [filterTypes]);

    // set filterTypes
    useEffect(() => {
        if (type === ApplicationStatus.PENDING) {
            const pendingStatusForAdmin = [
                {
                    id: 1,
                    name: t('supportProgramApplicationApproveReject.common.applicationStatus.preselection'),
                    value: StatusTypes.PRESELECTION,
                    selected: true,
                },
                {
                    id: 2,
                    name: t('supportProgramApplicationApproveReject.common.applicationStatus.committeeQualifying'),
                    value: StatusTypes.COMMITTEE_QUALIFYING,
                    selected: false,
                },
                {
                    id: 3,
                    name: t('supportProgramApplicationApproveReject.common.applicationStatus.fromCommittee'),
                    value: StatusTypes.IN_THE_INTERVIEW_PROCESS,
                    selected: false,
                },
            ];
            if (isAdmin) {
                setFilterTypes(pendingStatusForAdmin);
            } else if (isEvaluationCommittee) {
                const pendingStatusForEvaluationCommittee = [...pendingStatusForAdmin];
                pendingStatusForEvaluationCommittee.splice(1, 1);
                pendingStatusForEvaluationCommittee[0].value = StatusTypes.COMMITTEE_QUALIFYING;
                pendingStatusForEvaluationCommittee[1].name = t(
                    'supportProgramApplicationApproveReject.common.applicationStatus.inInterviewProcess'
                );
                setFilterTypes(pendingStatusForEvaluationCommittee);
            }
        } else if (type === ApplicationStatus.RESULTED) {
            setFilterTypes([
                {
                    id: 1,
                    name: t('supportProgramApplicationApproveReject.common.applicationStatus.approved'),
                    value: 'APPROVED',
                    selected: true,
                },
                {
                    id: 2,
                    name: t('supportProgramApplicationApproveReject.common.applicationStatus.rejected'),
                    value: 'DENIED',
                    selected: false,
                },
            ]);
        }
        resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
    }, [type]);

    useEffect(() => {
        if (selectedStatus) {
            resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
            setSearchTerm('');
            getStartupAndIdeas({
                makeFirstItemSelected: true,
                operationType: 'default',
                page: 0,
            });
        }
    }, [selectedStatus, selectedAppliedProgramId]);

    useEffect(() => {
        const useInfiniteScroll = !isMobile && !!selectedStatus && pageNumber !== 0;
        const usePagination = isMobile && !!selectedStatus;

        if (useInfiniteScroll || usePagination) {
            getStartupAndIdeas({
                makeFirstItemSelected: isMobile,
                operationType: searchTerm ? 'fromSearchBox' : 'default',
                page: pageNumber,
                withPagination: isMobile,
            });
        }
    }, [pageNumber]);

    //populate Sections
    useEffect(() => {
        if (selectedContent) {
            let representativeInfos = {};
            const questionsCategoryMap = selectedContent.questionCategoryMap;

            if (selectedContent.supportApplicantInfo) {
                representativeInfos = parseRepresentativeInfos(selectedContent.supportApplicantInfo);
            }
            let _sections = [
                {
                    title: t('supportProgramApplicationApproveReject.common.sections.representativeInfos'),
                    component: <RepresentativeInfos infos={representativeInfos} />,
                    initiallyOpen: true,
                },
            ];

            for (const currentCategory in questionsCategoryMap) {
                if (!isEmpty(questionsCategoryMap[currentCategory])) {
                    const section = questionsCategoryMap[currentCategory];
                    section.title = section.categoryDescription;
                    section.component = <Section infos={section.questionList} t={t} />;
                    section.initiallyOpen = false;
                    _sections.push(section);
                }
            }
            setSections(_sections);
        }
    }, [selectedContent]);

    // set modal content and text of buttons
    useEffect(() => {
        if (isAdmin) {
            setModalRejectText(t('supportProgramApplicationApproveReject.admin.general.modalRejectText'));
            if (selectedStatus === StatusTypes.PRESELECTION) {
                setApproveButtonText(t('supportProgramApplicationApproveReject.admin.preselection.approveButtonText'));
                setModalApproveText(t('supportProgramApplicationApproveReject.admin.preselection.modalApproveText'));
            } else if (selectedStatus === StatusTypes.IN_THE_INTERVIEW_PROCESS) {
                setApproveButtonText(
                    t('supportProgramApplicationApproveReject.admin.inTheInterviewProcess.approveButtonText')
                );
                setModalApproveText(
                    t('supportProgramApplicationApproveReject.admin.inTheInterviewProcess.modalApproveText')
                );
            }
        } else if (isEvaluationCommittee) {
            setModalRejectText(t('supportProgramApplicationApproveReject.evaluationCommittee.general.modalRejectText'));
            setApproveButtonText(
                t('supportProgramApplicationApproveReject.evaluationCommittee.general.approveButtonText')
            );
            setModalApproveText(
                t('supportProgramApplicationApproveReject.evaluationCommittee.general.modalApproveText')
            );
        }
    }, [selectedStatus]);

    const observer = useRef();

    const lastPickerItemRef = useCallback(
        (node) => {
            if (pickerItems.length > 0) {
                if (observer.current) observer.current.disconnect();
                observer.current = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting && hasMore) {
                        !isMobile && setPageNumber((prev) => prev + 1);
                    }
                });
                if (node) observer.current.observe(node);
            }
        },
        [hasMore, pickerItems]
    );
    const handleTypeChange = (status) => {
        if (selectedStatus !== status) {
            resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
            setSelectedStatus(status);
        }
    };

    const getLogo = () => <IconsSingleMultipleGirisimci />;

    const handleAppliedProgramsOnChange = (programId) => {
        resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
        setSelectedAppliedProgramId(programId);
    };

    const getSelectedContent = (selectedStartup) => {
        const { supportApplicationId, reviewStatus } = selectedStartup;

        selectedStartupSupportApplicationId.current = supportApplicationId;
        getSelectedApplicationDetail({ supportApplicationId, reviewStatus });

        scrollIntoApplicationDetails(selectedStartup, isMobile);
    };

    const handleInputChange = (value) => {
        if (value.length === 0) {
            resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
            getStartupAndIdeas({
                makeFirstItemSelected: true,
                operationType: 'default',
                page: 0,
            });
        }
        setSearchTerm(value);
    };

    const handleSearchButton = () => {
        resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);
        getStartupAndIdeas({
            makeFirstItemSelected: true,
            operationType: 'fromSearchBox',
            page: 0,
        });
    };

    const makeApplicationOperation = ({ operation, rejectionMessage, setOpenModal }) => {
        const pickerItemsBeforeReset = pickerItems;

        dispatch(setActiveRequest(true));
        resetInfiniteScrollRelatedStates(setHasMore, setPickerItems, setPageNumber);

        operation({ keycloak, supportApplicationId: selectedStartupSupportApplicationId.current })
            .then((response) => {
                dispatch(setActiveRequest(false));
                if (response && response.success && response.data) {
                    // rejectionMessage for rejection, response.message for success
                    enqueueSnackbar(rejectionMessage || response.message, {
                        variant: 'success',
                    });
                } else {
                    enqueueSnackbar(response.message, {
                        variant: 'error',
                    });
                }
                getStartupAndIdeas({
                    makeFirstItemSelected: true,
                    operationType: 'default',
                });
                setOpenModal(false);
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason.message, {
                    variant: 'error',
                });
                setPickerItems(pickerItemsBeforeReset);
            });
    };

    const approveApplication = () => {
        makeApplicationOperation({
            operation: approveSupportProgramApplication,
            setOpenModal: setOpenApproveModal,
        });
    };

    const rejectApplication = () => {
        makeApplicationOperation({
            operation: rejectSupportProgramApplication,
            rejectionMessage: t('supportProgramApplicationApproveReject.common.confirmModal.result.success.reject'),
            setOpenModal: setOpenRejectModal,
        });
    };

    const showOperationButtons =
        selectedStatus === StatusTypes.PRESELECTION ||
        (isEvaluationCommittee && StatusTypes.COMMITTEE_QUALIFYING) ||
        (isAdmin && selectedStatus === StatusTypes.IN_THE_INTERVIEW_PROCESS);

    const handlePageChangeForMobile = (clickedPageNumber) => {
        if (pageNumber === clickedPageNumber - 1) return;
        setPageNumber(clickedPageNumber - 1);
        isMobile && scrollIntoApplicationPicker();
    };

    return (
        <>
            <TypeSelect
                items={filterTypes}
                onClick={handleTypeChange}
                className={ApplicationStyles.typeSelect}
                loading={isApplicationDetailLoading}
            />

            <Grid container className={ApplicationStyles.contentContainer}>
                <ApplicationPickerAndSearch
                    className={classes.picker}
                    ref={lastPickerItemRef}
                    pickerItems={pickerItems}
                    showApplicationProgramSelectBox
                    onChange={handleInputChange}
                    searchTerm={searchTerm}
                    handleSearchButton={handleSearchButton}
                    getSelectedContentCallback={getSelectedContent}
                    buttonText={t('supportProgramApplicationApproveReject.common.pickerAndSearch.buttonText')}
                    placeholder={t('supportProgramApplicationApproveReject.common.pickerAndSearch.placeholder')}
                    getLogoByApplicationType={getLogo}
                    appliedPrograms={appliedPrograms}
                    selectedAppliedProgramId={selectedAppliedProgramId}
                    handleAppliedProgramOnChangeCallback={handleAppliedProgramsOnChange}
                    showIsOperationAlreadyHasDone={
                        isEvaluationCommittee && selectedStatus === StatusTypes.COMMITTEE_QUALIFYING
                    }
                    totalPagesForMobile={totalPages}
                    handlePageChangeForMobile={handlePageChangeForMobile}
                    isLoading={populatePickerItemsLoading}
                />
                <ApplicationDetails
                    status={type}
                    getQuadGridItems={() => getQuadGridItems(t, selectedContent, type, selectedStatus, classes)}
                    sections={sections}
                    buttonsOperation={{
                        reject: {
                            text: rejectButtonText,
                            handler: () => setOpenRejectModal(true),
                        },
                        approve: {
                            text: approveButtonText,
                            handler: () => setOpenApproveModal(true),
                        },
                        showButtons: selectedContent?.supportApplicationId ? showOperationButtons : false,
                    }}
                    selectedContentId={selectedContent?.supportApplicationId}
                    processAlreadyCompleted={processAlreadyCompleted}
                    showOperationButtonsAndInfoText={showOperationButtonsAndInfoText}
                    isLoading={isApplicationDetailLoading}
                />
            </Grid>
            <ConfirmDialog
                open={openApproveModal}
                icon={
                    <IconsSingleCheckBig
                        style={{ width: '62px', height: '62px', backgroundColor: '#e6fae6', borderRadius: '50%' }}
                    />
                }
                content={modalApproveText}
                labelConfirm={t('supportProgramApplicationApproveReject.common.confirmModal.button.confirm')}
                labelCancel={t('supportProgramApplicationApproveReject.common.confirmModal.button.cancel')}
                dialogRootClass={ApplicationStyles.dialogRoot}
                onConfirm={approveApplication}
                onCancel={() => {
                    setOpenApproveModal(false);
                }}
                showTopCloseButton
                confirmDisabled={hasActiveRequest}
                cancelDisabled={hasActiveRequest}
            />

            <ConfirmDialog
                open={openRejectModal}
                icon={
                    <IconsSingleCloseBig
                        style={{ width: '62px', height: '62px', backgroundColor: '#fae6e6', borderRadius: '50%' }}
                    />
                }
                content={modalRejectText}
                labelConfirm={t('supportProgramApplicationApproveReject.common.confirmModal.button.confirm')}
                labelCancel={t('supportProgramApplicationApproveReject.common.confirmModal.button.cancel')}
                dialogRootClass={ApplicationStyles.dialogRoot}
                confirmDialogContentClass={ApplicationStyles.confirmDialogContentFailed}
                onConfirm={rejectApplication}
                onCancel={() => {
                    setOpenRejectModal(false);
                }}
                showTopCloseButton
                confirmDisabled={hasActiveRequest}
                cancelDisabled={hasActiveRequest}
            />
        </>
    );
};

export default SupportProgramApplications;

const getQuadGridItems = (t, selectedContentInfo, type, selectedStatus, classes) => {
    const itemsJSX = [];
    const applicationReviewList = selectedContentInfo?.supportApplicationReviewLogDtoList || [];

    /*
    To achieve
    applicationReviewMap = {
        COMMITTEE_MEMBER: [{}, ...],
        ADMIN: [{}, ...]
    }
    */
    const applicationReviewMap = applicationReviewList.reduce((accumulator, currentValue) => {
        const newMap = accumulator;
        if (newMap[currentValue.reviewedUserType]) {
            newMap[currentValue.reviewedUserType] = newMap[currentValue.reviewedUserType].concat(currentValue);
        } else {
            newMap[currentValue.reviewedUserType] = [currentValue];
        }
        return newMap;
    }, {});

    const systemAdminConfirmationApprovers =
        isEqual(ApplicationStatus.PENDING, type) ||
        isEmpty(applicationReviewMap[SupportApplicationReviewedUserType.ADMIN])
            ? undefined
            : applicationReviewMap[SupportApplicationReviewedUserType.ADMIN];

    const committeeInterviewConfirmationList =
        selectedStatus !== StatusTypes.PRESELECTION &&
        selectedStatus !== StatusTypes.COMMITTEE_QUALIFYING &&
        applicationReviewMap &&
        !isEmpty(applicationReviewMap[SupportApplicationReviewedUserType.COMMITTEE_MEMBER])
            ? applicationReviewMap[SupportApplicationReviewedUserType.COMMITTEE_MEMBER]
            : undefined;

    itemsJSX.push(
        <IntroGridItem
            key="appliedProgram"
            htmlBind={false}
            title={
                <DetailTitle>
                    {t('supportProgramApplicationApproveReject.common.applicationInfo.appliedProgram')}
                </DetailTitle>
            }
            content={<DetailContent>{selectedContentInfo?.programName || '-'}</DetailContent>}
        />
    );

    let applicationDate;
    if (selectedContentInfo?.applicationDate) {
        applicationDate = format(new Date(selectedContentInfo.applicationDate), NUMERIC_FULL_DATE_W_HOUR_SECOND_2);
    }

    itemsJSX.push(
        <IntroGridItem
            key="applicationDate"
            htmlBind={false}
            title={
                <DetailTitle>
                    {t('supportProgramApplicationApproveReject.common.applicationInfo.applicationDate')}
                </DetailTitle>
            }
            content={<DetailContent>{applicationDate || '-'}</DetailContent>}
        />
    );

    itemsJSX.push(
        <IntroGridItem
            key="committeeInverviewStatus"
            htmlBind={false}
            title={
                <DetailTitle>
                    {t('supportProgramApplicationApproveReject.common.applicationInfo.committeeInverviewStatus')}
                </DetailTitle>
            }
            content={
                <>
                    {committeeInterviewConfirmationList
                        ? committeeInterviewConfirmationList.map((cur, index) => {
                              return (
                                  <DetailContent key={cur.reviewDate + index}>
                                      {getStyledApproverByStatus(cur, classes)}
                                  </DetailContent>
                              );
                          })
                        : '-'}
                </>
            }
        />
    );

    itemsJSX.push(
        <IntroGridItem
            key="systemAdminConfirmation"
            htmlBind={false}
            title={
                <DetailTitle>
                    {t('supportProgramApplicationApproveReject.common.applicationInfo.systemAdminConfirmation')}
                </DetailTitle>
            }
            content={
                <>
                    {systemAdminConfirmationApprovers
                        ? systemAdminConfirmationApprovers.map((cur, index) => {
                              return (
                                  <DetailContent key={cur.reviewDate + index}>
                                      {getStyledApproverByStatus(cur, classes)}
                                  </DetailContent>
                              );
                          })
                        : '-'}
                </>
            }
        />
    );

    return itemsJSX;
};

const parseRepresentativeInfos = (applicantInfo) => {
    const splittedBirthDate = applicantInfo.birthDate.split('-');
    let [year, month, day] = [splittedBirthDate[0], splittedBirthDate[1] - 1, splittedBirthDate[2]];

    return {
        ...applicantInfo,
        surname: applicantInfo.lastName,
        identityNumber: applicantInfo.identity?.identityType === 'TCKN' ? applicantInfo.identity?.value : undefined,
        birthDate: new Date(year, month, day).getTime(),
        nationality: applicantInfo?.nationality?.alphaTree,
        gsmNo: applicantInfo.phoneNumber.country.callingCode + applicantInfo.phoneNumber.number,
        expertiseYear: applicantInfo?.workExperienceInYears,
        educationInfo: applicantInfo?.educationInfo.name,
    };
};

const getStyledApproverByStatus = (approvalInfo, classes) => {
    const { approvalStatus, reviewDate, reviewerFullName } = approvalInfo;
    const formattedDate = `(${format(new Date(reviewDate), NUMERIC_FULL_DATE_W_HOUR_SECOND_3, {
        locale: getFnsLocale(),
    })})`;

    const isApproved = approvalStatus === SupportApplicationApprovalStatus.APPROVED;

    return (
        <Grid container alignItems="center">
            <Grid item className={`${classes.approverIcon} ${!isApproved ? classes.deniedIcon : ''}`}>
                {isApproved ? (
                    <SvgIconsMultipleSuccess
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        circleFill={COLOR_GREEN}
                        pathFill={COLOR_THEME_FILL_01}
                    />
                ) : (
                    <IconsSingleClose4 width="16" height="16" currentColor={COLOR_THEME_FILL_03} />
                )}
            </Grid>
            <Grid item className={`${classes.approverNameAndDate} ${isApproved ? classes.approved : classes.denied}`}>
                <div>{reviewerFullName}</div>
                <div>{formattedDate}</div>
            </Grid>
        </Grid>
    );
};
