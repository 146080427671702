import { makeStyles } from '@material-ui/core';
import { cardGridStyle, COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    TeamCard: {
        ...cardGridStyle,
        margin: '25px 0 0',
        padding: '20px',
    },
    teamItem: {
        minHeight: '96px',
        padding: '16px 16px 16px 0',
        marginBottom: '8px',
        border: `solid 1px ${COLOR_PRIMARY_SHADE_02}`,
        borderRadius: '8px',
    },
});

export default useStyles;
