import * as React from 'react';

function SvgIconsMultipleNotifications(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#3F5162"
                    fillRule="nonzero"
                    d="M21.441 19.546v-1.1l-2.148-2.2v-5.974c0-1.642-.515-3.1-1.545-4.375-1.03-1.274-2.332-2.104-3.903-2.488v-.786c0-.454-.149-.838-.446-1.152C13.102 1.157 12.71 1 12.221 1c-.49 0-.891.157-1.205.472-.28.314-.42.698-.42 1.152v.786c-1.571.384-2.872 1.214-3.903 2.488a6.768 6.768 0 00-1.545 4.375v5.972L3 18.445v1.101h18.441zm-2.934-1.677H5.934l.419-.419.471-.524v-6.653c0-1.257.385-2.384 1.153-3.38.768-.995 1.746-1.615 2.934-1.859l1.257-.262h.105c.244 0 .664.087 1.257.262 1.188.244 2.166.864 2.934 1.86a5.382 5.382 0 011.153 3.379v6.653l.471.524.42.42zm-6.286 4.925c.593 0 1.1-.218 1.519-.655.419-.436.629-.951.629-1.545h-4.296c0 .593.21 1.109.628 1.545.42.437.926.655 1.52.655z"
                />
                <path
                    fill="#D9DCE0"
                    d="M18.507 17.87H5.934l.419-.42.471-.524v-6.653c0-1.257.385-2.384 1.153-3.38.768-.995 1.746-1.615 2.934-1.859l1.257-.262h.105c.244 0 .664.087 1.257.262 1.188.244 2.166.864 2.934 1.86a5.382 5.382 0 011.153 3.379v6.653l.471.524.42.42z"
                />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleNotifications;
