import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './index.style';
import AvatarGrid from './Avatar';
import ContactInfoGrid from './ContactInfo';
import InteractionGrid from './Interaction';
import SvgIconsSingleClose3 from 'fintech/icons/IconsSingleClose3';

const TeamMember = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        logo,
        name,
        surname,
        position,
        countryCode,
        phoneNumber,
        mailAddress,
        editable,
        onClickEdit,
        onClickDelete,
        email,
        status,
        showDeleteButton = true,
    } = props;
    const fullPhoneNumber = countryCode == null || phoneNumber == null ? null : `${countryCode}${phoneNumber}`;
    const isInvited = status && (status.status === 'INVITED' || status.status === 'INVITE_SUCCESS');
    const deleteIcon = isInvited ? <SvgIconsSingleClose3 /> : '';
    const deleteButtonText = isInvited ? t('profile.team.cancelInvitation.text') : '';

    return (
        <Grid className={classes.TeamItem} item>
            <Grid container direction="row-reverse" justify="space-between" alignItems="center">
                <InteractionGrid
                    breakPoints={{ xs: 12, md: 3 }}
                    editable={editable}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                    deleteIcon={deleteIcon}
                    deleteButtonText={deleteButtonText}
                    showDeleteButton={showDeleteButton}
                    isInvited={isInvited}
                />
                <Grid item xs={12} md={9} style={{ maxWidth: '750px' }}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <AvatarGrid
                            breakPoints={{ xs: 12, md: 4 }}
                            logo={logo}
                            name={name && surname && `${name} ${surname}`}
                            role={position}
                            isInvited={isInvited}
                        />
                        <ContactInfoGrid
                            breakPoints={{ xs: 12, md: 8 }}
                            mobile={fullPhoneNumber}
                            email={mailAddress || email}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TeamMember;
