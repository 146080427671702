import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    faqSection: {
        padding: '69px 121px 118px 120px',
        backgroundColor: '#f9fbfc',
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('md')]: {
            padding: '50px 22px 50px 22px',
            overflow: 'hidden',
        },
    },
    title: {
        fontSize: 36,
        color: '#3f5162',
        fontWeight: 500,
        textAlign: 'center',
    },
    categoryWrapper: {
        display: 'flex',
        marginTop: 35,
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '16px',
    },
    questionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 40,
    },
}));

export default useStyles;
