import React from 'react';
import useStyle from './index.style';
import { COLOR_ORANGE } from 'fintech/components/style/common';

const Index = ({ opacityPercantage, percentage, barText, title, defaultBarColor }) => {
    const classes = useStyle();

    return (
        <div className={classes.progress}>
            <p>{title}</p>
            <div>
                <span
                    className="bar"
                    style={{
                        width: `${percentage}%`,
                        opacity: 1 - opacityPercantage,
                        backgroundColor: defaultBarColor ? defaultBarColor : COLOR_ORANGE,
                    }}
                ></span>
                <p>{`${barText} (%${percentage}) `}</p>
            </div>
        </div>
    );
};

Index.defaultProps = { title: '' };

export default Index;
