export { AUTH_COOKIE_NAME, ANONYMOUS } from './auth';
export { LANGUAGE_CODES } from './language';
export * as APIS from './apis';
export const maskedText = '************';
export const uniqueSeperator = '☻';
// Event & support program cards
export const CARD_NAME_CHAR_LIMIT = 45;
export const CARD_DESC_CHAR_LIMIT = 80;

export const ROLE_KEY = 'user_roles';
