import React from 'react';

function IconsSingleMsortDown(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill={props.color}>
                    <path d="M15.159 13.778c.505 0 .96.278 1.188.725.26.507.184 1.106-.193 1.528l-3.16 3.525c-.252.283-.615.444-.994.444-.38 0-.742-.161-.994-.444l-3.16-3.524c-.377-.423-.453-1.022-.193-1.529.228-.447.683-.725 1.188-.725h6.318z" />
                </g>
            </g>
        </svg>
    );
}

IconsSingleMsortDown.defaultProps = {
    color: '#98A3AC',
};

export default IconsSingleMsortDown;
