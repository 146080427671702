import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, useMediaQuery, DialogContent, Typography } from '@material-ui/core';
import ActionModal from 'fintech/components/util/ActionModal';
import MultipleSelect from 'fintech/components/ui/form/select/MultipleSelect';
import { laptopLarge } from 'fintech/components/style/common';
import { SUBMIT, REJECT } from 'fintech/components/util/ActionModalConstants';
import IconsSingleTooltip from 'fintech/icons/IconsSingleTooltip';
import { fontStyle } from 'fintech/components/style/common';
import useStyles from 'fintech/components/modal/RepresentiveChangeModal/index.style';
import { useSelector } from 'react-redux';

export default function ({ buttonActions, multipleSelectValues }) {
    const matches = useMediaQuery(laptopLarge);
    const modalSize = matches ? { minWidth: '700px', minHeight: '690px' } : {};
    const { t: entrepreneurTranslation } = useTranslation('entrepreneur');
    const { currentRepresentativeID } = useSelector((state) => state.entrepreneur.adminModal);
    const [selectedRepresantiveID, setSelectedRepresantiveID] = useState();
    const styles = useStyles();
    const [isValidToSubmit, setIsValidToSubmit] = useState(false);

    const editModalActionHandler = (action) => {
        const { type } = action;

        if (type === SUBMIT) {
            currentRepresentativeID !== selectedRepresantiveID && buttonActions.apply(selectedRepresantiveID);
            buttonActions.reject();
        } else if (type === REJECT) {
            buttonActions.reject();
        } else {
            return;
        }
    };

    const multipleSelectHandler = (elements, isValid, selectedValues) => {
        if (selectedValues !== null && selectedValues) {
            setSelectedRepresantiveID(selectedValues.id);
        }
        if (selectedValues !== null && elements && selectedValues && elements.length > 0) {
            setIsValidToSubmit(true);
        } else {
            setIsValidToSubmit(false);
        }
    };

    return (
        <Box display="flex">
            <ActionModal
                open={open}
                title={entrepreneurTranslation('modal.changeRepresentative')}
                onAction={editModalActionHandler}
                modalSize={modalSize}
                okClickDisabled={!isValidToSubmit}
            >
                <DialogContent className={styles.RepInfoDialog}>
                    <Typography style={{ ...fontStyle }}>
                        <IconsSingleTooltip /> {entrepreneurTranslation('form.changeRepresentativeInfo')}
                    </Typography>
                </DialogContent>
                <Box p={2}>
                    <MultipleSelect
                        label={entrepreneurTranslation('modal.representive')}
                        callBack={(elements, isValid, selectedValues) =>
                            multipleSelectHandler(elements, isValid, selectedValues)
                        }
                        id="technology"
                        value={multipleSelectValues}
                        helperText={entrepreneurTranslation('validation.notEmpty')}
                    />
                </Box>
            </ActionModal>
        </Box>
    );
}
