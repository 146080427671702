import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_PRIMARY_SHADE_01,
    COLOR_SECONDARY_BASE,
    COLOR_SECONDARY_SHADE_01,
    COLOR_THEME_FILL_04,
} from 'fintech/components/style/common';
import { fontStyle } from '@material-ui/system';

const onlineInformationStyleBaseStyle = {
    height: 24,
    borderRadius: 4,
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '68%',
    marginTop: 4,
};
const useStyles = makeStyles({
    eventNameStyle: {
        fontWeight: 'bold',
        fontSize: 16,
        maxWidth: 230,
        wordBreak: 'break-word',
        color: COLOR_SECONDARY_BASE,
        marginTop: 15,
        marginBottom: 1,
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
    },
    eventDateStyle: {
        fontSize: 12,
        fontWeight: 300,
        color: COLOR_SECONDARY_SHADE_01,
    },
    eventInformationStyle: {
        ...fontStyle,
        maxWidth: 230,
        position: 'absolute',
        wordBreak: 'break-word',
        fontSize: 14,
        color: COLOR_SECONDARY_BASE,
        marginTop: 10,
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
    },
    onlineInformationStyle: {
        ...onlineInformationStyleBaseStyle,
        backgroundColor: (props) => (props.eventQuotaFull ? COLOR_THEME_FILL_04 : COLOR_PRIMARY_SHADE_01),
        marginLeft: -7,
    },
    onlineInformationStylePreLogin: {
        ...onlineInformationStyleBaseStyle,
        backgroundColor: (props) => (props.eventQuotaFull ? COLOR_THEME_FILL_04 : COLOR_PRIMARY_SHADE_01),
    },
});

export default useStyles;
