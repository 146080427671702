import { getRequest } from 'fintech/api/api';
import { EstablishmentActions } from './ActionTypes';
import { APIS } from 'fintech/constants';
import { parseMedia } from 'fintech/utils/DataParser';
import { postProfileVisitCount } from 'fintech/api/fintechService';

const setEstablishmentsLoading = () => ({ type: EstablishmentActions.SET_ESTABLISHMENT_LOADING });
const setEstablishments = (establishments) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_LIST,
    establishments,
});
const setEstablishmentsError = () => ({ type: EstablishmentActions.SET_ESTABLISHMENT_LOAD_ERROR });

const setEstablishmentIntro = (data) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_INTRO_DATA,
    data,
});
const setEstablishmentIntroLoading = () => ({ type: EstablishmentActions.SET_ESTABLISHMENT_INTRO_LOADING });
const setEstablishmentIntroError = () => ({ type: EstablishmentActions.SET_ESTABLISHMENT_INTRO_ERROR });

const setInvestorTypesloading = () => ({ type: EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_TYPES_LOADING });
const setInvestorTypes = (data, selectedType) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_TYPES,
    data,
    selectedType,
});
const setInvestorTypesError = () => ({ type: EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_TYPES_LOAD_ERROR });

const setInvestorGroupsLoading = () => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_GROUP_LOADING,
});
const setInvestorGroups = (data, selectedGroup) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_GROUP,
    data,
    selectedGroup,
});
const setInvestorGroupError = () => ({ type: EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_GROUP_LOAD_ERROR });

const setInvestorVerticalsLoading = () => ({ type: EstablishmentActions.SET_ESTABLISHMENT_VERTICALS_LOADING });
const setInvestorVerticals = (data) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_VERTICALS_DATA,
    data,
});
const setInvestorVerticalsError = () => ({ type: EstablishmentActions.SET_ESTABLISHMENT_VERTICALS_LOAD_ERROR });

const setInvestorInvestmentAreasLoading = () => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_INVESTMENT_AREAS_LOADING,
});
const setInvestorInvestmentAreasData = (data) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_INVESTMENT_AREAS_DATA,
    data,
});
const setInvestorInvestmentLoadError = () => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_INVESTMENT_AREAS_LOAD_ERROR,
});

const setInvestorStagesLoading = () => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_STAGES_LOADING,
});
const setInvestorStagesData = (data) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_STAGES_DATA,
    data,
});
const setInvestorStagesLoadError = () => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_STAGES_LOAD_ERROR,
});

const setInvestorAboutInfoLoading = () => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_ABOUT_INFO_LOADİNG,
});
const setInvestorAboutInfoData = (data) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_ABOUT_INFO_DATA,
    data,
});
const setInvestorAboutInfoLoadError = () => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_ABOUT_INFO_LOAD_ERROR,
});

const setEstablishmentTeamLoading = () => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_TEAM_LOADING,
});
const setEstablishmentTeamData = (uuid, team) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_TEAM_DATA,
    id: uuid,
    team: team,
});
const setEstablishmentTeamError = () => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_TEAM_LOAD_ERROR,
});

export const setEstablishmentInvestmentForm = (id) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_DATA,
    id: id,
});

export const sendEstablishmentInvestmentForm = () => ({
    type: EstablishmentActions.SEND_ESTABLISHMENT_PREFERENCES_INVESTMENT_FORM,
});

export const resetEstablishmentInvestorForm = () => ({
    type: EstablishmentActions.RESET_ESTABLISHMENT_PREFERENCES_INVESTMENT_FORM,
});

const setStartupsForAutoSelect = (startups) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_START_OPTIONS,
    data: startups,
});

export const fetchEstablishmentsListData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setEstablishmentsLoading());
        getRequest(`${APIS.INVESTOR.getInvestorsRelatedToUser(uuid)}`, keycloak)
            .then((data) => {
                dispatch(setEstablishments(data.data));
            })
            .catch(() => {
                dispatch(setEstablishmentsError());
            });
    };
};

const setVisitTimes = (visitTimes) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_INTRO_VISIT_TIMES,
    visitTimes,
});

export const fetchEstablishmentIntroData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setEstablishmentIntroLoading());
        getRequest(`${APIS.INVESTOR.getInvestor(uuid)}`, keycloak)
            .then((response) => {
                if (response.data.investorMediaSet != null && response.data.investorMediaSet.length > 0) {
                    parseMedia(response.data.investorMediaSet).then(function (resp) {
                        response.data.investorMediaSet = resp;
                        dispatch(setEstablishmentIntro(response.data));
                    });
                } else {
                    dispatch(setEstablishmentIntro(response.data));
                }
            })
            .catch(() => {
                dispatch(setEstablishmentIntroError());
            });
    };
};

export const fetchInvestorTypes = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setInvestorTypesloading());
        getRequest(`${APIS.INVESTOR.getInvestorTypes(uuid)}`, keycloak)
            .then((response) => {
                if (response && response.success) {
                    let selectedType = {};
                    if (response.data) {
                        selectedType = response.data.find((cur) => cur.selected);
                    }

                    dispatch(setInvestorTypes(response.data, selectedType));
                }
            })
            .catch(() => {
                dispatch(setInvestorTypesError());
            });
    };
};

export const fetchInvestorGroups = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setInvestorGroupsLoading());
        getRequest(`${APIS.INVESTOR.getInvestorGroups(uuid)}`, keycloak)
            .then((response) => {
                if (response && response.success) {
                    let selectedGroup = {};
                    if (response.data) {
                        selectedGroup = response.data.find((cur) => cur.selected);
                    }
                    dispatch(setInvestorGroups(response.data, selectedGroup));
                }
            })
            .catch(() => {
                dispatch(setInvestorGroupError());
            });
    };
};

export const fetchInvestorVerticals = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setInvestorVerticalsLoading());
        getRequest(`${APIS.INVESTOR.getInvestorVerticals(uuid)}`, keycloak)
            .then((response) => {
                dispatch(setInvestorVerticals(response.data));
            })
            .catch(() => {
                dispatch(setInvestorVerticalsError());
            });
    };
};

export const fetchInvestorInvestmentAreas = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setInvestorInvestmentAreasLoading());
        getRequest(`${APIS.INVESTOR.getInvestorInvestmentAreas(uuid)}`, keycloak)
            .then((response) => {
                if (response && response.success) {
                    dispatch(setInvestorInvestmentAreasData(response.data));
                }
            })
            .catch(() => {
                dispatch(setInvestorInvestmentLoadError());
            });
    };
};

export const fetchInvestorStages = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setInvestorStagesLoading());
        getRequest(`${APIS.INVESTOR.getInvestorStages(uuid)}`, keycloak)
            .then((response) => {
                if (response && response.success) {
                    dispatch(setInvestorStagesData(response.data));
                }
            })
            .catch(() => {
                dispatch(setInvestorStagesLoadError());
            });
    };
};

export const fetchEstablishmentAboutData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setInvestorAboutInfoLoading());
        getRequest(`${APIS.INVESTOR.getInvestorAboutInfo(uuid)}`, keycloak)
            .then((response) => {
                if (response && response.success) {
                    let data = response?.data?.about || '';
                    data = data.replace(/(?:^[\s\u00a0]+)|(?:[\s\u00a0]+$)/g, '').startsWith('<p></p>') ? '' : data;
                    dispatch(setInvestorAboutInfoData({ about: data }));
                }
            })
            .catch(() => {
                dispatch(setInvestorAboutInfoLoadError());
            });
    };
};

export const fetchEstablishmentTeamData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch(setEstablishmentTeamLoading());
        getRequest(APIS.INVESTOR.getInvestorTeamData(uuid), keycloak)
            .then((data) => {
                dispatch(setEstablishmentTeamData(uuid, data));
            })
            .catch(() => {
                dispatch(setEstablishmentTeamError());
            });
    };
};

export const fetchInvestorPreferencesData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_LOADING });
        getRequest(APIS.INVESTOR.getInvestorInvestmentPreferences(uuid), keycloak)
            .then((response) => {
                dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_DATA, data: response.data });
            })
            .catch(() => {
                dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_LOAD_ERROR });
            });
    };
};

export const fetchInvestorManagementTypes = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_LOADING });
        getRequest(APIS.INVESTOR.getInvestorManagementTypes(uuid), keycloak)
            .then((response) => {
                dispatch({
                    type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_DATA,
                    data: response.data,
                });
            })
            .catch(() => {
                dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_LOAD_ERROR });
            });
    };
};

export const fetchInvestorInvestmentAmounts = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_LOADING });
        getRequest(APIS.INVESTOR.getInvestorInvestmentAmounts(uuid), keycloak)
            .then((response) => {
                dispatch({
                    type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_DATA,
                    data: response.data,
                });
            })
            .catch(() => {
                dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_LOAD_ERROR });
            });
    };
};

export const fetchInvestorCompanyInvestmensAmounts = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_LOADING });
        getRequest(APIS.INVESTOR.getInvestorCompanyInvestmentsAmounts(uuid), keycloak)
            .then((response) => {
                dispatch({
                    type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_DATA,
                    data: response.data,
                });
            })
            .catch(() => {
                dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_LOAD_ERROR });
            });
    };
};
export const fetchInvestorAllInvestments = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_LOADING });
        getRequest(APIS.INVESTOR.getInvestorAllInvestments(uuid), keycloak)
            .then((response) => {
                dispatch({
                    type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_DATA,
                    data: response.data,
                });
            })
            .catch(() => {
                dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_LOAD_ERROR });
            });
    };
};

export const fetchStartupOptions = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: EstablishmentActions.GET_ESTABLISHMENT_STARTUP_OPTIONS });
        getRequest(APIS.STARTUP.getStartupsAutoCompleteURL(), keycloak)
            .then((data) => dispatch(setStartupsForAutoSelect(data)))
            .catch(() => {
                dispatch({ type: EstablishmentActions.SET_ESTABLISHMENT_STARTUP_OPTIONS_LOAD_ERROR });
            });
    };
};

export const setEstablishmentStartup = ({
    startupId,
    startupName,
    freeTextStartupName,
    isValid,
    isVisible,
    unregistered,
}) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_STARTUP,
    startup: {
        startupId: startupId,
        startupName: startupName,
        freeTextStartupName: freeTextStartupName,
        isValid: isValid,
        unregistered: unregistered,
    },
    startupVisible: isVisible,
});

export const setEstablishmentInvestmentDate = ({ dateISOStr, isVisible, isValid }) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_STARTUP_INVESTMENT_DATE,
    investmentDate: dateISOStr,
    investmentDateIsValid: isValid,
    investmentDateVisible: isVisible,
});

export const setEstablishmentInvestmentAmount = ({ id, value, currency, isVisible, isValid }) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_STARTUP_INVESTMENT_AMOUNT,
    investmentAmount: {
        id: id,
        value: value,
        currencyId: currency,
        isValid: isValid,
    },
    investmentAmountVisible: isVisible,
});

export const setEstablishmentInvestmentVisibility = (isVisible) => ({
    type: EstablishmentActions.SET_ESTABLISHMENT_INVESTMENT_VISIBILITY,
    investmentVisible: isVisible,
});

export const updateVisitCount = (keycloak, pageType, investorUuid) => {
    return (dispatch) => {
        postProfileVisitCount(keycloak, pageType, investorUuid).then((response) => {
            if (response && response.success) {
                dispatch(setVisitTimes(response.data));
            }
        });
    };
};
