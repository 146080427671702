import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LocalOffer } from '@material-ui/icons';
import IntroTab from 'fintech/components/profile/startup/Intro/IntroTab';
import IntroHeader from 'fintech/components/profile/startup/Intro/Header';
import IntroDetails from 'fintech/components/profile/startup/Intro/Details';
import { IntroCardContent, IntroCardTitle } from 'fintech/components/profile/common';
import SplitButton from 'fintech/components/ui/button/SplitButton';
import ErrorBoundary from 'fintech/components/util/ErrorBoundary';
import Empty from 'fintech/components/util/Empty';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import { StyledTag } from 'fintech/components/ui/button/common';
import useStyles from 'fintech/components/profile/startup/Intro/IntroGrid/index.style';
import EstablishmentIntroEditModal from 'fintech/components/profile/establishment/intro/form/EstablishmentIntroEditModal';
import { STATES } from 'fintech/store/actions/ActionTypes';
import {
    splitButtonNotInDatagridGroupStyle,
    splitButtonTagsNotInDatagridGroupStyle,
    styledTagNotInDatagridStyle,
} from 'fintech/components/style/common';

const IntroGrid = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { data: fetchedData, isLoading, isError } = useSelector((state) => state.establishment.intro);
    const [establishmentData, setEstablishmentData] = useState(fetchedData);
    const [openEditModal, setOpenEditModal] = useState(false);

    let hasData = _.keys(fetchedData).length > 0;

    let gridJSX = null;
    let emptyProps = {};

    const {
        name,
        dateFounded,
        editable,
        website,
        investmentAreas,
        verticals,
        startupStagePreferenceSet,
        informalInvestorGroup,
        type,
    } = establishmentData;

    const clickEditHandler = () => setOpenEditModal(true);
    const cancelEditHandler = () => setOpenEditModal(false);
    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.errorMessage');
    } else if (hasData) {
        gridJSX = (
            <Grid
                className={classes.StupIntroCard}
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
            >
                {establishmentData.uuid && (
                    <>
                        <IntroHeader
                            name={name}
                            website={{ value: website }}
                            editable={editable}
                            onClickEditCallback={() => clickEditHandler()}
                            fetchedData={establishmentData}
                            isStartup={false}
                        />
                        <IntroDetails
                            getQuadGridItems={() =>
                                getQuadGridItems(t, editable, dateFounded, type, informalInvestorGroup)
                            }
                            getDuoGridItems={() =>
                                getDuoGridItems(t, verticals, startupStagePreferenceSet, investmentAreas)
                            }
                            mediaItems={establishmentData.investorMediaSet}
                        />
                    </>
                )}
                {editable && <EstablishmentIntroEditModal open={openEditModal} onCancel={() => cancelEditHandler()} />}
            </Grid>
        );
    } else {
        emptyProps.message = t('profile.emptyMessage');
    }

    useEffect(() => {
        setEstablishmentData(fetchedData);
    }, [fetchedData]);

    return (
        <>
            <IntroTab type={STATES.ESTABLISHMENT} times={fetchedData.visitTimes} />
            <ErrorBoundary message={t('profile.errorMessage')}>
                {hasData ? gridJSX : <Empty {...emptyProps} />}
            </ErrorBoundary>
        </>
    );
};

const introCardContentOrDash = (value) => <IntroCardContent>{value ? value : '-'}</IntroCardContent>;

const getQuadGridItems = (t, editable, dateFounded, type, informalInvestorGroup) => {
    const itemsJSX = [];

    // Foundation date
    itemsJSX.push(
        <IntroGridItem
            key="dateFounded"
            half
            title={<IntroCardTitle>{t('profile.establishments.dateFounded')}</IntroCardTitle>}
            content={introCardContentOrDash(dateFounded)}
        />
    );

    // Investor type
    itemsJSX.push(
        <IntroGridItem
            key="investorType"
            half
            title={<IntroCardTitle>{t('profile.establishments.investorType')}</IntroCardTitle>}
            content={introCardContentOrDash(type)}
        />
    );

    itemsJSX.push(
        <IntroGridItem
            key="angelInvestorGroup"
            half
            title={<IntroCardTitle>{t('profile.establishments.angelInvestorGroup')}</IntroCardTitle>}
            content={introCardContentOrDash(informalInvestorGroup)}
        />
    );

    return itemsJSX;
};

const tagGroupOrDash = (list, clickableStyledTagStyle) =>
    list.length ? (
        <TagGroup limit={5} items={list} clickableStyledTagStyle={clickableStyledTagStyle} />
    ) : (
        <IntroCardContent>{'-'}</IntroCardContent>
    );

const getDuoGridItems = (t, verticals, startupStagePreferenceSet, investmentAreas) => {
    const itemsJSX = [];

    // startupStagePreferenceSet
    const _startupStagePreferenceSet = startupStagePreferenceSet?.map((stage, idx) => (
        <StyledTag
            disableElevation
            disableRipple
            disableTouchRipple
            key={idx}
            startIcon={<LocalOffer fontSize="small" />}
            style={styledTagNotInDatagridStyle}
        >
            {stage}
        </StyledTag>
    ));

    itemsJSX.push(
        <IntroGridItem
            title={<IntroCardTitle>{t('profile.establishments.startupStagePreference')}</IntroCardTitle>}
            content={tagGroupOrDash(_startupStagePreferenceSet, styledTagNotInDatagridStyle)}
        />
    );

    // Verticals
    const verts = _.keys(verticals)?.map((vert, idx) => (
        <SplitButton
            groupStyle={splitButtonTagsNotInDatagridGroupStyle}
            key={idx}
            option={vert}
            subOptions={verticals[vert]}
        />
    ));
    itemsJSX.push(
        <IntroGridItem
            title={<IntroCardTitle>{t('profile.establishments.interestedFintechVerticals')}</IntroCardTitle>}
            content={tagGroupOrDash(verts, splitButtonNotInDatagridGroupStyle)}
            wrapperClassName={'margin-top-8'}
        />
    );

    // investmentAreas
    const _investmentAreas = investmentAreas?.map((area, idx) => (
        <StyledTag
            disableElevation
            disableRipple
            disableTouchRipple
            key={idx}
            startIcon={<LocalOffer fontSize="small" />}
            style={styledTagNotInDatagridStyle}
        >
            {area}
        </StyledTag>
    ));

    itemsJSX.push(
        <IntroGridItem
            title={<IntroCardTitle>{t('profile.establishments.investmentAreas')}</IntroCardTitle>}
            content={tagGroupOrDash(_investmentAreas, styledTagNotInDatagridStyle)}
            wrapperClassName={'margin-top-5'}
        />
    );

    return itemsJSX;
};

export default IntroGrid;
