import { makeStyles } from '@material-ui/core';
import { buttonStyle, fontStyle, poppins500_14px } from 'fintech/components/style/common';
import { COLOR_THEME_FILL_01, COLOR_PRIMARY_SHADE_03 } from 'fintech/components/style/common';

const useStyles = makeStyles(() => ({
    dropdownContainer: {
        '&  ul': {
            justifyContent: 'center',
            position: 'absolute',
            right: '30px',
            borderRadius: '4px',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
            border: 'solid 1px #d7e2e8',
            backgroundColor: COLOR_THEME_FILL_01,
            padding: '12px',
            margin: '0',
            zIndex: '1',
            '& > li': {
                listStyle: 'none',
                height: '24px',
                alignItems: 'center',
                cursor: 'pointer',
                '& > p': {
                    ...poppins500_14px,
                    margin: '0',
                    display: 'inline',
                },
                '&:hover': {
                    backgroundColor: COLOR_PRIMARY_SHADE_03,
                },
            },
        },
    },
    otherDataButton: {
        ...fontStyle,
        ...buttonStyle,
        maxHeight: '28px',
        margin: '4px 4px',
    }
}));

export default useStyles;
