import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    mentorSearchWrapper: {
        alignSelf: 'flex-start',
        width: '100%',
        position: 'relative',
    },
    titleWrapper: {
        display: 'flex',
    },
    divider: {
        width: 3,
        height: 32,
        margin: '1px 13px 0 0',
        background: '#5b7adb',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mentorDefinition: {
        fontSize: 24,
        marginBottom: 0,

        '& span': {
            color: '#5b7adb',
        },

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    searchBar: {
        width: '100%',
        marginTop: '25px !important',
        color: '#3f5162',
        fontSize: 14,
        fontWeight: 500,
    },
    mentorsWrapper: {
        display: 'flex',
        marginTop: 25,
        alignItems: 'stretch',

        [theme.breakpoints.down('sm')]: {
            overflow: 'hide',
        },
    },
    iconsWrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        '& svg': {
            marginLeft: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#5b7adb',
            cursor: 'pointer',
            borderRadius: '8px',
        },

        [theme.breakpoints.down('sm')]: {
            top: 'auto',
            bottom: -50,
        },
    },
    disable: {
        pointerEvents: 'none',

        '& svg': {
            background: 'gray !important',
        },
    },
    itemWrapper: {
        width: '-webkit-fill-available',
        maxWidth: 400,
        marginRight: '17px',
        '&:only-of-type': {
            marginRight: '0px',
        },
        '&:last-of-type': {
            marginRight: '0px',
        },
    },
}));

export default useStyles;
