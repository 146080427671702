import * as React from 'react';

function SvgIconsAlertError(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <filter id="ntu59zvi3a" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.384314 0 0 0 0 0.247059 0 0 0 0 0.247059 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g filter="url(#ntu59zvi3a)" transform="translate(-314 -179) translate(296 167)">
                        <g>
                            <path
                                fill="#000"
                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
                                transform="translate(18 12)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
export default SvgIconsAlertError;
