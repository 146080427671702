import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DialogContent, Grid } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import { parse } from 'date-fns';
import * as constants from 'fintech/components/util/ActionModalConstants';
import CfoDatePicker from 'fintech/components/ui/form/picker/CfoDatePicker';
import CurrencyInput from 'fintech/components/ui/form/textinput/CurrencyInput';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import VisibilityToggle from 'fintech/components/ui/form/toggle/VisibilityToggle';
import { fetchStartupCitiesData, fetchStartupCountryData } from 'fintech/store/actions/startup';
import CityUpdate from 'fintech/components/profile/startup/form/intro/CityUpdate';
import CountryUpdate from 'fintech/components/profile/startup/form/intro/CountryUpdate';
import useStyles from './index.style';
import { ISO_FORMAT } from 'fintech/utils/DateUtils';
import { isEmpty } from 'lodash';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const GeneralInfoModal = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const {
        dateFounded,
        employees,
        address,
        investment,
        email,
        website,
        infoChangeCallback,
        summary,
        isIdea,
        formSubmitted,
    } = props;
    const { isLoading: curError, isError: curLoading } = useSelector((state) => state.common.currencies);
    const { countryData, cityData } = useSelector((state) => state.startup.form);
    const { uuid } = useSelector((state) => state.startup.intro.data);
    const [countries, setCountry] = useState([]);
    const [cities, setCity] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmpty(countryData.data)) {
            dispatch(fetchStartupCountryData(keycloak, uuid));
        }

        if (isEmpty(cityData.data)) {
            dispatch(fetchStartupCitiesData(keycloak, uuid));
        }
    }, []);

    useEffect(() => {
        setCountry(countryData.data);
        setCity(cityData.data);
    }, [cityData, countryData]);

    // State definitions.
    // TODO: Use useReducer() instead of multiple useState calls.
    const [ideaSummary, setIdeaSummary] = useState({
        value: summary?.value ? summary.value : '',
        validated: summary?.value ? true : false,
    });

    const [foundDate, setDateFounded] = useState({
        value: dateFounded?.value && parse(dateFounded.value, ISO_FORMAT, new Date()),
        validated: true,
    });

    const [employeeCount, setEmployeeCount] = useState({
        value: employees?.value ? employees.value : '',
        validated: employees?.value ? true : false,
    });

    const [addressDesc, setAddressDescription] = useState({
        value: address?.description ? address.description : '',
        validated: address?.description ? true : false,
        public: address?.public,
    });

    const [investmentAmnt, setInvestment] = useState({
        value: investment?.value ? investment.value : '',
        currencyId: investment?.currencyId ? investment?.currencyId : 1,
        validated: investment?.value ? true : false,
        public: investment?.public,
    });

    const [formEmail, setFormEmail] = useState({
        value: email?.value ? email.value : '',
        validated: email?.value ? true : false,
        public: email?.public ? true : false,
    });

    const [formWebsite, setFormWebsite] = useState({
        value: website?.value ? website.value : '',
        validated: website?.value ? true : false,
    });

    // State change handlers

    const ideaSummaryChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setIdeaSummary(newState);
        infoChangeCallback({ type: constants.STARTUP_INTRO_SUMMARY_UPDATE, data: newState });
    };

    const dateChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setDateFounded(newState);
        infoChangeCallback({ type: constants.STARTUP_INTRO_DATE_UPDATE, data: newState });
    };

    const employeeChangeHandler = (val, validated) => {
        const newState = {
            value: val ? val : '',
            validated: isIdea ? true : validated,
        };
        setEmployeeCount(newState);
        infoChangeCallback({ type: constants.STARTUP_INTRO_EMPLOYEES_UPDATE, data: newState });
    };

    const addressChangeHandler = (val, validated) => {
        const newState = { description: val ? val : '', descValidated: validated };
        setAddressDescription((prevAddress) => ({
            ...prevAddress,
            ...newState,
        }));
        infoChangeCallback({ type: constants.STARTUP_INTRO_ADDRESS_UPDATE, data: newState });
    };

    const addressVisibilityHandler = (val) => {
        setAddressDescription((prevAddress) => ({
            ...prevAddress,
            public: val,
        }));
        infoChangeCallback({ type: constants.STARTUP_INTRO_ADDRESS_UPDATE, data: { public: val } });
    };

    const investmentChangeHandler = (monVal, validated) => {
        const newState = { value: monVal.value ? monVal.value : '', validated: validated };
        setInvestment((prevInvestment) => ({
            ...prevInvestment,
            ...newState,
        }));
        infoChangeCallback({ type: constants.STARTUP_INTRO_INVESTMENT_UPDATE, data: newState });
    };

    const investmentVisibilityHandler = (val) => {
        setInvestment((prevInvestment) => ({
            ...prevInvestment,
            public: val,
        }));
        infoChangeCallback({ type: constants.STARTUP_INTRO_INVESTMENT_UPDATE, data: { public: val } });
    };

    const cityChangeHandler = (val, selected) => {
        setCity(val);
        infoChangeCallback({
            type: constants.STARTUP_INTRO_ADDRESS_UPDATE,
            data: {
                city: {
                    id: selected?.id,
                    name: selected?.data,
                },
                cityValidated: selected && true,
            },
        });
    };

    const emailChangeHandler = (val, validated) => {
        const newState = {
            value: val ? val : '',
            validated: validated,
        };
        setFormEmail(newState);
        infoChangeCallback({ type: constants.STARTUP_INTRO_EMAIL_UPDATE, data: newState });
    };

    const emailVisibilityHandler = (val) => {
        setFormEmail((prev) => ({
            ...prev,
            public: val,
        }));
        infoChangeCallback({ type: constants.STARTUP_INTRO_EMAIL_UPDATE, data: { public: val } });
    };
    const websiteChangeHandler = (val, validated) => {
        const newState = {
            value: val ? val : '',
            validated: validated,
        };
        setFormWebsite(newState);
        infoChangeCallback({ type: constants.STARTUP_INTRO_WEBSITE_UPDATE, data: newState });
    };

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item xs={12}>
                <Grid container direction="row" justify="center">
                    {isIdea && (
                        <Grid item xs={12}>
                            <InputTextArea
                                type="text"
                                label={t('profile.startup.summary')}
                                validationText={t('validation.notEmpty')}
                                initText={ideaSummary.value}
                                onChange={(val, validated) => ideaSummaryChangeHandler(val, validated)}
                                required
                                className={classes.summary}
                                count="400"
                                maxLength
                                rowCount={5}
                                validateOnBlur
                                validatedAfterFormSubmit={!(formSubmitted && !ideaSummary.value)}
                            />
                        </Grid>
                    )}

                    {!isIdea && (
                        <Grid item xs={12} md={6}>
                            <CfoDatePicker
                                id={t('profile.startup.dateFounded')}
                                label={t('profile.startup.dateFounded')}
                                invalidDateMessage={foundDate.value ? t('validation.date') : t('validation.notEmpty')}
                                initialDate={foundDate.value}
                                onChange={dateChangeHandler}
                                autoOk
                                openTo="year"
                                disableFuture
                                maxDateMessage={t('validation.noFutureDate')}
                                required
                                validateOnBlur
                                validatedAfterFormSubmit={!(formSubmitted && !foundDate.value)}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={isIdea ? 12 : 6}>
                        <InputTextArea
                            type="integer-positive"
                            label={t('profile.startup.employees')}
                            validationText={
                                employeeCount.value?.length > 0
                                    ? t('validation.positiveNumber')
                                    : t('validation.notEmpty')
                            }
                            initText={employeeCount.value}
                            onChange={(val, validated) => employeeChangeHandler(val, validated)}
                            required={!isIdea}
                            validateOnBlur
                            validatedAfterFormSubmit={!(formSubmitted && !employeeCount.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                        <DialogContent>
                            <CountryUpdate
                                countries={countries}
                                isLoading={countryData.isLoading}
                                isError={countryData.isError}
                                changeCallback={() => {}}
                            />
                        </DialogContent>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DialogContent>
                            <CityUpdate
                                cities={cities}
                                changeCallback={(data, valid) => cityChangeHandler(data, valid)}
                                formSubmitted={formSubmitted}
                            />
                        </DialogContent>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <InputTextArea
                                    type="text"
                                    label={t('profile.startup.address')}
                                    validationText={t('validation.notEmpty')}
                                    initText={addressDesc.value}
                                    onChange={(val, validated) => addressChangeHandler(val, validated)}
                                    rowCount={5}
                                    required={!isIdea}
                                    dontValidate={isIdea}
                                    validateOnBlur
                                    validatedAfterFormSubmit={!(!isIdea && formSubmitted && !addressDesc.value)}
                                />
                            </Grid>
                            {!isIdea && (
                                <Grid item>
                                    <DialogContent style={{ paddingTop: '0', textAlign: 'end' }}>
                                        <VisibilityToggle
                                            initialValue={addressDesc.public}
                                            onChange={addressVisibilityHandler}
                                        />
                                    </DialogContent>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <CurrencyInput
                                    id={t('profile.startup.investment')}
                                    label={t('profile.startup.investment')}
                                    initialValue={investmentAmnt}
                                    onChange={investmentChangeHandler}
                                    validateOnBlur
                                />
                            </Grid>
                            {!isIdea && !curLoading && !curError && (
                                <Grid item>
                                    <DialogContent style={{ paddingTop: '0', textAlign: 'end' }}>
                                        <VisibilityToggle
                                            initialValue={investmentAmnt.public}
                                            onChange={investmentVisibilityHandler}
                                        />
                                    </DialogContent>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column">
                            <Grid item>
                                <InputTextArea
                                    type="mail"
                                    label={t('profile.venture.email')}
                                    validationText={
                                        formEmail.value?.length > 0 ? t('validation.email') : t('validation.notEmpty')
                                    }
                                    initText={formEmail.value}
                                    onChange={(val, validated) => emailChangeHandler(val, validated)}
                                    required
                                    validateOnBlur
                                    validatedAfterFormSubmit={!(formSubmitted && !formEmail.value)}
                                />
                            </Grid>
                            <Grid item>
                                <DialogContent style={{ paddingTop: '0', textAlign: 'end' }}>
                                    <VisibilityToggle
                                        initialValue={formEmail.public}
                                        onChange={emailVisibilityHandler}
                                    />
                                </DialogContent>
                            </Grid>
                            <Grid item className={classes.emailInfo}>
                                <InfoOutlinedIcon fontSize="small" />
                                <span>{t('profile.venture.emailInfo')}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputTextArea
                            type="url"
                            label={t('formLabel.website')}
                            validationText={t('validation.url')}
                            initText={formWebsite.value}
                            onChange={(val, validated) => websiteChangeHandler(val, validated)}
                            validateOnBlur
                            initiallyValidated
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GeneralInfoModal;
