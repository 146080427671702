import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ProfileVisitHistory({ visitCount }) {
    const { t, i18n } = useTranslation();

    useEffect(() => {}, []);

    return (
        <div className={'visitHistoryContainer poppin-500-14px'}>
            <span
                className={'web'}
                dangerouslySetInnerHTML={{ __html: i18n.t('idea.profileVisitLabel', { count: visitCount }) }}
            />
            <span
                className={'mobile'}
                dangerouslySetInnerHTML={{ __html: i18n.t('idea.mobileProfileVisitLabel', { count: visitCount }) }}
            />
        </div>
    );
}
