import { makeStyles } from '@material-ui/core';
import {
    fontStyle,
    buttonStyle,
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    COLOR_PRIMARY_BASE,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    inputTextAreaWrapper: {
        padding: '6px 12px 0 12px',
        '&.MuiDialogContent-root:first-child': {
            padding: '6px 12px 2px 12px',
        },
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            padding: '6px 0 0 0',
            '&.MuiDialogContent-root:first-child': {
                padding: '6px 0 2px 0',
            },
        },
    },
    cfoDatePickerWrapper: {
        padding: '6px 12px 0 12px',
        '&.MuiDialogContent-root:first-child': {
            padding: '6px 12px 2px 12px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '6px 0 0 0',
            '&.MuiDialogContent-root:first-child': {
                padding: '6px 0 2px 0',
            },
        },
        overflowY: 'hidden',
    },
    addButton: {
        ...fontStyle,
        ...buttonStyle,
        height: '29px',
    },
    primaryButton: {
        ...fontStyle,
        ...buttonStyle,
        height: '48px',
    },
    secondaryButton: {
        ...fontStyle,
        ...buttonStyle,
        height: '48px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        color: COLOR_SECONDARY_BASE,
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
            color: COLOR_SECONDARY_BASE,
        },
    },
    pageTitle: {
        ...fontStyle,
        fontSize: '32px',
        padding: '16px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: '24px',
        },
    },
    legalDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& p': {
            paddingBottom: '10px',
        },
        '& .MuiCheckbox-root': {
            marginTop: '-35px',
        },
        '& .policySubtitle': {
            fontSize: '11px',
            marginTop: '8px',
        },
    },
    singleSelectRoot: {
        paddingTop: '6px',
        '& .MuiInputBase-root': {
            maxHeight: '53.63px !important',
        },
    },
    singleSelectWrapper: {
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& label': {
            paddingLeft: '12px',
        },
    },
    singleSelectLeftWrapper: {
        paddingTop: '6px',
        paddingLeft: '12px',
        '& .MuiInputBase-root': {
            maxHeight: '53.63px !important',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    socialMediaWrapper: {
        marginTop: '32px',
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    socialMediaTextInputWrapper: {
        // InputTextArea
        padding: '6px 0 0 0',
        '&.MuiDialogContent-root:first-child': {
            padding: '6px 0 2px 0',
        },
        overflowY: 'hidden',
    },
    keywordUpdateWrapper: {
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    buttonsWrapper: {
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    radioWrapper: {
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    radio: {
        '&.MuiRadio-colorPrimary.Mui-checked': {
            color: COLOR_PRIMARY_BASE,
        },
    },
    addButtonWrapper: {
        padding: '16px 12px',
        [theme.breakpoints.down('sm')]: {
            padding: '16px 0',
        },
    },
    founderAddButtonWrapper: {
        padding: '16px',
        [theme.breakpoints.down('sm')]: {
            padding: '16px 4px',
        },
    },
    checkboxWrapper: {
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    checkboxHelperWrapper: {
        paddingLeft: '14px',
        paddingRight: '14px',
    },
    formHelperWrapper: {
        paddingLeft: '26px',
        paddingRight: '26px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '14px',
            paddingRight: '14px',
        },
    },
    fileUploadWrapper: {
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    preAppBtnListItem: {
        paddingTop: '16px',
    },
    uppercase: {
        textTransformation: 'capitalize',
    },
}));

export default useStyles;
