import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    summary: {
        [theme.breakpoints.up('md')]: {
            height: '136px',
            '& .MuiInputBase-formControl': {
                height: '100%',
            },
        },
    },
    emailInfo: {
        padding: '8px 15px 8px 24px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        fontSize: '12px',
        '& > span': {
            padding: '8px',
        },
    },
}));

export default useStyles;
