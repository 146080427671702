import { CookieActions } from './ActionTypes';

export const setCookieBanner = (status) => ({
    type: CookieActions.SET_COOKIE_BANNER,
    status,
});

export const setPerformanceCookie = (status) => ({
    type: CookieActions.SET_PERFORMANCE_COOKIE,
    status,
});
