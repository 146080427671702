import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    carouselContainer: {
        margin: '12px auto',
        maxWidth: '500px',
        ['@media (max-width:832px)']: {
            margin: 'unset',
        },
        ['@media (min-width:832px)']: {
            // eslint-disable-line no-useless-computed-key
            maxWidth: '832px',
        },
        ['@media (min-width:1225px)']: {
            // eslint-disable-line no-useless-computed-key
            maxWidth: '1200px',
        },
        ['@media (min-width:1504px)']: {
            // eslint-disable-line no-useless-computed-key
            maxWidth: '1344px',
        },
        '& .carousel__inner-slide': {
            width: 'calc(100% - 16px)',
            marginLeft: '8px',
            marginBottom: '30px',
            ['@media (min-width:1272px)']: {
                width: 'calc(100% - 32px)',
                marginLeft: '16px',
            },
        },
    },
});

export default useStyles;
