import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './index.style';
import ProgramCardGrid from './CardItem/ProgramCardGrid';

const SupportsOnePage = ({ supportProgramOnePage }) => {
    const { t } = useTranslation('preLogin');
    const classes = useStyles();

    return (
        <>
            <section className={classes.supportSection} id="section-supports">
                <div className={classes.supportContainer}>
                    <span className={classes.title}>{t('titles.supports')}</span>
                    <ProgramCardGrid data={supportProgramOnePage.supportAreaData} />
                </div>
            </section>
        </>
    );
};

export default SupportsOnePage;
