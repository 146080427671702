import React, { useState } from 'react';
import IconsMultipleKisi from 'fintech/icons/IconsMultipleKisi';
import ReportCard from 'fintech/components/reports/common/card';
import RoleBox from 'fintech/components/reports/common/roleBox';
import { poppins_16px, COLOR_PURPLE } from 'fintech/components/style/common';
import { Grid } from '@material-ui/core';
import ProgressBars from 'fintech/components/reports/common/progressbar';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { getPerson } from 'fintech/api/fintechService';
import { ReportType } from 'fintech/utils/ReportUtils';
const Index = ({ onShowModal }) => {
    const { t } = useTranslation('reports');
    const { keycloak } = useKeycloak();
    const [personData, setPersonData] = useState(null);

    return (
        <ReportCard
            title={t('card.contacts')}
            icon={<IconsMultipleKisi />}
            button={{
                text: t('card.generateDetailedReport'),
                onClick: () => {
                    onShowModal(ReportType.USER);
                },
            }}
            isLoading={!personData}
            isCollapse
            onOpen={() => {
                if (!personData) {
                    getPerson(keycloak).then((e) => {
                        setPersonData(e.data);
                    });
                }
            }}
        >
            {personData && personData.rolePercentageDistribution && (
                <Grid container spacing={3}>
                    <Grid item xs={12} xl={12} lg={12}>
                        <p style={{ ...poppins_16px, margin: '0' }}>{t('card.numberOfPeopleByRoles')}</p>
                    </Grid>
                    <Grid item xs={12} xl={12} lg={12}>
                        <RoleBox
                            items={[
                                {
                                    percentage: personData.rolePercentageDistribution.find((x) => x.label === 'Startup')
                                        ?.percentage,
                                    description: personData.rolePercentageDistribution.find(
                                        (x) => x.label === 'Startup'
                                    )?.count,
                                    title: t('card.startups'),
                                },
                                {
                                    percentage: personData.rolePercentageDistribution.find(
                                        (x) => x.label === 'Investor'
                                    )?.percentage,
                                    description: personData.rolePercentageDistribution.find(
                                        (x) => x.label === 'Investor'
                                    )?.count,
                                    title: t('card.investors'),
                                },
                                {
                                    percentage: personData.rolePercentageDistribution.find((x) => x.label === 'Mentor')
                                        ?.percentage,
                                    description: personData.rolePercentageDistribution.find((x) => x.label === 'Mentor')
                                        ?.count,
                                    title: t('card.mentor'),
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={5} lg={5}>
                        <ProgressBars
                            title={t('card.numberOfPeoplebyFintechVerticalsOfInterest')}
                            items={
                                personData !== null
                                    ? personData.fintechVerticalPercentageDistribution.map((x) => {
                                          return {
                                              percentage: x.percentage,
                                              barText: x.count,
                                              title: x.label,
                                          };
                                      })
                                    : []
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={5} lg={5}>
                        <ProgressBars
                            title={t('card.numberOfPeoplebyTechnologyAreasOfInterest')}
                            defaultBarColor={COLOR_PURPLE}
                            items={
                                personData !== null
                                    ? personData.technologyPercentageDistribution.map((x) => {
                                          return {
                                              percentage: x.percentage,
                                              barText: x.count,
                                              title: x.label,
                                          };
                                      })
                                    : []
                            }
                            defaultOpacity={0.05}
                        />
                    </Grid>
                </Grid>
            )}
        </ReportCard>
    );
};

Index.propTypes = {};

export default Index;
