import * as React from 'react';

function SvgIconsSingleArrowRight(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <g fill="#5b7adb" fillRule="nonzero">
                    <path d="M17.238 18.45c.408 0 .762-.15 1.062-.45.3-.3.45-.654.45-1.062V6.462c0-.408-.15-.762-.45-1.062-.3-.3-.654-.45-1.062-.45H6.762c-.42 0-.777.15-1.071.45-.294.3-.441.654-.441 1.062V9.45h1.512V6.462h10.476v10.476H6.762V13.95H5.25v2.988c0 .408.147.762.441 1.062.294.3.651.45 1.071.45h10.476zm-5.616-2.988l3.762-3.762-3.762-3.762L10.56 9l1.926 1.962H5.25v1.476h7.236L10.56 14.4l1.062 1.062z" />
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleArrowRight;
