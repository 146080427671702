import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import AdvancedFilteringModal from 'fintech/components/modal/AdvancedFilteringModal';
import { makeAdvancedFilteringStyle } from 'fintech/pages/post-login/EntrepreneursPage/index.style';
import SplitButton from 'fintech/components/ui/button/SplitButton';
import { groupBy } from 'lodash';
import { formatNumericValue } from 'fintech/utils/StringUtils';
import * as EntrepreneurActions from 'fintech/store/actions/entrepreneur';
import { buttonStyleGridClear, mobileMediaQuery } from 'fintech/components/style/common';
import useStyles from './index.style';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import { Add, Close } from '@material-ui/icons';
import SvgIconsSingleFilter from 'fintech/icons/IconsSingleFilter';
import { Grid } from '@material-ui/core';

const AdvancedFilterArea = (props) => {
    const classes = useStyles();
    const styles = makeAdvancedFilteringStyle();
    const matches = useMediaQuery(mobileMediaQuery);
    const {
        type,
        removeItemHandler,
        removeComponentHandler,
        buttonActions,
        setSelectedCategory,
        toggleCheckbox,
        toggleAdvancedFilter,
        clearAll,
        showAdvancedFilterToggle = true,
        overrideWhenMobile = false,
        changeTextOnQueryType = false,

        // Admin button for opening the new user form.
        showModalToggle = false,
        modalToggleLabel,
        onToggleClicked,
    } = props;
    const { t } = useTranslation(type);
    const { advancedFilter } = useSelector((state) => state[type]);
    const { filteredNumber, lastQueryType } = useSelector((state) => state[type].global);
    const { appliedFilters } = useSelector((state) => state[type]);
    const dispatch = useDispatch();

    const isDisplayed = advancedFilter?.isDisplayed;
    const categories = advancedFilter?.categories;
    const selectedCategory = advancedFilter?.selectedCategory;
    const data = advancedFilter?.data;

    const [listedCount, setListedCount] = useState(filteredNumber);

    useEffect(() => {
        setListedCount(filteredNumber);
    }, [filteredNumber]);

    const getNumberOfAppliedFilters = () => {
        let count = 0;

        for (const properties in appliedFilters) {
            count += appliedFilters[properties].length;
        }

        return count;
    };
    const numberOfAppliedFilters = getNumberOfAppliedFilters();

    const generateSplittedFilters = ({ appliedFilters, type, t }) => {
        const render = [];

        Object.entries(appliedFilters).map((filter) => {
            const [category, values] = filter;

            const groups = groupBy(values, 'parentData');
            for (const [key, value] of Object.entries(groups)) {
                let subCategory = '';
                let infixText = '';
                if (key !== 'null') {
                    subCategory = key;
                    infixText = `: ${key}`;
                }

                const title = `${t(`${type}.${category}`)} ${infixText} [${value.length}]`;
                render.push(
                    <SplitButton
                        key={title}
                        idPrefix={type === 'entrepreneur' ? EntrepreneurActions.generateIDPrefix(category) : category}
                        removeComponentHandler={removeComponentHandler}
                        removeItemHandler={removeItemHandler}
                        startIcon={null}
                        type="filter"
                        option={title}
                        subOptions={value}
                        subCategory={subCategory}
                    ></SplitButton>
                );
            }
        });

        return render;
    };

    let listedMessageKey = null;
    if (changeTextOnQueryType) {
        if (!(lastQueryType === 'search')) {
            listedMessageKey = filteredNumber > 1 ? 'dataGrid.listedRecords' : 'dataGrid.listedRecord';
        } else {
            listedMessageKey = filteredNumber > 1 ? 'dataGrid.listedSearchRecords' : 'dataGrid.listedSearchRecord';
        }
    } else {
        listedMessageKey = filteredNumber > 1 ? 'dataGrid.listedRecords' : 'dataGrid.listedRecord';
    }

    return (
        <Box id="advanced-filter-area">
            {isDisplayed && (
                <AdvancedFilteringModal
                    data={data}
                    buttonActions={buttonActions}
                    type={type}
                    verticals={categories}
                    selectedVertical={selectedCategory}
                    setVertical={(index) => dispatch(setSelectedCategory(index))}
                    toggleCheckbox={(id) => {
                        dispatch(toggleCheckbox(id));
                    }}
                />
            )}

            <Grid
                className={styles.container}
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item xs={9} container direction="row" justify="flex-start" alignItems="center">
                    <Grid item>
                        {filteredNumber ? (
                            <Typography
                                classes={{ root: classes.listedMessageTypo }}
                                display="inline"
                                dangerouslySetInnerHTML={{
                                    __html: t(listedMessageKey, {
                                        count: formatNumericValue(listedCount),
                                    }),
                                }}
                            />
                        ) : filteredNumber === 0 ? (
                            ''
                        ) : (
                            <Typography classes={{ root: classes.listedMessageTypo }} display="inline">
                                {t('dataGrid.allRecords')}
                            </Typography>
                        )}
                    </Grid>
                    {(numberOfAppliedFilters !== 0 || lastQueryType === 'search') && (
                        <ShrinkingButton
                            onClick={clearAll}
                            shrinkedIcon={<Close fontSize="small" />}
                            fullIcon={<Close />}
                            fullText={
                                lastQueryType === 'search' ? t('searchbar.clearResults') : t('searchbar.clearFilters')
                            }
                            otherStyles={buttonStyleGridClear}
                        />
                    )}
                    {(showAdvancedFilterToggle || (overrideWhenMobile && !matches)) && (
                        <ShrinkingButton
                            onClick={() => dispatch(toggleAdvancedFilter())}
                            shrinkedIcon={<SvgIconsSingleFilter />}
                            fullIcon={<SvgIconsSingleFilter />}
                            fullText={
                                numberOfAppliedFilters === 0
                                    ? t('dataGrid.filterRecords')
                                    : numberOfAppliedFilters > 1
                                    ? t('dataGrid.appliedFilters', { count: numberOfAppliedFilters })
                                    : t('dataGrid.appliedFilter', { count: numberOfAppliedFilters })
                            }
                        />
                    )}
                    {showModalToggle && (
                        <ShrinkingButton
                            onClick={() => onToggleClicked && onToggleClicked()}
                            shrinkedIcon={<Add fontSize="small" />}
                            fullIcon={<Add />}
                            fullText={modalToggleLabel}
                        />
                    )}
                </Grid>
            </Grid>
            {numberOfAppliedFilters !== 0 && (
                <Box className={styles.splittedButtonContainer}>
                    {generateSplittedFilters({ appliedFilters, type, t })}
                </Box>
            )}
        </Box>
    );
};

export default AdvancedFilterArea;
