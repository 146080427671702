import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import { SUBMIT, REJECT } from 'fintech/components/util/ActionModalConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import InvestorActionModal from './ActionModal';
import ActionModal from 'fintech/components/util/ActionModal';
import { laptopLarge } from 'fintech/components/style/common';
import { useMediaQuery } from '@material-ui/core';
import { STATES } from 'fintech/store/actions/ActionTypes';
import EstablishmentInvestorActionModal from 'fintech/components/profile/establishment/InvestmentPreferences/form/EstablishmentInvestorActionModal/EstablishmentInvestorActionModal';
import { getInvestorOrStartupFormValueFromState } from 'fintech/utils/DataParser';
import { setActiveRequest } from 'fintech/store/actions/common';

const InvestorEditModal = (props) => {
    const { type, open, onCancel, recordId, editing, okButtonLabel, actions, services } = props;

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { uuid, hasActiveRequest } = useSelector((state) => ({ ...state[type].intro.data, ...state.common }));
    const formData = useSelector((state) => getInvestorOrStartupFormValueFromState(state, type));

    const matches = useMediaQuery(laptopLarge);
    const modalSize = matches ? { minWidth: '700px', minHeight: '690px' } : {};

    const displayedFormPropType = type === STATES.STARTUP ? 'investor' : 'startup';

    useEffect(() => {
        editing && recordId && dispatch(actions.setInvestmentForm(recordId));
    }, [editing, recordId]);

    const editModalActionHandler = (action) => {
        const { type } = action;

        if (type === SUBMIT) {
            updateData();
        } else if (type === REJECT) {
            rejectData();
        } else {
            return;
        }
    };

    const updateData = () => {
        !formData.sending && dispatch(actions.sendInvestmentForm());
        if (!validateForm()) {
            enqueueSnackbar(t('validation.genericError'), { variant: 'error' });
            return;
        }
        dispatch(setActiveRequest(true));

        const formToUpdate = omit(formData, ['investmentDateIsValid', 'sending']);
        const investorToUpdate = omit(formData[displayedFormPropType], ['isValid', 'unregistered']);
        const amountToUpdate = omit(formData.investmentAmount, ['isValid']);

        // if the current tab is Financials in Startup page , then access investor.investorId
        // if the current tab is InvestmentPreferences in Establishment page , then access startup.startupId
        const formTypeId = `${displayedFormPropType}Id`; // equals to startupId or investorId according to the above conditions.
        const requestData = {
            ...formToUpdate,
            [displayedFormPropType]: {
                ...investorToUpdate,
                [`${displayedFormPropType}Id`]:
                    investorToUpdate[formTypeId] === -1 ? null : investorToUpdate[formTypeId],
            },
            investmentAmount: amountToUpdate,
        };

        services
            .updateInvestmentInfo(keycloak, requestData, uuid)
            .then(() => {
                dispatch(setActiveRequest(false));
                onCancel();
                dispatch(actions.resetInvestmentForm());
                dispatch(actions.fetchInvestmentsData(keycloak, uuid));
                enqueueSnackbar(t('idea.updateSucces'), { variant: 'success' });
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            });
    };

    const rejectData = () => {
        onCancel();
        dispatch(actions.resetInvestmentForm());
    };

    const validateForm = () =>
        formData.investmentDateIsValid && formData[displayedFormPropType].isValid && formData.investmentAmount.isValid;

    const titleMsgId = recordId ? 'titleEdit' : 'titleAdd';
    return (
        <ActionModal
            open={open}
            title={t(`profile.financials.form.${titleMsgId}`)}
            onAction={editModalActionHandler}
            modalSize={modalSize}
            okButtonLabel={okButtonLabel}
            okClickDisabled={hasActiveRequest}
            cancelClickDisabled={hasActiveRequest}
            showSpinnerWhenDisabled={hasActiveRequest}
        >
            {type === STATES.ESTABLISHMENT ? <EstablishmentInvestorActionModal /> : <InvestorActionModal />}
        </ActionModal>
    );
};

export default InvestorEditModal;
