import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Avatar, Backdrop, Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import FileResizer from 'react-image-file-resizer';

import { getPublicFileUrl, uploadAvatarFile } from 'fintech/api/fileService';
import { useExpandableAvatarStyles } from 'fintech/components/profile/common/ExpandableAvatar/ExpandableAvatar.style';
import SvgCloseButton from 'fintech/icons/CloseButton';
import { IMAGE_MIMES_START } from 'fintech/components/util/FileConstants';
import { fetchUserImage } from 'fintech/store/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInUserUuid } from 'fintech/utils/Auth';

const fiveSeconds = 5000;
const AVATAR_SIZE_LIMIT = 10000000; // 10 MB

export const ExpandableAvatar = (props) => {
    const { placeholder, isPreLogin, userServiceUri, profilePictureFile, userUuid, editable } = props;
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const classes = useExpandableAvatarStyles();

    const [selectedImage, setSelectedImage] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const uuid = useSelector(({ user }) => user.intro.data?.uuid);

    const expand = () => setExpanded(true);
    const shrink = () => setExpanded(false);

    const resizeFile = (file) =>
        new Promise((resolve) => {
            FileResizer.imageFileResizer(file, 300, 400, 'JPEG', 80, 0, (uri) => resolve(uri), 'file');
        });

    const fetchOnUpload = () => {
        if (getLoggedInUserUuid(keycloak) === uuid) {
            dispatch(fetchUserImage(keycloak, userUuid));
        }
    };

    const changeHandler = (event) => {
        const image = event.target.files[0];

        if (!image.type.startsWith(IMAGE_MIMES_START)) {
            enqueueSnackbar(t('avatar.imageFormatError'), {
                variant: 'error',
                autoHideDuration: fiveSeconds,
            });
        } else if (image.size > AVATAR_SIZE_LIMIT) {
            enqueueSnackbar(t('avatar.imageSizeError'), {
                variant: 'error',
                autoHideDuration: 5000,
            });
        } else {
            resizeFile(image)
                .then((resizedImage) => {
                    const formData = new FormData();
                    formData.append('uploaded_file', resizedImage);
                    uploadAvatarFile(keycloak, formData, userUuid, image.name, userServiceUri).then(() => {
                        fetchOnUpload();
                        setSelectedImage(resizedImage);
                    });
                })
                .catch((error) => {
                    console.error({ error });
                    enqueueSnackbar(t('avatar.uploadError'), { variant: 'error', autoHideDuration: 5000 });
                });
        }
    };

    const getProfileImage = () => {
        if (selectedImage) {
            return URL.createObjectURL(selectedImage);
        } else {
            return profilePictureFile ? getPublicFileUrl(profilePictureFile) : null;
        }
    };

    let profileImage = getProfileImage();
    let isPlaceHolder = false;
    if (!profileImage) {
        profileImage = placeholder;
        isPlaceHolder = true;
    }

    let avatarClassName = classes.avatarIconWithoutBorder;
    if (isPlaceHolder) {
        avatarClassName = classes.avatarIconPlaceholder;
    } else {
        avatarClassName = classes.avatarIcon;
    }

    if (isPreLogin) {
        avatarClassName = classes.preLoginPlaceholderAvatar;
    }

    let placeholderClassName = classes.avatarIconPlaceholder;
    if (isPreLogin) {
        placeholderClassName = classes.preLoginPlaceholderAvatar;
    }

    const rootClass = !isPreLogin ? classes.root : '';

    return (
        <div className={rootClass} style={{ position: 'relative' }}>
            <Button className={classes.expandButton} onClick={expand}>
                <Avatar src={profileImage} className={avatarClassName}>
                    <Avatar src={placeholder} className={placeholderClassName}>
                        {placeholder}
                    </Avatar>
                </Avatar>
            </Button>
            {editable && (
                <label className={classes.editButton}>
                    <input
                        accept="*"
                        className={classes.input}
                        name="uploaded_file"
                        type="file"
                        onChange={changeHandler}
                    />
                    <Edit className={classes.editIcon} />
                </label>
            )}
            <Backdrop className={classes.backdrop} open={expanded} onClick={shrink}>
                <div className={classes.imageWrapper}>
                    <span className={classes.closeButtonWrapper}>
                        <SvgCloseButton style={{ cursor: 'pointer' }} />
                    </span>
                    <img
                        src={profileImage}
                        style={{ height: '50%' }}
                        onError={({ target }) => (target.src = placeholder)}
                    />
                </div>
            </Backdrop>
        </div>
    );
};
