import { makeStyles } from '@material-ui/core';
import { poppins500_12px, poppinsBold_12px } from 'fintech/components/style/common';
import { COLOR_ORANGE, COLOR_SECONDARY_SHADE_01 } from 'fintech/components/style/common';

const useStyles = makeStyles(() => ({
    progress: {
        width: '100%',
        '&  > p ': {
            ...poppins500_12px,
            fontWeight: 'normal',
            color: COLOR_SECONDARY_SHADE_01,
            margin: '0 0 1px',
        },
        '& > div': {
            display: 'flex',
            alignItems: 'center',
        },
        ' & > div > p': {
            ...poppinsBold_12px,
            margin: '0 0 0 8px',
            lineHeight: 'inherit',
        },
        ' & > div > span': {
            backgroundColor: COLOR_ORANGE,
            height: '10px',
            borderRadius: '5.5px',
        },
    },
}));

export default useStyles;
