import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useStyles from 'fintech/pages/pre-login/sections/Footer/index.style';
import { LANGUAGE_CODES } from 'fintech/constants';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import CBFOLogoTr from 'fintech/static/images/2_satir_yatay-03.png';
import CBFOLogoEn from 'fintech/static/images/ingilizce_yatay-02.png';
import SocialMediaIcons from 'fintech/components/applications/layout/pre-login/SocialMediaIcons';
import * as ROUTES from 'fintech/routes';

const Footer = () => {
    const history = useHistory();
    const { t } = useTranslation('preLogin');
    const selectedLanguage = getSystemLanguage();
    const logoObj = selectedLanguage === LANGUAGE_CODES.EN ? CBFOLogoEn : CBFOLogoTr;
    const classes = useStyles();

    return (
        <footer className={classes.footerWrapper} style={{ marginLeft: '8px', marginRight: '8px' }} id="footer">
            <div className={classes.leftWrapper}>
                <span
                    style={{ fontSize: 12, marginBottom: 20, cursor: 'pointer' }}
                    onClick={() => history.push(ROUTES.HOME)}
                >
                    {t('backToHome')}
                </span>

                <img src={logoObj} className={classes.cbfoLogo} />
                <div className={classes.address}>{t('footer.address')}</div>
            </div>
            <div className={classes.rightWrapper}>
                <SocialMediaIcons iconsWrapperClassname={classes.iconsWrapper} />
                <div className={classes.policyContainer}>
                    <span onClick={() => history.push(ROUTES.CLARIFICATION_TEXT)}>{t('footer.clarification')}</span>
                    <span onClick={() => history.push(ROUTES.TERMS_OF_USE)}>{t('footer.termsOfUse')}</span>
                    <span onClick={() => history.push(ROUTES.PRIVACY_POLICY)}>{t('footer.privacyPolicy')}</span>
                    <span onClick={() => history.push(ROUTES.COOKIE_POLICY)}>{t('footer.cookiePolicy')}</span>
                </div>
                <span className={classes.copyRight}>{t('mobileMenu.copyRight')}</span>
            </div>
        </footer>
    );
};

export default Footer;
