import React, { useState, useEffect, useRef } from 'react';
import useStyles from './index.style';
import { Grid } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import IntroQuadGrid from 'fintech/components/profile/startup/Intro/Details/QuadGrid';
import { ApplicationTypes } from 'fintech/components/membership/ApplicationTypes';
import { isNil } from 'lodash';
import IconsSingleMinus from 'fintech/icons/IconsSingleMinus';
import IconsSingleAdd from 'fintech/icons/IconsSingleAdd';
import IconsSingleCheckWhite from 'fintech/icons/IconsSingleCheckWhite';
import { SupportApplicationApprovalStatus } from 'fintech/constants/supportProgram';
import { loadingSpinnerWithTextGeneralTextStyle, COLOR_GREEN, COLOR_MUI_ERROR } from 'fintech/components/style/common';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';
import fileDownload from 'react-file-download';
import { fetchSupportApplicationDetailsPdfDownload } from 'fintech/api/fintechService';
import PdfDownload from 'fintech/components/support/program/pdfDownload';
import { hasRoles } from 'fintech/utils/Auth';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';

const loadingStyle = {
    alignSelf: 'normal',
    padding: 0,
};

const ApplicationDetails = (props) => {
    const classes = useStyles();
    const {
        status,
        sections,
        getQuadGridItems,
        getDuoGridItems,
        buttonsOperation,
        selectedContentId,
        // condition status === ApplicationTypes.PENDING valid for Membership Management page.
        // this prop should be passed for Support Program page.
        showOperationButtonsAndInfoText = status === ApplicationTypes.PENDING,
        processAlreadyCompleted = null, // it is passed only for support programs.
        isLoading = false,
    } = props;
    const [state, setState] = useState({ quadItems: [], duoItems: [] });
    const [pdfLoading, setPdfLoading] = useState(false);
    const { keycloak } = useKeycloak();
    const isAdmin = hasRoles(keycloak, ROLE_ADMIN);

    useEffect(() => {
        const quadItems = getQuadGridItems();
        setState({
            quadItems: quadItems,
        });
    }, [getQuadGridItems, getDuoGridItems]);

    const handleDownload = async () => {
        if (selectedContentId) {
            setPdfLoading(true);
            await fetchSupportApplicationDetailsPdfDownload({ keycloak, selectedContentId })
                .then((result) => {
                    fileDownload(result, `support-application-${selectedContentId}.pdf`);
                })
                .catch((error) => console.log('error', error))
                .finally(() => {
                    setPdfLoading(false);
                });
        }
    };

    return (
        <Grid
            id="applicationDetails"
            item
            className={classes.container}
            style={isLoading ? loadingStyle : { alignSelf: 'flex-start' }}
        >
            <Grid container direction="row-reverse" alignItems="flex-start">
                <Grid item xs={12}>
                    {isLoading ? (
                        <LoadingSpinnerWithText
                            iconGridStyle={classes.iconGrid}
                            style={loadingSpinnerWithTextGeneralTextStyle}
                        />
                    ) : (
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            classes={{
                                root: classes.gridRoot,
                            }}
                        >
                            {selectedContentId && isAdmin && (
                                <PdfDownload handleClick={handleDownload} pdfLoading={pdfLoading} />
                            )}

                            <div className={classes.applicationInfo}>
                                <IntroQuadGrid items={state.quadItems} />
                            </div>

                            <Sections status={status} sections={sections} classes={classes} />

                            {showOperationButtonsAndInfoText &&
                                (processAlreadyCompleted ? (
                                    <div
                                        className={classes.informationMessage}
                                        style={{
                                            color:
                                                processAlreadyCompleted.status ===
                                                SupportApplicationApprovalStatus.APPROVED
                                                    ? COLOR_GREEN
                                                    : COLOR_MUI_ERROR,
                                        }}
                                    >
                                        {processAlreadyCompleted.text}
                                    </div>
                                ) : (
                                    buttonsOperation.showButtons && (
                                        <Grid
                                            container
                                            alignItems="center"
                                            justify="center"
                                            className={classes.buttons}
                                        >
                                            <Grid
                                                item
                                                className={classes.rejectButton}
                                                onClick={buttonsOperation.reject.handler}
                                            >
                                                {buttonsOperation.reject.text}
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.confirmButton}
                                                onClick={buttonsOperation.approve.handler}
                                            >
                                                <span>
                                                    <IconsSingleCheckWhite />
                                                </span>
                                                <span>{buttonsOperation.approve.text}</span>
                                            </Grid>
                                        </Grid>
                                    )
                                ))}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

const Sections = ({ status, sections, classes }) => {
    return (
        <div id="sections">
            {sections.map((section, index) => {
                return <Section key={index} section={section} status={status} classes={classes} />;
            })}
        </div>
    );
};

const Section = ({ status, section, classes }) => {
    const [showSectionContent, setShowSectionContent] = useState(
        !isNil(section.initiallyOpen) ? section.initiallyOpen : true
    );

    let isInitialRender = useRef(true);

    const toggleContentVisibility = () => {
        setShowSectionContent((prev) => !prev);
    };

    useEffect(() => {
        if (!isInitialRender.current) {
            setShowSectionContent(true);
            isInitialRender.current = false;
        }
    }, [status]);

    return (
        <div className={classes.section}>
            <div className={classes.titleContainer} onClick={toggleContentVisibility} style={{ cursor: 'pointer' }}>
                <Grid container className={classes.title}>
                    <Grid item>{section.title}</Grid>
                    <Grid item style={{ marginLeft: 'auto', cursor: 'pointer' }}>
                        {showSectionContent ? <IconsSingleMinus /> : <IconsSingleAdd />}
                    </Grid>
                </Grid>
            </div>
            {showSectionContent && <div className={classes.sectionContent}>{section.component}</div>}
        </div>
    );
};

export default ApplicationDetails;
