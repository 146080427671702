import { doLogout } from 'fintech/utils/Auth';

export const invisibleMenuItems = [
    // {
    //     id: 'INBOX',
    //     menuLabel: 'leftMenu.inbox',
    //     urlPath: '/platform/inbox',
    // },
    {
        id: 'SETTINGS',
        menuLabel: 'leftMenu.accountSettings',
        urlPath: '/platform/settings',
    },
];

export const getSignOutMenuElement = (t, keycloak) => {
    return {
        onlyMobile: true,
        id: 'SIGN_OUT',
        menuLabel: t('leftMenu.signOut'),
        onClick: () => doLogout(keycloak),
    };
};

export const getSettingsMenuElement = (t) => {
    return {
        onlyMobile: true,
        id: 'SETTINGS',
        menuLabel: t('leftMenu.accountSettings'),
        onlySideMenuLabel: t('leftMenu.settings'),
        urlPath: '/platform/settings',
    };
};
