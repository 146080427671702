import * as React from 'react';

function SvgIconsSingleDate(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <filter id="nbzof2z5ia" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g filter="url(#nbzof2z5ia)" transform="translate(-1166 -592) translate(1162 590)">
                        <g>
                            <path
                                fill="#3F5162"
                                fillRule="nonzero"
                                d="M17.238 19.188c.408 0 .762-.144 1.062-.432.3-.288.45-.636.45-1.044V7.2c0-.408-.15-.762-.45-1.062-.3-.3-.654-.45-1.062-.45H16.5V4.212h-1.512v1.476H9.012V4.212H7.5v1.476h-.738c-.42 0-.777.15-1.071.45-.294.3-.441.654-.441 1.062v10.512c0 .408.147.756.441 1.044.294.288.651.432 1.071.432h10.476zm0-1.476H6.762V9.45h10.476v8.262zm-1.476-5.274v-1.476H14.25v1.476h1.512zm-3.024 0v-1.476h-1.476v1.476h1.476zm-2.988 0v-1.476H8.238v1.476H9.75z"
                                transform="translate(4 2)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleDate;
