import React from 'react';
import PropTypes from 'prop-types';
import useStyle from './index.style';
import ProgressbarItem from './components/progressItem';

const Index = ({ items, defaultBarColor, defaultOpacity, title }) => {
    const classes = useStyle();
    return (
        <div className={classes.progressBarsContainer}>
            <p>{title}</p>
            {items.map((x, index) => (
                <ProgressbarItem
                    percentage={x.percentage}
                    key={'' + index}
                    opacityPercantage={index * defaultOpacity}
                    barText={x.barText}
                    title={x.title}
                    defaultBarColor={defaultBarColor}
                />
            ))}
        </div>
    );
};

Index.propTypes = {
    items: PropTypes.arrayOf().isRequired,
    defaultBarColor: PropTypes.string.isRequired,
};
Index.defaultProps = {
    defaultOpacity: 0.1,
    title: '',
};

export default Index;
