import { makeStyles } from '@material-ui/core';
import { cardGridStyle } from 'fintech/components/style/common';

const sliderButton = {
    border: 'none',
    '&:disabled': {
        display: 'none',
    },
    width: 48,
    height: 48,
    backgroundColor: 'transparent',
};

const useStyles = makeStyles({
    MentorCarouselGrid: {
        ...cardGridStyle,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '314px',
    },
    SliderButtonBack: {
        ...sliderButton,
        position: 'absolute',
        top: '50%',
        left: 32,
        transform: 'translateY(-50%)',
    },
    SliderButtonNext: {
        ...sliderButton,
        position: 'absolute',
        top: '50%',
        right: 32,
        transform: 'translateY(-50%)',
    },
    MentorSliderContainer: {
        marginRight: 'auto',
        marginLeft: 'auto',
    },
});

export default useStyles;
