import React, { useState, useEffect, useCallback } from 'react';
import useStyles from './index.style';
import IconsSingleEllipsis2 from 'fintech/icons/IconsSingleEllipsis2';
import IconsSingleArrow from 'fintech/icons/IconsSingleArrow';
import IconsSingleClose2 from 'fintech/icons/IconsSingleClose2';
import IconsSingleDate from 'fintech/icons/IconsSingleDate';
import { Avatar, Popover, Grid, FormControl } from '@material-ui/core';
import {
    getConsultantCalendarWorkDays,
    getConsultantFilteredCalender,
    consultantApproveMeetingRequest,
    consultantRejectMeetingRequest,
    consultantCancelMeetingRequest,
} from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import ModalOrganizeWorkingHours from 'fintech/components/profile/user/consultant/ModalOrganizeWorkingHours/ModalOrganizeWorkingHours';
import ModalMeetingOperation from 'fintech/components/profile/user/consultant/calendar/ModalMeetingOperation/ModalMeetingOperation';
import { getPublicFileUrl } from 'fintech/api/fileService';
import {
    WAITING,
    APPROVE_REQUEST,
    REJECT_REQUEST,
    MEETING_OPERATION_TYPE,
    isMeetingTypeRejectOrCancel as _isMeetingOperationTypeRejectOrCancel,
    CANCEL_REQUEST_BY_CONSULTANT,
    CANCEL_REQUEST_BY_USER,
} from 'fintech/components/profile/user/consultant/calendar/RequestTypes/RequestTypes';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import IconsSingleCheckWhite from 'fintech/icons/IconsSingleCheckWhite';
import IconsSingleCloseWhite from 'fintech/icons/IconsSingleCloseWhite';
import OperationButton from 'fintech/components/profile/user/consultant/calendar/ModalMeetingOperation/OperationButton/OperationButton';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveRequest } from 'fintech/store/actions/common';
import { getUsername } from 'fintech/utils/Auth';
import { sendChatMessage } from 'fintech/store/actions/inbox';
import IconsAlertError from 'fintech/icons/IconsAlertError';
import SingleSelect from 'fintech/components/ui/form/select/SingleSelect';

const MEETING_APPROVE_LIMIT_EXCEPTION = 'E1130';

function ConsultantCalendar({ userUuid }) {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { hasActiveRequest } = useSelector((state) => state.common);
    const dispatch = useDispatch();

    const [meetingRequestTypes] = useState([
        { id: WAITING, data: t('profile.user.consultant.meetingCalendar.requestType.pending') },
        { id: APPROVE_REQUEST, data: t('profile.user.consultant.meetingCalendar.requestType.approved') },
        { id: REJECT_REQUEST, data: t('profile.user.consultant.meetingCalendar.requestType.rejected') },
        {
            id: `${CANCEL_REQUEST_BY_USER},${CANCEL_REQUEST_BY_CONSULTANT}`,
            data: t('profile.user.consultant.meetingCalendar.requestType.canceled'),
        },
    ]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [meetingRequestType, setMeetingRequestType] = useState(WAITING);
    const [meetingDates, setMeetingDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(0);
    const [filteredMeetings, setFilteredMeetings] = useState([]);
    const [openOrganizeWorkingHoursModal, setOpenOrganizeWorkingHoursModal] = useState(false);
    const [openMeetingOperationModal, setOpenMeetingOperationModal] = useState(false);
    const [popoverReadDetailsText, setPopoverReadDetailsText] = useState(
        t('profile.user.consultant.meetingCalendar.readDetailsAndConfirm')
    );
    const [popoverRejectOrCancelText, setPopoverRejectOrCancelText] = useState(
        t('profile.user.consultant.meetingCalendar.reject')
    );
    const [selectedMeeting, setSelectedMeeting] = useState();
    const [meetingOperationType, setMeetingOperationType] = useState(null);
    const [validatedAfterFormSubmit, setValidatedAfterFormSubmit] = useState(true);
    const [showMeetingApproveLimitWarning, setShowMeetingApproveLimitWarning] = useState(false);

    const meetingAnswerSender = getUsername();

    let modalHeading = '';
    if (meetingOperationType === MEETING_OPERATION_TYPE.readDetailAndAccept) {
        modalHeading = t('profile.user.consultant.meetingCalendar.meetingDetail.title');
    } else if (meetingOperationType === MEETING_OPERATION_TYPE.cancel) {
        modalHeading = t('profile.user.consultant.meetingCalendar.meetingDetail.cancel');
    } else {
        modalHeading = t('profile.user.consultant.meetingCalendar.meetingDetail.reject');
    }
    const isMeetingOperationTypeRejectOrCancel = _isMeetingOperationTypeRejectOrCancel(meetingOperationType);

    let isMeetingStatusRejectedOrCanceledWhenClickedReadDetail = false;
    let validationText = '';
    let inputLabel = '';

    if (selectedMeeting && selectedMeeting.status && selectedMeeting.status.type) {
        isMeetingStatusRejectedOrCanceledWhenClickedReadDetail =
            meetingOperationType === MEETING_OPERATION_TYPE.readDetailAndAccept &&
            (selectedMeeting.status.type === REJECT_REQUEST ||
                selectedMeeting.status.type === CANCEL_REQUEST_BY_USER ||
                selectedMeeting.status.type === CANCEL_REQUEST_BY_CONSULTANT);

        if (
            selectedMeeting.status.type === CANCEL_REQUEST_BY_USER ||
            selectedMeeting.status.type === CANCEL_REQUEST_BY_CONSULTANT ||
            meetingOperationType === MEETING_OPERATION_TYPE.cancel
        ) {
            validationText = t('validation.meetingCancel');
            inputLabel = t('profile.user.consultant.meetingCalendar.meetingDetail.cancelReason');
        }
        if (selectedMeeting.status.type === REJECT_REQUEST || meetingOperationType === MEETING_OPERATION_TYPE.reject) {
            validationText = t('validation.meetingReject');
            inputLabel = t('profile.user.consultant.meetingCalendar.meetingDetail.rejectReason');
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (meeting) => (event) => {
        setSelectedMeeting(meeting);
        setAnchorEl(event.currentTarget);
    };

    const closeOrganizeWorkingHoursModal = useCallback(() => {
        setOpenOrganizeWorkingHoursModal(false);
    }, []);

    const open = !!anchorEl;
    const id = open ? 'simple-popover' : undefined;
    const getFilteredCalendar = () => {
        getConsultantFilteredCalender(keycloak, userUuid, {
            dayId: selectedDate,
            statuses: meetingRequestType,
        }).then((response) => {
            if (response && response.success && response.data) {
                const { data } = response;
                const meetings = [];
                if (data && data.timeSlots && data.timeSlots.length > 0) {
                    for (let i = 0; i < data.timeSlots.length; i++) {
                        if (!data.timeSlots[i].meetings || data.timeSlots[i].meetings.length < 1) break;
                        for (let j = 0; j < data.timeSlots[i].meetings.length; j++) {
                            meetings.push(data.timeSlots[i].meetings[j]);
                        }
                    }
                }
                setFilteredMeetings(meetings);
            }
        });
        setShowMeetingApproveLimitWarning(false);
    };

    const handleClickVariant = (variant, text) => {
        enqueueSnackbar(text, { variant });
    };

    const sendMessage = (tKey, info) => {
        const meetingAnswerReceiver = selectedMeeting.meetingRequestOwner.userName;
        const message = t(tKey, { info });

        dispatch(sendChatMessage(meetingAnswerSender, meetingAnswerReceiver, message));
    };

    const sendRequestMessage = (tKey, callback) => {
        const date = selectedMeeting.meetingDateTimeString;
        const timeSlot = selectedMeeting.meetingTimeString;
        const startupName = selectedMeeting.meetingStartup.name;
        const meetingAnswerReceiver = selectedMeeting.meetingRequestOwner.userName;

        const message = t(tKey, { date, timeSlot, startupName });

        dispatch(sendChatMessage(meetingAnswerSender, meetingAnswerReceiver, message, callback));
    };

    // const sendApproveMessage = (callback) =>
    //     sendRequestMessage('profile.consultants.meetingRequest.message.acceptRequest', callback);
    // const sendZoomLink = (link) => sendMessage('profile.consultants.meetingRequest.message.zoomLink', link);
    // const sendRejectMessage = (callback) =>
    //     sendRequestMessage('profile.consultants.meetingRequest.message.rejectRequest', callback);
    // const sendRejectReason = (reason) => sendMessage('profile.consultants.meetingRequest.message.rejectReason', reason);
    // const sendCancelMessage = (callback) =>
    //     sendRequestMessage('profile.consultants.meetingRequest.message.cancelRequest', callback);
    // const sendCancelReason = (reason) => sendMessage('profile.consultants.meetingRequest.message.cancelReason', reason);

    const meetingOperation = (cancelOrRejectSubject) => {
        dispatch(setActiveRequest(true));
        if (meetingOperationType === MEETING_OPERATION_TYPE.readDetailAndAccept) {
            consultantApproveMeetingRequest(keycloak, selectedMeeting.id, { meetingId: selectedMeeting.id })
                .then((data) => {
                    dispatch(setActiveRequest(false));
                    if (data && data.success) {
                        handleClickVariant(
                            'success',
                            t('profile.user.consultant.meetingCalendar.meetingDetail.success.approved')
                        );
                        setOpenMeetingOperationModal(false);
                        getFilteredCalendar();
                        // sendApproveMessage(() => sendZoomLink(data.data.location));
                    }
                })
                .catch((error) => {
                    if (error.data === MEETING_APPROVE_LIMIT_EXCEPTION) {
                        setShowMeetingApproveLimitWarning(true);
                    }
                    dispatch(setActiveRequest(false));
                    handleClickVariant('error', error.message);
                });
        } else if (isMeetingOperationTypeRejectOrCancel) {
            if (!cancelOrRejectSubject || !cancelOrRejectSubject.val || !cancelOrRejectSubject.validated) {
                dispatch(setActiveRequest(false));
                setValidatedAfterFormSubmit(false);
                handleClickVariant(
                    'error',
                    meetingOperationType === MEETING_OPERATION_TYPE.reject
                        ? t('validation.meetingReject')
                        : t('validation.meetingCancel')
                );
                return;
            }
            if (meetingOperationType === MEETING_OPERATION_TYPE.reject) {
                consultantRejectMeetingRequest(keycloak, selectedMeeting.id, {
                    subject: cancelOrRejectSubject.val,
                })
                    .then((data) => {
                        dispatch(setActiveRequest(false));
                        if (data && data.success) {
                            setOpenMeetingOperationModal(false);

                            handleClickVariant(
                                'success',
                                t('profile.user.consultant.meetingCalendar.meetingDetail.success.rejected')
                            );
                            getFilteredCalendar();
                            // sendRejectMessage(() => sendRejectReason(data.data.resultText));
                        }
                    })
                    .catch(() => {
                        dispatch(setActiveRequest(false));
                        handleClickVariant(
                            'error',
                            t('profile.user.consultant.meetingCalendar.meetingDetail.error.rejected')
                        );
                    });
            } else if (meetingOperationType === MEETING_OPERATION_TYPE.cancel) {
                consultantCancelMeetingRequest(keycloak, selectedMeeting.id, {
                    subject: cancelOrRejectSubject.val,
                })
                    .then((data) => {
                        dispatch(setActiveRequest(false));
                        if (data) {
                            setOpenMeetingOperationModal(false);
                            handleClickVariant(
                                'success',
                                t('profile.user.consultant.meetingCalendar.meetingDetail.success.cancelled')
                            );
                            getFilteredCalendar();
                            // sendCancelMessage(() => sendCancelReason(data.data.resultText));
                        }
                    })
                    .catch(() => {
                        dispatch(setActiveRequest(false));
                        handleClickVariant(
                            'error',
                            t('profile.user.consultant.meetingCalendar.meetingDetail.error.cancelled')
                        );
                    });
            }
        }
    };
    const actionButtonComponent = (cancelOrRejectSubject) => {
        if (selectedMeeting.status.type === WAITING) {
            return meetingOperationType === MEETING_OPERATION_TYPE.readDetailAndAccept ? (
                <OperationButton
                    icon={<IconsSingleCheckWhite />}
                    text={t('profile.user.consultant.meetingCalendar.meetingDetail.accept')}
                    onClick={() => meetingOperation(cancelOrRejectSubject)}
                    disabled={hasActiveRequest}
                />
            ) : (
                <OperationButton
                    icon={<IconsSingleCloseWhite />}
                    text={t('profile.user.consultant.meetingCalendar.meetingDetail.reject')}
                    onClick={() => meetingOperation(cancelOrRejectSubject)}
                    disabled={hasActiveRequest}
                />
            );
        }
        if (selectedMeeting.status.type === APPROVE_REQUEST && meetingOperationType === MEETING_OPERATION_TYPE.cancel) {
            return (
                <OperationButton
                    icon={<IconsSingleCloseWhite />}
                    text={t('profile.user.consultant.meetingCalendar.meetingDetail.cancel')}
                    onClick={() => meetingOperation(cancelOrRejectSubject)}
                    disabled={hasActiveRequest}
                />
            );
        }
    };
    useEffect(() => {
        getConsultantCalendarWorkDays(keycloak, userUuid).then((data) => {
            if (data && data.data && data.success) {
                setMeetingDates(data.data);
                setSelectedDate(data.data[0].id);
            }
        });
    }, []);

    useEffect(() => {
        getFilteredCalendar();
    }, [selectedDate, meetingRequestType]);

    useEffect(() => {
        if (meetingRequestType === WAITING) {
            setPopoverReadDetailsText(t('profile.user.consultant.meetingCalendar.readDetailsAndConfirm'));
            setPopoverRejectOrCancelText(t('profile.user.consultant.meetingCalendar.reject'));
            return;
        } else if (meetingRequestType === APPROVE_REQUEST)
            setPopoverRejectOrCancelText(t('profile.user.consultant.meetingCalendar.cancel'));
        setPopoverReadDetailsText(t('profile.user.consultant.meetingCalendar.readDetails'));
    }, [meetingRequestType]);

    const handleSetSelectedDate = (value) => {
        setSelectedDate(value.id);
    };

    const handleSetMeetingRequestType = (value) => {
        setMeetingRequestType(value.id);
    };

    const selectedDateObject = meetingDates.find((m) => m.id === selectedDate);
    const meetingRequestTypeObject = meetingRequestTypes.find((m) => m.id === meetingRequestType);

    return (
        <>
            <div>
                <div className={classes.heading}>{t('profile.user.consultant.meetingCalendar.title')}</div>
                <Grid container>
                    <Grid
                        container
                        alignItems="center"
                        className={classes.organizeWorkingHoursArea}
                        onClick={() => setOpenOrganizeWorkingHoursModal(true)}
                    >
                        <Grid item>
                            <IconsSingleDate />
                        </Grid>
                        <Grid item className={classes.organizeWorkingHoursText}>
                            <div>{t('profile.user.consultant.meetingCalendar.organizeWorkingHours.title')}</div>
                        </Grid>
                    </Grid>
                </Grid>
                {openOrganizeWorkingHoursModal && (
                    <ModalOrganizeWorkingHours
                        open={openOrganizeWorkingHoursModal}
                        onClose={closeOrganizeWorkingHoursModal}
                        meetingDates={meetingDates}
                    />
                )}
            </div>

            <Grid container className={classes.selectDateAndRequestType}>
                <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <SingleSelect
                            label={t('profile.user.consultant.meetingCalendar.date')}
                            dontValidate={true}
                            canBeEmpty={false}
                            options={meetingDates}
                            initValue={selectedDateObject}
                            onChange={handleSetSelectedDate}
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <SingleSelect
                            label={t('profile.user.consultant.meetingCalendar.requestType.title')}
                            canBeEmpty={false}
                            options={meetingRequestTypes}
                            initValue={meetingRequestTypeObject}
                            onChange={handleSetMeetingRequestType}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            {showMeetingApproveLimitWarning && (
                <Grid container className={classes.warningArea} alignItems="center">
                    <Grid item>
                        <IconsAlertError color="primary" />
                    </Grid>
                    <Grid item className={classes.warningDetail}>
                        <Grid container>
                            <Grid item className={classes.warningText}>
                                {t('profile.user.consultant.meetingCalendar.approveLimitExceed')}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <div className={classes.tableContainer}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th>
                                <span>#</span> <span>{t('profile.user.consultant.meetingCalendar.time')}</span>
                            </th>
                            <th>{t('profile.user.consultant.meetingCalendar.requestOwner')}</th>
                            <th>{t('profile.user.consultant.meetingCalendar.meetingStartup')}</th>
                        </tr>
                    </thead>
                    <tbody className={classes.tbody}>
                        {filteredMeetings.map((meeting) => (
                            <tr key={meeting.id}>
                                <td className={classes.time}>
                                    <span>
                                        <IconsSingleEllipsis2 onClick={handleClick(meeting)} />
                                    </span>{' '}
                                    <span>{meeting.meetingTimeString}</span>
                                </td>
                                <td className={classes.requirer}>
                                    <div>
                                        <Avatar
                                            className={classes.avatar}
                                            src={
                                                meeting.meetingRequestOwner &&
                                                meeting.meetingRequestOwner.profilePictureFile &&
                                                meeting.meetingRequestOwner.profilePictureFile.uuid
                                                    ? getPublicFileUrl(
                                                          meeting.meetingRequestOwner.profilePictureFile.uuid
                                                      )
                                                    : ''
                                            }
                                        />
                                        <span>
                                            {meeting.meetingRequestOwner.name} {meeting.meetingRequestOwner.surname}
                                        </span>
                                    </div>
                                </td>
                                <td className={classes.attemptName}>{meeting.meetingStartup.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.popoverContent}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <IconsSingleArrow />
                            </Grid>
                            <Grid
                                item
                                className={classes.accept}
                                onClick={() => {
                                    setOpenMeetingOperationModal(true);
                                    setMeetingOperationType(MEETING_OPERATION_TYPE.readDetailAndAccept);
                                    handleClose();
                                }}
                            >
                                {popoverReadDetailsText}
                            </Grid>
                        </Grid>
                        {(meetingRequestType === WAITING || meetingRequestType === APPROVE_REQUEST) && (
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <IconsSingleClose2 />
                                </Grid>
                                <Grid
                                    item
                                    className={classes.reject}
                                    onClick={() => {
                                        if (meetingRequestType === APPROVE_REQUEST) {
                                            setMeetingOperationType(MEETING_OPERATION_TYPE.cancel);
                                        } else setMeetingOperationType(MEETING_OPERATION_TYPE.reject);

                                        setValidatedAfterFormSubmit(true);
                                        setOpenMeetingOperationModal(true);
                                        handleClose();
                                    }}
                                >
                                    {popoverRejectOrCancelText}
                                </Grid>
                            </Grid>
                        )}
                        {selectedMeeting && selectedMeeting.location && meetingRequestType === APPROVE_REQUEST && (
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <IconsSingleArrow />
                                </Grid>
                                <Grid
                                    item
                                    onClick={() => {
                                        window.open(selectedMeeting.location, '_blank');
                                        handleClose();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    className={classes.accept}
                                >
                                    {t('profile.user.consultant.meetingCalendar.goToMeeting')}
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </Popover>
                {selectedMeeting && (
                    <ModalMeetingOperation
                        open={openMeetingOperationModal}
                        setOpen={setOpenMeetingOperationModal}
                        selectedMeeting={selectedMeeting}
                        biggerHeight={
                            isMeetingOperationTypeRejectOrCancel ||
                            isMeetingStatusRejectedOrCanceledWhenClickedReadDetail
                        }
                        modalHeading={modalHeading}
                        actionButtonComponent={actionButtonComponent}
                        showCancelOrRejectReason={
                            meetingOperationType === MEETING_OPERATION_TYPE.readDetailAndAccept &&
                            (selectedMeeting.status.type === REJECT_REQUEST ||
                                selectedMeeting.status.type === CANCEL_REQUEST_BY_USER ||
                                selectedMeeting.status.type === CANCEL_REQUEST_BY_CONSULTANT)
                        }
                        enableCancelOrRejectInput={isMeetingOperationTypeRejectOrCancel}
                        validationText={validationText}
                        inputLabel={inputLabel}
                        validatedAfterFormSubmit={validatedAfterFormSubmit}
                        borderedSubject={
                            (selectedMeeting.status.type === WAITING ||
                                selectedMeeting.status.type === APPROVE_REQUEST) &&
                            meetingOperationType === MEETING_OPERATION_TYPE.readDetailAndAccept
                        }
                    />
                )}
            </div>
        </>
    );
}

export { ConsultantCalendar };
