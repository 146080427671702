import { useEffect } from 'react';
import { isNil } from 'lodash';

const useTranslationClick = (elementId, onClick) => {
    useEffect(() => {
        const element = document.getElementById(elementId);

        if (!isNil(element)) {
            element.addEventListener('click', onClick);
        }

        // remove event listener
        return () => {
            if (!isNil(element)) {
                element.removeEventListener('click', onClick);
            }
        };
    });
};

export default useTranslationClick;
