import { makeStyles } from '@material-ui/core';
import {
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_BASE,
    COLOR_THEME_FILL_01,
    poppins600_24px,
    poppins500_14px,
    COLOR_PRIMARY_BUTTON_HOVERED,
    COLOR_PRIMARY_SHADE_03,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    button: {
        minHeight: '48px',
        textTransform: 'capitalize',
        borderRadius: '8px',
        paddingLeft: '22px',
        paddingRight: '22px',
    },
    acceptButton: {
        backgroundColor: COLOR_PRIMARY_BASE,
        color: COLOR_THEME_FILL_01,
        marginLeft: '16px',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
        }
    },
    closeButton: {
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        color: COLOR_SECONDARY_BASE,
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        }
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        borderRadius: '6px',
        boxShadow: theme.shadows[5],
        padding: '24px 8% 32px 8%',
        '&  p:first-of-type': {
            ...poppins600_24px,
            margin: '19px 0 0 0',
        },
        '&  p:last-of-type': {
            ...poppins500_14px,
            textAlign: 'center',
            margin: '16px 0 100px 0',
        },
    },
    icon: {
        fontSize: '48px',
        color: COLOR_PRIMARY_BASE,
    }
}));

export default useStyles;
