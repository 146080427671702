import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import PageTemplate from 'fintech/components/PageTemplate';
import TabSwitch from 'fintech/components/ui/navigation/TabSwitch';
import Applications from 'fintech/components/membership/Applications/Applications';
import { ApplicationTypes } from 'fintech/components/membership/ApplicationTypes';
import Invites from 'fintech/components/membership/Invites/Invites';

const MembershipManagement = () => {
    const { t } = useTranslation('membershipManagement');

    const filterTypes = [
        { id: 1, name: t('filterTypes.all.label'), value: t('filterTypes.all.value'), selected: true },
        {
            id: 2,
            name: t('filterTypes.onlyStartups.label'),
            value: t('filterTypes.onlyStartups.value'),
            selected: false,
        },
        { id: 3, name: t('filterTypes.onlyMentors.label'), value: t('filterTypes.onlyMentors.value'), selected: false },
        {
            id: 4,
            name: t('filterTypes.onlyInvestors.label'),
            value: t('filterTypes.onlyInvestors.value'),
            selected: false,
        },
        { id: 5, name: t('filterTypes.onlyUsers.label'), value: t('filterTypes.onlyUsers.value'), selected: false },
    ];

    const applicationsTabs = [
        {
            header: { label: t('tabs.pending.label') },
            component: <Applications status={ApplicationTypes.PENDING} filterTypes={filterTypes} />,
            errorMsg: t('tabs.pending.errorMessage'),
        },
        {
            header: { label: t('tabs.resulted.label') },
            component: <Applications status={ApplicationTypes.ACCEPTED} filterTypes={filterTypes} />,
            errorMsg: t('tabs.resulted.errorMessage'),
        },
        {
            header: { label: t('tabs.invites.label') },
            component: <Invites />,
            errorMsg: 'No data available',
        },
        // TODO: Add application tabs
    ];
    return (
        <PageTemplate>
            <PageHeaderBox />
            <TabSwitch tabs={applicationsTabs} isSearchNavTabs={true} />
        </PageTemplate>
    );
};

export default MembershipManagement;
