import React, { useEffect, useState, useRef } from 'react';
import { APIS } from 'fintech/constants';
import { getRequest } from 'fintech/api/api';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadingSpinnerWithTextGeneralTextStyle, mobileMediaQuery } from 'fintech/components/style/common';
import { Typography, useMediaQuery, Button } from '@material-ui/core';
import * as constants from 'fintech/components/util/ApplicationFormConstants';
import PersonForm from 'fintech/components/applications/form/common/PersonForm';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import { SupportProgramApplicationActions } from 'fintech/store/actions/ActionTypes';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import useStyles from 'fintech/components/support/program/form/SupportProgramApplication/index.style';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCH_API_KEY_INVIS } from 'fintech/constants/auth';
import { getLocale } from 'fintech/utils/LanguageUtils';
import { useSnackbar } from 'notistack';
import {
    CATEGORY_REPRESANT_INFO,
    generateRepInfoData,
} from 'fintech/components/support/program/utils/SupportProgramApplicationUtils';
import * as FintechBeService from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const RepresentativeInformation = ({ pageFunctions: { nextPage }, secure, formId }) => {
    const { t } = useTranslation('supportProgramApplication');
    const { keycloak } = useKeycloak();
    const [countries, setCountries] = useState({
        data: [],
        isLoading: true,
        isError: false,
    });
    const [genders, setGenders] = useState({
        data: [],
        isLoading: true,
        isError: false,
    });
    const [educationLevels, setEducationLevels] = useState({
        data: [],
        isLoading: true,
        isError: false,
    });
    const matches = useMediaQuery(mobileMediaQuery);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [sending, setSending] = useState(false);

    // ReCaptcha - Start
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

    const recaptchaRef = useRef();

    const asyncOnLoad = () => {
        setRecaptchaLoaded(true);
    };

    // ReCaptcha - End

    const dispatch = useDispatch();
    const { reCatpchaVerified, supportApplicantInfo, hasActiveRequest } = useSelector((state) => {
        return {
            reCatpchaVerified: state.supportProgramApplication.reCatpchaVerified,
            supportApplicantInfo: state.supportProgramApplication.form.supportApplicantInfo,
            hasActiveRequest: state.common.hasActiveRequest,
        };
    });

    useEffect(() => {
        getRequest(APIS.USER.getCountriesURL())
            .then(({ data }) => {
                setCountries((prevState) => ({
                    ...prevState,
                    data,
                    isLoading: false,
                }));
            })
            .catch(() => {
                setCountries((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isError: true,
                }));
            });
        getRequest(APIS.USER.getGendersURL())
            .then(({ data }) => {
                setGenders((prevState) => ({
                    ...prevState,
                    data,
                    isLoading: false,
                }));
            })
            .catch(() => {
                setGenders((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isError: true,
                }));
            });
        getRequest(APIS.SUPPORT_PROGRAM.getEducationInfos())
            .then(({ data }) => {
                setEducationLevels((prevState) => ({
                    ...prevState,
                    data,
                    isLoading: false,
                }));
            })
            .catch(() => {
                setEducationLevels((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    isError: true,
                }));
            });
    }, []);

    useEffect(() => {
        supportApplicantInfo.isError && enqueueSnackbar(t('idea.updateError'), { variant: 'error' });
    }, [supportApplicantInfo.isError]);

    const changeCallback = ({ type, data }) => {
        switch (type) {
            case constants.PERSON_FORM_NAME_UPDATE:
                dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_NAME, data });
                break;
            case constants.PERSON_FORM_SURNAME_UPDATE:
                dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_LASTNAME, data });
                break;
            case constants.PERSON_FORM_COUNTRY_UPDATE:
                dispatch({
                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_NATIONALITY,
                    data,
                });
                break;
            case constants.PERSON_FORM_IDENTITY_UPDATE:
                dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_IDENTITY, data });
                break;
            case constants.PERSON_FORM_GENDER_UPDATE:
                dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_GENDER, data });
                break;
            case constants.PERSON_FORM_BIRTHDATE_UPDATE:
                dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_BIRTHDATE, data });
                break;
            case constants.PERSON_FORM_EMAIL_UPDATE:
                dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_EMAIL, data });
                break;
            case constants.PERSON_FORM_PHONE_NUMBER_UPDATE:
                if (data.value)
                    dispatch({
                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_PHONENUMBER,
                        data,
                    });
                break;
            case constants.PERSON_FORM_EXPERIENCE_UPDATE:
                dispatch({
                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_WORK_EXPERIENCE,
                    data,
                });
                break;
            case constants.PERSON_FORM_JOB_TITLE_UPDATE:
                dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_WORK_TITLE, data });
                break;
            case constants.PERSON_FORM_EDUCATION_UPDATE:
                dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_EDUCATION, data });
                break;
        }
    };

    const isValid = () => {
        return (
            supportApplicantInfo?.name?.validated &&
            supportApplicantInfo?.lastName?.validated &&
            supportApplicantInfo?.identity?.validated &&
            supportApplicantInfo?.gender?.validated &&
            supportApplicantInfo?.birthDate?.validated &&
            supportApplicantInfo?.email?.validated &&
            supportApplicantInfo?.phoneNumber?.validated &&
            supportApplicantInfo?.workExperienceInYears?.validated &&
            supportApplicantInfo?.title?.validated &&
            supportApplicantInfo?.educationInfo?.validated
        );
    };

    const showError = (error) => {
        dispatch(setActiveRequest(false));
        enqueueSnackbar(error?.message || t('idea.updateError'), { variant: 'error' });
    };

    const clickContinueHandler = async () => {
        setSending(true);


        if (isValid()) {
            dispatch(setActiveRequest(true));
            const requestData = {};
            requestData.supportApplicantInfo = generateRepInfoData(supportApplicantInfo);

            let res = null;
            try {
                res = await FintechBeService.supportProgramApply({
                    keycloak,
                    formId,
                    requestData,
                    category: CATEGORY_REPRESANT_INFO,
                    isPreRecaptcha: true,
                });
            } catch (reason) {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            if (!res || !res.success) {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            if (!reCatpchaVerified) {
                // Trigger captcha
                try {
                    const token = await recaptchaRef?.current?.executeAsync();
                    dispatch(setActiveRequest(false));
                    if (!token) {
                        enqueueSnackbar(t('validation.reCaptcha'), { variant: 'error' });
                        return;
                    }
                    dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION_RECAPTCHA });
                } catch (e) {
                    dispatch(setActiveRequest(false));
                    enqueueSnackbar(t('validation.reCaptcha'), { variant: 'error' });
                    return;
                }
            }

            FintechBeService.supportProgramApply({
                keycloak,
                formId,
                requestData,
                category: CATEGORY_REPRESANT_INFO,
            })
                .then((res) => {
                    if (!res || !res.success) {
                        showError(res);
                    } else {
                        nextPage();
                    }
                })
                .catch((reason) => {
                    showError(reason);
                });
        }
    };

    const isLoading = countries?.isLoading || genders.isLoading || educationLevels.isLoading;

    return isLoading ? (
        <LoadingSpinnerWithText style={loadingSpinnerWithTextGeneralTextStyle} />
    ) : (
        <CfoFormContainer>
            {matches && (
                <Typography className={classes.pageTitle}>{t('pageTitles.representativeInformation')}</Typography>
            )}
            <PersonForm
                sending={sending}
                selectOptions={{ countries, genders, educationLevels }}
                changeCallback={changeCallback}
                initFormData={{
                    name: supportApplicantInfo?.name,
                    surname: supportApplicantInfo?.lastName,
                    idNumber: supportApplicantInfo?.identity,
                    gender: supportApplicantInfo?.gender,
                    birthDate: supportApplicantInfo?.birthDate,
                    email: supportApplicantInfo?.email,
                    phoneNumber: supportApplicantInfo?.phoneNumber,
                    experience: supportApplicantInfo?.workExperienceInYears,
                    jobTitle: supportApplicantInfo?.title,
                    education: supportApplicantInfo?.educationInfo,
                }}
                nameDisabled={secure}
                surnameDisabled={secure}
                idNumberDisabled={secure}
                emailDisabled={secure}
                genderDisabled={secure}
                birthDateDisabled={secure}
                phoneNumberDisabled={secure}
                experienceDisabled={secure}
                educationDisabled={secure}
                refresh
                trackForRefresh={supportApplicantInfo.isLoading}
            />
            <CfoFormInput xs={12} lg={12} style={matches ? { padding: '12px' } : {}}>
                <Button
                    className={classes.primaryButton}
                    onClick={clickContinueHandler}
                    disabled={
                        hasActiveRequest ||
                        !recaptchaLoaded ||
                        (secure && supportApplicantInfo.isError) ||
                        countries.isError ||
                        genders.isError ||
                        educationLevels.isError
                    }
                    fullWidth
                    variant="contained"
                >
                    {!hasActiveRequest ? t('buttonTexts.continue') : <ButtonCircularIndicator />}
                </Button>
            </CfoFormInput>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={CAPTCH_API_KEY_INVIS}
                asyncScriptOnLoad={asyncOnLoad}
                hl={getLocale()}
                onChange={() => recaptchaRef?.current?.reset()}
            />
        </CfoFormContainer>
    );
};

export default RepresentativeInformation;
