import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconsSingleCloseRounded from 'fintech/icons/IconsSingleCloseRounded';
import EscKeyListener from 'fintech/components/util/EscKeyListener';

export default function InformationModal(props) {
    const { title, subTitle, open, handleAction } = props;

    const handleCloseClick = () => {
        handleAction({ type: 'close' });
    };

    return (
        <div className={'actionModalContainer'}>
            {open && <EscKeyListener onEsc={handleCloseClick} />}
            <Dialog open={open} aria-labelledby="form-dialog-title" className={'informationModal'}>
                <div
                    className={'d-flex align-items-end justify-content-end dialogCloseButton'}
                    onClick={handleCloseClick}
                >
                    <IconsSingleCloseRounded />
                </div>
                <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
                    <Grid item xs={12}>
                        <DialogTitle id="form-dialog-title" className={'d-flex information-dialog-title'}>
                            {title}
                        </DialogTitle>
                        <div className={'information-dialog-middle-info'}>{subTitle}</div>
                    </Grid>
                </Grid>
                {props.children}
            </Dialog>
        </div>
    );
}
