import { isNil } from 'lodash';
import { getSystemLanguage } from './LanguageUtils';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';

export const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/);

    if (match) {
        return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
    }

    return null;
};

export const formatMonetaryValue = (num, cur) => {
    const str = num.toString();
    return new Intl.NumberFormat(getSystemLanguage(), { style: 'currency', currency: cur })
        .format(str)
        .replace(/\D00(?=\D*$)/, '')
        .replace('TRY', '₺');
};

export const getCurrencySign = (cur) => {
    return (
        new Intl.NumberFormat(getSystemLanguage(), { style: 'currency', currency: cur })
            .format('0')
            // TODO: Better regex
            .replace(/\D00(?=\D*$)/, '')
            .replace(' ', '')
            .replace(0, '')
            .replace('TRY', '₺')
    );
};

export const formatNumericValue = (num) => {
    const str = num ? num.toString() : null;
    return new Intl.NumberFormat(getSystemLanguage()).format(str);
};

export const isMailAddress = (mailString) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(mailString).toLowerCase());
};

export const isUrl = (urlString, checkIfCompleteUrl = false) => {
    if (!urlString) {
        return false;
    }

    if (checkIfCompleteUrl) {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
            urlString
        );
    }

    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

    const regex = new RegExp(expression);

    return regex.test(urlString);
};

// Ref: https://www.yusufsezer.com.tr/javascript-tc-kimlik-no-dogrulama/
export const isValidTckn = (tckn) => {
    if (isNil(tckn) || tckn.length != 11 || tckn[0] == 0) {
        return false;
    }

    let odd = 0,
        even = 0,
        result = 0,
        sum = 0;

    odd = parseInt(tckn[0]) + parseInt(tckn[2]) + parseInt(tckn[4]) + parseInt(tckn[6]) + parseInt(tckn[8]);
    even = parseInt(tckn[1]) + parseInt(tckn[3]) + parseInt(tckn[5]) + parseInt(tckn[7]);

    odd = odd * 7;
    result = Math.abs(odd - even);
    if (result % 10 != tckn[9]) return false;

    for (let i = 0; i < 10; i++) {
        sum += parseInt(tckn[i]);
    }

    if (sum % 10 != tckn[10]) return false;

    return true;
};

// Ref: https://gist.github.com/ziyahan/3938729
export const isValidVkn = (vkn) => {
    if (isNil(vkn) || vkn.length != 10) {
        return false;
    }

    let v = [];
    let lastDigit = Number(vkn.charAt(9));
    for (let i = 0; i < 9; i++) {
        let tmp = (Number(vkn.charAt(i)) + (9 - i)) % 10;
        v[i] = (tmp * 2 ** (9 - i)) % 9;
        if (tmp !== 0 && v[i] === 0) v[i] = 9;
    }
    let sum = v.reduce((a, b) => a + b, 0) % 10;
    return (10 - (sum % 10)) % 10 === lastDigit;
};

export const getWebsiteUrl = (website) => {
    if (website) {
        if (website.startsWith('https://') || website.startsWith('http://')) {
            return website;
        } else {
            return 'http://'.concat(website);
        }
    }
};

/**
 *
 * @param {String} string
 * @param {Number} number
 * @returns {String}
 */
export const appendThreeDotIfExceedsGivenSize = (value, size = 16) => {
    return value.length >= size ? value.substring(0, size) + '...' : value;
};

export const getPlaintextFromHtml = (htmlStr) => {
    if (isNil(htmlStr)) {
        return '';
    }

    const blocksFromHTML = convertFromHTML(htmlStr);
    const content = convertToRaw(
        ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
    );
    let resultStr = '';
    content?.blocks?.forEach(function (item) {
        resultStr = resultStr.concat(`${item?.text} `);
    });

    return resultStr;
};

// http://stackoverflow.com/a/1026087/181295
// REF: https://gist.github.com/anova/6902147
String.prototype.turkishUpperCase = function () {
    return this.replace(/ğ/g, 'Ğ')
        .replace(/ü/g, 'Ü')
        .replace(/ş/g, 'Ş')
        .replace(/ı/g, 'I')
        .replace(/i/g, 'İ')
        .replace(/ö/g, 'Ö')
        .replace(/ç/g, 'Ç')
        .toUpperCase();
}; // String.turkishUpperCase

String.prototype.turkishLowerCase = function () {
    return this.replace(/Ğ/g, 'ğ')
        .replace(/Ü/g, 'ü')
        .replace(/Ş/g, 'ş')
        .replace(/I/g, 'ı')
        .replace(/İ/g, 'i')
        .replace(/Ö/g, 'ö')
        .replace(/Ç/g, 'ç')
        .toLowerCase();
}; //String.turkishLowerCase

String.prototype.turkishCapitalize = function () {
    return this.charAt(0).turkishUpperCase() + this.slice(1).turkishLowerCase();
}; //String.turkishCapitalize

String.prototype.turkishCapitalizeWords = function () {
    var a = this.split(' ');
    for (var i = 0; i < a.length; i++) a[i] = a[i].turkishCapitalize();
    return a.join(' ');
}; //String.turkishCapitalizeWords

export const capitalizeTurkishCharacter = (string) => {
    if (string) {
        return string.turkishCapitalizeWords();
    }

    return '';
};
