import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Grid, Typography } from '@material-ui/core';
import useLogoStyles from 'fintech/components/profile/user/VenturesGrid/Venture/Logo/index.style';
import PlaceholdersInvestor from 'fintech/static/images/placeholders-yatirimda-bulunan.png';
import { HideFieldIcon, InfoIcon } from 'fintech/components/profile/common';
import useStyles from './index.style';
import PopperWrapper from 'fintech/components/util/PopperWrapper';
import { maskedText } from 'fintech/constants';

const InvestmentLogoGrid = (props) => {
    const classes = useStyles();
    const logoClasses = useLogoStyles();
    const { t } = useTranslation();
    const { breakPoints = {}, logo, companyTitle, companyIsPublic, showCompany } = props;

    const maskedJSX = (
        <Typography className={classes.CompanyTitleTypo}>
            {maskedText} <InfoIcon fontSize="small" />
        </Typography>
    );

    const logoJSX =
        logo && showCompany ? (
            <Avatar className={logoClasses.MemberLogoMask} src={logo.url} />
        ) : (
            <Avatar className={logoClasses.MemberLogoMask} src={PlaceholdersInvestor} />
        );

    let companyJSX = null;
    if (showCompany) {
        companyJSX = (
            <Typography className={classes.CompanyTitleTypo}>
                {companyTitle} {` `}
                {!companyIsPublic && <HideFieldIcon />}
            </Typography>
        );
    } else {
        companyJSX = maskedJSX;
    }

    return (
        <Grid item {...breakPoints}>
            <Grid container direction="row" justify="flex-start" alignItems="center">
                <Grid item className={logoClasses.LogoGrid}>
                    {logoJSX}
                </Grid>
                <Grid item>
                    <Grid
                        className={logoClasses.NameGrid}
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item>
                            <PopperWrapper
                                showPopper={!showCompany}
                                content={t('profile.financials.investors.hiddenCompanyMessage')}
                            >
                                {companyJSX}
                            </PopperWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default InvestmentLogoGrid;
