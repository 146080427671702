import { makeStyles } from '@material-ui/core';
import { COLOR_THEME_FILL_01, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    sideImage: {
        marginTop: (barHeight) => (barHeight ? barHeight + 8 : 0),
    },
    formGrid: {
        marginTop: (barHeight) => (barHeight ? barHeight + 8 : 0),
        [theme.breakpoints.down('sm')]: {
            marginTop: `0 !important`,
        },
    },
    mobilePageIndicator: {
        margin: '0 8px',
        padding: '32px 46px 22px 26px',
        backgroundColor: COLOR_THEME_FILL_01,
        textAlign: 'center',
    },
    pageIndicatorTypo: {
        ...fontStyle,
        fontSize: '24px',
        fontWeight: 600,
    },
    contentWrapper: {
        backgroundColor: COLOR_THEME_FILL_01,
        margin: '0 8px 8px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '0 8px 8px 8px',
            paddingBottom: '60px',
        },
        minHeight: '682px',
    },
}));

export default useStyles;
