import React, { useState, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useKeycloak } from '@react-keycloak/web';
import DeleteButton from 'fintech/components/profile/startup/GeneralInfo/GeneralInfoGrid/Documents/DeleteButton/DeleteButton';
import useStyles from './index.style';
import SvgIconsSingleUpload from 'fintech/icons/IconsSingleUpload';
import * as constants from 'fintech/components/util/ActionModalConstants';
import ActionModal from 'fintech/components/util/ActionModal';
import MediaUpdateActionModal from 'fintech/components/profile/idea/actionmodal/informationcard/content/MediaUpdateActionModal';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import { InfoIcon } from 'fintech/components/profile/common';
import { useTranslation } from 'react-i18next';
import * as FileServerService from 'fintech/api/fileService';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveRequest } from 'fintech/store/actions/common';
import { getFileIconByMimeType } from 'fintech/utils/FileUtils';

const documentListFetchSize = 10000;
const documentListFetchPageNumber = 0;

const Documents = ({ uuid, editable, documentServices }) => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [openMediaModal, setOpenMediaModal] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState({});
    const [confirmModalContent, setConfirmModalContent] = useState('');
    const [totalPages, setTotalPages] = useState();
    const dispatch = useDispatch();
    const { hasActiveRequest } = useSelector((state) => state.common);

    const [page, setPage] = useState(1);
    const [size] = useState(4);
    let [contents, setContents] = useState([]);
    const [mediaInfos, setMediaInfos] = useState([]);

    const handleFileRequest = (id, name) => {
        FileServerService.downloadFile(id, name);
    };

    const deleteFile = () => {
        dispatch(setActiveRequest(true));
        documentServices
            .deleteDocuments(keycloak, uuid, [selectedDocument])
            .then((response) => {
                dispatch(setActiveRequest(false));
                if (response && response.success && response.data) {
                    enqueueSnackbar(t('startup.generalInfos.documents.delete.success'), {
                        variant: 'success',
                    });
                    fetchDocuments();
                    setOpenConfirmModal(false);
                } else {
                    enqueueSnackbar(t('startup.generalInfos.documents.delete.fail'), {
                        variant: 'error',
                    });
                }
            })
            .catch((error) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(error.message, {
                    variant: 'error',
                });
            });
    };

    const updateData = () => {
        dispatch(setActiveRequest(true));
        documentServices
            .updateDocuments(keycloak, uuid, mediaInfos)
            .then((response) => {
                dispatch(setActiveRequest(false));
                if (response.success && response.data) {
                    enqueueSnackbar(t('startup.generalInfos.documents.uploadNewFile.success'), {
                        variant: 'success',
                    });
                    fetchDocuments();
                }
            })
            .catch((error) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(error.message, {
                    variant: 'error',
                });
            });
    };

    const fetchDocuments = () => {
        documentServices
            .getDocuments(keycloak, uuid, documentListFetchPageNumber, documentListFetchSize)
            .then((response) => {
                if (response && response.success && response.data && response.data.content) {
                    setPage(1);
                    const newTotalPages = Math.ceil(response.data.totalElements / size);
                    setTotalPages(newTotalPages);
                    const newContents = [...response.data.content];
                    const featuredIndex = newContents.findIndex((x) => x.featured === true);
                    if (featuredIndex > -1) {
                        const featuredElement = newContents[featuredIndex];
                        newContents.splice(featuredIndex, 1);
                        newContents.unshift(featuredElement);
                    }
                    setContents(newContents);
                    setMediaInfos(response.data.content);
                }
            });
    };

    const uploadMediaHandler = (action) => {
        switch (action.type) {
            case constants.SUBMIT:
                updateData();
                setOpenMediaModal(false);
                return;
            case constants.REJECT:
                setOpenMediaModal(false);
                return;
            default:
                return;
        }
    };

    const handlePageChange = (event, clickedPageNumber) => {
        if (page === clickedPageNumber) return;
        setPage(clickedPageNumber);
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    const currentIndexStart = (page - 1) * size;
    const currentIndexEnd = page * size;

    return (
        <>
            <Paper className={classes.documents}>
                <Grid container justify="space-between" className={classes.documentHeader}>
                    <Grid item className={classes.title}>
                        {t('startup.generalInfos.documents.title')}
                    </Grid>
                    <Grid item>
                        {editable && (
                            <Grid container className={classes.uploadNew}>
                                <Grid item>
                                    <SvgIconsSingleUpload />
                                </Grid>
                                <Grid item onClick={() => setOpenMediaModal(true)}>
                                    {t('startup.generalInfos.documents.uploadNewFile.title')}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {contents.length < 1 ? (
                    <div className={classes.notFound}>{t('startup.generalInfos.documents.notFound')}</div>
                ) : (
                    <table className={classes.table}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t('startup.generalInfos.documents.table.file')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contents.slice(currentIndexStart, currentIndexEnd).map((content, index) => {
                                return (
                                    <tr key={content.file.uuid} className={classes.docItemContainer}>
                                        <td>{(page - 1) * size + index + 1}</td>
                                        <td>
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <Grid
                                                        container
                                                        className={classes.docName}
                                                        onClick={() =>
                                                            handleFileRequest(content.file.uuid, content.file.fileName)
                                                        }
                                                    >
                                                        <Grid item>{getFileIconByMimeType(content.file.mimeType)}</Grid>
                                                        <Grid item>{content.file.fileName}</Grid>
                                                    </Grid>
                                                </Grid>
                                                {editable && (
                                                    <Grid item>
                                                        <DeleteButton
                                                            onClick={() => {
                                                                setSelectedDocument(content);
                                                                setOpenConfirmModal(true);
                                                                setConfirmModalContent(
                                                                    t('startup.generalInfos.documents.delete.confirm', {
                                                                        fileName: content.file?.fileName || '',
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}

                <ConfirmDialog
                    open={openConfirmModal}
                    icon={<InfoIcon fontSize="large" />}
                    title={t('dialog.confirmTitle')}
                    injectHtml
                    content={confirmModalContent}
                    labelCancel={t('dialog.cancelAction')}
                    labelConfirm={t('dialog.confirmAction')}
                    onCancel={() => setOpenConfirmModal(false)}
                    onConfirm={deleteFile}
                    confirmDisabled={hasActiveRequest}
                    showSpinnerWhenDisabled={hasActiveRequest}
                />
                {totalPages > 0 && (
                    <Pagination
                        count={totalPages}
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        className={classes.pagination}
                        onChange={handlePageChange}
                    />
                )}
            </Paper>
            <ActionModal
                open={openMediaModal}
                title={t('startup.generalInfos.documents.uploadNewFile.title')}
                onAction={uploadMediaHandler}
                onClickDisabled={hasActiveRequest}
                cancelClickDisabled={hasActiveRequest}
                showSpinnerWhenDisabled={hasActiveRequest}
            >
                <MediaUpdateActionModal
                    areDocuments
                    fileLimit={documentListFetchSize}
                    initData={contents}
                    changeMediaCallback={(mediaInfos) => {
                        setMediaInfos(mediaInfos);
                    }}
                />
            </ActionModal>
        </>
    );
};

export default Documents;
