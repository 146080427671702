import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Box, CardMedia, InputBase, Typography, useMediaQuery } from '@material-ui/core';
import { makeSearchStyle } from 'fintech/pages/post-login/EntrepreneursPage/index.style';
import SearchPrefs from './SearchPrefs';
import { COLOR_PRIMARY_BASE, fontStyle, mobileMediaQuery } from 'fintech/components/style/common';

const BoxedSearchBar = (props) => {
    const styles = makeSearchStyle();
    const matches = useMediaQuery(mobileMediaQuery);
    const { type } = props;
    const { t } = useTranslation(type);
    const {
        labelHtml,
        preferencesText = t('searchbar.preferences'),
        placeholderText = t('searchbar.placeholder'),
        btnLabel = t('searchbar.search'),
        makeSearch,
        searchBoxOnChange,
        setSearchBoxFilterStatus,
        resetAllFilters,
        fetchGridData,
        toggleAdvancedFilter,
        backgroundImage,
        backgroundImageMobile,
        mirrorHorizontally = true,
        showEndAdornments = false,
    } = props;
    const [totalText, setTotalText] = useState(labelHtml);

    const { appliedFilters } = useSelector((state) => state[type]);
    const { input, filters, lastQueryParams } = useSelector((state) => state[type].searchBox);
    const dispatch = useDispatch();

    const getNumberOfAppliedFilters = () => {
        let count = 0;

        for (const properties in appliedFilters) {
            count += appliedFilters[properties].length;
        }

        return count;
    };
    const numberOfAppliedFilters = getNumberOfAppliedFilters();

    useEffect(() => {
        setTotalText(labelHtml);
    }, [labelHtml]);

    useEffect(() => {
        if (input === '' && lastQueryParams?.searchTerm && lastQueryParams.searchTerm !== '') {
            resetAllFilters();
            fetchGridData();
        }
    }, [input]);

    const handleSearch = () => {
        makeSearch(input, filters);
    };

    let adornmentJSX = null;
    if (showEndAdornments && matches) {
        const clearAdn = input.length > 0 && (
            <div
                style={{ cursor: 'pointer', paddingLeft: '10px' }}
                onClick={() => {
                    resetAllFilters();
                    fetchGridData();
                }}
            >
                <Typography style={{ ...fontStyle, color: COLOR_PRIMARY_BASE }}>{t('searchbar.clear')}</Typography>
            </div>
        );

        const filterAdn = (
            <div
                className={styles.searchBoxFilterAdn}
                onClick={() => toggleAdvancedFilter && dispatch(toggleAdvancedFilter())}
            >
                <Typography noWrap className={styles.searchBoxFilterTypo}>
                    {numberOfAppliedFilters === 0
                        ? t('dataGrid.filterRecords')
                        : t('dataGrid.appliedFilters', { count: numberOfAppliedFilters })}
                </Typography>
            </div>
        );

        adornmentJSX = (
            <div className={styles.searchBoxAdnWrapperDiv}>
                {clearAdn}
                {filterAdn}
            </div>
        );
    }

    return (
        <Box id="boxed-searchbar" className={styles.searchBarContainer}>
            <CardMedia
                className={styles.searchbarPlaceholder}
                style={mirrorHorizontally ? null : { transform: 'none' }}
                image={matches ? backgroundImage : backgroundImageMobile}
                alt=""
            />
            {totalText && (
                <p
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                        __html: totalText,
                    }}
                />
            )}
            <Box p={2} className={styles.searchArea}>
                <Box flexGrow={2}>
                    <SearchPrefs
                        type={type}
                        preferencesText={preferencesText}
                        setSearchBoxFilterStatus={setSearchBoxFilterStatus}
                    />
                </Box>
                <Box className="searchInput" flexGrow={7}>
                    <InputBase
                        style={{ width: '100%' }}
                        value={input}
                        onChange={(e) => dispatch(searchBoxOnChange(e.target.value))}
                        onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                        placeholder={placeholderText}
                        inputProps={{ 'aria-label': 'naked' }}
                        endAdornment={adornmentJSX}
                    />
                </Box>
                <Box className={styles.searchButtonContainer} flexGrow={1}>
                    <Button onClick={handleSearch} className={styles.searchButton}>
                        {btnLabel}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default BoxedSearchBar;
