import { makeStyles } from '@material-ui/core';
import { buttonStyle, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    contentRoot: {
        overflowY: 'auto',
        margin: 'auto',
        maxWidth: '460px',
    },
    imgWrapper: {
        padding: '0 48px 16px',
        marginBottom: '32px',
        width: '100%',
        height: '100%',
    },
    NotFoundImg: {
        width: '100%',
        height: '100%',
    },
    titleTypo: {
        ...fontStyle,
        textAlign: 'center',
        fontSize: '32px',
        padding: '0 8px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
        },
        marginBottom: '20px',
    },
    infoTypo: {
        ...fontStyle,
        textAlign: 'center',
        padding: '0 8px',
        marginBottom: '30px',
    },
    homeBtn: {
        ...buttonStyle,
        ...fontStyle,
        padding: '14px 22px',
    },
}));

export default useStyles;
