import * as React from 'react';

function SvgIconsMentorler(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        fill="#D9DCE0"
                                        d="M5 39.375L12.5 39.375 12.5 22.5 5 22.5z"
                                        transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                    />
                                    <g>
                                        <path
                                            fill="#D9DCE0"
                                            d="M15 24.375V10c0-1.38-1.12-2.5-2.5-2.5H5c-1.38 0-2.5 1.12-2.5 2.5v14.375H5V22.5h7.5v1.875H15z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#3F5162"
                                            d="M8.125 39.375L9.375 39.375 9.375 25.625 8.125 25.625zM8.125 11.25L9.375 11.25 9.375 10 8.125 10zM5 23.125L12.5 23.125 12.5 21.875 5 21.875z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#3F5162"
                                            d="M4.375 39.375L5.625 39.375 5.625 11.25 4.375 11.25zM11.875 39.375L13.125 39.375 13.125 11.25 11.875 11.25z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#FFF"
                                            d="M11.25 3.125c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#3F5162"
                                            d="M8.75 1.25c-1.034 0-1.875.841-1.875 1.875S7.716 5 8.75 5s1.875-.841 1.875-1.875S9.784 1.25 8.75 1.25m0 5c-1.723 0-3.125-1.402-3.125-3.125S7.027 0 8.75 0s3.125 1.402 3.125 3.125S10.473 6.25 8.75 6.25M0 40L17.5 40 17.5 38.75 0 38.75z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#D9DCE0"
                                            d="M22.5 35L37.5 35 37.5 25 22.5 25z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#3F5162"
                                            d="M37.5 30L22.5 30 22.5 25 23.75 25 23.75 28.75 36.25 28.75 36.25 25 37.5 25z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#3F5162"
                                            d="M25 31.25L26.25 31.25 26.25 29.375 25 29.375zM33.75 31.25L35 31.25 35 29.375 33.75 29.375zM33.75 25L32.5 25 32.5 23.75 27.5 23.75 27.5 25 26.25 25 26.25 22.5 33.75 22.5zM17.5 36.25L40 36.25 40 35 17.5 35z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#3F5162"
                                            d="M18.75 35.625L20 35.625 20 0 18.75 0z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#D9DCE0"
                                            d="M36.25 11.25L20 11.25 20 1.25 36.25 1.25 32.5 6.25z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#3F5162"
                                            d="M18.75 40L20 40 20 38.75 18.75 38.75zM15 36.25L16.25 36.25 16.25 35 15 35z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                        <path
                                            fill="#FFF"
                                            d="M25.625 8.384L23.308 6.067 24.192 5.183 25.625 6.616 28.308 3.933 29.192 4.817z"
                                            transform="translate(-903 -1644) translate(0 1579) translate(354 65) translate(406) translate(143)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMentorler;
