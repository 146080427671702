/* eslint-disable no-case-declarations */
import { InviteSearchActions } from 'fintech/store/actions/ActionTypes';
import { cloneDeep } from 'lodash';
import { GRID_HEADER_STATE } from 'fintech/store/actions/entrepreneur';

const initialState = {
    global: {
        headers: null,
        totalNumber: null,
        filteredNumber: null,
        lastQueryType: null,
        persist: false,
    },
    searchBox: {
        input: '',
        lastQueryParams: {},
    },
    grid: {
        rows: [], // Needs to be fetched from server
        isLoading: true,
        gridDataLoading: false,
        isError: false,
        pagination: {
            count: 0,
            pageSize: 15,
            defaultPage: 1,
            page: 1,
        },
    },
};

const inviteSearch = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        // Global
        case InviteSearchActions.SET_INVITE_SEARCH_GLOBAL_PERSIST_STATE:
            return {
                ...state,
                global: {
                    ...state.global,
                    persist: action.data,
                },
            };
        case InviteSearchActions.SET_INVITE_GLOBAL_TOTAL_ACTIVE_NUMBER:
            return {
                ...state,
                global: {
                    ...state.global,
                    totalNumber: action.data,
                },
            };
        case InviteSearchActions.SET_INVITE_GLOBAL_FILTERED_NUMBER:
            return {
                ...state,
                global: {
                    ...state.global,
                    filteredNumber: action.data,
                },
            };
        // SearchBox
        case InviteSearchActions.SET_INVITE_SEARCH_SEARCHBOX_LAST_QUERY:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    lastQueryParams: action.data,
                },
            };
        case InviteSearchActions.SET_INVITE_SEARCH_SEARCHBOX_INPUT:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    input: action.data,
                },
            };
        case InviteSearchActions.SET_INVITE_SEARCH_SEARCHBOX_FILTERSTATUS:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    filters: state.searchBox.filters.map((element) => {
                        return action.data.includes(element.label)
                            ? {
                                  ...element,
                                  status: true,
                              }
                            : {
                                  ...element,
                                  status: false,
                              };
                    }),
                },
            };
        case InviteSearchActions.RESET_INVITE_SEARCH_SEARCHBOX:
            return {
                ...state,
                searchBox: initialState.searchBox,
            };
        // Grid
        case InviteSearchActions.SET_INVITE_SEARCH_LAST_QUERY_TYPE:
            return {
                ...state,
                global: {
                    ...state.global,
                    lastQueryType: action.data,
                },
            };
        case InviteSearchActions.SET_INVITE_SEARCH_GRID_HEADERS:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    headers: action.data,
                },
            };
        case InviteSearchActions.SET_INVITE_SEARCH_GRID_DATA:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    rows: action.data,
                    isLoading: false,
                    gridDataLoading: false,
                },
            };
        case InviteSearchActions.SET_INVITE_SEARCH_GRID_LOADING_INITIAL:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    isLoading: true,
                    isError: false,
                },
            };
        case InviteSearchActions.SET_INVITE_SEARCH_GRID_LOADING:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    gridDataLoading: true,
                    isError: false,
                },
            };
        case InviteSearchActions.SET_INVITE_SEARCH_GRID_LOAD_ERROR:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    isLoading: false,
                    gridDataLoading: false,
                    isError: true,
                },
            };
        // Pagination
        case InviteSearchActions.SET_INVITE_SEARCH_GRID_PAGINATION:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    pagination: {
                        ...state.grid.pagination,
                        ...action.data,
                    },
                },
            };
        case InviteSearchActions.RESET_INVITE_SEARCH_GRID_PAGINATION:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    pagination: initialState.grid.pagination,
                },
            };
        case InviteSearchActions.TOGGLE_INVITE_SEARCH_GRID_STATE:
            let _headers = cloneDeep(state.grid.headers);
            _headers = _headers.map((el) =>
                el.type === 'sortLabel' && el.id !== action.data
                    ? {
                          ...el,
                          value: {
                              ...el.value,
                              status: GRID_HEADER_STATE.NONE,
                          },
                      }
                    : el
            );
            const selectedHeader = state.grid.headers.findIndex((e) => e.id === action.data);

            switch (_headers[selectedHeader].value.status) {
                case GRID_HEADER_STATE.NONE:
                    _headers[selectedHeader].value.status = GRID_HEADER_STATE.ASC;
                    break;
                case GRID_HEADER_STATE.ASC:
                    _headers[selectedHeader].value.status = GRID_HEADER_STATE.DESC;
                    break;
                case GRID_HEADER_STATE.DESC:
                    _headers[selectedHeader].value.status = GRID_HEADER_STATE.ASC;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                grid: {
                    ...state.grid,
                    headers: _headers,
                    pagination: initialState.grid.pagination,
                },
            };
        default:
            return state;
    }
};

export default inviteSearch;
