import { makeStyles } from '@material-ui/core/styles';
import { emptyStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    BgDiv: {
        marginTop: '26px',
        paddingTop: '50px',
        paddingBottom: '50px',
        backgroundColor: 'transparent',
        backgroundSize: 'contain',
        overflow: 'hidden',
        width: '100%',
    },
    ...emptyStyle,
});

export default useStyles;
