import { makeStyles } from '@material-ui/core';
import {
    COLOR_PRIMARY_BASE,
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_SHADE_01,
    COLOR_PRIMARY_SHADE_03,
    COLOR_THEME_FILL_01,
} from 'fintech/components/style/common';

const scrollButtonCommon = {
    backgroundColor: `${COLOR_PRIMARY_SHADE_03} !important`,
    marginTop: '0px !important',
    marginBottom: '0px !important',
    marginLeft: 0,
    marginRight: '0px !important',
    maxHeight: '38px !important',
    height: '38px !important',
    maxWidth: '38px !important',
    width: '38px !important',
    borderRadius: '0px !important',
    '&:hover': {
        backgroundColor: `${COLOR_SECONDARY_SHADE_01} !important`,
    },
};
const fullWidthAndHeightCommon = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'start',
    width: '100%',
    height: '100%',
    maxWidth: '250px',
};
const useStyles = makeStyles((theme) => ({
    stickyTableContainer: {
        position: 'absolute',
        width: '100%',
        '& .sticky-table-table': {
            borderRight: ({ isScrollable }) => (!isScrollable ? `4px solid ${COLOR_THEME_FILL_01}` : null),
        },
    },
    fullWidthAndHeightFlexContainer: {
        ...fullWidthAndHeightCommon,
        justifyContent: 'flex-start',
    },
    fullWidthAndHeightFlexCenteredContainer: {
        ...fullWidthAndHeightCommon,
        justifyContent: 'center',
    },
    sortLabelSpan: {
        marginTop: 11,
        marginBottom: 12,
    },
    headerLabel: {
        marginTop: 11,
        marginBottom: 12,
    },
    defaultLabel: {
        color: COLOR_SECONDARY_BASE,
        fontWeight: '500',
        fontSize: 12,
    },
    ventureColumnTextWrapper: {
        maxWidth: '216px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '60px',
        },
    },
    ventureColumnText: {
        color: COLOR_SECONDARY_BASE,
        fontWeight: '600',
        fontSize: '14px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    nameSurnameTextWrapper: {
        maxWidth: '216px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '60px',
        },
    },
    nameSurnameText: {
        color: COLOR_SECONDARY_BASE,
        fontWeight: 'bold',
        fontSize: '14px',
    },
    avatar: {
        marginRight: '0.3rem',
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        border: `solid 1px ${COLOR_PRIMARY_SHADE_03}`,
        backgroundColor: COLOR_PRIMARY_SHADE_02,
    },
    avatarPlaceholder: {
        marginRight: '0.3rem',
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        border: `solid 1px ${COLOR_PRIMARY_SHADE_03}`,
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        '& img': {
            padding: '6px',
            width: '38px',
            height: '38px',
        },
    },

    // Extended column
    userColumnTextWrapper: {
        minWidth: '310px',
        maxWidth: '310px',
        [theme.breakpoints.down('xs')]: {
            minWidth: 'unset',
            maxWidth: '60px',
        },
    },

    // Extended column
    investorColumnTextWrapper: {
        minWidth: '182px',
        maxWidth: '222px',
        [theme.breakpoints.down('xs')]: {
            minWidth: 'unset',
            maxWidth: '60px',
        },
    },

    // Extended column
    /* src\store\actions\investorSearch.js
     * textmargin style should also be MODIFIED!
     */
    investorTypeColumnTextWrapper: {
        minWidth: '233px',
    },
    ListItemButton: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        },
    },
    arrowContainer: {
        cursor: 'pointer',
    },
    upArrow: {
        position: 'relative',
        width: '18px',
        height: '18px !important',
        top: '8px',
    },
    downArrow: {
        position: 'relative',
        bottom: '10px',
        width: '18px',
        height: '18px !important',
    },
    svgStyle: {
        fontSize: '20px !important',
        color: COLOR_SECONDARY_SHADE_01,
        padding: '0 !important',
    },
    paddingLeftVenture: {
        paddingLeft: '8px',
    },
    alingVerticalNameSurname: {
        top: '-6px',
    },
    paddingLeftVentureFavourite: {
        paddingLeft: '4px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 'unset',
        },
    },
    companyLogo: {
        marginRight: '0.3rem',
        width: '40px',
        height: '40px',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
        },
    },
    modalTitle: {
        borderBottom: ({ showBorder }) => (showBorder ? '1px solid #dee2e6' : null),
        paddingTop: '24px',
        paddingBottom: '32px',
        textAlign: 'center',
    },
    leftScrollButton: {
        ...scrollButtonCommon,
        position: 'absolute',
        right: '0px',
        top: '0px',
    },
    rightScrollButton: {
        ...scrollButtonCommon,
        position: 'absolute',
        top: '0px',
    },
}));

export const tagsModalStyle = makeStyles((theme) => ({
    paper: {
        height: '420px',
        [theme.breakpoints.up('xs')]: {
            width: '400px',
        },
    },
}));

// Arrow
export const ARROW_COLOR = {
    selected: COLOR_PRIMARY_BASE,
    unselected: COLOR_SECONDARY_SHADE_01,
};

export const tableHeaderStyle = {
    textAlign: 'center',
    backgroundColor: COLOR_PRIMARY_SHADE_02,
    borderTop: 'solid 1px ' + COLOR_PRIMARY_SHADE_03,
    borderRight: 'solid 1px ' + COLOR_PRIMARY_SHADE_03,
    borderBottom: 'solid 1px ' + COLOR_PRIMARY_SHADE_03,
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '0px',
    paddingLeft: '0px',
    height: '38px',
};

export const tableLeftPaddingHeaderStyle = {
    textAlign: 'start',
    backgroundColor: COLOR_PRIMARY_SHADE_02,
    borderTop: 'solid 1px ' + COLOR_PRIMARY_SHADE_03,
    borderRight: 'solid 1px ' + COLOR_PRIMARY_SHADE_03,
    borderBottom: 'solid 1px ' + COLOR_PRIMARY_SHADE_03,
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '0px',
    paddingLeft: '24px',
    height: '38px',
};

export const tableRowStyle = {
    padding: '0px 4px 0px 24px',
    verticalAlign: 'middle',
};

export const tableStatusRowStyle = {
    padding: '0px 12px 0px 0px',
    verticalAlign: 'middle',
};

export const tableTagRowStyle = {
    padding: '0px 12px 0px',
    verticalAlign: 'middle',
};

export const tableFavoriteRowStyle = {
    padding: '0px 12px 0px 0px',
    verticalAlign: 'middle',
};

export const tagHyphenStyle = {
    position: 'relative',
    bottom: '6px',
    paddingLeft: '12px',
};

export default useStyles;
