import * as React from 'react';

function SvgIconsSingleCheck(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <filter id="qj62p8t37a" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g filter="url(#qj62p8t37a)" transform="translate(-575 -667) translate(399 663) translate(166)">
                            <g>
                                <path
                                    fill="#3F5162"
                                    fillRule="nonzero"
                                    d="M9.75 16.938L18.75 7.938 17.688 6.876 9.75 14.832 6.618 11.7 5.574 12.762z"
                                    transform="translate(10 4)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleCheck;
