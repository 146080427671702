import * as React from 'react';

function SvgIconsMultipleYatirimci(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#D9DCE0"
                    d="M9.375 12H6.75L6 10.125H4.875l-.75-1.875H3l-.75-1.875h6c.621 0 1.125.504 1.125 1.125V12z"
                />
                <path
                    fill="#D9DCE0"
                    d="M14.625 13.5v-6c0-.621.504-1.125 1.125-1.125h7.875V4.5H.375v1.875H8.25c.621 0 1.125.504 1.125 1.125v6h5.25zM9.75 23.875h4.5v-10h-4.5z"
                />
                <path fill="#3F5162" d="M11.625 23.625h.75v-8.25h-.75zm0-16.875h.75V6h-.75z" />
                <path
                    fill="#D9DCE0"
                    d="M14.625 12h2.625l.75-1.875h1.125l.75-1.875H21l.75-1.875h-6c-.621 0-1.125.504-1.125 1.125V12z"
                />
                <path fill="#3F5162" d="M21.254 8.625H18v-.75h2.746l.656-1.639.696.278z" />
                <path fill="#3F5162" d="M19.379 10.5H16.5v-.75h2.371l.656-1.639.696.278z" />
                <path fill="#3F5162" d="M17.504 12.375H15.75v-.75h1.246l.656-1.639.696.278z" />
                <path fill="#FFF" d="M13.5 1.875a1.5 1.5 0 11-3.001-.001 1.5 1.5 0 013.001.001" />
                <path
                    fill="#3F5162"
                    d="M12 .75c-.62 0-1.125.505-1.125 1.125S11.38 3 12 3s1.125-.505 1.125-1.125S12.62.75 12 .75m0 3c-1.034 0-1.875-.841-1.875-1.875S10.966 0 12 0s1.875.841 1.875 1.875S13.034 3.75 12 3.75M6 8.625H2.746l-.844-2.111.696-.278.656 1.639H6z"
                />
                <path fill="#3F5162" d="M7.5 10.5H4.621l-.844-2.111.696-.278.656 1.639H7.5z" />
                <path fill="#3F5162" d="M8.25 12.375H6.496l-.844-2.111.696-.278.656 1.639H8.25z" />
                <path
                    fill="#3F5162"
                    d="M15 13.875H9V7.5a.75.75 0 00-.75-.75H.375V6H8.25c.827 0 1.5.673 1.5 1.5v5.625h4.5V7.5c0-.827.673-1.5 1.5-1.5h7.875v.75H15.75a.75.75 0 00-.75.75v6.375zM7 24.05h10v-.75H7z"
                />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleYatirimci;
