import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import smoothscroll from 'smoothscroll-polyfill';

import 'bootstrap/dist/css/bootstrap.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import reducers from 'fintech/store/reducers';
import { keycloakClient, keycloakInitOptions } from 'fintech/utils/Keycloak';
import App from 'fintech/App';
import { loadUserRoles } from 'fintech/utils/Auth';
import LoadingPage from './pages/LoadingPage';
import { getFileToken } from './api/fintechService';
import { setFileServerToken } from './utils/FileUtils';

// this is for smooth scroll on Safari
smoothscroll.polyfill();

const composeEnhancers = ['local', 'development', 'test'].includes(process.env.DOCKER_ENV)
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const theme = createMuiTheme({
    typography: {
        fontFamily: ["'Poppins'", 'sans-serif'].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1280,
            xl: 1920,
        },
    },
});

setFileServerToken(null);

const renderApp = async () => {
    const handleOnEvent = (event) => {
        if (event === 'onAuthSuccess') {
            if (keycloakClient.authenticated) {
                loadUserRoles(keycloakClient);
                getFileToken(keycloakClient)
                    .then(({ data }) => {
                        setFileServerToken(data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    };
    const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

    console.log(keycloakClient);

    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <ReactKeycloakProvider
                authClient={keycloakClient}
                initOptions={keycloakInitOptions}
                LoadingComponent={<LoadingPage />}
                onEvent={(event, error) => handleOnEvent(event, error)}
            >
                <Provider store={store}>
                    <App />
                </Provider>
            </ReactKeycloakProvider>
        </ThemeProvider>,
        document.getElementById('root')
    );
};

renderApp();
