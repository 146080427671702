// Scroll related
export const DIRECTION_RIGHT = 'right';
export const DIRECTION_LEFT = 'left';

export const DEF_FREQUENCY = 8;
export const DEF_SPEED = 12;
export const DEF_DISTANCE = 135;
export const DEF_STEP = 10;
export const DEF_STEP_CONT = 15;
export const DEF_DELAY = 200;
