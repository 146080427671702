import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, DialogContent, MenuItem, Select, TextField, withStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { getCurrencySign } from 'fintech/utils/StringUtils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrencies } from 'fintech/store/actions/common';
import { useKeycloak } from '@react-keycloak/web';
import { COLOR_PRIMARY_SHADE_03, selectInputEmptyStyle } from 'fintech/components/style/common';
import DropdownError from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/DropdownError';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';

const StyledTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '6px 0 0 6px',
                border: `1px solid ${COLOR_PRIMARY_SHADE_03}`,
            },
            '&:hover fieldset': {
                borderRadius: '6px 0 0 6px',
            },
            '&.Mui-focused fieldset': {
                borderRadius: '6px 0 0 6px',
            },
        },
    },
})(TextField);

const CurrencyInput = (props) => {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const {
        id = 'currency-input',
        label,
        initialValue,
        required,
        onChange,
        validatedAfterFormSubmit = false,
        validateOnBlur = false,
        dontValidate = false,
    } = props;
    const { data, isLoading, isError } = useSelector((state) => state.common.currencies);
    const [monetaryValue, setMonetaryValue] = useState({
        value: initialValue?.value ? initialValue.value : '',
        currencyId: initialValue?.currencyId ? initialValue.currencyId : 1,
    });

    const [validated, setValidated] = useState(validateOnBlur ? true : validation(monetaryValue.value));
    const dispatch = useDispatch();

    useEffect(() => {
        !data && dispatch(fetchCurrencies(keycloak));
    }, [data]);

    /**
     * If [validatedAfterFormSubmit] is true, validation will be performed.
     *
     * This is useful if the user tries save changes before filling out the required fields.
     */

    useEffect(() => {
        if (!validatedAfterFormSubmit) {
            setValidated(validation(monetaryValue.value));
        }
    }, [validatedAfterFormSubmit]);

    function validation(value) {
        if (dontValidate) {
            return true;
        }

        const result = value && +value > 0 ? true : false;
        return (!required && value?.length == 0) || result;
    }

    const valueChangeHandler = (e) => {
        const newVal = e.target.value;
        setMonetaryValue((prevState) => ({
            ...prevState,
            value: newVal,
        }));
        !validateOnBlur && setValidated(true);
        onChange && onChange({ value: newVal, currencyId: monetaryValue.currencyId }, validation(newVal));
    };

    const currencyChangeHandler = (e) => {
        const cur = e.target.value;
        setMonetaryValue((prevState) => ({
            ...prevState,
            currencyId: cur,
        }));
        onChange && onChange({ value: monetaryValue.value, currencyId: cur }, validation(monetaryValue.value));
    };

    function inputFilter(e) {
        // Can be extended
        if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
            e.preventDefault();
        }
    }

    let renderedJSX = null;
    if (isLoading) {
        renderedJSX = (
            <div style={{ ...selectInputEmptyStyle }}>
                <LoadingSpinner />
            </div>
        );
    } else if (isError) {
        renderedJSX = <DropdownError style={{ ...selectInputEmptyStyle }} />;
    } else {
        let validationText = null;
        if (!validation(monetaryValue.value)) {
            validationText =
                !monetaryValue.value || monetaryValue.value === ''
                    ? t('validation.notEmpty')
                    : t('validation.positiveNumber');
        }

        renderedJSX = (
            <ButtonGroup style={{ width: '100%' }}>
                <TextInputField
                    id={id}
                    error={!validated}
                    label={label}
                    monetaryValue={monetaryValue}
                    validationText={!validated && validationText}
                    onKeyPress={inputFilter}
                    valueChangeHandler={valueChangeHandler}
                    onBlur={
                        validateOnBlur
                            ? (event) => {
                                  const tmpValue = event.target.value;
                                  setValidated(validation(tmpValue));
                              }
                            : () => {}
                    }
                />
                <CurrencySelect
                    id={id}
                    error={!validated}
                    monetaryValue={monetaryValue}
                    currencyChangeHandler={currencyChangeHandler}
                    data={data}
                />
            </ButtonGroup>
        );
    }

    return <DialogContent>{renderedJSX}</DialogContent>;
};

/**
 * If a component other than <Button /> is provided to a <ButtonGroup />, the button specific
 * props passed by the button group will cause errors. The functions below are mini components (functions)
 * that ensure that only the required props are passed down.
 */
const TextInputField = ({
    id,
    error,
    label,
    monetaryValue,
    validationText,
    valueChangeHandler,
    onBlur,
    onKeyPress,
}) => {
    return (
        <div className={'inputTextAreaContainer poppin-500-14px'} style={{ width: '100%' }}>
            <StyledTextField
                error={error}
                id={`${id}-value`}
                variant="outlined"
                type="text"
                label={label}
                FormHelperTextProps={{ style: { marginRight: '-80px' } }}
                helperText={validationText}
                defaultValue={monetaryValue.value}
                onBlur={onBlur}
                onChange={valueChangeHandler}
                onKeyPress={onKeyPress}
            />
        </div>
    );
};

const CurrencySelect = ({ id, error, monetaryValue, currencyChangeHandler, data }) => {
    return (
        <div className={'selectComboboxContainer'}>
            <Select
                error={error}
                id={`${id}-currencyType`}
                style={{ borderRadius: '0 6px 6px 0', minWidth: '65px', maxHeight: '56px' }}
                variant="outlined"
                defaultValue={monetaryValue.currencyId}
                IconComponent={ExpandMore}
                onChange={currencyChangeHandler}
                disabled
            >
                {data?.map((cur) => (
                    <MenuItem key={cur.id} value={cur.id}>
                        {getCurrencySign(cur.currencyType)}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default CurrencyInput;
