import * as React from 'react';

function SvgIconsSingleUpload(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <filter id="cmsltbcqza" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g
                            filter="url(#cmsltbcqza)"
                            transform="translate(-805 -1010) translate(280 988) translate(521 20)"
                        >
                            <g>
                                <path
                                    fill="#3F5162"
                                    fillRule="nonzero"
                                    d="M14.25 14.688v-4.5h2.988L12 4.95l-5.238 5.238H9.75v4.5h4.5zm2.988 3.024V16.2H6.762v1.512h10.476z"
                                    transform="translate(4 2)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleUpload;
