import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import UserVenture from 'fintech/components/profile/user/VenturesGrid/Venture';
import useStyles from 'fintech/components/profile/user/VenturesGrid/Venture/index.style';
import { fetchUserUsersships } from 'fintech/store/actions/user';
import Empty from 'fintech/components/util/Empty';

const VenturesGrid = (props) => {
    const { t, userUuid, onTagExpand } = props;
    const classes = useStyles();
    const { keycloak } = useKeycloak();

    const { data: fetchedData, isLoading, isError } = useSelector((state) => state.user.usersship);
    const [venturesData, setVenturesData] = useState(fetchedData);
    const dispatch = useDispatch();

    let hasData = fetchedData.length > 0;
    let venturesJSX = null;
    let emptyProps = {};
    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.relatedVentures.errorMessage');
    } else if (hasData) {
        venturesJSX = (
            <Grid
                className={classes.UserVentureCard}
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
            >
                {venturesData &&
                    venturesData.map((venture) => (
                        <UserVenture key={venture.uuid} {...venture} onTagExpand={onTagExpand} />
                    ))}
            </Grid>
        );
    } else {
        emptyProps.message = t('profile.relatedVentures.emptyMessage');
    }
    useEffect(() => {
        dispatch(fetchUserUsersships(keycloak, userUuid));
    }, []);

    useEffect(() => {
        setVenturesData(fetchedData);
    }, [fetchedData]);

    return hasData ? venturesJSX : <Empty {...emptyProps} />;
};

export default withTranslation('profile')(VenturesGrid);
