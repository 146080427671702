import { IdeaActions } from 'fintech/store/actions/ActionTypes';
import { filterObjectList, findObject, uniqueArrayForField } from 'fintech/utils/ArrayUtils';
import { getLogo, parseMedia } from 'fintech/utils/DataParser';

const initialState = {
    intro: {
        data: {},
        isLoading: true,
        isError: false,
    },
    team: {
        data: [],
        isLoading: true,
        isError: false,
    },
    verticalData: {
        data: [],
        isLoading: true,
        isError: false,
    },
    technologyData: {
        data: [],
        isLoading: true,
        isError: false,
    },
    stageData: {
        data: [],
        isLoading: true,
        isError: false,
    },
};

const idea = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case IdeaActions.SET_IDEA_INTRO:
            const { idea } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    data: {
                        ...idea,
                        id: idea.uuid,
                        name: idea.name,
                        summary: getSummary(idea.startupInfos),
                        verticals: getVerticals(idea.subverticals),
                        technologies: parseTechnologies(idea.technologies),
                        media: idea.medias,
                        webSiteUrl: getWebSiteUrl(idea.startupInfos),
                        logoUrl: getLogo(idea.logoFile),
                        stage: idea.stage?.name,
                        viewTimes: idea.viewTimes,
                        email: getEmail(idea.startupInfos),
                        editable: idea.editable,
                    },
                    isLoading: false,
                },
            };

        case IdeaActions.GET_IDEA_INTRO:
            return {
                ...state,
                intro: {
                    ...state.intro,
                    data: {
                        ...state.intro.idea,
                    },
                    isLoading: false,
                },
            };
        case IdeaActions.SET_IDEA_INTRO_LOADING:
            return {
                ...state,
                intro: {
                    ...state.intro,
                    isLoading: true,
                },
            };
        case IdeaActions.SET_IDEA_INTRO_LOAD_ERROR:
            return {
                ...state,
                intro: {
                    ...state.intro,
                    isLoading: false,
                    isError: true,
                },
            };
        case IdeaActions.SET_IDEA_TEAM:
            const { id, team } = action;
            return {
                ...state,
                team: {
                    ...state.team,
                    data: {
                        ...team.data,
                        id: id,
                    },
                    isLoading: false,
                },
            };
        case IdeaActions.SET_IDEA_TEAM_LOADING:
            return {
                ...state,
                team: {
                    ...state.team,
                    isLoading: true,
                },
            };
        case IdeaActions.SET_IDEA_TEAM_LOAD_ERROR:
            return {
                ...state,
                team: {
                    ...state.team,
                    isLoading: false,
                    isError: true,
                },
            };
        case IdeaActions.SET_IDEA_VERTICALS:
            const { ideaId, verticalData } = action;
            return {
                ...state,
                verticalData: {
                    ...state.verticalData,
                    data: {
                        verticals: [...verticalData.data],
                        ideaId: ideaId,
                    },
                    isLoading: false,
                },
            };
        case IdeaActions.SET_IDEA_VERTICALS_LOADING:
            return {
                ...state,
                verticalData: {
                    ...state.verticalData,
                    isLoading: true,
                },
            };
        case IdeaActions.SET_IDEA_VERTICALS_LOAD_ERROR:
            return {
                ...state,
                verticalData: {
                    ...state.verticalData,
                    isLoading: false,
                    isError: true,
                },
            };

        case IdeaActions.SET_IDEA_TECHNOLOGIES:
            const { technologyData } = action;
            return {
                ...state,
                technologyData: {
                    ...state.technologyData,
                    data: {
                        technologies: [...technologyData.data],
                    },
                    isLoading: false,
                },
            };
        case IdeaActions.SET_IDEA_TECHNOLOGIES_LOADING:
            return {
                ...state,
                technologyData: {
                    ...state.technologyData,
                    isLoading: true,
                },
            };
        case IdeaActions.SET_IDEA_TECHNOLOGIES_LOAD_ERROR:
            return {
                ...state,
                technologyData: {
                    ...state.technologyData,
                    isLoading: false,
                    isError: true,
                },
            };
        case IdeaActions.SET_IDEA_STAGES:
            const { stageData } = action;
            return {
                ...state,
                stageData: {
                    ...state.stageData,
                    data: {
                        stages: [...stageData.data],
                    },
                    isLoading: false,
                },
            };
        case IdeaActions.SET_IDEA_STAGES_LOADING:
            return {
                ...state,
                stageData: {
                    ...state.stageData,
                    isLoading: true,
                },
            };
        case IdeaActions.SET_IDEA_STAGES_LOAD_ERROR:
            return {
                ...state,
                stageData: {
                    ...state.stageData,
                    isLoading: false,
                    isError: true,
                },
            };
        case IdeaActions.REMOVE_IDEA_INFORMATION_CARD_EDIT_STATE:
            return {
                ...state,
                technologyData: {
                    ...state,
                    data: { technologies: undefined },
                    isLoading: false,
                    isError: false,
                },
                stageData: {
                    ...state,
                    data: { stages: undefined },
                    isLoading: false,
                    isError: false,
                },
                verticalData: {
                    ...state,
                    data: {
                        verticals: undefined,
                        ideaId: undefined,
                    },
                    isLoading: false,
                    isError: false,
                },
            };
        default:
            return state;
    }
};

export default idea;

const getSummary = (obj) => {
    const summary = findObject(obj, 'field', 'summary');
    if (summary) {
        summary.validated = true;
    }
    return summary;
};

const getEmail = (obj) => {
    const email = findObject(obj, 'field', 'email');
    if (email) {
        email.validated = true;
    }
    return email;
};
const getMedia = (obj) => {
    return parseMedia(obj).then(function (response) {
        return response;
    });
};

const getWebSiteUrl = (obj) => {
    const webSite = findObject(obj, 'field', 'website');
    if (webSite) {
        webSite.validated = true;
    }
    return webSite;
};

const getLogoUrl = (obj) => {
    const logoObject = findObject(obj, 'type', 'VENTURE_LOGO');
    return { url: logoObject.url };
};

const getVerticals = (subvertArr) => {
    const verticals = {};

    subvertArr?.forEach((sub) => {
        const name = sub.vertical?.name;
        verticals[name] = verticals[name] ? [...verticals[name], sub.name] : [sub.name];
    });

    return verticals;
};
const parseTechnologies = (techArr) => techArr?.map((tech) => tech.name);
