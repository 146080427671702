import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'fintech/components/util/ErrorBoundary';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';

const TabSwitch = (props) => {
    const { tabs, isSearchNavTabs, defaultIndex } = props;

    const labels = tabs?.map((tab) => tab.header);
    const [selectedTabIndex, setSelectedTabIndex] = useState(defaultIndex);
    const onTabSwitchHandler = (e, idx) => setSelectedTabIndex(idx);
    const renderedTab = tabs[selectedTabIndex];

    useEffect(() => {
        onTabSwitchHandler(null, defaultIndex);
    }, [defaultIndex]);

    return (
        <>
            {isSearchNavTabs && isSearchNavTabs === true && (
                <NavTabs id="search-navtabs" value={selectedTabIndex} tabs={labels} onChange={onTabSwitchHandler} />
            )}
            {!isSearchNavTabs && <NavTabs value={selectedTabIndex} tabs={labels} onChange={onTabSwitchHandler} />}
            <ErrorBoundary message={renderedTab.errorMsg}>{renderedTab.component}</ErrorBoundary>
        </>
    );
};

TabSwitch.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.shape({
                label: PropTypes.string.isRequired,
            }),
            // Should be a React component
            component: PropTypes.any.isRequired,
            errorMsg: PropTypes.string.isRequired,
        })
    ),
    defaultIndex: PropTypes.number.isRequired,
};

TabSwitch.defaultProps = {
    defaultIndex: 0,
};

export default TabSwitch;
