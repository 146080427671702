import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles(() => ({
    pdfContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 5px 5px',
        margin: 0,
        marginBottom: '10px',
        borderBottom: `2px solid ${COLOR_PRIMARY_SHADE_02}`,
    },
    pdfTitle: { fontSize: '12px', fontWeight: '600', marginBottom: '0px', marginLeft: '14px' },
    buttonContainer: { marginBottom: '10px' },
}));

export default useStyles;
