import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconsSingleCheckBig from 'fintech/icons/IconsSingleCheckBig';
import useStyles from './SettingsGrid.style';
import { useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import { withTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import * as UserActions from 'fintech/store/actions/user';
import { getRequest } from 'fintech/api/api';
import { APIS } from 'fintech/constants';

const SettingsGrid = (props) => {
    const classes = useStyles();
    const { t } = props;
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [passwordUpdatedWithSuccess, setPasswordUpdatedWithSuccess] = useState(false);
    const [formIsSubmitted, setFormIsSubmitted] = useState(false);
    const showErrorMessage = (message) => enqueueSnackbar(message, { variant: 'error' });

    const [values, setValues] = useState({
        currentPassword: '',
        newPassword: '',
        reNewPassword: '',
        showCurrentPassword: false,
        showNewPassword: false,
        showReNewPassword: false,
        currentPasswordValidated: false,
        newPasswordValidated: false,
        reNewPasswordValidated: false,
        otpToken: '',
        secondStep: 0,
    });

    const tabs = [{ label: t('settings.changePasswordOperation'), value: 0 }];

    const handleChange = (key, value, validated) => {
        setValues({ ...values, [key]: value, [key + 'Validated']: validated });
    };

    const handleClickShowPassword = (prop) => {
        setValues({ ...values, [prop]: !values[prop] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePasswordHandler = () => {
        const isValid = values.currentPasswordValidated && values.newPasswordValidated && values.reNewPasswordValidated;
        setFormIsSubmitted(true);

        if (isValid) {
            const passwordData = {
                oldPassword: values.currentPassword,
                newPassword: values.newPassword,
                newPasswordConfirmation: values.reNewPassword,
            };

            if (values.secondStep === 0) {
                getRequest(APIS.USER.getUserOtpControl(), keycloak)
                    .then((result) => {
                        if (result.data) {
                            setValues({ ...values, secondStep: 1 });
                        } else {
                            updatePasswordDispatch(passwordData);
                        }
                    })
                    .catch((error) => showErrorMessage(error.message));
            } else if (values.secondStep === 1 && values.otpToken !== '') {
                passwordData.otpToken = values.otpToken;
                updatePasswordDispatch(passwordData);
            } else {
                showErrorMessage(t('validation.genericError'));
            }
        } else {
            showErrorMessage(t('profile.user.validationError'));
        }
    };

    const updatePasswordDispatch = (passwordData) => {
        dispatch(
            UserActions.updatePassword(
                keycloak,
                () => {
                    setPasswordUpdatedWithSuccess(true);
                },
                (error) => {
                    showErrorMessage(error.message);
                },
                passwordData
            )
        );
    };

    const changePasswordCancel = () => {
        setValues({ ...values, secondStep: 0, otpToken: '' });
    };

    const changePasswordFormJsx = (
        <>
            <div className={classes.infoDiv}>
                {values.secondStep === 1 ? t('settings.secondStepPasswordVal') : t('settings.passwordVal')}
            </div>
            <form className={classes.changePasswordForm}>
                {values.secondStep === 0 && (
                    <>
                        <div className={classes.changePasswordFormItem}>
                            <InputTextArea
                                label={t('settings.currentPassword')}
                                required={true}
                                validateOnBlur={true}
                                validatedAfterFormSubmit={!formIsSubmitted}
                                validationText={t('validation.notEmpty')}
                                type={values.showCurrentPassword ? 'text' : 'password'}
                                initText={values.currentPassword}
                                onChange={(value, validated) => handleChange('currentPassword', value, validated)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('showCurrentPassword')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                        <div className={classes.changePasswordFormItem}>
                            <InputTextArea
                                label={t('settings.newPassword')}
                                required={true}
                                validateOnBlur={true}
                                validatedAfterFormSubmit={!formIsSubmitted}
                                validationText={t('validation.notEmpty')}
                                type={values.showNewPassword ? 'text' : 'password'}
                                initText={values.newPassword}
                                onChange={(value, validated) => handleChange('newPassword', value, validated)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('showNewPassword')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                        <div className={classes.changePasswordFormItem}>
                            <InputTextArea
                                label={t('settings.reNewPassword')}
                                required={true}
                                validateOnBlur={true}
                                validatedAfterFormSubmit={!formIsSubmitted}
                                validationText={t('validation.notEmpty')}
                                type={values.showReNewPassword ? 'text' : 'password'}
                                initText={values.reNewPassword}
                                onChange={(value, validated) => handleChange('reNewPassword', value, validated)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('showReNewPassword')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showReNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </>
                )}

                {values.secondStep === 1 && (
                    <div className={classes.changePasswordFormItem}>
                        <InputTextArea
                            label={t('settings.otpCode')}
                            fullWidth
                            required={values.secondStep === 1}
                            validateOnBlur={true}
                            validatedAfterFormSubmit={!formIsSubmitted}
                            validationText={t('validation.notEmpty')}
                            type="text"
                            initText={values.otpToken}
                            onChange={(value, validated) => handleChange('otpToken', value, validated)}
                        />
                    </div>
                )}
                <div className={classes.buttonsWrapper}>
                    <div>
                        {values.secondStep === 1 && (
                            <Button className={classes.cancelButton} onClick={changePasswordCancel}>
                                {t('settings.cancelButton')}
                            </Button>
                        )}
                    </div>
                    <Button className={classes.changePassword} onClick={changePasswordHandler}>
                        {t('settings.changePassword')}
                    </Button>
                </div>
            </form>
        </>
    );

    const afterChangePasswordFormJsx = (
        <div>
            <div className={classes.successIconDiv}>
                <IconsSingleCheckBig width={62} height={62} />
            </div>
            <div className={classes.updatePasswordSuccessfulTextDiv}>{t('settings.updatePasswordSuccessful')}</div>
        </div>
    );

    return (
        <>
            <NavTabs value={0} tabs={tabs} />
            <div className={classes.SettingsGrid}>
                {passwordUpdatedWithSuccess ? afterChangePasswordFormJsx : changePasswordFormJsx}
            </div>
        </>
    );
};

export default withTranslation('settings')(SettingsGrid);
