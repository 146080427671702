import React, { useState } from 'react';
import { Avatar, Card, CardContent, Typography } from '@material-ui/core';
import {
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    COLOR_THEME_FILL_01,
} from 'fintech/components/style/common';
import PlaceHoldersPerson from 'fintech/static/images/placeholder-kisi-rounded.png';
import useStyles from './index.style';

const SupportsCard = (props) => {
    const { title, description, logo } = props;
    const initStyles = {
        headerColor: COLOR_PRIMARY_SHADE_02,
        btnColor: COLOR_THEME_FILL_01,
    };
    const [hoverStyles, setHoverStyles] = useState(initStyles);
    const classes = useStyles(hoverStyles);

    const onMouseEnterHandler = () =>
        setHoverStyles({
            headerColor: COLOR_PRIMARY_SHADE_03,
            btnColor: COLOR_PRIMARY_BASE,
        });

    const onMouseLeaveHandler = () => setHoverStyles(initStyles);

    return (
        <Card className={classes.CardRoot} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
            <CardContent className={classes.CardHeaderBg}>
                <Avatar className={classes.CardAvatar} alt={title} src={logo}>
                    <Avatar
                        className={classes.CardAvatar}
                        style={{ marginTop: '0' }}
                        alt={'icon'}
                        src={PlaceHoldersPerson}
                    />
                </Avatar>
            </CardContent>

            <CardContent className={classes.CardContentContainer}>
                <Typography className={classes.SupportNameTypo}>{title}</Typography>
                {description.map((item, inx) => (
                    <Typography className={classes.VerticalsTitle} key={inx}>
                        {item}
                    </Typography>
                ))}
            </CardContent>
        </Card>
    );
};

export default SupportsCard;
