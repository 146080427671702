import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, Grid, Typography } from '@material-ui/core';

import notFoundImage from 'fintech/static/images/cable.png';
import useStyles from './index.style';

import { HOME } from 'fintech/routes';

const NotFound = () => {
    const classes = useStyles();
    const { t } = useTranslation('error');
    const history = useHistory();

    return (
        <Grid item className={classes.contentRoot}>
            <Grid item xs={12}>
                <div className={classes.imgWrapper}>
                    <img className={classes.NotFoundImg} src={notFoundImage} />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.titleTypo}> {t('notFound.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.infoTypo}>{t('notFound.info')}</Typography>
            </Grid>
            <Grid item xs={12} container direction="row" justify="center">
                <Button className={classes.homeBtn} onClick={() => history.push(HOME)}>
                    {t('common.backToHome')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default NotFound;
