import React from 'react';
import useStyle from './index.style';
import { IconsSingleTooltip } from 'fintech/icons';
import Tooltip from 'fintech/components/ui/tooltip';
const Index = ({ title, description, tooltipText }) => {
    const classes = useStyle();

    return (
        <div className={classes.infoBox}>
            <Tooltip title={tooltipText} aria-label={tooltipText}>
                <div>
                    <IconsSingleTooltip />
                </div>
            </Tooltip>
            <p>{title}</p>
            <p>{description}</p>
        </div>
    );
};

Index.propTypes = {};
Index.defaultProps = {};

export default Index;
