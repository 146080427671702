import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import SvgIconsMultipleSuccess from 'fintech/icons/IconsMultipleSuccess';
import { COLOR_THEME_FILL_05, COLOR_THEME_FILL_06 } from 'fintech/components/style/common';
import { HOME } from 'fintech/routes';
import useStyles from './index.style';

export const ApplicationResult = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('application');
    const history = useHistory();
    const {
        title = t('common.resultTitle'),
        info = t('common.resultInfo'),
        buttonLabel = t('dialog.backToHome'),
        onClick = () => {
            history.push(HOME);
        },
        secure = false,
    } = props;

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <CfoFormContainer result>
            <Grid item xs={12}>
                <Typography className={classes.titleTypo}>
                    <SvgIconsMultipleSuccess
                        className={classes.titleSvg}
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        circleFill={COLOR_THEME_FILL_06}
                        pathFill={COLOR_THEME_FILL_05}
                    />
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.infoTypo}>{info}</Typography>
            </Grid>
            {!secure && (
                <CfoFormInput xs={12} lg={12} container direction="row" justify="flex-start">
                    <Button className={classes.actionBtn} variant="contained" onClick={onClick}>
                        {buttonLabel}
                    </Button>
                </CfoFormInput>
            )}
        </CfoFormContainer>
    );
};

ApplicationResult.propTypes = {
    title: PropTypes.string,
    info: PropTypes.string,
    buttonLabel: PropTypes.string,
    onClick: PropTypes.func,
};

export default ApplicationResult;
