import * as React from 'react';

function SvgIconsMultipleLogout(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#D9DCE0"
                    d="M4.59 3h9.82c.38 0 .517.04.655.114a.768.768 0 01.321.32c.075.14.114.277.114.656v15.82c0 .38-.04.517-.114.655a.768.768 0 01-.32.321c-.14.075-.277.114-.656.114H4.59c-.38 0-.517-.04-.655-.114a.768.768 0 01-.321-.32c-.075-.14-.114-.277-.114-.656V4.09c0-.38.04-.517.114-.655a.768.768 0 01.32-.321c.14-.075.277-.114.656-.114z"
                />
                <path
                    fill="#3F5162"
                    fillRule="nonzero"
                    d="M3.75 22c-.338 0-.631-.12-.879-.361a1.193 1.193 0 01-.37-.889V3.25c0-.352.123-.648.37-.889.248-.24.54-.361.88-.361h10.623c.352 0 .648.12.889.361.24.241.361.537.361.889v5.625h-1.25V4.012a.733.733 0 00-.224-.537.735.735 0 00-.537-.225H4.53a.7.7 0 00-.527.225.75.75 0 00-.215.537l-.02 15.976c0 .209.072.388.216.537a.7.7 0 00.527.225h9.103a.733.733 0 00.537-.225.73.73 0 00.224-.537v-4.883h1.25v5.645c0 .352-.12.648-.361.889-.241.24-.537.361-.889.361H3.75zm14.61-6.016a.613.613 0 01-.235-.048.91.91 0 01-.215-.127.652.652 0 01-.175-.45c0-.169.058-.319.175-.449l2.305-2.285H9.395A.616.616 0 018.77 12c0-.182.058-.332.176-.45a.61.61 0 01.449-.175h10.781L17.91 9.109a.595.595 0 01-.175-.44c0-.175.058-.321.175-.439a.618.618 0 01.899 0l3.011 3.092a.998.998 0 010 1.395l-3.011 3.092a.91.91 0 01-.215.127.612.612 0 01-.234.048z"
                />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleLogout;
