import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent, Grid } from '@material-ui/core';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import VisibilityToggle from 'fintech/components/ui/form/toggle/VisibilityToggle';
import InvestorSearchSelect from 'fintech/components/ui/form/select/InvestorSearchSelect';
import { setEstablishmentStartup } from 'fintech/store/actions/establishment';
import { STATES } from 'fintech/store/actions/ActionTypes';

const EstablishmentAttemptUpdate = () => {
    const { t } = useTranslation();
    const { startup, startupVisible, investmentVisible, sending } = useSelector(
        (state) => state.establishment.investmentPreferences.form.startupForm
    );
    const dispatch = useDispatch();

    const onChangeHandler = ({ startupId, startupName, freeTextStartupName, isValid, isVisible, unregistered }) =>
        dispatch(
            setEstablishmentStartup({
                startupId: startupId,
                startupName: startupName,
                freeTextStartupName: freeTextStartupName,
                isValid: isValid,
                unregistered: unregistered,
                isVisible: isVisible,
            })
        );

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item>
                <DialogContent>
                    <InvestorSearchSelect
                        type={STATES.ESTABLISHMENT}
                        initialValue={{
                            id: startup.startupId,
                            name: startup.startupName,
                            unregistered: startup.unregistered,
                            logo: startup.logo,
                        }}
                        onChange={(value, isValid) =>
                            onChangeHandler({
                                startupId: value?.id,
                                startupName: value?.name,
                                isValid: isValid,
                                unregistered: value?.unregistered,
                                isVisible: startupVisible,
                            })
                        }
                        validateOnBlur
                        overrideInitiallyValidateState={sending}
                    />
                </DialogContent>
            </Grid>
            {startup.unregistered && (
                <Grid item>
                    <InputTextArea
                        type="text"
                        label={t('profile.establishments.attemptName')}
                        validationText={t('validation.notEmpty')}
                        initText={startup.freeTextStartupName}
                        onChange={(val, isValid) =>
                            onChangeHandler({
                                freeTextStartupName: val,
                                isValid: isValid,
                                startupId: startup.startupId,
                                unregistered: startup.unregistered,
                                isVisible: startupVisible,
                            })
                        }
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        required
                    />
                </Grid>
            )}
            <Grid item>
                <DialogContent style={{ paddingTop: '0', textAlign: 'end' }}>
                    <VisibilityToggle
                        initialValue={startupVisible}
                        disabled={!investmentVisible}
                        useDisabledValue
                        disabledValue={false}
                        useEnabledValue
                        enabledValue={startupVisible}
                        onChange={(isVisible) =>
                            onChangeHandler({
                                isVisible: isVisible,
                                startupId: startup.startupId,
                                startupName: startup.startupName,
                                freeTextStartupName: startup.freeTextStartupName,
                                isValid: startup.isValid,
                                unregistered: startup.unregistered,
                            })
                        }
                    />
                </DialogContent>
            </Grid>
        </Grid>
    );
};

export default EstablishmentAttemptUpdate;
