import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_BASE,
    fontStyle,
    COLOR_THEME_FILL_01,
} from 'fintech/components/style/common';

const cardRootCommon = {
    borderRadius: '8px',
    padding: '4px',
    backgroundColor: COLOR_THEME_FILL_01,
    minHeight: '480px',
    maxWidth: '388px',
    width: '388px',
    height: '480px',
};
const useStyles = makeStyles({
    CardRoot: {
        ...cardRootCommon,
        ['@media (max-width:1210px)']: {
            width: '100%',
            maxWidth: '100%',
            minWidth: '388px',
        },
        ['@media (max-width:539px)']: {
            maxWidth: 'calc(100vw - 60px)',
            minWidth: 'auto',
            height: 'auto',
        },
    },
    CardHeaderBg: {
        backgroundColor: ({ headerColor = COLOR_PRIMARY_SHADE_02 }) => headerColor,
        borderRadius: '6px 6px 0 0',
        height: '68px',
    },
    CardAvatar: {
        marginTop: '4px',
        height: '96px',
        width: '96px',
        margin: 'auto',
        background: COLOR_PRIMARY_SHADE_02,
        '& img': {
            width: '56px',
            height: '56px',
        },
    },
    SupportNameTypo: {
        ...fontStyle,
        color: COLOR_SECONDARY_BASE,
        fontSize: '15px',
        fontWeight: 'bold',
        margin: '12px auto',
    },
    CardContentContainer: {
        marginTop: '42px',
        textAlign: 'center',
    },
    VerticalsTitle: {
        fontSize: '13px',
        color: COLOR_SECONDARY_BASE,
        marginBottom: '8px',
        fontWeight: '400',
        ['@media (max-width:1080px)']: {
            marginBottom: '6px',
        },
    },
});

export default useStyles;
