import { Grid } from '@material-ui/core';
import React from 'react';

const BackgroundPiece = (props) => {
    const { imgSrc, imgAlign } = props;
    return (
        <Grid item xs={4}>
            <div style={{ textAlign: imgAlign }}>
                <img src={imgSrc} />
            </div>
        </Grid>
    );
};

export default BackgroundPiece;
