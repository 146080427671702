import { useEffect } from 'react';
import { fetchNotifications } from 'fintech/store/actions/notifications';
import { useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';

export default function useFetchNotification(pageNumber = 0) {
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    // const [isNotificationExists, setIsNotificationExist] = useState(false);

    // const [notifications, setNotifications] = useState([]);
    // const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch(fetchNotifications(pageNumber, keycloak));
    }, [pageNumber]);

    return null;
}
