import PageTemplate from 'fintech/components/PageTemplate';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import React from 'react';
import EventDetailGrid from 'fintech/components/dashboard/eventdetail/evendetailgrid/EventDetailGrid';

export default function EventDetailPage() {
    return (
        <PageTemplate>
            <PageHeaderBox />
            <EventDetailGrid />
        </PageTemplate>
    );
}
