import { TCKN } from 'fintech/components/util/IdentityConstants';
import { isEmpty } from 'lodash';

export const CATEGORY_REPRESANT_INFO = 'REPRESANT_INFO';
export const CATEGORY_GENERAL_INFORMATION = 'GENERAL_INFORMATION';
export const CATEGORY_INTELLECTUAL_PROPERTY = 'INTELLECTUAL_PROPERTY';
export const CATEGORY_FOUNDER_INFO = 'FOUNDER_INFO';
export const CATEGORY_STRUCTURE_INFO = 'STRUCTURE_INFO';
export const CATEGORY_OTHERS = 'OTHERS';

export const isChecked = (question, id) =>
    question?.selectionAnswerList?.[0]
        ? question.selectionAnswerList[0].selectedAnswerOptionList.some((selected) => selected.id === id)
        : false;

export const shouldShowInput = (question) =>
    question?.selectionAnswerList?.[0]?.selectedAnswerOptionList.some((selected) =>
        question.answerDefinitionList[0].answerOptionList
            .filter((option) => option.answerOptionType === 'OTHER')
            .map((option) => option.id)
            .includes(selected.id)
    );

export const checkboxAction = (event, type, id, question) => ({
    type,
    data: {
        id,
        checked: event.target.checked,
        selections: question?.selectionAnswerList?.[0]?.selectedAnswerOptionList ?? [],
    },
});

// Request body
// Individual answers
const initAnswer = (question) => {
    const answer = {};
    answer.questionFormat = {
        questionNumber: question.questionNumber,
        required: question.required,
    };

    return answer;
};

export const generateFreeTextAnswer = (question) => {
    const answer = initAnswer(question);
    if (question?.freeTextAnswerList) {
        answer.freeTextAnswerList = [question?.freeTextAnswerList[0]];
    }

    return answer;
};

export const generateSelectionAnswer = (question) => {
    const answer = initAnswer(question);
    answer.selectionAnswerList = question.selectionAnswerList;

    return answer;
};

export const generateMultiFreeTextAnswer = (question) => {
    const answer = initAnswer(question);
    answer.freeTextAnswerList = question.freeTextAnswerList;

    return answer;
};

export const generateDateAnswer = (question) => {
    const answer = initAnswer(question);
    answer.dateAnswerList = question.dateAnswerList;

    return answer;
};

export const generateSourceSelectAnswer = (question) => {
    const answer = initAnswer(question);
    answer.sourceSelectAnswerList = question.sourceSelectAnswerList;

    return answer;
};

export const generateFileUploadAnswer = (question) => {
    const answer = initAnswer(question);
    answer.fileUploadAnswerList = question.fileUploadAnswerList;

    return answer;
};

// Exceptional cases
export const generateAnswerQ9 = (question) => {
    const answer = initAnswer(question);
    answer.freeTextAnswerList = [];
    question.freeTextAnswerList.forEach((ans) => {
        ans.value && ans.value?.length > 0 && answer.freeTextAnswerList.push(ans);
    });

    return answer;
};

export const generateAnswerQ19 = (question, linkedInValid, githubValid) => {
    const answer = initAnswer(question);
    answer.freeTextAnswerList = [];

    if (linkedInValid) {
        const linkedInArray = question.freeTextAnswerList.linkedIn.filter((a) => a.value && a.value.length > 0);
        answer.freeTextAnswerList = [...answer.freeTextAnswerList, ...linkedInArray];
    }

    if (githubValid) {
        const githubArray = question.freeTextAnswerList.github.filter((a) => a.value && a.value.length > 0);
        answer.freeTextAnswerList = [...answer.freeTextAnswerList, ...githubArray];
    }

    return answer;
};

export const generateAnswerSelectionWithFreeText = (question) => {
    const answer = initAnswer(question);
    answer.selectionAnswerList = question.selectionAnswerList;
    if (question.freeTextAnswerList?.[0]?.value?.length > 0) {
        answer.freeTextAnswerList = question.freeTextAnswerList;
    }

    return answer;
};

export const generateAnswerQ34 = (question) => {
    const answer = initAnswer(question);
    answer.selectionAnswerList = question.selectionAnswerList;
    if (
        question.selectionAnswerList[0].selectedAnswerOptionList[0].id ===
        question?.answerDefinitionList[1]?.relatedAnswerOptionId
    ) {
        answer.freeTextAnswerList = question.freeTextAnswerList;
        answer.sourceSelectAnswerList = question.sourceSelectAnswerList;
    }
    return answer;
};

// Page-specific
export const generateRepInfoData = (applicantInfo) => {
    const body = {};
    body.name = applicantInfo.name.value;
    body.lastName = applicantInfo.lastName.value;
    body.gender = applicantInfo.gender.value.id;
    body.birthDate = applicantInfo.birthDate.value;
    body.email = applicantInfo.email.value;
    body.workExperienceInYears = +applicantInfo.workExperienceInYears.value;
    body.title = applicantInfo.title.value;
    body.educationInfo = applicantInfo.educationInfo.value;
    body.nationality = applicantInfo.nationality.value;
    body.identity = {
        value: applicantInfo.identity.value,
        identityType: TCKN,
    };
    body.phoneNumber = {
        number: applicantInfo.phoneNumber.value.nationalNumber,
        country: {
            callingCode: applicantInfo.phoneNumber.value.countryCallingCode,
            alphaTwo: applicantInfo.phoneNumber.value.country,
        },
    };

    return body;
};

export const generateGeneralInfoAnswers = (questions) => {
    let body = [];
    body = [
        // Q1-Q7 FREE_TEXT
        generateFreeTextAnswer(questions[0]),
        generateFreeTextAnswer(questions[1]),
        generateFreeTextAnswer(questions[2]),
        generateFreeTextAnswer(questions[3]),
        generateFreeTextAnswer(questions[4]),
        generateFreeTextAnswer(questions[6]),

        // Q11 DATE_PICKER
        generateDateAnswer(questions[10]),

        // Q12 & Q13 COMBOBOX
        generateSourceSelectAnswer(questions[11]),
        generateSourceSelectAnswer(questions[12]),
    ];

    // Q5 Optional free text
    //
    if (questions[5].freeTextAnswerList && questions[5].freeTextAnswerList[0]?.value?.length > 0) {
        body = [...body, generateFreeTextAnswer(questions[5])];
    }

    // Q8 FILE_UPLOAD
    if (!isEmpty(questions[7].fileUploadAnswerList)) {
        body = [...body, generateFileUploadAnswer(questions[7])];
    }

    // Q9 MULTI_FREE_TEXT
    const values = [];
    questions[8].freeTextAnswerList?.forEach((ans) => {
        ans.value && ans.value?.length > 0 && values.push(ans.value);
    });
    if (!isEmpty(values)) {
        body = [...body, generateAnswerQ9(questions[8])];
    }

    // Q10 COMBOBOX (Stage)
    if (!isEmpty(questions[9].sourceSelectAnswerList) && questions[9].sourceSelectAnswerList[0].selectedId) {
        body = [...body, generateSourceSelectAnswer(questions[9])];
    }

    return body;
};

export const generateIntellectualPropertyAnswers = (questions) => {
    let body = [];
    body = [
        generateFreeTextAnswer(questions[0]),
        generateSelectionAnswer(questions[1]),
        // Placeholder option comes from BE
        generateSelectionAnswer(questions[3]),
    ];

    // Q16 Optional free text
    if (questions[2].freeTextAnswerList && questions[2].freeTextAnswerList[0]?.value?.length > 0) {
        body = [...body, generateFreeTextAnswer(questions[2])];
    }

    return body;
};

export const generateFounderInfoAnswers = (questions) => {
    let body = [];
    body = [
        // Q18
        generateFreeTextAnswer(questions[0]),
        // Q20
        generateMultiFreeTextAnswer(questions[2]),
        // Q22
        generateSelectionAnswer(questions[4]),
        // Q23
        generateAnswerSelectionWithFreeText(questions[5]),
        // Q24
        generateSelectionAnswer(questions[6]),
        // Q25
        generateAnswerSelectionWithFreeText(questions[7]),
        // Q26
        generateAnswerSelectionWithFreeText(questions[8]),
        // Q27
        generateSelectionAnswer(questions[9]),
    ];

    const q19 = questions[1];

    let linkedInValid = false;
    let githubValid = false;
    const q19FreeTextAnswerList = q19.freeTextAnswerList;
    if (q19FreeTextAnswerList) {
        if (q19FreeTextAnswerList.linkedIn && !isEmpty(q19FreeTextAnswerList.linkedIn)) {
            for (let i = 0; i < q19FreeTextAnswerList.linkedIn.length; i++) {
                const isCurrentValid = q19FreeTextAnswerList.linkedIn[i].value.length > 0;
                linkedInValid = linkedInValid || isCurrentValid;
            }
        }
        if (q19FreeTextAnswerList.github && !isEmpty(q19FreeTextAnswerList.github)) {
            for (let i = 0; i < q19FreeTextAnswerList.github.length; i++) {
                const isCurrentValid = q19FreeTextAnswerList.github[i].value.length > 0;
                githubValid = githubValid || isCurrentValid;
            }
        }
    }
    // Q19 Optional
    if (linkedInValid || githubValid) {
        body = [...body, generateAnswerQ19(q19, linkedInValid, githubValid)];
    }

    // Q21 Optional free text
    if (questions[3].freeTextAnswerList && questions[3].freeTextAnswerList[0]?.value?.length > 0) {
        body = [...body, generateFreeTextAnswer(questions[3])];
    }

    return body;
};

export const generateStructuralInfoAnswers = (questions) => {
    let body = [];
    body = [
        // Q28 - Q31
        generateFreeTextAnswer(questions[0]),
        generateFreeTextAnswer(questions[1]),
        generateFreeTextAnswer(questions[2]),
        generateFreeTextAnswer(questions[3]),
        // Q32
        generateSelectionAnswer(questions[4]),
        // Q33
        generateFreeTextAnswer(questions[5]),
        // Q34
        generateAnswerQ34(questions[6]),
        // Q35
        generateSelectionAnswer(questions[7]),
        // Q36 - Q39
        generateFreeTextAnswer(questions[8]),
        generateFreeTextAnswer(questions[9]),
        generateFreeTextAnswer(questions[10]),
        generateFreeTextAnswer(questions[11]),
    ];

    return body;
};

export const generateOtherInfoAnswers = (questions) => {
    let body = [];
    body = [
        generateAnswerSelectionWithFreeText(questions[0]),
        generateFreeTextAnswer(questions[1]),
        generateSelectionAnswer(questions[2]),
    ];

    // 43 FILE_UPLOAD
    if (!isEmpty(questions[3].fileUploadAnswerList)) {
        body = [...body, generateFileUploadAnswer(questions[3])];
    }

    return body;
};

export const generateApplicationBody = (applicationInfo, questions) => {
    let body = {
        supportApplicantInfo: {},
        questionList: [],
    };

    body.supportApplicantInfo = generateRepInfoData(applicationInfo);
    body.questionList = [
        ...generateGeneralInfoAnswers(questions.slice(0, 13)),
        ...generateIntellectualPropertyAnswers(questions.slice(13, 17)),
        ...generateFounderInfoAnswers(questions.slice(17, 27)),
        ...generateStructuralInfoAnswers(questions.slice(27, 39)),
        ...generateOtherInfoAnswers(questions.slice(39, 43)),
    ];

    return body;
};
