import * as React from 'react';

function SvgIconsSingleCheckBig(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
            <defs>
                <filter id="p8qqj1q2aa" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.088296 0 0 0 0 0.283333 0 0 0 0 0.056667 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g
                            filter="url(#p8qqj1q2aa)"
                            transform="translate(-696 -130) translate(370 90) translate(319 33)"
                        >
                            <g>
                                <path
                                    fill="#3F5162"
                                    fillRule="nonzero"
                                    d="M19.5 33.876L37.5 15.876 35.376 13.752 19.5 29.664 13.236 23.4 11.148 25.524z"
                                    transform="translate(7 7)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleCheckBig;
