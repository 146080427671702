import { makeStyles, Typography, withStyles } from '@material-ui/core';
import { OfflineBoltTwoTone, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    SocialMediaIcon: {
        height: '24px',
        width: '24px',
        marginRight: '8px',
    },
    SocialLink: {
        color: 'inherit',
    },
});

export default useStyles;
