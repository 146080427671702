import React from 'react';
import { Grid } from '@material-ui/core';
import SvgIconsSingleRemove from 'fintech/icons/IconsSingleRemove';
import useStyles from './index.style';
import { useTranslation } from 'react-i18next';

const DeleteButton = ({ onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container className={classes.deleteButtonContainer} onClick={onClick}>
            <Grid item>
                <SvgIconsSingleRemove />
            </Grid>
            <Grid item>{t('startup.generalInfos.documents.table.deleteButton')}</Grid>
        </Grid>
    );
};

export default DeleteButton;
