import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { Grid } from '@material-ui/core';
import Empty from 'fintech/components/util/Empty';
import StartupInvestorsHeader from 'fintech/components/profile/startup/Financials/InvestorsGrid/Header/';
import { useTranslation } from 'react-i18next';
import { dateIsAfter, NUMERIC_FULL_DATE } from 'fintech/utils/DateUtils';
import useStyles from './index.style';
import StartupInvestor from 'fintech/components/profile/startup/Financials/Investor';
import { STATES } from 'fintech/store/actions/ActionTypes';
import InvestorEditModal from 'fintech/components/profile/startup/form/financials/InvestorEditModal';
import {
    fetchStartupOptions,
    fetchInvestorAllInvestments,
    setEstablishmentInvestmentForm,
    sendEstablishmentInvestmentForm,
    resetEstablishmentInvestorForm,
} from 'fintech/store/actions/establishment';
import { updateInvestorInvestmentInfo, deleteInvestorInvestmentInfo } from 'fintech/api/fintechService';
import { IntroCardContent } from 'fintech/components/profile/common';

const InvestmentHistoryGrid = () => {
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const { data: fetchedData, isLoading, isError } = useSelector(
        (state) => state.establishment.investmentPreferences.investments
    );

    const [data, setInvestments] = useState(fetchedData);
    const [openModal, setOpenModal] = useState(false);

    const [actions] = useState({
        fetchInvestmentsData: fetchInvestorAllInvestments,
        resetInvestmentForm: resetEstablishmentInvestorForm,
        setInvestmentForm: setEstablishmentInvestmentForm,
        sendInvestmentForm: sendEstablishmentInvestmentForm,
    });

    const [services] = useState({
        deleteInvestmentInfo: deleteInvestorInvestmentInfo,
        updateInvestmentInfo: updateInvestorInvestmentInfo,
    });

    useEffect(() => {
        setInvestments(fetchedData);
    }, [fetchedData]);

    useEffect(() => {
        dispatch(fetchStartupOptions(keycloak));
    }, []);

    const clickAddHandler = () => setOpenModal(true);
    const cancelModalHandler = () => setOpenModal(false);

    const sortInvestments = (a, b) => {
        if (a.date !== b.date) {
            return dateIsAfter(a.date, b.date, NUMERIC_FULL_DATE) ? 1 : -1;
        } else {
            return a.id > b.id ? 1 : -1;
        }
    };

    let hasData = data?.investments?.length > 0;
    let investmentHistoryGridJSX = null;
    let emptyProps = {};

    const { editable } = data;

    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.financials.investors.errorMessage');
    } else {
        investmentHistoryGridJSX = (
            <Grid
                className={classes.StartupInvestorsGrid}
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
            >
                <StartupInvestorsHeader
                    editable={editable}
                    onClickAdd={clickAddHandler}
                    title={t('profile.establishments.investmentHistory.title')}
                    addNewText={t('profile.establishments.investmentHistory.addNewRecord')}
                />
                <IntroCardContent>{t('profile.establishments.investmentHistory.historyExplanation')}</IntroCardContent>
                {hasData ? (
                    <Grid
                        item
                        className={classes.InvestorsCard}
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                    >
                        {data?.investments
                            .sort((a, b) => sortInvestments(a, b))
                            .map((inv) => (
                                <StartupInvestor
                                    key={inv.id}
                                    id={inv.id}
                                    editable={editable}
                                    style={{ 'max-width': 'none' }}
                                    type={STATES.ESTABLISHMENT}
                                    editDialogContent={t(
                                        'profile.establishments.investmentPreferences.form.deleteWarning',
                                        {
                                            startupName: inv.companyTitle,
                                        }
                                    )}
                                    actions={actions}
                                    services={services}
                                    {...inv}
                                />
                            ))}
                    </Grid>
                ) : (
                    <Grid item>
                        <Empty message={t('profile.financials.investors.emptyMessage')} />
                    </Grid>
                )}
                {editable && (
                    <InvestorEditModal
                        open={openModal}
                        okButtonLabel={t('profile.financials.form.addCompanyLabel')}
                        onCancel={() => cancelModalHandler()}
                        type={STATES.ESTABLISHMENT}
                        actions={actions}
                        services={services}
                    />
                )}
            </Grid>
        );
    }

    return investmentHistoryGridJSX ? investmentHistoryGridJSX : <Empty {...emptyProps} />;
};

export default InvestmentHistoryGrid;
