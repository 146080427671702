import React, { useState } from 'react';
import { Typography, Grid, useMediaQuery, Button, FormControl, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { loadingSpinnerWithTextGeneralTextStyle, mobileMediaQuery } from 'fintech/components/style/common';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'fintech/components/support/program/form/SupportProgramApplication/index.style';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import CfoFormLabel from 'fintech/components/applications/form/common/CfoFormLabel';
import { DOCUMENT_MIME_TYPES } from 'fintech/components/util/FileConstants';
import FileUpload from 'fintech/components/util/FileUpload';
import { ArrowBack } from '@material-ui/icons';
import { SupportProgramApplicationActions } from 'fintech/store/actions/ActionTypes';
import {
    checkboxAction,
    generateApplicationBody,
    isChecked,
    shouldShowInput,
} from 'fintech/components/support/program/utils/SupportProgramApplicationUtils';
import { isEmpty } from 'lodash';
import LegalTextModal from 'fintech/components/applications/modal/LegalTextModal';
import useLegalTextClick from 'fintech/hooks/useLegalTextClick';
import CfoSingleCheckbox from 'fintech/components/ui/form/checkbox/CfoSingleCheckbox';
import * as FintechBeService from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const Other = ({ pageFunctions: { prevPage, nextPage }, formId, secure = false }) => {
    const { t } = useTranslation('supportProgramApplication');
    const { t: t2 } = useTranslation('application');
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const matches = useMediaQuery(mobileMediaQuery);
    const dispatch = useDispatch();
    const { form, hasActiveRequest } = useSelector((state) => ({
        ...state.supportProgramApplication,
        ...state.common,
    }));
    const questions = form.questionFormatList.slice(39, 43);

    const [sending, setSending] = useState(false);

    // Handle legal texts - start
    const [openModal, setOpenModal] = useState(false);
    const [clickedAgreement, setClickedAgreement] = useState(null);

    useLegalTextClick({
        legalClickListener: (e) => {
            if (e?.currentTarget) {
                setClickedAgreement(e?.currentTarget?.id);
                setOpenModal(true);
            }
        },
    });

    const closeModalHandler = () => setOpenModal(false);
    // Handle legal texts - end

    const onCheckboxChange = (event, type, id, question) => {
        dispatch(checkboxAction(event, type, id, question));
    };

    const getCorrespondingInput = (question) => {
        switch (question.questionNumber) {
            case 40: {
                const { answerOptionList } = question.answerDefinitionList[0];
                const error = sending && question.required === 'REQUIRED';
                return (
                    <>
                        <FormControl error={error}>
                            <Grid container className={classes.checkboxWrapper}>
                                {answerOptionList.map(({ id, name }) => (
                                    <Grid key={id} xs={6} item>
                                        <CfoSingleCheckbox
                                            returnEvent
                                            checked={isChecked(question, id)}
                                            onChange={(event) =>
                                                onCheckboxChange(
                                                    event,
                                                    SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q40_P1,
                                                    id,
                                                    question
                                                )
                                            }
                                            label={name}
                                        />
                                    </Grid>
                                ))}
                                {error && (
                                    <Grid xs={12} item>
                                        <FormHelperText className={classes.checkboxHelperWrapper} error>
                                            {t('validation.selectionNotEmpty')}
                                        </FormHelperText>
                                    </Grid>
                                )}
                            </Grid>
                        </FormControl>
                        {shouldShowInput(question) && (
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseExplain')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q40_P2,
                                        data: { value, validated },
                                    });
                                }}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        )}
                    </>
                );
            }
            case 41:
                return (
                    <InputTextArea
                        type="text"
                        placeholder={t('form.pleaseEnter')}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q41,
                                data: { value, validated },
                            });
                        }}
                        rowCount={5}
                        required={question.required === 'REQUIRED'}
                        count={question.answerDefinitionList[0].textLimit ?? 1000}
                        containerClass={classes.inputTextAreaWrapper}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        initText={question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''}
                    />
                );
            case 42: {
                const { answerOptionList } = question.answerDefinitionList[0];
                const error = sending && question.required === 'REQUIRED';
                return (
                    <FormControl error={error}>
                        <Grid container className={classes.checkboxWrapper}>
                            {answerOptionList.map(({ name, id }) => (
                                <Grid key={id} xs={6} item>
                                    <CfoSingleCheckbox
                                        returnEvent
                                        disabled={
                                            !isChecked(question, id) &&
                                            question.selectionAnswerList?.[0]?.selectedAnswerOptionList?.length >= 3
                                                ? true
                                                : false
                                        }
                                        checked={isChecked(question, id)}
                                        onChange={(event) =>
                                            onCheckboxChange(
                                                event,
                                                SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q42,
                                                id,
                                                question
                                            )
                                        }
                                        label={name}
                                    />
                                </Grid>
                            ))}
                            {error && (
                                <Grid xs={12} item>
                                    <FormHelperText className={classes.checkboxHelperWrapper} error>
                                        {t('validation.threeSelections')}
                                    </FormHelperText>
                                </Grid>
                            )}
                        </Grid>
                    </FormControl>
                );
            }
            case 43:
                return (
                    <FileUpload
                        styleClass={classes.fileUploadWrapper}
                        featureEnabled={false}
                        hasNoHorizontalMargin
                        hasNoHorizontalMarginInner
                        fileLabel={t('formLabel.uploadedFiles')}
                        uploadCallback={(files) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q43,
                                data: { files },
                            });
                        }}
                        acceptMimeTypes={DOCUMENT_MIME_TYPES}
                        videoLimit={0}
                        imageLimit={0}
                        // TODO: Ask file limit
                        documentLimit={5}
                        fileLimit={5}
                        initFiles={
                            !isEmpty(question.fileUploadAnswerList)
                                ? question.fileUploadAnswerList.map((ans) => ans.file)
                                : []
                        }
                        areDocuments
                        isPublic={!secure}
                        multiple={false}
                    />
                );
            default:
                return null;
        }
    };

    const isValid = () => {
        return true;
    };
    //Neden böyle yapılmış anlamadık:
    //    questions[0].required === 'OPTIONAL' &&
    //    questions[1].required === 'OPTIONAL' &&
    //    questions[2].required === 'OPTIONAL' &&
    //    questions[3].required === 'OPTIONAL';
    // Validate Q40 && Q41 FREE_TEXT
    // Q40
    // (shouldShowInput(questions[0])
    //     ? questions[0].freeTextAnswerList && questions[0].freeTextAnswerList[0]?.validated
    //     : true) &&
    // // Q41
    // questions[1].freeTextAnswerList &&
    // questions[1].freeTextAnswerList[0]?.validated &&
    // // Validate Q40 & Q42 CHECKBOX
    // !isEmpty(questions[0].selectionAnswerList?.[0]?.selectedAnswerOptionList) &&
    // // Q42
    // !isEmpty(questions[2].selectionAnswerList?.[0]?.selectedAnswerOptionList) &&
    // questions[2].selectionAnswerList[0].selectedAnswerOptionList.length >= 3;

    const clickContinueHandler = async () => {
        setSending(true);

        if (isValid()) {
            dispatch(setActiveRequest(true));
            const requestData = generateApplicationBody(form.supportApplicantInfo, form.questionFormatList);

            let res = null;
            try {
                res = await FintechBeService.supportProgramApply({
                    keycloak,
                    formId,
                    requestData,
                    isOnlyValidate: false,
                });
                dispatch(setActiveRequest(false));
            } catch (reason) {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            if (!res || !res.success) {
                enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            nextPage();
        } else {
            enqueueSnackbar(t('validation.genericMessage'), { variant: 'error' });
        }
    };

    const isLoading = form.isLoading || questions.length < 1;

    return isLoading ? (
        <LoadingSpinnerWithText style={loadingSpinnerWithTextGeneralTextStyle} />
    ) : (
        <CfoFormContainer>
            {matches && <Typography className={classes.pageTitle}>{t('pageTitles.otherLongText')}</Typography>}
            {questions.map((question, index) => (
                <Grid
                    item
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    key={question.questionNumber}
                    style={{ paddingBottom: '24px' }}
                >
                    <CfoFormLabel
                        label={`${question.questionNumber}. ${question.name}`}
                        subText={question.subText}
                        isOptional={question.required === 'OPTIONAL'}
                    />
                    <CfoFormInput style={index === 3 ? { width: '0' } : {}}>
                        {getCorrespondingInput(question)}
                    </CfoFormInput>
                </Grid>
            ))}
            <Grid item container direction="row" justify="flex-end">
                <Grid item xs={12} lg={8}>
                    <div className={classes.legalDiv}>
                        <CfoSingleCheckbox
                            checked={form.readAgreement}
                            onChange={() => {
                                dispatch({
                                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION_AGREEMENTS,
                                });
                            }}
                            label={<Typography dangerouslySetInnerHTML={{ __html: t('legal') }} />}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className={classes.buttonsWrapper}
            >
                <CfoFormInput xs={4} lg={3}>
                    <Button
                        startIcon={matches && <ArrowBack />}
                        className={classes.secondaryButton}
                        onClick={prevPage}
                        fullWidth
                        disabled={hasActiveRequest}
                    >
                        {matches ? t('buttonTexts.returnToPreviousStep') : t('buttonTexts.previousStep')}
                    </Button>
                </CfoFormInput>
                <CfoFormInput xs={7} lg={8}>
                    <Button
                        disabled={hasActiveRequest || !form.readAgreement}
                        className={classes.primaryButton}
                        onClick={clickContinueHandler}
                        fullWidth
                    >
                        {!hasActiveRequest ? t2('common.form.label.sendApplication') : <ButtonCircularIndicator />}
                    </Button>
                </CfoFormInput>
            </Grid>
            <LegalTextModal
                initialTab={clickedAgreement}
                open={openModal}
                onCancel={closeModalHandler}
                supportClarificationText
            />
        </CfoFormContainer>
    );
};

export default Other;
