import React from 'react';
import Divider from '@material-ui/core/Divider';
import useStyles from './SecureSideFooter.style';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_CODES } from 'fintech/constants';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import CBFOLogoTr from 'fintech/static/images/2_satir_dikey-03.png';
import CBFOLogoEn from 'fintech/static/images/ingilizce_dikey-02.png';
import CookieBanner from 'fintech/components/legalText/cookieBanner';

const SecureSideFooter = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const selectedLanguage = getSystemLanguage();
    const logoObj = selectedLanguage === LANGUAGE_CODES.EN ? CBFOLogoEn : CBFOLogoTr;

    return (
        <div className={classes.footerWrapper}>
            <Divider className={classes.footerDivider} />
            <img src={logoObj} className={classes.logo} />
            <div className={`${classes.footerText} ${classes.footerAddressText}`}>{t('footer.address')}</div>
            <div className={classes.footerText}>{t('footer.allRightsReserved')}</div>
            <CookieBanner />
        </div>
    );
};

export default SecureSideFooter;
