import React from 'react';

function SvgIconsSingleCloseWhite(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" {...props}>
            <defs>
                <filter id="fjzqjyy7la" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g
                                filter="url(#fjzqjyy7la)"
                                transform="translate(-836 -1008) translate(350 324) translate(48 672) translate(434.987)"
                            >
                                <g>
                                    <path
                                        fill="#3F5162"
                                        fillRule="nonzero"
                                        d="M12.845 18L12.845 12.845 18 12.845 18 11.155 12.845 11.155 12.845 6 11.155 6 11.155 11.155 6 11.155 6 12.845 11.155 12.845 11.155 18z"
                                        transform="translate(4 12) rotate(45 12 12)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
export default SvgIconsSingleCloseWhite;
