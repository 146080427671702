import * as React from 'react';

function SvgIconsSingleChevronRight(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" {...props}>
            <defs>
                <filter id="k5ihksi41a" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g filter="url(#k5ihksi41a)" transform="translate(-885 -2077) translate(237 1867)">
                        <g>
                            <path
                                fill="#3F5162"
                                fillRule="nonzero"
                                d="M13.536 23.733L20.869 16.4 13.536 9.067 11.805 10.797 17.437 16.4 11.805 22.003z"
                                transform="translate(648 210)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleChevronRight;
