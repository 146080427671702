import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NewInputTextArea from 'fintech/components/ui/form/textinput/NewInputTextArea';
import { StartupApplicationActions } from 'fintech/store/actions/ActionTypes';
import useGeneralInfoStyles from 'fintech/components/applications/form/investor/GeneralInfoForm/index.style';
import { STARTUP_INDIVIDUAL } from 'fintech/components/util/ApplicationFormConstants';
import AlertInfo from '../../common/AlertInfo';

const BasicInfoForm = (props) => {
    const generalInfoClasses = useGeneralInfoStyles();
    const { t } = useTranslation('application');
    const dispatch = useDispatch();
    const { disabled, validationError, setPathIsBlurred } = props;
    const { type, tradeName, companyEmail, companyIdentity } = useSelector((state) => state.startupApplication);

    const titleChangeHandler = (val) => {
        dispatch({
            type: StartupApplicationActions.SET_STARTUP_APPLICATION_TRADE_NAME,
            data: val.toLocaleUpperCase('TR'),
        });
    };

    const idChangeHandler = (val) => {
        dispatch({
            type: StartupApplicationActions.SET_STARTUP_APPLICATION_EQUITY_IDENTITY,
            data: val,
        });
    };

    const emailChangedHandler = (val) => {
        dispatch({
            type: StartupApplicationActions.SET_STARTUP_APPLICATION_EQUITY_EMAIL,
            data: val,
        });
    };

    return (
        <Grid item xs={12} container direction="row" justify="center">
            <Grid item xs={12}>
                <AlertInfo
                    title={
                        type == STARTUP_INDIVIDUAL
                            ? t('startup.form.helper.tooltipIndividual')
                            : t('startup.form.helper.tooltipInstitutional')
                    }
                    subTitle={t('startup.form.helper.tooltipIndividualSubTitle')}
                />
            </Grid>
            <Grid item xs={12}>
                <div>
                    <NewInputTextArea
                        id="tradeName"
                        containerClass={generalInfoClasses.inputTextAreaWrapper}
                        disabled={disabled}
                        type="text"
                        label={t('startup.form.label.tradeName')}
                        maxLength={255}
                        hideCountIndicator
                        validationError={validationError}
                        validationPath="tradeName"
                        value={tradeName}
                        onChange={titleChangeHandler}
                        onBlur={() => setPathIsBlurred('tradeName')}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={generalInfoClasses.divInputLeft}>
                    <NewInputTextArea
                        id="companyIdentity"
                        containerClass={generalInfoClasses.inputTextAreaWrapper}
                        disabled={disabled}
                        type="identity"
                        label={type === STARTUP_INDIVIDUAL ? t('formLabel.idNumber') : t('formLabel.taxIdNumber')}
                        maxLength={type === STARTUP_INDIVIDUAL ? 11 : 10}
                        hideCountIndicator
                        validationError={validationError}
                        validationPath="companyIdentity"
                        value={companyIdentity}
                        onChange={idChangeHandler}
                        onBlur={() => setPathIsBlurred('companyIdentity')}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={generalInfoClasses.divInputRight}>
                    <NewInputTextArea
                        id="companyEmail"
                        containerClass={generalInfoClasses.inputTextAreaWrapper}
                        type="text"
                        label={t('startup.form.label.companyEmail')}
                        validationError={validationError}
                        validationPath="companyEmail"
                        value={companyEmail}
                        onChange={emailChangedHandler}
                        onBlur={() => setPathIsBlurred('companyEmail')}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default BasicInfoForm;
