import { Grid, Link, Typography, Popover, useMediaQuery, useTheme } from '@material-ui/core';
import { CallMade, Edit, Language, Send } from '@material-ui/icons';
import { COLOR_PRIMARY_BASE, mobileMediaQuery } from 'fintech/components/style/common';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import Supported from 'fintech/icons/Supported';
import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import * as FintechBeService from 'fintech/api/fintechService';
import { useSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import useStyles, { shrinkingButtonStyles, shrinkingButtonFontSizeForTablet } from './index.style';
import { getWebsiteUrl } from 'fintech/utils/StringUtils';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import IconsSingleEllipsis3 from 'fintech/icons/IconsSingleEllipsis3';
import IconsSingleArrowRight2 from 'fintech/icons/IconsSingleArrowRight2';
import IconsSingleTooltip28 from 'fintech/icons/IconsSingleTooltip28';
import placeholderStartup from 'fintech/static/images/placeholders-startup3x-rounded.png';
import placeholderInvestor from 'fintech/static/images/placeholders-investor3x-rounded.png';
import placeholderIdea from 'fintech/static/images/placeholders-idea3x-rounded.png';
import { INBOX } from 'fintech/routes';
import { useHistory } from 'react-router';
import Tooltip from 'fintech/components/ui/tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveRequest } from 'fintech/store/actions/common';
import { ExpandableAvatar } from 'fintech/components/profile/common/ExpandableAvatar/ExpandableAvatar';
import { setNewContact } from 'fintech/store/actions/inbox';
import { VENTURE_TYPES } from 'fintech/store/actions/ActionTypes';

const startupLogoUrl = 'api/startup/logo/';
const investorLogoUrl = 'api/investor/logo/';

const IntroHeader = (props) => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const { t, name, isBacked, website, onClickEditCallback, isStartup, fetchedData, isAttempt } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [openBlockApprovePopup, setOpenBlockApprovePopup] = useState(false);
    const [blockedBy, setBlockedBy] = useState(false);
    const [blockedTo, setBlockedTo] = useState(false);
    const [blockInfoIsUnknown, setBlockInfoIsUnknown] = useState(true);
    const [isBackedTooltipOpen, setIsBackedTooltipOpen] = useState(false);
    const isWeb = useMediaQuery(mobileMediaQuery);
    const { hasActiveRequest } = useSelector((state) => state.common);
    const dispatch = useDispatch();

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { representativeUserName } = fetchedData;

    const isIdea =
        fetchedData?.ventureType?.description &&
        (fetchedData?.ventureType?.description === VENTURE_TYPES.IDEA ||
            fetchedData?.ventureType?.description === VENTURE_TYPES.BACKED_IDEA);

    useEffect(() => {
        if (!isIdea) {
            fetchBlockInfo();
        }
    }, []);

    const editable = fetchedData.editable;
    const blockable = fetchedData.blockable;
    const uuid = fetchedData.uuid;
    const serviceFuncName = isAttempt ? 'Startup' : 'Investor';

    const fetchBlockInfo = async () => {
        const funcToBeCalled = FintechBeService[`fetch${serviceFuncName}IsBlocked`];
        funcToBeCalled(keycloak, uuid)
            .then((roleResponse) => {
                if (roleResponse && roleResponse.data) {
                    setBlockedBy(roleResponse.data.blockedBy);
                    setBlockedTo(roleResponse.data.blockedTo);
                    setBlockInfoIsUnknown(false);
                } else {
                    setBlockInfoIsUnknown(false);
                }
            })
            .catch(() => {
                setBlockInfoIsUnknown(false);
            });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEllipsisClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const thisObject = isAttempt ? t('profile.thisAttempt') : t('profile.thisEnterprise');
    const objects = isAttempt ? t('profile.attempts') : t('profile.enterprises');
    const ofThisObject = isAttempt ? t('profile.ofThisAttempt') : t('profile.ofThisEnterprise');
    const ofThisObject1 = isAttempt ? t('profile.ofThisAttempt1') : t('profile.ofThisEnterprise1');
    const currentObject = isAttempt ? t('profile.attempt') : t('profile.enterprise');

    const handleBlockOrUnblockUser = () => {
        dispatch(setActiveRequest(true));
        const functionToBeCalled = blockedBy
            ? FintechBeService[`postUnblock${serviceFuncName}`]
            : FintechBeService[`postBlock${serviceFuncName}`];
        const successMessage = blockedBy
            ? t('profile.success.unblocked', { thingToBeBlocked: ofThisObject1 })
            : t('profile.success.blocked', { blockedThing: currentObject });
        functionToBeCalled(keycloak, uuid)
            .then((response) => {
                dispatch(setActiveRequest(false));
                if (response && response.success && response.data) {
                    enqueueSnackbar(successMessage, { variant: 'success' });
                    fetchBlockInfo();
                    setOpenBlockApprovePopup(false);
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error' });
                }
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason.message, { variant: 'error' });
            });
    };

    const handleShowBlockOrUnblockUserPopup = () => {
        setOpenBlockApprovePopup(true);
        handleClose();
    };

    const handleIsBackedTooltipClose = () => {
        setIsBackedTooltipOpen(false);
    };

    const handleIsBackedTooltipOpen = () => {
        setIsBackedTooltipOpen(true);
    };

    const open = !!anchorEl;

    /* Refs:
     * - https://material-ui.com/guides/composition/#link
     * - https://material-ui.com/guides/composition/#caveat-with-refs
     * - https://reactjs.org/docs/react-api.html#reactforwardref
     */
    // eslint-disable-next-line react/display-name
    // eslint-disable-next-line no-unused-vars
    const WebsiteLinkButton = React.forwardRef((props, ref) => (
        <ShrinkingButton
            {...props}
            shrinkedIcon={<Language fontSize="small" />}
            fullIcon={<CallMade />}
            fullText={t(isAttempt ? 'profile.venture.gotoWebsite' : 'profile.establishments.goToWebsite')}
            otherStyles={shrinkingButtonFontSizeForTablet(theme)}
        >
            {props.children}
        </ShrinkingButton>
    ));

    const blockButtonText = blockedBy
        ? t('profile.unblockUser', { blockedThing: thisObject })
        : t('profile.blockUser', { blockedThing: thisObject });
    const blockDialogTitle = blockedBy
        ? t('profile.approveUnblockTitle', { thingToBeBlocked: ofThisObject })
        : t('profile.approveBlockTitle', { blockedThing: thisObject });
    const blockDialogContent = blockedBy
        ? t('profile.approveUnblockContent', { blockedThings: objects })
        : t('profile.approveBlockContent', { blockedThings: objects });

    const placeholderLogo = isAttempt ? (isStartup ? placeholderStartup : placeholderIdea) : placeholderInvestor;

    return (
        <>
            <Grid
                className={classes.StupHeaderGrid}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={4} lg={6} sm={8}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Grid item>
                            <ExpandableAvatar
                                userServiceUri={isAttempt ? startupLogoUrl : investorLogoUrl}
                                userUuid={fetchedData?.uuid}
                                placeholder={placeholderLogo}
                                editable={editable}
                                profilePictureFile={fetchedData?.logoFile?.uuid}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={isWeb ? 'margin-left-16' : ''} style={{ fontSize: '24px' }}>
                                {name}
                                {` `}
                                {isBacked &&
                                    (isWeb ? (
                                        <Tooltip title={t('profile.isBacked')} aria-label={t('profile.isBacked')}>
                                            <span>
                                                <Supported />
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <ClickAwayListener onClickAway={handleIsBackedTooltipClose}>
                                            <Tooltip
                                                title={t('profile.isBacked')}
                                                aria-label={t('profile.isBacked')}
                                                onClose={handleIsBackedTooltipClose}
                                                open={isBackedTooltipOpen}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                            >
                                                <span onClick={handleIsBackedTooltipOpen}>
                                                    <Supported />
                                                </span>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    ))}
                            </Typography>
                        </Grid>
                        {blockedBy && (
                            <Grid item>
                                <Typography className={classes.blockedByYouText} paragraph>
                                    {'(' + t('profile.blockedByYou', { blockedThing: thisObject }) + ')'}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={8} lg={6} sm={4}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        {/* {!editable && !(blockInfoIsUnknown || blockedBy || blockedTo) && !isIdea && (
                            <Grid item>
                                <ShrinkingButton
                                    shrinkedIcon={<Send fontSize="small" />}
                                    fullIcon={<Send />}
                                    fullText={t('profile.sendMessage')}
                                    style={{ color: '#ffffff' }}
                                    onClick={() => {
                                        dispatch(setNewContact(representativeUserName));
                                        history.push(INBOX);
                                    }}
                                />
                            </Grid>
                        )} */}
                        {website && website.value && (
                            <Grid item>
                                <Link
                                    color="initial"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={website && getWebsiteUrl(website.value)}
                                    component={WebsiteLinkButton}
                                >
                                    {''}
                                </Link>
                            </Grid>
                        )}
                        {editable && (
                            <Grid item>
                                <ShrinkingButton
                                    style={{ marginRight: 10 }}
                                    onClick={() => onClickEditCallback()}
                                    shrinkedIcon={<Edit fontSize="small" style={{ color: COLOR_PRIMARY_BASE }} />}
                                    fullIcon={<Edit style={{ color: COLOR_PRIMARY_BASE }} />}
                                    fullText={t('profile.venture.editCard')}
                                    otherStyles={shrinkingButtonStyles(theme)}
                                />
                            </Grid>
                        )}
                        {/* {blockable && !isIdea && (
                            <Grid item>
                                <>
                                    <div onClick={handleEllipsisClick} className={classes.EllipsisButton}>
                                        <IconsSingleEllipsis3 />
                                    </div>
                                    <Popover
                                        id={'ellipsesButtonPopover'}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        className={classes.ellipsesButtonClass}
                                    >
                                        <div
                                            className={classes.EllipsisPopover}
                                            onClick={handleShowBlockOrUnblockUserPopup}
                                        >
                                            <div className={classes.arrowRightIcon}>
                                                <IconsSingleArrowRight2 />
                                            </div>
                                            <div className={classes.blockUserText}>
                                                <span>{blockButtonText}</span>
                                            </div>
                                        </div>
                                    </Popover>
                                </>
                            </Grid>
                        )} */}
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={openBlockApprovePopup}
                icon={
                    <IconsSingleTooltip28
                        style={{ width: '62px', height: '62px', backgroundColor: '#eeeff1', borderRadius: '50%' }}
                    />
                }
                title={blockDialogTitle}
                content={blockDialogContent}
                labelConfirm={t('profile.approveBlockButton')}
                labelCancel={t('profile.rejectBlockButton')}
                dialogRootClass={classes.dialogRoot}
                confirmDialogContentClass={classes.confirmDialogContent}
                onConfirm={handleBlockOrUnblockUser}
                onCancel={() => {
                    setOpenBlockApprovePopup(false);
                }}
                showTopCloseButton
                confirmDisabled={hasActiveRequest}
                cancelClickDisabled={hasActiveRequest}
                showSpinnerWhenDisabled={hasActiveRequest}
            />
        </>
    );
};

export default withTranslation('profile')(IntroHeader);
