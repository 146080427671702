import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { MoreVert } from '@material-ui/icons';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { StyledListItemIcon, StyledListItemTypo } from 'fintech/components/ui/button/SplitButton/index.style';
import useStyles from './index.style';

import SvgIconsSingleArrowRight from 'fintech/icons/IconsSingleArrowRight';

const StandaloneMoreButton = (props) => {
    const { options, itemsClickable = false, preventDefault = false, onButtonClick, disabled = false } = props;
    const classes = useStyles({ disabled });
    const [subOpen, setSubOpen] = useState(false);
    const itemRef = useRef(null);

    // Subverticals
    const handleSubToggle = (event) => {
        if (!disabled && !preventDefault) {
            setSubOpen((prevOpen) => !prevOpen);
        }
        onButtonClick && onButtonClick(event);
    };

    const handleSubClose = (event) => {
        if (!disabled && !preventDefault) {
            if (itemRef.current && itemRef.current.contains(event.target)) {
                return;
            }

            setSubOpen(false);
        }
    };

    return (
        <>
            <Button ref={itemRef} disableElevation className={classes.OptionsButton} onClick={handleSubToggle}>
                <MoreVert className={classes.MoreVertIcon} />
            </Button>
            <Popper
                style={{ zIndex: '50' }}
                open={subOpen}
                anchorEl={itemRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleSubClose}>
                                <List>
                                    {options.map((opt) => (
                                        <ListItem
                                            id={`faa-${opt.id}`}
                                            key={`faa-${opt.id}`}
                                            className={classes.ListItemButton}
                                            onClick={(e) => {
                                                itemsClickable && opt.onClick && opt.onClick(opt.id, opt.name);
                                                handleSubClose(e);
                                            }}
                                        >
                                            <StyledListItemIcon style={{ marginRight: '10px' }}>
                                                <SvgIconsSingleArrowRight />
                                            </StyledListItemIcon>
                                            <ListItemText>
                                                <StyledListItemTypo>{opt.title}</StyledListItemTypo>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default StandaloneMoreButton;
