import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import CountryUpdate from 'fintech/components/profile/startup/form/intro/CountryUpdate';
import SingleSelect from 'fintech/components/ui/form/select/SingleSelect';
import CfoDatePicker from 'fintech/components/ui/form/picker/CfoDatePicker';
import PhoneNumberInput from 'fintech/components/ui/form/PhoneNumberInput';
import * as constants from 'fintech/components/util/ApplicationFormConstants';
import useStyles from './index.style';
import { ISO_FORMAT } from 'fintech/utils/DateUtils';
import { parse } from 'date-fns';

/**
 * Common form component for person information in application pages.
 * Currently assumes all inputs have the same behavior. (required, validateOnBlur, validatedAfterFormSubmit)
 *
 * If a component needs to behave differently from others (Not required for example), you can define a new
 * prop for that specific field.
 */
const PersonForm = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const emptyOptions = {
        data: [],
        isLoading: true,
        isError: false,
    };
    const {
        changeCallback,
        initFormData = {},
        sending,
        selectOptions = {
            countries: {
                ...emptyOptions,
            },
            genders: {
                ...emptyOptions,
            },
            educationLevels: {
                ...emptyOptions,
            },
        },
        refresh,
        trackForRefresh,
        dateFormat = ISO_FORMAT,
        ...others
    } = props;

    const [name, setName] = useState(initFormData.name);
    const [surname, setSurname] = useState(initFormData.surname);
    const [countries, setCountry] = useState(initFormData.countries);
    const [idNumber, setIdNumber] = useState(initFormData.idNumber);
    const [genderOptions, setGenderOptions] = useState(emptyOptions);
    const [gender, setGender] = useState(initFormData.gender);
    const [birthDate, setBirthDate] = useState(initFormData.birthDate);
    const [email, setEmail] = useState(initFormData.email);
    const [phoneNumber, setPhoneNumber] = useState(initFormData.phoneNumber);
    const [experience, setExperience] = useState(initFormData.experience);
    const [jobTitle, setJobTitle] = useState(initFormData.jobTitle);
    const [educationOptions, setEducationOptions] = useState(emptyOptions);
    const [education, setEducation] = useState(initFormData.education);

    useEffect(() => {
        setCountry(selectOptions.countries?.data);
    }, [selectOptions.countries]);

    useEffect(() => {
        selectOptions.genders && setGenderOptions(selectOptions.genders);
    }, [selectOptions.genders]);

    useEffect(() => {
        selectOptions.educationLevels && setEducationOptions(selectOptions.educationLevels);
    }, [selectOptions.educationLevels]);

    useEffect(() => {
        // Currently refreshes values only for member application.
        // Can be extended if needed.
        if (refresh) {
            setName(initFormData.name);
            setSurname(initFormData.surname);
            setEmail(initFormData.email);
            setIdNumber(initFormData.idNumber);
            setGender(initFormData.gender);
            setBirthDate(initFormData.birthDate);
            setPhoneNumber(initFormData.phoneNumber);
            setExperience(initFormData.experience);
            setEducation(initFormData.education);
        }
    }, [trackForRefresh]);

    const nameChangedHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setName(newState);
        changeCallback({ type: constants.PERSON_FORM_NAME_UPDATE, data: newState });
    };

    const surnameChangedHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setSurname(newState);
        changeCallback({ type: constants.PERSON_FORM_SURNAME_UPDATE, data: newState });
    };

    const countryChangeHandler = (val, selected) => {
        setCountry(val);
        changeCallback({
            type: constants.PERSON_FORM_COUNTRY_UPDATE,
            data: {
                value: {
                    id: selected?.id,
                    data: selected?.data,
                },
                validated: selected && true,
            },
        });
    };

    const idChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setIdNumber(newState);
        changeCallback({ type: constants.PERSON_FORM_IDENTITY_UPDATE, data: newState });
    };

    const genderChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setGender(newState);
        changeCallback({ type: constants.PERSON_FORM_GENDER_UPDATE, data: newState });
    };

    const dateChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setBirthDate(newState);
        changeCallback({ type: constants.PERSON_FORM_BIRTHDATE_UPDATE, data: newState });
    };

    const emailChangedHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setEmail(newState);
        changeCallback({ type: constants.PERSON_FORM_EMAIL_UPDATE, data: newState });
    };

    const phoneNumberChangedHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setPhoneNumber(newState);
        changeCallback({ type: constants.PERSON_FORM_PHONE_NUMBER_UPDATE, data: newState });
    };

    const experienceChangedHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setExperience(newState);
        changeCallback({ type: constants.PERSON_FORM_EXPERIENCE_UPDATE, data: newState });
    };

    const jobTitleChangedHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setJobTitle(newState);
        changeCallback({ type: constants.PERSON_FORM_JOB_TITLE_UPDATE, data: newState });
    };

    const educationChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setEducation(newState);
        changeCallback({ type: constants.PERSON_FORM_EDUCATION_UPDATE, data: newState });
    };

    let educationVal = null;
    if (education && education.value && education.value.id) {
        educationVal = educationOptions.data.find((e) => education.value.id === e.id);
    }

    let genderVal = null;
    if (gender && gender.value && gender.value.id) {
        genderVal = genderOptions.data.find((e) => gender.value.id === e.id);
    }

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item xs={12} container direction="row" justify="center">
                <Grid item xs={12} md={6}>
                    <div className={classes.divInput}>
                        <InputTextArea
                            disabled={others.nameDisabled}
                            containerClass={classes.inputTextAreaWrapper}
                            type="text"
                            label={t('formLabel.name')}
                            validationText={t('validation.notEmpty')}
                            count={100}
                            hideCountIndicator
                            initText={name?.value}
                            onChange={nameChangedHandler}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.divInput}>
                        <InputTextArea
                            disabled={others.surnameDisabled}
                            containerClass={classes.inputTextAreaWrapper}
                            type="text"
                            label={t('formLabel.surname')}
                            validationText={t('validation.notEmpty')}
                            count={100}
                            hideCountIndicator
                            initText={surname?.value}
                            onChange={surnameChangedHandler}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" justify="center">
                <Grid item xs={12} md={6}>
                    <div className={classes.divInputSelect}>
                        <CountryUpdate
                            label={t('formLabel.nationality')}
                            countries={countries}
                            isError={selectOptions.countries?.isError}
                            changeCallback={countryChangeHandler}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.divInput}>
                        <InputTextArea
                            disabled={others.idNumberDisabled}
                            containerClass={classes.inputTextAreaWrapper}
                            type="tckn"
                            label={t('formLabel.idNumber')}
                            validationText={idNumber?.value ? t('validation.tckn') : t('validation.notEmpty')}
                            initText={idNumber?.value}
                            onChange={idChangeHandler}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.divInputSelect}>
                        <SingleSelect
                            disabled={others.genderDisabled}
                            label={t('formLabel.gender')}
                            options={genderOptions.data}
                            initValue={genderVal}
                            isError={genderOptions.isError}
                            onChange={genderChangeHandler}
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.divInput}>
                        <CfoDatePicker
                            dateFormat={dateFormat}
                            disabled={others.birthDateDisabled}
                            containerClass={classes.inputTextAreaWrapper}
                            id={'formLabel.birthDate'}
                            label={t('formLabel.birthDate')}
                            invalidDateMessage={birthDate?.value ? t('validation.date') : t('validation.notEmpty')}
                            initialDate={birthDate?.value && parse(birthDate.value, dateFormat, new Date())}
                            onChange={dateChangeHandler}
                            autoOk
                            openTo="year"
                            disableFuture
                            maxDateMessage={t('validation.noFutureDate')}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.divInput}>
                        <InputTextArea
                            disabled={others.emailDisabled}
                            containerClass={classes.inputTextAreaWrapper}
                            type="mail"
                            label={t('formLabel.email')}
                            validationText={email?.value ? t('validation.email') : t('validation.notEmpty')}
                            initText={email?.value}
                            onChange={emailChangedHandler}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={`${classes.divInputSelect} full-width`}>
                        <PhoneNumberInput
                            disabled={others.phoneNumberDisabled}
                            phoneNumber={phoneNumber?.value}
                            onChange={phoneNumberChangedHandler}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.divInput}>
                        <InputTextArea
                            containerClass={classes.inputTextAreaWrapper}
                            disabled={others.experienceDisabled}
                            type="integer-positive"
                            includeZero
                            label={t('formLabel.experience')}
                            validationText={t('validation.notEmpty')}
                            initText={experience?.value}
                            onChange={experienceChangedHandler}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </div>
                </Grid>
                {!others.hideJobTitle && (
                    <Grid item xs={12} md={6}>
                        <div className={classes.divInput}>
                            <InputTextArea
                                containerClass={classes.inputTextAreaWrapper}
                                type="text"
                                label={t('formLabel.jobTitle')}
                                validationText={t('validation.notEmpty')}
                                initText={jobTitle?.value}
                                onChange={jobTitleChangedHandler}
                                required
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                            />
                        </div>
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <div className={classes.divInputSelect}>
                        <SingleSelect
                            disabled={others.educationDisabled}
                            label={t('formLabel.education')}
                            options={educationOptions.data}
                            initValue={educationVal}
                            isError={educationOptions.isError}
                            onChange={educationChangeHandler}
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box component="div" height="2px" width="2px" />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PersonForm;
