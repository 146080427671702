import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    consultantWrapper: {
        marginTop: 35,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    definition: {
        display: 'block',
        textAlign: 'center',
        fontSize: 16,
        color: '#3f5162',
        marginBottom: '60px',
    },
    applyButton: {
        [theme.breakpoints.down('sm')]: {
            padding: '8px 35px !important',
        },
        '@media (max-width:320px)': {
            fontSize: 11,
        },
    },
}));

export default useStyles;
