import React, { useEffect, useState } from 'react';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import PropTypes from 'prop-types';
import 'pure-react-carousel/dist/react-carousel.es.css';
import useWindowSize from 'fintech/hooks/useWindowSize';
import * as EventCardUtils from 'fintech/components/dashboard/event/eventcardgrid/EventCardUtils';
import { mobileSizeForCarousel } from 'fintech/components/dashboard/event/eventcardgrid/EventCardUtils';
import ProgramCard from '../ProgramCard';

const ProgramCardGrid = (props) => {
    const { showAdd, data = [] } = props;
    const [visibleSlides, setVisibleSlides] = useState(4);
    const windowSize = useWindowSize();
    const [showNextButton, setShowNextButton] = useState();

    useEffect(() => {
        setShowNextButton(EventCardUtils.isNextButtonVisible(windowSize, data.length));
    }, [windowSize, data]);

    let programSlides = [];

    const programCards = data?.map((event) => <ProgramCard item key={event.id} {...event} />);

    const programJSX = showAdd ? [newCardJSX, ...programCards] : programCards;

    programSlides = programJSX.map((programJSX, index) => {
        const style = windowSize.width < mobileSizeForCarousel ? null : { maxWidth: 300 };
        return (
            <Slide style={style} key={index} index={index}>
                {programJSX}
            </Slide>
        );
    });

    useEffect(() => {
        setVisibleSlides(EventCardUtils.getNumberOfVisibleSlides(windowSize));
        setShowNextButton(EventCardUtils.isNextButtonVisible(windowSize, programSlides.length));
    }, [windowSize]);

    const getCarousel = () => {
        return (
            <>
                <CarouselProvider
                    className={'eventCardCarousel'}
                    style={{ marginTop: 26 }}
                    naturalSlideWidth={285}
                    naturalSlideHeight={344}
                    totalSlides={programSlides.length}
                    visibleSlides={visibleSlides}
                >
                    <div className={'sliderContainer'}>
                        <Slider style={{ height: 360 }}>{programSlides}</Slider>
                        {windowSize.width > mobileSizeForCarousel ? (
                            <>
                                <ButtonBack className={'sliderButton sliderButtonBack'}>{'<'}</ButtonBack>
                                {showNextButton ? (
                                    <ButtonNext className={'sliderButton sliderButtonNext'}>{'>'}</ButtonNext>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                </CarouselProvider>
            </>
        );
    };

    return <>{getCarousel()}</>;
};

ProgramCardGrid.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string,
        })
    ),
};

export default ProgramCardGrid;
