import * as React from 'react';

function SvgIconsMultipleMenu3(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            {...props}
        >
            <defs>
                <path id="3pjss6qxwa" d="M0 18L18 18 18 0 0 0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g transform="translate(-1090 -175) translate(1071 77) translate(0 88) translate(19 9.5) translate(0 1)">
                                    <path
                                        fill="#E8E6E4"
                                        d="M1.688 4.781V4.22c0-.31-.252-.563-.563-.563H.562c-.155 0-.28.126-.28.281v2.25l1.405-1.406z"
                                    />
                                    <path
                                        fill="#D9DCE0"
                                        d="M6.75 18L11.25 18 11.25 15.469 6.75 15.469zM16.594 5.063L13.78 7.874H4.22L1.406 5.062.281 6.188l3.656 3.657h1.407c.465 0 .843.378.843.844v4.78h5.625v-4.78c0-.466.379-.844.844-.844h1.406l3.657-3.656-1.125-1.125z"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M8.719 18L9.281 18 9.281 16.313 8.719 16.313zM6.75 15.75L11.25 15.75 11.25 15.188 6.75 15.188z"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#E8E6E4"
                                        d="M16.313 4.781V4.22c0-.31.252-.563.562-.563h.563c.155 0 .28.126.28.281v2.25l-1.405-1.406z"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M11.531 18h-.562v-2.813h.562v-4.5c0-.62.505-1.124 1.125-1.124h1.29l3.492-3.492V3.938h-.563c-.155 0-.281.126-.281.28h-.563c0-.464.379-.843.844-.843h.563c.31 0 .562.252.562.563v2.366l-3.821 3.821h-1.523c-.31 0-.562.252-.562.563v5.062h-.563V18z"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M16.313 5.344c-.156 0-.282-.126-.282-.282V4.22c0-.155.126-.282.282-.282.155 0 .28.127.28.282v.843c0 .156-.125.282-.28.282M1.688 5.344c-.156 0-.282-.126-.282-.282V4.22c0-.155.126-.282.282-.282.155 0 .28.127.28.282v.843c0 .156-.125.282-.28.282"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M7.031 18H6.47v-2.25h-.563v-5.062c0-.31-.252-.563-.562-.563H3.82L0 6.304V3.937c0-.31.252-.562.563-.562h.562c.465 0 .844.379.844.844h-.563c0-.155-.126-.282-.281-.282H.562v2.134l3.492 3.492h1.29c.62 0 1.125.504 1.125 1.125v4.5h.562V18z"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M4.781 11.813L5.344 11.813 5.344 11.25 4.781 11.25zM4.781 12.938L5.344 12.938 5.344 12.375 4.781 12.375zM12.656 14.625L13.219 14.625 13.219 14.063 12.656 14.063zM12.656 15.75L13.219 15.75 13.219 15.188 12.656 15.188z"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#D9DCE0"
                                        d="M12.094 3.094C12.094 1.385 10.709 0 9 0 7.291 0 5.906 1.385 5.906 3.094c0 1.969 1.688 2.25 1.688 4.219h2.812c0-1.97 1.688-2.25 1.688-4.22"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        fillOpacity=".52"
                                        d="M10.125 10.125L9.563 10.688 8.438 10.688 7.875 10.125 7.875 7.875 10.125 7.875z"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M7.313 7.875L10.688 7.875 10.688 7.313 7.313 7.313zM7.313 9L10.688 9 10.688 8.438 7.313 8.438zM7.313 10.125L10.688 10.125 10.688 9.563 7.313 9.563z"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M8.719 7.594L9.281 7.594 9.281 3.094 8.719 3.094z"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#FFF"
                                        d="M9.844 3.094c0 .466-.378.844-.844.844-.466 0-.844-.378-.844-.844 0-.466.378-.844.844-.844.466 0 .844.378.844.844"
                                        mask="url(#kl0l707lvb)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M9 2.531c-.31 0-.563.253-.563.563 0 .31.253.562.563.562.31 0 .563-.252.563-.562 0-.31-.253-.563-.563-.563M9 4.22c-.62 0-1.125-.505-1.125-1.125S8.38 1.969 9 1.969s1.125.504 1.125 1.125c0 .62-.505 1.125-1.125 1.125"
                                        mask="url(#kl0l707lvb)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleMenu3;
