export const WAITING_ID = 1;
export const APPROVE_REQUEST_ID = 2;
export const REJECT_REQUEST_ID = 3;
export const CANCEL_REQUEST_ID = 4;
export const WAITING = 'WAITING';
export const APPROVE_REQUEST = 'APPROVE_REQUEST';
export const REJECT_REQUEST = 'REJECT_REQUEST';
export const CANCEL_REQUEST_BY_USER = 'CANCEL_REQUEST_BY_USER';
export const CANCEL_REQUEST_BY_CONSULTANT = 'CANCEL_REQUEST_BY_CONSULTANT';

export const MEETING_OPERATION_TYPE = {
    readDetailAndAccept: 'readDetailAndAccept',
    reject: 'reject',
    cancel: 'cancel',
};

export const isMeetingTypeRejectOrCancel = (meetingType) => {
    return meetingType === MEETING_OPERATION_TYPE.reject || meetingType === MEETING_OPERATION_TYPE.cancel;
};
