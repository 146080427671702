import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { LocalOffer, MoreVert, CloseOutlined } from '@material-ui/icons';
import { List, ListItem, ListItemText, useMediaQuery } from '@material-ui/core';
import useStyles, {
    StyledListItemIcon,
    StyledSplitBtnTypo,
    StyledListItemTypo,
    StyledListHeaderTypo,
} from './index.style';

import { COLOR_SECONDARY_BASE, mobileMediaQuery, popperStyle } from 'fintech/components/style/common';

const SplitButton = (props) => {
    const matches = useMediaQuery(mobileMediaQuery);
    const [isMobile, setIsMobile] = useState(!matches);
    const {
        t,
        option,
        subOptions,
        subCategory,
        headerMsg,
        collapseAlways,
        type,
        startIcon,
        idPrefix,
        removeItemHandler,
        removeComponentHandler,
        groupStyle = {},
        stopPropagation = false,
    } = props;
    const classes = useStyles({ type: type });
    const [verOpen, setVerOpen] = useState(false);
    const [subOpen, setSubOpen] = useState(false);
    const subverticalRef = useRef(null);

    useEffect(() => {
        setIsMobile(!matches);
    }, [matches]);

    // Verticals
    const handleVerToggle = (open) => {
        setVerOpen((collapseAlways || isMobile) && open);
    };

    // Subverticals
    const handleSubToggle = (event) => {
        stopPropagation && event.stopPropagation();
        setSubOpen((prevOpen) => !prevOpen);
    };

    const handleSubClose = (event) => {
        if (subverticalRef.current && subverticalRef.current.contains(event.target)) {
            return;
        }

        setSubOpen(false);
    };

    const handleRemove = (event) => {
        stopPropagation && event.stopPropagation();
        removeComponentHandler(idPrefix, subCategory);
    };

    return (
        <>
            <ButtonGroup
                className={classes.SplitButtonGroup}
                style={{ ...groupStyle }}
                variant="contained"
                ref={subverticalRef}
            >
                <Button
                    onMouseEnter={() => handleVerToggle(true)}
                    onMouseLeave={() => handleVerToggle(false)}
                    style={collapseAlways || isMobile ? { maxWidth: '150px' } : null}
                    disableElevation
                    disableRipple
                    disableTouchRipple
                    className={classes.SplitButtonTag}
                    startIcon={startIcon}
                >
                    {type === 'filter' && (
                        <CloseOutlined
                            onClick={handleRemove}
                            style={{ marginRight: '1rem', cursor: 'pointer' }}
                            fontSize="small"
                            color="primary"
                        ></CloseOutlined>
                    )}
                    <StyledSplitBtnTypo noWrap>{option}</StyledSplitBtnTypo>
                </Button>
                {subOptions && (
                    <Button disableElevation className={classes.SubVerticalsButton} onClick={handleSubToggle}>
                        <MoreVert className={classes.MoreVertIcon} />
                    </Button>
                )}
            </ButtonGroup>
            <Popper
                style={{ zIndex: '50' }}
                open={subOpen}
                anchorEl={subverticalRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleSubClose}>
                                <List>
                                    <ListItem>
                                        <StyledListHeaderTypo>
                                            {headerMsg ? headerMsg : t('profile.venture.subVerticals')}
                                        </StyledListHeaderTypo>
                                    </ListItem>
                                    {subOptions && renderSubOptions(idPrefix, type, subOptions, removeItemHandler)}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <Popper
                style={{ zIndex: '50' }}
                open={verOpen}
                anchorEl={subverticalRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <div style={{ ...popperStyle }}>{option}</div>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

const renderSubOptions = (idPrefix, type, subOptions, removeItemHandler) => {
    if (type === 'filter') {
        const handleItemOnClick = (event) => {
            const listItem = event.target.closest('li');
            if (listItem && listItem.id) {
                const splittedID = event.target.closest('li').id.split('-');
                const verticalPrefix = splittedID[1];
                const itemID = splittedID[2];

                if (itemID === 'null') {
                    removeItemHandler(verticalPrefix);
                } else {
                    removeItemHandler(verticalPrefix, +itemID);
                }
            }
        };

        return subOptions.map((sub) => (
            <ListItem
                id={`afsb-${idPrefix}-${sub.id}`}
                key={`afsb-${idPrefix}-${sub.id}`}
                style={{ cursor: 'pointer' }}
                onClick={handleItemOnClick}
            >
                <StyledListItemIcon>
                    <CloseOutlined fontSize="small" color="primary" />
                </StyledListItemIcon>
                <ListItemText>
                    <StyledListItemTypo>{sub.data}</StyledListItemTypo>
                </ListItemText>
            </ListItem>
        ));
    } else {
        return subOptions.map((sub) => (
            <ListItem key={sub}>
                <StyledListItemIcon style={{ color: COLOR_SECONDARY_BASE }}>
                    <LocalOffer fontSize="small" />
                </StyledListItemIcon>
                <ListItemText>
                    <StyledListItemTypo>{sub}</StyledListItemTypo>
                </ListItemText>
            </ListItem>
        ));
    }
};

SplitButton.defaultProps = {
    type: 'default',
    startIcon: <LocalOffer />,
};

export default withTranslation('profile')(SplitButton);
