import { EventActions } from 'fintech/store/actions/ActionTypes';

const initialState = {
    events: [],
    otherEvents: [],
    viewedEvent: null,
    isLoading: true,
    isError: false,
};

const event = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case EventActions.SET_EVENT:
            const { events } = action;
            return {
                ...state,
                events: events,
                isLoading: false,
            };
        case EventActions.SET_OTHER_EVENTS:
            const { otherEvents } = action;
            return {
                ...state,
                otherEvents: otherEvents,
                isLoading: false,
            };
        case EventActions.SET_VIEWED_EVENT:
            const { viewedEvent } = action;
            return {
                ...state,
                viewedEvent: viewedEvent,
                isLoading: false,
            };
        case EventActions.SET_EVENT_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case EventActions.SET_EVENT_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            return state;
    }
};

export default event;
