import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { REJECT, SUBMIT } from 'fintech/components/util/ActionModalConstants';
import ActionModal from 'fintech/components/util/ActionModal';
import { useMediaQuery } from '@material-ui/core';
import { laptopLarge } from 'fintech/components/style/common';
import * as FintechBeService from 'fintech/api/fintechService';
import {
    STARTUP_FINANCIALS_REQUEST_UPDATE,
    STARTUP_FINANCIALS_MARKET_VALUE_UPDATE,
    STARTUP_FINANCIALS_INCOME_MODEL_UPDATE,
} from 'fintech/components/util/ActionModalConstants';
import FinancialsActionModal from './ActionModal';
import { fetchStartupFinancialsData } from 'fintech/store/actions/startup';
import { omit } from 'lodash';
import { setActiveRequest } from 'fintech/store/actions/common';

const FinancialsEditModal = (props) => {
    const { open, onCancel } = props;
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const { uuid } = useSelector((state) => state.startup.intro.data);
    const { id, acceptOffers, marketValue } = useSelector((state) => state.startup.financials.info.data);
    const { hasActiveRequest } = useSelector((state) => state.common);
    const { isError: incomeError } = useSelector((state) => state.startup.form.incomeModelData);
    const dispatch = useDispatch();

    const matches = useMediaQuery(laptopLarge);
    const modalSize = matches ? { minWidth: '700px', minHeight: '690px' } : {};

    const [formData, setFormData] = useState({
        id: id,
        acceptOffers: acceptOffers,
        incomeModelData: [],
        incomeValid: false,
        marketValue: marketValue
            ? {
                  ...marketValue,
                  validated: true,
              }
            : {
                  value: '',
                  currencyId: 1,
                  validated: false,
              },
        sending: false,
    });

    const formDataChangeHandler = ({ type, data }) => {
        setFormData((prevState) => {
            switch (type) {
                case STARTUP_FINANCIALS_REQUEST_UPDATE:
                    return {
                        ...prevState,
                        acceptOffers: data.checked,
                    };
                case STARTUP_FINANCIALS_MARKET_VALUE_UPDATE: {
                    return {
                        ...prevState,
                        marketValue: {
                            ...prevState.marketValue,
                            ...data.marketValue,
                        },
                    };
                }
                case STARTUP_FINANCIALS_INCOME_MODEL_UPDATE: {
                    return {
                        ...prevState,
                        incomeModelData: data.incomeModelData,
                        incomeValid: data.incomeValid,
                    };
                }
                default:
                    return prevState;
            }
        });
    };

    const editModalActionHandler = (action) => {
        const { type } = action;

        if (type === SUBMIT) {
            updateData();
        } else if (type === REJECT) {
            rejectData();
        } else {
            return;
        }
    };

    const updateData = () => {
        setFormData((prevState) => ({
            ...prevState,
            sending: true,
        }));

        if (!validateForm()) {
            return;
        }

        dispatch(setActiveRequest(true));
        const requestData = {
            id: formData.id,
            acceptOffers: formData.acceptOffers,
            incomeModels: formData.incomeModelData.map((inc) => ({
                id: inc.id,
                name: inc.data,
            })),
            marketValue: omit(formData.marketValue, ['isValid']),
        };

        FintechBeService.updateStartupFinancialsInfo(keycloak, requestData, uuid)
            .then(() => {
                dispatch(setActiveRequest(false));
                dispatch(fetchStartupFinancialsData(keycloak, uuid));
                onCancel();
                enqueueSnackbar(t('idea.updateSucces'), { variant: 'success' });
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            });
    };

    const rejectData = () => {
        setFormData((prevState) => ({
            ...prevState,
            sending: false,
        }));
        onCancel();
    };

    const validateForm = () => formData.marketValue.validated && formData.incomeValid;

    return (
        <ActionModal
            open={open}
            title={t(`profile.financials.form.financialsEdit`)}
            onAction={editModalActionHandler}
            modalSize={modalSize}
            okClickDisabled={hasActiveRequest || incomeError}
            cancelClickDisabled={hasActiveRequest}
            showSpinnerWhenDisabled={hasActiveRequest}
        >
            <FinancialsActionModal
                initialRequest={acceptOffers}
                initialMarketValue={marketValue}
                changeCallback={formDataChangeHandler}
                sending={formData.sending}
            />
        </ActionModal>
    );
};

export default FinancialsEditModal;
