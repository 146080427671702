import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_BASE } from 'fintech/components/style/common';

const useStyles = makeStyles({
    checkbox: {
        '&.MuiCheckbox-colorPrimary.Mui-checked': {
            color: ({ checkedColor }) => (checkedColor ? checkedColor : COLOR_PRIMARY_BASE),
        },
    },
});

const CfoSingleCheckbox = (props) => {
    const { label, checked, onChange, disabled, checkedColor, returnEvent = false } = props;
    const classes = useStyles({ checkedColor });

    const changeHandler = (e) => {
        onChange && onChange(returnEvent ? e : e?.target?.checked);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    className={classes.checkbox}
                    disabled={disabled}
                    checked={checked}
                    onChange={changeHandler}
                    color="primary"
                />
            }
            label={label}
        />
    );
};

CfoSingleCheckbox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    checkedColor: PropTypes.string,
};

export default CfoSingleCheckbox;
