import useWindowSize from 'fintech/hooks/useWindowSize';
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';

export default function useTableScroll(track) {
    const [isScrollable, setIsScrollable] = useState(false);

    const window = useWindowSize();

    const checkScrollable = () => {
        const tableWrapperSize = document.getElementById('sticky-table-custom-container')?.getBoundingClientRect();
        const elements = document.getElementsByClassName('sticky-table-table');

        if (tableWrapperSize && !isEmpty(elements)) {
            const tableSize = elements[0]?.getBoundingClientRect();
            setIsScrollable(tableSize.width > tableWrapperSize.width);
        }
    };

    useEffect(() => {
        checkScrollable();
    }, track);

    useEffect(() => checkScrollable(), [window]);

    return isScrollable;
}
