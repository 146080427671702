import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import { useTranslation } from 'react-i18next';
import PhoneNumberInput from 'fintech/components/ui/form/PhoneNumberInput';
import { DialogContent } from '@material-ui/core';
import VisibilityToggle from 'fintech/components/ui/form/toggle/VisibilityToggle';

export default function GeneralInfoTab({ initData }) {
    const { t } = useTranslation();
    const [biography, setBiography] = useState(initData ? initData.biography : {});
    if (initData && biography !== initData.biography) {
        setBiography(initData.biography);
    }

    const [mailAddress, setMailAddress] = useState(initData ? initData.mailAddress : {});
    if (initData && mailAddress !== initData.mailAddress) {
        setMailAddress(initData.mailAddress);
    }

    const [personalWebsite, setPersonalWebsite] = useState(initData ? initData.personalWebsite : {});
    if (initData && personalWebsite !== initData.personalWebsite) {
        setPersonalWebsite(initData.personalWebsite);
    }

    const [phoneNumber, setPhoneNumber] = useState(initData ? initData.phoneNumber : {});
    if (initData && phoneNumber !== initData.phoneNumber) {
        setPhoneNumber(initData.phoneNumber);
    }
    const biographyChange = (val, validated) => {
        const tmpBiography = biography;
        tmpBiography.value = val ? val : '';
        tmpBiography.validated = validated;
        setBiography(tmpBiography);
    };

    const mailAddressChange = (val, validated) => {
        const tmpEmail = mailAddress;
        tmpEmail.value = val ? val : '';
        tmpEmail.validated = validated;
        setMailAddress(tmpEmail);
    };

    const personalWebsiteChange = (val, validated) => {
        const tmpPersonalWebsite = personalWebsite;
        tmpPersonalWebsite.value = val ? val : '';
        tmpPersonalWebsite.validated = validated;
        setPersonalWebsite(tmpPersonalWebsite);
    };

    const phoneNumberChange = (value, valid) => {
        const tmpPhoneNumber = phoneNumber;
        tmpPhoneNumber.value = value;
        tmpPhoneNumber.validated = valid;
        setPhoneNumber(tmpPhoneNumber);
    };

    const emailVisibilityHandler = (val) => {
        const tmpMail = mailAddress;
        tmpMail.public = val;
        setMailAddress(tmpMail);
    };

    const phoneNumberVisibilityChange = (val) => {
        const tmpPhoneNumber = phoneNumber;
        tmpPhoneNumber.public = val;
        setPhoneNumber(tmpPhoneNumber);
    };

    return (
        <Grid container direction={'row'}>
            <Grid xs={12} item className={'full-width'}>
                <InputTextArea
                    count={3000}
                    initText={biography ? biography.value : ''}
                    label={t('profile.user.biography')}
                    onChange={(val, validated) => biographyChange(val, validated)}
                    rowCount={4}
                    type={'text'}
                    required={true}
                    validationText={t('validation.notEmpty')}
                />
            </Grid>
            <Grid xs={6} item className={'full-width'}>
                <Grid container direction="column">
                    <Grid item>
                        <InputTextArea
                            initText={mailAddress.value}
                            label={t('profile.user.email')}
                            onChange={(val, validated) => mailAddressChange(val, validated)}
                            rowCount={1}
                            isCleanText
                            type={'mail'}
                            required={true}
                            validationText={t('validation.notEmpty')}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <DialogContent style={{ paddingTop: '0', textAlign: 'end' }}>
                            <VisibilityToggle initialValue={mailAddress.public} onChange={emailVisibilityHandler} />
                        </DialogContent>
                    </Grid>
                </Grid>
            </Grid>

            <Grid xs={6} item className={'full-width'}>
                <InputTextArea
                    initText={personalWebsite ? personalWebsite.value : ''}
                    label={t('profile.user.personalWebsite')}
                    onChange={(val, validated) => personalWebsiteChange(val, validated)}
                    rowCount={1}
                    isCleanText
                    type={'url'}
                    required={false}
                    validationText={t('validation.url')}
                />
            </Grid>
            <Grid
                xs={6}
                item
                className={'full-width'}
                style={{
                    padding: '8px 24px',
                }}
            >
                <Grid container direction="column">
                    <Grid item>
                        <PhoneNumberInput
                            validateOnBlur
                            phoneNumber={phoneNumber.value}
                            required
                            onChange={phoneNumberChange}
                        />
                    </Grid>
                    <Grid item>
                        <DialogContent style={{ paddingTop: '0', textAlign: 'end', paddingRight: '0' }}>
                            <VisibilityToggle
                                initialValue={phoneNumber.public}
                                onChange={phoneNumberVisibilityChange}
                            />
                        </DialogContent>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
