import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    applyMenuWrapper: {
        position: 'absolute',
        background: 'white',
        borderRadius: 8,
        width: 248,
    },
    applyList: {
        display: 'flex',
        flexDirection: 'column',
        listStyleType: 'none',
        padding: 0,
        margin: 0,

        '& li': {
            cursor: 'pointer',
            fontSize: 14,
            color: '#3f5162',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 18px 10px 18px',

            '&:hover': {
                background: '#f1f5f7',
                fontWeight: 500,
                borderRadius: 8,
            },

            '& span': {
                minWidth: 30,
                '& img': {
                    marginRight: 11,
                },
            },

            '&:first-child': {
                paddingTop: 18,
            },

            '&:last-child': {
                paddingBottom: 18,
            },
        },
    },
});

export default useStyles;
