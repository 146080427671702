import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { useSnackbar } from 'notistack';
import GeneralInfoModal from '../GeneralInfoModal';
import KeywordsUpdateActionModal from 'fintech/components/profile/idea/actionmodal/informationcard/content/KeywordsUpdateActionModal';
import { VENTURE_TYPES, StartupActions } from 'fintech/store/actions/ActionTypes';
import ActionModal from 'fintech/components/util/ActionModal';
import * as constants from 'fintech/components/util/ActionModalConstants';
import MediaUpdateActionModal from 'fintech/components/profile/idea/actionmodal/informationcard/content/MediaUpdateActionModal';
import * as FintechBeService from 'fintech/api/fintechService';
import { hasRoles } from 'fintech/utils/Auth';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';
import { fetchStartupIntroData } from 'fintech/store/actions/startup';
import { ISO_FORMAT, NUMERIC_FULL_DATE } from 'fintech/utils/DateUtils';
import { isEmpty, isNil } from 'lodash';
import { setActiveRequest } from 'fintech/store/actions/common';
import { fetchIdeaStagesData, fetchIdeaTechnologiesData, fetchIdeaVerticalsData } from 'fintech/store/actions/idea';
import {
    fetchStartupStagesData,
    fetchStartupTechnologiesData,
    fetchStartupVerticalsData,
} from 'fintech/store/actions/startup';
import { filterObjectList } from '../../../../../../utils/ArrayUtils';

const StartupIntroEditModal = (props) => {
    const { startupId, open, onCancel, ventureType } = props;
    const isIdea = ventureType === VENTURE_TYPES.IDEA || ventureType === VENTURE_TYPES.BACKED_IDEA;
    const isStartup = ventureType === VENTURE_TYPES.STARTUP || ventureType === VENTURE_TYPES.BACKED_STARTUP;
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const [selectedTabId, setSelectedTabId] = useState(constants.STARTUP_GENERAL_INFO_UPDATE);
    const { uuid, media, hasActiveRequest } = useSelector((state) => ({
        ...state.startup.intro.data,
        ...state.common,
    }));
    const fetchedData = useSelector((state) => getGeneralInfosFromState(state, isIdea));

    const [generalInfos, setGeneralInfos] = useState(fetchedData ? { ...fetchedData } : {});

    const [keywordsData, setKeywordsData] = useState({
        verticals: [],
        technologies: [],
        stageData: null,
    });
    const [mediaInfos, setMediaInfos] = useState(media);

    const { enqueueSnackbar } = useSnackbar();

    const type = 'startup';
    const ventureId = startupId;

    const { data: fetchedVerticals, isLoading: verticalsLoading, isError: verticalsError } = useSelector((state) =>
        type === 'startup' ? state.startup.form.verticalData : state.idea.verticalData
    );
    const { data: fetchedTechnologies, isLoading: techsLoading, isError: techsError } = useSelector((state) =>
        type === 'startup' ? state.startup.form.technologyData : state.idea.technologyData
    );
    const { data: fetchedStages, isLoading: stagesLoading, isError: stagesError } = useSelector((state) =>
        type === 'startup' ? state.startup.form.stageData : state.idea.stageData
    );

    const mediaChangeHandler = (mediaInfos) => {
        setMediaInfos(mediaInfos);
    };

    useEffect(() => {
        if (open) {
            if (type === 'startup') {
                dispatch(fetchStartupVerticalsData(keycloak, ventureId));
                dispatch(fetchStartupTechnologiesData(keycloak, ventureId));
                dispatch(fetchStartupStagesData(keycloak, ventureId));
            } else {
                dispatch(fetchIdeaVerticalsData(keycloak, ventureId));
                dispatch(fetchIdeaTechnologiesData(keycloak, ventureId));
                dispatch(fetchIdeaStagesData(keycloak, ventureId));
            }
        }
    }, [open]);

    useEffect(() => {
        const tmpStages = filterObjectList(fetchedStages.stages, 'selected', [true]);
        const updateData = {
            verticals: filterObjectList(fetchedVerticals.verticals, 'selected', [true]),
            technologies: filterObjectList(fetchedTechnologies.technologies, 'selected', [true]),
            stageData: tmpStages ? tmpStages[0] : {},
        };
        setKeywordsData(updateData);
    }, [fetchedVerticals, fetchedTechnologies, fetchedStages]);

    const tabs = [
        {
            id: constants.STARTUP_GENERAL_INFO_UPDATE,
            name: t('idea.updateGeneralInfoTabLabel'),
            selected: true,
        },
        {
            id: constants.STARTUP_KEYWORDS_UPDATE,
            name: t('idea.updateKeywordsLabel'),
            selected: false,
        },
        {
            id: constants.STARTUP_MEDIA_UPDATE,
            name: t('idea.updateMedialLabel'),
            selected: false,
        },
    ];

    const generalInfosChangeHandler = ({ type, data }) => {
        setGeneralInfos((prevState) => {
            switch (type) {
                case constants.STARTUP_INTRO_SUMMARY_UPDATE:
                    return {
                        ...prevState,
                        summary: {
                            ...prevState.summary,
                            ...data,
                        },
                    };
                case constants.STARTUP_INTRO_DATE_UPDATE:
                    return {
                        ...prevState,
                        dateFounded: data,
                    };
                case constants.STARTUP_INTRO_EMPLOYEES_UPDATE:
                    return {
                        ...prevState,
                        employees: {
                            ...prevState.employees,
                            ...data,
                        },
                    };
                case constants.STARTUP_INTRO_ADDRESS_UPDATE:
                    return {
                        ...prevState,
                        address: {
                            ...prevState.address,
                            ...data,
                        },
                    };
                case constants.STARTUP_INTRO_INVESTMENT_UPDATE:
                    return {
                        ...prevState,
                        investment: {
                            ...prevState.investment,
                            ...data,
                        },
                    };
                case constants.STARTUP_INTRO_EMAIL_UPDATE:
                    return {
                        ...prevState,
                        email: {
                            ...prevState.email,
                            ...data,
                        },
                    };
                case constants.STARTUP_INTRO_WEBSITE_UPDATE:
                    return {
                        ...prevState,
                        website: {
                            ...prevState.website,
                            ...data,
                        },
                    };
                case constants.STARTUP_INTRO_FORM_SUBMIT:
                    return {
                        ...prevState,
                        formSubmitted: data,
                    };
                default:
                    return prevState;
            }
        });
    };

    const keywordsChangeHandler = (data) => {
        setKeywordsData(data);
    };

    const editModalActionHandler = (action) => {
        switch (action.type) {
            case constants.TAB: {
                const { selectedTabId } = action;
                setSelectedTabId(selectedTabId);
                break;
            }
            case constants.SUBMIT:
                updateData();
                generalInfosChangeHandler({ type: constants.STARTUP_INTRO_FORM_SUBMIT, data: true });
                return;
            case constants.REJECT:
                rejectForm();
                return;
            default:
                return;
        }
    };

    const isStartupValidated =
        (ventureType === VENTURE_TYPES.STARTUP || VENTURE_TYPES.BACKED_STARTUP) &&
        generalInfos?.dateFounded?.validated &&
        generalInfos?.address?.descValidated &&
        generalInfos?.address?.cityValidated &&
        generalInfos?.investment?.validated &&
        generalInfos?.employees?.validated &&
        generalInfos?.email?.validated &&
        generalInfos?.website?.validated &&
        !isEmpty(keywordsData.verticals) &&
        !isEmpty(keywordsData.technologies) &&
        (!hasRoles(keycloak, ROLE_ADMIN) || !isNil(keywordsData.stageData));

    const isIdeaValidated =
        (ventureType === VENTURE_TYPES.IDEA || ventureType === VENTURE_TYPES.BACKED_IDEA) &&
        generalInfos?.summary?.validated &&
        generalInfos?.email?.validated &&
        generalInfos?.website?.validated &&
        generalInfos?.investment?.validated &&
        !isEmpty(keywordsData.verticals) &&
        !isEmpty(keywordsData.technologies) &&
        (!hasRoles(keycloak, ROLE_ADMIN) || !isNil(keywordsData.stageData));

    const updateData = () => {
        if (isStartupValidated || isIdeaValidated) {
            dispatch(setActiveRequest(true));
            const requestData = {
                startupInfoDtoList: [
                    {
                        id: generalInfos.email.id,
                        value: generalInfos.email.value,
                        public: generalInfos.email.public,
                        field: 'email',
                    },
                    {
                        id: generalInfos.website.id,
                        value: generalInfos.website.value,
                        field: 'website',
                    },
                    {
                        id: generalInfos.investment.infoId,
                        public: generalInfos.investment.public,
                        field: 'investment',
                    },
                    {
                        id: generalInfos.address.id,
                        public: generalInfos.address.public,
                        field: 'address',
                    },
                ],
                startupVerticals: keywordsData.verticals,
                startupTechnologies: keywordsData.technologies,
                startupMedias: mediaInfos,
                startupAddress: {
                    id: generalInfos.address.addressId,
                    city: {
                        ...generalInfos.address.city,
                    },
                    country: generalInfos.address.country,
                    description: generalInfos.address.description,
                },
            };

            if (generalInfos.employees.value) {
                requestData.startupInfoDtoList.push({
                    id: generalInfos.employees.id,
                    value: generalInfos.employees.value,
                    field: 'employees',
                });
            }
            if (generalInfos.investment) {
                requestData.totalInvestment = {
                    id: generalInfos.investment.id,
                    value: generalInfos.investment.value,
                    currencyId: generalInfos.investment.currencyId,
                    currencyType: generalInfos.investment.currencType,
                };
            }
            if (hasRoles(keycloak, ROLE_ADMIN)) {
                requestData.startupStage = keywordsData.stageData;
            }

            if (isIdea) {
                requestData.startupInfoDtoList.push({
                    id: generalInfos.summary.id,
                    value: generalInfos.summary.value,
                    field: 'summary',
                });
            }

            if (isStartup) {
                requestData.startupDateFounded = generalInfos.dateFounded?.value;
            }
            FintechBeService.updateStartupInfo(keycloak, requestData, startupId)
                .then(() => {
                    dispatch(setActiveRequest(false));
                    dispatch({
                        type: StartupActions.REMOVE_STARTUP_INFORMATION_CARD_EDIT_STATE,
                    });
                    dispatch(fetchStartupIntroData(keycloak, uuid));
                    onCancel();
                    enqueueSnackbar(t('idea.updateSucces'), { variant: 'success' });
                })
                .catch((reason) => {
                    dispatch(setActiveRequest(false));
                    enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
                });
        } else {
            enqueueSnackbar(t('validation.genericError'), { variant: 'error' });
        }
    };

    const rejectForm = () => {
        dispatch({
            type: StartupActions.REMOVE_STARTUP_INFORMATION_CARD_EDIT_STATE,
        });
        setGeneralInfos(fetchedData ? { ...fetchedData } : {});
        onCancel();
    };

    const getSelectedTab = (selectedTabId) => {
        switch (selectedTabId) {
            case constants.STARTUP_GENERAL_INFO_UPDATE:
                return (
                    <GeneralInfoModal
                        {...generalInfos}
                        isIdea={isIdea}
                        infoChangeCallback={generalInfosChangeHandler}
                    />
                );
            case constants.STARTUP_KEYWORDS_UPDATE:
                return (
                    <KeywordsUpdateActionModal
                        formIsSubmitted={generalInfos.formSubmitted}
                        changeCallback={(tabId) => keywordsChangeHandler(tabId)}
                        fetchedVerticals={fetchedVerticals}
                        fetchedTechnologies={fetchedTechnologies}
                        fetchedStages={fetchedStages}
                        verticalsLoading={verticalsLoading}
                        verticalsError={verticalsError}
                        techsLoading={techsLoading}
                        techsError={techsError}
                        stagesLoading={stagesLoading}
                        stagesError={stagesError}
                    />
                );
            case constants.STARTUP_MEDIA_UPDATE:
                return (
                    <MediaUpdateActionModal
                        initData={mediaInfos}
                        changeMediaCallback={(mediaInfos) => {
                            mediaChangeHandler(mediaInfos);
                        }}
                    />
                );
            default:
                return <GeneralInfoModal isIdea={isIdea} />;
        }
    };

    return (
        <ActionModal
            open={open}
            tabs={tabs}
            title={t('idea.edit_information_card_title')}
            onAction={editModalActionHandler}
            okClickDisabled={hasActiveRequest || verticalsError || techsError || stagesError}
            cancelClickDisabled={hasActiveRequest}
            showSpinnerWhenDisabled={hasActiveRequest}
        >
            {getSelectedTab(selectedTabId)}
        </ActionModal>
    );
};

export default StartupIntroEditModal;

const getGeneralInfosFromState = (state, isIdea) => {
    const initialState = {};
    const { dateFounded, address, totalInvestment, startupInfos, summary } = state.startup.intro.data;

    //idea summary
    if (summary) {
        initialState.summary = startupInfos?.summary ? { ...startupInfos.summary } : {};
        initialState.summary.validated = startupInfos?.summary?.value ? true : false;
    }

    // Date founded
    initialState.dateFounded = {
        value: dateFounded ? format(parse(dateFounded, NUMERIC_FULL_DATE, new Date()), ISO_FORMAT) : null,
        validated: dateFounded ? true : false,
    };

    // Number of employees
    initialState.employees = startupInfos?.employees ? { ...startupInfos.employees } : {};

    if (isIdea) {
        initialState.employees.validated = true;
    } else {
        initialState.employees.validated = startupInfos?.employees?.value ? true : false;
    }

    // Address
    initialState.address = startupInfos?.address
        ? {
              ...startupInfos.address,
              country: address?.country,
              city: address?.city?.name,
              description: address?.description,
              addressId: address?.id,
          }
        : {};
    initialState.address.descValidated = address?.description && true;
    initialState.address.cityValidated = address?.city && true;

    // Investment amount
    initialState.investment = startupInfos?.investment
        ? {
              ...startupInfos.investment,
              ...totalInvestment,
              id: totalInvestment ? totalInvestment.id : null,
              infoId: startupInfos.investment.id,
          }
        : {};
    initialState.investment.validated = true;

    // Email
    initialState.email = startupInfos?.email ? { ...startupInfos.email } : {};
    initialState.email.validated = startupInfos?.email?.value ? true : false;

    // Website
    initialState.website = startupInfos?.website ? { ...startupInfos.website } : {};
    initialState.website.validated = startupInfos?.website?.value ? true : false;

    return initialState;
};
