import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { mobileMediaQuery } from 'fintech/components/style/common';
import useStyles from './PageTemplate.style';
import SecureSideFooter from './menu/leftmenu/SecureSideFooter';

const PageTemplate = (props) => {
    const classes = useStyles();
    const pageTemplateClassName = useMediaQuery(mobileMediaQuery)
        ? classes.pageTemplateWeb
        : classes.pageTemplateMobile;

    return (
        <div className={pageTemplateClassName} {...props}>
            <div className={classes.mainWrapper}>{props.children}</div>
            <SecureSideFooter />
        </div>
    );
};

export default PageTemplate;
