import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        cursor: 'pointer',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 14,
    },
    question: {
        color: '#3f5162',
        fontSize: 16,
        fontWeight: 500,
        marginLeft: 16,
    },
    content: {
        fontSize: 16,
        color: '#596d81',
        marginLeft: 40,

        '&:first-child': {
            marginTop: 12,
        },
    },
    divider: {
        width: '100%',
        height: '1px',
        background: '#d7e2e8',
    },
});

export default useStyles;
