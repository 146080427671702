import { CookieActions } from '../actions/ActionTypes';
import { shouldShowPopup, shouldShowSwitch } from 'fintech/utils/Cookie';

const initialState = {
    cookieBanner: shouldShowPopup(),
    performanceCookie: shouldShowSwitch(),
};

const cookie = (state = initialState, action) => {
    switch (action.type) {
        case CookieActions.SET_COOKIE_BANNER:
            return {
                ...state,
                cookieBanner: action.status,
            };
        case CookieActions.SET_PERFORMANCE_COOKIE:
            return {
                ...state,
                performanceCookie: action.status,
            };
        default:
            return state;
    }
};

export default cookie;
