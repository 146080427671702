export const DO_LOGIN = 'DO_LOGIN';
export const DO_LOGOUT = 'DO_LOGOUT';
export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';

// values should be lowercase to correspond state names.
// example: state[STATES.ESTABLISHMENT].intro ==  state.establishment.intro
export const STATES = {
    STARTUP: 'startup',
    ESTABLISHMENT: 'establishment',
    ATTEMPTS: 'attemps',
};

export const VENTURE_TYPES = {
    IDEA: 'IDEA',
    BACKED_IDEA: 'BACKED_IDEA',
    STARTUP: 'STARTUP',
    BACKED_STARTUP: 'BACKED_STARTUP',
};

export const CurrencyActions = {
    SET_CURRENCIES: 'SET_CURRENCIES',
    SET_CURRENCIES_LOADING: 'SET_CURRENCIES_LOADING',
    SET_CURRENCIES_LOAD_ERROR: 'SET_CURRENCIES_LOAD_ERROR',
};

export const AnnouncementActions = {
    SET_ANNOUNCEMENT: 'SET_ANNOUNCEMENT',
    SET_ANNOUNCEMENT_LOADING: 'SET_ANNOUNCEMENT_LOADING',
    SET_ANNOUNCEMENT_LOAD_ERROR: 'SET_ANNOUNCEMENT_LOAD_ERROR',
};

export const EventActions = {
    SET_EVENT: 'SET_EVENT',
    SET_VIEWED_EVENT: 'SET_VIEWED_EVENT',
    SET_OTHER_EVENTS: 'SET_OTHER_EVENTS',
    SET_EVENT_LOADING: 'SET_EVENT_LOADING',
    SET_EVENT_ERROR: 'SET_EVENT_ERROR',
};

export const UserActions = {
    SET_USER: 'SET_USER',
    SET_USER_LOADING: 'SET_USER_LOADING',
    SET_USER_ERROR: 'SET_USER_ERROR',
    SET_USER_IMAGE: 'SET_USER_IMAGE',
    SET_VISIT_COUNT: 'SET_VISIT_COUNT',
    // Actions for related ventures
    SET_USER_VENTURES: 'SET_USER_VENTURES',
    SET_USER_VENTURES_LOADING: 'SET_USER_VENTURES_LOADING',
    SET_USER_VENTURES_LOAD_ERROR: 'SET_USER_VENTURES_LOAD_ERROR',

    // Actions for user mentorships
    SET_USER_MENTORSHIPS: 'SET_USER_MENTORSHIPS',
    SET_USER_MENTORSHIPS_LOADING: 'SET_USER_MENTORSHIPS_LOADING',
    SET_USER_MENTORSHIPS_LOAD_ERROR: 'SET_USER_MENTORSHIPS_LOAD_ERROR',

    // Actions for user userships
    SET_USER_USERSSHIPS: 'SET_USER_USERSSHIPS',
    SET_USER_USERSSHIPS_LOADING: 'SET_USER_USERSSHIPS_LOADING',
    SET_USER_USERSSHIPS_LOAD_ERROR: 'SET_USER_USERSSHIPS_LOAD_ERROR',

    // Actions for user related investors
    SET_USER_RELATED_INVESTORS: 'SET_USER_RELATED_INVESTORS',
    SET_USER_RELATED_INVESTORS_LOADING: 'SET_USER_RELATED_INVESTORS_LOADING',
    SET_USER_RELATED_INVESTORS_LOAD_ERROR: 'SET_USER_RELATED_INVESTORS_LOAD_ERROR',

    // Actions for verticals
    GET_USER_VERTICALS: 'GET_USER_VERTICALS',
    SET_USER_VERTICALS: 'SET_USER_VERTICALS',
    SET_USER_VERTICALS_LOADING: 'SET_USER_VERTICALS_LOADING',
    SET_USER_VERTICALS_LOAD_ERROR: 'SET_USER_VERTICALS_LOAD_ERROR',

    // Actions for technologies
    GET_USER_TECHNOLOGIES: 'GET_USER_TECHNOLOGIES',
    SET_USER_TECHNOLOGIES: 'SET_USER_TECHNOLOGIES',
    SET_USER_TECHNOLOGIES_LOADING: 'SET_USER_TECHNOLOGIES_LOADING',
    SET_USER_TECHNOLOGIES_LOAD_ERROR: 'SET_USER_TECHNOLOGIES_LOAD_ERROR',

    SET_HIDE_SEND_MESSAGE_BUTTON: 'SET_HIDE_SEND_MESSAGE_BUTTON',

    // Clean up user
    CLEAN_UP_USER: 'CLEAN_UP_USER',
};

export const StartupActions = {
    // Actions for Intro card & general information
    SET_STARTUP_INTRO: 'SET_STARTUP',
    SET_STARTUP_INTRO_LOADING: 'SET_STARTUP_LOADING',
    SET_STARTUP_INTRO_LOAD_ERROR: 'SET_STARTUP_LOAD_ERROR',
    SET_STARTUP_INTRO_VISIT_TIMES: 'SET_STARTUP_VISIT_TIMES',

    // Actions for team members
    SET_STARTUP_TEAM: 'SET_STARTUP_TEAM',
    SET_STARTUP_TEAM_LOADING: 'SET_STARTUP_TEAM_LOADING',
    SET_STARTUP_TEAM_ERROR: 'SET_STARTUP_TEAM_LOAD_ERROR',

    // Actions for financials info in the financials tab
    SET_STARTUP_FINANCIALS: 'SET_STARTUP_FINANCIALS',
    SET_STARTUP_FINANCIALS_LOADING: 'SET_STARTUP_FINANCIALS_LOADING',
    SET_STARTUP_FINANCIALS_LOAD_ERROR: 'SET_STARTUP_FINANCIALS_LOAD_ERROR',

    // Actions for verticals
    SET_STARTUP_VERTICALS: 'SET_STARTUP_VERTICALS',
    SET_STARTUP_VERTICALS_LOADING: 'SET_STARTUP_VERTICALS_LOADING',
    SET_STARTUP_VERTICALS_LOAD_ERROR: 'SET_STARTUP_VERTICALS_LOAD_ERROR',

    // Actions for technologies
    SET_STARTUP_TECHNOLOGIES: 'SET_STARTUP_TECHNOLOGIES',
    SET_STARTUP_TECHNOLOGIES_LOADING: 'SET_STARTUP_TECHNOLOGIES_LOADING',
    SET_STARTUP_TECHNOLOGIES_LOAD_ERROR: 'SET_STARTUP_TECHNOLOGIES_LOAD_ERROR',

    // Actions for stages
    SET_STARTUP_STAGES: 'SET_STARTUP_STAGES',
    SET_STARTUP_STAGES_LOADING: 'SET_STARTUP_STAGES_LOADING',
    SET_STARTUP_STAGES_LOAD_ERROR: 'SET_STARTUP_STAGES_LOAD_ERROR',

    // Actions for countries
    SET_STARTUP_COUNTRIES: 'SET_STARTUP_COUNTRIES',
    SET_STARTUP_COUNTRIES_LOADING: 'SET_STARTUP_COUNTRIES_LOADING',
    SET_STARTUP_COUNTRIES_LOAD_ERROR: 'SET_STARTUP_COUNTRIES_LOAD_ERROR',

    // Actions for cities
    SET_STARTUP_CITIES: 'SET_STARTUP_CITIES',
    SET_STARTUP_CITIES_LOADING: 'SET_STARTUP_CITIES_LOADING',
    SET_STARTUP_CITIES_LOAD_ERROR: 'SET_STARTUP_CITIES_LOAD_ERROR',

    REMOVE_STARTUP_INFORMATION_CARD_EDIT_STATE: 'REMOVE_STARTUP_INFORMATION_CARD_EDIT_STATE',

    // Actions for SimilarVentures
    SET_STARTUP_SIMILARVENTURES_GRID_DATA: 'SET_STARTUP_SIMILARVENTURES_GRID_DATA',
    RESET_STARTUP_SIMILARVENTURES_GRID_DATA: 'RESET_STARTUP_SIMILARVENTURES_GRID_DATA',
    SET_STARTUP_SIMILARVENTURES_GRID_ROW_FAVOURITE: 'SET_STARTUP_SIMILARVENTURES_GRID_ROW_FAVOURITE',
    SET_STARTUP_SIMILARVENTURES_GRID_PAGINATION: 'SET_STARTUP_SIMILARVENTURES_GRID_PAGINATION',
    SET_STARTUP_SIMILARVENTURES_GRID_LOAD_ERROR: 'SET_STARTUP_SIMILARVENTURES_GRID_LOAD_ERROR',
    TOGGLE_STARTUP_SIMILARVENTURES_GRID_STATE: 'TOGGLE_STARTUP_SIMILARVENTURES_GRID_STATE',

    // Actions for the investments in the financials tab
    SET_STARTUP_INVESTMENTS: 'SET_STARTUP_INVESTMENTS',
    SET_STARTUP_INVESTMENTS_LOADING: 'SET_STARTUP_INVESTMENTS_LOADING',
    SET_STARTUP_INVESTMENTS_LOAD_ERROR: 'SET_STARTUP_INVESTMENTS_LOAD_ERROR',

    // Actions for financials interactions in the financials tab
    SET_STARTUP_INCOME_MODELS_LOADING: 'SET_STARTUP_INCOME_MODELS_LOADING',
    SET_STARTUP_INCOME_MODELS: 'SET_STARTUP_INCOME_MODELS',
    SET_STARTUP_INCOME_MODELS_LOAD_ERROR: 'SET_STARTUP_INCOME_MODELS_LOAD_ERROR',

    // Interactions in the financials tab
    SET_STARTUP_INVESTOR_OPTIONS: 'SET_STARTUP_INVESTOR_OPTIONS',
    GET_STARTUP_INVESTOR_OPTIONS: 'GET_STARTUP_INVESTOR_OPTIONS',
    SET_STARTUP_INVESTOR_OPTIONS_LOAD_ERROR: 'SET_STARTUP_INVESTOR_OPTIONS_LOAD_ERROR',
    // Initialize the form with initial data if editing a record.
    SET_STARTUP_INVESTMENT: 'SET_STARTUP_INVESTMENT',
    // Investor id, name & visibility
    SET_STARTUP_INVESTOR: 'SET_STARTUP_INVESTOR',
    SET_STARTUP_INVESTMENT_DATE: 'SET_STARTUP_INVESTMENT_DATE',
    // Monetary value id, amount, currencyType & visibility
    SET_STARTUP_INVESTMENT_AMOUNT: 'SET_STARTUP_INVESTMENT_AMOUNT',
    // General visibility of the record
    SET_STARTUP_INVESTMENT_VISIBILITY: 'SET_STARTUP_INVESTMENT_VISIBILITY',
    RESET_STARTUP_INVESTOR_FORM: 'RESET_STARTUP_INVESTOR_FORM',
    // Send button is clicked. Used to show validation.
    SEND_STARTUP_INVESTMENT_FORM: 'SEND_STARTUP_INVESTMENT_FORM',
};

export const IdeaActions = {
    // Actions for Intro card & general information
    GET_IDEA_INTRO: 'GET_IDEA_INTRO',
    SET_IDEA_INTRO: 'SET_IDEA_INTRO',
    SET_IDEA_INTRO_LOADING: 'SET_IDEA_INTRO_LOADING',
    SET_IDEA_INTRO_LOAD_ERROR: 'SET_IDEA_INTRO_LOAD_ERROR',

    // Actions for team members
    SET_IDEA_TEAM: 'SET_IDEA_TEAM',
    SET_IDEA_TEAM_LOADING: 'SET_IDEA_TEAM_LOADING',
    SET_IDEA_TEAM_LOAD_ERROR: 'SET_IDEA_TEAM_LOAD_ERROR',

    // Actions for verticals
    SET_IDEA_VERTICALS: 'SET_IDEA_VERTICALS',
    SET_IDEA_VERTICALS_LOADING: 'SET_IDEA_VERTICALS_LOADING',
    SET_IDEA_VERTICALS_LOAD_ERROR: 'SET_IDEA_VERTICALS_LOAD_ERROR',

    // Actions for technologies
    SET_IDEA_TECHNOLOGIES: 'SET_IDEA_TECHNOLOGIES',
    SET_IDEA_TECHNOLOGIES_LOADING: 'SET_IDEA_TECHNOLOGIES_LOADING',
    SET_IDEA_TECHNOLOGIES_LOAD_ERROR: 'SET_IDEA_TECHNOLOGIES_LOAD_ERROR',

    // Actions for stages
    SET_IDEA_STAGES: 'SET_IDEA_STAGES',
    SET_IDEA_STAGES_LOADING: 'SET_IDEA_STAGES_LOADING',
    SET_IDEA_STAGES_LOAD_ERROR: 'SET_IDEA_STAGES_LOAD_ERROR',

    REMOVE_IDEA_INFORMATION_CARD_EDIT_STATE: 'REMOVE_IDEA_INFORMATION_CARD_EDIT_STATE',
};

export const AttempActions = {
    // Actions for attemp(startup&idea) list
    SET_ATTEMP_LIST: 'SET_ATTEMP_LIST',
    SET_ATTEMP_LIST_LOADING: 'SET_ATTEMP_LIST_LOADING',
    SET_ATTEMP_LIST_LOAD_ERROR: 'SET_ATTEMP_LIST_LOAD_ERROR',
};

export const EstablishmentActions = {
    SET_ESTABLISHMENT_LIST: 'SET_ESTABLISHMENT_LIST',
    SET_ESTABLISHMENT_LOADING: 'SET_ESTABLISHMENT_LOADING',
    SET_ESTABLISHMENT_LOAD_ERROR: 'SET_ESTABLISHMENT_LOAD_ERROR',

    SET_ESTABLISHMENT_INTRO_LOADING: 'SET_ESTABLISHMENT_INTRO_LOADING',
    SET_ESTABLISHMENT_INTRO_DATA: 'SET_ESTABLISHMENT_INTRO_DATA',
    SET_ESTABLISHMENT_INTRO_ERROR: 'SET_ESTABLISHMENT_INTRO_ERROR',

    SET_ESTABLISHMENT_INVESTOR_TYPES_LOADING: 'SET_ESTABLISHMENT_INVESTOR_TYPES_LOADING',
    SET_ESTABLISHMENT_INVESTOR_TYPES: 'SET_ESTABLISHMENT_INVESTOR_TYPES',
    SET_ESTABLISHMENT_INVESTOR_TYPES_LOAD_ERROR: 'SET_ESTABLISHMENT_INVESTOR_TYPES_LOAD_ERROR',

    SET_ESTABLISHMENT_INVESTOR_GROUP_LOADING: 'SET_ESTABLISHMENT_INVESTOR_GROUP_LOADING',
    SET_ESTABLISHMENT_INVESTOR_GROUP: 'SET_ESTABLISHMENT_INVESTOR_GROUP',
    SET_ESTABLISHMENT_INVESTOR_GROUP_LOAD_ERROR: 'SET_ESTABLISHMENT_INVESTOR_GROUP_LOAD_ERROR',

    SET_ESTABLISHMENT_VERTICALS_LOADING: 'SET_ESTABLISHMENT_VERTICALS_LOADING',
    SET_ESTABLISHMENT_VERTICALS_DATA: 'SET_ESTABLISHMENT_VERTICALS_DATA',
    SET_ESTABLISHMENT_VERTICALS_LOAD_ERROR: 'SET_ESTABLISHMENT_VERTICALS_LOAD_ERROR',

    SET_ESTABLISHMENT_INVESTMENT_AREAS_LOADING: 'SET_ESTABLISHMENT_INVESTMENT_AREAS_LOADING',
    SET_ESTABLISHMENT_INVESTMENT_AREAS_DATA: 'SET_ESTABLISHMENT_INVESTMENT_AREAS_DATA',
    SET_ESTABLISHMENT_INVESTMENT_AREAS_LOAD_ERROR: 'SET_ESTABLISHMENT_INVESTMENT_AREAS_LOAD_ERROR',

    SET_ESTABLISHMENT_STAGES_LOADING: 'SET_ESTABLISHMENT_STAGES_LOADING',
    SET_ESTABLISHMENT_STAGES_DATA: 'SET_ESTABLISHMENT_STAGES_DATA',
    SET_ESTABLISHMENT_STAGES_LOAD_ERROR: 'SET_ESTABLISHMENT_STAGES_LOAD_ERROR',

    SET_ESTABLISHMENT_ABOUT_INFO_LOADİNG: 'SET_ESTABLISHMENT_ABOUT_INFO_LOADİNG',
    SET_ESTABLISHMENT_ABOUT_INFO_DATA: 'SET_ESTABLISHMENT_ABOUT_INFO_DATA',
    SET_ESTABLISHMENT_ABOUT_INFO_LOAD_ERROR: 'SET_ESTABLISHMENT_ABOUT_INFO_LOAD_ERROR',

    SET_ESTABLISHMENT_TEAM_LOADING: 'SET_ESTABLISHMENT_TEAM_LOADING',
    SET_ESTABLISHMENT_TEAM_DATA: 'SET_ESTABLISHMENT_TEAM_DATA',
    SET_ESTABLISHMENT_TEAM_LOAD_ERROR: 'SET_ESTABLISHMENT_ABOUT_INFO_LOAD_ERROR',

    SET_ESTABLISHMENT_PREFERENCES_LOADING: 'SET_ESTABLISHMENT_PREFERENCES_LOADING',
    SET_ESTABLISHMENT_PREFERENCES_DATA: 'SET_ESTABLISHMENT_PREFERENCES_DATA',
    SET_ESTABLISHMENT_PREFERENCES_LOAD_ERROR: 'SET_ESTABLISHMENT_PREFERENCES_LOAD_ERROR',

    SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_LOADING: 'SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_LOADING',
    SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_DATA: 'SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_DATA',
    SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_LOAD_ERROR:
        'SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_LOAD_ERROR',

    SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_LOADING:
        'SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_LOADING',

    SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_DATA: 'SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_DATA',
    SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_LOAD_ERROR:
        'SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_LOAD_ERROR',

    SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_LOADING:
        'SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_LOADING',
    SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_DATA: 'SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_DATA',
    SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_LOAD_ERROR:
        'SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_LOAD_ERROR',

    SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_LOADING: 'SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_LOADING',
    SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_DATA: 'SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_DATA',
    SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_LOAD_ERROR: 'SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_LOAD_ERROR',
    SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_DATA: 'SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_DATA',

    SEND_ESTABLISHMENT_PREFERENCES_INVESTMENT_FORM: 'SEND_ESTABLISHMENT_PREFERENCES_INVESTMENT_FORM',
    RESET_ESTABLISHMENT_INTRO_CARD: 'RESET_ESTABLISHMENT_INTRO_CARD',
    RESET_ESTABLISHMENT_PREFERENCES_INVESTMENT_FORM: 'RESET_ESTABLISHMENT_PREFERENCES_INVESTMENT_FORM',

    REMOVE_ESTABLISHMENT_INFORMATION_CARD_EDIT_STATE: 'REMOVE_ESTABLISHMENT_INFORMATION_CARD_EDIT_STATE',

    GET_ESTABLISHMENT_STARTUP_OPTIONS: 'GET_ESTABLISHMENT_STARTUP_OPTIONS',
    SET_ESTABLISHMENT_STARTUP_OPTIONS_LOAD_ERROR: 'SET_ESTABLISHMENT_STARTUP_OPTIONS_LOAD_ERROR',
    SET_ESTABLISHMENT_START_OPTIONS: 'SET_ESTABLISHMENT_START_OPTIONS',
    SET_ESTABLISHMENT_STARTUP: 'SET_ESTABLISHMENT_STARTUP',
    SET_ESTABLISHMENT_STARTUP_INVESTMENT_DATE: 'SET_ESTABLISHMENT_STARTUP_INVESTMENT_DATE',
    SET_ESTABLISHMENT_STARTUP_INVESTMENT_AMOUNT: 'SET_ESTABLISHMENT_STARTUP_INVESTMENT_AMOUNT',
    SET_ESTABLISHMENT_INVESTMENT_VISIBILITY: 'SET_ESTABLISHMENT_INVESTMENT_VISIBILITY',
    SET_ESTABLISHMENT_INTRO_VISIT_TIMES: 'SET_ESTABLISHMENT_INTRO_VISIT_TIMES',
};

export const EntrepreneurActions = {
    // Global
    SET_ENTREPRENEUR_GLOBAL_TOTALNUMBEROFSTARTUPS: 'SET_ENTREPRENEUR_GLOBAL_TOTALNUMBEROFSTARTUPS',
    SET_ENTREPRENEUR_GLOBAL_TOTALNUMBEROFRESULTS: 'SET_ENTREPRENEUR_GLOBAL_TOTALNUMBEROFRESULTS',
    SET_ENTREPRENEUR_GLOBAL_SELECTEDTAB: 'SET_ENTREPRENEUR_GLOBAL_SELECTEDTAB',
    SET_ENTREPRENEUR_MENTOR_LIST: 'SET_ENTREPRENEUR_MENTOR_LIST',
    SET_ENTREPRENEUR_REPRESENTIVE_LIST: 'SET_ENTREPRENEUR_REPRESENTIVE_LIST',
    SET_LAST_FETCH_STATUS: 'SET_LAST_FETCH_STATUS',
    SET_ENTREPRENEUR_GLOBAL_PERSIST_STATE: 'SET_ENTREPRENEUR_GLOBAL_PERSIST_STATE',

    // Actions for advanced filters
    SET_ENTREPRENEUR_ADVANCEDFILTER_DATA: 'SET_ENTREPRENEUR_ADVANCEDFILTER_DATA',
    TOGGLE_ENTREPRENEUR_ADVANCEDFILTER: 'TOGGLE_ENTREPRENEUR_ADVANCEDFILTER',
    TOGGLE_ENTREPRENEUR_ADVANCEDFILTER_CHECKBOX: 'TOGGLE_ENTREPRENEUR_ADVANCEDFILTER_CHECKBOX',
    RESET_ENTREPRENEUR_ADVANCEDFILTER_CHECKBOX: 'RESET_ENTREPRENEUR_ADVANCEDFILTER_CHECKBOX',
    SET_ENTREPRENEUR_VERTICAL: 'SET_ENTREPRENEUR_VERTICAL',
    RESET_ENTREPRENEUR_ADVANCEDFILTER: 'RESET_ENTREPRENEUR_ADVANCEDFILTER',

    // Actions for applied filters
    SET_ENTREPRENEUR_APPLIED_FILTERS: 'SET_ENTREPRENEUR_APPLIED_FILTERS',
    REMOVE_ENTREPRENEUR_APPLIED_FILTERS: 'REMOVE_ENTREPRENEUR_APPLIED_FILTERS',
    REMOVE_ENTREPRENEUR_APPLIED_FILTERS_BY_SUBCATEGORY: 'REMOVE_ENTREPRENEUR_APPLIED_FILTERS_BY_SUBCATEGORY',
    RESET_ENTREPRENEUR_APPLIED_FILTERS: 'RESET_ENTREPRENEUR_APPLIED_FILTERS',

    // Actions for searchbox
    SET_ENTREPRENEUR_SEARCHBOX_LAST_QUERY: 'SET_ENTREPRENEUR_SEARCHBOX_LAST_QUERY',
    SET_ENTREPRENEUR_SEARCHBOX_INPUT: 'SET_ENTREPRENEUR_SEARCHBOX_INPUT',
    SET_ENTREPRENEUR_SEARCHBOX_FILTERSTATUS: 'SET_ENTREPRENEUR_SEARCHBOX_FILTERSTATUS',
    RESET_ENTREPRENEUR_SEARCHBOX: 'RESET_ENTREPRENEUR_SEARCHBOX',

    // Grid
    SET_ENTREPRENEUR_GRID_DATA: 'SET_ENTREPRENEUR_GRID_DATA',
    SET_ENTREPRENEUR_GRID_ROW_FAVOURITE: 'SET_ENTREPRENEUR_GRID_ROW_FAVOURITE',
    SET_ENTREPRENEUR_GRID_PAGINATION: 'SET_ENTREPRENEUR_GRID_PAGINATION',
    RESET_ENTREPRENEUR_GRID_PAGINATION: 'RESET_ENTREPRENEUR_GRID_PAGINATION',
    TOGGLE_ENTREPRENEUR_GRID_STATE: 'TOGGLE_ENTREPRENEUR_GRID_STATE',
    SET_ENTREPRENEUR_GRID_LOADING_INITIAL: 'SET_ENTREPRENEUR_GRID_LOADING_INITIAL',
    SET_ENTREPRENEUR_GRID_LOADING: 'SET_ENTREPRENEUR_GRID_LOADING',
    SET_ENTREPRENEUR_GRID_LOAD_ERROR: 'SET_ENTREPRENEUR_GRID_LOAD_ERROR',
    SET_ENTREPRENEUR_GRID_STARTUP_STATUS: 'SET_ENTREPRENEUR_GRID_STARTUP_STATUS',
};

export const InviteSearchActions = {
    // Global
    SET_INVITE_GLOBAL_TOTAL_ACTIVE_NUMBER: 'SET_INVITE_GLOBAL_TOTAL_ACTIVE_NUMBER',
    SET_INVITE_GLOBAL_FILTERED_NUMBER: 'SET_INVITE_GLOBAL_FILTERED_NUMBER',
    SET_INVITE_SEARCH_GLOBAL_PERSIST_STATE: 'SET_INVITE_SEARCH_GLOBAL_PERSIST_STATE',

    // Actions for searchbox (investor)
    SET_INVITE_SEARCH_SEARCHBOX_LAST_QUERY: 'SET_INVITE_SEARCH_SEARCHBOX_LAST_QUERY',
    SET_INVITE_SEARCH_SEARCHBOX_INPUT: 'SET_INVITE_SEARCH_SEARCHBOX_INPUT',
    SET_INVITE_SEARCH_SEARCHBOX_FILTERSTATUS: 'SET_INVITE_SEARCH_SEARCHBOX_FILTERSTATUS',
    RESET_INVITE_SEARCH_SEARCHBOX: 'RESET_INVITE_SEARCH_SEARCHBOX',

    // Grid (investor)
    SET_INVITE_SEARCH_LAST_QUERY_TYPE: 'SET_INVITE_SEARCH_LAST_QUERY_TYPE',
    SET_INVITE_SEARCH_GRID_HEADERS: 'SET_INVITE_SEARCH_GRID_HEADERS',
    SET_INVITE_SEARCH_GRID_DATA: 'SET_INVITE_SEARCH_GRID_DATA',
    SET_INVITE_SEARCH_GRID_LOADING_INITIAL: 'SET_INVITE_SEARCH_GRID_LOADING_INITIAL',
    SET_INVITE_SEARCH_GRID_LOADING: 'SET_INVITE_SEARCH_GRID_LOADING',
    SET_INVITE_SEARCH_GRID_LOAD_ERROR: 'SET_INVITE_SEARCH_GRID_LOAD_ERROR',
    SET_INVITE_SEARCH_GRID_PAGINATION: 'SET_INVITE_SEARCH_GRID_PAGINATION',
    RESET_INVITE_SEARCH_GRID_PAGINATION: 'RESET_INVITE_SEARCH_GRID_PAGINATION',
    TOGGLE_INVITE_SEARCH_GRID_STATE: 'TOGGLE_INVITE_SEARCH_GRID_STATE',
};

export const InvestorSearchActions = {
    // Global
    SET_INVESTOR_GLOBAL_TOTAL_ACTIVE_NUMBER: 'SET_INVESTOR_GLOBAL_TOTAL_ACTIVE_NUMBER',
    SET_INVESTOR_GLOBAL_FILTERED_NUMBER: 'SET_INVESTOR_GLOBAL_FILTERED_NUMBER',
    SET_INVESTOR_GLOBAL_PERSIST_STATE: 'SET_INVESTOR_GLOBAL_PERSIST_STATE',

    // Actions for advanced filters (investor)
    SET_INVESTOR_ADVANCEDFILTER_DATA: 'SET_INVESTOR_ADVANCEDFILTER_DATA',
    TOGGLE_INVESTOR_ADVANCEDFILTER: 'TOGGLE_INVESTOR_ADVANCEDFILTER',
    TOGGLE_INVESTOR_ADVANCEDFILTER_CHECKBOX: 'TOGGLE_INVESTOR_ADVANCEDFILTER_CHECKBOX',
    RESET_INVESTOR_ADVANCEDFILTER_CHECKBOX: 'RESET_INVESTOR_ADVANCEDFILTER_CHECKBOX',
    SET_INVESTOR_CATEGORY: 'SET_INVESTOR_CATEGORY',
    RESET_INVESTOR_ADVANCED_FILTER: 'RESET_INVESTOR_ADVANCED_FILTER',

    // Actions for applied filters (investor)
    SET_INVESTOR_APPLIED_FILTERS: 'SET_INVESTOR_APPLIED_FILTERS',
    REMOVE_INVESTOR_APPLIED_FILTERS: 'REMOVE_INVESTOR_APPLIED_FILTERS',
    REMOVE_INVESTOR_APPLIED_FILTERS_BY_SUBCATEGORY: 'REMOVE_INVESTOR_APPLIED_FILTERS_BY_SUBCATEGORY',
    RESET_INVESTOR_APPLIED_FILTERS: 'RESET_INVESTOR_APPLIED_FILTERS',

    // Actions for searchbox (investor)
    SET_INVESTOR_SEARCHBOX_LAST_QUERY: 'SET_INVESTOR_SEARCHBOX_LAST_QUERY',
    SET_INVESTOR_SEARCHBOX_INPUT: 'SET_INVESTOR_SEARCHBOX_INPUT',
    SET_INVESTOR_SEARCHBOX_FILTERSTATUS: 'SET_INVESTOR_SEARCHBOX_FILTERSTATUS',
    RESET_INVESTOR_SEARCHBOX: 'RESET_INVESTOR_SEARCHBOX',

    // Grid (investor)
    SET_INVESTOR_LAST_QUERY_TYPE: 'SET_INVESTOR_LAST_QUERY_TYPE',
    SET_INVESTOR_GRID_HEADERS: 'SET_INVESTOR_GRID_HEADERS',
    SET_INVESTOR_GRID_DATA: 'SET_INVESTOR_GRID_DATA',
    SET_INVESTOR_GRID_LOADING_INITIAL: 'SET_INVESTOR_GRID_LOADING_INITIAL',
    SET_INVESTOR_GRID_LOADING: 'SET_INVESTOR_GRID_LOADING',
    SET_INVESTOR_GRID_LOAD_ERROR: 'SET_INVESTOR_GRID_LOAD_ERROR',
    SET_INVESTOR_GRID_PAGINATION: 'SET_INVESTOR_GRID_PAGINATION',
    RESET_INVESTOR_GRID_PAGINATION: 'RESET_INVESTOR_GRID_PAGINATION',
    TOGGLE_INVESTOR_GRID_STATE: 'TOGGLE_INVESTOR_GRID_STATE',

    // Admin related actions
    RESET_INVESTOR_REPRESENTATIVE_FORM: 'RESET_INVESTOR_REPRESENTATIVE_FORM',
    GET_INVESTOR_GRID_TEAM_DATA: 'GET_INVESTOR_GRID_TEAM_DATA',
    SET_INVESTOR_GRID_TEAM_DATA: 'SET_INVESTOR_GRID_TEAM_DATA',
    SET_INVESTOR_GRID_TEAM_DATA_LOAD_ERROR: 'SET_INVESTOR_GRID_TEAM_DATA_LOAD_ERROR',
};

export const MentorSearchActions = {
    // Global
    SET_MENTOR_GLOBAL_TOTAL_ACTIVE_NUMBER: 'SET_MENTOR_GLOBAL_TOTAL_ACTIVE_NUMBER',
    SET_MENTOR_GLOBAL_FILTERED_NUMBER: 'SET_MENTOR_GLOBAL_FILTERED_NUMBER',

    // Actions for searchbox (mentor)
    SET_MENTOR_SEARCHBOX_LAST_QUERY: 'SET_MENTOR_SEARCHBOX_LAST_QUERY',
    SET_MENTOR_SEARCHBOX_INPUT: 'SET_MENTOR_SEARCHBOX_INPUT',
    SET_MENTOR_SEARCHBOX_FILTERSTATUS: 'SET_MENTOR_SEARCHBOX_FILTERSTATUS',
    RESET_MENTOR_SEARCHBOX: 'RESET_MENTOR_SEARCHBOX',

    // Grid (mentor)
    SET_MENTOR_GRID_HEADERS: 'SET_MENTOR_GRID_HEADERS',
    SET_MENTOR_GRID_DATA: 'SET_MENTOR_GRID_DATA',
    SET_MENTOR_GRID_LOADING_INITIAL: 'SET_MENTOR_GRID_LOADING_INITIAL',
    SET_MENTOR_GRID_LOADING: 'SET_MENTOR_GRID_LOADING',
    SET_MENTOR_GRID_LOAD_ERROR: 'SET_MENTOR_GRID_LOAD_ERROR',
    SET_MENTOR_GRID_PAGINATION: 'SET_MENTOR_GRID_PAGINATION',
    RESET_MENTOR_GRID_PAGINATION: 'RESET_MENTOR_GRID_PAGINATION',
    TOGGLE_INVESTOR_GRID_STATE: 'TOGGLE_INVESTOR_GRID_STATE',
};

export const ConsultantSearchActions = {
    RESET_CONSULTANT_GRID_PAGINATION: 'RESET_CONSULTANT_GRID_PAGINATION',

    SET_CONSULTANT_GLOBAL_TOTAL_ACTIVE_NUMBER: 'SET_CONSULTANT_GLOBAL_TOTAL_ACTIVE_NUMBER',
    SET_CONSULTANT_GLOBAL_FILTERED_NUMBER: 'SET_CONSULTANT_GLOBAL_FILTERED_NUMBER',

    SET_CONSULTANT_SEARCHBOX_LAST_QUERY: 'SET_CONSULTANT_SEARCHBOX_LAST_QUERY',
    SET_CONSULTANT_SEARCHBOX_INPUT: 'SET_CONSULTANT_SEARCHBOX_INPUT',
    SET_CONSULTANT_SEARCHBOX_FILTERSTATUS: 'SET_CONSULTANT_SEARCHBOX_FILTERSTATUS',
    RESET_CONSULTANT_SEARCHBOX: 'RESET_CONSULTANT_SEARCHBOX',

    SET_CONSULTANT_EXPERTISE: 'SET_CONSULTANT_EXPERTISE',
    SET_CONSULTANT_SELECTED_EXPERTISE: 'SET_CONSULTANT_SELECTED_EXPERTISE',

    SET_CONSULTANT_GRID_HEADERS: 'SET_CONSULTANT_GRID_HEADERS',
    SET_CONSULTANT_GRID_DATA: 'SET_CONSULTANT_GRID_DATA',
    SET_CONSULTANT_GRID_LOADING_INITIAL: 'SET_CONSULTANT_GRID_LOADING_INITIAL',
    SET_CONSULTANT_GRID_LOADING: 'SET_CONSULTANT_GRID_LOADING',
    SET_CONSULTANT_GRID_LOAD_ERROR: 'SET_CONSULTANT_GRID_LOAD_ERROR',
    SET_CONSULTANT_GRID_PAGINATION: 'SET_CONSULTANT_GRID_PAGINATION',
};

export const ConsultantFAQSearchActions = {
    SET_CONSULTANT_FAQ_LOADING: 'SET_CONSULTANT_FAQ_LOADING',
    SET_CONSULTANT_FAQ_DATA: 'SET_CONSULTANT_FAQ_DATA',
    SET_CONSULTANT_FAQ_DATA_INFINITE_SCROLL: 'SET_CONSULTANT_FAQ_DATA_INFINITE_SCROLL',
    SET_CONSULTANT_FAQ_PAGINATION: 'SET_CONSULTANT_FAQ_PAGINATION',
    SET_CONSULTANT_FAQ_LOAD_ERROR: 'SET_CONSULTANT_FAQ_LOAD_ERROR',
    SET_CONSULTANT_FAQ_SEARCHBOX_INPUT: 'SET_CONSULTANT_FAQ_SEARCHBOX_INPUT',
    SET_CONSULTANT_FAQ_SEARCHBOX_LAST_QUERY: 'SET_CONSULTANT_FAQ_SEARCHBOX_LAST_QUERY',

    RESET_CONSULTANT_FAQ_SEARCHBOX: 'RESET_CONSULTANT_FAQ_SEARCHBOX',
    RESET_CONSULTANT_FAQ_PAGINATION: 'RESET_CONSULTANT_FAQ_PAGINATION',
    RESET_PAGINATION_FROM_SEARCHBOX_OR_EXPERTISE: 'RESET_PAGINATION_FROM_SEARCHBOX_OR_EXPERTISE',

    SET_CONSULTANT_FAQ_EXPERTISE: 'SET_CONSULTANT_FAQ_EXPERTISE',
    SET_CONSULTANT_FAQ_SELECTED_EXPERTISE: 'SET_CONSULTANT_FAQ_SELECTED_EXPERTISE',
};

export const UserSearchActions = {
    // Global
    SET_USER_GLOBAL_TOTAL_ACTIVE_NUMBER: 'SET_USER_GLOBAL_TOTAL_ACTIVE_NUMBER',
    SET_USER_GLOBAL_FILTERED_NUMBER: 'SET_USER_GLOBAL_FILTERED_NUMBER',
    SET_USER_GLOBAL_PERSIST_STATE: 'SET_USER_GLOBAL_PERSIST_STATE',

    // Actions for advanced filters (user)
    SET_USER_ADVANCEDFILTER_DATA: 'SET_USER_ADVANCEDFILTER_DATA',
    TOGGLE_USER_ADVANCEDFILTER: 'TOGGLE_USER_ADVANCEDFILTER',
    TOGGLE_USER_ADVANCEDFILTER_CHECKBOX: 'TOGGLE_USER_ADVANCEDFILTER_CHECKBOX',
    RESET_USER_ADVANCEDFILTER_CHECKBOX: 'RESET_USER_ADVANCEDFILTER_CHECKBOX',
    SET_USER_CATEGORY: 'SET_USER_CATEGORY',
    RESET_USER_ADVANCED_FILTER: 'RESET_USER_ADVANCED_FILTER',

    // Actions for applied filters (user)
    SET_USER_APPLIED_FILTERS: 'SET_USER_APPLIED_FILTERS',
    REMOVE_USER_APPLIED_FILTERS: 'REMOVE_USER_APPLIED_FILTERS',
    REMOVE_USER_APPLIED_FILTERS_BY_SUBCATEGORY: 'REMOVE_USER_APPLIED_FILTERS_BY_SUBCATEGORY',
    RESET_USER_APPLIED_FILTERS: 'RESET_USER_APPLIED_FILTERS',

    // Actions for searchbox (user)
    SET_USER_SEARCHBOX_LAST_QUERY: 'SET_USER_SEARCHBOX_LAST_QUERY',
    SET_USER_SEARCHBOX_INPUT: 'SET_USER_SEARCHBOX_INPUT',
    SET_USER_SEARCHBOX_FILTERSTATUS: 'SET_USER_SEARCHBOX_FILTERSTATUS',
    RESET_USER_SEARCHBOX: 'RESET_USER_SEARCHBOX',

    // Grid (user)
    SET_USER_LAST_QUERY_TYPE: 'SET_USER_LAST_QUERY_TYPE',
    SET_USER_GRID_HEADERS: 'SET_USER_GRID_HEADERS',
    SET_USER_GRID_DATA: 'SET_USER_GRID_DATA',
    SET_USER_GRID_LOADING_INITIAL: 'SET_USER_GRID_LOADING_INITIAL',
    SET_USER_GRID_LOADING: 'SET_USER_GRID_LOADING',
    SET_USER_GRID_LOAD_ERROR: 'SET_USER_GRID_LOAD_ERROR',
    SET_USER_GRID_PAGINATION: 'SET_USER_GRID_PAGINATION',
    RESET_USER_GRID_PAGINATION: 'RESET_USER_GRID_PAGINATION',
    TOGGLE_USER_GRID_STATE: 'TOGGLE_USER_GRID_STATE',

    // Admin related actions
    RESET_USER_REPRESENTATIVE_FORM: 'RESET_USER_REPRESENTATIVE_FORM',
    GET_INVESTOR_GRID_TEAM_DATA: 'GET_INVESTOR_GRID_TEAM_DATA',
    SET_USER_GRID_TEAM_DATA: 'SET_USER_GRID_TEAM_DATA',
    SET_USER_GRID_TEAM_DATA_LOAD_ERROR: 'SET_USER_GRID_TEAM_DATA_LOAD_ERROR',

    SET_USER_FORM_ROLES: 'SET_USER_FORM_ROLES',
    GET_USER_FORM_ROLES: 'GET_USER_FORM_ROLES',
    SET_USER_FORM_ROLES_LOAD_ERROR: 'SET_USER_FORM_ROLES_LOAD_ERROR',

    SET_USER_FORM_GENDERS: 'SET_USER_FORM_GENDERS',
    GET_USER_FORM_GENDERS: 'GET_USER_FORM_GENDERS',
    SET_USER_FORM_GENDERS_LOAD_ERROR: 'SET_USER_FORM_GENDERS_LOAD_ERROR',

    SET_USER_FORM_FIELDS_OF_EXPERTISE: 'SET_USER_FORM_FIELDS_OF_EXPERTISE',
    GET_USER_FORM_FIELDS_OF_EXPERTISE: 'GET_USER_FORM_FIELDS_OF_EXPERTISE',
    SET_USER_FORM_FIELDS_OF_EXPERTISE_LOAD_ERROR: 'SET_USER_FORM_FIELDS_OF_EXPERTISE_LOAD_ERROR',

    SET_USER_FORM_COUNTRIES: 'SET_USER_FORM_COUNTRIES',
    GET_USER_FORM_COUNTRIES: 'GET_USER_FORM_COUNTRIES',
    SET_USER_FORM_COUNTRIES_LOAD_ERROR: 'SET_USER_FORM_COUNTRIES_LOAD_ERROR',
};

export const NotificationActions = {
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    SET_HAS_MORE: 'SET_HAS_MORE',
    SET_TOTAL_NUMBER_OF_UNREAD_NOTIFICATIONS: 'SET_TOTAL_NUMBER_OF_UNREAD_NOTIFICATIONS',
    DISABLE_NOTIFICATION: 'DISABLE_NOTIFICATION',
    DISABLE_ALL_NOTIFICATION: 'DISABLE_ALL_NOTIFICATION',
};

export const SupportProgramActions = {
    // Actions related to displaying the support programs.
    SET_SUPPORT_PROGRAMS: 'SET_SUPPORT_PROGRAMS',
    GET_SUPPORT_PROGRAMS: 'GET_SUPPORT_PROGRAMS',
    SET_SUPPORT_PROGRAMS_ERROR: 'SET_SUPPORT_PROGRAMS_ERROR',
    // Actions related to displaying a support program's details.
    SET_VIEWED_SUPPORT_PROGRAM: 'SET_VIEWED_SUPPORT_PROGRAM',
    GET_VIEWED_SUPPORT_PROGRAM: 'GET_VIEWED_SUPPORT_PROGRAM',
    SET_VIEWED_SUPPORT_PROGRAM_ERROR: 'SET_VIEWED_SUPPORT_PROGRAM_ERROR',
};

export const InvestorApplicationActions = {
    // Investor type
    SET_INVESTOR_APPLICATION_INVESTOR_TYPE: 'SET_INVESTOR_APPLICATION_INVESTOR_TYPE',

    // For invite URL scenarios
    SET_INVESTOR_APPLICATION_INVITE_LOADING: 'SET_INVESTOR_APPLICATION_INVITE_LOADING',

    // Instution General info
    SET_INVESTOR_APPLICATION_INSTITUTION_NAME: 'SET_INVESTOR_APPLICATION_INSTITUTION_NAME',
    SET_INVESTOR_APPLICATION_INSTITUTION_IDENTITY: 'SET_INVESTOR_APPLICATION_INSTITURION_VKN',
    SET_INVESTOR_APPLICATION_INSTITUTION_EMAIL: 'SET_INVESTOR_APPLICATION_INSTITUTION_EMAIL',

    // Representative info & individual investor general info
    SET_INVESTOR_APPLICATION_COVER_LETTER: 'SET_INVESTOR_APPLICATION_COVER_LETTER',
    SET_INVESTOR_APPLICATION_REP_NAME: 'SET_INVESTOR_APPLICATION_REP_NAME',
    SET_INVESTOR_APPLICATION_REP_SURNAME: 'SET_INVESTOR_APPLICATION_REP_SURNAME',
    SET_INVESTOR_APPLICATION_REP_COUNTRY: 'SET_INVESTOR_APPLICATION_REP_COUNTRY',
    SET_INVESTOR_APPLICATION_REP_IDENTITY: 'SET_INVESTOR_APPLICATION_REP_IDENTITY',
    SET_INVESTOR_APPLICATION_REP_GENDER: 'SET_INVESTOR_APPLICATION_REP_GENDER',
    SET_INVESTOR_APPLICATION_REP_BIRTHDATE: 'SET_INVESTOR_APPLICATION_REP_BIRTHDATE',
    SET_INVESTOR_APPLICATION_REP_EMAIL: 'SET_INVESTOR_APPLICATION_REP_EMAIL',
    SET_INVESTOR_APPLICATION_REP_PHONE_NUMBER: 'SET_INVESTOR_APPLICATION_REP_PHONE_NUMBER',
    SET_INVESTOR_APPLICATION_REP_EXPERIENCE: 'SET_INVESTOR_APPLICATION_REP_EXPERIENCE',
    SET_INVESTOR_APPLICATION_REP_JOB_TITLE: 'SET_INVESTOR_APPLICATION_REP_JOB_TITLE',
    SET_INVESTOR_APPLICATION_REP_EDUCATION: 'SET_INVESTOR_APPLICATION_REP_EDUCATION',

    // Read legal agreements
    SET_INVESTOR_APPLICATION_AGREEMENTS: 'SET_INVESTOR_APPLICATION_AGREEMENTS',
    SET_INVESTOR_APPLICATION_MKK_FORM: 'SET_INVESTOR_APPLICATION_MKK_FORM',

    // From invite
    SET_INVESTOR_APPLICATION_INVITE_INDIVIDUAL: 'SET_INVESTOR_APPLICATION_INVITE_INDIVIDUAL',
    SET_INVESTOR_APPLICATION_INVITE_INSTITUTIONAL: 'SET_INVESTOR_APPLICATION_INVITE_INSTITUTIONAL',

    // Options for select components
    SET_INVESTOR_APPLICATION_GENDERS: 'SET_INVESTOR_APPLICATION_GENDERS',
    GET_INVESTOR_APPLICATION_GENDERS: 'GET_INVESTOR_APPLICATION_GENDERS',
    SET_INVESTOR_APPLICATION_GENDERS_LOAD_ERROR: 'SET_INVESTOR_APPLICATION_GENDERS_LOAD_ERROR',

    SET_INVESTOR_APPLICATION_COUNTRIES: 'SET_INVESTOR_APPLICATION_COUNTRIES',
    GET_INVESTOR_APPLICATION_COUNTRIES: 'GET_INVESTOR_APPLICATION_COUNTRIES',
    SET_INVESTOR_APPLICATION_COUNTRIES_LOAD_ERROR: 'SET_INVESTOR_APPLICATION_COUNTRIES_LOAD_ERROR',

    SET_INVESTOR_APPLICATION_EDUCATION_LEVELS: 'SET_INVESTOR_APPLICATION_EDUCATION_LEVELS',
    GET_INVESTOR_APPLICATION_EDUCATION_LEVELS: 'GET_INVESTOR_APPLICATION_EDUCATION_LEVELS',
    SET_INVESTOR_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR: 'SET_INVESTOR_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR',

    // Reset form completely
    RESET_INVESTOR_APPLICATION_FORM: 'RESET_INVESTOR_APPLICATION_FORM',

    // Reset institution info
    RESET_INVESTOR_APPLICATION_GENERAL_INFO: 'RESET_INVESTOR_APPLICATION_GENERAL_INFO',
};

export const StartupApplicationActions = {
    // Startup type
    SET_STARTUP_APPLICATION_STARTUP_TYPE: 'SET_STARTUP_APPLICATION_STARTUP_TYPE',

    // For invite URL scenarios
    SET_STARTUP_APPLICATION_INVITE_LOADING: 'SET_STARTUP_APPLICATION_INVITE_LOADING',

    // Startup General info
    SET_STARTUP_APPLICATION_TRADE_NAME: 'SET_STARTUP_APPLICATION_TRADE_NAME',
    SET_STARTUP_APPLICATION_EQUITY_IDENTITY: 'SET_STARTUP_APPLICATION_EQUITY_IDENTITY',
    SET_STARTUP_APPLICATION_EQUITY_EMAIL: 'SET_STARTUP_APPLICATION_EQUITY_EMAIL',

    // Representative info & individual startup general info
    SET_STARTUP_APPLICATION_STARTUP_NAME: 'SET_STARTUP_APPLICATION_STARTUP_NAME',
    SET_STARTUP_APPLICATION_COVER_LETTER: 'SET_STARTUP_APPLICATION_COVER_LETTER',
    SET_STARTUP_APPLICATION_STARTUP_WEBSITE: 'SET_STARTUP_APPLICATION_STARTUP_WEBSITE',
    SET_STARTUP_APPLICATION_REP_NAME: 'SET_STARTUP_APPLICATION_REP_NAME',
    SET_STARTUP_APPLICATION_REP_SURNAME: 'SET_STARTUP_APPLICATION_REP_SURNAME',
    SET_STARTUP_APPLICATION_REP_COUNTRY: 'SET_STARTUP_APPLICATION_REP_COUNTRY',
    SET_STARTUP_APPLICATION_REP_IDENTITY: 'SET_STARTUP_APPLICATION_REP_IDENTITY',
    SET_STARTUP_APPLICATION_REP_GENDER: 'SET_STARTUP_APPLICATION_REP_GENDER',
    SET_STARTUP_APPLICATION_REP_BIRTHDATE: 'SET_STARTUP_APPLICATION_REP_BIRTHDATE',
    SET_STARTUP_APPLICATION_REP_EMAIL: 'SET_STARTUP_APPLICATION_REP_EMAIL',
    SET_STARTUP_APPLICATION_REP_PHONE_NUMBER: 'SET_STARTUP_APPLICATION_REP_PHONE_NUMBER',
    SET_STARTUP_APPLICATION_REP_EXPERIENCE: 'SET_STARTUP_APPLICATION_REP_EXPERIENCE',
    SET_STARTUP_APPLICATION_REP_JOB_TITLE: 'SET_STARTUP_APPLICATION_REP_JOB_TITLE',
    SET_STARTUP_APPLICATION_REP_EDUCATION: 'SET_STARTUP_APPLICATION_REP_EDUCATION',

    // Read legal agreements
    SET_STARTUP_APPLICATION_AGREEMENTS: 'SET_STARTUP_APPLICATION_AGREEMENTS',

    // From invite
    SET_STARTUP_APPLICATION_INVITE_INDIVIDUAL: 'SET_STARTUP_APPLICATION_INVITE_INDIVIDUAL',
    SET_STARTUP_APPLICATION_INVITE_EQUITY: 'SET_STARTUP_APPLICATION_INVITE_EQUITY',

    // Options for select components
    SET_STARTUP_APPLICATION_GENDERS: 'SET_STARTUP_APPLICATION_GENDERS',
    GET_STARTUP_APPLICATION_GENDERS: 'GET_STARTUP_APPLICATION_GENDERS',
    SET_STARTUP_APPLICATION_GENDERS_LOAD_ERROR: 'SET_STARTUP_APPLICATION_GENDERS_LOAD_ERROR',

    SET_STARTUP_APPLICATION_COUNTRIES: 'SET_STARTUP_APPLICATION_COUNTRIES',
    GET_STARTUP_APPLICATION_COUNTRIES: 'GET_STARTUP_APPLICATION_COUNTRIES',
    SET_STARTUP_APPLICATION_COUNTRIES_LOAD_ERROR: 'SET_STARTUP_APPLICATION_COUNTRIES_LOAD_ERROR',

    SET_STARTUP_APPLICATION_EDUCATION_LEVELS: 'SET_STARTUP_APPLICATION_EDUCATION_LEVELS',
    GET_STARTUP_APPLICATION_EDUCATION_LEVELS: 'GET_STARTUP_APPLICATION_EDUCATION_LEVELS',
    SET_STARTUP_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR: 'SET_STARTUP_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR',

    // Reset form completely
    RESET_STARTUP_APPLICATION_FORM: 'RESET_STARTUP_APPLICATION_FORM',

    // Reset institution info
    RESET_STARTUP_APPLICATION_GENERAL_INFO: 'RESET_STARTUP_APPLICATION_GENERAL_INFO',
};

export const MemberApplicationActions = {
    // For invite URL scenarios
    SET_MEMBER_APPLICATION_INVITE_LOADING: 'SET_MEMBER_APPLICATION_INVITE_LOADING',

    SET_MEMBER_APPLICATION_MEMBER_NAME: 'SET_MEMBER_APPLICATION_MEMBER_NAME',
    SET_MEMBER_APPLICATION_MEMBER_SURNAME: 'SET_MEMBER_APPLICATION_MEMBER_SURNAME',
    SET_MEMBER_APPLICATION_MEMBER_COUNTRY: 'SET_MEMBER_APPLICATION_MEMBER_COUNTRY',
    SET_MEMBER_APPLICATION_MEMBER_IDENTITY: 'SET_MEMBER_APPLICATION_MEMBER_IDENTITY',
    SET_MEMBER_APPLICATION_MEMBER_GENDER: 'SET_MEMBER_APPLICATION_MEMBER_GENDER',
    SET_MEMBER_APPLICATION_MEMBER_BIRTHDATE: 'SET_MEMBER_APPLICATION_MEMBER_BIRTHDATE',
    SET_MEMBER_APPLICATION_MEMBER_EMAIL: 'SET_MEMBER_APPLICATION_MEMBER_EMAIL',
    SET_MEMBER_APPLICATION_MEMBER_PHONE_NUMBER: 'SET_MEMBER_APPLICATION_MEMBER_PHONE_NUMBER',
    SET_MEMBER_APPLICATION_MEMBER_EXPERIENCE: 'SET_MEMBER_APPLICATION_MEMBER_EXPERIENCE',
    SET_MEMBER_APPLICATION_MEMBER_EDUCATION: 'SET_MEMBER_APPLICATION_MEMBER_EDUCATION',

    // Read legal agreements
    SET_MEMBER_APPLICATION_AGREEMENTS: 'SET_MEMBER_APPLICATION_AGREEMENTS',

    // From invite
    SET_MEMBER_APPLICATION_INVITE_INFOS: 'SET_MEMBER_APPLICATION_INVITE_INFOS',

    // Options for select components
    SET_MEMBER_APPLICATION_GENDERS: 'SET_MEMBER_APPLICATION_GENDERS',
    GET_MEMBER_APPLICATION_GENDERS: 'GET_MEMBER_APPLICATION_GENDERS',
    SET_MEMBER_APPLICATION_GENDERS_LOAD_ERROR: 'SET_MEMBER_APPLICATION_GENDERS_LOAD_ERROR',

    SET_MEMBER_APPLICATION_COUNTRIES: 'SET_MEMBER_APPLICATION_COUNTRIES',
    GET_MEMBER_APPLICATION_COUNTRIES: 'GET_MEMBER_APPLICATION_COUNTRIES',
    SET_MEMBER_APPLICATION_COUNTRIES_LOAD_ERROR: 'SET_MEMBER_APPLICATION_COUNTRIES_LOAD_ERROR',

    SET_MEMBER_APPLICATION_EDUCATION_LEVELS: 'SET_MEMBER_APPLICATION_EDUCATION_LEVELS',
    GET_MEMBER_APPLICATION_EDUCATION_LEVELS: 'GET_MEMBER_APPLICATION_EDUCATION_LEVELS',
    SET_MEMBER_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR: 'SET_MEMBER_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR',

    // Reset form completely
    RESET_MEMBER_APPLICATION_FORM: 'RESET_MEMBER_APPLICATION_FORM',
};

export const MentorApplicationActions = {
    SET_MENTOR_APPLICATION_MENTOR_NAME: 'SET_MENTOR_APPLICATION_MENTOR_NAME',
    SET_MENTOR_APPLICATION_MENTOR_SURNAME: 'SET_MENTOR_APPLICATION_MENTOR_SURNAME',
    SET_MENTOR_APPLICATION_MENTOR_COUNTRY: 'SET_MENTOR_APPLICATION_MENTOR_COUNTRY',
    SET_MENTOR_APPLICATION_MENTOR_IDENTITY: 'SET_MENTOR_APPLICATION_MENTOR_IDENTITY',
    SET_MENTOR_APPLICATION_MENTOR_GENDER: 'SET_MENTOR_APPLICATION_MENTOR_GENDER',
    SET_MENTOR_APPLICATION_MENTOR_BIRTHDATE: 'SET_MENTOR_APPLICATION_MENTOR_BIRTHDATE',
    SET_MENTOR_APPLICATION_MENTOR_EMAIL: 'SET_MENTOR_APPLICATION_MENTOR_EMAIL',
    SET_MENTOR_APPLICATION_MENTOR_PHONE_NUMBER: 'SET_MENTOR_APPLICATION_MENTOR_PHONE_NUMBER',
    SET_MENTOR_APPLICATION_MENTOR_EXPERIENCE: 'SET_MENTOR_APPLICATION_MENTOR_EXPERIENCE',
    SET_MENTOR_APPLICATION_MENTOR_EDUCATION: 'SET_MENTOR_APPLICATION_MENTOR_EDUCATION',
    SET_MENTOR_APPLICATION_COVER_LETTER: 'SET_MENTOR_APPLICATION_COVER_LETTER',
    SET_MENTOR_APPLICATION_MENTOR_INTERESTED_VERTICALS: 'SET_MENTOR_APPLICATION_MENTOR_INTERESTED_VERTICALS',
    SET_MENTOR_APPLICATION_MENTOR_INTERESTED_TECHNOLOGIES: 'SET_MENTOR_APPLICATION_MENTOR_INTERESTED_TECHNOLOGIES',
    SET_MENTOR_APPLICATION_MENTOR_FILES: 'SET_MENTOR_APPLICATION_MENTOR_FILES',

    // Read legal agreements
    SET_MENTOR_APPLICATION_AGREEMENTS: 'SET_MENTOR_APPLICATION_AGREEMENTS',

    // From invite
    SET_MEMBER_APPLICATION_INVITE_INFOS: 'SET_MEMBER_APPLICATION_INVITE_INFOS',

    // Options for select components
    SET_MENTOR_APPLICATION_GENDERS: 'SET_MENTOR_APPLICATION_GENDERS',
    GET_MENTOR_APPLICATION_GENDERS: 'GET_MENTOR_APPLICATION_GENDERS',
    SET_MENTOR_APPLICATION_GENDERS_LOAD_ERROR: 'SET_MENTOR_APPLICATION_GENDERS_LOAD_ERROR',

    SET_MENTOR_APPLICATION_COUNTRIES: 'SET_MENTOR_APPLICATION_COUNTRIES',
    GET_MENTOR_APPLICATION_COUNTRIES: 'GET_MENTOR_APPLICATION_COUNTRIES',
    SET_MENTOR_APPLICATION_COUNTRIES_LOAD_ERROR: 'SET_MENTOR_APPLICATION_COUNTRIES_LOAD_ERROR',

    SET_MENTOR_APPLICATION_EDUCATION_LEVELS: 'SET_MENTOR_APPLICATION_EDUCATION_LEVELS',
    GET_MENTOR_APPLICATION_EDUCATION_LEVELS: 'GET_MENTOR_APPLICATION_EDUCATION_LEVELS',
    SET_MENTOR_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR: 'SET_MENTOR_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR',

    SET_MENTOR_APPLICATION_VERTICALS: 'SET_MENTOR_APPLICATION_VERTICALS',
    GET_MENTOR_APPLICATION_VERTICALS: 'GET_MENTOR_APPLICATION_VERTICALS',
    SET_MENTOR_APPLICATION_VERTICALS_LOAD_ERROR: 'SET_MENTOR_APPLICATION_VERTICALS_LOAD_ERROR',

    SET_MENTOR_APPLICATION_TECHNOLOGIES: 'SET_MENTOR_APPLICATION_TECHNOLOGIES',
    GET_MENTOR_APPLICATION_TECHNOLOGIES: 'GET_MENTOR_APPLICATION_TECHNOLOGIES',
    SET_MENTOR_APPLICATION_TECHNOLOGIES_LOAD_ERROR: 'SET_MENTOR_APPLICATION_TECHNOLOGIES_LOAD_ERROR',

    // Reset form completely
    RESET_MENTOR_APPLICATION_FORM: 'RESET_MENTOR_APPLICATION_FORM',
};

export const SupportProgramApplicationActions = {
    SET_SUPPORT_PROGRAM_APPLICATION: 'SET_SUPPORT_PROGRAM_APPLICATION',
    GET_SUPPORT_PROGRAM_APPLICATION: 'GET_SUPPORT_PROGRAM_APPLICATION',
    SET_SUPPORT_PROGRAM_APPLICATION_ERROR: 'SET_SUPPORT_PROGRAM_APPLICATION_ERROR',

    // Representative info
    SET_SUPPORT_PROGRAM_APPLICATION_REP_INFO: 'SET_SUPPORT_PROGRAM_APPLICATION_REP_INFO',
    GET_SUPPORT_PROGRAM_APPLICATION_REP_INFO: 'GET_SUPPORT_PROGRAM_APPLICATION_REP_INFO',
    SET_SUPPORT_PROGRAM_APPLICATION_REP_INFO_ERROR: 'SET_SUPPORT_PROGRAM_APPLICATION_REP_INFO_ERROR',

    SET_SUPPORT_PROGRAM_APPLICATION_RECAPTCHA: 'SET_SUPPORT_PROGRAM_APPLICATION_RECAPTCHA',

    // Read legal agreements
    SET_SUPPORT_PROGRAM_APPLICATION_AGREEMENTS: 'SET_SUPPORT_PROGRAM_APPLICATION_AGREEMENTS',

    // REPRESENTATIVE_INFORMATION
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_NAME: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_NAME',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_LASTNAME: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_LASTNAME',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_NATIONALITY: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_NATIONALITY',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_IDENTITY: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_IDENTITY',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_GENDER: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_GENDER',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_BIRTHDATE: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_BIRTHDATE',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_EMAIL: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_EMAIL',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_PHONENUMBER: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_PHONENUMBER',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_WORK_EXPERIENCE: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_WORK_EXPERIENCE',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_WORK_TITLE: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_WORK_TITLE',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_EDUCATION: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_EDUCATION',

    // GENERAL_INFORMATION
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q01: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q01',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q02: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q02',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q03: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q03',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q04: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q04',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q05: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q05',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q06: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q06',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q07: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q07',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q08: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q08',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q09_P1: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q09_P1',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q09_P2: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q09_P2',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q10: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q10',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q11: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q11',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q12: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q12',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q13: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q13',

    // INTELLECTUAL_PROPERTY
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q14: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q14',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q15: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q15',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q16: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q16',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q17: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q17',

    // FOUNDER_AND_TEAM_INFORMATION
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q18: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q18',
    // Initialize with empty data
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_INIT: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_INIT',

    // LinkedIn
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1_ADD: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1_ADD',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1_REMOVE: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1_REMOVE',

    // Github
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2_ADD: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2_ADD',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2_REMOVE: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2_REMOVE',

    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_INIT: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_INIT',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_ADD: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_ADD',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_REMOVE: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_REMOVE',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q21: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q21',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q22: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q22',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q23_P1: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q23_P1',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q23_P2: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q23_P2',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q24: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q24',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q25_P1: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q25_P1',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q25_P2: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q25_P2',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q26_P1: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q26_P1',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q26_P2: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q26_P2',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q27: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q27',

    // Market/Revenue Model/Capital Structure
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q28: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q28', // Q01 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q29: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q29', // Q01 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q30: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q30', // Q01 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q31: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q31', // Q01 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q32: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q32', // Q27 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q33: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q33', // Q01 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_INIT: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_INIT',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_TYPES: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_TYPES',
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P1: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P1', // Q23 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P2: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P2', // Q23 Based (Free text)
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P3: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P3', // Q23 Based (Combobox)
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q35: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q35', // Q15 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q36: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q36', // Q01 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q37: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q37', // Q01 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q38: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q38', // Q01 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q39: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q39', // Q01 Based

    // Other information
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q40_P1: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q40_P1', // Q23 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q40_P2: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q40_P2', // Q23 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q41: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q41', // Q01 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q42: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q42', // Q27 Based
    SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q43: 'SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q43', // Q08 Based
};

export const InboxActions = {
    SET_SOCKET: 'SET_SOCKET',
    SET_VIEWED_ROOM: 'SET_VIEWED_ROOM',
    SET_ACTIVE_MESSAGE: 'SET_ACTIVE_MESSAGE',
    SEND_MESSAGE: 'SEND_MESSAGE',
    SET_ROOM_LIST: 'SET_ROOM_LIST',
    SET_MESSAGES: 'SET_MESSAGES',
    ADD_ROOM: 'ADD_ROOM',
    ADD_MESSAGE: 'ADD_MESSAGE',
    GET_RECEIVED: 'GET_RECEIVED',
    REMOVE_MESSAGE: 'REMOVE_MESSAGE',
    APPROVE_ROOM: 'APPROVE_ROOM',
    REJECT_ROOM: 'REJECT_ROOM',
    INCREASE_UNREAD: 'INCREASE_UNREAD',
    DECREASE_UNREAD: 'DECREASE_UNREAD',
    CLEAR_VIEWED_ROOM: 'CLEAR_VIEWED_ROOM',
    UPDATE_ROOM_LAST_MESSAGE: 'UPDATE_ROOM_LAST_MESSAGE',
    REMOVE_ROOM_LAST_MESSAGE: 'REMOVE_ROOM_LAST_MESSAGE',
    ADD_MESSAGES: 'ADD_MESSAGES',
    SET_USER_INFO: 'SET_USER_INFO',
    SET_SENDER_INFO: 'SET_SENDER_INFO',
    SET_HAS_BLOCKED_TARGET: 'SET_HAS_BLOCKED_TARGET',
    SET_INSIDE_A_ROOM: 'SET_INSIDE_A_ROOM',
    MARK_ALL_AS_READ: 'MARK_ALL_AS_READ',
    SET_NEW_CONTACT: 'SET_NEW_CONTACT',
};

export const NavigationActions = {
    SET_TITLE_TEXT: 'SET_TITLE_TEXT',
};

export const CommonActions = {
    SET_ACTIVE_REQUEST: 'SET_ACTIVE_REQUEST',
};

export const CookieActions = {
    SET_COOKIE_BANNER: 'SET_COOKIE_BANNER',
    SET_PERFORMANCE_COOKIE: 'SET_PERFORMANCE_COOKIE',
};
