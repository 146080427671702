import { cloneDeep, isEmpty } from 'lodash';
import { UserSearchActions } from 'fintech/store/actions/ActionTypes';
import { GRID_HEADER_STATE } from 'fintech/store/actions/entrepreneur';
import { defaultFilterCategories, defaultSearchBoxFilters } from 'fintech/constants/userSearch';

const initialState = {
    global: {
        headers: null,
        totalNumber: null,
        filteredNumber: null,
        lastQueryType: null,
        persist: false,
    },
    advancedFilter: {
        isDisplayed: false,
        selectedCategory: 0,
        categories: defaultFilterCategories,
        data: null,
    },
    appliedFilters: {
        fintechVerticals: [],
        technologies: [],
        roles: [],
    },
    searchBox: {
        input: '',
        lastQueryParams: {},
        filters: defaultSearchBoxFilters,
    },
    grid: {
        users: null,
        rows: [], // Needs to be fetched from server
        isLoading: true,
        gridDataLoading: false,
        isError: false,
        pagination: {
            count: 0,
            pageSize: 15,
            defaultPage: 1,
            page: 1,
        },
    },
    form: {
        roles: {
            data: null,
            isLoading: true,
            isError: false,
        },
        genders: {
            data: null,
            isLoading: true,
            isError: false,
        },
        expertise: {
            data: null,
            isLoading: true,
            isError: false,
        },
        countries: {
            data: null,
            isLoading: true,
            isError: false,
        },
    },
};

const userSearch = (state = initialState, action) => {
    const { type } = action;
    const advancedFilter = state.advancedFilter;
    const data = cloneDeep(advancedFilter.data);

    switch (type) {
        // Global
        case UserSearchActions.SET_USER_GLOBAL_PERSIST_STATE:
            return {
                ...state,
                global: {
                    ...state.global,
                    persist: action.data,
                },
            };
        case UserSearchActions.SET_USER_GLOBAL_TOTAL_ACTIVE_NUMBER:
            return {
                ...state,
                global: {
                    ...state.global,
                    totalNumber: action.data,
                },
            };
        case UserSearchActions.SET_USER_GLOBAL_FILTERED_NUMBER:
            return {
                ...state,
                global: {
                    ...state.global,
                    filteredNumber: action.data,
                },
            };
        case UserSearchActions.SET_USER_ADVANCEDFILTER_DATA:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    data: action.data,
                },
            };
        case UserSearchActions.TOGGLE_USER_ADVANCEDFILTER:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    isDisplayed: !state.advancedFilter.isDisplayed,
                },
            };
        case UserSearchActions.TOGGLE_USER_ADVANCEDFILTER_CHECKBOX: {
            const { vertical, checkboxInputID } = action.data;
            const verticalKey = vertical;

            const selectedIndex = data[verticalKey].findIndex((e) => e.id === checkboxInputID);
            data[verticalKey][selectedIndex] = {
                ...data[verticalKey][selectedIndex],
                selected: !data[verticalKey][selectedIndex].selected,
            };

            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    data,
                },
            };
        }
        case UserSearchActions.RESET_USER_ADVANCEDFILTER_CHECKBOX:
            for (const property in data) {
                data[property].forEach((element, index, arr) => {
                    arr[index] = {
                        ...element,
                        selected: false,
                    };
                });
            }
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    data,
                },
            };
        case UserSearchActions.SET_USER_CATEGORY:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    selectedCategory: action.data,
                },
            };
        case UserSearchActions.RESET_USER_ADVANCED_FILTER:
            return {
                ...state,
                advancedFilter: {
                    ...initialState.advancedFilter,
                    data: state.advancedFilter.data,
                },
            };
        // Applied Filters
        case UserSearchActions.SET_USER_APPLIED_FILTERS: {
            const newFilters = !isEmpty(action.data) ? action.data : initialState.appliedFilters;
            return {
                ...state,
                appliedFilters: newFilters,
            };
        }
        case UserSearchActions.REMOVE_USER_APPLIED_FILTERS: {
            const appliedFilterCategory = action.data.categoryPrefix;
            const appliedFilterID = action.data.id;

            if (appliedFilterID === null) {
                return {
                    ...state,
                    appliedFilters: {
                        ...state.appliedFilters,
                        [appliedFilterCategory]: [],
                    },
                };
            } else {
                return {
                    ...state,
                    appliedFilters: {
                        ...state.appliedFilters,
                        [appliedFilterCategory]: state.appliedFilters[appliedFilterCategory].filter(
                            (e) => e.id !== appliedFilterID
                        ),
                    },
                };
            }
        }
        case UserSearchActions.REMOVE_USER_APPLIED_FILTERS_BY_SUBCATEGORY: {
            const _subCategoryFilter = action.data.subCategory === '' ? null : action.data.subCategory;

            return {
                ...state,
                appliedFilters: {
                    ...state.appliedFilters,
                    [action.data.categoryPrefix]: state.appliedFilters[action.data.categoryPrefix].filter(
                        (e) => e.parentData !== _subCategoryFilter
                    ),
                },
            };
        }
        case UserSearchActions.RESET_USER_APPLIED_FILTERS: {
            return {
                ...state,
                appliedFilters: initialState.appliedFilters,
            };
        }
        // SearchBox
        case UserSearchActions.SET_USER_SEARCHBOX_LAST_QUERY:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    lastQueryParams: action.data,
                },
            };
        case UserSearchActions.SET_USER_SEARCHBOX_INPUT:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    input: action.data,
                },
            };
        case UserSearchActions.SET_USER_SEARCHBOX_FILTERSTATUS:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    filters: state.searchBox.filters.map((element) => {
                        return action.data.includes(element.label)
                            ? {
                                  ...element,
                                  status: true,
                              }
                            : {
                                  ...element,
                                  status: false,
                              };
                    }),
                },
            };
        case UserSearchActions.RESET_USER_SEARCHBOX:
            return {
                ...state,
                searchBox: initialState.searchBox,
            };
        // Grid
        case UserSearchActions.SET_USER_LAST_QUERY_TYPE:
            return {
                ...state,
                global: {
                    ...state.global,
                    lastQueryType: action.data,
                },
            };
        case UserSearchActions.SET_USER_GRID_HEADERS:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    headers: action.data,
                },
            };
        case UserSearchActions.SET_USER_GRID_DATA:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    users: action.data.users,
                    rows: action.data.rows,
                    isLoading: false,
                    gridDataLoading: false,
                },
            };
        case UserSearchActions.SET_USER_GRID_LOADING_INITIAL:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    rows: [],
                    isLoading: true,
                    isError: false,
                },
            };
        case UserSearchActions.SET_USER_GRID_LOADING:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    gridDataLoading: true,
                    isError: false,
                },
            };
        case UserSearchActions.SET_USER_GRID_LOAD_ERROR:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    isLoading: false,
                    gridDataLoading: false,
                    isError: true,
                },
            };

        case UserSearchActions.TOGGLE_USER_GRID_STATE: {
            let _headers = cloneDeep(state.grid.headers);
            _headers = _headers.map((el) =>
                el.type === 'sortLabel' && el.id !== action.data
                    ? {
                          ...el,
                          value: {
                              ...el.value,
                          },
                          status: GRID_HEADER_STATE.NONE,
                      }
                    : el
            );
            const selectedHeader = state.grid.headers.findIndex((e) => e.id === action.data);

            switch (_headers[selectedHeader].status) {
                case GRID_HEADER_STATE.NONE:
                    _headers[selectedHeader].status = GRID_HEADER_STATE.ASC;
                    break;
                case GRID_HEADER_STATE.ASC:
                    _headers[selectedHeader].status = GRID_HEADER_STATE.DESC;
                    break;
                case GRID_HEADER_STATE.DESC:
                    _headers[selectedHeader].status = GRID_HEADER_STATE.ASC;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                grid: {
                    ...state.grid,
                    headers: _headers,
                },
            };
        }
        // Pagination
        case UserSearchActions.SET_USER_GRID_PAGINATION:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    pagination: {
                        ...state.grid.pagination,
                        ...action.data,
                    },
                },
            };
        case UserSearchActions.RESET_USER_GRID_PAGINATION:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    pagination: initialState.grid.pagination,
                },
            };

        // Admin actions
        // Roles
        case UserSearchActions.SET_USER_FORM_ROLES: {
            const { roles } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    roles: {
                        ...state.form.roles,
                        data: roles.data?.map((r) => ({ ...r, data: r.description })),
                        isLoading: false,
                    },
                },
            };
        }
        case UserSearchActions.GET_USER_FORM_ROLES:
            return {
                ...state,
                form: {
                    ...state.form,
                    roles: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case UserSearchActions.SET_USER_FORM_ROLES_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    roles: {
                        ...state.form.roles,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Genders
        case UserSearchActions.SET_USER_FORM_GENDERS: {
            const { genders } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    genders: {
                        ...state.form.genders,
                        data: genders.data,
                        isLoading: false,
                    },
                },
            };
        }
        case UserSearchActions.GET_USER_FORM_GENDERS:
            return {
                ...state,
                form: {
                    ...state.form,
                    genders: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case UserSearchActions.SET_USER_FORM_GENDERS_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    genders: {
                        ...state.form.genders,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Expertise
        case UserSearchActions.SET_USER_FORM_FIELDS_OF_EXPERTISE: {
            const { expertise } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    expertise: {
                        ...state.form.expertise,
                        data: expertise.data,
                        isLoading: false,
                    },
                },
            };
        }
        case UserSearchActions.GET_USER_FORM_FIELDS_OF_EXPERTISE:
            return {
                ...state,
                form: {
                    ...state.form,
                    expertise: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case UserSearchActions.SET_USER_FORM_FIELDS_OF_EXPERTISE_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    expertise: {
                        ...state.form.expertise,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Countries
        case UserSearchActions.SET_USER_FORM_COUNTRIES: {
            const { countries } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    countries: {
                        ...state.form.countries,
                        data: countries.data,
                        isLoading: false,
                    },
                },
            };
        }
        case UserSearchActions.GET_USER_FORM_COUNTRIES:
            return {
                ...state,
                form: {
                    ...state.form,
                    countries: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case UserSearchActions.SET_USER_FORM_COUNTRIES_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    countries: {
                        ...state.form.countries,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        default:
            return state;
    }
};

export default userSearch;
