import { makeStyles } from '@material-ui/core';
import {
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_BASE,
    COLOR_THEME_FILL_01,
    fontStyle,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    mediaWrapper: {
        backgroundColor: COLOR_THEME_FILL_01,
        borderRight: `8px solid ${COLOR_PRIMARY_SHADE_02}`,
        borderLeft: `8px solid ${COLOR_PRIMARY_SHADE_02}`,
        borderBottom: `8px solid ${COLOR_PRIMARY_SHADE_02}`,
        position: 'relative',
        width: '100%',
        height: '690px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            height: '160px !important',
        },
    },
    media: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        border: `6px solid ${COLOR_THEME_FILL_01}`,
        top: '0',
        left: '0',
        opacity: 0.2,
    },
    mediaText: {
        ...fontStyle,
        fontSize: '32px',
        position: 'absolute',
        color: COLOR_SECONDARY_BASE,
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
    },
}));

export default useStyles;
