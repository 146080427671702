import * as React from 'react';

function SvgIconsMultipleRapor(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#D9DCE0" d="M3.75 20.5L1 17.75l11-11 2.75 2.75z" />
                <path fill="#3F5162" d="M14.75 15L12 12.25l7.219-7.219L18.188 4H23v4.813l-1.031-1.032z" />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleRapor;
