import { EstablishmentActions } from 'fintech/store/actions/ActionTypes';
import { parseSubVerticals, parseTags, parseEstablishmentInvestmentData } from 'fintech/utils/DataParser';
import { format, parse } from 'date-fns';
import { ISO_FORMAT, NUMERIC_FULL_DATE } from 'fintech/utils/DateUtils';

const initialState = {
    establishments: [],
    intro: {
        data: {},
        isLoading: false,
        isError: false,
        form: {
            investorTypes: {
                data: [],
                selectedType: {
                    id: 0,
                    data: '',
                },
                isError: false,
                isLoading: false,
            },
            investorGroups: {
                data: [],
                selectedGroup: {
                    id: 0,
                    data: '',
                },
                isError: false,
                isLoading: false,
            },
            verticals: {
                data: [],
                selectedVerticals: [],
                isError: false,
                isLoading: false,
            },
            investmentAreas: {
                data: [],
                isError: false,
                isLoading: false,
            },
            stages: {
                data: [],
                isError: false,
                isLoading: false,
            },
        },
    },
    about: {
        data: {
            about: '',
        },
        isLoading: false,
        isError: false,
    },
    team: {
        data: [],
        isLoading: false,
        isError: false,
    },
    investmentPreferences: {
        data: {},
        form: {
            managementTypes: {
                data: [],
                isLoading: false,
                isError: false,
            },
            investmentAmounts: {
                data: [],
                isLoading: false,
                isError: false,
            },
            companyInvestmentAmounts: {
                data: [],
                isLoading: false,
                isError: false,
            },
            startupForm: {
                id: null,
                startup: {
                    id: null,
                    startupId: null,
                    startupName: null,
                    freeTextStartupName: null,
                    isValid: false,
                    unregistered: false,
                },
                startupVisible: true,
                investmentDate: format(new Date(), ISO_FORMAT),
                investmentDateIsValid: true,
                investmentDateVisible: true,
                investmentAmount: {
                    id: null,
                    value: '',
                    currencyId: 1,
                    isValid: false,
                },
                investmentAmountVisible: true,
                investmentVisible: true,
                sending: false,
            },
            startupsData: {
                data: [],
                isLoading: false,
                isError: false,
            },
        },
        investments: {
            data: {
                editable: false,
                startupInvestments: [],
            },
            isLoading: false,
            isError: false,
        },
        isLoading: false,
        isError: false,
    },
    isLoading: true,
    isError: false,
};

const establishment = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case EstablishmentActions.SET_ESTABLISHMENT_LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_LIST: {
            const { establishments } = action;
            return {
                ...state,
                establishments: establishments,
                isError: false,
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_LOAD_ERROR: {
            return {
                ...state,
                isError: true,
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INTRO_LOADING: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    isLoading: true,
                    isError: false,
                    data: {},
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INTRO_DATA: {
            const { data } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    data: {
                        ...state.intro.data,
                        ...data,
                        verticals: parseSubVerticals(data.investorFintechSubVerticalInterest, true),
                        startupStagePreferenceSet: parseTags(data.startupStagePreferenceSet),
                        investmentAreas: parseTags(data.investmentAreas),
                    },
                    isLoading: false,
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INTRO_ERROR: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    isError: true,
                    isLoading: false,
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INTRO_VISIT_TIMES: {
            const { visitTimes } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    data: {
                        ...state.intro.data,
                        visitTimes,
                    },
                },
            };
        }
        case EstablishmentActions.RESET_ESTABLISHMENT_INTRO_CARD: {
            return {
                ...state,
                intro: {
                    ...initialState.intro,
                    form: {
                        ...initialState.intro.form,
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_TYPES_LOADING: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        investorTypes: {
                            ...state.intro.form.investorTypes,
                            isLoading: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_TYPES: {
            const { data, selectedType } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        investorTypes: {
                            data: data,
                            selectedType: selectedType || initialState.intro.form.investorTypes.selectedType,
                            isLoading: false,
                            isError: false,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_TYPES_LOAD_ERROR: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        investorTypes: {
                            ...state.intro.form.investorTypes,
                            isError: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_GROUP_LOADING: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        investorGroups: {
                            ...state.intro.form.investorGroups,
                            isLoading: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_GROUP: {
            const { data, selectedGroup } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        investorGroups: {
                            data: data,
                            selectedGroup: selectedGroup || initialState.intro.form.investorGroups.selectedGroup,
                            isLoading: false,
                            isError: false,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INVESTOR_GROUP_LOAD_ERROR: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        investorGroups: {
                            ...state.intro.form.investorGroups,
                            isError: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_VERTICALS_LOADING: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        verticals: {
                            ...state.intro.form.verticals,
                            isLoading: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_VERTICALS_DATA: {
            const { data } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        verticals: {
                            data,
                            isLoading: false,
                            isError: false,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_VERTICALS_LOAD_ERROR: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        verticals: {
                            ...state.intro.form.verticals,
                            isError: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INVESTMENT_AREAS_LOADING: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        investmentAreas: {
                            ...state.intro.form.investmentAreas,
                            isLoading: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INVESTMENT_AREAS_DATA: {
            const { data } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        investmentAreas: {
                            data,
                            isLoading: false,
                            isError: false,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INVESTMENT_AREAS_LOAD_ERROR: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        investmentAreas: {
                            ...state.intro.form.investmentAreas,
                            isError: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_STAGES_LOADING: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        stages: {
                            ...state.intro.form.stages,
                            isLoading: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_STAGES_DATA: {
            const { data } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        stages: {
                            data,
                            isLoading: false,
                            isError: false,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_STAGES_LOAD_ERROR: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: {
                        ...state.intro.form,
                        stages: {
                            ...state.intro.form.stages,
                            isError: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.REMOVE_ESTABLISHMENT_INFORMATION_CARD_EDIT_STATE: {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    form: initialState.intro.form,
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_ABOUT_INFO_LOADİNG: {
            return {
                ...state,
                about: {
                    ...state.about,
                    isLoading: true,
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_ABOUT_INFO_DATA: {
            const { data } = action;
            return {
                ...state,
                about: {
                    data,
                    isLoading: false,
                    isError: false,
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_ABOUT_INFO_LOAD_ERROR: {
            return {
                ...state,
                about: {
                    ...state.about,
                    isLoading: false,
                    isError: true,
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_TEAM_LOADING: {
            return {
                ...state,
                team: {
                    ...state.team,
                    isLoading: true,
                    isError: false,
                    data: [],
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_TEAM_DATA: {
            const { team, id } = action;
            return {
                ...state,
                team: {
                    ...state.team,
                    data: {
                        ...team.data,
                        id: id,
                    },
                    isLoading: false,
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_TEAM_LOAD_ERROR: {
            return {
                ...state,
                team: {
                    ...state.team,
                    isLoading: false,
                    isError: true,
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_LOADING: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    isLoading: true,
                    isError: false,
                    data: {},
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_DATA: {
            const { data } = action;
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    data,
                    isLoading: false,
                    isError: false,
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_LOAD_ERROR: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    isLoading: false,
                    isError: true,
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_LOADING: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        managementTypes: {
                            ...state.investmentPreferences.form.managementTypes,
                            isLoading: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_DATA: {
            const { data } = action;
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        managementTypes: {
                            data,
                            isLoading: false,
                            isError: false,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_LOAD_ERROR: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        managementTypes: {
                            ...state.investmentPreferences.form.managementTypes,
                            isLoading: false,
                            isError: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_LOADING: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        investmentAmounts: {
                            ...state.investmentPreferences.form.investmentAmounts,
                            isLoading: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_DATA: {
            const { data } = action;
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        investmentAmounts: {
                            data,
                            isLoading: false,
                            isError: false,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNTS_LOAD_ERROR: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        investmentAmounts: {
                            ...state.investmentPreferences.form.investmentAmounts,
                            isLoading: false,
                            isError: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_LOADING: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        companyInvestmentAmounts: {
                            ...state.investmentPreferences.form.companyInvestmentAmounts,
                            isLoading: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_DATA: {
            const { data } = action;
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        companyInvestmentAmounts: {
                            data,
                            isLoading: false,
                            isError: false,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENTS_LOAD_ERROR: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        companyInvestmentAmounts: {
                            ...state.investmentPreferences.form.companyInvestmentAmounts,
                            isLoading: false,
                            isError: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_LOADING: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    investments: {
                        ...state.investmentPreferences.investments,
                        isLoading: true,
                    },
                },
            };
        }

        case EstablishmentActions.SEND_ESTABLISHMENT_PREFERENCES_INVESTMENT_FORM: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        startupForm: {
                            ...state.investmentPreferences.form.startupForm,
                            sending: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.RESET_ESTABLISHMENT_PREFERENCES_INVESTMENT_FORM: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        startupForm: initialState.investmentPreferences.form.startupForm,
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENT_DATA: {
            const { id } = action;
            const investment = parseEstablishmentInvestmentToForm(id, state.investmentPreferences.investments.data);
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        startupForm: {
                            ...state.investmentPreferences.form.startupForm,
                            ...investment,
                            startup: {
                                ...state.investmentPreferences.form.startupForm.startup,
                                ...investment.startup,
                            },
                            investmentAmount: {
                                ...state.investmentPreferences.form.startupForm.investmentAmount,
                                ...investment.investmentAmount,
                            },
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_DATA: {
            const { data } = action;
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    investments: {
                        ...state.investmentPreferences.investments,
                        data: parseEstablishmentInvestmentData(data),
                        isLoading: false,
                        isError: false,
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_PREFERENCES_INVESTMENTS_LOAD_ERROR: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    investments: {
                        ...state.investmentPreferences.investments,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        }
        case EstablishmentActions.GET_ESTABLISHMENT_STARTUP_OPTIONS: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        startupsData: {
                            ...state.investmentPreferences.form.startupsData,
                            isLoading: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_START_OPTIONS: {
            const { data } = action;
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        startupsData: {
                            data: data,
                            isLoading: false,
                            isError: false,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_STARTUP_OPTIONS_LOAD_ERROR: {
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        startupsData: {
                            ...state.investmentPreferences.form.startupsData,
                            isLoading: false,
                            isError: true,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_STARTUP: {
            const { startup, startupVisible } = action;
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        startupForm: {
                            ...state.investmentPreferences.form.startupForm,
                            startup: {
                                ...state.investmentPreferences.form.startupForm.startup,
                                ...startup,
                            },
                            startupVisible: startupVisible,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_STARTUP_INVESTMENT_DATE: {
            const { investmentDate, investmentDateVisible, investmentDateIsValid } = action;
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        startupForm: {
                            ...state.investmentPreferences.form.startupForm,
                            investmentDate: investmentDate,
                            investmentDateIsValid: investmentDateIsValid,
                            investmentDateVisible: investmentDateVisible,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_STARTUP_INVESTMENT_AMOUNT: {
            const { investmentAmount, investmentAmountVisible } = action;
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        startupForm: {
                            ...state.investmentPreferences.form.startupForm,
                            investmentAmount: investmentAmount,
                            investmentAmountVisible: investmentAmountVisible,
                        },
                    },
                },
            };
        }
        case EstablishmentActions.SET_ESTABLISHMENT_INVESTMENT_VISIBILITY: {
            const { investmentVisible } = action;
            return {
                ...state,
                investmentPreferences: {
                    ...state.investmentPreferences,
                    form: {
                        ...state.investmentPreferences.form,
                        startupForm: {
                            ...state.investmentPreferences.form.startupForm,
                            investmentVisible: investmentVisible,
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};

export const parseEstablishmentInvestmentToForm = (id, data) => {
    const inv = data?.investments?.find((el) => el.id === id);
    const unregistered = !inv?.startupId && inv?.companyTitle ? true : false;
    const startupIsValid = unregistered || (inv?.startupId && inv?.companyTitle);
    const amountIsValid = inv?.investment.id && inv?.investment.value ? true : false;

    return {
        id: id,
        startup: {
            id: inv?.investorStartupId,
            startupId: unregistered ? -1 : inv?.startupId,
            startupName: !unregistered ? inv?.companyTitle : null,
            freeTextStartupName: unregistered ? inv?.companyTitle : null,
            isValid: startupIsValid,
            unregistered: unregistered,
            logo: inv.logo,
        },
        startupVisible: inv?.companyIsPublic,
        investmentDate: format(parse(inv.date, NUMERIC_FULL_DATE, new Date()), ISO_FORMAT),
        investmentDateIsValid: inv?.date ? true : false,
        investmentDateVisible: inv?.dateIsPublic,
        investmentAmount: {
            id: inv?.investment.id,
            value: inv?.investment.value,
            currencyId: 1,
            isValid: amountIsValid,
        },
        investmentAmountVisible: inv?.investmentIsPublic,
        investmentVisible: !inv?.hideAll,
    };
};
export default establishment;
