import React from 'react';
import { Avatar, Grid, Typography, useMediaQuery } from '@material-ui/core';
import useStyles from './index.style';
import PlaceholdersIdea from 'fintech/static/images/placeholders-idea3x.png';
import PlaceholdersStartup from 'fintech/static/images/placeholders-startup3x.png';
import PlaceholdersInvestor from 'fintech/static/images/placeholders-yatirimda-bulunan.png';
import { COLOR_SECONDARY_BASE, fontStyle, desktopLargeQuery, mobileMicroQuery } from 'fintech/components/style/common';
import PopperWrapper from 'fintech/components/util/PopperWrapper';
import { VENTURE_TYPES } from 'fintech/store/actions/ActionTypes';

const VentureLogoGrid = (props) => {
    const classes = useStyles();
    const isLarge = useMediaQuery(desktopLargeQuery);
    const isMicroMobile = !useMediaQuery(mobileMicroQuery);
    const { logo, name, type, ventureType } = props;

    const isVenture = !(ventureType === "INVESTOR");

    return (
        <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item className={classes.LogoGrid} xs={6}>
                <Avatar
                    className={classes.logoMedia}
                    alt={logo?.title}
                    src={logo?.url}
                >
                    <Avatar
                        className={isVenture ? classes.ventureLogoMedia : classes.logoMedia}
                        alt='placeholder-venture'
                        src={!isVenture ? PlaceholdersInvestor :
                            ([VENTURE_TYPES.IDEA, VENTURE_TYPES.BACKED_IDEA].includes(ventureType)
                                ? PlaceholdersIdea
                                : PlaceholdersStartup)}
                    />
                </Avatar>
            </Grid>
            <Grid item xs={6}>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.NameGrid}
                >
                    {name && (
                        <Grid item>
                            <PopperWrapper showPopper={isLarge || isMicroMobile} content={name}>
                                <div style={isLarge || isMicroMobile ? { maxWidth: '150px' } : null}>
                                    <Typography style={{ ...fontStyle, fontWeight: '600' }}>
                                        {name && name}
                                    </Typography>
                                </div>
                            </PopperWrapper>
                        </Grid>
                    )}
                    {type && (
                        <Typography style={{ ...fontStyle, color: COLOR_SECONDARY_BASE }}>
                            {type}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VentureLogoGrid;
