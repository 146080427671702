import React, { useState, useEffect, useRef } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import { Modal, FormControl, Grid } from '@material-ui/core';
import useStyles from './index.style';
import { CloseButton, SaveButton } from 'fintech/components/ui/modal/Buttons/Buttons';
import TimeSlot from './TimeSlot/TimeSlot';
import { getConsultantDailyCalendar, updateConsultantWorkingHours } from 'fintech/api/fintechService';
import { useTranslation } from 'react-i18next';
import * as MeetingStatus from 'fintech/components/profile/user/consultant/calendar/MeetingStatus/MeetingStatus';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveRequest } from 'fintech/store/actions/common';
import EscKeyListener from 'fintech/components/util/EscKeyListener';
import SingleSelect from 'fintech/components/ui/form/select/SingleSelect';

function ModalOrganizeWorkingHours({ open, onClose, meetingDates }) {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { hasActiveRequest } = useSelector((state) => state.common);
    const dispatch = useDispatch();

    const [selectedDate, setSelectedDate] = useState(meetingDates.length > 0 ? meetingDates[0].id : '');
    const [timeSlots, setTimeSlots] = useState([]);

    const unavailableSlots = useRef([]);
    const dayId = useRef(0);

    const handleClickVariant = (variant, text) => {
        enqueueSnackbar(text, { variant });
    };
    const toggleAvailability = (slot, index) => {
        if (
            !slot ||
            slot.status.type === MeetingStatus.PRE_RESERVATION ||
            slot.status.type === MeetingStatus.RESERVATION
        )
            return;

        const newTimeSlots = timeSlots.slice();

        newTimeSlots[index] = {
            ...newTimeSlots[index],
            status: {
                ...newTimeSlots[index].status,
                type:
                    newTimeSlots[index].status.type === MeetingStatus.AVAILABLE
                        ? MeetingStatus.NOT_AVAILABLE
                        : MeetingStatus.AVAILABLE,
            },
        };

        unavailableSlots.current = newTimeSlots.filter((slot) => slot.status.type === MeetingStatus.NOT_AVAILABLE);
        setTimeSlots(newTimeSlots);
    };
    const saveChanges = () => {
        dispatch(setActiveRequest(true));
        updateConsultantWorkingHours(keycloak, unavailableSlots.current, dayId.current)
            .then((data) => {
                dispatch(setActiveRequest(false));
                if (data && data.success) {
                    handleClickVariant(
                        'success',
                        t('profile.user.consultant.meetingCalendar.organizeWorkingHours.success')
                    );
                    onClose();
                    return;
                }
                handleClickVariant('error', t('profile.user.consultant.meetingCalendar.organizeWorkingHours.warning'));
            })
            .catch(() => {
                dispatch(setActiveRequest(false));
                handleClickVariant('error', t('profile.user.consultant.meetingCalendar.organizeWorkingHours.warning'));
            });
    };

    useEffect(() => {
        getConsultantDailyCalendar(keycloak, { dayId: selectedDate })
            .then((response) => {
                if (
                    response &&
                    response.success &&
                    response.data &&
                    response.data.timeSlots &&
                    response.data.timeSlots.length > 0
                ) {
                    const { data } = response;

                    // to reset previously saved slots after selected date changed.
                    unavailableSlots.current = [];
                    dayId.current = data.id;

                    setTimeSlots(data.timeSlots);
                }
            })
            .catch(() => {
                handleClickVariant(
                    'error',
                    t('profile.user.consultant.meetingCalendar.organizeWorkingHours.fetchError')
                );
            });
    }, [selectedDate]);

    const handleSetSelectedDate = (value) => {
        setSelectedDate(value.id);
    };

    const selectedDateObject = meetingDates.find((m) => m.id === selectedDate);

    return (
        <>
            {open && <EscKeyListener onEsc={onClose} />}
            <Modal open={open} className={classes.modal} onClose={onClose}>
                <div className={classes.modalContainer} tabIndex="">
                    <div className={classes.modalHeading}>
                        {t('profile.user.consultant.meetingCalendar.organizeWorkingHours.title')}
                    </div>
                    <div className={classes.modalDescription}>
                        {t('profile.user.consultant.meetingCalendar.organizeWorkingHours.description')}
                    </div>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <SingleSelect
                            label={t('profile.user.consultant.meetingCalendar.date')}
                            dontValidate={true}
                            canBeEmpty={false}
                            options={meetingDates}
                            initValue={selectedDateObject}
                            onChange={handleSetSelectedDate}
                        />
                    </FormControl>
                    <Grid container className={classes.timeSlots}>
                        {timeSlots.map((slot, index) => (
                            <Grid
                                item
                                key={index}
                                className={classes.slot}
                                onClick={() => toggleAvailability(slot, index)}
                            >
                                <TimeSlot slot={slot} index={index} />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid item>
                            <CloseButton onClick={onClose}>
                                {t('profile.user.consultant.meetingCalendar.meetingDetail.close')}
                            </CloseButton>
                        </Grid>
                        <Grid item>
                            <SaveButton
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                                disabled={hasActiveRequest}
                            >
                                {t('profile.user.consultant.meetingCalendar.meetingDetail.saveChanges')}
                            </SaveButton>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </>
    );
}

export default React.memo(ModalOrganizeWorkingHours);
