import React, { useEffect, useState } from 'react';
import useStyles from './index.style';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconsChevronLeft from 'fintech/icons/IconsChevronLeft';
import IconsChevronRight from 'fintech/icons/IconsChevronRight';
import { APIS } from 'fintech/constants';
import { getRequest } from 'fintech/api/api';
import MentorCard from 'fintech/components/search/mentor/MentorCard';
import { Grid } from '@material-ui/core';
import { getLogo, parseSubVerticals } from 'fintech/utils/DataParser';
import { useTranslation } from 'react-i18next';
import { formatNumericValue } from 'fintech/utils/StringUtils';
import { useKeycloak } from '@react-keycloak/web';
import { getLoggedInUserUuid } from 'fintech/utils/Auth';
import { ANONYMOUS } from 'fintech/constants';
import { MENTORS_PROFILE } from 'fintech/routes';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';
import { getItemShow } from 'fintech/components/util/GetItemShow';

const MentorSearch = () => {
    const classes = useStyles();
    const [mentorsCount, setMentorsCount] = useState(null);
    const { t } = useTranslation('preLogin');
    const allTranslation = useTranslation();
    const [loading, setLoading] = useState(false);
    const [mentors, setMentors] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const { keycloak } = useKeycloak();
    const userUuid = getLoggedInUserUuid(keycloak);
    const isAnonymous = userUuid === ANONYMOUS;
    const [itemShow, setItemShow] = useState(getItemShow(window.innerWidth));

    const updateDimensions = () => {
        setItemShow(getItemShow(window.innerWidth));
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        getRequest(APIS.MENTOR_PRE_LOGIN.getActiveMentorsCountURL(), null).then((payload) => {
            setMentorsCount(payload.data);
        });
        setLoading(true);
        getRequest(APIS.MENTOR_PRE_LOGIN.getMentors(searchTerm, itemShow, activePage), null)
            .then((payload) => {
                setMentors(payload.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [itemShow]);

    useEffect(() => {
        if (activePage !== 0) {
            setLoading(true);
            getRequest(APIS.MENTOR_PRE_LOGIN.getMentors(searchTerm, itemShow, activePage), null)
                .then((payload) => {
                    setMentors(payload.data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [activePage]);

    useEffect(() => {
        if (searchTerm) {
            setLoading(true);
            getRequest(APIS.MENTOR_PRE_LOGIN.getMentors(searchTerm, itemShow, 0), null)
                .then((payload) => {
                    setMentors(payload.data);
                    setActivePage(0);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [searchTerm]);

    const parseMentor = (mentor) => {
        return {
            uuid: mentor.uuid,
            name: `${mentor.name} ${mentor.surname}`,
            logo: getLogo(mentor.profilePictureFile),
            verticals: parseSubVerticals(mentor.userFintechSubVerticalSet, true),
        };
    };

    const mentorsWrapperInlineStyle = {};
    if ((mentorsCount && mentorsCount > 0) || loading) {
        mentorsWrapperInlineStyle.minHeight = 332;
    }

    return (
        <div className={classes.mentorSearchWrapper}>
            <div className={classes.titleWrapper}>
                <div className={classes.divider}></div>
                <div
                    className={classes.mentorDefinition}
                    dangerouslySetInnerHTML={{
                        __html: t('registeredMentor', {
                            mentorsCount: mentorsCount ? formatNumericValue(mentorsCount) : '',
                        }),
                    }}
                ></div>
            </div>
            <TextField
                type="search"
                variant="outlined"
                className={classes.searchBar}
                placeholder={t('searchMentor')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                onChange={(event) => setSearchTerm(event.target.value === '' ? '%00' : event.target.value)}
            />
            <div className={classes.mentorsWrapper} style={mentorsWrapperInlineStyle}>
                {loading ? (
                    <LoadingSpinnerWithText loadingMessage=" " />
                ) : (
                    mentors.content &&
                    mentors.content.map((mentor) => {
                        return (
                            <Grid
                                key={mentor.uuid}
                                item
                                className={classes.itemWrapper}
                                style={{ width: '-moz-available' }}
                            >
                                <MentorCard
                                    isAnonymous={isAnonymous}
                                    title={allTranslation.t('profile.user.verticals')}
                                    personUrl={MENTORS_PROFILE}
                                    {...parseMentor(mentor)}
                                />
                            </Grid>
                        );
                    })
                )}
            </div>
            <div className={classes.iconsWrapper}>
                <div
                    className={`${mentors.first || loading ? classes.disable : ''}`}
                    onClick={() => setActivePage((prevState) => --prevState)}
                >
                    <IconsChevronLeft />
                </div>
                <div
                    className={`${mentors.last || loading ? classes.disable : ''}`}
                    onClick={() => setActivePage((prevState) => ++prevState)}
                >
                    <IconsChevronRight />
                </div>
            </div>
        </div>
    );
};

export default MentorSearch;
