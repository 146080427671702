const initialState = {
    loggedIn: false,
    user: null,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default auth;
