import { makeStyles } from '@material-ui/core';
import { COLOR_THEME_FILL_01, COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';
import pattern from 'fintech/static/images/pattern-white-reserved.svg';

const useStyles = makeStyles((theme) => ({
    testimonialSection: {
        padding: '92px 120px 92px 120px',
        background: `${COLOR_PRIMARY_SHADE_02} url(${pattern}) repeat-x top center`,
        [theme.breakpoints.down('md')]: {
            padding: '92px 22px 92px 22px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '48px 22px 43px 22px',
        },
    },
    testimonialWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',

        '& > div:not(:first-child)': {
            width: '100%',
        },
    },
}));

export default useStyles;
