import { makeStyles } from '@material-ui/core';

import { COLOR_THEME_FILL_01 } from 'fintech/components/style/common';

export const makeChatStyles = makeStyles(() => ({
    grid: {
        backgroundColor: COLOR_THEME_FILL_01,
        borderRadius: '8px',
        height: '600px',
        position: 'relative',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
    },
    loading: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
