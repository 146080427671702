import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ActionModal from 'fintech/components/util/ActionModal';
import { REJECT, SUBMIT } from 'fintech/components/util/ActionModalConstants';
import * as FintechBeService from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import { useMediaQuery } from '@material-ui/core';
import GeneralInfoActionModal from 'fintech/components/profile/startup/GeneralInfo/ActionModal/content/GeneralInfoActionModal';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { laptopLarge } from 'fintech/components/style/common';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveRequest } from 'fintech/store/actions/common';

const emptyGeneralInfo = {
    id: null,
    field: 'generalInfo',
    value: '',
};

export default function UpdateStartupGeneralInfoActionModal({ initData, title, open, setOpen, updateCallback }) {
    const generalInfo = initData.startupInfos?.generalInfo
        ? _.clone(initData.startupInfos?.generalInfo, true)
        : emptyGeneralInfo;
    const [isOpen, setIsOpen] = useState(open);
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { hasActiveRequest } = useSelector((state) => state.common);

    const matches = useMediaQuery(laptopLarge);
    const modalSize = matches ? { minWidth: '700px', minHeight: '690px' } : {};

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const actionEventTrigger = (actionData) => {
        if (actionData.type == SUBMIT) {
            if (generalInfo.validated) {
                updateData();
            } else {
                enqueueSnackbar(t('profile.generalInfo.editModalValidationError'), { variant: 'error' });
            }
        } else if (actionData.type == REJECT) {
            rejectData();
        }
    };

    const rejectData = () => {
        setIsOpen(false);
    };

    const updateData = () => {
        dispatch(setActiveRequest(true));
        const requestData = {
            id: generalInfo.id,
            field: generalInfo.field,
            value: generalInfo.value,
        };

        FintechBeService.updateStartupGeneralInfo(keycloak, requestData, initData.uuid)
            .then((value) => {
                dispatch(setActiveRequest(false));
                setIsOpen(false);
                updateCallback(true);
                enqueueSnackbar(value.message, { variant: 'success' });
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason.message, { variant: 'error' });
            });
    };

    return (
        <div>
            <ActionModal
                open={isOpen}
                title={title}
                onAction={actionEventTrigger}
                modalSize={modalSize}
                okClickDisabled={hasActiveRequest}
                cancelClickDisabled={hasActiveRequest}
                showSpinnerWhenDisabled={hasActiveRequest}
            >
                <GeneralInfoActionModal initData={generalInfo} />
            </ActionModal>
        </div>
    );
}
