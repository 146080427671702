import { ListItemIcon, makeStyles, Typography, withStyles } from '@material-ui/core';
import {
    buttonStyle,
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_SHADE_01,
    COLOR_THEME_FILL_01,
    fontStyle,
} from 'fintech/components/style/common';

const useStyles = makeStyles({
    SplitButtonGroup: {
        boxShadow: 'none',
        height: '24px',
        marginRight: '8px',
    },
    SplitButtonTag: {
        ...buttonStyle,
        color: '#3f5162',
        backgroundColor: ({ type }) => {
            return type === 'default' ? COLOR_PRIMARY_SHADE_02 : COLOR_THEME_FILL_01;
        },
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        '&:hover': {
            color: '#3f5162',
            backgroundColor: ({ type }) => {
                return type === 'default' ? COLOR_PRIMARY_SHADE_02 : COLOR_THEME_FILL_01;
            },
            boxShadow: 'none',
            cursor: 'default',
        },
    },
    SubVerticalsButton: {
        ...buttonStyle,
        backgroundColor: COLOR_PRIMARY_BASE,
        borderRadius: '0 8px 8px 0',
        width: '24px',
        minWidth: '24px',
        maxWidth: '24px',
        padding: 0,
    },
    MoreVertIcon: {
        color: '#ffffff',
    },
});

export const StyledSplitBtnTypo = withStyles({
    root: {
        ...fontStyle,
        fontSize: '12px',
    },
})(Typography);

export const StyledListItemIcon = withStyles({
    root: {
        minWidth: '24px',
        width: '24px',
        color: COLOR_PRIMARY_BASE,
    },
})(ListItemIcon);

export const StyledListHeaderTypo = withStyles({
    root: {
        ...fontStyle,
        fontSize: '12px',
        textAlign: 'left',
        color: COLOR_SECONDARY_SHADE_01,
    },
})(Typography);

export const StyledListItemTypo = withStyles({
    root: {
        ...fontStyle,
        fontSize: '12px',
        textAlign: 'left',
    },
})(Typography);

export default useStyles;
