import React, { useEffect } from 'react';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import { Typography } from '@material-ui/core';
import { COLOR_MUI_ERROR } from 'fintech/components/style/common';

export default function RichTextArea({
    count,
    initText,
    validationText,
    maxCharactersText,
    label,
    placeholder = '',
    onChange,
    ignoreBorder,
    required,
    dontValidate,
    validateOnBlur,
    validatedAfterFormSubmit = true,
    initiallyValidated,
    editorStyle = {},
    toolbarStyle = {},
}) {
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [validated, setValidated] = React.useState(true);
    const [remainingCount, setRemainingCount] = React.useState(0);

    useEffect(() => {
        const blocksFromHTML = convertFromHTML(initText);
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        if (!initiallyValidated && !validateOnBlur && state.blocks && state.blocks.length > 0) {
            const length = getUsedLength(state);
            setValidated(validation(length));
        }
        setEditorState(EditorState.createWithContent(state));
    }, []);

    useEffect(() => {
        if (dontValidate && validatedAfterFormSubmit) setValidated(true);
    }, [dontValidate]);

    useEffect(() => {
        if (!validatedAfterFormSubmit) {
            validateFromEditorState();
        }
    }, [validatedAfterFormSubmit]);

    useEffect(() => {
        if (count && count > 0) {
            const content = convertToRaw(editorState.getCurrentContent());
            let usedLength = 0;
            if (content.blocks && content.blocks.length > 0) {
                usedLength = getUsedLength(content);
                setRemainingCount(count - usedLength);
            }
            onChange(draftToHtml(content), validation(usedLength));
        } else {
            onChange('', validation(0));
        }
    }, [editorState]);

    const getUsedLength = (content) => {
        let usedLength = 0;
        content.blocks.forEach(function (item) {
            usedLength += item.text.length;
        });

        if (content.blocks.length > 1) {
            usedLength += content.blocks.length - 1;
        }

        return usedLength;
    };

    const validateFromEditorState = () => {
        const content = convertToRaw(editorState.getCurrentContent());
        let usedLength = 0;
        if (content.blocks && content.blocks.length > 0) {
            usedLength = getUsedLength(content);
            setValidated(validation(usedLength));
        } else {
            setValidated(validation(0));
        }
    };

    const validation = (usedLength) => {
        if (dontValidate) {
            return true;
        }

        let result = false;
        if (count && count > 0) {
            result = usedLength <= count;
            if (required) {
                result = usedLength > 0;
            }
        }

        return !required && usedLength == 0 ? true : result;
    };

    let fieldsetStyle = {};
    let legendStyle = {};
    let toolbarStyleOverride = { ...toolbarStyle };
    let editorStyleOverride = { ...editorStyle };

    if (!validated) {
        editorStyleOverride.border = `1px solid ${COLOR_MUI_ERROR}`;
        toolbarStyleOverride.borderColor = COLOR_MUI_ERROR;
    }

    if (ignoreBorder) {
        fieldsetStyle = { border: 'none' };
    } else if (!validated) {
        fieldsetStyle = { borderColor: COLOR_MUI_ERROR };
        legendStyle = { borderTop: `1px solid ${COLOR_MUI_ERROR}` };
    }

    return (
        <div className={'richTextAreaContainer'}>
            <fieldset style={fieldsetStyle}>
                <legend className={getSystemLanguage() === 'tr' ? 'tr-width' : 'eng-width'} style={legendStyle}>
                    {label}
                </legend>
                <Editor
                    placeholder={placeholder}
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    editorStyle={editorStyleOverride}
                    onEditorStateChange={(editorState) => setEditorState(editorState)}
                    toolbar={{
                        options: ['inline', 'list'],
                        inline: {
                            inDropdown: false,
                            visible: true,
                            options: ['bold', 'italic', 'underline'],
                        },
                        list: {
                            inDropdown: true,
                            options: ['unordered', 'ordered'],
                        },
                    }}
                    toolbarStyle={toolbarStyleOverride}
                    onBlur={
                        validateOnBlur
                            ? () => {
                                  validateFromEditorState();
                              }
                            : () => {}
                    }
                />
                {count && count > 0 && (
                    <>
                        <div className={'d-flex align-items-start justify-content-start margin-top-3'}>
                            {!validated && (
                                <Typography
                                    style={
                                        !validated
                                            ? { color: COLOR_MUI_ERROR, fontSize: '12px', marginLeft: '14px' }
                                            : {}
                                    }
                                >
                                    {count && remainingCount < 0 ? maxCharactersText : validationText}
                                </Typography>
                            )}
                        </div>
                        <div className={'d-flex align-items-end justify-content-end margin-top-8'}>
                            <div className={'countContainer'}>{remainingCount}</div>
                        </div>
                    </>
                )}
            </fieldset>
        </div>
    );
}
