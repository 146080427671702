import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        display: 'flex',
        background: 'white',
        borderTop: '1px solid #d7e2e8',
        padding: '20px 45px 37px 35px',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            borderTop: 0,
        },
    },
    leftWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,

        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    rightWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,

        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            marginTop: 53,
        },
    },
    listStyle: {
        listStyleType: 'none',
        width: '100%',
        display: 'flex',
        padding: 0,
        marginBottom: 20,
        gap: 25,

        '& span': {
            fontSize: 12,
            cursor: 'pointer',
            fontWeight: 500,
            textDecoration: 'none',
            minWidth: 'fit-content',
            '&:hover': {
                textDecoration: 'underline',
            },
        },

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    cbfoLogo: {
        width: 137,
        height: 50,
        marginBottom: 20,

        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
    address: {
        fontSize: 12,
        marginTop: 'auto',
        fontWeight: 500,

        [theme.breakpoints.down('sm')]: {
            maxWidth: 265,
            textAlign: 'center',
        },
    },
    iconsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',

        '& img': {
            marginLeft: 18,
            cursor: 'pointer',

            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginRight: 20,

                '&:last-child': {
                    marginRight: 0,
                },
            },
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 14,
        },
    },
    policyContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridRowGap: '5px',
        gridColumnGap: '9px',
        marginLeft: 'auto',
        marginTop: 60,
        textAlign: 'right',

        '& span': {
            fontSize: 12,
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 500,
        },

        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: 0,

            '& span:last-child': {
                textAlign: 'left',
            },
        },
    },
    copyRight: {
        fontSize: 12,
        marginLeft: 'auto',
        marginTop: '5px',
        fontWeight: 500,

        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
}));

export default useStyles;
