import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import { useStyles } from 'fintech/components/dashboard/eventdetail/eventdetailheader/EventDetailHeader.style';
import { fromEpoch, NUMERIC_FULL_DATE_W_HOUR } from 'fintech/utils/DateUtils';

const EventDetailHeader = (props) => {
    const { t, eventName, eventDate, eventEndDate } = props;

    const classes = useStyles();

    return (
        <>
            <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="flex-start"
                className={classes.EventDetailHeaderGrid}
            >
                <Grid item xs={12}>
                    <Typography className={classes.EventNameStyle}>{eventName}</Typography>
                    <Typography className={classes.EventDateStyle}>
                        {`${fromEpoch(eventDate, NUMERIC_FULL_DATE_W_HOUR)} - ${fromEpoch(
                            eventEndDate,
                            NUMERIC_FULL_DATE_W_HOUR
                        )}`}
                    </Typography>
                </Grid>
            </Grid>
            <div style={{ padding: '4px 16px 0px 16px' }}>
                <p
                    className={classes.EventApplicationInfo}
                    dangerouslySetInnerHTML={{ __html: t('eventDetail.eventApplicationInfo') }}
                />
            </div>
        </>
    );
};

export default withTranslation('event')(EventDetailHeader);
