import * as React from 'react';

function SvgIconsSingleMultipleGirisimci(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <path
                            fill="#D9DCE0"
                            d="M9.457 12.338h4.373s.699 1.63-.417 2.867l-4.482 4.48s-.922-2.889.37-5.111l.156-2.236zM8.973 8.975L7.66 6.167s-1.629-.699-2.866.417L.316 11.063s2.891.922 5.11-.366l3.547-1.722z"
                            transform="translate(1 3)"
                        />
                        <path
                            fill="#D9DCE0"
                            d="M19.68.316c-.004 1.764-.307 3.452-.867 5.026-.753 2.117-1.965 4.012-3.523 5.57-1.742 1.742-3.91 3.056-6.338 3.771l-3.636-3.637c1.355-4.597 4.853-8.268 9.339-9.863 1.57-.56 3.262-.867 5.025-.867zm-7.42 4.9c-1.394 0-2.525 1.13-2.525 2.526 0 1.394 1.13 2.525 2.525 2.525s2.525-1.13 2.525-2.525-1.13-2.526-2.525-2.526z"
                            transform="translate(1 3)"
                        />
                        <path
                            fill="#D9DCE0"
                            d="M18.813 5.342c-1.006-.164-1.97-.64-2.744-1.414-.774-.775-1.25-1.739-1.414-2.745 1.57-.56 3.262-.867 5.025-.867-.004 1.768-.307 3.456-.867 5.026zM4.478 17.264c1.166-1.166-.58-2.91-1.746-1.747-1.158-1.158 2.31-2.311 3.468-3.469l1.746 1.747c-1.153 1.158-2.31 4.626-3.468 3.469z"
                            transform="translate(1 3)"
                        />
                        <path
                            fill="#3F5162"
                            d="M19.903.093C19.844.033 19.764 0 19.68 0c-1.763 0-3.489.299-5.13.884-.998.354-1.966.817-2.87 1.372-.148.093-.194.287-.102.434.089.147.287.194.434.105 0 0 .004 0 .004-.004.762-.467 1.574-.859 2.403-1.183.228.96.716 1.831 1.427 2.543.707.707 1.578 1.195 2.538 1.423-.728 1.902-1.865 3.662-3.317 5.114-1.679 1.68-3.762 2.939-6.022 3.637l-.812-.812-2.56-2.56c.889-2.866 2.61-5.363 4.984-7.219.067-.05.11-.126.122-.21.008-.085-.013-.165-.063-.232-.106-.135-.312-.16-.446-.055-.981.766-1.852 1.638-2.606 2.593-.425-.147-1.898-.547-3.089.53L.093 10.84c-.08.08-.11.19-.084.299.025.105.105.193.21.227.072.021 1.086.337 2.374.337.774 0 1.646-.118 2.475-.476.008.013.012.03.02.038l.712.711c-.366.295-.875.602-1.372.901-1.073.644-2.088 1.25-2.176 1.991-.038.295.072.569.32.817.122.122.324.122.446 0 .45-.45.976-.324 1.3 0 .325.324.45.85 0 1.3-.122.123-.122.325 0 .447.215.215.455.324.703.324.038 0 .072-.004.11-.008.74-.093 1.347-1.103 1.99-2.177.3-.496.607-1.006.901-1.372l.711.712c.013.012.026.016.038.025-.955 2.21-.177 4.727-.139 4.845.034.105.118.185.228.21.025.005.05.009.075.009.085 0 .165-.034.223-.093l4.491-4.491c1.073-1.187.665-2.66.518-3.086.471-.37.921-.77 1.35-1.196 1.604-1.603 2.846-3.57 3.6-5.687.58-1.637.879-3.363.883-5.131-.004-.084-.038-.164-.097-.223zM.935 10.895l4.07-4.071c.8-.724 1.788-.577 2.26-.45C6.364 7.618 5.66 9 5.173 10.481c-1.511.817-3.354.594-4.238.413zm5.644 4.348c-.489.813-1.095 1.823-1.528 1.878h-.042c.416-.661.223-1.415-.249-1.886-.471-.472-1.224-.665-1.885-.249v-.042c.055-.433 1.065-1.04 1.877-1.528.534-.32 1.086-.652 1.494-.99l1.322 1.323c-.333.408-.665.96-.99 1.494zm6.607-.26l-4.082 4.082c-.181-.884-.409-2.723.42-4.239 1.461-.484 2.846-1.195 4.1-2.096.13.467.278 1.456-.438 2.252zm5.408-10.007c-.87-.194-1.662-.632-2.302-1.272-.644-.644-1.077-1.435-1.27-2.306C16.417.93 17.874.669 19.36.636c-.038 1.494-.299 2.946-.766 4.34z"
                            transform="translate(1 3)"
                        />
                        <path
                            fill="#3F5162"
                            d="M11.14 3.3c.068 0 .127-.016.182-.054.143-.097.176-.295.08-.438-.097-.14-.303-.177-.442-.08-.068.046-.114.118-.13.202-.013.084.004.168.054.236.059.084.156.134.257.134zM9.415 7.742c0 .757.295 1.473.833 2.008.535.534 1.25.833 2.008.833s1.473-.295 2.008-.833c1.106-1.108 1.106-2.91 0-4.02-.535-.535-1.25-.834-2.008-.834s-1.473.294-2.008.833c-.534.54-.833 1.25-.833 2.013zm2.841-2.21c.59 0 1.145.231 1.561.648.863.863.863 2.265 0 3.123-.416.417-.972.649-1.561.649-.59 0-1.145-.232-1.561-.649-.417-.416-.649-.972-.649-1.561 0-.59.232-1.145.649-1.562.416-.417.972-.649 1.561-.649z"
                            transform="translate(1 3)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleMultipleGirisimci;
