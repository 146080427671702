import React from 'react';
import { withTranslation } from 'react-i18next';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useSelector } from 'react-redux';
import MentorAvatar from 'fintech/components/profile/startup/GeneralInfo/startupmentors/mentoravatar/MentorAvatar';
import useStyles from 'fintech/components/profile/startup/GeneralInfo/startupmentors/mentorcarousel/MentorCarousel.style';
import { Grid } from '@material-ui/core';
import MentorCarouselHeader from 'fintech/components/profile/startup/GeneralInfo/startupmentors/mentorheader/MentorCarouselHeader';
import { COLOR_PRIMARY_BASE } from 'fintech/components/style/common';

/**
 * Startup Mentors which uses the carousel for showing the startup mentor cards
 */
const MentorCarousel = () => {
    const { mentors: fetchedMentors } = useSelector((state) => state.startup.intro?.data);
    const classes = useStyles();

    let MentorsSlides = [];

    const startupMentorsJSX = fetchedMentors?.map((mentor) => <MentorAvatar item key={mentor.id} {...mentor} />);

    MentorsSlides = startupMentorsJSX.map((mentorJSX, index) => {
        return (
            <Slide key={index} index={index}>
                {mentorJSX}
            </Slide>
        );
    });

    const getCarousel = () => {
        return (
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={124}
                totalSlides={MentorsSlides.length}
                visibleSlides={1}
                isIntrinsicHeight
                style={{ height: 248 }}
            >
                <MentorCarouselHeader />
                <Slider className={classes.MentorSliderContainer}>{MentorsSlides}</Slider>
                <>
                    <ButtonBack className={classes.SliderButtonBack}>
                        <i style={{ color: COLOR_PRIMARY_BASE, fontSize: 24 }} className="fas fa-chevron-left" />
                    </ButtonBack>
                    <ButtonNext className={classes.SliderButtonNext}>
                        <i style={{ color: COLOR_PRIMARY_BASE, fontSize: 24 }} className="fas fa-chevron-right" />
                    </ButtonNext>
                </>
            </CarouselProvider>
        );
    };

    return <Grid className={classes.MentorCarouselGrid}>{getCarousel()}</Grid>;
};

export default withTranslation('profile')(MentorCarousel);
