import { makeStyles } from '@material-ui/core';
import { poppins_16px } from 'fintech/components/style/common';
import { COLOR_SECONDARY_BASE } from 'fintech/components/style/common';
const useStyles = makeStyles(() => ({
    progressBarsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        '& > div': {
            marginBottom: '14px',
        },
        '& > p': {
            margin: '0 0 20px 0',
            ...poppins_16px,
            color: COLOR_SECONDARY_BASE,
        },
        '& > div:last-child': {
            marginBottom: '0px',
        },
    },
}));

export default useStyles;
