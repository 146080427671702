import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_BASE,
    COLOR_SECONDARY_SHADE_01,
    fontStyle,
} from 'fintech/components/style/common';

const useStyles = makeStyles({
    ...fontStyle,
    AtcHeader: {
        marginLeft: '0',
        marginTop: '15px',
        marginBottom: '2px',
        textAlign: 'start',
        color: COLOR_SECONDARY_SHADE_01,
    },
    AtcIcon: {
        marginLeft: '0',
        marginRight: '16px',
        marginTop: '15px',
        marginBottom: '2px',
        textAlign: 'start',
        color: COLOR_SECONDARY_SHADE_01,
    },
    AtcNumber: {
        ...fontStyle,
        textAlign: 'center',
        minWidth: '14px',
        paddingTop: '6px',
        marginRight: '12px',
    },
    AtcButton: {
        maxWidth: 'calc(100vw * 0.75)',
        justifyContent: 'left',
        color: COLOR_SECONDARY_BASE,
        '&:hover': {
            textDecoration: 'underline',
            color: COLOR_PRIMARY_BASE,
            '& g': {
                fill: COLOR_PRIMARY_BASE,
            },
        },
    },
    horizontalRule: {
        width: '100%',
        height: '1px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
    },
});

export default useStyles;
