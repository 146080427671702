import { makeStyles } from '@material-ui/core';
import {
    poppins600_10px,
    COLOR_PRIMARY_SHADE_02,
    poppins600_12px,
    COLOR_SECONDARY_SHADE_01,
    COLOR_PRIMARY_SHADE_03,
} from 'fintech/components/style/common';

export default makeStyles(() => ({
    root: {
        // padding: '16px 16px 16px 16px',
        '&:hover': {
            backgroundColor: (props) => (props.isReaded ? 'unset' : COLOR_PRIMARY_SHADE_03),
            cursor: (props) => (props.isReaded ? 'unset' : 'pointer'),
        },
        // justifyContent: 'space-between',
        padding: '16px 0px 16px 0px',
        overflowX: 'hidden'
    },
    bellIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0px',
        width: '40px',
        height: '40px',
        borderRadius: '8px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        opacity: (props) => (props.isReaded ? '0.6' : '1'),
    },
    bellIcon: {
        zIndex: (props) => (props.isReaded ? '-1' : 'unset'),
    },
    bodyText: {
        ...poppins600_10px,
        fontSize: '14px',
        width: '100%',
        fontWeight: (props) => (props.isReaded ? 'normal' : '600'),
    },
    dateText: {
        ...poppins600_12px,
        color: COLOR_SECONDARY_SHADE_01,
        fontWeight: (props) => (props.isReaded ? 'normal' : '600'),
    },
    checkbox: {
        paddingLeft: '5px',
        float: 'right',
    },
}));
