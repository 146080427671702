import { makeStyles } from '@material-ui/core/styles';
import { buttonStyle, COLOR_THEME_FILL_01 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    cardButton: {
        ...buttonStyle,
        marginTop: '20px',
        fontStyle: 'Medium',
        lineHeight: 'normal',
        borderRadius: 8,
        backgroundColor: '#5b7adb',
        fontSize: 14,
        height: 28,
    },
    box: {
        backgroundColor: COLOR_THEME_FILL_01,
        display: 'flex',
        flexDirection: 'column',
        padding: '37px 0',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '16px',
        borderRadius: '8px',
        '& > div': {
            textAlign: 'center',
        },
    },
});

export default useStyles;
