import { Button, withStyles } from '@material-ui/core';
import {
    buttonStyle,
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_03,
    fontStyle,
    tagStyle,
} from 'fintech/components/style/common';

export const StyledTag = withStyles({
    root: {
        ...buttonStyle,
        ...fontStyle,
        ...tagStyle,
    },
})(Button);

export const ClickableStyledTag = withStyles({
    root: {
        ...buttonStyle,
        ...fontStyle,
        ...tagStyle,
        marginTop: '0px',
        minWidth: '40px !important',
        color: COLOR_PRIMARY_BASE,
        '&:hover': {
            color: COLOR_PRIMARY_BASE,
            backgroundColor: COLOR_PRIMARY_SHADE_03,
            boxShadow: 'none',
        },
    },
})(Button);
