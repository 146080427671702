import * as React from 'react';

function SvgIconsMultipleChat(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#D9DCE0"
                    d="M5.91 20.572l-.37-3.178a.674.674 0 00-.205-.431 7.985 7.985 0 01-1.753-2.605 7.818 7.818 0 01-.605-3.035c0-1.12.232-2.194.697-3.22a8.303 8.303 0 011.928-2.665 8.944 8.944 0 012.87-1.805c1.122-.438 2.298-.656 3.528-.656s2.406.218 3.527.656a9.334 9.334 0 012.871 1.815 8.182 8.182 0 011.928 2.656 7.731 7.731 0 01.697 3.23 7.738 7.738 0 01-.697 3.23 8.303 8.303 0 01-1.928 2.665 9.155 9.155 0 01-2.87 1.785A9.39 9.39 0 0112 19.69c-1.08 0-2.12-.17-3.117-.512a.741.741 0 00-.615.061l-2.359 1.333z"
                />
                <path
                    fill="#3F5162"
                    fillRule="nonzero"
                    d="M5.314 22.5a.751.751 0 00.37-.103l3.035-1.722c1.066.328 2.16.492 3.281.492 1.408 0 2.762-.253 4.06-.759a10.758 10.758 0 003.343-2.112 9.681 9.681 0 002.256-3.117 9.117 9.117 0 00.841-3.856c0-1.326-.28-2.604-.84-3.835a9.922 9.922 0 00-2.257-3.137c-.97-.903-2.085-1.6-3.342-2.092A10.996 10.996 0 0012 1.5a10.98 10.98 0 00-4.06.759 10.455 10.455 0 00-3.343 2.092 9.914 9.914 0 00-2.256 3.137 9.147 9.147 0 00-.841 3.835 9.18 9.18 0 00.677 3.476 9.884 9.884 0 001.927 3.025l.472 4.02c.041.26.178.45.41.574a.647.647 0 00.328.082zm.595-1.928l-.369-3.178a.674.674 0 00-.205-.431 7.985 7.985 0 01-1.753-2.605 7.818 7.818 0 01-.605-3.035c0-1.12.232-2.194.697-3.22a8.303 8.303 0 011.928-2.665 8.944 8.944 0 012.87-1.805c1.122-.438 2.298-.656 3.528-.656s2.406.218 3.527.656a9.334 9.334 0 012.871 1.815 8.182 8.182 0 011.928 2.656 7.731 7.731 0 01.697 3.23 7.738 7.738 0 01-.697 3.23 8.303 8.303 0 01-1.928 2.665 9.155 9.155 0 01-2.87 1.785A9.39 9.39 0 0112 19.69c-1.08 0-2.12-.17-3.117-.512a.741.741 0 00-.615.061l-2.359 1.333zm1.58-8.1c.314 0 .58-.11.8-.328.218-.22.327-.486.327-.8 0-.315-.11-.581-.328-.8a1.087 1.087 0 00-.8-.328c-.314 0-.58.11-.8.328a1.091 1.091 0 00-.328.8c0 .314.11.58.328.8.22.218.486.328.8.328zm9.023 0c.314 0 .58-.11.8-.328.218-.22.328-.486.328-.8 0-.315-.11-.581-.328-.8a1.09 1.09 0 00-.8-.328c-.315 0-.581.11-.8.328a1.087 1.087 0 00-.328.8c0 .314.11.58.328.8.219.218.485.328.8.328zm-4.512 0c.314 0 .581-.11.8-.328a1.09 1.09 0 00.328-.8c0-.315-.11-.581-.328-.8a1.089 1.089 0 00-.8-.328c-.314 0-.581.11-.8.328a1.087 1.087 0 00-.328.8c0 .314.11.58.328.8.219.218.486.328.8.328z"
                />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleChat;
