import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTemplate from 'fintech/components/PageTemplate';
import { Grid, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import { makeChatStyles } from 'fintech/pages/post-login/ChatPage/index.style';
import ChatPanel from 'fintech/components/chat/ChatPanel';
import ChatSidebar from 'fintech/components/chat/ChatSidebar';
import { getPublicFileUrl } from 'fintech/api/fileService';
import placeholderPerson from 'fintech/static/images/placeholder-kisi-rounded.png';
import { getRequest, putRequest } from 'fintech/api/api';
import { APIS } from 'fintech/constants';
import { useSnackbar } from 'notistack';

const ChatPage = () => {
    const { t } = useTranslation('inbox');
    const { keycloak } = useKeycloak();
    const chatStyles = makeChatStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { enqueueSnackbar } = useSnackbar();
    const showErrorMessage = (message) => enqueueSnackbar(message, { variant: 'error' });
    const [messageEntries, setMessageEntries] = useState([]);

    const [currentClientUuid, setCurrentClientUuid] = useState(null);

    const handleMessageEntrySelect = (clientUuid) => {
        setMessagesSeen(clientUuid);
        setCurrentClientUuid(clientUuid);
    };

    const setMessagesSeen = (clientUuid) => {
        putRequest(APIS.CHAT.getMessagesSeenByUserAndClient(clientUuid), keycloak)
            .then((result) => {
                setMessageEntries(result.data);
            })
            .catch((error) => showErrorMessage(error.message));
    };

    useEffect(() => {
        getRequest(APIS.CHAT.getMessageEntriesByUser(), keycloak)
            .then((result) => {
                if (result.data) {
                    setMessageEntries(result.data);
                }
            })
            .catch((error) => showErrorMessage(error.message));
    }, []);

    return (
        <PageTemplate>
            <Grid spacing={1} className={chatStyles.grid}>
                <ChatSidebar onMessageEntrySelect={handleMessageEntrySelect} messageEntries={messageEntries} />
                <ChatPanel currentClientUuid={currentClientUuid} />
            </Grid>
        </PageTemplate>
    );
};

export default ChatPage;
