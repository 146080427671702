import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import CfoFormLabel from 'fintech/components/applications/form/common/CfoFormLabel';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import PersonForm from 'fintech/components/applications/form/common/PersonForm';
import useLegalTextClick from 'fintech/hooks/useLegalTextClick';
import LegalTextModal from 'fintech/components/applications/modal/LegalTextModal';
import * as constants from 'fintech/components/util/ApplicationFormConstants';
import { StartupApplicationActions } from 'fintech/store/actions/ActionTypes';
import { fetchCountries, fetchEducationLevels, fetchGenders } from 'fintech/store/actions/application-startup';
import useStyles from 'fintech/components/applications/form/investor/InvestorNRepForm/index.style';
import { TCKN, VKN } from 'fintech/components/util/IdentityConstants';
import CfoSingleCheckbox from 'fintech/components/ui/form/checkbox/CfoSingleCheckbox';
import { Check, KeyboardBackspace } from '@material-ui/icons';
import * as FintechBeService from 'fintech/api/fintechService';
import { loadingSpinnerWithTextGeneralTextStyle, mobileMediaQuery } from 'fintech/components/style/common';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const StartupNRepForm = (props) => {
    const { t } = useTranslation('application');
    const { switchTabHandler, isInvite } = props;
    const { keycloak } = useKeycloak();
    const classes = useStyles();
    const matches = !useMediaQuery(mobileMediaQuery);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { options, ...form } = useSelector((state) => state.startupApplication);
    const { hasActiveRequest } = useSelector((state) => state.common);
    const { countries, genders, educationLevels } = options;
    const [openModal, setOpenModal] = useState(false);
    const [clickedAgreement, setClickedAgreement] = useState(null);

    useLegalTextClick({
        legalClickListener: (e) => {
            if (e?.currentTarget) {
                setClickedAgreement(e?.currentTarget?.id);
                setOpenModal(true);
            }
        },
    });

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        dispatch(fetchCountries(keycloak));
        dispatch(fetchGenders(keycloak));
        dispatch(fetchEducationLevels(keycloak));
    }, []);

    const closeModalHandler = () => setOpenModal(false);

    const formChangeHandler = ({ type, data }) => {
        switch (type) {
            case constants.STARTUP_FORM_STARTUP_NAME_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_STARTUP_NAME, data });
                break;
            case constants.STARTUP_FORM_COVER_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_COVER_LETTER, data });
                break;
            case constants.STARTUP_FORM_WEBSITE_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_STARTUP_WEBSITE, data });
                break;
            case constants.PERSON_FORM_NAME_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_NAME, data });
                break;
            case constants.PERSON_FORM_SURNAME_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_SURNAME, data });
                break;
            case constants.PERSON_FORM_COUNTRY_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_COUNTRY, data });
                break;
            case constants.PERSON_FORM_IDENTITY_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_IDENTITY, data });
                break;
            case constants.PERSON_FORM_GENDER_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_GENDER, data });
                break;
            case constants.PERSON_FORM_BIRTHDATE_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_BIRTHDATE, data });
                break;
            case constants.PERSON_FORM_EMAIL_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_EMAIL, data });
                break;
            case constants.PERSON_FORM_PHONE_NUMBER_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_PHONE_NUMBER, data });
                break;
            case constants.PERSON_FORM_EXPERIENCE_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_EXPERIENCE, data });
                break;
            case constants.PERSON_FORM_JOB_TITLE_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_JOB_TITLE, data });
                break;
            case constants.PERSON_FORM_EDUCATION_UPDATE:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_REP_EDUCATION, data });
                break;
            case constants.STARTUP_FORM_AGREEMENTS:
                dispatch({ type: StartupApplicationActions.SET_STARTUP_APPLICATION_AGREEMENTS });
                break;
        }
    };

    const clickContinueHandler = async () => {
        const {
            type,
            tradeName,
            companyEmail,
            companyIdentity,
            startupName,
            cover,
            website,
            name,
            surname,
            country,
            idNumber,
            gender,
            birthDate,
            email,
            phoneNumber,
            experience,
            jobTitle,
            education,
            trafficSource,
        } = form;
        dispatch(setActiveRequest(true));
        const requestData = {};

        // First step
        requestData.tradeName = tradeName;
        requestData.identity = {
            value: companyIdentity,
            identityType: type === constants.STARTUP_INDIVIDUAL ? TCKN : VKN,
        };
        requestData.startupEmail = companyEmail;

        // Second step
        requestData.startupName = startupName?.value;
        requestData.coverLetter = cover?.value;
        requestData.website = website?.value;
        requestData.name = name?.value;
        requestData.surname = surname?.value;
        requestData.countryCode = country?.value?.id;
        requestData.tckn = idNumber?.value;
        requestData.gender = gender?.value?.id;
        requestData.birthDate = birthDate?.value;
        requestData.email = email?.value;
        requestData.gsmNo = {
            number: phoneNumber?.value?.nationalNumber,
            country: {
                alphaTwo: phoneNumber?.value?.country,
                callingCode: phoneNumber?.value?.countryCallingCode,
            },
        };
        requestData.expertiseYear = +experience?.value;
        requestData.title = jobTitle?.value;
        requestData.educationInfo = education?.value?.id;
        requestData.trafficSource = trafficSource;

        let res = null;
        try {
            res = await FintechBeService.startupApplicationComplete(keycloak, requestData);
            dispatch(setActiveRequest(false));
        } catch (reason) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        if (!res || !res.success) {
            enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        switchTabHandler(true);
    };

    const validate = () => {
        const {
            startupName,
            cover,
            website,
            name,
            surname,
            country,
            idNumber,
            gender,
            birthDate,
            email,
            phoneNumber,
            experience,
            jobTitle,
            education,
            readAgreement,
        } = form;

        return (
            startupName?.validated &&
            cover?.validated &&
            website?.validated &&
            name?.validated &&
            surname?.validated &&
            country?.validated &&
            idNumber?.validated &&
            gender?.validated &&
            birthDate?.validated &&
            email?.validated &&
            phoneNumber?.validated &&
            experience?.validated &&
            jobTitle?.validated &&
            education?.validated &&
            readAgreement
        );
    };

    const repIndivDisabled = form.type === constants.STARTUP_INDIVIDUAL && isInvite;
    const repEqDisabled = isInvite && form.type === constants.STARTUP_EQUITY;

    const isLoading = countries?.isLoading || genders.isLoading || educationLevels.isLoading;

    return isLoading ? (
        <LoadingSpinnerWithText style={loadingSpinnerWithTextGeneralTextStyle} />
    ) : (
        <CfoFormContainer>
            <Grid
                className={classes.formGridContainer}
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <CfoFormLabel
                    rootStyle={{ marginBottom: '20px' }}
                    label={t('startup.form.label.startupName')}
                    subText={t('startup.form.helper.startupName')}
                />
                <CfoFormInput>
                    <InputTextArea
                        containerClass={classes.inputTextAreaWrapper}
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        initText={form?.startupName?.value}
                        onChange={(value, validated) => {
                            formChangeHandler({
                                type: constants.STARTUP_FORM_STARTUP_NAME_UPDATE,
                                data: { value, validated },
                            });
                        }}
                        type="text"
                        required
                        count={150}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                    />
                </CfoFormInput>
            </Grid>
            <Grid
                className={classes.formGridContainer}
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <CfoFormLabel rootStyle={{ marginBottom: '20px' }} label={t('startup.form.label.aboutStartup')} />
                <CfoFormInput>
                    <InputTextArea
                        containerClass={classes.inputTextAreaWrapper}
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        initText={form?.cover?.value}
                        onChange={(value, validated) => {
                            formChangeHandler({
                                type: constants.STARTUP_FORM_COVER_UPDATE,
                                data: { value, validated },
                            });
                        }}
                        type="text"
                        required
                        rowCount={5}
                        count={400}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                    />
                </CfoFormInput>
            </Grid>
            <Grid
                className={classes.formGridContainer}
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <CfoFormLabel rootStyle={{ marginBottom: '20px' }} label={t('startup.form.label.website')} />
                <CfoFormInput>
                    <InputTextArea
                        containerClass={classes.inputTextAreaWrapper}
                        placeholder={t('formPlaceholder.url')}
                        initText={form?.website?.value}
                        onChange={(value, validated) => {
                            formChangeHandler({
                                type: constants.STARTUP_FORM_WEBSITE_UPDATE,
                                data: { value, validated },
                            });
                        }}
                        type="url"
                        count={100}
                        hideCountIndicator
                        validationText={t('validation.url')}
                        validateOnBlur
                    />
                </CfoFormInput>
            </Grid>
            <Grid item container direction="row" justify="space-between" alignItems="flex-start">
                <div>
                    <CfoFormLabel
                        label={t('common.form.label.representativeInfo')}
                        subText={t('startup.form.helper.representativeInfo')}
                    />
                    <CfoFormLabel subText={t('startup.form.helper.representativeInfoSubTitle')} />
                </div>
                <CfoFormInput className={classes.personFormWrapper}>
                    <PersonForm
                        changeCallback={formChangeHandler}
                        selectOptions={{ countries, genders, educationLevels }}
                        initFormData={{ ...form }}
                        nameDisabled={repIndivDisabled || repEqDisabled}
                        surnameDisabled={repIndivDisabled || repEqDisabled}
                        emailDisabled={repIndivDisabled || repEqDisabled}
                    />
                </CfoFormInput>
            </Grid>
            <Grid item container direction="row" justify="flex-end">
                <Grid item xs={12} lg={8}>
                    <div className={classes.legalDiv}>
                        <CfoSingleCheckbox
                            checked={form.readAgreement}
                            onChange={() => {
                                formChangeHandler({
                                    type: constants.STARTUP_FORM_AGREEMENTS,
                                });
                            }}
                            label={<Typography dangerouslySetInnerHTML={{ __html: t('startup.legal') }} />}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid item container direction="row-reverse" justify="space-between" alignItems="flex-start">
                <CfoFormInput xs={7} lg={8} className={classes.applyBtnWrapper}>
                    <Button
                        className={classes.applyBtn}
                        startIcon={!matches && <Check />}
                        disabled={hasActiveRequest || !validate()}
                        variant="contained"
                        onClick={clickContinueHandler}
                    >
                        {!hasActiveRequest ? t('common.form.label.sendApplication') : <ButtonCircularIndicator />}
                    </Button>
                </CfoFormInput>
                <CfoFormInput xs={5} lg={4} className={classes.backBtnWrapper}>
                    <Button
                        className={classes.backBtn}
                        startIcon={!matches && <KeyboardBackspace />}
                        disabled={hasActiveRequest}
                        variant="contained"
                        onClick={() => switchTabHandler()}
                    >
                        {matches ? t('common.form.label.previousStepShort') : t('common.form.label.previousStep')}
                    </Button>
                </CfoFormInput>
            </Grid>
            <LegalTextModal initialTab={clickedAgreement} open={openModal} onCancel={closeModalHandler} />
        </CfoFormContainer>
    );
};

export default StartupNRepForm;
