import { INVESTOR_INSTITUTIONAL } from 'fintech/components/util/ApplicationFormConstants';
import { INVITE_URL, WEBSITE } from 'fintech/components/util/TrafficStatusConstants';
import { InvestorApplicationActions } from 'fintech/store/actions/ActionTypes';
import { isValidTckn } from 'fintech/utils/StringUtils';

const initialState = {
    type: INVESTOR_INSTITUTIONAL,

    // For invite URL scenarios
    isLoading: true,

    institution: {
        name: '',
        idNumber: '',
        email: '',
    },
    cover: {
        value: '',
        validated: false,
    },
    name: {
        value: '',
        validated: false,
    },
    surname: {
        value: '',
        validated: false,
    },
    country: {
        value: '',
        validated: false,
    },
    idNumber: {
        value: '',
        validated: false,
    },
    gender: {
        value: null,
        validated: false,
    },
    birthDate: {
        value: '',
        validated: false,
    },
    email: {
        value: '',
        validated: false,
    },
    phoneNumber: {
        value: {
            country: 'TR',
            countryCallingCode: '90',
            nationalNumber: '',
            number: '',
        },
        validated: false,
    },
    experience: {
        value: '',
        validated: false,
    },
    jobTitle: {
        value: '',
        validated: false,
    },
    education: {
        value: null,
        validated: false,
    },
    readAgreement: false,
    acceptsMKKform: false,
    trafficSource: WEBSITE,

    // Select options
    options: {
        countries: {
            data: null,
            isLoading: true,
            isError: false,
        },
        genders: {
            data: null,
            isLoading: true,
            isError: false,
        },
        educationLevels: {
            data: null,
            isLoading: true,
            isError: false,
        },
    },
};

const investorApplication = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_INVESTOR_TYPE:
            return {
                ...state,
                type: action.data,
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_INVITE_LOADING:
            return {
                ...state,
                isLoading: action.data,
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_INSTITUTION_NAME:
            return {
                ...state,
                institution: {
                    ...state.institution,
                    name: action.data,
                },
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_INSTITUTION_IDENTITY:
            return {
                ...state,
                institution: {
                    ...state.institution,
                    idNumber: action.data,
                },
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_INSTITUTION_EMAIL:
            return {
                ...state,
                institution: {
                    ...state.institution,
                    email: action.data,
                },
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_COVER_LETTER:
            return {
                ...state,
                cover: action.data,
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_NAME:
            return {
                ...state,
                name: action.data,
            };

        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_SURNAME:
            return {
                ...state,
                surname: action.data,
            };

        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_COUNTRY:
            return {
                ...state,
                country: action.data,
            };

        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_IDENTITY:
            return {
                ...state,
                idNumber: action.data,
            };

        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_GENDER:
            return {
                ...state,
                gender: action.data,
            };

        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_BIRTHDATE:
            return {
                ...state,
                birthDate: action.data,
            };

        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_EMAIL:
            return {
                ...state,
                email: action.data,
            };

        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: {
                    ...state.phoneNumber,
                    ...action.data,
                },
            };

        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_EXPERIENCE:
            return {
                ...state,
                experience: action.data,
            };

        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_JOB_TITLE:
            return {
                ...state,
                jobTitle: action.data,
            };

        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_REP_EDUCATION:
            return {
                ...state,
                education: action.data,
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_AGREEMENTS:
            return {
                ...state,
                readAgreement: !state.readAgreement,
            };

        // From invite
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_INVITE_INDIVIDUAL:
            return {
                ...state,
                type: action.data.type,
                name: { value: action.data.name, validated: action.data.name ? true : false },
                surname: { value: action.data.surname, validated: action.data.surname ? true : false },
                idNumber: {
                    value: action.data.companyIdentityNumber,
                    validated: isValidTckn(action.data.companyIdentityNumber),
                },
                email: { value: action.data.email, validated: action.data.email ? true : false },
                trafficSource: INVITE_URL,
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_INVITE_INSTITUTIONAL: {
            return {
                ...state,
                type: action.data.type,
                institution: {
                    ...state.institution,
                    name: action.data.companyName,
                    idNumber: action.data.companyIdentityNumber,
                },
                name: { value: action.data.name, validated: action.data.name ? true : false },
                surname: { value: action.data.surname, validated: action.data.surname ? true : false },
                email: { value: action.data.email, validated: action.data.email ? true : false },
                trafficSource: INVITE_URL,
            };
        }

        // Countries
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_COUNTRIES:
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        ...state.options.countries,
                        data: action.countries.data,
                        isLoading: false,
                    },
                },
            };
        case InvestorApplicationActions.GET_INVESTOR_APPLICATION_COUNTRIES:
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_COUNTRIES_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        ...state.options.countries,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Genders
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_GENDERS:
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        ...state.options.genders,
                        data: action.genders.data,
                        isLoading: false,
                    },
                },
            };
        case InvestorApplicationActions.GET_INVESTOR_APPLICATION_GENDERS:
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_GENDERS_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        ...state.options.genders,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Education levels
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_EDUCATION_LEVELS:
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        ...state.options.educationLevels,
                        data: action.levels.data,
                        isLoading: false,
                    },
                },
            };
        case InvestorApplicationActions.GET_INVESTOR_APPLICATION_EDUCATION_LEVELS:
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        ...state.options.levels,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case InvestorApplicationActions.RESET_INVESTOR_APPLICATION_FORM:
            return { ...initialState, isLoading: false };

        case InvestorApplicationActions.RESET_INVESTOR_APPLICATION_GENERAL_INFO:
            return {
                ...state,
                institution: initialState.institution,
                name: initialState.name,
                surname: initialState.surname,
                idNumber: initialState.idNumber,
            };
        case InvestorApplicationActions.SET_INVESTOR_APPLICATION_MKK_FORM:
            return {
                ...state,
                acceptsMKKform: action.value,
            }
        default:
            return state;
    }
};

export default investorApplication;
