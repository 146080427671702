import { makeStyles } from '@material-ui/core/styles';
import { fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    searchInputOfSelect: {
        '& input': {
            ...fontStyle,
        },
    },
});

export default useStyles;
