import * as React from 'react';

function SvgIconsMultipleMenu4(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        fill="#D9DCE0"
                                        d="M7.031 9H5.063L4.5 7.594h-.844l-.562-1.407H2.25l-.563-1.406h4.5c.467 0 .844.378.844.844V9z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#D9DCE0"
                                        d="M10.969 10.125v-4.5c0-.466.378-.844.844-.844h5.906V3.375H.28v1.406h5.907c.465 0 .843.378.843.844v4.5h3.938zM7.313 17.906L10.688 17.906 10.688 10.406 7.313 10.406z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M8.719 17.719L9.281 17.719 9.281 11.531 8.719 11.531zM8.719 5.063L9.281 5.063 9.281 4.5 8.719 4.5z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#D9DCE0"
                                        d="M10.969 9h1.969l.562-1.406h.844l.562-1.407h.844l.563-1.406h-4.5c-.467 0-.844.378-.844.844V9z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M15.94 6.469L13.5 6.469 13.5 5.906 15.56 5.906 16.051 4.677 16.574 4.886z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M14.534 7.875L12.375 7.875 12.375 7.313 14.153 7.313 14.645 6.083 15.167 6.292z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M13.128 9.281L11.813 9.281 11.813 8.719 12.747 8.719 13.239 7.489 13.761 7.698z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#FFF"
                                        d="M10.125 1.406c0 .622-.504 1.125-1.125 1.125s-1.125-.503-1.125-1.125C7.875.785 8.379.281 9 .281s1.125.504 1.125 1.125"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M9 .563c-.465 0-.844.378-.844.843 0 .465.379.844.844.844.465 0 .844-.379.844-.844 0-.465-.379-.843-.844-.843m0 2.25c-.775 0-1.406-.631-1.406-1.407C7.594.631 8.224 0 9 0c.775 0 1.406.63 1.406 1.406 0 .776-.63 1.407-1.406 1.407M4.5 6.469L2.06 6.469 1.426 4.886 1.949 4.677 2.44 5.906 4.5 5.906z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M5.625 7.875L3.466 7.875 2.832 6.292 3.355 6.083 3.847 7.313 5.625 7.313z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M6.188 9.281L4.872 9.281 4.239 7.698 4.761 7.489 5.253 8.719 6.188 8.719z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                    <path
                                        fill="#3F5162"
                                        d="M11.25 10.406h-4.5V5.625c0-.31-.252-.563-.563-.563H.282V4.5h5.907c.62 0 1.125.505 1.125 1.125v4.219h3.375V5.625c0-.62.504-1.125 1.124-1.125h5.907v.563h-5.907c-.31 0-.562.252-.562.562v4.781zM5.25 18.038L12.75 18.038 12.75 17.475 5.25 17.475z"
                                        transform="translate(-1090 -215) translate(1071 77) translate(0 128) translate(19 9.5) translate(0 1)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleMenu4;
