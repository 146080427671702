import { UserActions } from 'fintech/store/actions/ActionTypes';
import { arrayToMap } from 'fintech/utils/ArrayUtils';
import { parseInfos, parsePhoneNumberData, parseTags, parseVentureData } from 'fintech/utils/DataParser';

const initialState = {
    intro: { user: [], isLoading: true, isError: false },
    userImage: null,
    ventures: {
        data: [],
        isLoading: true,
        isError: false,
    },
    mentorships: {
        data: [],
        isLoading: true,
        isError: false,
    },
    usersship: {
        data: [],
        isLoading: true,
        isError: false,
    },
    relatedInvestors: {
        data: [],
        isLoading: true,
        isError: false,
    },
    verticalData: {
        data: [],
        isLoading: true,
        isError: false,
    },
    technologyData: {
        data: [],
        isLoading: true,
        isError: false,
    },
};

const user = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case UserActions.SET_USER:
            const { user } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    data: {
                        ...user,
                        profileInfos: parseInfos(user.userProfileInfoList),
                        verticals: arrayToMap(user.userFintechSubVerticalSet, 'vertical'),
                        technologies: parseTags(user.technologySet),
                        phoneNumber: parsePhoneNumberData(user.phoneNumber),
                        role: user.role,
                        isConsultant: user.isConsultant,
                        isConsultantFromUsersPerspective: user.isConsultantFromUsersPerspective,
                        isOwnProfile: user.isOwnProfile,
                    },
                    isLoading: false,
                },
            };
        case UserActions.SET_HIDE_SEND_MESSAGE_BUTTON: {
            const { hideSendMessage } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    data: {
                        ...state.intro.data,
                        hideSendMessage,
                    },
                },
            };
        }
        case UserActions.SET_USER_LOADING:
            return {
                ...state,
                intro: {
                    ...state.intro,
                    isLoading: true,
                    isError: false,
                    user: [],
                },
            };
        case UserActions.SET_USER_ERROR:
            return {
                ...state,
                intro: {
                    ...state.intro,
                    isLoading: false,
                    isError: true,
                },
            };
        case UserActions.SET_USER_VENTURES:
            const { ventures } = action;
            return {
                ...state,
                ventures: {
                    ...state.ventures,
                    data: ventures.data.map((vent) => parseVentureData(vent)),
                    isLoading: false,
                },
            };
        case UserActions.SET_USER_VENTURES_LOADING:
            return {
                ...state,
                ventures: {
                    ...state.ventures,
                    isLoading: true,
                    isError: false,
                    data: [],
                },
            };
        case UserActions.SET_USER_VENTURES_LOAD_ERROR:
            return {
                ...state,
                ventures: {
                    ...state.ventures,
                    isLoading: false,
                    isError: true,
                },
            };
        case UserActions.SET_USER_MENTORSHIPS:
            const { mentorships } = action;
            return {
                ...state,
                mentorships: {
                    ...state.mentorships,
                    data: mentorships.data.map((vent) => parseVentureData(vent)),
                    isLoading: false,
                },
            };
        case UserActions.SET_USER_MENTORSHIPS_LOADING:
            return {
                ...state,
                mentorships: {
                    ...state.mentorships,
                    isLoading: true,
                    isError: false,
                    data: [],
                },
            };
        case UserActions.SET_USER_MENTORSHIPS_LOAD_ERROR:
            return {
                ...state,
                mentorships: {
                    ...state.mentorships,
                    isLoading: false,
                    isError: true,
                },
            };

        ///
        case UserActions.SET_USER_USERSSHIPS:
            const { usersship } = action;
            return {
                ...state,
                usersship: {
                    ...state.usersship,
                    data: usersship.data.map((vent) => parseVentureData(vent)),
                    isLoading: false,
                },
            };
        case UserActions.SET_USER_USERSSHIPS_LOADING:
            return {
                ...state,
                usersship: {
                    ...state.usersship,
                    isLoading: true,
                    isError: false,
                    data: [],
                },
            };
        case UserActions.SET_USER_MENTORSHIPS_LOAD_ERROR:
            return {
                ...state,
                usersship: {
                    ...state.usersship,
                    isLoading: false,
                    isError: true,
                },
            };

        ///
        case UserActions.SET_USER_RELATED_INVESTORS:
            const { relatedInvestors } = action;
            return {
                ...state,
                relatedInvestors: {
                    ...state.relatedInvestors,
                    data: relatedInvestors.data,
                    isLoading: false,
                },
            };
        case UserActions.SET_USER_RELATED_INVESTORS_LOADING:
            return {
                ...state,
                relatedInvestors: {
                    ...state.relatedInvestors,
                    isLoading: true,
                    isError: false,
                    data: [],
                },
            };
        case UserActions.SET_USER_RELATED_INVESTORS_LOAD_ERROR:
            return {
                ...state,
                relatedInvestors: {
                    ...state.relatedInvestors,
                    isLoading: false,
                    isError: true,
                },
            };

        //
        case UserActions.SET_USER_VERTICALS:
            const { userUuid, verticalData } = action;
            return {
                ...state,
                verticalData: {
                    ...state.verticalData,
                    data: {
                        verticals: [...verticalData.data],
                        userUuid: userUuid,
                    },
                    isLoading: false,
                },
            };
        case UserActions.SET_USER_VERTICALS_LOADING:
            return {
                ...state,
                verticalData: {
                    ...state.verticalData,
                    isLoading: true,
                },
            };
        case UserActions.SET_USER_VERTICALS_LOAD_ERROR:
            return {
                ...state,
                verticalData: {
                    ...state.verticalData,
                    isLoading: false,
                    isError: true,
                },
            };

        case UserActions.SET_USER_TECHNOLOGIES:
            const { technologyData } = action;
            return {
                ...state,
                technologyData: {
                    ...state.technologyData,
                    data: {
                        technologies: [...technologyData.data],
                    },
                    isLoading: false,
                },
            };
        case UserActions.SET_USER_TECHNOLOGIES_LOADING:
            return {
                ...state,
                technologyData: {
                    ...state.technologyData,
                    isLoading: true,
                },
            };
        case UserActions.SET_USER_TECHNOLOGIES_LOAD_ERROR:
            return {
                ...state,
                technologyData: {
                    ...state.technologyData,
                    isLoading: false,
                    isError: true,
                },
            };
        case UserActions.SET_USER_IMAGE:
            const { userImage } = action;
            return {
                ...state,
                userImage: userImage,
            };
        case UserActions.SET_VISIT_COUNT:
            const { count } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    data: {
                        ...state.intro.data,
                        visitTimes: count,
                    },
                },
            };
        case UserActions.CLEAN_UP_USER:
            return {
                ...initialState,
                userImage: state.userImage,
            };
        default:
            return state;
    }
};

export default user;
