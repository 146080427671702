import * as React from 'react';

function SvgIconsMultipleProfil(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            fill="#D9DCE0"
                                            d="M7.347 2.204c0-1.014.822-1.837 1.837-1.837C15.429.367 18 6.98 18 6.98l2.204 5.877v.735H18V18c0 .406-.33.735-.735.735H9.918c-1.42 0-2.571-1.152-2.571-2.572V2.204z"
                                            transform="translate(-117.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(16.000000, 11.000000) translate(1.500000, 0.000000)"
                                        />
                                        <path
                                            fill="#3F5162"
                                            d="M13.959 23.51L14.694 23.51 14.694 18.735 13.959 18.735z"
                                            transform="translate(-117.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(16.000000, 11.000000) translate(1.500000, 0.000000)"
                                        />
                                        <path
                                            fill="#3F5162"
                                            d="M17.265 19.102h-5.51v-.735h5.51c.203 0 .368-.165.368-.367v-4.776h2.204v-.3l-2.181-5.815c-.092-.273-2.21-6.374-8.472-6.374H2.939V0h6.245c6.798 0 9.141 6.795 9.164 6.864l2.223 5.927v1.168h-2.204V18c0 .608-.494 1.102-1.102 1.102M1.469.735L2.204.735 2.204 0 1.469 0zM0 .735L.735.735.735 0 0 0zM1.102 8.816L5.51 8.816 5.51 8.082 1.102 8.082z"
                                            transform="translate(-117.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(16.000000, 11.000000) translate(1.500000, 0.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleProfil;
