import { Tabs, Tab, withStyles, makeStyles } from '@material-ui/core';
import {
    fontStyle,
    COLOR_SECONDARY_SHADE_01,
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_BASE,
    COLOR_THEME_FILL_01,
} from 'fintech/components/style/common';

export const StyledTabs = withStyles((theme) => ({
    indicator: {
        display: 'none',
    },
    root: {
        borderLeft: '4px solid #3f5162',
        minHeight: '24px',
        height: '24px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '16px',
            height: '16px',
        },
    },
}))(Tabs);

export const StyledTab = withStyles((theme) => ({
    root: {
        ...fontStyle,
        height: '25px',
        margin: '0',
        paddingLeft: '16px',
        paddingRight: '8px',
        minWidth: '50px',
        fontSize: '18px',
        maxWidth: '500px',
        color: COLOR_SECONDARY_SHADE_01,
        textTransform: 'none',
        bottom: '12px',
        '&:hover': {
            color: COLOR_SECONDARY_BASE,
            opacity: 1.0,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            bottom: '17px',
        },
    },
    wrapper: {
        width: 'inherit',
    },
    selected: {
        color: COLOR_SECONDARY_BASE,
    },
}))(Tab);

export const useTabContainerStyles = makeStyles((theme) => ({
    indicator: {
        display: 'none',
    },
    root: {
        borderLeft: '4px solid #3f5162',
        minHeight: '24px',
        height: '24px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '16px',
            height: '16px',
        },
        '&:hover': {
            [theme.breakpoints.down('sm')]: {
                '& .MuiTab-root': {
                    color: ({ multipleTabs }) => (multipleTabs ? `${COLOR_PRIMARY_BASE} !important` : null),
                },
            },
        },
    },
}));

export const useTabStyles = makeStyles((theme) => ({
    root: {
        ...fontStyle,
        height: '25px',
        margin: '0',
        paddingLeft: '16px',
        paddingRight: '8px',
        minWidth: '50px',
        fontSize: '18px',
        maxWidth: '500px',
        color: COLOR_SECONDARY_SHADE_01,
        textTransform: 'none',
        bottom: '12px',
        '&:hover': {
            color: COLOR_SECONDARY_BASE,
            opacity: 1.0,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            bottom: '17px',
        },
    },
    wrapper: {
        width: 'inherit',
    },
    selected: {
        color: COLOR_SECONDARY_BASE,
    },
}));

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        padding: '0 8px',
        borderRadius: '4px',
        backgroundColor: COLOR_THEME_FILL_01,
    },
    paperElevation: {
        boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26) !important',
    },
}));

export default useStyles;
