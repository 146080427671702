import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import useStyle from './index.style';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@material-ui/icons';

const Index = ({ showModal, setModalShow, onOk }) => {
    const classes = useStyle();
    const { t } = useTranslation('reports');
    return (
        <Modal open={showModal} onClose={() => setModalShow(false)}>
            <Box className={classes.modalContainer}>
                <InfoOutlined className={classes.icon} />
                <p>{t('popUp.requestApproval')}</p>
                <p>
                    {t('popUp.requestReceived')}
                    <br></br>
                    {t('popUp.gotoReportslist')}
                </p>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                        className={classes.button + ' ' + classes.closeButton}
                        p={2}
                        onClick={() => setModalShow(false)}>
                        {t('popUp.close')}
                    </Button>
                    <Button
                        className={classes.button + ' ' + classes.acceptButton}
                        onClick={() => onOk()}
                    >
                        {t('popUp.reportsList')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

Index.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setModalShow: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
};

export default Index;
