import { makeStyles } from '@material-ui/core';
import {
    COLOR_THEME_FILL_01,
    COLOR_PRIMARY_BUTTON_HOVERED,
    COLOR_PRIMARY_SHADE_01,
    COLOR_PRIMARY_SHADE_02,
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_BASE,
    COLOR_THEME_FILL_04,
    COLOR_THEME_FILL_03,
    COLOR_RED_BUTTON_HOVERED,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: COLOR_THEME_FILL_01,
        width: '66%',
        [theme.breakpoints.between(900, 1280)]: {
            width: '58%',
        },
        paddingTop: '13px',
        borderRadius: '8px',
    },
    gridRoot: {
        flexWrap: 'nowrap',
        height: '100%',
    },
    applicationInfo: {
        paddingLeft: '20px',
    },
    titleContainer: {
        paddingLeft: '3px',
        paddingRight: '3px',
    },
    title: {
        padding: '3px 20px 3px 17px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        color: COLOR_SECONDARY_BASE,
        fontSize: '10px',
        fontWeight: '600',
        textTransform: 'uppercase',
        alignItems: 'center',
    },
    sectionContent: {
        paddingLeft: '20px',
        paddingBottom: '10px',
    },
    buttons: {
        border: '1px solid #d7e2e8',
        padding: '29px 0',
        marginTop: 'auto',
        [theme.breakpoints.between(900, 950)]: {
            flexDirection: 'column-reverse',
            '& > div:first-child': {
                marginTop: '10px',
            },
        },
        [theme.breakpoints.down(400)]: {
            flexDirection: 'column-reverse',
            '& > div:first-child': {
                marginTop: '10px',
            },
        },
    },
    confirmButton: {
        cursor: 'pointer',
        backgroundColor: COLOR_PRIMARY_BASE,
        color: COLOR_THEME_FILL_01,
        padding: '4px 12px 4px 4px',
        borderRadius: '8px',

        '& > span:first-child': {
            marginRight: '4px',
        },
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
        },
    },
    rejectButton: {
        cursor: 'pointer',
        backgroundColor: COLOR_THEME_FILL_04,
        color: COLOR_THEME_FILL_03,
        fontWeight: '500',
        padding: '7px 22px',
        borderRadius: '8px',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: COLOR_RED_BUTTON_HOVERED,
        },
    },
    informationMessage: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: COLOR_PRIMARY_SHADE_01,
        margin: '34px auto',
        padding: '2px 13px',
        fontWeight: '500',
        borderRadius: '4px',
    },
    iconGrid: {
        margin: '20px 0',
    },
}));

export default useStyles;
