import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useStyles, { SearchButton } from './index.style.js';
import { useSnackbar } from 'notistack';
import { hasRoles } from 'fintech/utils/Auth';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';
import { adminTableHeaders, defaultTableHeaders } from 'fintech/constants/invitesSearch';
import { cancelInvite, sendInviteMail } from 'fintech/api/fintechService';
import {
    fetchGridData,
    persistState,
    resetAllFilters,
    searchBoxOnChange,
    setGridHeaders,
    setGridPage,
} from 'fintech/store/actions/inviteSearch';
import AdvancedFilterArea from 'fintech/components/search/AdvancedFilterArea';
import { InviteSearchActions } from 'fintech/store/actions/ActionTypes';
import SearchDataGrid from 'fintech/components/search/DataGrid';
import { uniqueSeperator } from 'fintech/constants';
import GridPagination from 'fintech/components/pagination/GridPagination';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import { InfoIcon } from 'fintech/components/profile/common';
import IconsSingleSearch from 'fintech/icons/IconsSingleSearch';
import { Grid } from '@material-ui/core';
import { setActiveRequest } from 'fintech/store/actions/common.js';

const Invites = () => {
    const classes = useStyles();
    const { t } = useTranslation('membershipManagement');
    const { keycloak } = useKeycloak();
    const { persist } = useSelector((state) => state.inviteSearch.global);
    const { headers, rows, pagination, hasActiveRequest } = useSelector((state) => ({
        ...state.inviteSearch.grid,
        ...state.common,
    }));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const errorCallback = (msg = t('idea.updateError')) => enqueueSnackbar(msg, { variant: 'error' });
    const type = 'inviteSearch';
    const { input, lastQueryParams } = useSelector((state) => state[type].searchBox);
    const isAdmin = hasRoles(keycloak, ROLE_ADMIN);
    var dayPrefix = t('invites.dataGrid.dayPrefix');
    const initHeaders = (!isAdmin ? defaultTableHeaders : adminTableHeaders).map((he) => ({
        ...he,
        value:
            he.type === 'sortLabel'
                ? {
                      ...he.value,
                      title: t(he.value.title),
                  }
                : t(he.value),
    }));

    // Status update confirmation dialog
    const [dialogState, setDialogState] = useState({
        open: false,
        editingId: null,
        value: null,
    });

    const clickRemoveInvite = (id) =>
        setDialogState({
            open: true,
            editingId: id,
            value: false,
        });

    const dialogConfirmedHandler = () => {
        dispatch(setActiveRequest(true));
        cancelInvite(keycloak, dialogState.editingId)
            .then(() => {
                setTimeout(function () {
                    dispatch(setActiveRequest(false));
                    dispatch(
                        fetchGridData({
                            keycloak,
                            errorCallback,
                            useLastQueryType: true,
                            dayTranslation: dayPrefix,
                        })
                    );
                    setDialogState({
                        open: false,
                        editingId: null,
                        value: null,
                    });
                    enqueueSnackbar(t('invites.dataGrid.adminActionResult.sendMailSuccess'), {
                        variant: 'success',
                    });
                }, 1000);
            })
            .catch(() => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(t('invites.dataGrid.adminActionResult.sendMailError'), {
                    variant: 'error',
                });
            });
    };

    const dialogCancelHandler = () =>
        setDialogState({
            open: false,
            editingId: null,
            switchCallback: null,
            value: null,
        });

    const makeSearchHandler = () => {
        dispatch({ type: InviteSearchActions.RESET_INVITE_SEARCH_GRID_PAGINATION });
        dispatch(
            fetchGridData({
                keycloak,
                data: {
                    searchTerm: input,
                },
                errorCallback,
                isFilter: false,
                dayTranslation: dayPrefix,
            })
        );
    };

    useEffect(() => {
        if (input === '' && lastQueryParams?.searchTerm && lastQueryParams.searchTerm !== '') {
            resetAllFilters();
            fetchGridData({ dayTranslation: dayPrefix });
        }
    }, [input]);

    useEffect(() => {
        resetAction();
    }, []);

    const resetAction = () => {
        if (!persist) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            dispatch(setGridHeaders(initHeaders));
            dispatch(resetAllFilters());
            dispatch(
                fetchGridData({
                    keycloak,
                    errorCallback,
                    dayTranslation: dayPrefix,
                })
            );
        } else {
            dispatch(persistState(false));
        }
    };

    const gridPageChangeHandler = (e, pageNumber) => {
        dispatch(setGridPage(pageNumber));
        dispatch(
            fetchGridData({
                keycloak,
                errorCallback,
                useLastQueryType: true,
                reloadGrid: false,
                dayTranslation: dayPrefix,
            })
        );
    };

    return (
        <div className={`${classes.invitesContainer}`}>
            <Grid container className={classes.searchBox}>
                <Grid item>
                    <span>
                        <IconsSingleSearch />
                    </span>
                </Grid>
                <Grid item className={classes.searchInputGrid}>
                    <input
                        type="text"
                        placeholder={t('invites.searchBox.placeholder')}
                        onChange={(e) => dispatch(searchBoxOnChange(e.target.value))}
                        onKeyDown={(e) => e.key === 'Enter' && makeSearchHandler()}
                        value={input}
                        className={classes.searchInput}
                    />
                </Grid>
                <Grid item>
                    <SearchButton onClick={makeSearchHandler} style={{ height: 28 }}>
                        {t('invites.searchBox.actionButton')}
                    </SearchButton>
                </Grid>
            </Grid>
            <AdvancedFilterArea
                changeTextOnQueryType={true}
                type={type}
                showModalToggle={false}
                showAdvancedFilterToggle={false}
                clearAll={() => {
                    dispatch(resetAllFilters());
                    resetAction();
                }}
            />

            <SearchDataGrid
                type={type}
                headers={headers}
                pagination={pagination}
                leftStickyColumnCount={isAdmin ? 2 : 1}
                heightInPixelsPerElement={50}
                buttonActions={{
                    sort: (id) => {
                        dispatch({ type: InviteSearchActions.TOGGLE_INVITE_SEARCH_GRID_STATE, data: +id });
                        dispatch(
                            fetchGridData({
                                keycloak,
                                errorCallback,
                                useLastQueryType: true,
                                reloadGrid: false,
                                dayTranslation: dayPrefix,
                            })
                        );
                    },
                    clearAll: () => {
                        dispatch(resetAllFilters());
                        dispatch(fetchGridData({ keycloak, errorCallback, dayTranslation: dayPrefix }));
                    },
                }}
                // For actions specific to ADMIN
                adminActions={[
                    {
                        title: t('invites.dataGrid.adminActions.sendMail'),
                        onClick: (id) => {
                            sendInviteMail(keycloak, id?.split(uniqueSeperator)[0])
                                .then(() => {
                                    enqueueSnackbar(t('invites.dataGrid.adminActionResult.sendMailSuccess'), {
                                        variant: 'success',
                                    });
                                })
                                .catch(() => {
                                    enqueueSnackbar(t('invites.dataGrid.adminActionResult.sendMailError'), {
                                        variant: 'error',
                                    });
                                });
                        },
                    },
                    {
                        title: t('invites.dataGrid.adminActions.removeRecord'),
                        onClick: (id) => clickRemoveInvite(id?.split(uniqueSeperator)[0]),
                        type: 'DANGER',
                    },
                ]}
            />
            {rows && rows.length > 0 && (
                <GridPagination controlled {...pagination} handleChange={gridPageChangeHandler} />
            )}
            {isAdmin && (
                <ConfirmDialog
                    open={dialogState.open}
                    icon={<InfoIcon fontSize="large" />}
                    title={t('invites.dataGrid.dialog.removeTitle')}
                    injectHtml
                    content={t('invites.dataGrid.dialog.removeContent')}
                    labelCancel={t('invites.dataGrid.dialog.cancel')}
                    labelConfirm={t('invites.dataGrid.dialog.confirm')}
                    onCancel={dialogCancelHandler}
                    onConfirm={dialogConfirmedHandler}
                    confirmDisabled={hasActiveRequest}
                    cancelDisabled={hasActiveRequest}
                />
            )}
        </div>
    );
};

export default Invites;
