import { makeStyles } from '@material-ui/core/styles';
import { poppins500_14px } from 'fintech/components/style/common';

export const useStyles = makeStyles({
    EventDetailContentGrid: {
        padding: '0px 16px 0px 16px',
        marginBottom: 20,
    },
    EventDetailContentImage: {
        objectFit: 'contain',
        width: '100%',
        height: '100%',
        borderRadius: 8,
        cursor: 'pointer',
    },
    EventDetailModalImage: {
        outline: 'none',
        maxWidth: '100%',
    },
    EventDetailContent: {
        paddingRight: '44px',
        whiteSpace: 'pre-wrap',
        ...poppins500_14px,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
