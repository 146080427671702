import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useKeycloak } from '@react-keycloak/web';
import VerticalUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/VerticalsUpdate';
import TechnologyUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/TechnologyUpdate';
import StagesUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/StagesUpdate';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import { hasRoles } from 'fintech/utils/Auth';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';
import { DialogContent } from '@material-ui/core';

export default function KeywordsUpdateActionModal({
    changeCallback,
    formIsSubmitted,
    fetchedVerticals,
    fetchedTechnologies,
    fetchedStages,
    verticalsLoading,
    verticalsError,
    techsLoading,
    techsError,
    stagesLoading,
    stagesError,
}) {
    const { keycloak } = useKeycloak();

    const [keywordsData, setKeywordsData] = useState({
        technologies: [],
        verticals: [],
        stages: [],
    });

    useEffect(() => {
        setKeywordsData({
            verticals: fetchedVerticals.verticals,
            technologies: fetchedTechnologies.technologies,
            stages: fetchedStages.stages,
        });
    }, [fetchedVerticals, fetchedTechnologies, fetchedStages]);

    const changeVerticals = (verticals, valid) => {
        setKeywordsData((prevState) => ({
            ...prevState,
            verticals: verticals,
        }));
        const tmpStages = filterObjectList(keywordsData.stages, 'selected', [true]);
        const updateData = {
            verticals: filterObjectList(keywordsData.verticals, 'selected', [true]),
            technologies: filterObjectList(keywordsData.technologies, 'selected', [true]),
            stageData: tmpStages ? tmpStages[0] : {},
            verticalsValid: valid,
        };
        changeCallback(updateData);
    };
    const changeTechnologies = (technologies, valid) => {
        setKeywordsData((prevState) => ({
            ...prevState,
            technologies: technologies,
        }));
        const tmpStages = filterObjectList(keywordsData.stages, 'selected', [true]);
        const updateData = {
            verticals: filterObjectList(keywordsData.verticals, 'selected', [true]),
            technologies: filterObjectList(keywordsData.technologies, 'selected', [true]),
            stageData: tmpStages ? tmpStages[0] : {},
            technologiesValid: valid,
        };
        changeCallback(updateData);
    };
    const changeStage = (stages, valid) => {
        setKeywordsData((prevState) => ({
            ...prevState,
            stages: stages,
        }));
        const tmpStages = filterObjectList(keywordsData.stages, 'selected', [true]);
        const updateData = {
            verticals: filterObjectList(keywordsData.verticals, 'selected', [true]),
            technologies: filterObjectList(keywordsData.technologies, 'selected', [true]),
            stageData: tmpStages ? tmpStages[0] : {},
            stageValid: valid,
        };
        changeCallback(updateData, valid);
    };
    return (
        <div className={''}>
            <Grid container direction={'row'}>
                <DialogContent className={'full-width padding-top-8'}>
                    <VerticalUpdate
                        verticals={keywordsData.verticals}
                        isLoading={verticalsLoading}
                        isError={verticalsError}
                        changeCallback={(data, valid) => changeVerticals(data, valid)}
                        formIsSubmitted={formIsSubmitted}
                        validateOnBlur={true}
                    />
                </DialogContent>
                <DialogContent className={'full-width'}>
                    <TechnologyUpdate
                        technologies={keywordsData.technologies}
                        isLoading={techsLoading}
                        isError={techsError}
                        changeCallback={(data, valid) => changeTechnologies(data, valid)}
                        formIsSubmitted={formIsSubmitted}
                        validateOnBlur={true}
                    />
                </DialogContent>
                {hasRoles(keycloak, ROLE_ADMIN) && (
                    <DialogContent className={'full-width'}>
                        <StagesUpdate
                            stages={keywordsData.stages}
                            isLoading={stagesLoading}
                            isError={stagesError}
                            changeCallback={(data, valid) => changeStage(data, valid)}
                            formIsSubmitted={formIsSubmitted}
                            validateOnBlur={true}
                        />
                    </DialogContent>
                )}
            </Grid>
        </div>
    );
}
