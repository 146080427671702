import React, { useRef, useState, useEffect } from 'react';
import {
    Avatar,
    Box,
    ClickAwayListener,
    Grid,
    Grow,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRowClickListener, useStickyTableHeaderPositioning } from 'fintech/components/table/StickyTable/hooks';
import { FavoriteBorder, FavoriteOutlined, LocalOffer } from '@material-ui/icons';
import { IconsMultipleProfil, IconsSingleMsortDown, IconsSingleMsortUp } from 'fintech/icons';
import withPreventPropagation from 'fintech/hoc/withPreventPropagation';

import { getPublicFileUrl } from 'fintech/api/fileService';

import SplitButton from 'fintech/components/ui/button/SplitButton';
import { StyledTag } from 'fintech/components/ui/button/common';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import PopperWrapper from 'fintech/components/util/PopperWrapper';

import { Cell, Row, StickyTable } from 'react-sticky-table';
import useStyles, {
    ARROW_COLOR,
    tableFavoriteRowStyle,
    tableHeaderStyle,
    tableLeftPaddingHeaderStyle,
    tableRowStyle,
    tableStatusRowStyle,
    tableTagRowStyle,
    tagHyphenStyle,
} from 'fintech/components/table/StickyTable/index.style';

import PlaceholdersInvestor from 'fintech/static/images/placeholders-yatirimda-bulunan.png';
import PlaceholdersIdea from 'fintech/static/images/placeholders-idea3x.png';
import PlaceholdersStartup from 'fintech/static/images/placeholders-startup3x.png';
import PlaceholdersPerson from 'fintech/static/images/placeholder-kisi.png';
import { isEmpty, isNil, keys } from 'lodash';
import MoreButton from 'fintech/components/ui/button/MoreButton';
import { uniqueSeperator } from 'fintech/constants';
import { StyledListItemIcon, StyledListItemTypo } from 'fintech/components/ui/button/SplitButton/index.style';
import SvgIconsSingleArrowRight from 'fintech/icons/IconsSingleArrowRight';
import {
    COLOR_DANGER,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    fontStyle,
} from 'fintech/components/style/common';
import ActionModal from 'fintech/components/util/ActionModal';
import * as constants from 'fintech/components/util/ActionModalConstants';
import { tagsModalStyle } from './index.style';
import useTableScroll from './hooks/useTableScroll';
import { VENTURE_TYPES } from 'fintech/store/actions/ActionTypes';
import SvgIconsSingleClose4 from 'fintech/icons/IconsSingleClose4';
import useWindowSize from 'fintech/hooks/useWindowSize';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import useLongPress from 'fintech/hooks/useLongPress';
import { defaultScrollOptions, sideScroll } from 'fintech/utils/DataGridUtils';
import { DEF_SPEED, DEF_STEP_CONT, DIRECTION_LEFT } from 'fintech/components/util/DataGridContants';
import ChevronLeft from 'fintech/static/images/icons-single-chevron-left.svg';
import ChevronRight from 'fintech/static/images/icons-single-chevron-right.svg';

const getHeaderComponent = ({
    header,
    styles,
    type,
    buttonActions,
    t,
    index,
    leftStickyColumnCount,
    isScrollable,
    leftScroll,
    rightScroll,
    rightScrollButtonLeftParam,
}) => {
    const { id, type: headerType, value, style, status } = header;

    const isLastStickyColumn = leftStickyColumnCount === index + 1;
    const isFirstColumn = index === 0;

    const leftScrollButton = isScrollable && isLastStickyColumn && (
        <ShrinkingButton
            id="left-scroll-button"
            style={{ display: 'none' }}
            className={styles.leftScrollButton}
            alwaysShrink
            shrinkedIcon={<img src={ChevronLeft} />}
            {...leftScroll}
        />
    );
    const rightScrollButton = isScrollable && isFirstColumn && (
        <ShrinkingButton
            id="right-scroll-button"
            className={styles.rightScrollButton}
            style={{ left: rightScrollButtonLeftParam }}
            alwaysShrink
            shrinkedIcon={<img src={ChevronRight} />}
            {...rightScroll}
        />
    );

    switch (headerType) {
        case 'label-actions':
            return (
                <Cell
                    key={index}
                    style={{
                        ...tableHeaderStyle,
                        minWidth: null,
                        borderRight: 'none',
                        borderLeft: 'solid 1px ' + COLOR_PRIMARY_SHADE_03,
                        ...style,
                    }}
                >
                    <Box className={styles.headerLabel} display="flex" justifyContent="center">
                        {rightScrollButton}
                        {type === 'entrepreneur' ? t(`${type}.${value.title}`) : `${value}`}
                        {leftScrollButton}
                    </Box>
                </Cell>
            );
        case 'label-status':
            return (
                <Cell
                    key={index}
                    style={{
                        ...tableHeaderStyle,
                        textAlign: 'start',
                        minWidth: null,
                        borderLeft: index === 0 ? 'solid 1px ' + COLOR_PRIMARY_SHADE_03 : 'none',
                        ...style,
                    }}
                >
                    <Box className={styles.headerLabel} display="flex" justifyContent="flex-start">
                        {rightScrollButton}
                        {type === 'entrepreneur' ? t(`${type}.${value.title}`) : `${value}`}
                        {leftScrollButton}
                    </Box>
                </Cell>
            );
        case 'label-favorite':
            return (
                <Cell
                    key={index}
                    style={{
                        ...tableHeaderStyle,
                        borderRight: 'none',
                        borderLeft: 'solid 1px ' + COLOR_PRIMARY_SHADE_03,
                        ...style,
                    }}
                >
                    <Box className={styles.headerLabel} display="flex" justifyContent="center">
                        {rightScrollButton}
                        {type === 'entrepreneur' || type === 'startup.similarVentures'
                            ? t(`${type}.${value.title}`)
                            : `${value}`}
                        {leftScrollButton}
                    </Box>
                </Cell>
            );
        case 'label-actiontext':
            return (
                <Cell
                    key={index}
                    style={{
                        ...tableHeaderStyle,
                        textAlign: 'start',
                        minWidth: null,
                        ...style,
                    }}
                >
                    <Box className={styles.headerLabel} display="flex" justifyContent="flex-start">
                        {rightScrollButton}
                        {type === 'entrepreneur' ? t(`${type}.${value.title}`) : `${value}`}
                        {leftScrollButton}
                    </Box>
                </Cell>
            );
        case 'label':
            // FIXME generalize translation logic
            return (
                <Cell
                    key={index}
                    style={{
                        ...tableLeftPaddingHeaderStyle,
                        borderLeft: index === 0 ? 'solid 1px ' + COLOR_PRIMARY_SHADE_03 : 'none',
                        ...style,
                    }}
                >
                    <Box className={styles.headerLabel} display="flex" justifyContent="flex-start">
                        {rightScrollButton}
                        {type === 'entrepreneur' || type === 'startup.similarVentures'
                            ? t(`${type}.${value.title}`)
                            : `${value}`}
                        {leftScrollButton}
                    </Box>
                </Cell>
            );
        case 'sortLabel':
            return (
                <Cell
                    key={index}
                    style={{
                        ...tableLeftPaddingHeaderStyle,
                        borderLeft: index === 0 ? 'solid 1px ' + COLOR_PRIMARY_SHADE_03 : 'none',
                        ...style,
                    }}
                >
                    <Box display="flex" justifyContent="flex-start" alignItems="center" mr={2}>
                        {rightScrollButton}
                        <span
                            id={`sortLabelSpan-${id}`}
                            className={styles.sortLabelSpan}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                const arrowContainer = e.target.closest('span');
                                if (arrowContainer && arrowContainer.id) {
                                    const id = arrowContainer.id.split('-')[1];
                                    buttonActions.sort(id);
                                }
                            }}
                        >
                            {' '}
                            {type === 'entrepreneur' || type === 'startup.similarVentures'
                                ? t(`${type}.${value.title}`)
                                : `${value.title}`}
                        </span>
                        <Box
                            id={`sortLabel-${id}`}
                            className={styles.arrowContainer}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            onClick={(e) => {
                                const arrowContainer = e.target.closest('div');
                                if (arrowContainer && arrowContainer.id) {
                                    const id = arrowContainer.id.split('-')[1];
                                    buttonActions.sort(id);
                                }
                            }}
                        >
                            {/* status for UserSearch */}
                            <IconsSingleMsortUp
                                color={
                                    status === 'ASC' || value.status === 'ASC'
                                        ? ARROW_COLOR.selected
                                        : ARROW_COLOR.unselected
                                }
                                className={styles.upArrow}
                            ></IconsSingleMsortUp>
                            <IconsSingleMsortDown
                                color={
                                    status === 'DESC' || value.status === 'DESC'
                                        ? ARROW_COLOR.selected
                                        : ARROW_COLOR.unselected
                                }
                                className={styles.downArrow}
                            ></IconsSingleMsortDown>
                        </Box>
                        {leftScrollButton}
                    </Box>
                </Cell>
            );
        case 'sortLabel-shrinked':
            return (
                <Cell
                    key={index}
                    style={{
                        ...tableHeaderStyle,
                        borderLeft: index === 0 ? 'solid 1px ' + COLOR_PRIMARY_SHADE_03 : 'none',
                        ...style,
                    }}
                >
                    <Box display="flex" justifyContent="flex-start" alignItems="center" mr={2}>
                        {rightScrollButton}
                        <span
                            id={`sortLabelSpan-${id}`}
                            className={styles.sortLabelSpan}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                const arrowContainer = e.target.closest('span');
                                if (arrowContainer && arrowContainer.id) {
                                    const id = arrowContainer.id.split('-')[1];
                                    buttonActions.sort(id);
                                }
                            }}
                        >
                            {' '}
                            {type === 'entrepreneur' || type === 'startup.similarVentures'
                                ? t(`${type}.${value.title}`)
                                : `${value.title}`}
                        </span>
                        <Box
                            id={`sortLabel-${id}`}
                            className={styles.arrowContainer}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            onClick={(e) => {
                                const arrowContainer = e.target.closest('div');
                                if (arrowContainer && arrowContainer.id) {
                                    const id = arrowContainer.id.split('-')[1];
                                    buttonActions.sort(id);
                                }
                            }}
                        >
                            <IconsSingleMsortUp
                                color={value.status === 'ASC' ? ARROW_COLOR.selected : ARROW_COLOR.unselected}
                                className={styles.upArrow}
                            ></IconsSingleMsortUp>
                            <IconsSingleMsortDown
                                color={value.status === 'DESC' ? ARROW_COLOR.selected : ARROW_COLOR.unselected}
                                className={styles.downArrow}
                            ></IconsSingleMsortDown>
                        </Box>
                        {leftScrollButton}
                    </Box>
                </Cell>
            );
        default:
            return (
                <Cell
                    key={index}
                    style={{
                        ...tableHeaderStyle,
                        ...style,
                    }}
                >
                    {rightScrollButton}
                    {leftScrollButton}
                </Cell>
            );
    }
};
const getRowComponent = (
    row,
    index,
    subIndex,
    buttonActions,
    adminActions,
    styles,
    t,
    rowRef,
    handleActionsToggle,
    handeTagExpand
) => {
    const { type, title, value, style = {} } = row;

    // For 'venture', 'ventureFavorite', 'user' & 'investor'
    const hasLogo = value?.logoFile?.uuid ? true : false;
    let opts = [];

    switch (type) {
        case 'text':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableRowStyle}>
                    <Box
                        className={`${styles.fullWidthAndHeightFlexContainer} ${styles.defaultLabel}`}
                        style={{ ...style }}
                    >
                        {value.label ? value.label : '-'}
                    </Box>
                </Cell>
            );
        case 'text-centered':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                    <Box className={`${styles.fullWidthAndHeightFlexCenteredContainer} ${styles.defaultLabel}`}>
                        {value.label ? value.label : '-'}
                    </Box>
                </Cell>
            );
        case 'textmargin':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                    <Box
                        className={`${styles.fullWidthAndHeightFlexContainer} ${styles.defaultLabel}`}
                        style={{
                            marginLeft: '10px',
                            ...fontStyle,
                            wordBreak: 'break-word',
                            ...style,
                        }}
                    >
                        <PopperWrapper showPopper={!isNil(value.label)} content={value.label}>
                            <Box className={`${styles.investorTypeColumnTextWrapper} ${styles.defaultLabel}`}>
                                <span>{value.label}</span>
                            </Box>
                        </PopperWrapper>
                    </Box>
                </Cell>
            );
        case 'role-multiline':
            if (value && value.length > 0) {
                const roles = value?.map((role, idx) => {
                    const customStyle = idx > 0 ? { marginTop: '4px', marginRight: '8px' } : { marginRight: '8px' };
                    return (
                        <Grid item key={idx} style={customStyle}>
                            <span style={{ ...fontStyle, textAlign: 'center' }}>{role}</span>
                        </Grid>
                    );
                });
                return (
                    <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                        {withPreventPropagation(
                            <Grid
                                style={{ marginLeft: '12px', ...style }}
                                container
                                direction="row"
                                alignItems="center"
                            >
                                <TagGroup
                                    isGridItem
                                    limit={1}
                                    items={roles}
                                    preventDefault
                                    onClick={() => handeTagExpand({ title, tags: roles, notTag: true })}
                                ></TagGroup>
                            </Grid>
                        )}
                    </Cell>
                );
            } else {
                return (
                    <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                        <Box
                            style={{ marginLeft: '12px', ...style }}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            -
                        </Box>
                    </Cell>
                );
            }
        case 'venture':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                    <Box
                        className={styles.paddingLeftVenture}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Avatar
                            className={styles.avatar}
                            src={getPublicFileUrl(value.logoFile?.uuid)}
                        >
                            <Avatar
                                style={{ marginRight: '0' }}
                                className={styles.avatarPlaceholder}
                                src={[VENTURE_TYPES.IDEA, VENTURE_TYPES.BACKED_IDEA].includes(value.ventureType)
                                    ? PlaceholdersIdea
                                    : PlaceholdersStartup} />
                        </Avatar>
                        <PopperWrapper showPopper={!isNil(value.label)} content={value.label}>
                            <Box className={styles.ventureColumnTextWrapper}>
                                <Typography className={styles.ventureColumnText} noWrap>
                                    {value.label}
                                </Typography>
                            </Box>
                        </PopperWrapper>
                    </Box>
                </Cell>
            );
        case 'ventureFavorite':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableFavoriteRowStyle}>
                    <Box
                        className={styles.paddingLeftVentureFavourite}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Avatar
                            className={styles.avatar}
                            src={getPublicFileUrl(value.logoFile?.uuid)}
                        >
                            <Avatar
                                style={{ marginRight: '0' }}
                                className={styles.avatarPlaceholder}
                                src={[VENTURE_TYPES.IDEA, VENTURE_TYPES.BACKED_IDEA].includes(value.ventureType)
                                    ? PlaceholdersIdea
                                    : PlaceholdersStartup}
                            />
                        </Avatar>
                        <PopperWrapper showPopper={!isNil(value.label)} content={value.label}>
                            <Box className={styles.ventureColumnTextWrapper}>
                                <Typography className={styles.ventureColumnText} noWrap>
                                    {value.label}
                                </Typography>
                            </Box>
                        </PopperWrapper>
                    </Box>
                </Cell>
            );
        case 'favorite':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={{ ...tableTagRowStyle, borderRight: 'none' }}>
                    <Box className={styles.fullWidthAndHeightFlexContainer}>
                        {withPreventPropagation(
                            <IconButton
                                size="small"
                                onClick={() => {
                                    if (value.isFavourite) {
                                        buttonActions.unfavouriteCompany(value, index, subIndex);
                                    } else {
                                        buttonActions.favouriteCompany(value, index, subIndex);
                                    }
                                }}
                            >
                                {value.isFavourite ? (
                                    <FavoriteOutlined fontSize="small" style={{ color: '#DB5B5B' }} />
                                ) : (
                                    <FavoriteBorder fontSize="small" />
                                )}
                            </IconButton>
                        )}
                    </Box>
                </Cell>
            );
        case 'investor':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                    <Box
                        style={{
                            marginLeft: '12px',
                        }}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Avatar
                            style={{
                                marginRight: '0.5rem',
                            }}
                            src={value.logoFile ? getPublicFileUrl(value.logoFile?.uuid) : PlaceholdersInvestor}
                        >
                            <Avatar src={PlaceholdersInvestor} />
                        </Avatar>
                        <PopperWrapper showPopper={!isNil(value.label)} content={value.label}>
                            <Box className={styles.investorColumnTextWrapper}>
                                <Typography className={styles.ventureColumnText} noWrap>
                                    {value.label}
                                </Typography>
                            </Box>
                        </PopperWrapper>
                    </Box>
                </Cell>
            );
        case 'user':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                    <Box
                        style={{
                            marginLeft: '12px',
                            ...style,
                        }}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Avatar
                            className={styles.avatar}
                            style={{
                                marginRight: '0.5rem',
                            }}
                            src={getPublicFileUrl(value.logoFile?.uuid)}
                        >
                            <Avatar
                                className={styles.avatarPlaceholder}
                                style={{
                                    marginRight: '0',
                                }}
                                src={PlaceholdersPerson}
                            />
                        </Avatar>
                        <PopperWrapper showPopper={!isNil(value.label)} content={value.label}>
                            <Box className={styles.userColumnTextWrapper}>
                                <Typography className={styles.ventureColumnText} noWrap>
                                    {value.label}
                                </Typography>
                            </Box>
                        </PopperWrapper>
                    </Box>
                </Cell>
            );
        case 'tag':
            return isNil(value.label) ? (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                    <Box
                        className={`${styles.fullWidthAndHeightFlexContainer} ${styles.defaultLabel}`}
                        style={tagHyphenStyle}
                    >
                        -
                    </Box>
                </Cell>
            ) : (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                    {withPreventPropagation(
                        <Box display="flex" flexDirection="column" justifyContent="center">
                            <StyledTag
                                disableElevation
                                disableRipple
                                disableTouchRipple
                                key={`tag-${value.label}`}
                                startIcon={<LocalOffer fontSize="small" />}
                            >
                                {value.label}
                            </StyledTag>
                        </Box>
                    )}
                </Cell>
            );
        case 'tagGroup':
            if (value && value.length > 0) {
                const techs = value?.map((tech, ind) => {
                    const customStyle = ind === 0 ? { marginTop: 0 } : null;
                    return (
                        <Grid item key={tech.id}>
                            <StyledTag
                                style={customStyle}
                                disableElevation
                                disableRipple
                                disableTouchRipple
                                startIcon={<LocalOffer fontSize="small" />}
                            >
                                {tech.name}
                            </StyledTag>
                        </Grid>
                    );
                });
                return (
                    <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                        {withPreventPropagation(
                            <Box display="flex" flexDirection="row" style={style}>
                                <TagGroup
                                    isGridItem
                                    limit={1}
                                    items={techs}
                                    preventDefault
                                    onClick={() => handeTagExpand({ title, tags: techs })}
                                ></TagGroup>
                            </Box>
                        )}
                    </Cell>
                );
            } else {
                return (
                    <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                        <Box
                            className={`${styles.fullWidthAndHeightFlexContainer} ${styles.defaultLabel}`}
                            style={{ ...tagHyphenStyle, ...style }}
                        >
                            -
                        </Box>
                    </Cell>
                );
            }
        case 'verticalGroup':
            if (value && keys(value).length > 0) {
                const verts = keys(value)?.map((vert, idx) => {
                    const groupStyle = idx > 0 ? { marginTop: '11px' } : null;
                    return (
                        <Grid item key={idx}>
                            <SplitButton groupStyle={groupStyle} option={vert} subOptions={value[vert]} />
                        </Grid>
                    );
                });
                return (
                    <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                        {withPreventPropagation(
                            <Box display="flex" flexDirection="row" alignItems="flex-start" style={style}>
                                <TagGroup
                                    isGridItem
                                    limit={1}
                                    items={verts}
                                    preventDefault
                                    onClick={() => handeTagExpand({ title, tags: verts })}
                                />
                            </Box>
                        )}
                    </Cell>
                );
            } else {
                return (
                    <Cell key={`row-component-${index}-${subIndex}`} style={tableTagRowStyle}>
                        <Box
                            className={`${styles.fullWidthAndHeightFlexContainer} ${styles.defaultLabel}`}
                            style={{ ...tagHyphenStyle, ...style }}
                        >
                            -
                        </Box>
                    </Cell>
                );
            }
        case 'status':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableStatusRowStyle}>
                    <Box className={`${styles.fullWidthAndHeightFlexContainer} ${styles.defaultLabel}`}>
                        {!isNil(value) ? t(`form.${value.toLowerCase()}`) : '-'}
                    </Box>
                </Cell>
            );
        case 'actions':
            value.forEach((act, idx) => {
                if (!act.disabled && adminActions[idx]) {
                    opts = [
                        ...opts,
                        {
                            ...adminActions[idx],
                            id: `${act.id}${uniqueSeperator}${idx}`,
                            name: act.name,
                        },
                    ];
                }
            });
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={{ ...tableTagRowStyle, borderRight: 'none' }}>
                    {!isEmpty(opts) ? (
                        <Box className={styles.fullWidthAndHeightFlexCenteredContainer}>
                            {withPreventPropagation(
                                <MoreButton
                                    preventDefault
                                    onButtonClick={(event) => {
                                        handleActionsToggle(event, opts);
                                    }}
                                />
                            )}
                        </Box>
                    ) : (
                        <Box className={styles.fullWidthAndHeightFlexCenteredContainer}>
                            {withPreventPropagation(<MoreButton disabled />)}
                        </Box>
                    )}
                </Cell>
            );
        case 'adminPanel':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={{ ...tableTagRowStyle, borderRight: 'none' }}>
                    {!isEmpty(value.statusOptions) ? (
                        <Box className={styles.fullWidthAndHeightFlexContainer}>
                            {withPreventPropagation(
                                <MoreButton
                                    preventDefault
                                    onButtonClick={(event) => {
                                        handleActionsToggle(
                                            event,
                                            value.statusOptions.map((element) => {
                                                return {
                                                    ...element,
                                                    title: t(element.title),
                                                    onClick: buttonActions.admin[element.name].bind(
                                                        this,
                                                        value.uuid,
                                                        value.name
                                                    ),
                                                };
                                            })
                                        );
                                    }}
                                />
                            )}
                        </Box>
                    ) : (
                        <Box className={styles.fullWidthAndHeightFlexContainer}>
                            {withPreventPropagation(<MoreButton disabled />)}
                        </Box>
                    )}
                </Cell>
            );
        case 'placeholder-name-surname':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableRowStyle}>
                    <Box
                        className={styles.alingVerticalNameSurname}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <span className={'margin-right-10'}>
                            <IconsMultipleProfil />
                        </span>
                        <PopperWrapper showPopper={!isNil(value.label)} content={value.label}>
                            <Box className={styles.nameSurnameTextWrapper}>
                                <Typography className={styles.nameSurnameText} noWrap>
                                    {value.label ? value.label : '-'}
                                </Typography>
                            </Box>
                        </PopperWrapper>
                    </Box>
                </Cell>
            );
        case 'component':
            return (
                <Cell key={`row-component-${index}-${subIndex}`} style={tableRowStyle}>
                    <Box
                        className={`${styles.fullWidthAndHeightFlexContainer} ${styles.defaultLabel}`}
                        style={{ ...style }}
                    >
                        {row.component}
                    </Box>
                </Cell>
            );
        default:
            break;
    }
};

const _StickyTable = (props) => {
    const {
        containerClass,
        rightScrollButtonRightMargin = 47,
        headers,
        rows,
        buttonActions,
        adminActions,
        type,
        stickyHeaderCount = 1,
        leftStickyColumnCount = 1,
        disableStickyHeader = false,
        rowHeight = 82,
    } = props;
    const isDataFetched = headers && rows;
    const { t } = useTranslation(type);

    useRowClickListener({ rowClickListener: buttonActions.handleRowClick, rows });
    useStickyTableHeaderPositioning({ disableStickyHeader, rows });
    const rowRef = useRef();

    /* Handle tags */
    const [showTagsModal, setShowTagsModal] = useState(false);
    const [title, setTitle] = useState('');
    const [tags, setTags] = useState([]);
    const defaultLimit = 8;
    const textLimit = 11;
    const [tagLimit, setTagLimit] = useState(defaultLimit);

    const onTagExpand = ({ title, tags, notTag = false }) => {
        setTags(tags);
        setTitle(t(title));
        setShowTagsModal(true);
        if (notTag && tagLimit != textLimit) {
            setTagLimit(textLimit);
        } else if (!notTag && tagLimit != defaultLimit) {
            setTagLimit(defaultLimit);
        } else {
            return;
        }
    };

    const onCancel = () => {
        setShowTagsModal(false);
        setTimeout(() => {
            setTitle(t(''));
            setTags([]);
        }, [300]);
    };

    const tagsModalActionHandler = (action) => {
        switch (action.type) {
            case constants.SUBMIT:
                onCancel();
                return;
            case constants.REJECT:
                onCancel();
                return;
            default:
                return;
        }
    };
    /* Handle tags - End */

    const isScrollable = useTableScroll([rows]);
    const styles = useStyles({ showBorder: tags.length > tagLimit, isScrollable });

    /* Handle admin actions render problem - Start */
    const [actionsState, setActionsState] = useState({
        anchorEl: null,
        open: false,
        options: null,
    });

    const handleActionsToggle = (event, options) => {
        const currentTarget = event && event.currentTarget;
        // If same button is clicked, close the current one & open the new one after a small timeout
        if (actionsState.anchorEl && currentTarget && currentTarget !== actionsState.anchorEl) {
            const el = currentTarget;
            handleActionsClose();

            /**
             * Close the previous popper and halt briefly before opening the new one.
             */
            setTimeout(() => {
                setActionsState((prevState) => ({
                    ...prevState,
                    anchorEl: el,
                    open: el ? true : false,
                    options: el ? options : null,
                }));
            }, 100);
        } else if (currentTarget && options) {
            const el = actionsState.anchorEl ? null : currentTarget;
            setActionsState((prevState) => ({
                ...prevState,
                anchorEl: el,
                open: true,
                options: options,
            }));
        } else {
            handleActionsClose();
        }
    };

    const handleActionsClose = () => {
        setActionsState({
            open: false,
            options: null,
        });
    };
    /* Handle admin actions render problem - End */

    /* Handle table scroll */
    const window = useWindowSize();
    const tableWrapperRef = useRef(null);

    const [longScrollLeft, setLongScrollLeft] = useState(false);
    const [longScrollRight, setLongScrollRight] = useState(false);
    const [rightScrollButtonLeftParam, setRightScrollButtonLeftParam] = useState(0);

    const moveRightScrollButtonToRightSide = () => {
        const tableContainer = document.getElementById('sticky-table-custom-container');
        if (tableContainer) {
            const tableWidth = tableContainer.clientWidth;
            setRightScrollButtonLeftParam(tableWidth - rightScrollButtonRightMargin);
        }
    };

    useEffect(() => {
        moveRightScrollButtonToRightSide();
        setTimeout(() => {
            moveRightScrollButtonToRightSide();
        }, 1000);
    }, [window]);

    const afterScrollCallback = () => {
        const scrollLeftValue = tableWrapperRef.current.scrollLeft;
        const isLeftScrollable = scrollLeftValue > 0;
        document.getElementById('left-scroll-button').style.display = isLeftScrollable ? 'block' : 'none';
        const scrollRightValue =
            tableWrapperRef.current.scrollWidth - tableWrapperRef.current.offsetWidth - scrollLeftValue;
        const isRightScrollable = scrollRightValue > 0;
        document.getElementById('right-scroll-button').style.display = isRightScrollable ? 'block' : 'none';
        // if we manage these buttons' display styles using setState, then scrolling operations get spoiled,
        // so we manage them through dom by hand
    };

    const leftScroll = useLongPress({
        onLongPress: () => {
            setLongScrollLeft(true);
        },
        onClick: () => {
            sideScroll({
                element: tableWrapperRef?.current,
                direction: DIRECTION_LEFT,
                callback: afterScrollCallback,
            });
        },
        onMouseLeave: () => setLongScrollLeft(false),
        onMouseUp: () => setLongScrollLeft(false),
        onTouchEnd: () => setLongScrollLeft(false),
        ...defaultScrollOptions,
    });

    const rightScroll = useLongPress({
        onLongPress: () => {
            setLongScrollRight(true);
        },
        onClick: () => {
            sideScroll({
                element: tableWrapperRef?.current,
                callback: afterScrollCallback,
            });
        },
        onMouseLeave: () => setLongScrollRight(false),
        onMouseUp: () => setLongScrollRight(false),
        onTouchEnd: () => setLongScrollRight(false),
        ...defaultScrollOptions,
    });

    useEffect(() => {
        if (longScrollLeft && tableWrapperRef) {
            const slideTimer = setInterval(function () {
                tableWrapperRef.current.scrollLeft -= DEF_SPEED;
            }, DEF_STEP_CONT);

            return () => {
                clearInterval(slideTimer);
                afterScrollCallback();
            };
        }
    }, [longScrollLeft]);

    useEffect(() => {
        if (longScrollRight && tableWrapperRef) {
            const slideTimer = setInterval(function () {
                tableWrapperRef.current.scrollLeft += DEF_SPEED;
            }, DEF_STEP_CONT);

            return () => {
                clearInterval(slideTimer);
                afterScrollCallback();
            };
        }
    }, [longScrollRight]);
    /* Handle table scroll - end */

    return isDataFetched ? (
        <>
            <div
                id="search-data-grid-wrapper"
                ref={tableWrapperRef}
                className={containerClass}
                onScroll={afterScrollCallback}
            >
                <Box id="sticky-table-custom-container" className={styles.stickyTableContainer}>
                    <StickyTable
                        stickyHeaderCount={stickyHeaderCount}
                        leftStickyColumnCount={leftStickyColumnCount}
                        borderWidth="1px"
                        borderColor={COLOR_PRIMARY_SHADE_02}
                        style={{ overflow: 'initial' }}
                        leftColumnZ={1}
                    >
                        {/* Render Headers */}
                        <Row id="sticky-table-header">
                            {headers.map((header, index) => {
                                return getHeaderComponent({
                                    header,
                                    styles,
                                    buttonActions,
                                    t,
                                    type,
                                    index,
                                    leftStickyColumnCount,
                                    isScrollable,
                                    leftScroll,
                                    rightScroll,
                                    rightScrollButtonLeftParam,
                                });
                            })}
                        </Row>

                        {rows.map((row, index) => {
                            const rowToParse =
                                type === 'entrepreneur' || type === 'startup.similarVentures' ? row : row.slice(1);
                            return (
                                <Row
                                    style={{ height: `${rowHeight}px` }}
                                    key={index}
                                    id={
                                        type === 'entrepreneur' || type === 'startup.similarVentures'
                                            ? `${row[0].startupUuid}&${row[0].ventureTypeID}`
                                            : `${row[0].id}&`
                                    }
                                >
                                    {rowToParse.map((element, subIndex) => {
                                        return getRowComponent(
                                            element,
                                            index,
                                            subIndex,
                                            buttonActions,
                                            adminActions,
                                            styles,
                                            t,
                                            rowRef,
                                            handleActionsToggle,
                                            onTagExpand
                                        );
                                    })}
                                </Row>
                            );
                        })}
                    </StickyTable>
                </Box>
            </div>
            <Popper
                style={{ zIndex: '10000' }}
                open={actionsState.open}
                anchorEl={actionsState.anchorEl}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => handleActionsToggle()}>
                                <List>
                                    {actionsState.options?.map((opt) => (
                                        <ListItem
                                            id={`faa-${opt.id}`}
                                            key={`faa-${opt.id}`}
                                            className={styles.ListItemButton}
                                            onClick={(e) => {
                                                opt.onClick && opt.onClick(opt.id, opt.name);
                                                handleActionsClose(e);
                                            }}
                                        >
                                            {!opt.type && (
                                                <>
                                                    <StyledListItemIcon style={{ marginRight: '10px' }}>
                                                        <SvgIconsSingleArrowRight />
                                                    </StyledListItemIcon>
                                                    <ListItemText>
                                                        <StyledListItemTypo>{opt.title} </StyledListItemTypo>
                                                    </ListItemText>
                                                </>
                                            )}
                                            {opt.type && opt.type === 'DANGER' && (
                                                <>
                                                    <StyledListItemIcon
                                                        style={{ marginRight: '10px', color: COLOR_DANGER }}
                                                    >
                                                        <SvgIconsSingleClose4 />
                                                    </StyledListItemIcon>
                                                    <ListItemText>
                                                        <StyledListItemTypo style={{ color: COLOR_DANGER }}>
                                                            {opt.title}
                                                        </StyledListItemTypo>
                                                    </ListItemText>
                                                </>
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <ActionModal
                titleMarginBottom30={false}
                titleContainerClass={`sticky-table-tag-modal ${styles.modalTitle}`}
                tabsContainerClass={'padding-bottom-26 border-bottom'}
                modalStyle={tagsModalStyle}
                dialogActionsStyle={{
                    borderTop: tags.length > tagLimit ? '1px solid #dee2e6' : null,
                    height: '44px',
                }}
                open={showTagsModal}
                okClickHidden
                overrideTitle
                title={title}
                okButtonLabel={t('dialog.close')}
                onAction={tagsModalActionHandler}
                showCancelOnTop
            >
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    {tags}
                </Grid>
            </ActionModal>
        </>
    ) : null;
};

export default _StickyTable;
