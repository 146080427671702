import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    boxMobile: {
        height: 12,
    },
    boxWeb: {
        height: 6,
    },
}));

export default useStyles;
