import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import {
    DOCUMENT_MIMES_START,
    FILE_TYPE_ERROR,
    IMAGE_MIMES_START,
    VIDEO_MIMES_START,
} from 'fintech/components/util/FileConstants';
import BackupIcon from '@material-ui/icons/Backup';
import ProgressBar from 'fintech/components/profile/common/ProgressBar';
import * as FileServerService from 'api/fileService';
import { useKeycloak } from '@react-keycloak/web';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { Switch, withStyles, useMediaQuery } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { COLOR_SECONDARY_BASE, COLOR_SECONDARY_SHADE_01, laptopLargeMax } from '../style/common';
import { getMegaByteToByte } from 'fintech/utils/FileUtils';

const imageUploadMegaByteLimit = 10;
const videoUploadMegaByteLimit = 300;
const documentUploadMegaByteLimit = 10;

const imageUploadLimit = getMegaByteToByte(imageUploadMegaByteLimit);
const videoUploadLimit = getMegaByteToByte(videoUploadMegaByteLimit);
const documentUploadLimit = getMegaByteToByte(documentUploadMegaByteLimit);

async function uploadFile(file, keycloak, progressCallback, isPublic) {
    const formData = new FormData();
    formData.append('uploaded_file', file);
    formData.append('isPrivate', '0');

    let response = await FileServerService.uploadFileWithPercentage(
        keycloak,
        formData,
        file.name,
        progressCallback,
        isPublic
    );
    return response;
}

const CustomSwitch = withStyles({
    switchBase: {
        color: '#98a3ac',
        '&$checked': {
            color: '#e6ebfa',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#5b7adb',
            },
        },
    },
    checked: {
        opacity: 1,
    },
    track: {
        backgroundColor: '#ffffff',
        border: '1px solid #98a3ac',
        opacity: 1,
    },
})(Switch);

export default function FileUpload(props) {
    const { t } = useTranslation();
    const {
        uploadCallback,
        fileLimit,
        videoLimit,
        imageLimit,
        documentLimit,
        acceptMimeTypes,
        initFiles,
        hasNoHorizontalMargin = false,
        hasNoHorizontalMarginInner = false,
        areDocuments = false,
        isPublic = false,
        featureEnabled = true,
        styleClass = '',
        fileLabel = t('idea.upload.fileName'),
        multiple = true,
    } = props;

    const { keycloak } = useKeycloak();
    const isDevice = useMediaQuery(laptopLargeMax);

    const { enqueueSnackbar } = useSnackbar();
    const [files, setFiles] = useState(initFiles || null);
    if (initFiles && files !== initFiles && files == null) {
        setFiles(initFiles);
    }

    const [enableProgressBar, setEnableProgressBar] = useState(false);
    const [disabledDropzoneArea, setDisabledDropzoneArea] = useState(false);
    const [featureSwitchStates, setFeatureSwitchStates] = useState(null);
    const [uploadingFileName, setUploadingFileName] = useState('');
    const [progressPercentage, setProgressPercentage] = useState(0);
    const handleClickVariant = (variant, text) => {
        enqueueSnackbar(text, { variant });
    };

    const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
        multiple,
        accept: acceptMimeTypes,
        maxFiles: fileLimit,
        disabled: disabledDropzoneArea,
    });

    const handleProgressCallback = (receivedPercentage) => {
        setProgressPercentage(receivedPercentage);
    };

    const successCase = () => {
        setProgressPercentage(0);
        setEnableProgressBar(false);
        acceptedFiles.forEach(function (file) {
            setEnableProgressBar(true);
            setUploadingFileName(file.name);
            uploadFile(file, keycloak, handleProgressCallback, isPublic).then(function (response) {
                if (response) {
                    const tmpFiles = [...files];
                    tmpFiles.push({
                        file: response,
                        featured: false,
                    });
                    if (tmpFiles.length == fileLimit) {
                        setDisabledDropzoneArea(true);
                    }
                    setFiles(tmpFiles);
                    uploadCallback(tmpFiles);
                    setEnableProgressBar(false);
                } else {
                    setEnableProgressBar(false);
                    handleClickVariant('error', t('idea.upload.fileUploadError'));
                }
            });
        });
    };

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const tmpFiles = files;
            const uploadedVideos = _.filter(tmpFiles, function (o) {
                return o.file.mimeType.startsWith(VIDEO_MIMES_START);
            });
            const uploadedImages = _.filter(tmpFiles, function (o) {
                return o.file.mimeType.startsWith(IMAGE_MIMES_START);
            });
            const uploadedDocuments = _.filter(tmpFiles, function (o) {
                return o.file.mimeType.startsWith(DOCUMENT_MIMES_START);
            });

            const inProgressDocuments = _.filter(acceptedFiles, function (o) {
                return o.type === '' || o.type.startsWith(DOCUMENT_MIMES_START);
            });

            if (areDocuments) {
                if (inProgressDocuments.length === 0) {
                    handleClickVariant('error', t('startup.generalInfos.documents.supportedFormats'));
                    return;
                }
                for (let inProgressDocument of inProgressDocuments) {
                    if (inProgressDocument.size > documentUploadLimit) {
                        handleClickVariant(
                            'error',
                            t('startup.generalInfos.documents.maxLimit', {
                                documentUploadLimit: documentUploadMegaByteLimit,
                            })
                        );
                        return;
                    }
                }
                successCase();
            } else {
                let uploadedImageSize = 0;
                tmpFiles.forEach(function (file) {
                    if (file.file.mimeType.startsWith(IMAGE_MIMES_START)) {
                        uploadedImageSize += file.file.fileSize;
                    }
                });

                let uploadedVideoSize = 0;
                tmpFiles.forEach(function (file) {
                    if (file.file.mimeType.startsWith(VIDEO_MIMES_START)) {
                        uploadedVideoSize += file.file.fileSize;
                    }
                });

                const inProgressVideos = _.filter(acceptedFiles, function (o) {
                    return o.type.startsWith(VIDEO_MIMES_START);
                });
                const inProgressImages = _.filter(acceptedFiles, function (o) {
                    return o.type.startsWith(IMAGE_MIMES_START);
                });

                let dropImageSize = 0;
                acceptedFiles.forEach(function (file) {
                    if (file.type.startsWith(IMAGE_MIMES_START)) {
                        dropImageSize += file.size;
                    }
                });

                let dropVideoSize = 0;
                acceptedFiles.forEach(function (file) {
                    if (file.type.startsWith(VIDEO_MIMES_START)) {
                        dropVideoSize += file.size;
                    }
                });

                if (dropImageSize + uploadedImageSize > imageUploadLimit) {
                    handleClickVariant(
                        'error',
                        t('idea.upload.imageSizeError', { imageMaxSize: imageUploadMegaByteLimit })
                    );
                } else if (dropVideoSize + uploadedVideoSize > videoUploadLimit) {
                    handleClickVariant(
                        'error',
                        t('idea.upload.videoSizeError', { videoMaxSize: videoUploadMegaByteLimit })
                    );
                } else if (
                    imageLimit == 0 ||
                    (uploadedImages.length + inProgressImages.length > imageLimit &&
                        inProgressImages &&
                        inProgressImages.length > 0)
                ) {
                    handleClickVariant('error', t('idea.upload.imageCountError', { count: imageLimit }));
                } else if (
                    inProgressVideos &&
                    inProgressVideos.length > 0 &&
                    (videoLimit == 0 || uploadedVideos.length + inProgressVideos.length > videoLimit)
                ) {
                    handleClickVariant('error', t('idea.upload.videoCountError', { count: videoLimit }));
                } else if (
                    inProgressDocuments &&
                    inProgressDocuments.length > 0 &&
                    (documentLimit == 0 || uploadedDocuments.length + inProgressDocuments.length > documentLimit)
                ) {
                    handleClickVariant('error', t('idea.upload.documentCountError', { count: documentLimit }));
                } else {
                    successCase();
                }
            }
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (fileRejections && fileRejections.length > 0) {
            fileRejections.forEach(function (rejection) {
                rejection.errors.forEach(function (error) {
                    if (error.code == FILE_TYPE_ERROR) {
                        if (areDocuments) {
                            handleClickVariant('error', t('startup.generalInfos.documents.supportedFormats'));
                        } else if (rejection.file.type.startsWith(IMAGE_MIMES_START)) {
                            handleClickVariant('error', t('idea.upload.imageFormatError'));
                        } else if (rejection.file.type.startsWith(VIDEO_MIMES_START)) {
                            handleClickVariant('error', t('idea.upload.videoFormatError'));
                        } else {
                            handleClickVariant('error', t('idea.upload.genericMediaError'));
                        }
                    }
                });
            });
        }
    }, [fileRejections]);

    useEffect(() => {
        let tmpSwichStates = featureSwitchStates;
        if (!tmpSwichStates) tmpSwichStates = Array.from({ length: fileLimit }, () => false);
        initFiles.forEach(function (item, idx) {
            if (item.featured) {
                tmpSwichStates[idx] = true;
            }
        });
        setFeatureSwitchStates(tmpSwichStates);
    }, [initFiles]);

    const fileFeaturedSwitch = (idx, checked, fileId) => {
        let switchStates = [...featureSwitchStates];
        switchStates = _.map(switchStates, function (state) {
            state = false;
            return state;
        });
        switchStates[idx] = checked;
        setFeatureSwitchStates(switchStates);

        let tmpFiles = [...files];
        tmpFiles = _.map(tmpFiles, function (file) {
            if (file.file.uuid == fileId && checked) {
                file.featured = true;
            } else {
                file.featured = false;
            }
            return file;
        });

        setFiles(tmpFiles);
        uploadCallback(tmpFiles);
    };

    const deleteFile = (file) => {
        // let tmpFiles = _.remove(_.clone(files, true), function (fileItem) {
        //     // return fileItem.file.uuid === file.file.uuid;
        //     return fileItem.file.id !== file.file.id;
        // });

        let tmpFiles = _.filter(files, function (fileItem) {
            return fileItem.file.uuid !== file.file.uuid;
            // return fileItem.file.id !== file.file.id;
        });

        if (tmpFiles.length < fileLimit) {
            setDisabledDropzoneArea(false);
        }
        setFiles(tmpFiles);
        uploadCallback(tmpFiles);
    };

    const yourComputerOrDeviceText = isDevice ? t('idea.upload.yourDevice') : t('idea.upload.yourComputer');

    return (
        <div className={`file-upload dropzoneContainer margin-bottom-50 ${styleClass}`}>
            {!enableProgressBar && (
                <section className={`rectangle ${!hasNoHorizontalMargin && 'margin-right-30 margin-left-30'}`}>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <div className={'cloudIconContainer'}>
                            <BackupIcon className={'cloudIcon'} />
                        </div>
                        <div>
                            <p className={'text-align-center poppin-500-14px'}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('idea.upload.uploadDescription', {
                                            yourComputerOrDevice: yourComputerOrDeviceText,
                                        }),
                                    }}
                                />
                            </p>

                            <p className={'text-align-center disabled-text poppin-500-14px'}>
                                {areDocuments ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('idea.upload.supportedDocuments', {
                                                documentUploadLimit: documentUploadMegaByteLimit,
                                            }),
                                        }}
                                    />
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('idea.upload.uploadDetails', {
                                                imageMaxSize: imageUploadMegaByteLimit,
                                                videoMaxSize: videoUploadMegaByteLimit,
                                            }),
                                        }}
                                    />
                                )}
                            </p>
                        </div>
                    </div>
                </section>
            )}
            {enableProgressBar && (
                <div>
                    <div className={`d-flex ${!hasNoHorizontalMarginInner && 'margin-right-30 margin-left-30'}`}>
                        <div className={'flex-1'}>{uploadingFileName}</div>
                        <div>
                            <BackupIcon className={'loadingCloudIcon'} />
                        </div>
                    </div>
                    <div className={`${!hasNoHorizontalMarginInner && 'margin-right-30 margin-left-30'}`}>
                        <ProgressBar progressPercentage={progressPercentage} />
                    </div>
                </div>
            )}

            {files && files.length > 0 && featureSwitchStates && featureSwitchStates.length > 0 && (
                <div className={`${!hasNoHorizontalMarginInner && 'margin-right-30 margin-left-30'}`}>
                    <div className={'margin-top-25'}>
                        <div className={'d-flex'}>
                            {featureEnabled && (
                                <div className={'flex-1 margin-right-5 disabled-text poppin-500-14px'}>
                                    {t('idea.upload.featured')}
                                </div>
                            )}
                            <div
                                className={'flex-4 disabled poppin-500-14px disabled-text'}
                                style={!featureEnabled ? { color: COLOR_SECONDARY_SHADE_01 } : {}}
                            >
                                {fileLabel}
                            </div>
                        </div>

                        <div>
                            {files.map((file, idx) => (
                                <div className={'d-flex margin-bottom-5'} key={idx}>
                                    {featureEnabled && (
                                        <div className={'flex-1 margin-right-5'}>
                                            <CustomSwitch
                                                checked={featureSwitchStates[idx]}
                                                onChange={(event, checked) =>
                                                    fileFeaturedSwitch(idx, checked, file.file.uuid)
                                                }
                                                color="primary"
                                                name="checkedB"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    )}
                                    <div className={'d-flex flex-4 justify-content-between'}>
                                        <div
                                            className={
                                                'd-flex align-items-center long-word-break margin-right-5 poppin-500-14px'
                                            }
                                            style={!featureEnabled ? { color: COLOR_SECONDARY_BASE } : {}}
                                        >
                                            {file.file.fileName}
                                        </div>
                                        <div>
                                            <Button
                                                className={'deleteButton poppin-500-14px'}
                                                onClick={() => deleteFile(file)}
                                                startIcon={<DeleteIcon className={'deleteIcon'} />}
                                            >
                                                {t('idea.upload.remove')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
