import { format, parse } from 'date-fns';
import { StartupActions } from 'fintech/store/actions/ActionTypes';
import { findObject } from 'fintech/utils/ArrayUtils';
import {
    getLogo,
    parseInfos,
    parseSubVerticals,
    parseTags,
    parseStartupInvestmentData,
    parseStartupInvestorOptionsData,
} from 'fintech/utils/DataParser';
import {
    getAllHeadersAferToggle,
    getInitSimilarVenturesGridData,
} from 'fintech/components/profile/startup/SimilarVentures/SimilarVenturesUtils';
import { cloneDeep } from 'lodash';
import { ISO_FORMAT, NUMERIC_FULL_DATE } from 'fintech/utils/DateUtils';

const initialState = {
    intro: {
        data: {},
        isLoading: true,
        isError: false,
    },
    team: {
        data: [],
        isLoading: true,
        isError: false,
    },
    financials: {
        info: {
            data: {},
            isLoading: true,
            isError: false,
        },
        investments: {
            data: {},
            isLoading: true,
            isError: false,
        },
    },
    form: {
        verticalData: {
            data: [],
            isLoading: true,
            isError: false,
        },
        technologyData: {
            data: [],
            isLoading: true,
            isError: false,
        },
        stageData: {
            data: [],
            isLoading: true,
            isError: false,
        },
        countryData: {
            data: [],
            isLoading: true,
            isError: false,
        },
        cityData: {
            data: [],
            isLoading: true,
            isError: false,
        },
        incomeModelData: {
            data: [],
            isLoading: true,
            isError: false,
        },
        investorsData: {
            data: [],
            isLoading: true,
            isError: false,
        },
        // Used when both editing & addding an investor.
        // Assumes only one form will be available at any given time
        investorForm: {
            id: null,
            investor: {
                id: null,
                investorId: null,
                investorName: null,
                freeTextInvestorName: null,
                isValid: false,
                unregistered: false,
            },
            investorVisible: true,
            investmentDate: null,
            investmentDateIsValid: false,
            investmentDateVisible: true,
            investmentAmount: {
                id: null,
                value: '',
                currencyId: 1,
                isValid: false,
            },
            investmentAmountVisible: true,
            investmentVisible: true,
            sending: false,
        },
    },
    similarVentures: {
        grid: getInitSimilarVenturesGridData(),
    },
};

const startup = (state = initialState, action) => {
    const { id, type } = action;
    switch (type) {
        case StartupActions.SET_STARTUP_INTRO: {
            const { startup } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    data: {
                        ...state.intro.data,
                        ...startup,
                        logo: getLogo(startup.logoFile),
                        startupInfos: parseInfos(startup.startupInfos),

                        verticals: parseSubVerticals(startup.subverticals),
                        technologies: parseTags(startup.technologies),
                        media: startup.medias,
                        stage: startup.stage?.name,
                        isBacked: parseBacked(startup.ventureType?.description),
                        address: startup.address && startup.address[0],
                        ventureType: startup.ventureType,
                        //for idea
                        summary: getSummary(startup.startupInfos),
                        email: getEmail(startup.startupInfos),
                        webSiteUrl: getWebSiteUrl(startup.startupInfos),
                    },
                    isLoading: false,
                },
            };
        }
        case StartupActions.SET_STARTUP_INTRO_LOADING:
            return {
                ...state,
                intro: {
                    ...state.intro,
                    isLoading: true,
                    isError: false,
                    data: {},
                },
            };
        case StartupActions.SET_STARTUP_INTRO_LOAD_ERROR:
            return {
                ...state,
                intro: {
                    ...state.intro,
                    isLoading: false,
                    isError: true,
                },
            };
        case StartupActions.SET_STARTUP_INTRO_VISIT_TIMES: {
            const { visitTimes } = action;
            return {
                ...state,
                intro: {
                    ...state.intro,
                    data: {
                        ...state.intro.data,
                        visitTimes: visitTimes,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_TEAM: {
            const { team } = action;
            return {
                ...state,
                team: {
                    ...state.team,
                    data: {
                        ...team.data,
                        id: id,
                    },
                    isLoading: false,
                },
            };
        }
        case StartupActions.SET_STARTUP_TEAM_LOADING:
            return {
                ...state,
                team: {
                    ...state.team,
                    isLoading: true,
                    isError: false,
                    data: [],
                },
            };
        case StartupActions.SET_STARTUP_TEAM_ERROR:
            return {
                ...state,
                team: {
                    ...state.team,
                    isLoading: false,
                    isError: true,
                },
            };
        case StartupActions.SET_STARTUP_FINANCIALS: {
            const { startupId, financials } = action;
            return {
                ...state,
                financials: {
                    ...state.financials,
                    info: {
                        ...state.financials.info,
                        data: {
                            ...financials.data,
                            incomeModels: parseTags(financials.data.incomeModels),
                            startupId: startupId,
                        },
                        isLoading: false,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_FINANCIALS_LOADING:
            return {
                ...state,
                financials: {
                    ...state.financials,
                    info: {
                        isLoading: true,
                        isError: false,
                        data: {},
                    },
                },
            };
        case StartupActions.SET_STARTUP_FINANCIALS_LOAD_ERROR:
            return {
                ...state,
                financials: {
                    ...state.financials,
                    info: {
                        ...state.financials.info,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_VERTICALS: {
            const { verticalData } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    verticalData: {
                        ...state.form.verticalData,
                        data: {
                            verticals: [...verticalData.data],
                            id: id,
                        },
                        isLoading: false,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_VERTICALS_LOADING:
            return {
                ...state,
                form: {
                    ...state.form,
                    verticalData: {
                        ...state.form.verticalData,
                        isLoading: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_VERTICALS_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    verticalData: {
                        ...state.form.verticalData,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_TECHNOLOGIES: {
            const { technologyData } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    technologyData: {
                        ...state.form.technologyData,
                        data: {
                            technologies: [...technologyData.data],
                        },
                        isLoading: false,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_TECHNOLOGIES_LOADING:
            return {
                ...state,
                form: {
                    ...state.form,
                    technologyData: {
                        ...state.form.technologyData,
                        isLoading: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_TECHNOLOGIES_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    technologyData: {
                        ...state.form.technologyData,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_STAGES: {
            const { stageData } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    stageData: {
                        ...state.form.stageData,
                        data: {
                            stages: [...stageData.data],
                        },
                        isLoading: false,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_STAGES_LOADING:
            return {
                ...state,
                form: {
                    ...state.form,
                    stageData: {
                        ...state.form.stageData,
                        isLoading: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_STAGES_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    stageData: {
                        ...state.form.stageData,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_COUNTRIES: {
            const { countryData } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    countryData: {
                        ...state.form.countryData,
                        data: [...countryData.data],
                        isLoading: false,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_COUNTRIES_LOADING:
            return {
                ...state,
                form: {
                    ...state.form,
                    countryData: {
                        ...state.form.countryData,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case StartupActions.SET_STARTUP_COUNTRIES_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    countryData: {
                        ...state.form.countryData,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_CITIES: {
            const { cityData } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    cityData: {
                        ...state.form.cityData,
                        data: [...cityData.data],
                        isLoading: false,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_CITIES_LOADING:
            return {
                ...state,
                form: {
                    ...state.form,
                    cityData: {
                        ...state.form.cityData,
                        isLoading: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_CITIES_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    cityData: {
                        ...state.form.cityData,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case StartupActions.REMOVE_STARTUP_INFORMATION_CARD_EDIT_STATE:
            return {
                ...state,
                form: {
                    ...state.form,
                    technologyData: {
                        data: { technologies: undefined },
                        isLoading: false,
                        isError: false,
                    },
                    stageData: {
                        data: { stages: undefined },
                        isLoading: false,
                        isError: false,
                    },
                    verticalData: {
                        data: {
                            verticals: undefined,
                            id: undefined,
                        },
                        isLoading: false,
                        isError: false,
                    },
                    countryData: {
                        data: [],
                        isLoading: false,
                        isError: false,
                    },
                    cityData: {
                        data: [],
                        isLoading: false,
                        isError: false,
                    },
                },
            };
        case StartupActions.SET_STARTUP_INCOME_MODELS: {
            const { incomeModelData } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    incomeModelData: {
                        ...state.form.incomeModelData,
                        data: {
                            incomeModels: [...incomeModelData.data],
                        },
                        isLoading: false,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_INCOME_MODELS_LOADING:
            return {
                ...state,
                form: {
                    ...state.form,
                    incomeModelData: {
                        ...state.form.incomeModelData,
                        isLoading: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_INCOME_MODELS_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    incomeModelData: {
                        ...state.form.incomeModelData,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_INVESTMENTS: {
            const { investments } = action;
            return {
                ...state,
                financials: {
                    ...state.financials,
                    investments: {
                        ...state.financials.investments,
                        data: parseStartupInvestmentData(investments.data),
                        isLoading: false,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_INVESTMENTS_LOADING:
            return {
                ...state,
                financials: {
                    ...state.financials,
                    investments: {
                        isLoading: true,
                        isError: false,
                        data: {},
                    },
                },
            };
        case StartupActions.SET_STARTUP_INVESTMENTS_LOAD_ERROR:
            return {
                ...state,
                financials: {
                    ...state.financials,
                    investments: {
                        ...state.financials.investments,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_INVESTOR_OPTIONS: {
            const { investors } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    investorsData: {
                        ...state.form.investorsData,
                        data: parseStartupInvestorOptionsData(investors.data),
                        isLoading: false,
                    },
                },
            };
        }
        case StartupActions.GET_STARTUP_INVESTOR_OPTIONS:
            return {
                ...state,
                form: {
                    ...state.form,
                    investorsData: {
                        ...state.form.investorsData,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case StartupActions.SET_STARTUP_INVESTOR_OPTIONS_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    investorsData: {
                        ...state.form.investorsData,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Interactions in the financials tab

        // Initialize the form with initial data if editing a record.
        case StartupActions.SET_STARTUP_INVESTMENT: {
            const investment = parseInvesmentToForm(id, state.financials.investments.data);
            return {
                ...state,
                form: {
                    ...state.form,
                    investorForm: {
                        ...state.form.investorForm,
                        ...investment,
                        investor: {
                            ...state.form.investorForm.investor,
                            ...investment.investor,
                        },
                        investmentAmount: {
                            ...state.form.investorForm.investmentAmount,
                            ...investment.investmentAmount,
                        },
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_INVESTOR: {
            const { investor, investorVisible } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    investorForm: {
                        ...state.form.investorForm,
                        investor: {
                            ...state.form.investorForm.investor,
                            ...investor,
                        },
                        investorVisible: investorVisible,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_INVESTMENT_DATE: {
            // Should be in ISO format
            const { investmentDate, investmentDateVisible, investmentDateIsValid } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    investorForm: {
                        ...state.form.investorForm,
                        investmentDate: investmentDate,
                        investmentDateIsValid: investmentDateIsValid,
                        investmentDateVisible: investmentDateVisible,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_INVESTMENT_AMOUNT: {
            const { investmentAmount, investmentAmountVisible } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    investorForm: {
                        ...state.form.investorForm,
                        investmentAmount: investmentAmount,
                        investmentAmountVisible: investmentAmountVisible,
                    },
                },
            };
        }
        case StartupActions.SET_STARTUP_INVESTMENT_VISIBILITY: {
            const { investmentVisible } = action;
            return {
                ...state,
                form: {
                    ...state.form,
                    investorForm: {
                        ...state.form.investorForm,
                        investmentVisible: investmentVisible,
                    },
                },
            };
        }
        case StartupActions.SEND_STARTUP_INVESTMENT_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    investorForm: {
                        ...state.form.investorForm,
                        sending: true,
                    },
                },
            };
        case StartupActions.RESET_STARTUP_INVESTOR_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    investorForm: initialState.form.investorForm,
                },
            };
        // SimilarVentures Reducer
        case StartupActions.SET_STARTUP_SIMILARVENTURES_GRID_DATA:
            return {
                ...state,
                similarVentures: {
                    ...state.similarVentures,
                    grid: {
                        ...state.similarVentures.grid,
                        headers: action.data.headers,
                        rows: action.data.rows,
                        isLoading: false,
                        gridDataLoading: false,
                    },
                },
            };
        case StartupActions.RESET_STARTUP_SIMILARVENTURES_GRID_DATA:
            return {
                ...state,
                similarVentures: initialState.similarVentures,
            };
        case StartupActions.TOGGLE_STARTUP_SIMILARVENTURES_GRID_STATE:
            return {
                ...state,
                similarVentures: {
                    grid: {
                        ...state.similarVentures.grid,
                        headers: getAllHeadersAferToggle(state.similarVentures.grid.headers, action.data),
                    },
                },
            };
        case StartupActions.SET_STARTUP_SIMILARVENTURES_GRID_PAGINATION:
            return {
                ...state,
                similarVentures: {
                    grid: {
                        ...state.similarVentures.grid,
                        pagination: action.data,
                    },
                },
            };
        case StartupActions.SET_STARTUP_SIMILARVENTURES_GRID_LOAD_ERROR:
            return {
                ...state,
                similarVentures: {
                    grid: {
                        ...state.similarVentures.grid,
                        isLoading: false,
                        gridDataLoading: false,
                        isError: true,
                    },
                },
            };
        case StartupActions.SET_STARTUP_SIMILARVENTURES_GRID_ROW_FAVOURITE:
            return {
                ...state,
                similarVentures: {
                    grid: {
                        ...state.similarVentures.grid,
                        rows: getSimilarVenturesRowsBySettingFavourite(
                            action.data,
                            cloneDeep(state.similarVentures.grid.rows)
                        ),
                    },
                },
            };
        default:
            return state;
    }
};

export default startup;

const parseBacked = (desc) => ['BACKED_IDEA', 'BACKED_STARTUP'].includes(desc);

const parseInvesmentToForm = (id, data) => {
    const inv = data?.investments?.find((el) => el.id === id);
    const unregistered = !inv?.investorId && inv?.companyTitle ? true : false;
    const investorIsValid = unregistered || (inv?.investorId && inv?.companyTitle);
    const amountIsValid = inv?.investment.id && inv?.investment.value ? true : false;

    return {
        id: id,
        investor: {
            id: inv?.stupInvestorId,
            investorId: unregistered ? -1 : inv?.investorId,
            investorName: !unregistered ? inv?.companyTitle : null,
            freeTextInvestorName: unregistered ? inv?.companyTitle : null,
            isValid: investorIsValid,
            unregistered: unregistered,
            logo: inv.logo,
        },
        investorVisible: inv?.companyIsPublic,
        investmentDate: format(parse(inv.date, NUMERIC_FULL_DATE, new Date()), ISO_FORMAT),
        investmentDateIsValid: inv?.date ? true : false,
        investmentDateVisible: inv?.dateIsPublic,
        investmentAmount: {
            id: inv?.investment.id,
            value: inv?.investment.value,
            currencyId: 1,
            isValid: amountIsValid,
        },
        investmentAmountVisible: inv?.investmentIsPublic,
        investmentVisible: !inv?.hideAll,
    };
};

const getSummary = (obj) => {
    const summary = findObject(obj, 'field', 'summary');
    if (summary) {
        summary.validated = true;
    }
    return summary;
};

const getEmail = (obj) => {
    const email = findObject(obj, 'field', 'email');
    if (email) {
        email.validated = true;
    }
    return email;
};

const getWebSiteUrl = (obj) => {
    const webSite = findObject(obj, 'field', 'website');
    if (webSite) {
        webSite.validated = true;
    }
    return webSite;
};

const getSimilarVenturesRowsBySettingFavourite = (favourite, rows) => {
    rows[favourite.index][favourite.subIndex].value.isFavourite = favourite.status;
    return rows;
};
