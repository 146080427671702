import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { DialogContent, makeStyles, useMediaQuery } from '@material-ui/core';
import ActionModalTab from 'fintech/components/util/ActionModalTab';
import ActionButton from 'fintech/components/util/Button';
import { CANCEL_BUTTON, PRIMARY_BUTTON } from 'fintech/components/util/ButtonConstanst';
import { useTranslation } from 'react-i18next';
import { REJECT, SUBMIT, TAB } from 'fintech/components/util/ActionModalConstants';
import { laptopLarge } from 'fintech/components/style/common';
import IconsSingleCloseRounded from 'fintech/icons/IconsSingleCloseRounded';
import { isEmpty } from 'lodash';
import ButtonCircularIndicator from '../ui/spinner/ButtonCircularIndicator';
import EscKeyListener from 'fintech/components/util/EscKeyListener';

export default function ActionModal(props) {
    const {
        title,
        tabs,
        onAction,
        open,
        buttonContainerClass,
        modalSize,
        modalStyle,
        titleMarginBottom30 = true,
        okClickDisabled = false,
        okClickHidden = false,
        cancelClickDisabled = false,
        okButtonLabel,
        cancelButtonLabel,
        dialogActionsStyle = {
            justifyContent: 'space-between',
            padding: '32px 24px 33px',
        },
        showCancelOnTop = false,
        overrideInitialTab = false,
        titleContainerClass = 'd-flex justify-content-center',
        tabsContainerClass = 'margin-bottom-40',
        isGridItem = true,
        showSpinnerWhenDisabled = false,
    } = props;
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(
        !isEmpty(tabs) ? (overrideInitialTab ? tabs.filter((tab) => tab.selected)[0]?.id : tabs[0].id) : null
    );

    useEffect(() => {
        overrideInitialTab && !isEmpty(tabs) && setSelectedTab(tabs.filter((tab) => tab.selected)[0]?.id);
    }, [tabs]);

    const matches = useMediaQuery(laptopLarge);
    const defaultSize = matches ? { minWidth: '700px', height: '690px' } : {};
    let useStyles;
    if (modalStyle) {
        useStyles = modalStyle;
    } else if (!modalSize) {
        useStyles = makeStyles(() => ({
            paper: defaultSize,
        }));
    } else {
        useStyles = makeStyles(() => ({
            paper: modalSize,
        }));
    }

    const classes = useStyles();

    const cancelClick = () => {
        if (tabs) {
            changeSelectedTab(tabs[0].id);
            onAction({ selectedTabId: selectedTab.id, type: REJECT });
        } else {
            onAction({ type: REJECT });
        }
    };
    const okClick = () => {
        if (tabs) {
            onAction({ selectedTabId: selectedTab.id, type: SUBMIT });
        } else {
            onAction({ type: SUBMIT });
        }
    };

    const changeSelectedTab = (selectedTabId) => {
        setSelectedTab(selectedTabId);
        onAction({ selectedTabId: selectedTabId, type: TAB });
    };

    return (
        <div className={'actionModalContainer'}>
            {open && (
                <EscKeyListener
                    onEsc={() => {
                        !cancelClickDisabled && cancelClick();
                    }}
                />
            )}
            <Dialog open={open} aria-labelledby="form-dialog-title" classes={{ paper: classes.paper }}>
                {showCancelOnTop && (
                    <div style={{ position: 'absolute', width: '100%', textAlign: 'end' }}>
                        <IconsSingleCloseRounded
                            style={{ marginTop: '8px', marginRight: '8px', cursor: 'pointer' }}
                            onClick={() => {
                                !cancelClickDisabled && cancelClick();
                            }}
                        />
                    </div>
                )}
                <Grid container direction={'row'} alignItems="center">
                    <Grid
                        item
                        xs={12}
                        className={titleMarginBottom30 ? 'margin-bottom-30' : ''}
                        style={showCancelOnTop ? { paddingTop: 0 } : null}
                    >
                        <DialogTitle id="form-dialog-title" className={titleContainerClass}>
                            {title}
                        </DialogTitle>
                    </Grid>

                    {tabs && (
                        <Grid xs={12} className={tabsContainerClass} item>
                            <ActionModalTab
                                overrideInitialTab={overrideInitialTab}
                                tabs={tabs}
                                changeSelectedView={(selectedTabId) => changeSelectedTab(selectedTabId)}
                            />
                        </Grid>
                    )}
                </Grid>
                <DialogContent style={{ padding: 0 }}>
                    {isGridItem ? (
                        <Grid item style={{ height: '100%' }}>
                            {props.children}
                        </Grid>
                    ) : (
                        props.children
                    )}
                </DialogContent>
                <DialogActions className={`buttonContainer ${buttonContainerClass || ''}`} style={dialogActionsStyle}>
                    {!showCancelOnTop && (
                        <ActionButton
                            type={CANCEL_BUTTON}
                            label={cancelButtonLabel ? cancelButtonLabel : t('idea.updateCancelButton')}
                            disabled={cancelClickDisabled}
                            onClick={cancelClick}
                        />
                    )}

                    {!okClickHidden && (
                        <ActionButton
                            disabled={okClickDisabled}
                            type={PRIMARY_BUTTON}
                            label={
                                okClickDisabled && showSpinnerWhenDisabled ? (
                                    <ButtonCircularIndicator size={24} />
                                ) : okButtonLabel ? (
                                    okButtonLabel
                                ) : (
                                    t('idea.updateOkButton')
                                )
                            }
                            onClick={okClick}
                        />
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
