import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        },
    },
}));

export default useStyles;
