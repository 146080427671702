import React from 'react';
import { CircularProgress } from '@material-ui/core';

import { COLOR_THEME_FILL_01 } from 'fintech/components/style/common';

const ButtonCircularIndicator = (props) => {
    const { size = 32, classes = {}, style = { color: COLOR_THEME_FILL_01 } } = props;

    return <CircularProgress size={size} classes={classes} style={style} />;
};

export default ButtonCircularIndicator;
