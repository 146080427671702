import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import { LocalOffer } from '@material-ui/icons';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import { StyledTag } from 'fintech/components/ui/button/common';
import { IntroCardTitle } from 'fintech/components/profile/common';
import SplitButton from 'fintech/components/ui/button/SplitButton';
import VentureLogoGrid from 'fintech/components/profile/user/VenturesGrid/Venture/Logo';
import VentureInfoGridItem from 'fintech/components/profile/user/VenturesGrid/Venture/VentureInfo';
import useStyles from 'fintech/components/profile/user/VenturesGrid/Venture/index.style';
import { getLogo, parseSubVerticals } from 'fintech/utils/DataParser';
import _ from 'lodash';
import { ESTABLISHMENT } from 'fintech/routes';
import { useHistory } from 'react-router';
import withPreventPropagation from 'fintech/hoc/withPreventPropagation';

const InvestorItem = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { t, logoFile, name, investorFintechSubVerticalInterest, investmentAreas, type, uuid, onTagExpand } = props;
    const [verticals] = useState(parseSubVerticals(investorFintechSubVerticalInterest, true));

    // Verticals
    let vertsJSX = null;
    if (verticals && _.keys(verticals).length > 0) {
        const verts = _.keys(verticals)?.map((vert, idx) => {
            const groupStyle = idx > 0 ? { marginTop: '11px' } : null;
            return (
                <Grid item key={idx}>
                    <SplitButton groupStyle={groupStyle} option={vert} subOptions={verticals[vert]} />
                </Grid>
            );
        });
        vertsJSX = (
            <>
                {withPreventPropagation(
                    <Box display="flex" flexDirection="row" alignItems="flex-start">
                        <TagGroup
                            isGridItem
                            limit={1}
                            items={verts}
                            preventDefault
                            onClick={() =>
                                onTagExpand({ title: t('profile.relatedInvestors.interestedVerticals'), tags: verts })
                            }
                        />
                    </Box>
                )}
            </>
        );
    } else {
        vertsJSX = <>-</>;
    }

    let investmentAreasJSX = null;
    if (investmentAreas && investmentAreas.length > 0) {
        const investmentAreasMap = investmentAreas?.map((tech, ind) => {
            const customStyle = ind === 0 ? { marginTop: 0 } : null;
            return (
                <Grid item key={tech.id}>
                    <StyledTag
                        style={customStyle}
                        disableElevation
                        disableRipple
                        disableTouchRipple
                        startIcon={<LocalOffer fontSize="small" />}
                    >
                        {tech.name}
                    </StyledTag>
                </Grid>
            );
        });
        investmentAreasJSX = (
            <>
                {withPreventPropagation(
                    <Box display="flex" flexDirection="row">
                        <TagGroup
                            isGridItem
                            limit={1}
                            items={investmentAreasMap}
                            preventDefault
                            onClick={() =>
                                onTagExpand({
                                    title: t('profile.relatedInvestors.previousInvestmentAreas'),
                                    tags: investmentAreasMap,
                                })
                            }
                        ></TagGroup>
                    </Box>
                )}
            </>
        );
    } else {
        investmentAreasJSX = <>-</>;
    }

    const goToInvestorPage = () => {
        history.push(`${ESTABLISHMENT}/${uuid}`);
    };

    return (
        <Grid className={classes.UserVentureContainer} container direction="row" justify="center" alignItems="stretch">
            <Grid className={classes.UserVentureWrapper} item xs={12}>
                <Grid
                    className={classes.UserVenture}
                    container
                    direction={'row'}
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} md={4} lg={5} onClick={goToInvestorPage} style={{ cursor: 'pointer' }}>
                        <VentureLogoGrid logo={getLogo(logoFile)} name={name} type={type} ventureType={"INVESTOR"} />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <VentureInfoGridItem
                            title={<IntroCardTitle>{t('profile.relatedInvestors.interestedVerticals')}</IntroCardTitle>}
                            content={vertsJSX}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <VentureInfoGridItem
                            title={
                                <IntroCardTitle>{t('profile.relatedInvestors.previousInvestmentAreas')}</IntroCardTitle>
                            }
                            content={investmentAreasJSX}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withTranslation('profile')(InvestorItem);
