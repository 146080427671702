import React, {useEffect, useState} from 'react';

import { makeSidebarPanelStyles } from './ChatSidebar.style';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import { getRequest } from 'fintech/api/api';
import { APIS } from 'fintech/constants';
import ChatEntry from "fintech/components/chat/ChatEntry";
import StartChatPanel from "fintech/components/chat/StartChatPanel";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const ChatSidebar = ({ messageEntries, onMessageEntrySelect }) => {
    const chatSidebarStyles = makeSidebarPanelStyles();
    const [contactInEntries, setContactInEntries] = useState(false);
    const { t } = useTranslation();

    const onContactSelect = (clientUuid) => {
        messageEntries.forEach((me) => {
            setContactInEntries(false);
            if(me.addressedUserUuid === clientUuid){
                onMessageEntrySelect(clientUuid);
                setContactInEntries(true);
            }
        })
        if(!contactInEntries){
            onMessageEntrySelect(clientUuid);
        }
    }

    return <div className={chatSidebarStyles.sidebar}>
            <StartChatPanel onContactSelect={onContactSelect} />
            {messageEntries.length > 0 ? (
                messageEntries.map((entry) => {
                    return <ChatEntry conversationTitle={entry.addressedUserFullName} lastMessage={entry.lastMessageSent} clientUuid={entry.addressedUserUuid} lastMessageSeen={entry.lastMessageSeen} onClick={() => onMessageEntrySelect(entry.addressedUserUuid)} />;
                })
        ) : (
            <div className={chatSidebarStyles.noChatHere}>
                <Typography style={{ textAlign: 'center' }}>{t("profile.user.chat.thereIsNoChatHere")}</Typography>
            </div>
        )}
    </div>;
};

export default ChatSidebar;
