import * as React from 'react';

function SvgFlagsSUsUnitedStates(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={16}
            height={12}
            viewBox="0 0 16 12"
            {...props}
        >
            <defs>
                <path id="iaj5117yda" d="M0 0H16V12H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g transform="translate(-1040 -1179) translate(918 987) translate(99 148) translate(23 44)">
                                <path fill="#E31D1C" d="M0 0L16 0 16 12 0 12z" mask="url(#neuj9tk9gb)" />
                                <path
                                    fill="#F7FCFF"
                                    d="M16 11v1H0v-1h16zm0-2v1H0V9h16zm0-2v1H0V7h16zm0-2v1H0V5h16zm0-2v1H0V3h16zm0-2v1H0V1h16z"
                                    mask="url(#neuj9tk9gb)"
                                />
                                <path fill="#2E42A5" d="M0 0H9V7H0z" mask="url(#neuj9tk9gb)" />
                                <path
                                    fill="#F7FCFF"
                                    d="M7.57.564l.241.537h.524l-.412.416.16.657-.513-.37-.426.296h.191l-.412.417.141.583h.299l.206-.536.242.537h.524l-.412.416.16.657-.513-.37-.426.296h.191l-.412.417.141.583h.299l.206-.536.242.537h.524l-.412.416.16.657-.513-.37-.53.37.179-.657-.47-.416h.232l-.411-.296-.426.295h.191l-.412.417.16.657-.513-.37-.53.37.179-.657-.47-.416h.232l-.411-.296-.426.295h.191l-.412.417.16.657-.513-.37-.53.37.179-.657-.47-.416h.232l-.411-.296-.426.295h.191l-.412.417.16.657-.513-.37-.53.37.179-.657-.47-.416h.614l.206-.537.242.537h.248l.16-.584-.47-.416h.232l-.411-.296-.53.369.179-.657-.47-.416h.614l.206-.537.242.537h.248l.16-.584-.47-.416h.232l-.411-.296-.53.369.179-.657-.47-.416h.614l.206-.537.242.537h.524l-.412.416.141.583h.299l.206-.536.242.537h.248l.16-.584-.47-.416h.614l.206-.537.242.537h.524l-.412.416.141.583h.299l.206-.536.242.537h.248l.16-.584-.47-.416h.614l.206-.537.242.537h.524l-.412.416.141.583h.299l.206-.536.242.537h.248l.16-.584-.47-.416h.614l.206-.537zM5.98 4.1l-.411-.295-.426.295h.191l-.412.417.141.583h.299l.206-.536.242.537h.248l.16-.584-.47-.416h.232zM3.57 3.805l-.426.295h.191l-.412.417.141.583h.299l.206-.536.242.537h.248l.16-.584-.47-.416h.232l-.411-.296zM6.98 3.1l-.411-.295-.426.295h.191l-.412.417.141.583h.299l.206-.536.242.537h.248l.16-.584-.47-.416h.232zM2.57 2.805l-.426.295h.191l-.412.417.141.583h.299l.206-.536.242.537h.248l.16-.584-.47-.416h.232l-.411-.296zm2 0l-.426.295h.191l-.412.417.141.583h.299l.206-.536.242.537h.248l.16-.584-.47-.416h.232l-.411-.296zm1-1l-.426.295h.191l-.412.417.141.583h.299l.206-.536.242.537h.248l.16-.584-.47-.416h.232l-.411-.296zm-2 0l-.426.295h.191l-.412.417.141.583h.299l.206-.536.242.537h.248l.16-.584-.47-.416h.232l-.411-.296z"
                                    mask="url(#neuj9tk9gb)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgFlagsSUsUnitedStates;
