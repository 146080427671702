import React from 'react';

import ErrorPageTemplate from 'fintech/components/errors/layout/PageTemplate';
import ExpiredInvite from 'fintech/components/errors/ExpiredInvite';

const ExpiredInvitePage = () => {
    return (
        <ErrorPageTemplate>
            <ExpiredInvite />
        </ErrorPageTemplate>
    );
};

export default ExpiredInvitePage;
