import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import PageTemplate from 'fintech/components/PageTemplate';
import BoxedSearchBar from 'fintech/components/search/BoxedSearchBar';
import {
    fetchActiveMentorsCount,
    fetchGridData,
    resetAllFilters,
    searchBoxOnChange,
    setSearchBoxFilterStatus,
    setGridPage,
} from 'fintech/store/actions/mentorSearch';
import { formatNumericValue } from 'fintech/utils/StringUtils';
import { MentorSearchActions } from 'fintech/store/actions/ActionTypes';
import mentorImage from 'fintech/static/images/mentor.jpg';
import mentorImageMobile from 'fintech/static/images/mentor-mobil.jpg';
import GridPagination from 'fintech/components/pagination/GridPagination';
import SearchCardGrid from 'fintech/components/search/CardGrid';
import MentorCard from 'fintech/components/search/mentor/MentorCard';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import { MENTORS_PROFILE } from 'fintech/routes';

const MentorsPage = () => {
    const { t } = useTranslation('mentorSearch');
    const { keycloak } = useKeycloak();
    const { totalNumber } = useSelector((state) => state.mentorSearch.global);
    const { rows, pagination, isLoading, gridDataLoading } = useSelector((state) => state.mentorSearch.grid);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const errorCallback = (msg = t('idea.updateError')) => enqueueSnackbar(msg, { variant: 'error' });
    const type = 'mentorSearch';
    const tabs = [{ label: t('nav.allMentors') }];

    const makeSearchHandler = (input, filters) => {
        const searchFilter = filters?.find((fi) => fi.status);
        if (searchFilter) {
            dispatch({ type: MentorSearchActions.RESET_MENTOR_GRID_PAGINATION });
            dispatch(
                fetchGridData({
                    keycloak,
                    data: {
                        nameSelected: filters.find(({ label }) => label === 'mentorName').status,
                        fintechVerticalsSelected: filters.find(({ label }) => label === 'mentorSubVerticals').status,
                        searchTerm: input,
                    },
                    errorCallback,
                })
            );
        } else {
            errorCallback(t('searchbar.validationText'));
        }
    };

    useEffect(() => {
        window.scroll({ top: 0, left: 0 });
        dispatch(resetAllFilters());
        dispatch(fetchActiveMentorsCount(keycloak, errorCallback));
        dispatch(fetchGridData({ keycloak, errorCallback }));
    }, []);

    const gridPageChangeHandler = (e, pageNumber) => {
        dispatch(setGridPage(pageNumber));
        dispatch(fetchGridData({ keycloak, errorCallback, useLastQueryType: true }));
    };

    const cards = rows?.map((usr) => ({
        uuid: usr.uuid,
        component: (
            <MentorCard
                {...usr}
                type={type}
                title={t('profile.user.verticals')}
                cardType={type}
                personUrl={MENTORS_PROFILE}
            />
        ),
    }));

    return (
        <PageTemplate>
            <PageHeaderBox />
            <NavTabs value={0} tabs={tabs} />
            <BoxedSearchBar
                type={type}
                backgroundImage={mentorImage}
                backgroundImageMobile={mentorImageMobile}
                labelHtml={t('searchbar.registeredMentors', { count: formatNumericValue(totalNumber) })}
                placeholderText={t('searchbar.placeholder')}
                searchBoxOnChange={searchBoxOnChange}
                setSearchBoxFilterStatus={setSearchBoxFilterStatus}
                makeSearch={makeSearchHandler}
                resetAllFilters={() => dispatch(resetAllFilters())}
                fetchGridData={() => dispatch(fetchGridData({ keycloak, errorCallback }))}
                mirrorHorizontally={false}
            />
            <SearchCardGrid
                cards={cards}
                type={type}
                clearAll={() => {
                    dispatch(resetAllFilters());
                    dispatch(fetchGridData({ keycloak, errorCallback }));
                }}
            />
            {rows && rows.length > 0 && (
                <div>
                    <GridPagination
                        controlled
                        disabled={isLoading || gridDataLoading}
                        {...pagination}
                        handleChange={gridPageChangeHandler}
                    />
                </div>
            )}
        </PageTemplate>
    );
};

export default MentorsPage;
