import * as React from 'react';

function SvgIconsMultipleHamburger(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <filter id="qupfahv0ua" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g filter="url(#qupfahv0ua)" transform="translate(-327 -20)">
                    <g fill="#3F5162" fillRule="nonzero">
                        <path
                            d="M21.35 7.85v-2.1H2.6v2.1h18.75zm0 5.175v-2.05H2.6v2.05h18.75zm0 5.225v-2.1H2.6v2.1h18.75z"
                            transform="translate(327 20)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleHamburger;
