import * as React from 'react';

function SvgIconsSingleWord(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#3F5162" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M18.75 21c.532 0 .964-.432.964-.964V8.464c0-.532-.311-1.275-.683-1.647l-3.134-3.134C15.526 3.311 14.782 3 14.25 3h-9c-.532 0-.964.432-.964.964v16.072c0 .532.432.964.964.964h13.5zm-.321-1.286H5.57V4.286h7.715v4.178c0 .533.432.965.964.965h4.179v10.285zm-.08-11.571H14.57V4.366c.171.06.342.15.412.221l3.144 3.144c.07.07.161.241.221.412zm-7.775 10.286l1.285-4.872c.05-.15.08-.301.1-.462.01-.08.02-.16.02-.241h.041l.03.24c.03.142.04.302.09.463l1.286 4.872h1.597l1.648-6.64h.703v-1.075H14.36v1.075h.904l-.995 4.4c-.04.16-.06.331-.07.462l-.02.21h-.04c0-.06-.02-.14-.03-.21-.03-.13-.05-.302-.091-.462l-1.446-5.475h-1.146L9.981 16.19c-.04.16-.05.331-.08.462l-.04.21h-.04l-.02-.21c-.01-.13-.03-.302-.071-.462l-.995-4.4h.905v-1.075H6.626v1.075h.703l1.648 6.64h1.597z"
                                    transform="translate(-332 -1179) translate(280 988) translate(52 61) translate(0 130)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleWord;
