import React from 'react';

export const mobileSizeForCarousel = 500;

//Gets number of visible slides in the carousel according to window size
export const getNumberOfVisibleSlides = (windowSize) => {
    const carouselSize = windowSize.width - 320;
    if (windowSize.width > mobileSizeForCarousel) {
        return Math.round(carouselSize / 310);
    } else {
        return 1;
    }
};

//Checks the visibility of the next button according to event items length and the window size
export const isNextButtonVisible = (windowSize, itemLength) => {
    const visibleItemsLength = itemLength * 300;
    return windowSize.width - 320 < visibleItemsLength;
};

export const eventStatus = {
    ACTIVE: 'ACTIVE',
    FINISHED: 'FINISHED',
};

export const isActiveEventsTabSelected = (value) => {
    return eventStatus.ACTIVE === value;
};
