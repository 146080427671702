import React from 'react';
import useStyles, { Title, Content } from './index.style';
import * as FileServerService from 'fintech/api/fileService';
import { isEmpty } from 'lodash';
import useFileStyles from 'fintech/components/membership/Sections/MentorSections/index.style';
import { StyledTag } from 'fintech/components/ui/button/common';
import { LocalOffer } from '@material-ui/icons';
import { parseSubVerticals } from 'fintech/utils/DataParser';
import SplitButton from 'fintech/components/ui/button/SplitButton';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import { isUrl } from 'fintech/utils/StringUtils';

const Section = ({ infos, t }) => {
    const FileStyles = useFileStyles();
    const classes = useStyles();
    return (
        !isEmpty(infos) &&
        infos.map((info, index) => (
            <Question
                key={index}
                title={`${info.questionNumber}.${info.questionLabel}`}
                contentJSX={
                    <>
                        {info.answers.map((answerDetail) =>
                            getContentByType(answerDetail, info.questionNumber, FileStyles, classes, t)
                        )}
                    </>
                }
                classes={classes}
            />
        ))
    );
};

export default Section;

const Question = ({ title, contentJSX, classes }) => {
    return (
        <div className={classes.item}>
            <Title>{title}</Title>
            {contentJSX}
        </div>
    );
};

const getContentByType = (answerDetail, questionNumber, FileStyles, classes, t) => {
    if (isEmpty(answerDetail.value)) return <Content>-</Content>;

    if (answerDetail.sourceType === 'FINTECH_SUB_VERTICAL') {
        const convertedDataToParse = answerDetail.value.map((value) => {
            return {
                id: value.id,
                parentId: value.parentId,
                vertical: value.parentData,
                subVertical: value.data,
            };
        });

        const verticals = parseSubVerticals(convertedDataToParse, true);

        // eslint-disable-next-line no-undef
        const verts = _.keys(verticals)?.map((vert, idx) => (
            <SplitButton key={idx} option={vert} subOptions={verticals[vert]} />
        ));

        return (
            <div>
                <TagGroup limit={5} items={verts} clickableStyledTagStyle={{ verticalAlign: 'top' }} />
            </div>
        );
    } else if (answerDetail.sourceType === 'STARTUP_STAGE' || answerDetail.sourceType === 'TECHNOLOGY') {
        return (
            <div>
                {answerDetail.value.map((value) => (
                    <StyledTag
                        key={value}
                        disableElevation
                        disableRipple
                        disableTouchRipple
                        startIcon={<LocalOffer fontSize="small" />}
                        style={{ marginTop: '0', marginRight: '8px', verticalAlign: 'baseline' }}
                    >
                        {value}
                    </StyledTag>
                ))}
            </div>
        );
    } else if (answerDetail.answerType === 'FILE_UPLOAD') {
        const files = answerDetail.value;

        const handleFileRequest = (id, name) => {
            FileServerService.downloadFile(id, name);
        };

        return files.map((file) => (
            <Content
                key={file.uuid}
                onClick={() => handleFileRequest(file.uuid, file.fileName)}
                className={FileStyles.file}
            >
                {file.fileName}
            </Content>
        ));
    } else {
        return answerDetail.value.map((value) => {
            let contentValue = value;

            if (
                answerDetail.answerType === 'CHECKBOX' &&
                value === t('supportProgramApplicationApproveReject.common.sections.other')
            ) {
                return;
            }

            if (questionNumber === 34) {
                let placeholder = answerDetail.answerType === 'RADIO_BUTTON' ? '' : answerDetail.placeHolder;
                contentValue = `${placeholder ? `${placeholder}: ${value}` : value} `;
            }
            return (
                <Content
                    key={value}
                    className={isUrl(value, true) ? classes.url : ''}
                    onClick={() => {
                        if (!isUrl(value, true)) {
                            return;
                        }
                        window.open(value);
                    }}
                    style={{ whiteSpace: answerDetail.answerType === 'FREE_TEXT' ? 'pre-line' : 'normal' }}
                >
                    {contentValue}
                </Content>
            );
        });
    }
};
