import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import MultipleSelect from 'fintech/components/ui/form/select/MultipleSelect';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import DropdownError from './DropdownError';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';

export default function StagesUpdate({
    stages,
    changeCallback,
    label,
    multipleSelect = false,
    formIsSubmitted,
    usePlaceholder = false,
    validateOnBlur = false,
    validatedAfterFormSubmit = true,
    preventValidation = false,
    className = 'full-width',
    noBottomMargin = false,
    isLoading = false,
    isError = false,
}) {
    const { t } = useTranslation();
    const [stageData, setStageData] = useState(stages || []);
    if (stages && stageData !== stages) {
        setStageData(stages);
    }

    const [valid, setIsValid] = useState(false);
    const [selectionIsChanged, setSelectionIsChanged] = useState(false);

    const stageChange = (val, valid) => {
        setIsValid(valid);
        setStageData(val && val.length > 0 ? val : []);
        changeCallback(val && val.length > 0 ? val : [], valid);
    };
    const stageId = 'stageId';

    const handleOnSelectionChange = () => {
        setSelectionIsChanged(true);
    };

    const changedOrSubmitted = formIsSubmitted || selectionIsChanged;

    let renderedJSX = null;
    if (isLoading) {
        renderedJSX = (
            <div className={className} style={{ marginBottom: !noBottomMargin && '30px', textAlign: 'center' }}>
                <LoadingSpinner />
            </div>
        );
    } else if (isError) {
        renderedJSX = (
            <div className={className}>
                <DropdownError noBottomMargin={noBottomMargin} />
            </div>
        );
    } else {
        renderedJSX = (
            <div className={className}>
                {stageData && stageData.length > 0 && (
                    <Grid container direction={'row'}>
                        <Grid xs={12} className={`full-width ${!noBottomMargin ? 'margin-bottom-30' : ''}`}>
                            <MultipleSelect
                                label={!usePlaceholder ? (label ? label : t('idea.stage')) : ''}
                                placeholder={
                                    usePlaceholder && isEmpty(filterObjectList(stageData, 'selected', [true]))
                                        ? label
                                        : ''
                                }
                                callBack={(data, valid) => stageChange(data, valid)}
                                id={stageId}
                                value={stageData}
                                helperText={t('validation.notEmpty')}
                                multipleSelect={multipleSelect}
                                validateOnBlur={validateOnBlur}
                                preventValidation={preventValidation || !changedOrSubmitted}
                                onSelectionChange={handleOnSelectionChange}
                                validatedAfterFormSubmit={validatedAfterFormSubmit}
                            />
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }

    return renderedJSX;
}
