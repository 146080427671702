import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';

import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import PersonForm from 'fintech/components/applications/form/common/PersonForm';
import useLegalTextClick from 'fintech/hooks/useLegalTextClick';
import LegalTextModal from 'fintech/components/applications/modal/LegalTextModal';
import * as constants from 'fintech/components/util/ApplicationFormConstants';
import { MemberApplicationActions } from 'fintech/store/actions/ActionTypes';
import { fetchCountries, fetchEducationLevels, fetchGenders } from 'fintech/store/actions/application-member';
import useGeneralInfoStyles from './index.style';
import useStyles from 'fintech/components/applications/form/investor/InvestorNRepForm/index.style';
import CfoSingleCheckbox from 'fintech/components/ui/form/checkbox/CfoSingleCheckbox';
import { setMemberInfoFromInvite } from 'fintech/store/actions/application-member';
import * as ROUTES from 'fintech/routes';
import { CAPTCH_API_KEY_INVIS } from 'fintech/constants/auth';
import { getLocale } from 'fintech/utils/LanguageUtils';
import * as FintechBeService from 'fintech/api/fintechService';
import { mobileMediaQuery } from 'fintech/components/style/common';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const PersonalInfoForm = (props) => {
    const { t } = useTranslation('application');
    const { inviteToken, switchTabHandler } = props;
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const classes = useStyles();
    const matches = useMediaQuery(mobileMediaQuery);
    const generalInfoClasses = useGeneralInfoStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { options, inviteFetch, isLoading, ...form } = useSelector((state) => state.memberApplication);
    const { hasActiveRequest } = useSelector((state) => state.common);
    const { countries, genders, educationLevels } = options;
    const [openModal, setOpenModal] = useState(false);
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
    const [clickedAgreement, setClickedAgreement] = useState(null);

    // ReCaptcha - Start
    const recaptchaRef = useRef();

    const asyncOnLoad = () => {
        setRecaptchaLoaded(true);
    };

    // ReCaptcha - End

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        if (inviteToken) {
            setTimeout(() => {
                FintechBeService.fetchTeamMemberInviteInfo(keycloak, inviteToken)
                    .then((res) => {
                        // Invite endpoint throws an exception if invite status is anythinh
                        // other than INVITED
                        dispatch(setMemberInfoFromInvite(res.data));
                        dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_INVITE_LOADING, data: false });
                    })
                    .catch((e) => {
                        if (e.data === constants.INVITE_EXPIRED) {
                            history.replace(ROUTES.EXPIRED_INVITE);
                        } else {
                            history.replace(ROUTES.NOT_FOUND);
                        }
                    });
            }, 1000);
        } else {
            history.replace(ROUTES.NOT_FOUND);
        }
    }, []);

    useLegalTextClick({
        legalClickListener: (e) => {
            if (e?.currentTarget) {
                setClickedAgreement(e?.currentTarget?.id);
                setOpenModal(true);
            }
        },
    });

    useEffect(() => {
        dispatch(fetchCountries(keycloak));
        dispatch(fetchGenders(keycloak));
        dispatch(fetchEducationLevels(keycloak));
    }, []);

    const closeModalHandler = () => setOpenModal(false);

    const formChangeHandler = ({ type, data }) => {
        switch (type) {
            case constants.PERSON_FORM_NAME_UPDATE:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_NAME, data });
                break;
            case constants.PERSON_FORM_SURNAME_UPDATE:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_SURNAME, data });
                break;
            case constants.PERSON_FORM_COUNTRY_UPDATE:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_COUNTRY, data });
                break;
            case constants.PERSON_FORM_IDENTITY_UPDATE:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_IDENTITY, data });
                break;
            case constants.PERSON_FORM_GENDER_UPDATE:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_GENDER, data });
                break;
            case constants.PERSON_FORM_BIRTHDATE_UPDATE:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_BIRTHDATE, data });
                break;
            case constants.PERSON_FORM_EMAIL_UPDATE:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_EMAIL, data });
                break;
            case constants.PERSON_FORM_PHONE_NUMBER_UPDATE:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_PHONE_NUMBER, data });
                break;
            case constants.PERSON_FORM_EXPERIENCE_UPDATE:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_EXPERIENCE, data });
                break;
            case constants.PERSON_FORM_EDUCATION_UPDATE:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_EDUCATION, data });
                break;
            case constants.MEMBER_FORM_AGREEMENTS:
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_AGREEMENTS });
                break;
        }
    };

    const clickContinueHandler = async () => {
        const {
            url,
            companyName,
            companyIdentityNumber,
            companyType,
            title,
            investorId,
            startupId,
            name,
            surname,
            country,
            idNumber,
            gender,
            birthDate,
            email,
            phoneNumber,
            experience,
            education,
            trafficSource,
        } = form;
        dispatch(setActiveRequest(true));
        const requestData = {};

        // Invite data
        requestData.url = url;
        requestData.companyName = companyName;
        requestData.companyIdentityNumber = companyIdentityNumber;
        requestData.companyType = companyType;
        requestData.investorId = investorId;
        requestData.startupId = startupId;
        requestData.title = title;

        // User data
        requestData.name = name?.value;
        requestData.surname = surname?.value;
        requestData.countryCode = country?.value?.id;
        requestData.tckn = idNumber?.value;
        requestData.gender = gender?.value?.id;
        requestData.birthDate = birthDate?.value;
        requestData.email = email?.value;
        requestData.gsmNo = {
            number: phoneNumber?.value?.nationalNumber,
            country: {
                alphaTwo: phoneNumber?.value?.country,
                callingCode: phoneNumber?.value?.countryCallingCode,
            },
        };
        requestData.expertiseYear = +experience?.value;
        requestData.educationInfo = education?.value?.id;
        requestData.trafficSource = trafficSource;

        let res = null;
        try {
            res = await FintechBeService.membershipApplicationFirstStep(keycloak, requestData);
        } catch (reason) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        if (!res || !res.success) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        // Trigger captcha
        try {
            const token = await recaptchaRef?.current?.executeAsync();
            if (!token) {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(t('validation.reCaptcha'), { variant: 'error' });
                return;
            }
        } catch (e) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(t('validation.reCaptcha'), { variant: 'error' });
            return;
        }

        // TCKN validation
        try {
            res = await FintechBeService.membershipApplicationComplete(keycloak, requestData);
            dispatch(setActiveRequest(false));
        } catch (reason) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        if (!res || !res.success) {
            enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        switchTabHandler(true);
    };

    const validate = () => {
        const {
            name,
            surname,
            country,
            idNumber,
            gender,
            birthDate,
            email,
            phoneNumber,
            experience,
            education,
            readAgreement,
        } = form;

        return (
            name?.validated &&
            surname?.validated &&
            country?.validated &&
            idNumber?.validated &&
            gender?.validated &&
            birthDate?.validated &&
            email?.validated &&
            phoneNumber?.validated &&
            experience?.validated &&
            education?.validated &&
            readAgreement
        );
    };

    return (
        <>
            <CfoFormContainer>
                {!isLoading ? (
                    <>
                        {matches && (
                            <Grid item xs={12}>
                                <Typography className={generalInfoClasses.titleTypo}>
                                    {t('member.tabs.personalInfo')}
                                </Typography>
                            </Grid>
                        )}
                        <CfoFormInput className={classes.personFormWrapper} xs={12} lg={12}>
                            <PersonForm
                                changeCallback={formChangeHandler}
                                selectOptions={{ countries, genders, educationLevels }}
                                initFormData={{ ...form }}
                                hideJobTitle
                                nameDisabled
                                surnameDisabled
                                emailDisabled
                                refresh
                                trackForRefresh={inviteFetch}
                            />
                        </CfoFormInput>
                        <Grid item container direction="row" justify="flex-start">
                            <Grid item xs={12} lg={12}>
                                <div className={classes.legalDiv}>
                                    <CfoSingleCheckbox
                                        checked={form.readAgreement}
                                        onChange={() => {
                                            formChangeHandler({
                                                type: constants.MEMBER_FORM_AGREEMENTS,
                                            });
                                        }}
                                        label={<Typography dangerouslySetInnerHTML={{ __html: t('investor.legal') }} />}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <CfoFormInput xs={12} lg={12} className={classes.singlepplyBtnWrapper}>
                            <Button
                                className={classes.applyBtn}
                                startIcon={<Check />}
                                disabled={hasActiveRequest || !validate() || !recaptchaLoaded}
                                variant="contained"
                                onClick={clickContinueHandler}
                            >
                                {!hasActiveRequest ? (
                                    t('common.form.label.sendApplication')
                                ) : (
                                    <ButtonCircularIndicator />
                                )}
                            </Button>
                        </CfoFormInput>
                        <LegalTextModal initialTab={clickedAgreement} open={openModal} onCancel={closeModalHandler} />
                    </>
                ) : (
                    <div style={{ margin: '250px 0' }}>
                        <LoadingSpinnerWithText />
                    </div>
                )}
            </CfoFormContainer>

            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={CAPTCH_API_KEY_INVIS}
                asyncScriptOnLoad={asyncOnLoad}
                hl={getLocale()}
            />
        </>
    );
};

export default PersonalInfoForm;
