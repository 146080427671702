import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { renderGridStyle } from 'fintech/pages/post-login/EntrepreneursPage/index.style';
import Empty from 'fintech/components/util/Empty';
import { formatNumericValue } from 'fintech/utils/StringUtils';
import useAdvancedFilterAreaStyles from 'fintech/components/search/AdvancedFilterArea/index.style.js';
import useStyles, { defaultCardStyle } from './index.style';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import { buttonStyleGridClear } from 'fintech/components/style/common';

const SearchCardGrid = (props) => {
    const { cards, itemStyles = defaultCardStyle, type, clearAll } = props;
    const { t } = useTranslation(type);
    const classes = useStyles();
    const advancedFilterAreaClasses = useAdvancedFilterAreaStyles();
    const gridStyles = renderGridStyle();

    const { rows, isLoading, isError } = useSelector((state) => state[type].grid);
    const { filteredNumber } = useSelector((state) => state[type].global);
    const { lastQueryParams } = useSelector((state) => state[type].searchBox);

    const [listedCount, setListedCount] = useState(filteredNumber);

    useEffect(() => {
        setListedCount(filteredNumber);
    }, [filteredNumber]);

    const emptyMessage = (
        <>
            <Typography style={{ textAlign: 'center' }}>{t('searchbar.noResult')}</Typography>
            {clearAll && (
                <Box className={gridStyles.removeSearchResultBtnContainer}>
                    <Button className={gridStyles.removeSearchResultBtn} onClick={clearAll}>
                        {t('searchbar.clearSearchResult')}
                    </Button>
                </Box>
            )}
        </>
    );

    let gridJSX = null;
    if (isLoading) {
        gridJSX = (
            <div className={classes.EmptyWrapperWithBorder}>
                <Empty isLoading message={t('profile.loadingMessage')} circularShapes />
            </div>
        );
    } else if (isError) {
        gridJSX = (
            <div className={classes.EmptyWrapperWithBorder}>
                <Empty isError message={t('dataGrid.fetchError')} circularShapes />
            </div>
        );
    } else if (rows && rows.length > 0) {
        const cardLength = cards && cards.length;
        let classNameOfGrid = classes.SearchCardGrid;
        if (cardLength === 2 || cardLength === 3) {
            classNameOfGrid = classes.SearchCardGridTwoOrThreeCards;
        }
        gridJSX = (
            <Grid className={classNameOfGrid} item container>
                {cards?.map((card) => (
                    <Grid key={card.uuid} item style={{ ...itemStyles }}>
                        {card.component}
                    </Grid>
                ))}
            </Grid>
        );
    } else {
        gridJSX = (
            <Grid item>
                <div className={classes.EmptyWrapperWithBorder}>
                    <Empty showIcon={false} messageComponent={emptyMessage} circularShapes />
                </div>
            </Grid>
        );
    }

    const showClearBtn = lastQueryParams.searchTerm !== '';
    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                style={{ marginTop: '12px', marginBottom: '18px' }}
            >
                <Grid item xs={showClearBtn ? 6 : 12} md={showClearBtn ? 8 : 12}>
                    {filteredNumber === null ? (
                        <Typography className={advancedFilterAreaClasses.listedMessageTypo} display="inline">
                            {t('dataGrid.allInvestors')}
                        </Typography>
                    ) : (
                        <Typography
                            className={advancedFilterAreaClasses.listedMessageTypo}
                            display="inline"
                            dangerouslySetInnerHTML={
                                listedCount > 1
                                    ? {
                                          __html: t('dataGrid.listedInvestors', {
                                              count: formatNumericValue(listedCount),
                                          }),
                                      }
                                    : {
                                          __html: t('dataGrid.listedInvestor', {
                                              count: formatNumericValue(listedCount),
                                          }),
                                      }
                            }
                        />
                    )}
                </Grid>
                {showClearBtn && (
                    <Grid item xs={6} md={4} container direction="row" justify="flex-end" alignItems="center">
                        <ShrinkingButton
                            onClick={clearAll}
                            shrinkedIcon={<Close fontSize="small" />}
                            fullIcon={<Close />}
                            fullText={t('searchbar.clearResults')}
                            otherStyles={buttonStyleGridClear}
                        />
                    </Grid>
                )}
            </Grid>
            {gridJSX}
        </Grid>
    );
};

export default SearchCardGrid;
