import * as React from 'react';

function SvgChevronLeft(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <defs>
                <filter id="4ssa6xse6a" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g
                                filter="url(#4ssa6xse6a)"
                                transform="translate(-1247 -1992) translate(0 1579) translate(120 412) translate(1127 1)"
                            >
                                <path
                                    fill="#3F5162"
                                    fillRule="nonzero"
                                    d="M18.464 23.733L20.195 22.003 14.563 16.4 20.195 10.797 18.464 9.067 11.131 16.4z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgChevronLeft;
