import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Apps } from '@material-ui/icons';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import ProfileVisitHistory from 'fintech/components/profile/common/ProfileVisitHistory';
import { useSelector } from 'react-redux';
import SwitchActionModal from 'fintech/components/util/SwitchActionModal';
import { STATES } from 'fintech/store/actions/ActionTypes';

const getFetchedDataFromState = (state, type) => {
    if (type === STATES.ATTEMPTS) {
        return state[type].attemps;
    } else if (type == STATES.ESTABLISHMENT) {
        return state[type].establishments;
    }
};
const IntroTab = (props) => {
    const { t, times, type } = props;
    const tabs = [{ label: t('profile.venture.introCard') }];
    const [openEditModal, setOpenEditModal] = useState(false);

    const fetchedData = useSelector((state) => getFetchedDataFromState(state, type));

    const handlePopupOpen = () => {
        setOpenEditModal(true);
    };

    const isAttempt = STATES.ATTEMPTS === type;

    const fullText = isAttempt ? t('profile.venture.allVentures') : t('profile.establishments.allEstablishments');
    const title = isAttempt ? t('idea.interactionModal.title') : t('establishment.interactionModal.title');
    const subTitle = isAttempt ? t('idea.interactionModal.subTitle') : t('establishment.interactionModal.subTitle');
    const footerTitle = isAttempt
        ? t('idea.interactionModal.footerTitle')
        : t('establishment.interactionModal.footerTitle');

    return (
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={8}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item>
                        <NavTabs id="search-navtabs" value={0} tabs={tabs} />
                    </Grid>
                    {fetchedData && fetchedData.length > 1 && (
                        <Grid item onClick={handlePopupOpen}>
                            <ShrinkingButton
                                shrinkedIcon={<Apps fontSize="small" />}
                                fullIcon={<Apps />}
                                fullText={fullText}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {Number(times) > 0 && (
                <Grid item xs={4}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        <Grid item>
                            <ProfileVisitHistory visitCount={times} />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid>
                <SwitchActionModal
                    open={openEditModal}
                    setOpen={setOpenEditModal}
                    fetchedData={fetchedData}
                    title={title}
                    subTitle={subTitle}
                    footerTitle={footerTitle}
                    type={type}
                    handleCallback={() => {
                        return;
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default withTranslation('profile')(IntroTab);
