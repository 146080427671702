import React, { useState } from 'react';
import { CarouselProvider } from 'pure-react-carousel';
import PropTypes from 'prop-types';
import 'pure-react-carousel/dist/react-carousel.es.css';
import CarouselSlider from './slider';
import useStyles from './index.style';

const ProgramCardGrid = (props) => {
    const { data = [] } = props;
    const [visibleSlides, setVisibleSlides] = useState(1);
    const [currentSlide, setCurrentSlide] = useState(0);
    const classes = useStyles();

    const getCarousel = () => {
        return (
            <div className={classes.carouselContainer}>
                <CarouselProvider
                    style={{ marginTop: 26 }}
                    totalSlides={3}
                    step={2}
                    visibleSlides={visibleSlides}
                    isIntrinsicHeight={true}
                    currentSlide={currentSlide}
                    isPlaying={true}
                >
                    <CarouselSlider setSlideCount={setVisibleSlides} setCurrentSlide={setCurrentSlide} data={data} />
                </CarouselProvider>
            </div>
        );
    };

    return <>{getCarousel()}</>;
};

ProgramCardGrid.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.array,
        })
    ),
};

export default ProgramCardGrid;
