import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TechnologyUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/TechnologyUpdate';
import {
    ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_UPDATE,
    ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNT_UPDATE,
    ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENT_AMOUNT_UPDATE,
} from 'fintech/components/util/ActionModalConstants';
import { DialogContent, Grid, Select, MenuItem, FormControl, useTheme, useMediaQuery } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { getCurrencySign } from 'fintech/utils/StringUtils';
import useStyles, { MobileLabel } from './index.style';
import { IconsSingleTooltip } from 'fintech/icons/';
import Tooltip from 'fintech/components/ui/tooltip';
import { isEmpty } from 'lodash';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const getSelectedType = (items) => {
    if (!isEmpty(items.data)) {
        const selectedItem = items.data.find((item) => item.selected);
        return selectedItem ? selectedItem.data : '';
    }
    return '';
};

const PreferencesActionModal = (props) => {
    const { managementTypes, investmentAmounts, companyInvestmentAmounts, changeCallback } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [selectedManagement, setSelectedManamgement] = useState(() => getSelectedType(managementTypes));
    const [selectedInvestment, setSelectedInvestment] = useState(() => getSelectedType(investmentAmounts));
    const [selectedCompanyInvestment, setSelectedCompanyInvestment] = useState(() =>
        getSelectedType(companyInvestmentAmounts)
    );

    const [isSelectedManagementTooltipOpen, setIsSelectedManagementTooltipOpen] = useState(false);
    const [isSelectedInvestmentTooltip, setIsSelectedInvestmentTooltip] = useState(false);
    const [isSelectedCompanyInvestmentTooltipOpen, setSelectedCompanyInvestmentTooltipOpen] = useState(false);

    const managementTypesChangeHandler = (data, valid) => {
        const selectedManagementType = data.find((cur) => cur.selected);

        setSelectedManamgement(selectedManagementType?.data);

        changeCallback({
            type: ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_UPDATE,
            data: {
                id: selectedManagementType?.id,
                validated: valid,
            },
        });
    };

    const investmentAmountChangeHandler = (data, valid) => {
        const selectedInvestmentAmount = data.find((cur) => cur.selected);
        const deleteInvestmentAmountValueId = 6;

        setSelectedInvestment(selectedInvestmentAmount?.data);
        changeCallback({
            type: ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNT_UPDATE,
            data: {
                id: valid ? selectedInvestmentAmount?.id : deleteInvestmentAmountValueId,
                validated: valid,
            },
        });
    };
    const companyInvestmentAmountChangeHandler = (data, valid) => {
        const selectedCompanyInvestmentAmount = data.find((cur) => cur.selected);

        setSelectedCompanyInvestment(selectedCompanyInvestmentAmount?.data);

        changeCallback({
            type: ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENT_AMOUNT_UPDATE,
            data: {
                id: selectedCompanyInvestmentAmount?.id,
                validated: valid,
            },
        });
    };

    const handleSelectedManagementTooltipOpen = () => setIsSelectedManagementTooltipOpen(true);
    const handleSelectedManagementTooltipClose = () => setIsSelectedManagementTooltipOpen(false);

    const handleSelectedInvestmentTooltipOpen = () => setIsSelectedInvestmentTooltip(true);
    const handleSelectedInvestmentTooltipClose = () => setIsSelectedInvestmentTooltip(false);

    const handleSelectedCompanyInvestmentTooltipOpen = () => setSelectedCompanyInvestmentTooltipOpen(true);
    const handleSelectedCompanyInvestmentTooltipClose = () => setSelectedCompanyInvestmentTooltipOpen(false);

    return (
        <>
            <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.container}>
                <Grid item xs={12}>
                    <MobileLabel className={classes.mobile}>
                        {t('profile.establishments.investmentPreferences.titles.managementParticipationType')}
                    </MobileLabel>
                    <DialogContent style={{ padding: '10px 0 0 0' }} className={classes.amountSelect}>
                        <ClickAwayListener onClickAway={handleSelectedManagementTooltipClose}>
                            <Tooltip
                                open={isSelectedManagementTooltipOpen}
                                title={selectedManagement}
                                aria-label={selectedManagement}
                                onClose={handleSelectedManagementTooltipClose}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                            >
                                <span onClick={handleSelectedManagementTooltipOpen}>
                                    <IconsSingleTooltip />
                                </span>
                            </Tooltip>
                        </ClickAwayListener>
                        <TechnologyUpdate
                            label={t('profile.establishments.investmentPreferences.titles.managementParticipationType')}
                            noLabel={isMobile}
                            technologies={managementTypes.data}
                            isLoading={managementTypes.isLoading}
                            isError={managementTypes.isError}
                            changeCallback={(data, valid) => managementTypesChangeHandler(data, valid)}
                            multipleSelect={false}
                            noBottomMargin
                        />
                    </DialogContent>
                </Grid>

                <Grid item xs={12}>
                    <MobileLabel className={classes.mobile}>
                        {t('profile.establishments.investmentPreferences.titles.intendedTotalInvestmentAmount')}
                    </MobileLabel>
                    <Grid container>
                        <Grid item xs={9} sm={10}>
                            <DialogContent className={classes.amountSelect}>
                                <ClickAwayListener onClickAway={handleSelectedInvestmentTooltipClose}>
                                    <Tooltip
                                        open={isSelectedInvestmentTooltip}
                                        title={selectedInvestment}
                                        aria-label={selectedInvestment}
                                        onClose={handleSelectedInvestmentTooltipClose}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >
                                        <span onClick={handleSelectedInvestmentTooltipOpen}>
                                            <IconsSingleTooltip />
                                        </span>
                                    </Tooltip>
                                </ClickAwayListener>
                                <TechnologyUpdate
                                    label={t(
                                        'profile.establishments.investmentPreferences.titles.intendedTotalInvestmentAmount'
                                    )}
                                    noLabel={isMobile}
                                    technologies={investmentAmounts.data}
                                    isLoading={investmentAmounts.isLoading}
                                    isError={investmentAmounts.isError}
                                    changeCallback={(data, valid) => investmentAmountChangeHandler(data, valid)}
                                    multipleSelect={false}
                                    preventValidation
                                    dontSort
                                    noBottomMargin
                                />
                            </DialogContent>
                        </Grid>
                        <Grid item xs={3} sm={2} className={classes.currencyContainer}>
                            <FormControl variant="outlined" className={classes.currencySelect}>
                                <Select variant="outlined" IconComponent={ExpandMore} disabled defaultValue={1}>
                                    <MenuItem value={1}>{getCurrencySign('TRY')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <MobileLabel className={classes.mobile}>
                        {t(
                            'profile.establishments.investmentPreferences.titles.intendedTotalInvestmentAmountForACompany'
                        )}
                    </MobileLabel>
                    <Grid container>
                        <Grid item xs={9} sm={10}>
                            <DialogContent className={classes.amountSelect}>
                                <ClickAwayListener onClickAway={handleSelectedCompanyInvestmentTooltipClose}>
                                    <Tooltip
                                        open={isSelectedCompanyInvestmentTooltipOpen}
                                        title={selectedCompanyInvestment}
                                        aria-label={selectedCompanyInvestment}
                                        onClose={handleSelectedCompanyInvestmentTooltipClose}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >
                                        <span onClick={handleSelectedCompanyInvestmentTooltipOpen}>
                                            <IconsSingleTooltip />
                                        </span>
                                    </Tooltip>
                                </ClickAwayListener>

                                <TechnologyUpdate
                                    label={t(
                                        'profile.establishments.investmentPreferences.titles.intendedTotalInvestmentAmountForACompany'
                                    )}
                                    noLabel={isMobile}
                                    technologies={companyInvestmentAmounts.data}
                                    isLoading={companyInvestmentAmounts.isLoading}
                                    isError={companyInvestmentAmounts.isError}
                                    changeCallback={(data, valid) => companyInvestmentAmountChangeHandler(data, valid)}
                                    multipleSelect={false}
                                    dontSort
                                    noBottomMargin
                                />
                            </DialogContent>
                        </Grid>
                        <Grid item xs={3} sm={2} className={classes.currencyContainer}>
                            <FormControl variant="outlined" className={classes.currencySelect}>
                                <Select variant="outlined" IconComponent={ExpandMore} disabled defaultValue={1}>
                                    <MenuItem value={1}>{getCurrencySign('TRY')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PreferencesActionModal;
