import React from 'react';
import { Grid } from '@material-ui/core';

const CfoFormInput = (props) => {
    return (
        <Grid item xs={12} lg={8} {...props}>
            {props.children}
        </Grid>
    );
};

export default CfoFormInput;
