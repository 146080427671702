import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_SHADE_02, COLOR_PRIMARY_SHADE_03 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    deleteButtonContainer: {
        padding: '2px 11px 2px 6px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        borderRadius: '8px',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        },
        '& > div:last-child': {
            marginLeft: '4px',
        },
    },
});

export default useStyles;
