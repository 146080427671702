import React from 'react';
import InstagramLogo from 'fintech/static/images/icons-single-instagram.svg';
import TwitterLogo from 'fintech/static/images/icons-single-twitter.svg';
import YoutubeLogo from 'fintech/static/images/icons-single-youtube.svg';
import LinkedinLogo from 'fintech/static/images/linkedin@svg.svg';

const SocialMediaIcons = (props) => {
    return (
        <div className={props.iconsWrapperClassname}>
            <a href="https://www.instagram.com/fintechhubtr" target="_blank" rel="noreferrer">
                <img src={InstagramLogo} />
            </a>
            <a href="https://twitter.com/fintechhubtr" target="_blank" rel="noreferrer">
                <img src={TwitterLogo} />
            </a>
            <a href="https://www.youtube.com/channel/UC0M0BuOD2FyB9yxIalJs5RQ" target="_blank" rel="noreferrer">
                <img src={YoutubeLogo} />
            </a>
            <a href="https://www.linkedin.com/company/fintechhubtr" target="_blank" rel="noreferrer">
                <img src={LinkedinLogo} />
            </a>
        </div>
    );
};

export default SocialMediaIcons;
