import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import IconsMultipleNotifications from 'fintech/icons/IconsMultipleNotifications';
import { COLOR_PRIMARY_SHADE_02, poppins500_14px } from 'fintech/components/style/common';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
    },
    logoContainer: {
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        width: '40px',
        height: '40px',
        borderRadius: '8px',
        marginBottom: '8px',
    },
    description: {
        ...poppins500_14px,
    },
});

export default function PlaceHolder() {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Box className={styles.root} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box
                className={styles.logoContainer}
                component="span"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <IconsMultipleNotifications />
            </Box>
            <Typography classes={{ root: styles.description }} display="block">
                {t('notifications.activeNotificationDoesNotExist')}
            </Typography>
        </Box>
    );
}
