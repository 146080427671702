import React from 'react';
import { get } from 'lodash';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

export default function NewInputTextArea({
    value = '',
    validationError,
    validationPath,
    id,
    label,
    placeholder,
    onChange,
    onBlur = () => {},
    rowCount,
    type,
    isCleanText,
    className,
    containerClass,
    maxLength,
    disabled = false,
    hideCountIndicator,
    startAdornment,
    endAdornment,
}) {
    const inputFilter = (e) => {
        if (type === 'integer' && ['e', 'E', ',', '.'].includes(e.key)) {
            e.preventDefault();
        } else if (
            ['tckn', 'vkn', 'identity', 'integer-positive'].includes(type) &&
            !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)
        ) {
            e.preventDefault();
        }
    };

    const handleChange = (event) => {
        let valueToBeChanged = event.target.value;

        if (type === 'integer-positive') {
            if (parseInt(valueToBeChanged) < 0) {
                event.preventDefault();
                return;
            }
        }

        if (isCleanText) {
            valueToBeChanged = valueToBeChanged.trim();
        }

        onChange(valueToBeChanged);
    };

    const getInputType = (type) => {
        if (['integer', 'integer-positive'].includes(type)) {
            return 'number';
        } else if (['number-positive', 'tckn', 'vkn', 'identity'].includes(type)) {
            return 'text';
        } else {
            return type;
        }
    };

    const remainingCount = maxLength - (value ? value.length : 0);

    let inputProps = maxLength ? { maxLength: maxLength } : {};

    let bigInputProps = {};
    if (startAdornment) {
        bigInputProps.startAdornment = startAdornment;
    }
    if (endAdornment) {
        bigInputProps.endAdornment = endAdornment;
    }

    return (
        <div className={'inputTextAreaContainer poppin-500-14px'}>
            <DialogContent className={containerClass || ''}>
                <TextField
                    id={id}
                    disabled={disabled}
                    className={className || ''}
                    error={!!get(validationError, validationPath)}
                    helperText={get(validationError, validationPath) || ' '}
                    label={label}
                    placeholder={placeholder}
                    type={getInputType(type)}
                    multiline={rowCount && rowCount > 0 ? true : false}
                    rowsMax={rowCount && rowCount > 0 ? rowCount : 1}
                    rows={rowCount && rowCount > 0 ? rowCount : 1}
                    variant="outlined"
                    value={value || ''}
                    onKeyPress={inputFilter}
                    onChange={handleChange}
                    InputProps={bigInputProps}
                    inputProps={inputProps}
                    onBlur={onBlur}
                />
                {!hideCountIndicator && maxLength && (
                    <div className={'d-flex align-items-end justify-content-end margin-top-8'}>
                        <div className={'countContainer'}>{remainingCount}</div>
                    </div>
                )}
            </DialogContent>
        </div>
    );
}
