import React from 'react';
import PropTypes from 'prop-types';
import useStyle from './index.style';
import InfoBoxItem from './components/InfoBoxItem';
import { useTranslation } from 'react-i18next';
const Index = ({ items }) => {
    const classes = useStyle();
    const { t } = useTranslation('reports');
    const getText = (index) => {
        switch (index) {
            case 0:
                return t('tooltip.activeStartupCount');
            case 1:
                return t('tooltip.startupApplicationCount');
            case 2:
                return t('tooltip.newStartupCount');
            default:
                return '';
        }
    };
    return (
        <div className={classes.container}>
            {items.map((x, index) => (
                <InfoBoxItem {...x} key={'' + index} tooltipText={getText(index)} />
            ))}
        </div>
    );
};

Index.propTypes = {
    items: PropTypes.arrayOf().isRequired,
};
Index.defaultProps = {
    items: [],
};

export default Index;
