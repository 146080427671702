import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useStyles } from 'fintech/components/dashboard/eventdetail/evendetailgrid/EventDetailGrid.style';
import ProgramDetail from 'fintech/components/support/program/detail/ProgramDetail';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchViewedProgram } from 'fintech/store/actions/supportProgram';
import { useKeycloak } from '@react-keycloak/web';
import upperImage from 'fintech/static/images/destek-programlari-detay.jpg';
import useStylesEmpty from 'fintech/components/dashboard/event/eventcardgrid/index.style';
import { useTranslation } from 'react-i18next';
import Empty from 'fintech/components/util/Empty';
import ProgramProcessGrid from '../ProgramProcessGrid';

const ProgramDetailGrid = () => {
    const { t } = useTranslation('supportProgram');
    const classes = useStyles();
    const { viewedProgram, isLoading, isError } = useSelector((state) => ({
        viewedProgram: state.supportProgram.viewed.program,
        isLoading: state.supportProgram.viewed.isLoading,
        isError: state.supportProgram.viewed.isError,
    }));
    const [program, setProgram] = useState(null);
    const { id } = useParams();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchViewedProgram(keycloak, id));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [id]);

    useEffect(() => {
        viewedProgram ? setProgram(viewedProgram) : null;
    }, [viewedProgram]);

    const getProgramDetailsJSX = () => {
        return (
            <>
                <Grid item xs={12}>
                    <img alt={'support-program-up-image'} className={classes.EventDetailUpImage} src={upperImage} />
                </Grid>
                <ProgramDetail {...program} />
            </>
        );
    };

    let emptyProps = {};
    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('details.errorMessage');
    }

    return (
        <>
            {isLoading || isError ? (
                <Empty {...emptyProps} useStyles={useStylesEmpty} />
            ) : (
                <>
                    <Grid className={classes.EventDetailGrid} container>
                        {program ? getProgramDetailsJSX() : null}
                    </Grid>
                    <Box height="32px" />
                    {program?.current && <ProgramProcessGrid />}
                </>
            )}
        </>
    );
};
export default ProgramDetailGrid;
