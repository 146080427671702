import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    RepInfoDialog: {
        marginTop: '0',
        padding: '0 16px 16px',
        margin: '0 24px 20px',
        borderRadius: '4px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
    }
});

export default useStyles;
