import React, {useEffect, useState} from 'react';

import { makeChatEntryStyles } from "./ChatEntry.style";
import {Typography} from "@material-ui/core";
import {getRequest} from "fintech/api/api";
import {getPublicFileUrl} from "fintech/api/fileService";
import { APIS } from 'fintech/constants';
import {useKeycloak} from "@react-keycloak/web";

const ChatEntry = ({ conversationTitle, lastMessage, clientUuid, lastMessageSeen, onClick }) => {
    const { keycloak } = useKeycloak();
    const chatEntryStyles = makeChatEntryStyles();
    const [profilePictureSrc, setProfilePictureSrc] = useState(null);

    useEffect(() => {
        setProfileImage();
    }, []);

    const setProfileImage = () => {
        getRequest(APIS.USER.getUserImage(clientUuid), keycloak).then((data) => {
            const fileUuid = data.uuid;
            setProfilePictureSrc(getPublicFileUrl(fileUuid));
        });
    };

    return <div className={chatEntryStyles.grid} onClick={onClick}>
        <img src={profilePictureSrc} className={chatEntryStyles.profilePicture} />
        <div className={chatEntryStyles.info}>
            <Typography className={`${chatEntryStyles.title} ${!lastMessageSeen ? chatEntryStyles.notSeenTitle : chatEntryStyles.seenTitle}`}>{conversationTitle}</Typography>
            <Typography className={`${chatEntryStyles.message} ${!lastMessageSeen ? chatEntryStyles.notSeenMessage : chatEntryStyles.seenMessage}`}>{lastMessage}</Typography>
        </div>
    </div>;
};

export default ChatEntry;
