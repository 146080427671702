import { makeStyles } from '@material-ui/core';
import { fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    titleTypo: {
        ...fontStyle,
        fontSize: '32px',
        marginBottom: '32px',
        paddingLeft: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
}));

export default useStyles;
