export const mobileMicroQuery = '(min-width:300px)';
export const mobile360pxQuery = '(min-width:360px)';
export const mobile450pxQuery = '(min-width:450px)';
export const mobileSmallQuery = '(min-width:600px)';
export const mobileMediaQuery = '(min-width:900px)';
export const laptopLarge = '(min-width:1025px)';
export const laptopLargeMax = '(max-width: 1025px)';
export const desktopLargeQuery = `(min-width:1280px)`;

export const COLOR_PRIMARY_BASE = '#5b7adb';
export const COLOR_PRIMARY_SHADE_01 = '#e6ebfa';
export const COLOR_PRIMARY_SHADE_02 = '#f1f5f7';
export const COLOR_PRIMARY_SHADE_03 = '#d7e2e8';
export const COLOR_PRIMARY_BUTTON_HOVERED = '#395ac2';
export const COLOR_PRIMARY_BUTTON_DISABLED = '#adbcec';
export const COLOR_PRIMARY_BUTTON_DISABLED_TEXT = '#d6def6';
export const COLOR_THEME_FILL_01 = '#ffffff';
export const COLOR_SECONDARY_SHADE_01 = '#98a3ac';
export const COLOR_SECONDARY_BASE = '#3f5162';
export const COLOR_SECONDARY_BUTTON_DISABLED = '#f8fafb';
export const COLOR_SECONDARY_BUTTON_DISABLED_TEXT = '#9ca6ae';
export const COLOR_THEME_FILL_02 = '#000000';
export const COLOR_THEME_FILL_06 = '#e6fae6';
export const COLOR_THEME_FILL_03 = '#623f3f';
export const COLOR_THEME_FILL_05 = '#17480e';
export const COLOR_THEME_FILL_04 = '#fae6e6';
export const COLOR_THEME_FILL_07 = '#4d609d';
export const COLOR_THEME_FILL_08 = '#0b5058';
export const COLOR_THEME_FILL_09 = '#3C4D5D';
export const COLOR_THEME_FILL_10 = '#7e4668';
export const COLOR_DANGER = '#c0392b';
export const COLOR_MUI_ERROR = '#f44336';
export const COLOR_DARK_BLUE_GREY = '#102738';
export const COLOR_ORANGE = '#f8c99d';
export const COLOR_BLUE = '#9db9f8';
export const COLOR_PURPLE = '#9d9ef8';
export const COLOR_RED_BUTTON_HOVERED = '#f1d1d1';
export const COLOR_ROW_HOVERED = '#f9fafb';
export const COLOR_MUI_OUTLINED_BORDER = 'rgb(189, 189, 189)';
export const COLOR_GREEN = '#27ae60';

export const fontStyle = {
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
};

export const buttonStyle = {
    backgroundColor: COLOR_PRIMARY_BASE,
    borderRadius: '8px',
    textTransform: 'none',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
        color: '#ffffff',
    },
    '&.Mui-disabled': {
        backgroundColor: `${COLOR_PRIMARY_BUTTON_DISABLED} !important`,
        color: COLOR_PRIMARY_BUTTON_DISABLED_TEXT,
    },
};

export const buttonStyleColorAlt = {
    backgroundColor: COLOR_PRIMARY_SHADE_02,
    borderRadius: '8px',
    textTransform: 'none',
    color: COLOR_SECONDARY_BASE,
    '&:hover': {
        backgroundColor: COLOR_PRIMARY_SHADE_03,
        color: COLOR_SECONDARY_BASE,
    },
    '&.Mui-disabled': {
        backgroundColor: `${COLOR_SECONDARY_BUTTON_DISABLED} !important`,
        color: COLOR_SECONDARY_BUTTON_DISABLED_TEXT,
    },
};

export const buttonStyleInteraction = {
    backgroundColor: COLOR_PRIMARY_SHADE_02,
    color: COLOR_SECONDARY_BASE,
    '&:hover': {
        backgroundColor: COLOR_PRIMARY_SHADE_03,
        color: COLOR_SECONDARY_BASE,
    },
};

export const buttonStyleGridClear = {
    backgroundColor: COLOR_THEME_FILL_04,
    color: COLOR_THEME_FILL_03,
    '&:hover': {
        backgroundColor: COLOR_RED_BUTTON_HOVERED,
        color: COLOR_THEME_FILL_03,
    },
};

export const splitButtonTagsNotInDatagridGroupStyle = {
    marginBottom: 0,
    marginTop: 6,
};

export const splitButtonNotInDatagridGroupStyle = {
    verticalAlign: 'super',
    marginTop: 6,
};

export const styledTagInSimilarVenturesStyle = {
    marginBottom: 0,
    marginTop: 0,
};

export const styledTagNotInDatagridStyle = {
    marginBottom: 0,
    marginTop: 8,
};

export const splitButtonTagsInCardGroupStyle = {
    marginBottom: 0,
    marginTop: 1,
};

export const styledTagInCardStyle = {
    marginBottom: 0,
    marginTop: 1,
};

export const tagStyle = {
    fontSize: '12px',
    color: COLOR_SECONDARY_BASE,
    backgroundColor: COLOR_PRIMARY_SHADE_02,
    borderRadius: '8px',
    height: '24px',
    padding: '10px 15px',
    marginRight: '8px',
    marginTop: '11px',
    '&:hover': {
        color: COLOR_SECONDARY_BASE,
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        boxShadow: 'none',
        cursor: 'default',
    },
};

export const emptyStyle = {
    BgIconRev: {
        transform: 'rotate(180deg)',
    },
    BgPara: {
        ...fontStyle,
        marginTop: '8px',
        fontSize: '18px',
        textAlign: 'center',
        color: COLOR_SECONDARY_BASE,
    },
    iconGrid: {
        marginTop: '34px',
    },
};

export const loadingSpinnerWithTextGeneralTextStyle = {
    minHeight: 'inherit',
    verticalAlign: 'middle',
};

export const cardGridStyle = {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
};

export const popperStyle = {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    borderRadius: '4px',
    fontSize: '12px',
    color: COLOR_SECONDARY_BASE,
    padding: '5px 10px',
};

export const selectInputEmptyStyle = {
    textAlign: 'center',
    minHeight: '54px',
};

export const poppins500_14px = {
    fontFamily: ' Poppins',
    fontSize: ' 14px',
    fontWeight: ' 500',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' normal',
    letterSpacing: ' normal',
    color: COLOR_SECONDARY_BASE,
};
export const poppins600_10px = {
    fontFamily: 'Poppins',
    fontSize: '10px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: COLOR_SECONDARY_BASE,
};

export const poppins500_12px = {
    ...poppins500_14px,
    fontSize: '12px',
};

export const poppins600_12px = {
    ...poppins600_10px,
    fontSize: '12px',
};

export const poppins600_14px = {
    ...poppins600_10px,
    fontSize: '14px',
};

export const poppinsBold_18px = {
    ...poppins600_10px,
    fontSize: '18px',
    fontWeight: 'Bold',
};

export const poppinsBold_12px = {
    ...poppins600_10px,
    fontSize: '12px',
    fontWeight: 'Bold',
};

export const poppins_16px = {
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: COLOR_SECONDARY_BASE,
};

export const poppins_14px = {
    fontFamily: 'Poppins',
    fontSize: '14px',
};

export const poppins600_24px = {
    ...poppins600_10px,
    fontSize: '24px',
};
