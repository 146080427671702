import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, Grid } from '@material-ui/core';
import CfoDatePicker from 'fintech/components/ui/form/picker/CfoDatePicker';
import VisibilityToggle from 'fintech/components/ui/form/toggle/VisibilityToggle';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'date-fns';
import { ISO_FORMAT } from 'fintech/utils/DateUtils';
import { getInvestorOrStartupFormValueFromState } from 'fintech/utils/DataParser';

const InvestorDateUpdate = (props) => {
    const { type, setInvestmentDate } = props;
    const { t } = useTranslation();
    const {
        investmentDate,
        investmentDateIsValid,
        investmentDateVisible,
        investmentVisible,
        sending,
    } = useSelector((state) => getInvestorOrStartupFormValueFromState(state, type));

    const dispatch = useDispatch();

    const onChangeHandler = ({ date, isVisible, isValid }) =>
        dispatch(
            setInvestmentDate({
                dateISOStr: date,
                isVisible: isVisible,
                isValid: isValid,
            })
        );

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item>
                <CfoDatePicker
                    id="startup-investment-date-picker"
                    label={t('profile.financials.investors.investmentDate')}
                    invalidDateMessage={investmentDate ? t('validation.date') : t('validation.notEmpty')}
                    autoOk
                    openTo="year"
                    disableFuture
                    maxDateMessage={t('validation.noFutureDate')}
                    initialDate={investmentDate ? parse(investmentDate, ISO_FORMAT, new Date()) : null}
                    onChange={(date, isValid) =>
                        onChangeHandler({ date: date, isValid: isValid, isVisible: investmentDateVisible })
                    }
                    required
                    validateOnBlur
                    validatedAfterFormSubmit={!sending}
                />
            </Grid>
            <Grid item>
                <DialogContent style={{ paddingTop: '0', textAlign: 'end' }}>
                    <VisibilityToggle
                        initialValue={investmentDateVisible}
                        disabled={!investmentVisible}
                        useDisabledValue
                        disabledValue={false}
                        useEnabledValue
                        enabledValue={investmentDateVisible}
                        onChange={(isVisible) =>
                            onChangeHandler({
                                isVisible: isVisible,
                                date: investmentDate,
                                isValid: investmentDateIsValid,
                            })
                        }
                    />
                </DialogContent>
            </Grid>
        </Grid>
    );
};

export default InvestorDateUpdate;
