import React from 'react';

function IconsSingleMsortUp(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill={props.color}>
                    <path d="M12 4c.337 0 .661.127.906.354l.088.09 3.16 3.525c.377.422.453 1.021.193 1.528-.21.413-.615.681-1.073.72l-.115.005H8.84c-.505 0-.96-.278-1.188-.725-.241-.47-.193-1.021.117-1.435l.077-.094 3.159-3.524C11.258 4.16 11.62 4 12 4z" />
                </g>
            </g>
        </svg>
    );
}

IconsSingleMsortUp.defaultProps = {
    color: '#98A3AC',
};

export default IconsSingleMsortUp;
