import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkedIn, Instagram, Twitter, YouTube } from '@material-ui/icons';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import useStyles from 'fintech/components/profile/user/modal/tabs/SocialMediaTab/index.style';
import { COLOR_PRIMARY_BASE, COLOR_SECONDARY_SHADE_01 } from 'fintech/components/style/common';
import useInputTextAreaStyle from 'fintech/components/support/program/form/SupportProgramApplication/index.style';
import { isNil } from 'lodash';

const SOCIAL_MEDIA_PLATFORMS = {
    Instagram: { C: Instagram },
    Twitter: { C: Twitter },
    Youtube: { C: YouTube },
    Linkedin: { C: LinkedIn },
};

const SocialMediaUrlInput = ({ onChangeCb, style, value, styleClass }) => {
    const classes = useStyles();
    const inputTextAreaClasses = useInputTextAreaStyle();
    const { t } = useTranslation('supportProgramApplication');

    const [urls, setUrls] = useState(value ? value : null);
    const [selectedSocialMedia, setSelectedSocialMedia] = useState(isNil(value) ? null : Object.keys(value)?.[0]);
    const [selectedAnswerNumber, setSelectedAnswerNubmer] = useState(null);

    useEffect(() => {
        if (value) setUrls(value);
    }, [value]);

    const onClickHandler = ({ socialMedia, index }) => {
        setSelectedAnswerNubmer(index + 2);
        setSelectedSocialMedia(socialMedia);
    };

    const onChangeHandler = (val, validated) => {
        setUrls((prevState) => {
            const newState = {
                ...prevState,
                [selectedSocialMedia]: {
                    value: val,
                    validated: validated,
                    answerNumber: selectedAnswerNumber,
                },
            };

            onChangeCb(newState);
        });
    };

    return (
        <div style={style} className={styleClass}>
            {Object.entries(SOCIAL_MEDIA_PLATFORMS).map(([socialMedia, { C }], index) => (
                <C
                    className={
                        selectedSocialMedia === socialMedia ? classes.ActiveSocialMediaIcon : classes.SocialMediaIcon
                    }
                    key={socialMedia}
                    onClick={() => onClickHandler({ socialMedia, index })}
                    style={index === 0 ? { marginBottom: '16px', marginLeft: '0' } : { marginBottom: '16px' }}
                    fill={selectedSocialMedia === socialMedia ? COLOR_PRIMARY_BASE : COLOR_SECONDARY_SHADE_01}
                />
            ))}
            <InputTextArea
                type="url"
                placeholder={
                    selectedSocialMedia
                        ? t('form.urlAddress', { name: selectedSocialMedia })
                        : t('formPlaceholder.select')
                }
                onChange={onChangeHandler}
                disabled={selectedSocialMedia === null}
                key={selectedSocialMedia}
                initText={urls?.[selectedSocialMedia]?.value ? urls[selectedSocialMedia].value : ''}
                containerClass={inputTextAreaClasses.socialMediaTextInputWrapper}
                validationText={t('validation.url')}
                validateOnBlur
            />
        </div>
    );
};
export default SocialMediaUrlInput;
