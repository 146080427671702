import React, { useEffect, useRef, useState } from 'react';
import { DialogContent, Typography } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import { tr, enUS } from 'date-fns/locale';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import { LANGUAGE_CODES } from 'fintech/constants';
import { COLOR_MUI_ERROR, COLOR_THEME_FILL_01 } from 'fintech/components/style/common';
import { ISO_FORMAT } from 'fintech/utils/DateUtils';

const CfoDatePicker = (props) => {
    const {
        initialDate,
        dateFormat = ISO_FORMAT,
        onChange,
        invalidDateMessage,
        required,
        dontValidate,
        validateOnBlur,
        validatedAfterFormSubmit = true,
        initiallyValidated,
        minDate,
        minDateMessage = '',
        containerClass,
        disabled,
        ...others
    } = props;
    const [selectedDate, setSelectedDate] = useState(initialDate ? initialDate : null);
    const [validated, setValidated] = useState(validateOnBlur ? true : validate(selectedDate));
    const [minimumDate, setMinimumDate] = useState(minDate);
    const pickerRef = useRef();

    useEffect(() => {
        if (initiallyValidated) {
            isValid(initialDate) && onChange(format(initialDate, dateFormat), true);
        }
    }, []);

    useEffect(() => {
        if (dontValidate && validatedAfterFormSubmit) setValidated(true);
    }, [dontValidate]);

    useEffect(() => {
        if (!validatedAfterFormSubmit) {
            setValidated(validate(selectedDate));
        }
    }, [validatedAfterFormSubmit]);

    useEffect(() => {
        minDate && setMinimumDate(minDate);
    }, [minDate]);

    // Used if the disabled value of the component is provided later
    useEffect(() => {
        disabled && initialDate && setSelectedDate(initialDate);
        disabled && initialDate && setValidated(true);
    }, [initialDate]);

    // Return formatted date if valid, return the date object otherwise
    const changeHandler = (date) => {
        setSelectedDate(date);

        if (!validateOnBlur) {
            setValidated(validate(date));
        }

        const returnDate = isValid(date) ? format(date, dateFormat) : date;
        onChange && onChange(returnDate, validate(date));
    };

    // TODO: Can be extended to cover more locales
    // TODO: Can be moved under utilities
    const getLocale = () => {
        return getSystemLanguage() === LANGUAGE_CODES.TR ? tr : enUS;
    };

    // TODO: Can be extendedto cover more languages & formats
    // TODO: Can be moved under utilities
    const getPickerFormat = () => {
        return 'dd/MM/yyyy';
    };

    // TODO: Can be extended to cover more properties
    // TODO: Can be moved under utilities
    function validate(date) {
        let valid = isValid(date);
        if (others.disableFuture) {
            valid = valid && !isAfter(date, new Date());
        }

        if (minimumDate) {
            valid = valid && !isBefore(date, minimumDate);
        }

        return !required ? isValid(date) : valid;
    }

    return (
        <DialogContent className={`cfo-date-picker ${containerClass}`}>
            <MuiPickersUtilsProvider locale={getLocale()} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    {...others}
                    innerRef={pickerRef}
                    disabled={disabled}
                    invalidDateMessage={!validated ? invalidDateMessage : ''}
                    error={!validated}
                    style={{ marginTop: '0', marginBottom: '0', width: '100%' }}
                    disableToolbar
                    keyboardIcon={<DateRange style={{ backgroundColor: COLOR_THEME_FILL_01, zIndex: 1 }} />}
                    variant="inline"
                    inputVariant="outlined"
                    format={getPickerFormat()}
                    margin="normal"
                    onChange={changeHandler}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    minDate={minimumDate}
                    minDateMessage={!validated ? minDateMessage : ''}
                    value={selectedDate}
                    onBlur={
                        validateOnBlur
                            ? () => {
                                  setValidated(validate(selectedDate));
                              }
                            : () => {}
                    }
                    onOpen={() => {
                        setValidated(true);
                    }}
                    onClose={() => {
                        /**
                         * - inputRef prop of the KeyboardDatePicker returns 'undefined'.
                         *
                         * - onClose does not provide the latest value of the input component.
                         *
                         * - When a date is selected from the picker, onClose is fired immediately after onAccept.
                         *   This causes the validation to be performed on the previous selected date.
                         *
                         * Therefore, the latest value of the <input> had to be retrieved using the innerRef prop.
                         */
                        setValidated(
                            validate(
                                parse(
                                    pickerRef.current.getElementsByClassName('MuiInputBase-input')?.[0].value,
                                    getPickerFormat(),
                                    new Date()
                                )
                            )
                        );
                    }}
                    onAccept={(date) => {
                        setValidated(validate(date));
                    }}
                />
            </MuiPickersUtilsProvider>
            {!validated && !dontValidate && !selectedDate && (
                <div className={'d-flex align-items-start justify-content-start margin-top-4'}>
                    <Typography style={{ color: COLOR_MUI_ERROR, fontSize: '12px', marginLeft: '14px' }}>
                        {invalidDateMessage}
                    </Typography>
                </div>
            )}
        </DialogContent>
    );
};

export default CfoDatePicker;
