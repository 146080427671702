import { makeStyles } from '@material-ui/core';

const mediaGridTileCommon = {
    paddingBottom: 0,
    textAlign: 'center',
    margin: 'auto',
};
const useStyles = makeStyles({
    MediaGridTile: {
        ...mediaGridTileCommon,
        cursor: 'pointer',
    },
    MediaGridTilePlaceholder: {
        ...mediaGridTileCommon,
    },
});

export default useStyles;
