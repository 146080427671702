import React from 'react';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import { DetailContent, DetailTitle } from 'fintech/components/membership/Applications/index.style';
import RepresentativeInfos from 'fintech/components/membership/Sections/RepresentativeInfos';
import { useTranslation } from 'react-i18next';

const getInvestorSections = (titles, infos) => {
    return [
        { title: titles.generalInfos, component: <GeneralInfos infos={infos} /> },
        { title: titles.representativeInfos, component: <RepresentativeInfos infos={infos} /> },
    ];
};

export default getInvestorSections;

const GeneralInfos = ({ infos }) => {
    const itemsJSX = [];
    const { t } = useTranslation('membershipManagement');

    itemsJSX.push(
        <IntroGridItem
            key="name"
            htmlBind={false}
            title={
                <DetailTitle>
                    {t(
                        `applicationDetails.sections.investorGeneralInfos.name.${
                            infos.applicationType || 'INVESTOR_INDIVIDUAL'
                        }`
                    )}
                </DetailTitle>
            }
            content={<DetailContent>{infos.companyName || '-'}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="identityNumber"
            htmlBind={false}
            title={
                <DetailTitle>
                    {t(
                        `applicationDetails.sections.investorGeneralInfos.identityNumber.${
                            infos.applicationType || 'INVESTOR_INDIVIDUAL'
                        }`
                    )}
                </DetailTitle>
            }
            content={<DetailContent>{infos.companyIdentityNumber || '-'}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="investorsEmailAddress"
            htmlBind={false}
            title={
                <DetailTitle>{t('applicationDetails.sections.investorGeneralInfos.investorsEmailAddress')}</DetailTitle>
            }
            content={<DetailContent>{infos.companyEmail || '-'}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="preliminaryInfo"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.investorGeneralInfos.preliminaryInfo')}</DetailTitle>}
            content={<DetailContent>{infos.coverLetter || '-'}</DetailContent>}
        />
    );

    return itemsJSX;
};
