import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_BASE } from 'fintech/components/style/common';

const defaultSelectionText = {
    fontSize: '14px',
    marginRight: '20px',
};
const useStyles = makeStyles(() => ({
    languageDiv: {
        marginRight: '10px',
        cursor: 'pointer',
    },
    languageDownIcon: {
        '& *': {
            fill: 'white !important',
        },
    },
    selectedLanguageLabel: {
        color: 'white',
    },
    languageSelectorMenu: {
        '& .MuiPopover-paper': {
            borderRadius: '4px',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        },
    },
    selectionMenuElement: {
        display: 'flex',
    },
    selectionFlagDiv: {
        marginRight: '6px',
        '& svg': {
            verticalAlign: 'inherit',
        },
    },
    selectionText: {
        ...defaultSelectionText,
    },
    currentlySelectedSelectionText: {
        ...defaultSelectionText,
        color: COLOR_PRIMARY_BASE,
    },
}));

export default useStyles;
