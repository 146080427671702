import Keycloak from 'keycloak-js';

const keycloakClientId =
    process.env.ADMIN_URL === window.location.origin ? process.env.KC_CLIENT_ADMIN : process.env.KC_CLIENT;

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
export const keycloakClient = new Keycloak({
    url: `${process.env.KC_BASE_URL}`,
    realm: `${process.env.KC_REALM}`,
    clientId: `${keycloakClientId}`,
});

export const keycloakInitOptions = { pkceMethod: 'S256' };
