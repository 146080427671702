import React, { useEffect, useState } from 'react';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Add, HighlightOff } from '@material-ui/icons';
import {
    Grid,
    Radio,
    Button,
    RadioGroup,
    IconButton,
    Typography,
    useMediaQuery,
    FormControlLabel,
    FormControl,
    FormHelperText,
} from '@material-ui/core';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import CfoFormLabel from 'fintech/components/applications/form/common/CfoFormLabel';
import { SupportProgramApplicationActions } from 'fintech/store/actions/ActionTypes';
import { mobileMediaQuery, loadingSpinnerWithTextGeneralTextStyle } from 'fintech/components/style/common';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import useStyles from 'fintech/components/support/program/form/SupportProgramApplication/index.style';
import {
    CATEGORY_FOUNDER_INFO,
    checkboxAction,
    generateFounderInfoAnswers,
    generateRepInfoData,
    isChecked,
    shouldShowInput,
} from 'fintech/components/support/program/utils/SupportProgramApplicationUtils';
import { isEmpty } from 'lodash';
import CfoSingleCheckbox from 'fintech/components/ui/form/checkbox/CfoSingleCheckbox';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import * as FintechBeService from 'fintech/api/fintechService';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const FounderAndTeamInformation = ({ pageFunctions: { prevPage, nextPage }, formId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation('supportProgramApplication');
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const { form, hasActiveRequest } = useSelector((state) => ({
        ...state.supportProgramApplication,
        ...state.common,
    }));
    const matches = useMediaQuery(mobileMediaQuery);

    const [sending, setSending] = useState(false);

    useEffect(() => {
        if (form.questionFormatList && form.questionFormatList[18] && !form.questionFormatList[18].freeTextAnswerList)
            dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_INIT });
        if (form.questionFormatList && form.questionFormatList[19] && !form.questionFormatList[19].freeTextAnswerList)
            dispatch({ type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_INIT });
    }, [form]);

    const questions = form.questionFormatList.slice(17, 27);

    const onCheckboxChange = (event, type, id, question) => {
        dispatch(checkboxAction(event, type, id, question));
    };

    const getCorrespondingInput = (question) => {
        switch (question.questionNumber) {
            case 18:
                return (
                    <Grid container direction="row">
                        <Grid item xs={11}>
                            <InputTextArea
                                type="integer-positive"
                                includeZero
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q18,
                                        data: { value, validated },
                                    });
                                }}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                hideCountIndicator
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 19: {
                return (
                    <>
                        <Grid container direction="row" spacing={1}>
                            {question?.freeTextAnswerList?.linkedIn.map((urlData, index) => {
                                const showRemoveButton = index !== 0;
                                const required = question.required === 'REQUIRED';

                                return (
                                    <Grid key={urlData.id} item container direction="row">
                                        <Grid item xs={11}>
                                            <InputTextArea
                                                type="url"
                                                label="Linkedin URL"
                                                onChange={(value, validated) => {
                                                    dispatch({
                                                        type:
                                                            SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1,
                                                        data: {
                                                            value,
                                                            validated,
                                                            index,
                                                        },
                                                    });
                                                }}
                                                required={required}
                                                count={question.answerDefinitionList[0].textLimit}
                                                containerClass={classes.inputTextAreaWrapper}
                                                validationText={t('validation.url')}
                                                validateOnBlur
                                                validatedAfterFormSubmit={!sending}
                                                initText={urlData.value}
                                            />
                                        </Grid>
                                        {showRemoveButton && (
                                            <Grid
                                                container
                                                item
                                                xs={1}
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                            >
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() => {
                                                        dispatch({
                                                            type:
                                                                SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1_REMOVE,
                                                            data: {
                                                                id: urlData.id,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <HighlightOff style={{ color: '#f44336' }} />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <div className={classes.addButtonWrapper}>
                            <Button
                                size="small"
                                startIcon={<Add />}
                                className={classes.addButton}
                                onClick={() => {
                                    dispatch({
                                        type:
                                            SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1_ADD,
                                    });
                                }}
                            >
                                {t('form.addNewLine')}
                            </Button>
                        </div>
                        <Grid container direction="row" spacing={1}>
                            {question?.freeTextAnswerList?.github.map((urlData, index) => {
                                const showRemoveButton = index !== 0;
                                const required = question.required === 'REQUIRED';
                                return (
                                    <Grid key={urlData.id} item container direction="row">
                                        <Grid item xs={11}>
                                            <InputTextArea
                                                type="url"
                                                label="Github URL"
                                                onChange={(value, validated) => {
                                                    dispatch({
                                                        type:
                                                            SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2,
                                                        data: {
                                                            value,
                                                            validated,
                                                            index,
                                                        },
                                                    });
                                                }}
                                                required={required}
                                                count={question.answerDefinitionList[0].textLimit}
                                                containerClass={classes.inputTextAreaWrapper}
                                                validationText={t('validation.url')}
                                                validateOnBlur
                                                validatedAfterFormSubmit={!sending}
                                                initText={urlData.value}
                                            />
                                        </Grid>
                                        {showRemoveButton && (
                                            <Grid
                                                container
                                                item
                                                xs={1}
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                            >
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() => {
                                                        dispatch({
                                                            type:
                                                                SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2_REMOVE,
                                                            data: {
                                                                id: urlData.id,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <HighlightOff style={{ color: '#f44336' }} />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <div className={classes.addButtonWrapper}>
                            <Button
                                size="small"
                                startIcon={<Add />}
                                className={classes.addButton}
                                onClick={() => {
                                    dispatch({
                                        type:
                                            SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2_ADD,
                                    });
                                }}
                            >
                                {t('form.addNewLine')}
                            </Button>
                        </div>
                    </>
                );
            }
            case 20:
                return (
                    <>
                        <Grid container direction="row" spacing={1}>
                            {question?.freeTextAnswerList?.map((nameData, index) => {
                                const showRemoveButton = index !== 0;
                                const required = question.required === 'REQUIRED';
                                return (
                                    <Grid key={nameData.id} item container direction="row">
                                        <Grid xs={11} item>
                                            <InputTextArea
                                                type="text"
                                                placeholder={t('formLabel.nameSurname')}
                                                onChange={(value, validated) => {
                                                    dispatch({
                                                        type:
                                                            SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20,
                                                        data: {
                                                            value,
                                                            validated,
                                                            index,
                                                        },
                                                    });
                                                }}
                                                required={required}
                                                count={question.answerDefinitionList[0].textLimit ?? 100}
                                                hideCountIndicator
                                                containerClass={classes.inputTextAreaWrapper}
                                                validationText={t('validation.notEmpty')}
                                                validateOnBlur
                                                validatedAfterFormSubmit={!sending}
                                                initText={nameData.value}
                                            />
                                        </Grid>
                                        {showRemoveButton && (
                                            <Grid
                                                container
                                                item
                                                xs={1}
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                            >
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() => {
                                                        dispatch({
                                                            type:
                                                                SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_REMOVE,
                                                            data: {
                                                                id: nameData.id,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <HighlightOff style={{ color: '#f44336' }} />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            })}
                            <div className={classes.founderAddButtonWrapper}>
                                <Button
                                    size="small"
                                    startIcon={<Add />}
                                    className={classes.addButton}
                                    onClick={() => {
                                        dispatch({
                                            type:
                                                SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_ADD,
                                        });
                                    }}
                                >
                                    {t('form.addNewLine')}
                                </Button>
                            </div>
                        </Grid>
                    </>
                );
            case 21:
                return (
                    <Grid container direction="row">
                        <Grid item xs={11}>
                            <InputTextArea
                                type="integer-positive"
                                includeZero
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q21,
                                        data: { value, validated },
                                    });
                                }}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                hideCountIndicator
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 22: {
                const { answerOptionList } = question.answerDefinitionList[0];

                const val = question?.selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id;
                const error = sending && question.required === 'REQUIRED';
                return (
                    <FormControl>
                        <RadioGroup
                            className={classes.radioWrapper}
                            row
                            value={val ? `${val}` : ''}
                            onChange={({ target: { value } }) => {
                                dispatch({
                                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q22,
                                    data: { value: +value },
                                });
                            }}
                        >
                            {answerOptionList.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    value={`${option.id}`}
                                    control={<Radio classes={{ colorPrimary: classes.radio }} color="primary" />}
                                    label={option.name}
                                />
                            ))}
                        </RadioGroup>
                        {error && (
                            <FormHelperText className={classes.formHelperWrapper} error>
                                {t('validation.selectionNotEmpty')}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }
            case 23: {
                const { answerOptionList } = question.answerDefinitionList[0];
                const error = sending && question.required === 'REQUIRED';

                return (
                    <>
                        <FormControl error={error}>
                            <Grid container className={classes.checkboxWrapper}>
                                {answerOptionList.map(({ id, name }) => (
                                    <Grid xs={12} key={id} item>
                                        <CfoSingleCheckbox
                                            returnEvent
                                            checked={isChecked(question, id)}
                                            onChange={(event) =>
                                                onCheckboxChange(
                                                    event,
                                                    SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q23_P1,
                                                    id,
                                                    question
                                                )
                                            }
                                            label={name}
                                        />
                                    </Grid>
                                ))}
                                {error && (
                                    <Grid xs={12} item>
                                        <FormHelperText className={classes.checkboxHelperWrapper} error>
                                            {t('validation.selectionNotEmpty')}
                                        </FormHelperText>
                                    </Grid>
                                )}
                            </Grid>
                        </FormControl>
                        {shouldShowInput(question) && (
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q23_P2,
                                        data: { value, validated },
                                    });
                                }}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[1].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        )}
                    </>
                );
            }
            case 24: {
                const { answerOptionList } = question.answerDefinitionList[0];

                const val = question?.selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id;
                const error = sending && !val;
                return (
                    <FormControl error={error}>
                        <RadioGroup
                            className={classes.radioWrapper}
                            row
                            value={val ? `${val}` : ''}
                            onChange={({ target: { value } }) => {
                                dispatch({
                                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q24,
                                    data: { value: +value },
                                });
                            }}
                        >
                            {answerOptionList.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    value={`${option.id}`}
                                    control={<Radio classes={{ colorPrimary: classes.radio }} color="primary" />}
                                    label={option.name}
                                />
                            ))}
                        </RadioGroup>
                        {error && (
                            <FormHelperText className={classes.formHelperWrapper} error>
                                {t('validation.selectionNotEmpty')}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }
            case 25: {
                const { answerOptionList } = question.answerDefinitionList[0];
                const error = sending && question.required === 'REQUIRED';
                return (
                    <>
                        <FormControl error={error}>
                            <Grid container className={classes.checkboxWrapper}>
                                {answerOptionList.map(({ id, name }) => (
                                    <Grid xs={6} sm={4} key={id} item>
                                        <CfoSingleCheckbox
                                            returnEvent
                                            checked={isChecked(question, id)}
                                            onChange={(event) =>
                                                onCheckboxChange(
                                                    event,
                                                    SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q25_P1,
                                                    id,
                                                    question
                                                )
                                            }
                                            label={name}
                                        />
                                    </Grid>
                                ))}
                                {error && (
                                    <Grid xs={12} item>
                                        <FormHelperText className={classes.checkboxHelperWrapper} error>
                                            {t('validation.selectionNotEmpty')}
                                        </FormHelperText>
                                    </Grid>
                                )}
                            </Grid>
                        </FormControl>
                        {shouldShowInput(question) && (
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseExplain')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q25_P2,
                                        data: { value, validated },
                                    });
                                }}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[1].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        )}
                    </>
                );
            }
            case 26: {
                const { answerOptionList } = question.answerDefinitionList[0];
                const error = sending && question.required === 'REQUIRED';
                return (
                    <>
                        <FormControl error={error}>
                            <Grid container className={classes.checkboxWrapper}>
                                {answerOptionList.map(({ id, name }) => (
                                    <Grid xs={6} sm={4} key={id} item>
                                        <CfoSingleCheckbox
                                            returnEvent
                                            checked={isChecked(question, id)}
                                            onChange={(event) =>
                                                onCheckboxChange(
                                                    event,
                                                    SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q26_P1,
                                                    id,
                                                    question
                                                )
                                            }
                                            label={name}
                                        />
                                    </Grid>
                                ))}
                                {error && (
                                    <Grid xs={12} item>
                                        <FormHelperText className={classes.checkboxHelperWrapper} error>
                                            {t('validation.selectionNotEmpty')}
                                        </FormHelperText>
                                    </Grid>
                                )}
                            </Grid>
                        </FormControl>
                        {shouldShowInput(question) && (
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseExplain')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q26_P2,
                                        data: { value, validated },
                                    });
                                }}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[1].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        )}
                    </>
                );
            }
            case 27: {
                const { answerOptionList } = question.answerDefinitionList[0];
                const error = sending && question.required === 'REQUIRED';
                return (
                    <FormControl error={error}>
                        <Grid container className={classes.checkboxWrapper}>
                            {answerOptionList.map(({ id, name }) => (
                                <Grid xs={12} key={id} item>
                                    <CfoSingleCheckbox
                                        returnEvent
                                        checked={isChecked(question, id)}
                                        onChange={(event) =>
                                            onCheckboxChange(
                                                event,
                                                SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q27,
                                                id,
                                                question
                                            )
                                        }
                                        label={name}
                                    />
                                </Grid>
                            ))}
                            {error && (
                                <Grid xs={12} item>
                                    <FormHelperText className={classes.checkboxHelperWrapper} error>
                                        {t('validation.selectionNotEmpty')}
                                    </FormHelperText>
                                </Grid>
                            )}
                        </Grid>
                    </FormControl>
                );
            }
            default:
                return null;
        }
    };

    const isValid = () =>
        // Validate Q18, Q21, Q23 p2, Q25 p2, Q26 p2  FREE_TEXT
        questions[0].freeTextAnswerList &&
        questions[0].freeTextAnswerList[0]?.validated &&
        // (questions[3].freeTextAnswerList ? questions[3].freeTextAnswerList[0]?.validated : true) &&
        // Q23
        // (shouldShowInput(questions[5])
        //     ? questions[5].freeTextAnswerList && questions[5].freeTextAnswerList[0]?.validated
        //     : true) &&
        // Q25
        // (shouldShowInput(questions[7])
        //     ? questions[7].freeTextAnswerList && questions[7].freeTextAnswerList[0]?.validated
        //     : true) &&
        // Q26
        // (shouldShowInput(questions[8])
        //     ? questions[8].freeTextAnswerList && questions[8].freeTextAnswerList[0]?.validated
        //     : true) &&
        // Validate Q22 & Q24 RADIO_BUTTON
        // questions[4].selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id &&
        questions[6].selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id &&
        // Validate Q25-Q27 CHECKBOX
        // !isEmpty(questions[5].selectionAnswerList?.[0]?.selectedAnswerOptionList) &&
        // !isEmpty(questions[7].selectionAnswerList?.[0]?.selectedAnswerOptionList) &&
        // !isEmpty(questions[8].selectionAnswerList?.[0]?.selectedAnswerOptionList) &&
        // !isEmpty(questions[9].selectionAnswerList?.[0]?.selectedAnswerOptionList) &&

        // Validate Q19 & Q20 MULTI_FREE_TEXT
        // !questions[1].freeTextAnswerList?.linkedIn.some((urlData) => urlData.value && !urlData.validated) &&
        // !questions[1].freeTextAnswerList?.github.some((urlData) => urlData.value && !urlData.validated) &&
        !questions[2].freeTextAnswerList?.some((nameData) => !nameData.validated);

    const clickContinueHandler = async () => {
        setSending(true);

        if (isValid()) {
            dispatch(setActiveRequest(true));
            const requestData = {};
            requestData.supportApplicantInfo = generateRepInfoData(form.supportApplicantInfo);
            requestData.questionList = generateFounderInfoAnswers(questions);

            let res = null;
            try {
                res = await FintechBeService.supportProgramApply({
                    keycloak,
                    formId,
                    requestData,
                    category: CATEGORY_FOUNDER_INFO,
                });
                dispatch(setActiveRequest(false));
            } catch (reason) {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            if (!res || !res.success) {
                enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            nextPage();
        } else {
            enqueueSnackbar(t('validation.genericMessage'), { variant: 'error' });
        }
    };

    const isLoading = form.isLoading || questions.length < 1;

    return isLoading ? (
        <LoadingSpinnerWithText style={loadingSpinnerWithTextGeneralTextStyle} />
    ) : (
        <CfoFormContainer>
            {matches && (
                <Typography className={classes.pageTitle}>{t('pageTitles.founderAndTeamInformation')}</Typography>
            )}
            {questions.map((question) => (
                <Grid
                    item
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    key={question.questionNumber}
                    style={{ paddingBottom: '24px' }}
                >
                    <CfoFormLabel
                        label={`${question.questionNumber}. ${question.name}`}
                        subText={question.subText}
                        isOptional={question.required === 'OPTIONAL'}
                    />
                    <CfoFormInput>{getCorrespondingInput(question)}</CfoFormInput>
                </Grid>
            ))}
            <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className={classes.buttonsWrapper}
            >
                <CfoFormInput xs={4} lg={3}>
                    <Button
                        startIcon={matches && <ArrowBack />}
                        className={classes.secondaryButton}
                        onClick={prevPage}
                        fullWidth
                        disabled={hasActiveRequest}
                    >
                        {matches ? t('buttonTexts.returnToPreviousStep') : t('buttonTexts.previousStep')}
                    </Button>
                </CfoFormInput>
                <CfoFormInput xs={7} lg={8}>
                    <Button
                        className={classes.primaryButton}
                        onClick={clickContinueHandler}
                        fullWidth
                        disabled={hasActiveRequest}
                    >
                        {!hasActiveRequest ? t('buttonTexts.continue') : <ButtonCircularIndicator />}
                    </Button>
                </CfoFormInput>
            </Grid>
        </CfoFormContainer>
    );
};

export default FounderAndTeamInformation;
