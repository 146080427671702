import React, { useEffect, useState } from 'react';
import useStyles from 'fintech/components/ui/form/select/SingleSelect.style';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isNil } from 'lodash';
import DropdownError from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/DropdownError';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';
import { ExpandMore } from '@material-ui/icons';

const initialValidate = (dontValidate, valueParam, canBeEmpty, invalidValues) => {
    if (dontValidate) {
        return true;
    }
    if (canBeEmpty && !valueParam) {
        return true;
    }
    return valueParam && !invalidValues.includes(valueParam.id);
};

export default function SingleSelect({
    options, // Expects comboboxDto (id & data fields for each option)
    isLoading,
    isError,
    initValue = null,
    onChange,
    label,
    placeholder,
    validateOnBlur,
    disabled = false,
    dontValidate,
    preventValidation,
    validatedAfterFormSubmit = true,
    initiallyValidated,
    canBeEmpty = false,
    initiallyEmpty = false,
    classes = {},
    invalidValues = [],
    helperText,
    getOptionLabel = (option) => (option.data ? option.data : ''),
    getOptionSelected = (option, value) => option.id === value.id,
}) {
    const styles = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(!initiallyEmpty ? (initValue ? { ...initValue } : null) : null);
    const [validated, setValidated] = useState(
        dontValidate || validateOnBlur ? true : initialValidate(dontValidate, initValue, canBeEmpty, invalidValues)
    );

    useEffect(() => {
        if (initiallyValidated) {
            onChange(value, true);
        }
    }, []);

    useEffect(() => {
        if (dontValidate && validatedAfterFormSubmit) setValidated(validation(value));
    }, [dontValidate]);

    useEffect(() => {
        if (!validatedAfterFormSubmit) {
            setValidated(validation(value));
        }
    }, [validatedAfterFormSubmit]);

    // Used if the disabled value of the component is provided later
    useEffect(() => {
        setValue(initValue ? { ...initValue } : null);
    }, [initValue]);

    const handleChange = (event, valueParam) => {
        if (!isNil(valueParam)) {
            setValue({ ...valueParam });
            setValidated(validation(valueParam));
            onChange(valueParam, validation(valueParam));
        } else {
            let isValid = false;
            if (canBeEmpty) {
                isValid = true;
            }
            setValue(null);
            setValidated(isValid);
            onChange(null, isValid);
        }
    };

    const handleOnBlur = () => {
        if (validateOnBlur) {
            handleChange(null, value);
        }
    };

    const validation = (valueParam) => {
        return initialValidate(dontValidate, valueParam, canBeEmpty, invalidValues);
    };

    let renderedJSX = null;
    if (isLoading) {
        renderedJSX = (
            <div className={classes.root} style={{ textAlign: 'center' }}>
                <LoadingSpinner />
            </div>
        );
    } else if (isError) {
        renderedJSX = (
            <div className={classes.root}>
                <DropdownError noBottomMargin />
            </div>
        );
    } else {
        renderedJSX = (
            <div className={'selectComboboxContainer'}>
                <Autocomplete
                    className="singleSelectCombobox"
                    popupIcon={<ExpandMore />}
                    classes={classes}
                    onChange={handleChange}
                    disableClearable={!canBeEmpty}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    value={value}
                    options={options}
                    getOptionLabel={getOptionLabel}
                    getOptionSelected={getOptionSelected}
                    onBlur={handleOnBlur}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={styles.searchInputOfSelect}
                            variant="outlined"
                            label={label}
                            error={preventValidation ? false : !validated}
                            helperText={!validated ? (helperText ? helperText : t('validation.notEmpty')) : null}
                            placeholder={placeholder}
                        />
                    )}
                />
            </div>
        );
    }

    return renderedJSX;
}
