import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    COLOR_THEME_FILL_01,
} from 'fintech/components/style/common';

const defaultAvatarIcon = {
    width: 64,
    height: 64,
    display: 'block',
};
export const useExpandableAvatarStyles = makeStyles(() => ({
    root: {
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '& > *': {
            margin: '0',
        },
    },
    input: {
        display: 'none',
    },
    preLoginPlaceholderAvatar: {
        ...defaultAvatarIcon,
        width: 42,
        height: 42,
    },
    avatarIconWithoutBorder: {
        ...defaultAvatarIcon,
    },
    avatarIcon: {
        ...defaultAvatarIcon,
        border: `solid 2px ${COLOR_PRIMARY_SHADE_02}`,
    },
    avatarIconPlaceholder: {
        ...defaultAvatarIcon,
        border: `solid 1px ${COLOR_PRIMARY_SHADE_03}`,
        marginTop: '-2px',
        marginLeft: '-2px',
    },
    editButton: {
        backgroundColor: COLOR_PRIMARY_BASE,
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        padding: '0',
        position: 'absolute',
        bottom: 0,
        right: '0',
        border: '2px solid ' + COLOR_THEME_FILL_01,
        textAlign: 'center',
        cursor: 'pointer',
    },
    editIcon: {
        fontSize: '12px',
        color: COLOR_PRIMARY_SHADE_02,
    },
    expandButton: {
        padding: '4px',
        borderRadius: '50%',
    },
    backdrop: {
        zIndex: '1300',
    },
    imageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    closeButtonWrapper: {
        width: '100%',
        textAlign: 'right',
        marginBottom: '4px',
    }
}));
