import React, { useState, useEffect } from 'react';
import IconsMultipleYatirimci from 'fintech/icons/IconsMultipleYatirimci';
import ReportCard from 'fintech/components/reports/common/card';
import { useTranslation } from 'react-i18next';
import Table from 'fintech/components/reports/common/table';
import { getInvestor } from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import { ReportType } from 'fintech/utils/ReportUtils';

const Index = ({ onShowModal }) => {
    const { t } = useTranslation('reports');
    const { keycloak } = useKeycloak();

    const [investorData, setInvestorData] = useState(null);

    return (
        <ReportCard
            title={t('card.investors')}
            icon={<IconsMultipleYatirimci />}
            button={{
                text: t('card.generateDetailedReport'),
                onClick: () => {
                    onShowModal(ReportType.INVESTOR)
                }
            }}
            isLoading={investorData === null}
            isCollapse
            onOpen={() => {
                if (investorData === null)
                    getInvestor(keycloak).then((e) => {
                        setInvestorData(e.data);
                    });
            }}
        >
            {investorData !== null && (
                <Table
                    column={[
                        { title: t('card.activeInvestorCount'), dataIndex: 'activeInvestorCount' },
                        { title: t('card.activeEquityInvestorCount'), dataIndex: 'activeEquityInvestorCount' },
                        { title: t('card.activeIndividualInvestorCount'), dataIndex: 'activeIndividualInvestorCount' },
                        { title: t('card.investorApplicationCount'), dataIndex: 'investorApplicationCount' },
                        { title: t('card.newInvestorCount'), dataIndex: 'newInvestorCount' },
                    ]}
                    dataSource={investorData}
                />
            )}
        </ReportCard>
    );
};

Index.propTypes = {};

export default Index;
