import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ActionModal from 'fintech/components/util/ActionModal';
import { useMediaQuery } from '@material-ui/core';
import { laptopLarge } from 'fintech/components/style/common';
import * as FintechBeService from 'fintech/api/fintechService';
import PreferencesActionModal from 'fintech/components/profile/establishment/InvestmentPreferences/form/PreferencesActionModal/PreferencesActionModal';
import {
    fetchInvestorPreferencesData,
    fetchInvestorManagementTypes,
    fetchInvestorInvestmentAmounts,
    fetchInvestorCompanyInvestmensAmounts,
} from 'fintech/store/actions/establishment';
import {
    REJECT,
    SUBMIT,
    ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_UPDATE,
    ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNT_UPDATE,
    ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENT_AMOUNT_UPDATE,
} from 'fintech/components/util/ActionModalConstants';
import { setActiveRequest } from 'fintech/store/actions/common';
import useStyles from './index.style';

const PreferencesEditModal = (props) => {
    const { open, onCancel } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const classes = useStyles();

    const { uuid, hasActiveRequest } = useSelector((state) => ({ ...state.establishment.intro.data, ...state.common }));
    const { managementTypes, investmentAmounts, companyInvestmentAmounts } = useSelector(
        (state) => state.establishment.investmentPreferences.form
    );

    const { enqueueSnackbar } = useSnackbar();

    const [formData, setFormData] = useState({
        management: {
            id: null,
            validated: true,
        },
        investment: {
            id: null,
            validated: true,
        },
        companyInvestment: {
            id: null,
            validated: true,
        },
    });

    const matches = useMediaQuery(laptopLarge);
    const modalSize = matches ? { minWidth: '700px', minHeight: '690px' } : { width: '100%' };

    const formDataChangeHandler = ({ type, data }) => {
        setFormData((prev) => {
            switch (type) {
                case ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_UPDATE: {
                    return {
                        ...prev,
                        management: { ...data },
                    };
                }
                case ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNT_UPDATE: {
                    return {
                        ...prev,
                        investment: { ...data },
                    };
                }
                case ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENT_AMOUNT_UPDATE: {
                    return {
                        ...prev,
                        companyInvestment: { ...data },
                    };
                }
            }
        });
    };

    const editModalActionHandler = (action) => {
        const { type } = action;
        if (type === SUBMIT) {
            updateData();
        } else if (type === REJECT) {
            onCancel();
        } else {
            return;
        }
    };

    const updateData = () => {
        if (!isFormValid()) {
            enqueueSnackbar(t('validation.genericError'), { variant: 'error' });
            return;
        }
        dispatch(setActiveRequest(true));

        const requestData = {
            managementId: formData.management.id,
            investmentId: formData.investment.id,
            companyInvestmentId: formData.companyInvestment.id,
        };

        FintechBeService.updateInvestorPreferences(keycloak, uuid, requestData)
            .then(() => {
                dispatch(setActiveRequest(false));
                dispatch(fetchInvestorPreferencesData(keycloak, uuid));
                onCancel();
                enqueueSnackbar(t('idea.updateSucces'), { variant: 'success' });
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            });
    };

    const isFormValid = () => formData.management.validated && formData.companyInvestment.validated;

    useEffect(() => {
        dispatch(fetchInvestorManagementTypes(keycloak, uuid));
        dispatch(fetchInvestorInvestmentAmounts(keycloak, uuid));
        dispatch(fetchInvestorCompanyInvestmensAmounts(keycloak, uuid));
    }, []);

    return (
        <ActionModal
            open={open}
            title={t('profile.establishments.investmentPreferences.label')}
            onAction={editModalActionHandler}
            modalSize={modalSize}
            okClickDisabled={
                hasActiveRequest ||
                managementTypes.isError ||
                investmentAmounts.isError ||
                companyInvestmentAmounts.isError
            }
            cancelClickDisabled={hasActiveRequest}
            showSpinnerWhenDisabled={hasActiveRequest}
            buttonContainerClass={classes.buttons}
        >
            <PreferencesActionModal
                managementTypes={managementTypes}
                investmentAmounts={investmentAmounts}
                companyInvestmentAmounts={companyInvestmentAmounts}
                changeCallback={formDataChangeHandler}
            />
        </ActionModal>
    );
};

export default PreferencesEditModal;
