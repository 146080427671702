import * as React from 'react';

function SvgIconsMultipleKisi(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#E8E6E4" d="M9.857 23.357h4.286v-2.5H9.857z" />
                <path
                    fill="#D9DCE0"
                    d="M14.143 13H9.857c-.789 0-1.428.64-1.428 1.429v7.5h1.428v-1.072h4.286v1.072h1.428v-7.5c0-.79-.64-1.429-1.428-1.429"
                />
                <path
                    fill="#3F5162"
                    d="M11.643 23.357h.714v-1.428h-.714zm0-8.214h.714v-.714h-.714zM9.5 23.357h.714v-8.214H9.5zm4.286 0h.714v-8.214h-.714z"
                />
                <path fill="#3F5162" d="M9.857 21.214h4.286V20.5H9.857z" />
                <path fill="#E8E6E4" d="M13.429 10.5a1.43 1.43 0 11-2.859 0 1.43 1.43 0 012.859 0" />
                <path
                    fill="#3F5162"
                    d="M12 9.429c-.59 0-1.071.48-1.071 1.071 0 .59.48 1.071 1.071 1.071.59 0 1.071-.48 1.071-1.071 0-.59-.48-1.071-1.071-1.071m0 2.857c-.985 0-1.786-.801-1.786-1.786S11.015 8.714 12 8.714s1.786.801 1.786 1.786-.801 1.786-1.786 1.786m-2.678-2.89l-5-2.872.356-.619 5 2.872zm10 5.715l-5-2.873.356-.619 5 2.873zm-7.679-7.468h.714V1.929h-.714zm-8.214 3.214h5.714v-.714H3.429zm6.832-2.68l-2.857-5 .62-.354 2.857 5z"
                />
                <path
                    fill="#D9DCE0"
                    d="M13.071 1.929a1.072 1.072 0 11-2.143-.001 1.072 1.072 0 012.143.001M8.786 3a1.071 1.071 0 11-2.142 0 1.071 1.071 0 012.142 0M5.571 6.214a1.072 1.072 0 11-2.144 0 1.072 1.072 0 012.144 0M3.429 9.429a1.072 1.072 0 11-.001 2.143 1.072 1.072 0 01.001-2.143"
                />
                <path
                    fill="#3F5162"
                    d="M12 1.214a.715.715 0 10.001 1.43.715.715 0 00-.001-1.43m0 2.143A1.43 1.43 0 0112 .5c.788 0 1.429.64 1.429 1.429A1.43 1.43 0 0112 3.357M7.714 2.286a.714.714 0 100 1.429.714.714 0 000-1.429m0 2.143a1.43 1.43 0 010-2.858 1.43 1.43 0 010 2.858M4.5 5.5a.714.714 0 100 1.429.714.714 0 000-1.429m0 2.143a1.43 1.43 0 111.429-1.429A1.43 1.43 0 014.5 7.643m10.178 1.753l-.356-.619 5-2.872.356.619zm-.939-1.219l-.62-.354 2.857-5 .62.354z"
                />
                <path
                    fill="#D9DCE0"
                    d="M15.214 3a1.071 1.071 0 102.142 0 1.071 1.071 0 00-2.142 0m3.215 3.214a1.072 1.072 0 102.144 0 1.072 1.072 0 00-2.144 0M19.5 10.5a1.071 1.071 0 102.142 0 1.071 1.071 0 00-2.142 0"
                />
                <path
                    fill="#3F5162"
                    d="M16.286 2.286a.715.715 0 100 1.427.715.715 0 000-1.427m0 2.143a1.43 1.43 0 010-2.858 1.43 1.43 0 010 2.858M19.5 5.5a.714.714 0 100 1.429.714.714 0 000-1.429m0 2.143a1.43 1.43 0 111.429-1.429A1.43 1.43 0 0119.5 7.643M3.429 9.786a.715.715 0 100 1.427.715.715 0 000-1.427m0 2.143a1.43 1.43 0 010-2.858 1.43 1.43 0 010 2.858m11.428-1.072H19.5v-.714h-4.643z"
                />
                <path
                    fill="#3F5162"
                    d="M20.571 9.786a.714.714 0 100 1.429.714.714 0 000-1.429m0 2.143A1.43 1.43 0 1122 10.5a1.43 1.43 0 01-1.429 1.429"
                />
                <path fill="#D9DCE0" d="M18.429 14.786a1.072 1.072 0 102.143-.001 1.072 1.072 0 00-2.143.001" />
                <path
                    fill="#3F5162"
                    d="M19.5 14.071a.715.715 0 10.001 1.43.715.715 0 00-.001-1.43m0 2.143a1.43 1.43 0 010-2.857 1.43 1.43 0 010 2.857M4.678 15.111l-.356-.619 5-2.873.356.619z"
                />
                <path fill="#D9DCE0" d="M5.571 14.786a1.072 1.072 0 11-2.143-.001 1.072 1.072 0 012.143.001" />
                <path
                    fill="#3F5162"
                    d="M4.5 14.071a.715.715 0 10.001 1.43.715.715 0 00-.001-1.43m0 2.143a1.43 1.43 0 010-2.857 1.43 1.43 0 010 2.857"
                />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleKisi;
