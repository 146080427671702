import React, { useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from './LanguagePanel.style';
import FlagsSTrTurkey from 'fintech/icons/FlagsSTrTurkey';
import FlagsSUsUnitedStates from 'fintech/icons/FlagsSUsUnitedStates';
import { getSystemLanguage, setSystemLanguage } from 'fintech/utils/LanguageUtils';
import { LANGUAGE_CODES } from 'fintech/constants';
import { useTranslation } from 'react-i18next';
import IconsSingleChevronDown from 'fintech/icons/IconsSingleChevronDown';

export default function LanguagePanel(props) {
    const styles = useStyles();
    const { t } = useTranslation();
    const { alwaysDisplayText, makeColorWhite } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedLanguage, setSelectedLanguage] = React.useState(getSystemLanguage());
    const [selectedLanguageLabel, setSelectedLanguageLabel] = React.useState(getSystemLanguage());
    const [languages] = React.useState([
        {
            code: LANGUAGE_CODES.TR,
            name: 'TR',
            displayText: t('language.tr'),
        },
        {
            code: LANGUAGE_CODES.EN,
            name: 'EN',
            displayText: t('language.en'),
        },
    ]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, value) => {
        setAnchorEl(null);

        if (value && (value === LANGUAGE_CODES.TR || value === LANGUAGE_CODES.EN)) {
            setSystemLanguage(value);
            setSelectedLanguage(value);
            window.location.reload();
        }
    };

    useEffect(() => {
        setSelectedLanguageLabel('language.' + selectedLanguage);
    }, [selectedLanguage]);

    return (
        <div>
            <div
                aria-controls="language-selector-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={styles.languageDiv}
            >
                <div className={'d-flex align-items-center poppin-500-14px'}>
                    {selectedLanguage === LANGUAGE_CODES.EN ? <FlagsSUsUnitedStates /> : <FlagsSTrTurkey />}
                    <div className={`margin-left-5 ${alwaysDisplayText ? '' : 'web'}`}>
                        <span className={makeColorWhite && styles.selectedLanguageLabel}>
                            {t(selectedLanguageLabel)}
                        </span>
                    </div>
                    <IconsSingleChevronDown className={makeColorWhite && styles.languageDownIcon} />
                </div>
            </div>
            <Menu
                id="language-selector-menu"
                elevation={0}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={styles.languageSelectorMenu}
            >
                {languages.map((language) => {
                    const isSelected = selectedLanguage === language.code;
                    return (
                        <MenuItem key={language.code} onClick={(event) => handleClose(event, language.code)}>
                            <div className={styles.selectionMenuElement}>
                                <div className={styles.selectionFlagDiv}>
                                    {language.code === LANGUAGE_CODES.EN ? (
                                        <FlagsSUsUnitedStates />
                                    ) : (
                                        <FlagsSTrTurkey />
                                    )}
                                </div>
                                <div
                                    className={
                                        isSelected ? styles.currentlySelectedSelectionText : styles.selectionText
                                    }
                                >
                                    {language.displayText}
                                </div>
                            </div>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
