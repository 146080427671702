import { makeStyles } from '@material-ui/core';
import { cardGridStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    GeneralInfoCard: {
        ...cardGridStyle,
        margin: '25px 0 0',
    },
});

export default useStyles;
