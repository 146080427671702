import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import PageTemplate from 'fintech/components/PageTemplate';
import BoxedSearchBar from 'fintech/components/search/BoxedSearchBar';
import AdvancedFilterArea from 'fintech/components/search/AdvancedFilterArea';
import SearchDataGrid from 'fintech/components/search/DataGrid';
import {
    closeAdvancedFilter,
    fetchActiveInvestorsCount,
    fetchAdvancedFilterData,
    fetchGridData,
    removeAppliedFilter,
    removeAppliedFiltersBySubcategory,
    resetAllFilters,
    searchBoxOnChange,
    setSearchBoxFilterStatus,
    setAppliedFilters,
    setGridHeaders,
    setGridPage,
    setSelectedCategory,
    toggleAdvancedFilter,
    toggleCheckbox,
    uncheckAllCheckboxes,
    persistState,
} from 'fintech/store/actions/investorSearch';
import { formatNumericValue } from 'fintech/utils/StringUtils';
import GridPagination from 'fintech/components/pagination/GridPagination';
import { InvestorSearchActions } from 'fintech/store/actions/ActionTypes';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';
import { defaultTableHeaders, adminTableHeaders } from 'fintech/constants/investorSearch';
import { hasRoles } from 'fintech/utils/Auth';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import { InfoIcon } from 'fintech/components/profile/common';
import { useState } from 'react';
import RepresentativeEditModal from 'fintech/components/search/investor/RepresentativeEditModal';
import { ACTIVE, PASSIVE } from 'fintech/components/util/StatusConstants';
import * as FintechBeService from 'fintech/api/fintechService';
import investorImage from 'fintech/static/images/yatirimcilar.jpg';
import investorImageMobile from 'fintech/static/images/yatirimcilar-mobil.jpg';
import { uniqueSeperator } from 'fintech/constants';
import { INVESTORS_ESTABLISHMENT } from 'fintech/routes';
import { useHistory } from 'react-router';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import { setActiveRequest } from 'fintech/store/actions/common';

const InvestorsPage = () => {
    const { t } = useTranslation('investorSearch');
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const { totalNumber, persist } = useSelector((state) => state.investorSearch.global);
    const { headers, rows, pagination, isLoading, gridDataLoading, hasActiveRequest } = useSelector((state) => ({
        ...state.investorSearch.grid,
        ...state.common,
    }));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const errorCallback = (msg = t('idea.updateError')) => enqueueSnackbar(msg, { variant: 'error' });
    const type = 'investorSearch';
    const tabs = [{ label: t('nav.allInvestors') }];
    const isAdmin = hasRoles(keycloak, ROLE_ADMIN);
    const initHeaders = (!isAdmin ? defaultTableHeaders : adminTableHeaders).map((he) => ({
        ...he,
        value:
            he.type === 'sortLabel'
                ? {
                      ...he.value,
                      title: t(he.value.title),
                  }
                : t(he.value),
    }));

    // Representative edit modal
    const [modalState, setModalState] = useState({
        open: false,
        editingId: null,
        editingName: null,
    });

    // Status update confirmation dialog
    const [dialogState, setDialogState] = useState({
        open: false,
        editingId: null,
        editingName: null,
        value: null,
    });

    // Representative modal handlers
    const clickEditHandler = (uuid, name) =>
        setModalState({
            open: true,
            editingId: uuid,
            editingName: name,
        });
    const cancelModalHandler = () =>
        setModalState((prevState) => ({
            ...prevState,
            open: false,
        }));

    // Representative dialog handlers
    const clickSwitchHandler = (uuid, name) =>
        setDialogState({
            open: true,
            editingId: uuid,
            editingName: name,
            value: false,
        });

    const dialogConfirmedHandler = () => {
        const requestData = dialogState.value ? ACTIVE : PASSIVE;

        dispatch(setActiveRequest(true));

        FintechBeService.updateInvestorStatus(keycloak, requestData, dialogState.editingId)
            .then(() => {
                dispatch(setActiveRequest(false));
                dispatch(fetchActiveInvestorsCount(keycloak, errorCallback));
                dispatch(fetchGridData({ keycloak, errorCallback, useLastQueryType: true }));
                setDialogState({
                    open: false,
                    editingId: null,
                    editingName: null,
                    value: null,
                });
                enqueueSnackbar(t('form.statusDialogSuccess', { name: dialogState.editingName }), {
                    variant: 'success',
                });
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                errorCallback(reason?.message);
            });
    };

    const dialogCancelHandler = () =>
        setDialogState({
            open: false,
            editingId: null,
            switchCallback: null,
            value: null,
        });

    const makeSearchHandler = (input, filters) => {
        const searchFilter = filters?.find((fi) => fi.status);
        if (searchFilter) {
            dispatch({ type: InvestorSearchActions.RESET_INVESTOR_GRID_PAGINATION });
            dispatch(
                fetchGridData({
                    keycloak,
                    data: {
                        investorNameSelected: filters.find(({ label }) => label === 'investorName').status,
                        investorTypeSelected: filters.find(({ label }) => label === 'investorTypes').status,
                        stageSelected: filters.find(({ label }) => label === 'startupStages').status,
                        investmentAreasSelected: filters.find(({ label }) => label === 'investmentAreas').status,
                        interestedVerticalsSelected: filters.find(
                            ({ label }) => label === 'fintechSubVerticalInterests'
                        ).status,
                        searchTerm: input,
                    },
                    errorCallback,
                    isFilter: false,
                })
            );
        } else {
            errorCallback(t('searchbar.validationText'));
        }
    };

    useEffect(() => {
        if (!persist) {
            window.scroll({ top: 0, left: 0 });
            dispatch(setGridHeaders(initHeaders));
            dispatch(resetAllFilters());
            dispatch(fetchActiveInvestorsCount(keycloak, errorCallback));
            dispatch(fetchGridData({ keycloak, errorCallback }));
            dispatch(fetchAdvancedFilterData(keycloak, errorCallback));
        } else {
            dispatch(fetchActiveInvestorsCount(keycloak, errorCallback));
            dispatch(fetchGridData({ keycloak, errorCallback }));
            dispatch(fetchAdvancedFilterData(keycloak, errorCallback));
            dispatch(persistState(false));
        }
    }, []);

    const gridPageChangeHandler = (e, pageNumber) => {
        window.scroll({ top: 0, left: 0 });
        dispatch(setGridPage(pageNumber));
        dispatch(fetchGridData({ keycloak, errorCallback, useLastQueryType: true }));
    };

    return (
        <PageTemplate>
            <PageHeaderBox />
            <NavTabs id="search-navtabs" value={0} tabs={tabs} />
            <BoxedSearchBar
                type={type}
                backgroundImage={investorImage}
                backgroundImageMobile={investorImageMobile}
                labelHtml={t('searchbar.registeredInvestors', { investorsCount: formatNumericValue(totalNumber) })}
                placeholderText={t('searchbar.placeholder')}
                searchBoxOnChange={searchBoxOnChange}
                setSearchBoxFilterStatus={setSearchBoxFilterStatus}
                makeSearch={makeSearchHandler}
                resetAllFilters={() => dispatch(resetAllFilters())}
                fetchGridData={() => dispatch(fetchGridData({ keycloak, errorCallback }))}
            />
            <AdvancedFilterArea
                changeTextOnQueryType
                type={type}
                closeAdvancedFilter={closeAdvancedFilter}
                removeAppliedFilter={removeAppliedFilter}
                removeAppliedFiltersBySubcategory={removeAppliedFiltersBySubcategory}
                removeComponentHandler={(_categorylPrefix, _subCategory) => {
                    dispatch(removeAppliedFiltersBySubcategory(_categorylPrefix, _subCategory));
                    dispatch(fetchGridData({ keycloak, errorCallback }));
                }}
                removeItemHandler={(_categorylPrefix, _itemID) => {
                    dispatch(removeAppliedFilter(_categorylPrefix, _itemID));
                    dispatch(fetchGridData({ keycloak, errorCallback }));
                }}
                clearAll={() => {
                    dispatch(setAppliedFilters(keycloak, [], errorCallback));
                    dispatch(resetAllFilters());
                    dispatch(uncheckAllCheckboxes());
                }}
                setSelectedCategory={setSelectedCategory}
                toggleAdvancedFilter={toggleAdvancedFilter}
                toggleCheckbox={toggleCheckbox}
                buttonActions={{
                    closeButton: () => {
                        dispatch(closeAdvancedFilter());
                    },
                    unCheckAll: () => {
                        dispatch(uncheckAllCheckboxes());
                    },
                    apply: () => {
                        dispatch({ type: InvestorSearchActions.RESET_INVESTOR_GRID_PAGINATION });
                        dispatch(setAppliedFilters(keycloak, null, errorCallback));
                    },
                }}
            />
            <SearchDataGrid
                type={type}
                headers={headers}
                pagination={pagination}
                leftStickyColumnCount={isAdmin ? 2 : 1}
                buttonActions={{
                    sort: (id) => {
                        dispatch({ type: InvestorSearchActions.TOGGLE_INVESTOR_GRID_STATE, data: +id });
                        dispatch(fetchGridData({ keycloak, errorCallback, useLastQueryType: true }));
                    },
                    clearAll: () => {
                        dispatch(resetAllFilters());
                        dispatch(fetchGridData({ keycloak, errorCallback }));
                    },
                    handleRowClick: (uuid) => {
                        dispatch(persistState(true));
                        history.push(`${INVESTORS_ESTABLISHMENT}/${uuid}`);
                    },
                }}
                // For actions specific to ADMIN
                adminActions={[
                    {
                        title: t('form.changeRepresentativeLabel'),
                        onClick: (id, name) => {
                            clickEditHandler(id?.split(uniqueSeperator)[0], name);
                        },
                    },
                    {
                        title: t('form.switchStatus'),
                        onClick: (id, name) => clickSwitchHandler(id?.split(uniqueSeperator)[0], name),
                    },
                ]}
            />
            {rows && rows.length > 0 && (
                <GridPagination
                    controlled
                    {...pagination}
                    disabled={isLoading || gridDataLoading}
                    handleChange={gridPageChangeHandler}
                />
            )}
            {isAdmin && (
                <RepresentativeEditModal
                    type="investorSearch"
                    uuid={modalState.editingId}
                    name={modalState.editingName}
                    open={modalState.open}
                    onCancel={() => cancelModalHandler()}
                />
            )}
            {isAdmin && (
                <ConfirmDialog
                    open={dialogState.open}
                    icon={<InfoIcon fontSize="large" />}
                    title={t('dialog.statusChangeTitle')}
                    injectHtml
                    content={t('form.statusDialogWarning', {
                        status: dialogState.value ? t('form.active') : t('form.passive'),
                    })}
                    labelCancel={t('dialog.cancelAction')}
                    labelConfirm={t('dialog.confirmAction')}
                    onCancel={dialogCancelHandler}
                    onConfirm={dialogConfirmedHandler}
                    confirmDisabled={hasActiveRequest}
                    cancelDisabled={hasActiveRequest}
                    showSpinnerWhenDisabled={hasActiveRequest}
                />
            )}
        </PageTemplate>
    );
};

export default InvestorsPage;
