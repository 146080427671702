import { isValidTckn, isValidVkn } from './StringUtils';

export const translations = {
    en: {
        required: 'This field cannot be empty.',
        email: 'Please enter a valid email address.',
    },
    tr: {
        required: 'Bu alan boş bırakılamaz',
        email: 'Lütfen geçerli bir email adresi girin.',
    },
};

const tcknFunction = (ruleParams) => {
    const { context, value } = ruleParams;

    if (!value) {
        return null;
    }

    if (!isValidTckn(value)) {
        if (context.lang === 'en') {
            return `The entered Turkish identity number is incorrect, please check and re-enter.`;
        } else {
            return 'Girdiğiniz TCKN hatalıdır, lütfen kontrol edip tekrar giriniz.';
        }
    }

    return null;
};

const vknFunction = (ruleParams) => {
    const { context, value } = ruleParams;

    if (!value) {
        return null;
    }

    if (!isValidVkn(value)) {
        if (context.lang === 'en') {
            return `Please enter a valid tax identification number.`;
        } else {
            return 'Lütfen geçerli bir vergi kimlik numarası giriniz.';
        }
    }

    return null;
};

export const customRules = {
    tckn: tcknFunction,
    vkn: vknFunction,
};
