import React from 'react';
import { Grid } from '@material-ui/core';
import InvestorDateUpdate from 'fintech/components/profile/startup/form/financials/DateUpdate';
import InvestorAmountUpdate from 'fintech/components/profile/startup/form/financials/AmountUpdate';
import InvestorVisibilitySwitch from 'fintech/components/profile/startup/form/financials/VisibilitySwitch';
import InvestorCompanyUpdate from 'fintech/components/profile/startup/form/financials/CompanyUpdate';
import { setStartupInvestmentDate, setStartupInvestmentAmount } from 'fintech/store/actions/startup';
import { STATES } from 'fintech/store/actions/ActionTypes';
import { setStartupInvestmentVisibility } from 'fintech/store/actions/startup';

const InvestorActionModal = () => {
    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item xs={12}>
                <InvestorCompanyUpdate />
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                        <InvestorDateUpdate type={STATES.STARTUP} setInvestmentDate={setStartupInvestmentDate} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InvestorAmountUpdate type={STATES.STARTUP} setInvestmentAmount={setStartupInvestmentAmount} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <InvestorVisibilitySwitch
                    type={STATES.STARTUP}
                    setInvestmentVisibility={setStartupInvestmentVisibility}
                />
            </Grid>
        </Grid>
    );
};

export default InvestorActionModal;
