import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    inputTextAreaWrapper: {
        padding: '6px 0 0 0',
        '&.MuiDialogContent-root:first-child': {
            padding: '6px 0 2px 0',
        },
        overflowY: 'hidden',
    },
    divInput: {
        padding: '10px 12px 16px 12px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 0 16px 0',
        },
    },
    divInputSelect: {
        padding: '16px 12px 16px 12px',
        [theme.breakpoints.down('sm')]: {
            padding: '16px 0 16px 0',
        },
    },
}));

export default useStyles;
