import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './index.style';
import continuaImage from 'fintech/static/images/surec.svg';
import QuestionItem from '../Faq/QuestionItem';

const Continua = ({ supportProgramOnePage }) => {
    const { t } = useTranslation('preLogin');
    const classes = useStyles();

    return (
        <section className={classes.continuaSection} id="section-continua">
            <Grid container style={{ height: '100%' }} justify="center" alignItems="center">
                <Grid item md={6} xs={12}>
                    <img src={continuaImage} className={classes.continuaImg} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <div className={classes.defWrapper}>
                        <span className={classes.sectionTitle}>{t('titles.continua')}</span>
                        {supportProgramOnePage.continuaAreaData.map((question, index) => {
                            return <QuestionItem item={question} key={index} />;
                        })}
                    </div>
                </Grid>
            </Grid>
        </section>
    );
};

export default Continua;
