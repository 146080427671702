import * as React from 'react';

function SvgIconsSingleAdd(props) {
    return (
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16.984 22.584v-6h6v-1.968h-6v-6h-1.968v6h-6v1.968h6v6z" fill="#5B7ADB" fillRule="nonzero" />
        </svg>
    );
}

export default SvgIconsSingleAdd;
