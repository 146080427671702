import * as React from 'react';

function SvgIconsSingleEllipsis2(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
            <defs>
                <filter id="o2et0m8ysa" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g
                            filter="url(#o2et0m8ysa)"
                            transform="translate(-288 -764) translate(280 702) translate(8 62)"
                        >
                            <path
                                fill="#3F5162"
                                fillRule="nonzero"
                                d="M16.02 21.026c1.188 0 2.154.966 2.154 2.153 0 1.188-.966 2.154-2.153 2.154-1.188 0-2.154-.966-2.154-2.154 0-1.187.966-2.153 2.154-2.153zm0-7.18c1.188 0 2.154.967 2.154 2.154 0 1.187-.966 2.154-2.153 2.154-1.188 0-2.154-.967-2.154-2.154 0-1.187.966-2.154 2.154-2.154zm0-7.18c1.188 0 2.154.967 2.154 2.155 0 1.187-.966 2.153-2.153 2.153-1.188 0-2.154-.966-2.154-2.153 0-1.188.966-2.154 2.154-2.154z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleEllipsis2;
