import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    modalTitle: {
        borderBottom: ({ showBorder }) => (showBorder ? '1px solid #dee2e6' : null),
        paddingTop: 10,
        paddingLeft: 14,
        paddingRight: 32,
        paddingBottom: 0,
        textAlign: 'center',
    },
}));
