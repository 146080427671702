import React from 'react';
import { Card, Typography, styled, Box, Link } from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { SUPPORT_PROGRAM, SUPPORT_PROGRAM_APPLICATION_SECURE } from 'fintech/routes';
import { hasAnyAuthorities, isAuthenticated } from 'fintech/utils/Auth';
import { ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE } from 'fintech/components/util/RoleConstants';
// utils

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(() => ({
    boxShadow: 'none',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '100px',
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(6),
    height: theme.spacing(6),
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    color: '#fff',
    border: '6px solid #F1F5F7',
    background: `#3C4D5D`,
    '& > h6': {
        marginBottom: 0,
        fontSize: '1rem',
        fontWeight: 'bold',
    },
}));

const DateWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    padding: '4px 16px',
    borderRadius: '16px',
    backgroundColor: '#F1F5F7',
    maxWidth: '200px',
    '& > h6': {
        marginBottom: 0,
        color: '#3F5162',
        fontSize: '14px',
        fontWeight: '700',
    },
}));

const TypographyStyle = styled(Typography)(() => ({
    fontSize: '16px',
    maxWidth: '200px',
    marginBottom: '16px',
    lineHeight: '1.1em',
    height: '35px',
}));

const LinkStyle = styled(Link)(() => ({
    fontSize: '15px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'flex-end',
    cursor: 'pointer',
    gap: '5px',
    textDecoration: 'underline',
    textTransform: 'uppercase',
    marginTop: '2px',
}));

const AvatarAreaStyle = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const AnchorAreaStyle = styled('div')(() => ({
    height: 2,
    width: 2,
    marginBottom: '11px',
}));

// ----------------------------------------------------------------------

export default function CardItem({ item, supportProgramId }) {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const { t } = useTranslation('supportProgram');

    const onApplyButtonClick = (e) => {
        e.stopPropagation();
        if (isAuthenticated(keycloak)) {
            if (hasAnyAuthorities(keycloak, [ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE])) {
                enqueueSnackbar(t('snack.notAllowedToApply'), { variant: 'error' });
                return;
            }

            history.push(`${SUPPORT_PROGRAM_APPLICATION_SECURE}/${supportProgramId}`);
        } else {
            history.push(`${SUPPORT_PROGRAM}/${supportProgramId}`);
        }
    };

    const opacityStyle = 0.7 + Number(item.id) / 23;
    return (
        <Box>
            <RootStyle>
                <AvatarAreaStyle>
                    <AnchorAreaStyle id={`element${item?.anchorLeftId}`} />
                    <IconWrapperStyle id={`element${item?.id}`} style={{ opacity: opacityStyle || 1 }}>
                        <Typography variant="h6">{item?.id}</Typography>
                    </IconWrapperStyle>
                    <AnchorAreaStyle id={`element${item?.anchorId}`} />
                </AvatarAreaStyle>
                <TypographyStyle variant="h6" dangerouslySetInnerHTML={{ __html: item?.description }} />
                {item?.date && (
                    <DateWrapperStyle>
                        <Typography variant="h6">{item?.date}</Typography>
                    </DateWrapperStyle>
                )}
                {!item?.date && (
                    <LinkStyle color="initial" rel="noreferrer" onClick={onApplyButtonClick}>
                        {item?.hrefTitle} <CallMadeIcon style={{ fontSize: '1.4rem' }} />
                    </LinkStyle>
                )}
            </RootStyle>
        </Box>
    );
}
