import PageTemplate from 'fintech/components/PageTemplate';
import SettingsGrid from 'fintech/components/settings/SettingsGrid';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import React from 'react';

export default function SettingsPage() {
    return (
        <PageTemplate>
            <PageHeaderBox />
            <SettingsGrid />
        </PageTemplate>
    );
}
