import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        [theme.breakpoints.up('sm')]: {
            '& .MuiPaper-root': {
                width: '700px',
                height: '360px',
                maxWidth: 'none',
            },
        },
    },
    confirmDialogContentSuccess: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: '0',
            padding: '0 40px !important',
        },
    },
    confirmDialogContentFailed: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: '0',
            padding: '0 95px !important',
        },
    },
}));

export default useStyles;
