import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useOutsideClick from 'fintech/hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';
import { generateIDPrefix } from 'fintech/store/actions/entrepreneur';
import SideBar from 'fintech/components/modal/AdvancedFilteringModal/SideBar';
import { Button, IconButton, Modal, Box, Typography, Checkbox, Divider, InputLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from 'fintech/components/modal/AdvancedFilteringModal/index.style';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { mobileSmallQuery } from 'fintech/components/style/common';
import EscKeyListener from 'fintech/components/util/EscKeyListener';

const renderMainContent = ({ data, verticals, selectedVertical, toggleCheckbox, styles, isMobile, domainType }) => {
    const { value: selectedKey } = verticals[selectedVertical];
    let idPrefix = domainType === 'entrepreneur' ? generateIDPrefix(selectedKey) : selectedKey;

    let selectedData = data[selectedKey];
    selectedData = Object.entries(_.groupBy(selectedData, 'parentData'));

    return (
        <Box
            display="flex"
            flexDirection="column"
            style={{ height: isMobile ? 'unset' : '90%', width: '100%', overflowY: 'auto' }}
        >
            {selectedData.map((element) => {
                let title = null;
                let checkboxes = null;
                const [key, values] = element;

                if (!_.isUndefined(key) && key !== 'null') {
                    title = <Typography className={styles.mainContentTitle}>{key}</Typography>;
                }

                checkboxes = values.map(({ selected, id, data: checkboxData }) => {
                    return (
                        <Box display="flex" key={id} alignItems="center">
                            <Checkbox id={`afmc-${idPrefix}-${id}`} color="primary" checked={selected}></Checkbox>
                            <InputLabel
                                id=""
                                htmlFor={`afmc-${idPrefix}-${id}`}
                                classes={{ root: styles.mainContentCheckboxLabel }}
                            >
                                {checkboxData}
                            </InputLabel>
                        </Box>
                    );
                });

                const groupedCheckboxes = [];
                while (checkboxes.length > 0) groupedCheckboxes.push(checkboxes.splice(0, 6));

                return (
                    <Box
                        className={styles.mainContentGroup}
                        key={key}
                        onClick={(e) => {
                            // Optimization
                            const checkboxInput = e.target.closest('input');

                            if (!_.isNull(checkboxInput)) {
                                const [type, vertical, checkboxInputID] = checkboxInput.id.split('-');

                                toggleCheckbox({
                                    type,
                                    vertical,
                                    checkboxInputID: +checkboxInputID,
                                });
                            }
                        }}
                    >
                        {title}
                        <Box
                            display="flex"
                            flexDirection={isMobile ? 'column' : 'row'}
                            alignItems="flex-start"
                            flexWrap="wrap"
                        >
                            {/* {checkboxes} */}
                            {groupedCheckboxes.map((elements, groupElementIndex, _groupedCheckboxes) => {
                                let dynamicFlexStyle = isMobile ? 'unset' : '1 0 45%';
                                if (_groupedCheckboxes.length - 1 === groupElementIndex) {
                                    // Aligning orphans
                                    dynamicFlexStyle = 'auto';
                                }

                                return (
                                    <Box
                                        style={isMobile ? { width: '100%' } : { width: '45%' }}
                                        key={`cb-${key}${groupElementIndex}`}
                                        flex={dynamicFlexStyle}
                                    >
                                        {elements.map((element) => {
                                            return element;
                                        })}
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

const renderFooter = ({ buttonActions, t, styles }) => {
    const { closeButton, unCheckAll, apply } = buttonActions;

    return (
        <Box className={styles.footer} display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button
                style={{ marginRight: 'auto' }}
                onClick={() => closeButton()}
                p={2}
                className={styles.secondaryButton}
            >
                {t('entrepreneur.close')}
            </Button>
            <Button p={2} className={styles.secondaryButton} onClick={() => unCheckAll()}>
                {t('entrepreneur.uncheckAllSelections')}
            </Button>
            <Button p={2} className={styles.primaryButton} onClick={() => apply()}>
                {t('entrepreneur.apply')}
            </Button>
        </Box>
    );
};

const renderMobileVersion = ({
    buttonActions,
    verticals,
    selectedVertical,
    setVertical,
    toggleCheckbox,
    data,
    t,
    styles,
    isMobile,
    type,
}) => {
    const { closeButton, unCheckAll, apply } = buttonActions;

    return (
        <Box className={styles.modalContainer} flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
                <Typography className={styles.mobileHeader} variant="h4">
                    {t('entrepreneur.filterTable')}
                </Typography>
                <IconButton className={styles.mobileCloseButton} onClick={closeButton}>
                    <ClearIcon color="primary"></ClearIcon>
                </IconButton>
            </Box>

            <Button className={`${styles.secondaryButton} ${styles.mobileButton}`} onClick={unCheckAll}>
                <Typography className={styles.mainContentCheckboxLabel}>
                    {t('entrepreneur.uncheckAllSelections')}
                </Typography>
            </Button>
            <Button className={`${styles.primaryButton} ${styles.mobileButton}`} onClick={apply}>
                {t('entrepreneur.apply')}
            </Button>

            <SideBar
                verticals={verticals}
                selectedVertical={selectedVertical}
                setVertical={setVertical}
                type={type}
            ></SideBar>

            <Box style={{ width: '95%', overflowY: 'scroll' }} mx={2} flexDirection="column">
                {renderMainContent({
                    data,
                    selectedVertical,
                    verticals,
                    toggleCheckbox,
                    styles,
                    isMobile,
                    domainType: type,
                })}
                <Divider />
            </Box>
        </Box>
    );
};

const renderDesktopVersion = ({
    buttonActions,
    verticals,
    selectedVertical,
    setVertical,
    toggleCheckbox,
    data,
    t,
    styles,
    isMobile,
    type,
}) => {
    return (
        <Box className={styles.modalContainer} flexDirection="row">
            <SideBar
                verticals={verticals}
                selectedVertical={selectedVertical}
                setVertical={setVertical}
                type={type}
            ></SideBar>

            <Box style={{ width: '75%' }} mx={2} flexDirection="column">
                {renderMainContent({
                    data,
                    selectedVertical,
                    verticals,
                    toggleCheckbox,
                    styles,
                    isMobile,
                    domainType: type,
                })}
                <Divider />
                {renderFooter({ buttonActions, t, styles })}
            </Box>
        </Box>
    );
};

// Main Component
const AdvancedFilteringModal = (props) => {
    const { buttonActions, verticals, selectedVertical, setVertical, toggleCheckbox, data, type } = props;
    const { t } = useTranslation(type);
    const styles = useStyles();
    const filteringModalRef = useRef(null);

    useOutsideClick(filteringModalRef, () => buttonActions.closeButton());
    const isMobile = !useMediaQuery(mobileSmallQuery);

    return (
        <>
            <EscKeyListener onEsc={buttonActions.closeButton} />
            <Modal open={true}>
                {isMobile
                    ? renderMobileVersion({
                          buttonActions,
                          verticals,
                          selectedVertical,
                          setVertical,
                          toggleCheckbox,
                          data,
                          t,
                          styles,
                          isMobile,
                          type,
                      })
                    : renderDesktopVersion({
                          buttonActions,
                          verticals,
                          selectedVertical,
                          setVertical,
                          toggleCheckbox,
                          data,
                          t,
                          styles,
                          isMobile,
                          type,
                      })}
            </Modal>
        </>
    );
};

AdvancedFilteringModal.propTypes = {
    data: PropTypes.object.isRequired,
    buttonActions: PropTypes.object.isRequired,
    verticals: PropTypes.array.isRequired,
    selectedVertical: PropTypes.number.isRequired,
    setVertical: PropTypes.func.isRequired,
    toggleCheckbox: PropTypes.func.isRequired,
};
export default AdvancedFilteringModal;
