import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTemplate from 'fintech/components/PageTemplate';
import TabSwitch from 'fintech/components/ui/navigation/TabSwitch';
import ProgramCardGridContainer from 'fintech/components/support/program/card/ProgramCardGridContainer';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import { CURRENT, PAST, ApplicationStatus } from 'fintech/constants/supportProgram';
import SupportProgramApplications from 'fintech/components/support/program/Applications/SupportProgramApplications';
import { ROLE_ADMIN, ROLE_EVALUATION_COMMITTEE } from 'fintech/components/util/RoleConstants';
import { hasRoles } from 'fintech/utils/Auth';
import { useKeycloak } from '@react-keycloak/web';

const SupportProgramsGrid = () => {
    const { t } = useTranslation('supportProgram');
    const { keycloak } = useKeycloak();
    const isAdminOrEvaluationCommittee =
        hasRoles(keycloak, ROLE_ADMIN) || hasRoles(keycloak, ROLE_EVALUATION_COMMITTEE);

    const prgTabs = [
        {
            header: { label: t('tabs.currentPrograms') },
            component: <ProgramCardGridContainer type={CURRENT} />,
            errorMsg: t('list.currentProgramsError'),
        },
        {
            header: { label: t('tabs.pastPrograms') },
            component: <ProgramCardGridContainer type={PAST} />,
            errorMsg: t('list.pastProgramsError'),
        },
    ];

    if (isAdminOrEvaluationCommittee) {
        prgTabs.push(
            {
                header: { label: t('tabs.receivedApplications') },
                component: <SupportProgramApplications type={ApplicationStatus.PENDING} />,
                errorMsg: t('list.receivedApplicationsError'),
            },
            {
                header: { label: t('tabs.resolvedApplications') },
                component: <SupportProgramApplications type={ApplicationStatus.RESULTED} />,
                errorMsg: t('list.resolvedApplicationsError'),
            }
        );
    }

    return (
        <PageTemplate>
            <PageHeaderBox />
            <TabSwitch tabs={prgTabs} />
        </PageTemplate>
    );
};

export default SupportProgramsGrid;
