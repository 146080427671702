import { Grid, Link, Typography, useMediaQuery, Popover } from '@material-ui/core';
import { CallMade, Edit, Language } from '@material-ui/icons';
import { COLOR_PRIMARY_BASE, mobileMediaQuery } from 'fintech/components/style/common';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import * as FintechBeService from 'fintech/api/fintechService';
import { useSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import useStyles from './index.style';
import SendIcon from '@material-ui/icons/Send';
import { getWebsiteUrl } from 'fintech/utils/StringUtils';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import IconsSingleEllipsis3 from 'fintech/icons/IconsSingleEllipsis3';
import IconsSingleArrowRight2 from 'fintech/icons/IconsSingleArrowRight2';
import IconsSingleTooltip28 from 'fintech/icons/IconsSingleTooltip28';
import { INBOX } from 'fintech/routes';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { buttonStyleInteraction } from 'fintech/components/style/common';
import placeholderPerson from 'fintech/static/images/placeholder-kisi-rounded.png';
import { setActiveRequest } from 'fintech/store/actions/common';
import { ExpandableAvatar } from 'fintech/components/profile/common/ExpandableAvatar/ExpandableAvatar';
import { setBlockingFromProfile, setNewContact } from 'fintech/store/actions/inbox';

const IntroHeader = (props) => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const {
        t,
        name,
        personalWebsite,
        editable,
        onClickEditCallback,
        userUuid,
        isOwnProfile,
        hideSendMessage,
        username,
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [openBlockApprovePopup, setOpenBlockApprovePopup] = useState(false);
    const [blockedBy, setBlockedBy] = useState(false);
    const [blockedTo, setBlockedTo] = useState(false);
    const [blockInfoIsUnknown, setBlockInfoIsUnknown] = useState(true);
    const matches = useMediaQuery(mobileMediaQuery);
    const { enqueueSnackbar } = useSnackbar();

    const { hasActiveRequest } = useSelector((state) => state.common);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isOwnProfile) {
            fetchBlockInfo();
        }
    }, []);

    const fetchBlockInfo = async () => {
        FintechBeService.fetchUserIsBlocked(keycloak, username)
            .then((roleResponse) => {
                if (roleResponse && roleResponse.data) {
                    setBlockedBy(roleResponse.data.blockedBy);
                    setBlockedTo(roleResponse.data.blockedTo);
                    setBlockInfoIsUnknown(false);
                    dispatch(setBlockingFromProfile(username, roleResponse.blockedBy, keycloak));
                } else {
                    setBlockInfoIsUnknown(false);
                }
            })
            .catch(() => {
                setBlockInfoIsUnknown(false);
            });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEllipsisClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBlockOrUnblockUser = () => {
        dispatch(setActiveRequest(true));
        const functionToBeCalled = blockedBy ? FintechBeService.postUnblockUser : FintechBeService.postBlockUser;
        const successMessage = blockedBy
            ? t('profile.success.unblocked', { thingToBeBlocked: t('profile.ofThisPerson1') })
            : t('profile.success.blocked', { blockedThing: t('profile.person') });
        functionToBeCalled(keycloak, userUuid)
            .then((response) => {
                dispatch(setActiveRequest(false));
                if (response && response.success && response.data) {
                    enqueueSnackbar(successMessage, { variant: 'success' });
                    fetchBlockInfo();
                    setOpenBlockApprovePopup(false);
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error' });
                }
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason.message, { variant: 'error' });
            });
    };

    const handleShowBlockOrUnblockUserPopup = () => {
        setOpenBlockApprovePopup(true);
        handleClose();
    };

    const open = !!anchorEl;
    const history = useHistory();
    const { userName, profilePictureFile } = useSelector((state) => state.user.intro.data);

    /* Refs:
     * - https://material-ui.com/guides/composition/#link
     * - https://material-ui.com/guides/composition/#caveat-with-refs
     * - https://reactjs.org/docs/react-api.html#reactforwardref
     */
    // eslint-disable-next-line react/display-name
    // eslint-disable-next-line no-unused-vars
    const WebsiteLinkButton = React.forwardRef((props, ref) => (
        <ShrinkingButton
            {...props}
            queryCondition={1024}
            shrinkedIcon={<Language fontSize="small" />}
            fullIcon={<CallMade />}
            fullText={t('profile.user.goToPersonalWebsite')}
        >
            {props.children}
        </ShrinkingButton>
    ));

    const blockButtonText = blockedBy
        ? t('profile.unblockUser', { blockedThing: t('profile.thisPerson') })
        : t('profile.blockUser', { blockedThing: t('profile.thisPerson') });
    const blockDialogTitle = blockedBy
        ? t('profile.approveUnblockTitle', { thingToBeBlocked: t('profile.ofThisPerson') })
        : t('profile.approveBlockTitle', { blockedThing: t('profile.thisPerson') });
    const blockDialogContent = blockedBy
        ? t('profile.approveUnblockContent', { blockedThings: t('profile.persons') })
        : t('profile.approveBlockContent', { blockedThings: t('profile.persons') });

    return (
        <>
            <Grid
                className={classes.StupHeaderGrid}
                container
                direction="row"
                justify="space-between"
                alignItems={matches ? 'center' : 'flex-start'}
            >
                <Grid item lg={6} sm={8}>
                    <Grid
                        container
                        direction={matches ? 'row' : 'column'}
                        justify="flex-start"
                        alignItems={matches ? 'center' : 'flex-start'}
                    >
                        <Grid item>
                            <ExpandableAvatar
                                userServiceUri="api/user/image/"
                                userUuid={userUuid}
                                placeholder={placeholderPerson}
                                editable={editable}
                                profilePictureFile={profilePictureFile?.uuid}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={matches ? 'margin-left-16' : ''} style={{ fontSize: '24px' }}>
                                {name}
                            </Typography>
                        </Grid>
                        {blockedBy && (
                            <Grid item>
                                <Typography className={classes.blockedByYouText}>
                                    {'(' + t('profile.blockedByYou', { blockedThing: t('profile.thisPerson') }) + ')'}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Grid item lg={6} sm={4}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        {/* {!hideSendMessage && !isOwnProfile && !(blockInfoIsUnknown || blockedBy || blockedTo) && (
                            <Grid item>
                                <ShrinkingButton
                                    queryCondition={1024}
                                    shrinkedIcon={<SendIcon fontSize="small" />}
                                    fullIcon={<SendIcon />}
                                    fullText={t('profile.user.sendMessage')}
                                    onClick={() => {
                                        dispatch(setNewContact(userName));
                                        history.push(INBOX);
                                    }}
                                >
                                    {props.children}
                                </ShrinkingButton>
                            </Grid>
                        )} */}

                        {personalWebsite && (
                            <Grid item>
                                <Link
                                    color="initial"
                                    target="_blank"
                                    rel="noopener"
                                    href={getWebsiteUrl(personalWebsite)}
                                    component={WebsiteLinkButton}
                                >
                                    {''}
                                </Link>
                            </Grid>
                        )}
                        <Grid item>
                            {editable ? (
                                <ShrinkingButton
                                    onClick={() => onClickEditCallback()}
                                    queryCondition={1024}
                                    shrinkedIcon={<Edit fontSize="small" style={{ color: COLOR_PRIMARY_BASE }} />}
                                    fullIcon={<Edit style={{ color: COLOR_PRIMARY_BASE }} />}
                                    fullText={t('profile.user.edit')}
                                    otherStyles={buttonStyleInteraction}
                                />
                            ) : null}
                        </Grid>
                        {/* <Grid item>
                            {!isOwnProfile ? (
                                <>
                                    <div onClick={handleEllipsisClick} className={classes.EllipsisButton}>
                                        <IconsSingleEllipsis3 />
                                    </div>
                                    <Popover
                                        id={'ellipsesButtonPopover'}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        className={classes.ellipsesButtonClass}
                                    >
                                        <div
                                            className={classes.EllipsisPopover}
                                            onClick={handleShowBlockOrUnblockUserPopup}
                                        >
                                            <div className={classes.arrowRightIcon}>
                                                <IconsSingleArrowRight2 />
                                            </div>
                                            <div className={classes.blockUserText}>
                                                <span>{blockButtonText}</span>
                                            </div>
                                        </div>
                                    </Popover>
                                </>
                            ) : null}
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmDialog
                open={openBlockApprovePopup}
                icon={
                    <IconsSingleTooltip28
                        style={{ width: '62px', height: '62px', backgroundColor: '#eeeff1', borderRadius: '50%' }}
                    />
                }
                title={blockDialogTitle}
                content={blockDialogContent}
                labelConfirm={t('profile.approveBlockButton')}
                labelCancel={t('profile.rejectBlockButton')}
                dialogRootClass={classes.dialogRoot}
                confirmDialogContentClass={classes.confirmDialogContent}
                onConfirm={handleBlockOrUnblockUser}
                onCancel={() => {
                    setOpenBlockApprovePopup(false);
                }}
                showTopCloseButton
                confirmDisabled={hasActiveRequest}
                cancelDisabled={hasActiveRequest}
                showSpinnerWhenDisabled={hasActiveRequest}
            />
        </>
    );
};

export default withTranslation('profile')(IntroHeader);
