import { makeStyles, Typography, withStyles } from '@material-ui/core';
import { cardGridStyle, COLOR_SECONDARY_SHADE_01, fontStyle } from 'fintech/components/style/common';

// TODO: Externalize common colors & styling
export const StyledTitle = withStyles({
    root: {
        ...fontStyle,
        color: COLOR_SECONDARY_SHADE_01,
        textAlign: 'start',
        marginTop: '4px',
        marginRight: '20px',
        marginBottom: '8px',
    },
})(Typography);

const useStyles = makeStyles({
    StupIntroCard: {
        ...cardGridStyle,
        margin: '25px 0 0',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
});

export default useStyles;
