import React from 'react';
import useStyles from './index.style';
import ConsultantSearch from './consultant-search';
import { Trans } from 'react-i18next';

const Consultant = ({ preLogin }) => {
    const classes = useStyles();

    return (
        <div className={classes.consultantWrapper}>
            <span className={classes.definition}>
                <Trans
                    i18nKey="preLoginInfo.consultants"
                    components={{
                        br: <br />,
                    }}
                />
            </span>
            <ConsultantSearch preLogin={preLogin} />
        </div>
    );
};

export default Consultant;
