import { makeStyles } from '@material-ui/core';
import { COLOR_THEME_FILL_01, COLOR_SECONDARY_BASE, poppins500_12px } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    modal: {
        zIndex: '10000',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLOR_THEME_FILL_01,
        width: '700px',
        height: '690px',
        padding: '24px 78px 33px',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
    },
    modalHeading: {
        fontSize: '24px',
        fontWeight: '600',
        textAlign: 'center',
        color: COLOR_SECONDARY_BASE,
    },
    modalDescription: {
        ...poppins500_12px,
        textAlign: 'center',
        marginTop: '11px',
    },
    modalButtons: {
        marginTop: 'auto',
    },
    input: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    disabled: {
        opacity: 0.5,
    },
}));

export default useStyles;
