import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import useStyles from 'fintech/pages/pre-login/sections/Footer/index.style';
import { LANGUAGE_CODES } from 'fintech/constants';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import CBFOLogoTr from 'fintech/static/images/2_satir_yatay-03.png';
import CBFOLogoEn from 'fintech/static/images/ingilizce_yatay-02.png';
import SocialMediaIcons from 'fintech/components/applications/layout/pre-login/SocialMediaIcons/index';
import * as ROUTES from 'fintech/routes';
import {
    SECTION_STARTUP,
    SECTION_FAQ,
    SECTION_INVESTORS,
    SECTION_MENTORS,
    SECTION_CONSULTANTS,
} from 'fintech/components/util/HomePageConstants';
import { getLoggedInUserUuid } from 'fintech/utils/Auth';
import { ANONYMOUS } from 'fintech/constants';
import CookieBanner from 'fintech/components/legalText/cookieBanner';

const Footer = () => {
    const classes = useStyles();
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const selectedLanguage = getSystemLanguage();
    const logoObj = selectedLanguage === LANGUAGE_CODES.EN ? CBFOLogoEn : CBFOLogoTr;
    const { t } = useTranslation('preLogin');
    const userUuid = getLoggedInUserUuid(keycloak);

    const footerMenuElements = [
        {
            title: t('titles.startups'),
            onClick: () => history.push(`${ROUTES.HOME}/${SECTION_STARTUP}`),
        },
        {
            title: t('titles.mentors'),
            onClick: () => history.push(`${ROUTES.HOME}/${SECTION_MENTORS}`),
        },
        {
            title: t('titles.consultants'),
            onClick: () => history.push(`${ROUTES.HOME}/${SECTION_CONSULTANTS}`),
        },
        {
            title: t('titles.investors'),
            onClick: () => history.push(`${ROUTES.HOME}/${SECTION_INVESTORS}`),
        },
        {
            title: t('titles.faqList'),
            onClick: () => history.push(`${ROUTES.HOME}/${SECTION_FAQ}`),
        },
        {
            userUuidToBeChecked: ANONYMOUS,
            title: t('footer.login'),
            onClick: () => history.push(ROUTES.PLATFORM),
        },
    ];

    const footerMenuElementsJsx = footerMenuElements.map((el, ind) => {
        const isVisible = el.userUuidToBeChecked ? el.userUuidToBeChecked === userUuid : true;
        return (
            isVisible && (
                <span key={ind} onClick={el.onClick}>
                    {el.title}
                </span>
            )
        );
    });

    return (
        <>
            <footer
                className={classes.footerWrapper}
                style={{ marginLeft: '8px', marginRight: '8px', paddingBottom: '80px' }}
                id="footer"
            >
                <div className={classes.leftWrapper}>
                    <div className={classes.listStyle}>{footerMenuElementsJsx}</div>
                    <img src={logoObj} className={classes.cbfoLogo} />
                    <div className={classes.address}>{t('footer.address')}</div>
                </div>
                <div className={classes.rightWrapper}>
                    <SocialMediaIcons iconsWrapperClassname={classes.iconsWrapper} />
                    <div className={classes.policyContainer}>
                        <span onClick={() => history.push(ROUTES.CLARIFICATION_TEXT)}>{t('footer.clarification')}</span>
                        <span onClick={() => history.push(ROUTES.TERMS_OF_USE)}>{t('footer.termsOfUse')}</span>
                        <span onClick={() => history.push(ROUTES.PRIVACY_POLICY)}>{t('footer.privacyPolicy')}</span>
                        <span onClick={() => history.push(ROUTES.COOKIE_POLICY)}>{t('footer.cookiePolicy')}</span>
                    </div>
                    <span className={classes.copyRight}>{t('mobileMenu.copyRight')}</span>
                </div>
            </footer>
            <CookieBanner />
        </>
    );
};

export default Footer;
