import { makeStyles } from '@material-ui/core';
import {
    COLOR_PRIMARY_BASE,
    COLOR_THEME_FILL_01,
    poppins500_14px,
    COLOR_SECONDARY_SHADE_01,
    COLOR_PRIMARY_BUTTON_HOVERED,
} from 'fintech/components/style/common';

const useStyles = makeStyles({
    documents: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '20px',
    },
    documentHeader: {},
    title: {
        ...poppins500_14px,
        color: COLOR_SECONDARY_SHADE_01,
    },
    uploadNew: {
        backgroundColor: COLOR_PRIMARY_BASE,
        padding: '2px 10.5px',
        borderRadius: '8px',
        alignItems: 'center',
        cursor: 'pointer',
        '& > *': {
            ...poppins500_14px,
            color: COLOR_THEME_FILL_01,
        },
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
            color: COLOR_THEME_FILL_01,
        },
    },
    table: {
        width: '100%',
        marginTop: '31px',
        '& > thead > tr': {
            ...poppins500_14px,
            color: COLOR_SECONDARY_SHADE_01,

            '& > th': {
                textDecoration: 'underline',
            },
        },
    },
    docItemContainer: {
        ...poppins500_14px,
        '&:not(:last-child)': {
            borderBottom: '1px solid #f1f5f7',
        },
        '& > *': {
            padding: '5px 0',
        },
    },
    docName: {
        cursor: 'pointer',
        alignItems: 'center',
        '&:hover': {
            textDecoration: 'underline',
            color: COLOR_PRIMARY_BASE,
            '& g': {
                fill: COLOR_PRIMARY_BASE,
            },
        },
        '& > div:last-child': {
            marginLeft: '8px',
        },
    },
    pagination: {
        marginTop: 'auto',
        '& > ul': {
            justifyContent: 'center',
        },
    },
    notFound: {
        margin: '20px 0',
    },
});

export default useStyles;
