import * as React from 'react';

function SvgIconsHamburgerClose(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <g fill="#3F5162" fillRule="nonzero">
                    <g>
                        <path
                            d="M17.88 4.646l1.449 1.45L13.424 12l5.905 5.904-1.45 1.45-5.904-5.905-5.904 5.905-1.45-1.45L10.526 12 4.621 6.096l1.45-1.45 5.904 5.905 5.904-5.905z"
                            transform="translate(-319 -36) translate(319 36)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsHamburgerClose;
