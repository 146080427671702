import React, { useState, useEffect } from 'react';
import { Modal, Grid, Button } from '@material-ui/core';
import useStyles from './index.style';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import { useTranslation } from 'react-i18next';
import EscKeyListener from 'fintech/components/util/EscKeyListener';

function ModalMeetingOperation({
    open,
    setOpen,
    selectedMeeting,
    showCancelOrRejectReason,
    enableCancelOrRejectInput,
    modalHeading,
    actionButtonComponent,
    biggerHeight,
    validationText,
    inputLabel,
    validatedAfterFormSubmit = true,
    borderedSubject,
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    modalHeading = modalHeading || t('profile.user.consultant.meetingCalendar.meetingDetail.title');

    const initialRejectSubject = { val: '', validated: true };
    const [cancelOrRejectSubject, setCancelOrRejectSubject] = useState(initialRejectSubject);

    let modalContainer = `${classes.modalContainer}`;
    if (biggerHeight) {
        modalContainer += ` ${classes.modalReject}`;
    }

    const meetingSubjectClass = borderedSubject ? `${classes.modalDescription} ${classes.borderedSubject}` : {};

    useEffect(() => {
        if (!open) setCancelOrRejectSubject(initialRejectSubject);
    }, [open]);

    return (
        <>
            {open && <EscKeyListener onEsc={() => setOpen(false)} />}
            <Modal open={open} className={classes.modal} onClose={() => setOpen(false)}>
                <Grid container direction="column" className={modalContainer} tabIndex="">
                    <div className={classes.modalHeading}>{modalHeading}</div>
                    <div className={classes.modalBody}>
                        <Grid container className={classes.modalInfo}>
                            <Grid item md={6}>
                                <div className={classes.modalTitle}>
                                    {t('profile.user.consultant.meetingCalendar.meetingDetail.requestOwner')}
                                </div>
                                <div className={classes.modalDescription}>
                                    {`${selectedMeeting.meetingRequestOwner.name} ${selectedMeeting.meetingRequestOwner.surname}`}
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <div className={classes.modalTitle}>
                                    {t('profile.user.consultant.meetingCalendar.meetingDetail.relatedAttempt')}
                                </div>
                                <div className={classes.modalDescription}>{selectedMeeting.meetingStartup.name}</div>
                            </Grid>
                            <Grid item md={6}>
                                <div className={classes.modalTitle}>
                                    {t('profile.user.consultant.meetingCalendar.meetingDetail.date')}
                                </div>
                                <div className={classes.modalDescription}>{selectedMeeting.meetingDateTimeString}</div>
                            </Grid>
                            <Grid item md={6}>
                                <div className={classes.modalTitle}>
                                    {t('profile.user.consultant.meetingCalendar.meetingDetail.time')}
                                </div>
                                <div className={classes.modalDescription}>{selectedMeeting.meetingTimeString}</div>
                            </Grid>
                        </Grid>

                        <div>
                            <div className={classes.modalTitle}>
                                {t('profile.user.consultant.meetingCalendar.meetingDetail.subject')}
                            </div>
                            <div className={meetingSubjectClass} style={{ wordBreak: 'break-word' }}>
                                {selectedMeeting.meetingSubject}
                            </div>
                        </div>
                    </div>

                    {showCancelOrRejectReason && (
                        <InputTextArea
                            disabled
                            maxLength
                            multiline
                            rowCount={7}
                            initText={selectedMeeting.resultText}
                            containerClass={classes.modalRejectInputContainer}
                            className={classes.modalRejectInput}
                            validationText={validationText}
                            label={inputLabel}
                            type="text"
                            id="outlined-multiline-static"
                            variant="outlined"
                        />
                    )}

                    {enableCancelOrRejectInput && (
                        <InputTextArea
                            required
                            maxLength
                            validateOnBlur
                            validatedAfterFormSubmit={validatedAfterFormSubmit}
                            multiline
                            count={400}
                            onChange={(val, validated) => setCancelOrRejectSubject({ val, validated })}
                            rowCount={7}
                            initText=""
                            containerClass={classes.modalRejectInputContainer}
                            className={classes.modalRejectInput}
                            validationText={validationText}
                            label={inputLabel}
                            type="text"
                            id="outlined-multiline-static"
                            variant="outlined"
                        />
                    )}
                    <Grid container justify="space-between" className={classes.modalButtons}>
                        <Grid item>
                            <Button onClick={() => setOpen(false)}>
                                {t('profile.user.consultant.meetingCalendar.meetingDetail.close')}
                            </Button>
                        </Grid>
                        {actionButtonComponent && actionButtonComponent(cancelOrRejectSubject)}
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
}

export default ModalMeetingOperation;
