import { INVITE_URL } from 'fintech/components/util/TrafficStatusConstants';
import { MemberApplicationActions } from 'fintech/store/actions/ActionTypes';
import { isMailAddress } from 'fintech/utils/StringUtils';

const initialState = {
    // For invite URL scenarios
    isLoading: true,

    // Invite info
    url: '',
    companyName: '',
    companyIdentityNumber: '',
    companyType: '',
    title: '',
    startupId: null,
    investorId: null,

    name: {
        value: '',
        validated: false,
    },
    surname: {
        value: '',
        validated: false,
    },
    country: {
        value: '',
        validated: false,
    },
    idNumber: {
        value: '',
        validated: false,
    },
    gender: {
        value: null,
        validated: false,
    },
    birthDate: {
        value: '',
        validated: false,
    },
    email: {
        value: '',
        validated: false,
    },
    phoneNumber: {
        value: {
            country: 'TR',
            countryCallingCode: '90',
            nationalNumber: '',
            number: '',
        },
        validated: false,
    },
    experience: {
        value: '',
        validated: false,
    },
    education: {
        value: null,
        validated: false,
    },
    readAgreement: false,
    inviteFetch: false,
    trafficSource: INVITE_URL,

    // Select options
    options: {
        countries: {
            data: null,
            isLoading: true,
            isError: false,
        },
        genders: {
            data: null,
            isLoading: true,
            isError: false,
        },
        educationLevels: {
            data: null,
            isLoading: true,
            isError: false,
        },
    },
};

const investorApplication = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case MemberApplicationActions.SET_MEMBER_APPLICATION_INVITE_LOADING:
            return {
                ...state,
                isLoading: action.data,
            };
        case MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_NAME:
            return {
                ...state,
                name: action.data,
            };

        case MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_SURNAME:
            return {
                ...state,
                surname: action.data,
            };

        case MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_COUNTRY:
            return {
                ...state,
                country: action.data,
            };

        case MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_IDENTITY:
            return {
                ...state,
                idNumber: action.data,
            };

        case MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_GENDER:
            return {
                ...state,
                gender: action.data,
            };

        case MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_BIRTHDATE:
            return {
                ...state,
                birthDate: action.data,
            };

        case MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_EMAIL:
            return {
                ...state,
                email: action.data,
            };

        case MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: {
                    ...state.phoneNumber,
                    ...action.data,
                },
            };

        case MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_EXPERIENCE:
            return {
                ...state,
                experience: action.data,
            };
        case MemberApplicationActions.SET_MEMBER_APPLICATION_MEMBER_EDUCATION:
            return {
                ...state,
                education: action.data,
            };
        case MemberApplicationActions.SET_MEMBER_APPLICATION_AGREEMENTS:
            return {
                ...state,
                readAgreement: !state.readAgreement,
            };

        // From invite
        case MemberApplicationActions.SET_MEMBER_APPLICATION_INVITE_INFOS:
            return {
                ...state,
                name: { value: action.data.name, validated: action.data.name ? true : false },
                surname: { value: action.data.surname, validated: action.data.surname ? true : false },
                email: { value: action.data.email, validated: isMailAddress(action.data.email) },
                url: action.data.url,
                companyName: action.data.companyName,
                companyIdentityNumber: action.data.companyIdentityNumber,
                companyType: action.data.companyType,
                title: action.data.title,
                startupId: action.data.startupId,
                investorId: action.data.investorId,
                inviteFetch: true,
            };

        // Countries
        case MemberApplicationActions.SET_MEMBER_APPLICATION_COUNTRIES:
            const { countries } = action;
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        ...state.options.countries,
                        data: countries.data,
                        isLoading: false,
                    },
                },
            };
        case MemberApplicationActions.GET_MEMBER_APPLICATION_COUNTRIES:
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case MemberApplicationActions.SET_MEMBER_APPLICATION_COUNTRIES_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        ...state.options.countries,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Genders
        case MemberApplicationActions.SET_MEMBER_APPLICATION_GENDERS:
            const { genders } = action;
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        ...state.options.genders,
                        data: genders.data,
                        isLoading: false,
                    },
                },
            };
        case MemberApplicationActions.GET_MEMBER_APPLICATION_GENDERS:
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case MemberApplicationActions.SET_MEMBER_APPLICATION_GENDERS_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        ...state.options.genders,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Education levels
        case MemberApplicationActions.SET_MEMBER_APPLICATION_EDUCATION_LEVELS:
            const { levels } = action;
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        ...state.options.educationLevels,
                        data: levels.data,
                        isLoading: false,
                    },
                },
            };
        case MemberApplicationActions.GET_MEMBER_APPLICATION_EDUCATION_LEVELS:
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case MemberApplicationActions.SET_MEMBER_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        ...state.options.levels,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case MemberApplicationActions.RESET_MEMBER_APPLICATION_FORM:
            return initialState;
        default:
            return state;
    }
};

export default investorApplication;
