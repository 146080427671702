import React, { useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { isMailAddress, isUrl, isValidTckn, isValidVkn } from 'fintech/utils/StringUtils';

export default function InputTextArea({
    initText = '',
    count,
    label,
    placeholder,
    onChange,
    rowCount,
    type,
    validationText,
    maxCharactersText,
    required,
    isCleanText,
    className,
    containerClass,
    maxLength,
    validateOnBlur,
    disabled = false,
    dontValidate,
    validatedAfterFormSubmit = true,
    initiallyValidated,
    hideCountIndicator,
    flush,
    trackForFlush,
    startAdornment,
    endAdornment,
    capitalize,
    fullWidth,

    // Use with integer-positive
    includeZero = false,
}) {
    const [value, setValue] = React.useState(initText);
    const [validated, setValidated] = React.useState(validateOnBlur ? true : validation(value));
    const [remainingCount, setRemainingCount] = React.useState(count - (value ? value.length : 0));

    useEffect(() => {
        if (initiallyValidated) {
            onChange(value, true);
        }
    }, []);

    useEffect(() => {
        if (dontValidate && validatedAfterFormSubmit) setValidated(true);
    }, [dontValidate]);

    useEffect(() => {
        if (!validatedAfterFormSubmit) {
            setValidated(validation(value));
        }
    }, [validatedAfterFormSubmit]);

    useEffect(() => {
        !disabled && flush && setValue('');
    }, [trackForFlush]);

    // Used if the disabled value of the component is provided later
    useEffect(() => {
        disabled && setValue(capitalize ? initText.toLocaleUpperCase('TR') : initText);
    }, [initText]);

    function validation(value) {
        if (dontValidate) {
            return true;
        }
        let result = false;
        if (type == 'mail') {
            result = isMailAddress(value);
        } else if (type == 'text' || type == 'password') {
            if (value && value != '') {
                if (count && count > 0) {
                    result = count >= value.length;
                } else {
                    result = true;
                }
            }
        } else if (type == 'url') {
            if (required) {
                result = isUrl(value);
            } else {
                result = value ? value === '' || isUrl(value) : true;
            }
        } else if (['identity', 'integer-positive'].includes(type)) {
            result = value?.length > 0 && (includeZero ? +value >= 0 : +value > 0);
            if (count && count > 0) {
                return !required && value?.length == 0 ? true : result && count >= value?.length;
            } else {
                return !required && value?.length == 0 ? true : result;
            }
        } else if (type == 'tckn') {
            result = +value > 0 && isValidTckn(value);
        } else if (type == 'vkn') {
            result = +value > 0 && isValidVkn(value);
        }
        return !required && value?.length == 0 ? true : result;
    }

    function inputFilter(e) {
        // Can be extended
        if (type === 'integer' && ['e', 'E', ',', '.'].includes(e.key)) {
            e.preventDefault();
        } else if (
            ['tckn', 'vkn', 'identity', 'integer-positive'].includes(type) &&
            !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)
        ) {
            e.preventDefault();
        }

        if (count && value?.length >= count) {
            e.preventDefault();
        }
    }

    const handleChange = (event) => {
        let tmpValue = event.target.value;

        if (type === 'integer-positive') {
            if (parseInt(tmpValue) < 0) {
                event.preventDefault();
                return;
            }
        }

        if (count && tmpValue?.length >= count) {
            tmpValue = tmpValue.substr(0, count);
        }
        const trim = v => isCleanText ? v.trim() : v;
        const capt = v => capitalize ? v.toLocaleUpperCase('TR') : v;

        setValue(capt(trim(tmpValue)));

        if (!validateOnBlur) {
            setValidated(validation(isCleanText ? tmpValue.trim() : tmpValue));
        }

        onChange(tmpValue, validation(isCleanText && tmpValue ? tmpValue.trim() : tmpValue));

        let length = 0;
        if (isCleanText && tmpValue) {
            length = tmpValue.trim().length;
        } else if (tmpValue) {
            length = tmpValue.length;
        }
        setRemainingCount(count - length);
    };

    const getInputType = (type) => {
        // TODO: Extend if necessary
        if (['integer', 'integer-positive'].includes(type)) {
            return 'number';
        } else if (['number-positive', 'tckn', 'vkn', 'identity'].includes(type)) {
            return 'text';
        } else {
            return type;
        }
    };

    let inputProps = { maxLength: maxLength ? count : '' };
    if (startAdornment) {
        inputProps.startAdornment = startAdornment;
    }
    if (endAdornment) {
        inputProps.endAdornment = endAdornment;
    }

    return (
        <div className={'inputTextAreaContainer poppin-500-14px'} style={fullWidth ? { width: '100%' } : {}}>
            <DialogContent className={containerClass || ''}>
                <TextField
                    disabled={disabled}
                    className={className || ''}
                    error={!validated}
                    label={label}
                    placeholder={placeholder}
                    type={getInputType(type)}
                    multiline={rowCount && rowCount > 0 ? true : false}
                    rowsMax={rowCount && rowCount > 0 ? rowCount : 1}
                    rows={rowCount && rowCount > 0 ? rowCount : 1}
                    autoComplete="current-password"
                    variant="outlined"
                    value={value || ''}
                    onKeyPress={inputFilter}
                    onChange={handleChange}
                    helperText={!validated ? (count && remainingCount < 0 ? maxCharactersText : validationText) : ''}
                    InputProps={inputProps}
                    onBlur={
                        validateOnBlur
                            ? (event) => {
                                const tmpValue = event.target.value;
                                setValidated(validation(isCleanText ? tmpValue.trim() : tmpValue));
                            }
                            : () => { }
                    }
                />
                {!hideCountIndicator && count && count > 0 && (
                    <div className={'d-flex align-items-end justify-content-end margin-top-8'}>
                        <div className={'countContainer'}>{remainingCount}</div>
                    </div>
                )}
            </DialogContent>
        </div>
    );
}
