import React from 'react';
import useStyles from './index.style';
import Button from '@material-ui/core/Button';
import IconsRightArrow from 'fintech/icons/IconsRightArrow';
import MentorSearch from './mentor-search';
import { Trans, useTranslation } from 'react-i18next';
import * as ROUTES from 'fintech/routes';
import { useHistory } from 'react-router';

const Mentor = ({ isMentorApplicationActive, supportProgramId }) => {
    const { t } = useTranslation('preLogin');
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.mentorWrapper}>
            {!supportProgramId && (
                <span className={classes.definition}>
                    <Trans
                        i18nKey="preLoginInfo.mentors"
                        components={{
                            br: <br />,
                        }}
                    />
                </span>
            )}
            {isMentorApplicationActive ? (
                <Button
                    variant="contained"
                    endIcon={<IconsRightArrow></IconsRightArrow>}
                    style={{
                        height: 48,
                        padding: '8px 48px 8px 49px',
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
                        marginTop: 35,
                        marginBottom: 65,
                    }}
                    className={classes.applyButton}
                    onClick={() => history.push(ROUTES.MENTOR_APPLICATION)}
                >
                    {t('applyMentor')}
                </Button>
            ) : (
                <>
                    {!supportProgramId && (
                        <div
                            style={{
                                height: 25,
                                marginTop: 35,
                            }}
                        />
                    )}
                </>
            )}
            <MentorSearch />
        </div>
    );
};

export default Mentor;
