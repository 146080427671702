import React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDefaultStyles, useSelectedStyles } from './index.style';

const CfoRadioGroup = (props) => {
    const {
        label,
        options,
        value,
        defaultValue,
        onChange,
        disabled,
        row,
        classFormControl = '',
        classFormControlLabel,
        classFormControlLabelSelected,
        classRadioLabel,
        classRadioLabelSelected,
        classRadio,
        classRadioSelected,
        // If true, main color of the default styles is COLOR_PRIMARY_BASE
        secureTheme = false,
    } = props;
    const classesDef = useDefaultStyles({ secureTheme });
    const classesSelected = useSelectedStyles({ secureTheme });

    const changeHandler = (e) => {
        onChange && onChange(e?.target?.value);
    };

    const optsJSX = options.map((opt, idx) => {
        // Override default styles - start
        let radioLabelRoot = '';
        if (opt.value === value) {
            radioLabelRoot = classFormControlLabelSelected
                ? classFormControlLabelSelected
                : classesSelected.radioLabelRoot;
        } else {
            radioLabelRoot = classFormControlLabel ? classFormControlLabel : classesDef.radioLabelRoot;
        }

        let radioLabel = '';
        if (opt.value === value) {
            radioLabel = classRadioLabelSelected ? classRadioLabelSelected : classesSelected.radioLabel;
        } else {
            radioLabel = classRadioLabel ? classRadioLabel : classesDef.radioLabel;
        }

        let radio = '';
        if (opt.value === value) {
            radio = classRadioSelected ? classRadioSelected : classesSelected.radio;
        } else {
            radio = classRadio ? classRadio : classesDef.radio;
        }
        // Override default styles - end

        return (
            <FormControlLabel
                classes={{ root: radioLabelRoot, label: radioLabel }}
                key={idx}
                value={opt.value}
                control={<Radio classes={{ colorPrimary: radio }} color="primary" />}
                label={opt.label}
            />
        );
    });

    return (
        <FormControl disabled={disabled} component="div" className={classFormControl}>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <RadioGroup row={row} onChange={changeHandler} value={value} defaultValue={defaultValue}>
                {optsJSX}
            </RadioGroup>
        </FormControl>
    );
};

CfoRadioGroup.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    row: PropTypes.bool,
    classFormControl: PropTypes.string,
    classFormControlLabel: PropTypes.string,
    classFormControlLabelSelected: PropTypes.string,
    classRadioLabel: PropTypes.string,
    classRadioLabelSelected: PropTypes.string,
    classRadio: PropTypes.string,
    classRadioSelected: PropTypes.string,
    secureTheme: PropTypes.bool,
};

export default CfoRadioGroup;
