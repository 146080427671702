import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardActions } from '@material-ui/core';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { COLOR_PRIMARY_BASE, COLOR_PRIMARY_BUTTON_HOVERED, COLOR_THEME_FILL_01 } from 'fintech/components/style/common';
import { SUPPORT_PROGRAM_ONE_PAGE_PRE } from 'fintech/routes';
import ProgramCardContent from '../ProgramCardContent';

const defaultCardButton = {
    lineHeight: 'normal',
    borderRadius: 10,
    backgroundColor: COLOR_PRIMARY_BASE,
    fontSize: 14,
    height: 28,
    minWidth: 'fit-content',
    textTransform: 'none',
    color: COLOR_THEME_FILL_01,
    padding: '16px 24px',
    '&:hover': {
        backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
        color: COLOR_THEME_FILL_01,
    },
};

const useStyles = makeStyles({
    root: {
        width: 287,
        marginRight: 20,
        height: 344,
        padding: '24px 16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '10px!important',
        boxShadow: 'none',
        border: ' 1px solid #D8E3E9',
    },
    cardButton: {
        ...defaultCardButton,
    },
});

const ProgramCard = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('supportProgram');
    const { id, ...cardContent } = props;
    const history = useHistory();

    const onDetailsButtonClick = (e) => {
        e.stopPropagation();
        history.push(`${SUPPORT_PROGRAM_ONE_PAGE_PRE}/${id}`);
    };

    return (
        <Card className={classes.root}>
            <ProgramCardContent {...cardContent} />
            <CardActions style={{ padding: '0px' }}>
                <Button size="small" className={classes.cardButton} onClick={onDetailsButtonClick}>
                    {t('list.card.showDetails')}
                </Button>
            </CardActions>
        </Card>
    );
};

export default ProgramCard;
