import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Step, StepButton, StepConnector, Stepper } from '@material-ui/core';
import CardItem from '../CardItem';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiStepper-root': {
            padding: '24px 12px 48px 12px',
        },
        '& .MuiStepIcon-root': {
            color: '#B2C0CE',
            fontWeight: 'bold',
            border: '2px solid #F1F5F7',
            borderRadius: '50%',
            fontSize: '2rem',
        },
        '& .MuiStepIcon-active': {
            color: '#3F5162',
        },
        '& .MuiStepConnector-alternativeLabel': {
            top: '15px',
        },
    },
}));

const ColorlibConnector = withStyles({
    line: {
        height: 2,
        border: 0,
        backgroundColor: '#F1F5F7',
    },
})(StepConnector);

export default function MobileStepper({ supportProgramOnePage, supportProgramId }) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const steps = [...supportProgramOnePage.timesheetAreaData];

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    function getStepContent(step) {
        let list = _.sortBy(steps, [
            function (o) {
                return o.id;
            },
        ]);
        const foundSupportProgramOnePage = list.find((element) => {
            return Number(element.id) === step + 1;
        });
        return <CardItem item={foundSupportProgramOnePage} supportProgramId={supportProgramId} />;
    }

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label, index) => {
                    return (
                        <Step key={`step_${index}`}>
                            <StepButton onClick={handleStep(index)} />
                        </Step>
                    );
                })}
            </Stepper>
            <div>{getStepContent(activeStep)}</div>
        </div>
    );
}
