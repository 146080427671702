import * as React from 'react';

function SvgIconsSingleChevronUp(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <g fill="#3F5162" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            d="M9.899 17.399L15.399 11.899 9.899 6.399 8.601 7.697 12.825 11.899 8.601 16.101z"
                                            transform="translate(-1281 -32) translate(120 20) translate(1093) translate(18 12) translate(50) rotate(-90 12 11.899)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleChevronUp;
