import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { getIsMentorApplicationActive } from 'fintech/api/fintechService';
import * as ROUTES from 'fintech/routes';
import ApplicationPageTemplate from 'fintech/components/applications/layout/pre-login/PageTemplate';
import membershipImage from 'fintech/static/images/mentor.jpg';
import PersonalInfoForm from 'fintech/components/applications/form/mentor/PersonalInfoForm';
import ApplicationResult from 'fintech/components/applications/form/common/ApplicationResult';
import CoverLetterAndInterestsForm from 'fintech/components/applications/form/mentor/CoverLetterAndInterestsForm';
import { useDispatch } from 'react-redux';
import { MentorApplicationActions } from 'fintech/store/actions/ActionTypes';
import LoadingPage from 'fintech/pages/LoadingPage';

const APPLICATION_ACTIVE_STATE = {
    ACTIVE: 'ACTIVE',
    PASSIVE: 'PASSIVE',
    UNKNOWN: 'UNKNOWN',
};

export const MentorApplicationPage = () => {
    const { keycloak } = useKeycloak();
    const [mentorApplicationActiveState, setMentorApplicationActiveState] = useState(APPLICATION_ACTIVE_STATE.UNKNOWN);
    const { t } = useTranslation('application');
    const [tab, setTab] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        getIsMentorApplicationActive(keycloak)
            .then((response) => {
                if (response) {
                    if (response.data) {
                        setMentorApplicationActiveState(APPLICATION_ACTIVE_STATE.ACTIVE);
                    } else {
                        setMentorApplicationActiveState(APPLICATION_ACTIVE_STATE.PASSIVE);
                    }
                } else {
                    setMentorApplicationActiveState(APPLICATION_ACTIVE_STATE.PASSIVE);
                }
            })
            .catch((reason) => {
                console.error(reason.toString());
                setMentorApplicationActiveState(APPLICATION_ACTIVE_STATE.PASSIVE);
            });
        dispatch({ type: MentorApplicationActions.RESET_MENTOR_APPLICATION_FORM });
    }, []);

    const switchTab = (next) => setTab((prev) => (next ? prev + 1 : prev - 1));

    const getSelectedTab = () => {
        switch (tab) {
            case 0:
                return <PersonalInfoForm switchTabHandler={switchTab} />;
            case 1:
                return <CoverLetterAndInterestsForm switchTabHandler={switchTab} />;
            case 2:
                dispatch({ type: MentorApplicationActions.RESET_MENTOR_APPLICATION_FORM });
                return <ApplicationResult title={t('mentor.resultTitle')} info={t('common.resultInfo')} />;
        }
    };

    return mentorApplicationActiveState === APPLICATION_ACTIVE_STATE.ACTIVE ? (
        <ApplicationPageTemplate
            image={membershipImage}
            imageTitle={t('mentor.title')}
            options={[t('mentor.tabs.personalInfo'), t('mentor.tabs.coverLetterAndInterests'), t('common.tabs.result')]}
            currentIndex={tab}
        >
            {getSelectedTab()}
        </ApplicationPageTemplate>
    ) : mentorApplicationActiveState === APPLICATION_ACTIVE_STATE.PASSIVE ? (
        <Redirect to={ROUTES.NOT_FOUND} />
    ) : (
        <LoadingPage />
    );
};
