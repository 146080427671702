import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import { useTranslation } from 'react-i18next';

import InstagramIcon from '@material-ui/icons/Instagram';
import Twitter from '@material-ui/icons/Twitter';
import YouTube from '@material-ui/icons/YouTube';
import { LinkedIn } from '@material-ui/icons';

import useStyles from 'fintech/components/profile/user/modal/tabs/SocialMediaTab/index.style';

export default function SocialMediaTab({ initData }) {
    const { t } = useTranslation();
    const classes = useStyles();

    const [selectedSocialMedia, setSelectedSocialMedia] = useState('instagram');

    const [instagram, setInstagram] = useState(initData ? initData.instagram : {});
    const [twitter, setTwitter] = useState(initData ? initData.twitter : {});
    const [youtube, setYoutube] = useState(initData ? initData.youtube : {});
    const [linkedin, setLinkedin] = useState(initData ? initData.linkedin : {});

    if (initData && instagram !== initData.instagram) {
        setInstagram(initData.instagram);
    }

    if (initData && twitter !== initData.twitter) {
        setTwitter(initData.twitter);
    }

    if (initData && youtube !== initData.youtube) {
        setYoutube(initData.youtube);
    }

    if (initData && linkedin !== initData.linkedin) {
        setLinkedin(initData.linkedin);
    }

    const selectSocialMedia = (socialMedia) => {
        setSelectedSocialMedia(socialMedia);
    };

    const instagramChange = (val, validated) => {
        const tmpInstagram = instagram;
        tmpInstagram.value = val ? val : '';
        tmpInstagram.validated = validated;
        setInstagram(tmpInstagram);
    };

    const twitterChange = (val, validated) => {
        const tmpTwitter = twitter;
        tmpTwitter.value = val ? val : '';
        tmpTwitter.validated = validated;
        setTwitter(tmpTwitter);
    };

    const youtubeChange = (val, validated) => {
        const tmpYoutube = youtube;
        tmpYoutube.value = val ? val : '';
        tmpYoutube.validated = validated;
        setYoutube(tmpYoutube);
    };

    const linkedinChange = (val, validated) => {
        const tmpLinkedin = linkedin;
        tmpLinkedin.value = val ? val : '';
        tmpLinkedin.validated = validated;
        setLinkedin(tmpLinkedin);
    };

    const getSocialMediaIconClass = (socialMedia) =>
        selectedSocialMedia === socialMedia ? classes.ActiveSocialMediaIcon : classes.SocialMediaIcon;

    return (
        <Grid container direction={'column'} alignContent="flex-start" style={{ height: '100%' }}>
            <Grid container direction={'row'}>
                <InstagramIcon
                    className={getSocialMediaIconClass('instagram')}
                    onClick={() => selectSocialMedia('instagram')}
                />
                <Twitter className={getSocialMediaIconClass('twitter')} onClick={() => selectSocialMedia('twitter')} />
                <YouTube className={getSocialMediaIconClass('youtube')} onClick={() => selectSocialMedia('youtube')} />
                <LinkedIn
                    className={getSocialMediaIconClass('linkedin')}
                    onClick={() => selectSocialMedia('linkedin')}
                />
            </Grid>
            <Grid container direction={'row'}>
                {selectedSocialMedia === 'instagram' && (
                    <Grid xs={12} item className="fill-width">
                        <InputTextArea
                            initText={instagram ? instagram.value : ''}
                            label={'Instagram'}
                            onChange={(val, validated) => instagramChange(val, validated)}
                            rowCount={1}
                            type={'url'}
                            required={false}
                            isCleanText
                            validationText={t('validation.url')}
                        />
                    </Grid>
                )}

                {selectedSocialMedia === 'twitter' && (
                    <Grid xs={12} item className="fill-width">
                        <InputTextArea
                            initText={twitter ? twitter.value : ''}
                            label={'Twitter'}
                            onChange={(val, validated) => twitterChange(val, validated)}
                            rowCount={1}
                            type={'url'}
                            required={false}
                            isCleanText
                            validationText={t('validation.url')}
                        />
                    </Grid>
                )}

                {selectedSocialMedia === 'youtube' && (
                    <Grid xs={12} item className="fill-width">
                        <InputTextArea
                            initText={youtube ? youtube.value : ''}
                            label={'Youtube'}
                            onChange={(val, validated) => youtubeChange(val, validated)}
                            rowCount={1}
                            type={'url'}
                            required={false}
                            isCleanText
                            validationText={t('validation.url')}
                        />
                    </Grid>
                )}

                {selectedSocialMedia === 'linkedin' && (
                    <Grid xs={12} item className="fill-width">
                        <InputTextArea
                            initText={linkedin ? linkedin.value : ''}
                            label={'Linkedin'}
                            onChange={(val, validated) => linkedinChange(val, validated)}
                            rowCount={1}
                            type={'url'}
                            required={false}
                            isCleanText
                            validationText={t('validation.url')}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
