import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    testimonialSection: {
        padding: '65px 120px 84px',
        backgroundColor: '#f9fbfc',

        [theme.breakpoints.down('md')]: {
            padding: '48px 22px 58px 22px',
        },
    },
    testimonialWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',

        '& > div:not(:first-child)': {
            width: '100%',
        },
    },
    indicator: {
        backgroundColor: '#3f5162',
    },
    customTabRoot: {
        color: '#3f5162',
        '& .MuiTabs-flexContainer': {
            gap: '115px',
            [theme.breakpoints.down('md')]: {
                gap: '50px',
            },
        },
    },
    tabRoot: {
        fontSize: '30px',
        fontWeight: '500',
        textTransform: 'none',
        marginBottom: 10,
        '&[aria-selected="false"]': {
            color: '#3f5162',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },
}));

export default useStyles;
