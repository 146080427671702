import React from 'react';
import { TextField } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

// The purpose of this component is to provide a basis to extend upon.
// The component itself only provides the basic styling.
const SimpleAutocomplete = (props) => {
    // IMPORTANT
    // withStyles hook causes problems with Autocomplete component
    /*
    const StyledAutoComplete = withStyles({
        listbox: {
            backgroundColor: COLOR_PRIMARY_SHADE_02,
        },
    })(Autocomplete); 
    */

    return (
        <Autocomplete
            id="simple-autocomplete"
            popupIcon={<ExpandMore />}
            renderInput={(params) => <TextField {...params} label="simple-select" variant="outlined" />}
            {...props}
        />
    );
};

export default SimpleAutocomplete;
