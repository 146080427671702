import React, { useState } from 'react';
import useStyles from './index.style';
import CategoryItem from './CategoryItem';
import QuestionItem from './QuestionItem';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_CODES } from 'fintech/constants';
import TurkishFaq from './turkish-faq.json';
import EnglishFaq from './english-faq.json';

const Faq = ({ supportProgramOnePage }) => {
    const classes = useStyles();
    let questions;

    if (getSystemLanguage() === LANGUAGE_CODES.TR) {
        questions = TurkishFaq;
    } else {
        questions = EnglishFaq;
    }

    const { t } = useTranslation('preLogin');
    const [activeCategory, setActiveCategory] = useState(
        supportProgramOnePage ? Object.keys(questions)[2] : Object.keys(questions)[0]
    );

    return (
        <div className={classes.faqSection} id="section-faq">
            <div className={classes.title}>{t('faq')}</div>
            {!supportProgramOnePage && (
                <div className={classes.categoryWrapper}>
                    {Object.keys(questions).map((category, index) => {
                        return (
                            <CategoryItem
                                category={category}
                                setActiveCategory={setActiveCategory}
                                activeCategory={activeCategory}
                                key={index}
                            />
                        );
                    })}
                </div>
            )}
            <div className={classes.questionWrapper}>
                {questions[activeCategory].map((question, index) => {
                    return <QuestionItem item={question} key={index} activeCategory={activeCategory}></QuestionItem>;
                })}
            </div>
        </div>
    );
};

export default Faq;
