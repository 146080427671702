import * as React from 'react';

function SvgIconsSet(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="88"
            height="36"
            viewBox="0 0 88 36"
        >
            <defs>
                <filter id="thu1geoypa" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.858824 0 0 0 0 0.356863 0 0 0 0 0.356863 0 0 0 1.000000 0"
                    />
                </filter>
                <filter
                    id="jjqgrt5u3b"
                    width="186.6%"
                    height="170.6%"
                    x="-43.3%"
                    y="-29.4%"
                    filterUnits="objectBoundingBox"
                >
                    <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter1" />
                    <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                    <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                </filter>
                <path
                    id="34qfnakxkc"
                    d="M3.982 10l-.887-1.355C2.49 7.72 1.2 7.383.214 7.89l.23-.118c-.245.126-.362.44-.262.7l1.228 3.175c.194.504.691 1.216 1.103 1.573 0 0 2.469 2.044 2.469 2.81V17h4l1-2 1 2h1v-.97c0-.766 1.509-3.18 1.509-3.18.277-.468.509-1.295.509-1.843V6.972c-.018-.894-.777-1.617-1.714-1.617-.47 0-.849.362-.849.808v.324c0-.894-.76-1.618-1.696-1.618-.469 0-.848.362-.848.81V6c0-.893-.76-1.617-1.697-1.617-.468 0-.848.362-.848.809v.323c0-.143-.015-.258-.045-.348l-.259-4.167C6.01.438 5.534 0 4.982 0c-.556 0-1 .448-1 1v9zM11 9h1v4h-1V9zM9 9h1v4H9V9zM7 9h1v4H7V9z"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g fill="#3F5162" fillRule="nonzero">
                            <path
                                d="M12 18.702l1.098-.99c1.632-1.488 2.754-2.544 3.366-3.168 1.02-1.032 1.764-1.95 2.232-2.754.528-.924.792-1.836.792-2.736 0-.756-.18-1.449-.54-2.079-.36-.63-.855-1.125-1.485-1.485-.63-.36-1.323-.54-2.079-.54-.648 0-1.272.144-1.872.432-.6.288-1.104.672-1.512 1.152-.408-.48-.912-.864-1.512-1.152-.6-.288-1.218-.432-1.854-.432-.768 0-1.464.18-2.088.54-.624.36-1.119.855-1.485 1.485-.366.63-.549 1.323-.549 2.079 0 .912.27 1.83.81 2.754.456.804 1.194 1.722 2.214 2.754.648.66 1.776 1.722 3.384 3.186l1.08.954zm0-2.016l-.072-.072c-1.488-1.332-2.532-2.298-3.132-2.898-.936-.936-1.626-1.752-2.07-2.448-.492-.78-.738-1.518-.738-2.214 0-.492.114-.936.342-1.332.228-.396.543-.705.945-.927.402-.222.855-.333 1.359-.333.576 0 1.116.165 1.62.495.504.33.852.753 1.044 1.269h1.404c.204-.516.555-.939 1.053-1.269.498-.33 1.041-.495 1.629-.495.504 0 .954.111 1.35.333.396.222.708.531.936.927.228.396.342.84.342 1.332 0 .696-.246 1.434-.738 2.214-.444.696-1.134 1.512-2.07 2.448-.6.6-1.644 1.566-3.132 2.898l-.072.072z"
                                transform="translate(-796 -426) translate(796 426)"
                            />
                        </g>
                        <g filter="url(#thu1geoypa)" transform="translate(-796 -426) translate(796 426)">
                            <g>
                                <path
                                    fill="#3F5162"
                                    fillRule="nonzero"
                                    d="M12 18.702l1.098-.954c1.608-1.464 2.73-2.526 3.366-3.186 1.02-1.032 1.764-1.95 2.232-2.754.528-.924.792-1.842.792-2.754 0-.756-.18-1.449-.54-2.079-.36-.63-.855-1.125-1.485-1.485-.63-.36-1.323-.54-2.079-.54-.648 0-1.272.144-1.872.432-.6.288-1.104.672-1.512 1.152-.408-.48-.912-.864-1.512-1.152-.6-.288-1.218-.432-1.854-.432-.768 0-1.464.18-2.088.54-.624.36-1.119.855-1.485 1.485-.366.63-.549 1.323-.549 2.079 0 .912.27 1.824.81 2.736.456.804 1.194 1.722 2.214 2.754.624.636 1.752 1.692 3.384 3.168l1.08.99z"
                                    transform="translate(64)"
                                />
                            </g>
                        </g>
                        <g fill="#3F5162" fillRule="nonzero">
                            <path
                                d="M14.425 16c.108 0 .19-.028.244-.083l3.25-3.648c.054-.055.081-.138.081-.248 0-.11-.027-.194-.081-.249-.027-.028-1.11-1.244-3.25-3.648-.163-.165-.325-.165-.488 0-.162.166-.162.332 0 .498l2.722 3.067H6.3c-.217 0-.325.11-.325.332 0 .22.108.331.325.331h10.603l-2.722 3.068c-.162.165-.162.331 0 .497.054.055.136.083.244.083z"
                                transform="translate(-796 -426) translate(796 426) translate(32)"
                            />
                        </g>
                        <g transform="translate(-796 -426) translate(796 426) translate(15 14)">
                            <use fill="#000" filter="url(#jjqgrt5u3b)" xlinkHref="#34qfnakxkc" />
                            <path
                                fill="#FFF"
                                stroke="#000"
                                strokeOpacity=".8"
                                d="M4.982-.5c.408 0 .788.161 1.07.428.28.264.466.63.491 1.042h0l.186 2.996.016-.007c.094-.032.193-.055.295-.066l.156-.009c.604 0 1.15.235 1.545.612.045.043.088.087.129.134l.024.03.011-.01c.19-.145.424-.243.68-.272l.156-.009c.604 0 1.15.235 1.544.612.045.043.088.088.13.134l.024.03.01-.009c.191-.146.425-.244.68-.273l.157-.008c.601 0 1.145.233 1.542.608.408.384.662.917.672 1.504h0v4.04c0 .624-.263 1.565-.579 2.098-.006.01-1.44 2.217-1.44 2.925h0v1.47h-1.808l-.691-1.382L9.29 17.5H4.48v-1.47c0-.572-1.588-1.874-2.123-2.297l-.126-.099-.047-.036c-.463-.403-1.023-1.204-1.242-1.77h0L-.284 8.652c-.095-.245-.09-.516 0-.755.09-.243.266-.45.5-.57h0l.006.013.205-.063c.524-.144.986-.148 1.416-.05l.182.048c.538.163 1.027.485 1.376.938h0l.08.12V1c0-.363.13-.696.343-.955l.097-.107c.27-.27.644-.438 1.06-.438z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSet;
