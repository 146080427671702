import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_SHADE_02, COLOR_SECONDARY_BASE, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    InvRequestDialog: {
        padding: '16px',
        margin: '0 24px 8px',
        borderRadius: '4px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
    },
    InvRequestLabel: {
        ...fontStyle,
        lineHeight: 1.71,
        letterSpacing: '0.13px',
        color: COLOR_SECONDARY_BASE,
    },
});

export default useStyles;
