import React, { useState } from 'react';
import { Grid, Popover } from '@material-ui/core';
import IconsSingleCheck from 'fintech/icons/IconsSingleCheck';
import IconsSingleClose2 from 'fintech/icons/IconsSingleClose2';
import useStyles from './index.style';
import * as MeetingStatus from 'fintech/components/profile/user/consultant/calendar/MeetingStatus/MeetingStatus';
import { useTranslation } from 'react-i18next';

const TimeSlot = ({ ...props }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    if (!props.slot) return null;

    const AvailableSlot = ({ slot }) => (
        <Grid container alignItems="center" className={classes.availableSlot}>
            <Grid item>
                <IconsSingleCheck />
            </Grid>
            <Grid item>{slot.slotTimeString}</Grid>
        </Grid>
    );
    const UnavailableSlot = ({ slot }) => (
        <Grid container alignItems="center" className={classes.unavailableSlot}>
            <Grid item>
                <IconsSingleClose2 />
            </Grid>
            <Grid item>{slot.slotTimeString}</Grid>
        </Grid>
    );
    const DisabledSlot = ({ slot }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const handlePopoverOpen = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
            setAnchorEl(null);
        };

        const open = !!anchorEl;
        return (
            <>
                <Grid
                    container
                    alignItems="center"
                    className={classes.disabledSlot}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    <Grid item>{slot.slotTimeString}</Grid>
                </Grid>
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <div>
                        {t(
                            'profile.user.consultant.meetingCalendar.organizeWorkingHours.timeSlot.approvedOrPendingRequest'
                        )}
                    </div>
                </Popover>
            </>
        );
    };

    switch (props.slot.status.type) {
        case MeetingStatus.AVAILABLE:
            return <AvailableSlot {...props} />;
        case MeetingStatus.NOT_AVAILABLE:
            return <UnavailableSlot {...props} />;
        default:
            return <DisabledSlot {...props} />;
    }
};

export default TimeSlot;
