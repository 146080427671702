import { makeStyles } from '@material-ui/core';
import { buttonStyle, COLOR_SECONDARY_SHADE_01, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    titleTypo: {
        ...fontStyle,
        fontSize: '32px',
        marginBottom: '32px',
    },
    radioGrpDiv: {
        marginBottom: '56px',
    },
    infoTypo: {
        ...fontStyle,
        fontWeight: 'normal',
        fontSize: '16px',
        color: COLOR_SECONDARY_SHADE_01,
        marginBottom: '16px',
    },
    inputTextAreaWrapper: {
        padding: '12px 0 12px 0',
        '&.MuiDialogContent-root:first-child': {
            padding: '12px 0 12px 0',
        },
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    continueBtn: {
        ...buttonStyle,
        width: '100%',
        marginTop: '44px',
        height: '48px',
    },
    divInputLeft: {
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },
    divInputRight: {
        paddingLeft: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    legalDiv: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& p': {
            paddingBottom: '10px',
            '& span': {
                color: '#5b7adb',
                textDecoration: 'underline',
                cursor: 'pointer',
            }
        },
    },
}));

export default useStyles;
