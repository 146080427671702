import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconsMentorler from 'fintech/icons/IconsMentorler';
import IconsDanısmanlar from 'fintech/icons/IconsDanısmanlar';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import Mentor from './Mentor';
import Consultant from './Consultant';
import { useTranslation } from 'react-i18next';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const Testimonial = (props) => {
    const { t } = useTranslation('preLogin');
    const [value, setValue] = useState(0);
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <section className={classes.testimonialSection} id="section-testimonial">
            <div className={classes.testimonialWrapper}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    classes={{
                        root: classes.customTabRoot,
                        indicator: classes.indicator,
                    }}
                >
                    <Tab
                        label={t('titles.mentors')}
                        className={classes.tabRoot}
                        icon={<IconsMentorler />}
                        {...a11yProps(0)}
                        id="mentors"
                    />
                    <Tab
                        label={t('titles.consultants')}
                        className={classes.tabRoot}
                        icon={<IconsDanısmanlar />}
                        {...a11yProps(1)}
                        id="consultants"
                    />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Mentor isMentorApplicationActive={props.isMentorApplicationActive} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Consultant preLogin />
                </TabPanel>
            </div>
        </section>
    );
};

export default Testimonial;
