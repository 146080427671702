import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import RichTextArea from 'fintech/components/ui/form/textinput/RichTextArea';

export default function GeneralInfoActionModal({ initData }) {
    const [generalInfo, setGeneralInfo] = useState(initData);
    if (initData && initData.value !== generalInfo.value) {
        setGeneralInfo(initData);
    }

    const generalInfoChange = (val, validated) => {
        const tmpGeneralInfo = generalInfo;
        tmpGeneralInfo.value = val ? val : '';
        tmpGeneralInfo.validated = validated;
        setGeneralInfo(tmpGeneralInfo);
    };

    return (
        <div>
            {generalInfo && (
                <Grid container direction={'row'}>
                    <Grid xs={12} item className={'full-width'}>
                        <RichTextArea
                            count={1000}
                            initText={generalInfo.value}
                            onChange={(val, validated) => generalInfoChange(val, validated)}
                            ignoreBorder={true}
                            editorStyle={{ minHeight: '25em', maxHeight: '28em' }}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
