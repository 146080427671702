import { makeStyles } from '@material-ui/core/styles';
import { emptyStyle } from 'fintech/components/style/common';
import { COLOR_THEME_FILL_01, COLOR_SECONDARY_SHADE_01 } from 'fintech/components/style/common';

const useStylesEmpty = makeStyles({
    BgDiv: {
        marginTop: '26px',
        paddingTop: '50px',
        height: '336px',
        marginBottom: '29px',
        paddingBottom: '50px',
        backgroundColor: 'transparent',
        backgroundSize: 'contain',
        width: '100%',
    },
    modalTitle: {
        borderBottom: ({ showBorder }) => (showBorder ? '1px solid #dee2e6' : null),
        fontSize: '24px',
        paddingTop: '24px',
        paddingBottom: '32px',
        textAlign: 'center',
    },
    dialogButtonContainerClass: {
        justifyContent: 'center !important',
        '& button': {
            width: 87,
            height: 48,
        },
    },
    detailsModalContent: {
        paddingLeft: 48,
        paddingRight: 48,
        fontSize: 14,
    },
    detailsModalContentLabel: {
        color: COLOR_SECONDARY_SHADE_01,
        marginTop: 16,
    },
    detailsModalEventDetail: {
        maxHeight: 200,
        overflow: 'auto',
        whiteSpace: 'pre-wrap',
    },
    preLoginEmptyDiv: {
        height: 176,
        display: 'flex',
        alignItems: 'center',
    },
    preLoginEmptyDivIcon: {
        '& > svg': {
            fill: COLOR_THEME_FILL_01,
        },
    },
    preLoginEmptyDivMessage: {
        marginLeft: 20,
        '& > p': {
            color: COLOR_THEME_FILL_01,
            marginBottom: '0px !important',
            marginTop: '0px !important',
        },
    },
    ...emptyStyle,
});

export const detailsModalStyle = makeStyles((theme) => ({
    paper: {
        width: '740px',
        maxWidth: '740px',
        height: '520px',
        [theme.breakpoints.down('sm')]: {
            width: '400px',
        },
    },
}));

export default useStylesEmpty;
