import { makeStyles } from '@material-ui/core/styles';
import { buttonStyle, COLOR_THEME_FILL_01, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    processGrid: {
        marginTop: '27px',
        padding: '32px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        backgroundColor: COLOR_THEME_FILL_01,
    },
    applyButton: {
        ...fontStyle,
        ...buttonStyle,
        width: '100%',
        maxWidth: '270px',
        height: '48px',
    },
});

export default useStyles;
