import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Switch, withStyles, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './index.style';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs({ cookieSwitch, setCookieSwitch }) {
    const { t } = useTranslation('policy');
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = useState(0);

    const tabList = [
        { id: 0, title: t('cookiePolicyTitle'), description: t('cookiePolicy') },
        {
            id: 1,
            title: t('imperativeCookies'),
            description: t('imperativeCookiesDescription'),
            switchText: t('alwaysActive'),
        },
        { id: 2, title: t('functionalCookies'), description: t('functionalCookiesDescription'), switch: true },
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const CustomSwitch = withStyles({
        switchBase: {
            color: '#98a3ac',
            '&$checked': {
                color: '#e6ebfa',
                '& + $track': {
                    opacity: 1,
                    backgroundColor: '#5b7adb',
                },
            },
        },
        checked: {
            opacity: 1,
        },
        track: {
            backgroundColor: '#ffffff',
            border: '1px solid #98a3ac',
            opacity: 1,
        },
    })(Switch);

    const handleSwitch = (event, checked) => {
        setCookieSwitch(checked);
    };

    return (
        <Box className={isMobile ? classes.tabContainerMobile : classes.tabContainerWeb}>
            <Tabs
                orientation={isMobile ? 'horizontal' : 'vertical'}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="cookie-manage-tabs"
                classes={{ root: classes.tabsRoot, indicator: classes.indicator }}
            >
                {tabList.map((item) => (
                    <Tab
                        key={`keyTab_${item.id}`}
                        label={item.title}
                        {...a11yProps(item.id)}
                        classes={{ root: classes.tabRoot, selected: classes.selectedTab, wrapper: classes.wrapperTab }}
                    />
                ))}
            </Tabs>

            <Box className={isMobile ? classes.tabPanelMobile : classes.tabPanelWeb}>
                {tabList.map((item) => (
                    <TabPanel value={value} index={item.id} key={`keyTabPanel_${item.id}`}>
                        <Box className={classes.tabPanelHeader}>
                            <h6>{item.title}</h6>
                            {item?.switchText && <h6 className={classes.switchText}>{item.switchText}</h6>}
                            {item?.switch && (
                                <CustomSwitch
                                    checked={cookieSwitch}
                                    onChange={(event, checked) => handleSwitch(event, checked)}
                                    color="primary"
                                    name="manageCookie"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            )}
                        </Box>
                        <Box
                            style={{ fontSize: '13px', textAlign: 'justify' }}
                            dangerouslySetInnerHTML={{
                                __html: item.description,
                            }}
                        />
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
}
