/* eslint-disable no-case-declarations */
import { cloneDeep, isEmpty } from 'lodash';
import { InvestorSearchActions } from 'fintech/store/actions/ActionTypes';
import { GRID_HEADER_STATE } from 'fintech/store/actions/entrepreneur';
import { defaultFilterCategories, defaultSearchBoxFilters } from 'fintech/constants/investorSearch';
import { filterObjectList } from 'fintech/utils/ArrayUtils';

const initialState = {
    global: {
        headers: null,
        totalNumber: null,
        filteredNumber: null,
        lastQueryType: null,
        persist: false,
    },
    advancedFilter: {
        isDisplayed: false,
        selectedCategory: 0,
        categories: defaultFilterCategories,
        data: null,
    },
    appliedFilters: {
        investorTypes: [],
        startupStages: [],
        investmentAreas: [],
        fintechSubVerticalInterests: [],
    },
    searchBox: {
        input: '',
        lastQueryParams: {},
        filters: defaultSearchBoxFilters,
    },
    grid: {
        rows: [], // Needs to be fetched from server
        isLoading: true,
        gridDataLoading: false,
        isError: false,
        pagination: {
            count: 0,
            pageSize: 15,
            defaultPage: 1,
            page: 1,
        },
    },
    form: {
        teamData: {
            data: [],
            isLoading: true,
            isError: false,
        },
        currentRepresentative: null,
        representativeForm: {
            investorId: null,
            userId: null,
            userName: null,
        },
    },
};

const investorSearch = (state = initialState, action) => {
    const { type } = action;
    const advancedFilter = state.advancedFilter;
    const data = cloneDeep(advancedFilter.data);

    switch (type) {
        // Global
        case InvestorSearchActions.SET_INVESTOR_GLOBAL_PERSIST_STATE:
            return {
                ...state,
                global: {
                    ...state.global,
                    persist: action.data,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_GLOBAL_TOTAL_ACTIVE_NUMBER:
            return {
                ...state,
                global: {
                    ...state.global,
                    totalNumber: action.data,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_GLOBAL_FILTERED_NUMBER:
            return {
                ...state,
                global: {
                    ...state.global,
                    filteredNumber: action.data,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_ADVANCEDFILTER_DATA:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    data: action.data,
                },
            };
        case InvestorSearchActions.TOGGLE_INVESTOR_ADVANCEDFILTER:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    isDisplayed: !state.advancedFilter.isDisplayed,
                },
            };
        case InvestorSearchActions.TOGGLE_INVESTOR_ADVANCEDFILTER_CHECKBOX:
            const { vertical, checkboxInputID } = action.data;
            const verticalKey = vertical;

            const selectedIndex = data[verticalKey].findIndex((e, index) => e.id === checkboxInputID);
            data[verticalKey][selectedIndex] = {
                ...data[verticalKey][selectedIndex],
                selected: !data[verticalKey][selectedIndex].selected,
            };

            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    data,
                },
            };
        case InvestorSearchActions.RESET_INVESTOR_ADVANCEDFILTER_CHECKBOX:
            for (const property in data) {
                data[property].forEach((element, index, arr) => {
                    arr[index] = {
                        ...element,
                        selected: false,
                    };
                });
            }
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    data,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_CATEGORY:
            return {
                ...state,
                advancedFilter: {
                    ...state.advancedFilter,
                    selectedCategory: action.data,
                },
            };
        case InvestorSearchActions.RESET_INVESTOR_ADVANCED_FILTER:
            return {
                ...state,
                advancedFilter: {
                    ...initialState.advancedFilter,
                    data: state.advancedFilter.data,
                },
            };
        // Applied Filters
        case InvestorSearchActions.SET_INVESTOR_APPLIED_FILTERS:
            const newFilters = !isEmpty(action.data) ? action.data : initialState.appliedFilters;
            return {
                ...state,
                appliedFilters: newFilters,
            };
        case InvestorSearchActions.REMOVE_INVESTOR_APPLIED_FILTERS:
            const appliedFilterCategory = action.data.categoryPrefix;
            const appliedFilterID = action.data.id;

            if (appliedFilterID === null) {
                return {
                    ...state,
                    appliedFilters: {
                        ...state.appliedFilters,
                        [appliedFilterCategory]: [],
                    },
                };
            } else {
                return {
                    ...state,
                    appliedFilters: {
                        ...state.appliedFilters,
                        [appliedFilterCategory]: state.appliedFilters[appliedFilterCategory].filter(
                            (e) => e.id !== appliedFilterID
                        ),
                    },
                };
            }
        case InvestorSearchActions.REMOVE_INVESTOR_APPLIED_FILTERS_BY_SUBCATEGORY:
            const _subCategoryFilter = action.data.subCategory === '' ? null : action.data.subCategory;

            return {
                ...state,
                appliedFilters: {
                    ...state.appliedFilters,
                    [action.data.categoryPrefix]: state.appliedFilters[action.data.categoryPrefix].filter(
                        (e) => e.parentData !== _subCategoryFilter
                    ),
                },
            };
        case InvestorSearchActions.RESET_INVESTOR_APPLIED_FILTERS: {
            return {
                ...state,
                appliedFilters: initialState.appliedFilters,
            };
        }
        // SearchBox
        case InvestorSearchActions.SET_INVESTOR_SEARCHBOX_LAST_QUERY:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    lastQueryParams: action.data,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_SEARCHBOX_INPUT:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    input: action.data,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_SEARCHBOX_FILTERSTATUS:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    filters: state.searchBox.filters.map((element) => {
                        return action.data.includes(element.label)
                            ? {
                                  ...element,
                                  status: true,
                              }
                            : {
                                  ...element,
                                  status: false,
                              };
                    }),
                },
            };
        case InvestorSearchActions.RESET_INVESTOR_SEARCHBOX:
            return {
                ...state,
                searchBox: initialState.searchBox,
            };
        // Grid
        case InvestorSearchActions.SET_INVESTOR_LAST_QUERY_TYPE:
            return {
                ...state,
                global: {
                    ...state.global,
                    lastQueryType: action.data,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_GRID_HEADERS:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    headers: action.data,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_GRID_DATA:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    rows: action.data,
                    isLoading: false,
                    gridDataLoading: false,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_GRID_LOADING_INITIAL:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    rows: [],
                    isLoading: true,
                    isError: false,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_GRID_LOADING:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    gridDataLoading: true,
                    isError: false,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_GRID_LOAD_ERROR:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    isLoading: false,
                    gridDataLoading: false,
                    isError: true,
                },
            };

        case InvestorSearchActions.TOGGLE_INVESTOR_GRID_STATE:
            let _headers = cloneDeep(state.grid.headers);
            _headers = _headers.map((el) =>
                el.type === 'sortLabel' && el.id !== action.data
                    ? {
                          ...el,
                          value: {
                              ...el.value,
                              status: GRID_HEADER_STATE.NONE,
                          },
                      }
                    : el
            );
            const selectedHeader = state.grid.headers.findIndex((e) => e.id === action.data);

            switch (_headers[selectedHeader].value.status) {
                case GRID_HEADER_STATE.NONE:
                    _headers[selectedHeader].value.status = GRID_HEADER_STATE.ASC;
                    break;
                case GRID_HEADER_STATE.ASC:
                    _headers[selectedHeader].value.status = GRID_HEADER_STATE.DESC;
                    break;
                case GRID_HEADER_STATE.DESC:
                    _headers[selectedHeader].value.status = GRID_HEADER_STATE.ASC;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                grid: {
                    ...state.grid,
                    headers: _headers,
                    pagination: initialState.grid.pagination,
                },
            };
        // Pagination
        case InvestorSearchActions.SET_INVESTOR_GRID_PAGINATION:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    pagination: {
                        ...state.grid.pagination,
                        ...action.data,
                    },
                },
            };
        case InvestorSearchActions.RESET_INVESTOR_GRID_PAGINATION:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    pagination: initialState.grid.pagination,
                },
            };

        // Admin related actions
        case InvestorSearchActions.RESET_INVESTOR_REPRESENTATIVE_FORM:
            return {
                ...state,
                form: initialState.form,
            };
        case InvestorSearchActions.GET_INVESTOR_GRID_TEAM_DATA:
            return {
                ...state,
                form: {
                    ...state.form,
                    teamData: {
                        ...state.form.teamData,
                        isLoading: true,
                    },
                },
            };
        case InvestorSearchActions.SET_INVESTOR_GRID_TEAM_DATA:
            const { teamData } = action;
            const filteredData = filterObjectList(teamData.data, 'selected', [true]);
            const selectedReps = filteredData?.map((el) => ({
                ...el,
                id: el.uuid,
            }));
            return {
                ...state,
                form: {
                    ...state.form,
                    teamData: {
                        ...state.form.teamData,
                        data: [
                            ...teamData.data?.map((el) => ({
                                ...el,
                                id: el.uuid,
                            })),
                        ],
                        isLoading: false,
                    },
                    currentRepresentative: selectedReps && !isEmpty(selectedReps) ? selectedReps[0] : null,
                },
            };
        case InvestorSearchActions.SET_INVESTOR_GRID_TEAM_DATA_LOAD_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    teamData: {
                        ...state.form.teamData,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        default:
            return state;
    }
};

export default investorSearch;
