import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import useStyles from './index.style';
import IconsSingleChevronDown from 'fintech/icons/IconsSingleChevronDown';
import IconsSingleChevronUp from 'fintech/icons/IconsSingleChevronUp';
import ApplyMenu from './apply-menu';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const ApplyContainer = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('preLogin');
    const ref = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuClick = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    return (
        <>
            <Button
                variant="contained"
                endIcon={
                    isMenuOpen ? (
                        <IconsSingleChevronUp></IconsSingleChevronUp>
                    ) : (
                        <IconsSingleChevronDown></IconsSingleChevronDown>
                    )
                }
                className={classes.applyButton}
                onClick={handleMenuClick}
                ref={ref}
            >
                {t('applyButton')}
            </Button>
            {isMenuOpen
                ? ReactDOM.createPortal(
                      <ApplyMenu
                          ref={ref}
                          handleOutsideClick={handleOutsideClick}
                          setIsMenuOpen={setIsMenuOpen}
                          isMentorApplicationActive={props.isMentorApplicationActive}
                      />,
                      document.querySelector('body')
                  )
                : null}
        </>
    );
};

export default ApplyContainer;
