import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_SECONDARY_BASE,
    COLOR_THEME_FILL_01,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    COLOR_SECONDARY_SHADE_01,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    modal: {
        zIndex: '10000',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        backgroundColor: COLOR_THEME_FILL_01,
        width: '740px',
        height: '520px',
        padding: '24px 48px  32px',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
            minHeight: '520px',
            height: 'auto',
        },
    },
    modalReject: {
        height: '752px',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    modalRejectInputContainer: {
        padding: 0,
        marginTop: '11px',
    },
    modalRejectInput: {
        [theme.breakpoints.up('md')]: {
            height: '188px',
            '& .MuiInputBase-formControl': {
                height: '100%',
            },
        },
    },
    modalHeading: {
        fontSize: '24px',
        fontWeight: '600',
        textAlign: 'center',
        color: COLOR_SECONDARY_BASE,
    },
    modalBody: {
        marginTop: '39px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '6px',
        },
    },
    modalInfo: {
        '& > *': {
            marginBottom: '32px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginBottom: '10px',
            },
        },
    },
    modalTitle: {
        fontWeight: '500',
        color: COLOR_SECONDARY_SHADE_01,
    },
    modalDescription: {
        fontWeight: '500',
        color: COLOR_SECONDARY_BASE,
    },
    modalButtons: {
        marginTop: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '14px',
        },
        '& button': {
            textTransform: 'none',
        },
        '& > :first-child > button': {
            backgroundColor: COLOR_PRIMARY_SHADE_02,
            '&:hover': {
                backgroundColor: COLOR_PRIMARY_SHADE_03,
            },
        },
    },
    borderedSubject: {
        border: '1px solid #d3dbdf',
        height: '175px',
        marginTop: '3px',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            paddingTop: '10px',
            paddingBottom: '10px',
        },
    },
}));

export default useStyles;
