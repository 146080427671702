import React from 'react';
import { connect } from 'react-redux';
import AnnouncementPage from 'fintech/pages/AnnouncementPage';
import EntrepreneurPage from 'fintech/pages/post-login/EntrepreneursPage';
import LanguagePanel from 'fintech/components/lang/LanguagePanel';
import ProfilePage from 'fintech/pages/post-login/ProfilePage';
import { Route } from 'react-router-dom';
import EventDetailPage from 'fintech/pages/post-login/EventDetailPage';
import AttempPage from 'fintech/pages/post-login/AttempPage';
import { Switch } from 'react-router';
import InvestorsPage from 'fintech/pages/post-login/InvestorsPage';
import MentorsPage from 'fintech/pages/post-login/MentorsPage';
import UsersPage from 'fintech/pages/post-login/UsersPage';
import SupportProgramsPage from 'fintech/pages/post-login/support/program/SupportProgramsPage';
import SupportProgramDetailPage from 'fintech/pages/post-login/support/program/SupportProgramDetailPage';
import ConsultantPage from 'fintech/pages/post-login/ConsultantPage';
import EstablishmentPage from 'fintech/pages/post-login/EstablishmentPage';
import MembershipManagement from 'fintech/pages/post-login/MembershipManagement';
import SettingsPage from 'fintech/pages/post-login/SettingsPage';
import ChatPage from 'fintech/pages/post-login/ChatPage';
import Reports from 'fintech/pages/post-login/Reports';
import SupportProgramApplicationPage from 'fintech/pages/post-login/support/program/SupportProgramApplicationPage';
import { AuthorizedRoute } from 'fintech/components/AuthorizedRoute';
import { ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE } from 'fintech/components/util/RoleConstants';

const PageContainer = ({ pageTitle }) => {
    return (
        <div>
            <div className={'mobileSecurePageContainerHeader mobile'}>
                <div className={'flex-1 poppin-bold-18px'}>{pageTitle}</div>
                <div className={'mobileSecureLanguagePanel'}>
                    <LanguagePanel />
                </div>
            </div>
            <div>
                <Switch>
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/attempt" children={<AttempPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/attempt/:id/" children={<AttempPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/entrepreneurs/attempt/:id/" children={<AttempPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/attempt/:id/:type" children={<AttempPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/establishment/:id/" children={<EstablishmentPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/investors/establishment/:id/" children={<EstablishmentPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/profile/:userId" children={<ProfilePage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/profile" children={<ProfilePage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/persons/profile/:userId" children={<ProfilePage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/mentors/profile/:userId" children={<ProfilePage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/consultants/profile/:userId" children={<ProfilePage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/events" children={<AnnouncementPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/entrepreneurs" children={<EntrepreneurPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/investors" children={<InvestorsPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/mentors" children={<MentorsPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/consultants" children={<ConsultantPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/persons" children={<UsersPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path={'/platform/events/:id'} children={<EventDetailPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path={'/platform/support-programs'} children={<SupportProgramsPage />} />
                    <Route
                        exact
                        path={'/platform/support-programs/program-detail/:id'}
                        // eslint-disable-next-line react/no-children-prop
                        children={<SupportProgramDetailPage />}
                    />
                    <AuthorizedRoute
                        unauthorizedRoles={[ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE]}
                        exact
                        path={'/platform/support-programs/application-form/:id'}
                        // eslint-disable-next-line react/no-children-prop
                        component={SupportProgramApplicationPage}
                    />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path={'/platform/membership-management'} children={<MembershipManagement />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path={'/platform/settings'} children={<SettingsPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path={'/platform/chat'} children={<ChatPage />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <Route exact path="/platform/reports" children={<Reports />} />
                    {/* eslint-disable-next-line react/no-children-prop */}
                </Switch>
            </div>
        </div>
    );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(PageContainer);
