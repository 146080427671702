import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserTechnologiesData, fetchUserVerticalsData } from 'fintech/store/actions/user';
import { useKeycloak } from '@react-keycloak/web';
import VerticalsUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/VerticalsUpdate';
import TechnologyUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/TechnologyUpdate';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import { DialogContent } from '@material-ui/core';

export default function KeywordsTab({ userUuid, changeCallback, formIsSubmitted }) {
    const { keycloak } = useKeycloak();

    const { data: fetchedVerticals, isLoading: verticalsLoading, isError: verticalsError } = useSelector(
        (state) => state.user.verticalData
    );
    const [verticalData, setVerticalData] = useState([]);

    const { data: fetchedTechnologies, isLoading: techsLoading, isError: techsError } = useSelector(
        (state) => state.user.technologyData
    );
    const [technologyData, setTechnologyData] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!fetchedVerticals.verticals || !fetchedTechnologies.technologies) {
            dispatch(fetchUserVerticalsData(keycloak, userUuid));
            dispatch(fetchUserTechnologiesData(keycloak, userUuid));
        }
    }, []);

    useEffect(() => {
        setVerticalData(_.clone(fetchedVerticals.verticals, true));
        setTechnologyData(_.clone(fetchedTechnologies.technologies, true));
    }, [fetchedVerticals, fetchedTechnologies]);

    const changeVerticals = (verticals, valid) => {
        setVerticalData(verticals);
        const updateData = {
            verticals: filterObjectList(verticalData, 'selected', [true]),
            technologies: filterObjectList(technologyData, 'selected', [true]),
            verticalsValid: valid,
        };
        changeCallback(updateData);
    };
    const changeTechnologies = (technologies, valid) => {
        setTechnologyData(technologies);
        const updateData = {
            verticals: filterObjectList(verticalData, 'selected', [true]),
            technologies: filterObjectList(technologyData, 'selected', [true]),
            technologiesValid: valid,
        };
        changeCallback(updateData);
    };
    return (
        <div className={''}>
            <Grid container direction={'row'}>
                <DialogContent className={'full-width padding-top-8'}>
                    <VerticalsUpdate
                        verticals={verticalData}
                        isLoading={verticalsLoading}
                        isError={verticalsError}
                        changeCallback={(data, valid) => changeVerticals(data, valid)}
                        formIsSubmitted={formIsSubmitted}
                    />
                </DialogContent>
                <DialogContent className={'full-width'}>
                    <TechnologyUpdate
                        technologies={technologyData}
                        isLoading={techsLoading}
                        isError={techsError}
                        changeCallback={(data, valid) => changeTechnologies(data, valid)}
                        formIsSubmitted={formIsSubmitted}
                    />
                </DialogContent>
            </Grid>
        </div>
    );
}
