import React from 'react';
import { Grid } from '@material-ui/core';

const IntroDuoGrid = (props) => {
    const { items } = props;

    const renderedJSX = items.map((item, idx) => (
        <Grid key={idx} item md={12} container direction="row">
            {item}
        </Grid>
    ));

    return <>{renderedJSX}</>;
};

export default IntroDuoGrid;
