import { makeStyles } from '@material-ui/core';
import { COLOR_THEME_FILL_01, COLOR_SECONDARY_BASE } from 'fintech/components/style/common';
import particle1 from 'fintech/static/images/placeholder-1-right.svg';
import particle2 from 'fintech/static/images/placeholder-2-left.svg';

const useStyles = makeStyles((theme) => ({
    timesheetSection: {
        background: `${COLOR_THEME_FILL_01} `,
        position: 'relative',
    },
    webContainer: {
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobileContainer: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    one: {
        background: `url(${particle1}) no-repeat`,
        backgroundPosition: '-227px -169px',
        width: '59px',
        height: '59px',
        left: '8%',
        top: '14%',
        position: 'absolute',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    two: {
        background: `url(${particle1}) no-repeat`,
        backgroundPosition: '-107px -79px',
        width: '98px',
        height: '25px',
        top: '15%',
        right: '10%',
        position: 'absolute',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    three: {
        background: `url(${particle2}) no-repeat`,
        backgroundPosition: '-47px -167px',
        width: '135px',
        height: '80px',
        bottom: '12%',
        right: '10%',
        position: 'absolute',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    timesheetContainer: {
        padding: '92px 120px 0px 120px',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('md')]: {
            padding: '40px 22px 65px',
            margin: '0',
        },
    },
    title: {
        fontSize: '36px',
        fontWeight: '500',
        color: COLOR_SECONDARY_BASE,
        marginTop: 0,
        marginBottom: '82px',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: 25,
            marginBottom: '50px',
        },
    },
    xArrowLabels: {
        width: '20px',
    },
}));

export default useStyles;
