import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import useWindowSize from 'fintech/hooks/useWindowSize';
import * as EventCardUtils from 'fintech/components/dashboard/event/eventcardgrid/EventCardUtils';
import { mobileSizeForCarousel } from 'fintech/components/dashboard/event/eventcardgrid/EventCardUtils';
import ProgramCard from 'fintech/components/support/program/card/ProgramCard';
import PropTypes from 'prop-types';
import InteractionCard from 'fintech/components/ui/card/InteractionCard';
import { Add } from '@material-ui/icons';
import { COLOR_PRIMARY_BASE } from 'fintech/components/style/common';
import SupportProgramEditModal from 'fintech/components/support/program/form/SupportProgramEditModal';

/**
 * Program Card Grid which uses the carousel for showing the event cards
 */
const ProgramCardGrid = (props) => {
    const { isAdmin, showAdd, programs = [] } = props;
    const { t } = useTranslation('supportProgram');
    const [visibleSlides, setVisibleSlides] = useState(4);
    const windowSize = useWindowSize();
    const [showNextButton, setShowNextButton] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [recordId, setRecordId] = useState(null);

    const clickEditHandler = (id) => {
        setRecordId(id ? id : null);
        setOpenModal(true);
    };

    const submitModalHandler = () => {
        setRecordId(null);
    };

    const cancelModalHandler = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        setShowNextButton(EventCardUtils.isNextButtonVisible(windowSize, programs.length));
    }, [windowSize, programs]);

    let programSlides = [];

    const newCardJSX = (
        <InteractionCard
            onClick={clickEditHandler}
            key={-1}
            icon={<Add style={{ width: '81px', height: '81px', color: COLOR_PRIMARY_BASE }} />}
            label={t('list.card.createNewProgram')}
        />
    );

    const programCards = programs?.map((event) => (
        <ProgramCard onClickEdit={clickEditHandler} showEdit={isAdmin} item key={event.id} {...event} />
    ));

    const programJSX = showAdd ? [newCardJSX, ...programCards] : programCards;

    programSlides = programJSX.map((programJSX, index) => {
        const style = windowSize.width < mobileSizeForCarousel ? null : { maxWidth: 285 };
        return (
            <Slide style={style} key={index} index={index}>
                {programJSX}
            </Slide>
        );
    });

    useEffect(() => {
        setVisibleSlides(EventCardUtils.getNumberOfVisibleSlides(windowSize));
        setShowNextButton(EventCardUtils.isNextButtonVisible(windowSize, programSlides.length));
    }, [windowSize]);

    const getCarousel = () => {
        return (
            <>
                <CarouselProvider
                    className={'eventCardCarousel'}
                    style={{ marginTop: 26 }}
                    naturalSlideWidth={270}
                    naturalSlideHeight={368}
                    totalSlides={programSlides.length}
                    visibleSlides={visibleSlides}
                >
                    <div className={'sliderContainer'}>
                        <Slider style={{ height: 400 }}>{programSlides}</Slider>
                        {windowSize.width > mobileSizeForCarousel ? (
                            <>
                                <ButtonBack className={'sliderButton sliderButtonBack'}>{'<'}</ButtonBack>
                                {showNextButton ? (
                                    <ButtonNext className={'sliderButton sliderButtonNext'}>{'>'}</ButtonNext>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                </CarouselProvider>
                {isAdmin && (
                    <SupportProgramEditModal
                        recordId={recordId}
                        open={openModal}
                        onSubmit={submitModalHandler}
                        onCancel={cancelModalHandler}
                    />
                )}
            </>
        );
    };

    return <>{getCarousel()}</>;
};

ProgramCardGrid.propTypes = {
    programs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            lastApplicationDate: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string,
            startDate: PropTypes.string.isRequired,
            endDate: PropTypes.string.isRequired,
            image: PropTypes.string,
        })
    ),
};

export default ProgramCardGrid;
