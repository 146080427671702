import React from 'react';
import { Grid } from '@material-ui/core';

const IntroGridItem = (props) => {
    const { title, content, half, titleProps, contentProps, htmlBind, wrapperClassName } = props;

    const size = half ? { xs: 6, md: 12 } : { xs: 12 };
    return (
        <Grid item {...size} className={wrapperClassName}>
            <Grid container direction="row">
                <Grid xs={12} md={6} lg={3} item {...titleProps}>
                    {title}
                </Grid>
                <Grid
                    xs={12}
                    md={6}
                    lg={9}
                    item
                    {...contentProps}
                    style={{ textAlign: 'start' }}
                    className={'long-word-break'}
                >
                    {htmlBind ? <div dangerouslySetInnerHTML={{ __html: content.props.children }}></div> : content}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IntroGridItem;
