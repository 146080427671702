import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { COLOR_PRIMARY_BASE } from 'fintech/components/style/common';
import Button from '@material-ui/core/Button';
import * as FileServerService from 'fintech/api/fileService';
import { getPublicFileUrl } from 'fintech/api/fileService';
import { useKeycloak } from '@react-keycloak/web';
import Resizer from 'react-image-file-resizer';
import useStyles from 'fintech/components/avataruploader/AvatarUploader.style';
import { withTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { IMAGE_MIMES_START } from 'fintech/components/util/FileConstants';

const AVATAR_SIZE_LIMIT = 10000000;

const AvatarUploader = (props) => {
    const classes = useStyles();
    const { t, serviceUri, pictureUuid, placeholder, entityUuid, disabled, isPreLogin, hideBorder, fetchOnUpload } = {
        ...props,
    };
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadOverlayHovered, setUploadOverlayHovered] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const userServiceUri = serviceUri;
    const profilePictureFile = pictureUuid;

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                400,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                'file'
            );
        });

    const { keycloak } = useKeycloak();

    const changeHandler = async (event) => {
        const image = event.target.files[0];

        if (!image) {
            onUploadOverlayLeave();
            return;
        }

        if (!image.type.startsWith(IMAGE_MIMES_START)) {
            onUploadOverlayLeave();
            enqueueSnackbar(t('avatar.imageFormatError'), { variant: 'error', autoHideDuration: 5000 });
            return;
        } else if (image.size > AVATAR_SIZE_LIMIT) {
            onUploadOverlayLeave();
            enqueueSnackbar(t('avatar.imageSizeError'), { variant: 'error', autoHideDuration: 5000 });
            return;
        }

        const resizedImage = await resizeFile(image);

        const formData = new FormData();
        formData.append('uploaded_file', resizedImage);

        try {
            await FileServerService.uploadAvatarFile(keycloak, formData, entityUuid, image.name, userServiceUri);
            fetchOnUpload && fetchOnUpload(); //usage example(user avatar on the left menu wasn't uploading after the image change, dispatch function changes the image)
            setSelectedImage(resizedImage);
            onUploadOverlayLeave();
        } catch (e) {
            onUploadOverlayLeave();
            enqueueSnackbar(t('avatar.uploadError'), { variant: 'error', autoHideDuration: 5000 });
        }
    };

    const onUploadOverlayLeave = () => {
        setUploadOverlayHovered(false);
    };

    const onUploadOverlayHover = () => {
        setUploadOverlayHovered(true);
    };

    const getProfileImage = () => {
        if (selectedImage) {
            return URL.createObjectURL(selectedImage);
        } else {
            return profilePictureFile ? getPublicFileUrl(profilePictureFile) : null;
        }
    };

    let profileImage = getProfileImage();
    let isPlaceHolder = false;
    if (!profileImage) {
        profileImage = placeholder;
        isPlaceHolder = true;
    }

    let avatarClassName = classes.avatarIconWithoutBorder;
    if (!hideBorder) {
        if (isPlaceHolder) {
            avatarClassName = classes.avatarIconPlaceholder;
        } else {
            avatarClassName = classes.avatarIcon;
        }
    }
    if (isPreLogin) {
        avatarClassName = classes.preLoginPlaceholderAvatar;
    }

    let placeholderClassName = hideBorder ? classes.avatarIconWithoutBorder : classes.avatarIconPlaceholder;
    if (isPreLogin) {
        placeholderClassName = classes.preLoginPlaceholderAvatar;
    }

    const rootClass = !isPreLogin ? classes.root : '';

    return (
        <div className={rootClass}>
            <label>
                <input
                    accept="*"
                    className={classes.input}
                    name="uploaded_file"
                    type="file"
                    disabled={disabled}
                    onChange={changeHandler}
                />
                <Button className={classes.iconButton} disabled={disabled && !isPreLogin} component="span">
                    <div className={classes.container}>
                        <Avatar src={profileImage} className={avatarClassName}>
                            <Avatar src={placeholder} className={placeholderClassName}>
                                {placeholder}
                            </Avatar>{' '}
                            {/* This components will be rendered if there is an error at the file server*/}
                        </Avatar>
                        {disabled ? null : (
                            <div
                                className={classes.overlay}
                                onClick={() => onUploadOverlayHover()}
                                onMouseEnter={() => onUploadOverlayHover()}
                                onMouseLeave={() => onUploadOverlayLeave()}
                                style={uploadOverlayHovered ? { opacity: 1 } : { opacity: 0 }}
                            >
                                <Avatar className={classes.hovered}>
                                    <i
                                        className="fas fa-cloud-upload-alt"
                                        style={{ color: COLOR_PRIMARY_BASE, marginBottom: 2, fontSize: 15 }}
                                    />
                                    <span className={classes.hoveredText}>
                                        {profilePictureFile || selectedImage ? t('avatar.change') : t('avatar.upload')}
                                    </span>
                                </Avatar>
                            </div>
                        )}
                    </div>
                </Button>
            </label>
        </div>
    );
};

export default withTranslation('avatar')(AvatarUploader);
