import * as React from 'react';

function SvgIconsSingleCloseBig(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
            <defs>
                <filter id="vrmigrqrea" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.384314 0 0 0 0 0.247059 0 0 0 0 0.247059 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g
                            filter="url(#vrmigrqrea)"
                            transform="translate(-696 -545) translate(370 505) translate(319 33)"
                        >
                            <g>
                                <path
                                    fill="#3F5162"
                                    fillRule="nonzero"
                                    d="M25.691 36L25.691 25.691 36 25.691 36 22.309 25.691 22.309 25.691 12 22.309 12 22.309 22.309 12 22.309 12 25.691 22.309 25.691 22.309 36z"
                                    transform="translate(7 7) rotate(45 24 24)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleCloseBig;
