import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import VerticalsUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/VerticalsUpdate';
import InvestmentAreas from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/TechnologyUpdate';
import { DialogContent } from '@material-ui/core';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import { useTranslation } from 'react-i18next';
import {
    fetchInvestorVerticals,
    fetchInvestorInvestmentAreas,
    fetchInvestorStages,
} from 'fintech/store/actions/establishment';
import { useKeycloak } from '@react-keycloak/web';
import { isEmpty } from 'lodash';

const KeywordsUpdateActionModal = ({
    changeCallback,
    formIsSubmitted,
    fetchedVerticals,
    fetchedInvestmentAreas,
    fetchedStages,
    verticalsLoading,
    verticalsError,
    areasLoading,
    areasError,
    stagesLoading,
    stagesError,
}) => {
    const { uuid } = useSelector((state) => state.establishment.intro.data);

    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmpty(fetchedVerticals)) dispatch(fetchInvestorVerticals(keycloak, uuid));
        if (isEmpty(fetchedInvestmentAreas)) dispatch(fetchInvestorInvestmentAreas(keycloak, uuid));
        if (isEmpty(fetchedStages)) dispatch(fetchInvestorStages(keycloak, uuid));
    }, [uuid]);

    const { t } = useTranslation();

    const [keywordsData, setKeywordsData] = useState({
        verticals: [],
        investmentAreas: [],
        stageData: [],
    });

    useEffect(() => {
        setKeywordsData({
            verticals: fetchedVerticals.verticals,
            investmentAreas: fetchedInvestmentAreas.investmentAreas,
            stageData: fetchedStages.stages,
        });
    }, [fetchedVerticals, fetchedInvestmentAreas, fetchedStages]);

    const changeVerticals = (verticals, valid) => {
        setKeywordsData((prevState) => ({
            ...prevState,
            verticals: verticals,
        }));
        const updateData = {
            verticals: filterObjectList(keywordsData.verticals, 'selected', [true]),
            investmentAreas: filterObjectList(keywordsData.investmentAreas, 'selected', [true]),
            stageData: filterObjectList(keywordsData.stageData, 'selected', [true]),
            verticalsValid: valid,
        };
        changeCallback(updateData);
    };

    const changeInvestmentAreas = (investmentAreas, valid) => {
        setKeywordsData((prevState) => ({
            ...prevState,
            investmentAreas: investmentAreas,
        }));
        const updateData = {
            verticals: filterObjectList(keywordsData.verticals, 'selected', [true]),
            investmentAreas: filterObjectList(keywordsData.investmentAreas, 'selected', [true]),
            stageData: filterObjectList(keywordsData.stageData, 'selected', [true]),
            investmentsValid: valid,
        };
        changeCallback(updateData);
    };

    const changeStage = (stages, valid) => {
        setKeywordsData((prevState) => ({
            ...prevState,
            stageData: stages,
        }));

        const updateData = {
            verticals: filterObjectList(keywordsData.verticals, 'selected', [true]),
            investmentAreas: filterObjectList(keywordsData.investmentAreas, 'selected', [true]),
            stageData: filterObjectList(keywordsData.stageData, 'selected', [true]),
            stagesValid: valid,
        };
        changeCallback(updateData, valid);
    };

    useEffect(() => {
        setKeywordsData({
            verticals: fetchedVerticals,
            investmentAreas: fetchedInvestmentAreas,
            stageData: fetchedStages,
        });
    }, [fetchedVerticals, fetchedInvestmentAreas, fetchedStages]);

    return (
        <div className={''}>
            <Grid container direction={'row'}>
                <DialogContent className={'full-width padding-top-8'}>
                    <VerticalsUpdate
                        verticals={keywordsData.verticals}
                        isLoading={verticalsLoading}
                        isError={verticalsError}
                        changeCallback={(data, valid) => changeVerticals(data, valid)}
                        title={t('profile.establishments.interestedFintechVerticals')}
                        formIsSubmitted={formIsSubmitted}
                        validateOnBlur={true}
                    />
                </DialogContent>
                <DialogContent className={'full-width'}>
                    <InvestmentAreas
                        technologies={keywordsData.investmentAreas}
                        isLoading={areasLoading}
                        isError={areasError}
                        changeCallback={(data, valid) => changeInvestmentAreas(data, valid)}
                        label={t('profile.establishments.investmentAreas')}
                        formIsSubmitted={formIsSubmitted}
                        validateOnBlur={true}
                    />
                </DialogContent>
                <DialogContent className={'full-width'}>
                    <InvestmentAreas
                        technologies={keywordsData.stageData}
                        isLoading={stagesLoading}
                        isError={stagesError}
                        changeCallback={(data, valid) => changeStage(data, valid)}
                        label={t('profile.establishments.startupStagePreference')}
                        formIsSubmitted={formIsSubmitted}
                        validateOnBlur={true}
                    />
                </DialogContent>
            </Grid>
        </div>
    );
};

export default KeywordsUpdateActionModal;
