import { makeStyles } from '@material-ui/core';
import { cardGridStyle, COLOR_PRIMARY_SHADE_02, COLOR_ROW_HOVERED } from 'fintech/components/style/common';

const useStyles = makeStyles({
    UserVentureCard: {
        ...cardGridStyle,
        margin: '25px 0 0',
        padding: '20px',
    },
    UserVentureContainer: {
        minHeight: '96px',
        marginBottom: '8px',
    },
    UserVentureWrapper: {
        paddingTop: '10px',
        border: `solid 1px ${COLOR_PRIMARY_SHADE_02}`,
        borderRadius: '8px',
        minHeight: '96px',
        '&:hover': {
            backgroundColor: COLOR_ROW_HOVERED,
        },
    },
    UserVenture: {
        padding: '0 16px',
    },
});

export default useStyles;
