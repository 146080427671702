import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { CardMedia } from '@material-ui/core';
import { useHistory } from 'react-router';
import useStyles from 'fintech/components/dashboard/event/eventcard/index.style';
import ProgramCardContent from 'fintech/components/support/program/card/ProgramCardContent';
import defaultImage from 'fintech/static/images/destek-programlari.jpg';
import { SUPPORT_PROGRAM_DETAIL } from 'fintech/routes';

/**
 * Program Card Component which have information about the event that will be organized
 */
const ProgramCard = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('supportProgram');
    const { image = defaultImage, onClickEdit, ...cardContent } = props;
    const history = useHistory();

    return (
        <Card className={classes.root}>
            <CardMedia className={classes.media} image={image} />
            <ProgramCardContent
                onClickEdit={() => {
                    onClickEdit(cardContent.id);
                }}
                {...cardContent}
            />
            <CardActions>
                <Button
                    size="small"
                    className={classes.cardButton}
                    onClick={() => history.push(`${SUPPORT_PROGRAM_DETAIL}/${cardContent.id}`)}
                >
                    {t('list.card.viewDetails')}
                </Button>
            </CardActions>
        </Card>
    );
};

export default ProgramCard;
