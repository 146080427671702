import React, { useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    useMediaQuery,
    FormControl,
    FormHelperText,
    Popover,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { loadingSpinnerWithTextGeneralTextStyle, mobileMediaQuery } from 'fintech/components/style/common';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'fintech/components/support/program/form/SupportProgramApplication/index.style';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import SingleSelect from 'fintech/components/ui/form/select/SingleSelect';
import CfoFormLabel from 'fintech/components/applications/form/common/CfoFormLabel';
import { ArrowBack } from '@material-ui/icons';
import { SupportProgramApplicationActions } from 'fintech/store/actions/ActionTypes';
import {
    CATEGORY_STRUCTURE_INFO,
    checkboxAction,
    generateRepInfoData,
    generateStructuralInfoAnswers,
    isChecked,
} from 'fintech/components/support/program/utils/SupportProgramApplicationUtils';
import { isEmpty } from 'lodash';
import { getRequest } from 'fintech/api/api';
import { APIS } from 'fintech/constants';
import CfoSingleCheckbox from 'fintech/components/ui/form/checkbox/CfoSingleCheckbox';
import * as FintechBeService from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import { setActiveRequest } from 'fintech/store/actions/common';
import { findObject } from 'fintech/utils/ArrayUtils';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';
import { IconsSingleTooltip } from 'fintech/icons';

const StructuralInformation = ({ pageFunctions: { prevPage, nextPage }, formId }) => {
    const { t } = useTranslation('supportProgramApplication');
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const matches = useMediaQuery(mobileMediaQuery);
    const dispatch = useDispatch();

    const [sending, setSending] = useState(false);

    const { form, hasActiveRequest } = useSelector((state) => ({
        ...state.supportProgramApplication,
        ...state.common,
    }));
    const questions = form.questionFormatList.slice(27, 39);

    const [startupTypes, setStartupTypes] = useState(questions[6]?.startupTypes ? questions[6].startupTypes : []);
    const [placeholderTextForCompanyType, setPlaceholderTextForCompanyType] = useState(null);
    const [validIds, setValidIds] = useState([]);
    const [flush, setFlush] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        return false;
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (isEmpty(startupTypes)) {
            getRequest(APIS.SUPPORT_PROGRAM.getSourceTypes('STARTUP_TYPE')).then(({ data }) => {
                const placeholderId = findObject(data, 'selected', true)?.id;
                const placeholderText = findObject(data, 'selected', true)?.data;
                const filteredData = data.filter((d) => d.id !== placeholderId);
                setStartupTypes(filteredData);
                setPlaceholderTextForCompanyType(placeholderText);
                setValidIds(data.filter((el) => el.id !== placeholderId)?.map((val) => val.id));
                dispatch({
                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_TYPES,
                    data: {
                        types: data,
                    },
                });
            });
        } else {
            const placeholderId = findObject(startupTypes, 'selected', true)?.id;
            const placeholderText = findObject(startupTypes, 'selected', true)?.data;
            setPlaceholderTextForCompanyType(placeholderText);
            setValidIds(startupTypes.filter((el) => el.id !== placeholderId)?.map((val) => val.id));
        }

        setFlush(true);
    }, []);

    useEffect(() => {
        if (form.questionFormatList && form.questionFormatList[33] && !form.questionFormatList[33].freeTextAnswerList) {
            dispatch({
                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_INIT,
            });
        }
    }, [form]);

    const onCheckboxChange = (event, type, id, question) => {
        dispatch(checkboxAction(event, type, id, question));
    };

    const getCorrespondingInput = (question) => {
        switch (question.questionNumber) {
            case 28:
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q28,
                                        data: { value, validated },
                                    });
                                }}
                                required={question.required === 'REQUIRED'}
                                rowCount={5}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 29:
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q29,
                                        data: { value, validated },
                                    });
                                }}
                                rowCount={5}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 30:
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q30,
                                        data: { value, validated },
                                    });
                                }}
                                rowCount={5}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 31:
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q31,
                                        data: { value, validated },
                                    });
                                }}
                                rowCount={5}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 32: {
                const { answerOptionList } = question.answerDefinitionList[0];
                const error = sending && isEmpty(question.selectionAnswerList?.[0]?.selectedAnswerOptionList);
                return (
                    <>
                        <FormControl error={error}>
                            <Grid container className={classes.checkboxWrapper}>
                                {answerOptionList.map(({ name, id }) => (
                                    <Grid key={id} xs={6} item>
                                        <CfoSingleCheckbox
                                            returnEvent
                                            checked={isChecked(question, id)}
                                            onChange={(event) =>
                                                onCheckboxChange(
                                                    event,
                                                    SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q32,
                                                    id,
                                                    question
                                                )
                                            }
                                            label={name}
                                        />
                                    </Grid>
                                ))}
                                {error && (
                                    <Grid xs={12} item>
                                        <FormHelperText className={classes.checkboxHelperWrapper} error>
                                            {t('validation.selectionNotEmpty')}
                                        </FormHelperText>
                                    </Grid>
                                )}
                            </Grid>
                        </FormControl>
                    </>
                );
            }
            case 33:
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q33,
                                        data: { value, validated },
                                    });
                                }}
                                rowCount={5}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 34: {
                const { answerOptionList } = question.answerDefinitionList[0];

                const showRelatedAnswers =
                    question?.answerDefinitionList[1]?.relatedAnswerOptionId ===
                    question?.selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id;

                const val = question?.selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id;
                let selectedTypeObject = null;
                if (question?.sourceSelectAnswerList?.[0]?.selectedId) {
                    selectedTypeObject = startupTypes.find(
                        (s) => s.id === question.sourceSelectAnswerList[0].selectedId
                    );
                } else {
                    selectedTypeObject = startupTypes && findObject(startupTypes, 'selected', true);
                }
                const selectedType = selectedTypeObject && selectedTypeObject.id;

                const radioError = sending && !val;
                return (
                    <>
                        <FormControl error={radioError}>
                            <RadioGroup
                                className={classes.radioWrapper}
                                row
                                value={val ? `${val}` : ''}
                                onChange={({ target: { value } }) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P1,
                                        data: { value: +value },
                                    });
                                }}
                            >
                                {answerOptionList.map((option) => (
                                    <FormControlLabel
                                        key={option.id}
                                        value={`${option.id}`}
                                        control={<Radio classes={{ colorPrimary: classes.radio }} color="primary" />}
                                        label={option.name}
                                    />
                                ))}
                            </RadioGroup>
                            {radioError && (
                                <FormHelperText className={classes.formHelperWrapper} error>
                                    {t('validation.selectionNotEmpty')}
                                </FormHelperText>
                            )}
                        </FormControl>
                        {showRelatedAnswers && (
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                    <InputTextArea
                                        type="text"
                                        placeholder={t('formLabel.companyTradeName')}
                                        onChange={(value, validated) => {
                                            dispatch({
                                                type:
                                                    SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P2,
                                                data: { value: value.toLocaleUpperCase('TR'), validated, index: 0 },
                                            });
                                        }}
                                        required={question.required === 'REQUIRED'}
                                        count={question.answerDefinitionList[1].textLimit}
                                        containerClass={classes.inputTextAreaWrapper}
                                        validationText={t('validation.notEmpty')}
                                        validateOnBlur
                                        validatedAfterFormSubmit={!sending}
                                        initText={
                                            question?.freeTextAnswerList?.[0]?.value
                                                ? question.freeTextAnswerList[0].value
                                                : ''
                                        }
                                        capitalize
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconsSingleTooltip
                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                        aria-haspopup="true"
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                        style={{
                                            marginTop: '21px',
                                        }}
                                    />
                                    <Popover
                                        id="mouse-over-popover"
                                        style={{
                                            pointerEvents: 'none',
                                        }}
                                        open={open}
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        onClose={handlePopoverClose}
                                        disableRestoreFocus
                                    >
                                        <Typography
                                            style={{
                                                padding: '4px',
                                            }}
                                        >
                                            {t('tradeNameTooltip')}
                                        </Typography>
                                    </Popover>
                                </Grid>
                                <Grid item xs={5}>
                                    <SingleSelect
                                        classes={{ root: classes.singleSelectLeftWrapper }}
                                        options={startupTypes}
                                        onChange={(value, validated) => {
                                            dispatch({
                                                type:
                                                    SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P3,
                                                data: { value, validated, index: 1 },
                                            });
                                        }}
                                        validateOnBlur
                                        initValue={selectedTypeObject}
                                        helperText={t('validation.selectionNotEmpty')}
                                        validatedAfterFormSubmit={!sending}
                                        placeholder={placeholderTextForCompanyType}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {selectedType === 2 ? (
                                        <InputTextArea
                                            key="vkn"
                                            type="vkn"
                                            onChange={(value, validated) => {
                                                dispatch({
                                                    type:
                                                        SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P2,
                                                    data: { value, validated, index: 1 },
                                                });
                                            }}
                                            required={question.required === 'REQUIRED'}
                                            containerClass={classes.inputTextAreaWrapper}
                                            validationText={
                                                question?.freeTextAnswerList?.[1]?.value
                                                    ? t('validation.vkn')
                                                    : t('validation.notEmpty')
                                            }
                                            validateOnBlur
                                            validatedAfterFormSubmit={!sending}
                                            initText={
                                                question?.freeTextAnswerList?.[1]?.value
                                                    ? question.freeTextAnswerList[1].value
                                                    : ''
                                            }
                                            placeholder={t('formLabel.taxIdNumber')}
                                            maxCharactersText={t('validation.vknMax')}
                                            count={10}
                                            hideCountIndicator
                                            flush={flush}
                                            trackForFlush={selectedType}
                                        />
                                    ) : (
                                        <InputTextArea
                                            key="tckn"
                                            type="tckn"
                                            onChange={(value, validated) => {
                                                dispatch({
                                                    type:
                                                        SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P2,
                                                    data: { value, validated, index: 1 },
                                                });
                                            }}
                                            disabled={!validIds.includes(selectedType)}
                                            required={question.required === 'REQUIRED'}
                                            containerClass={classes.inputTextAreaWrapper}
                                            validationText={
                                                question?.freeTextAnswerList?.[1]?.value
                                                    ? t('validation.tckn')
                                                    : t('validation.notEmpty')
                                            }
                                            validateOnBlur
                                            validatedAfterFormSubmit={!sending}
                                            initText={
                                                question?.freeTextAnswerList?.[1]?.value
                                                    ? question.freeTextAnswerList[1].value
                                                    : ''
                                            }
                                            placeholder={t('formLabel.idNumber')}
                                            maxCharactersText={t('validation.tcknMax')}
                                            count={11}
                                            hideCountIndicator
                                            flush={flush}
                                            trackForFlush={selectedType}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={11}>
                                    <InputTextArea
                                        type="mail"
                                        onChange={(value, validated) => {
                                            dispatch({
                                                type:
                                                    SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P2,
                                                data: { value, validated, index: 2 },
                                            });
                                        }}
                                        placeholder={t('form.companyEmail')}
                                        required={question.required === 'REQUIRED'}
                                        count={question.answerDefinitionList[4].textLimit}
                                        containerClass={classes.inputTextAreaWrapper}
                                        validationText={t('validation.email')}
                                        validateOnBlur
                                        validatedAfterFormSubmit={!sending}
                                        initText={
                                            question?.freeTextAnswerList?.[2]?.value
                                                ? question.freeTextAnswerList[2].value
                                                : ''
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </>
                );
            }
            case 35: {
                const { answerOptionList } = question.answerDefinitionList[0];

                const val = question?.selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id;
                const error = sending && question.required === 'REQUIRED' && !val;
                return (
                    <FormControl error={error}>
                        <RadioGroup
                            className={classes.radioWrapper}
                            row
                            value={val ? `${val}` : ''}
                            onChange={({ target: { value } }) => {
                                dispatch({
                                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q35,
                                    data: { value: +value },
                                });
                            }}
                        >
                            {answerOptionList.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    value={`${option.id}`}
                                    control={<Radio classes={{ colorPrimary: classes.radio }} color="primary" />}
                                    label={option.name}
                                />
                            ))}
                        </RadioGroup>
                        {error && (
                            <FormHelperText className={classes.formHelperWrapper} error>
                                {t('validation.selectionNotEmpty')}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }
            case 36:
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q36,
                                        data: { value, validated },
                                    });
                                }}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                hideCountIndicator
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 37:
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q37,
                                        data: { value, validated },
                                    });
                                }}
                                rowCount={5}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 38:
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q38,
                                        data: { value, validated },
                                    });
                                }}
                                rowCount={5}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            case 39:
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <InputTextArea
                                type="text"
                                placeholder={t('form.pleaseEnter')}
                                onChange={(value, validated) => {
                                    dispatch({
                                        type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q39,
                                        data: { value, validated },
                                    });
                                }}
                                rowCount={5}
                                required={question.required === 'REQUIRED'}
                                count={question.answerDefinitionList[0].textLimit ?? 1000}
                                containerClass={classes.inputTextAreaWrapper}
                                validationText={t('validation.notEmpty')}
                                validateOnBlur
                                validatedAfterFormSubmit={!sending}
                                initText={
                                    question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''
                                }
                            />
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    };

    const isValid = () =>
        // Validate Q28-29-31, Q33 & Q36-Q39 FREE_TEXT
        questions[0].freeTextAnswerList &&
        questions[0].freeTextAnswerList[0]?.validated &&
        questions[1].freeTextAnswerList &&
        questions[1].freeTextAnswerList[0]?.validated &&
        // questions[2].freeTextAnswerList &&
        // questions[2].freeTextAnswerList[0]?.validated &&
        questions[3].freeTextAnswerList &&
        questions[3].freeTextAnswerList[0]?.validated &&
        // questions[5].freeTextAnswerList &&
        // questions[5].freeTextAnswerList[0]?.validated &&
        // questions[8].freeTextAnswerList &&
        // questions[8].freeTextAnswerList[0]?.validated &&
        questions[9].freeTextAnswerList &&
        questions[9].freeTextAnswerList[0]?.validated &&
        questions[10].freeTextAnswerList &&
        questions[10].freeTextAnswerList[0]?.validated &&
        // questions[11].freeTextAnswerList &&
        // questions[11].freeTextAnswerList[0]?.validated &&
        // Validate Q35 RADIO_BUTTON
        // questions[7].selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id &&
        // Validate Q32 CHECKBOX
        !isEmpty(questions[4].selectionAnswerList?.[0]?.selectedAnswerOptionList) &&
        // Validate Q34
        // RADIO_BUTTON
        (questions[6].selectionAnswerList?.[0]?.selectedAnswerOptionList?.[0]?.id &&
        // relatedAnswerOptionId
        questions[6].selectionAnswerList[0].selectedAnswerOptionList[0].id !==
            questions[6]?.answerDefinitionList[1]?.relatedAnswerOptionId
            ? true
            : // FREE_TEXT
              questions[6].freeTextAnswerList?.[0].validated &&
              questions[6].freeTextAnswerList?.[1].validated &&
              questions[6].freeTextAnswerList?.[2].validated &&
              //COMBOBOX
              questions[6].sourceSelectAnswerList?.[0].selectedId);

    const clickContinueHandler = async () => {
        setSending(true);

        if (isValid()) {
            dispatch(setActiveRequest(true));
            const requestData = {};
            requestData.supportApplicantInfo = generateRepInfoData(form.supportApplicantInfo);
            requestData.questionList = generateStructuralInfoAnswers(questions);

            let res = null;
            try {
                res = await FintechBeService.supportProgramApply({
                    keycloak,
                    formId,
                    requestData,
                    category: CATEGORY_STRUCTURE_INFO,
                });
                dispatch(setActiveRequest(false));
            } catch (reason) {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            if (!res || !res.success) {
                enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            nextPage();
        } else {
            enqueueSnackbar(t('validation.genericMessage'), { variant: 'error' });
        }
    };

    const isLoading = form.isLoading || questions.length < 1;

    return isLoading ? (
        <LoadingSpinnerWithText style={loadingSpinnerWithTextGeneralTextStyle} />
    ) : (
        <CfoFormContainer>
            {matches && (
                <Typography className={classes.pageTitle}>{t('pageTitles.structureInformationLongText')}</Typography>
            )}
            {questions.map((question) => (
                <Grid
                    item
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    key={question.questionNumber}
                    style={{ paddingBottom: '24px' }}
                >
                    <CfoFormLabel
                        label={`${question.questionNumber}. ${question.name}`}
                        subText={question.subText}
                        isOptional={question.required === 'OPTIONAL'}
                    />
                    <CfoFormInput>{getCorrespondingInput(question)}</CfoFormInput>
                </Grid>
            ))}
            <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className={classes.buttonsWrapper}
            >
                <CfoFormInput xs={4} lg={3}>
                    <Button
                        startIcon={matches && <ArrowBack />}
                        className={classes.secondaryButton}
                        onClick={prevPage}
                        fullWidth
                        disabled={hasActiveRequest}
                    >
                        {matches ? t('buttonTexts.returnToPreviousStep') : t('buttonTexts.previousStep')}
                    </Button>
                </CfoFormInput>
                <CfoFormInput xs={7} lg={8}>
                    <Button
                        className={classes.primaryButton}
                        onClick={clickContinueHandler}
                        fullWidth
                        disabled={hasActiveRequest}
                    >
                        {!hasActiveRequest ? t('buttonTexts.continue') : <ButtonCircularIndicator />}
                    </Button>
                </CfoFormInput>
            </Grid>
        </CfoFormContainer>
    );
};

export default StructuralInformation;
