import {
    DIRECTION_RIGHT,
    DEF_FREQUENCY,
    DEF_DISTANCE,
    DEF_STEP,
    DIRECTION_LEFT,
    DEF_DELAY,
} from 'fintech/components/util/DataGridContants';

// Long mouse press
export const defaultScrollOptions = {
    shouldPreventDefault: true,
    delay: DEF_DELAY,
};

// Ref: https://stackoverflow.com/a/24636116
export const sideScroll = ({
    element,
    direction = DIRECTION_RIGHT,
    frequency = DEF_FREQUENCY,
    distance = DEF_DISTANCE,
    step = DEF_STEP,
    callback,
}) => {
    if (!element) {
        return;
    }

    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
        if (direction == DIRECTION_LEFT) {
            element.scrollLeft -= step;
            callback();
        } else {
            element.scrollLeft += step;
            callback();
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
            window.clearInterval(slideTimer);
        }
    }, frequency);
};
