import React from 'react';
import PropTypes from 'prop-types';
import useStyles from 'fintech/components/modal/AdvancedFilteringModal/index.style';
import { withTranslation } from 'react-i18next';
import { Box, ButtonGroup, Button, Select, FormControl, InputLabel } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { mobileSmallQuery } from 'fintech/components/style/common';

const renderDesktopVersion = ({ verticals, selectedVertical, setVertical, t, styles, type }) => {
    return (
        <ButtonGroup
            className={styles.categorySideBarContainer}
            onClick={(e) => {
                // Optimization
                const buttonElement = e.target.closest('button');
                if (!_.isNull(buttonElement)) {
                    const buttonElementID = buttonElement.id.split('-')[1];
                    setVertical(+buttonElementID); // Extract string and convert to number
                }
            }}
        >
            {verticals.map((element) => {
                const { id, value } = element;

                return (
                    <Button
                        key={id}
                        id={`afm-${id}`}
                        variant="contained"
                        fullWidth
                        className={selectedVertical === id ? styles.selectedSideButton : styles.sideButton}
                    >
                        {t(`${type}.${value}`)}
                    </Button>
                );
            })}
        </ButtonGroup>
    );
};

const renderMobileVersion = ({ verticals, selectedVertical, setVertical, t, type, styles }) => {
    return (
        <FormControl classes={{ root: styles.mobileFilterCategory }} variant="outlined">
            <InputLabel classes={{ root: styles.primaryText }} htmlFor="outlined-age-native-simple">
                Filtre Kategorileri
            </InputLabel>
            <Select
                native
                label="Filtre Kategorileri"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedVertical}
                onChange={(e) => {
                    setVertical(e.target.value);
                }}
                inputProps={{
                    name: 'Filtre Kategorileri',
                    id: 'outlined-age-native-simple',
                }}
            >
                {verticals.map((element) => {
                    const { id, value } = element;
                    return (
                        <option key={id} value={id}>
                            {t(`${type}.${value}`)}
                        </option>
                    );
                })}
            </Select>
        </FormControl>
    );
};

const SideBar = (props) => {
    const styles = useStyles();
    const isMobile = !useMediaQuery(mobileSmallQuery);

    return <>{isMobile ? renderMobileVersion({ ...props, styles }) : renderDesktopVersion({ ...props, styles })}</>;
};

SideBar.propTypes = {
    verticals: PropTypes.array.isRequired,
    selectedVertical: PropTypes.number.isRequired,
    setVertical: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default withTranslation('entrepreneur')(SideBar);
