import { makeStyles } from '@material-ui/core';
import { COLOR_SECONDARY_BASE, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    CompanyTitleTypo: {
        ...fontStyle,
        fontWeight: 600,
    },
    InvestmentInfoTypo: {
        ...fontStyle,
        color: COLOR_SECONDARY_BASE,
    },
});

export default useStyles;
