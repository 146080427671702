import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { fontStyle } from 'fintech/components/style/common';

export const AgreementWrapperDiv = withStyles({
    root: {
        padding: '4px 26px',
    },
})(Box);

export const AgreementTypo = withStyles({
    root: {
        ...fontStyle,
    },
})(Typography);
