import * as React from 'react';

function SvgIconsSingleWord(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#3F5162" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M18.75 21c.532 0 .964-.432.964-.964V8.464c0-.532-.311-1.275-.683-1.647l-3.134-3.134C15.526 3.311 14.782 3 14.25 3h-9c-.532 0-.964.432-.964.964v16.072c0 .532.432.964.964.964h13.5zm-.321-1.286H5.57V4.286h7.715v4.178c0 .533.432.965.964.965h4.179v10.285zm-.08-11.571H14.57V4.366c.171.06.342.15.412.221l3.144 3.144c.07.07.161.241.221.412zM7.51 18.429c.603 0 1.396-.864 2.43-2.632 1.076-.372 2.542-.683 3.938-.834.844.442 1.818.733 2.461.733.533 0 .683-.2.713-.381v-.01c.01-.01.02-.03.03-.04.111-.242.05-.422-.02-.523-.25-.412-1.104-.492-1.777-.492-.362 0-.754.02-1.176.07-.311-.18-.592-.361-.843-.562-.593-.482-1.115-1.336-1.467-2.39.12-.694.13-1.297.14-1.93v-.3c0-.03 0-.05-.01-.081.02-.02.03-.05.04-.08.061-.322.031-.543-.09-.683-.08-.1-.2-.151-.351-.151h-.221c-.201 0-.352.15-.422.402-.18.663-.08 1.788.241 2.882-.24.844-.593 1.728-1.045 2.632-.401.814-.763 1.467-1.074 1.979 0-.01-.01-.03-.02-.04-.05-.08-.151-.11-.231-.06-1.246.652-1.788 1.426-1.889 1.888-.03.2.01.311.06.362.02.02.03.04.06.05l.242.12c.09.05.18.07.281.07zm3.948-8.458c-.08-.563-.08-1.045.02-1.326 0-.01.01-.02.01-.04.06.07.12.18.13.362.01.02.01.04.02.05-.02.03-.03.05-.04.08-.02.12-.05.261-.07.432-.02.13-.04.281-.07.442zm-1.226 5.314c.15-.272.301-.553.452-.834.362-.713.643-1.366.834-1.979.341.733.783 1.336 1.276 1.768.09.08.19.15.29.231-.984.18-1.968.462-2.852.814zm6.328-.12c-.301 0-.753-.101-1.245-.282 1.095 0 1.356.19 1.406.24.01.01.02.021.02.031-.04.01-.09.01-.18.01zm-9.08 2.721c.15-.341.623-1.004 1.376-1.587-.643 1.015-1.075 1.447-1.376 1.587z"
                                    transform="translate(-332 -1220) translate(280 988) translate(52 61) translate(0 171)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleWord;
