import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector, useDispatch } from 'react-redux';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import { IntroCardContent, IntroCardTitle } from 'fintech/components/profile/common';
import Empty from 'fintech/components/util/Empty';
import { formatMonetaryValue } from 'fintech/utils/StringUtils';
import { fetchInvestorPreferencesData, fetchInvestorAllInvestments } from 'fintech/store/actions/establishment';
import { useTranslation } from 'react-i18next';
import PreferencesInfo from 'fintech/components/profile/establishment/InvestmentPreferences/PreferencesInfo/PreferencesInfo';
import InvestmentHistoryGrid from 'fintech/components/profile/establishment/InvestmentPreferences/InvestmentHistoryGrid/InvestmentHistoryGrid';

const InvestmentPreferences = ({ id: investorUuid, editable }) => {
    const { data: fetchedData, isLoading, isError } = useSelector((state) => state.establishment.investmentPreferences);
    const [preferencesData, setPreferencesData] = useState(fetchedData);
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();

    // eslint-disable-next-line no-undef
    let hasData = _.keys(preferencesData).length > 0 && preferencesData.id;

    let preferencesJSX = null;
    let emptyProps = {};

    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.financials.errorMessage');
    } else {
        preferencesJSX = (
            <PreferencesInfo editable={editable} duoItems={hasData ? getDuoGridItems(t, preferencesData) : null} />
        );
    }

    useEffect(() => {
        dispatch(fetchInvestorPreferencesData(keycloak, investorUuid));
        dispatch(fetchInvestorAllInvestments(keycloak, investorUuid));
    }, [investorUuid]);

    useEffect(() => {
        setPreferencesData(fetchedData);
    }, [fetchedData]);

    return (
        <>
            {preferencesJSX ? preferencesJSX : <Empty {...emptyProps} />}
            <InvestmentHistoryGrid />
        </>
    );
};

export default InvestmentPreferences;

const getDuoGridItems = (
    t,
    { managementParticipateType, investmentAmountInterval, companyInvestmentAmountInterval }
) => {
    const itemsJSX = [];

    itemsJSX.push(
        <IntroGridItem
            title={
                <IntroCardTitle>
                    {t('profile.establishments.investmentPreferences.titles.managementParticipationType')}
                </IntroCardTitle>
            }
            content={managementParticipateType && <IntroCardContent>{managementParticipateType}</IntroCardContent>}
        />
    );

    itemsJSX.push(
        <IntroGridItem
            title={
                <IntroCardTitle>
                    {t('profile.establishments.investmentPreferences.titles.intendedTotalInvestmentAmount')}
                </IntroCardTitle>
            }
            content={
                <IntroCardContent>
                    {investmentAmountInterval &&
                        investmentAmountInterval.lowerLimit &&
                        formatAmounts(
                            investmentAmountInterval.lowerLimit,
                            investmentAmountInterval.upperLimit,
                            investmentAmountInterval.currencyType
                        )}
                </IntroCardContent>
            }
        />
    );
    itemsJSX.push(
        <IntroGridItem
            title={
                <IntroCardTitle>
                    {t('profile.establishments.investmentPreferences.titles.intendedTotalInvestmentAmountForACompany')}
                </IntroCardTitle>
            }
            content={
                <IntroCardContent>
                    {companyInvestmentAmountInterval &&
                        companyInvestmentAmountInterval.lowerLimit &&
                        formatAmounts(
                            companyInvestmentAmountInterval.lowerLimit,
                            companyInvestmentAmountInterval.upperLimit,
                            companyInvestmentAmountInterval.currencyType
                        )}
                </IntroCardContent>
            }
        />
    );

    return itemsJSX;
};

const formatAmounts = (lowerLimit, upperLimit, currencyType) => {
    if (!upperLimit) {
        return `> ${formatMonetaryValue(lowerLimit, currencyType)}`;
    }
    return `${formatMonetaryValue(lowerLimit, currencyType)} - ${formatMonetaryValue(upperLimit, currencyType)}`;
};
