import * as React from 'react';

function SvgFlagsSTrTurkey(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={16}
            height={12}
            viewBox="0 0 16 12"
            {...props}
        >
            <defs>
                <path id="vsb7egw8wa" d="M0 0H16V12H0z" />
                <path id="jz8vxv0q6c" d="M0 0H16V12H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g transform="translate(-1040 -1151) translate(918 987) translate(99 148) translate(23 16)">
                                <g mask="url(#k9sio7pzhb)">
                                    <use fill="#E31D1C" xlinkHref="#jz8vxv0q6c" />
                                    <path
                                        fill="#F7FCFF"
                                        d="M7.936 2.033c.927.014 2.047.574 2.359 1.347 0 0-1.026-.615-2.207-.354-1.182.26-1.945 1.287-1.975 2.516-.033 1.313.79 2.224 1.856 2.506 1.066.283 2.205-.325 2.205-.325-.145.405-.825 1.351-2.224 1.329C6.204 9.024 4.24 7.727 4.296 5.51c.053-2.138 1.916-3.504 3.64-3.477zm2.502 2.04l.777.896 1.116-.354-.698.94 1.023.91-1.309-.118-.81.938.044-1.268-1.196-.446 1.073-.373-.02-1.125z"
                                        mask="url(#y9iogdw0wd)"
                                        transform="rotate(-1 8.475 5.542)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgFlagsSTrTurkey;
