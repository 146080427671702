import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { DialogContent, Grid, Typography } from '@material-ui/core';
import SwitchLabel from 'fintech/components/ui/form/switch/SwitchLabel';
import useStyles from './index.style';
import CurrencyInput from 'fintech/components/ui/form/textinput/CurrencyInput';
import TechnologyUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/TechnologyUpdate';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import { fetchStartupIncomeModelsData } from 'fintech/store/actions/startup';
import {
    STARTUP_FINANCIALS_REQUEST_UPDATE,
    STARTUP_FINANCIALS_MARKET_VALUE_UPDATE,
    STARTUP_FINANCIALS_INCOME_MODEL_UPDATE,
} from 'fintech/components/util/ActionModalConstants';

const FinancialsActionModal = (props) => {
    const classes = useStyles();
    const { initialRequest, initialMarketValue, changeCallback, sending } = props;
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { uuid } = useSelector((state) => state.startup.intro.data);

    const requestChangeHandler = (checked) =>
        changeCallback({
            type: STARTUP_FINANCIALS_REQUEST_UPDATE,
            data: {
                checked: checked,
            },
        });

    const marketValueChangedHandler = (monVal, validated) =>
        changeCallback({
            type: STARTUP_FINANCIALS_MARKET_VALUE_UPDATE,
            data: {
                marketValue: {
                    value: monVal.value ? monVal.value : '',
                    validated: validated,
                },
            },
        });

    const incomeModelChangeHandler = (incomeModels, valid) =>
        changeCallback({
            type: STARTUP_FINANCIALS_INCOME_MODEL_UPDATE,
            data: {
                incomeModelData: filterObjectList(incomeModels, 'selected', [true]),
                incomeValid: valid,
            },
        });

    const { data: fetchedIncomeModels, isLoading: incomeLoading, isError: incomeError } = useSelector(
        (state) => state.startup.form.incomeModelData
    );
    const [incomeModelData, setincomeModelData] = useState([]);

    useEffect(() => {
        dispatch(fetchStartupIncomeModelsData(keycloak, uuid));
    }, []);

    useEffect(() => {
        setincomeModelData(_.clone(fetchedIncomeModels.incomeModels, true));
    }, [fetchedIncomeModels]);

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item xs={12}>
                <DialogContent className={classes.InvRequestDialog}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography className={classes.InvRequestLabel}>
                                {t('profile.financials.investmentRequest')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <SwitchLabel
                                labelStyle={{ minWidth: '150px' }}
                                checked={initialRequest}
                                onChange={requestChangeHandler}
                                labelChecked={t('profile.financials.acceptOffers')}
                                labelUnchecked={t('profile.financials.declineOffers')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Grid>
            <Grid item xs={12}>
                <CurrencyInput
                    id="startup-financials-marketvalue-input"
                    label={t('profile.financials.marketValue')}
                    initialValue={initialMarketValue}
                    validatedAfterFormSubmit={!sending}
                    onChange={marketValueChangedHandler}
                    validateOnBlur
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <DialogContent className={classes.IncomeModelDialog}>
                    <TechnologyUpdate
                        label={t('profile.financials.form.incomeModels')}
                        technologies={incomeModelData}
                        isLoading={incomeLoading}
                        isError={incomeError}
                        changeCallback={(data, valid) => {
                            incomeModelChangeHandler(data, valid);
                        }}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                    />
                </DialogContent>
            </Grid>
        </Grid>
    );
};

export default FinancialsActionModal;
