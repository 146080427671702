import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import { renderSearchCategoryStyle } from 'fintech/pages/post-login/EntrepreneursPage/index.style';
import { useDispatch, useSelector } from 'react-redux';

const SearchPrefs = (props) => {
    const styles = renderSearchCategoryStyle();
    const { type, preferencesText, setSearchBoxFilterStatus = () => {} } = props;
    const { t } = useTranslation(type);

    const { filters } = useSelector((state) => state[type].searchBox);
    const selectedLabels = filters.filter((element) => element.status === true).map((e) => e.label);
    const dispatch = useDispatch();

    return (
        <Select
            multiple
            value={selectedLabels}
            onChange={(e) => {
                dispatch(setSearchBoxFilterStatus(e.target.value));
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            }}
            displayEmpty
            renderValue={(selected) => {
                return (
                    <Box component="div">
                        <Box component="span" className={`${styles.secondaryBase} ${styles.bold}`}>
                            {preferencesText}
                        </Box>
                        <Typography
                            className={`${styles.dropdown} ${styles.bold}`}
                            color="primary"
                            display="inline"
                        >{`(${selected.length}/${filters.length})`}</Typography>
                    </Box>
                );
            }}
        >
            {filters.map((element) => (
                <MenuItem key={element.id} value={element.label}>
                    <Checkbox color="primary" checked={selectedLabels.indexOf(element.label) > -1}></Checkbox>
                    <ListItemText primary={t(`${type}.${element.label}`)}></ListItemText>
                </MenuItem>
            ))}
        </Select>
    );
};

export default SearchPrefs;
