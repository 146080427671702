import { getRequest, postRequest, putRequest, patchRequest, deleteRequest, getRequestBlob } from 'fintech/api/api';
import { reCaptchaVerificationURL } from 'fintech/constants/apis';
import { APIS } from 'fintech/constants';

export const getIsMentorApplicationActive = (keycloak) => {
    return getRequest(APIS.PUBLIC_RESOURCES.getIsMentorApplicationActive(), keycloak);
};

export const getFileToken = (keycloak) => {
    const uri = 'api/file/s';
    return getRequest(uri, keycloak);
};

export const getUser = (keycloak) => {
    const uri = 'api/auth/user';
    return getRequest(uri, keycloak);
};

export const getAdmin = (keycloak) => {
    const uri = 'api/auth/admin';
    return getRequest(uri, keycloak);
};

export const getAllUser = (keycloak) => {
    const uri = 'api/auth/all-user';
    return getRequest(uri, keycloak);
};

export const getLeftMenuItem = (keycloak) => {
    const uri = 'api/menu/menuitem';
    return getRequest(uri, keycloak);
};

export const postFileInformation = async (keycloak, fileInfo, isPublic) => {
    const uri = isPublic ? 'api/public/file/upload-file' : 'api/file/upload-file';
    return await postRequest(uri, keycloak, fileInfo);
};

export const getStartupDocuments = (keycloak, startupUuid, page, size) => {
    // default values for page and size are 0 and 20 respectively.
    const uri = `api/startup/${startupUuid}/documents?page=${page}&size=${size}`;
    return getRequest(uri, keycloak);
};

export const updateStartupDocuments = (keycloak, startupUuid, params) => {
    const uri = `api/startup/${startupUuid}/documents`;
    return putRequest(uri, keycloak, params);
};

export const deleteStartupDocument = (keycloak, startupUuid, body) => {
    const uri = `api/startup/${startupUuid}/documents`;
    return deleteRequest(uri, keycloak, body, false, true);
};
export const getInvestorDocuments = (keycloak, investorUuid, page, size) => {
    // default values for page and size are 0 and 20 respectively.
    const uri = `api/investor/${investorUuid}/documents?page=${page}&size=${size}`;
    return getRequest(uri, keycloak);
};

export const updateInvestorDocuments = (keycloak, investorUuid, params) => {
    const uri = `api/investor/${investorUuid}/documents`;
    return putRequest(uri, keycloak, params);
};

export const deleteInvestorDocument = (keycloak, investorUuid, body) => {
    const uri = `api/investor/${investorUuid}/documents`;
    return deleteRequest(uri, keycloak, body, false, true);
};

export const postStartupInviteMember = (keycloak, startupUuid, body) => {
    const uri = `api/startup/${startupUuid}/team-invite`;
    return postRequest(uri, keycloak, body);
};

export const updateStartupTeamMember = (keycloak, startupUuid, memberId, body) => {
    const uri = `api/startup/${startupUuid}/team-member/${memberId}`;
    return putRequest(uri, keycloak, body);
};

export const deleteStartupTeamMember = (keycloak, startupUuid, memberId) => {
    const uri = `api/startup/${startupUuid}/team-member/${memberId}`;
    return deleteRequest(uri, keycloak);
};

export const cancelStartupTeamInvitation = (keycloak, startupUuid, inviteId) => {
    const uri = `api/startup/${startupUuid}/team-invite?inviteId=${inviteId}`;
    return putRequest(uri, keycloak);
};

export const updateStartupInfo = (keycloak, startupInfo, startupId) => {
    const uri = 'api/startup/' + startupId + '/informationCard';
    return putRequest(uri, keycloak, startupInfo);
};

export const updateUserProfile = (keycloak, user) => {
    const uri = 'api/user/';
    return postRequest(uri, keycloak, user);
};

export const updateStartupGeneralInfo = (keycloak, startupGeneralInfo, startupId) => {
    const uri = 'api/startup/' + startupId + '/general-info';
    return patchRequest(uri, keycloak, startupGeneralInfo);
};

export const updateStartupFinancialsInfo = (keycloak, financialsInfo, startupId) => {
    const uri = 'api/startup/' + startupId + '/finance';
    return putRequest(uri, keycloak, financialsInfo);
};

export const updateStartupInvestmentInfo = (keycloak, investmentInfo, startupId) => {
    const uri = 'api/startup/' + startupId + '/investment';
    return putRequest(uri, keycloak, investmentInfo);
};

export const deleteStartupInvestmentInfo = (keycloak, investmentId) => {
    const uri = 'api/startup/investment/' + investmentId;
    return deleteRequest(uri, keycloak);
};

export const getConsultantCalendarWorkDays = (keycloak, consultantId) => {
    const uri = 'api/consultant-calender/' + consultantId + '/work-days';
    return getRequest(uri, keycloak);
};

export const getConsultantFilteredCalender = (keycloak, uuid, params) => {
    const uri =
        'api/consultant-calender/' + uuid + '/filter-calender?dayId=' + params.dayId + '&statuses=' + params.statuses;
    return getRequest(uri, keycloak);
};

export const consultantApproveMeetingRequest = (keycloak, meetingId, params) => {
    const uri = 'api/consultant-meeting/' + meetingId + '/meeting-approve';
    return putRequest(uri, keycloak, params);
};

export const consultantRejectMeetingRequest = (keycloak, meetingId, params) => {
    const uri = 'api/consultant-meeting/' + meetingId + '/meeting-reject';
    return putRequest(uri, keycloak, params);
};

export const consultantCancelMeetingRequest = (keycloak, meetingId, params) => {
    const uri = 'api/consultant-meeting/' + meetingId + '/meeting-cancel';
    return putRequest(uri, keycloak, params);
};

// danismanın kendi profilinde calisma saatlerininin uygunluğunu getiren servis.
export const getConsultantDailyCalendar = (keycloak, params) => {
    const uri = 'api/consultant-calender/daily-calender';
    return getRequest(uri, keycloak, params);
};

export const updateConsultantWorkingHours = (keycloak, params, dayId) => {
    const uri = 'api/consultant-calender/slot-update/' + dayId;
    return putRequest(uri, keycloak, params);
};

export const getBackedStartups = (keycloak) => {
    const uri = 'api/startup/backedstartups';
    return getRequest(uri, keycloak);
};

// kullanici danismanin profiline girdiğinde, danismanin uygun olduğu slotları (saatleri) getiren servis.
export const getDailyAvailableCalenderSlots = (keycloak, consultantId, dayId) => {
    const uri = `api/consultant-calender/${consultantId}/daily-available-calender-slots?dayId=${dayId}`;
    return getRequest(uri, keycloak);
};

export const getWaitingMeetings = (keycloak, consultantId) => {
    const uri = 'api/consultant-meeting/waiting-meetings?consultantId=' + consultantId;
    return getRequest(uri, keycloak);
};

export const postSlotReservation = (keycloak, consultantId, params) => {
    const uri = `api/consultant-calender/${consultantId}/slot-reservation`;
    return postRequest(uri, keycloak, params);
};

// Investor search admin actions
export const updateInvestorRepresentative = (keycloak, userInfo, investorId) => {
    const uri = 'api/investor/' + investorId + '/investor-users/' + userInfo;
    return putRequest(uri, keycloak, userInfo);
};

export const updateInvestorStatus = (keycloak, statusData, investorId) => {
    const uri = 'api/investor/' + investorId + '/status';
    return putRequest(uri, keycloak, statusData);
};

export const updateInvestorInfo = (keycloak, params, investorId) => {
    const uri = 'api/investor/' + investorId + '/informationCard';
    return putRequest(uri, keycloak, params);
};

export const updateInvestorAboutInfo = (keycloak, params, investorId) => {
    const uri = 'api/investor/' + investorId + '/about-info';
    return patchRequest(uri, keycloak, params);
};

export const postInvestorInviteMember = (keycloak, investorUuid, body) => {
    const uri = `api/investor/${investorUuid}/team-invite`;
    return postRequest(uri, keycloak, body);
};

export const updateInvestorTeamMember = (keycloak, investorUuid, memberId, body) => {
    const uri = `api/investor/${investorUuid}/team-member/${memberId}`;
    return putRequest(uri, keycloak, body);
};

export const deleteInvestorTeamMember = (keycloak, investorUuid, memberId) => {
    const uri = `api/investor/${investorUuid}/team-member/${memberId}`;
    return deleteRequest(uri, keycloak);
};

export const cancelInvestorTeamInvitation = (keycloak, investorUuid, inviteId) => {
    const uri = `api/investor/${investorUuid}/team-invite?inviteId=${inviteId}`;
    return putRequest(uri, keycloak);
};

export const updateInvestorPreferences = (
    keycloak,
    investorUuid,
    { managementId, investmentId, companyInvestmentId }
) => {
    let uri = `api/investor/${investorUuid}/investor-preferences`;
    uri += `?managementId=${managementId}`;
    uri += `&investmentId=${investmentId}`;
    uri += `&companyInvestmentId=${companyInvestmentId}`;

    return putRequest(uri, keycloak);
};

export const updateInvestorInvestmentInfo = (keycloak, investmentInfo, investorUuid) => {
    const uri = `api/investor/${investorUuid}/investment`;
    return putRequest(uri, keycloak, investmentInfo);
};

export const deleteInvestorInvestmentInfo = (keycloak, investmentId, investorUuid) => {
    const uri = `api/investor/${investorUuid}/investment?investmentId=${investmentId}`;
    return deleteRequest(uri, keycloak);
};

export const getProfileVisitCount = (keycloak, pageType, uuid) => {
    const uri = `api/visit-history/count/${pageType}/${uuid}`;
    return getRequest(uri, keycloak);
};

export const postProfileVisitCount = (keycloak, pageType, uuid) => {
    const uri = `api/visit-history/visit/${pageType}/${uuid}`;
    return postRequest(uri, keycloak);
};

// User search admin actions
export const updateUserStatus = (keycloak, isActive, userId) => {
    const uri = `api/user/management/${isActive ? 'deactivate' : 'activate'}/${userId}`;
    return putRequest(uri, keycloak);
};

export const addNewUser = (keycloak, userData) => {
    const uri = `api/user/management/person`;
    return postRequest(uri, keycloak, userData);
};

// Support program update/create
export const updateSupportProgram = (keycloak, programInfo) => {
    const uri = 'api/support-program/update';
    return putRequest(uri, keycloak, programInfo);
};

export const fetchSupportPrograms = (keycloak) => {
    const uri = 'api/support-program-approval/programs';
    return getRequest(uri, keycloak);
};

export const fetchStartupAndIdeas = ({ keycloak, page, size = 10, programId, status }) => {
    const uri = `api/support-program-approval/${programId}/get-startups-ideas?status=${status}&size=${size}&page=${page}`;
    return getRequest(uri, keycloak);
};

export const fetchStartupAndIdeasFromSearchBox = ({ keycloak, page, size = 10, body }) => {
    const uri = `api/support-program-approval/search-startups-ideas?size=${size}&page=${page}`;
    return postRequest(uri, keycloak, body);
};

export const fetchSupportApplicationDetails = ({ keycloak, supportApplicationId }) => {
    const uri = `api/support-program-approval/${supportApplicationId}/support-application`;
    return getRequest(uri, keycloak);
};

export const fetchSupportApplicationDetailsPdfDownload = ({ keycloak, selectedContentId }) => {
    const uri = `api/support-program-approval/${selectedContentId}/support-application/pdf`;
    return getRequestBlob(uri, keycloak);
};

export const approveSupportProgramApplication = ({ keycloak, supportApplicationId }) => {
    const uri = `api/support-program-approval/${supportApplicationId}/approve`;
    return getRequest(uri, keycloak);
};

export const rejectSupportProgramApplication = ({ keycloak, supportApplicationId }) => {
    const uri = `api/support-program-approval/${supportApplicationId}/reject`;
    return getRequest(uri, keycloak);
};

// user roles

export const fetchUserRoles = (keycloak) => {
    const uri = 'api/user/roles';
    return getRequest(uri, keycloak);
};

// User block
export const fetchUserIsBlocked = (keycloak, userName) => {
    return getRequest(APIS.USER.getIsBlockedURL(userName), keycloak);
};

export const postBlockUser = (keycloak, userUuid) => {
    return postRequest(APIS.USER.getBlockURL(userUuid), keycloak);
};

export const postUnblockUser = (keycloak, userUuid) => {
    return postRequest(APIS.USER.getUnblockURL(userUuid), keycloak);
};

// Startup block
export const fetchStartupIsBlocked = (keycloak, startupUuid) => {
    return postRequest(APIS.STARTUP.getIsBlockedURL(startupUuid), keycloak);
};

export const postBlockStartup = (keycloak, startupUuid) => {
    return postRequest(APIS.STARTUP.getBlockURL(startupUuid), keycloak);
};

export const postUnblockStartup = (keycloak, startupUuid) => {
    return postRequest(APIS.STARTUP.getUnblockURL(startupUuid), keycloak);
};

// Investor block
export const fetchInvestorIsBlocked = (keycloak, investorUuid) => {
    return postRequest(APIS.INVESTOR.getIsBlockedURL(investorUuid), keycloak);
};

export const postBlockInvestor = (keycloak, investorUuid) => {
    return postRequest(APIS.INVESTOR.getBlockURL(investorUuid), keycloak);
};

export const postUnblockInvestor = (keycloak, investorUuid) => {
    return postRequest(APIS.INVESTOR.getUnblockURL(investorUuid), keycloak);
};

export const postApproveApplication = (keycloak, userApplicationId) => {
    const uri = `api/user-application/approve/${userApplicationId}`;
    return postRequest(uri, keycloak);
};

export const postRejectApplication = (keycloak, userApplicationId) => {
    const uri = `api/user-application/reject/${userApplicationId}`;
    return postRequest(uri, keycloak);
};

// Investor application
export const fetchInvestorInviteInfo = (keycloak, token) => {
    const uri = `api/public/investor/${token}/invite-info`;
    return getRequest(uri, keycloak);
};

export const investorApplicationFirstStep = (keycloak, investorInfo) => {
    const uri = 'api/public/investor/apply-first-step-pre-recaptcha';
    return postRequest(uri, keycloak, investorInfo);
};

export const investorApplicationLastStep = (keycloak, investorInfo) => {
    const uri = 'api/public/investor/apply-first-step-post-recaptcha';
    return postRequest(uri, keycloak, investorInfo);
};

export const investorApplicationComplete = (keycloak, investorInfo) => {
    const uri = 'api/public/investor/apply-complete';
    return postRequest(uri, keycloak, investorInfo);
};

// Startup application
export const fetchStartupInviteInfo = (keycloak, token) => {
    const uri = `api/public/startup/${token}/invite-info`;
    return getRequest(uri, keycloak);
};

export const startupApplicationFirstStep = (keycloak, investorInfo) => {
    const uri = 'api/public/startup/apply-first-step-pre-recaptcha';
    return postRequest(uri, keycloak, investorInfo);
};

export const startupApplicationLastStep = (keycloak, investorInfo) => {
    const uri = 'api/public/startup/apply-first-step-post-recaptcha';
    return postRequest(uri, keycloak, investorInfo);
};

export const startupApplicationComplete = (keycloak, investorInfo) => {
    const uri = 'api/public/startup/apply-complete';
    return postRequest(uri, keycloak, investorInfo);
};

// Team member application
export const fetchTeamMemberInviteInfo = (keycloak, token) => {
    const uri = `api/public/team-member-application/${token}/invite-info`;
    return getRequest(uri, keycloak);
};

export const membershipApplicationFirstStep = (keycloak, investorInfo) => {
    const uri = 'api/public/team-member-application/apply-pre-recaptcha';
    return postRequest(uri, keycloak, investorInfo);
};

export const membershipApplicationComplete = (keycloak, investorInfo) => {
    const uri = 'api/public/team-member-application/apply-post-recaptcha';
    return postRequest(uri, keycloak, investorInfo);
};

export const verifyReCaptcha = (secret, response) => {
    const uri = reCaptchaVerificationURL(secret, response);
    return postRequest(uri);
};

//invite search operation
export const sendInviteMail = (keycloak, inviteId) => {
    const uri = APIS.INVITES.sendInviteMailURL(inviteId, keycloak);
    return postRequest(uri, keycloak);
};

export const cancelInvite = (keycloak, inviteId) => {
    const uri = APIS.INVITES.cancelInviteURL(inviteId, keycloak);
    return putRequest(uri, keycloak);
};
//invite search operation

//reports pages

export const getPerson = (keycloak) => {
    const uri = 'api/dashboard/person';
    return getRequest(uri, keycloak);
};

export const getConsultant = (keycloak) => {
    const uri = 'api/dashboard/consultant';
    return getRequest(uri, keycloak);
};

export const getGeneral = (keycloak) => {
    const uri = 'api/dashboard/general';
    return getRequest(uri, keycloak);
};

export const getInvestor = (keycloak) => {
    const uri = 'api/dashboard/investor';
    return getRequest(uri, keycloak);
};

export const getStartup = (keycloak) => {
    const uri = 'api/dashboard/startup';
    return getRequest(uri, keycloak);
};

export const getReportList = (keycloak, param) => {
    const uri = 'api/report/';
    return getRequest(uri, keycloak, param);
};

export const requestReport = (keycloak, type) => {
    const uri = 'api/report/' + type;
    return postRequest(uri, keycloak);
};

// Support program application
export const supportProgramApply = ({
    keycloak,
    formId,
    category = null,
    requestData,
    isOnlyValidate = true,
    isPreRecaptcha = false,
}) => {
    const uri = `api/public/support-program/apply/${formId}?isOnlyValidate=${isOnlyValidate}${
        category ? `&category=${category}` : ''
    }${isPreRecaptcha ? `&isPreRecaptcha=${isPreRecaptcha}` : ''}`;
    return postRequest(uri, keycloak, requestData);
};
