import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { ClickableStyledTag } from 'fintech/components/ui/button/common';
import { Grid } from '@material-ui/core';

const TagGroup = (props) => {
    const {
        t,
        items,
        limit = 5,
        isGridItem,
        stopPropagation = false,
        preventDefault = false,
        onClick,
        clickableStyledTagStyle,
    } = props;
    const [isExpanded, setIsExpanded] = useState(false);

    let itemsJSX = null;
    let othersJSX = null;
    let showMoreButton = null;
    let showLessButton = null;

    const expansionHandler = (e, val) => {
        stopPropagation && e.stopPropagation();

        if (preventDefault) {
            e.preventDefault();
            onClick && onClick();
            return;
        }

        val ? setIsExpanded(val) : setIsExpanded((prev) => !prev);
    };

    const oversized = items && items.length > limit;
    if (oversized) {
        itemsJSX = items.slice(0, limit);
        othersJSX = items.slice(limit, items.length);
        const more = (
            <ClickableStyledTag
                style={clickableStyledTagStyle}
                key={limit + 1}
                onClick={expansionHandler}
            >{`+${othersJSX.length}`}</ClickableStyledTag>
        );
        showMoreButton = isGridItem ? (
            <Grid key={limit + 1} item>
                {more}
            </Grid>
        ) : (
            more
        );

        const less = (
            <ClickableStyledTag style={clickableStyledTagStyle} key={limit + 2} onClick={expansionHandler}>
                {`${t('profile.showLess')}`}
            </ClickableStyledTag>
        );
        showLessButton = isGridItem ? (
            <Grid key={limit + 2} item>
                {less}
            </Grid>
        ) : (
            less
        );
    }

    // Display logic
    let displayedJSX = [];
    if (oversized) {
        displayedJSX = isExpanded ? [itemsJSX, othersJSX, showLessButton] : [itemsJSX, showMoreButton];
    } else {
        displayedJSX = items;
    }

    return displayedJSX;
};

export default withTranslation('profile')(TagGroup);
