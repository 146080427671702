import { FormHelperText, Grid } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { COLOR_MUI_OUTLINED_BORDER, COLOR_SECONDARY_BASE } from 'fintech/components/style/common';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DropdownError = (props) => {
    const { t } = useTranslation();
    const { noBottomMargin = false } = props;

    return (
        <Grid
            style={{
                marginBottom: !noBottomMargin && '30px',
                borderRadius: '4px',
                border: `1px solid ${COLOR_MUI_OUTLINED_BORDER}`,
                padding: '4px',
                ...props,
            }}
            container
            direction="column"
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <ErrorOutline style={{ color: COLOR_SECONDARY_BASE }} fontSize="small" />
            </Grid>
            <Grid item>
                <FormHelperText style={{ color: COLOR_SECONDARY_BASE, textAlign: 'center' }}>
                    {t('genericError')}
                </FormHelperText>
            </Grid>
        </Grid>
    );
};

export default DropdownError;
