import { Grid } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import IntroDetails from 'fintech/components/profile/user/Intro/Details';
import StrechedGridItem from 'fintech/components/profile/common/StechedGridItem';
import IntroHeader from 'fintech/components/profile/user/Intro/Header';
import IntroTab from 'fintech/components/profile/user/Intro/IntroTab';
import {
    poppins500_14px,
    splitButtonNotInDatagridGroupStyle,
    splitButtonTagsNotInDatagridGroupStyle,
    styledTagNotInDatagridStyle,
} from 'fintech/components/style/common';
import SplitButton from 'fintech/components/ui/button/SplitButton';
import ErrorBoundary from 'fintech/components/util/ErrorBoundary';
import { fetchUser } from 'fintech/store/actions/user';
import { arrayToMap } from 'fintech/utils/ArrayUtils';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SocialLink from '../Intro/Details/SocialLink';
import { StyledTag } from 'fintech/components/ui/button/common';
import useStyles from 'fintech/components/profile/user/IntroGrid/index.style';
import { LocalOffer } from '@material-ui/icons';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import UpdateModal from 'fintech/components/profile/user/modal/UpdateModal';
import Empty from 'fintech/components/util/Empty';
import ModalConsultantProfileUpdate from 'fintech/components/profile/user/modal/UpdateModal/consultant/ModalConsultantProfileUpdate';
import { ROLE_COUNSELOR } from 'fintech/components/util/RoleConstants';
import { hasRoles } from 'fintech/utils/Auth';
import ShowMore from 'fintech/components/ui/ShowMore';
import { getWebsiteUrl } from 'fintech/utils/StringUtils';
import { IntroCardTitle, IntroCardContent, titleWithVisibilityBadge } from 'fintech/components/profile/common';

const IntroGrid = (props) => {
    const classes = useStyles();
    const { t, userUuid, isOwnProfile } = props;

    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const clickEdit = () => {
        setOpenEditModal(true);
    };

    const updateCallback = (isUpdated) => {
        if (isUpdated) {
            dispatch(fetchUser(keycloak, userUuid, isOwnProfile));
        }
    };

    const { data: fetchedData, isLoading, isError } = useSelector((state) => state.user.intro);
    const isConsultant = fetchedData && fetchedData.isConsultant;
    const isLoggedInUserCounselor = hasRoles(keycloak, ROLE_COUNSELOR);

    let emptyProps = {};
    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    }

    if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.relatedVentures.errorMessage');
    }

    if (isLoading || isError) {
        return <Empty {...emptyProps} />;
    }

    const ModalProfileUpdate = () => {
        return isLoggedInUserCounselor && openEditModal ? (
            <ModalConsultantProfileUpdate
                title={t('profile.user.editProfileCardCounselor')}
                open={openEditModal}
                setOpen={setOpenEditModal}
                updateCallback={(isUpdated) => updateCallback(isUpdated)}
                userUuid={userUuid}
                fetchedData={fetchedData}
            />
        ) : (
            <UpdateModal
                title={t('profile.user.editProfileCardPerson')}
                open={openEditModal}
                setOpen={setOpenEditModal}
                userUuid={userUuid}
                updateCallback={(isUpdated) => updateCallback(isUpdated)}
            />
        );
    };
    return (
        <>
            <IntroTab times={fetchedData.visitTimes} />
            <ErrorBoundary message={t('profile.errorMessage')}>
                <Grid
                    className={classes.StupIntroCard}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                >
                    {userUuid && (
                        <IntroHeader
                            name={`${fetchedData.name} ${fetchedData.surname}`}
                            personalWebsite={!isConsultant && fetchedData.personalWebsite}
                            editable={fetchedData.editable}
                            userUuid={userUuid}
                            onClickEditCallback={clickEdit}
                            isOwnProfile={fetchedData.isOwnProfile}
                            username={fetchedData.userName}
                        />
                    )}
                    <IntroDetails duoItems={getDuoGridItems(t, fetchedData, isConsultant)} />
                    {ModalProfileUpdate()}
                </Grid>
            </ErrorBoundary>
        </>
    );
};

export default withTranslation('profile')(IntroGrid);

const getDuoGridItems = (t, fetchedData, isConsultant) => {
    const { editable, profileInfos } = fetchedData;
    const { phoneNumber, email } = profileInfos;
    const itemsJSX = [];

    itemsJSX.push(
        <StrechedGridItem
            title={<IntroCardTitle>{t('profile.user.biography')}</IntroCardTitle>}
            content={
                <IntroCardContent>
                    <span style={{ ...poppins500_14px, wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                        <ShowMore text={fetchedData.profileInfos?.biography?.value || '-'} limit={1400} />
                    </span>
                </IntroCardContent>
            }
        />
    );

    if (isConsultant && fetchedData) {
        const expertise = fetchedData.expertise;
        const expertiseJSX = expertise ? (
            <StyledTag startIcon={<LocalOffer fontSize="small" />} style={styledTagNotInDatagridStyle}>
                {expertise.name}
            </StyledTag>
        ) : (
            '-'
        );
        itemsJSX.push(
            <StrechedGridItem
                title={<IntroCardTitle>{t('profile.user.expertise')}</IntroCardTitle>}
                content={
                    <TagGroup limit={5} items={expertiseJSX} clickableStyledTagStyle={styledTagNotInDatagridStyle} />
                }
            />
        );
        return itemsJSX;
    }

    const fintechVertical = fetchedData.userFintechSubVerticalSet
        ? arrayToMap(fetchedData.userFintechSubVerticalSet, 'vertical')
        : {};
    const verts = Object.keys(fintechVertical).map((key) => (
        <SplitButton
            groupStyle={splitButtonTagsNotInDatagridGroupStyle}
            key={key}
            option={key}
            subOptions={fintechVertical[key].map((e) => e.subVertical)}
        />
    ));
    itemsJSX.push(
        <StrechedGridItem
            title={<IntroCardTitle>{t('profile.user.verticals')}</IntroCardTitle>}
            content={
                verts && verts.length > 0 ? (
                    <TagGroup limit={5} items={verts} clickableStyledTagStyle={splitButtonNotInDatagridGroupStyle} />
                ) : (
                    <IntroCardContent>-</IntroCardContent>
                )
            }
        />
    );

    const techs = fetchedData.technologySet.map((tech) => (
        <StyledTag key={tech.id} startIcon={<LocalOffer fontSize="small" />} style={styledTagNotInDatagridStyle}>
            {tech.name}
        </StyledTag>
    ));
    itemsJSX.push(
        <StrechedGridItem
            title={<IntroCardTitle>{t('profile.user.technology')}</IntroCardTitle>}
            content={
                techs && techs.length > 0 ? (
                    <TagGroup limit={5} items={techs} clickableStyledTagStyle={styledTagNotInDatagridStyle} />
                ) : (
                    <IntroCardContent>-</IntroCardContent>
                )
            }
            wrapperClassName={'margin-top-5 margin-bottom-8'}
        />
    );

    let phoneNumberContent = '-';
    if (editable || phoneNumber?.public) {
        phoneNumberContent = fetchedData?.phoneNumber?.formatInternational() || '-';
    }

    itemsJSX.push(
        <StrechedGridItem
            title={
                editable ? (
                    titleWithVisibilityBadge(phoneNumber?.public, phoneNumber?.canHide, t('profile.user.mobilePhone'))
                ) : (
                    <IntroCardTitle>{t('profile.user.mobilePhone')}</IntroCardTitle>
                )
            }
            content={
                <IntroCardContent>
                    <span style={{ ...poppins500_14px }}>{phoneNumberContent}</span>
                </IntroCardContent>
            }
        />
    );

    let emailContent = '-';
    if (editable || email?.public) {
        emailContent = fetchedData.mailAddress || '-';
    }
    itemsJSX.push(
        <StrechedGridItem
            title={
                editable ? (
                    titleWithVisibilityBadge(
                        profileInfos.email?.public,
                        profileInfos.email?.canHide,
                        t('profile.user.email')
                    )
                ) : (
                    <IntroCardTitle>{t('profile.user.email')}</IntroCardTitle>
                )
            }
            content={
                <IntroCardContent>
                    <span style={{ ...poppins500_14px }}>{emailContent}</span>
                </IntroCardContent>
            }
        />
    );

    itemsJSX.push(
        <StrechedGridItem
            title={<IntroCardTitle>{t('profile.user.social')}</IntroCardTitle>}
            content={
                <>
                    {fetchedData.profileInfos?.instagram && (
                        <SocialLink social="instagram" link={getWebsiteUrl(fetchedData.profileInfos.instagram.value)} />
                    )}
                    {fetchedData.profileInfos?.twitter && (
                        <SocialLink social="twitter" link={getWebsiteUrl(fetchedData.profileInfos.twitter.value)} />
                    )}
                    {fetchedData.profileInfos?.youtube && (
                        <SocialLink social="youtube" link={getWebsiteUrl(fetchedData.profileInfos.youtube.value)} />
                    )}
                    {fetchedData.profileInfos?.linkedin && (
                        <SocialLink social="linkedin" link={getWebsiteUrl(fetchedData.profileInfos.linkedin.value)} />
                    )}
                    {!fetchedData.profileInfos?.instagram &&
                        !fetchedData.profileInfos?.twitter &&
                        !fetchedData.profileInfos?.youtube &&
                        !fetchedData.profileInfos?.linkedin &&
                        '-'}
                </>
            }
        />
    );

    return itemsJSX;
};
