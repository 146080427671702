import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { addDays, isAfter, isEqual, isValid, parse, startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import RichTextArea from 'fintech/components/ui/form/textinput/RichTextArea';
import CfoDatePicker from 'fintech/components/ui/form/picker/CfoDatePicker';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import * as constants from 'fintech/components/util/ActionModalConstants';
import { ISO_FORMAT } from 'fintech/utils/DateUtils';

const ProgramActionModal = (props) => {
    const { t } = useTranslation('supportProgram');
    const {
        editing,
        title,
        description,
        lastApplicationDate,
        committeeLastReviewDate,
        startDate,
        endDate,
        changeCallback,
        sending,
    } = props;
    // State definitions.
    const [progTitle, setProgTitle] = useState({
        value: title.value ? title.value : '',
        validated: title.validated,
    });

    const [progDescription, setProgDescription] = useState({
        value: description.value ? description.value : '',
        validated: description.validated,
    });

    const [progApplicationDate, setProgApplicationDate] = useState({
        value: lastApplicationDate.value ? parse(lastApplicationDate.value, ISO_FORMAT, new Date()) : null,
        validated: lastApplicationDate.validated,
        //CBFOFH-864
        different: false,
    });

    const [progLastReviewDate, setProgLastReviewDate] = useState({
        value: committeeLastReviewDate.value ? parse(committeeLastReviewDate.value, ISO_FORMAT, new Date()) : null,
        validated: committeeLastReviewDate.validated,
        //CBFOFH-864
        different: false,
    });

    const [progStartDate, setProgStartDate] = useState({
        value: startDate.value ? parse(startDate.value, ISO_FORMAT, new Date()) : null,
        validated: startDate.validated,
        //CBFOFH-864
        different: false,
    });

    const [progEndDate, setProgEndDate] = useState({
        value: endDate.value ? parse(endDate.value, ISO_FORMAT, new Date()) : null,
        validated: endDate.validated,
        // CBFOFH-864
        different: false,
    });

    /**
     * CBFOFH-864 - Start
     * Kullanıcı; sadece mevcut bir programda güncelleme yaparken tarihlerden herhangi birini değiştirir ve kaydetmek isterse
     * bu durumda güncellemediği yani değişiklik yapmadığı tarih alanlarında sadece 'günün tarihinden küçük olma validasyonu'
     * yapılmamalıdır.
     */

    const [initApplicationDate] = useState(progApplicationDate.value);

    const [initLastReviewDate] = useState(progLastReviewDate.value);

    const [initStartDate] = useState(progStartDate.value);

    const [initEndDate] = useState(progEndDate.value);

    const today = startOfDay(new Date());

    // Ref: https://material-ui-pickers.dev/api/KeyboardDatePicker
    const defMinDate = parse('1900-01-01', ISO_FORMAT, new Date());

    // Start date
    let startMinDate = defMinDate;
    let startMinDateMsg = '';
    if (editing) {
        if (progStartDate.different) {
            startMinDate = today;
            startMinDateMsg = t('validation.startDateBeforeToday');
        }
    } else {
        startMinDate = today;
        startMinDateMsg = t('validation.startDateBeforeToday');
    }

    // End date
    let endMinDate = isValid(progStartDate.value) ? addDays(progStartDate.value, 1) : today;
    let endMinDateMsg = t('validation.endDateAfterStart');
    if (editing) {
        if (progEndDate.different) {
            endMinDate = isAfter(progStartDate.value, today) ? addDays(progStartDate.value, 1) : today;
            endMinDateMsg = isAfter(progStartDate.value, today)
                ? t('validation.endDateAfterStart')
                : t('validation.endDateBeforeToday');
        }
    } else {
        endMinDate = isValid(progStartDate.value) ? addDays(progStartDate.value, 1) : today;
        endMinDateMsg = isAfter(progStartDate.value, today)
            ? t('validation.endDateAfterStart')
            : t('validation.endDateBeforeToday');
    }

    // Application date
    let applicationMinDate = defMinDate;
    let applicationMinDateMsg = '';
    if (editing) {
        if (progApplicationDate.different) {
            applicationMinDate = today;
            applicationMinDateMsg = t('validation.lastApplicationDateBeforeToday');
        }
    } else {
        applicationMinDate = today;
        applicationMinDateMsg = t('validation.lastApplicationDateBeforeToday');
    }

    // Last review date
    let reviewMinDate = isValid(progApplicationDate.value) ? addDays(progApplicationDate.value, 1) : today;
    let reviewMinDateMsg = t('validation.lastCommitteeReviewDateAfterApplicationDate');
    if (editing) {
        if (progLastReviewDate.different) {
            reviewMinDate = isAfter(progApplicationDate.value, today) ? addDays(progApplicationDate.value, 1) : today;
            reviewMinDateMsg = isAfter(progApplicationDate.value, today)
                ? t('validation.lastCommitteeReviewDateAfterApplicationDate')
                : t('validation.lastCommitteeReviewDateBeforeToday');
        }
    } else {
        reviewMinDate = isValid(progApplicationDate.value) ? addDays(progApplicationDate.value, 1) : today;
        reviewMinDateMsg = isAfter(progApplicationDate.value, today)
            ? t('validation.lastCommitteeReviewDateAfterApplicationDate')
            : t('validation.lastCommitteeReviewDateBeforeToday');
    }
    // CBFOFH-864 - End

    // State change handlers
    const titleChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setProgTitle(newState);
        changeCallback({ type: constants.SUPPORT_PROGRAM_TITLE_UPDATE, data: newState });
    };

    const descChangeHandler = (val, validated) => {
        const newState = {
            value: val,
            validated: validated,
        };
        setProgDescription(newState);
        changeCallback({ type: constants.SUPPORT_PROGRAM_DESC_UPDATE, data: newState });
    };

    const applicationDateChangeHandler = (val, validated) => {
        setProgApplicationDate({
            value: parse(val, ISO_FORMAT, new Date()),
            validated: validated,
            different: !isEqual(initApplicationDate, parse(val, ISO_FORMAT, new Date())),
        });
        changeCallback({
            type: constants.SUPPORT_PROGRAM_LAST_APPLICATION_DATE_UPDATE,
            data: {
                value: val,
                validated: validated,
            },
        });
    };

    const lastReviewDateChangeHandler = (val, validated) => {
        setProgLastReviewDate({
            value: parse(val, ISO_FORMAT, new Date()),
            validated: validated,
            different: !isEqual(initLastReviewDate, parse(val, ISO_FORMAT, new Date())),
        });
        changeCallback({
            type: constants.SUPPORT_PROGRAM_LAST_REVIEW_DATE_UPDATE,
            data: {
                value: val,
                validated: validated,
            },
        });
    };

    const startDateChangeHandler = (val, validated) => {
        setProgStartDate({
            value: parse(val, ISO_FORMAT, new Date()),
            validated: validated,
            different: !isEqual(initStartDate, parse(val, ISO_FORMAT, new Date())),
        });
        changeCallback({
            type: constants.SUPPORT_PROGRAM_START_DATE_UPDATE,
            data: {
                value: val,
                validated: validated,
            },
        });
    };

    const endDateChangeHandler = (val, validated) => {
        setProgEndDate({
            value: parse(val, ISO_FORMAT, new Date()),
            validated: validated,
            different: !isEqual(initEndDate, parse(val, ISO_FORMAT, new Date())),
        });
        changeCallback({
            type: constants.SUPPORT_PROGRAM_END_DATE_UPDATE,
            data: {
                value: val,
                validated: validated,
            },
        });
    };

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item xs={12}>
                <InputTextArea
                    placeholder={t('form.titleLabel')}
                    onChange={titleChangeHandler}
                    type={'text'}
                    required
                    count={150}
                    initText={progTitle.value}
                    validationText={t('validation.notEmpty')}
                    maxCharactersText={t('form.titleMaxCharsMessage')}
                    validateOnBlur
                    validatedAfterFormSubmit={!sending}
                />
            </Grid>
            <Grid item xs={12} container direction="row" justify="flex-start">
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                        <CfoDatePicker
                            id="support-program-application-date-picker"
                            label={t('form.lastApplicationDateLabel')}
                            invalidDateMessage={
                                progApplicationDate.value ? t('validation.date') : t('validation.notEmpty')
                            }
                            initialDate={progApplicationDate.value}
                            minDate={applicationMinDate}
                            minDateMessage={applicationMinDateMsg}
                            autoOk
                            openTo="year"
                            onChange={applicationDateChangeHandler}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CfoDatePicker
                            id="support-program-review-date-picker"
                            label={t('form.committeeLastReviewDateLabel')}
                            invalidDateMessage={
                                progLastReviewDate.value ? t('validation.date') : t('validation.notEmpty')
                            }
                            initialDate={progLastReviewDate.value}
                            minDate={reviewMinDate}
                            minDateMessage={reviewMinDateMsg}
                            autoOk
                            openTo="year"
                            onChange={lastReviewDateChangeHandler}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                        <CfoDatePicker
                            id="support-program-start-date-picker"
                            label={t('form.startDateLabel')}
                            invalidDateMessage={progStartDate.value ? t('validation.date') : t('validation.notEmpty')}
                            initialDate={progStartDate.value}
                            minDate={startMinDate}
                            minDateMessage={startMinDateMsg}
                            autoOk
                            openTo="year"
                            onChange={startDateChangeHandler}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CfoDatePicker
                            id="support-program-end-date-picker"
                            label={t('form.endDateLabel')}
                            invalidDateMessage={progEndDate.value ? t('validation.date') : t('validation.notEmpty')}
                            initialDate={progEndDate.value}
                            minDate={endMinDate}
                            minDateMessage={endMinDateMsg}
                            autoOk
                            openTo="year"
                            onChange={endDateChangeHandler}
                            required
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <RichTextArea
                    count={1500}
                    placeholder={t('form.descriptionPlaceholder')}
                    initText={progDescription.value}
                    validationText={t('validation.notEmpty')}
                    maxCharactersText={t('form.detailMaxCharsMessage')}
                    onChange={descChangeHandler}
                    ignoreBorder
                    editorStyle={{ minHeight: '14em', maxHeight: '14em', padding: '5px' }}
                    required
                    validateOnBlur
                    validatedAfterFormSubmit={!sending}
                />
            </Grid>
        </Grid>
    );
};

export default ProgramActionModal;
