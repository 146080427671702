import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import IntroQuadGrid from './QuadGrid/index.js';
import IntroMediaGrid from './MediaGrid/index.js';
import IntroDuoGrid from 'fintech/components/profile/common/DuoGrid/index.js';

const IntroDetails = (props) => {
    const { getQuadGridItems, getDuoGridItems, mediaItems } = props;
    const [state, setState] = useState({ quadItems: [], duoItems: [] });

    useEffect(() => {
        const quadItems = getQuadGridItems();
        const duoItems = getDuoGridItems();
        setState({
            quadItems: quadItems,
            duoItems: duoItems,
        });
    }, [getQuadGridItems, getDuoGridItems]);
    return (
        <Grid
            container
            direction="row-reverse"
            justify="space-between"
            alignItems="flex-start"
            style={{ padding: '20px 20px 10px 20px' }}
        >
            <IntroMediaGrid media={mediaItems} />
            <Grid item xs={12} md={8}>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                    style={{ maxWidth: '670px' }}
                >
                    <IntroQuadGrid items={state.quadItems} />
                    <IntroDuoGrid items={state.duoItems} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IntroDetails;
