import React from 'react';
import { Grid } from '@material-ui/core';
import EstablishmentAttemptUpdate from 'fintech/components/profile/establishment/InvestmentPreferences/form/EstablishmentAttemptUpdate/EstablishmentAttemptUpdate';
import InvestorDateUpdate from 'fintech/components/profile/startup/form/financials/DateUpdate';
import InvestorAmountUpdate from 'fintech/components/profile/startup/form/financials/AmountUpdate';
import InvestorVisibilitySwitch from 'fintech/components/profile/startup/form/financials/VisibilitySwitch';
import {
    setEstablishmentInvestmentDate,
    setEstablishmentInvestmentAmount,
    setEstablishmentInvestmentVisibility,
} from 'fintech/store/actions/establishment';
import { STATES } from 'fintech/store/actions/ActionTypes';
const EstablishmentInvestorActionModal = () => {
    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item xs={12}>
                <EstablishmentAttemptUpdate />
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                        <InvestorDateUpdate
                            type={STATES.ESTABLISHMENT}
                            setInvestmentDate={setEstablishmentInvestmentDate}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InvestorAmountUpdate
                            type={STATES.ESTABLISHMENT}
                            setInvestmentAmount={setEstablishmentInvestmentAmount}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <InvestorVisibilitySwitch
                    type={STATES.ESTABLISHMENT}
                    setInvestmentVisibility={setEstablishmentInvestmentVisibility}
                />
            </Grid>
        </Grid>
    );
};

export default EstablishmentInvestorActionModal;
