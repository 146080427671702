import React, {useEffect, useState} from 'react';

import { makeMessageInputStyles } from "./MessageInput.style";
import {Typography} from "@material-ui/core";
import AttachmentLogo from 'fintech/static/images/icons-single-add-blue.svg';
import { useKeycloak } from '@react-keycloak/web';
import { postRequest } from "fintech/api/api";
import {getPublicFileUrl, uploadAvatarFile, uploadFile} from 'fintech/api/fileService';
import { APIS } from 'fintech/constants';
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {DOCUMENT_MIMES_START, IMAGE_MIMES_START} from "fintech/components/util/FileConstants";

const MessageInput = ({ currentClientUuid }) => {
    const messageInputStyles = makeMessageInputStyles();
    const { keycloak } = useKeycloak();
    const [messageValue, setMessageValue] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (!file.type.startsWith(DOCUMENT_MIMES_START)) {
            enqueueSnackbar(t('avatar.imageFormatError'), {
                variant: 'error',
                autoHideDuration: 5000,
            });
        } else if (file.size > 10000000) {
            enqueueSnackbar(t('avatar.imageSizeError'), {
                variant: 'error',
                autoHideDuration: 5000,
            });
        } else {
            const formData = new FormData();
            formData.append('uploaded_file', file);
            uploadFile(keycloak, formData, file.name).then((fileResult) => {
                handleSendFile(fileResult);
            });
        }
    };

    const handleSendFile = (fileResult) => {
        const messageData = {
            senderUuid: keycloak.subject,
            receiverUuid: currentClientUuid,
            content: `File sent: ${fileResult.fileName}`,
            is_seen: false,
            attachmentFileDto: {
                uuid: fileResult.uuid,
                fileName: fileResult.fileName,
                mimeType: fileResult.mimeType,
                fileSize: fileResult.fileSize,
            },
        };

        postRequest(APIS.CHAT.sendMessage(), keycloak, messageData).then((response) => {
            if (response.success) {
                return;
            }
            else {
                enqueueSnackbar(t('validation.genericError'), { variant: 'error' });
            }
        });
    }

    const handleSendMessage = () => {

        if (messageValue && messageValue !== "") {
            const messageData = {
                senderUuid: keycloak.subject,
                receiverUuid: currentClientUuid,
                content: messageValue,
                is_seen: false,
            }

            postRequest(APIS.CHAT.sendMessage(), keycloak, messageData).then((response) => {
                if (response.success) {
                    setMessageValue("");
                }
                else {
                    enqueueSnackbar(t('validation.genericError'), { variant: 'error' });
                }
            });
        }
    };

    return (<div className={messageInputStyles.inputArea}>
        <textarea className={messageInputStyles.input} value={messageValue} onChange={(event) => setMessageValue(event.target.value)} rows={2} cols={200} placeholder={"Mesajınızı yazın..."}/>
        <div className={messageInputStyles.send}>
            <label>
                <input
                    accept="*"
                    className={messageInputStyles.fileInput}
                    name="uploaded_file"
                    type="file"
                    onChange={handleFileUpload}
                />
                <img className={messageInputStyles.sendImage} src={AttachmentLogo} />
            </label>
            <button className={messageInputStyles.sendButton} onClick={handleSendMessage} disabled={(!messageValue || messageValue === "")}>Gönder</button>
        </div>
    </div>);
};

export default MessageInput;
