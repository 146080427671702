import { GRID_HEADER_STATE } from 'fintech/store/actions/entrepreneur';

// TODO: Use constants for field value
// Investor Filter categories
const categories = [
    {
        id: 0,
        value: 'investorTypes',
    },
    {
        id: 1,
        value: 'startupStages',
    },
    {
        id: 2,
        value: 'investmentAreas',
    },
    {
        id: 3,
        value: 'fintechSubVerticalInterests',
    },
];

export const defaultFilterCategories = [...categories];

// Currently unused until Phase 2
export const adminFilterCategories = [
    {
        id: 4,
        value: 'status',
    },
    ...categories,
];

// Investor Search
export const defaultSearchBoxFilters = [
    { id: 0, label: 'investorName', status: true },
    { id: 1, label: 'investorTypes', status: true },
    { id: 2, label: 'startupStages', status: true },
    { id: 3, label: 'investmentAreas', status: true },
    { id: 4, label: 'fintechSubVerticalInterests', status: true },
];

// Currently unused until Phase 2
export const searchBoxAdminFilters = [
    {
        id: 5,
        label: 'status',
    },
    ...defaultSearchBoxFilters,
];

const headers = [
    {
        id: 0,
        type: 'sortLabel',
        value: {
            title: 'dataGrid.headers.investor',
            status: GRID_HEADER_STATE.ASC,
            searchIndex: 'name',
        },
    },
    {
        id: 1,
        type: 'sortLabel',
        value: {
            title: 'dataGrid.headers.type',
            status: GRID_HEADER_STATE.NONE,
            searchIndex: 'investorType',
        },
    },
    {
        id: 2,
        type: 'label',
        value: 'dataGrid.headers.stage',
    },
    {
        id: 3,
        type: 'sortLabel',
        value: {
            title: 'dataGrid.headers.dateFounded',
            status: GRID_HEADER_STATE.NONE,
            searchIndex: 'dateFounded',
        },
    },
    {
        id: 4,
        type: 'label',
        value: 'dataGrid.headers.investmentAreas',
    },
    {
        id: 5,
        type: 'label',
        value: 'dataGrid.headers.fintechVerticals',
    },
];

export const defaultTableHeaders = [...headers];

export const adminTableHeaders = [
    {
        id: 5,
        type: 'label-actions',
        value: '#',
    },
    {
        id: 6,
        type: 'label-status',
        value: 'dataGrid.headers.status',
    },
    ...headers,
];
