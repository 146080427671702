import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactPlayer from 'react-player';
import { getThumbnailUrl } from 'fintech/api/fileService';
import { VIDEO_MIMES_START } from 'fintech/components/util/FileConstants';

const MediaCrousel = (props) => {
    const { media, selectedItem } = props;
    const mediaCarouselJSX = media?.map((med, idx) =>
        med.file.mimeType.startsWith(VIDEO_MIMES_START) ? (
            <div style={{
                position: 'relative',
                paddingTop: '56.25%',
            }}>
                <ReactPlayer
                    key={idx}
                    controls
                    url={med.url}
                    width='100%'
                    height='100%'
                    light={getThumbnailUrl(med.file.uuid)}
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                    }}
                />
            </div>
        ) : (
            <div key={idx}>
                <img src={med.url} />
            </div>
        )
    );

    const customThumbs = () =>
        media?.map((med, idx) => (
            <img
                key={idx}
                src={med.file.mimeType.startsWith(VIDEO_MIMES_START) ? getThumbnailUrl(med.file.uuid) : med.url}
            />
        ));

    return (
        <div style={{ maxHeight: '80%', textAlign: 'center', overflow: 'auto' }}>
            <Carousel
                selectedItem={selectedItem}
                showThumbs={true}
                renderThumbs={customThumbs}
                showStatus={false}
                showArrows={true}
                showIndicators={false}
                useKeyboardArrows={false}
                dynamicHeight={true}
                autoPlay={false}
                swipeable={true}
            >
                {mediaCarouselJSX}
            </Carousel>
        </div>
    );
};

export default MediaCrousel;
