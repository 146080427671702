import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    menuWrapper: {
        height: '100vh',
        width: '100%',
        background: 'white',
        zIndex: 99,
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        padding: '32px 22px 32px 22px',
    },
    logoWrapper: {
        marginBottom: 65,
    },
    closeIcon: {
        position: 'absolute',
        top: 32,
        right: 22,
        cursor: 'pointer',
    },
    hamburgerList: {
        display: 'flex',
        flexDirection: 'column',
        listStyleType: 'none',
        paddingLeft: 0,
        marginBottom: 10,
        overflow: 'auto',

        '& li': {
            fontSize: 18,
            color: '#3f5162',
            marginBottom: 20,
            cursor: 'pointer',
            '&:hover': {
                color: '#000',
            },
        },

        '& img': {
            marginRight: 20,
        },
    },
    iconsWrapper: {
        display: 'flex',
        marginBottom: 16,

        '& img': {
            marginRight: 20,
            cursor: 'pointer',
        },
    },
    policyContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridRowGap: '5px',
        gridColumnGap: '5px',
        maxWidth: 250,

        '& span': {
            fontSize: 12,
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 500,
            '&:hover': {
                color: '#000',
            },
        },
    },
    copyRight: {
        fontSize: 12,
        marginTop: 15,
        fontWeight: 500,
    },
});

export default useStyles;
