import React, { useRef } from 'react';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import IconsSingleChevronDownWhite from 'fintech/icons/IconsSingleChevronDownWhite';
import useStyle from './index.style';
import SvgIconsSingleExport from 'fintech/icons/IconsSingleExport';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Button } from '@material-ui/core';

const Index = ({ buttonText, items, alwaysFull }) => {
    const classes = useStyle();
    const buttonRef = useRef(null);
    const [isOpen, setOpen] = useState(false);

    return (
        <div className={classes.dropdownContainer} ref={buttonRef}>
            <OutsideClickHandler
                onOutsideClick={() => {
                    if (isOpen) {
                        setOpen(!isOpen);
                    }
                }}
            >
                {alwaysFull ?
                    <Button
                        className={classes.otherDataButton}
                        startIcon={<IconsSingleChevronDownWhite />}
                        onClick={() => {
                            setOpen(!isOpen);
                        }}
                    >
                        {buttonText}
                    </Button> :
                    <ShrinkingButton
                        shrinkedIcon={<IconsSingleChevronDownWhite fontSize="small" />}
                        fullIcon={<IconsSingleChevronDownWhite />}
                        onClick={() => {
                            setOpen(!isOpen);
                        }}
                        fullText={buttonText}
                    ></ShrinkingButton>
                }

                {isOpen && (
                    <ul>
                        {items.map((x, index) => (
                            <li onClick={x.onClick} key={`${index}`}>
                                <SvgIconsSingleExport />
                                <p>{x.text}</p>
                                {index < items.length - 1 && <div style={{ height: '40px' }}></div>}
                            </li>
                        ))}
                    </ul>
                )}
            </OutsideClickHandler>
        </div>
    );
};

Index.propTypes = {};

export default Index;
