import { makeStyles } from '@material-ui/core';
import {
    poppins_14px,
    poppinsBold_18px,
    poppins600_14px,
    COLOR_PRIMARY_SHADE_03,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_BASE,
} from 'fintech/components/style/common';
import { COLOR_SECONDARY_SHADE_01 } from 'fintech/components/style/common';
const useStyles = makeStyles((theme) => ({
    roleBox: {
        position: 'relative',
        width: '140px',
        height: ' 140px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        borderRadius: ' 8px',
        display: ' flex',
        alignItems: ' center',
        justifyContent: ' center',
        flexDirection: ' column',
        marginRight: ' 16px',
        [theme.breakpoints.down('sm')]: {
            flex: '0 0 calc(50% - 10px)',
            width: 'initial',
            marginBottom: '19px',
            marginRight: '19px',
            '&:nth-child(even)': {
                marginRight: '0',
            },
        },
        border: `1px solid ${COLOR_PRIMARY_SHADE_03}`,
        '&:last-child': {
            marginRight: '0',
        },
        '& > p:first-child ': {
            ...poppins_14px,
            fontWeight: 'normal',
            color: COLOR_SECONDARY_SHADE_01,
            margin: '0',
        },
        '& > p:nth-child(2)': {
            ...poppinsBold_18px,
            margin: '10px 0 0 0',
        },
        '& > p:last-child': {
            ...poppins600_14px,
            color: COLOR_PRIMARY_BASE,
            margin: '10px 0 0 0',
        },
    },
}));

export default useStyles;
