import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { CardContent, makeStyles, Typography } from '@material-ui/core';
import { COLOR_PRIMARY_BASE, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    root: {
        width: 270,
        marginRight: 20,
        height: 368,
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    cardContent: {
        padding: 0,
    },
    typoRoot: {
        ...fontStyle,
        fontSize: '18px',
        fontWeight: 'normal',
        textAlign: 'center',
        color: COLOR_PRIMARY_BASE,
    },
});

/**
 * Generic Card Component that indicates an available action.
 * Consists of an icon and a text indicating the action
 */
const InteractionCard = (props) => {
    const classes = useStyles();
    // TODO: onClick support
    const { icon, label, onClick } = props;

    return (
        <Card
            classes={{ root: classes.root }}
            onClick={() => {
                onClick();
            }}
        >
            <CardActions>{icon}</CardActions>
            <CardContent className={classes.cardContent}>
                <Typography classes={{ root: classes.typoRoot }}>{label}</Typography>
            </CardContent>
        </Card>
    );
};

export default InteractionCard;
