import React from 'react';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import IconsSingleCloseBig from 'fintech/icons/IconsSingleCloseBig';
import useStyles from '../index.style';
import { useTranslation } from 'react-i18next';

function RequestResultErrorModal({ open, setOpen, onClick }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const content = (
        <>
            <div style={{ marginTop: '16px' }}>
                {t('profile.consultants.meetingRequest.info.result.error.description')}
            </div>
        </>
    );
    return (
        <ConfirmDialog
            open={open}
            icon={
                <IconsSingleCloseBig
                    style={{ width: '62px', height: '62px', backgroundColor: '#fae6e6', borderRadius: '50%' }}
                />
            }
            title={t('profile.consultants.meetingRequest.info.result.error.title')}
            labelConfirm={t('profile.consultants.meetingRequest.info.result.error.buttonText')}
            content={content}
            dialogRootClass={classes.dialogRoot}
            confirmDialogContentClass={classes.confirmDialogContentFailed}
            onConfirm={onClick}
            onCancel={() => {
                setOpen(false);
            }}
            hideCancelButton
            showTopCloseButton
        />
    );
}

export default RequestResultErrorModal;
