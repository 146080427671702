import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SUBMIT, REJECT } from 'fintech/components/util/ActionModalConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import ProgramActionModal from './ActionModal';
import ActionModal from 'fintech/components/util/ActionModal';
import { laptopLarge } from 'fintech/components/style/common';
import { useMediaQuery } from '@material-ui/core';
import * as FintechBeService from 'fintech/api/fintechService';
import * as constants from 'fintech/components/util/ActionModalConstants';
import format from 'date-fns/format/index';
import parse from 'date-fns/parse';
import { fetchSupportProgramList } from 'fintech/store/actions/supportProgram';
import { ISO_FORMAT, NUMERIC_FULL_DATE } from 'fintech/utils/DateUtils';
import { setActiveRequest } from 'fintech/store/actions/common';

const SupportProgramEditModal = (props) => {
    const { t } = useTranslation('supportProgram');
    const { enqueueSnackbar } = useSnackbar();
    const { keycloak } = useKeycloak();
    const { open, onSubmit, onCancel, recordId } = props;
    const dispatch = useDispatch();

    const { hasActiveRequest } = useSelector((state) => state.common);
    const supportProgram = useSelector((state) => state.supportProgram);
    const fetchedData = getInitialFormStateFromRedux(supportProgram, recordId);
    const [formData, setFormData] = useState(fetchedData);

    const matches = useMediaQuery(laptopLarge);
    const modalSize = matches ? { minWidth: '700px', minHeight: '690px' } : {};

    useEffect(() => {
        setFormData(fetchedData);
    }, [recordId]);

    const emptyField = {
        value: null,
        validated: false,
    };

    const emptyState = {
        title: {
            ...emptyField,
        },
        description: {
            ...emptyField,
        },
        lastApplicationDate: {
            ...emptyField,
        },
        committeeLastReviewDate: {
            ...emptyField,
        },
        startDate: {
            ...emptyField,
        },
        endDate: {
            ...emptyField,
        },
        sending: false,
    };

    const formDataChangeHandler = ({ type, data }) => {
        setFormData((prevState) => {
            switch (type) {
                case constants.SUPPORT_PROGRAM_TITLE_UPDATE:
                    return {
                        ...prevState,
                        title: {
                            ...prevState.title,
                            ...data,
                        },
                    };
                case constants.SUPPORT_PROGRAM_DESC_UPDATE:
                    return {
                        ...prevState,
                        description: {
                            ...prevState.description,
                            ...data,
                        },
                    };
                case constants.SUPPORT_PROGRAM_LAST_APPLICATION_DATE_UPDATE:
                    return {
                        ...prevState,
                        lastApplicationDate: data,
                    };
                case constants.SUPPORT_PROGRAM_LAST_REVIEW_DATE_UPDATE:
                    return {
                        ...prevState,
                        committeeLastReviewDate: data,
                    };
                case constants.SUPPORT_PROGRAM_START_DATE_UPDATE:
                    return {
                        ...prevState,
                        startDate: data,
                    };
                case constants.SUPPORT_PROGRAM_END_DATE_UPDATE:
                    return {
                        ...prevState,
                        endDate: data,
                    };
                default:
                    return prevState;
            }
        });
    };

    const editModalActionHandler = (action) => {
        const { type } = action;

        if (type === SUBMIT) {
            updateData();
        } else if (type === REJECT) {
            rejectData();
        } else {
            return;
        }
    };

    const updateData = () => {
        setFormData((prevState) => ({
            ...prevState,
            sending: true,
        }));

        if (!validateForm()) {
            return;
        }
        dispatch(setActiveRequest(true));

        const requestData = {
            id: formData.id,
            title: formData.title.value,
            description: formData.description.value,
            lastApplicationDate: formData.lastApplicationDate.value,
            committeeLastReviewDate: formData.committeeLastReviewDate.value,
            startDate: formData.startDate.value,
            endDate: formData.endDate.value,
        };

        FintechBeService.updateSupportProgram(keycloak, requestData)
            .then(() => {
                dispatch(setActiveRequest(false));
                onCancel();
                dispatch(fetchSupportProgramList(keycloak));
                enqueueSnackbar(recordId ? t('snack.updateSuccess') : t('snack.createSuccess'), { variant: 'success' });
                setFormData(emptyState);
                onSubmit();
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(
                    reason?.message ? reason.message : recordId ? t('snack.updateFail') : t('snack.createFail'),
                    { variant: 'error' }
                );
            });
    };

    const rejectData = () => {
        setFormData(
            !recordId ? getInitialFormStateFromRedux() : getInitialFormStateFromRedux(supportProgram, recordId)
        );
        onCancel();
    };

    const validateForm = () =>
        formData.title.validated &&
        formData.description.validated &&
        formData.lastApplicationDate.validated &&
        formData.committeeLastReviewDate.validated &&
        formData.startDate.validated &&
        formData.endDate.validated;

    const titleMsgId = recordId ? 'titleEdit' : 'titleAdd';
    return (
        <ActionModal
            open={open}
            title={t(`form.${titleMsgId}`)}
            onAction={editModalActionHandler}
            modalSize={modalSize}
            okButtonLabel={t('form.okBtnLabel')}
            okClickDisabled={hasActiveRequest}
            cancelClickDisabled={hasActiveRequest}
            showSpinnerWhenDisabled={hasActiveRequest}
        >
            <ProgramActionModal
                {...formData}
                editing={recordId}
                sending={formData.sending}
                changeCallback={formDataChangeHandler}
            />
        </ActionModal>
    );
};

export default SupportProgramEditModal;

const getInitialFormStateFromRedux = (supportProgram, id) => {
    const initialState = {};
    const programs = supportProgram?.programs;
    const { current, past } = programs ? programs : {};
    const prog = current && past ? [...current, ...past].find((el) => el.id === id) : {};
    const { title, description, lastApplicationDate, committeeLastReviewDate, startDate, endDate } = prog ? prog : {};

    initialState.id = id;

    initialState.title = {
        value: title,
        validated: title ? true : false,
    };

    initialState.description = {
        value: description,
        validated: description ? true : false,
    };

    initialState.lastApplicationDate = {
        value: lastApplicationDate
            ? format(parse(lastApplicationDate, NUMERIC_FULL_DATE, new Date()), ISO_FORMAT)
            : null,
        validated: lastApplicationDate ? true : false,
    };

    initialState.committeeLastReviewDate = {
        value: committeeLastReviewDate
            ? format(parse(committeeLastReviewDate, NUMERIC_FULL_DATE, new Date()), ISO_FORMAT)
            : null,
        validated: committeeLastReviewDate ? true : false,
    };

    initialState.startDate = {
        value: startDate ? format(parse(startDate, NUMERIC_FULL_DATE, new Date()), ISO_FORMAT) : null,
        validated: startDate ? true : false,
    };

    initialState.endDate = {
        value: endDate ? format(parse(endDate, NUMERIC_FULL_DATE, new Date()), ISO_FORMAT) : null,
        validated: endDate ? true : false,
    };

    initialState.sending = false;

    return initialState;
};
