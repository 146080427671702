import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';

import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import PersonForm from 'fintech/components/applications/form/common/PersonForm';
import * as constants from 'fintech/components/util/ApplicationFormConstants';
import { MentorApplicationActions } from 'fintech/store/actions/ActionTypes';
import { fetchCountries, fetchEducationLevels, fetchGenders } from 'fintech/store/actions/application-mentor';
import useGeneralInfoStyles from './index.style';
import useStyles from 'fintech/components/applications/form/investor/InvestorNRepForm/index.style';
import { CAPTCH_API_KEY_INVIS } from 'fintech/constants/auth';
import { getLocale } from 'fintech/utils/LanguageUtils';
import { postRequest } from 'fintech/api/api';
import { MENTOR_APPLICATION } from 'fintech/constants/apis';
import { loadingSpinnerWithTextGeneralTextStyle, mobileMediaQuery } from 'fintech/components/style/common';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const PersonalInfoForm = (props) => {
    const { t } = useTranslation('application');
    const { switchTabHandler } = props;
    const { keycloak } = useKeycloak();
    const classes = useStyles();
    const matches = useMediaQuery(mobileMediaQuery);
    const generalInfoClasses = useGeneralInfoStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { options, inviteFetch, ...form } = useSelector((state) => state.mentorApplication);
    const { hasActiveRequest } = useSelector((state) => state.common);
    const { countries, genders, educationLevels } = options;
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

    // ReCaptcha - Start
    const recaptchaRef = useRef();

    const asyncOnLoad = () => {
        setRecaptchaLoaded(true);
    };

    // ReCaptcha - End

    useEffect(() => {
        dispatch(fetchCountries(keycloak));
        dispatch(fetchGenders(keycloak));
        dispatch(fetchEducationLevels(keycloak));
    }, []);

    const formChangeHandler = ({ type, data }) => {
        switch (type) {
            case constants.PERSON_FORM_NAME_UPDATE:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_NAME, data });
                break;
            case constants.PERSON_FORM_SURNAME_UPDATE:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_SURNAME, data });
                break;
            // For now only Turkey selected as a country for now
            // Later, when multiple countries could be selected from system, this action will be used
            // case constants.PERSON_FORM_COUNTRY_UPDATE:
            //     dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_COUNTRY, data });
            //     break;
            case constants.PERSON_FORM_IDENTITY_UPDATE:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_IDENTITY, data });
                break;
            case constants.PERSON_FORM_GENDER_UPDATE:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_GENDER, data });
                break;
            case constants.PERSON_FORM_BIRTHDATE_UPDATE:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_BIRTHDATE, data });
                break;
            case constants.PERSON_FORM_EMAIL_UPDATE:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_EMAIL, data });
                break;
            case constants.PERSON_FORM_PHONE_NUMBER_UPDATE:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_PHONE_NUMBER, data });
                break;
            case constants.PERSON_FORM_EXPERIENCE_UPDATE:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_EXPERIENCE, data });
                break;
            case constants.PERSON_FORM_EDUCATION_UPDATE:
                dispatch({ type: MentorApplicationActions.SET_MENTOR_APPLICATION_MENTOR_EDUCATION, data });
                break;
        }
    };

    const showError = (error) => {
        dispatch(setActiveRequest(false));
        enqueueSnackbar(error?.message || t('idea.updateError'), { variant: 'error' });
    };

    const clickContinueHandler = async () => {
        const {
            name,
            surname,
            country,
            idNumber,
            gender,
            birthDate,
            email,
            phoneNumber,
            experience,
            education,
            trafficSource,
        } = form;
        dispatch(setActiveRequest(true));

        const requestData = {};
        requestData.name = name?.value;
        requestData.surname = surname?.value;
        requestData.countryCode = country?.value?.id;
        requestData.tckn = idNumber?.value;
        requestData.gender = gender?.value?.id;
        requestData.birthDate = birthDate?.value;
        requestData.email = email?.value;
        requestData.gsmNo = phoneNumber?.value?.number;
        requestData.expertiseYear = +experience?.value;
        requestData.educationInfo = {
            id: education?.value?.id,
        };
        requestData.trafficSource = trafficSource;

        let res = null;
        try {
            res = await postRequest(MENTOR_APPLICATION.fetchApplyFirstStepPreRecaptcha(), keycloak, requestData);
        } catch (reason) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        if (!res || !res.data) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        // Trigger captcha
        try {
            const token = await recaptchaRef?.current?.executeAsync();
            dispatch(setActiveRequest(false));
            if (!token) {
                enqueueSnackbar(t('validation.reCaptcha'), { variant: 'error' });
                return;
            }
        } catch (e) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(t('validation.reCaptcha'), { variant: 'error' });
            return;
        }

        postRequest(MENTOR_APPLICATION.fetchApplyFirstStepPostRecaptcha(), keycloak, requestData)
            .then((res) => {
                if (!res || !res.data) {
                    showError(res);
                } else {
                    switchTabHandler(true);
                }
            })
            .catch((reason) => {
                showError(reason);
            });
    };

    const validate = () => {
        const { name, surname, country, idNumber, gender, birthDate, email, phoneNumber, experience, education } = form;

        return (
            name?.validated &&
            surname?.validated &&
            country?.validated &&
            idNumber?.validated &&
            gender?.validated &&
            birthDate?.validated &&
            email?.validated &&
            phoneNumber?.validated &&
            experience?.validated &&
            education?.validated
        );
    };

    const isLoading = countries?.isLoading || genders.isLoading || educationLevels.isLoading;

    return isLoading ? (
        <LoadingSpinnerWithText style={loadingSpinnerWithTextGeneralTextStyle} />
    ) : (
        <>
            <CfoFormContainer>
                {matches && (
                    <Grid item xs={12}>
                        <Typography className={generalInfoClasses.titleTypo}>
                            {t('member.tabs.personalInfo')}
                        </Typography>
                    </Grid>
                )}
                <CfoFormInput className={classes.personFormWrapper} xs={12} lg={12}>
                    <PersonForm
                        changeCallback={formChangeHandler}
                        selectOptions={{ countries, genders, educationLevels }}
                        initFormData={{ ...form }}
                        hideJobTitle
                        refresh
                        trackForRefresh={inviteFetch}
                    />
                </CfoFormInput>
                <CfoFormInput xs={12} lg={12} className={classes.singlepplyBtnWrapper}>
                    <Button
                        className={classes.applyBtn}
                        disabled={hasActiveRequest || !validate() || !recaptchaLoaded}
                        variant="contained"
                        onClick={clickContinueHandler}
                    >
                        {!hasActiveRequest ? t('common.form.label.continue') : <ButtonCircularIndicator />}
                    </Button>
                </CfoFormInput>
            </CfoFormContainer>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={CAPTCH_API_KEY_INVIS}
                asyncScriptOnLoad={asyncOnLoad}
                hl={getLocale()}
                onChange={() => recaptchaRef?.current?.reset()}
            />
        </>
    );
};

export default PersonalInfoForm;
