import React, { useState } from 'react';
import Tooltip from 'fintech/components/ui/tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const PopperWrapper = (props) => {
    const { showPopper = true, content } = props;
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return showPopper ? (
        <ClickAwayListener onClickAway={handleClose}>
            <Tooltip
                title={content}
                aria-label={content}
                onClose={handleClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <div onMouseEnter={handleOpen} onMouseLeave={handleClose}>
                    {props.children}
                </div>
            </Tooltip>
        </ClickAwayListener>
    ) : (
        props.children
    );
};

export default PopperWrapper;
