import useStyles from 'fintech/components/profile/user/VenturesGrid/Venture/index.style';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Empty from 'fintech/components/util/Empty';
import { withTranslation } from 'react-i18next';
import InvestorItem from 'fintech/components/profile/user/InvestorsGrid/InvestorItem/InvestorItem';
import { fetchUserRelatedInvestors } from 'fintech/store/actions/user';

const InvestorsGrid = (props) => {
    const { t, userUuid, onTagExpand } = props;
    const classes = useStyles();
    const { keycloak } = useKeycloak();

    const { data: fetchedData, isLoading, isError } = useSelector((state) => state.user.relatedInvestors);
    const [investorsData, setInvestorsData] = useState(fetchedData);
    const dispatch = useDispatch();

    let hasData = fetchedData.length > 0;
    let investorsJSX = null;
    let emptyProps = {};
    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.relatedInvestors.errorMessage');
    } else if (hasData) {
        investorsJSX = (
            <Grid
                className={classes.UserVentureCard}
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
            >
                {investorsData &&
                    investorsData.map((investor) => (
                        <InvestorItem key={investor.uuid} {...investor} onTagExpand={onTagExpand} />
                    ))}
            </Grid>
        );
    } else {
        emptyProps.message = t('profile.relatedInvestors.emptyMessage');
    }

    useEffect(() => {
        dispatch(fetchUserRelatedInvestors(keycloak, userUuid));
    }, []);

    useEffect(() => {
        setInvestorsData(fetchedData);
    }, [fetchedData]);

    return hasData ? investorsJSX : <Empty {...emptyProps} />;
};

export default withTranslation('profile')(InvestorsGrid);
