import React, {useEffect, useState} from 'react';

import { makeMessageBoxStyles } from "./MessageBox.style";

import AttachmentLogo from 'fintech/static/images/icons-single-add-blue.svg';
import {getSystemLanguage} from "fintech/utils/LanguageUtils";
import * as FileServerService from "fintech/api/fileService";

const MessageBox = ({ message, currentClientUuid, userProfilePictureSrc, clientProfilePictureSrc }) => {
    const messageBoxStyles = makeMessageBoxStyles();
    const selectedLanguage = getSystemLanguage();
    const messageDatetime = new Date(message.timestamp);
    const readableDate = new Intl.DateTimeFormat(selectedLanguage, { dateStyle: 'short', timeStyle: 'short' }).format(messageDatetime);

    return (
        <>
            {/* If current User is sender */}
            {(message.receiverUuid === currentClientUuid) ? (
                <div className={messageBoxStyles.boxUser}>
                    <div className={messageBoxStyles.info}>
                        <img src={userProfilePictureSrc} className={messageBoxStyles.messageOwnerImage} />
                        <span>{readableDate}</span>
                    </div>
                    {message.attachmentFileDto ? (
                        <div className={messageBoxStyles.fileContent}>
                            <p className={messageBoxStyles.textFieldUser} onClick={() => FileServerService.downloadFile(message.attachmentFileDto.uuid, message.attachmentFileDto.fileName)}>{message.content}</p>
                        </div>
                    ) : (
                        <div className={messageBoxStyles.content}>
                            <p className={messageBoxStyles.textFieldUser}>{message.content}</p>
                        </div>
                    )}

                </div>
            ) : (
                <div className={messageBoxStyles.boxClient}>
                    <div className={messageBoxStyles.info}>
                        <img src={clientProfilePictureSrc} className={messageBoxStyles.messageOwnerImage} />
                        <span>{readableDate}</span>
                    </div>
                    {message.attachmentFileDto ? (
                        <div className={messageBoxStyles.fileContent}>
                            <p className={messageBoxStyles.textFieldClient} onClick={() => FileServerService.downloadFile(message.attachmentFileDto.uuid, message.attachmentFileDto.fileName)}>{message.content}</p>
                        </div>
                    ) : (
                        <div className={messageBoxStyles.content}>
                            <p className={messageBoxStyles.textFieldClient}>{message.content}</p>
                        </div>
                    )}
                </div>
            )}
        </>
    )
};

export default MessageBox;