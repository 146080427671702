import * as React from 'react';

function SvgIconsSinglePpt(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#3F5162" fillRule="nonzero">
                    <path d="M18.75 21c.532 0 .964-.432.964-.964V8.464c0-.532-.311-1.275-.683-1.647l-3.134-3.134C15.526 3.311 14.782 3 14.25 3h-9c-.532 0-.964.432-.964.964v16.072c0 .532.432.964.964.964h13.5zm-.321-1.286H5.57V4.286h7.715v4.178c0 .533.432.965.964.965h4.179v10.285zm-.08-11.571H14.57V4.366c.171.06.342.15.412.221l3.144 3.144c.07.07.161.241.221.412zm-6.6 10.286v-1.065h-.934v-1.678h1.376c.432 0 .824-.02 1.185-.15.904-.312 1.467-1.246 1.467-2.34 0-1.096-.543-1.94-1.376-2.29-.382-.152-.844-.192-1.306-.192H8.464v1.075h.924v5.575h-.924v1.065h3.285zm.261-3.878h-1.195V11.86h1.205c.352 0 .623.06.834.181.361.211.562.623.562 1.155 0 .563-.2.995-.622 1.206-.211.1-.473.15-.784.15z" />
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSinglePpt;
