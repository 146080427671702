import React from 'react';
import { Grid, Typography, ListItemText, Button, List, ListItem, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import useStyles from './index.style';
import { StyledListItemIcon } from 'fintech/components/ui/button/SplitButton/index.style';
import SvgIconsSingleArrowRight from 'fintech/icons/IconsSingleArrowRight';
import { COLOR_PRIMARY_BASE, mobileMediaQuery } from 'fintech/components/style/common';
import { useHistory, useParams } from 'react-router';
import { SUPPORT_PROGRAM_APPLICATION_SECURE } from 'fintech/routes';
import { useSnackbar } from 'notistack';
import { useKeycloak } from '@react-keycloak/web';
import { ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE } from 'fintech/components/util/RoleConstants';
import { hasAnyAuthorities } from 'fintech/utils/Auth';

const ProgramProcessGrid = () => {
    const classes = useStyles();
    const { t } = useTranslation('supportProgram');
    const tabs = [{ label: t('process.label') }];
    const matches = useMediaQuery(mobileMediaQuery);
    const { id } = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { keycloak } = useKeycloak();

    const onApplyButtonClick = () => {
        const notAllowed = hasAnyAuthorities(keycloak, [ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE]);
        if (notAllowed) {
            enqueueSnackbar(t('snack.notAllowedToApply'), { variant: 'error' });
        } else {
            history.push(`${SUPPORT_PROGRAM_APPLICATION_SECURE}/${id}`);
        }
    };

    return (
        <>
            <NavTabs value={0} tabs={tabs} />
            <Grid className={classes.processGrid} container direction="row" justify="space-between" alignItems="center">
                <Grid
                    item
                    xs={12}
                    md={8}
                    container
                    direction="row"
                    justify={matches ? 'flex-start' : 'center'}
                    alignItems="center"
                >
                    <List>
                        <ListItem>
                            <StyledListItemIcon style={{ marginRight: '10px' }}>
                                <SvgIconsSingleArrowRight />
                            </StyledListItemIcon>
                            <ListItemText>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: t('process.step1', { color: COLOR_PRIMARY_BASE, totalCount: 43 }),
                                    }}
                                />
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <StyledListItemIcon style={{ marginRight: '10px' }}>
                                <SvgIconsSingleArrowRight />
                            </StyledListItemIcon>
                            <ListItemText>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: t('process.step2', {
                                            color: COLOR_PRIMARY_BASE,
                                            totalCount: 43,
                                            optionalCount: 18,
                                        }),
                                    }}
                                />
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <StyledListItemIcon style={{ marginRight: '10px' }}>
                                <SvgIconsSingleArrowRight />
                            </StyledListItemIcon>
                            <ListItemText>
                                <Typography>{t('process.step3')}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <StyledListItemIcon style={{ marginRight: '10px' }}>
                                <SvgIconsSingleArrowRight />
                            </StyledListItemIcon>
                            <ListItemText>
                                <Typography>{t('process.step4')}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={4} container justify={matches ? 'flex-end' : 'center'}>
                    <Button className={classes.applyButton} onClick={onApplyButtonClick}>
                        {t('process.start')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default ProgramProcessGrid;
