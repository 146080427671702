import React, { useState } from 'react';
import { Avatar, Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { keys } from 'lodash';
import {
    styledTagInCardStyle,
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    COLOR_THEME_FILL_01,
    splitButtonTagsInCardGroupStyle,
} from 'fintech/components/style/common';
import PlaceHoldersPerson from 'fintech/static/images/placeholder-kisi-rounded.png';
import SplitButton from 'fintech/components/ui/button/SplitButton';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import useStyles from './index.style';

// consultancy default logo
import grantLogo from 'fintech/static/images/consultancy/hibe-tesvik.svg';
import lawLogo from 'fintech/static/images/consultancy/hukuk-regulasyon.svg';
import businessDevelopment from 'fintech/static/images/consultancy/is-gelistirme-ve-pazarlama.svg';
import companyLogo from 'fintech/static/images/consultancy/sirket-kurulusu.svg';
import taxLogo from 'fintech/static/images/consultancy/surdurulebilir-vergi-yonetimi.svg';
import investmentLogo from 'fintech/static/images/consultancy/yatirim-yonetimi.svg';

const MentorCard = (props) => {
    const {
        isAnonymous,
        cardType = 'mentorSearch',
        uuid,
        name,
        logo,
        verticals,
        title,
        expertise,
        personUrl,
        preLogin = false,
    } = props;
    const { t } = useTranslation('mentorSearch');
    const initStyles = {
        headerColor: COLOR_PRIMARY_SHADE_02,
        btnColor: COLOR_THEME_FILL_01,
    };
    const [hoverStyles, setHoverStyles] = useState(initStyles);
    const classes = useStyles(hoverStyles);

    const history = useHistory();

    const onMouseEnterHandler = () =>
        setHoverStyles({
            headerColor: COLOR_PRIMARY_SHADE_03,
            btnColor: COLOR_PRIMARY_BASE,
        });

    const onMouseLeaveHandler = () => setHoverStyles(initStyles);

    const onClickHandler = () => {
        if (isAnonymous) {
            return;
        }

        history.push(`${personUrl}/${uuid}`);
    };

    let info = '';

    if (cardType === 'mentorSearch') {
        info = keys(verticals)?.map((vert, idx) => (
            <SplitButton
                groupStyle={splitButtonTagsInCardGroupStyle}
                stopPropagation
                key={idx}
                option={vert}
                subOptions={verticals[vert]}
            />
        ));
    } else if (cardType === 'consultantSearch' && expertise) {
        info = <SplitButton groupStyle={splitButtonTagsInCardGroupStyle} stopPropagation option={expertise.name} />;
    }

    const handleConsultanyAvatar = () => {
        const { id } = expertise;
        switch (id) {
            case 1:
                return lawLogo;
            case 2:
                return grantLogo;
            case 3:
                return taxLogo;
            case 4:
                return companyLogo;
            case 5:
                return investmentLogo;
            case 6:
                return businessDevelopment;
            default:
                return PlaceHoldersPerson;
        }
    };

    return (
        <Card
            className={isAnonymous ? classes.CardRootAnonymous : classes.CardRoot}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            onClick={onClickHandler}
            style={{ minHeight: !preLogin ? '332px' : '200px' }}
        >
            {!preLogin ? (
                <>
                    <CardContent className={classes.CardHeaderBg}>
                        <Avatar className={classes.CardAvatar} alt={logo?.title} src={logo?.url}>
                            <Avatar
                                className={classes.CardAvatar}
                                style={{ marginTop: '0' }}
                                alt={'mentor-logo'}
                                src={PlaceHoldersPerson}
                            />
                        </Avatar>
                    </CardContent>
                    <CardContent className={classes.CardContentContainer}>
                        <div style={{ height: '102px' }}>
                            <Typography className={classes.MentorNameTypo}>{name}</Typography>
                            {isAnonymous ? (
                                <div></div>
                            ) : (
                                <CardActions>
                                    <Button className={classes.ProfileBtn} onClick={onClickHandler}>
                                        {t('dataGrid.viewProfile')}
                                    </Button>
                                </CardActions>
                            )}
                        </div>

                        <Typography className={classes.VerticalsTitle}>{title}</Typography>
                        <div style={{ minHeight: '55px' }}>
                            <TagGroup
                                stopPropagation
                                isGridItem
                                limit={1}
                                items={info}
                                clickableStyledTagStyle={styledTagInCardStyle}
                            />
                        </div>
                    </CardContent>
                </>
            ) : (
                <>
                    <CardContent className={classes.CardHeaderBg}>
                        <Avatar
                            className={classes.CardAvatar}
                            alt={logo?.title}
                            src={logo?.url}
                            style={{ background: '#F1F5F7' }}
                        >
                            <Avatar
                                className={classes.CardAvatar}
                                style={{ marginTop: '0' }}
                                alt="logo"
                                src={handleConsultanyAvatar()}
                            />
                        </Avatar>
                    </CardContent>
                    <CardContent className={classes.CardContentContainer}>
                        <div style={{ height: '102px' }}>
                            <Typography className={classes.MentorNameTypo}>{name}</Typography>
                            {isAnonymous ? (
                                <div></div>
                            ) : (
                                <CardActions>
                                    <Button className={classes.ProfileBtn} onClick={onClickHandler}>
                                        {t('dataGrid.viewProfile')}
                                    </Button>
                                </CardActions>
                            )}
                        </div>

                        <Typography className={classes.VerticalsTitle}>{title}</Typography>
                        <div style={{ minHeight: '55px' }}>
                            <TagGroup
                                stopPropagation
                                isGridItem
                                limit={1}
                                items={info}
                                clickableStyledTagStyle={styledTagInCardStyle}
                            />
                        </div>
                    </CardContent>
                </>
            )}
        </Card>
    );
};

export default MentorCard;
