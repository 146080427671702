import React, { useState, useEffect } from 'react';
import InformationModal from 'fintech/components/util/InformationModal';
import IdeaStartupCard from 'fintech/components/profile/idea/actionmodal/switchcard/component/IdeaStartupCard';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { getPublicFileUrl } from 'fintech/api/fileService';
import { useHistory } from 'react-router-dom';
import IconsSingleTooltip from 'fintech/icons/IconsSingleTooltip';
import IconsSingleChevronRight from 'fintech/icons/IconsSingleChevronRight';
import { findObject, indexOf } from 'fintech/utils/ArrayUtils';
import { ATTEMPT, ESTABLISHMENT } from 'fintech/routes';
import { useTranslation } from 'react-i18next';
import { LogoAvatar } from 'fintech/components/profile/startup/Intro/Header/LogoAvatar';
import placeholderStartup from 'fintech/static/images/placeholders-startup3x-rounded.png';
import placeholderIdea from 'fintech/static/images/placeholders-idea3x-rounded.png';
import { STATES } from 'fintech/store/actions/ActionTypes';
import placeholderInvestor from 'fintech/static/images/placeholders-investor3x-rounded.png';

function SwitchActionModal({ open, setOpen, fetchedData, title, subTitle, footerTitle, type }) {
    const history = useHistory();
    const { t } = useTranslation();
    const [selectedId, setSelectedId] = useState(location.pathname.split('/')[3]);
    if (selectedId != location.pathname.split('/')[3]) {
        setSelectedId(location.pathname.split('/')[3]);
    }

    const changeCallback = (id) => {
        if (type === STATES.ESTABLISHMENT) {
            history.push(`${ESTABLISHMENT}/${id}/`);
        } else {
            history.push(`${ATTEMPT}/${id}/`);
        }
        setOpen(false);
    };

    const handleAction = (data) => {
        if (data.type == 'close') {
            setOpen(false);
        }
    };

    const getLogoUrl = (data) => {
        let placeholder;
        if (type === STATES.ESTABLISHMENT) {
            placeholder = placeholderInvestor;
        } else {
            placeholder = placeholderStartup;
        }

        return data?.logoFile ? getPublicFileUrl(data.logoFile.uuid) : placeholder;
    };

    useEffect(() => {
        if (fetchedData.length > 0 && selectedId) {
            const selectedIndex = indexOf(fetchedData, findObject(fetchedData, 'uuid', selectedId));
            if (selectedIndex !== -1) {
                const tmp = fetchedData[selectedIndex];
                fetchedData[selectedIndex] = fetchedData[0];
                fetchedData[0] = tmp;
            }
        }
    }, [selectedId]);

    return (
        <div className={'startupIdeaSwitchContainer'}>
            <InformationModal open={open} title={title} subTitle={subTitle} handleAction={(data) => handleAction(data)}>
                {fetchedData && fetchedData.length > 0 && (
                    <>
                        <div className={'carouselWrapper'}>
                            <CarouselProvider
                                visibleSlides={3}
                                naturalSlideWidth={1}
                                naturalSlideHeight={1.25}
                                totalSlides={fetchedData.length}
                                className={'carousel-container margin-bottom-45'}
                            >
                                <Slider className={'sliderContainer'}>
                                    {fetchedData.map((current) => (
                                        <Slide key={current.uuid}>
                                            <IdeaStartupCard
                                                key={current?.uuid}
                                                clickCallback={(id) => changeCallback(id)}
                                                id={current?.uuid}
                                                name={current?.name}
                                                logoUrl={getLogoUrl(current)}
                                                selectedId={selectedId}
                                            />
                                        </Slide>
                                    ))}
                                </Slider>
                                <ButtonBack className={'backButton sliderButton'}>
                                    <IconsSingleChevronRight />
                                </ButtonBack>
                                <ButtonNext className={'nextButton sliderButton'}>
                                    <IconsSingleChevronRight />
                                </ButtonNext>
                            </CarouselProvider>
                            <div className={'poppin-12px margin-bottom-45 margin-right-20 text-align-center'}>
                                <IconsSingleTooltip />
                                <span className={'footerInfoText'}>{footerTitle}</span>
                            </div>
                        </div>
                        <div className={'mobileSwitchModalWrapper'}>
                            <div className={'mobileFooterInfoView'}>
                                <IconsSingleTooltip />
                                <div className={'footerInfoText'}>{footerTitle}</div>
                            </div>
                            <div className={'mobileDataView'}>
                                {fetchedData.map((current) => {
                                    const isSelected = selectedId == current.uuid;
                                    let itemClasName = 'mobileDataItem';
                                    if (isSelected) {
                                        itemClasName += ' mobileItemSelected';
                                    }
                                    return (
                                        <div
                                            key={current.uuid}
                                            className={itemClasName}
                                            onClick={() => changeCallback(current.uuid)}
                                        >
                                            <div className={'mobileLogoDiv'}>
                                                <LogoAvatar
                                                    placeholder={
                                                        type === 'establishment' ? placeholderIdea : placeholderStartup
                                                    }
                                                    userUuid={current?.uuid}
                                                    pictureUuid={current?.logoFile?.uuid}
                                                />
                                            </div>
                                            <div className={'mobileTextDiv'}>
                                                <div className={'mobileNameDiv'}>{current.name}</div>
                                                {isSelected && (
                                                    <div className={'selectedLabelMobile'}>
                                                        <span>{t('idea.interactionModal.selected')}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                )}
            </InformationModal>
        </div>
    );
}

export default SwitchActionModal;
