import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { REJECT, SUBMIT } from 'fintech/components/util/ActionModalConstants';
import ActionModal from 'fintech/components/util/ActionModal';
import { useMediaQuery } from '@material-ui/core';
import { laptopLarge } from 'fintech/components/style/common';
import NewUserActionModal from './ActionModal';
import * as FintechBeService from 'fintech/api/fintechService';
import * as constants from 'fintech/components/util/ActionModalConstants';
import { useEffect } from 'react';
import {
    fetchCountries,
    fetchFieldsOfExpertise,
    fetchGenders,
    fetchGridData,
    fetchRoles,
} from 'fintech/store/actions/userSearch';
import { format } from 'date-fns';
import { ISO_FORMAT } from 'fintech/utils/DateUtils';
import { ROLE_COUNSELOR } from 'fintech/components/util/RoleConstants';
import { setActiveRequest } from 'fintech/store/actions/common';

const NewUserModal = (props) => {
    const { open, onCancel } = props;
    const { t } = useTranslation('userSearch');
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const errorCallback = (msg = t('idea.updateError')) => enqueueSnackbar(msg, { variant: 'error' });
    const dispatch = useDispatch();
    const { hasActiveRequest } = useSelector((state) => state.common);
    const {
        roles: fetchedRoles,
        countries: fetchedCountries,
        genders: fetchedGenders,
        expertise: fetchedExpertise,
    } = useSelector((state) => state.userSearch.form);

    const matches = useMediaQuery(laptopLarge);
    const modalSize = matches ? { minWidth: '700px', minHeight: '690px' } : {};

    const initialState = {
        role: {
            value: '',
            validated: false,
        },
        name: {
            value: '',
            validated: false,
        },
        surname: {
            value: '',
            validated: false,
        },
        country: [],
        idNumber: {
            value: '',
            validated: false,
        },
        gender: {
            value: '',
            validated: false,
        },
        birthDate: {
            value: '',
            validated: true,
        },
        email: {
            value: '',
            validated: false,
        },
        expertise: [],
        sending: false,
    };

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        dispatch(fetchRoles(keycloak));
        dispatch(fetchGenders(keycloak));
        dispatch(fetchCountries(keycloak));
        dispatch(fetchFieldsOfExpertise(keycloak));
    }, []);

    const formDataChangeHandler = ({ type, data }) => {
        setFormData((prevState) => {
            switch (type) {
                case constants.USER_SEARCH_USER_TYPE_UPDATE:
                    return {
                        ...prevState,
                        role: data,
                    };
                case constants.USER_SEARCH_NAME_UPDATE:
                    return {
                        ...prevState,
                        name: data,
                    };
                case constants.USER_SEARCH_SURNAME_UPDATE:
                    return {
                        ...prevState,
                        surname: data,
                    };
                case constants.USER_SEARCH_COUNTRY_UPDATE:
                    return {
                        ...prevState,
                        country: data.country,
                        countryValid: data.countryValid,
                    };
                case constants.USER_SEARCH_IDENTITY_UPDATE:
                    return {
                        ...prevState,
                        idNumber: data,
                    };
                case constants.USER_SEARCH_GENDER_UPDATE:
                    return {
                        ...prevState,
                        gender: data,
                    };
                case constants.USER_SEARCH_BIRTHDATE_UPDATE:
                    return {
                        ...prevState,
                        birthDate: data,
                    };
                case constants.USER_SEARCH_EMAIL_UPDATE:
                    return {
                        ...prevState,
                        email: data,
                    };
                case constants.USER_SEARCH_EXPERTISE_UPDATE:
                    return {
                        ...prevState,
                        expertise: data.expertise,
                        expertiseValid: data.expertiseValid,
                    };
                default:
                    return prevState;
            }
        });
    };

    const editModalActionHandler = (action) => {
        const { type } = action;

        if (type === SUBMIT) {
            updateData();
        } else if (type === REJECT) {
            rejectData();
        } else {
            return;
        }
    };

    const updateData = () => {
        setFormData((prevState) => ({
            ...prevState,
            sending: true,
        }));

        if (!validateForm()) {
            return;
        }

        dispatch(setActiveRequest(true));

        const requestData = {
            name: formData.name.value,
            surname: formData.surname.value,
            country: formData.country,
            identityNumber: formData.idNumber.value,
            gender: formData.gender.value.id,
            birthDate: formData.birthDate.value,
            email: formData.email.value,
            role: formData.role.value,
            expertise: formData.expertise[0],
        };

        FintechBeService.addNewUser(keycloak, requestData)
            .then(() => {
                dispatch(setActiveRequest(false));
                dispatch(fetchGridData({ keycloak, errorCallback }));
                onCancel();
                enqueueSnackbar(t('idea.addSuccess'), { variant: 'success' });
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                const msg = reason?.message ? reason.message : t('idea.updateError');
                enqueueSnackbar(
                    <div
                        dangerouslySetInnerHTML={{
                            __html: msg,
                        }}
                    />,
                    { variant: 'error' }
                );
            });
    };

    const rejectData = () => {
        setFormData(initialState);
        onCancel();
    };

    const validateForm = () => {
        const withoutExpertise =
            formData.role.validated &&
            formData.name.validated &&
            formData.surname.validated &&
            formData.idNumber.validated &&
            formData.gender.validated &&
            formData.birthDate.validated &&
            formData.email.validated &&
            formData.countryValid;

        // Don't include expertise if user type is not a consultant
        return formData.role.value && formData.role.value.roleUniqueId === ROLE_COUNSELOR
            ? withoutExpertise && formData.expertiseValid
            : withoutExpertise;
    };

    return (
        <ActionModal
            open={open}
            title={t('form.addUserTitle')}
            onAction={editModalActionHandler}
            modalSize={modalSize}
            okClickDisabled={
                hasActiveRequest ||
                fetchedRoles.isError ||
                fetchedCountries.isError ||
                fetchedGenders.isError ||
                fetchedExpertise.isError
            }
            cancelClickDisabled={hasActiveRequest}
            showSpinnerWhenDisabled={hasActiveRequest}
        >
            <NewUserActionModal
                initFormData={formData}
                sending={formData.sending}
                changeCallback={formDataChangeHandler}
            />
        </ActionModal>
    );
};

export default NewUserModal;
