import { makeStyles } from '@material-ui/core';
import { COLOR_THEME_FILL_01, COLOR_SECONDARY_SHADE_01, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    rectangle: {
        padding: '30px 88px 33px',
        borderRadius: '8px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        backgroundColor: COLOR_THEME_FILL_01,
    },
    stickyContainer: {
        position: 'sticky',
        top: '78px',
        marginBottom: '16px',
        zIndex: '2',
    },
    navigateArrow: { color: COLOR_SECONDARY_SHADE_01 },
    mobilePageIndicator: {
        marginBottom: '8px',
        padding: '32px 46px 22px 26px',
        backgroundColor: COLOR_THEME_FILL_01,
        textAlign: 'center',
    },
    pageIndicatorTypo: {
        ...fontStyle,
        fontSize: '24px',
        fontWeight: 600,
    },
    breadcrumbsOl: {
        justifyContent: 'center',
    },
    breadcrumbsSeperator: {
        margin: 0,
    },
});

export default useStyles;
