import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        gap: '10px',
        [theme.breakpoints.down('1024')]: {
            flexDirection: 'column',
        },
        '& > *': {
            marginTop: '8px',
        },
    },
}));

export default useStyles;
