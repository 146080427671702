import { useEffect } from 'react';
import { isNil } from 'lodash';

const useHomePageClick = ({ legalClickListener }) => {
    useEffect(() => {
        const homePageSpan = document.getElementById('error-homepage-redirect');

        const onClickListener = (e) => {
            legalClickListener(e);
        };

        if (!isNil(homePageSpan)) {
            homePageSpan.addEventListener('click', onClickListener);
        }

        // Removes Event Listeners
        return () => {
            if (!isNil(homePageSpan)) {
                homePageSpan.removeEventListener('click', onClickListener);
            }
        };
    });
};

export default useHomePageClick;
