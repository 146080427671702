import { makeStyles } from '@material-ui/core';
import {
    poppins500_14px,
    COLOR_PRIMARY_SHADE_02,
    COLOR_THEME_FILL_04,
    COLOR_THEME_FILL_03,
    COLOR_PRIMARY_SHADE_03,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    availableSlot: {
        border: '1px solid',
        padding: '3px 21px 3px 10px',
        borderColor: COLOR_PRIMARY_SHADE_02,
    },
    unavailableSlot: {
        border: '1px solid',
        padding: '3px 21px 3px 10px',
        borderColor: COLOR_THEME_FILL_03,
        backgroundColor: COLOR_THEME_FILL_04,
        color: COLOR_THEME_FILL_03,
    },
    disabledSlot: {
        border: '1px solid',
        color: COLOR_PRIMARY_SHADE_03,
        borderColor: COLOR_PRIMARY_SHADE_03,
        width: '144.33px',
        height: '32px',
        padding: '3px 21px 3px 28px',
        cursor: 'default',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        ...poppins500_14px,
        maxWidth: '350px',
        borderRadius: '8px',
        padding: '10px 18px 10px 17px',
    },
}));

export default useStyles;
