import React from 'react';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import { DetailContent, DetailTitle } from 'fintech/components/membership/Applications/index.style';
import RepresentativeInfos from 'fintech/components/membership/Sections/RepresentativeInfos';
import { STARTUP } from 'fintech/components/membership/ApplicationTypes';
import { useTranslation } from 'react-i18next';

const getStartupSections = (titles, infos) => {
    return [
        { title: titles.startupInfos, component: <StartupInfos infos={infos} /> },
        { title: titles.representativeInfos, component: <RepresentativeInfos infos={infos} /> },
    ];
};

export default getStartupSections;

const StartupInfos = ({ infos }) => {
    const { t } = useTranslation('membershipManagement');

    const itemsJSX = [];
    const isIndividual = infos.applicationType === STARTUP.INDIVIDUAL;

    itemsJSX.push(
        <IntroGridItem
            key="startupName"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.startup.name')}</DetailTitle>}
            content={<DetailContent>{infos.companyName || ''}</DetailContent>}
        />
    );

    itemsJSX.push(
        <IntroGridItem
            key="startupTitle"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.startup.title')}</DetailTitle>}
            content={<DetailContent>{infos.commercialTitle || ''}</DetailContent>}
        />
    );

    itemsJSX.push(
        <IntroGridItem
            key="isStartupIndividual"
            htmlBind={false}
            title={
                <DetailTitle>
                    {isIndividual
                        ? t('applicationDetails.sections.startup.companyIdentityNumber.individiual')
                        : t('applicationDetails.sections.startup.companyIdentityNumber.other')}
                </DetailTitle>
            }
            content={<DetailContent>{infos.companyIdentityNumber || ''}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="startupWebsite"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.startup.website')}</DetailTitle>}
            content={<DetailContent>{infos.website || ''}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="startupEmail"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.startup.email')}</DetailTitle>}
            content={<DetailContent>{infos.companyEmail || ''}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="startupPreliminaryInfo"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.startup.preliminaryInfo')}</DetailTitle>}
            content={<DetailContent>{infos.coverLetter || ''}</DetailContent>}
        />
    );

    return itemsJSX;
};
