import { makeStyles } from '@material-ui/core';
import { poppins600_10px, poppins500_12px } from 'fintech/components/style/common';
import { COLOR_THEME_FILL_01, COLOR_PRIMARY_SHADE_02, COLOR_PRIMARY_SHADE_03 } from 'fintech/components/style/common';
const useStyles = makeStyles(() => ({
    table: {
        width: '100%',
        backgroundColor: COLOR_THEME_FILL_01,
        padding: '3px',
        borderRadius: '8px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        '& > table': {
            width: '100%',
            '& > thead > tr': {
                '& > th': {
                    height: '38px',
                    backgroundColor: COLOR_PRIMARY_SHADE_02,
                    paddingLeft: '18px',
                    borderRight: `1px solid ${COLOR_PRIMARY_SHADE_03}`,
                    ...poppins600_10px,
                    width: '200px',
                    '&:last-child': { width: 'auto' },
                    '&:first-child': { width: '50px' },
                },
            },
            '& > tbody > tr': {
                borderBottom: `1px solid ${COLOR_PRIMARY_SHADE_02}`,
                '&:last-child': {
                    border: 'none',
                },
                '& > td': {
                    height: '48px',
                    paddingLeft: '18px',
                    ...poppins500_12px,
                    '&:first-child': {
                        paddingLeft: 0,
                        textAlign: 'center',
                    },
                },
            },
        },
        '& .sticky-table-table': {
            width: '100%',
        }
    },
}));

export default useStyles;
