import { getRequest } from 'fintech/api/api';
import { EventActions } from 'fintech/store/actions/ActionTypes';

const setEvent = (event) => ({
    type: EventActions.SET_EVENT,
    events: event,
});

export const setViewedEvent = (viewedEvent) => ({
    type: EventActions.SET_VIEWED_EVENT,
    viewedEvent: viewedEvent,
});

const setOtherEvents = (otherEvents) => ({
    type: EventActions.SET_OTHER_EVENTS,
    otherEvents: otherEvents,
});

const setLoading = () => ({ type: EventActions.SET_EVENT_LOADING });

const setError = () => ({ type: EventActions.SET_EVENT_ERROR });

export const fetchEvents = (keycloak, isEventsActive) => {
    return (dispatch) => {
        dispatch(setLoading());
        const url = isEventsActive ? 'api/public/event/latest-active' : 'api/event/latest-finished';
        getRequest(url, keycloak)
            .then((payload) => dispatch(setEvent(payload.data)))
            .catch(() => {
                dispatch(setError());
            });
    };
};

//Other events for showing the latest n event at the event details page
export const fetchOtherEvents = (keycloak, number) => {
    return (dispatch) => {
        dispatch(setLoading());
        const url = 'api/event/latest';
        getRequest(url, keycloak, { count: number })
            .then((payload) => dispatch(setOtherEvents(payload.data)))
            .catch(() => {
                dispatch(setError());
            });
    };
};

//Fetch the viewed event in case of page refresh, otherwise the event can be retrieved from the event
export const fetchViewedEvent = (keycloak, number) => {
    return (dispatch) => {
        dispatch(setLoading());
        const url = 'api/event/' + number;
        getRequest(url, keycloak)
            .then((payload) => dispatch(setViewedEvent(payload.data)))
            .catch(() => {
                dispatch(setError());
            });
    };
};
