import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    item: {
        padding: '20px 24px 8px',
        '& > div': {
            width: '100%',
        },
    },
}));

export default useStyles;
