import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import InteractionGrid from 'fintech/components/profile/startup/Team/TeamMember/Interaction';
import StartupInvestmentInfoGrid from 'fintech/components/profile/startup/Financials/Investor/Investment';
import InvestmentLogoGrid from 'fintech/components/profile/startup/Financials/Investor/Logo';
import InvestorEditModal from 'fintech/components/profile/startup/form/financials/InvestorEditModal';
import useStyles from './index.style';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import { InfoIcon } from 'fintech/components/profile/common';
import { setActiveRequest } from 'fintech/store/actions/common';

const StartupInvestor = (props) => {
    const classes = useStyles();
    const {
        id,
        editable,
        hideAll,
        logo,
        companyTitle,
        companyIsPublic,
        date,
        dateIsPublic,
        investment,
        investmentIsPublic,
        actions,
        services,
        type,
        gridStyle,
        editDialogContent,
    } = props;

    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const { uuid, hasActiveRequest } = useSelector((state) => ({ ...state[type].intro.data, ...state.common }));
    const [editing, setEditing] = useState(false);
    const [deleting, setDeleteState] = useState(false);
    const dispatch = useDispatch();

    const showInvestment = editable || (!hideAll && investmentIsPublic);
    const showCompany = editable || (!hideAll && companyIsPublic);
    const showDate = editable || (!hideAll && dateIsPublic);

    const [openModal, setOpenModal] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const clickEditHandler = () => {
        setEditing(true);
        setOpenModal(true);
    };
    const cancelModalHandler = () => {
        setOpenModal(false);
        setEditing(false);
    };

    const clickDeleteHandler = () => setDeleteState(true);

    const dialogCancelHandler = () => setDeleteState(false);

    const dialogConfirmedHandler = () => {
        if (uuid && id) {
            dispatch(setActiveRequest(true));
            services
                .deleteInvestmentInfo(keycloak, id, uuid)
                .then(() => {
                    dispatch(setActiveRequest(false));
                    dispatch(actions.fetchInvestmentsData(keycloak, uuid));
                    enqueueSnackbar(t('profile.financials.form.deleteSuccess'), { variant: 'success' });
                })
                .catch((reason) => {
                    dispatch(setActiveRequest(false));
                    enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
                });
        } else {
            enqueueSnackbar(t('idea.updateError'), { variant: 'error' });
        }
    };

    return (
        <>
            <Grid
                item
                className={classes.StartupInvestor}
                container
                direction="row-reverse"
                justify="space-between"
                alignItems="center"
            >
                <InteractionGrid
                    breakPoints={{ xs: 12, md: 3 }}
                    editable={editable}
                    onClickEdit={clickEditHandler}
                    onClickDelete={clickDeleteHandler}
                />
                <Grid item xs={12} md={9} style={gridStyle || { maxWidth: '1000px' }}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <InvestmentLogoGrid
                            breakPoints={{ xs: 12, lg: 5 }}
                            logo={logo}
                            companyTitle={companyTitle}
                            companyIsPublic={companyIsPublic && !hideAll}
                            showCompany={showCompany}
                        />

                        <StartupInvestmentInfoGrid
                            breakPoints={{ xs: 12, lg: 7 }}
                            investment={investment}
                            investmentIsPublic={investmentIsPublic && !hideAll}
                            showInvestment={showInvestment}
                            date={date}
                            dateIsPublic={dateIsPublic && !hideAll}
                            showDate={showDate}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {editable && (
                <InvestorEditModal
                    type={type}
                    editing={editing}
                    recordId={id}
                    open={openModal}
                    onCancel={cancelModalHandler}
                    actions={actions}
                    services={services}
                />
            )}
            {editable && (
                <ConfirmDialog
                    open={deleting}
                    icon={<InfoIcon fontSize="large" />}
                    title={t('dialog.confirmTitle')}
                    injectHtml
                    content={editDialogContent}
                    labelCancel={t('dialog.cancelAction')}
                    labelConfirm={t('dialog.confirmAction')}
                    onCancel={dialogCancelHandler}
                    onConfirm={dialogConfirmedHandler}
                    confirmDisabled={hasActiveRequest}
                    cancelDisabled={hasActiveRequest}
                    showSpinnerWhenDisabled={hasActiveRequest}
                />
            )}
        </>
    );
};

export default StartupInvestor;
