import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import MultipleSelect from 'fintech/components/ui/form/select/MultipleSelect';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import DropdownError from './DropdownError';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';

export default function TechnologyUpdate({
    noLabel,
    label,
    technologies,
    changeCallback,
    preventValidation = false,
    multipleSelect = true,
    formIsSubmitted,
    dontSort,
    usePlaceholder = false,
    validateOnBlur = false,
    validatedAfterFormSubmit = true,
    className = 'full-width',
    noBottomMargin = false,
    isLoading = false,
    isError = false,
}) {
    const { t } = useTranslation();

    if (!label) {
        label = t('idea.technology');
    }

    const [technologyData, setTechnologyData] = useState(technologies || []);
    if (technologies && technologyData !== technologies) {
        setTechnologyData(technologies);
    }
    const [valid, setValid] = useState(false);
    const [selectionIsChanged, setSelectionIsChanged] = useState(false);

    const technologyId = 'technology';

    const technologyChange = (val, valid) => {
        setTechnologyData(val && val.length > 0 ? val : []);
        setValid(valid);
        changeCallback(val && val.length > 0 ? val : [], valid);
    };

    const handleOnSelectionChange = () => {
        setSelectionIsChanged(true);
    };

    const changedOrSubmitted = formIsSubmitted || selectionIsChanged;

    let renderedJSX = null;
    if (isLoading) {
        renderedJSX = (
            <div className={className} style={{ marginBottom: !noBottomMargin && '30px', textAlign: 'center' }}>
                <LoadingSpinner />
            </div>
        );
    } else if (isError) {
        renderedJSX = (
            <div className={className}>
                <DropdownError noBottomMargin={noBottomMargin} />
            </div>
        );
    } else {
        renderedJSX = (
            <div className={className}>
                {technologyData && technologyData.length > 0 && (
                    <Grid container direction={'row'}>
                        <Grid xs={12} item className={`full-width ${!noBottomMargin ? 'margin-bottom-30' : ''}`}>
                            <MultipleSelect
                                label={!noLabel && !usePlaceholder ? label : ''}
                                placeholder={
                                    usePlaceholder && isEmpty(filterObjectList(technologyData, 'selected', [true]))
                                        ? label
                                        : ''
                                }
                                callBack={(data, valid) => technologyChange(data, valid)}
                                id={technologyId}
                                value={technologyData}
                                multipleSelect={multipleSelect}
                                helperText={t('validation.notEmpty')}
                                preventValidation={preventValidation || !changedOrSubmitted}
                                onSelectionChange={handleOnSelectionChange}
                                dontSort={dontSort}
                                validateOnBlur={validateOnBlur}
                                validatedAfterFormSubmit={validatedAfterFormSubmit}
                            />
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }

    return renderedJSX;
}
