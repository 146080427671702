import * as React from 'react';

function SvgIconsSingleTooltip(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <defs>
                <filter id="icons-single-tooltip_svg__a">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g filter="url(#icons-single-tooltip_svg__a)" fill="none" fillRule="evenodd">
                <path
                    fill="#3F5162"
                    fillRule="nonzero"
                    d="M12 19.188a7.364 7.364 0 003.798-1.026 7.097 7.097 0 002.664-2.664 7.364 7.364 0 001.026-3.798 7.364 7.364 0 00-1.026-3.798 7.097 7.097 0 00-2.664-2.664A7.364 7.364 0 0012 4.212a7.331 7.331 0 00-3.78 1.026A7.37 7.37 0 005.538 7.92a7.331 7.331 0 00-1.026 3.78c0 1.356.342 2.616 1.026 3.78a7.231 7.231 0 002.664 2.682A7.364 7.364 0 0012 19.188zm0-1.476a5.851 5.851 0 01-3.024-.828 5.83 5.83 0 01-2.16-2.16c-.552-.936-.828-1.944-.828-3.024s.276-2.088.828-3.024a5.83 5.83 0 012.16-2.16c.936-.552 1.944-.828 3.024-.828s2.088.276 3.024.828a5.83 5.83 0 012.16 2.16c.552.936.828 1.944.828 3.024s-.276 2.088-.828 3.024a5.83 5.83 0 01-2.16 2.16 5.851 5.851 0 01-3.024.828zm.738-8.262V7.938h-1.476V9.45h1.476zm0 6.012v-4.5h-1.476v4.5h1.476z"
                />
            </g>
        </svg>
    );
}

export default SvgIconsSingleTooltip;
