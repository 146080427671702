import Button from '@material-ui/core/Button';
import React from 'react';
import useStyles from 'fintech/components/profile/startup/GeneralInfo/startupmentors/mentoravatar/MentorAvatar.style';
import placeholderPerson from 'fintech/static/images/placeholder-kisi-rounded.png';
import { withTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import { getPublicFileUrl } from 'fintech/api/fileService';
import { Typography } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { USER_PROFILE } from 'fintech/routes';

/**
 * Mentor Avatar Component
 */
const MentorAvatar = (props) => {
    const classes = useStyles();
    const { t, uuid, name, surname, profilePictureFile } = props;
    const history = useHistory();

    const getProfileImage = () => {
        return profilePictureFile ? getPublicFileUrl(profilePictureFile?.uuid) : placeholderPerson;
    };

    const goMentorsProfile = () => {
        history.push(`${USER_PROFILE}/${uuid}`);
    };

    return (
        <div className={classes.mentorAvatarContainer}>
            <Avatar src={getProfileImage()} className={classes.mentorAvatar}>
                <Avatar src={placeholderPerson} />
            </Avatar>
            <Typography className={classes.mentorName}>{`${name} ${surname}`}</Typography>
            <Button size="small" className={classes.mentorButton} onClick={() => goMentorsProfile()}>
                <Visibility style={{ marginRight: '4px' }} fontSize={'small'} />
                {t('profile.startup.goMentorProfile')}
            </Button>
        </div>
    );
};

export default withTranslation('profile')(MentorAvatar);
