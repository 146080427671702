export const CURRENT = 'current';
export const PAST = 'past';

export const ApplicationStatus = {
    PENDING: 'PENDING',
    RESULTED: 'RESULTED',
};
export const SupportApplicationReviewedUserType = {
    COMMITTEE_MEMBER: 'COMMITTEE_MEMBER',
    ADMIN: 'ADMIN',
};

export const StatusTypes = {
    PRESELECTION: 'PRESELECTION',
    COMMITTEE_QUALIFYING: 'COMMITTEE_QUALIFYING',
    IN_THE_INTERVIEW_PROCESS: 'IN_THE_INTERVIEW_PROCESS',
};

export const SupportApplicationApprovalStatus = {
    APPROVED: 'APPROVED',
    DENIED: 'DENIED',
    NOTSEEN: 'NOTSEEN',
};
