import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_SECONDARY_SHADE_01,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        marginTop: 20,
        textAlign: 'center',
    },
    footerDivider: {
        backgroundColor: COLOR_PRIMARY_SHADE_03,
        width: '100%',
        marginTop: 40,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    logo: {
        width: 100,
        top: -36,
        position: 'relative',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: -20,
        [theme.breakpoints.down('sm')]: {
            top: 0,
            marginBottom: 0,
        },
    },
    footerAddressText: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
        },
    },
    footerText: {
        color: COLOR_SECONDARY_SHADE_01,
        fontSize: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 8,
        },
    },
}));

export default useStyles;
