import { Box } from '@material-ui/core';
import React from 'react';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import AnnouncementGrid from 'fintech/components/dashboard/AnnouncementGrid';
import PageTemplate from 'fintech/components/PageTemplate';
import EventGridContainer from 'fintech/components/dashboard/event/EventGridContainer';

export default function CenteredGrid() {
    return (
        <PageTemplate>
            <PageHeaderBox />
            <EventGridContainer />
            <Box height="32px" />
            <AnnouncementGrid />
        </PageTemplate>
    );
}
