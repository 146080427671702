import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';

import commonMessagesEnglish from 'fintech/locales/common/messages-en.json';
import commonMessagesTurkish from 'fintech/locales/common/messages-tr.json';
import eventEnglish from 'fintech/locales/event/event-en.json';
import eventTurkish from 'fintech/locales/event/event-tr.json';
import preLoginTurkish from 'fintech/locales/pre-login/pre-login-tr.json';
import preLoginEnglish from 'fintech/locales/pre-login/pre-login-en.json';
import entrepreneurEnglish from 'fintech/locales/entrepreneur/entrepreneur-en.json';
import entrepreneurTurkish from 'fintech/locales/entrepreneur/entrepreneur-tr.json';
import investorSearchEn from 'fintech/locales/investor/investorSearch-en.json';
import investorSearchTr from 'fintech/locales/investor/investorSearch-tr.json';
import mentorSearchEn from 'fintech/locales/mentor/mentorSearch-en.json';
import mentorSearchTr from 'fintech/locales/mentor/mentorSearch-tr.json';
import userSearchEn from 'fintech/locales/user/userSearch-en.json';
import userSearchTr from 'fintech/locales/user/userSearch-tr.json';
import inviteSearchEn from 'fintech/locales/invite/inviteSearch-en.json';
import inviteSearchTr from 'fintech/locales/invite/inviteSearch-tr.json';
import supportProgramEn from 'fintech/locales/supportPrograms/supportProgram-en.json';
import supportProgramTr from 'fintech/locales/supportPrograms/supportProgram-tr.json';
import supportProgramApplicationEn from 'fintech/locales/supportProgramApplication/supportProgramApplication-en.json';
import supportProgramApplicationTr from 'fintech/locales/supportProgramApplication/supportProgramApplication-tr.json';
import consultantSearchTr from 'fintech/locales/consultant/consultantSearch-tr.json';
import consultantSearchEn from 'fintech/locales/consultant/consultantSearch-en.json';
import policyTextTr from 'fintech/locales/policy/policy-tr.json';
import policyTextEn from 'fintech/locales/policy/policy-en.json';
import membershipManagementTr from 'fintech/locales/membershipManagement/membershipManagement-tr.json';
import membershipManagementEn from 'fintech/locales/membershipManagement/membershipManagement-en.json';
import applicationTr from 'fintech/locales/application/application-tr.json';
import applicationEn from 'fintech/locales/application/application-en.json';
import errorTr from 'fintech/locales/error/error-tr.json';
import errorEn from 'fintech/locales/error/error-en.json';
import settingsEn from 'fintech/locales/settings/settings-en.json';
import settingsTr from 'fintech/locales/settings/settings-tr.json';
import inboxEn from 'fintech/locales/inbox/inbox-en.json';
import inboxTr from 'fintech/locales/inbox/inbox-tr.json';
import reportsEn from 'fintech/locales/reports/reports-en.json';
import reportsTR from 'fintech/locales/reports/reports-tr.json';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                common: commonMessagesEnglish,
                event: eventEnglish,
                preLogin: preLoginEnglish,
                entrepreneur: entrepreneurEnglish,
                investorSearch: investorSearchEn,
                mentorSearch: mentorSearchEn,
                userSearch: userSearchEn,
                inviteSearch: inviteSearchEn,
                supportProgram: supportProgramEn,
                consultantSearch: consultantSearchEn,
                policy: policyTextEn,
                membershipManagement: membershipManagementEn,
                application: applicationEn,
                supportProgramApplication: supportProgramApplicationEn,
                error: errorEn,
                settings: settingsEn,
                inbox: inboxEn,
                reports: reportsEn,
            },
            tr: {
                common: commonMessagesTurkish,
                event: eventTurkish,
                preLogin: preLoginTurkish,
                entrepreneur: entrepreneurTurkish,
                investorSearch: investorSearchTr,
                mentorSearch: mentorSearchTr,
                userSearch: userSearchTr,
                inviteSearch: inviteSearchTr,
                supportProgram: supportProgramTr,
                consultantSearch: consultantSearchTr,
                policy: policyTextTr,
                membershipManagement: membershipManagementTr,
                application: applicationTr,
                supportProgramApplication: supportProgramApplicationTr,
                error: errorTr,
                settings: settingsTr,
                inbox: inboxTr,
                reports: reportsTR,
            },
        },
        interpolation: { escapeValue: false }, // React already does escaping
        fallbackNS: 'common',
        lng: getSystemLanguage(),
        fallbackLng: 'en',
        whitelist: ['en', 'tr'],
    });

document.documentElement.setAttribute('lang', getSystemLanguage());

export default i18n;
