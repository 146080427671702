import * as React from 'react';

function SvgIconsMultipleDanisman(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#D9DCE0" d="M3 23.625h4.5V13.5H3z" />
                <path
                    fill="#D9DCE0"
                    d="M9 14.625V6a1.5 1.5 0 00-1.5-1.5H3A1.5 1.5 0 001.5 6v8.625H3V13.5h4.5v1.125H9z"
                />
                <path fill="#3F5162" d="M4.875 23.625h.75v-8.25h-.75zm0-16.875h.75V6h-.75zM3 13.875h4.5v-.75H3z" />
                <path fill="#3F5162" d="M2.625 23.625h.75V6.75h-.75zm4.5 0h.75V6.75h-.75z" />
                <path fill="#FFF" d="M6.75 1.875a1.5 1.5 0 11-3.001-.001 1.5 1.5 0 013.001.001" />
                <path
                    fill="#3F5162"
                    d="M5.25.75c-.62 0-1.125.505-1.125 1.125S4.63 3 5.25 3s1.125-.505 1.125-1.125S5.87.75 5.25.75m0 3c-1.034 0-1.875-.841-1.875-1.875S4.216 0 5.25 0s1.875.841 1.875 1.875S6.284 3.75 5.25 3.75M0 24h10.5v-.75H0z"
                />
                <path fill="#D9DCE0" d="M13.5 21h9v-6h-9z" />
                <path fill="#3F5162" d="M22.5 18h-9v-3h.75v2.25h7.5V15h.75z" />
                <path
                    fill="#3F5162"
                    d="M15 18.75h.75v-1.125H15zm5.25 0H21v-1.125h-.75zm0-3.75h-.75v-.75h-3V15h-.75v-1.5h4.5zm-9.75 6.75H24V21H10.5z"
                />
                <path fill="#3F5162" d="M11.25 21.375H12V0h-.75z" />
                <path fill="#D9DCE0" d="M21.75 6.75H12v-6h9.75l-2.25 3z" />
                <path fill="#3F5162" d="M11.25 24H12v-.75h-.75zM9 21.75h.75V21H9z" />
                <path fill="#FFF" d="M15.375 5.03l-1.39-1.39.53-.53.86.86 1.61-1.61.53.53z" />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleDanisman;
