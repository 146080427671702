// Invite status constants
export const INVITED = 'INVITED';
export const INVITE_SUCCESS = 'E1061';
export const INVITE_CANCELED = 'E1186';
export const INVITE_EXPIRED = 'E1060';

// -- INVESTOR APPLICATION
// Investor types
export const INVESTOR_INDIVIDUAL = 'INVESTOR_INDIVIDUAL';
export const INVESTOR_INSTITUTIONAL = 'INVESTOR_INSTITUTIONAL';

// Investor cover letter
export const INVESTOR_FORM_COVER_UPDATE = 'INVESTOR_FORM_COVER_UPDATE';

// Investor read agreement
export const INVESTOR_FORM_AGREEMENTS = 'INVESTOR_FORM_AGREEMENTS';

// -- STARTUP APPLICATION
// Startup types
export const STARTUP_INDIVIDUAL = 'STARTUP_INDIVIDUAL';
export const STARTUP_EQUITY = 'STARTUP_EQUITY';

// Startup cover letter
export const STARTUP_FORM_STARTUP_NAME_UPDATE = 'STARTUP_FORM_STARTUP_NAME_UPDATE';
export const STARTUP_FORM_COVER_UPDATE = 'STARTUP_FORM_COVER_UPDATE';
export const STARTUP_FORM_WEBSITE_UPDATE = 'STARTUP_FORM_WEBSITE_UPDATE';

// Startup read agreement
export const STARTUP_FORM_AGREEMENTS = 'STARTUP_FORM_AGREEMENTS';

// -- MEMBERSHIP APPLICATION
// Membership read agreement
export const MEMBER_FORM_AGREEMENTS = 'MEMBER_FORM_AGREEMENTS';

// Person form actions
export const PERSON_FORM_NAME_UPDATE = 'PERSON_FORM_NAME_UPDATE';
export const PERSON_FORM_SURNAME_UPDATE = 'PERSON_FORM_SURNAME_UPDATE';
export const PERSON_FORM_COUNTRY_UPDATE = 'PERSON_FORM_COUNTRY_UPDATE';
export const PERSON_FORM_IDENTITY_UPDATE = 'PERSON_FORM_IDENTITY_UPDATE';
export const PERSON_FORM_GENDER_UPDATE = 'PERSON_FORM_GENDER_UPDATE';
export const PERSON_FORM_BIRTHDATE_UPDATE = 'PERSON_FORM_BIRTHDATE_UPDATE';
export const PERSON_FORM_EMAIL_UPDATE = 'PERSON_FORM_EMAIL_UPDATE';
export const PERSON_FORM_PHONE_NUMBER_UPDATE = 'PERSON_FORM_PHONE_NUMBER_UPDATE';
export const PERSON_FORM_EXPERIENCE_UPDATE = 'PERSON_FORM_EXPERIENCE_UPDATE';
export const PERSON_FORM_JOB_TITLE_UPDATE = 'PERSON_FORM_JOB_TITLE_UPDATE';
export const PERSON_FORM_EDUCATION_UPDATE = 'PERSON_FORM_EDUCATION_UPDATE';

// Mentor form actions
export const MENTOR_FORM_COVER_LETTER_UPDATE = 'MENTOR_FORM_COVER_LETTER_UPDATE';
export const MENTOR_FORM_INTERESTED_VERTICAL_UPDATE = 'MENTOR_FORM_INTERESTED_VERTICAL_UPDATE';
export const MENTOR_FORM_INTERESTED_TECHNOLOGY_UPDATE = 'MENTOR_FORM_INTERESTED_TECHNOLOGY_UPDATE';
export const MENTOR_FORM_FILE_UPDATE = 'MENTOR_FORM_FILE_UPDATE';
export const MENTOR_FORM_AGREEMENTS = 'MENTOR_FORM_AGREEMENTS';
