import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import 'pure-react-carousel/dist/react-carousel.es.css';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import { fetchEvents } from 'fintech/store/actions/event';
import * as EventCardUtils from 'fintech/components/dashboard/event/eventcardgrid/EventCardUtils';
import useStylesEmpty from 'fintech/components/dashboard/event/eventcardgrid/index.style';
import useStyles from './EventGridContainer.style';
import Empty from 'fintech/components/util/Empty';
import EventCardGrid from 'fintech/components/dashboard/event/eventcardgrid/EventCardGrid';

/**
 * Event Card Grid which uses the carousel for showing the event cards
 */
const EventGridContainer = (props) => {
    const { t, isUserAuthenticated, isPreLogin } = props;
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(EventCardUtils.eventStatus.ACTIVE);
    const { keycloak } = useKeycloak();
    const { events, isLoading, isError } = useSelector((state) => state.event);
    const dispatch = useDispatch();

    const tabs = [
        { label: t('event.upcomingEvents'), value: EventCardUtils.eventStatus.ACTIVE },
        { label: t('event.finishedEvents'), value: EventCardUtils.eventStatus.FINISHED },
    ];

    const hasEvents = events && events.length > 0;

    let emptyProps = {};

    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('event.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('event.errorMessage');
    } else {
        emptyProps.message = EventCardUtils.isActiveEventsTabSelected(selectedTab)
            ? t('event.emptyMessage')
            : t('event.emptyFinishedMessage');
    }

    useEffect(() => {
        dispatch(fetchEvents(keycloak, EventCardUtils.isActiveEventsTabSelected(selectedTab)));
    }, [selectedTab]);

    const preLoginJsx = hasEvents && (
        <>
            <div className="d-flex">
                <div className={classes.rectangleDivider}></div>
                <span className={classes.eventTitle}>{t('event.upcomingEvents')}</span>
            </div>
            <div className={classes.eventGridContainer}>
                <EventCardGrid events={events} isPreLogin={isPreLogin} isUserAuthenticated={isUserAuthenticated} />
            </div>
        </>
    );

    const secureSideJsx = (
        <>
            <NavTabs
                value={EventCardUtils.eventStatus.ACTIVE}
                tabs={tabs}
                onChange={(e, value) => setSelectedTab(value)}
            />
            {hasEvents ? (
                <EventCardGrid events={events} isPreLogin={isPreLogin} isUserAuthenticated={isUserAuthenticated} />
            ) : (
                <Empty {...emptyProps} useStyles={useStylesEmpty} isPreLogin={isPreLogin} />
            )}
        </>
    );

    return isPreLogin ? preLoginJsx : secureSideJsx;
};

export default withTranslation('event')(EventGridContainer);
