import React from 'react';
import { withTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { CardMedia } from '@material-ui/core';
import useStyles from 'fintech/components/dashboard/event/eventcard/index.style';
import EventCardContent from 'fintech/components/dashboard/event/eventcardcontent/EventCardContent';
import { useHistory } from 'react-router';
import { EVENT_DETAIL } from 'fintech/routes';
import cardImage from 'fintech/static/images/event-big.png';

/**
 * Event Card Component which have information about the event that will be organized
 */
const EventCard = (props) => {
    const classes = useStyles();
    const { t, isUserAuthenticated, isPreLogin, setEventCardContent, ...eventCardContent } = props;
    const history = useHistory();
    let cardJSX;

    if (isPreLogin) {
        if (isUserAuthenticated) {
            cardJSX = (
                <>
                    <EventCardContent {...eventCardContent} isPreLogin={isPreLogin} />
                    <CardActions>
                        <Button
                            size="small"
                            className={classes.cardButtonPreLogin}
                            onClick={() => history.push(EVENT_DETAIL + '/' + eventCardContent.id)}
                        >
                            {t('event.seeDetails')}
                        </Button>
                    </CardActions>
                </>
            );
        } else {
            cardJSX = (
                <>
                    <EventCardContent {...eventCardContent} isPreLogin={isPreLogin} />
                    <CardActions>
                        <Button
                            size="small"
                            className={classes.cardButtonPreLogin}
                            onClick={() => setEventCardContent(eventCardContent)}
                        >
                            {t('event.readMore')}
                        </Button>
                    </CardActions>
                </>
            );
        }
    } else {
        cardJSX = (
            <>
                <CardMedia className={classes.media} image={cardImage} isPreLogin={isPreLogin} />
                <EventCardContent {...eventCardContent} />
                <CardActions>
                    <Button
                        size="small"
                        className={classes.cardButton}
                        onClick={() => history.push(EVENT_DETAIL + '/' + eventCardContent.id)}
                    >
                        {t('event.seeDetails')}
                    </Button>
                </CardActions>
            </>
        );
    }

    return <Card className={classes.root}>{cardJSX}</Card>;
};

export default withTranslation('event')(EventCard);
