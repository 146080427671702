import React from 'react';
import { Button } from '@material-ui/core';
import useStyles from './index.style';

const ShrinkingButton = (props) => {
    const { queryCondition = 900, shrinkedIcon, fullIcon, fullText, alwaysShrink, otherStyles = {}, ...others } = props;

    const classes = useStyles({ queryCondition: queryCondition, otherStyles: otherStyles });

    others.startIcon = fullIcon;
    const shrinkOthers = { ...others };
    delete shrinkOthers.startIcon;

    let buttonJSX = (
        <>
            <Button classes={{ root: classes.FullButtonText }} fullWidth={false} {...others}>
                {fullText}
            </Button>
            <Button classes={{ root: classes.ShrinkedButtonIcon }} {...shrinkOthers}>
                {shrinkedIcon}
            </Button>
        </>
    );

    if (alwaysShrink) {
        buttonJSX = (
            <Button classes={{ root: classes.AlwaysShrinkedButtonIcon }} {...shrinkOthers}>
                {shrinkedIcon}
            </Button>
        );
    }

    return buttonJSX;
};

export default ShrinkingButton;
