import React, { useEffect, useState } from 'react';
import { APIS } from 'fintech/constants';
import { getRequest } from 'fintech/api/api';
import { useTranslation } from 'react-i18next';
import { ArrowBack } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import FileUpload from 'fintech/components/util/FileUpload';
import { DOCUMENT_MIME_TYPES } from 'fintech/components/util/FileConstants';
import { loadingSpinnerWithTextGeneralTextStyle, mobileMediaQuery } from 'fintech/components/style/common';
import { Grid, Typography, useMediaQuery, Button } from '@material-ui/core';
import CfoDatePicker from 'fintech/components/ui/form/picker/CfoDatePicker';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import CfoFormLabel from 'fintech/components/applications/form/common/CfoFormLabel';
import { SupportProgramApplicationActions } from 'fintech/store/actions/ActionTypes';
import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import useStyles from 'fintech/components/support/program/form/SupportProgramApplication/index.style';
import SocialMediaUrlInput from 'fintech/components/support/program/form/SupportProgramApplication/SocialMediaUrlInput';
import VerticalsUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/VerticalsUpdate';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import TechnologyUpdate from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/TechnologyUpdate';
import SingleSelect from 'fintech/components/ui/form/select/SingleSelect';
import * as FintechBeService from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import {
    CATEGORY_GENERAL_INFORMATION,
    generateGeneralInfoAnswers,
    generateRepInfoData,
} from 'fintech/components/support/program/utils/SupportProgramApplicationUtils';
import { isEmpty } from 'lodash';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import { parse } from 'date-fns';
import { ISO_FORMAT } from 'fintech/utils/DateUtils';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const GeneralInformation = ({ pageFunctions: { prevPage, nextPage }, formId, secure = false }) => {
    const { t } = useTranslation('supportProgramApplication');
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const dispatch = useDispatch();
    const matches = useMediaQuery(mobileMediaQuery);

    const { form, hasActiveRequest } = useSelector((state) => ({
        ...state.supportProgramApplication,
        ...state.common,
    }));
    const questions = form.questionFormatList.slice(0, 13);

    const [sending, setSending] = useState(false);
    const [startUpStages, setStartUpStages] = useState({
        data: questions[9]?.startUpStages ? questions[9].startUpStages : [],
        isLoading: questions[9]?.startUpStages ? false : true,
        isError: false,
    });
    const [fintechVerticals, setFintechVerticals] = useState({
        data: questions[11]?.verticals ? questions[11].verticals : [],
        isLoading: questions[11]?.verticals ? false : true,
        isError: false,
    });
    const [technologies, setTechnologies] = useState({
        data: questions[12]?.technologies ? questions[12].technologies : [],
        isLoading: questions[12]?.technologies ? false : true,
        isError: false,
    });

    useEffect(() => {
        isEmpty(startUpStages.data) &&
            getRequest(APIS.SUPPORT_PROGRAM.getSourceTypes('STARTUP_STAGE'))
                .then(({ data }) => {
                    setStartUpStages((prevState) => ({
                        ...prevState,
                        data,
                        isLoading: false,
                    }));
                })
                .catch(() => {
                    setStartUpStages((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        isError: true,
                    }));
                });

        isEmpty(fintechVerticals.data) &&
            getRequest(APIS.SUPPORT_PROGRAM.getSourceTypes('FINTECH_SUB_VERTICAL'))
                .then(({ data }) => {
                    setFintechVerticals((prevState) => ({
                        ...prevState,
                        data,
                        isLoading: false,
                    }));
                })
                .catch(() => {
                    setFintechVerticals((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        isError: true,
                    }));
                });

        isEmpty(technologies.data) &&
            getRequest(APIS.SUPPORT_PROGRAM.getSourceTypes('TECHNOLOGY'))
                .then(({ data }) => {
                    setTechnologies((prevState) => ({
                        ...prevState,
                        data,
                        isLoading: false,
                    }));
                })
                .catch(() => {
                    setTechnologies((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        isError: true,
                    }));
                });
    }, []);

    const getCorrespondingInput = (question) => {
        switch (question.questionNumber) {
            case 1:
                return (
                    <InputTextArea
                        type="text"
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q01,
                                data: { value, validated },
                            });
                        }}
                        required={question.required === 'REQUIRED'}
                        count={question.answerDefinitionList[0].textLimit ?? 1000}
                        containerClass={classes.inputTextAreaWrapper}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        initText={question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''}
                    />
                );
            case 2:
                return (
                    <InputTextArea
                        type="text"
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q02,
                                data: { value, validated },
                            });
                        }}
                        required={question.required === 'REQUIRED'}
                        rowCount={5}
                        count={question.answerDefinitionList[0].textLimit ?? 300}
                        containerClass={classes.inputTextAreaWrapper}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        initText={question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''}
                    />
                );
            case 3:
                return (
                    <InputTextArea
                        type="text"
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q03,
                                data: { value, validated },
                            });
                        }}
                        required={question.required === 'REQUIRED'}
                        rowCount={5}
                        count={question.answerDefinitionList[0].textLimit ?? 1000}
                        containerClass={classes.inputTextAreaWrapper}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        initText={question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''}
                    />
                );
            case 4:
                return (
                    <InputTextArea
                        type="text"
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q04,
                                data: { value, validated },
                            });
                        }}
                        required={question.required === 'REQUIRED'}
                        rowCount={5}
                        count={question.answerDefinitionList[0].textLimit ?? 1000}
                        containerClass={classes.inputTextAreaWrapper}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        initText={question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''}
                    />
                );
            case 5:
                return (
                    <InputTextArea
                        type="text"
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q05,
                                data: { value, validated },
                            });
                        }}
                        required={question.required === 'REQUIRED'}
                        rowCount={5}
                        count={question.answerDefinitionList[0].textLimit ?? 1000}
                        containerClass={classes.inputTextAreaWrapper}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        initText={question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''}
                    />
                );
            case 6:
                return (
                    <InputTextArea
                        type="text"
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q06,
                                data: { value, validated },
                            });
                        }}
                        required={question.required === 'REQUIRED'}
                        rowCount={5}
                        count={question.answerDefinitionList[0].textLimit ?? 1000}
                        containerClass={classes.inputTextAreaWrapper}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        initText={question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''}
                    />
                );
            case 7:
                return (
                    <InputTextArea
                        type="text"
                        placeholder={t('formPlaceholder.genericPlaceholder')}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q07,
                                data: { value, validated },
                            });
                        }}
                        required={question.required === 'REQUIRED'}
                        rowCount={5}
                        count={question.answerDefinitionList[0].textLimit ?? 1000}
                        containerClass={classes.inputTextAreaWrapper}
                        validationText={t('validation.notEmpty')}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        initText={question?.freeTextAnswerList?.[0]?.value ? question.freeTextAnswerList[0].value : ''}
                    />
                );
            case 8:
                return (
                    <FileUpload
                        styleClass={classes.fileUploadWrapper}
                        featureEnabled={false}
                        hasNoHorizontalMargin
                        hasNoHorizontalMarginInner
                        fileLabel={t('formLabel.uploadedFiles')}
                        uploadCallback={(files) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q08,
                                data: { files },
                            });
                        }}
                        acceptMimeTypes={DOCUMENT_MIME_TYPES}
                        videoLimit={0}
                        imageLimit={0}
                        documentLimit={1}
                        fileLimit={1}
                        initFiles={question.fileUploadAnswerList?.[0]?.file ? [question.fileUploadAnswerList[0]] : []}
                        areDocuments
                        isPublic={!secure}
                        multiple={false}
                    />
                );
            case 9:
                return (
                    <>
                        <InputTextArea
                            type="url"
                            placeholder={t('form.website')}
                            onChange={(value, validated) => {
                                dispatch({
                                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q09_P1,
                                    data: { value, validated },
                                });
                            }}
                            required={question.required === 'REQUIRED'}
                            count={question.answerDefinitionList[0].textLimit ?? 1000}
                            hideCountIndicator
                            containerClass={classes.inputTextAreaWrapper}
                            validationText={t('validation.url')}
                            validateOnBlur
                            validatedAfterFormSubmit={!sending}
                            initText={question?._websiteUrl}
                        />
                        <SocialMediaUrlInput
                            styleClass={classes.socialMediaWrapper}
                            onChangeCb={(data) =>
                                dispatch({
                                    type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q09_P2,
                                    data,
                                })
                            }
                            value={question?._socialMediaUrls}
                        />
                    </>
                );
            case 10: {
                const initId = question.sourceSelectAnswerList?.[0].selectedId;
                const initVal = initId && startUpStages.data.find((s) => s.id === initId);
                return (
                    <SingleSelect
                        initiallyEmpty={!initVal}
                        initiallyValidated={!initVal}
                        classes={{ root: classes.singleSelectWrapper }}
                        usePlaceholder
                        placeholder={t('formPlaceholder.select')}
                        options={startUpStages.data}
                        isError={startUpStages.isError}
                        initValue={initVal}
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q10,
                                data: { value, validated, stages: startUpStages.data },
                            });
                        }}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                    />
                );
            }
            case 11:
                return (
                    <CfoDatePicker
                        placeholder={t('formPlaceholder.select')}
                        containerClass={classes.cfoDatePickerWrapper}
                        invalidDateMessage={
                            question?.dateAnswerList?.[0]?.value ? t('validation.date') : t('validation.notEmpty')
                        }
                        autoOk
                        openTo="year"
                        onChange={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q11,
                                data: { value, validated },
                            });
                        }}
                        required={question.required === 'REQUIRED'}
                        dontValidate={true}
                        disableFuture
                        initialDate={
                            question?.dateAnswerList?.[0]?.value &&
                            parse(question.dateAnswerList[0].value, ISO_FORMAT, new Date())
                        }
                    />
                );
            case 12:
                return (
                    <VerticalsUpdate
                        noBottomMargin
                        className={classes.keywordUpdateWrapper}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        usePlaceholder
                        verticals={fintechVerticals.data}
                        isError={fintechVerticals.isError}
                        changeCallback={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q12,
                                data: {
                                    value: filterObjectList(value, 'selected', [true]),
                                    verticals: value,
                                    validated,
                                },
                            });
                        }}
                    />
                );
            case 13:
                return (
                    <TechnologyUpdate
                        noBottomMargin
                        className={classes.keywordUpdateWrapper}
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        usePlaceholder
                        technologies={technologies.data}
                        isError={technologies.isError}
                        changeCallback={(value, validated) => {
                            dispatch({
                                type: SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q13,
                                data: {
                                    value: filterObjectList(value, 'selected', [true]),
                                    technologies: value,
                                    validated,
                                },
                            });
                        }}
                    />
                );
            default:
                return null;
        }
    };

    const isValid = () =>
        // Validate Q1-Q7 FREE_TEXT
        questions[0].freeTextAnswerList &&
        questions[0].freeTextAnswerList[0]?.validated &&
        questions[1].freeTextAnswerList &&
        questions[1].freeTextAnswerList[0]?.validated &&
        questions[2].freeTextAnswerList &&
        questions[2].freeTextAnswerList[0]?.validated &&
        questions[3].freeTextAnswerList &&
        questions[3].freeTextAnswerList[0]?.validated &&
        questions[4].freeTextAnswerList &&
        questions[4].freeTextAnswerList[0]?.validated &&
        (questions[5].freeTextAnswerList ? questions[5].freeTextAnswerList[0]?.validated : true) &&
        questions[6].freeTextAnswerList &&
        questions[6].freeTextAnswerList[0]?.validated &&
        // Validate Q9 MULTI_FREE_TEXT
        (isEmpty(questions[8]) || !questions[8].freeTextAnswerList?.some((nameData) => !nameData.validated)) &&
        // VALIDATE Q11 DATE_PICKER
        // questions[10].dateAnswerList &&
        // questions[10].dateAnswerList[0]?.validated &&
        // VALIDATE Q12 & Q13 COMBOBOX
        questions[11].validated &&
        questions[12].validated;

    const clickContinueHandler = async () => {
        setSending(true);

        if (isValid()) {
            dispatch(setActiveRequest(true));
            const requestData = {};
            requestData.supportApplicantInfo = generateRepInfoData(form.supportApplicantInfo);
            requestData.questionList = generateGeneralInfoAnswers(questions);

            let res = null;
            try {
                res = await FintechBeService.supportProgramApply({
                    keycloak,
                    formId,
                    requestData,
                    category: CATEGORY_GENERAL_INFORMATION,
                });
                dispatch(setActiveRequest(false));
            } catch (reason) {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            if (!res || !res.success) {
                enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
                return;
            }

            nextPage();
        } else {
            enqueueSnackbar(t('validation.genericMessage'), { variant: 'error' });
        }
    };

    const isLoading =
        form.isLoading ||
        questions.length < 1 ||
        startUpStages?.isLoading ||
        fintechVerticals.isLoading ||
        technologies.isLoading;

    return isLoading ? (
        <LoadingSpinnerWithText style={loadingSpinnerWithTextGeneralTextStyle} />
    ) : (
        <CfoFormContainer>
            {matches && <Typography className={classes.pageTitle}>{t('pageTitles.generalInformation')}</Typography>}
            {questions.map((question, index) => (
                <Grid
                    item
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    key={question.questionNumber}
                    style={{ paddingBottom: '24px' }}
                >
                    <CfoFormLabel
                        label={`${question.questionNumber}. ${question.name}`}
                        subText={question.subText}
                        isOptional={question.required === 'OPTIONAL'}
                    />
                    <CfoFormInput style={index === 7 ? { width: '0' } : {}}>
                        {getCorrespondingInput(question)}
                    </CfoFormInput>
                </Grid>
            ))}
            <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className={classes.buttonsWrapper}
            >
                <CfoFormInput xs={4} lg={3}>
                    <Button
                        startIcon={matches && <ArrowBack />}
                        className={classes.secondaryButton}
                        onClick={prevPage}
                        fullWidth
                        disabled={hasActiveRequest}
                    >
                        {matches ? t('buttonTexts.returnToPreviousStep') : t('buttonTexts.previousStep')}
                    </Button>
                </CfoFormInput>
                <CfoFormInput xs={7} lg={8}>
                    <Button
                        className={classes.primaryButton}
                        onClick={clickContinueHandler}
                        fullWidth
                        disabled={
                            hasActiveRequest ||
                            startUpStages.isError ||
                            fintechVerticals.isError ||
                            technologies.isError
                        }
                    >
                        {!hasActiveRequest ? t('buttonTexts.continue') : <ButtonCircularIndicator />}
                    </Button>
                </CfoFormInput>
            </Grid>
        </CfoFormContainer>
    );
};

export default GeneralInformation;
