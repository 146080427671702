import React, {useEffect, useState} from 'react';

import { makeChatPanelStyles } from "./ChatPanel.style";
import MessageInput from "fintech/components/chat/MessageInput";
import ConversationArea from "fintech/components/chat/ConversationArea";
import {useKeycloak} from "@react-keycloak/web";
import {useSnackbar} from "notistack";
import {getRequest} from "fintech/api/api";
import { APIS } from 'fintech/constants';
import BlankChatArea from "fintech/components/chat/BlankChatArea";

const ChatPanel = ({ currentClientUuid }) => {
    const chatPanelStyles = makeChatPanelStyles();

    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const showErrorMessage = (message) => enqueueSnackbar(message, { variant: 'error' });
    const [messages, setMessages] = useState(null);

    const fetchMessages = async () => {
        if (currentClientUuid) {
            getRequest(APIS.CHAT.getMessagesByUserAndClient(currentClientUuid), keycloak)
                .then((result) => {
                    if (result.data) {
                        setMessages(result.data);
                    }
                })
                .catch((error) => showErrorMessage(error.message));
        }
    }

    useEffect(() => {
        fetchMessages();

        const interval = setInterval(fetchMessages, 1000);
        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [currentClientUuid]);

    return <div className={chatPanelStyles.panel}>
        {messages ? (
            <>
                <ConversationArea messages={messages} currentClientUuid={currentClientUuid} />
                <MessageInput currentClientUuid={currentClientUuid} />
            </>
        ) : (
            <BlankChatArea />
        )}

    </div>;
};

export default ChatPanel;
