import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import PropType from 'prop-types';
import PageUnauthorized from 'fintech/containers/PageUnauthorized';
import { isAuthenticated, hasAnyAuthorities, doLogin } from 'fintech/utils/Auth';

import logo from 'fintech/static/images/loading-spinner.gif';

export const AuthorizedRoute = (props) => {
    const { component: Component, authorities, unauthorizedRoles = [], ...rest } = props;

    const { keycloak, initialized } = useKeycloak();
    if (!initialized) {
        return (
            <div>
                <img src={logo} alt="loading..." />
            </div>
        );
    }

    const authenticated = isAuthenticated(keycloak);
    if (!authenticated) {
        doLogin(keycloak);
        return;
    }

    const permitted = hasAnyAuthorities(keycloak, authorities);
    const unpermitted = unauthorizedRoles.length > 0 && hasAnyAuthorities(keycloak, unauthorizedRoles);
    if (!permitted || unpermitted) {
        return <Route component={PageUnauthorized} />;
    }

    return (
        <Route
            {...rest}
            render={() => (
                <React.Fragment>
                    <Component {...rest} />
                </React.Fragment>
            )}
        />
    );
};

const mapStateToProps = (state) => ({});

AuthorizedRoute.propTypes = {
    component: PropType.func.isRequired,
    authorities: PropType.arrayOf(PropType.string),
};

AuthorizedRoute.defaultProps = {
    authorities: [],
};

export default connect(mapStateToProps)(AuthorizedRoute);
