import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SUBMIT, REJECT, INVESTOR_GRID_REPRESENTATIVE_UPDATE } from 'fintech/components/util/ActionModalConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { isEmpty, isEqual } from 'lodash';
import { useMediaQuery } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ActionModal from 'fintech/components/util/ActionModal';
import { laptopLarge } from 'fintech/components/style/common';
import { fetchGridData, resetInvestorRepresentativeForm } from 'fintech/store/actions/investorSearch';
import * as FintechBeService from 'fintech/api/fintechService';
import RepresentativeActionModal from './ActionModal';
import { filterObjectList } from 'fintech/utils/ArrayUtils';
import { setActiveRequest } from 'fintech/store/actions/common';

const RepresentativeEditModal = (props) => {
    const { open, onCancel, uuid, name, type } = props;
    const { t } = useTranslation(type);
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const matches = useMediaQuery(laptopLarge);
    const modalSize = matches ? { minWidth: '700px', minHeight: '690px' } : {};

    const { currentRepresentative, hasActiveRequest } = useSelector((state) => ({
        ...state[type].form,
        ...state.common,
    }));

    const [formData, setFormData] = useState({
        repData: [],
        repValid: false,
    });

    const errorCallback = () => enqueueSnackbar(t('idea.updateError'), { variant: 'error' });

    const formDataChangeHandler = ({ type, data }) => {
        setFormData((prevState) => {
            if (type === INVESTOR_GRID_REPRESENTATIVE_UPDATE) {
                return {
                    ...prevState,
                    repData: data.repData,
                    repValid: data.repValid,
                };
            }
            return prevState;
        });
    };

    const editModalActionHandler = (action) => {
        const { type } = action;

        if (type === SUBMIT) {
            updateData();
        } else if (type === REJECT) {
            rejectData();
        } else {
            return;
        }
    };

    const updateData = () => {
        const selectedReps = filterObjectList(formData.repData, 'selected', [true]);
        if (selectedReps && !isEmpty(selectedReps) && isEqual(currentRepresentative, selectedReps[0])) {
            onCancel();
            return;
        }
        dispatch(setActiveRequest(true));

        const userInfo = formData.repData.map((el) => el.id)[0];

        FintechBeService.updateInvestorRepresentative(keycloak, userInfo, uuid)
            .then(() => {
                dispatch(setActiveRequest(false));
                dispatch(fetchGridData({ keycloak, errorCallback, useLastQueryType: true }));
                onCancel();
                enqueueSnackbar(t('form.changeRepSuccess', { name: name }), { variant: 'success' });
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            });
    };

    const rejectData = () => {
        dispatch(resetInvestorRepresentativeForm());
        onCancel();
    };

    const validateForm = () => formData.repValid;

    return (
        <ActionModal
            open={open}
            title={t('form.changeRepresentativeTitle')}
            onAction={editModalActionHandler}
            modalSize={modalSize}
            okClickDisabled={hasActiveRequest || !validateForm()}
            cancelClickDisabled={hasActiveRequest}
            showSpinnerWhenDisabled={hasActiveRequest}
        >
            <RepresentativeActionModal uuid={uuid} changeCallback={formDataChangeHandler} />
        </ActionModal>
    );
};

export default RepresentativeEditModal;
