import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Card, CardMedia, Modal } from '@material-ui/core';
import useStyles from './index.style';
import { VIDEO_MIMES_START } from 'fintech/components/util/FileConstants';
import { getVideoUrl, getThumbnailUrl } from 'fintech/api/fileService';
import ReactPlayer from 'react-player/lazy';
import CloseButton from 'fintech/icons/CloseButton';
import { getFileServerToken, getMediaUrlWithToken } from '../../../../utils/FileUtils';

const AnnouncementMedia = (props) => {
    const classes = useStyles();
    const { medias } = props;

    const [showMediaModal, setShowMediaModal] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(false);

    const showMediaModalHandler = (media) => {
        setShowMediaModal(true);
        setSelectedMedia(media);
    };

    const dismissMediaModalHandler = () => {
        setShowMediaModal(false);
    };

    const cardMediaJSX = medias?.map((media, idx) => (
        <Card key={idx} className={classes.AnnMask}>
            <CardMedia
                className={classes.AnnMaskMedia}
                image={
                    media.mimeType.startsWith(VIDEO_MIMES_START)
                        ? getThumbnailUrl(media.uuid)
                        : getMediaUrlWithToken(media.url)
                }
                title={media.fileName}
                onClick={() => {
                    showMediaModalHandler(media);
                }}
            ></CardMedia>
        </Card>
    ));

    const mediaModalJsx = selectedMedia ? (
        selectedMedia.mimeType.startsWith(VIDEO_MIMES_START) ? (
            <ReactPlayer
                className="react-player"
                light={getThumbnailUrl(selectedMedia.uuid)}
                controls
                url={getVideoUrl(selectedMedia.uuid, getFileServerToken())}
            />
        ) : (
            <img
                src={getMediaUrlWithToken(selectedMedia.url)}
                med={selectedMedia.fileName}
                style={{ width: '100%', height: '80%' }}
            />
        )
    ) : null;

    return (
        <>
            <Modal
                style={{ zIndex: '10000', textAlign: 'center', border: 'none' }}
                open={showMediaModal}
                onClose={dismissMediaModalHandler}
            >
                <div
                    className="carousel-modal-div"
                    style={{ width: 'auto', maxWidth: '80%', height: '80%', textAlign: 'end' }}
                >
                    <div style={{ marginBottom: '4px' }} onClick={dismissMediaModalHandler}>
                        <CloseButton style={{ cursor: 'pointer' }} />
                    </div>
                    {mediaModalJsx}
                </div>
            </Modal>
            <Grid container direction="row">
                {cardMediaJSX}
            </Grid>
        </>
    );
};

export default AnnouncementMedia;
