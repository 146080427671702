import { makeStyles } from '@material-ui/core';
import {
    poppins_14px,
    poppinsBold_18px,
    COLOR_PRIMARY_SHADE_03,
    COLOR_PRIMARY_SHADE_02,
} from 'fintech/components/style/common';
import { COLOR_SECONDARY_SHADE_01 } from 'fintech/components/style/common';
const useStyles = makeStyles((theme) => ({
    infoBox: {
        position: 'relative',
        width: '140px',
        height: ' 140px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        borderRadius: ' 8px',
        display: ' flex',
        alignItems: ' center',
        justifyContent: ' center',
        flexDirection: ' column',
        marginRight: ' 15px',
        border: `1px solid ${COLOR_PRIMARY_SHADE_03}`,
        padding: '12px',
        [theme.breakpoints.down('sm')]: {
            flex: '0 0 calc(50% - 10px)',
            width: 'initial',
            marginBottom: '19px',
            marginRight: '19px',
            '&:nth-child(even)': {
                marginRight: '0',
            },
        },
        '&:last-child': {
            marginRight: '0',
        },
        '& > div': {
            width: ' 21px',
            height: ' 21px',
            position: ' absolute',
            top: ' 4px',
            right: ' 4px',
        },
        '& > p:first-of-type ': {
            ...poppins_14px,
            fontWeight: 'normal',
            color: COLOR_SECONDARY_SHADE_01,
            margin: '0',
            lineHeight: 'inherit',
            textAlign: 'center',
        },
        '& > p:last-child': {
            ...poppinsBold_18px,
            margin: '17px 0 0 0',
        },
    },
}));

export default useStyles;
