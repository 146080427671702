import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_BASE } from 'fintech/components/style/common';

const useStyles = makeStyles({
    file: {
        color: COLOR_PRIMARY_BASE,
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
});

export default useStyles;
