import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { toLower, isString } from 'lodash';
import { useTranslation } from 'react-i18next';
import tr from 'react-phone-input-2/lang/tr.json';
import { LANGUAGE_CODES } from 'fintech/constants';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import { COLOR_MUI_ERROR } from 'fintech/components/style/common';

const PhoneNumberInput = ({
    phoneNumber,
    onChange,
    validateOnBlur,
    dontValidate,
    validatedAfterFormSubmit = true,
    disabled,
}) => {
    const { t } = useTranslation();
    const props = {};

    const [value, setValue] = useState(
        isString(phoneNumber) ? phoneNumber : phoneNumber?.number ? phoneNumber.number : ''
    );
    const [validated, setValidated] = useState(
        validateOnBlur
            ? true
            : isValidPhoneNumber(isString(phoneNumber) ? phoneNumber : phoneNumber?.number ? phoneNumber.number : '')
    );
    // Needed only initially
    const [country] = useState(toLower(phoneNumber?.country ? phoneNumber.country : 'TR'));

    useEffect(() => {
        if (dontValidate && validatedAfterFormSubmit) setValidated(true);
    }, [dontValidate]);

    useEffect(() => {
        if (!validatedAfterFormSubmit) {
            setValidated(isValidPhoneNumber(isString(value) ? value : value.number));
        }
    }, [validatedAfterFormSubmit]);

    // Used if the disabled value of the component is provided later
    useEffect(() => {
        disabled && setValue(isString(phoneNumber) ? phoneNumber : phoneNumber?.number ? phoneNumber.number : '');
    }, [phoneNumber]);

    const isTr = getSystemLanguage() === LANGUAGE_CODES.TR;
    if (isTr) {
        props.localization = tr;
    }

    function validate(value) {
        return isValidPhoneNumber(value);
    }

    let containerClass = '';
    if (!validated) {
        containerClass = `${containerClass} phoneNumberWrapper-error`;
    }

    if (disabled) {
        containerClass = `${containerClass} phoneNumberWrapper-disabled`;
    }

    return (
        <>
            <PhoneInput
                {...props}
                disabled={disabled}
                containerClass={containerClass}
                dropdownStyle={{
                    bottom: '60px',
                    border: '1px solid black',
                }}
                enableSearch
                country={country}
                value={value}
                specialLabel={t('profile.user.mobilePhone')}
                isValid={validated}
                onChange={(value) => {
                    const number = `+${value}`;
                    setValue(number);
                    !validateOnBlur && setValidated(validate(number));
                    onChange(parsePhoneNumber(number), isValidPhoneNumber(number));
                }}
                onBlur={
                    validateOnBlur
                        ? () => {
                              setValidated(validate(value));
                          }
                        : () => {}
                }
            />
            <div className={'d-flex align-items-start justify-content-start margin-top-3'}>
                {!validated && (
                    <Typography
                        style={!validated ? { color: COLOR_MUI_ERROR, fontSize: '12px', marginLeft: '14px' } : {}}
                    >
                        {t('profile.user.invalidPhoneNumber')}
                    </Typography>
                )}
            </div>
        </>
    );
};

export default PhoneNumberInput;
