import { makeStyles } from '@material-ui/core';

const pageTemplateCommon = {
    alignItems: 'stretch',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
};
const useStyles = makeStyles(() => ({
    pageTemplateMobile: {
        ...pageTemplateCommon,
        marginRight: -10,
        marginLeft: -10,
        minHeight: 'calc(100vh - 160px)',
    },
    pageTemplateWeb: {
        ...pageTemplateCommon,
        marginRight: 16,
        marginLeft: 16,
        minHeight: 'calc(100vh - 130px)',
    },
}));

export default useStyles;
