import { MemberApplicationActions } from 'fintech/store/actions/ActionTypes';
import { APIS } from 'fintech/constants';
import { getRequest } from 'fintech/api/api';

// Set investor info from invite
export const setMemberInfoFromInvite = ({
    name,
    surname,
    email,
    url,
    companyName,
    companyIdentityNumber,
    companyType,
    investorId,
    startupId,
    position,
}) => {
    return {
        type: MemberApplicationActions.SET_MEMBER_APPLICATION_INVITE_INFOS,
        data: {
            name,
            surname,
            email,
            url,
            companyName,
            companyIdentityNumber,
            companyType,
            investorId,
            startupId,
            title: position,
        },
    };
};

// Actions for the countries in the system for nationality choice
const setCountries = (countries) => ({
    type: MemberApplicationActions.SET_MEMBER_APPLICATION_COUNTRIES,
    countries: countries,
});

export const fetchCountries = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: MemberApplicationActions.GET_MEMBER_APPLICATION_COUNTRIES });
        getRequest(APIS.USER.getCountriesURL(), keycloak)
            .then((data) => {
                dispatch(setCountries(data));
            })
            .catch(() => {
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_COUNTRIES_LOAD_ERROR });
            });
    };
};

// Actions for genders in the system
const setGenders = (genders) => ({
    type: MemberApplicationActions.SET_MEMBER_APPLICATION_GENDERS,
    genders: genders,
});

export const fetchGenders = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: MemberApplicationActions.GET_MEMBER_APPLICATION_GENDERS });
        getRequest(APIS.USER.getGendersURL(), keycloak)
            .then((data) => {
                dispatch(setGenders(data));
            })
            .catch(() => {
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_GENDERS_LOAD_ERROR });
            });
    };
};

// Actions for the education in the system for nationality choice
const setEducationLevels = (levels) => ({
    type: MemberApplicationActions.SET_MEMBER_APPLICATION_EDUCATION_LEVELS,
    levels: levels,
});

export const fetchEducationLevels = (keycloak) => {
    return (dispatch) => {
        dispatch({ type: MemberApplicationActions.GET_MEMBER_APPLICATION_EDUCATION_LEVELS });
        getRequest(APIS.SUPPORT_PROGRAM.getEducationInfos(), keycloak)
            .then((data) => {
                dispatch(setEducationLevels(data));
            })
            .catch(() => {
                dispatch({ type: MemberApplicationActions.SET_MEMBER_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR });
            });
    };
};
