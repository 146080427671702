import React from 'react';
import { useTranslation } from 'react-i18next';

export default function IdeaStartupCard(props) {
    const { clickCallback, id, name, selectedId, ventureType, logoUrl } = props;
    const { t } = useTranslation();
    const handleClickStartup = () => {
        clickCallback(id, ventureType);
    };

    return (
        <div
            className={
                (selectedId == id ? 'selectedCard ' : 'nonSelectedCard') +
                ' full-width full-height startupCardViewContainer '
            }
            onClick={() => handleClickStartup()}
        >
            <div className={'d-flex flex-column justify-content-center  full-height'}>
                <div className={'align-items-center flex-1'}>
                    <div className={'topContainer'}>
                        <div>
                            <div className={'d-flex justify-content-center margin-bottom-12'}>
                                <img className={'logo'} src={logoUrl} />
                            </div>
                            <div className={'d-flex justify-content-center poppin-500-14px text-align-center'}>
                                {name}
                            </div>
                        </div>
                    </div>
                    <div>
                        {selectedId == id && (
                            <div className={'d-flex justify-content-center selectedLabel poppin-12px'}>
                                <span>{t('idea.interactionModal.selected')}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
