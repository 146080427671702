import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as ROUTES from 'fintech/routes';

import { AuthorizedRoute } from 'fintech/components/AuthorizedRoute';
import Home from 'fintech/pages/pre-login/Home';
import SupportProgramOnePage from './pages/pre-login/SupportProgramOnePage';
import Platform from 'fintech/components/menu/leftmenu/Platform';
import FileUploadPage from 'fintech/components/fileupload/FileUpload';
import ClarificationText from 'fintech/pages/pre-login/policies/clarification-text';
import PrivacyPolicy from 'fintech/pages/pre-login/policies/privacy-policy';
import CookiePolicy from 'fintech/pages/pre-login/policies/cookie-policy';
import CookiePolicyMobileApp from 'fintech/pages/pre-login/policies/cookie-policy-mobile-app';
import TermsOfUse from 'fintech/pages/pre-login/policies/terms-of-use';
import SupportProgramApplicationPage from 'fintech/pages/pre-login/application/SupportProgramApplicationPage';
import InvestorApplicationPage from 'fintech/pages/pre-login/application/InvestorApplicationPage';
import StartupApplicationPage from 'fintech/pages/pre-login/application/StartupApplicationPage';
import TeamMemberApplicationPage from 'fintech/pages/pre-login/application/TeamMemberApplicationPage';
import ExpiredInvitePage from 'fintech/pages/error/ExpiredInvitePage';
import NotFoundPage from 'fintech/pages/error/NotFoundPage';
import { MentorApplicationPage } from 'fintech/pages/pre-login/application/MentorApplicationPage';

const AppRouter = () => {
    return (
        <>
            <Switch>
                <Redirect exact from={ROUTES.WELCOME} to={ROUTES.HOME} />
                <Route path={ROUTES.HOME_SECTION} component={Home} />
                <Route path={ROUTES.HOME} component={Home} />
                <Route path={ROUTES.SUPPORT_PROGRAM_ONE_PAGE} component={SupportProgramOnePage} />
                <Route path={ROUTES.CLARIFICATION_TEXT} component={ClarificationText} />
                <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
                <Route path={ROUTES.COOKIE_POLICY} component={CookiePolicy} />
                <Route path={ROUTES.COOKIE_POLICY_MOBILE_APP} component={CookiePolicyMobileApp} />
                <Route path={ROUTES.TERMS_OF_USE} component={TermsOfUse} />
                <Route path={ROUTES.INVESTOR_APPLICATION_INVITE} component={InvestorApplicationPage} />
                <Route path={ROUTES.INVESTOR_APPLICATION} component={InvestorApplicationPage} />
                <Route path={ROUTES.STARTUP_APPLICATION_INVITE} component={StartupApplicationPage} />
                <Route path={ROUTES.STARTUP_APPLICATION} component={StartupApplicationPage} />
                <Route path={ROUTES.MEMBER_APPLICATION_INVITE} component={TeamMemberApplicationPage} />
                <Route path={ROUTES.MENTOR_APPLICATION} component={MentorApplicationPage} />
                <Route path={ROUTES.SUPPORT_PROGRAM_APPLICATION} component={SupportProgramApplicationPage} />
                <Route path={ROUTES.EXPIRED_INVITE} component={ExpiredInvitePage} />
                <AuthorizedRoute path={ROUTES.PLATFORM} component={Platform} />
                <AuthorizedRoute path={ROUTES.FILE_UPLOAD} authorities={['USER']} component={FileUploadPage} />
                <Route path={ROUTES.NOT_FOUND} component={NotFoundPage} />
                <Route component={NotFoundPage} />
            </Switch>
        </>
    );
};

export default AppRouter;
