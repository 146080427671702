import * as React from 'react';

function SvgIconsSingleZoom(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <path
                d="M12 21a9 9 0 11.001-18.001A9 9 0 0112 21zM6.13 9.183v4.226c0 .95.78 1.721 1.739 1.721h6.163c.174 0 .316-.14.316-.313v-4.226A1.73 1.73 0 0012.61 8.87H6.446a.315.315 0 00-.316.313zm8.61 1.649v2.344l2.543 1.855c.18.138.39.18.39-.195V9.171c0-.335-.169-.377-.39-.195l-2.544 1.855z"
                fill={props.fill ? props.fill : '#3F5162'}
                fillRule="evenodd"
            />
        </svg>
    );
}

export default SvgIconsSingleZoom;
