import { useEffect } from 'react';
import { isNil } from 'lodash';

const useLegalTextClick = ({ legalClickListener }) => {
    useEffect(() => {
        const clarificationSpan = document.getElementById('application-clarification');
        const termsSpan = document.getElementById('application-terms');
        const privacySpan = document.getElementById('application-privacy');

        const onClickListener = (e) => {
            legalClickListener(e);
        };

        if (!isNil(clarificationSpan)) {
            clarificationSpan.addEventListener('click', onClickListener);
        }

        if (!isNil(termsSpan)) {
            termsSpan.addEventListener('click', onClickListener);
        }

        if (!isNil(privacySpan)) {
            privacySpan.addEventListener('click', onClickListener);
        }

        // Removes Event Listeners
        return () => {
            if (!isNil(clarificationSpan)) {
                clarificationSpan.removeEventListener('click', onClickListener);
            }

            if (!isNil(termsSpan)) {
                termsSpan.removeEventListener('click', onClickListener);
            }

            if (!isNil(privacySpan)) {
                privacySpan.removeEventListener('click', onClickListener);
            }
        };
    });
};

export default useLegalTextClick;
