import React from 'react';
import useStyles from './index.style';
import { useTranslation } from 'react-i18next';
import PolicyHeader from '../policy-header';
import Footer from '../../sections/Footer';
import { useEffect } from 'react';

const CookiePolicy = () => {
    const classes = useStyles();
    const { t } = useTranslation('policy');

    useEffect(() => {
        document.getElementById('policy-header').scrollIntoView();
    }, []);

    return (
        <section>
            <PolicyHeader />
            <div className={classes.title}>{t('cookiePolicyTitle')}</div>
            <div
                className={classes.content}
                dangerouslySetInnerHTML={{
                    __html: t('cookiePolicyMobileApp'),
                }}
            ></div>
            <Footer></Footer>
        </section>
    );
};

export default CookiePolicy;
