import * as React from 'react';

function SvgIconsMultipleGirisimci(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <defs>
                <path id="bwcyz4eb3a" d="M0 24L24 24 24 0 0 0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g transform="translate(-112.000000, -468.000000) translate(79.000000, 115.000000) translate(33.000000, 305.000000) translate(0.000000, 48.000000)">
                                <path
                                    fill="#E8E6E4"
                                    d="M2.25 6.375v-.75c0-.414-.336-.75-.75-.75H.75c-.207 0-.375.168-.375.375v3L2.25 6.375z"
                                />
                                <path
                                    fill="#D9DCE0"
                                    d="M9 24L15 24 15 20.625 9 20.625zM22.125 6.75l-3.75 3.75H5.625l-3.75-3.75-1.5 1.5 4.875 4.875h1.875c.621 0 1.125.504 1.125 1.125v6.375h7.5V14.25c0-.621.504-1.125 1.125-1.125h1.875l4.875-4.875-1.5-1.5z"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M11.625 24L12.375 24 12.375 21.75 11.625 21.75zM9 21L15 21 15 20.25 9 20.25z"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#E8E6E4"
                                    d="M21.75 6.375v-.75c0-.414.336-.75.75-.75h.75c.207 0 .375.168.375.375v3L21.75 6.375z"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M15.375 24h-.75v-3.75h.75v-6c0-.827.673-1.5 1.5-1.5h1.72l4.655-4.655V5.25h-.75c-.207 0-.375.168-.375.375h-.75c0-.62.505-1.125 1.125-1.125h.75c.414 0 .75.336.75.75v3.155L18.905 13.5h-2.03c-.414 0-.75.336-.75.75V21h-.75v3z"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M21.75 7.125c-.207 0-.375-.168-.375-.375V5.625c0-.207.168-.375.375-.375s.375.168.375.375V6.75c0 .207-.168.375-.375.375M2.25 7.125c-.207 0-.375-.168-.375-.375V5.625c0-.207.168-.375.375-.375s.375.168.375.375V6.75c0 .207-.168.375-.375.375"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M9.375 24h-.75v-3h-.75v-6.75c0-.414-.336-.75-.75-.75h-2.03L0 8.405V5.25c0-.414.336-.75.75-.75h.75c.62 0 1.125.505 1.125 1.125h-.75c0-.207-.168-.375-.375-.375H.75v2.845l4.655 4.655h1.72c.827 0 1.5.673 1.5 1.5v6h.75V24z"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M6.375 15.75L7.125 15.75 7.125 15 6.375 15zM6.375 17.25L7.125 17.25 7.125 16.5 6.375 16.5zM16.875 19.5L17.625 19.5 17.625 18.75 16.875 18.75zM16.875 21L17.625 21 17.625 20.25 16.875 20.25z"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#D9DCE0"
                                    d="M16.125 4.125C16.125 1.847 14.278 0 12 0S7.875 1.847 7.875 4.125c0 2.625 2.25 3 2.25 5.625h3.75c0-2.625 2.25-3 2.25-5.625"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#3F5162"
                                    fillOpacity=".52"
                                    d="M13.5 13.5L12.75 14.25 11.25 14.25 10.5 13.5 10.5 10.5 13.5 10.5z"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M9.75 10.5L14.25 10.5 14.25 9.75 9.75 9.75zM9.75 12L14.25 12 14.25 11.25 9.75 11.25zM9.75 13.5L14.25 13.5 14.25 12.75 9.75 12.75z"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M11.625 10.125L12.375 10.125 12.375 4.125 11.625 4.125z"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#FFF"
                                    d="M13.125 4.125c0 .621-.504 1.125-1.125 1.125s-1.125-.504-1.125-1.125S11.379 3 12 3s1.125.504 1.125 1.125"
                                    mask="url(#07whqa2icb)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M12 3.375c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75-.336-.75-.75-.75m0 2.25c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5"
                                    mask="url(#07whqa2icb)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleGirisimci;
