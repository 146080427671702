import * as React from 'react';
import _ from 'lodash';

function SvgIconsSingleExport(props) {
    const uniqueId = _.uniqueId('export-icon-');
    const filterId = uniqueId;
    const filterIdUrl = 'url(#' + uniqueId + ')';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <filter id={filterId} colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values={
                            props.white
                                ? '0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0'
                                : '0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0'
                        }
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g filter={filterIdUrl} transform="translate(-1130 -156) translate(1119 144)">
                        <g>
                            <path
                                fill={'#3F5162'}
                                fillRule="nonzero"
                                d="M9.894 14.868l7.344-7.344v2.664h1.512V4.95h-5.238v1.512h2.664l-7.344 7.344 1.062 1.062zm7.344 3.582c.408 0 .762-.15 1.062-.45.3-.3.45-.654.45-1.062V11.7h-1.512v5.238H6.762V6.462H12V4.95H6.762c-.42 0-.777.15-1.071.45-.294.3-.441.654-.441 1.062v10.476c0 .408.147.762.441 1.062.294.3.651.45 1.071.45h10.476z"
                                transform="translate(11 12)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleExport;
