import { makeStyles } from '@material-ui/core';
import { cardGridStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    StupIntroCard: {
        ...cardGridStyle,
        margin: '25px 0 0',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
});

export default useStyles;
