import React, { useState } from 'react';
import PageTemplate from 'fintech/components/PageTemplate';
import { Grid } from '@material-ui/core';
import Contacts from 'fintech/components/reports/contacts';
import Consultants from 'fintech/components/reports/consultants';
import Investors from 'fintech/components/reports/investors';
import Initiatives from 'fintech/components/reports/initiatives';
import Messaging from 'fintech/components/reports/messaging';
import ConfirmModal from 'fintech/components/reports/common/modal';
import Dropdown from 'fintech/components/ui/dropdown';
import { useTranslation } from 'react-i18next';
import ReportsTable from 'fintech/components/reports/common/reportlistTable';
import { requestReport } from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import { ReportType } from 'fintech/utils/ReportUtils';
import { useSnackbar } from 'notistack';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';

const ReportsPage = () => {
    const { t } = useTranslation('reports');
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const [showModal, setModalShow] = useState(false);
    const [selectedTabIndex, setTabIndex] = useState(0);

    const handleModalShow = (reportType) => {
        requestReport(keycloak, reportType)
            .then((_) => {
                setModalShow(true);
            })
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            });
    };
    const tabs = [
        {
            header: {
                label: t('nav.dashboard'),
            },
            component: (
                <Grid container spacing={1}>
                    <Grid item style={{ display: 'flex' }} justify={'flex-end'} xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Dropdown
                            alwaysFull
                            buttonText={t('card.otherData')}
                            items={[
                                {
                                    text: t('card.generateMentorReport'),
                                    onClick: () => {
                                        handleModalShow(ReportType.MENTOR);
                                    },
                                },
                                {
                                    text: t('card.generateSupportProgramReport'),
                                    onClick: () => {
                                        handleModalShow(ReportType.SUPPORT_PROGRAM);
                                    },
                                },
                            ]}
                        ></Dropdown>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Initiatives onShowModal={handleModalShow} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
                        <Investors onShowModal={handleModalShow} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
                        <Consultants onShowModal={handleModalShow} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Contacts onShowModal={handleModalShow} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Messaging onShowModal={handleModalShow} />
                    </Grid>
                </Grid>
            ),
            errorMsg: t('tabs.allConsultants.errorMessage'),
        },
        {
            header: {
                label: t('nav.reportsList'),
            },
            component: (
                <Grid container spacing={1}>
                    <Grid item style={{ marginTop: '44px' }}></Grid>
                    <ReportsTable />
                </Grid>
            ),
        },
    ];
    return (
        <PageTemplate>
            <NavTabs
                tabs={tabs.map((tab) => tab.header)}
                value={selectedTabIndex}
                onChange={(_, value) => setTabIndex(value)}
                keepValueOnParent
            />
            {tabs[selectedTabIndex].component}
            <ConfirmModal
                setModalShow={setModalShow}
                showModal={showModal}
                onOk={() => {
                    setTabIndex(1);
                    setModalShow(false);
                }}
            />
        </PageTemplate>
    );
};

export default ReportsPage;
