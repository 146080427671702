import { makeStyles } from '@material-ui/core';
import { COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    TeamItem: {
        minheight: '96px',
        padding: '16px 16px 16px 0',
        marginBottom: '8px',
        border: `solid 1px ${COLOR_PRIMARY_SHADE_02}`,
        borderRadius: '8px',
    },
});

export default useStyles;
