import { MentorSearchActions } from 'fintech/store/actions/ActionTypes';
import { APIS } from 'fintech/constants';
import { getRequest, postRequest } from 'fintech/api/api';
import { withPagination } from 'fintech/constants/apis';

export const resetAllFilters = () => {
    return (dispatch) => {
        dispatch({ type: MentorSearchActions.RESET_MENTOR_SEARCHBOX });

        // Do not display the result of a query after all criterias are cleared
        dispatch(setFilteredNumber(null));

        // Reset pagination
        dispatch({ type: MentorSearchActions.RESET_MENTOR_GRID_PAGINATION });
    };
};

// Global
export const setTotalNumber = (data) => ({
    type: MentorSearchActions.SET_MENTOR_GLOBAL_TOTAL_ACTIVE_NUMBER,
    data,
});
export const setFilteredNumber = (data) => ({
    type: MentorSearchActions.SET_MENTOR_GLOBAL_FILTERED_NUMBER,
    data,
});

export const setGridHeaders = (headers) => {
    return (dispatch) => {
        dispatch({ type: MentorSearchActions.SET_MENTOR_GRID_HEADERS, data: headers });
    };
};

export const setGridPage = (pageNumber) => ({
    type: MentorSearchActions.SET_MENTOR_GRID_PAGINATION,
    data: {
        page: pageNumber,
    },
});

export const fetchActiveMentorsCount = (keycloak, errorCallback) => {
    return (dispatch) => {
        getRequest(APIS.MENTOR.getActiveMentorsURL(), keycloak)
            .then((resp) => {
                dispatch({ type: MentorSearchActions.SET_MENTOR_GLOBAL_TOTAL_ACTIVE_NUMBER, data: resp.data });
            })
            .catch(() => {
                errorCallback();
            });
    };
};

export const fetchGridData = ({
    keycloak,
    data,
    errorCallback,
    useLastQueryType = false,
    reloadGrid = true,
    timeout = 700,
}) => {
    return (dispatch, getState) => {
        // Initial call when the component mounts
        if (reloadGrid) {
            // Show a loading indicator in place of the whole grid
            dispatch({ type: MentorSearchActions.SET_MENTOR_GRID_LOADING_INITIAL });
        } else {
            // TODO: Give the grid a disabled effect much like in DealRoom
            dispatch({ type: MentorSearchActions.SET_MENTOR_GRID_LOADING });
        }

        setTimeout(() => {
            const { grid, searchBox } = getState().mentorSearch;
            const { lastQueryParams } = searchBox;
            const { pagination } = grid;

            let queryData = !useLastQueryType ? data : lastQueryParams;

            if (!useLastQueryType) {
                if (data) {
                    queryData = data;
                } else {
                    // queryData = {
                    //     name: true,
                    //     userFintechSubVerticalSet: true,
                    //     searchTerm: '',
                    // };
                    queryData = {
                        nameSelected: true,
                        fintechVerticalsSelected: true,
                        searchTerm: '',
                    };
                }
            } else {
                queryData = lastQueryParams;
            }

            const url = withPagination(APIS.MENTOR.getSearchURL(), pagination.pageSize, pagination.page - 1);
            postRequest(`${url}&sort=fullName,ASC`, keycloak, queryData)
                .then((resp) => {
                    const { totalElements, content, totalPages } = resp.data;

                    let count = queryData.searchTerm?.length;
                    if (count > 0) {
                        dispatch(setFilteredNumber(totalElements));
                    }

                    // Save last query
                    dispatch({ type: MentorSearchActions.SET_MENTOR_SEARCHBOX_LAST_QUERY, data: queryData });

                    dispatch({ type: MentorSearchActions.SET_MENTOR_GRID_DATA, data: content });
                    dispatch({
                        type: MentorSearchActions.SET_MENTOR_GRID_PAGINATION,
                        data: { count: totalPages },
                    });
                })
                .catch(() => {
                    dispatch({ type: MentorSearchActions.SET_MENTOR_GRID_LOAD_ERROR });
                    errorCallback();
                });
        }, timeout);
    };
};

// SearchBox
export const searchBoxOnChange = (data) => ({
    type: MentorSearchActions.SET_MENTOR_SEARCHBOX_INPUT,
    data,
});

export const setSearchBoxFilterStatus = (data) => ({
    type: MentorSearchActions.SET_MENTOR_SEARCHBOX_FILTERSTATUS,
    data,
});
