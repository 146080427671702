import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultipleSelect from 'fintech/components/ui/form/select/MultipleSelect';
import { selectInputEmptyStyle } from 'fintech/components/style/common';
import DropdownError from 'fintech/components/profile/idea/actionmodal/informationcard/contentcomponent/DropdownError';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';

export default function CountryUpdate({ countries, isLoading, isError, changeCallback, label }) {
    const { t } = useTranslation();
    const [countryData, setcountryData] = useState(countries);

    useEffect(() => {
        setcountryData(countries);
    }, [countries]);

    const countryId = 'country';

    const countryChange = (val) => {
        setcountryData(val && val.length > 0 ? val : []);
        const selected = val?.filter((item) => item.selected);
        changeCallback(val && val.length > 0 ? val : [], selected && selected[0]);
    };

    let renderedJSX = null;
    if (isLoading) {
        renderedJSX = (
            <div style={{ ...selectInputEmptyStyle }}>
                <LoadingSpinner />
            </div>
        );
    } else if (isError) {
        renderedJSX = <DropdownError noBottomMargin style={{ ...selectInputEmptyStyle }} />;
    } else {
        renderedJSX = (
            <MultipleSelect
                label={label ? label : t('formLabel.country')}
                callBack={(data, valid) => countryChange(data, valid)}
                id={countryId}
                value={countryData}
                helperText={t('validation.notEmpty')}
                disabled
            />
        );
    }

    return renderedJSX;
}
