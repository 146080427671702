import { makeStyles } from '@material-ui/core';
import {
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_03,
    COLOR_SECONDARY_BASE,
    COLOR_THEME_FILL_01,
    fontStyle,
} from 'fintech/components/style/common';

export const useDefaultStyles = makeStyles({
    radioLabelRoot: {
        backgroundColor: COLOR_THEME_FILL_01,
        border: `1px solid ${COLOR_PRIMARY_SHADE_03}`,
        borderRadius: '8px',
        minWidth: '225px',
        marginLeft: 0,
        justifyContent: 'center',
        '& > span': {
            paddingLeft: '0px',
        },
    },
    radioLabel: {
        ...fontStyle,
        lineHeight: 1.71,
        letterSpacing: '0.13px',
        color: ({ secureTheme }) => (secureTheme ? COLOR_PRIMARY_BASE : COLOR_SECONDARY_BASE),
    },

    // Common for Radio
    radio: {
        color: ({ secureTheme }) => (secureTheme ? COLOR_PRIMARY_BASE : COLOR_SECONDARY_BASE),
        '&.MuiRadio-colorPrimary.Mui-checked': {
            color: COLOR_THEME_FILL_01,
        },
    },
});

export const useSelectedStyles = makeStyles({
    radioLabelRoot: {
        backgroundColor: ({ secureTheme }) => (secureTheme ? COLOR_PRIMARY_BASE : COLOR_SECONDARY_BASE),
        borderRadius: '8px',
        minWidth: '225px',
        marginLeft: 0,
        justifyContent: 'center',
        '& > span': {
            paddingLeft: '0px',
        },
    },
    radioLabel: {
        ...fontStyle,
        lineHeight: 1.71,
        letterSpacing: '0.13px',
        color: COLOR_THEME_FILL_01,
        '&.MuiFormControlLabel-label.Mui-disabled': {
            color: COLOR_THEME_FILL_01,
        },
    },

    // Common for Radio
    radio: {
        color: ({ secureTheme }) => (secureTheme ? COLOR_PRIMARY_BASE : COLOR_SECONDARY_BASE),
        '&.MuiRadio-colorPrimary.Mui-checked': {
            color: COLOR_THEME_FILL_01,
        },
    },
});
