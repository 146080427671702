import { APIS } from 'fintech/constants';
import { ConsultantSearchActions } from 'fintech/store/actions/ActionTypes';
import { withPagination } from 'fintech/constants/apis';
import { getRequest, postRequest } from 'fintech/api/api';

export const resetAllFilters = () => {
    return (dispatch) => {
        dispatch({ type: ConsultantSearchActions.RESET_CONSULTANT_SEARCHBOX });
        dispatch(setFilteredNumber(null));
        dispatch({ type: ConsultantSearchActions.RESET_CONSULTANT_GRID_PAGINATION });
    };
};

// Global
export const setTotalNumber = (data) => ({
    type: ConsultantSearchActions.SET_CONSULTANT_GLOBAL_TOTAL_ACTIVE_NUMBER,
    data,
});
export const setFilteredNumber = (data) => ({
    type: ConsultantSearchActions.SET_CONSULTANT_GLOBAL_FILTERED_NUMBER,
    data,
});

export const setGridPage = (pageNumber) => ({
    type: ConsultantSearchActions.SET_CONSULTANT_GRID_PAGINATION,
    data: { page: pageNumber },
});

export const getActiveConsultantCount = (keycloak) => {
    return (dispatch) => {
        getRequest(APIS.CONSULTANT_PRE_LOGIN.getActiveConsultantCountURL(), keycloak).then((response) => {
            if (response && response.success && response.data) {
                dispatch(setTotalNumber(response.data));
            }
        });
    };
};

export const fetchGridData = ({
    keycloak,
    data,
    errorCallback,
    useLastQueryType = false,
    reloadGrid = true,
    timeout = 700,
}) => {
    return (dispatch, getState) => {
        // Initial call when the component mounts
        if (reloadGrid) {
            // Show a loading indicator in place of the whole grid
            dispatch({ type: ConsultantSearchActions.SET_CONSULTANT_GRID_LOADING_INITIAL });
        } else {
            dispatch({ type: ConsultantSearchActions.SET_CONSULTANT_GRID_LOADING });
        }

        setTimeout(() => {
            const { grid, searchBox, global } = getState().consultantSearch;
            const { lastQueryParams } = searchBox;
            const { pagination } = grid;
            const { previousSelectedExpertiseType } = global;

            let queryData = !useLastQueryType ? data : lastQueryParams;

            let searchAll = false;

            if (!useLastQueryType) {
                if (data) {
                    queryData = data;
                } else {
                    // queryData = {
                    //     nameSelected: false,
                    //     searchTerm: '',
                    //     userExpertiseSelected: false,
                    //     selectedExpertiseTerm: '',
                    // };
                    queryData = {
                        nameSelected: true,
                        searchTerm: '',
                        userExpertiseSelected: true,
                        selectedExpertiseTerm: '',
                    };
                    /*
                    if queryData is like above and previousSelectedExpertiseType is an empty string, it is considered initial call. 
                    The number of result is not showed in that case.
                    So, set searchAll to true. call dispatch(setFilteredNumber) based on this value.
                    */
                    if (previousSelectedExpertiseType === '') {
                        searchAll = true;
                    }
                }
            } else {
                queryData = lastQueryParams;
            }

            const url = withPagination(APIS.CONSULTANT.getSearchURL(), pagination.pageSize, pagination.page - 1);
            postRequest(`${url}&sort=fullName,ASC`, keycloak, queryData)
                .then((resp) => {
                    const { content, totalPages, totalElements } = resp.data;

                    if (!searchAll) {
                        dispatch(setFilteredNumber(totalElements));
                    }

                    // Save last query
                    dispatch({ type: ConsultantSearchActions.SET_CONSULTANT_SEARCHBOX_LAST_QUERY, data: queryData });

                    dispatch({ type: ConsultantSearchActions.SET_CONSULTANT_GRID_DATA, data: content });
                    dispatch({
                        type: ConsultantSearchActions.SET_CONSULTANT_GRID_PAGINATION,
                        data: { count: totalPages },
                    });
                })
                .catch(() => {
                    dispatch({ type: ConsultantSearchActions.SET_CONSULTANT_GRID_LOAD_ERROR });
                    errorCallback();
                });
        }, timeout);
    };
};

// SearchBox
export const searchBoxOnChange = (data) => ({
    type: ConsultantSearchActions.SET_CONSULTANT_SEARCHBOX_INPUT,
    data,
});

export const setSearchBoxFilterStatus = (data) => ({
    type: ConsultantSearchActions.SET_CONSULTANT_SEARCHBOX_FILTERSTATUS,
    data,
});

export const setExpertise = (data) => ({
    type: ConsultantSearchActions.SET_CONSULTANT_EXPERTISE,
    data,
});

export const setSelectedExpertise = (selectedType, currentExpertiseType) => ({
    type: ConsultantSearchActions.SET_CONSULTANT_SELECTED_EXPERTISE,
    selectedType,
    currentExpertiseType,
});

export const updateSelectedExpertise = (selectedType) => {
    return (dispatch, getState) => {
        const currentSelectedExpertiseType = getState().consultantSearch.global.selectedExpertiseType;
        dispatch(setSelectedExpertise(selectedType, currentSelectedExpertiseType.name || ''));
    };
};
