import React from 'react';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useEventStyles from './eventClasses.style';
import useStyles from './index.style.js';
import { getPlaintextFromHtml, capitalizeTurkishCharacter } from 'fintech/utils/StringUtils.js';

const ProgramCardContent = (props) => {
    const eventClasses = useEventStyles();
    const { t } = useTranslation('supportProgram');
    const { title, startDate, endDate, description, lastApplicationDate } = props;
    const classes = useStyles();

    const name = title;
    const plainTextDesc = getPlaintextFromHtml(description);
    const information = plainTextDesc;

    const headerJSX = (
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
                <Typography className={classes.lastApplicationDateTypo}>
                    {t('general.applicationDeadline', { date: lastApplicationDate })}
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <CardContent style={{ padding: '0px' }}>
            {headerJSX}
            <Typography className={eventClasses.eventNameStyle}>{capitalizeTurkishCharacter(name)}</Typography>
            <Typography className={eventClasses.eventDateStyle}>{`${startDate} - ${endDate}`}</Typography>
            <Typography className={eventClasses.eventInformationStyle}>{information}</Typography>
        </CardContent>
    );
};

export default ProgramCardContent;
