import cloneDeep from 'lodash/cloneDeep';

// Constants
export const GRID_HEADER_STATE = {
    NONE: 'NONE',
    ASC: 'ASC',
    DESC: 'DESC',
};

// Data Generators
export const getInitSimilarVenturesGridData = () => ({
    headers: getInitialGridHeaderState(),
    rows: null,
    pagination: getInitialPaginationState(),
    isLoading: true,
    gridDataLoading: false,
    isError: false,
});

const getInitialGridHeaderState = () => {
    return [
        {
            id: 0,
            type: 'label',
            value: {
                title: 'startup',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'name',
            },
        },
        {
            id: 1,
            type: 'label',
            value: {
                title: 'location',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'address.city.name',
            },
        },
        {
            id: 2,
            type: 'label',
            value: {
                title: 'fintechVerticals',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: '',
            },
        },
        {
            id: 3,
            type: 'label',
            value: {
                title: 'startupStages',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: '',
            },
        },
        {
            id: 4,
            type: 'label',
            value: {
                title: 'dateFounded',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'dateFounded',
            },
        },
        {
            id: 5,
            type: 'label',
            value: {
                title: 'numberOfEmployees',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'employees',
            },
        },
        {
            id: 6,
            type: 'label',
            value: {
                title: 'totalInvestment',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'totalInvestment.value',
            },
        },
        {
            id: 7,
            type: 'label',
            value: {
                title: 'marketValue',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'startupFinancial.marketValue.value',
            },
        },
        {
            id: 8,
            type: 'label',
            value: {
                title: 'incomeModel',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: '',
            },
        },
        {
            id: 9,
            type: 'label',
            value: {
                title: 'technologies',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: '',
            },
        },
    ];
};

const getInitialPaginationState = () => ({
    count: 1,
    page: 1,
    defaultPage: 1,
});

// Reducer Helpers
export const getAllHeadersAferToggle = (headers, id) => {
    let _headers = cloneDeep(headers);
    const selectedHeader = _headers.findIndex((e) => e.id === id);

    switch (_headers[selectedHeader].value.status) {
        case GRID_HEADER_STATE.NONE:
            _headers[selectedHeader].value.status = GRID_HEADER_STATE.ASC;
            break;
        case GRID_HEADER_STATE.ASC:
            _headers[selectedHeader].value.status = GRID_HEADER_STATE.DESC;
            break;
        case GRID_HEADER_STATE.DESC:
            _headers[selectedHeader].value.status = GRID_HEADER_STATE.NONE;
            break;
        default:
            break;
    }

    _headers = _headers.map((element, index) => {
        if (selectedHeader !== index) {
            return {
                ...element,
                value: {
                    ...element.value,
                    status: GRID_HEADER_STATE.NONE,
                },
            };
        } else {
            return element;
        }
    });

    return _headers;
};
