import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import IntroDuoGrid from 'fintech/components/profile/common/DuoGrid';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import { Edit } from '@material-ui/icons';
import useStyles from './index.style';
import Empty from 'fintech/components/util/Empty';
import FinancialsEditModal from 'fintech/components/profile/startup/form/financials/FinancialsEditModal';

const FinancialDetails = (props) => {
    const classes = useStyles();
    const { t, editable, duoItems = [] } = props;
    const [openModal, setOpenModal] = useState(false);

    const clickEditHandler = () => setOpenModal(true);
    const cancelModalHandler = () => setOpenModal(false);

    return (
        <>
            <Grid
                className={classes.StupFinancialsCard}
                container
                direction={editable ? 'row-reverse' : 'row'}
                justify="space-between"
                alignItems="flex-start"
            >
                {editable && (
                    <Grid
                        item
                        container
                        xs={12}
                        md={duoItems ? 4 : 12}
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-start"
                    >
                        <ShrinkingButton
                            shrinkedIcon={<Edit fontSize="small" />}
                            fullIcon={<Edit />}
                            fullText={t('profile.edit')}
                            onClick={clickEditHandler}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={duoItems ? 8 : 12}>
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        style={duoItems && { maxWidth: '670px' }}
                    >
                        {duoItems ? (
                            <IntroDuoGrid items={duoItems} />
                        ) : (
                            <Empty message={t('profile.financials.emptyMessage')} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {editable && <FinancialsEditModal open={openModal} onCancel={() => cancelModalHandler()} />}
        </>
    );
};

export default withTranslation('profile')(FinancialDetails);
