export const APPLICATION_TYPE_ROLE_IDS = {
    USER: [4, 8, 10],
    INVESTOR: 5,
    MENTOR: 6,
    STARTUP: 7,
};

const INVESTOR = {
    EQUITY: 'STARTUP_EQUITY',
    INDIVIDUAL: 'INVESTOR_INDIVIDUAL',
};

const STARTUP = {
    EQUITY: 'STARTUP_EQUITY',
    INDIVIDUAL: 'STARTUP_INDIVIDUAL',
};

const ApplicationTypes = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
};

const isMentorOrUser = (applicationTypeRoleId) => {
    return (
        APPLICATION_TYPE_ROLE_IDS.MENTOR === applicationTypeRoleId ||
        APPLICATION_TYPE_ROLE_IDS.USER.includes(applicationTypeRoleId)
    );
};

export { INVESTOR, STARTUP, ApplicationTypes, isMentorOrUser };
