import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import AppBar from 'fintech/components/errors/layout/AppBar';
import Footer from 'fintech/components/errors/layout/Footer';
import useStyles from './index.style';

const ErrorPageTemplate = (props) => {
    const appBarRef = useRef(null);
    const [barHeight, setBarHeight] = useState(0);

    useEffect(() => {
        setBarHeight(appBarRef?.current?.offsetHeight);
    }, [appBarRef?.current?.offsetHeight]);

    const classes = useStyles(barHeight);

    return (
        <Grid container direction="column" justify="end" alignItems="stretch">
            <Grid item container direction="row" justify="flex-start" alignItems="flex-start">
                <AppBar innerRef={appBarRef} />
                <Grid
                    className={classes.contentWrapper}
                    item
                    xs={12}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    {props.children}
                </Grid>
            </Grid>
            <Grid item>
                <Footer />
            </Grid>
        </Grid>
    );
};

ErrorPageTemplate.propTypes = {
    children: PropTypes.node,
};

export default ErrorPageTemplate;
