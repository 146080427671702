import { makeStyles, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { COLOR_SECONDARY_SHADE_01, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    ContactGrid: {
        padding: '10px 24px',
    },
});

export const ContactTitle = withStyles({
    root: {
        ...fontStyle,
        color: COLOR_SECONDARY_SHADE_01,
    },
})(Typography);

export const ContactContent = withStyles({
    root: {
        ...fontStyle,
        fontWeight: '600',
    },
})(Typography);

export default useStyles;
