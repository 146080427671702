import { makeStyles } from '@material-ui/core';
import pattern from 'fintech/static/images/pattern-white.svg';
import { COLOR_SECONDARY_BASE, COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    supportSection: {
        background: `${COLOR_PRIMARY_SHADE_02} url(${pattern}) repeat-x bottom center`,
    },
    supportContainer: {
        padding: '92px 0px 99px 0px',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('md')]: {
            padding: '40px 22px 65px',
            margin: '0',
        },
    },
    title: {
        fontSize: '36px',
        fontWeight: '500',
        color: COLOR_SECONDARY_BASE,
        marginTop: 0,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: 25,
        },
    },
    definition: {
        fontSize: 16,
        color: COLOR_SECONDARY_BASE,
        maxWidth: 835,
        margin: '32px auto 0px auto',
        textAlign: 'center',
        fontWeight: '400',
        lineHeight: '28px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 330,
            marginBottom: 0,
        },
    },
}));

export default useStyles;
