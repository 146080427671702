import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Slide } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './index.style';
import { storageType, consentPropertyName, consentCookieName, shouldShowSwitch } from 'fintech/utils/Cookie';
import { setCookieBanner, setPerformanceCookie } from 'fintech/store/actions/cookie';
import CookieDialog from '../cookieDialog';

const CookieBanner = () => {
    const classes = useStyles();
    const { t } = useTranslation('policy');
    const dispatch = useDispatch();

    const { cookieBanner, performanceCookie } = useSelector((state) => state.cookie);
    const [open, setOpen] = useState(false);
    const [cookieSwitch, setCookieSwitch] = useState(performanceCookie);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        dispatch(setPerformanceCookie(shouldShowSwitch()));
    };

    const approvalAllCookies = () => {
        storageType.setItem(consentPropertyName, true);
        storageType.setItem(consentCookieName, true);
        dispatch(setCookieBanner(false));
        dispatch(setPerformanceCookie(true));
    };

    const rejectionAllCookies = () => {
        storageType.setItem(consentPropertyName, true);
        storageType.setItem(consentCookieName, false);
        dispatch(setPerformanceCookie(false));
        dispatch(setCookieBanner(false));
    };

    const handleManageCookie = () => {
        storageType.setItem(consentCookieName, cookieSwitch);
        dispatch(setPerformanceCookie(cookieSwitch));
        storageType.setItem(consentPropertyName, true);
        dispatch(setCookieBanner(false));
        setOpen(false);
    };

    return (
        <>
            <Slide direction="up" in={cookieBanner} mountOnEnter unmountOnExit>
                <Box className={classes.wrapper}>
                    <Box
                        dangerouslySetInnerHTML={{
                            __html: t('cookieBanner'),
                        }}
                    />
                    <Box className={classes.buttonWrapper}>
                        <Box>
                            <Button variant="contained" className={classes.manageCookieButton} onClick={handleOpen}>
                                {t('manageCookie')}
                            </Button>
                        </Box>
                        <Box className={classes.approvalRejectionArea}>
                            <Button
                                variant="contained"
                                className={classes.approvalRejectionButton}
                                onClick={rejectionAllCookies}
                            >
                                {t('rejectionCookie')}
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.approvalRejectionButton}
                                onClick={approvalAllCookies}
                            >
                                {t('approvalCookie')}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Slide>
            <CookieDialog
                open={open}
                handleClose={handleClose}
                handleManageCookie={handleManageCookie}
                cookieSwitch={cookieSwitch}
                setCookieSwitch={setCookieSwitch}
            />
        </>
    );
};

export default CookieBanner;
