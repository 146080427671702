import { makeStyles } from '@material-ui/core';
import { buttonStyle, buttonStyleColorAlt } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    formGridContainer: {
        marginBottom: '24px',
    },
    personFormWrapper: {
        marginTop: '-10px',
    },
    inputTextAreaWrapper: {
        padding: '4px 12px 0 12px',
        '&.MuiDialogContent-root:first-child': {
            padding: '4px 12px 0 12px',
        },
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            padding: '4px 0 0 0',
            '&.MuiDialogContent-root:first-child': {
                padding: '4px 0 0 0',
            },
        },
    },
    legalDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& p': {
            paddingBottom: '10px',
        },
        '& .MuiCheckbox-root': {
            marginTop: '-35px',
        },
        '& .policySubtitle': {
            fontSize: '11px',
            marginTop: '8px',
        },
    },
    backBtnWrapper: {
        padding: '6px 12px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 8px 0 0',
        },
    },
    backBtn: {
        ...buttonStyleColorAlt,
        height: '48px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    applyBtnWrapper: {
        padding: '6px 12px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 0 8px',
        },
    },
    singlepplyBtnWrapper: {
        padding: '6px 12px',
        [theme.breakpoints.down('sm')]: {
            padding: '6px 0',
        },
    },
    applyBtn: {
        ...buttonStyle,
        height: '48px',
        width: '100%',
    },
}));

export default useStyles;
