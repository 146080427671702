import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@material-ui/core';
import useStyles from './index.style';
import { getFileIconByMimeType, getFileServerToken } from 'fintech/utils/FileUtils';
import fileDownload from 'react-file-download';
import * as FileServerService from 'fintech/api/fileService';
import { fontStyle } from 'fintech/components/style/common';

const Attachment = (props) => {
    const classes = useStyles();
    const { header, attachments, showOrdered = false } = props;

    const handleFileRequest = (id, name) => {
        const idWithToken = id + '?s=' + getFileServerToken();
        FileServerService.getFile(idWithToken).then((result) => {
            fileDownload(result, name);
        });
    };

    const getHeader = () => {
        if (showOrdered) {
            return (
                <Grid item container direction="row" justify="flex-start">
                    <Grid item>
                        <Typography className={classes.AtcIcon}>#</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.AtcHeader}>{header}</Typography>
                    </Grid>
                </Grid>
            );
        } else {
            return <Typography className={classes.AtcHeader}>{header}</Typography>;
        }
    };

    const getAtcItem = (atc, idx) => {
        const isLastElement = idx === attachments.length - 1;
        if (showOrdered) {
            return (
                <Grid key={idx} item container direction="row" justify="flex-start">
                    <Grid item>
                        <Typography id="atcnumber" className={classes.AtcNumber}>
                            {idx + 1}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            startIcon={getFileIconByMimeType(atc.mimeType)}
                            className={classes.AtcButton}
                            style={{ textTransform: 'none' }}
                            onClick={() => handleFileRequest(atc.uuid, atc.fileName)}
                        >
                            <Typography noWrap style={fontStyle}>
                                {atc.fileName}
                            </Typography>
                        </Button>
                    </Grid>
                    {!isLastElement && <div className={classes.horizontalRule}></div>}
                </Grid>
            );
        } else {
            return (
                <>
                    <Button
                        key={idx}
                        startIcon={getFileIconByMimeType(atc.mimeType)}
                        className={classes.AtcButton}
                        style={{ textTransform: 'none' }}
                        onClick={() => handleFileRequest(atc.uuid, atc.fileName)}
                    >
                        <Typography noWrap style={fontStyle}>
                            {atc.fileName}
                        </Typography>
                    </Button>
                    {!isLastElement && <div className={classes.horizontalRule}></div>}
                </>
            );
        }
    };

    return (
        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            {getHeader()}
            {attachments?.map(getAtcItem)}
        </Grid>
    );
};

export default Attachment;
