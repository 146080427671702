import React from 'react';
import { Badge, Typography, withStyles } from '@material-ui/core';
import { ErrorOutline, OfflineBoltTwoTone, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import {
    COLOR_PRIMARY_BASE,
    COLOR_SECONDARY_SHADE_01,
    COLOR_THEME_FILL_05,
    fontStyle,
} from 'fintech/components/style/common';

export const IntroCardTitle = withStyles({
    root: {
        ...fontStyle,
        color: COLOR_SECONDARY_SHADE_01,
        textAlign: 'start',
        marginTop: '4px',
        marginRight: '20px',
        marginBottom: '8px',
    },
})(Typography);

export const IntroCardContent = withStyles({
    root: {
        ...fontStyle,
        marginTop: '4px',
        marginRight: '20px',
        marginBottom: '10px',
        textAlign: 'start',
    },
})(Typography);

export const BackedIcon = withStyles({
    root: {
        color: COLOR_THEME_FILL_05,
    },
})(OfflineBoltTwoTone);

export const HideFieldIcon = withStyles({
    root: {
        color: COLOR_PRIMARY_BASE,
    },
})(VisibilityOffOutlined);

export const ShowFieldIcon = withStyles({
    root: {
        color: COLOR_PRIMARY_BASE,
    },
})(VisibilityOutlined);

export const InfoIcon = withStyles({
    root: {
        color: COLOR_PRIMARY_BASE,
        transform: 'rotate(180deg)',
    },
})(ErrorOutline);

export const titleWithVisibilityBadge = (isPublic, canHide, title) => {
    const publicFieldIcon = isPublic ? <ShowFieldIcon /> : <HideFieldIcon />;
    let titleJSX = null;
    titleJSX = (
        <IntroCardTitle>
            {title} {canHide && <Badge>{publicFieldIcon}</Badge>}
        </IntroCardTitle>
    );
    return titleJSX;
};
