import React from 'react';
import { Box } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { mobileMediaQuery } from 'fintech/components/style/common';
import useStyles from './index.style';

const PageHeaderBox = (props) => {
    const { disableOnMobile } = props;
    const classes = useStyles();
    const isWeb = useMediaQuery(mobileMediaQuery);
    const boxClassName = isWeb ? classes.boxWeb : !disableOnMobile ? classes.boxMobile : '';
    return <Box className={boxClassName} />;
};

export default PageHeaderBox;
