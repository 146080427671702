import { CommonActions, CurrencyActions } from 'fintech/store/actions/ActionTypes';

const initialState = {
    // CBFOFH-870 Prevent interaction when a request is sent
    hasActiveRequest: false,
    currencies: {
        data: null,
        isLoading: true,
        isError: false,
    },
};

const common = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case CommonActions.SET_ACTIVE_REQUEST:
            return {
                ...state,
                hasActiveRequest: action.data,
            };
        case CurrencyActions.SET_CURRENCIES: {
            const { currencies } = action;
            return {
                ...state,
                currencies: {
                    ...state.currencies,
                    data: currencies.data,
                    isLoading: false,
                },
            };
        }
        case CurrencyActions.SET_CURRENCIES_LOADING:
            return {
                ...state,
                currencies: {
                    data: null,
                    isLoading: true,
                    isError: false,
                },
            };
        case CurrencyActions.SET_CURRENCIES_LOAD_ERROR:
            return {
                ...state,
                currencies: {
                    ...state.currencies,
                    isLoading: false,
                    isError: true,
                },
            };
        default:
            return state;
    }
};

export default common;
