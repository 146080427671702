import React from 'react';
import { Grid, Button } from '@material-ui/core';

function OperationButton({ icon, text, onClick, disabled }) {
    return (
        <Grid item>
            <Grid container>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: '#5b7adb',
                            marginLeft: '8px',
                            paddingLeft: '4px',
                        }}
                        onClick={onClick}
                        disabled={disabled}
                    >
                        {icon}
                        <span>{text}</span>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default OperationButton;
