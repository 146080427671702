import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import fileDownload from 'react-file-download';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import * as FileServerService from 'api/fileService';
import VideoComponent from 'fintech/components/fileupload/VideoComponent';

/*
    File upload and download page for POC
 */
function FileUploadPage() {
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsSelected] = useState(false);
    const [requestedFile, setRequestedFile] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);
    const [publicFileError, setPublicFileError] = useState('');
    const [image, setImage] = useState();

    const { keycloak } = useKeycloak();

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const handleSubmission = () => {
        const formData = new FormData();

        formData.append('uploaded_file', selectedFile);
        formData.append('isPrivate', isPrivate ? '1' : '0');

        FileServerService.uploadFile(keycloak, formData, selectedFile.name);
    };

    const handleFileRequest = () => {
        FileServerService.getFile(requestedFile)
            .then((result) => {
                setImage(result);
                fileDownload(result, 'file_name.jpg');
                publicFileError ? setPublicFileError('') : null;
            })
            .catch((reason) => setPublicFileError(reason));
    };

    const handlePrivateFileRequest = () => {
        FileServerService.getFile(requestedFile, keycloak)
            .then((result) => {
                fileDownload(result, 'file_name.jpg');
            })
            .catch((reason) => console.error(reason));
    };

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: '0 1 50%' }}>
                    <input type="file" name="uploaded_file" onChange={changeHandler} />
                    {isFilePicked ? (
                        <div>
                            <p>Filename: {selectedFile.name}</p>
                            <p>Filetype: {selectedFile.type}</p>
                            <p>Size in bytes: {selectedFile.size}</p>
                            <p>lastModifiedDate: {selectedFile.lastModifiedDate.toLocaleDateString()}</p>
                        </div>
                    ) : (
                        <p>Select a file to show details</p>
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox checked={isPrivate} onChange={() => setIsPrivate(!isPrivate)} name="isPrivate" />
                        }
                        label="Make the file private"
                    />
                    <div>
                        <button onClick={handleSubmission}>Submit</button>
                    </div>
                </div>

                <div style={{ flex: '0 1 50%' }}>
                    <div>
                        <label>Filename(UUID):</label>
                        <input value={requestedFile} onInput={(e) => setRequestedFile(e.target.value)} />
                    </div>
                    <div>
                        <button onClick={handleFileRequest}>Download File</button>
                        {publicFileError ? <p>{publicFileError}</p> : null}
                    </div>
                    <div>
                        <button onClick={handlePrivateFileRequest}>Download Private File</button>
                    </div>
                    <div>{image ? <img src={URL.createObjectURL(image)} /> : null}</div>
                </div>
            </div>
            <VideoComponent />
        </div>
    );
}

export default FileUploadPage;
