import { makeStyles } from '@material-ui/core/styles';
import {
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    poppins500_14px,
    COLOR_SECONDARY_SHADE_01,
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_BUTTON_HOVERED,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        display: 'flex',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',

        width: '90vw',
        height: '80vh',

        backgroundColor: '#fff',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: '0.3rem',
    },

    primaryText: {
        color: COLOR_PRIMARY_BASE + ' !important',
    },
    sideButton: {
        ...poppins500_14px,
        color: '#3f5162',
        textTransform: 'none',
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        fontWeight: 500,
        fontSize: '14px',
        width: '100%',
        textAlign: 'left',
        display: 'inline-block',
        '&:hover': {
            display: 'inline-block',
            color: COLOR_PRIMARY_BASE,
            background: 'none',
            borderStyle: 'none',
            boxShadow: 'none',
            textAlign: 'left',
        },
    },
    selectedSideButton: {
        ...poppins500_14px,
        color: '#fff',
        backgroundColor: COLOR_PRIMARY_BASE,
        textTransform: 'none',
        width: '100%',
        textAlign: 'left',
        borderRadius: 'unset',
        display: 'inline-block',
        '&:hover': {
            display: 'inline-block',
            ...poppins500_14px,
            color: '#fff',
            backgroundColor: COLOR_PRIMARY_BASE,
            textTransform: 'none',
            width: '100%',
            textAlign: 'left',
            borderRadius: 'unset',
        },
    },

    primaryButton: {
        ...poppins500_14px,
        color: '#fff',
        backgroundColor: COLOR_PRIMARY_BASE,
        textTransform: 'none',
        marginLeft: '1rem',
        marginBottom: '1rem',
        padding: '0.5rem 1.5rem',
        [theme.breakpoints.down('md')]: {
            flex: 1,
            marginLeft: 'unset',
        },
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
        },
    },

    secondaryButton: {
        ...poppins500_14px,
        color: '#3f5162',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        textTransform: 'none',
        marginBottom: '1rem',
        padding: '0.5rem 1.5rem',
        [theme.breakpoints.down('md')]: {
            flex: 1,
        },
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        },
    },
    categorySideBarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '20%',
        height: '100%',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        '& > button:first-child': {
            marginTop: '2rem',
        },
    },
    categoryDropDownContainer: {},
    categoryDropDownSelect: {},
    mainContent: {
        width: '80%',
        padding: '2rem',
    },
    mainContentTitle: {
        ...poppins500_14px,
        color: COLOR_SECONDARY_SHADE_01,
        display: 'block',
        textTransform: 'uppercase',
        marginBottom: '1rem',
    },
    mainContentGroup: {
        padding: '2rem 0',
    },
    mainContentCheckboxLabel: {
        ...poppins500_14px,
        color: COLOR_SECONDARY_BASE + ' !important',
        fontWeight: '500 !important',
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginTop: '8px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    footer: {
        height: '10%',
        [theme.breakpoints.down('md')]: {
            gridColumnGap: '20px',
        },
    },

    // Mobile
    mobileHeader: {
        fontFamily: ' Poppins',
        fontWeight: '600',
        color: COLOR_SECONDARY_BASE,
    },
    mobileButton: {
        maxHeight: '50px',
        margin: '0.5rem 1rem',
        padding: '1rem 0',
        borderRadius: '8px',
    },
    mobileCloseButton: {
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        borderRadius: '9px',
        width: '30px',
        height: '30px',
    },
    mobileFilterCategory: {
        margin: '2rem 1rem !important',
    },
}));

export default useStyles;
