import React from 'react';
import Empty from 'fintech/components/util/Empty';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // FIXME: Delete before merging to production
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            const errorMessage = this.props.message;
            return <Empty isError message={errorMessage} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
