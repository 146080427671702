import { makeStyles, withStyles, Button } from '@material-ui/core';
import { COLOR_PRIMARY_SHADE_02, poppins500_14px } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: '#3f5162 !important',
        color: '#fff !important',
    },
    buttons: {
        [theme.breakpoints.down('xs')]: {
            flexGrow: 0,
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
}));

export const ButtonTypeSelect = withStyles({
    root: {
        ...poppins500_14px,
        textTransform: 'none',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        borderRadius: '8px',
        padding: '9px 32px 11px',
        border: '1px solid #d7e2e8',
    },
})(Button);

export default useStyles;
