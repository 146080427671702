import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './index.style';
import { fromEpoch, DAY_MONTH_DESC } from 'fintech/utils/DateUtils';

const AnnouncementHeader = (props) => {
    const classes = useStyles();
    const { date, title } = props;

    return (
        <>
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Grid item xs={8}>
                    <div className={classes.AnnHeaderDiv}>
                        <Grid item className={classes.AnnDate}>
                            <Typography className={classes.AnnDateTypo}>{fromEpoch(date, DAY_MONTH_DESC)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.AnnTitle}>{title}</Typography>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

AnnouncementHeader.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('announcement')(AnnouncementHeader);
