import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Grid, Typography } from '@material-ui/core';

import expiredImage from 'fintech/static/images/expired.png';
import { HOME } from 'fintech/routes';
import useHomePageClick from 'fintech/hooks/useHomePageClick';
import useStyles from './index.style';

const NotFound = () => {
    const classes = useStyles();
    const { t } = useTranslation('error');
    const history = useHistory();

    useHomePageClick({
        legalClickListener: (e) => {
            if (e?.currentTarget) {
                history.push(HOME);
            }
        },
    });

    return (
        <Grid item className={classes.contentRoot} container direction="column" justify="center" alignItems="center">
            <Grid item xs={12}>
                <div className={classes.imgWrapper}>
                    <img className={classes.ExpiredImg} src={expiredImage} />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.titleTypo}> {t('expiredInvite.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.infoTypo}>{t('expiredInvite.info1')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    className={classes.infoTypo}
                    dangerouslySetInnerHTML={{ __html: t('expiredInvite.info2') }}
                />
            </Grid>
        </Grid>
    );
};

export default NotFound;
