import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { fontStyle } from 'fintech/components/style/common';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import ProfileVisitHistory from 'fintech/components/profile/common/ProfileVisitHistory';

export const ViewTimesTypo = withStyles({
    root: {
        ...fontStyle,
        textAlign: 'start',
        color: '#98a3ac',
        paddingBottom: 0,
        marginBottom: 0,
        '& span': {
            color: '#3f5162',
            fontWeight: 'bold',
        },
    },
})(Typography);

const IntroTab = (props) => {
    const { t, times } = props;
    const tabs = [{ label: t('profile.user.introCard') }];

    return (
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={8}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item>
                        <NavTabs value={0} tabs={tabs} />
                    </Grid>
                </Grid>
            </Grid>
            {Number(times) > 0 && (
                <Grid item xs={4}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                        <Grid item>
                            <Grid item>{<ProfileVisitHistory visitCount={times} />}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default withTranslation('profile')(IntroTab);
