import { cloneDeep, isEmpty } from 'lodash';
import { APIS } from 'fintech/constants';
import { getRequest, patchRequest } from 'fintech/api/api';
import { NotificationActions } from 'fintech/store/actions/ActionTypes';

export const fetchNotifications = (pageNumber, keycloak) => {
    return (dispatch, getState) => {
        if (pageNumber === 0) {
            dispatch({
                type: NotificationActions.SET_NOTIFICATIONS,
                data: [],
            });
            dispatch({
                type: NotificationActions.SET_IS_LOADING,
                data: true,
            });
        }
        getRequest(APIS.NOTIFICATIONS.getNotifications() + `?page=${pageNumber}`, keycloak)
            .then((resp) => {
                const { notifications } = getState().notifications;

                dispatch({
                    type: NotificationActions.SET_IS_LOADING,
                    data: false,
                });

                dispatch({
                    type: NotificationActions.SET_NOTIFICATIONS,
                    data: isEmpty(notifications)
                        ? [...resp.data.content]
                        : merge(cloneDeep(notifications), cloneDeep(resp.data.content), 'id'),
                });

                dispatch(setHasMore(resp.data.last));
            })
            .catch(() => {
                dispatch({
                    type: NotificationActions.SET_IS_LOADING,
                    data: false,
                });
            });
    };
};

export const readNotification = (notificationID, keycloak) => {
    return (dispatch, getState) => {
        patchRequest(APIS.NOTIFICATIONS.getReadNotification(notificationID), keycloak).then(() => {
            const { totalNumberOfUnreadNotifications } = getState().notifications;
            dispatch({ type: NotificationActions.DISABLE_NOTIFICATION, data: notificationID });
            if (totalNumberOfUnreadNotifications && totalNumberOfUnreadNotifications >= 1) {
                dispatch({
                    type: NotificationActions.SET_TOTAL_NUMBER_OF_UNREAD_NOTIFICATIONS,
                    data: totalNumberOfUnreadNotifications - 1,
                });
            }
        });
    };
};

export const readAllNotification = (keycloak) => {
    return (dispatch) => {
        patchRequest(APIS.NOTIFICATIONS.getReadAllNotifications(), keycloak).then(() => {
            dispatch({ type: NotificationActions.DISABLE_ALL_NOTIFICATION });
            dispatch({ type: NotificationActions.SET_TOTAL_NUMBER_OF_UNREAD_NOTIFICATIONS, data: 0 });
        });
    };
};

export const fetchTotalNumberOfUnreadNotifications = (keycloak) => {
    return (dispatch) => {
        getRequest(APIS.NOTIFICATIONS.getBadgeCount(), keycloak).then(({ data }) => {
            dispatch({ type: NotificationActions.SET_TOTAL_NUMBER_OF_UNREAD_NOTIFICATIONS, data: +data });
        });
    };
};

// Privates
const setHasMore = (data) => ({
    type: NotificationActions.SET_HAS_MORE,
    data,
});

var merge = (a, b, p) => {
    return a.filter((aa) => !b.find((bb) => aa[p] === bb[p])).concat(b);
};
