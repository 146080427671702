import * as React from 'react';

function SvgIconsSingleTooltip28(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
        <defs>
            <filter id="mdfczbyq2a" colorInterpolationFilters="auto">
                <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.247059 0 0 0 0 0.317647 0 0 0 0 0.384314 0 0 0 1.000000 0"/>
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g filter="url(#mdfczbyq2a)" transform="translate(-696.000000, -495.000000) translate(370.000000, 455.000000) translate(319.000000, 33.000000)">
                        <g>
                            <path fill="#3F5162" fillRule="nonzero" d="M24 38.376c2.736 0 5.268-.684 7.596-2.052 2.256-1.296 4.032-3.072 5.328-5.328 1.368-2.328 2.052-4.86 2.052-7.596 0-2.736-.684-5.268-2.052-7.596-1.296-2.256-3.072-4.032-5.328-5.328-2.328-1.368-4.86-2.052-7.596-2.052-2.712 0-5.232.684-7.56 2.052-2.256 1.32-4.044 3.108-5.364 5.364-1.368 2.328-2.052 4.848-2.052 7.56 0 2.712.684 5.232 2.052 7.56 1.296 2.256 3.072 4.044 5.328 5.364 2.328 1.368 4.86 2.052 7.596 2.052zm0-2.952c-2.16 0-4.176-.552-6.048-1.656-1.824-1.056-3.264-2.496-4.32-4.32-1.104-1.872-1.656-3.888-1.656-6.048s.552-4.176 1.656-6.048c1.056-1.824 2.496-3.264 4.32-4.32 1.872-1.104 3.888-1.656 6.048-1.656s4.176.552 6.048 1.656c1.824 1.056 3.264 2.496 4.32 4.32 1.104 1.872 1.656 3.888 1.656 6.048s-.552 4.176-1.656 6.048c-1.056 1.824-2.496 3.264-4.32 4.32-1.872 1.104-3.888 1.656-6.048 1.656zM25.476 18.9v-3.024h-2.952V18.9h2.952zm0 12.024v-9h-2.952v9h2.952z" transform="translate(7.000000, 7.000000)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    );
}

export default SvgIconsSingleTooltip28;
