import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: 'rgba(8, 17, 59, 0.85)',
        height: '100vh',
        padding: '20px 120px 3.5%',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: '20px 65px 3.5%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '20px 22px 60px 22px',
            height: 'auto',
        },
    },
    backgroundImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '-1',
        transform: 'scaleX(-1)',
        objectFit: 'cover',
    },
    loginButton: {
        height: '48px',
        marginRight: '8px',
        color: 'white',
        border: 'solid 2px white',
        borderRadius: '8px',
        fontWeight: 600,
        padding: '14px 21px',
        textTransform: 'none',
        minWidth: 'fit-content',
        '& span': {
            minWidth: 'fit-content',
        },
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    navbar: {
        justifyContent: 'space-between',
    },
    logoWrapper: {
        marginTop: 21,
        cursor: 'pointer',
        '& > img': {
            maxWidth: '220px',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            margin: '0 auto',
            marginBottom: 67,
            paddingTop: 10,
        },
        [theme.breakpoints.down(370)]: {
            '& > img': {
                width: '190px !important',
            },
        },
    },
    languagePanelWrapper: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    listNav: {
        display: 'flex',
        paddingTop: 10,
        paddingRight: '40px',
        listStyleType: 'none',
        width: '100%',
        justifyContent: 'center',
        gap: '24px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        height: '40px',
        alignItems: 'center',
    },
    listItem: {
        'font-family': "'Poppins'",
        'font-size': '14px',
        color: 'white',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    rightButtonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 57,
        },
    },
    supportProgramTextContainer: {
        margin: 'auto',
        maxWidth: '920px',
    },
    welcomeTextContainer: {
        justifyContent: 'center',
    },
    eventsContainer: {
        alignSelf: 'flex-end',
    },
    supportProgramTitle: {
        'font-weight': 'bold',
        'font-size': '40px',
        'margin-bottom': '32px',
        'line-height': 1.18,
        color: 'white',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            'font-size': '32px',
        },
    },
    welcomeTitle: {
        'font-weight': 'bold',
        'font-size': '40px',
        'margin-bottom': '32px',
        'max-width': '525px',
        'line-height': 1.18,
        color: 'white',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            maxWidth: '100%',
            'font-size': '32px',
        },
    },
    supportProgramSubTitle: {
        color: 'white',
        'font-size': '16px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            'font-size': '16px',
        },
    },
    welcomeSubTitle: {
        color: 'white',
        'max-width': '782px',
        'font-size': '16px',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginBottom: 50,
            'font-size': '16px',
        },
    },
    applyProgramButtonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '64px',
    },
    applyProgramButton: {
        backgroundColor: 'white',
        width: '240px',
        height: '48px',
        borderRadius: '8px',
        color: '#3F5162',
        fontSize: '14px',
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    mobileLanguagePanelWrapper: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            top: 32,
            right: 50,
            position: 'absolute',
        },
        [theme.breakpoints.down(370)]: {
            right: '45px !important',
        },
    },
    preLoginHamburgerIcon: {
        display: 'none',

        [theme.breakpoints.down(900)]: {
            display: 'block',
            top: 32,
            right: 24,
            position: 'absolute',
        },
    },
    headerMiddleBarLinksBigScreen: {
        display: 'flex !important',
        alignItems: 'center',
        '& ul': {
            marginTop: '0px !important',
            marginBottom: '0px !important',
        },
        [theme.breakpoints.down(1400)]: {
            '& li': {
                display: 'none !important',
            },
        },
    },
    headerMiddleBarLinksMiddle: {
        width: '100%',
        '& ul': {
            justifyContent: 'space-between',
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
        [theme.breakpoints.up(1400)]: {
            display: 'none !important',
        },
    },
    fintechVideo: {
        position: 'absolute !important',
        top: '0 !important',
        left: '0 !important',
        bottom: '0 !important',
        right: '0 !important',
        backgroundAttachment: 'fixed !important',
        objectFit: 'cover !important',
        zIndex: '-1 !important',
        height: '100%',
        width: '100%',
    },
}));

export default useStyles;
