import * as React from 'react';

function SvgIconsMultipleStartup() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <g>
                            <path
                                fill="#D9DCE0"
                                d="M9.513 12.338h4.399s.703 1.63-.42 2.867l-4.508 4.48s-.927-2.889.373-5.111l.156-2.236zM9.026 8.975l-1.32-2.808s-1.639-.699-2.884.417L.318 11.063s2.908.922 5.14-.366l3.568-1.722z"
                                transform="translate(-24 -24) translate(24 24) translate(1.006 3)"
                            />
                            <path
                                fill="#D9DCE0"
                                d="M19.796.316c-.004 1.764-.309 3.452-.872 5.026-.757 2.117-1.977 4.012-3.543 5.57-1.753 1.742-3.933 3.056-6.376 3.771l-3.658-3.637c1.364-4.597 4.882-8.268 9.395-9.863 1.579-.56 3.28-.867 5.054-.867zm-7.463 4.9c-1.403 0-2.54 1.13-2.54 2.526 0 1.394 1.137 2.525 2.54 2.525s2.54-1.13 2.54-2.525-1.137-2.526-2.54-2.526z"
                                transform="translate(-24 -24) translate(24 24) translate(1.006 3)"
                            />
                            <path
                                fill="#D9DCE0"
                                d="M18.924 5.342c-1.011-.164-1.98-.64-2.76-1.414-.779-.775-1.257-1.739-1.422-2.745 1.579-.56 3.28-.867 5.054-.867-.004 1.768-.309 3.456-.872 5.026zM4.505 17.264c1.173-1.166-.584-2.91-1.757-1.747-1.164-1.158 2.324-2.311 3.488-3.469.585.58 1.173 1.166 1.757 1.747-1.16 1.158-2.324 4.626-3.488 3.469z"
                                transform="translate(-24 -24) translate(24 24) translate(1.006 3)"
                            />
                            <path
                                fill="#3F5162"
                                d="M20.02.093C19.963.033 19.882 0 19.797 0c-1.773 0-3.51.299-5.16.884-1.004.354-1.977.817-2.888 1.372-.148.093-.194.287-.101.434.089.147.288.194.436.105 0 0 .004 0 .004-.004.766-.467 1.584-.859 2.418-1.183.228.96.72 1.831 1.435 2.543.711.707 1.587 1.195 2.553 1.423-.733 1.902-1.876 3.662-3.336 5.114-1.69 1.68-3.785 2.939-6.059 3.637l-.817-.812-2.574-2.56C6.6 8.087 8.332 5.59 10.72 3.734c.067-.05.11-.126.123-.21.008-.085-.013-.165-.064-.232-.106-.135-.313-.16-.449-.055-.986.766-1.863 1.638-2.62 2.593-.428-.147-1.91-.547-3.108.53L.094 10.84c-.08.08-.11.19-.085.299.025.105.106.193.212.227.071.021 1.092.337 2.387.337.78 0 1.656-.118 2.49-.476.008.013.012.03.02.038l.716.711c-.368.295-.88.602-1.38.901-1.08.644-2.1 1.25-2.189 1.991-.038.295.072.569.322.817.123.122.326.122.449 0 .453-.45.982-.324 1.308 0 .326.324.453.85 0 1.3-.123.123-.123.325 0 .447.216.215.457.324.707.324.038 0 .072-.004.11-.008.745-.093 1.355-1.103 2.003-2.177.3-.496.61-1.006.906-1.372l.715.712c.013.012.025.016.038.025-.96 2.21-.178 4.727-.14 4.845.034.105.119.185.229.21.025.005.05.009.076.009.085 0 .165-.034.225-.093l4.517-4.491c1.08-1.187.669-2.66.52-3.086.475-.37.928-.77 1.36-1.196 1.612-1.603 2.861-3.57 3.62-5.687.583-1.637.884-3.363.888-5.131-.004-.084-.038-.164-.097-.223zM.94 10.895l4.094-4.071c.804-.724 1.8-.577 2.273-.45C6.402 7.618 5.695 9 5.203 10.481c-1.52.817-3.374.594-4.263.413zm5.677 4.348c-.49.813-1.1 1.823-1.536 1.878h-.043c.42-.661.225-1.415-.25-1.886-.474-.472-1.231-.665-1.896-.249v-.042c.055-.433 1.071-1.04 1.888-1.528.538-.32 1.092-.652 1.503-.99l1.33 1.323c-.335.408-.67.96-.996 1.494zm6.647-.26l-4.106 4.082c-.182-.884-.411-2.723.423-4.239 1.469-.484 2.862-1.195 4.123-2.096.132.467.28 1.456-.44 2.252zm5.44-10.007c-.876-.194-1.672-.632-2.316-1.272-.647-.644-1.083-1.435-1.278-2.306C16.515.93 17.98.669 19.475.636c-.038 1.494-.3 2.946-.77 4.34z"
                                transform="translate(-24 -24) translate(24 24) translate(1.006 3)"
                            />
                            <path
                                fill="#3F5162"
                                d="M11.207 3.3c.067 0 .127-.016.182-.054.144-.097.177-.295.08-.438-.097-.14-.305-.177-.444-.08-.068.046-.115.118-.132.202-.012.084.005.168.055.236.06.084.157.134.259.134zM9.47 7.742c0 .757.297 1.473.84 2.008.537.534 1.256.833 2.018.833.763 0 1.482-.295 2.02-.833 1.113-1.108 1.113-2.91 0-4.02-.538-.535-1.257-.834-2.02-.834-.762 0-1.481.294-2.019.833-.538.54-.838 1.25-.838 2.013zm2.858-2.21c.593 0 1.152.231 1.571.648.868.863.868 2.265 0 3.123-.419.417-.978.649-1.57.649-.593 0-1.152-.232-1.571-.649-.42-.416-.652-.972-.652-1.561 0-.59.233-1.145.652-1.562.419-.417.978-.649 1.57-.649z"
                                transform="translate(-24 -24) translate(24 24) translate(1.006 3)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleStartup;
