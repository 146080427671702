import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import Tooltip from 'fintech/components/ui/tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useStyles, { SearchButton } from './index.style';
import IconsSingleSearch from 'fintech/icons/IconsSingleSearch';
import { isEmpty } from 'lodash';
import SvgIconsMultipleSuccess from 'fintech/icons/IconsMultipleSuccess';
import {
    COLOR_PRIMARY_BASE,
    COLOR_THEME_FILL_01,
    mobileMediaQuery,
    loadingSpinnerWithTextGeneralTextStyle,
} from 'fintech/components/style/common';
import { SupportApplicationApprovalStatus } from 'fintech/constants/supportProgram';
import { useTranslation } from 'react-i18next';
import Pagination from '@material-ui/lab/Pagination';
import SingleSelect from 'fintech/components/ui/form/select/SingleSelect';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const ApplicationPickerAndSearch = React.forwardRef(
    (
        {
            className,
            pickerItems,
            showApplicationProgramSelectBox = false,
            onChange,
            searchTerm,
            handleSearchButton,
            getSelectedContentCallback,
            buttonText,
            placeholder,
            getLogoByApplicationType,
            handlePageChangeForMobile,
            totalPagesForMobile = 1,
            appliedPrograms, // it is only passed for support programs.
            selectedAppliedProgramId: _selectedAppliedProgramId, // it is only passed for support programs.
            handleAppliedProgramOnChangeCallback, // it is only passed for support programs.
            showIsOperationAlreadyHasDone = false, // it is only passed for support programs.
            isLoading = false,
        },
        ref
    ) => {
        const isMobile = !useMediaQuery(mobileMediaQuery);

        const { t } = useTranslation('membershipManagement');
        const classes = useStyles();

        // eslint-disable-next-line no-undef
        const [clonedItems, setClonedItems] = useState([...pickerItems]);
        const [popoverAnchors, setPopoverAnchors] = useState([]);

        useEffect(() => {
            setPopoverAnchors(new Array(pickerItems.length).fill(null));
        }, [pickerItems.length]);

        const handleChange = (value) => {
            onChange(value);
        };

        const isElementOverflowing = (element) => {
            return element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;
        };

        const updateAnchorAt = (index, element) => {
            return [...popoverAnchors.slice(0, index), element, ...popoverAnchors.slice(index + 1)];
        };

        const handlePopoverOpen = (event, index) => {
            const element = event.currentTarget;
            if (isElementOverflowing(element)) {
                setPopoverAnchors(updateAnchorAt(index, element));
            }
        };

        const handlePopoverClose = (index) => {
            setPopoverAnchors(updateAnchorAt(index, null));
        };

        const handleRowItemClick = (selectedItem) => {
            setTimeout(() => {
                const temp = clonedItems.map((cur) => {
                    if (selectedItem.id !== cur.id) {
                        return {
                            ...cur,
                            selected: false,
                        };
                    } else {
                        return {
                            ...cur,
                            selected: true,
                        };
                    }
                });

                getSelectedContentCallback(selectedItem);
                setClonedItems(temp);
            }, 0);
        };

        const handleAppliedPrograms = (value) => {
            handleAppliedProgramOnChangeCallback(value.id);
        };

        const handlePageChange = (event, clickedPageNumber) => handlePageChangeForMobile(clickedPageNumber);

        useEffect(() => {
            setClonedItems([...pickerItems]);
        }, [pickerItems]);

        const initialSelectedAppliedProgram =
            showApplicationProgramSelectBox && appliedPrograms.find((p) => p.id === _selectedAppliedProgramId);

        return (
            <Grid id="applicationPicker" item className={`${classes.container} ${className || ''}`}>
                {showApplicationProgramSelectBox && (
                    <div className={classes.appliedProgramsSelectContainer}>
                        <SingleSelect
                            initValue={initialSelectedAppliedProgram}
                            canBeEmpty={false}
                            dontValidate={true}
                            label={t('applicationDetails.picker.appliedPrograms')}
                            className={classes.select}
                            options={appliedPrograms}
                            onChange={handleAppliedPrograms}
                        />
                    </div>
                )}

                <SearchBox
                    onClick={handleSearchButton}
                    onChange={handleChange}
                    buttonText={buttonText}
                    placeholder={placeholder}
                    searchTerm={searchTerm}
                />

                <div className={classes.items}>
                    {!isEmpty(clonedItems) ? (
                        clonedItems.map((item, index) => (
                            <Grid
                                container
                                key={index}
                                className={`${classes.item} ${item.selected ? classes.selected : classes.notSelected} `}
                                onClick={() => handleRowItemClick(item)}
                                ref={ref}
                                spacing={0}
                            >
                                <Grid item xs={2} sm={2} md={4} lg={2} className={classes.logo}>
                                    {getLogoByApplicationType(item.applicationTypeRoleId)}
                                </Grid>
                                <ClickAwayListener onClickAway={handlePopoverClose}>
                                    <Tooltip
                                        title={item.name}
                                        aria-label={item.name}
                                        onClose={handlePopoverClose}
                                        open={Boolean(popoverAnchors[index])}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={8}
                                            md={4}
                                            lg={showIsOperationAlreadyHasDone ? 7 : 8}
                                            className={classes.name}
                                            onMouseEnter={(event) => handlePopoverOpen(event, index)}
                                            onMouseLeave={() => handlePopoverClose(index)}
                                        >
                                            <span>{item.name}</span>
                                        </Grid>
                                    </Tooltip>
                                </ClickAwayListener>
                                <Grid item xs={4} sm={2} md={4} lg={showIsOperationAlreadyHasDone ? 3 : 2}>
                                    <Grid container alignItems="center">
                                        {showIsOperationAlreadyHasDone && (
                                            <Grid item xs={3} lg={4}>
                                                <div className={classes.reviewIconWrapper}>
                                                    {item.reviewStatus !== SupportApplicationApprovalStatus.NOTSEEN ? (
                                                        <SvgIconsMultipleSuccess
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 24 24"
                                                            circleFill={COLOR_PRIMARY_BASE}
                                                            pathFill={COLOR_THEME_FILL_01}
                                                        />
                                                    ) : (
                                                        <div
                                                            className={classes.emptyCircle}
                                                            style={{ borderColor: item.selected ? '#fff' : '' }}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                        )}
                                        <Grid item xs={9} lg={8} className={classes.date}>
                                            {item.applicationDate}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <>
                            {!isLoading && (
                                <div className={classes.notFound}>{t('applicationDetails.picker.search.notFound')}</div>
                            )}
                        </>
                    )}
                    {isLoading && (
                        <LoadingSpinnerWithText
                            style={loadingSpinnerWithTextGeneralTextStyle}
                            // loadingMessage={t('applicationDetails.picker.search.loading')}
                        />
                    )}
                </div>
                {isMobile && (
                    <Pagination
                        count={totalPagesForMobile}
                        variant="outlined"
                        shape="rounded"
                        className={classes.pagination}
                        onChange={handlePageChange}
                    />
                )}
            </Grid>
        );
    }
);

export default React.memo(ApplicationPickerAndSearch);

const SearchBox = React.memo(({ onClick, onChange, searchTerm, buttonText, placeholder }) => {
    const classes = useStyles();

    const inputChangeHandler = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className={classes.searchBoxContainer}>
            <Grid container className={classes.searchBox}>
                <Grid item>
                    <span>
                        <IconsSingleSearch />
                    </span>
                </Grid>
                <Grid item className={classes.searchInputGrid}>
                    <input
                        value={searchTerm}
                        className={classes.input}
                        placeholder={placeholder}
                        onChange={inputChangeHandler}
                        onKeyDown={(e) => e.key === 'Enter' && onClick()}
                    />
                </Grid>
                <Grid item>
                    <SearchButton onClick={onClick}>{buttonText}</SearchButton>
                </Grid>
            </Grid>
        </div>
    );
});
