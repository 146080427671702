import { ConsultantFAQSearchActions } from 'fintech/store/actions/ActionTypes';
import { parseQuestions } from 'fintech/utils/DataParser';

const initialState = {
    global: {
        totalNumber: null,
        filteredNumber: null,
        expertiseTypes: [],
        selectedExpertiseType: {
            id: 0,
            name: '',
        },
    },
    searchBox: {
        input: '',
        lastQueryParams: {
            searchTerm: '',
            selectedExpertiseTerm: '',
        },
        filters: [
            { id: 0, label: 'nameSelected', status: true },
            { id: 1, label: 'userExpertiseSelected', status: true },
        ],
    },
    questions: {
        rows: [], // Needs to be fetched from server
        isLoading: true,
        isError: false,
        gridDataLoading: false,
        pagination: {
            count: 0,
            pageSize: 10,
            defaultPage: 1,
            page: 1,
            resetFromSearchBoxOrExpertiseType: false,
        },
        hasMore: false,
    },
};

const faqSearch = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_LOADING: {
            return {
                ...state,
                questions: {
                    ...state.questions,
                    isLoading: true,
                    isError: false,
                    hasMore: false,
                },
            };
        }
        case ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_DATA:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    rows: parseQuestions(action.data, action.isInfiniteScroll ? state.questions.rows : undefined),
                    isLoading: false,
                    isError: false,
                    hasMore: action.hasMore,
                    pagination: {
                        ...state.questions.pagination,
                        resetFromSearchBoxOrExpertiseType: false,
                    },
                },
            };
        case ConsultantFAQSearchActions.RESET_PAGINATION_FROM_SEARCHBOX_OR_EXPERTISE:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    pagination: {
                        ...initialState.questions.pagination,
                        resetFromSearchBoxOrExpertiseType: true,
                    },
                },
            };
        case ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_EXPERTISE:
            return {
                ...state,
                global: {
                    ...state.global,
                    expertiseTypes: action.data,
                },
            };
        case ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_SELECTED_EXPERTISE:
            return {
                ...state,
                global: {
                    ...state.global,
                    selectedExpertiseType: action.data,
                },
            };
        case ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_SEARCHBOX_INPUT:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    input: action.data,
                },
            };
        case ConsultantFAQSearchActions.RESET_CONSULTANT_FAQ_SEARCHBOX:
            return {
                ...state,
                searchBox: initialState.searchBox,
            };
        case ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_LOAD_ERROR:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    isLoading: false,
                    isError: true,
                },
            };
        case ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_PAGINATION:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    pagination: {
                        ...state.questions.pagination,
                        page: action.data,
                    },
                },
            };
        case ConsultantFAQSearchActions.RESET_CONSULTANT_FAQ_PAGINATION:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    pagination: initialState.questions.pagination,
                },
            };
        case ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_SEARCHBOX_LAST_QUERY:
            return {
                ...state,
                searchBox: {
                    ...state.searchBox,
                    lastQueryParams: action.data,
                },
            };
        default:
            return state;
    }
};

export default faqSearch;
