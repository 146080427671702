export const SUBMIT = 'SUBMIT';
export const REJECT = 'REJECT';
export const TAB = 'TAB';

export const IDEA_GENERAL_INFO_UPDATE = 'IDEA_GENERAL_INFO_UPDATE';
export const IDEA_KEYWORDS_UPDATE = 'IDEA_KEYWORDS_UPDATE';
export const IDEA_MEDIA_UPDATE = 'IDEA_MEDIA_UPDATE';

export const PROFILE_GENERAL_INFO_UPDATE = 'PROFILE_GENERAL_INFO_UPDATE';
export const PROFILE_KEYWORDS_UPDATE = 'PROFILE_KEYWORDS_UPDATE';
export const PROFILE_SOCIAL_MEDIA_UPDATE = 'PROFILE_SOCIAL_MEDIA_UPDATE';

// Startup intro card edit modal
export const STARTUP_INTRO_SUMMARY_UPDATE = 'STARTUP_INTRO_SUMMARY_UPDATE';
export const STARTUP_GENERAL_INFO_UPDATE = 'STARTUP_GENERAL_INFO_UPDATE';
export const STARTUP_KEYWORDS_UPDATE = 'STARTUP_KEYWORDS_UPDATE';
export const STARTUP_MEDIA_UPDATE = 'STARTUP_MEDIA_UPDATE';
export const STARTUP_INTRO_DATE_UPDATE = 'STARTUP_INTRO_DATE_UPDATE';
export const STARTUP_INTRO_EMPLOYEES_UPDATE = 'STARTUP_INTRO_EMPLOYEES_UPDATE';
export const STARTUP_INTRO_ADDRESS_UPDATE = 'STARTUP_INTRO_ADDRESS_UPDATE';
export const STARTUP_INTRO_INVESTMENT_UPDATE = 'STARTUP_INTRO_INVESTMENT_UPDATE';
export const STARTUP_INTRO_EMAIL_UPDATE = 'STARTUP_INTRO_EMAIL_UPDATE';
export const STARTUP_INTRO_WEBSITE_UPDATE = 'STARTUP_INTRO_WEBSITE_UPDATE';
export const STARTUP_INTRO_FORM_SUBMIT = 'STARTUP_INTRO_FORM_SUBMIT';

export const ESTABLISHMENT_GENERAL_INFO_UPDATE = 'ESTABLISHMENT_GENERAL_INFO_UPDATE';
export const ESTABLISHMENT_KEYWORDS_UPDATE = 'ESTABLISHMENT_KEYWORDS_UPDATE';
export const ESTABLISHMENT_MEDIA_UPDATE = 'ESTABLISHMENT_MEDIA_UPDATE';

export const ESTABLISHMENT_DATE_FOUNDED_UPDATE = 'ESTABLISHMENT_DATE_FOUNDED_UPDATE';
export const ESTABLISHMENT_INVESTOR_TYPE_UPDATE = 'ESTABLISHMENT_INVESTOR_TYPE_UPDATE';
export const ESTABLISHMENT_INVESTOR_GROUP_UPDATE = 'ESTABLISHMENT_INVESTOR_GROUP_UPDATE';
export const ESTABLISHMENT_INVESTOR_WEBSITE_UPDATE = 'ESTABLISHMENT_INVESTOR_WEBSITE_UPDATE';
export const ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_UPDATE = 'ESTABLISHMENT_PREFERENCES_MANAGEMENT_TYPE_UPDATE';
export const ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNT_UPDATE = 'ESTABLISHMENT_PREFERENCES_INVESTMENT_AMOUNT_UPDATE';
export const ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENT_AMOUNT_UPDATE =
    'ESTABLISHMENT_PREFERENCES_COMPANY_INVESTMENT_AMOUNT_UPDATE';
export const ESTABLISHMENT_INVESTOR_FORM_SUBMIT = 'ESTABLISHMENT_INVESTOR_FORM_SUBMIT';

// Startup financials - Financials edit modal
export const STARTUP_FINANCIALS_REQUEST_UPDATE = 'STARTUP_FINANCIALS_REQUEST_UPDATE';
export const STARTUP_FINANCIALS_MARKET_VALUE_UPDATE = 'STARTUP_FINANCIALS_MARKET_VALUE_UPDATE';
export const STARTUP_FINANCIALS_INCOME_MODEL_UPDATE = 'STARTUP_FINANCIALS_INCOME_MODEL_UPDATE';

// Investor search - Representative edit modal
export const INVESTOR_GRID_REPRESENTATIVE_UPDATE = 'INVESTOR_GRID_REPRESENTATIVE_UPDATE';

// User search - Add new user
export const USER_SEARCH_USER_TYPE_UPDATE = 'USER_SEARCH_USER_TYPE_UPDATE';
export const USER_SEARCH_NAME_UPDATE = 'USER_SEARCH_NAME_UPDATE';
export const USER_SEARCH_SURNAME_UPDATE = 'USER_SEARCH_SURNAME_UPDATE';
export const USER_SEARCH_COUNTRY_UPDATE = 'USER_SEARCH_COUNTRY_UPDATE';
export const USER_SEARCH_IDENTITY_UPDATE = 'USER_SEARCH_IDENTITY_UPDATE';
export const USER_SEARCH_GENDER_UPDATE = 'USER_SEARCH_GENDER_UPDATE';
export const USER_SEARCH_BIRTHDATE_UPDATE = 'USER_SEARCH_BIRTHDATE_UPDATE';
export const USER_SEARCH_EMAIL_UPDATE = 'USER_SEARCH_EMAIL_UPDATE';
// Consultant specific
export const USER_SEARCH_EXPERTISE_UPDATE = 'USER_SEARCH_EXPERTISE_UPDATE';

// Support programs - Add/update edit program
export const SUPPORT_PROGRAM_TITLE_UPDATE = 'SUPPORT_PROGRAM_TITLE_UPDATE';
export const SUPPORT_PROGRAM_DESC_UPDATE = 'SUPPORT_PROGRAM_DESC_UPDATE';
export const SUPPORT_PROGRAM_LAST_APPLICATION_DATE_UPDATE = 'SUPPORT_PROGRAM_LAST_APPLICATION_DATE_UPDATE';
export const SUPPORT_PROGRAM_LAST_REVIEW_DATE_UPDATE = 'SUPPORT_PROGRAM_LAST_REVIEW_DATE_UPDATE';
export const SUPPORT_PROGRAM_START_DATE_UPDATE = 'SUPPORT_PROGRAM_START_DATE_UPDATE';
export const SUPPORT_PROGRAM_END_DATE_UPDATE = 'SUPPORT_PROGRAM_END_DATE_UPDATE';

// Legal text modal
export const LEGAL_TEXT_CLARIFICATION = 'application-clarification';
export const LEGAL_TEXT_TERMS_OF_USE = 'application-terms';
export const LEGAL_PRIVACY_POLICY = 'application-privacy';
