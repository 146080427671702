import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchEstablishmentIntroData,
    fetchEstablishmentAboutData,
    fetchEstablishmentTeamData,
    updateVisitCount,
} from 'fintech/store/actions/establishment';
import { useKeycloak } from '@react-keycloak/web';
import { useParams } from 'react-router';
import IntroGrid from 'fintech/components/profile/establishment/intro/IntroGrid';
import PageTemplate from 'fintech/components/PageTemplate';
import { Box } from '@material-ui/core';
import TabSwitch from 'fintech/components/ui/navigation/TabSwitch';
import { useTranslation } from 'react-i18next';
import GeneralInfoGrid from 'fintech/components/profile/startup/GeneralInfo/GeneralInfoGrid';
import {
    getInvestorDocuments,
    updateInvestorDocuments,
    deleteInvestorDocument,
    deleteInvestorTeamMember,
    cancelInvestorTeamInvitation,
    postInvestorInviteMember,
    updateInvestorTeamMember,
} from 'fintech/api/fintechService';
import TeamGrid from 'fintech/components/profile/startup/Team/TeamGrid';
import { STATES, EstablishmentActions } from 'fintech/store/actions/ActionTypes';
import InvestmentPreferences from 'fintech/components/profile/establishment/InvestmentPreferences/InvestmentPreferences';
import * as PAGE_TYPES from 'fintech/components/util/PageTypes';

function EstablishmentPage() {
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const params = useParams();
    const { t } = useTranslation();

    const { editable } = useSelector((state) => state.establishment.intro.data);
    const { data: aboutData, isLoading, isError } = useSelector((state) => state.establishment.about);
    const { data: introData } = useSelector((state) => state.establishment.intro);

    useEffect(() => {
        dispatch({
            type: EstablishmentActions.RESET_ESTABLISHMENT_INTRO_CARD,
        });
        dispatch(fetchEstablishmentIntroData(keycloak, params.id));
        dispatch(fetchEstablishmentAboutData(keycloak, params.id));
        dispatch(updateVisitCount(keycloak, PAGE_TYPES.INVESTOR_PROFILE, params.id));
    }, [params.id]);

    const tabs = [
        {
            header: { label: t('profile.generalInfo.label') },
            component: (
                <GeneralInfoGrid
                    pageType="establishment"
                    generalInfo={aboutData?.about}
                    documentServices={{
                        getDocuments: getInvestorDocuments,
                        updateDocuments: updateInvestorDocuments,
                        deleteDocuments: deleteInvestorDocument,
                    }}
                    introData={introData}
                    editable={editable}
                    isLoading={isLoading}
                    isError={isError}
                    uuid={params.id}
                />
            ),
            errorMsg: t('profile.generalInfo.errorMessage'),
        },
        {
            header: { label: t('profile.team.label') },
            component: (
                <TeamGrid
                    id={params.id}
                    type={STATES.ESTABLISHMENT}
                    teamServices={{
                        fetchTeamData: fetchEstablishmentTeamData,
                        deleteTeamMember: deleteInvestorTeamMember,
                        cancelTeamInvitation: cancelInvestorTeamInvitation,
                        postInviteMember: postInvestorInviteMember,
                        updateTeamMember: updateInvestorTeamMember,
                    }}
                />
            ),
            errorMsg: t('profile.team.errorMessage'),
        },
        {
            header: { label: t('profile.establishments.investmentPreferences.label') },
            component: <InvestmentPreferences id={params.id} editable={editable} />,
            errorMsg: t('profile.team.errorMessage'),
        },
    ];

    return (
        <PageTemplate>
            <IntroGrid />
            <Box height="32px" />
            <TabSwitch tabs={tabs} />
        </PageTemplate>
    );
}

export default EstablishmentPage;
