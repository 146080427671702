import { Backdrop, Grid, Modal, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { getPublicFileUrl } from 'fintech/api/fileService';
import { useStyles } from 'fintech/components/dashboard/eventdetail/eventdetailcontent/EventDetailContent.style';
import { withTranslation } from 'react-i18next';
import { mobileMediaQuery } from 'fintech/components/style/common';
import CloseButton from 'fintech/icons/CloseButton';
import EscKeyListener from 'fintech/components/util/EscKeyListener';
import { getFileServerToken } from '../../../../utils/FileUtils';

const EventDetailContent = (props) => {
    const { eventInformation, eventImageUuid } = props;
    const matches = useMediaQuery(mobileMediaQuery);
    const [modalOpen, setModalOpen] = React.useState(false);
    const classes = useStyles();

    const leftGridXs = matches ? 8 : false;
    const rightGridXs = matches ? 4 : false;

    return (
        <>
            <Grid
                container
                item
                direction={matches ? 'row' : 'column-reverse'}
                className={classes.EventDetailContentGrid}
            >
                <Grid
                    container
                    item
                    direction={'column'}
                    style={{ justifyContent: 'space-between' }}
                    xs={eventImageUuid ? leftGridXs : '12'}
                >
                    <Grid item className={classes.EventDetailContent}>
                        <div dangerouslySetInnerHTML={{ __html: eventInformation }} />
                    </Grid>
                </Grid>
                {eventImageUuid ? (
                    <Grid item xs={rightGridXs}>
                        <img
                            alt={'event-detail'}
                            className={classes.EventDetailContentImage}
                            src={getPublicFileUrl(eventImageUuid, getFileServerToken())}
                            onClick={() => setModalOpen(true)}
                        />
                        {open && <EscKeyListener onEsc={() => setModalOpen(false)} />}
                        <Modal
                            className={classes.modal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 100,
                            }}
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                        >
                            <div
                                className="carousel-modal-div"
                                style={{ width: 'auto', maxWidth: '80%', height: '80%', textAlign: 'end' }}
                            >
                                <div style={{ marginBottom: '4px' }} onClick={() => setModalOpen(false)}>
                                    <CloseButton style={{ cursor: 'pointer' }} />
                                </div>
                                <img
                                    alt={'event-detail'}
                                    className={classes.EventDetailModalImage}
                                    src={getPublicFileUrl(eventImageUuid, getFileServerToken())}
                                />
                            </div>
                        </Modal>
                    </Grid>
                ) : null}
            </Grid>
        </>
    );
};

export default withTranslation('event')(EventDetailContent);
