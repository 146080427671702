import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import LogoFinTechHub from 'fintech/static/images/Logo-FinTechHub-Disi.svg';
import WelcomeBg from 'fintech/static/images/welcome-background.png';
import BackgroundVideo from 'fintech/static/videos/bg-video-hd-compressed.mp4';
import useStyles from './index.style';
import LanguagePanel from 'fintech/components/lang/LanguagePanel';
import Button from '@material-ui/core/Button';
import ApplyContainer from './apply-container';
import IconsHamburger from 'fintech/icons/IconsMultipleHamburger';
import MobileMenu from './mobile-menu';
import { useTranslation } from 'react-i18next';
import SecureContainer from './secure-container';
import { useKeycloak } from '@react-keycloak/web';
import {
    isAuthenticated,
    getLoggedInUserUuid,
    getGivenNameAndFamilyNameFirstChar,
    hasAnyAuthorities,
} from 'fintech/utils/Auth';
import * as ROUTES from 'fintech/routes';
import { useHistory } from 'react-router-dom';
import EventGridContainer from 'fintech/components/dashboard/event/EventGridContainer';
import { ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE } from '../../../../components/util/RoleConstants';
import { useSnackbar } from 'notistack';

const Welcome = (props) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('preLogin');
    const { t: ts } = useTranslation('supportProgram');
    const classes = useStyles();
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const { keycloak } = useKeycloak();
    const isUserAuthenticated = isAuthenticated(keycloak);
    const userUuid = getLoggedInUserUuid(keycloak);
    let rightWrapperJSX = null;
    const supportProgramOnePage = props.supportProgramOnePage;

    useEffect(() => {
        document.querySelector('#welcome-bg-video').playbackRate = 0.8;
    }, []);

    if (isUserAuthenticated) {
        rightWrapperJSX = (
            <SecureContainer
                userUuid={userUuid}
                userNameInfo={getGivenNameAndFamilyNameFirstChar(keycloak)}
                keycloak={keycloak}
            />
        );
    } else {
        rightWrapperJSX = (
            <>
                <Button
                    variant="outlined"
                    className={classes.loginButton}
                    onClick={() => history.push(ROUTES.PLATFORM)}
                >
                    {t('loginButton')}
                </Button>
                <ApplyContainer isMentorApplicationActive={props.isMentorApplicationActive}></ApplyContainer>
            </>
        );
    }

    const scrollTo = (element) => {
        const { top, left } = document.getElementById(element).getBoundingClientRect();
        window.scroll({ top, left, behavior: 'smooth' });
    };

    const openHamburger = () => {
        document.body.style.height = '100vh';
        document.body.style.overflow = 'hidden';
        setMobileMenuActive(true);
    };

    const onApplyButtonClick = () => {
        if (isAuthenticated(keycloak)) {
            if (hasAnyAuthorities(keycloak, [ROLE_ADMIN, ROLE_COUNSELOR, ROLE_EVALUATION_COMMITTEE])) {
                enqueueSnackbar(ts('snack.notAllowedToApply'), { variant: 'error' });
                return;
            }

            history.push(`${ROUTES.SUPPORT_PROGRAM_APPLICATION_SECURE}/${props.supportProgramId}`);
        } else {
            history.push(`${ROUTES.SUPPORT_PROGRAM}/${props.supportProgramId}`);
        }
    };

    const topMenuLinks = !supportProgramOnePage ? (
        <ul className={classes.listNav}>
            <li className={classes.listItem} onClick={() => scrollTo('section-support')}>
                {t('titles.support')}
            </li>
            <li
                className={classes.listItem}
                onClick={() => {
                    scrollTo('section-testimonial');
                    document.getElementById('mentors').click();
                }}
            >
                {t('titles.mentors')}
            </li>
            <li
                className={classes.listItem}
                onClick={() => {
                    scrollTo('section-testimonial');
                    document.getElementById('consultants').click();
                }}
            >
                {t('titles.consultants')}
            </li>
            <li className={classes.listItem} onClick={() => scrollTo('section-startup')}>
                {t('titles.startups')}
            </li>
            <li className={classes.listItem} onClick={() => scrollTo('section-investors')}>
                {t('titles.investors')}
            </li>
            <li className={classes.listItem} onClick={() => scrollTo('section-faq')}>
                {t('titles.faqList')}
            </li>
            <li>
                <div className={classes.languagePanelWrapper}>
                    <LanguagePanel makeColorWhite={true} />
                </div>
            </li>
        </ul>
    ) : (
        <ul className={classes.listNav} style={{ justifyContent: 'center', paddingRight: 0 }}>
            <li className={classes.listItem} onClick={() => scrollTo('section-about-program')}>
                {t('titles.aboutProgram')}
            </li>
            <li
                className={classes.listItem}
                onClick={() => {
                    scrollTo('section-supports');
                    document.getElementById('supports').click();
                }}
            >
                {t('titles.supports')}
            </li>
            <li
                className={classes.listItem}
                onClick={() => {
                    scrollTo('section-timesheet');
                    document.getElementById('timesheet').click();
                }}
            >
                {t('titles.timesheet')}
            </li>
            <li className={classes.listItem} onClick={() => scrollTo('section-continua')}>
                {t('titles.continua')}
            </li>
            <li
                className={classes.listItem}
                onClick={() => {
                    scrollTo('section-testimonial');
                    document.getElementById('mentors').click();
                }}
            >
                {t('titles.mentors')}
            </li>
            <li className={classes.listItem} onClick={() => scrollTo('section-educationAndSeminars')}>
                {t('titles.educationAndSeminars')}
            </li>
            <li className={classes.listItem} onClick={() => scrollTo('section-faq')}>
                {t('titles.faqList')}
            </li>
            <li>
                <div className={classes.languagePanelWrapper}>
                    <LanguagePanel makeColorWhite={true} />
                </div>
            </li>
        </ul>
    );

    const pageContent = supportProgramOnePage ? (
        <>
            <Grid container item direction="column" xs={12} className={classes.supportProgramTextContainer}>
                <div className={classes.supportProgramTitle}>{supportProgramOnePage.programTitle}</div>
                <div className={classes.supportProgramSubTitle}>{supportProgramOnePage.programSubTitle}</div>
                <div className={classes.applyProgramButtonWrapper}>
                    <div onClick={onApplyButtonClick} className={classes.applyProgramButton}>
                        {t('applyProgram')}
                    </div>
                </div>
            </Grid>
        </>
    ) : (
        <>
            <Grid container item direction="column" xs={12} className={classes.welcomeTextContainer}>
                <div className={classes.welcomeTitle}>
                    <div>{t('titles.welcome')}</div>
                    <div>{t('titles.welcomeSubtitle')}</div>
                </div>
                <div className={classes.welcomeSubTitle}>{t('welcome')}</div>
            </Grid>
            <Grid container item direction="column" xs={12} className={classes.eventsContainer}>
                <EventGridContainer isPreLogin={true} isUserAuthenticated={isUserAuthenticated}></EventGridContainer>
            </Grid>
        </>
    );

    return (
        <section className={classes.wrapper} id="section-welcome">
            <img src={WelcomeBg} className={classes.backgroundImage} />
            <video
                id="welcome-bg-video"
                className={classes.fintechVideo}
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
            >
                <source src={BackgroundVideo} type="video/mp4" />
            </video>
            <Grid container direction="row" style={{ height: '100%' }}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Grid container direction="row" className={classes.navbar}>
                            <Grid
                                item
                                xs={12}
                                md={2}
                                className={classes.logoWrapper}
                                onClick={() => history.push(ROUTES.HOME)}
                            >
                                <img width="220px" src={LogoFinTechHub} />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={!supportProgramOnePage ? 8 : 10}
                                className={classes.headerMiddleBarLinksBigScreen}
                            >
                                {topMenuLinks}
                            </Grid>
                            {!supportProgramOnePage && (
                                <Grid item xs={12} md={2} className={classes.rightButtonsWrapper}>
                                    {rightWrapperJSX}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.headerMiddleBarLinksMiddle}>
                        {topMenuLinks}
                    </Grid>
                </Grid>
                {pageContent}
            </Grid>
            <div className={classes.mobileLanguagePanelWrapper}>
                <LanguagePanel makeColorWhite={true} />
            </div>
            <div className={classes.preLoginHamburgerIcon} onClick={openHamburger}>
                <IconsHamburger></IconsHamburger>
            </div>
            {mobileMenuActive && (
                <MobileMenu
                    setMobileMenuActive={setMobileMenuActive}
                    supportProgramOnePage={supportProgramOnePage}
                ></MobileMenu>
            )}
        </section>
    );
};

export default Welcome;
