/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import IconsSingleKeyword from 'fintech/icons/IconsSingleKeyword';
import { filterObjectList, findObject } from 'fintech/utils/ArrayUtils';
import { ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export default function MultipleSelect({
    id = 'default',
    label,
    placeholder,
    callBack,
    chipCallBack,
    searchDataAndParentData,
    value,
    multipleSelect,
    helperText,
    noOptionsText,
    disabled,
    preventValidation,
    onSelectionChange,
    dontSort,
    validateOnBlur = false,
    validatedAfterFormSubmit = true,
    disableClearable,
}) {
    const { t } = useTranslation();
    const [selectedValues, setSelectedValues] = useState(
        multipleSelect ? filterObjectList(value, 'selected', [true]) : findObject(value, 'selected', true)
    );
    const [allData, setAllData] = useState(value);
    const [valid, setIsValid] = useState(false);
    const filterOptions = createFilterOptions(
        searchDataAndParentData
            ? {
                  stringify: ({ data, parentData }) => `${data} ${parentData}`,
              }
            : {
                  stringify: ({ data }) => `${data}`,
              }
    );

    useEffect(() => {
        setAllData(value);
        setSelectedValues(
            multipleSelect ? filterObjectList(value, 'selected', [true]) : findObject(value, 'selected', true)
        );
    }, [value]);

    useEffect(() => {
        isValidSelected(selectedValues);
    }, [selectedValues]);

    useEffect(() => {
        if (!validatedAfterFormSubmit) {
            onSelectionChange && onSelectionChange();
        }
    }, [validatedAfterFormSubmit]);

    const isValidSelected = (input) => {
        if (multipleSelect && (!input || !input.length > 0)) {
            setIsValid(false);
            callBack(allData, false, selectedValues);
        } else if (!multipleSelect && !input) {
            setIsValid(false);
            callBack(allData, false, selectedValues);
        } else {
            setIsValid(true);
            callBack(allData, true, selectedValues);
        }
    };

    const changeSelectionHandle = (value) => {
        setSelectedValues(value);
        const tmpAllData = allData;
        tmpAllData.forEach(function (item) {
            if (value && value.id == item.id) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
        setAllData(tmpAllData);
        onSelectionChange && onSelectionChange();
    };

    const changeMultiSelectionHandle = (value) => {
        setSelectedValues(value);
        const tmpAllData = allData;
        tmpAllData.forEach(function (item) {
            if (value && findObject(value, 'id', item.id)) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
        setAllData(tmpAllData);
        onSelectionChange && onSelectionChange();
    };

    const shortOptions = () => {
        if (dontSort) {
            return allData;
        }
        if (searchDataAndParentData) {
            // alphabetical sorting has been carried out. data and parentData
            return _.orderBy(allData, ['parentData', 'data'], ['asc', 'asc']);
            // return allData.sort((a, b) => JSON.stringify(b.parentId).localeCompare(JSON.stringify(a.parentId)));
        } else {
            return allData.sort((a, b) => a.data.localeCompare(b.data));
        }
    };

    const getOptionLabel = (option) => {
        if (searchDataAndParentData) {
            return option && option.parentData ? option.parentData + ': ' + option.data : option.data;
        } else {
            return option ? option.data : '';
        }
    };

    return (
        <div className={'selectComboboxContainer'}>
            {allData && allData.length > 0 && (
                <Autocomplete
                    disabled={disabled}
                    disableClearable={disableClearable}
                    key={value}
                    onChange={
                        multipleSelect
                            ? (event, value) => changeMultiSelectionHandle(value)
                            : (event, value) => changeSelectionHandle(value)
                    }
                    multiple={multipleSelect}
                    id={`${id}-size-small-outlined-multi`}
                    size="small"
                    options={shortOptions()}
                    groupBy={searchDataAndParentData ? (option) => option.parentData : null}
                    defaultValue={selectedValues}
                    getOptionLabel={(option) => option.data}
                    popupIcon={<ExpandMore />}
                    value={selectedValues}
                    limitTags={3}
                    noOptionsText={noOptionsText ? noOptionsText : t('formLabel.noOptions')}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={label}
                            error={preventValidation ? false : !valid}
                            helperText={preventValidation ? false : !valid ? helperText : ''}
                            placeholder={placeholder}
                        />
                    )}
                    filterOptions={filterOptions}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                onClick={() => chipCallBack && chipCallBack(option)}
                                key={index}
                                icon={<IconsSingleKeyword className={'chipIcon'} />}
                                label={getOptionLabel(option)}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    onBlur={
                        validateOnBlur
                            ? () => {
                                  onSelectionChange && onSelectionChange();
                              }
                            : () => {}
                    }
                />
            )}
        </div>
    );
}
