import React from 'react';
import './index.css';
import LogoFinTechHub from 'fintech/static/images/Logo-FinTechHub-Disi.svg';
import LanguagePanel from 'fintech/components/lang/LanguagePanel';
import useStyles from './index.style';
import { HOME } from 'fintech/routes';
import { useHistory } from 'react-router-dom';

const PolicyHeader = () => {
    const history = useHistory();
    const classes = useStyles();

    return (
        <div className={classes.headerWrapper} id="policy-header">
            <div className={classes.logoWrapper} onClick={() => history.push(HOME)}>
                <img src={LogoFinTechHub} width="220px" />
            </div>
            <div className={classes.languagePanelWrapper}>
                <LanguagePanel makeColorWhite={true} />
            </div>
        </div>
    );
};

export default PolicyHeader;
