import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import 'pure-react-carousel/dist/react-carousel.es.css';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import { fetchOtherEvents } from 'fintech/store/actions/event';
import useStylesEmpty from 'fintech/components/dashboard/event/eventcardgrid/index.style';
import Empty from 'fintech/components/util/Empty';
import EventCardGrid from 'fintech/components/dashboard/event/eventcardgrid/EventCardGrid';

/**
 * Other Events Card Grid which uses the carousel for showing the event cards
 */
const OtherEventsGridContainer = (props) => {
    const { t, eventId } = props;
    const { keycloak } = useKeycloak();
    const { otherEvents: fetchedEvent, isLoading, isError } = useSelector((state) => state.event);
    const dispatch = useDispatch();

    const tabs = [{ label: t('eventDetail.otherEvents'), value: 0 }];

    useEffect(() => {
        dispatch(fetchOtherEvents(keycloak, 4));
    }, []);

    let events = [];
    let hasEvents = false;
    if (fetchedEvent && fetchedEvent.length > 0) {
        events = fetchedEvent.filter((event) => event.id !== eventId);
        hasEvents = events && events.length > 0;
    }

    let emptyProps = {};

    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('otherEvents.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('otherEvents.errorMessage');
    } else {
        emptyProps.message = t('otherEvents.emptyMessage');
    }

    return (
        <>
            <NavTabs value={0} tabs={tabs} />
            {hasEvents ? <EventCardGrid events={events} /> : <Empty {...emptyProps} useStyles={useStylesEmpty} />}
        </>
    );
};

export default withTranslation('event')(OtherEventsGridContainer);
