import { withStyles, Button } from '@material-ui/core';
import {
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_BASE,
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    COLOR_PRIMARY_BUTTON_HOVERED,
} from 'fintech/components/style/common';

const CloseButton = withStyles({
    root: {
        textTransform: 'none',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        color: COLOR_SECONDARY_BASE,
        borderRadius: '8px',
        padding: '14px 22px',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        },
    },
})(Button);

const SaveButton = withStyles({
    root: {
        textTransform: 'none',
        backgroundColor: COLOR_PRIMARY_BASE,
        borderRadius: '8px',
        padding: '14px 22px',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
        },
    },
})(Button);

export { CloseButton, SaveButton };
