import React from 'react';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import useStyles, { ContactContent, ContactTitle } from './index.style';
import { formatPhoneNumber } from 'fintech/utils/StringUtils';

const ContactInfoGrid = (props) => {
    const classes = useStyles();
    const { t, breakPoints = {}, mobile, email } = props;

    return (
        <Grid item {...breakPoints} container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    className={classes.ContactGrid}
                >
                    <Grid item>
                        <ContactTitle>
                            {t('profile.team.phone')}
                        </ContactTitle>
                        <ContactContent style={{ minWidth: '131.2px' }}>
                            {mobile ? formatPhoneNumber(`${mobile}`) : '-'}
                        </ContactContent>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    className={classes.ContactGrid}
                >
                    <Grid item>
                        <ContactTitle>{t('profile.team.email')}</ContactTitle>
                    </Grid>

                    <Grid item>
                        <ContactContent>{email ? email : '-'}</ContactContent>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withTranslation('profile')(ContactInfoGrid);
