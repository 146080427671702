import React, { useContext, useEffect, useState } from 'react';
import { CarouselContext } from 'pure-react-carousel';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import useStyles from 'fintech/components/profile/startup/GeneralInfo/startupmentors/mentorheader/MentorCarouselHeader.style';

const MentorCarouselHeader = (props) => {
    const { t } = props;
    const classes = useStyles();
    const carouselContext = useContext(CarouselContext);
    const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);

    useEffect(() => {
        function onChange() {
            setCurrentSlide(carouselContext.state.currentSlide);
        }
        carouselContext.subscribe(onChange);
        return () => carouselContext.unsubscribe(onChange);
    }, [carouselContext]);

    return (
        <Typography className={classes.MentorCarouselHeader}>
            {t('profile.startup.assignedMentors') + ` (${currentSlide + 1}/${carouselContext.state.totalSlides})`}
        </Typography>
    );
};

export default withTranslation('profile')(MentorCarouselHeader);
