import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    buttons: {
        alignItems: 'stretch',
        '& button': {
            height: '100%',
        },
    },
}));

export default useStyles;
