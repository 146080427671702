import React, { useEffect, useState } from 'react';
import useStyles from './index.style';
import { useTranslation } from 'react-i18next';
import * as ROUTES from 'fintech/routes';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { getLoggedInUserUuid } from 'fintech/utils/Auth';
import { LANGUAGE_CODES } from 'fintech/constants';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import CBFOLogoTr from 'fintech/static/images/2_satir_yatay-03.png';
import CBFOLogoEn from 'fintech/static/images/ingilizce_yatay-02.png';
import SocialMediaIcons from 'fintech/components/applications/layout/pre-login/SocialMediaIcons';
import { ANONYMOUS } from 'fintech/constants';
import {
    SECTION_WELCOME,
    SECTION_SUPPORT,
    SECTION_TESTIMONIAL,
    SECTION_STARTUP,
    SECTION_INVESTORS,
    SECTION_FAQ,
} from 'fintech/components/util/HomePageConstants';
import CookieBanner from 'fintech/components/legalText/cookieBanner';
import userManualFile from 'fintech/static/documents/uygulama-kilavuzu.pdf';

const Footer = () => {
    const history = useHistory();
    const { t } = useTranslation('preLogin');
    const selectedLanguage = getSystemLanguage();
    const [userManualVisible, setUserManualVisible] = useState(false);
    const logoObj = selectedLanguage === LANGUAGE_CODES.EN ? CBFOLogoEn : CBFOLogoTr;
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const userUuid = getLoggedInUserUuid(keycloak);

    const scrollTo = (element) => {
        const welcomeSectionProperties = document.getElementById(SECTION_WELCOME).getBoundingClientRect();
        const elementProperties = document.getElementById(element).getBoundingClientRect();

        window.scroll({
            top: -welcomeSectionProperties.top + elementProperties.top,
            left: -welcomeSectionProperties.left + elementProperties.left,
            behavior: 'smooth',
        });
    };

    const footerMenuElements = [
        {
            title: t('titles.support'),
            onClick: () => scrollTo(SECTION_SUPPORT),
        },
        {
            title: t('titles.mentors'),
            onClick: () => {
                scrollTo(SECTION_TESTIMONIAL);
                document.getElementById('mentors').click();
            },
        },
        {
            title: t('titles.consultants'),
            onClick: () => {
                scrollTo(SECTION_TESTIMONIAL);
                document.getElementById('consultants').click();
            },
        },
        {
            title: t('titles.startups'),
            onClick: () => scrollTo(SECTION_STARTUP),
        },
        {
            title: t('titles.investors'),
            onClick: () => scrollTo(SECTION_INVESTORS),
        },
        {
            title: t('titles.faqList'),
            onClick: () => scrollTo(SECTION_FAQ),
        },
        {
            userUuidToBeChecked: ANONYMOUS,
            title: t('footer.login'),
            onClick: () => history.push(ROUTES.PLATFORM),
        },
    ];

    const footerMenuElementsJsx = footerMenuElements.map((el, ind) => {
        const isVisible = el.userUuidToBeChecked ? el.userUuidToBeChecked === userUuid : true;
        return (
            isVisible && (
                <span key={ind} onClick={el.onClick}>
                    {el.title}
                </span>
            )
        );
    });

    const handleUserManualDownload = () => {
        if (userManualVisible) {
            const downloadLink = document.createElement('a');
            downloadLink.href = userManualFile;
            downloadLink.download = 'uygulama-kilavuzu.pdf';
            downloadLink.click();
        }
    };

    useEffect(() => {
        if (window.location.hostname.includes('admin')) {
            setUserManualVisible(true);
        } else {
            setUserManualVisible(false);
        }
    }, []);

    return (
        <>
            <footer className={classes.footerWrapper} id="footer">
                <div className={classes.leftWrapper}>
                    {history.location.pathname.includes(ROUTES.HOME) ? (
                        <div className={classes.listStyle}>{footerMenuElementsJsx}</div>
                    ) : (
                        <span
                            style={{ fontSize: 12, marginBottom: 20, cursor: 'pointer' }}
                            onClick={() => history.push(ROUTES.HOME)}
                        >
                            {t('backToHome')}
                        </span>
                    )}
                    <img src={logoObj} className={classes.cbfoLogo} />
                    <div className={classes.address}>{t('footer.address')}</div>
                </div>
                <div className={classes.rightWrapper}>
                    <SocialMediaIcons iconsWrapperClassname={classes.iconsWrapper} />
                    <div className={classes.policyContainer}>
                        {userManualVisible && (
                            <>
                                <span></span>
                                <span onClick={() => handleUserManualDownload()}>{t('footer.downloadUserManual')}</span>
                            </>
                        )}
                        <span onClick={() => history.push(ROUTES.CLARIFICATION_TEXT)}>{t('footer.clarification')}</span>
                        <span onClick={() => history.push(ROUTES.TERMS_OF_USE)}>{t('footer.termsOfUse')}</span>
                        <span onClick={() => history.push(ROUTES.PRIVACY_POLICY)}>{t('footer.privacyPolicy')}</span>
                        <span onClick={() => history.push(ROUTES.COOKIE_POLICY)}>{t('footer.cookiePolicy')}</span>
                    </div>
                    <span className={classes.copyRight}>{t('mobileMenu.copyRight')}</span>
                </div>
            </footer>
            <CookieBanner />
        </>
    );
};

export default Footer;
