import { MessageStatus, RoomStatus } from 'fintech/utils/InboxUtils';
import { InboxActions } from '../actions/ActionTypes';

const initialState = {
    socket: null,
    viewedRoomId: null,
    targetUser: null,
    activeMessage: '',
    rooms: {},
    messages: {},
    isInsideARoom: false,
    newContact: '',
};

const inbox = (state = initialState, action) => {
    const { rooms, viewedRoomId, messages } = state;

    switch (action.type) {
        case InboxActions.SET_VIEWED_ROOM:
            const newViewedRoomId = action.viewedRoomId;
            const targetUser = action.targetUser ?? rooms[newViewedRoomId]?.targetUser;

            return {
                ...state,
                viewedRoomId: newViewedRoomId,
                targetUser,
                activeMessage: '',
            };
        case InboxActions.SET_ACTIVE_MESSAGE:
            return {
                ...state,
                activeMessage: action.text,
            };
        case InboxActions.SET_ROOM_LIST:
            const newRooms = action.rooms.reduce(
                (roomsObject, currentRoom) => {
                    const currentRoomId = currentRoom.id;
                    if (
                        !rooms[currentRoomId] ||
                        rooms[currentRoomId]?.lastMessage?.messageId != currentRoom.lastMessage?.messageId
                    ) {
                        roomsObject[currentRoomId] = currentRoom;
                    }
                    return roomsObject;
                },
                { ...rooms }
            );

            return {
                ...state,
                rooms: newRooms,
            };
        case InboxActions.SEND_MESSAGE:
            const messagesInViewedRoom = [action.message, ...(messages[viewedRoomId] ?? [])];

            return {
                ...state,
                messages: {
                    ...messages,
                    [viewedRoomId]: messagesInViewedRoom,
                },
                activeMessage: '',
            };
        case InboxActions.SET_MESSAGES:
            return {
                ...state,
                messages: {
                    ...messages,
                    [action.roomId]: action.messages,
                },
            };
        case InboxActions.ADD_ROOM:
            return {
                ...state,
                rooms: {
                    ...rooms,
                    [action.roomData.id]: {
                        ...rooms[action.roomData.id],
                        ...action.roomData,
                    },
                },
            };
        case InboxActions.ADD_MESSAGE:
            const { sender } = action.messageData;
            const messagesInTargetRoom = [
                { owner: sender, ...action.messageData },
                ...(messages[action.messageData.roomId] ?? []),
            ];

            return {
                ...state,
                messages: {
                    ...messages,
                    [action.messageData.roomId]: messagesInTargetRoom,
                },
            };
        case InboxActions.GET_RECEIVED:
            const messagesInTheRoom = messages[action.roomId];
            let updatedMessages;
            if (action.messageId) {
                updatedMessages = messagesInTheRoom.map((message) => {
                    if (message.messageId === action.messageId) {
                        message.seenStatus = action.seen ? MessageStatus.SEEN : MessageStatus.RECEIVED;
                    }

                    return message;
                });
            } else {
                const targetUserName = rooms[action.roomId].targetUser.userName;
                updatedMessages = messagesInTheRoom.map((message) => {
                    if (message.owner !== targetUserName && message.seenStatus !== MessageStatus.SEEN) {
                        message.seenStatus = MessageStatus.RECEIVED;
                    }

                    return message;
                });
            }

            return {
                ...state,
                messages: {
                    ...messages,
                    [action.roomId]: [...updatedMessages],
                },
            };
        case InboxActions.REMOVE_MESSAGE:
            const messagesWithRemovedOne = messages[action.roomId].map((message) => {
                if (message.messageId === action.messageId) {
                    message.deleted = true;
                    message.text = '';
                }
                return message;
            });

            return {
                ...state,
                messages: {
                    ...messages,
                    [action.roomId]: [...messagesWithRemovedOne],
                },
            };
        case InboxActions.APPROVE_ROOM:
            const approvedRoom = rooms[action.roomId];
            return {
                ...state,
                rooms: {
                    ...rooms,
                    [action.roomId]: {
                        ...approvedRoom,
                        status: RoomStatus.APPROVED,
                    },
                },
            };
        case InboxActions.REJECT_ROOM:
            const rejectedRoom = rooms[action.roomId];
            return {
                ...state,
                rooms: {
                    ...rooms,
                    [action.roomId]: {
                        ...rejectedRoom,
                        status: RoomStatus.REJECTED,
                    },
                },
            };
        case InboxActions.SET_SOCKET:
            return {
                ...state,
                socket: action.socket,
            };
        case InboxActions.INCREASE_UNREAD:
            const increaseRoom = rooms[action.roomId];
            const increasedCount = (increaseRoom.unreadMessageCount ?? 0) + (action.count ?? 1);

            return {
                ...state,
                rooms: {
                    ...rooms,
                    [action.roomId]: {
                        ...increaseRoom,
                        unreadMessageCount: increasedCount,
                    },
                },
            };
        case InboxActions.DECREASE_UNREAD:
            const decreaseRoom = rooms[action.roomId];
            const decreasedCount = (decreaseRoom.unreadMessageCount ?? 0) - (action.count ?? 1);

            return {
                ...state,
                rooms: {
                    ...rooms,
                    [action.roomId]: {
                        ...decreaseRoom,
                        unreadMessageCount: decreasedCount,
                    },
                },
            };
        case InboxActions.CLEAR_VIEWED_ROOM:
            return {
                ...state,
                viewedRoomId: null,
            };
        case InboxActions.REMOVE_ROOM_LAST_MESSAGE:
            const deletedLastMessage = {
                ...rooms[action.roomId].lastMessage,
                deleted: true,
                text: '',
            };

            return {
                ...state,
                rooms: {
                    ...rooms,
                    [action.roomId]: {
                        ...rooms[action.roomId],
                        lastMessage: deletedLastMessage,
                    },
                },
            };
        case InboxActions.UPDATE_ROOM_LAST_MESSAGE:
            return {
                ...state,
                rooms: {
                    ...rooms,
                    [action.roomId]: {
                        ...rooms[action.roomId],
                        lastMessage: action.message,
                    },
                },
            };
        case InboxActions.ADD_MESSAGES:
            return {
                ...state,
                messages: {
                    ...messages,
                    [viewedRoomId]: [...(messages[viewedRoomId] ?? []), ...action.messages],
                },
            };
        case InboxActions.SET_USER_INFO:
            return {
                ...state,
                rooms: {
                    ...rooms,
                    [action.roomId]: {
                        ...rooms[action.roomId],
                        targetUser: {
                            ...rooms[action.roomId].targetUser,
                            avatar: action.avatarUuid,
                            type: action.userType,
                        },
                    },
                },
            };
        case InboxActions.SET_SENDER_INFO:
            return {
                ...state,
                senderUuid: action.uuid,
                senderType: action.userType,
            };
        case InboxActions.SET_HAS_BLOCKED_TARGET:
            return {
                ...state,
                rooms: {
                    ...rooms,
                    [action.roomId]: {
                        ...rooms[action.roomId],
                        targetUser: {
                            ...rooms[action.roomId].targetUser,
                            hasBlocked: action.value,
                        },
                    },
                },
            };
        case InboxActions.SET_INSIDE_A_ROOM:
            return {
                ...state,
                isInsideARoom: action.isInsideARoom,
            };
        case InboxActions.MARK_ALL_AS_READ:
            const targetUserName = rooms[action.roomId].targetUser.userName;
            const messagesToBeMarked = messages[action.roomId];
            const hasUnreadMessages = messagesToBeMarked.filter(
                ({ owner, seenStatus }) => owner !== targetUserName && seenStatus !== MessageStatus.SEEN
            ).length;

            if (hasUnreadMessages) {
                const messagesMarkedAsRead = messagesToBeMarked.map((message) => {
                    if (message.owner !== targetUserName) {
                        message.seenStatus = MessageStatus.SEEN;
                    }
                    return message;
                });

                return {
                    ...state,
                    messages: {
                        ...messages,
                        [action.roomId]: messagesMarkedAsRead,
                    },
                };
            }
            return state;
        case InboxActions.SET_NEW_CONTACT:
            return {
                ...state,
                newContact: action.username,
            };
        default:
            return state;
    }
};

export default inbox;
