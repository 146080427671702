import { getRequest } from 'fintech/api/api';
import { SUPPORT_PROGRAM } from 'fintech/constants/apis';
import { SupportProgramActions } from 'fintech/store/actions/ActionTypes';

const setPrograms = (data) => ({
    type: SupportProgramActions.SET_SUPPORT_PROGRAMS,
    data: data,
});

const setLoading = () => ({ type: SupportProgramActions.GET_SUPPORT_PROGRAMS });

const setError = () => ({ type: SupportProgramActions.SET_SUPPORT_PROGRAMS_ERROR });

export const fetchSupportProgramList = (keycloak) => {
    return (dispatch) => {
        dispatch(setLoading());
        getRequest(SUPPORT_PROGRAM.getSupportProgramList(), keycloak)
            .then((res) => dispatch(setPrograms(res.data)))
            .catch(() => {
                dispatch(setError());
            });
    };
};

//Fetch the viewed event in case of page refresh, otherwise the event can be retrieved from the event
export const setViewedProgram = (viewedProgram) => ({
    type: SupportProgramActions.SET_VIEWED_SUPPORT_PROGRAM,
    data: viewedProgram,
});

const setProgramLoading = () => ({ type: SupportProgramActions.GET_VIEWED_SUPPORT_PROGRAM });

const setProgramError = () => ({ type: SupportProgramActions.SET_VIEWED_SUPPORT_PROGRAM_ERROR });

export const fetchViewedProgram = (keycloak, id) => {
    return (dispatch) => {
        dispatch(setProgramLoading());
        getRequest(SUPPORT_PROGRAM.getSupportProgramDetail(id), keycloak)
            .then((resp) => dispatch(setViewedProgram(resp.data)))
            .catch(() => {
                dispatch(setProgramError());
            });
    };
};
