import * as React from 'react';

function SvgIconSinglePassword(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <filter id="3fvw6c321a" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g
                                filter="url(#3fvw6c321a)"
                                transform="translate(-1055 -133) translate(1044 65) translate(0 13) translate(11 14)"
                            >
                                <g>
                                    <path
                                        fill="#3F5162"
                                        fillRule="nonzero"
                                        d="M16.5 19.188c.408 0 .762-.144 1.062-.432.3-.288.45-.636.45-1.044v-7.524c0-.408-.15-.756-.45-1.044-.3-.288-.654-.432-1.062-.432h-.738V7.2c0-.684-.168-1.314-.504-1.89-.336-.576-.792-1.032-1.368-1.368-.576-.336-1.206-.504-1.89-.504-.684 0-1.314.168-1.89.504-.576.336-1.032.792-1.368 1.368-.336.576-.504 1.206-.504 1.89v1.512H7.5c-.408 0-.762.144-1.062.432-.3.288-.45.636-.45 1.044v7.524c0 .408.15.756.45 1.044.3.288.654.432 1.062.432h9zM14.322 8.712H9.678V7.2c0-.408.105-.792.315-1.152.21-.36.495-.645.855-.855.36-.21.744-.315 1.152-.315.408 0 .792.105 1.152.315.36.21.645.495.855.855.21.36.315.744.315 1.152v1.512zM12 15.462c-.408 0-.762-.15-1.062-.45-.3-.3-.45-.654-.45-1.062 0-.408.15-.762.45-1.062.3-.3.654-.45 1.062-.45.408 0 .762.15 1.062.45.3.3.45.654.45 1.062 0 .408-.15.762-.45 1.062-.3.3-.654.45-1.062.45z"
                                        transform="translate(0 41)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconSinglePassword;
