import * as React from 'react';

function SvgIconsSingleSearch(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#3F5162" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M18.286 19.286c.625 0 1.143-.518 1.143-1.143 0-.304-.125-.598-.33-.804l-3.063-3.062c.723-1.045 1.107-2.295 1.107-3.563 0-3.473-2.813-6.285-6.286-6.285-3.473 0-6.286 2.812-6.286 6.285 0 3.473 2.813 6.286 6.286 6.286 1.268 0 2.518-.384 3.563-1.107l3.062 3.053c.206.215.5.34.804.34zm-7.429-4.572c-2.205 0-4-1.794-4-4 0-2.205 1.795-4 4-4 2.206 0 4 1.795 4 4 0 2.206-1.794 4-4 4z"
                                    transform="translate(-314.000000, -259.000000) translate(280.000000, 223.000000) translate(20.000000, 20.000000) translate(14.000000, 16.000000)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleSearch;
