import { makeStyles } from '@material-ui/core';
import { buttonStyle, fontStyle } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down(900)]: {
            flexDirection: 'column-reverse',
        },
    },
    sectionTitle: {
        fontWeight: '500',
        fontSize: '36px',
        color: '#3f5162',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    sectionDef: {
        fontSize: '16px',
        maxWidth: '488px',
        margin: '9px 0 25px 0',
        color: '#3f5162',
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            textAlign: 'center',
            marginBottom: 40,
        },
    },
    startupImg: {
        maxWidth: '100%',
        height: 'auto',
        padding: 0,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            width: 320,
            display: 'block',
            margin: '0 auto',
        },
    },
    applyButton: {
        ...fontStyle,
        ...buttonStyle,
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
        },

        '@media (max-width:320px)': {
            fontSize: 11,
        },
    },
}));

export default useStyles;
