import React, { useState } from 'react';
import useStyles, { StyledTitle } from 'fintech/components/profile/user/IntroGrid/index.style';
import { useKeycloak } from '@react-keycloak/web';
import { Grid } from '@material-ui/core';
import StrechedGridItem from 'fintech/components/profile/common/StechedGridItem';
import ShowMore from 'fintech/components/ui/ShowMore';
import { poppins500_14px, styledTagNotInDatagridStyle } from 'fintech/components/style/common';
import { LocalOffer } from '@material-ui/icons';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import { StyledTag } from 'fintech/components/ui/button/common';
import IntroHeader from 'fintech/components/profile/user/Intro/Header';
import IntroTab from 'fintech/components/profile/user/Intro/IntroTab';
import ErrorBoundary from 'fintech/components/util/ErrorBoundary';
import IntroDetails from 'fintech/components/profile/user/Intro/Details';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalConsultantProfileUpdate from 'fintech/components/profile/user/modal/UpdateModal/consultant/ModalConsultantProfileUpdate';
import { fetchUser } from 'fintech/store/actions/user';
import { hasRoles } from 'fintech/utils/Auth';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';

function IntroGrid(props) {
    const { userUuid } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const { data: fetchedData } = useSelector((state) => state.user.intro);
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    const [openEditModal, setOpenEditModal] = useState(false);

    const { name, surname, expertise, visitTimes } = fetchedData;
    const infoList = fetchedData.userProfileInfoList;

    const isAdmin = hasRoles(keycloak, ROLE_ADMIN);

    let biography = '';
    for (let info of infoList) {
        if (info.field === 'biography') {
            biography = info.value;
            break;
        }
    }

    const updateCallback = (isUpdated) => {
        if (isUpdated) {
            dispatch(fetchUser(keycloak, userUuid));
        }
    };

    const getDuoGridItems = () => {
        const itemsJSX = [];
        itemsJSX.push(
            <StrechedGridItem
                title={<StyledTitle>{t('profile.user.biography')}</StyledTitle>}
                content={
                    <span style={{ ...poppins500_14px, wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                        <ShowMore text={biography} limit={1400} />
                    </span>
                }
            />
        );

        const expertiseJSX = expertise && (
            <StyledTag startIcon={<LocalOffer fontSize="small" />} style={styledTagNotInDatagridStyle}>
                {expertise.name}
            </StyledTag>
        );
        itemsJSX.push(
            <StrechedGridItem
                title={<StyledTitle>{t('profile.user.expertise')}</StyledTitle>}
                content={
                    <TagGroup limit={5} items={expertiseJSX} clickableStyledTagStyle={styledTagNotInDatagridStyle} />
                }
            />
        );
        return itemsJSX;
    };
    return (
        <>
            <IntroTab times={visitTimes} />
            <ErrorBoundary message={t('profile.errorMessage')}>
                <Grid
                    className={classes.StupIntroCard}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                >
                    {userUuid && (
                        <IntroHeader
                            name={`${name} ${surname}`}
                            userUuid={userUuid}
                            hideSendMessage={fetchedData?.hideSendMessage}
                            editable={isAdmin}
                            onClickEditCallback={() => setOpenEditModal(true)}
                            username={fetchedData.userName}
                        />
                    )}
                    <IntroDetails duoItems={getDuoGridItems()} />
                </Grid>
                {openEditModal && (
                    <ModalConsultantProfileUpdate
                        title={t('profile.user.editProfileCardCounselor')}
                        open={openEditModal}
                        setOpen={setOpenEditModal}
                        updateCallback={(isUpdated) => updateCallback(isUpdated)}
                        userUuid={userUuid}
                        fetchedData={fetchedData}
                    />
                )}
            </ErrorBoundary>
        </>
    );
}

export default IntroGrid;
