import * as React from 'react';

function SvgIconsSingleArrow(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <defs>
                <filter id="q7v4woqlza" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g filter="url(#q7v4woqlza)" transform="translate(-275 -1150) translate(264 1129.5)">
                        <g>
                            <path
                                fill="#3F5162"
                                fillRule="nonzero"
                                d="M14.425 16c.108 0 .19-.028.244-.083l3.25-3.648c.054-.055.081-.138.081-.248 0-.11-.027-.194-.081-.249-.027-.028-1.11-1.244-3.25-3.648-.163-.165-.325-.165-.488 0-.162.166-.162.332 0 .498l2.722 3.067H6.3c-.217 0-.325.11-.325.332 0 .22.108.331.325.331h10.603l-2.722 3.068c-.162.165-.162.331 0 .497.054.055.136.083.244.083z"
                                transform="translate(11 20.5)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleArrow;
