import { ConsultantFAQSearchActions } from 'fintech/store/actions/ActionTypes';
import { APIS } from 'fintech/constants';
import { withPagination } from 'fintech/constants/apis';
import { postRequest } from 'fintech/api/api';

export const searchBoxOnChange = (data) => ({
    type: ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_SEARCHBOX_INPUT,
    data,
});

export const updatePageNumber = (pageNumber) => ({
    type: ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_PAGINATION,
    data: pageNumber,
});

export const resetPagination = () => ({ type: ConsultantFAQSearchActions.RESET_CONSULTANT_FAQ_PAGINATION });
export const resetSearchBox = () => ({ type: ConsultantFAQSearchActions.RESET_CONSULTANT_FAQ_SEARCHBOX });
export const setExpertise = (data) => ({ type: ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_EXPERTISE, data });
export const setSelectedExpertise = (data) => ({
    type: ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_SELECTED_EXPERTISE,
    data,
});

export const resetPaginationOnSearchBoxOrExpertiseType = () => ({
    type: ConsultantFAQSearchActions.RESET_PAGINATION_FROM_SEARCHBOX_OR_EXPERTISE,
});

export const fetchQuestions = ({ keycloak, data, errorCallback, isInfiniteScroll }) => {
    return (dispatch, getState) => {
        const { questions } = getState().faqSearch;
        const { pagination } = questions;

        let queryData = data;

        if (data) {
            queryData = data;
        } else {
            queryData = {
                searchTerm: '',
                selectedExpertiseTerm: '',
            };
        }

        dispatch({ type: ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_LOADING });

        const url = withPagination(APIS.CONSULTANT.getFaqSearchUrl(), pagination.pageSize, pagination.page - 1);
        postRequest(`${url}&sort=ASC`, keycloak, queryData)
            .then((resp) => {
                const { content, last } = resp.data;

                // // Save last query
                dispatch({ type: ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_SEARCHBOX_LAST_QUERY, data: queryData });

                dispatch({
                    type: ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_DATA,
                    data: content,
                    hasMore: !last,
                    isInfiniteScroll: !!isInfiniteScroll,
                });
            })
            .catch(() => {
                dispatch({ type: ConsultantFAQSearchActions.SET_CONSULTANT_FAQ_LOAD_ERROR });
                errorCallback();
            });
    };
};
