import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import BoxedSearchBar from 'fintech/components/search/BoxedSearchBar';
import consultantImage from 'fintech/static/images/consultant.jpg';
import consultantImageMobile from 'fintech/static/images/consultant-mobil.jpg';
import { ConsultantSearchActions } from 'fintech/store/actions/ActionTypes';
import {
    fetchGridData,
    resetAllFilters,
    setGridPage,
    searchBoxOnChange,
    setSearchBoxFilterStatus,
    setExpertise,
    updateSelectedExpertise,
    getActiveConsultantCount,
} from 'fintech/store/actions/consultantSearch';
import { formatNumericValue } from 'fintech/utils/StringUtils';
import SearchCardGrid from 'fintech/components/search/CardGrid';
import MentorCard from 'fintech/components/search/mentor/MentorCard';
import ExpertiseSelect from 'fintech/components/search/consultant/ExpertiseSelect/ExpertiseSelect';
import GridPagination from 'fintech/components/pagination/GridPagination';
import { CONSULTANTS_PROFILE } from 'fintech/routes';
import _ from 'lodash';

const AllConsultants = ({ expertiseTypeInitialState }) => {
    const { t } = useTranslation('consultantSearch');
    const { keycloak } = useKeycloak();
    const { totalNumber, selectedExpertiseType } = useSelector((state) => state.consultantSearch.global);
    const { rows, pagination, isLoading, gridDataLoading } = useSelector((state) => state.consultantSearch.grid);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const errorCallback = (msg = t('idea.updateError')) => enqueueSnackbar(msg, { variant: 'error' });
    const [placeholderText, setPlaceholderText] = useState(t('searchbar.placeholder'));

    const type = 'consultantSearch';

    const initData = () => {
        dispatch(resetAllFilters());
        dispatch(fetchGridData({ keycloak, errorCallback }));
        dispatch(setExpertise(_.cloneDeep(expertiseTypeInitialState)));
    };

    const makeSearchHandler = (input, filters) => {
        const searchFilter = filters?.find((fi) => fi.status);
        if (searchFilter) {
            dispatch({ type: ConsultantSearchActions.RESET_CONSULTANT_GRID_PAGINATION });
            dispatch(
                fetchGridData({
                    keycloak,
                    data: {
                        nameSelected: filters.find(({ label }) => label === 'nameSelected').status,
                        userExpertiseSelected: filters.find(({ label }) => label === 'userExpertiseSelected').status,
                        searchTerm: input,
                        selectedExpertiseTerm: selectedExpertiseType?.name,
                    },
                    errorCallback,
                })
            );
        } else {
            errorCallback(t('searchbar.validationText'));
        }
    };

    useEffect(() => {
        window.scroll({ top: 0, left: 0 });
        initData();
        dispatch(getActiveConsultantCount(keycloak));
    }, []);

    const gridPageChangeHandler = (e, pageNumber) => {
        dispatch(setGridPage(pageNumber));
        dispatch(fetchGridData({ keycloak, errorCallback, useLastQueryType: true }));
    };

    const cards = rows?.map((usr) => ({
        uuid: usr.uuid,
        component: <MentorCard cardType={type} {...usr} title={t('cards.expertise')} personUrl={CONSULTANTS_PROFILE} />,
    }));

    const handleExpertiseSelectClick = (selectedType) => {
        const _selectedType = { ...selectedType };
        _selectedType.name = _selectedType.name === t('expertise.all') ? '' : _selectedType.name;
        dispatch(updateSelectedExpertise(_selectedType));
        setPlaceholderText(t(`searchbar.consultantSearch.${_selectedType.id}`));
    };
    const clearAllFilters = () => {
        dispatch(setExpertise(_.cloneDeep(expertiseTypeInitialState)));
        dispatch(resetAllFilters());
    };
    return (
        <>
            <BoxedSearchBar
                type={type}
                backgroundImage={consultantImage}
                backgroundImageMobile={consultantImageMobile}
                labelHtml={t('searchbar.registeredConsultants', { count: formatNumericValue(totalNumber) })}
                placeholderText={placeholderText}
                searchBoxOnChange={searchBoxOnChange}
                setSearchBoxFilterStatus={setSearchBoxFilterStatus}
                makeSearch={makeSearchHandler}
                resetAllFilters={clearAllFilters}
                fetchGridData={() => dispatch(fetchGridData({ keycloak, errorCallback }))}
                mirrorHorizontally={false}
            />

            <ExpertiseSelect
                errorCallback={errorCallback}
                fetchData={fetchGridData}
                type={type}
                handleExpertiseSelect={handleExpertiseSelectClick}
            />

            <SearchCardGrid cards={cards} type={type} clearAll={initData} />
            {rows && rows.length > 0 && (
                <div>
                    <GridPagination
                        controlled
                        disabled={isLoading || gridDataLoading}
                        {...pagination}
                        handleChange={gridPageChangeHandler}
                    />
                </div>
            )}
        </>
    );
};

export default AllConsultants;
