import consultantImage from 'fintech/static/images/icons-multiple-danisman-hamburger.svg';
import educationImage from 'fintech/static/images/icons-multiple-egitim.svg';
import reportImage from 'fintech/static/images/icons-multiple-rapor.svg';

// TÜRKÇE VE İNGİLİZCE ALANLAR

// Türkçe Alanlar
export const SUPPORT_PROGRAM_ONE_PAGE_LIST = [
    {
        testEnvironmentId: '1',
        uatEnvironmentId: '1',
        productionEnvironmentId: '1',
        programTitle: 'Finansal Teknolojiler Erken Aşama Girişim Hızlandırma Programı',
        programSubTitle:
            'Dijital Bankacılık, Dijital Borçlanma, Dijital Varlık Yönetimi, Dijital Ödemeler, Kripto Varlıklar, Insurtech, Dijital Sermaye Piyasaları, Dijital Tasarruf, İslami Fintek gibi alanlarda fikirden ürüne yol haritanız.',
        programAboutDescriptionOne:
            ', İstanbul Finans Merkezi’nin üstlendiği misyona uygun olarak geleceğin finans teknolojilerini geliştirmek amacıyla fikirden ürüne ve uluslararası pazarlara açılma yolunda bilgi ve teknoloji odaklı erken aşama girişimlerin desteklenmesi amacıyla geliştirilmiş bir hızlandırma programıdır.',
        programAboutDescriptionTwo:
            'FinTech Hub’ın erken aşama hızlandırma programı kapsamında Fintek alanındaki iş fikri sahiplerine yönelik ihtiyaç duydukları temel girişimcilik becerilerinin geliştirilmesi, iş fikirlerinin her anlamda test edilmesi, iş fikirlerinin ürüne dönüşme ve ticarileşme noktasında yapılması gereken faaliyetler alt yapı, eğitim, mentörlük ve çeşitli danışmanlık destekleri ile sağlanmaktadır.',
        educationAndSeminarsDescription:
            'Hızlandırma programına dahil olan ekipler, Fintek girişimlerine özgü hazırlanan eğitim ve seminerlere erişme imkanı yakalar. Program kapsamında verilecek bazı önemli eğitim ve seminer konuları:',
        supportAreaData: [
            {
                id: 1,
                title: 'Altyapı',
                logo: educationImage,
                description: [
                    'Merkezi konumda paylaşımlı ofis desteği',
                    'Tam donanımlı modern çalışma alanları',
                    'Teknik ve teknolojik altyapı',
                    'Ulusal ve uluslararası faydalı veri tabanlarına erişim',
                ],
            },
            {
                id: 2,
                title: 'Eğitim, mentörlük ve ağlara erişim',
                logo: reportImage,
                description: [
                    'Fintek girişimlerine özgü eğitim ve seminerlere katılım',
                    'İhtiyaca uygun mentörlük desteği',
                    'Ulusal ve uluslararası fintek ağlarına erişim',
                    'Farklı platformlarda tanıtım desteği',
                    'Başarılı girişimcilerden tecrübe aktarımı',
                    'Sektör liderleri ile tanışma ve işbirliği imkanı',
                    'Yatırımcılarla eşleştirme',
                    'Uluslararası hızlandırma programlarına dahil olma imkanı',
                ],
            },
            {
                id: 3,
                title: 'Danışmanlıklar',
                logo: consultantImage,
                description: [
                    'Hukuk ve Regülasyon',
                    'Hibe ve Teşvik',
                    'Sürdürülebilir Vergi Yönetimi ',
                    'Şirket Kuruluşu',
                    'Yatırım Yönetimi',
                    'İş Geliştirme ve Pazarlama',
                ],
            },
        ],
        continuaAreaData: [
            {
                question: 'Başvuru Değerlendirme Süreci',
                answer:
                    'Fintek girişimcilerinin online olarak başvurularının alınmasının ardından değerlendirme kurulu tarafından yapılan analiz ve puanlama ile programa dahil edilecek olan girişimciler ve girişimci adayları belirlenecek ve ilan edilecektir.',
            },
            {
                question: 'Eğitim-Mentörlük Süreci',
                answer:
                    'Fintek Girişimcilerinin ihtiyaç duyduğu temel bilgi ve donanımın kazandırılması amacıyla sektörün önde gelen temsilcileri ve akademisyenleri ile eğitim, seminer, mentörlük ve danışmanlıklar verilecektir. Fintek, regülasyon, vergi, girişimcilik, iş planı, ekip yönetimi gibi yaklaşık 20 farklı eğitim ve seminerin gerçekleştirilmesi ile tamamlanacaktır. ',
            },
            {
                question: 'Demo Day Sonrası',
                answer:
                    'Hızlandırma Programının ardından girişimcilerin ihtiyaç duyduğu daha spesifik alanlarda mentörlük destekleri verilmeye devam edecektir. Ayrıca girişimci-yatırımcı eşleştirmeleri, alt yapı desteklerinin yanı sıra şirketleşme, hukuk ve regülasyon, hibe ve teşvikler gibi alanlarda danışmanlık destekleri verilecektir. Programın amacı başarılı olma potansiyeline sahip girişimci adaylarına çok boyutlu destekler sağlayarak iş fikirlerinin hayata geçmesini sağlamak ve fintek ekosistemini zenginleştirmek ve ülkemizin rekabetçi gücüne katkı sağlamaktır. ',
            },
        ],
        educationAreaData: [
            'Fintek 101',
            'Yalın Girişim/İş Modeli Kanvası',
            'Agile Proje Yönetimi',
            'Finansal Hizmetlerde İş Modeli İnovasyonu',
            'Fintech Girişimleri için Büyüme Stratejileri',
            'Kurum – Fintech İş Birlikleri ve Yeni İş Modelleri',
            'Fintech’ler İçin Pazarlama (Pazar Analizi ve Dijital Pazarlama)',
            'Fintech Regülasyonları',
            'RegTech ve Sandbox Yapıları',
            'Yatırımcıya Nasıl Sunum Yapılır',
            'Fintech Ekosisteminde Değer Oluşturmak',
            'Finansal Hizmetlerde Teknolojik Trendler',
            'Kurumların Finansallaşması',
            'İnovasyon Stratejisi oluşturma',
            'Finansın Geleceğini Görebilme',
            'Fintech ve Finansal Hizmetler Sektörünün Geleceği',
            'Finansal Hizmetlerde İnovasyon: Fırsatlar ve Tehditler',
            'Muhasebe, Şirketleşme ve Muafiyet Destekleri',
        ],
        timesheetAreaData: [
            {
                id: '1',
                description: 'Program çağrısı ve başvuru aşaması',
                date: '15.01.2023 – 15.02.2023',
                href: '',
            },
            {
                id: '2',
                description: 'Ön değerlendirme',
                date: '25.02.2023',
                href: '',
            },
            {
                id: '3',
                description: 'Değerlendirme <br/>(Video veya sunum)',
                date: '01.03.2023',
                href: '',
                anchorId: '3_1',
            },
            {
                id: '6',
                description: 'Demo day',
                date: '25.05.2023',
                href: '',
                anchorLeftId: '6_1',
            },
            {
                id: '5',
                description: 'Mentörlük oturumları',
                date: '01.03.2023 – 24.05.2023',
                href: '',
            },
            {
                id: '4',
                description: 'Eğitim ve seminerler',
                date: '01.03.2023 - 30.04.2023',
                href: '',
                anchorId: '3_2',
            },
            {
                id: '7',
                description: 'Kuluçka, şirketleşme ve yatırım süreçleri',
                date: null,
                href: '/',
                hrefTitle: 'PROGRAMA BAŞVUR',
            },
        ],
    },
];

// ingilizce alanlar
export const SUPPORT_PROGRAM_ONE_PAGE_LIST_EN = [
    {
        testEnvironmentId: '45',
        uatEnvironmentId: '45',
        productionEnvironmentId: '1',
        programTitle: 'Financial Technologies Early Stage Startup Accelerator Program',
        programSubTitle:
            'Your roadmap from idea to product in areas such as Digital Banking, Digital Lending, Digital Asset Management, Digital Payments, Crypto Assets, Insurtech, Digital Capital Markets, Digital Savings, Islamic Fintech.',
        programAboutDescriptionOne:
            ' is an accelerator program developed to support information and technology-oriented early stage initiatives on the way from ideas to products and to international markets in order to develop the financial technologies of the future in accordance with the mission of the İstanbul Financial Center.',
        programAboutDescriptionTwo:
            'For the business idea owners in Fintech field, the activities to be carried out at the point of developing basic entrepreneurship skills that they need, testing business ideas in every sense, turning business ideas into products and commercializing them, infrastructure, training, mentoring and various consultancy supports are provided within the scope of Fintech Hub’s early stage accelerator program.',
        educationAndSeminarsDescription:
            'Teams involved in the accelerator program have the opportunity to access training and seminars specific to Fintech startup. Some important training and seminar topics to be given within the scope of the program:',
        supportAreaData: [
            {
                id: 1,
                title: 'Infrastructure',
                logo: educationImage,
                description: [
                    'Centralized shared office support',
                    'Fully equipped modern workspaces',
                    'Technical and technological infrastructure',
                    'Access to national and international useful databases',
                ],
            },
            {
                id: 2,
                title: 'Access to training, mentoring and networks',
                logo: reportImage,
                description: [
                    'Participation in training and seminars specific to fintech startup',
                    'Relevant mentoring support',
                    'Access to national and international fintech networks',
                    'Promotional support on different platforms',
                    'Experience sharing by successful entrepreneurs',
                    'Opportunity to meet and collaborate with sector leaders',
                    'Matching with investors',
                    'Opportunity to be included in international accelerator programs',
                ],
            },
            {
                id: 3,
                title: 'Consultancies',
                logo: consultantImage,
                description: [
                    'Law and regulation',
                    'Grant and incentive',
                    'Sustainable tax management',
                    'Company establishment',
                    'Investment management',
                    'Business development and marketing',
                ],
            },
        ],
        continuaAreaData: [
            {
                question: 'Application Evaluation Process',
                answer:
                    'After the online applications of Fintech entrepreneurs are received, the entrepreneurs and entrepreneur candidates to be included in the program will be announced with the analysis and scoring made by the evaluation board.',
            },
            {
                question: 'Training-Mentoring Process',
                answer:
                    'Training, seminars, mentorship and consultancy will be provided with the leading representatives and academicians of the sector in order to provide the basic knowledge and equipment needed by Fintech Entrepreneurs. This process will be completed with approximately 20 different trainings and seminars such as fintech, regulation, tax, entrepreneurship, business plan and team management.',
            },
            {
                question: 'After Demo Day',
                answer:
                    'After the Accelerator Program, mentoring support will continue to be provided in more specific areas that entrepreneurs need. In addition to entrepreneur-investor matching, infrastructure support, consultancy support will be provided in areas such as incorporation, law and regulation, grants and incentives. The aim of the program is to provide multidimensional support to entrepreneur candidates with the potential to be successful, to enable business ideas to come to life, to enrich the fintech ecosystem and to contribute to the competitive power of our country.',
            },
        ],
        educationAreaData: [
            'Fintech 101',
            'Lean Startup/Business Model Canvas',
            'Agile Project Management',
            'Business Model Innovation in Financial Services',
            'Growth Strategies for Fintech Startups',
            'Institution – Fintech Collaborations and New Business Models',
            'Marketing for Fintechs (Market Analysis and Digital Marketing)',
            'Fintech Regulations',
            'RegTech and Sandbox Structures',
            'How to Make a Presentation to the Investor',
            'Creating Value in the Fintech Ecosystem',
            'Technological Trends in Financial Services',
            'Financialization of Institutions',
            'Creating an Innovation Strategy',
            'Seeing the Future of Finance',
            'The Future of Fintech and Financial Services Sector',
            'Innovation in Financial Services: Opportunities and Threats',
            'Accounting, Incorporation and Exemption Supports',
        ],
        timesheetAreaData: [
            {
                id: '1',
                description: 'Program announcement and application',
                date: '15.01.2023 – 15.02.2023',
                href: '',
            },
            {
                id: '2',
                description: 'Pre-assessment',
                date: '25.02.2023',
                href: '',
            },
            {
                id: '3',
                description: 'Evaluation <br/>(Video or Presentation)',
                date: '01.03.2023',
                href: '',
                anchorId: '3_1',
            },
            {
                id: '6',
                description: 'Demo day',
                date: '25.05.2023',
                href: '',
                anchorLeftId: '6_1',
            },
            {
                id: '5',
                description: 'Mentoring sessions',
                date: '01.03.2023 – 24.05.2023',
                href: '',
            },

            {
                id: '4',
                description: 'Training and seminars',
                date: '01.03.2023 - 30.04.2023',
                href: '',
                anchorId: '3_2',
            },

            {
                id: '7',
                description: 'Incubation, incorporation and investment processes',
                date: null,
                href: '/',
                hrefTitle: 'Apply Program',
            },
        ],
    },
];
