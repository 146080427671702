import * as React from 'react';

function SvgIconsMultipleDuyuru(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fillRule="nonzero" fill="none">
                <path fill="#2D2D2E" d="M5.3 18.5l-1.067-.967-.666.7 1.5 1.434L11.4 17.3l-.367-.933z" />
                <path
                    fill="#3F5162"
                    d="M12.067 1.8L.267 18.5l3.8 3.8 4.266-1.567v1.5h7.8v-4.366l6.2-2.267L12.067 1.8zm3.1 19.433H9.333v-.866l5.8-2.134.034 3zM16.23 5.648l2.713-4.63.863.505-2.713 4.63zM18.802 8.861l4.627-1.61.33.944-4.628 1.61z"
                />
                <path fill="#D9DCE0" d="M1.567 18.4L12.1 3.5l8.633 11.6L4.3 21.133z" />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleDuyuru;
