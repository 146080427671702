import { NavigationActions } from "../actions/ActionTypes";

const initialState = {
    titleText: '',
}

const navigation = (state = initialState, action) => {
    switch (action.type) {
        case NavigationActions.SET_TITLE_TEXT:
            return {
                ...state,
                titleText: action.text,
            }
        default:
            return state;
    }
};

export default navigation;