import * as React from 'react';

function SvgIconsSingleArrowright(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
            <defs>
                <filter id="icons-single-arrowright_svg__a" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g filter="url(#icons-single-arrowright_svg__a)" fill="none" fillRule="evenodd">
                <path
                    fill={props.fill ? props.fill : '#3F5162'}
                    fillRule="nonzero"
                    d="M19.233 21.333c.145 0 .253-.037.325-.11l4.334-4.864c.072-.073.108-.184.108-.331 0-.148-.036-.258-.108-.332-.036-.037-1.48-1.658-4.334-4.864-.216-.22-.433-.22-.65 0-.216.222-.216.443 0 .664l3.63 4.09H8.4c-.289 0-.433.147-.433.442 0 .294.144.442.433.442h14.138l-3.63 4.09c-.216.22-.216.442 0 .663.073.073.18.11.325.11z"
                />
            </g>
        </svg>
    );
}

export default SvgIconsSingleArrowright;
