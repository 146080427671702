import React from 'react';
import useStyles from './index.style';
import { useTranslation } from 'react-i18next';
import PolicyHeader from '../policy-header';
import Footer from '../../sections/Footer';
import { useEffect } from 'react';

const PrivacyPolicy = () => {
  const classes = useStyles();
  const { t } = useTranslation('policy');

  useEffect(() => {
    document.getElementById("policy-header").scrollIntoView();
  }, []);

  return (
    <section>
      <PolicyHeader></PolicyHeader>
      <div className={classes.title}>{t('privacyPolicyTitle')}</div>
      <div className={classes.content}
        dangerouslySetInnerHTML={{
          __html: t('privacyPolicy'),
        }}></div>
      <Footer></Footer>
    </section>
  )
};

export default PrivacyPolicy;