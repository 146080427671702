import { REST_API_ADDRESS, SOCKET_ADDRESS, SOCKET_PATH } from 'fintech/constants/inbox';
import ics from 'fintech/chat-socket';
import { ENTREPRENEURS_ATTEMPT, INVESTORS_ESTABLISHMENT, PERSONS_PROFILE } from 'fintech/routes';
import {
    deleteMessage,
    getMessage,
    getReceived,
    getSenderInfo,
    getUsersInfo,
    markAllAsRead,
    setMessages,
    setRoomList,
    setSocket,
} from 'fintech/store/actions/inbox';

const PROFILE_TYPES = {
    USER: PERSONS_PROFILE,
    STARTUP: ENTREPRENEURS_ATTEMPT,
    INVESTOR: INVESTORS_ESTABLISHMENT,
};

export const configureChatSocket = (dispatch, keycloak) => {
    const { token, tokenParsed } = keycloak;
    const chatSocket = new ics(token, SOCKET_ADDRESS, SOCKET_PATH, REST_API_ADDRESS);
    const usernamesRoomsMap = {};

    dispatch(setSocket(chatSocket));
    dispatch(getSenderInfo(tokenParsed.preferred_username, keycloak));
    chatSocket.fetchRoomList((rooms) => {
        dispatch(setRoomList(rooms));
        rooms.forEach((room) => {
            const { id: roomId, targetUser } = room;
            chatSocket.fetchMessages({ roomId }, (messages) => {
                chatSocket.getRoomMessages({ roomId, lastMessageQueuedAt: messages[0]?.queuedAt }, (socketMessages) => {
                    dispatch(setMessages(roomId, [...socketMessages, ...messages]));
                });
            });
            usernamesRoomsMap[targetUser.userName] = roomId;
        });
        chatSocket.connect(() => {
            chatSocket.getRoomInfo(
                {
                    requests: rooms.map(({ roomId, lastMessage: { queuedAt } }) => ({
                        roomId,
                        lastMessageQueuedAt: queuedAt,
                    })),
                },
                (pendingRoomInfo) => {
                    dispatch(
                        setRoomList(
                            rooms.map(({ roomId, unreadMessageCount, ...rest }) => {
                                const { lastMessage, unreadMessageCount: pendingUnreadCount } = pendingRoomInfo[roomId];
                                return {
                                    ...rest,
                                    lastMessage,
                                    unreadMessageCount: unreadMessageCount + pendingUnreadCount,
                                };
                            })
                        )
                    );
                }
            );
        });
        chatSocket.getMessage((messageData) => dispatch(getMessage(messageData, keycloak)));
        chatSocket.getReceived(({ roomId, messageId, seen }) => dispatch(getReceived(roomId, messageId, seen)));
        chatSocket.getRemoved(({ roomId, messageId }) => dispatch(deleteMessage(roomId, messageId)));
        chatSocket.getConnected(({ roomId }) => dispatch(getReceived(roomId)));
        chatSocket.getLastSeen(({ roomId }) => dispatch(markAllAsRead(roomId)));
        dispatch(getUsersInfo(usernamesRoomsMap, keycloak));
    });
    keycloak.onAuthRefreshSuccess = () => {
        chatSocket.setToken(keycloak.token);
    };
};

export const getProfileUrl = (user) => {
    const { uuid, type } = user;
    return `${PROFILE_TYPES[type]}/${uuid}`;
};

export const InboxTypes = {
    CHATS: 0,
    REQUESTS: 1,
};

export const RoomStatus = {
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    BLOCKED: 'BLOCKED',
    WAITING: 'WAITING',
    NONE: 'NONE',
};

export const MessageStatus = {
    SENT: 'SENT',
    RECEIVED: 'RECEIVED',
    SEEN: 'SEEN',
};

export const MessageType = {
    TEXT: 'TEXT',
    POST: 'POST',
};

export const DEFAULT_MESSAGE_PAGE_SIZE = 20;
export const USER_INFO_BATCH_SIZE = 20;
