import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    AnnMask: {
        width: '78px',
        height: '78px',
        margin: '2px 8px 2px 0',
        padding: '1px 0.5px 0.5px 1px',
        borderRadius: '7.8px',
        backgroundColor: '#d2dfe5',
    },
    AnnMaskMedia: {
        width: '77px',
        height: '77px',
        borderRadius: '7px',
        cursor: 'pointer',
    },
});

export default useStyles;
