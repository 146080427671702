import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    alertStyle: {
        background: 'transparent',
        color: '#98a3ac!important',
        paddingLeft: '0px',
        paddingRight: '0px',
        fontSize: '16px',
        '& .MuiAlert-icon': {
            fontSize: 24,
            color: '#A1B8EE',
        },
    },
});

export const AlertInfo = ({ title, subTitle }) => {
    const classes = useStyles();
    return (
        <Alert severity="info" className={classes.alertStyle}>
            <AlertTitle style={{ fontWeight: 'normal' }}>{title}</AlertTitle>
            {subTitle}
        </Alert>
    );
};

AlertInfo.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
};

export default AlertInfo;
