import { makeStyles } from '@material-ui/core/styles';
import { COLOR_THEME_FILL_01, COLOR_PRIMARY_SHADE_02, COLOR_PRIMARY_BASE } from 'fintech/components/style/common';

const drawerWidth = 240;
const menuSectionDivider = {
    paddingLeft: 0,
    paddingRight: 0,
};
const defaultRowMenuDiv = {
    flex: '1 1 auto',
    width: '100%',
    paddingBottom: '0px !important',
    paddingRight: '0px !important',
    paddingTop: '0px !important',
};
const defaultRowMenuDivWeb = {
    ...defaultRowMenuDiv,
    overflowY: 'hidden',
    '&:hover': {
        overflowY: 'auto',
    },
};
const defaultRowMenuDivMobile = {
    ...defaultRowMenuDiv,
    overflowY: 'auto',
};
const defaultMenuItem = {
    '& > div:first-child': {
        cursor: 'pointer',
    },
};
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: COLOR_PRIMARY_SHADE_02,
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        [theme.breakpoints.down('md')]: {
            boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26)',
        },
    },
    menuButton: {
        paddingRight: '16px',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.down(370)]: {
            paddingRight: '6px !important',
        },
    },
    leftMenuWrapper: {
        height: '100%',
        display: 'flex',
        flex: '0 1 auto',
        flexDirection: 'column',
    },
    leftMenuTopSection: {
        left: '0px',
    },
    leftMenuBottomList: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    menuItem: {
        ...defaultMenuItem,
    },
    rowMenuItem: {
        ...defaultMenuItem,
        paddingLeft: '0px !important',
        paddingRight: '6px !important',
        paddingTop: '4px !important',
        paddingBottom: '8px !important',
        '&:first-of-type': {
            paddingTop: '0px !important',
        },
        '&:last-of-type': {
            paddingBottom: '0px !important',
        },
    },
    leftMenuLogoWrapper: {
        marginTop: 20,
    },
    leftMenuUserInfoWrapper: {
        marginTop: 30,
        marginBottom: 5,
    },
    drawerPaper: {
        width: drawerWidth,
        boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26)',
    },
    content: {
        flexGrow: 1,
        padding: '50px 24px 32px 24px',
    },
    mobileDownloadButtons: {
        width: '100%',
        textAlign: 'center',
    },
    menuSectionDivider: {
        ...menuSectionDivider,
    },
    menuSectionDividerTopOfMenuVisible: {
        ...menuSectionDivider,
        paddingBottom: '0px !important',
        marginTop: '8px !important',
    },
    menuSectionDividerTopOfMenuNotVisible: {
        ...menuSectionDivider,
        paddingBottom: '0px !important',
        marginTop: '8px !important',
        backgroundColor: `${COLOR_THEME_FILL_01} !important`,
    },
    menuSectionDividerBottomOfMenu: {
        ...menuSectionDivider,
        paddingTop: '0px !important',
        width: '100%',
    },
    finalMenuSectionDivider: {
        ...menuSectionDivider,
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    contactTextListItem: {
        padding: '11px 10px 11px 11px !important',
    },
    contactText: {
        fontSize: '12px',
        textAlign: 'center',
    },
    contactAddress: {
        color: COLOR_PRIMARY_BASE,
        fontWeight: 'bold',
    },
    promoteText: {
        padding: '0px 10px 0px 11px',
        fontSize: '12px',
        textAlign: 'center',
    },
    leftMenuFooterSection: {
        width: '100%',
        backgroundColor: '#f8f9fa',
        paddingBottom: '19px',
        paddingTop: '10px',
    },
    rowMenuDivWeb: {
        ...defaultRowMenuDivWeb,
    },
    rowMenuDivWithoutCardViewItemsWeb: {
        ...defaultRowMenuDivWeb,
    },
    rowMenuDivMobile: {
        ...defaultRowMenuDivMobile,
    },
    rowMenuDivWithoutCardViewItemsMobile: {
        ...defaultRowMenuDivMobile,
    },
    rowMenuInnerDiv: {
        maxWidth: '230px',
    },
    rowItemList: {
        width: '100%',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
}));

export default useStyles;
