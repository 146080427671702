import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from 'fintech/store/actions/ActionTypes';
import { Grid, FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles, { StyledButton } from './index.style.js';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import enLocale from 'date-fns/locale/en-US';
import trLocale from 'date-fns/locale/tr';
import { LANGUAGE_CODES } from 'fintech/constants';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import {
    getConsultantCalendarWorkDays,
    getBackedStartups,
    getDailyAvailableCalenderSlots,
    getWaitingMeetings,
    postSlotReservation,
    consultantCancelMeetingRequest,
} from 'fintech/api/fintechService';
import * as MeetingTypes from 'fintech/components/profile/user/consultant/calendar/RequestTypes/RequestTypes';
import IconsAlertError from 'fintech/icons/IconsAlertError';
import ModalMeetingOperation from 'fintech/components/profile/user/consultant/calendar/ModalMeetingOperation/ModalMeetingOperation';
import OperationButton from 'fintech/components/profile/user/consultant/calendar/ModalMeetingOperation/OperationButton/OperationButton';
import IconsSingleCloseWhite from 'fintech/icons/IconsSingleCloseWhite';
import RequestResultSuccessModal from 'fintech/components/profile/ConsultantCreateMeeting/MeetingCalendar/RequestResultModals/RequestResultSuccessModal';
import RequestResultErrorModal from 'fintech/components/profile/ConsultantCreateMeeting/MeetingCalendar/RequestResultModals/RequestResultErrorModal';
import { getRoles, hasRoles } from 'fintech/utils/Auth';
import {
    ROLE_STARTUP_REPRESANTATIVE,
    ROLE_STARTUP_WORKER,
    ROLE_IDEA_REPRESANTATIVE,
    ROLE_IDEA_WORKER,
    ROLE_ADMIN,
    ROLE_MENTOR,
    ROLE_COUNSELOR,
} from 'fintech/components/util/RoleConstants';
import { isAfter430PM, now, tomorrowMorning } from 'fintech/utils/DateUtils.js';
import { setActiveRequest } from 'fintech/store/actions/common.js';
import { sendChatMessage } from 'fintech/store/actions/inbox.js';
import SingleSelect from 'fintech/components/ui/form/select/SingleSelect.js';
import { findObject } from 'fintech/utils/ArrayUtils.js';

function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
}
const getLocale = () => {
    return getSystemLanguage() === LANGUAGE_CODES.TR ? trLocale : enLocale;
};
const getPickerFormat = () => {
    return getSystemLanguage() === LANGUAGE_CODES.TR ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
};

const allowedRoles = [
    ROLE_STARTUP_REPRESANTATIVE,
    ROLE_STARTUP_WORKER,
    ROLE_IDEA_REPRESANTATIVE,
    ROLE_IDEA_WORKER,
    ROLE_ADMIN,
];

const MeetingCalendar = ({ consultantId }) => {
    const { data: fetchedData, hasActiveRequest } = useSelector((state) => ({ ...state.user.intro, ...state.common }));
    const { t } = useTranslation();
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const today = now();
    const tomorrow = tomorrowMorning(today);
    const minDate = (isAfter430PM(today) ? tomorrow : today).getTime();

    const meetingRequestSender = keycloak?.tokenParsed?.preferred_username;
    const meetingRequestReceiver = useSelector(({ user }) => user.intro.data.userName);

    const [backedStartups, setBackedStartups] = useState([]);
    const [selectedStartup, setSelectedStartup] = useState('');
    const [selectedDate, setSelectedDate] = useState(minDate);
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState([]);
    const [details, setDetails] = useState({ val: '', validated: true });
    const [maxDate, setMaxDate] = useState('');
    const [waitingMeetings, setWaitingMeetings] = useState([]);
    const [meetingStatus, setMeetingStatus] = useState('');
    const [validatedAfterFormSubmit, setValidatedAfterFormSubmit] = useState(true);
    const [openMeetingOperationModal, setOpenMeetingOperationModal] = useState(false);
    const [enableCancelOrRejectInput, setEnableCancelOrRejectInput] = useState(false);
    const [biggerHeight, setBiggerHeight] = useState(false);
    const [isCancelInputValidatedAfterFormSubmit, setIsCancelInputValidatedAfterFormSubmit] = useState(true);
    const [requestResultDescriptionText, setRequestResultDescriptionText] = useState();
    const [isRequestResultSuccess, setIsRequestResultSuccess] = useState(null);
    const [openResultModal, setOpenResultModal] = useState(false);
    const [modalHeading, setModalHeading] = useState('');
    const { name, surname } = fetchedData;
    const roles = getRoles(keycloak);

    const isRoleAllowed = useMemo(() => {
        let isRoleAllowed = false;
        for (const allowedRole of allowedRoles) {
            if (roles.includes(allowedRole)) {
                isRoleAllowed = true;
                break;
            }
        }
        return isRoleAllowed;
    }, []);

    const isMentorOrCounselor = hasRoles(keycloak, ROLE_MENTOR) || hasRoles(keycloak, ROLE_COUNSELOR);

    const disableInputs = !isRoleAllowed || (isRoleAllowed && backedStartups.length < 1);

    function handleDateChange(date) {
        setSelectedDate(Date.parse(date));
    }

    const fetchWaitingMeetings = () => {
        getWaitingMeetings(keycloak, consultantId).then((response) => {
            if (response && response.success && response.data) {
                setWaitingMeetings(response.data);
                setMeetingStatus(response.data.length > 0 ? response.data[0].status.type : '');
            }
        });
    };

    const openModalWithDetails = () => {
        setModalHeading(t('profile.user.consultant.meetingCalendar.meetingDetail.title'));
        setOpenMeetingOperationModal(true);
        setEnableCancelOrRejectInput(false);
        setBiggerHeight(false);
    };

    const openModalWithCancellation = () => {
        setModalHeading(t('profile.user.consultant.meetingCalendar.meetingDetail.cancel'));
        setOpenMeetingOperationModal(true);
        setEnableCancelOrRejectInput(true);
        setBiggerHeight(true);
        setIsCancelInputValidatedAfterFormSubmit(true);
    };

    const sendCancelMessage = (callback) => {
        const meeting = waitingMeetings[0];
        const date = meeting.meetingDateTimeString;
        const timeSlot = meeting.meetingTimeString;
        const startupName = meeting.meetingStartup.name;

        const message = t('profile.consultants.meetingRequest.message.userCancel', {
            date,
            timeSlot,
            startupName,
        });

        dispatch(sendChatMessage(meetingRequestSender, meetingRequestReceiver, message, callback));
    };

    const sendCancelReason = (info) => {
        const message = t('profile.consultants.meetingRequest.message.cancelReason', { info });

        dispatch(sendChatMessage(meetingRequestSender, meetingRequestReceiver, message));
    };

    const cancelMeeting = (cancelOrRejectSubject) => {
        if (!cancelOrRejectSubject || !cancelOrRejectSubject.val) {
            enqueueSnackbar(t('validation.meetingCancel'), {
                variant: 'error',
            });
            setIsCancelInputValidatedAfterFormSubmit(false);
            return;
        }
        dispatch(setActiveRequest(true));
        consultantCancelMeetingRequest(keycloak, waitingMeetings[0].id, {
            subject: cancelOrRejectSubject.val,
        })
            .then((data) => {
                dispatch(setActiveRequest(false));
                if (data && data.success) {
                    setOpenMeetingOperationModal(false);

                    fetchWaitingMeetings();

                    enqueueSnackbar(t('profile.user.consultant.meetingCalendar.meetingDetail.success.cancelled'), {
                        variant: 'success',
                    });
                    // sendCancelMessage(() => sendCancelReason(data.data.resultText));
                }
            })
            .catch(() => {
                dispatch(setActiveRequest(false));
                enqueueSnackbar(t('profile.user.consultant.meetingCalendar.meetingDetail.error.cancelled'), {
                    variant: 'error',
                });
            });
    };

    const cancelButton = (cancelOrRejectSubject) => {
        return (
            <OperationButton
                icon={<IconsSingleCloseWhite />}
                text={t('profile.user.consultant.meetingCalendar.meetingDetail.cancel')}
                onClick={() => cancelMeeting(cancelOrRejectSubject)}
                disabled={hasActiveRequest}
            />
        );
    };

    const isMeetingWaiting = meetingStatus === MeetingTypes.WAITING;
    const isMeetingApproved = meetingStatus === MeetingTypes.APPROVE_REQUEST;
    const isMeetingWaitingOrApproved = isMeetingWaiting || isMeetingApproved;

    let warningText = '';
    if (isMeetingWaitingOrApproved) {
        warningText = isMeetingWaiting
            ? t('profile.consultants.meetingRequest.warning.waiting')
            : t('profile.consultants.meetingRequest.warning.organized');
    }
    const createRequest = () => {
        if (!details || !details.val) {
            setValidatedAfterFormSubmit(false);
            enqueueSnackbar(t('validation.meetingRequestDetail'), { variant: 'error' });
            return;
        }
        dispatch(setActiveRequest(true));
        postSlotReservation(keycloak, consultantId, {
            dayId: selectedDate,
            slotId: selectedTimeSlot,
            details: details.val,
            startupId: selectedStartup,
        })
            .then((response) => {
                dispatch(setActiveRequest(false));
                if (response && response.data && response.success) {
                    if (response.data.meetings && response.data.meetings.length > 0) {
                        const meeting = response.data.meetings[0];
                        const dateTime = meeting.meetingDateTimeString;
                        const meetingTimeString = meeting.meetingTimeString;
                        setRequestResultDescriptionText(
                            t('profile.consultants.meetingRequest.info.result.success.descriptionText', {
                                fullDateTime: `${dateTime}, ${meetingTimeString}`,
                                name: `${name} ${surname}`,
                            })
                        );
                    }
                    setOpenResultModal(true);
                    setIsRequestResultSuccess(true);
                }
            })
            .catch(() => {
                dispatch(setActiveRequest(false));
                setOpenResultModal(true);
                setIsRequestResultSuccess(false);
            });
    };

    useEffect(() => {
        if (isRoleAllowed) {
            getBackedStartups(keycloak)
                .then((response) => {
                    if (response && response.success && response.data && response.data.length > 0) {
                        setSelectedStartup(response.data[0].uuid);
                        setBackedStartups(response.data);
                        return true;
                    }
                    return false;
                })
                .then((hasBackedStartups) => {
                    if (hasBackedStartups) {
                        fetchWaitingMeetings();

                        getConsultantCalendarWorkDays(keycloak, consultantId).then((response) => {
                            if (response && response.data && response.success) {
                                const { data } = response;
                                if (data.length > 0) {
                                    var date = new Date(data[data.length - 1].id);
                                    const day = date.getDate() - 1;
                                    const month = date.getMonth() + 1;
                                    const year = date.getFullYear();
                                    setMaxDate(`${year}-${month}-${day}`);
                                }
                            }
                        });
                    }
                    dispatch({
                        type: UserActions.SET_HIDE_SEND_MESSAGE_BUTTON,
                        hideSendMessage: !(hasBackedStartups || isMentorOrCounselor),
                    });
                });
        } else {
            dispatch({
                type: UserActions.SET_HIDE_SEND_MESSAGE_BUTTON,
                hideSendMessage: !isMentorOrCounselor,
            });
        }
    }, []);

    useEffect(() => {
        getDailyAvailableCalenderSlots(keycloak, consultantId, selectedDate).then((response) => {
            if (response && response.success && response.data) {
                const { data } = response;
                setSelectedTimeSlot(data[0].id);
                setTimeSlots(data);
            }
        });
    }, [selectedDate]);

    const handleSetSelectedTimeSlot = (value) => {
        setSelectedTimeSlot(value.id);
    };

    const handleSetSelectedStartup = (value) => {
        setSelectedStartup(value.uuid);
    };

    const selectedTimeSlotObject = selectedTimeSlot && findObject(timeSlots, 'id', selectedTimeSlot);
    const selectedStartupObject = selectedStartup && findObject(backedStartups, 'uuid', selectedStartup);

    return (
        <div className={classes.container}>
            <div className={classes.heading}>{t('profile.consultants.meetingRequest.title')}</div>
            {isMeetingWaitingOrApproved && (
                <Grid container className={classes.warningArea} alignItems="center">
                    <Grid item>
                        <IconsAlertError color="primary" />
                    </Grid>
                    <Grid item className={classes.warningDetail}>
                        <Grid container>
                            <Grid item className={classes.warningText}>
                                {warningText}
                            </Grid>
                            <Grid item className={classes.cancelAndDetail}>
                                {isMeetingApproved && (
                                    <div className={classes.cancel} onClick={openModalWithCancellation}>
                                        {t('profile.consultants.meetingRequest.cancel')}
                                    </div>
                                )}
                                <div className={classes.showDetail} onClick={openModalWithDetails}>
                                    {t('profile.consultants.meetingRequest.showDetail')}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <div className={classes.formContainer}>
                <div>{t('profile.consultants.meetingRequest.warning.backedStartupsOnly')}</div>
                {
                    <div>
                        <div className={classes.formArea}>
                            <Grid container>
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                format={getPickerFormat()}
                                                value={selectedDate}
                                                shouldDisableDate={disableWeekends}
                                                onChange={handleDateChange}
                                                emptyLabel="test"
                                                inputVariant="outlined"
                                                minDate={minDate}
                                                cancelLabel={t('profile.consultants.meetingRequest.cancelLabel')}
                                                okLabel={t('profile.consultants.meetingRequest.okLabel')}
                                                maxDate={maxDate}
                                                className={classes.label}
                                                disabled={isMeetingWaitingOrApproved || disableInputs}
                                                invalidDateMessage={t(
                                                    'profile.consultants.meetingRequest.warning.invalidDate'
                                                )}
                                                maxDateMessage={t('profile.consultants.meetingRequest.warning.maxDate')}
                                                minDateMessage={t('profile.consultants.meetingRequest.warning.minDate')}
                                            ></KeyboardDatePicker>
                                        </FormControl>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <FormControl
                                        variant="outlined"
                                        className={`${classes.formControl} ${classes.timeSlot}`}
                                    >
                                        <SingleSelect
                                            disabled={isMeetingWaitingOrApproved || disableInputs}
                                            label={t('profile.consultants.meetingRequest.label.timeSlot')}
                                            dontValidate={true}
                                            canBeEmpty={false}
                                            options={timeSlots}
                                            initValue={selectedTimeSlotObject}
                                            onChange={handleSetSelectedTimeSlot}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <SingleSelect
                                    disabled={isMeetingWaitingOrApproved || disableInputs}
                                    label={t('profile.consultants.meetingRequest.label.yourBackedStartup')}
                                    dontValidate={true}
                                    canBeEmpty={false}
                                    options={backedStartups}
                                    initValue={selectedStartupObject}
                                    onChange={handleSetSelectedStartup}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.uuid === value.uuid}
                                />
                            </FormControl>
                            <InputTextArea
                                required
                                validateOnBlur
                                validatedAfterFormSubmit={validatedAfterFormSubmit}
                                dontValidate={isMeetingWaitingOrApproved}
                                disabled={isMeetingWaitingOrApproved || disableInputs}
                                containerClass={classes.detailsInputContainer}
                                onChange={(val, validated) => setDetails({ val, validated })}
                                validationText={t('validation.meetingRequest')}
                                label={t('profile.consultants.meetingRequest.label.about')}
                                type="text"
                                variant="outlined"
                                className={classes.detailsInput}
                                maxLength
                                multiline
                                count={400}
                                rowCount={7}
                                id="outlined-multiline-static"
                            />
                        </div>
                        <StyledButton
                            onClick={createRequest}
                            variant="contained"
                            color="primary"
                            disabled={hasActiveRequest || isMeetingWaitingOrApproved || disableInputs}
                        >
                            {t('profile.consultants.meetingRequest.createMeeting')}
                        </StyledButton>
                    </div>
                }
            </div>
            {waitingMeetings.length > 0 && (
                <ModalMeetingOperation
                    open={openMeetingOperationModal}
                    setOpen={setOpenMeetingOperationModal}
                    selectedMeeting={waitingMeetings[0]}
                    showCancelOrRejectReason={false}
                    enableCancelOrRejectInput={enableCancelOrRejectInput}
                    validationText={t('validation.meetingCancel')}
                    inputLabel={t('profile.user.consultant.meetingCalendar.meetingDetail.cancelReason')}
                    biggerHeight={biggerHeight}
                    actionButtonComponent={enableCancelOrRejectInput && cancelButton}
                    validatedAfterFormSubmit={isCancelInputValidatedAfterFormSubmit}
                    modalHeading={modalHeading}
                />
            )}

            {isRequestResultSuccess === true && (
                <RequestResultSuccessModal
                    open={openResultModal}
                    setOpen={setOpenResultModal}
                    onClick={() => {
                        setOpenResultModal(false);
                        fetchWaitingMeetings();
                    }}
                    descriptionText={requestResultDescriptionText}
                />
            )}
            {isRequestResultSuccess === false && (
                <RequestResultErrorModal
                    open={openResultModal}
                    setOpen={setOpenResultModal}
                    onClick={() => {
                        setOpenResultModal(false);
                    }}
                />
            )}
        </div>
    );
};
export default MeetingCalendar;
