import React, { useState, useEffect } from 'react';
import { ErrorOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'fintech/components/util/LoadingSpinner';
import useStyles from './index.style';
import supportProgramsIcon from 'fintech/static/images/destek-programi-icon.svg';
import { formatNumericValue } from 'fintech/utils/StringUtils';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import { LANGUAGE_CODES } from 'fintech/constants';
import { APIS } from 'fintech/constants';
import { getRequest } from 'fintech/api/api';
import ProgramCardGrid from '../CardItem/ProgramCardGrid';

const Support = () => {
    const { t } = useTranslation('preLogin');
    const classes = useStyles();
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [supportPrograms, setSupportPrograms] = useState([]);
    let supportProgramsJSX = null;

    useEffect(() => {
        if (!supportPrograms || !supportPrograms.length) {
            setIsLoading(true);

            getRequest(APIS.SUPPORT_PROGRAMS_PRE_LOGIN.getSupportProgramList(), null)
                .then((payload) => {
                    // geçici olarak böyle yapıldı birden fazla gelirse düzeltilmesi gerekiyor.
                    if (getSystemLanguage() === LANGUAGE_CODES.TR) {
                        setSupportPrograms(payload.data.currentPrograms);
                    } else {
                        const data = payload.data.currentPrograms;
                        if (data?.length === 1) {
                            data[data.length - 1]['description'] = t('program.description');
                            data[data.length - 1]['title'] = t('program.title');
                        }
                        setSupportPrograms(data);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    setIsError(true);
                });
        }
    }, []);

    let errorMessage = t('noUpToDateSupportProgram');
    let iconJSX = <ErrorOutline fontSize="large" />;

    if (isLoading) {
        errorMessage = t('loading');
        iconJSX = <LoadingSpinner />;
    } else if (isError) {
        errorMessage = t('currentProgramsError');
        iconJSX = <ErrorOutline fontSize="large" />;
    }

    if (supportPrograms.length) {
        supportProgramsJSX = (
            <>
                <div className={classes.titleWrapper}>
                    <div className={classes.divider}></div>
                    <div
                        className={classes.mentorDefinition}
                        dangerouslySetInnerHTML={{
                            __html: t('supportProgramsCount', {
                                supportProgramsCount: supportPrograms.length
                                    ? formatNumericValue(supportPrograms.length)
                                    : '',
                            }),
                        }}
                    ></div>
                </div>
                <ProgramCardGrid data={supportPrograms} />
            </>
        );
    } else {
        supportProgramsJSX = (
            <div className={classes.emptyWrapper}>
                {<span className={classes.preLoginEmptyDivIcon}>{iconJSX}</span>}
                <span className={classes.preLoginEmptyDivMessage}>{errorMessage}</span>
            </div>
        );
    }

    return (
        <>
            <section className={classes.supportSection} id="section-support">
                <div className={classes.supportContainer}>
                    <div className={classes.imagesContainer}>
                        <img src={supportProgramsIcon} className={classes.supportProgramsIcon} />
                    </div>
                    <span className={classes.title}>{t('titles.support')}</span>
                    <p className={classes.definition}>{t('support')}</p>
                    {supportProgramsJSX}
                </div>
            </section>
        </>
    );
};

export default Support;
