import React from 'react';
import Typography from '@material-ui/core/Typography';
import { CardContent } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { fromEpoch, NUMERIC_FULL_DATE_W_HOUR } from 'fintech/utils/DateUtils';
import useStyles from 'fintech/components/dashboard/event/eventcardcontent/index.style';

/**
 * Event Card Card Component for showing the contents received from the client
 */
const EventCardContent = (props) => {
    const classes = useStyles(props);
    const { t, eventName, eventDate, eventInformation, online, isPreLogin } = props;

    const getEventIsOnlineText = () => {
        return online ? t('event.online') : t('event.notOnline');
    };

    return (
        <CardContent>
            <Typography
                className={isPreLogin ? classes.onlineInformationStylePreLogin : classes.onlineInformationStyle}
            >
                {getEventIsOnlineText()}
            </Typography>
            <Typography className={classes.eventNameStyle}>{eventName}</Typography>
            <Typography className={classes.eventDateStyle}>{fromEpoch(eventDate, NUMERIC_FULL_DATE_W_HOUR)}</Typography>
            <div className={classes.eventInformationStyle} dangerouslySetInnerHTML={{ __html: eventInformation }}></div>
        </CardContent>
    );
};

export default withTranslation('event')(EventCardContent);
