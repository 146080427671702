import * as React from 'react';

function SvgIconsSingleRounded(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
            <defs>
                <filter id="ezwqf9io8a" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g filter="url(#ezwqf9io8a)" transform="translate(-1030 -98) translate(370 90)">
                        <g>
                            <path
                                fill="#3F5162"
                                fillRule="nonzero"
                                d="M16 28.333c2.172 0 4.18-.535 6.023-1.605 1.844-1.07 3.301-2.527 4.372-4.371C27.465 20.513 28 18.505 28 16.333s-.535-4.18-1.605-6.023c-1.07-1.844-2.528-3.297-4.372-4.36C20.18 4.873 18.172 4.334 16 4.334c-2.172 0-4.18.535-6.023 1.606-1.844 1.07-3.301 2.527-4.372 4.37C4.535 12.155 4 14.162 4 16.334s.535 4.18 1.605 6.024c1.07 1.844 2.528 3.3 4.372 4.37 1.843 1.071 3.851 1.606 6.023 1.606zm0-1.687c-1.406 0-2.742-.266-4.008-.797-1.234-.531-2.328-1.273-3.281-2.227-.953-.953-1.688-2.046-2.203-3.28-.547-1.266-.82-2.602-.82-4.009 0-1.406.265-2.742.796-4.007.532-1.235 1.274-2.329 2.227-3.282.953-.953 2.047-1.687 3.281-2.203 1.266-.547 2.602-.82 4.008-.82 1.406 0 2.742.265 4.008.797 1.234.531 2.328 1.273 3.281 2.226.953.953 1.688 2.047 2.203 3.282.547 1.265.82 2.601.82 4.007 0 1.407-.265 2.743-.796 4.008-.532 1.235-1.274 2.328-2.227 3.281-.953.954-2.047 1.688-3.281 2.204-1.266.546-2.602.82-4.008.82zm4.219-5.25c.234 0 .433-.082.597-.246.164-.164.247-.36.247-.586 0-.227-.086-.426-.258-.598l-3.61-3.633 3.61-3.632c.172-.157.258-.352.258-.586 0-.235-.083-.434-.247-.598-.164-.164-.363-.246-.597-.246-.235 0-.438.078-.61.234L16 15.138l-3.61-3.633c-.171-.156-.374-.234-.609-.234-.234 0-.433.082-.597.246-.164.164-.246.363-.246.598 0 .234.085.43.257.586l3.61 3.632-3.61 3.633c-.172.156-.258.352-.258.586 0 .234.083.434.247.598.164.164.363.246.597.246.235 0 .438-.078.61-.235L16 17.53l3.61 3.632c.171.157.374.235.609.235z"
                                transform="translate(660 8)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleRounded;
