import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Button, DialogTitle, DialogActions, DialogContent, useMediaQuery, Slide } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './index.style';
import VerticalTabs from './tabs';

// ----------------------------------------------------------------------
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CookieDialog({ open, handleClose, handleManageCookie, setCookieSwitch, cookieSwitch }) {
    const { t } = useTranslation('policy');
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={isMobile ? 'lg' : 'md'}
                open={open}
                aria-labelledby="cookie-manage"
                fullScreen={isMobile}
                TransitionComponent={Transition}
            >
                <DialogTitle id="cookie-manage" className={classes.dialogTitle}>
                    {t('cookiePreferences')}
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    <VerticalTabs cookieSwitch={cookieSwitch} setCookieSwitch={setCookieSwitch} />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button variant="contained" className={classes.approvalRejectionButton} onClick={handleClose}>
                        {t('close')}
                    </Button>
                    <Button variant="contained" className={classes.manageCookieButton} onClick={handleManageCookie}>
                        {t('confirmSelections')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
