import React, {useEffect, useState} from 'react';
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const BlankChatArea = () => {
    const { t } = useTranslation();

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Typography style={{ textAlign: "center", width: "60%" }}>{t("profile.user.chat.blankChatAreaText")}</Typography>
        </div>
    );
};

export default BlankChatArea;
