import { ANONYMOUS, ROLE_KEY } from 'fintech/constants';
import { fetchUserRoles } from 'fintech/api/fintechService';
import { getSystemLanguage } from './LanguageUtils';

export const doLogin = (keycloak) => {
    keycloak &&
        keycloak.login({
            redirectUri: `${window.location.origin}/`,
            locale: getSystemLanguage(),
        });
};

export const doLogout = (keycloak) => {
    clearUserRoles();
    keycloak &&
        keycloak.logout({
            redirectUri: `${window.location.origin}/`,
        });
};

export const isAuthenticated = (keycloak) => {
    return keycloak && keycloak['authenticated'] && true;
};

export const getRoles = (keycloak) => {
    if (!isAuthenticated(keycloak)) {
        return [];
    }
    return getUserRoles() || [];
};

/**
 * check localstorage
 * @param keycloak
 * @returns {Promise<void>}
 */
export const loadUserRoles = async (keycloak) => {
    if (!isAuthenticated(keycloak)) {
        return;
    }
    const roleResponse = await fetchUserRoles(keycloak);
    localStorage.setItem(ROLE_KEY, JSON.stringify(roleResponse.data));
};

// check local storage
export const getUserRoles = () => {
    if (!localStorage.getItem(ROLE_KEY)) {
        return [];
    }
    return JSON.parse(localStorage.getItem(ROLE_KEY));
};

export const clearUserRoles = () => {
    localStorage.removeItem(ROLE_KEY);
};

export const hasRoles = (keycloak, role) => {
    if (!isAuthenticated(keycloak)) {
        return false;
    }
    const roles = getUserRoles();

    return roles.includes(role);
};

export const getToken = (keycloak) => {
    return isAuthenticated(keycloak) && keycloak['token'];
};

export const getLoggedInUserUuid = (keycloak) => {
    if (!isAuthenticated(keycloak)) {
        return ANONYMOUS;
    }
    return keycloak.tokenParsed.sub;
};

export const getUsername = (keycloak) => {
    if (!isAuthenticated(keycloak)) {
        return ANONYMOUS;
    }
    return keycloak.tokenParsed.preferred_username;
};

export const getFamilyName = (keycloak) => {
    if (!isAuthenticated(keycloak)) {
        return ANONYMOUS;
    }
    return keycloak.tokenParsed.family_name;
};

export const getGivenName = (keycloak) => {
    if (!isAuthenticated(keycloak)) {
        return ANONYMOUS;
    }
    return keycloak.tokenParsed.given_name;
};

export const getGivenNameAndFamilyNameFirstChar = (keycloak) => {
    const familyName = getFamilyName(keycloak);
    const givenName = getGivenName(keycloak);
    if (!givenName || !familyName) {
        return getUsername(keycloak);
    }
    const firstLetterOfFamilyName = familyName ? familyName[0] + '.' : '';
    const userNameInfo = givenName + ' ' + firstLetterOfFamilyName;
    return userNameInfo;
};

export const hasAnyAuthorities = (keycloak, authorities) => {
    if (!isAuthenticated(keycloak)) {
        return false;
    }

    if (!authorities || authorities.length === 0) {
        return true;
    }

    const roles = getRoles(keycloak);
    if (!keycloak || !roles) {
        return false;
    }

    // check roles existance
    const roleFilter = (role) => authorities.some((innerAuth) => innerAuth === role);
    const selectedPermissions = roles.filter(roleFilter);

    return selectedPermissions && selectedPermissions.length !== 0;
};
