import { isAfter, parse, format } from 'date-fns';
import { LANGUAGE_CODES } from 'fintech/constants';
import { getFnsLocale, getSystemLanguage } from './LanguageUtils';
import moment from 'moment';

// Date format constants. Can be extended.
export const ISO_FORMAT = 'yyyy-MM-dd';
export const NUMERIC_FULL_DATE = 'dd.MM.yyyy';
export const NUMERIC_FULL_DATE_WITH_FORWARD_SLASH = 'dd/MM/yyyy';
export const DAY_MONTH_DESC = 'dd MMMM';
export const DAY_MONTH_DESC_W_YEAR = 'dd MMMM yyyy';
export const NUMERIC_FULL_DATE_W_HOUR = 'dd.MM.yyyy, HH:mm';
export const NUMERIC_FULL_DATE_W_HOUR_SECOND = 'd MMMM yyyy, HH:mm:ss';
export const NUMERIC_FULL_DATE_W_HOUR_SECOND_2 = 'd.MM.yyyy - k:mm:ss'; // 05.09.2021 - 23:30:40
export const NUMERIC_FULL_DATE_W_HOUR_SECOND_3 = 'd.MM.yyyy, k:mm:ss'; // 05.09.2021, 23:30:40
export const OPENING_TIME = 8;
export const SATURDAY = 6;
export const SUNDAY = 0;

export const trimYearFromDateString = (dateStr) => {
    const lang = getSystemLanguage();
    const { TR } = LANGUAGE_CODES;
    const chCount = lang === TR ? 5 : 6;
    const yearStr = dateStr.slice(dateStr.length - chCount, dateStr.length);
    const returnStr = dateStr.replace(yearStr, '');

    return returnStr;
};

export const getYearFromDateString = (dateStr) => dateStr && dateStr.slice(0, 4);

export const dateIsAfter = (date, dateToCompare, format = 'yyyy-MM-dd') => {
    const tmp = new Date();
    const firstDate = parse(date, format, tmp);
    const secondDate = parse(dateToCompare, format, tmp);
    return isAfter(firstDate, secondDate);
};

export const fromEpoch = (epoch, dateFormat) => {
    return format(moment(epoch).toDate(), dateFormat, {
        locale: getFnsLocale(),
    });
};

export const now = () => new Date();

export const tomorrowMorning = (date) => {
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const day = tomorrow.getDay();
    if (day === SATURDAY)
        tomorrow.setDate(tomorrow.getDate() + 2);
    else if (day === SUNDAY)
        tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(OPENING_TIME, 0, 0, 0);

    return tomorrow;
};

export const isAfter430PM = (date) => date.getHours() >= 17 || (date.getHours() == 16 && date.getMinutes() >= 30);
