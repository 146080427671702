import { makeStyles } from '@material-ui/core/styles';
import { COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    AnnHeaderDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    AnnTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    AnnDate: {
        width: '50px',
        height: '30px',
        margin: '0 14px 15px 2px',
        padding: '7px 11px 9px 12px',
        borderRadius: '8px',
        border: `solid 2px ${COLOR_PRIMARY_SHADE_02}`,
        display: 'table',
    },
    AnnDateTypo: {
        display: 'table-cell',
        verticalAlign: 'middle',
        textAlign: 'center',
        lineHeight: 1,
        fontSize: '12px',
    },
});

export default useStyles;
