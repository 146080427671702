import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, InputAdornment, TextField, Typography } from '@material-ui/core';
import { COLOR_PRIMARY_BASE } from 'fintech/components/style/common';
import PlaceholdersInvestor from 'fintech/static/images/placeholders-yatirimda-bulunan.png';
import SimpleAutocomplete from './SimpleAutocomplete';
import { useSelector } from 'react-redux';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import { STATES } from 'fintech/store/actions/ActionTypes';

const InvestorSearchSelect = (props) => {
    // Component state logic
    const { t } = useTranslation();
    const { type = STATES.STARTUP, initialValue, validateOnBlur, overrideInitiallyValidateState = false } = props;

    const unregOption = {
        id: -1,
        name: t('profile.financials.form.unregisteredCompany'),
        unregistered: true,
    };
    const { data } = useSelector((state) => getSelectValuesFromState(state, type));

    const [options, setOptions] = useState(data ? [data] : []);
    const [value, setValue] = useState(
        !initialValue?.id ? null : initialValue.unregistered ? unregOption : initialValue
    );
    const [initiallyValidate, setInitiallyValidate] = useState(!validateOnBlur);

    useEffect(() => {
        data && setOptions([unregOption, ...data]);
    }, [data]);

    useEffect(() => {
        overrideInitiallyValidateState && setInitiallyValidate(true);
    }, [overrideInitiallyValidateState]);

    const valueChangeHandler = (e, value) => {
        setValue(value);
        props.onChange && props.onChange(value, validate(value));
        !initiallyValidate && setInitiallyValidate(true);
    };

    const validate = (value) => value && value?.id && value.id !== unregOption.id;

    // Style related code blocks
    const renderOption = (option) => (
        <Typography style={option.unregistered ? { color: COLOR_PRIMARY_BASE } : null}>{option.name}</Typography>
    );

    const renderInput = (params) => {
        const newParams = {
            ...params,
            InputProps: {
                ...params.InputProps,
                startAdornment: value && (
                    <InputAdornment position="start">
                        <Avatar src={value?.logo ? value.logo.url : PlaceholdersInvestor} />
                    </InputAdornment>
                ),
            },
        };
        return (
            <TextField
                {...newParams}
                error={initiallyValidate && !value}
                helperText={initiallyValidate && !value ? t('validation.notEmpty') : null}
                variant="outlined"
                label={
                    type === STATES.ESTABLISHMENT
                        ? t('profile.establishments.attemptName')
                        : t('profile.financials.form.investor')
                }
            />
        );
    };

    const filter = createFilterOptions({
        stringify: (option) => option.name,
    });

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        return filtered[0]?.id !== unregOption.id ? [options[0], ...filtered] : filtered;
    };

    return (
        <SimpleAutocomplete
            renderInput={renderInput}
            renderOption={renderOption}
            onChange={valueChangeHandler}
            getOptionSelected={(option, value) => value.id === option.id}
            filterOptions={filterOptions}
            value={value}
            options={options}
            openOnFocus
            getOptionLabel={(option) => option.name}
        />
    );
};

export default InvestorSearchSelect;

const getSelectValuesFromState = (state, type) => {
    if (type === STATES.ESTABLISHMENT) {
        return state[STATES.ESTABLISHMENT].investmentPreferences.form.startupsData.data;
    } else {
        return state.startup.form.investorsData;
    }
};
