import { makeStyles } from '@material-ui/core';

import {
    COLOR_PRIMARY_SHADE_01,
} from 'fintech/components/style/common';

export const makeSidebarPanelStyles = makeStyles(() => ({
    sidebar: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        borderRight: `1px solid ${COLOR_PRIMARY_SHADE_01}`,
    },
    noChatHere: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));