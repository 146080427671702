import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { buttonStyleInteraction, COLOR_PRIMARY_BASE } from 'fintech/components/style/common';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';

const InteractionGrid = (props) => {
    const {
        t,
        breakPoints = {},
        editable,
        onClickEdit,
        onClickDelete,
        deleteButtonText,
        deleteIcon,
        showDeleteButton = true,
        isInvited,
    } = props;

    return (
        <Grid item {...breakPoints} container direction="row" justify="flex-end" alignItems="center">
            <Grid item>
                {editable && !isInvited ? (
                    <ShrinkingButton
                        style={{ marginRight: 0 }}
                        shrinkedIcon={<Edit fontSize="small" style={{ color: COLOR_PRIMARY_BASE }} />}
                        fullIcon={<Edit style={{ color: COLOR_PRIMARY_BASE }} />}
                        fullText={t('profile.edit')}
                        onClick={onClickEdit}
                        otherStyles={buttonStyleInteraction}
                    />
                ) : (
                    <Box style={{ width: '150px', height: '30px' }} />
                )}
            </Grid>
            {showDeleteButton && (
                <Grid item>
                    {editable ? (
                        <ShrinkingButton
                            style={{ marginRight: 0 }}
                            shrinkedIcon={<Delete fontSize="small" style={{ color: COLOR_PRIMARY_BASE }} />}
                            fullIcon={deleteIcon || <Delete style={{ color: COLOR_PRIMARY_BASE }} />}
                            fullText={deleteButtonText || t('profile.delete')}
                            onClick={onClickDelete}
                            otherStyles={buttonStyleInteraction}
                        />
                    ) : (
                        <Box style={{ width: '150px', height: '30px' }} />
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default withTranslation('profile')(InteractionGrid);
