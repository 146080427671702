export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const NOT_FOUND = '/404';

export const WELCOME = '/';
export const HOME = '/home';
export const HOME_SECTION = '/home/:section';
export const SUPPORT_PROGRAM_ONE_PAGE_PRE = '/support-program-one-page';
export const SUPPORT_PROGRAM_ONE_PAGE = SUPPORT_PROGRAM_ONE_PAGE_PRE + '/:id';
export const USER = '/user';
export const PLATFORM = '/platform';
export const ATTEMPT = '/platform/attempt';
export const ENTREPRENEURS_ATTEMPT = '/platform/entrepreneurs/attempt';
export const FILE_UPLOAD = '/file-upload';
export const EVENT_DETAIL = '/platform/events';
export const USER_PROFILE = '/platform/profile';
export const PERSONS_PROFILE = '/platform/persons/profile';
export const MENTORS_PROFILE = '/platform/mentors/profile';
export const CONSULTANTS_PROFILE = '/platform/consultants/profile';
export const ENTREPRENEURS = '/platform/entrepreneurs';
export const ENTERPRISE = '/platform/enterprise';
export const SETTINGS = '/platform/settings';
export const INBOX = '/platform/inbox';
export const CHAT = '/platform/chat';
export const ESTABLISHMENT = '/platform/establishment';
export const INVESTORS_ESTABLISHMENT = '/platform/investors/establishment';
export const SUPPORT_PROGRAM_SECURE = '/platform/support-programs';
export const SUPPORT_PROGRAM_DETAIL = '/platform/support-programs/program-detail';
export const SUPPORT_PROGRAM_APPLICATION_SECURE = '/platform/support-programs/application-form';

/* pre login policies */
export const CLARIFICATION_TEXT = '/clarification-text';
export const PRIVACY_POLICY = '/privacy-policy';
export const COOKIE_POLICY = '/cookie-policy';
export const COOKIE_POLICY_MOBILE_APP = '/cookie-policy-mobile-app';
export const TERMS_OF_USE = '/terms-of-use';

// Applications
export const INVESTOR_APPLICATION = '/application/investor';
export const INVESTOR_APPLICATION_INVITE = '/application/investor/:token';
export const STARTUP_APPLICATION = '/application/startup';
export const MENTOR_APPLICATION = '/application/mentor';
export const STARTUP_APPLICATION_INVITE = '/application/startup/:token';
export const MEMBER_APPLICATION_INVITE = '/application/team-member/:token';
export const SUPPORT_PROGRAM = '/support-programs/application-form';
export const SUPPORT_PROGRAM_APPLICATION = '/support-programs/application-form/:id';
export const EXPIRED_INVITE = '/application/invite-expired';
