import { GRID_HEADER_STATE } from 'fintech/store/actions/inviteSearch';



const headers = [
    {
        id: 0,
        type: 'sortLabel',
        value: {
            title: 'invites.dataGrid.headers.fullName',
            status: GRID_HEADER_STATE.NONE,
            searchIndex: 'fullName',
        },
    },
    {
        id: 1,
        type: 'sortLabel',
        value: {
            title: 'invites.dataGrid.headers.company',
            status: GRID_HEADER_STATE.NONE,
            searchIndex: 'companyName',
        },
    },
    {
        id: 2,
        type: 'sortLabel',
        value: {
            title: 'invites.dataGrid.headers.mailAddress',
            status: GRID_HEADER_STATE.NONE,
            searchIndex: 'email',
        },
    },
    {
        id: 3,
        type: 'sortLabel',
        value: {
            title: 'invites.dataGrid.headers.memberType',
            status: GRID_HEADER_STATE.NONE,
            searchIndex: 'memberType',
        },
    },
    {
        id: 4,
        type: 'sortLabel',
        value: {
            title: 'invites.dataGrid.headers.dayCount',
            status: GRID_HEADER_STATE.DESC,
            searchIndex: 'dayCount',
        },
    },
];

export const defaultTableHeaders = [...headers];

export const adminTableHeaders = [
    {
        id: 5,
        type: 'label-actions',
        value: '#',
    },
    ...headers,
];
