import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';

import useStyle from 'fintech/components/pagination/GridPagination/index.style';

const GridPagination = (props) => {
    const styles = useStyle();
    const { count, defaultPage, controlled, page, handleChange, disabled } = props;
    const [currentPage, setCurrentPage] = useState(defaultPage);

    useEffect(() => {
        setCurrentPage(page);
    }, [page]);

    return (
        <Pagination
            classes={{ root: styles.root, ul: styles.ul }}
            disabled={disabled}
            color="primary"
            variant="outlined"
            shape="rounded"
            count={count}
            defaultPage={!controlled ? defaultPage : null}
            page={controlled ? currentPage : null}
            boundaryCount={2}
            onChange={(event, value) => handleChange(event, value)}
        ></Pagination>
    );
};

GridPagination.defaultProps = {};

GridPagination.propTypes = {
    count: PropTypes.number.isRequired,
    defaultPage: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default GridPagination;
