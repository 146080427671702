import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import withQuery from 'with-query';
import { getToken } from 'fintech/utils/Auth';
import CustomError from 'fintech/utils/CustomError';

const GENERIC_ERROR_MSG = 'Bilinmeyen bir hata oluştu! Lütfen daha sonra tekrar deneyiniz.';

const ERROR_RESPONSE_CODES = [400, 401, 403, 500, 404];

const checkStatus = async (response) => {
    if (response.ok) {
        return response;
    }

    if (ERROR_RESPONSE_CODES.includes(response.status)) {
        const jsonResponse = await response.json();
        const errorMessage = jsonResponse.message;
        const errorData = jsonResponse.data;
        return Promise.reject(new CustomError(errorMessage, errorData));
    }

    const error = new RequestError(response.statusText ? response.statusText : GENERIC_ERROR_MSG, response);
    return Promise.reject(error);
};

const withHost = (uri) => `${process.env.API_URL}/${uri}`;

const getHeaders = (keycloak) => {
    const headers = {
        'Content-Type': 'application/json',
        // 'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        'Accept-Language': getSystemLanguage(),
    };

    let headerToken = getToken(keycloak);
    if (headerToken) {
        headers.Authorization = `Bearer ${headerToken}`;
    }
    return headers;
};

export const request = (method, requestUrl, keycloak, body = null, ignoreHeaders = false, stringifyBody = true) => {
    return fetch(withHost(requestUrl), {
        method,
        body: stringifyBody ? JSON.stringify(body) : body,
        headers: ignoreHeaders ? null : getHeaders(keycloak),
    })
        .then(checkStatus)
        .then((r) => r.json());
};

export const requestBlob = (method, requestUrl, keycloak, body = null, ignoreHeaders = false, stringifyBody = true) => {
    return fetch(withHost(requestUrl), {
        method,
        body: stringifyBody ? JSON.stringify(body) : body,
        headers: ignoreHeaders ? null : getHeaders(keycloak),
    })
        .then(checkStatus)
        .then((res) => res.blob());
};

export const getRequest = (requestUrl, keycloak, params = {}) =>
    request('GET', withQuery(requestUrl, params), keycloak, null, false, false);

export const getRequestBlob = (requestUrl, keycloak, params = {}) =>
    requestBlob('GET', withQuery(requestUrl, params), keycloak, null, false, false);

export const postRequest = (requestUrl, keycloak, body = {}, ignoreHeaders = false, stringifyBody = true) =>
    request('POST', requestUrl, keycloak, body, ignoreHeaders, stringifyBody);

export const putRequest = (requestUrl, keycloak, body = {}, ignoreHeaders = false, stringifyBody = true) =>
    request('PUT', requestUrl, keycloak, body, ignoreHeaders, stringifyBody);

export const patchRequest = (requestUrl, keycloak, body = {}, ignoreHeaders = false, stringifyBody = true) =>
    request('PATCH', requestUrl, keycloak, body, ignoreHeaders, stringifyBody);

export const deleteRequest = (requestUrl, keycloak, body = null, ignoreHeaders = false, stringifyBody = false) =>
    request('DELETE', requestUrl, keycloak, body, ignoreHeaders, stringifyBody);
