import React, { useEffect, useRef, useState } from 'react';
import { useValidatableForm } from 'react-validatable-form';
import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';

import CfoFormContainer from 'fintech/components/applications/form/common/CfoFormContainer';
import IndividualInvestorForm from 'fintech/components/applications/form/investor/IndividualInvestorForm';
import InstitutionalInvestorForm from 'fintech/components/applications/form/investor/InstitutionalInvestorForm';
import CfoFormInput from 'fintech/components/applications/form/common/CfoFormInput';
import CfoRadioGroup from 'fintech/components/ui/form/radio/CfoRadioGroup';
import {
    INVESTOR_INDIVIDUAL,
    INVESTOR_INSTITUTIONAL,
    INVITE_EXPIRED,
} from 'fintech/components/util/ApplicationFormConstants';
import { InvestorApplicationActions } from 'fintech/store/actions/ActionTypes';
import { setInvestorFromInvite, setMkkForm } from 'fintech/store/actions/application-investor';
import { TCKN, VKN } from 'fintech/components/util/IdentityConstants';
import useStyles from './index.style';
import * as ROUTES from 'fintech/routes';
import { CAPTCH_API_KEY_INVIS } from 'fintech/constants/auth';
import { getLocale } from 'fintech/utils/LanguageUtils';
import * as FintechBeService from 'fintech/api/fintechService';
import { mobileMediaQuery } from 'fintech/components/style/common';
import { setActiveRequest } from 'fintech/store/actions/common';
import ButtonCircularIndicator from 'fintech/components/ui/spinner/ButtonCircularIndicator';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';
import CfoSingleCheckbox from 'fintech/components/ui/form/checkbox/CfoSingleCheckbox';
import useTranslationClick from 'fintech/hooks/useTranslationClick';
import ActionModal from 'fintech/components/util/ActionModal';
import { AgreementTypo, AgreementWrapperDiv } from 'fintech/components/applications/modal/LegalTextModal/index.style';

const rules = [
    {
        path: 'institution.name',
        ruleSet: [
            {
                rule: 'required',
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INSTITUTIONAL;
                },
            },
            {
                rule: 'length',
                lessThanOrEqualTo: 255,
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INSTITUTIONAL;
                },
            },
        ],
    },
    {
        path: 'institution.idNumber',
        ruleSet: [
            {
                rule: 'required',
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INSTITUTIONAL;
                },
                dependantPaths: ['tab'],
            },
            {
                rule: 'vkn',
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INSTITUTIONAL;
                },
                dependantPaths: ['tab'],
            },
        ],
    },
    {
        path: 'institution.email',
        ruleSet: [
            {
                rule: 'required',
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INSTITUTIONAL;
                },
                dependantPaths: ['tab'],
            },
            {
                rule: 'email',
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INSTITUTIONAL;
                },
                dependantPaths: ['tab'],
            },
        ],
    },
    {
        path: 'name',
        ruleSet: [
            {
                rule: 'required',
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INDIVIDUAL;
                },
                dependantPaths: ['tab'],
            },
            {
                rule: 'length',
                lessThanOrEqualTo: 100,
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INDIVIDUAL;
                },
                dependantPaths: ['tab'],
            },
        ],
    },
    {
        path: 'surname',
        ruleSet: [
            {
                rule: 'required',
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INDIVIDUAL;
                },
                dependantPaths: ['tab'],
            },
            {
                rule: 'length',
                lessThanOrEqualTo: 100,
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INDIVIDUAL;
                },
                dependantPaths: ['tab'],
            },
        ],
    },
    {
        path: 'idNumber',
        ruleSet: [
            {
                rule: 'required',
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INDIVIDUAL;
                },
                dependantPaths: ['tab'],
            },
            {
                rule: 'tckn',
                enableIf: (formData) => {
                    return formData.tab === INVESTOR_INDIVIDUAL;
                },
                dependantPaths: ['tab'],
            },
        ],
    },
];

const initialFormData = {};

export const InvestorGeneralInfoForm = (props) => {
    const classes = useStyles();
    const matches = useMediaQuery(mobileMediaQuery);
    const { t } = useTranslation('application');
    const { t: policyT } = useTranslation('policy');
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { inviteToken, switchTabHandler } = props;
    const dispatch = useDispatch();
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
    const {
        type: tab,
        institution,
        name,
        surname,
        idNumber,
        acceptsMKKform,
        isLoading,
        hasActiveRequest,
    } = useSelector((state) => ({
        ...state.investorApplication,
        ...state.common,
    }));

    const {
        isValid,
        validationError,
        setFormData,
        setFormIsSubmitted,
        setPathIsBlurred,
        resetForm,
    } = useValidatableForm({
        rules,
        initialFormData,
        hideBeforeSubmit: true,
        showAfterBlur: true,
        focusToErrorAfterSubmit: true,
    });

    useEffect(() => {
        const nameVal = name ? name.value : null;
        const surnameVal = surname ? surname.value : null;
        const idNumberVal = idNumber ? idNumber.value : null;
        const acceptsMKKform = acceptsMKKform || null;
        const newFormData = {
            tab,
            institution,
            name: nameVal,
            surname: surnameVal,
            idNumber: idNumberVal,
        };
        setFormData(newFormData);
    }, [tab, institution, name, surname, idNumber, acceptsMKKform]);

    useEffect(() => {
        resetForm();
    }, [tab]);

    const [openModal, setOpenModal] = useState(false);
    useTranslationClick('mkk-form', () => setOpenModal(true));

    // ReCaptcha - Start
    const recaptchaRef = useRef();

    const asyncOnLoad = () => {
        setRecaptchaLoaded(true);
    };

    // ReCaptcha - End

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        if (inviteToken) {
            setTimeout(() => {
                FintechBeService.fetchInvestorInviteInfo(keycloak, inviteToken)
                    .then((res) => {
                        // Invite endpoint throws an exception if invite status is anythinh
                        // other than INVITED
                        dispatch(setInvestorFromInvite(res.data));
                        dispatch({
                            type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_INVITE_LOADING,
                            data: false,
                        });
                    })
                    .catch((e) => {
                        if (e.data === INVITE_EXPIRED) {
                            history.replace(ROUTES.EXPIRED_INVITE);
                        } else {
                            history.replace(ROUTES.NOT_FOUND);
                        }
                    });
            }, 1000);
        } else {
            dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_INVITE_LOADING, data: false });
        }
    }, []);

    const typeChangeHandler = (val) => {
        dispatch({ type: InvestorApplicationActions.RESET_INVESTOR_APPLICATION_FORM });
        dispatch({ type: InvestorApplicationActions.SET_INVESTOR_APPLICATION_INVESTOR_TYPE, data: val });
    };

    const showErrorSnackbar = (error) => {
        dispatch(setActiveRequest(false));
        enqueueSnackbar(error?.message || t('idea.updateError'), { variant: 'error' });
    };

    const clickContinueHandler = async () => {
        setFormIsSubmitted();
        if (!isValid) {
            return;
        }

        dispatch(setActiveRequest(true));
        const requestData = {};
        if (tab === INVESTOR_INDIVIDUAL) {
            requestData.companyName = `${name.value} ${surname.value}`;
            requestData.identity = {
                value: idNumber?.value,
                identityType: TCKN,
            };
        } else {
            const { name, email, idNumber } = institution;
            requestData.companyName = name;
            requestData.companyEmail = email;
            requestData.identity = {
                value: idNumber,
                identityType: VKN,
            };
        }
        requestData.approvedTextTypeList = [];
        acceptsMKKform && requestData.approvedTextTypeList.push('MKK');

        let res = null;
        try {
            res = await FintechBeService.investorApplicationFirstStep(keycloak, requestData);
        } catch (reason) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(reason?.message ? reason.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        if (!res || !res.data) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(res?.message ? res.message : t('idea.updateError'), { variant: 'error' });
            return;
        }

        // Trigger captcha
        try {
            const token = await recaptchaRef?.current?.executeAsync();
            dispatch(setActiveRequest(false));
            if (!token) {
                enqueueSnackbar(t('validation.reCaptcha'), { variant: 'error' });
                return;
            }
        } catch (e) {
            dispatch(setActiveRequest(false));
            enqueueSnackbar(t('validation.reCaptcha'), { variant: 'error' });
            return;
        }

        FintechBeService.investorApplicationLastStep(keycloak, requestData)
            .then((response) => {
                if (!response?.data) {
                    showErrorSnackbar(response);
                    return;
                }
                switchTabHandler(true);
            })
            .catch((error) => {
                showErrorSnackbar(error);
                return;
            });
    };

    let formJSX = null;
    if (tab === INVESTOR_INDIVIDUAL) {
        formJSX = (
            <IndividualInvestorForm
                disabled={inviteToken}
                validationError={validationError}
                setPathIsBlurred={setPathIsBlurred}
            />
        );
    } else {
        formJSX = (
            <InstitutionalInvestorForm
                disabled={inviteToken}
                validationError={validationError}
                setPathIsBlurred={setPathIsBlurred}
            />
        );
    }

    return (
        <CfoFormContainer generalInfo>
            {!isLoading ? (
                <>
                    {matches && (
                        <Grid item xs={12}>
                            <Typography className={classes.titleTypo}>{t('common.tabs.generalInfo')}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <CfoRadioGroup
                            classFormControl={classes.radioGrpDiv}
                            row
                            onChange={typeChangeHandler}
                            disabled={inviteToken}
                            value={tab}
                            defaultValue={tab}
                            options={[
                                {
                                    value: INVESTOR_INSTITUTIONAL,
                                    label: t(`investor.form.label.${INVESTOR_INSTITUTIONAL}`),
                                },
                                { value: INVESTOR_INDIVIDUAL, label: t(`investor.form.label.${INVESTOR_INDIVIDUAL}`) },
                            ]}
                        />
                    </Grid>
                    {formJSX}
                    <Grid item container direction="row">
                        <Grid item xs={12} lg={12}>
                            <div className={classes.legalDiv}>
                                <CfoSingleCheckbox
                                    checked={acceptsMKKform}
                                    onChange={() => {
                                        dispatch(setMkkForm(!acceptsMKKform));
                                    }}
                                />
                                <Typography dangerouslySetInnerHTML={{ __html: t('investor.mkk') }} />
                            </div>
                        </Grid>
                    </Grid>
                    <CfoFormInput xs={12} lg={12}>
                        <Button
                            className={classes.continueBtn}
                            disabled={!isValid || hasActiveRequest || !recaptchaLoaded}
                            variant="contained"
                            onClick={clickContinueHandler}
                        >
                            {!hasActiveRequest ? t('dialog.continue') : <ButtonCircularIndicator />}
                        </Button>
                    </CfoFormInput>
                </>
            ) : (
                <div style={{ margin: '198px 0' }}>
                    <LoadingSpinnerWithText />
                </div>
            )}
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={CAPTCH_API_KEY_INVIS}
                asyncScriptOnLoad={asyncOnLoad}
                hl={getLocale()}
                onChange={() => recaptchaRef.current.reset()}
            />
            <ActionModal
                overrideInitialTab
                titleMarginBottom30={false}
                tabsContainerClass={'padding-bottom-26 border-bottom'}
                dialogActionsStyle={{
                    borderTop: '1px solid #dee2e6',
                    justifyContent: 'center',
                    padding: '32px',
                }}
                open={openModal}
                title={policyT('mkkTitle')}
                okButtonLabel={t('dialog.close')}
                onAction={() => setOpenModal(false)}
                showCancelOnTop
            >
                <AgreementWrapperDiv component="div">
                    <AgreementTypo dangerouslySetInnerHTML={{ __html: policyT('mkkText') }} />
                </AgreementWrapperDiv>
            </ActionModal>
        </CfoFormContainer>
    );
};

export default InvestorGeneralInfoForm;
