import React from 'react';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import { DetailContent, DetailTitle } from 'fintech/components/membership/Applications/index.style';
import { useTranslation } from 'react-i18next';
import { NUMERIC_FULL_DATE } from 'fintech/utils/DateUtils';
import { format } from 'date-fns';
import { isDate } from 'lodash';

const RepresentativeInfos = ({ infos, excludedFields = [] }) => {
    const { t } = useTranslation('membershipManagement');
    const itemsJSX = [];
    const birthDate = infos.birthDate ? new Date(infos?.birthDate) : '';

    itemsJSX.push(
        <IntroGridItem
            key="name"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.representative.name')}</DetailTitle>}
            content={<DetailContent>{infos.name || '-'}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="surname"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.representative.surname')}</DetailTitle>}
            content={<DetailContent>{infos.surname || '-'}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="identityNumber"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.representative.identityNumber')}</DetailTitle>}
            content={<DetailContent>{infos.identityNumber || '-'}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="birthDate"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.representative.birthDate')}</DetailTitle>}
            content={<DetailContent>{isDate(birthDate) ? format(birthDate, NUMERIC_FULL_DATE) : '-'}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="gender"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.representative.gender.label')}</DetailTitle>}
            content={
                <DetailContent>
                    {infos.gender ? t(`applicationDetails.sections.representative.gender.${infos.gender}`) : '-'}
                </DetailContent>
            }
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="nationality"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.representative.nationality')}</DetailTitle>}
            content={<DetailContent>{infos.nationality || '-'}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="email"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.representative.email')}</DetailTitle>}
            content={<DetailContent>{infos.email || '-'}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="gsmNo"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.representative.phoneNumber')}</DetailTitle>}
            content={<DetailContent>{infos.gsmNo || '-'}</DetailContent>}
        />
    );
    itemsJSX.push(
        <IntroGridItem
            key="expertiseYear"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.representative.experience')}</DetailTitle>}
            content={<DetailContent>{infos.expertiseYear || '-'}</DetailContent>}
        />
    );
    !excludedFields.includes('title') &&
        itemsJSX.push(
            <IntroGridItem
                key="title"
                htmlBind={false}
                title={<DetailTitle>{t('applicationDetails.sections.representative.title')}</DetailTitle>}
                content={<DetailContent>{infos.title || '-'}</DetailContent>}
            />
        );
    itemsJSX.push(
        <IntroGridItem
            key="educationInfo"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.representative.educationInfo')}</DetailTitle>}
            content={<DetailContent>{infos.educationInfo || '-'}</DetailContent>}
        />
    );

    return itemsJSX;
};

export default RepresentativeInfos;
