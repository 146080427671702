import { AttempActions } from 'fintech/store/actions/ActionTypes';

const initialState = {
    attemps: [],
    isLoading: true,
    isError: false,
};
const attemps = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case AttempActions.SET_ATTEMP_LIST:
            const { attemps } = action;
            return {
                ...state,
                attemps: attemps,
                isLoading: false,
            };

        case AttempActions.SET_ATTEMP_LIST_LOADING:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case AttempActions.SET_ATTEMP_LIST_LOAD_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            return state;
    }
};

export default attemps;
