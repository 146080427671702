import PageTemplate from 'fintech/components/PageTemplate';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import React from 'react';
import ProgramDetailGrid from 'fintech/components/support/program/detail/ProgramDetailGrid';
import { useTranslation } from 'react-i18next';
import TabSwitch from 'fintech/components/ui/navigation/TabSwitch';

export default function SupportProgramDetailPage() {
    const { t } = useTranslation('supportProgram');

    const tabs = [
        {
            header: { label: t('details.label') },
            component: <ProgramDetailGrid />,
            errorMsg: t('details.errorMessage'),
        },
    ];

    return (
        <>
            <PageTemplate>
                <PageHeaderBox />
                <TabSwitch tabs={tabs} />
            </PageTemplate>
        </>
    );
}
