import React from 'react';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ReactValidatableFormProvider } from 'react-validatable-form';
import useStyles from 'fintech/App.style';
import history from 'fintech/history';
import AppRouter from 'fintech/AppRouter';
import i18n from 'fintech/i18n.js';
import './static/styles/scss/import.scss';
import { SnackbarProvider } from 'notistack';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SyncIcon from '@material-ui/icons/Sync';
import { useSelector } from 'react-redux';
// import { useKeycloak } from '@react-keycloak/web';
// import { configureChatSocket } from './utils/InboxUtils';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import { customRules, translations } from './utils/ValidationUtils';

const App = () => {
    const classes = useStyles();
    // const dispatch = useDispatch();
    // const { keycloak } = useKeycloak();
    // keycloak.token && configureChatSocket(dispatch, keycloak);
    const { performanceCookie } = useSelector((state) => state.cookie);

    // NOTE:  google tag manager id =  process.env.GOOGLE_ANALYTICS
    const gtmParams = { id: process.env.GOOGLE_ANALYTICS };

    return (
        <>
            {performanceCookie ? (
                <GTMProvider state={gtmParams}>
                    <SnackbarProvider
                        classes={{
                            variantSuccess: classes.success,
                            variantWarning: classes.warning,
                            variantInfo: classes.info,
                            variantError: classes.error,
                        }}
                        iconVariant={{
                            warning: <ErrorIcon />,
                            success: <CheckCircleIcon />,
                            info: <SyncIcon />,
                            error: <ErrorIcon />,
                        }}
                        preventDuplicate={true}
                        maxSnack={5}
                    >
                        <I18nextProvider i18n={i18n}>
                            <Router history={history}>
                                <ReactValidatableFormProvider
                                    lang={getSystemLanguage()}
                                    customRules={customRules}
                                    translations={translations}
                                >
                                    <AppRouter />
                                </ReactValidatableFormProvider>
                            </Router>
                        </I18nextProvider>
                    </SnackbarProvider>
                </GTMProvider>
            ) : (
                <SnackbarProvider
                    classes={{
                        variantSuccess: classes.success,
                        variantWarning: classes.warning,
                        variantInfo: classes.info,
                        variantError: classes.error,
                    }}
                    iconVariant={{
                        warning: <ErrorIcon />,
                        success: <CheckCircleIcon />,
                        info: <SyncIcon />,
                        error: <ErrorIcon />,
                    }}
                    preventDuplicate={true}
                    maxSnack={5}
                >
                    <I18nextProvider i18n={i18n}>
                        <Router history={history}>
                            <ReactValidatableFormProvider
                                lang={getSystemLanguage()}
                                customRules={customRules}
                                translations={translations}
                            >
                                <AppRouter />
                            </ReactValidatableFormProvider>
                        </Router>
                    </I18nextProvider>
                </SnackbarProvider>
            )}
        </>
    );
};

export default App;
