import React, { useEffect, useState } from 'react';
import ActionModal from 'fintech/components/util/ActionModal';
import { useTranslation } from 'react-i18next';
import {
    PROFILE_GENERAL_INFO_UPDATE,
    PROFILE_KEYWORDS_UPDATE,
    PROFILE_SOCIAL_MEDIA_UPDATE,
    REJECT,
    SUBMIT,
    TAB,
} from 'fintech/components/util/ActionModalConstants';
import * as FintechBeService from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import GeneralInfoTab from 'fintech/components/profile/user/modal/tabs/GeneralInfoTab';
import { useSnackbar } from 'notistack';
import SocialMediaTab from '../tabs/SocialMediaTab';
import KeywordsTab from '../tabs/KeywordsTab';
import { setActiveRequest } from 'fintech/store/actions/common';
import _ from 'lodash';

export default function UpdateModal({ userUuid, title, open, setOpen, updateCallback }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const handleClickVariant = (variant, text) => {
        enqueueSnackbar(text, { variant });
    };

    const { data: fetchedData, hasActiveRequest } = useSelector((state) => ({ ...state.user.intro, ...state.common }));
    const { isError: verticalsError } = useSelector((state) => state.user.verticalData);
    const { isError: techsError } = useSelector((state) => state.user.technologyData);

    const [generalInfos, setGenaralInfos] = useState(null);
    const [socialMediaInfos, setSocialMediaInfos] = useState(null);
    const [keywordsData, setKeywordsData] = useState({
        verticals: [],
        technologies: [],
        stageData: {},
    });
    const [verticalsValid, setVerticalsValid] = useState(true);
    const [techValid, setTechValid] = useState(true);
    const [counter, setCounter] = useState(0);
    const [formIsSubmitted, setFormIsSubmitted] = useState(false);

    const [tabs] = useState([
        {
            id: PROFILE_GENERAL_INFO_UPDATE,
            name: t('idea.updateGeneralInfoTabLabel'),
            selected: true,
        },
        {
            id: PROFILE_KEYWORDS_UPDATE,
            name: t('idea.updateKeywordsLabel'),
            selected: false,
        },
        {
            id: PROFILE_SOCIAL_MEDIA_UPDATE,
            name: t('profile.user.socialMedia'),
            selected: false,
        },
    ]);

    const [selectedTab, setSelectedTab] = useState(PROFILE_GENERAL_INFO_UPDATE);
    const [isOpen, setIsOpen] = useState(open);

    if (open != isOpen) {
        setIsOpen(open);
    }
    const { keycloak } = useKeycloak();

    const initGeneralInfos = () => {
        setGenaralInfos({
            biography: _.clone(
                fetchedData.profileInfos?.biography
                    ? { ...fetchedData.profileInfos.biography, validated: true }
                    : { field: 'biography', value: '', validated: true },
                true
            ),
            personalWebsite: _.clone({ value: fetchedData.personalWebsite, validated: true }, true),
            mailAddress: _.clone(
                { ...fetchedData.profileInfos.email, value: fetchedData.mailAddress, validated: true },
                true
            ),
            phoneNumber: _.clone(
                { ...fetchedData.profileInfos.phoneNumber, value: fetchedData.phoneNumber, validated: true },
                true
            ),
        });
        setSocialMediaInfos({
            instagram: _.clone(
                fetchedData.profileInfos?.instagram
                    ? { ...fetchedData.profileInfos.instagram, validated: true }
                    : { field: 'instagram', value: '', validated: true },
                true
            ),
            twitter: _.clone(
                fetchedData.profileInfos?.twitter
                    ? { ...fetchedData.profileInfos.twitter, validated: true }
                    : { field: 'twitter', value: '', validated: true },
                true
            ),
            youtube: _.clone(
                fetchedData.profileInfos?.youtube
                    ? { ...fetchedData.profileInfos.youtube, validated: true }
                    : { field: 'youtube', value: '', validated: true },
                true
            ),
            linkedin: _.clone(
                fetchedData.profileInfos?.linkedin
                    ? { ...fetchedData.profileInfos.linkedin, validated: true }
                    : { field: 'linkedin', value: '', validated: true },
                true
            ),
        });
        if (fetchedData.verticals && Object.keys(fetchedData.verticals).length > 0) {
            setVerticalsValid(true);
        } else {
            setVerticalsValid(false);
        }
        if (fetchedData.technologies.length > 0) {
            setTechValid(true);
        } else {
            setTechValid(false);
        }
    };

    useEffect(() => {
        initGeneralInfos();
    }, []);

    const updateData = () => {
        dispatch(setActiveRequest(true));
        let updatedUserProfileInfos = [];

        if (generalInfos.mailAddress.value) {
            updatedUserProfileInfos.push({
                ...generalInfos.mailAddress,
                id: null,
                value: null,
            });
        }

        if (generalInfos.phoneNumber.value) {
            updatedUserProfileInfos.push({
                ...generalInfos.phoneNumber,
                id: null,
                value: null,
            });
        }

        if (generalInfos.biography.value && generalInfos.biography.value !== '') {
            updatedUserProfileInfos.push({
                field: generalInfos.biography.field,
                value: generalInfos.biography.value,
            });
        }

        if (socialMediaInfos.instagram.value && socialMediaInfos.instagram.value !== '') {
            updatedUserProfileInfos.push({
                field: socialMediaInfos.instagram.field,
                value: socialMediaInfos.instagram.value,
            });
        }

        if (socialMediaInfos.twitter.value && socialMediaInfos.twitter.value !== '') {
            updatedUserProfileInfos.push({
                field: socialMediaInfos.twitter.field,
                value: socialMediaInfos.twitter.value,
            });
        }

        if (socialMediaInfos.youtube.value && socialMediaInfos.youtube.value !== '') {
            updatedUserProfileInfos.push({
                field: socialMediaInfos.youtube.field,
                value: socialMediaInfos.youtube.value,
            });
        }

        if (socialMediaInfos.linkedin.value && socialMediaInfos.linkedin.value !== '') {
            updatedUserProfileInfos.push({
                field: socialMediaInfos.linkedin.field,
                value: socialMediaInfos.linkedin.value,
            });
        }

        let requestData = {
            uuid: fetchedData.uuid,
            phoneNumber: {
                number: generalInfos.phoneNumber.value.nationalNumber,
                country: {
                    alphaTwo: generalInfos.phoneNumber.value.country,
                },
            },
            userProfileInfoList: updatedUserProfileInfos,
            mailAddress:
                generalInfos.mailAddress.value && generalInfos.mailAddress.value !== ''
                    ? generalInfos.mailAddress.value
                    : fetchedData.mailAddress,
            personalWebsite: generalInfos.personalWebsite.value,
            userFintechSubVerticalSet: keywordsData.verticals.map((el) => {
                return { id: el.id, subVertical: el.data, vertical: el.parentData };
            }),
            technologySet: keywordsData.technologies.map((el) => {
                return { id: el.id, name: el.data };
            }),
            userAdditionalCommunicationInfoSet: [],
        };

        FintechBeService.updateUserProfile(keycloak, requestData)
            .then(() => {
                dispatch(setActiveRequest(false));
                updateCallback(true);
                setIsOpen(false);
                setOpen(false);
                handleClickVariant('success', t('idea.updateSucces'));
                setTimeout(() => {
                    setSelectedTab(PROFILE_GENERAL_INFO_UPDATE);
                }, 10);
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                handleClickVariant('error', reason.message);
            });
    };

    const rejectData = () => {
        setOpen(false);
        initGeneralInfos();
    };

    const actionEventTrigger = (actionData) => {
        if (actionData.type == TAB) {
            setSelectedTab(actionData.selectedTabId);
        } else if (actionData.type == SUBMIT) {
            setFormIsSubmitted(true);
            if (
                generalInfos.biography.validated &&
                generalInfos.mailAddress.validated &&
                generalInfos.personalWebsite.validated &&
                generalInfos.phoneNumber.validated &&
                socialMediaInfos.instagram.validated &&
                socialMediaInfos.twitter.validated &&
                socialMediaInfos.youtube.validated &&
                socialMediaInfos.linkedin.validated &&
                verticalsValid &&
                techValid
            ) {
                updateData();
            } else {
                handleClickVariant('error', t('profile.user.fillRequiredFields'));
            }
        } else if (actionData.type == REJECT) {
            rejectData();
        }
        setCounter(counter + 1);
    };

    const changeTabData = (data) => {
        setKeywordsData(data);
        if (data.verticalsValid != undefined) {
            setVerticalsValid(data.verticalsValid);
        }
        if (data.technologiesValid != undefined) {
            setTechValid(data.technologiesValid);
        }
    };

    return (
        <div>
            {tabs && tabs.length > 0 && (
                <ActionModal
                    open={isOpen}
                    tabs={tabs}
                    title={title}
                    onAction={actionEventTrigger}
                    okClickDisabled={hasActiveRequest || verticalsError || techsError}
                    cancelClickDisabled={hasActiveRequest}
                    showSpinnerWhenDisabled={hasActiveRequest}
                >
                    {selectedTab === PROFILE_GENERAL_INFO_UPDATE && <GeneralInfoTab initData={generalInfos} />}

                    {selectedTab === PROFILE_KEYWORDS_UPDATE && (
                        <KeywordsTab
                            userUuid={userUuid}
                            changeCallback={(tabId) => changeTabData(tabId)}
                            initData={keywordsData}
                            formIsSubmitted={formIsSubmitted}
                        />
                    )}

                    {selectedTab === PROFILE_SOCIAL_MEDIA_UPDATE && <SocialMediaTab initData={socialMediaInfos} />}
                </ActionModal>
            )}
        </div>
    );
}
