import React from 'react';

function IconsSingleMinus(props) {
    return (
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16.984 16.584h6v-1.968H9.016v1.968h6z" fill="#5B7ADB" fillRule="nonzero" />
        </svg>
    );
}

export default IconsSingleMinus;
