import { useEffect } from 'react';
import { isNil } from 'lodash';

const useRowClickListener = ({ rowClickListener, rows }) => {
    const hoverClassNameToBeHandled = rowClickListener ? 'sticky-hovered-clickable' : 'sticky-hovered';
    useEffect(() => {
        const stickyTableRows = document.getElementsByClassName('sticky-table-row');
        const mouseOverEventListener = (e) => {
            const stickyTableRow = e.target.closest('.sticky-table-row');
            const stickyTableTable = stickyTableRow.parentElement.children;

            const stickyTableRowChildren = stickyTableRow.children;

            // Add sticky-hovered Class
            for (let j = 0; j < stickyTableRowChildren.length; j++) {
                if (!stickyTableRowChildren[j].classList.contains(hoverClassNameToBeHandled)) {
                    stickyTableRowChildren[j].classList.add(hoverClassNameToBeHandled);
                }
            }

            // Removing sticky-hovered Class
            for (let k = 1; k < stickyTableTable.length; k++) {
                if (stickyTableTable[k] !== stickyTableRow) {
                    for (let f = 0; f < stickyTableTable[k].children.length; f++) {
                        if (stickyTableTable[k].children[f].classList.contains(hoverClassNameToBeHandled)) {
                            stickyTableTable[k].children[f].classList.remove(hoverClassNameToBeHandled);
                        }
                    }
                }
            }
        };

        const onClickListener = (e) => {
            if (e.target.closest('.prevent-propagation') === null) {
                const clickedStickyTableRow = e.target.closest('.sticky-table-row');
                e.stopPropagation();

                if (!isNil(clickedStickyTableRow)) {
                    const [uuid] = clickedStickyTableRow.id.split('&');
                    rowClickListener && rowClickListener(uuid);
                }
            }
        };

        for (let k = 1; k < stickyTableRows.length; k++) {
            if (!isNil(stickyTableRows[k])) stickyTableRows[k].addEventListener('mouseover', mouseOverEventListener);
            if (!isNil(stickyTableRows[k])) stickyTableRows[k].addEventListener('click', onClickListener);
        }

        // Removes Event Listeners
        return () => {
            for (let l = 1; l < stickyTableRows.length; l++) {
                if (!isNil(stickyTableRows[l])) {
                    stickyTableRows[l].removeEventListener('mouseover', mouseOverEventListener);
                    stickyTableRows[l].removeEventListener('click', onClickListener);
                }
            }
        };
    }, [rows]);
};

export default useRowClickListener;
