import React, { useEffect, useState } from 'react';
import useStyles from './index.style';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useMediaQuery } from '@material-ui/core';
import IconsChevronLeft from 'fintech/icons/IconsChevronLeft';
import IconsChevronRight from 'fintech/icons/IconsChevronRight';
import { APIS } from 'fintech/constants';
import { getRequest } from 'fintech/api/api';
import { getLogo } from 'fintech/utils/DataParser';
import MentorCard from 'fintech/components/search/mentor/MentorCard';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatNumericValue } from 'fintech/utils/StringUtils';
import { useKeycloak } from '@react-keycloak/web';
import { getLoggedInUserUuid } from 'fintech/utils/Auth';
import { ANONYMOUS } from 'fintech/constants';
import { CONSULTANTS_PROFILE } from 'fintech/routes';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';
import { getItemShow } from 'fintech/components/util/GetItemShow';

const ConsultantSearch = ({ preLogin }) => {
    const classes = useStyles();
    const [consultantsCount, setConsultantsCount] = useState(null);
    const { t } = useTranslation('preLogin');
    const consultantSearchTranslation = useTranslation('consultantSearch');
    const [loading, setLoading] = useState(false);
    const [consultants, setConsultants] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const { keycloak } = useKeycloak();
    const userUuid = getLoggedInUserUuid(keycloak);
    const isAnonymous = userUuid === ANONYMOUS;
    const [itemShow, setItemShow] = useState(getItemShow(window.innerWidth));

    const updateDimensions = () => {
        setItemShow(getItemShow(window.innerWidth));
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        getRequest(APIS.CONSULTANT_PRE_LOGIN.getActiveConsultantCountURL(), null).then((payload) => {
            setConsultantsCount(payload.data);
        });
        setLoading(true);
        getRequest(APIS.CONSULTANT_PRE_LOGIN.getConsultants(searchTerm, itemShow, activePage), null)
            .then((payload) => {
                setConsultants(payload.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [itemShow]);

    useEffect(() => {
        if (activePage !== 0) {
            setLoading(true);
            getRequest(APIS.CONSULTANT_PRE_LOGIN.getConsultants(searchTerm, itemShow, activePage), null)
                .then((payload) => {
                    setConsultants(payload.data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [activePage]);

    useEffect(() => {
        if (searchTerm) {
            setLoading(true);
            getRequest(APIS.CONSULTANT_PRE_LOGIN.getConsultants(searchTerm, itemShow, 0), null)
                .then((payload) => {
                    setConsultants(payload.data);
                    setActivePage(0);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [searchTerm]);

    const parseConsultant = (consultant) => {
        return {
            uuid: consultant.uuid,
            name: `${consultant.name} ${consultant.surname}`,
            logo: getLogo(consultant.profilePictureFile),
            expertise: consultant.expertise,
        };
    };

    const consultantsWrapperInlineStyle = {};
    if ((consultantsCount && consultantsCount > 0) || loading) {
        consultantsWrapperInlineStyle.minHeight = 332;
    }

    return (
        <div className={classes.consultantSearchWrapper}>
            <div className={classes.titleWrapper}>
                <div className={classes.divider}></div>
                <div
                    className={classes.consultantDefinition}
                    dangerouslySetInnerHTML={{
                        __html: t('registeredConsultant', {
                            consultantsCount: consultantsCount ? formatNumericValue(consultantsCount) : '',
                        }),
                    }}
                ></div>
            </div>
            {!preLogin && (
                <TextField
                    type="search"
                    variant="outlined"
                    className={classes.searchBar}
                    placeholder={t('searchConsultant')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event) => setSearchTerm(event.target.value === '' ? '%00' : event.target.value)}
                />
            )}
            <div className={classes.consultantsWrapper} style={consultantsWrapperInlineStyle}>
                {loading ? (
                    <LoadingSpinnerWithText loadingMessage=" " />
                ) : (
                    consultants.content &&
                    consultants.content.map((consultant) => {
                        return (
                            <Grid
                                key={consultant.uuid}
                                item
                                className={classes.itemWrapper}
                                style={{ width: '-moz-available' }}
                            >
                                <MentorCard
                                    isAnonymous={isAnonymous}
                                    title={consultantSearchTranslation.t('cards.expertise')}
                                    cardType="consultantSearch"
                                    personUrl={CONSULTANTS_PROFILE}
                                    {...parseConsultant(consultant)}
                                    preLogin={preLogin}
                                />
                            </Grid>
                        );
                    })
                )}
            </div>
            <div className={classes.iconsWrapper}>
                <div
                    className={`${consultants.first || loading ? classes.disable : ''}`}
                    onClick={() => setActivePage((prevState) => --prevState)}
                >
                    <IconsChevronLeft />
                </div>
                <div
                    className={`${consultants.last || loading ? classes.disable : ''}`}
                    onClick={() => setActivePage((prevState) => ++prevState)}
                >
                    <IconsChevronRight />
                </div>
            </div>
        </div>
    );
};

export default ConsultantSearch;
