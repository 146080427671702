import * as React from 'react';

function SvgIconsSingleClose3(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <filter id="hy9il13kla" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g
                                filter="url(#hy9il13kla)"
                                transform="translate(-1226 -888) translate(280 724) translate(20 128) translate(922 34)"
                            >
                                <g>
                                    <path
                                        fill="#3F5162"
                                        fillRule="nonzero"
                                        d="M12.845 18L12.845 12.845 18 12.845 18 11.155 12.845 11.155 12.845 6 11.155 6 11.155 11.155 6 11.155 6 12.845 11.155 12.845 11.155 18z"
                                        transform="translate(4 2) rotate(45 12 12)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleClose3;
