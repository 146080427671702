import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import { useStyles } from 'fintech/components/dashboard/eventdetail/eventdetailheader/EventDetailHeader.style';
import useLocalStyles from './index.style';

import { getFnsLocale } from 'fintech/utils/LanguageUtils';
import { fontStyle } from 'fintech/components/style/common';
import { ISO_FORMAT, NUMERIC_FULL_DATE } from 'fintech/utils/DateUtils';

const ProgramDetail = (props) => {
    const { t } = useTranslation('supportProgram');
    const { title, description, startDate, endDate, lastApplicationDate } = props;

    const classes = useLocalStyles();
    const eventClasses = useStyles();

    return (
        <>
            <Grid
                item
                xs={12}
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                className={eventClasses.EventDetailHeaderGrid}
            >
                <Grid item container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography className={eventClasses.EventNameStyle}>{title}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.lastApplicationDateTypo}>
                            {t('general.applicationDeadline', {
                                date: `${format(parse(lastApplicationDate, ISO_FORMAT, new Date()), NUMERIC_FULL_DATE, {
                                    locale: getFnsLocale(),
                                })}`,
                            })}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography className={eventClasses.EventDateStyle}>{`${format(
                        parse(startDate, ISO_FORMAT, new Date()),
                        NUMERIC_FULL_DATE,
                        {
                            locale: getFnsLocale(),
                        }
                    )} - ${format(parse(endDate, ISO_FORMAT, new Date()), NUMERIC_FULL_DATE, {
                        locale: getFnsLocale(),
                    })}`}</Typography>
                </Grid>
                <Grid item>
                    <div
                        style={{ ...fontStyle, fontWeight: 'normal', marginBottom: '16px' }}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ProgramDetail;
