import * as React from 'react';

function SvgIconsSingleSlack(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <path
                d="M10.906 21.429c.893 0 1.607-.748 1.607-1.619 0-.178-.033-.357-.089-.524l-.614-1.83 3.46-1.184.614 1.842c.223.67.848 1.105 1.551 1.105.882 0 1.596-.737 1.596-1.619 0-.178-.022-.357-.078-.524l-.625-1.864 1.92-.658c.67-.235 1.038-.737 1.038-1.451 0-.916-.703-1.585-1.619-1.585a1.62 1.62 0 00-.513.09l-1.83.624-1.172-3.527 1.752-.602c.625-.212 1.16-.793 1.16-1.485 0-.893-.747-1.54-1.618-1.54-.167 0-.323.022-.48.067l-1.808.614-.591-1.797a1.637 1.637 0 00-1.551-1.105c-.882 0-1.608.737-1.608 1.618 0 .179.034.358.09.525l.602 1.786-3.46 1.172-.602-1.786a1.608 1.608 0 00-1.54-1.105A1.617 1.617 0 004.98 7.21l.591 1.774-1.752.592a1.634 1.634 0 00-1.105 1.54c0 .893.692 1.574 1.585 1.574.179 0 .357-.034.536-.09l1.74-.602 1.173 3.493-1.741.592a1.632 1.632 0 00-1.105 1.54c0 .904.68 1.573 1.585 1.573.19 0 .38-.044.558-.1l1.707-.592.614 1.82c.223.67.837 1.105 1.54 1.105zm-.1-6.987l-1.172-3.493 3.46-1.195 1.172 3.516-3.46 1.172z"
                fill={props.fill ? props.fill : '#3F5162'}
                fillRule="nonzero"
            />
        </svg>
    );
}

export default SvgIconsSingleSlack;
