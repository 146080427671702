import React, { useState, useRef, useCallback } from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import Notification from 'fintech/components/notificationBox/Notification';
import PlaceHolder from 'fintech/components/notificationBox/PlaceHolder';
import useFetchNotification from 'fintech/components/notificationBox/hooks/usefetchNotification';
import { readNotification, readAllNotification } from 'fintech/store/actions/notifications';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import useStyles from 'fintech/components/notificationBox/index.style';
import { useSelector, useDispatch } from 'react-redux';
import { mobileMediaQuery } from 'fintech/components/style/common';
import { useClickAway, useDebounceFn, useMount } from 'ahooks';
import LoadingSpinnerWithText from 'fintech/components/util/LoadingSpinnerWithText';

const handleNotificationClick = (e, notification, keycloak, dispatch) => {
    if (!notification.read) dispatch(readNotification(notification.id, keycloak));
};

const NotificationBox = ({ onClickOutside, onOpen }) => {
    const styles = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const { hasMore, notifications, isLoading } = useSelector((state) => state.notifications);
    const matches = useMediaQuery(mobileMediaQuery);

    // Inifinite Scroll Logic
    const observer = useRef();
    const lastNotificationRef = useCallback(
        (node) => {
            if (notifications.length > 0) {
                if (observer.current) observer.current.disconnect();
                observer.current = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting && !hasMore) {
                        // This means we are at the end of the scroll and more notifications exists
                        setPageNumber((prev) => prev + 1);
                    }
                });
                if (node) observer.current.observe(node);
            }
        },
        [hasMore, notifications]
    );

    useFetchNotification(pageNumber);

    //prevent double click
    const { run } = useDebounceFn(
        () => {
            setIsOpen(true);
            if (onOpen) onOpen();
        },
        {
            wait: 500,
        }
    );
    useMount(() => {
        run();
    });

    const notificationRef = useRef();
    useClickAway(
        () => {
            if (onClickOutside && isOpen) {
                onClickOutside();
            }
        },
        () => document.getElementById('box2')
    );

    return (
        <div ref={notificationRef} id="box2">
            <Box classes={{ root: matches ? styles.root : styles.rootMobile }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    classes={{ root: styles.headerContainer }}
                >
                    <Typography classes={{ root: styles.headerTextPrimary }}>
                        {t('notifications.notifications')}
                    </Typography>
                    <Typography
                        color="primary"
                        classes={{ root: styles.headerTextSecondary }}
                        onClick={() => dispatch(readAllNotification(keycloak))}
                    >
                        {t('notifications.readAll')}
                    </Typography>
                </Box>
                <Box style={{ height: '363px', overflowY: 'auto', overflowX: 'hidden' }}>
                    {isLoading ? (
                        <div className={styles.loadingGrid}>
                            <LoadingSpinnerWithText style={{ height: '100%' }} />
                        </div>
                    ) : notificationExists(notifications) ? (
                        notifications.map((notification, index) => {
                            if (notifications.length === index + 1) {
                                return (
                                    <Notification
                                        ref={lastNotificationRef}
                                        key={`notification-${notification.id}`}
                                        handleClick={(e) =>
                                            handleNotificationClick(e, notification, keycloak, dispatch)
                                        }
                                        value={notification}
                                    />
                                );
                            }

                            return (
                                <Notification
                                    key={`notification-${notification.id}`}
                                    handleClick={(e) => handleNotificationClick(e, notification, keycloak, dispatch)}
                                    value={notification}
                                />
                            );
                        })
                    ) : (
                        <PlaceHolder></PlaceHolder>
                    )}
                </Box>
            </Box>
        </div>
    );
};

const notificationExists = (notifications) => {
    if (notifications && notifications.length > 0) return true;
    return false;
};

export default NotificationBox;
