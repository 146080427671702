import { getRequest } from 'fintech/api/api';
import { AnnouncementActions } from './ActionTypes';
import { APIS } from 'fintech/constants';

const setAnnouncement = (announcements) => ({
    type: AnnouncementActions.SET_ANNOUNCEMENT,
    announcements: announcements,
});

const setLoading = () => ({ type: AnnouncementActions.SET_ANNOUNCEMENT_LOADING });

const setError = () => ({ type: AnnouncementActions.SET_ANNOUNCEMENT_LOAD_ERROR });

export const fetchAnnouncements = (keycloak) => {
    return (dispatch) => {
        dispatch(setLoading());
        getRequest(APIS.ANNOUNCEMENT.LATEST, keycloak)
            .then((payload) => dispatch(setAnnouncement(payload.data)))
            .catch(() => {
                dispatch(setError());
            });
    };
};
