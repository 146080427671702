import { makeStyles } from '@material-ui/core';
import { cardGridStyle } from 'fintech/components/style/common';

const useStyles = makeStyles({
    StartupInvestorsGrid: {
        ...cardGridStyle,
        margin: '16px 0 0',
        padding: '20px',
    },
    InvestorsCard: {
        ...cardGridStyle,
        margin: '25px 0 0',
        boxShadow: 'none',
    },
});

export default useStyles;
