import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useEventStyles from 'fintech/components/dashboard/event/eventcardcontent/index.style';
import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { buttonStyleInteraction, COLOR_PRIMARY_BASE } from 'fintech/components/style/common';
import useStyles from './index.style.js';
import { getPlaintextFromHtml } from 'fintech/utils/StringUtils.js';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton/index.js';

/**
 * Program Card Component for showing the contents received from the client
 */
const ProgramCardContent = (props) => {
    const eventClasses = useEventStyles();
    const { t } = useTranslation('supportProgram');
    const { title, startDate, endDate, description, lastApplicationDate, showEdit, onClickEdit } = props;
    const classes = useStyles();

    const name = title;
    const plainTextDesc = getPlaintextFromHtml(description);
    const information = plainTextDesc;

    const headerJSX = (
        <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
                <Typography className={classes.lastApplicationDateTypo}>
                    {t('general.applicationDeadline', { date: lastApplicationDate })}
                </Typography>
            </Grid>
            <Grid item>
                {showEdit ? (
                    <ShrinkingButton
                        alwaysShrink
                        style={{ marginRight: '-8px' }}
                        shrinkedIcon={<Edit fontSize="small" style={{ color: COLOR_PRIMARY_BASE }} />}
                        onClick={() => {
                            onClickEdit();
                        }}
                        otherStyles={buttonStyleInteraction}
                    />
                ) : (
                    <Box component="div" height={2} width={2} />
                )}
            </Grid>
        </Grid>
    );

    return (
        <CardContent>
            {headerJSX}
            <Typography className={eventClasses.eventNameStyle}>{name}</Typography>
            <Typography className={eventClasses.eventDateStyle}>{`${startDate} - ${endDate}`}</Typography>
            <Typography className={eventClasses.eventInformationStyle}>{information}</Typography>
        </CardContent>
    );
};

export default ProgramCardContent;
