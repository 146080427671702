import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';
import ShrinkingButton from 'fintech/components/ui/button/ShrinkingButton';
import useStyle from './index.style';

const PdfDownload = ({ handleClick, pdfLoading }) => {
    const styles = useStyle();
    const { t } = useTranslation('supportProgram');

    return (
        <div className={styles.pdfContainer}>
            <div className={styles.pdfTitle}> {t('pdf.applicationInformation')}</div>
            <div className={styles.buttonContainer}>
                <ShrinkingButton
                    onClick={() => handleClick()}
                    shrinkedIcon={<GetAppIcon fontSize="small" />}
                    fullIcon={<GetAppIcon />}
                    fullText={t('pdf.pdfCreate')}
                    disabled={pdfLoading}
                    loading={pdfLoading}
                />
            </div>
        </div>
    );
};

export default PdfDownload;
