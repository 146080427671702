import React from 'react';
import { Breadcrumbs, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import PropTypes from 'prop-types';
import LanguagePanel from 'fintech/components/lang/LanguagePanel';
import LogoFinTechHub from 'fintech/static/images/Logo-FinTechHub-Disi.svg';
import useStyles from './index.style';
import {
    COLOR_SECONDARY_SHADE_01,
    COLOR_THEME_FILL_01,
    fontStyle,
    mobileMediaQuery,
    mobileMicroQuery,
    mobileSmallQuery,
} from 'fintech/components/style/common';
import { HOME } from 'fintech/routes';
import { useHistory } from 'react-router-dom';

const AppBar = (props) => {
    const history = useHistory();
    const matches = useMediaQuery(mobileMediaQuery);
    const small = useMediaQuery(mobileSmallQuery);
    const micro = !useMediaQuery(mobileMicroQuery);

    const { currentIndex = 0, options = [], reducedPadding, ...others } = props;

    const classes = useStyles({ micro, reducedPadding });

    const optsJSX = options?.map((opt, idx) => (
        <Typography
            key={idx}
            style={{
                ...fontStyle,
                fontSize: '18px',
                color: idx <= currentIndex ? COLOR_THEME_FILL_01 : COLOR_SECONDARY_SHADE_01,
            }}
        >
            {opt}
        </Typography>
    ));

    return (
        <Grid
            {...others}
            className={classes.barRoot}
            item
            xs={12}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            wrap={matches ? 'nowrap' : 'wrap'}
        >
            <Grid className={classes.logoWrapper} item onClick={() => history.push(HOME)}>
                <img src={LogoFinTechHub} width={small ? 220 : 170} />
            </Grid>
            {matches && (
                <Grid item container direction="row" justify="center">
                    <Breadcrumbs
                        classes={{
                            root: classes.breadcrumbsRoot,
                            ol: classes.breadcrumbsOl,
                            separator: classes.breadcrumbsSeperator,
                        }}
                        separator={<NavigateNext style={{ color: COLOR_SECONDARY_SHADE_01 }} fontSize="small" />}
                    >
                        {optsJSX}
                    </Breadcrumbs>
                </Grid>
            )}
            <div className={classes.languagePanelWrapper}>
                <LanguagePanel />
            </div>
        </Grid>
    );
};

AppBar.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string),
    currentIndex: PropTypes.number,
};

export default AppBar;
