import React, { useEffect, useState } from 'react';
import useStyle from './index.style';
import IconsSingleDownload from 'fintech/icons/IconsSingleDownload';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { getReportList } from 'fintech/api/fintechService';
import * as FileServerService from 'fintech/api/fileService';
import { Grid, IconButton } from '@material-ui/core';
import StickyTable from 'fintech/components/table/StickyTable';
import { renderGridStyle } from 'fintech/pages/post-login/EntrepreneursPage/index.style';
import GridPagination from 'fintech/components/pagination/GridPagination';

const Index = () => {
    const classes = useStyle();
    const { t } = useTranslation('reports');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [reportsList, setReportsList] = useState([]);
    const { keycloak } = useKeycloak();
    const rowHeight = 48;
    const pageSize = 15;

    useEffect(() => {
        getReportList(keycloak, {
            page: page - 1,
            size: pageSize,
        }).then(response => {
            setReportsList(response.data.content);
            setCount(response.data.totalPages);
        });
    }, [page]);

    const columnCount = 5;
    const headers = [
        {
            id: columnCount - 5,
            type: 'label',
            value: '#',
            style: { width: '0%', minWidth: '59px' },
        },
        {
            id: columnCount - 4,
            type: 'label',
            value: t('table.reportName'),
        },
        {
            id: columnCount - 3,
            type: 'label',
            value: t('table.reportDate'),
            style: { minWidth: '116px' },
        },
        {
            id: columnCount - 2,
            type: 'label',
            value: t('table.reportSize'),
            style: { minWidth: '124px' },
        },
        {
            id: columnCount - 1,
            type: 'label',
            value: t('table.reportStatus'),
            style: { width: '50%' },
        },
    ];

    const sizeToText = fileSize => {
        if (isNaN(fileSize)) {
            return '-'
        }
        else {
            if (fileSize > 1024) {
                return (fileSize / 1024).toFixed(2) + ' MB';
            }
            return fileSize.toFixed(2) + ' KB';
        }
    }

    const generateFilename = (reportType) => t('table.name.' + reportType) + '.xlsx';

    const rows = reportsList.map(report => {
        const fileSize = (report.file?.fileSize / 1024);
        const fileId = report.file?.uuid;
        const fileName = generateFilename(report.reportType);
        const aRow = [
            {
                // Intentionally empty
            },
            {
                type: 'component',
                component: fileId && <IconButton
                    className={'prevent-propagation'}
                    style={{ marginLeft: '-18px' }}
                    onClick={() => {
                        FileServerService.downloadFile(fileId, fileName);
                    }}
                >
                    <IconsSingleDownload />
                </IconButton>
            },
            {
                type: 'text',
                value: { label: fileName }
            },
            {
                type: 'text',
                value: { label: report.createDate }
            },
            {
                type: 'text',
                value: { label: sizeToText(fileSize) }
            },
            {
                type: 'text',
                value: {
                    label: (report.reportPreparationStatus === 'COMPLETED' ?
                        t('table.status.completed') : (report.reportPreparationStatus === 'FAILED' ?
                            t('table.status.failed') : t('table.status.notcompleted'))),
                }
            },
        ];

        return aRow;
    });

    const styles = renderGridStyle({
        isLoading: false,
        numberOfRows: rows ? rows.length : 0,
        heightInPixelsPerElement: rowHeight,
        isScrollable: false,
    });


    return (
        <Grid item className={classes.table}>
            <StickyTable
                type={'reports'}
                containerClass={styles.container}
                headers={headers}
                rows={rows}
                rowHeight={rowHeight}
                rightScrollButtonRightMargin={43}
                buttonActions={{
                    handleRowClick: false,
                }}
                disableStickyHeader={true}
            />
            {rows && rows.length > 0 && (
                <GridPagination
                    controlled
                    page={page}
                    defaultPage={1}
                    count={count}
                    handleChange={(_, newPageValue) => {
                        setPage(newPageValue);
                        window.scroll({ top: 0, left: 0 });
                    }}
                />
            )}
        </Grid>
    );
};

Index.propTypes = {};

export default Index;
