import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    wrapper: {
        height: 44,
        fontSize: 14,
        fontWeight: 500,
        color: '#3f5162',
        padding: '10px',
        borderRadius: '8px',
        border: 'solid 1px #d7e2e8',
        width: 'fit-content',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 16,
    },
    active: {
        background: '#3f5162',
        color: 'white',
    },
});

export default useStyles;
