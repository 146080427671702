import React from 'react';
import useStyles from './index.style';
import LogoFinTechHub from 'fintech/static/images/Logo-FinTechHub-Erkek.svg';
import IconsHamburgerClose from 'fintech/icons/IconsSingleHamburgerClose';
import { useTranslation } from 'react-i18next';
import * as ROUTES from 'fintech/routes';
import { useHistory } from 'react-router-dom';
import SocialMediaIcons from 'fintech/components/applications/layout/pre-login/SocialMediaIcons/index';
import SupportProgramLogo from 'fintech/static/images/icons-multiple-destekprogramlari-hamburger.svg';
import MentorLogo from 'fintech/static/images/icons-multiple-mentor-hamburger.svg';
import ConsultantLogo from 'fintech/static/images/icons-multiple-danisman-hamburger.svg';
import StartupLogo from 'fintech/static/images/icons-multiple-girisimci-hamburger.svg';
import InvestorLogo from 'fintech/static/images/icons-multiple-yatirimci-hamburger.svg';
import FaqLogo from 'fintech/static/images/icons-multiple-sss-hamburger.svg';

// ONE PAGE MENU LOGO
import SupportsLogo from 'fintech/static/images/menu-icons/destekler-menu.svg';
import EducationLogo from 'fintech/static/images/menu-icons/egitimler-menu.svg';
import MentorsLogo from 'fintech/static/images/menu-icons/mentorlar-menu.svg';
import ProgramAboutLogo from 'fintech/static/images/menu-icons/program-hakkinda-menu.svg';
import ContinuaLogo from 'fintech/static/images/menu-icons/program-sureci-menu.svg';
import FAQLogo from 'fintech/static/images/menu-icons/sss-menu.svg';
import TimesheetLogo from 'fintech/static/images/menu-icons/zaman-cizelgesi-menu.svg';

const MobileMenu = ({ setMobileMenuActive, supportProgramOnePage }) => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation('preLogin');

    const closeHamburger = () => {
        document.body.style.height = 'auto';
        document.body.style.overflow = 'auto';
        setMobileMenuActive(false);
    };

    const scrollTo = (element) => {
        const { top, left } = document.getElementById(element).getBoundingClientRect();

        window.scroll({ top, left, behavior: 'smooth' });
    };

    return (
        <div className={classes.menuWrapper}>
            <img alt="logo" src={LogoFinTechHub} width="220px" className={classes.logoWrapper} />
            {!supportProgramOnePage ? (
                <ul className={classes.hamburgerList}>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-support');
                        }}
                    >
                        <img src={SupportProgramLogo} />
                        {t('titles.support')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-testimonial');
                            document.getElementById('mentors').click();
                        }}
                    >
                        <img src={MentorLogo} />
                        {t('titles.mentors')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-testimonial');
                            document.getElementById('consultants').click();
                        }}
                    >
                        <img src={ConsultantLogo} />
                        {t('titles.consultants')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-startup');
                        }}
                    >
                        <img src={StartupLogo} />
                        {t('titles.startups')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-investors');
                        }}
                    >
                        <img src={InvestorLogo} />
                        {t('titles.investors')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-faq');
                        }}
                    >
                        <img src={FaqLogo} />
                        {t('titles.faqList')}
                    </li>
                </ul>
            ) : (
                <ul className={classes.hamburgerList}>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-about-program');
                        }}
                    >
                        <img src={ProgramAboutLogo} />
                        {t('titles.aboutProgram')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-supports');
                        }}
                    >
                        <img src={SupportsLogo} />
                        {t('titles.supports')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-timesheet');
                        }}
                    >
                        <img src={TimesheetLogo} />
                        {t('titles.timesheet')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-continua');
                        }}
                    >
                        <img src={ContinuaLogo} />
                        {t('titles.continua')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-testimonial');
                            document.getElementById('mentors').click();
                        }}
                    >
                        <img src={MentorsLogo} />
                        {t('titles.mentors')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-educationAndSeminars');
                        }}
                    >
                        <img src={EducationLogo} />
                        {t('titles.educationAndSeminars')}
                    </li>
                    <li
                        onClick={() => {
                            closeHamburger();
                            scrollTo('section-faq');
                        }}
                    >
                        <img src={FAQLogo} />
                        {t('titles.faqList')}
                    </li>
                </ul>
            )}

            <SocialMediaIcons iconsWrapperClassname={classes.iconsWrapper} />
            <div className={classes.policyContainer}>
                <span
                    onClick={() => {
                        closeHamburger();
                        history.push(ROUTES.CLARIFICATION_TEXT);
                    }}
                >
                    {t('mobileMenu.policies.clarification')}
                </span>
                <span
                    onClick={() => {
                        closeHamburger();
                        history.push(ROUTES.TERMS_OF_USE);
                    }}
                >
                    {t('mobileMenu.policies.termsOfUse')}
                </span>
                <span
                    onClick={() => {
                        closeHamburger();
                        history.push(ROUTES.PRIVACY_POLICY);
                    }}
                >
                    {t('mobileMenu.policies.privacyPolicy')}
                </span>
                <span
                    onClick={() => {
                        closeHamburger();
                        history.push(ROUTES.COOKIE_POLICY);
                    }}
                >
                    {t('mobileMenu.policies.cookiePolicy')}
                </span>
            </div>
            <span className={classes.copyRight}>{t('mobileMenu.copyRight')}</span>
            <div className={classes.closeIcon} onClick={closeHamburger}>
                <IconsHamburgerClose />
            </div>
        </div>
    );
};

export default MobileMenu;
