export const DEFAULT_MIME_TYPES = [
    'image/*',
    'video/*',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const MEDIA_MIME_TYPES = [
    'image/png',
    'image/jpeg',
    'video/mp4',
    'video/quicktime',
    'video/mp4',
    'video/x-ms-wmv',
    'video/x-msvideo',
    'video/x-matroska',
];

export const DOCUMENT_MIME_TYPES = [
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    '.docx',
    '.doc',
    '.pptx',
    '.ppt',
];

export const MIME_TYPES = {
    pdf: 'application/pdf',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const VIDEO_MIMES_START = 'video/';
export const IMAGE_MIMES_START = 'image/';
export const DOCUMENT_MIMES_START = 'application/';

export const VIDEO_LIMIT_ERROR = 'video_limit_error';
export const IMAGE_LIMIT_ERROR = 'image_limit_error';
export const DOCUMENT_LIMIT_ERROR = 'document_limit_error';
export const FILE_TYPE_ERROR = 'file-invalid-type';
export const FILE_SIZE_ERROR = 'file-too-large';
