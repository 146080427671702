import { makeStyles } from '@material-ui/core';
import { COLOR_SECONDARY_BASE, COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    continuaSection: {
        padding: '61px 121px 82px 120px',
        backgroundColor: COLOR_PRIMARY_SHADE_02,

        [theme.breakpoints.down('sm')]: {
            paddingLeft: 14,
            paddingRight: 14,
            paddingTop: 50,
            paddingBottom: 50,
        },
    },
    sectionTitle: {
        fontWeight: '500',
        fontSize: '36px',
        color: COLOR_SECONDARY_BASE,
        marginBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 16,
            textAlign: 'center',
            marginBottom: '16px',
        },
    },
    continuaImg: {
        marginRight: 118,
        width: '100%',
        height: '375px',
        [theme.breakpoints.down('sm')]: {
            width: 238,
            display: 'block',
            margin: '0 auto',
            height: 'auto',
        },
    },
    defWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

export default useStyles;
