import { makeStyles } from '@material-ui/core';
import { buttonStyle, COLOR_PRIMARY_BASE, COLOR_PRIMARY_SHADE_03 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    OptionsButton: {
        ...buttonStyle,
        backgroundColor: '#ffffff',
        width: '24px',
        minWidth: '24px',
        maxWidth: '24px',
        padding: 0,
        '&:hover': {
            backgroundColor: '#ffffff',
            color: ({ disabled = false }) => (!disabled ? COLOR_PRIMARY_BASE : COLOR_PRIMARY_SHADE_03),
            cursor: ({ disabled = false }) => (!disabled ? 'pointer' : 'not-allowed'),
        },
    },
    ListItemButton: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        },
    },
    MoreVertIcon: {
        color: ({ disabled = false }) => (!disabled ? COLOR_PRIMARY_BASE : COLOR_PRIMARY_SHADE_03),
    },
    OptionsPopper: {
        zIndex: 9000,
    },
});

export default useStyles;
