import React from 'react';
import Cookies from 'js-cookie';
import { MIME_TYPES } from 'fintech/components/util/FileConstants';
import SvgIconsSingleExcel from 'fintech/icons/IconsSingleExcel';
import SvgIconsSinglePpt from 'fintech/icons/IconsSinglePpt';
import SvgIconsSingleWord from 'fintech/icons/IconsSingleWord';
import SvgIconsSinglePdf from 'fintech/icons/IconsSinglePdf';
import SvgIconsSingleDocument from 'fintech/icons/IconsSingleDocument';

export const getFileIconByMimeType = (type) => {
    switch (type) {
        case MIME_TYPES.xls: {
            return <SvgIconsSingleExcel />;
        }
        case MIME_TYPES.xlsx: {
            return <SvgIconsSingleExcel />;
        }
        case MIME_TYPES.ppt: {
            return <SvgIconsSinglePpt />;
        }
        case MIME_TYPES.pptx: {
            return <SvgIconsSinglePpt />;
        }
        case MIME_TYPES.doc:
        case MIME_TYPES.docx: {
            return <SvgIconsSingleWord />;
        }
        case MIME_TYPES.pdf: {
            return <SvgIconsSinglePdf />;
        }
        default:
            return <SvgIconsSingleDocument />;
    }
};

export const getMegaByteToByte = (megaByte) => {
    return megaByte * 1024 * 1024;
};

export const setFileServerToken = (fileToken) => {
    Cookies.set('file-server-token', fileToken, { expires: 365 });
};

export const getFileServerToken = () => Cookies.get('file-server-token');

export const getMediaUrlWithToken = (url) => {
    return url + '?s=' + getFileServerToken();
};
