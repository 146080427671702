import { makeStyles } from '@material-ui/core';

import { COLOR_SECONDARY_BASE, COLOR_SECONDARY_SHADE_01 } from 'fintech/components/style/common';

export const makeMessageBoxStyles = makeStyles(() => ({
    boxUser: {
        display: "flex",
        gap: "20px",
        flexDirection: "row-reverse",

    },

    boxClient: {
        display: "flex",
        gap: "20px",
    },

    info: {
        display: "flex",
        flexDirection: "column",
    },

    messageOwnerImage: {
        width: "40px",
        height: "40px",
        objectFit: "cover",
        borderRadius: "50%",
    },

    content: {
        maxWidth: "80%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },

    fileContent: {
        maxWidth: "80%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        cursor: "pointer",
        textDecoration: "underline",
        color: "blue",
    },

    textFieldUser: {
        backgroundColor: "white",
        padding: "10px 20px",
        borderRadius: "10px 0px 10px 10px",
    },

    textFieldClient: {
        backgroundColor: "white",
        padding: "10px 20px",
        borderRadius: "0px 10px 10px 10px",
    }

}));