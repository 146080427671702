import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formRoot: {
        padding: ({ result }) => (result ? '100px 38px 108px' : '40px 38px 48px'),
        overflowY: 'auto',
        overflowX: 'hidden',
        margin: 'auto',
        maxWidth: '900px',
        [theme.breakpoints.up('lg')]: {
            padding: ({ generalInfo, result }) => (generalInfo || result ? '102px 100px 102px' : '40px 38px 48px'),
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 16px 12px !important',
        },
    },
}));

export default useStyles;
