import React from 'react';
import PageTemplate from 'fintech/components/PageTemplate';
import AllConsultants from 'fintech/components/search/consultant/AllConsultants/AllConsultants';
import TabSwitch from 'fintech/components/ui/navigation/TabSwitch';
import { useTranslation } from 'react-i18next';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';

function ConsultantPage() {
    const { t } = useTranslation('consultantSearch');

    const expertiseTypeInitialState = [
        { id: 1, name: t('expertise.all'), selected: true },
        { id: 3, name: t('expertise.grantAndIncentive'), selected: false },
        { id: 2, name: t('expertise.lawAndRegulation'), selected: false },
        { id: 7, name: t('expertise.businessDevelopmentAndMarketing'), selected: false },
        { id: 4, name: t('expertise.sustainableTaxManagement'), selected: false },
        { id: 5, name: t('expertise.companyEstablishment'), selected: false },
        { id: 6, name: t('expertise.investmentManagement'), selected: false },
    ];

    const tabs = [
        {
            header: {
                label: t('tabs.allConsultants.label'),
            },
            component: <AllConsultants expertiseTypeInitialState={expertiseTypeInitialState} />,
            errorMsg: t('tabs.allConsultants.errorMessage'),
        },
    ];

    return (
        <PageTemplate>
            <PageHeaderBox />
            <TabSwitch tabs={tabs} />
        </PageTemplate>
    );
}

export default ConsultantPage;
