import React, { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import useTypeButtonStyles, { ButtonTypeSelect } from 'fintech/components/util/ButtonTypeSelect';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { ConsultantSearchActions, ConsultantFAQSearchActions } from 'fintech/store/actions/ActionTypes';
import useStyles from './index.style';
import { getSystemLanguage } from 'fintech/utils/LanguageUtils';
import { laptopLargeMax } from 'fintech/components/style/common';
import { resetPaginationOnSearchBoxOrExpertiseType } from 'fintech/store/actions/faqSearch';
import _ from 'lodash';

function ExpertiseSelect({ errorCallback, fetchData, type, handleExpertiseSelect }) {
    const TypeSelectButtonStyles = useTypeButtonStyles();
    const classes = useStyles();
    const matches = useMediaQuery(laptopLargeMax);

    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    const expertiseType = useSelector((state) => state[type].global.expertiseTypes);
    const [localExpertiseTypes, setLocalExpertiseTypes] = useState([]);

    const [selectedId, setSelectedId] = useState(0);
    const [systemLang] = useState(getSystemLanguage());

    const filterByExpertiseType = (id) => {
        if (type === 'consultantSearch') {
            dispatch({ type: ConsultantSearchActions.RESET_CONSULTANT_SEARCHBOX });
            dispatch({ type: ConsultantSearchActions.RESET_CONSULTANT_GRID_PAGINATION });
        } else {
            dispatch({ type: ConsultantFAQSearchActions.RESET_CONSULTANT_FAQ_SEARCHBOX });
            dispatch({ type: ConsultantFAQSearchActions.RESET_CONSULTANT_FAQ_PAGINATION });
        }

        const updatedExpertise = localExpertiseTypes.map((cur) => {
            if (cur.id === id) {
                cur.selected = !cur.selected;
            } else {
                cur.selected = false;
            }
            if (cur.selected) {
                setSelectedId(cur.id);
            }
            return cur;
        });

        // to avoid unnecessary api call.
        if (id === 1 && id === selectedId) {
            return;
        }

        const selectedType = updatedExpertise.find((cur) => cur.selected);

        handleExpertiseSelect(selectedType || localExpertiseTypes[0]);
        setLocalExpertiseTypes([...updatedExpertise]);

        if (!selectedType || id === 1) {
            // reset if no filter or all selected.
            dispatch(fetchData({ keycloak, data: null, errorCallback }));

            // if nothing is selected, select all
            setLocalExpertiseTypes((prev) => {
                const newList = [...prev];
                newList[0].selected = true;
                setSelectedId(newList[0].id);

                return newList;
            });

            return;
        }

        const data = {
            searchTerm: '',
            selectedExpertiseTerm: selectedType.name,
        };

        if (type === 'consultantSearch') {
            data.nameSelected = false;
            data.userExpertiseSelected = true;
        }

        dispatch(fetchData({ keycloak, data, errorCallback }));

        if (type === 'faqSearch') {
            dispatch(resetPaginationOnSearchBoxOrExpertiseType());
        }
    };

    useEffect(() => {
        setLocalExpertiseTypes(_.cloneDeep(expertiseType));
    }, [expertiseType]);

    const getMarginFor1024WidthScreen = (index) => {
        if (matches && ((systemLang === 'en' && index === 3) || (systemLang === 'tr' && index === 4))) {
            return { marginLeft: 0 };
        }
        return {};
    };
    return (
        <Grid container className={classes.container}>
            {localExpertiseTypes.map((expertise, index) => (
                <ButtonTypeSelect
                    key={expertise.id}
                    onClick={() => filterByExpertiseType(expertise.id)}
                    className={expertise.selected ? TypeSelectButtonStyles.selected : ''}
                    style={getMarginFor1024WidthScreen(index)}
                >
                    {expertise.name}
                </ButtonTypeSelect>
            ))}
        </Grid>
    );
}

export default ExpertiseSelect;
