import React from 'react';
import RepresentativeInfos from 'fintech/components/membership/Sections/RepresentativeInfos';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import { DetailContent, DetailTitle } from 'fintech/components/membership/Applications/index.style';
import SplitButton from 'fintech/components/ui/button/SplitButton';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import { StyledTag } from 'fintech/components/ui/button/common';
import { LocalOffer } from '@material-ui/icons';
import * as FileServerService from 'fintech/api/fileService';
import { useTranslation } from 'react-i18next';
import { parseSubVerticals } from 'fintech/utils/DataParser';
import useStyles from './index.style';

const getMentorSections = (titles, infos) => {
    return [
        { title: titles.personalInfos, component: <RepresentativeInfos infos={infos} excludedFields={['title']} /> },
        { title: titles.coverLetterAndInterests, component: <CoverLetterAndInterests infos={infos} /> },
    ];
};

const CoverLetterAndInterests = ({ infos }) => {
    const { t } = useTranslation('membershipManagement');
    const classes = useStyles();

    const itemsJSX = [];
    const verticals = parseSubVerticals(infos.fintechSubVerticals, true);

    const handleFileRequest = (id, name) => {
        FileServerService.downloadFile(id, name);
    };

    itemsJSX.push(
        <IntroGridItem
            key="coverletter"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.coverLetterAndInterest.coverLetter')}</DetailTitle>}
            content={<DetailContent>{infos.coverLetter || '-'}</DetailContent>}
        />
    );

    // eslint-disable-next-line no-undef
    const verts = _.keys(verticals)?.map((vert, idx) => (
        <SplitButton key={idx} option={vert} subOptions={verticals[vert]} />
    ));

    itemsJSX.push(
        <IntroGridItem
            key="verticals"
            title={
                <DetailTitle>
                    {t('applicationDetails.sections.coverLetterAndInterest.interestedFintechVerticals')}
                </DetailTitle>
            }
            content={verts && verts.length ? <TagGroup limit={5} items={verts} /> : '-'}
        />
    );

    const techs = infos.technologies?.map((tech) => (
        <StyledTag
            disableElevation
            disableRipple
            disableTouchRipple
            key={tech.id}
            startIcon={<LocalOffer fontSize="small" />}
        >
            {tech.name}
        </StyledTag>
    ));

    itemsJSX.push(
        <IntroGridItem
            key="interestedTechnologies"
            title={
                <DetailTitle>
                    {t('applicationDetails.sections.coverLetterAndInterest.interestedTechnologies')}
                </DetailTitle>
            }
            content={techs && techs.length ? <TagGroup limit={5} items={techs} /> : '-'}
        />
    );

    itemsJSX.push(
        <IntroGridItem
            key="files"
            htmlBind={false}
            title={<DetailTitle>{t('applicationDetails.sections.coverLetterAndInterest.uploadedFiles')}</DetailTitle>}
            content={
                <>
                    {infos.files && infos.files.length > 0 ? (
                        <DetailContent>
                            {infos.files.map((file) => (
                                <span key={file.uuid}>
                                    <span
                                        onClick={() => handleFileRequest(file.uuid, file.fileName)}
                                        className={classes.file}
                                    >
                                        {file.fileName}
                                    </span>
                                    &nbsp;
                                </span>
                            ))}
                        </DetailContent>
                    ) : (
                        <span style={{ fontWeight: '500' }}>{'-'}</span>
                    )}
                </>
            }
        />
    );

    return itemsJSX;
};

export default getMentorSections;
