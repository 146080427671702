import { InvestorSearchActions } from 'fintech/store/actions/ActionTypes';
import { APIS } from 'fintech/constants';
import { getRequest, postRequest } from 'fintech/api/api';
import { isNil } from 'lodash';
import { parseSubVerticals } from 'fintech/utils/DataParser';
import { withPagination } from 'fintech/constants/apis';
import { GRID_HEADER_STATE } from './entrepreneur';
import { hasRoles } from 'fintech/utils/Auth';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';
import { ACTIVE } from 'fintech/components/util/StatusConstants';

export const persistState = (persist) => ({
    type: InvestorSearchActions.SET_INVESTOR_GLOBAL_PERSIST_STATE,
    data: persist,
});

export const resetAllFilters = () => {
    return (dispatch) => {
        dispatch({ type: InvestorSearchActions.RESET_INVESTOR_ADVANCED_FILTER });
        dispatch({ type: InvestorSearchActions.RESET_INVESTOR_APPLIED_FILTERS });
        dispatch({ type: InvestorSearchActions.RESET_INVESTOR_SEARCHBOX });

        // Reset pagination
        dispatch({ type: InvestorSearchActions.RESET_INVESTOR_GRID_PAGINATION });
    };
};

// Global
export const setTotalNumber = (data) => ({
    type: InvestorSearchActions.SET_INVESTOR_GLOBAL_TOTAL_ACTIVE_NUMBER,
    data,
});
export const setFilteredNumber = (data) => ({
    type: InvestorSearchActions.SET_INVESTOR_GLOBAL_FILTERED_NUMBER,
    data,
});

export const toggleAdvancedFilter = () => ({ type: InvestorSearchActions.TOGGLE_INVESTOR_ADVANCEDFILTER });

export const fetchAdvancedFilterData = (keycloak, errorCallback) => {
    return (dispatch) => {
        getRequest(APIS.INVESTOR.getFilterURL(), keycloak)
            .then((resp) => {
                dispatch({ type: InvestorSearchActions.SET_INVESTOR_ADVANCEDFILTER_DATA, data: resp.data });
            })
            .catch(() => {
                errorCallback();
            });
    };
};

export const setGridHeaders = (headers) => {
    return (dispatch) => {
        dispatch({ type: InvestorSearchActions.SET_INVESTOR_GRID_HEADERS, data: headers });
    };
};

export const setGridPage = (pageNumber) => ({
    type: InvestorSearchActions.SET_INVESTOR_GRID_PAGINATION,
    data: {
        page: pageNumber,
    },
});

export const fetchActiveInvestorsCount = (keycloak, errorCallback) => {
    return (dispatch) => {
        getRequest(APIS.INVESTOR.getActiveUsersURL(), keycloak)
            .then((resp) => {
                dispatch({ type: InvestorSearchActions.SET_INVESTOR_GLOBAL_TOTAL_ACTIVE_NUMBER, data: resp.data });
            })
            .catch(() => {
                errorCallback();
            });
    };
};

export const fetchGridData = ({
    keycloak,
    data,
    errorCallback,
    useLastQueryType = false,
    isFilter = true,
    reloadGrid = true,
    timeout = 700,
}) => {
    return (dispatch, getState) => {
        // Initial call when the component mounts
        if (reloadGrid) {
            // Show a loading indicator in place of the whole grid
            dispatch({ type: InvestorSearchActions.SET_INVESTOR_GRID_LOADING_INITIAL });
        } else {
            // TODO: Give the grid a disabled effect much like in DealRoom
            dispatch({ type: InvestorSearchActions.SET_INVESTOR_GRID_LOADING });
        }

        setTimeout(() => {
            const { appliedFilters, global, grid, searchBox } = getState().investorSearch;
            const { filteredNumber, lastQueryType } = global;
            const { lastQueryParams } = searchBox;
            const { headers, pagination } = grid;

            let commonArgs = {
                dispatch,
                keycloak,
                headers,
                pageSize: pagination.pageSize,
                page: pagination.page,
                filteredNumber,
                errorCallback,
                isAdmin: hasRoles(keycloak, ROLE_ADMIN),
            };
            console.log(appliedFilters, data);
            if (!useLastQueryType) {
                isFilter ? makeFilter({ ...commonArgs, appliedFilters }) : makeSearch({ ...commonArgs, data });
            } else {
                lastQueryType === 'search'
                    ? makeSearch({ ...commonArgs, data: lastQueryParams })
                    : makeFilter({ ...commonArgs, appliedFilters });
            }
        }, timeout);
    };
};

export const makeFilter = ({ dispatch, keycloak, headers, appliedFilters, pageSize, page, errorCallback, isAdmin }) => {
    const url = withPagination(APIS.INVESTOR.getFilterURL(), pageSize, page - 1);
    postRequest(appendSortQueryToUrl(url, headers), keycloak, appliedFilters)
        .then((resp) => {
            const { content, totalElements, totalPages } = resp.data;
            const rows = [];

            content.forEach((data) => {
                rows.push(generateGridRowFromInvestorData(data, isAdmin));
            });

            let count = 0;
            for (const properties in appliedFilters) {
                count += appliedFilters[properties].length;
            }

            if (count > 0) {
                dispatch(setFilteredNumber(totalElements));
            } else {
                dispatch(setFilteredNumber(null));
            }

            dispatch({ type: InvestorSearchActions.SET_INVESTOR_GRID_DATA, data: rows });
            dispatch({
                type: InvestorSearchActions.SET_INVESTOR_GRID_PAGINATION,
                data: { count: totalPages },
            });
            dispatch({ type: InvestorSearchActions.SET_INVESTOR_LAST_QUERY_TYPE, data: 'filter' });
        })
        .catch(() => {
            dispatch({ type: InvestorSearchActions.SET_INVESTOR_GRID_LOAD_ERROR });
            errorCallback();
        });
};

export const makeSearch = ({ dispatch, keycloak, data, headers, pageSize, page, errorCallback, isAdmin }) => {
    const url = withPagination(APIS.INVESTOR.getSearchURL(), pageSize, page - 1);
    postRequest(appendSortQueryToUrl(url, headers), keycloak, data)
        .then((resp) => {
            const { totalElements, content, totalPages } = resp.data;
            const rows = [];

            content.forEach((element) => {
                rows.push(generateGridRowFromInvestorData(element, isAdmin));
            });

            let count = data.searchTerm?.length;
            if (count > 0) {
                dispatch(setFilteredNumber(totalElements));
            } else {
                dispatch(setFilteredNumber(null));
            }

            // Save last query
            dispatch({ type: InvestorSearchActions.SET_INVESTOR_SEARCHBOX_LAST_QUERY, data: data });

            // Reset advanced filters
            dispatch({ type: InvestorSearchActions.RESET_INVESTOR_ADVANCED_FILTER });
            dispatch({ type: InvestorSearchActions.RESET_INVESTOR_APPLIED_FILTERS });

            dispatch({ type: InvestorSearchActions.SET_INVESTOR_GRID_DATA, data: rows });
            dispatch({
                type: InvestorSearchActions.SET_INVESTOR_GRID_PAGINATION,
                data: { count: totalPages },
            });
            dispatch({ type: InvestorSearchActions.SET_INVESTOR_LAST_QUERY_TYPE, data: 'search' });
        })
        .catch(() => {
            dispatch({ type: InvestorSearchActions.SET_INVESTOR_GRID_LOAD_ERROR });
            errorCallback();
        });
};

export const setSelectedCategory = (index) => ({
    type: InvestorSearchActions.SET_INVESTOR_CATEGORY,
    data: index,
});

export const closeAdvancedFilter = () => {
    return (dispatch, getState) => {
        const state = getState().investorSearch;
        let isAppliedFiltersExist = false;

        for (const value of Object.values(state.appliedFilters)) {
            if (value && value.length !== 0) {
                isAppliedFiltersExist = true;
                break;
            }
        }

        if (isAppliedFiltersExist) {
            dispatch(setSelectedCategory(0));
            dispatch(toggleAdvancedFilter());
        } else {
            dispatch(setSelectedCategory(0));
            dispatch(toggleAdvancedFilter());
            dispatch(uncheckAllCheckboxes());
        }
    };
};

export const toggleCheckbox = (data) => ({
    type: InvestorSearchActions.TOGGLE_INVESTOR_ADVANCEDFILTER_CHECKBOX,
    data,
});

export const uncheckAllCheckboxes = () => ({
    type: InvestorSearchActions.RESET_INVESTOR_ADVANCEDFILTER_CHECKBOX,
});

// SearchBox
export const searchBoxOnChange = (data) => ({
    type: InvestorSearchActions.SET_INVESTOR_SEARCHBOX_INPUT,
    data,
});

export const setSearchBoxFilterStatus = (data) => ({
    type: InvestorSearchActions.SET_INVESTOR_SEARCHBOX_FILTERSTATUS,
    data,
});

// Applied Filters
export const setAppliedFilters = (keycloak, data, errorCallback) => {
    return (dispatch, getState) => {
        const state = getState();
        const { data: advanvedFilterData } = state.investorSearch.advancedFilter;

        const appliedFilters = generateAppliedFiltersTemplate();
        for (const [category, values] of Object.entries(advanvedFilterData)) {
            for (const value of values) {
                if (value.selected === true) {
                    appliedFilters[category].push(Object.assign({}, value));
                }
            }
        }

        dispatch({ type: InvestorSearchActions.RESET_INVESTOR_SEARCHBOX });

        if (!data) {
            dispatch({ type: InvestorSearchActions.SET_INVESTOR_APPLIED_FILTERS, data: appliedFilters });
            dispatch(toggleAdvancedFilter());
        } else {
            dispatch({ type: InvestorSearchActions.SET_INVESTOR_APPLIED_FILTERS, data });
        }
        dispatch(fetchGridData({ keycloak, errorCallback }));
    };
};

export const removeAppliedFilter = (categoryPrefix, id = null) => ({
    type: InvestorSearchActions.REMOVE_INVESTOR_APPLIED_FILTERS,
    data: {
        categoryPrefix: categoryPrefix,
        id: id,
    },
});

export const removeAppliedFiltersBySubcategory = (categoryPrefix, subCategory) => ({
    type: InvestorSearchActions.REMOVE_INVESTOR_APPLIED_FILTERS_BY_SUBCATEGORY,
    data: {
        categoryPrefix,
        subCategory,
    },
});

// Admin related actions
export const resetInvestorRepresentativeForm = () => ({
    type: InvestorSearchActions.RESET_INVESTOR_REPRESENTATIVE_FORM,
});

const setTeamData = (teamData) => ({
    type: InvestorSearchActions.SET_INVESTOR_GRID_TEAM_DATA,
    teamData: teamData,
});

export const fetchInvestorUsersComboData = (keycloak, uuid) => {
    return (dispatch) => {
        dispatch({ type: InvestorSearchActions.GET_INVESTOR_GRID_TEAM_DATA });
        getRequest(APIS.INVESTOR.getUsersComboURL(uuid), keycloak)
            .then((data) => dispatch(setTeamData(data)))
            .catch(() => {
                dispatch({ type: InvestorSearchActions.SET_INVESTOR_GRID_TEAM_DATA_LOAD_ERROR });
            });
    };
};

// Utils
const generateGridRowFromInvestorData = (data, isAdmin = false) => {
    const {
        uuid,
        logoFile,
        name,
        type: investorType,
        startupStagePreferenceSet: stages,
        dateFounded,
        investmentAreas,
        investorFintechSubVerticalInterest: subverticals,
        status,
    } = data;
    const defaultText = '-';

    const defaultColumns = [
        // Row id
        {
            id: uuid,
        },
        // Yatırımcı
        {
            type: 'investor',
            value: {
                label: name,
                logoFile,
            },
        },
        // Yatırımcı tipi
        {
            type: 'textmargin',
            value: {
                label: investorType,
            },
            /* src\components\table\StickyTable\index.style.js
             * investorTypeColumnTextWrapper style should also be MODIFIED!
             */
            style: {
                minWidth: '233px',
                maxWidth: '270px',
            },
        },
        // Girişim aşaması tercihi
        {
            type: 'tagGroup',
            value: stages.map((stage) => ({
                id: stage.id,
                name: stage.name,
            })),
            style: {
                minWidth: '241px',
            },
        },
        // Kuruluş Yılı
        {
            type: 'text',
            value: {
                label: dateFounded ? dateFounded : defaultText,
            },
            style: {
                minWidth: '239px',
            },
        },
        // Önceki yatırım alanları
        {
            type: 'tagGroup',
            value: investmentAreas
                ?.sort((a, b) => {
                    return a.id < b.id ? -1 : a.id === b.id ? 0 : 1;
                })
                .map((area) => ({
                    id: area.id,
                    name: area.name,
                })),
            title: 'investorSearch.expandModal.investmentAreas',
            style: {
                minWidth: '243px',
            },
        },
        // İlgilendiği fintek dikeyleri
        {
            type: 'verticalGroup',
            value: parseSubVerticals(subverticals, true),
            title: 'investorSearch.expandModal.fintechSubVerticalInterests',
            style: {
                minWidth: '243px',
            },
        },
    ];

    if (isAdmin) {
        defaultColumns.splice(
            1,
            0,
            {
                type: 'actions',
                value: [
                    // Representative change
                    {
                        id: uuid,
                        name: name,
                        disabled: isNil(status) ? true : !(status === ACTIVE),
                    },

                    // Status change
                    {
                        id: uuid,
                        name: name,
                        disabled: isNil(status) ? true : !(status === ACTIVE),
                    },
                ],
            },
            {
                type: 'status',
                value: isNil(status) ? null : status,
            }
        );
    }
    return defaultColumns;
};

const generateAppliedFiltersTemplate = () => {
    return {
        investorTypes: [],
        startupStages: [],
        investmentAreas: [],
        fintechSubVerticalInterests: [],
    };
};

const appendSortQueryToUrl = (url, headers) => {
    const filteredHeaders = headers.filter(
        (header) => header.type === 'sortLabel' && header.value.status !== GRID_HEADER_STATE.NONE
    );

    if (filteredHeaders.length === 0) {
        return url;
    }

    let query = '';
    filteredHeaders.forEach((e) => {
        query += '&sort=';
        query += `${e.value.searchIndex},${e.value.status}`;
    });

    return `${url}${query}`;
};
