import { STARTUP_EQUITY } from 'fintech/components/util/ApplicationFormConstants';
import { INVITE_URL, WEBSITE } from 'fintech/components/util/TrafficStatusConstants';
import { StartupApplicationActions } from 'fintech/store/actions/ActionTypes';

const initialState = {
    type: STARTUP_EQUITY,

    // For invite URL scenarios
    isLoading: true,

    tradeName: '',
    companyIdentity: '',
    companyEmail: '',
    startupName: {
        value: '',
        validated: false,
    },
    cover: {
        value: '',
        validated: false,
    },
    website: {
        value: '',
        validated: true,
    },
    name: {
        value: '',
        validated: false,
    },
    surname: {
        value: '',
        validated: false,
    },
    country: {
        value: '',
        validated: false,
    },
    idNumber: {
        value: '',
        validated: false,
    },
    gender: {
        value: null,
        validated: false,
    },
    birthDate: {
        value: '',
        validated: false,
    },
    email: {
        value: '',
        validated: false,
    },
    phoneNumber: {
        value: {
            country: 'TR',
            countryCallingCode: '90',
            nationalNumber: '',
            number: '',
        },
        validated: false,
    },
    experience: {
        value: '',
        validated: false,
    },
    jobTitle: {
        value: '',
        validated: false,
    },
    education: {
        value: null,
        validated: false,
    },
    readAgreement: false,
    trafficSource: WEBSITE,

    // Select options
    options: {
        countries: {
            data: null,
            isLoading: true,
            isError: false,
        },
        genders: {
            data: null,
            isLoading: true,
            isError: false,
        },
        educationLevels: {
            data: null,
            isLoading: true,
            isError: false,
        },
    },
};

const startupApplication = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case StartupApplicationActions.SET_STARTUP_APPLICATION_STARTUP_TYPE:
            return {
                ...state,
                type: action.data,
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_INVITE_LOADING:
            return {
                ...state,
                isLoading: action.data,
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_TRADE_NAME:
            return {
                ...state,
                tradeName: action.data,
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_EQUITY_IDENTITY:
            return {
                ...state,
                companyIdentity: action.data,
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_EQUITY_EMAIL:
            return {
                ...state,
                companyEmail: action.data,
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_STARTUP_NAME:
            return {
                ...state,
                startupName: action.data,
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_COVER_LETTER:
            return {
                ...state,
                cover: action.data,
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_STARTUP_WEBSITE:
            return {
                ...state,
                website: action.data,
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_NAME:
            return {
                ...state,
                name: action.data,
            };

        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_SURNAME:
            return {
                ...state,
                surname: action.data,
            };

        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_COUNTRY:
            return {
                ...state,
                country: action.data,
            };

        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_IDENTITY:
            return {
                ...state,
                idNumber: action.data,
            };

        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_GENDER:
            return {
                ...state,
                gender: action.data,
            };

        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_BIRTHDATE:
            return {
                ...state,
                birthDate: action.data,
            };

        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_EMAIL:
            return {
                ...state,
                email: action.data,
            };

        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: {
                    ...state.phoneNumber,
                    ...action.data,
                },
            };

        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_EXPERIENCE:
            return {
                ...state,
                experience: action.data,
            };

        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_JOB_TITLE:
            return {
                ...state,
                jobTitle: action.data,
            };

        case StartupApplicationActions.SET_STARTUP_APPLICATION_REP_EDUCATION:
            return {
                ...state,
                education: action.data,
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_AGREEMENTS:
            return {
                ...state,
                readAgreement: !state.readAgreement,
            };

        // From invite
        case StartupApplicationActions.SET_STARTUP_APPLICATION_INVITE_INDIVIDUAL:
            return {
                ...state,
                type: action.data.type,
                tradeName: action.data.companyName,
                companyIdentity: action.data.companyIdentityNumber,
                name: { value: action.data.name, validated: action.data.name ? true : false },
                surname: { value: action.data.surname, validated: action.data.surname ? true : false },
                email: { value: action.data.email, validated: action.data.email ? true : false },
                trafficSource: INVITE_URL,
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_INVITE_EQUITY: {
            return {
                ...state,
                type: action.data.type,
                tradeName: action.data.companyName,
                companyIdentity: action.data.companyIdentityNumber,
                name: { value: action.data.name, validated: action.data.name ? true : false },
                surname: { value: action.data.surname, validated: action.data.surname ? true : false },
                email: { value: action.data.email, validated: action.data.email ? true : false },
                trafficSource: INVITE_URL,
            };
        }

        // Countries
        case StartupApplicationActions.SET_STARTUP_APPLICATION_COUNTRIES:
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        ...state.options.countries,
                        data: action.countries.data,
                        isLoading: false,
                    },
                },
            };
        case StartupApplicationActions.GET_STARTUP_APPLICATION_COUNTRIES:
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_COUNTRIES_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    countries: {
                        ...state.options.countries,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Genders
        case StartupApplicationActions.SET_STARTUP_APPLICATION_GENDERS:
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        ...state.options.genders,
                        data: action.genders.data,
                        isLoading: false,
                    },
                },
            };
        case StartupApplicationActions.GET_STARTUP_APPLICATION_GENDERS:
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_GENDERS_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    genders: {
                        ...state.options.genders,
                        isLoading: false,
                        isError: true,
                    },
                },
            };

        // Education levels
        case StartupApplicationActions.SET_STARTUP_APPLICATION_EDUCATION_LEVELS:
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        ...state.options.educationLevels,
                        data: action.levels.data,
                        isLoading: false,
                    },
                },
            };
        case StartupApplicationActions.GET_STARTUP_APPLICATION_EDUCATION_LEVELS:
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        data: null,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case StartupApplicationActions.SET_STARTUP_APPLICATION_EDUCATION_LEVELS_LOAD_ERROR:
            return {
                ...state,
                options: {
                    ...state.options,
                    educationLevels: {
                        ...state.options.levels,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case StartupApplicationActions.RESET_STARTUP_APPLICATION_FORM:
            return { ...initialState, isLoading: false };

        case StartupApplicationActions.RESET_STARTUP_APPLICATION_GENERAL_INFO:
            return {
                ...state,
                tradeName: initialState.tradeName,
                companyIdentity: initialState.companyIdentity,
                companyEmail: initialState.companyEmail,
                idNumber: initialState.idNumber,
                email: initialState.email,
            };
        default:
            return state;
    }
};

export default startupApplication;
