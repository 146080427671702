import { EntrepreneurActions } from 'fintech/store/actions/ActionTypes';
import { APIS } from 'fintech/constants';
import { getRequest, putRequest, postRequest } from 'fintech/api/api';
import { formatMonetaryValue } from 'fintech/utils/StringUtils';

import { hasRoles } from 'fintech/utils/Auth';
import { ROLE_ADMIN } from 'fintech/components/util/RoleConstants';

import { isNil, isEmpty } from 'lodash';
import { LAST_FETCH_STATUS } from 'fintech/pages/post-login/EntrepreneursPage';

import { parseSubVerticals, sanitizeFalsyValuesFromObject } from 'fintech/utils/DataParser';
import { ACTIVE } from 'fintech/components/util/StatusConstants';

// Global
export const persistState = (persist) => ({
    type: EntrepreneurActions.SET_ENTREPRENEUR_GLOBAL_PERSIST_STATE,
    data: persist,
});

export const resetToInitialGrid = () => {
    return (dispatch) => {
        dispatch({ type: EntrepreneurActions.RESET_ENTREPRENEUR_ADVANCEDFILTER });
        dispatch({ type: EntrepreneurActions.RESET_ENTREPRENEUR_APPLIED_FILTERS });
        dispatch({ type: EntrepreneurActions.RESET_ENTREPRENEUR_SEARCHBOX });
        dispatch(uncheckAllCheckboxes());
        dispatch({ type: EntrepreneurActions.RESET_ENTREPRENEUR_GRID_PAGINATION });
    };
};

export const setTotalNumberOfStartups = (data) => ({
    type: EntrepreneurActions.SET_ENTREPRENEUR_GLOBAL_TOTALNUMBEROFSTARTUPS,
    data,
});

export const setTotalNumberOfResults = (data) => ({
    type: EntrepreneurActions.SET_ENTREPRENEUR_GLOBAL_TOTALNUMBEROFRESULTS,
    data,
});

export const getStartupActiveCount = (keycloak) => {
    return (dispatch) => {
        getRequest(APIS.STARTUP.getStartupActiveCountURL(), keycloak).then((response) => {
            dispatch(setTotalNumberOfStartups(response.data));
        });
    };
};

export const decreaseStartupActiveCount = () => {
    return (dispatch, getState) => {
        const totalNumberOfStartups = getState().entrepreneur.global.totalNumberOfStartups;
        if (isNil(totalNumberOfStartups)) return;
        dispatch({
            type: EntrepreneurActions.SET_ENTREPRENEUR_GLOBAL_TOTALNUMBEROFSTARTUPS,
            data: totalNumberOfStartups - 1,
        });
    };
};

export const fetchMentorList = (keycloak, uuid, errorCallback) => {
    return (dispatch) => {
        getRequest(APIS.STARTUP.getMentorListURL(uuid), keycloak)
            .then((resp) => {
                dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_MENTOR_LIST, data: resp.data.mentors });
            })
            .catch(() => {
                errorCallback();
            });
    };
};

export const postMentorList = (keycloak, uuid, data, successCallback, errorCallback) => {
    postRequest(APIS.STARTUP.getMentorListURL(uuid), keycloak, data)
        .then(() => {
            successCallback();
        })
        .catch(() => {
            errorCallback();
        });
};

export const fetchRepresentativeList = (keycloak, uuid, errorCallback) => {
    return (dispatch) => {
        getRequest(APIS.STARTUP.getTeamURL(uuid), keycloak)
            .then((resp) => {
                dispatch({
                    type: EntrepreneurActions.SET_ENTREPRENEUR_REPRESENTIVE_LIST,
                    data: [...resp.data.representatives, ...resp.data.members],
                });
            })
            .catch(() => {
                errorCallback();
            });
    };
};

export const putRepresentative = (keycloak, uuid, representativeUUID, successCallback, errorCallback) => {
    return () => {
        putRequest(APIS.STARTUP.getRepresantivePutURL(uuid, representativeUUID), keycloak)
            .then(() => {
                successCallback();
            })
            .catch(() => {
                errorCallback();
            });
    };
};

export const toggleAdvancedFilter = () => ({ type: EntrepreneurActions.TOGGLE_ENTREPRENEUR_ADVANCEDFILTER });

export const fetchAdvancedFilterData = (keycloak, errorCallback) => {
    return (dispatch) => {
        getRequest(APIS.STARTUP.getFilterURL(), keycloak)
            .then((resp) => {
                dispatch({ type: EntrepreneurActions.SET_LAST_FETCH_STATUS, data: LAST_FETCH_STATUS.FILTER });
                dispatch({
                    type: EntrepreneurActions.SET_ENTREPRENEUR_ADVANCEDFILTER_DATA,
                    data: sanitizeFalsyValuesFromObject(resp.data),
                });
            })
            .catch(() => {
                errorCallback();
            });
    };
};

export const fetchGridData = (
    keycloak,
    errorCallback,
    resetPaginationPageNumber = false,
    reloadGrid = true,
    timeout = 700
) => {
    return (dispatch, getState) => {
        const { appliedFilters, grid } = getState().entrepreneur;
        const { pagination, headers, canFavorite } = grid;

        const sortQuery = generateSortQueryFromHeaders(
            isNil(headers) ? getInitialGridHeaderState(hasRoles(keycloak, ROLE_ADMIN), canFavorite) : headers
        );

        if (reloadGrid) {
            dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOADING_INITIAL });
        } else {
            dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOADING });
        }

        setTimeout(() => {
            postRequest(
                `${APIS.STARTUP.getFilterURL()}?page=${
                    resetPaginationPageNumber ? 0 : pagination.page - 1
                }&size=15${sortQuery}`,
                keycloak,
                appliedFilters
            )
                .then((resp) => {
                    const { content, totalElements, totalPages } = resp.data.result;
                    const rows = [];
                    const initialHeaders = getInitialGridHeaderState(
                        hasRoles(keycloak, ROLE_ADMIN),
                        resp.data.canFavorite
                    );

                    content.forEach((data) => {
                        rows.push(
                            generateGridRowFromStartUpData(data, resp.data.canFavorite, hasRoles(keycloak, ROLE_ADMIN))
                        );
                    });

                    dispatch({ type: EntrepreneurActions.SET_LAST_FETCH_STATUS, data: LAST_FETCH_STATUS.FILTER });
                    dispatch({
                        type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_DATA,
                        data: {
                            canFavorite: resp.data.canFavorite,
                            rows,
                            headers: isNil(headers) ? initialHeaders : headers,
                        },
                    });

                    dispatch(setTotalNumberOfResults(totalElements));

                    if (resetPaginationPageNumber) {
                        dispatch({
                            type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_PAGINATION,
                            data: { ...pagination, count: totalPages, page: 1 },
                        });
                    } else {
                        dispatch({
                            type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_PAGINATION,
                            data: { ...pagination, count: totalPages },
                        });
                    }
                })
                .catch(() => {
                    dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOAD_ERROR });
                    errorCallback();
                });
        }, timeout);
    };
};

export const setGridRowFavourite = (data) => ({ type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_ROW_FAVOURITE, data });

export const makeSearch = (
    keycloak,
    data,
    errorCallback,
    resetPaginationPageNumber = false,
    reloadGrid = true,
    timeout = 700
) => {
    return (dispatch, getState) => {
        const { pagination, headers, canFavorite } = getState().entrepreneur.grid;
        const sortQuery = generateSortQueryFromHeaders(
            isNil(headers) ? getInitialGridHeaderState(hasRoles(keycloak, ROLE_ADMIN), canFavorite) : headers
        );

        if (reloadGrid) {
            dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOADING_INITIAL });
        } else {
            dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOADING });
        }

        setTimeout(() => {
            postRequest(
                `${APIS.STARTUP.getSearchURL()}?page=${
                    resetPaginationPageNumber ? 0 : pagination.page - 1
                }&size=15${sortQuery}`,
                keycloak,
                data
            )
                .then((resp) => {
                    const content = resp.data.result.content;
                    const rows = [];
                    const initialHeaders = getInitialGridHeaderState(
                        hasRoles(keycloak, ROLE_ADMIN),
                        resp.data.canFavorite
                    );

                    content.forEach((element) => {
                        rows.push(
                            generateGridRowFromStartUpData(
                                element,
                                resp.data.canFavorite,
                                hasRoles(keycloak, ROLE_ADMIN)
                            )
                        );
                    });

                    // Save last query
                    dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_SEARCHBOX_LAST_QUERY, data: data });

                    // Reset Actions
                    dispatch({ type: EntrepreneurActions.RESET_ENTREPRENEUR_ADVANCEDFILTER });
                    dispatch({ type: EntrepreneurActions.RESET_ENTREPRENEUR_APPLIED_FILTERS });

                    if (resetPaginationPageNumber) {
                        dispatch({
                            type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_PAGINATION,
                            data: { ...pagination, count: resp.data.result.totalPages, page: 1 },
                        });
                    } else {
                        dispatch({
                            type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_PAGINATION,
                            data: { ...pagination, count: resp.data.result.totalPages },
                        });
                    }

                    dispatch(setTotalNumberOfResults(resp.data.result.totalElements));
                    dispatch(uncheckAllCheckboxes());

                    dispatch({
                        type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_DATA,
                        data: {
                            canFavorite: resp.data.canFavorite,
                            rows,
                            headers: isNil(headers) ? initialHeaders : headers,
                        },
                    });
                    dispatch({ type: EntrepreneurActions.SET_LAST_FETCH_STATUS, data: LAST_FETCH_STATUS.SEARCH });
                })
                .catch(() => {
                    dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOAD_ERROR });
                    errorCallback();
                });
        }, timeout);
    };
};

export const setSelectedVertical = (index) => ({
    type: EntrepreneurActions.SET_ENTREPRENEUR_VERTICAL,
    data: index,
});

export const closeAdvancedFilter = () => {
    return (dispatch, getState) => {
        const state = getState().entrepreneur;
        let isAppliedFiltersExist = false;

        for (const value of Object.values(state.appliedFilters)) {
            if (value && value.length !== 0) {
                isAppliedFiltersExist = true;
                break;
            }
        }

        if (isAppliedFiltersExist) {
            dispatch(setSelectedVertical(0));
            dispatch(toggleAdvancedFilter());
        } else {
            dispatch(setSelectedVertical(0));
            dispatch(toggleAdvancedFilter());
            dispatch(uncheckAllCheckboxes());
        }
    };
};

export const toggleCheckbox = (data) => ({
    type: EntrepreneurActions.TOGGLE_ENTREPRENEUR_ADVANCEDFILTER_CHECKBOX,
    data,
});

export const uncheckAllCheckboxes = () => ({
    type: EntrepreneurActions.RESET_ENTREPRENEUR_ADVANCEDFILTER_CHECKBOX,
});

// SearchBox
export const searchBoxOnChange = (data) => ({
    type: EntrepreneurActions.SET_ENTREPRENEUR_SEARCHBOX_INPUT,
    data,
});

export const setSearchBoxFilterStatus = (data) => ({
    type: EntrepreneurActions.SET_ENTREPRENEUR_SEARCHBOX_FILTERSTATUS,
    data,
});

// Pagination
export const setDefaultPageOfPagination = (data) => {
    return (dispatch, getState) => {
        const { pagination } = getState().entrepreneur.grid;
        dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_PAGINATION, data: { ...pagination, page: data } });
    };
};

// Applied Filters
export const setAppliedFilters = (keycloak, data, errorCallback) => {
    return (dispatch, getState) => {
        const state = getState();
        const { data: advanvedFilterData } = state.entrepreneur.advancedFilter;

        const appliedFilters = generateAppliedFiltersTemplate();
        for (const [finTechVertical, values] of Object.entries(advanvedFilterData)) {
            for (const value of values) {
                if (value.selected === true) {
                    appliedFilters[finTechVertical].push(Object.assign({}, value));
                }
            }
        }

        dispatch({ type: EntrepreneurActions.RESET_ENTREPRENEUR_SEARCHBOX });

        if (isNil(data)) {
            dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_APPLIED_FILTERS, data: appliedFilters });
            dispatch(fetchGridData(keycloak, errorCallback, true));
            dispatch(toggleAdvancedFilter());
        } else {
            const _appliedFilters = isEmpty(data) ? getEmptyAppliedFilters() : data;

            dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_APPLIED_FILTERS, data: _appliedFilters });
            dispatch(fetchGridData(keycloak, errorCallback, true));
        }
    };
};

export const removeAppliedFilter = (verticalPrefix, id = null) => ({
    type: EntrepreneurActions.REMOVE_ENTREPRENEUR_APPLIED_FILTERS,
    data: {
        verticalPrefix: verticalPrefix,
        id: id,
    },
});

export const removeAppliedFiltersBySubcategory = (verticalPrefix, subCategory) => ({
    type: EntrepreneurActions.REMOVE_ENTREPRENEUR_APPLIED_FILTERS_BY_SUBCATEGORY,
    data: {
        verticalPrefix,
        subCategory,
    },
});

// Favorite
export const fetchFavoriteStartup = (
    keycloak,
    errorCallback,
    resetPaginationPageNumber = false,
    reloadGrid = true,
    timeout = 700
) => {
    return (dispatch, getState) => {
        const { grid, global } = getState().entrepreneur;
        const { selectedTab } = global;
        const { pagination, headers, canFavorite } = grid;
        const sortQuery = generateSortQueryFromHeaders(
            isNil(headers) ? getInitialGridHeaderState(hasRoles(keycloak, ROLE_ADMIN), canFavorite) : headers
        );

        if (reloadGrid) {
            dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOADING_INITIAL });
        } else {
            dispatch({ type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_LOADING });
        }

        setTimeout(() => {
            getRequest(
                `${APIS.USER.getFavorite()}?page=${
                    resetPaginationPageNumber ? 0 : pagination.page - 1
                }&size=15${sortQuery}`,
                keycloak
            )
                .then((resp) => {
                    const { content, totalPages } = resp.data;
                    const rows = [];
                    const initialHeaders = getInitialGridHeaderState(hasRoles(keycloak, ROLE_ADMIN), true);

                    content.forEach((data) => {
                        rows.push(generateGridRowFromStartUpData(data, selectedTab, hasRoles(keycloak, ROLE_ADMIN)));
                    });

                    dispatch({
                        type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_DATA,
                        data: {
                            canFavorite: resp.data.canFavorite,
                            rows,
                            headers: isNil(headers) ? initialHeaders : headers,
                        },
                    });

                    if (resetPaginationPageNumber) {
                        dispatch({
                            type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_PAGINATION,
                            data: { ...pagination, count: totalPages, page: 1 },
                        });
                    } else {
                        dispatch({
                            type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_PAGINATION,
                            data: { ...pagination, count: totalPages },
                        });
                    }
                })
                .catch(() => errorCallback());
        }, timeout);
    };
};

export const favoriteStartup = (keycloak, startupUuid, successCallback, errorCallback, index, subIndex) => {
    return (dispatch) => {
        putRequest(APIS.USER.getFavoriteStartup(startupUuid), keycloak)
            .then(() => {
                dispatch(setGridRowFavourite({ index, subIndex, status: true }));
                successCallback();
            })
            .catch(() => errorCallback());
    };
};

export const unFavoriteStartup = (keycloak, startupUuid, successCallback, errorCallback, index, subIndex) => {
    return (dispatch) => {
        putRequest(APIS.USER.getUnfavoriteStartup(startupUuid), keycloak)
            .then(() => {
                dispatch(setGridRowFavourite({ index, subIndex, status: false }));
                successCallback();
            })
            .catch(() => {
                errorCallback();
            });
    };
};

// Admin Operations
export const deactiveStartup = (startupUuid, keycloak, successCallback, errorCallback) => {
    return () => {
        putRequest(APIS.STARTUP.getDeactiveURL(startupUuid), keycloak)
            .then(() => {
                successCallback(keycloak);
            })
            .catch(() => {
                errorCallback();
            });
    };
};
export const setStartupStatus = (status, uuid) => ({
    type: EntrepreneurActions.SET_ENTREPRENEUR_GRID_STARTUP_STATUS,
    data: {
        status,
        uuid,
    },
});

// Utils
const generateGridRowFromStartUpData = (data, isFavourite, isAdmin = false) => {
    const {
        name,
        address,
        stage,
        dateFounded,
        totalInvestment,
        userFavorite,
        technologies,
        subverticals,
        logoFile,
        uuid,
        startupFinancial,
        startupInfos,
        ventureType,
        status,
    } = data;
    const defaultText = '-';

    const row = [
        {
            startupUuid: uuid,
            ventureTypeID: ventureType.id,
        },
        // # Girişim
        {
            type: isFavourite ? 'ventureFavorite' : 'venture',
            value: {
                label: name,
                isFavourite: userFavorite,
                logoFile,
                ventureType: ventureType?.description,
            },
        },
        // Lokasyon
        {
            type: 'text',
            value: {
                label: address[0] ? address[0]['city']['name'] : defaultText,
            },
        },
        // Fintek Dikeyleri
        {
            type: 'verticalGroup',
            value: parseSubVerticals(subverticals),
            title: 'entrepreneur.expandModal.fintechVerticals',
        },
        // Aşama
        {
            type: 'tagGroup',
            value:
                stage && stage.name
                    ? [
                          {
                              id: 0,
                              name: stage.name,
                          },
                      ]
                    : null,
        },
        // Kuruluş Yılı
        {
            type: 'text',
            value: {
                label: dateFounded,
            },
        },
        // Çalışan Sayısı
        {
            type: 'text-centered',
            value: {
                label:
                    startupInfos.find((e) => e.field === 'employees') &&
                    startupInfos.find((e) => e.field === 'employees')?.value !== ''
                        ? startupInfos.find((e) => e.field === 'employees').value
                        : defaultText,
            },
        },

        // Yatırım Tutarı
        {
            type: 'text',
            value: {
                label:
                    totalInvestment &&
                    totalInvestment.value &&
                    startupInfos.find((e) => e.field === 'investment').public
                        ? formatMonetaryValue(totalInvestment.value, totalInvestment.currencyType)
                        : defaultText,
            },
        },
        // Piyasa Değeri
        {
            type: 'text',
            value: {
                label:
                    startupFinancial && startupFinancial.marketValue
                        ? formatMonetaryValue(
                              startupFinancial.marketValue.value,
                              isNil(startupFinancial.marketValue.currencyType)
                                  ? 'TRY'
                                  : startupFinancial.marketValue.currencyType
                          )
                        : defaultText,
            },
        },

        // Gelir modeli
        {
            type: 'tagGroup',
            value: startupFinancial && startupFinancial.incomeModels ? startupFinancial.incomeModels : [],
            title: 'entrepreneur.expandModal.incomeModels',
        },

        // Teknoloji
        {
            type: 'tagGroup',
            value: technologies,
            title: 'entrepreneur.expandModal.technologies',
        },
    ];

    if (isFavourite) {
        row.splice(1, 0, {
            type: 'favorite',
            value: {
                label: name,
                isFavourite: userFavorite,
                startupUuid: uuid,
                ventureTypeID: ventureType.id,
                logoFile,
            },
        });
    } else if (isAdmin) {
        const statusOptions = [];

        if (status === ACTIVE) {
            statusOptions.push(
                {
                    id: 'uuid',
                    name: 'setPassive',
                    title: 'adminActions.changeStatus',
                },
                {
                    id: 'uuid',
                    name: 'assignMentor',
                    title: 'adminActions.assignMentor',
                },
                {
                    id: 'uuid',
                    name: 'changeRepresentative',
                    title: 'adminActions.changeRepresentative',
                }
            );
        }

        row.splice(
            1,
            0,
            {
                type: 'adminPanel',
                value: {
                    uuid,
                    name,
                    statusOptions,
                },
            },
            {
                type: 'status',
                value: status,
            }
        );
    }

    return row;
};

export const getGridRowsBySettingStartupStatus = (rows, outherIndex, innerIndex, status) => {
    const _rows = rows;
    _rows[outherIndex][innerIndex].value.status = status;
    return _rows;
};

const generateAppliedFiltersTemplate = () => {
    return {
        fintechVerticals: [],
        startupStages: [],
        incomeModels: [],
        technologies: [],
        cities: [],
    };
};

const generateSortQueryFromHeaders = (headers) => {
    if (isNil(headers)) return '';
    let query = '';
    const selectedHeader = headers.find((e) => e.value.status !== GRID_HEADER_STATE.NONE);

    if (!isNil(selectedHeader)) {
        query += `${selectedHeader.value.searchIndex},${selectedHeader.value.status}`;
    } else {
        query += 'name,ASC';
    }

    return query === '' ? '' : `&sort=${query}`;
};

export const generateSearchDataForFiltering = (filters, input) => {
    return {
        startupNameSelected: filters.find(({ label }) => label === 'startupName').status,
        locationSelected: filters.find(({ label }) => label === 'location').status,
        fintechVerticalSelected: filters.find(({ label }) => label === 'fintechVerticals').status,
        stageSelected: filters.find(({ label }) => label === 'startupStages').status,
        technologySelected: filters.find(({ label }) => label === 'technologies').status,
        searchTerm: input,
    };
};

export const isAllSearchDataFiltersDisabled = (filters) => {
    return filters.find((element) => element.status === true) ? false : true;
};

export const getVerticalKeyFromIDPrefix = (prefix) => {
    switch (prefix) {
        case 'ct':
            return 'cities';
        case 'fv':
            return 'fintechVerticals';
        case 'im':
            return 'incomeModels';
        case 'ss':
            return 'startupStages';
        case 't':
            return 'technologies';
        default:
            return '';
    }
};

// There is no uniqueness on ids so we have to map them by the category name.
export const generateIDPrefix = (key) => {
    switch (key) {
        case 'cities':
            return 'ct';
        case 'fintechVerticals':
            return 'fv';
        case 'incomeModels':
            return 'im';
        case 'startupStages':
            return 'ss';
        case 'technologies':
            return 't';
        default:
            return '';
    }
};

// Initials
export const getEmptyAppliedFilters = () => ({
    cities: [],
    fintechVerticals: [],
    incomeModels: [],
    startupStages: [],
    technologies: [],
});

export const getInitialAdvancedFiltersState = () => ({
    isDisplayed: false,
    selectedVertical: 0,
    verticals: [
        {
            id: 0,
            value: 'fintechVerticals',
        },
        {
            id: 1,
            value: 'startupStages',
        },
        {
            id: 2,
            value: 'incomeModels',
        },
        {
            id: 3,
            value: 'technologies',
        },
        {
            id: 4,
            value: 'cities',
        },
    ],
    data: null,
});

export const getInitialAppliedFiltersState = () => ({
    fintechVerticals: [],
    startupStages: [],
    incomeModels: [],
    technologies: [],
    cities: [],
});

export const getInitialSearchBoxState = () => ({
    input: '',
    filters: [
        { id: 0, label: 'startupName', status: true },
        { id: 1, label: 'location', status: true },
        { id: 2, label: 'fintechVerticals', status: true },
        { id: 3, label: 'startupStages', status: true },
        { id: 4, label: 'technologies', status: true },
    ],
    lastQueryParams: {},
});

export const GRID_HEADER_STATE = {
    NONE: 'NONE',
    ASC: 'ASC',
    DESC: 'DESC',
};

export const getInitialGridHeaderState = (isAdmin = false, canFavorite = false) => {
    const data = [
        {
            type: canFavorite ? 'sortLabel-shrinked' : 'sortLabel',
            value: {
                title: 'startup',
                status: GRID_HEADER_STATE.ASC,
                searchIndex: 'name',
            },
        },
        {
            type: 'sortLabel',
            value: {
                title: 'location',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'address.city.name',
            },
        },
        {
            type: 'label',
            value: {
                title: 'fintechVerticals',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: '',
            },
        },
        {
            type: 'label',
            value: {
                title: 'startupStages',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: '',
            },
        },
        {
            type: 'sortLabel',
            value: {
                title: 'dateFounded',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'dateFounded',
            },
        },
        {
            type: 'sortLabel',
            value: {
                title: 'numberOfEmployees',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'employees',
            },
        },
        {
            type: 'sortLabel',
            value: {
                title: 'totalInvestment',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'investment',
            },
        },
        {
            type: 'sortLabel',
            value: {
                title: 'marketValue',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: 'startupFinancial.marketValue.value',
            },
        },
        {
            type: 'label',
            value: {
                title: 'incomeModel',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: '',
            },
        },
        {
            type: 'label',
            value: {
                title: 'technologies',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: '',
            },
        },
    ];

    if (canFavorite) {
        data.unshift({
            type: 'label-favorite',
            value: {
                title: 'favorite',
                status: GRID_HEADER_STATE.NONE,
                searchIndex: '',
            },
        });
    }

    // Admin Columns
    else if (isAdmin) {
        data.unshift(
            {
                type: 'label-actions',
                value: {
                    title: 'actions',
                    status: GRID_HEADER_STATE.NONE,
                    searchIndex: '',
                },
            },
            {
                type: 'label-actiontext',
                value: {
                    title: 'status',
                    status: GRID_HEADER_STATE.NONE,
                    searchIndex: '',
                },
            }
        );
    }

    return data.map((element, index) => {
        return {
            ...element,
            id: index,
        };
    });
};

export const getInitialPaginationState = () => ({
    count: 1,
    page: 1,
    defaultPage: 1,
});
