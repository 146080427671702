import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { Grid } from '@material-ui/core';
import Empty from 'fintech/components/util/Empty';
import StartupInvestorsHeader from './Header';
import StartupInvestor from 'fintech/components/profile/startup/Financials/Investor';
import useStyles from './index.style';
import { useTranslation } from 'react-i18next';
import InvestorEditModal from 'fintech/components/profile/startup/form/financials/InvestorEditModal';
import { dateIsAfter, NUMERIC_FULL_DATE } from 'fintech/utils/DateUtils';
import {
    fetchInvestorOptions,
    fetchStartupInvestmentsData,
    resetStartupInvestorForm,
    sendStartupInvestorForm,
    setStartupInvestmentForm,
} from 'fintech/store/actions/startup';

import { updateStartupInvestmentInfo, deleteStartupInvestmentInfo } from 'fintech/api/fintechService';
import { STATES } from 'fintech/store/actions/ActionTypes';

const StartupInvestorsGrid = () => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const { data: fetchedData, isLoading, isError } = useSelector((state) => state.startup.financials.investments);
    const dispatch = useDispatch();

    const [data, setInvestmentData] = useState(fetchedData);
    const [openModal, setOpenModal] = useState(false);
    const [actions] = useState({
        fetchInvestmentsData: fetchStartupInvestmentsData,
        resetInvestmentForm: resetStartupInvestorForm,
        sendInvestmentForm: sendStartupInvestorForm,
        setInvestmentForm: setStartupInvestmentForm,
    });
    const [services] = useState({
        deleteInvestmentInfo: deleteStartupInvestmentInfo,
        updateInvestmentInfo: updateStartupInvestmentInfo,
    });

    const clickAddHandler = () => setOpenModal(true);
    const cancelModalHandler = () => setOpenModal(false);

    useEffect(() => {
        setInvestmentData(fetchedData);
    }, [fetchedData]);

    useEffect(() => {
        dispatch(fetchInvestorOptions(keycloak));
    }, []);

    const sortInvestments = (a, b) => {
        if (a.date !== b.date) {
            return dateIsAfter(a.date, b.date, NUMERIC_FULL_DATE) ? 1 : -1;
        } else {
            return a.id > b.id ? 1 : -1;
        }
    };

    let hasData = data?.investments?.length > 0;
    let investmentsJSX = null;
    let emptyProps = {};

    let { editable } = data;

    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.financials.investors.errorMessage');
    } else {
        investmentsJSX = (
            <Grid
                className={classes.StartupInvestorsGrid}
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
            >
                <StartupInvestorsHeader
                    editable={editable}
                    onClickAdd={clickAddHandler}
                    title={t('profile.financials.investors.label')}
                    description={t('profile.financials.investors.description')}
                    addNewText={t('profile.financials.investors.addNewInvestor')}
                />
                {hasData ? (
                    <Grid
                        item
                        className={classes.InvestorsCard}
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                    >
                        {data.investments
                            .sort((a, b) => sortInvestments(a, b))
                            .map((inv) => (
                                <StartupInvestor
                                    key={inv.id}
                                    id={inv.id}
                                    editable={editable}
                                    type={STATES.STARTUP}
                                    editDialogContent={t('profile.financials.form.deleteWarning', {
                                        investorName: inv.companyTitle,
                                    })}
                                    actions={actions}
                                    services={services}
                                    {...inv}
                                />
                            ))}
                    </Grid>
                ) : (
                    <Grid item>
                        <Empty message={t('profile.financials.investors.emptyMessage')} />
                    </Grid>
                )}
                {editable && (
                    <InvestorEditModal
                        type={STATES.STARTUP}
                        open={openModal}
                        okButtonLabel={t('profile.financials.form.addCompanyLabel')}
                        onCancel={() => cancelModalHandler()}
                        actions={actions}
                        services={services}
                    />
                )}
            </Grid>
        );
    }

    return investmentsJSX ? investmentsJSX : <Empty {...emptyProps} />;
};

export default StartupInvestorsGrid;
