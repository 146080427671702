import { makeStyles } from '@material-ui/core';
import { COLOR_SECONDARY_SHADE_01 } from 'fintech/components/style/common';

const useStyles = makeStyles({
    MentorCarouselHeader: {
        fontWeight: '500',
        fontSize: 14,
        color: COLOR_SECONDARY_SHADE_01,
        marginTop: 20,
        marginLeft: 20,
        marginBottom: 32,
    },
});

export default useStyles;
