import { makeStyles } from '@material-ui/core';
import { buttonStyle, buttonStyleColorAlt, fontStyle, COLOR_PRIMARY_SHADE_02 } from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        borderBottom: `1px solid ${COLOR_PRIMARY_SHADE_02}`,
        '& h2': {
            fontWeight: '600',
            fontSize: '24px',
        },
    },
    dialogActions: { borderTop: `1px solid ${COLOR_PRIMARY_SHADE_02}`, padding: '16px 24px', gap: '8px' },
    manageCookieButton: {
        ...fontStyle,
        ...buttonStyle,
        height: 48,
        padding: '8px 24px 8px 24px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        '@media (max-width:320px)': {
            fontSize: 11,
        },
    },
    approvalRejectionButton: {
        ...fontStyle,
        ...buttonStyleColorAlt,
        height: 48,
        padding: '8px 24px 8px 24px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        '@media (max-width:320px)': {
            fontSize: 11,
        },
    },
}));

export default useStyles;
