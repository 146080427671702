import * as React from 'react';

function SvgIconsSingleDocument(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#3F5162" fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M18.75 21c.532 0 .964-.432.964-.964V8.464c0-.532-.311-1.275-.683-1.647l-3.134-3.134C15.526 3.311 14.782 3 14.25 3h-9c-.532 0-.964.432-.964.964v16.072c0 .532.432.964.964.964h13.5zm-.321-1.286H5.57V4.286h7.715v4.178c0 .533.432.965.964.965h4.179v10.285zm-.08-11.571H14.57V4.366c.171.06.342.15.412.221l3.144 3.144c.07.07.161.241.221.412zm-7.775"
                                    transform="translate(-332 -1179) translate(280 988) translate(52 61) translate(0 130)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleDocument;
