import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './index.style';

const CfoFormContainer = (props) => {
    const { generalInfo, result, ...others } = props;
    const classes = useStyles({ generalInfo, result });

    return (
        <Grid
            className={classes.formRoot}
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            {...others}
        >
            {props.children}
        </Grid>
    );
};

CfoFormContainer.propTypes = {
    generalInfo: PropTypes.bool,
};

export default CfoFormContainer;
