import * as React from 'react';

function SvgIconsMultipleDestekprogramlari(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#D9DCE0"
                    d="M9.75 13.5h4.5V5.25h-4.5zm0 9.75h4.5v-9h-4.5zm6.75 0H21v-15h-4.5zm-13.5 0h4.5V12H3z"
                />
                <path
                    fill="#3F5162"
                    d="M11.625 6.75h.75V6h-.75zm0 16.875h.75V15.75h-.75zm3.375 0h.75V3H15zm-6.75 0H9V3h-.75z"
                />
                <path
                    fill="#3F5162"
                    d="M9.86 4.765L8.625 3.53 7.39 4.765l-.53-.53L8.625 2.47l1.765 1.765zm6.75 0L15.375 3.53 14.14 4.765l-.53-.53 1.765-1.765 1.765 1.765zM9.75 14.25h4.5v-.75h-4.5zm-4.875-3h.75v-.75h-.75zm14.25-3.75h.75v-.75h-.75zm-1.5 0h.75v-.75h-.75z"
                />
                <path fill="#FFF" d="M13.5 1.875a1.5 1.5 0 11-3.001-.001 1.5 1.5 0 013.001.001" />
                <path
                    fill="#3F5162"
                    d="M12 .75c-.62 0-1.125.505-1.125 1.125S11.38 3 12 3s1.125-.505 1.125-1.125S12.62.75 12 .75m0 3c-1.034 0-1.875-.841-1.875-1.875S10.966 0 12 0s1.875.841 1.875 1.875S13.034 3.75 12 3.75M0 24h24v-.75H0z"
                />
            </g>
        </svg>
    );
}

export default SvgIconsMultipleDestekprogramlari;
