import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent, Grid } from '@material-ui/core';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import VisibilityToggle from 'fintech/components/ui/form/toggle/VisibilityToggle';
import InvestorSearchSelect from 'fintech/components/ui/form/select/InvestorSearchSelect';
import { setStartupInvestor } from 'fintech/store/actions/startup';

const InvestorCompanyUpdate = () => {
    const { t } = useTranslation();
    const { investor, investorVisible, investmentVisible, sending } = useSelector(
        (state) => state.startup.form.investorForm
    );
    const dispatch = useDispatch();

    const onChangeHandler = ({ investorId, investorName, freeTextInvestorName, isValid, isVisible, unregistered }) =>
        dispatch(
            setStartupInvestor({
                investorId: investorId,
                investorName: investorName,
                freeTextInvestorName: freeTextInvestorName,
                isValid: isValid,
                unregistered: unregistered,
                isVisible: isVisible,
            })
        );

    return (
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item>
                <DialogContent>
                    <InvestorSearchSelect
                        initialValue={{
                            id: investor.investorId,
                            name: investor.investorName,
                            unregistered: investor.unregistered,
                            logo: investor.logo,
                        }}
                        onChange={(value, isValid) =>
                            onChangeHandler({
                                investorId: value?.id,
                                investorName: value?.name,
                                isValid: isValid,
                                unregistered: value?.unregistered,
                                isVisible: investorVisible,
                            })
                        }
                        validateOnBlur
                        overrideInitiallyValidateState={sending}
                    />
                </DialogContent>
            </Grid>
            {investor.unregistered && (
                <Grid item>
                    <InputTextArea
                        type="text"
                        label={t('profile.financials.form.enterCompanyName')}
                        validationText={t('validation.notEmpty')}
                        initText={investor.freeTextInvestorName}
                        onChange={(val, isValid) =>
                            onChangeHandler({
                                freeTextInvestorName: val,
                                isValid: isValid,
                                investorId: investor.investorId,
                                unregistered: investor.unregistered,
                                isVisible: investorVisible,
                            })
                        }
                        validateOnBlur
                        validatedAfterFormSubmit={!sending}
                        required
                    />
                </Grid>
            )}
            <Grid item>
                <DialogContent style={{ paddingTop: '0', textAlign: 'end' }}>
                    <VisibilityToggle
                        initialValue={investorVisible}
                        disabled={!investmentVisible}
                        useDisabledValue
                        disabledValue={false}
                        useEnabledValue
                        enabledValue={investorVisible}
                        onChange={(isVisible) =>
                            onChangeHandler({
                                isVisible: isVisible,
                                investorId: investor.investorId,
                                investorName: investor.investorName,
                                freeTextInvestorName: investor.freeTextInvestorName,
                                isValid: investor.isValid,
                                unregistered: investor.unregistered,
                            })
                        }
                    />
                </DialogContent>
            </Grid>
        </Grid>
    );
};

export default InvestorCompanyUpdate;
