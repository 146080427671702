import { makeStyles } from '@material-ui/core';
import {
    COLOR_PRIMARY_SHADE_02,
    COLOR_PRIMARY_SHADE_03,
    COLOR_THEME_FILL_01,
    COLOR_THEME_FILL_03,
    COLOR_SECONDARY_BASE,
    buttonStyleInteraction,
} from 'fintech/components/style/common';
const useStyles = makeStyles((theme) => ({
    StupHeaderGrid: {
        borderBottom: `1px solid ${COLOR_PRIMARY_SHADE_03}`,
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '10px',
    },
    StupOfDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    StupLogoMedia: {
        width: '64px',
        height: '64px',
    },
    EllipsisButton: {
        backgroundColor: COLOR_PRIMARY_SHADE_02,
        width: '32px',
        minWidth: '32px',
        height: '28px',
        borderRadius: '8px',
        cursor: 'pointer',
        textAlign: 'center',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_03,
        },
    },
    EllipsisPopover: {
        width: '200px',
        height: '48px',
        padding: '12px 0px 12px 11px',
        borderRadius: '4px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        border: `solid 1px ${COLOR_PRIMARY_SHADE_03}`,
        backgroundColor: COLOR_THEME_FILL_01,
        cursor: 'pointer',
        display: 'flex',
        '&:hover': {
            backgroundColor: COLOR_PRIMARY_SHADE_02,
        },
    },
    arrowRightIcon: {
        width: '24px',
        height: '24px',
        objectFit: 'contain',
    },
    blockUserText: {
        height: '20px',
        margin: '2px 0 2px 8px',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.13px',
        color: COLOR_SECONDARY_BASE,
    },
    blockedByYouText: {
        height: '20px',
        marginLeft: '6px',
        fontSize: '14px',
        fontWeight: 500,
        color: COLOR_THEME_FILL_03,
    },
    dialogRoot: {
        [theme.breakpoints.up('sm')]: {
            '& .MuiPaper-root': {
                width: '700px',
                height: '360px',
                maxWidth: 'none',
            },
        },
    },
    confirmDialogContent: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: '0',
            padding: '0 71px !important',
        },
    },
    ellipsesButtonClass: {
        '& .MuiPopover-paper': {
            transform: 'translateY(5px) !important',
        },
    },
}));

export default useStyles;

export const shrinkingButtonFontSizeForTablet = (theme) => {
    return {
        [theme.breakpoints.between(900, 1200)]: {
            fontSize: '12px',
        },
    };
};

export const shrinkingButtonStyles = (theme) => {
    return {
        ...buttonStyleInteraction,
        ...shrinkingButtonFontSizeForTablet(theme),
    };
};
