import * as React from 'react';

function SvgIconsMultipleSettings(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero">
                    <g>
                        <g>
                            <g>
                                <path
                                    fill="#3F5162"
                                    d="M11.688 22c.386 0 .712-.147.977-.44.265-.294.426-.606.483-.935l.387-1.783c.23-.072.444-.15.645-.237.2-.085.4-.179.601-.279l1.547.988c.287.187.623.29 1.01.312.387.021.716-.104.988-.376l.967-.967c.272-.272.387-.594.344-.967-.043-.372-.158-.701-.344-.988l-.945-1.59c.1-.186.19-.376.268-.57.079-.192.154-.397.226-.611l1.783-.43c.33-.086.641-.244.935-.473.293-.229.44-.53.44-.902v-1.375c0-.387-.147-.713-.44-.978-.294-.265-.606-.426-.935-.483l-1.762-.387c-.057-.229-.132-.447-.225-.655-.093-.208-.19-.412-.29-.612l.945-1.59c.186-.287.3-.616.344-.988.043-.373-.072-.695-.344-.967l-.967-.967c-.272-.272-.601-.397-.988-.376-.387.022-.723.125-1.01.312l-1.547.988c-.2-.1-.404-.194-.612-.28-.208-.085-.419-.164-.634-.236l-.387-1.804c-.071-.316-.236-.62-.494-.914-.258-.293-.58-.44-.966-.44h-1.376c-.386 0-.694.147-.923.44-.23.294-.38.606-.451.935l-.452 1.762c-.229.071-.454.154-.676.247-.222.093-.44.197-.656.311l-1.59-1.01c-.272-.186-.605-.29-.999-.311-.393-.021-.72.104-.977.376l-.988.967c-.258.272-.366.594-.323.967.043.372.15.701.323.988l1.01 1.676c-.087.186-.172.38-.258.58-.086.2-.158.4-.215.601l-1.762.387c-.33.057-.641.218-.935.483-.293.265-.44.591-.44.978v1.375c0 .372.147.673.44.902.294.23.606.387.935.473l1.783.43c.057.2.126.39.204.569.08.179.161.354.247.526L2.6 16.328c-.171.287-.279.616-.322.988-.043.373.065.695.323.967l.988.967c.258.272.584.397.977.376.394-.022.727-.125 1-.312l1.59-1.01c.214.115.436.22.665.312.23.093.459.176.688.247l.43 1.762c.085.33.243.641.472.935.23.293.53.44.902.44h1.376z"
                                    transform="translate(-1184 -40) translate(280 35) translate(904 5) translate(1 1)"
                                />
                                <path
                                    fill="#D9DCE0"
                                    d="M11.666 20.625H10.42c-.029-.029-.054-.068-.075-.118-.022-.05-.04-.111-.054-.183l-.645-2.535-.73-.236c-.186-.058-.372-.126-.559-.204-.186-.08-.372-.169-.558-.269l-.71-.365-2.255 1.44c-.043.028-.09.053-.14.074-.05.022-.096.033-.14.033l-.902-.881c0-.043.011-.093.033-.15.021-.058.053-.115.096-.172l1.397-2.342-.323-.666c-.085-.158-.16-.312-.225-.462-.065-.15-.118-.297-.161-.44l-.237-.731-2.513-.645c-.086-.014-.158-.032-.215-.053-.057-.022-.1-.047-.129-.075v-1.268c.014 0 .047-.014.097-.043.05-.029.096-.05.14-.064l2.599-.58.236-.752c.043-.158.097-.316.161-.473.065-.158.14-.33.226-.516l.344-.666-1.397-2.32c-.043-.072-.075-.136-.096-.193-.022-.058-.033-.108-.033-.15l.903-.903c.043.014.086.032.129.054l.107.053L7.09 5.285l.709-.365c.186-.1.372-.19.558-.269.187-.078.373-.146.559-.204l.73-.236.624-2.492c.014-.086.035-.158.064-.215.029-.057.057-.1.086-.129h1.268l.019.033.023.042c.03.05.05.104.065.161l.559 2.6.751.258c.187.043.366.104.538.182.171.08.343.162.515.247l.71.366 2.212-1.418c.043-.029.09-.05.14-.065.05-.014.096-.021.14-.021l.902.88c0 .03-.011.072-.033.13-.021.057-.053.114-.096.171l-1.354 2.256.344.688c.1.186.186.365.258.537.071.172.129.344.172.515l.236.752 2.557.58c.071.015.136.04.193.076.057.036.093.06.107.075l-.021 1.225c-.029.028-.068.053-.118.075-.05.021-.111.04-.183.053l-2.556.645-.237.709c-.057.172-.118.34-.182.505-.065.165-.14.326-.226.483l-.344.688 1.354 2.234c.043.072.075.136.096.193.022.058.033.108.033.15l-.903.903c-.043-.014-.086-.032-.129-.054l-.107-.053-2.256-1.44-.709.366c-.172.085-.344.164-.515.236-.172.072-.344.136-.516.193l-.752.258-.559 2.557c-.014.057-.039.114-.075.172-.036.057-.06.093-.075.107z"
                                    transform="translate(-1184 -40) translate(280 35) translate(904 5) translate(1 1)"
                                />
                                <path
                                    fill="#3F5162"
                                    d="M11 15.125c1.146 0 2.123-.401 2.933-1.203.809-.802 1.213-1.776 1.213-2.922 0-1.146-.404-2.12-1.213-2.922-.81-.802-1.787-1.203-2.933-1.203-1.132 0-2.102.401-2.911 1.203-.81.802-1.214 1.776-1.214 2.922 0 1.146.405 2.12 1.214 2.922.81.802 1.78 1.203 2.911 1.203z"
                                    transform="translate(-1184 -40) translate(280 35) translate(904 5) translate(1 1)"
                                />
                                <path
                                    fill="#D9DCE0"
                                    d="M11 13.75c-.76 0-1.407-.269-1.944-.806-.537-.537-.806-1.185-.806-1.944 0-.76.269-1.407.806-1.944.537-.537 1.185-.806 1.944-.806.76 0 1.407.269 1.944.806.537.537.806 1.185.806 1.944 0 .76-.269 1.407-.806 1.944-.537.537-1.185.806-1.944.806z"
                                    transform="translate(-1184 -40) translate(280 35) translate(904 5) translate(1 1)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleSettings;
