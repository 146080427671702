import { SupportProgramApplicationActions } from 'fintech/store/actions/ActionTypes';
import { parseRepresentativeInfo } from 'fintech/utils/DataParser';
import { isEmpty } from 'lodash';

const initialState = {
    form: {
        reCatpchaVerified: false,
        readAgreement: false,
        questionFormatList: [],
        supportApplicantInfo: {
            isLoading: true,
            isError: false,
            name: {
                value: '',
                validated: false,
            },
            lastName: {
                value: '',
                validated: false,
            },
            nationality: {
                value: '',
                validated: false,
            },
            identity: {
                value: '',
                validated: false,
            },
            gender: {
                value: null,
                validated: false,
            },
            birthDate: {
                value: '',
                validated: false,
            },
            email: {
                value: '',
                validated: false,
            },
            phoneNumber: {
                value: {
                    country: 'TR',
                    countryCallingCode: '90',
                    nationalNumber: '',
                    number: '',
                },
                validated: false,
            },
            workExperienceInYears: {
                value: '',
                validated: false,
            },
            title: {
                value: '',
                validated: false,
            },
            educationInfo: {
                value: null,
                validated: false,
            },
        },
    },
    isLoading: true,
    isError: false,
};

const supportProgramApplication = (state = initialState, action) => {
    const { data, type } = action;
    switch (type) {
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION:
            return {
                ...state,
                initialForm: {
                    ...data.supportProgram.form,
                },
                form: {
                    ...state.form,
                    ...data.supportProgram.form,
                },
                isLoading: false,
            };
        case SupportProgramApplicationActions.GET_SUPPORT_PROGRAM_APPLICATION:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION_REP_INFO:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        ...parseRepresentativeInfo(action.data),
                        isLoading: false,
                    },
                },
            };
        case SupportProgramApplicationActions.GET_SUPPORT_PROGRAM_APPLICATION_REP_INFO:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        isLoading: true,
                        isError: false,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION_REP_INFO_ERROR:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        isLoading: false,
                        isError: true,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION_RECAPTCHA: {
            return {
                ...state,
                form: {
                    ...state.form,
                    reCatpchaVerified: true,
                },
            };
        }
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_APPLICATION_AGREEMENTS:
            return {
                ...state,
                form: {
                    ...state.form,
                    readAgreement: !state.form.readAgreement,
                },
            };

        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_NAME:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        name: action.data,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_LASTNAME:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        lastName: action.data,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_NATIONALITY:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        nationality: action.data,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_IDENTITY:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        identity: action.data,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_GENDER:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        gender: action.data,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_BIRTHDATE:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        birthDate: action.data,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_EMAIL:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        email: action.data,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_PHONENUMBER:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        phoneNumber: {
                            ...state.phoneNumber,
                            ...action.data,
                        },
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_WORK_EXPERIENCE:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        workExperienceInYears: action.data,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_WORK_TITLE:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        title: action.data,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_EDUCATION:
            return {
                ...state,
                form: {
                    ...state.form,
                    supportApplicantInfo: {
                        ...state.form.supportApplicantInfo,
                        educationInfo: action.data,
                    },
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q01:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        {
                            ...state.form.questionFormatList[0],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(1),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q02:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 1),
                        {
                            ...state.form.questionFormatList[1],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(2),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q03:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 2),
                        {
                            ...state.form.questionFormatList[2],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(3),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q04:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 3),
                        {
                            ...state.form.questionFormatList[3],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(4),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q05:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 4),
                        {
                            ...state.form.questionFormatList[4],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(5),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q06:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 5),
                        {
                            ...state.form.questionFormatList[5],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(6),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q07:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 6),
                        {
                            ...state.form.questionFormatList[6],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(7),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q08: // fileupload
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 7),
                        {
                            ...state.form.questionFormatList[7],
                            fileUploadAnswerList: action.data.files?.[0]
                                ? [
                                      {
                                          answerDefinition: {
                                              answerNumber: 1,
                                              answerType: 'FILE_UPLOAD',
                                          },
                                          file: action.data.files[0].file,
                                      },
                                  ]
                                : null,
                        },
                        ...state.form.questionFormatList.slice(8),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q09_P1: // web + social
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 8),
                        {
                            ...state.form.questionFormatList[8],
                            freeTextAnswerList: state.form.questionFormatList[8]?.freeTextAnswerList
                                ? [
                                      {
                                          answerDefinition: {
                                              answerNumber: 1,
                                          },
                                          value: action.data.value,
                                          validated: action.data.validated,
                                      },
                                      ...state.form.questionFormatList[8]?.freeTextAnswerList?.slice(
                                          state.form.questionFormatList[8]._websiteUrl ? 1 : 0
                                      ),
                                  ]
                                : [
                                      {
                                          answerDefinition: {
                                              answerNumber: 1,
                                          },
                                          value: action.data.value,
                                          validated: action.data.validated,
                                      },
                                  ],
                            _websiteUrl: action.data.value,
                        },
                        ...state.form.questionFormatList.slice(9),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q09_P2: // web + social
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 8),
                        {
                            ...state.form.questionFormatList[8],
                            freeTextAnswerList: state.form.questionFormatList[8]._websiteUrl
                                ? [
                                      state.form.questionFormatList[8]?.freeTextAnswerList[0],
                                      ...Object.values(action.data).map((value) => ({
                                          answerDefinition: {
                                              answerNumber: value.answerNumber,
                                          },
                                          ...value,
                                      })),
                                  ]
                                : Object.values(action.data).map((value) => ({
                                      answerDefinition: {
                                          answerNumber: value.answerNumber,
                                      },
                                      ...value,
                                  })),
                            _socialMediaUrls: action.data,
                        },
                        ...state.form.questionFormatList.slice(9),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q10:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 9),
                        {
                            ...state.form.questionFormatList[9],
                            startUpStages: action.data.stages,
                            sourceSelectAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'COMBOBOX',
                                        sourceType: 'STARTUP_STAGE',
                                    },
                                    selectedId: action.data.value ? action.data.value.id : null,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(10),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q11:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 10),
                        {
                            ...state.form.questionFormatList[10],
                            dateAnswerList: action.data.validated
                                ? [
                                      {
                                          answerDefinition: {
                                              answerNumber: 1,
                                              answerType: 'DATE_PICKER',
                                          },
                                          value: action.data.value,
                                          validated: action.data.validated,
                                      },
                                  ]
                                : null,
                        },
                        ...state.form.questionFormatList.slice(11),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q12:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 11),
                        {
                            ...state.form.questionFormatList[11],
                            verticals: action.data.verticals,
                            sourceSelectAnswerList: action.data.value?.map((vert) => ({
                                answerDefinition: {
                                    answerNumber: 1,
                                    answerType: 'COMBOBOX',
                                    sourceType: 'FINTECH_SUB_VERTICAL',
                                },
                                selectedId: vert.id,
                            })),

                            validated: action.data.validated,
                        },
                        ...state.form.questionFormatList.slice(12),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q13:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 12),
                        {
                            ...state.form.questionFormatList[12],
                            technologies: action.data.technologies,
                            sourceSelectAnswerList: action.data.value?.map((tech) => ({
                                answerDefinition: {
                                    answerNumber: 1,
                                    answerType: 'COMBOBOX',
                                    sourceType: 'TECHNOLOGY',
                                },
                                selectedId: tech.id,
                            })),

                            validated: action.data.validated,
                        },
                        ...state.form.questionFormatList.slice(13),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q14:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 13),
                        {
                            ...state.form.questionFormatList[13],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(14),
                    ],
                },
            };

        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q15:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 14),
                        {
                            ...state.form.questionFormatList[14],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'RADIO_BUTTON',
                                    },
                                    selectedAnswerOptionList: [
                                        {
                                            id: action.data.value,
                                        },
                                    ],
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(15),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q16:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 15),
                        {
                            ...state.form.questionFormatList[15],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(16),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q17:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 16),
                        {
                            ...state.form.questionFormatList[16],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'RADIO_BUTTON',
                                    },
                                    selectedAnswerOptionList: [
                                        {
                                            id: action.data.value,
                                        },
                                    ],
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(17),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q18:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 17),
                        {
                            ...state.form.questionFormatList[17],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(18),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_INIT:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 18),
                        {
                            ...state.form.questionFormatList[18],
                            freeTextAnswerList: {
                                linkedIn: [
                                    {
                                        id: 0,
                                        answerDefinition: {
                                            answerNumber: 1,
                                        },
                                        value: '',
                                        validated: true,
                                    },
                                ],
                                github: [
                                    {
                                        id: 0,
                                        answerDefinition: {
                                            answerNumber: 2,
                                        },
                                        value: '',
                                        validated: true,
                                    },
                                ],
                                linkedInLastId: 0,
                                githubLastId: 0,
                            },
                        },
                        ...state.form.questionFormatList.slice(19),
                    ],
                },
            };
        // LinkedIn
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 18),
                        {
                            ...state.form.questionFormatList[18],
                            freeTextAnswerList: {
                                ...state.form.questionFormatList[18].freeTextAnswerList,
                                linkedIn: [
                                    ...state.form.questionFormatList[18]?.freeTextAnswerList.linkedIn.slice(
                                        0,
                                        action.data.index
                                    ),
                                    {
                                        ...state.form.questionFormatList[18]?.freeTextAnswerList.linkedIn[
                                            action.data.index
                                        ],
                                        value: action.data.value,
                                        validated: action.data.validated,
                                    },
                                    ...state.form.questionFormatList[18]?.freeTextAnswerList.linkedIn.slice(
                                        action.data.index + 1
                                    ),
                                ],
                            },
                        },
                        ...state.form.questionFormatList.slice(19),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1_ADD:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 18),
                        {
                            ...state.form.questionFormatList[18],
                            freeTextAnswerList: {
                                ...state.form.questionFormatList[18].freeTextAnswerList,
                                linkedIn: [
                                    ...state.form.questionFormatList[18].freeTextAnswerList.linkedIn,
                                    {
                                        id: state.form.questionFormatList[18].freeTextAnswerList.linkedInLastId + 1,
                                        answerDefinition: {
                                            answerNumber: 1,
                                        },
                                        value: '',
                                        validated: false,
                                    },
                                ],
                                linkedInLastId: state.form.questionFormatList[18].freeTextAnswerList.linkedInLastId + 1,
                            },
                        },
                        ...state.form.questionFormatList.slice(19),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P1_REMOVE:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 18),
                        {
                            ...state.form.questionFormatList[18],
                            freeTextAnswerList: {
                                ...state.form.questionFormatList[18].freeTextAnswerList,
                                linkedIn: state.form.questionFormatList[18].freeTextAnswerList.linkedIn.filter(
                                    (ans) => ans.id !== action.data.id
                                ),
                            },
                        },
                        ...state.form.questionFormatList.slice(19),
                    ],
                },
            };

        // Github
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 18),
                        {
                            ...state.form.questionFormatList[18],
                            freeTextAnswerList: {
                                ...state.form.questionFormatList[18].freeTextAnswerList,
                                github: [
                                    ...state.form.questionFormatList[18]?.freeTextAnswerList.github.slice(
                                        0,
                                        action.data.index
                                    ),
                                    {
                                        ...state.form.questionFormatList[18]?.freeTextAnswerList.github[
                                            action.data.index
                                        ],
                                        value: action.data.value,
                                        validated: action.data.validated,
                                    },
                                    ...state.form.questionFormatList[18]?.freeTextAnswerList.github.slice(
                                        action.data.index + 1
                                    ),
                                ],
                            },
                        },
                        ...state.form.questionFormatList.slice(19),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2_ADD:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 18),
                        {
                            ...state.form.questionFormatList[18],
                            freeTextAnswerList: {
                                ...state.form.questionFormatList[18].freeTextAnswerList,
                                github: [
                                    ...state.form.questionFormatList[18].freeTextAnswerList.github,
                                    {
                                        id: state.form.questionFormatList[18].freeTextAnswerList.githubLastId + 1,
                                        answerDefinition: {
                                            answerNumber: 2,
                                        },
                                        value: '',
                                        validated: false,
                                    },
                                ],
                                githubLastId: state.form.questionFormatList[18].freeTextAnswerList.githubLastId + 1,
                            },
                        },
                        ...state.form.questionFormatList.slice(19),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q19_P2_REMOVE:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 18),
                        {
                            ...state.form.questionFormatList[18],
                            freeTextAnswerList: {
                                ...state.form.questionFormatList[18].freeTextAnswerList,
                                github: state.form.questionFormatList[18].freeTextAnswerList.github.filter(
                                    (ans) => ans.id !== action.data.id
                                ),
                            },
                        },
                        ...state.form.questionFormatList.slice(19),
                    ],
                },
            };

        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_INIT:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 19),
                        {
                            ...state.form.questionFormatList[19],
                            freeTextAnswerList: [
                                {
                                    id: 0,
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: '',
                                    validated: false,
                                },
                            ],
                            lastId: 0,
                        },
                        ...state.form.questionFormatList.slice(20),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 19),
                        {
                            ...state.form.questionFormatList[19],
                            freeTextAnswerList: [
                                ...state.form.questionFormatList[19].freeTextAnswerList.slice(0, action.data.index),
                                {
                                    ...state.form.questionFormatList[19]?.freeTextAnswerList[action.data.index],
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                                ...state.form.questionFormatList[19]?.freeTextAnswerList.slice(action.data.index + 1),
                            ],
                        },
                        ...state.form.questionFormatList.slice(20),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_ADD:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 19),
                        {
                            ...state.form.questionFormatList[19],
                            freeTextAnswerList: [
                                ...state.form.questionFormatList[19].freeTextAnswerList,
                                {
                                    id: state.form.questionFormatList[19].lastId + 1,
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: '',
                                    validated: false,
                                },
                            ],
                            lastId: state.form.questionFormatList[19].lastId + 1,
                        },
                        ...state.form.questionFormatList.slice(20),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q20_REMOVE:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 19),
                        {
                            ...state.form.questionFormatList[19],
                            freeTextAnswerList: state.form.questionFormatList[19].freeTextAnswerList.filter(
                                (ans) => ans.id !== action.data.id
                            ),
                        },
                        ...state.form.questionFormatList.slice(20),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q21:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 20),
                        {
                            ...state.form.questionFormatList[20],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(21),
                    ],
                },
            };

        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q22:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 21),
                        {
                            ...state.form.questionFormatList[21],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'RADIO_BUTTON',
                                    },
                                    selectedAnswerOptionList: [
                                        {
                                            id: action.data.value,
                                        },
                                    ],
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(22),
                    ],
                },
            };

        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q23_P1:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 22),
                        {
                            ...state.form.questionFormatList[22],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'CHECKBOX',
                                    },
                                    selectedAnswerOptionList: getUpdatedSelectedAnswerOptionsList(action.data),
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(23),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q23_P2:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 22),
                        {
                            ...state.form.questionFormatList[22],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 2,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(23),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q24:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 23),
                        {
                            ...state.form.questionFormatList[23],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'RADIO_BUTTON',
                                    },
                                    selectedAnswerOptionList: [
                                        {
                                            id: action.data.value,
                                        },
                                    ],
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(24),
                    ],
                },
            };

        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q25_P1:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 24),
                        {
                            ...state.form.questionFormatList[24],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'CHECKBOX',
                                    },
                                    selectedAnswerOptionList: getUpdatedSelectedAnswerOptionsList(action.data),
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(25),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q25_P2:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 24),
                        {
                            ...state.form.questionFormatList[24],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 2,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(25),
                    ],
                },
            };

        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q26_P1:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 25),
                        {
                            ...state.form.questionFormatList[25],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'CHECKBOX',
                                    },
                                    selectedAnswerOptionList: getUpdatedSelectedAnswerOptionsList(action.data),
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(26),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q26_P2:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 25),
                        {
                            ...state.form.questionFormatList[25],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 2,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(26),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q27:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 26),
                        {
                            ...state.form.questionFormatList[26],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'CHECKBOX',
                                    },
                                    selectedAnswerOptionList: getUpdatedSelectedAnswerOptionsList(action.data),
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(27),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q28:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 27),
                        {
                            ...state.form.questionFormatList[27],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(28),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q29:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 28),
                        {
                            ...state.form.questionFormatList[28],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(29),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q30:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 29),
                        {
                            ...state.form.questionFormatList[29],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(30),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q31:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 30),
                        {
                            ...state.form.questionFormatList[30],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(31),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q32:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 31),
                        {
                            ...state.form.questionFormatList[31],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'CHECKBOX',
                                    },
                                    selectedAnswerOptionList: getUpdatedSelectedAnswerOptionsList(action.data),
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(32),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q33:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 32),
                        {
                            ...state.form.questionFormatList[32],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(33),
                    ],
                },
            };

        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_INIT:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 33),
                        {
                            ...state.form.questionFormatList[33],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 2,
                                    },
                                    value: '',
                                    validated: false,
                                },
                                {
                                    answerDefinition: {
                                        answerNumber: 4,
                                    },
                                    value: '',
                                    validated: false,
                                },
                                {
                                    answerDefinition: {
                                        answerNumber: 5,
                                    },
                                    value: '',
                                    validated: false,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(34),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_TYPES:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 33),
                        {
                            ...state.form.questionFormatList[33],
                            startupTypes: action.data.types,
                        },
                        ...state.form.questionFormatList.slice(34),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P1:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 33),
                        {
                            ...state.form.questionFormatList[33],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'RADIO_BUTTON',
                                    },
                                    selectedAnswerOptionList: [
                                        {
                                            id: action.data.value,
                                        },
                                    ],
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(34),
                    ],
                },
            };
        // FREE_TEXT
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P2:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 33),
                        {
                            ...state.form.questionFormatList[33],
                            freeTextAnswerList: [
                                ...state.form.questionFormatList[33].freeTextAnswerList?.slice(0, action.data.index),
                                {
                                    ...state.form.questionFormatList[33].freeTextAnswerList?.[action.data.index],
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                                ...state.form.questionFormatList[33].freeTextAnswerList?.slice(action.data.index + 1),
                            ],
                        },
                        ...state.form.questionFormatList.slice(34),
                    ],
                },
            };
        // COMBOBOX
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q34_P3:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 33),
                        {
                            ...state.form.questionFormatList[33],
                            sourceSelectAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 3,
                                        answerType: 'COMBOBOX',
                                        sourceType: 'STARTUP_TYPE',
                                    },
                                    selectedId: action.data.value.id,
                                },
                            ],
                            freeTextAnswerList: [
                                ...state.form.questionFormatList[33].freeTextAnswerList?.slice(0, 1),
                                {
                                    ...state.form.questionFormatList[33].freeTextAnswerList?.[action.data.index],
                                    value: '',
                                    validated: false,
                                },
                                ...state.form.questionFormatList[33].freeTextAnswerList?.slice(2),
                            ],
                        },
                        ...state.form.questionFormatList.slice(34),
                    ],
                },
            };

        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q35:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 34),
                        {
                            ...state.form.questionFormatList[34],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'RADIO_BUTTON',
                                    },
                                    selectedAnswerOptionList: [
                                        {
                                            id: action.data.value,
                                        },
                                    ],
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(35),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q36:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 35),
                        {
                            ...state.form.questionFormatList[35],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(36),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q37:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 36),
                        {
                            ...state.form.questionFormatList[36],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(37),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q38:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 37),
                        {
                            ...state.form.questionFormatList[37],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(38),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q39:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 38),
                        {
                            ...state.form.questionFormatList[38],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(39),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q40_P1:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 39),
                        {
                            ...state.form.questionFormatList[39],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'CHECKBOX',
                                    },
                                    selectedAnswerOptionList: getUpdatedSelectedAnswerOptionsList(action.data),
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(40),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q40_P2:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 39),
                        {
                            ...state.form.questionFormatList[39],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 2,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(40),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q41:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 40),
                        {
                            ...state.form.questionFormatList[40],
                            freeTextAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                    },
                                    value: action.data.value,
                                    validated: action.data.validated,
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(41),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q42:
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 41),
                        {
                            ...state.form.questionFormatList[41],
                            selectionAnswerList: [
                                {
                                    answerDefinition: {
                                        answerNumber: 1,
                                        answerType: 'CHECKBOX',
                                    },
                                    selectedAnswerOptionList: getUpdatedSelectedAnswerOptionsList(action.data),
                                },
                            ],
                        },
                        ...state.form.questionFormatList.slice(42),
                    ],
                },
            };
        case SupportProgramApplicationActions.SET_SUPPORT_PROGRAM_FORM_FIELD_OF_Q43: // fileupload
            return {
                ...state,
                form: {
                    ...state.form,
                    questionFormatList: [
                        ...state.form.questionFormatList.slice(0, 42),
                        {
                            ...state.form.questionFormatList[42],
                            fileUploadAnswerList: !isEmpty(action.data.files)
                                ? [
                                      ...action.data.files?.map((file) => ({
                                          answerDefinition: {
                                              answerNumber: 1,
                                              answerType: 'FILE_UPLOAD',
                                          },
                                          file: file?.file,
                                      })),
                                  ]
                                : null,
                        },
                        ...state.form.questionFormatList.slice(43),
                    ],
                },
            };
        default:
            return state;
    }
};

const getUpdatedSelectedAnswerOptionsList = ({ checked, selections, id }) =>
    checked ? [...selections, { id }] : selections.filter((selection) => selection.id !== id);

export default supportProgramApplication;
