import * as React from 'react';

function SvgIconsSingleRemove(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <defs>
                <filter id="hxufvv6jca" colorInterpolationFilters="auto">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 0.358407 0 0 0 0 0.478216 0 0 0 0 0.858182 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g
                            filter="url(#hxufvv6jca)"
                            transform="translate(-908 -1097) translate(280 988) translate(624 107)"
                        >
                            <g>
                                <path
                                    fill="#3F5162"
                                    fillRule="nonzero"
                                    d="M17.238 7.2V5.688h-2.592l-.774-.738h-3.726l-.774.738h-2.61V7.2h10.476zm-2.25 11.25c.408 0 .762-.15 1.062-.45.3-.3.45-.654.45-1.062v-9h-9v9c0 .408.15.762.45 1.062.3.3.654.45 1.062.45h5.976z"
                                    transform="translate(4 2)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsSingleRemove;
