import { AnnouncementActions } from 'fintech/store/actions/ActionTypes';

const initialState = {
    announcements: [],
    isLoading: true,
    isError: false,
};

const announcement = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case AnnouncementActions.SET_ANNOUNCEMENT:
            const { announcements } = action;
            return {
                ...state,
                announcements: announcements,
                isLoading: false,
            };
        case AnnouncementActions.SET_ANNOUNCEMENT_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case AnnouncementActions.SET_ANNOUNCEMENT_LOAD_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            return state;
    }
};

export default announcement;
