import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import LeftMenuItem from './LeftMenuItem';
// import ButtonIos from 'fintech/icons/ButtonIos';
// import ButtonAndroid from 'fintech/icons/ButtonAndroid';
import * as FintechBeService from 'fintech/api/fintechService';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { Trans, useTranslation } from 'react-i18next';
import { getLoggedInUserUuid, getGivenNameAndFamilyNameFirstChar } from 'fintech/utils/Auth';
import { mobileMediaQuery } from 'fintech/components/style/common';
import PageContainer from 'fintech/components/menu/leftmenu/PageContainer';
import useStyles from 'fintech/components/menu/leftmenu/Platform.style';
import Navbar from 'fintech/components/menu/leftmenu/Navbar';
import { useHistory, useLocation } from 'react-router';
import { includeObject } from 'fintech/utils/ArrayUtils';
import { UserAvatar } from 'fintech/components/profile/user/Intro/Header/UserAvatar';
import { fetchAttempListData } from 'fintech/store/actions/attemp';
import { fetchEstablishmentsListData } from 'fintech/store/actions/establishment';
import SwitchActionModal from 'fintech/components/util/SwitchActionModal';
import LogoFinTechHub from 'fintech/static/images/Logo-FinTechHub-Erkek.svg';
import { invisibleMenuItems, getSignOutMenuElement, getSettingsMenuElement } from 'fintech/utils/LeftMenuUtils';
import { setTitleText } from 'fintech/store/actions/navigation';
import { STATES } from 'fintech/store/actions/ActionTypes';
import { HOME } from 'fintech/routes';

const Platform = (props) => {
    const { window } = props;
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const isWeb = useMediaQuery(mobileMediaQuery);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [cardViewItems, setCardViewItems] = useState([]);
    const [rowItems, setRowItems] = useState([]);
    const { attemps: fetchedAttemps } = useSelector((state) => state.attemps);
    const { titleText } = useSelector(({ navigation }) => navigation);

    const [menuItemsHovered, setMenuItemsHovered] = useState(false);
    const [openAttemptPickerModal, setOpenAttemptPickerModal] = useState(false);
    const [openEstablishmentPickerModal, setOpenEstablishmentPickerModal] = useState(false);

    const { establishments: fetchedEstablishments } = useSelector((state) => state.establishment);

    const setTitle = (text) => {
        dispatch(setTitleText(text));
    };

    const selectMenuItem = (id, routing, onClick) => {
        if (onClick) {
            onClick();
            return;
        }
        if (id === 'MYATTEMPT') {
            if (fetchedAttemps.length > 1) {
                setOpenAttemptPickerModal(true);
            } else if (fetchedAttemps.length == 1) {
                history.push(routing + '/' + fetchedAttemps[0].uuid);
            }
        } else if (id === 'MYESTABLISHMENT') {
            if (fetchedEstablishments.length > 1) {
                setOpenEstablishmentPickerModal(true);
            } else if (fetchedEstablishments.length == 1) {
                history.push(routing + '/' + fetchedEstablishments[0].uuid);
            }
        } else {
            history.push(routing);
        }
        setMobileOpen(false);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        dispatch(fetchAttempListData(keycloak));
        dispatch(fetchEstablishmentsListData(keycloak, getLoggedInUserUuid(keycloak)));
        FintechBeService.getLeftMenuItem(keycloak)
            .then((value) => {
                if (value && value.data) {
                    const data = value.data;
                    const rowItems = _.filter(data, function (o) {
                        return !o.cardView;
                    });

                    const cardItems = _.filter(data, function (o) {
                        return o.cardView;
                    });
                    const itemsToBeChecked = [...data, ...invisibleMenuItems];
                    const selectedMenuObject = includeObject(itemsToBeChecked, 'urlPath', location.pathname);

                    const firstUrlToBeRedirected = rowItems[0].urlPath;

                    const signOutMenuElement = getSignOutMenuElement(t, keycloak);
                    rowItems.splice(0, 0, signOutMenuElement);
                    const settingsMenuElement = getSettingsMenuElement(t, keycloak);
                    rowItems.splice(1, 0, settingsMenuElement);

                    setRowItems(rowItems);
                    setCardViewItems(cardItems);

                    selectedMenuObject ? history.push(location.pathname) : history.push(firstUrlToBeRedirected);
                }
            })
            .catch((reason) => console.error(reason.toString()));
    }, []);

    useEffect(() => {
        const invisibleMenuItemsWithLocalizedTexts = invisibleMenuItems.map((item) => {
            return { ...item, menuLabel: t(item.menuLabel) };
        });
        const allItems = [...invisibleMenuItemsWithLocalizedTexts, ...rowItems, ...cardViewItems];
        if (allItems.length > 0) {
            const foundRoute = allItems.find((item) => location.pathname.includes(item.urlPath));
            if (foundRoute) {
                setTitle(foundRoute.menuLabel);
            }
        }
    }, [location]);

    const rowItemsFilteredToMedia = rowItems.filter((item) => {
        if (item.onlyMobile) {
            if (!isWeb) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    });

    const userNameInfo = getGivenNameAndFamilyNameFirstChar(keycloak);

    const cardViewItemsExist = cardViewItems && cardViewItems.length > 0;

    let menuSectionDividerTopOfMenuClassName = null;
    const rowMenuElement = document.getElementById('rowMenuDivElementId');
    const scrollbarVisible = rowMenuElement && rowMenuElement.scrollHeight > rowMenuElement.clientHeight;
    const isRowMenuHovered = rowMenuElement && menuItemsHovered;
    if (isWeb) {
        if (scrollbarVisible && isRowMenuHovered) {
            menuSectionDividerTopOfMenuClassName = classes.menuSectionDividerTopOfMenuVisible;
        } else {
            menuSectionDividerTopOfMenuClassName = classes.menuSectionDividerTopOfMenuNotVisible;
        }
    } else {
        menuSectionDividerTopOfMenuClassName = classes.menuSectionDividerTopOfMenuVisible;
    }

    let rowMenuDivClassName = null;
    if (isWeb) {
        rowMenuDivClassName = cardViewItemsExist ? classes.rowMenuDivWeb : classes.rowMenuDivWithoutCardViewItemsWeb;
    } else {
        rowMenuDivClassName = cardViewItemsExist
            ? classes.rowMenuDivMobile
            : classes.rowMenuDivWithoutCardViewItemsMobile;
    }

    let cardGridViewClassName = 'margin-top-8 d-flex align-items-center justify-content-center';
    if (cardViewItemsExist) {
        cardGridViewClassName += ' margin-bottom-25';
    }

    const drawer = (
        <div className={classes.leftMenuWrapper}>
            <div className={classes.leftMenuTopSection}>
                <Grid
                    container
                    direction={'column'}
                    alignItems={'center'}
                    justify={'center'}
                    className={classes.leftMenuLogoWrapper}
                >
                    <div style={{ cursor: 'pointer' }}>
                        <img src={LogoFinTechHub} width="185px" onClick={() => history.push(HOME)} />
                    </div>
                </Grid>
                <Grid
                    container
                    direction={'column'}
                    alignItems={'center'}
                    justify={'center'}
                    className={classes.leftMenuUserInfoWrapper}
                >
                    <Grid>
                        <UserAvatar disabled hideBorder={true} userUuid={getLoggedInUserUuid(keycloak)} />
                    </Grid>
                    <Grid className={'margin-top-9 poppin text-align-center'}>
                        {t('leftMenu.welcome') + ' '}
                        <span className={'poppin-bold'}>{userNameInfo}</span>
                    </Grid>
                </Grid>
                {/*CARD VIEW ITEMS*/}
                <Grid container direction={'row'} className={cardGridViewClassName}>
                    {cardViewItems.map(function (d, idx) {
                        return (
                            <Grid key={idx} item className={classes.menuItem}>
                                <LeftMenuItem
                                    id={d.id}
                                    name={d.onlySideMenuLabel || d.menuLabel}
                                    isOneRow={false}
                                    onSelect={selectMenuItem}
                                    onClick={d.onClick}
                                    routing={d.urlPath}
                                    currentPathname={location.pathname}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <Divider className={menuSectionDividerTopOfMenuClassName} />
            </div>
            {/*ROW VIEW ITEMS*/}
            <div
                className={rowMenuDivClassName}
                id="rowMenuDivElementId"
                onMouseEnter={() => setMenuItemsHovered(true)}
                onMouseLeave={() => setMenuItemsHovered(false)}
            >
                <div className={classes.rowMenuInnerDiv}>
                    <List className={classes.rowItemList}>
                        {rowItemsFilteredToMedia.map(function (d, idx) {
                            return (
                                <ListItem key={idx} className={classes.rowMenuItem}>
                                    <Grid container alignItems={'flex-start'} justify={'center'} item xs={12}>
                                        <LeftMenuItem
                                            id={d.id}
                                            name={d.onlySideMenuLabel || d.menuLabel}
                                            isOneRow={true}
                                            onSelect={selectMenuItem}
                                            onClick={d.onClick}
                                            routing={d.urlPath}
                                            currentPathname={location.pathname}
                                        />
                                    </Grid>
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            </div>

            <div className={classes.leftMenuBottomSection}>
                <Divider className={classes.menuSectionDividerBottomOfMenu} />
                <List className={classes.leftMenuBottomList}>
                    <ListItem className={classes.contactTextListItem}>
                        <span className={classes.contactText}>
                            <Trans
                                i18nKey="leftMenu.contactText"
                                components={{
                                    addressTr: <span key={'contactTextKey'} className={classes.contactAddress} />,
                                    addressEn: <span key={'contactTextKey'} className={classes.contactAddress} />,
                                    br: <br />,
                                }}
                            />
                        </span>
                    </ListItem>

                    <ListItem className={classes.finalMenuSectionDivider}>
                        <Grid container className={'full-width'}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </ListItem>

                    {/* <ListItem className={classes.leftMenuFooterSection}>
                        <Grid container direction={'column'} alignItems={'center'}>
                            <Grid item xs={12} className={'poppin-500-14px text-align-center margin-bottom-16'}>
                                <Grid container direction={'column'} alignItems={'center'}>
                                    <Grid item xs={12}>
                                        <img width="100px" src={LogoFinTechHub} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <span className={classes.promoteText}>{t('leftMenu.promoteText')}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.mobileDownloadButtons}>
                                <Grid container direction={'row'}>
                                    <Grid item xs={6}>
                                        <a href="#" target="_blank" rel="noreferrer">
                                            <ButtonIos width={83} height={21} />
                                        </a>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <a href="#" target="_blank" rel="noreferrer">
                                            <ButtonAndroid width={75} height={22} />
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem> */}
                </List>
            </div>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const navbarClassName = isWeb ? 'navbarContainer webNavbar' : 'navbarContainer mobileNavbar';
    const pageContainerClassName = isWeb ? 'margin-top-44 pageContainer' : 'margin-top-59 pageContainer';

    return (
        <div className={classes.root + ' mainPageContainer'}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <div className={navbarClassName}>
                    <Toolbar>
                        <IconButton
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Navbar selectedMenuTitle={titleText} />
                    </Toolbar>
                </div>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={pageContainerClassName}>
                    <PageContainer pageTitle={titleText} />
                    {openAttemptPickerModal && (
                        <SwitchActionModal
                            open={openAttemptPickerModal}
                            setOpen={setOpenAttemptPickerModal}
                            fetchedData={fetchedAttemps}
                            title={t('idea.interactionModal.title')}
                            subTitle={t('idea.interactionModal.subTitle')}
                            footerTitle={t('idea.interactionModal.footerTitle')}
                            type={STATES.ATTEMPTS}
                        />
                    )}
                    {openEstablishmentPickerModal && (
                        <SwitchActionModal
                            open={openEstablishmentPickerModal}
                            setOpen={setOpenEstablishmentPickerModal}
                            fetchedData={fetchedEstablishments}
                            title={t('establishment.interactionModal.title')}
                            subTitle={t('establishment.interactionModal.subTitle')}
                            footerTitle={t('establishment.interactionModal.footerTitle')}
                            type={STATES.ESTABLISHMENT}
                        />
                    )}
                </div>
            </main>
        </div>
    );
};

Platform.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};
export default Platform;
