import * as React from 'react';

function SvgIconsSingleDownload() {
    return (
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.5 9.647h-3.714V4H9.214v5.647H5.5l6.5 6.588 6.5-6.588zm-13 8.47V20h13v-1.882h-13z"
                fill="#5B7ADB"
                fillRule="nonzero"
            />
        </svg>
    );
}

export default SvgIconsSingleDownload;
