import React, { useState, useEffect } from 'react';
import ActionModal from 'fintech/components/util/ActionModal';
import { Grid, FormControl, useMediaQuery } from '@material-ui/core';
import { REJECT, SUBMIT } from 'fintech/components/util/ActionModalConstants';
import { laptopLarge } from 'fintech/components/style/common';
import { useStyles } from './index';
import { useSnackbar } from 'notistack';
import * as FintechBeService from 'fintech/api/fintechService';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import InputTextArea from 'fintech/components/ui/form/textinput/InputTextArea';
import { getRequest } from 'fintech/api/api';
import { APIS } from 'fintech/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveRequest } from 'fintech/store/actions/common';
import SingleSelect from 'fintech/components/ui/form/select/SingleSelect';

function ModalConsultantProfileUpdate({ title, open, setOpen, updateCallback, userUuid, fetchedData }) {
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();
    const classes = useStyles();
    const { hasActiveRequest } = useSelector((state) => state.common);
    const dispatch = useDispatch();

    const initialStates = {
        biography: {
            val:
                fetchedData &&
                fetchedData.profileInfos &&
                fetchedData.profileInfos.biography &&
                fetchedData.profileInfos.biography.value
                    ? fetchedData.profileInfos.biography.value
                    : '',
            validated: true,
        },
        mailAddress: {
            val: fetchedData && fetchedData.mailAddress ? fetchedData.mailAddress : '',
            validated: true,
        },
        expertise: {
            id: fetchedData && fetchedData.expertise ? fetchedData.expertise.id : '',
            name: fetchedData && fetchedData.expertise ? fetchedData.expertise.name : '',
        },
    };

    const [biography, setBiography] = useState(initialStates.biography);
    const [mailAddress, setMailAddress] = useState(initialStates.mailAddress);
    const [expertise, setExpertise] = useState(initialStates.expertise);
    const [expertiseList, setExpertiseList] = useState([]);

    const matches = useMediaQuery(laptopLarge);
    const modalSize = matches
        ? {
              minWidth: '700px',
              minHeight: '690px',
              '& > div:last-child': {
                  marginTop: 'auto',
              },
          }
        : {};

    const { enqueueSnackbar } = useSnackbar();
    const handleClickVariant = (variant, text) => {
        enqueueSnackbar(text, { variant });
    };

    const actionEventTrigger = (actionData) => {
        if (actionData.type == SUBMIT) {
            if (!biography.validated || !biography.val || !mailAddress.validated || !mailAddress.val) {
                handleClickVariant('error', t('validation.genericError'));
                return;
            }
            dispatch(setActiveRequest(true));

            const requestData = {
                uuid: userUuid,
                mailAddress: mailAddress.val,
                expertise: expertise,
                userProfileInfoList: [{ field: 'biography', value: `${biography.val}` }],
            };
            FintechBeService.updateUserProfile(keycloak, requestData)
                .then(() => {
                    dispatch(setActiveRequest(false));
                    setOpen(false);
                    updateCallback(true);
                    handleClickVariant('success', t('idea.updateSucces'));
                })
                .catch(() => {
                    dispatch(setActiveRequest(false));
                    handleClickVariant('error', t('idea.updateError'));
                });
        } else if (actionData.type == REJECT) {
            setOpen(false);
        }
    };

    useEffect(() => {
        getRequest(APIS.USER.getConsultantExpertisesURL(userUuid), keycloak)
            .then((data) => {
                if (data && data.success) {
                    setExpertiseList(data.data);
                }
            })
            .catch((error) => {
                console.error('Error occurred when fetching expertiseList:: ', error);
            });
        return () => {
            setBiography(initialStates.biography.val);
            setMailAddress(initialStates.mailAddress.val);
            setExpertise(initialStates.expertise);
        };
    }, []);

    const handleSetExpertise = (value) => {
        setExpertise(() => ({
            id: value.id,
            name: value.name,
        }));
    };

    const expertiseListWithName = expertiseList.map((e) => {
        return { ...e, name: e.data };
    });

    return (
        <ActionModal
            open={open}
            title={title}
            onAction={actionEventTrigger}
            buttonContainerClass={classes.buttonContainer}
            modalSize={modalSize}
            titleMarginBottom30={false}
            okClickDisabled={hasActiveRequest}
            cancelClickDisabled={hasActiveRequest}
            showSpinnerWhenDisabled={hasActiveRequest}
        >
            <div className={classes.contentContainer}>
                <Grid container>
                    <Grid item xs={12}>
                        <InputTextArea
                            required
                            maxLength
                            multiline
                            count={3000}
                            onChange={(val, validated) => setBiography(() => ({ val, validated }))}
                            rowCount={11}
                            initText={biography.val}
                            containerClass={classes.containerClass}
                            className={classes.biography}
                            validationText={t('validation.biography')}
                            type="text"
                            id="outlined-multiline-static"
                            label={t('profile.user.biography')}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputTextArea
                            required
                            validateOnBlur
                            containerClass={classes.containerClass}
                            initText={mailAddress.val}
                            onChange={(val, validated) => setMailAddress(() => ({ val, validated }))}
                            validationText={t('validation.email')}
                            label={t('profile.user.email')}
                            type="mail"
                            id="outlined-multiline-static"
                            variant="outlined"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <SingleSelect
                                label={t('profile.user.expertise')}
                                dontValidate={true}
                                canBeEmpty={false}
                                options={expertiseListWithName}
                                initValue={expertise}
                                onChange={handleSetExpertise}
                                getOptionLabel={(option) => option.name}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </ActionModal>
    );
}

export default ModalConsultantProfileUpdate;
