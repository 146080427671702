export const ROLE_CONTENT_ADMIN = 'ROLE_CONTENT_ADMIN';
export const ROLE_COUNSELOR = 'ROLE_COUNSELOR';
export const ROLE_EVALUATION_COMMITTEE = 'ROLE_EVALUATION_COMMITTEE';
export const ROLE_INVESTOR_STAKEHOLDER = 'ROLE_INVESTOR_STAKEHOLDER';
export const ROLE_INVESTOR_REPRESANTATIVE = 'ROLE_INVESTOR_REPRESANTATIVE';
export const ROLE_MENTOR = 'ROLE_MENTOR';
export const ROLE_STARTUP_REPRESANTATIVE = 'ROLE_STARTUP_REPRESANTATIVE';
export const ROLE_STARTUP_WORKER = 'ROLE_STARTUP_WORKER';
export const ROLE_IDEA_REPRESANTATIVE = 'ROLE_IDEA_REPRESANTATIVE';
export const ROLE_IDEA_WORKER = 'ROLE_IDEA_WORKER';
export const ROLE_ADMIN = 'ROLE_ADMIN';
