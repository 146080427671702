import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SwitchLabel from 'fintech/components/ui/form/switch/SwitchLabel';
import useStyles from './index.style';
import { getInvestorOrStartupFormValueFromState } from 'fintech/utils/DataParser';

const InvestorVisibilitySwitch = (props) => {
    const { type, setInvestmentVisibility } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        investmentVisible,
        investorVisible,
        investmentDateVisible,
        investmentAmountVisible,
    } = useSelector((state) => getInvestorOrStartupFormValueFromState(state, type));
    const dispatch = useDispatch();

    useEffect(() => {
        if (!investorVisible && !investmentDateVisible && !investmentAmountVisible) {
            onChangeHandler(true);
        }
        if (!investmentVisible && (investorVisible || investmentDateVisible || investmentAmountVisible)) {
            onChangeHandler(false);
        }
    }, [investorVisible, investmentDateVisible, investmentAmountVisible]);

    const onChangeHandler = (val) => {
        dispatch(setInvestmentVisibility(!val));
    };

    return (
        <DialogContent className={classes.VisibilityDialog}>
            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                <Grid item>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start">
                        <Grid item>
                            <Typography className={classes.VisibilityLabel}>
                                {t('profile.financials.form.hideInfo')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <SwitchLabel
                                checked={!investmentVisible}
                                useOverride
                                overrideValue={!investorVisible && !investmentDateVisible && !investmentAmountVisible}
                                onChange={onChangeHandler}
                                labelChecked={t('formLabel.open')}
                                labelUnchecked={t('formLabel.closed')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography className={classes.VisibilityDesc}>
                        {t('profile.financials.form.hideInfoDescription')}
                    </Typography>
                </Grid>
            </Grid>
        </DialogContent>
    );
};

export default InvestorVisibilitySwitch;
