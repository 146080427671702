import { makeStyles } from '@material-ui/core';
import {
    COLOR_PRIMARY_BASE,
    COLOR_THEME_FILL_01,
    COLOR_SECONDARY_BASE,
    COLOR_PRIMARY_BUTTON_HOVERED,
    COLOR_PRIMARY_SHADE_03,
} from 'fintech/components/style/common';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: COLOR_PRIMARY_BASE,
            color: COLOR_THEME_FILL_01,
            '&:hover': {
                backgroundColor: COLOR_PRIMARY_BUTTON_HOVERED,
            },
        },
        '& .MuiPaginationItem-page': {
            backgroundColor: COLOR_THEME_FILL_01,
            color: COLOR_SECONDARY_BASE,
            '&:hover': {
                backgroundColor: COLOR_PRIMARY_SHADE_03,
            },
        },
    },
    ul: {
        '& li button': {
            color: COLOR_SECONDARY_BASE,
            backgroundColor: COLOR_THEME_FILL_01,
            padding: '1rem',
            fontFamily: 'Poppins',
            fontWeight: '600',
        },
    },
}));

export default useStyles;
