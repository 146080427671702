import * as React from 'react';

function SvgIconsMultipleProfilStartup(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path
                                                fill="#F7F7F7"
                                                d="M20.969 3.094h-.344c-.38 0-.688.308-.688.687v4.813l-.565.743c-.189-.242-.48-.4-.81-.4l-3.093 3.094c-.688.688-.688 1.719-.688 1.719v2.406h3.438v-.343s0-.688.687-1.375l2.063-2.063c.687-.687.687-1.719.687-1.719V3.781c0-.38-.308-.687-.687-.687"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#D9DCE0"
                                                d="M13.75 19.25L19.25 19.25 19.25 16.5 13.75 16.5z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M15.125 15.813h-.688V13.75c0-.048.01-1.182.789-1.962l3.194-3.194h.142c.759 0 1.375.616 1.375 1.375v.108l-2.468 3.526-.563-.394 2.337-3.339c-.042-.29-.265-.522-.55-.576l-2.981 2.98c-.58.58-.587 1.467-.587 1.476v2.063zM17.875 17.875L18.563 17.875 18.563 17.188 17.875 17.188z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M18.563 16.5H13.75v-.687h4.125c0-.034.009-.84.788-1.618l2.063-2.063c.58-.58.587-1.467.587-1.476V3.781c0-.19-.154-.343-.344-.343h-.344c-.19 0-.344.154-.344.343v4.813h-.687V3.78c0-.568.462-1.031 1.031-1.031h.344c.568 0 1.031.463 1.031 1.031v6.875c0 .048-.009 1.183-.788 1.962l-2.063 2.063c-.567.567-.586 1.112-.587 1.135v.684z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#F7F7F7"
                                                d="M1.031 3.094h.344c.38 0 .688.308.688.687v4.813l.565.743c.189-.242.48-.4.81-.4l3.093 3.094c.688.688.688 1.719.688 1.719v2.406H3.78v-.343s0-.688-.687-1.375L1.03 12.375c-.687-.687-.687-1.719-.687-1.719V3.781c0-.38.308-.687.687-.687"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#D9DCE0"
                                                d="M2.75 19.25L8.25 19.25 8.25 16.5 2.75 16.5z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M7.563 15.813h-.688V13.75c0-.008-.012-.901-.587-1.476l-2.98-2.98c-.286.054-.51.286-.55.576l2.336 3.34-.563.393-2.468-3.526V9.97c0-.759.616-1.375 1.374-1.375h.143l3.194 3.194c.78.78.788 1.914.788 1.962v2.063zM3.438 17.875L4.125 17.875 4.125 17.188 3.438 17.188z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M8.25 16.5H3.437v-.687c0-.02-.02-.565-.586-1.132L.788 12.618C.008 11.838 0 10.704 0 10.656V3.781C0 3.213.463 2.75 1.031 2.75h.344c.569 0 1.031.463 1.031 1.031v4.813H1.72V3.78c0-.19-.154-.343-.344-.343h-.344c-.19 0-.343.154-.343.343v6.875c0 .008.012.902.586 1.476l2.063 2.063c.78.779.788 1.584.788 1.618H8.25v.687z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#D9DCE0"
                                                d="M15.469 0.344L6.531 0.344 4.469 3.781 11 12.031 17.531 3.781z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M4.886 3.754L11 11.478l6.114-7.724-1.84-3.067H6.726l-1.84 3.067zM11 12.584L4.051 3.809 6.337 0h9.327l2.285 3.808L11 12.585z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M4.469 4.125L17.531 4.125 17.531 3.438 4.469 3.438z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M11.33 12.128L10.67 11.935 13.076 3.685 13.736 3.878z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M10.67 12.128L8.264 3.878 8.924 3.685 11.33 11.935z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M8.299 3.958L6.237.521 6.826.167 8.889 3.604zM13.701 3.958L13.111 3.604 15.174.167 15.763.521z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M8.875 3.978L8.312 3.584 10.718 0.147 11.282 0.541z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                            <path
                                                fill="#3F5162"
                                                d="M13.125 3.978L10.718 0.541 11.282 0.147 13.688 3.584z"
                                                transform="translate(-257.000000, -345.000000) translate(79.000000, 115.000000) translate(22.000000, 38.000000) translate(0.000000, 181.000000) translate(140.000000, 0.000000) translate(16.000000, 11.000000) translate(1.000000, 2.000000)"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIconsMultipleProfilStartup;
