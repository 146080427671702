import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import NavTabs from 'fintech/components/ui/navigation/NavTabs';
import PageTemplate from 'fintech/components/PageTemplate';
import BoxedSearchBar from 'fintech/components/search/BoxedSearchBar';
import AdvancedFilterArea from 'fintech/components/search/AdvancedFilterArea';
import SearchDataGrid from 'fintech/components/search/DataGrid';
import {
    closeAdvancedFilter,
    fetchActiveUsersCount,
    fetchAdvancedFilterData,
    fetchGridData,
    removeAppliedFilter,
    removeAppliedFiltersBySubcategory,
    resetAllFilters,
    searchBoxOnChange,
    setSearchBoxFilterStatus,
    setAppliedFilters,
    setGridHeaders,
    setGridPage,
    setSelectedCategory,
    toggleAdvancedFilter,
    toggleCheckbox,
    uncheckAllCheckboxes,
    persistState,
} from 'fintech/store/actions/userSearch';
import { formatNumericValue } from 'fintech/utils/StringUtils';
import GridPagination from 'fintech/components/pagination/GridPagination';
import { UserSearchActions } from 'fintech/store/actions/ActionTypes';
import {
    ROLE_ADMIN,
    ROLE_IDEA_REPRESANTATIVE,
    ROLE_INVESTOR_REPRESANTATIVE,
    ROLE_STARTUP_REPRESANTATIVE,
} from 'fintech/components/util/RoleConstants';
import { defaultTableHeaders, adminTableHeaders } from 'fintech/constants/userSearch';
import { hasRoles } from 'fintech/utils/Auth';
import ConfirmDialog from 'fintech/components/ui/modal/ConfirmModal';
import { InfoIcon } from 'fintech/components/profile/common';
import { ACTIVE } from 'fintech/components/util/StatusConstants';
import * as FintechBeService from 'fintech/api/fintechService';
import userImage from 'fintech/static/images/kisiler.jpg';
import userImageMobile from 'fintech/static/images/kisiler-mobil.jpg';
import { isNil } from 'lodash';
import { uniqueSeperator } from 'fintech/constants';
import NewUserModal from 'fintech/components/search/user/NewUserModal';
import PageHeaderBox from 'fintech/components/util/PageHeaderBox';
import { PERSONS_PROFILE } from 'fintech/routes';
import { useHistory } from 'react-router';
import { setActiveRequest } from 'fintech/store/actions/common';

const UsersPage = () => {
    const { t } = useTranslation('userSearch');
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const { totalNumber, persist } = useSelector((state) => state.userSearch.global);
    const { headers, rows, users, pagination, isLoading, gridDataLoading, hasActiveRequest } = useSelector((state) => ({
        ...state.userSearch.grid,
        ...state.common,
    }));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const errorCallback = (msg = t('idea.updateError')) => enqueueSnackbar(msg, { variant: 'error' });
    const type = 'userSearch';
    const tabs = [{ label: t('nav.allUsers') }];
    const isAdmin = hasRoles(keycloak, ROLE_ADMIN);
    const initHeaders = (!isAdmin ? defaultTableHeaders : adminTableHeaders).map((he) => ({
        ...he,
        value:
            he.type === 'sortLabel'
                ? {
                      ...he.value,
                      title: t(he.value),
                  }
                : t(he.value),
    }));

    // Representative edit modal
    const [modalOpen, setModalOpen] = useState(false);

    // Status update confirmation dialog
    const [dialogState, setDialogState] = useState({
        open: false,
        editingId: null,
        editingName: null,
        value: null,
        isRepresentative: false,
        companyType: '',
        pageName: '',
    });

    // Representative modal handlers
    const clickAddHandler = () => setModalOpen(true);
    const cancelModalHandler = () => setModalOpen(false);

    // Admin dialog handlers
    const clickSwitchHandler = (uuid, name) => {
        const user = users && users[uuid];
        if (user && !isNil(user.status) && !isNil(user.roles)) {
            let companyType = '';
            let pageName = '';
            if (
                (user.roles.includes(ROLE_STARTUP_REPRESANTATIVE) || user.roles.includes(ROLE_IDEA_REPRESANTATIVE)) &&
                user.roles.includes(ROLE_INVESTOR_REPRESANTATIVE)
            ) {
                companyType = t('form.bothTypes');
                pageName = t('form.bothPages');
            } else if (
                user.roles.includes(ROLE_STARTUP_REPRESANTATIVE) ||
                user.roles.includes(ROLE_IDEA_REPRESANTATIVE)
            ) {
                companyType = t('form.startup');
                pageName = t('form.startups');
            } else if (user.roles.includes(ROLE_INVESTOR_REPRESANTATIVE)) {
                companyType = t('form.investor');
                pageName = t('form.investors');
            }
            setDialogState({
                open: true,
                editingId: uuid,
                editingName: name,
                value: user.status === ACTIVE ? false : true,
                isRepresentative:
                    user.roles.includes(ROLE_STARTUP_REPRESANTATIVE) ||
                    user.roles.includes(ROLE_IDEA_REPRESANTATIVE) ||
                    user.roles.includes(ROLE_INVESTOR_REPRESANTATIVE),
                companyType,
                pageName,
            });
        } else {
            errorCallback();
        }
    };

    const dialogConfirmedHandler = () => {
        dispatch(setActiveRequest(true));
        const isActive = !dialogState.value;

        FintechBeService.updateUserStatus(keycloak, isActive, dialogState.editingId)
            .then((res) => {
                dispatch(setActiveRequest(false));
                dispatch(fetchActiveUsersCount(keycloak, errorCallback));
                dispatch(fetchGridData({ keycloak, errorCallback, useLastQueryType: true }));
                setDialogState((prevState) => ({
                    ...prevState,
                    open: false,
                    editingId: null,
                    editingName: null,
                    value: null,
                }));
                enqueueSnackbar(
                    res.message ? res.message : t('form.statusDialogSuccess', { name: dialogState.editingName }),
                    {
                        variant: 'success',
                    }
                );
            })
            .catch((reason) => {
                dispatch(setActiveRequest(false));
                errorCallback(reason?.message);
            });
    };

    const dialogCancelHandler = () =>
        setDialogState((prevState) => ({
            ...prevState,
            open: false,
            editingId: null,
            editingName: null,
            value: null,
        }));

    const makeSearchHandler = (input, filters) => {
        const searchFilter = filters?.find((fi) => fi.status);
        if (searchFilter) {
            dispatch({ type: UserSearchActions.RESET_USER_GRID_PAGINATION });
            dispatch(
                fetchGridData({
                    keycloak,
                    data: {
                        nameSelected: filters.find(({ label }) => label === 'name').status,
                        fintechVerticalsSelected: filters.find(({ label }) => label === 'fintechVerticals').status,
                        technologySelected: filters.find(({ label }) => label === 'technologies').status,
                        searchTerm: input,
                    },
                    errorCallback,
                    isFilter: false,
                })
            );
        } else {
            errorCallback(t('searchbar.validationText'));
        }
    };

    useEffect(() => {
        if (!persist) {
            window.scroll({ top: 0, left: 0 });
            dispatch(setGridHeaders(initHeaders));
            dispatch(resetAllFilters());
            dispatch(fetchActiveUsersCount(keycloak, errorCallback));
            dispatch(fetchGridData({ keycloak, errorCallback }));
            dispatch(fetchAdvancedFilterData(keycloak, errorCallback));
        } else {
            dispatch(fetchActiveUsersCount(keycloak, errorCallback));
            dispatch(fetchGridData({ keycloak, errorCallback }));
            dispatch(fetchAdvancedFilterData(keycloak, errorCallback));
            dispatch(persistState(false));
        }
    }, []);

    const gridPageChangeHandler = (e, pageNumber) => {
        window.scroll({ top: 0, left: 0 });
        dispatch(setGridPage(pageNumber));
        dispatch(fetchGridData({ keycloak, errorCallback, useLastQueryType: true }));
    };

    return (
        <PageTemplate>
            <PageHeaderBox />
            <NavTabs id="search-navtabs" value={0} tabs={tabs} />
            <BoxedSearchBar
                type={type}
                backgroundImage={userImage}
                backgroundImageMobile={userImageMobile}
                labelHtml={t('searchbar.registeredUsers', { count: formatNumericValue(totalNumber) })}
                placeholderText={t('searchbar.placeholder')}
                searchBoxOnChange={searchBoxOnChange}
                setSearchBoxFilterStatus={setSearchBoxFilterStatus}
                makeSearch={makeSearchHandler}
                resetAllFilters={() => dispatch(resetAllFilters())}
                fetchGridData={() => dispatch(fetchGridData({ keycloak, errorCallback }))}
                toggleAdvancedFilter={toggleAdvancedFilter}
            />
            <AdvancedFilterArea
                changeTextOnQueryType
                type={type}
                showModalToggle={isAdmin}
                modalToggleLabel={t('form.addUserTitle')}
                onToggleClicked={clickAddHandler}
                closeAdvancedFilter={closeAdvancedFilter}
                removeAppliedFilter={removeAppliedFilter}
                removeAppliedFiltersBySubcategory={removeAppliedFiltersBySubcategory}
                removeComponentHandler={(_categorylPrefix, _subCategory) => {
                    dispatch(removeAppliedFiltersBySubcategory(_categorylPrefix, _subCategory));
                    dispatch(fetchGridData({ keycloak, errorCallback }));
                }}
                removeItemHandler={(_categorylPrefix, _itemID) => {
                    dispatch(removeAppliedFilter(_categorylPrefix, _itemID));
                    dispatch(fetchGridData({ keycloak, errorCallback }));
                }}
                clearAll={() => {
                    dispatch(setAppliedFilters(keycloak, [], errorCallback));
                    dispatch(resetAllFilters());
                    dispatch(uncheckAllCheckboxes());
                }}
                setSelectedCategory={setSelectedCategory}
                toggleAdvancedFilter={toggleAdvancedFilter}
                toggleCheckbox={toggleCheckbox}
                buttonActions={{
                    closeButton: () => {
                        dispatch(closeAdvancedFilter());
                    },
                    unCheckAll: () => {
                        dispatch(uncheckAllCheckboxes());
                    },
                    apply: () => {
                        dispatch({ type: UserSearchActions.RESET_USER_GRID_PAGINATION });
                        dispatch(setAppliedFilters(keycloak, null, errorCallback));
                    },
                }}
            />
            <SearchDataGrid
                type={type}
                headers={headers}
                pagination={pagination}
                leftStickyColumnCount={isAdmin ? 2 : 1}
                buttonActions={{
                    sort: (id) => {
                        dispatch({ type: UserSearchActions.TOGGLE_USER_GRID_STATE, data: +id });
                        dispatch(fetchGridData({ keycloak, errorCallback, useLastQueryType: true, reloadGrid: true }));
                    },
                    clearAll: () => {
                        dispatch(resetAllFilters());
                        dispatch(fetchGridData({ keycloak, errorCallback }));
                    },
                    handleRowClick: (uuid) => {
                        dispatch(persistState(true));
                        history.push(`${PERSONS_PROFILE}/${uuid}`);
                    },
                }}
                // For actions specific to ADMIN
                adminActions={[
                    {
                        title: t('form.switchStatus'),
                        onClick: (id, name) => clickSwitchHandler(id?.split(uniqueSeperator)[0], name),
                    },
                ]}
            />
            {rows && rows.length > 0 && (
                <GridPagination
                    controlled
                    {...pagination}
                    disabled={isLoading || gridDataLoading}
                    handleChange={gridPageChangeHandler}
                />
            )}
            {isAdmin && <NewUserModal type="userSearch" open={modalOpen} onCancel={() => cancelModalHandler()} />}
            {isAdmin && (
                <ConfirmDialog
                    open={dialogState.open}
                    icon={<InfoIcon fontSize="large" />}
                    title={t('dialog.confirmTitle')}
                    injectHtml
                    content={
                        dialogState.isRepresentative && !dialogState.value
                            ? t('form.statusDialogRepPassiveWarning', {
                                  status: dialogState.value ? t('form.active') : t('form.passive'),
                                  companyType: dialogState.companyType,
                                  pageName: dialogState.pageName,
                              })
                            : t('form.statusDialogWarning', {
                                  status: dialogState.value ? t('form.active') : t('form.passive'),
                              })
                    }
                    labelCancel={t('dialog.cancelAction')}
                    labelConfirm={t('dialog.confirmAction')}
                    onCancel={dialogCancelHandler}
                    onConfirm={dialogConfirmedHandler}
                    confirmDisabled={hasActiveRequest}
                    cancelDisabled={hasActiveRequest}
                    showSpinnerWhenDisabled={hasActiveRequest}
                />
            )}
        </PageTemplate>
    );
};

export default UsersPage;
