import React, { useState, useEffect } from 'react';

export default function ActionModalTab({ tabs, changeSelectedView, overrideInitialTab = false }) {
    const [selectedTab, setSelectedTab] = useState(
        overrideInitialTab ? tabs.filter((tab) => tab.selected)[0]?.id : tabs[0].id
    );

    useEffect(() => {
        overrideInitialTab ? setSelectedTab(tabs.filter((tab) => tab.selected)[0]?.id) : tabs[0].id;
    }, [tabs]);

    const clickTabItem = (tabItem) => {
        setSelectedTab(tabItem);
        changeSelectedView(tabItem);
    };
    return (
        <div className={'d-flex align-items-center justify-content-center poppin-500-14px'}>
            <div className={'actionModalTab'}>
                <nav className="nav nav-pills nav-justified">
                    {tabs.map(function (d, idx) {
                        return (
                            <a
                                className={'nav-link ' + (d.id === selectedTab && 'selected')}
                                href="#"
                                onClick={() => clickTabItem(d.id)}
                                key={idx}
                            >
                                {d.name}
                            </a>
                        );
                    })}
                </nav>
            </div>
        </div>
    );
}
