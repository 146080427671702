import React from 'react';
import PropTypes from 'prop-types';
import useStyle from './index.style';

const Table = ({ column, dataSource }) => {
    const classes = useStyle();
    return (
        <table className={classes.table}>
            {column.map((x, index) => (
                <tr key={'' + index + ''}>
                    <td>{x.title}</td>
                    <td>{dataSource[x.dataIndex]}</td>
                </tr>
            ))}
        </table>
    );
};

Table.propTypes = {
    column: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, dataIndex: PropTypes.string })).isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

Table.defaultProps = {};

export default Table;
