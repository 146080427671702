import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { LocalOffer } from '@material-ui/icons';
import useStyles from './index.style';
import IntroTab from 'fintech/components/profile/startup/Intro/IntroTab';
import IntroHeader from 'fintech/components/profile/startup/Intro/Header';
import IntroDetails from 'fintech/components/profile/startup/Intro/Details';
import { titleWithVisibilityBadge, IntroCardContent, IntroCardTitle } from 'fintech/components/profile/common';
import SplitButton from 'fintech/components/ui/button/SplitButton';
import ErrorBoundary from 'fintech/components/util/ErrorBoundary';
import Empty from 'fintech/components/util/Empty';
import TagGroup from 'fintech/components/profile/common/TagGroup';
import IntroGridItem from 'fintech/components/profile/common/GridItem';
import { StyledTag } from 'fintech/components/ui/button/common';
import StartupIntroEditModal from 'fintech/components/profile/startup/form/intro/IntroEditModal';
import { formatMonetaryValue } from 'fintech/utils/StringUtils';
import { VENTURE_TYPES, STATES } from 'fintech/store/actions/ActionTypes';
import {
    splitButtonNotInDatagridGroupStyle,
    splitButtonTagsNotInDatagridGroupStyle,
    styledTagNotInDatagridStyle,
} from 'fintech/components/style/common';

const IntroGrid = (props) => {
    const classes = useStyles();
    const { t, id, ventureType } = props;
    const { data: fetchedData, isLoading, isError } = useSelector((state) => state.startup.intro);
    const [openEditModal, setOpenEditModal] = useState(false);

    const isStartup = VENTURE_TYPES.STARTUP === ventureType || VENTURE_TYPES.BACKED_STARTUP === ventureType;

    let hasData = _.keys(fetchedData).length > 0;

    let startupJSX = null;
    let emptyProps = {};

    const { logo, name, isBacked, editable, startupInfos } = fetchedData;
    if (isLoading) {
        emptyProps.isLoading = true;
        emptyProps.message = t('profile.loadingMessage');
    } else if (isError) {
        emptyProps.isError = true;
        emptyProps.message = t('profile.errorMessage');
    } else if (hasData) {
        startupJSX = (
            <Grid
                className={classes.StupIntroCard}
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
            >
                {fetchedData.uuid && (
                    <>
                        <IntroHeader
                            isAttempt={true}
                            name={name}
                            logo={logo}
                            website={startupInfos?.website}
                            editable={editable}
                            isBacked={isBacked}
                            onClickEditCallback={() => clickEditHandler()}
                            isStartup={isStartup}
                            fetchedData={fetchedData}
                        />
                        <IntroDetails
                            getQuadGridItems={() => getQuadGridItems(t, editable, isStartup, fetchedData)}
                            getDuoGridItems={() => getDuoGridItems(t, editable, isStartup, fetchedData)}
                            mediaItems={fetchedData.media}
                        />
                    </>
                )}
                {editable && (
                    <StartupIntroEditModal
                        ventureType={ventureType}
                        startupId={id}
                        open={openEditModal}
                        onCancel={() => cancelEditHandler()}
                    />
                )}
            </Grid>
        );
    } else {
        emptyProps.message = t('profile.emptyMessage');
    }

    const clickEditHandler = () => setOpenEditModal(true);
    const cancelEditHandler = () => setOpenEditModal(false);

    return (
        <>
            <IntroTab type={STATES.ATTEMPTS} times={fetchedData.visitTimes} />
            <ErrorBoundary message={t('profile.errorMessage')}>
                {hasData ? startupJSX : <Empty {...emptyProps} />}
            </ErrorBoundary>
        </>
    );
};

export default withTranslation('profile')(IntroGrid);

const getQuadGridItems = (t, editable, isStartup, { dateFounded, startupInfos, totalInvestment, summary }) => {
    const itemsJSX = [];

    if (!isStartup) {
        if (startupInfos) {
            itemsJSX.push(
                <IntroGridItem
                    htmlBind={true}
                    key="startup-date"
                    title={<IntroCardTitle>{t('idea.summary')}</IntroCardTitle>}
                    content={<IntroCardContent>{summary?.value || '-'}</IntroCardContent>}
                />
            );
        }
    } else {
        // Foundation date
        itemsJSX.push(
            <IntroGridItem
                half
                key="startup-date"
                title={<IntroCardTitle>{t('profile.startup.dateFounded')}</IntroCardTitle>}
                content={<IntroCardContent>{dateFounded || '-'} </IntroCardContent>}
            />
        );

        // Num of employees
        itemsJSX.push(
            <IntroGridItem
                half
                key="startup-employee"
                title={<IntroCardTitle>{t('profile.startup.employees')}</IntroCardTitle>}
                content={
                    <IntroCardContent>
                        {startupInfos?.employees?.value ? startupInfos.employees.value : '-'}
                    </IntroCardContent>
                }
            />
        );
        // Investment amount
        // TODO: Refactor & extract to a seperate function
        if (startupInfos) {
            const { public: isPublic = false, canHide = false } = startupInfos.investment || {};
            if (editable) {
                itemsJSX.push(
                    <IntroGridItem
                        half
                        key="startup-investment"
                        title={titleWithVisibilityBadge(isPublic, canHide, t('profile.startup.investment'))}
                        content={
                            startupInfos.investment && totalInvestment?.value ? (
                                <IntroCardContent>
                                    {totalInvestment.value &&
                                        formatMonetaryValue(totalInvestment.value, totalInvestment.currencyType)}
                                </IntroCardContent>
                            ) : (
                                <IntroCardContent>-</IntroCardContent>
                            )
                        }
                    />
                );
            } else {
                itemsJSX.push(
                    <IntroGridItem
                        half
                        key="startup-investment"
                        title={<IntroCardTitle>{t('profile.startup.investment')}</IntroCardTitle>}
                        content={
                            isPublic && startupInfos.investment && totalInvestment ? (
                                <IntroCardContent>
                                    {totalInvestment.value &&
                                        formatMonetaryValue(totalInvestment.value, totalInvestment.currencyType)}
                                </IntroCardContent>
                            ) : (
                                <IntroCardContent>-</IntroCardContent>
                            )
                        }
                    />
                );
            }
        } else {
            if (totalInvestment && totalInvestment.value) {
                itemsJSX.push(
                    <IntroGridItem
                        half
                        key="startup-investment"
                        title={<IntroCardTitle>{t('profile.startup.investment')}</IntroCardTitle>}
                    />
                );
            }
        }
    }

    return itemsJSX;
};

const getDuoGridItems = (t, editable, isStartup, { verticals, technologies, stage, address, startupInfos }) => {
    const itemsJSX = [];

    // Verticals
    const verts = _.keys(verticals)?.map((vert, idx) => (
        <SplitButton
            groupStyle={splitButtonTagsNotInDatagridGroupStyle}
            key={idx}
            option={vert}
            subOptions={verticals[vert]}
        />
    ));
    itemsJSX.push(
        <IntroGridItem
            title={<IntroCardTitle>{t('profile.venture.verticals')}</IntroCardTitle>}
            content={
                !verts || verts.length < 1 ? (
                    <IntroCardContent>-</IntroCardContent>
                ) : (
                    <TagGroup limit={5} items={verts} clickableStyledTagStyle={splitButtonNotInDatagridGroupStyle} />
                )
            }
        />
    );

    // Technologies
    const techs = technologies?.map((tech, idx) => (
        <StyledTag
            disableElevation
            disableRipple
            disableTouchRipple
            key={idx}
            startIcon={<LocalOffer fontSize="small" />}
            style={styledTagNotInDatagridStyle}
        >
            {tech}
        </StyledTag>
    ));

    itemsJSX.push(
        <IntroGridItem
            title={<IntroCardTitle>{t('profile.venture.technology')}</IntroCardTitle>}
            content={
                techs && techs.length > 0 ? (
                    <TagGroup limit={5} items={techs} clickableStyledTagStyle={styledTagNotInDatagridStyle} />
                ) : (
                    <IntroCardContent>-</IntroCardContent>
                )
            }
            wrapperClassName={'margin-top-5'}
        />
    );

    // Stage
    itemsJSX.push(
        <IntroGridItem
            title={<IntroCardTitle>{t('profile.venture.stage')}</IntroCardTitle>}
            content={
                stage ? (
                    <StyledTag
                        disableElevation
                        disableRipple
                        disableTouchRipple
                        startIcon={<LocalOffer fontSize="small" />}
                        style={styledTagNotInDatagridStyle}
                    >
                        {stage}
                    </StyledTag>
                ) : (
                    <IntroCardContent>-</IntroCardContent>
                )
            }
            wrapperClassName={'margin-top-8 margin-bottom-8'}
        />
    );

    if (isStartup) {
        if (startupInfos && startupInfos.address) {
            // TODO: Refactor & extract to a seperate function
            const { public: isPublic, canHide } = startupInfos?.address;
            if (editable) {
                itemsJSX.push(
                    <IntroGridItem
                        key="startup-address"
                        title={titleWithVisibilityBadge(isPublic, canHide, t('profile.startup.address'))}
                        content={
                            <IntroCardContent>
                                {startupInfos.address && address && address.city && address.country
                                    ? `${address.description || ''} ${address.city.name} / ${address.country.name}`
                                    : '-'}
                            </IntroCardContent>
                        }
                    />
                );
            } else {
                let content = '';
                if (address) {
                    if (isPublic) {
                        content = `${address.description || ''} ${address.city.name} / ${address.country.name}`;
                    } else {
                        content = `${address.city.name} / ${address.country.name}`;
                    }
                }

                itemsJSX.push(
                    <IntroGridItem
                        key="startup-address"
                        title={<IntroCardTitle>{t('profile.startup.address')}</IntroCardTitle>}
                        content={<IntroCardContent>{content}</IntroCardContent>}
                    />
                );
            }
        } else {
            itemsJSX.push(
                <IntroGridItem
                    key="startup-address"
                    title={<IntroCardTitle>{t('profile.startup.address')}</IntroCardTitle>}
                />
            );
        }
    }
    // Address

    // Email
    if (startupInfos) {
        const { public: isPublic = false, canHide = false } = startupInfos.email || {};

        let content = '';

        if (editable || isPublic) {
            content = startupInfos?.email && <IntroCardContent>{startupInfos.email?.value || '-'}</IntroCardContent>;
        } else {
            content = <IntroCardContent>-</IntroCardContent>;
        }

        itemsJSX.push(
            <Grid item xs={12}>
                <Grid container direction="row">
                    <IntroGridItem
                        title={
                            editable ? (
                                titleWithVisibilityBadge(isPublic, canHide, t('profile.venture.email'))
                            ) : (
                                <IntroCardTitle>{t('profile.venture.email')}</IntroCardTitle>
                            )
                        }
                        content={content}
                    />
                </Grid>
            </Grid>
        );
    }
    return itemsJSX;
};
